import './gridMenu.less'

import React from 'react'
import ReactDOM from 'react-dom'

import Utils from 'tools/utils'
import State from 'tools/state'
import {reaction} from 'mobx'
import {AntButton} from "controls/react/ant/antButton"
import {MenuOutlined} from '@ant-design/icons'
import {createRoot} from "react-dom/client";


export let GridMenu = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default GridMenu;

GridMenu.AtLeastOneItemSelected = '1';
GridMenu.ExactlyOneItemSelected = '2';
GridMenu.AtLeastTwoItemsSelected = '3';
GridMenu.Never = '4';

const b = require('b_').with('grid-menu')

const MenuButton = (props) => {
	return <div className={b()}>
		<AntButton icon={<MenuOutlined />} onClick={props.onClick}/>
		<span className="cw_actions_wrapper left">
			<ul className="cw_actions_menu"></ul>
		</span>
	</div>
}

GridMenu.prototype = {
	/**
	 * @config {String} renderTo The selector of the tag where the menu is rendered
	 */
	/**
	 * @config {Array} items Array of objects containing items properties : id,
	 * icon, text, fn, scope, disabled, role
	 */
	itemList: [],

	initComponent: function () {
		this.reactionDisposers = []

		if (typeof this.renderTo == 'string') {
			this.renderTo = '#' + this.renderTo;
			this.root = $(this.renderTo);
		} else {
			this.root = this.renderTo;
		}

		if (!this.root[0]) {
			return;
		}

		this.reactRoot = createRoot(this.root[0])
		this.reactRoot.render(<MenuButton onClick={(e) => this.onActionButton(e)}/>)

		requestIdleCallback(() => {
			this.itemList = [];
			if (this.size === 'large') {
				this.root.find('.cw_actions_menu').css('width', '300px');
			}


			this.populateMenu(this.items)

			this.removeListeners();
			this.attachListeners();
			this.updateTopButton();
		})
	},

	populateMenu(newItems) {
		this.items = [...newItems];

		this.clearItems();

		if(this.sortingDisabled != true) {
			this.items.sort(this.compareAlphabetically);
		}

		if (this.indexedByCategory) {
			this.items.sort(this.compareByCategoryIndex);
			this.addItemsByCategory();
		} else {
			this.addItems();
		}
	},

	addItemsByCategory() {
		for (let i = 0; i < this.items.length; i++) {
			let currentItem = this.items[i];
			let nextItem = this.items[i + 1];
			if (nextItem) {
				if (currentItem.categoryIndex < nextItem.categoryIndex || currentItem.categoryIndex && !nextItem.categoryIndex) {
					currentItem.dividerClass = 'double_divider';
				}
			}
			this.addItem(currentItem);
		}
	},

	addItems() {
		const genericItemsText = [lang.ADD, lang.CREATE, lang.DELETE, lang.UPDATE, lang.EDIT];
		let genericItems = [];
		let customItems = [];
		for (let item of this.items) {
		//for (let i = 0, length = this.items.length; i < length; i++) {
			if (genericItemsText.indexOf(item.text) !== -1 || item.isGeneric) {
				genericItems.push(item);
			} else {
				customItems.push(item);
			}
		}
		for (let genericItem of genericItems) {
			if (genericItem === genericItems[genericItems.length - 1] && customItems.length) {
				genericItem.dividerClass = 'double_divider';
			}
			this.addItem(genericItem);
		}
		for (let customItem of customItems) {
			this.addItem(customItem);
		}
		// to apply roles restrictions
		this.items.forEach(x => {
			if (!x.disabled) {
				this.enableItem(x.id);
			}
		})
		this.onGridSelectionChanged()
	},

	removeListeners: function () {
	//	this.root.find('.cw_actions_trigger').off('click.grid_menu');
		window.removeEventListener('click', $.proxy(this.onWindowClick, this))
	},

	attachListeners: function () {
		//this.root.find('.cw_actions_trigger').on('click.grid_menu', $.proxy(this.onActionButton, this));
		window.addEventListener('click', $.proxy(this.onWindowClick, this))
	},

	setGrid(grid){
		//this.removeListeners();
		this.grid = grid;
		//this.attachListeners();
		//this.onGridSelectionChanged();
	},

	compareAlphabetically: function (a, b) {
		if ((a.text !== lang.CREATE ) && a.text !== lang.DELETE && a.text !== lang.UPDATE && a.text !== lang.EDIT && a.text !== lang.SET_IN_MAINTENANCE && a.text !== lang.END_MAINTENANCE) {
			if (a.text < b.text)
				return -1;
			if (a.text > b.text)
				return 1;
			return 0;
		}
	},

	compareByCategoryIndex(a, b) {
		if (a.categoryIndex && b.categoryIndex) {
			if (a.categoryIndex < b.categoryIndex)
				return -1;
			if (a.categoryIndex > b.categoryIndex)
				return 1;
			return 0;
		} else {
			if (a.categoryIndex && !b.categoryIndex)
				return -1;
			if (!a.categoryIndex && b.categoryIndex)
				return 1;
			return 0;
		}
	},

	/**
	 * Handler function for the click event on the action button
	 * @param e
	 */
	onActionButton: function (e) {
		const menuContent = $('.cw_actions_menu');
		this.onGridSelectionChanged();
		if (this.onActionButtonCustom) {
			this.onActionButtonCustom(e);
		}
		e.stopPropagation();
		if(menuContent.hasClass('is_open')){
			menuContent.removeClass('is_open');
			return;
		}
		const target = $(e.currentTarget);
		let actionsMenu;
		if (target.parent().find('.cw_actions_menu').length) {
			actionsMenu = target.parent().find('.cw_actions_menu');
		} else {
			actionsMenu = target.parent();
		}
		actionsMenu.toggleClass('is_open');

		let widgetContainer = actionsMenu.closest('.html-shape-container');
		if (widgetContainer.length) {
			let actionsMenuHeight = actionsMenu.height();
			let actionsMenuWidth = actionsMenu.width();
			let widgetContainerId = widgetContainer.attr('id');
			let widgetHeight = widgetContainer.height();
			let widgetOffset = widgetContainer.offset();
			$('.html-shape-container').each(function() {
				if ($(this).attr('id') !== widgetContainerId) {
					if (widgetOffset.top + 75 + actionsMenuHeight > $(this).offset().top && widgetOffset.left + 25 + actionsMenuWidth > $(this).offset().left) {
						actionsMenu.css('height', widgetHeight - 100);
					}
				}
			});
		}

		target.toggleClass('is_selected');
		//temporary
		if (this.isFromDesigner) {
			target.parent().find('.cw_actions_menu').css('top', '-24px').css('left', '28px');
			$('#left_panel').css('overflow', 'initial');
		}
	},

	onWindowClick: function(e){
		const menuContainer = this.root.find('.cw_actions_trigger')[0];
		const menuContent = this.root.find('.cw_actions_menu');
		if (!menuContainer?.contains(e.target) && menuContent.hasClass('is_open')){
			menuContent.removeClass('is_open');
		}
	},
	/**
	 * Disables a menu item
	 * @param {String} id The id of the item
	 */
	disableItem: function (id, reason) {
		const element = this.root.find('#' + id)
		element.addClass('is_disabled');

		if(reason){
			element.attr('title', reason)
		}

		this.updateTopButton();
	},
	/**
	 * Enables a menu item
	 * @param {String} id The id of the item
	 */
	enableItem: function (id) {
		let item = this.getItem(id);
		if( item == null )
			return;

		const checkRolesFunction = item.rolesCondition == 'AND'
			? predicate => !item.roles || item.roles.every(predicate)
			: predicate => !item.roles || item.roles.some(predicate);

		if ((!item.role || State.mainApp.session.hasRole(item.role)) && checkRolesFunction(r => State.mainApp.session.hasRole(r))) {
			this.root.find('#' + id).removeClass('is_disabled');
		} else {
			this.root.find('#' + id).addClass('is_disabled');
		}

		this.updateTopButton();
	},

	updateTopButton: function () {
		//TODO: there is an issue when user clicks on menu and all items are disabled
		//then menu becomes disabled completly and cant be closed
		return;

		var enable = false, length;
		for (var i = 0, length = this.items.length; i < length; i++) {
			if (!$('#' + this.items[i].id).hasClass('is_disabled')) {
				enable = true;
			}
		}
		this.enable(enable);
	},

	enable: function (enable) {
		if (enable) {
			this.root.find('.k-button:first').removeAttr('disabled');
		} else {
			this.root.find('.k-button:first').attr('disabled', 'disabled');
		}
	},

	enableItems: function (enable) {
		if (enable) {
			this.root.find('.cw_actions_menu').find('.cw_item').removeClass('is_disabled');
		} else {
			this.root.find('.cw_actions_menu').find('.cw_item').addClass('is_disabled');
		}
	},

	setItemText: function (id, text) {
		this.root.find('.cw_actions_menu').find('#' + id).find('.item-label').text(text);;
	},

	removeItem: function (id) {
		let listItem = this.root.find('#' + id),
			index = this.indexOfItem(id);

		if (index !== -1) {
			this.itemList.splice(index, 1);
		}
		if (listItem.length) {
			listItem.off();
			listItem.remove();
		}
	},

	clearItems: function() {
		this.root.find('.cw_actions_menu').children().each((index, item) => {
			$(item).off();
			$(item).remove();
		});
		this.itemList = [];
	},
	/**
	 * Adds a menu item
	 * @param {Object} item The item to be added
	 * @param {number} position The position of the item is optional (first is 1)
	 */
	addItem: function (menuItem, position) {
		if( menuItem.id == null ){
			menuItem.id = Utils.guid();
		}
		var list = this.root.find('ul'),
			leftSiblingPosition = 0,
			length = list.length,
			itemCode = '';

		itemCode += '<li id="' + menuItem.id + '" data-type="' + menuItem.dataType + '"  class="cw_item' + (menuItem.dividerClass ? ' ' + menuItem.dividerClass : '') + '">'; // adds divider line
		if (menuItem.iconType === 'material') {
			itemCode += '<i data-type="icon" data-icon-pack="material" data-id="' + menuItem.icon + '" class="uploaderGlyphIcon item material-icons">' + menuItem.icon + '</i>';
		} else {
			itemCode += '<span class="glyphicons ' + menuItem.icon + '"></span>';
		}
		itemCode += '<span class="item-label">' + menuItem.text + '</span>';

		if (menuItem.tooltip) {
			itemCode += '<span class="glyphicons question-sign grid-menu-tooltip" title="' + menuItem.tooltip + '"></span>';
		}

		itemCode += '</li>';

		// adds the menu item code to the list
		if (!position) {
			list.append(itemCode);
			this.itemList.push(menuItem);
		} else if (position === 1) {
			list.prepend(itemCode);
			this.itemList.splice(0, 0, menuItem);
		} else {
			leftSiblingPosition = position - 1;
			list.find('li:nth-child(' + leftSiblingPosition + ')').after(itemCode);
			if (this.itemList.length >= position) {
				this.itemList.splice(leftSiblingPosition, 0, menuItem);
			} else {
				this.itemList.push(menuItem);
			}
		}
		// adds the click listeners for the menu item
		if (menuItem.disabled || (menuItem.role && !State.mainApp.session.hasRole(menuItem.role))) {
			list.find('#' + menuItem.id).addClass('is_disabled');
		}
		list.find('#' + menuItem.id).off().on('click', $.proxy(function (e) {
			if (!$(e.currentTarget).hasClass('is_disabled')) {
				this.onActionButton(e);
				var item = this.getItem($(e.currentTarget).attr('id'));
				if (item.fn) {
					// moved upper because if item has display condition and after fn.call the condition may not be satisfied, item will be removed from dom and
					// $(e.currentTarget).closest('.cw_actions_menu') will be empty it doesn't gives to close menu
					$(e.currentTarget).closest('.cw_actions_menu').removeClass('is_open');
					item.fn.call(item.scope, e);
					if (item.deleteFromGrid && !$('.cw_dialog_content').length) {
						let grid = $('#' + item.deleteFromGrid).data('kendoCustomGrid') ?? $('.' + item.deleteFromGrid).data('kendoCustomGrid');
						kendo.ui.progress(grid.wrapper, true);
					}
				}
			}
		}, this));

		if(menuItem.isEnabled != null){
			this.reactionDisposers.push(reaction(() => menuItem.isEnabled(), (enabled) => {
				if(enabled){
					this.enableItem(menuItem.id)
				}else{
					this.disableItem(menuItem.id, menuItem.disabledReason)
				}
			}, {
				fireImmediately: true
			}))
		}
	},

	getItem: function (id) {
		return this.itemList.find(o => o.id == id );
	},

	indexOfItem: function (id) {
		return this.itemList.findIndex( o => o.id == id );
	},

	onGridSelectionChanged(){
		if(!this.grid)
			return;

		var count = $('.cw_grid_check:checked', this.grid.element).length;
		this.setRowsSelectedCount(count);
	},

	setRowsSelectedCount(count){
		//TODO: when you click on Select all then
		//then onGridSelectionChanged fires before any item actually selected
		for( let item of this.itemList) {
			switch(item.enabledIf){
				case GridMenu.AtLeastOneItemSelected:
					if( count != 0 ){
						this.enableItem(item.id);
					}else{
						this.disableItem(item.id);
					}
					break;

				case GridMenu.ExactlyOneItemSelected:
					if( count == 1 ){
						this.enableItem(item.id);
					}else{
						this.disableItem(item.id);
					}
					break;
				case GridMenu.AtLeastTwoItemsSelected:
					if( count > 1 ){
						this.enableItem(item.id);
					}else{
						this.disableItem(item.id);
					}
					break;
				case GridMenu.Never:
					this.disableItem(item.id);
					break;
			}
		}
	},

	destroy: function() {
		this.removeListeners();
		this.reactionDisposers.forEach(x => x())
		requestIdleCallback(() => this.reactRoot.unmount())
	}
}
