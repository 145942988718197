import Settings from 'settings'
import {createCookies} from 'core/cookies'
import {UrlBuilder} from "tools/urlBuilder";
import {createRoot} from 'react-dom/client';
import React from 'react';
import {AntAlert} from "controls/react/ant/antAlert"
import {authenticateUser} from "core/api";
import {apiFetch} from "framework/api";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator({
	'AZURE' : {
		en: 'Login with Azure AD',
		no : 'Login med Azure AD'
	},
	'SPNEGO' : {
		en : 'Login with Kerberos SSO',
		no : 'Login med Kerberos SSO'
	}
});

function login() {
	const builder = new UrlBuilder(window.location.href);
	const isSso = 'sso' in builder.params;

	function detectIE() {
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}
		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}
		return false;
	}

	function loadProgress() {
		var url = Settings.managementPath + 'progress';
		$.ajax({
			type: "GET",
			url: url,
			data: "",
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (result) {
				if (result.data < 100) {
					$('.login_content').find('.cw_mask').removeClass('hide');
					$('.cw_progress_content').removeClass('hide');
					$('.cw_preloader').css('display', 'block');
					$('#cw_progress_value').text(result.data + '%');
				} else {
					$('#cw_progress_value').text(result.data + '%');
					setTimeout(() => {
						$('.login_content').find('.cw_mask').addClass('hide');
						$('.login_content').removeClass('hide');
						$('.cw_preloader').css('display', 'none');
						$('.cw_progress_content').addClass('hide');
						clearInterval(document.checkLoginProgress);
						getSessionConfig();
					}, 500);
				}
			},
			error: function () {
				$('.cw_progress_content').removeClass('hide');
				$('.cw_preloader').css('display', 'block');
				$('#cw_progress_value').text('0%');
			}
		});
	}

	function getSessionConfig() {
		var url = Settings.serverPath + 'sessions/config';
		$.ajax({
			type: "GET",
			url: url,
			data: "",
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (result) {
				if (!result.success) {
					return;
				}
				const ssoType = result.data?.ssoType;
				if (ssoType === 'AZURE' || ssoType === 'SPNEGO') {
					$("#login_sso_button").text(i(ssoType));
					$("#login_sso_link").text(i(ssoType));
					$('#login_sso_container').removeClass('hide');
					$('#login_sso_button').on('click', () => {
						let url = window.location.href;
						if (ssoType === 'AZURE') {
							let query = url.indexOf('?') != -1 ? encodeURIComponent(url.substring(url.indexOf('?') + 1)) : '';
							window.location = result.data.ssoRedirect + '?q=' + query;
						} else if (ssoType === 'SPNEGO') {
							if (!isSso) {
								let query = url.indexOf('?') != -1 ? '&' + url.substring(url.indexOf('?') + 1) : '';
								url = builder.path + '?sso' + query;
							}
							window.location = url;
						}
					});
					$('#login_local_user').on('click', () => {
						$('#login_sso_container').addClass('hide');
						$('#login_form').removeClass('hide');
						$('#login_sso_link').removeClass('hide');
						$('#login_sso_link').on('click', () => {
							$('#login_sso_container').removeClass('hide');
							$('#login_form').addClass('hide');
						});
					});
				} else {
					$('#login_form').removeClass('hide');
				}
				if (result.data.loginMessage) {
					let infoContent = $('#cw_info_content');
					if (result.data.loginMessageSeverity === 'ERROR') {
						infoContent.css({
							'background-color': '#f2dede',
							'color': '#963b3b'
						});
					} else if (result.data.loginMessageSeverity === 'WARNING') {
						infoContent.css({
							'background-color': '#fcf8e3',
							'color': '#efcd6c'
						});
					}

					if (!this.loginMessageRoot) {
						this.loginMessageRoot = createRoot(infoContent.get(0))
					}
					this.loginMessageRoot.render(<AntAlert message={result.data.loginMessage}
					                                       type={result.data.loginMessageSeverity.toLowerCase()}/>);
				}
				checkIfParamException();
			}
		});
	}

	function checkIfParamException() {
		if (builder.params.exception) {
			let status = $('.status');
			status.find('p').addClass('error');
			let exceptionText = builder.params.exception;
			exceptionText = exceptionText.replace(/%20/g, " ");
			status.find('p').text(exceptionText);
			status.show();
			$('#login_form').removeClass('hide');
			$('#login_sso_container').addClass('hide');
			$('.cw_field').addClass('hide');
			$('#login').addClass('hide');
			$('#return_to_login').removeClass('hide').on('click', function (e) {
				e.preventDefault();
				status.find('p').text('');
				status.find('p').removeClass('error');
				status.hide();
				$(this).addClass('hide');
				$('.cw_field').removeClass('hide');
				$('#login').removeClass('hide');
			})
		}
	}

	$(document).ready(function () {
		if (detectIE()) {
			$('#cw_login_form').addClass('hide');
			$('#cw_ie_forbidden').removeClass('hide');
			$('#login').attr('disabled', true);
		} else {
			//load language script
			var status = $('.status');
			status.find('p').text('');
			status.find('p').removeClass('error');
			status.hide();
			$('#username').val('');
			$('#password').val('');

			if (isSso) {
				let ssoParam = '?sso=' + (builder.params.sso || '');
				var url = Settings.ssoPath + 'login' + ssoParam;
				jQuery.ajax({
					url: url,
					type: "GET",
					dataType: "json",
					contentType: "application/json; charset=utf-8",
					success: function (result) {
						if (result.success) {
							createCookies(result.data)
							ApplicationState.loadStateFromCookies()
							Utils.redirectTo(Settings.httpRoot, true);
						}
					}
				});
			}

			loadProgress();
			document.checkLoginProgress = setInterval(loadProgress, 1000);


			$('#login').on('click', async function (button) {
				button.preventDefault();
				var username = $('#username').val();
				var password = $('#password').val();
				if (username && password) {
					$('#username').removeClass('invalid');
					$('#password').removeClass('invalid');
					var auth = {
						username: username,
						password: password
					};
					// $(this).hide();
					$('.login_content').find('.cw_mask').removeClass('hide');
					try {
						const response = await apiFetch(authenticateUser(auth))
						$('.login_content').find('.cw_mask').addClass('hide');
						if (response.success) {
							createCookies(response.data)
							ApplicationState.loadStateFromCookies()
							Utils.redirectTo(Settings.httpRoot, true);
						} else {
							$('#login').show();
							switch (response.message) {
								case 'wizard':
									window.location = 'Wizard.jsp';
									break;
								case 'umchpwd':
									window.location = 'ChangePassword.jsp?user=' + $('#username').val();
									break;
								default:
									var status = $('.status');
									status.find('p').text(response.message);
									status.find('p').addClass('error');
									status.show().delay(2000).slideUp();
							}
						}
					} catch {
						$('#login').show();
						status.find('p').addClass('error');
						status.find('p').text(lang.account.messages.WARNING_USER_AUTH_CONNECTION_ISSUE);
						status.show().delay(2000).slideUp();
					}
				} else {
					if (!username) {
						$('#username').addClass('invalid');
					}
					if (!password) {
						$('#password').addClass('invalid');
					}
				}
			});
			$('#username').blur(function () {
				if ($(this).val() === '') {
					$(this).addClass('invalid');
				} else {
					$(this).removeClass('invalid');
				}
			});
			$('#password').blur(function () {
				if ($(this).val() === '') {
					$(this).addClass('invalid');
				} else {
					$(this).removeClass('invalid');
				}
			});
		}
	});
}

export default login;
