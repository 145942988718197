import {CostMetric, CostMetricData} from "../../models/costMetric";
import {KeysMatching} from "../../../../tools/types";
import {apiFetch} from "../../../../framework/api";
import {getCostMetricData} from "../../api";
import { makeAutoObservable } from "mobx";
import {BudgetTableStore} from "./BudgetTableStore";

export type MetricType = 'cost' | 'generic';

interface MetricCacheData {
	data: CostMetricData,
	visible: { [x in MetricType]: boolean },
	loading: boolean
}

export class CostMetricCache {
	metrics: Map<string, MetricCacheData> = new Map<string, MetricCacheData>();
	budgetTableStore: BudgetTableStore;

	constructor(budgetTableStore: BudgetTableStore) {
		this.budgetTableStore = budgetTableStore;
		makeAutoObservable(this);
	}

	data(id: string, type: MetricType): CostMetric[] {
		const key = `${type}Metrics` as KeysMatching<CostMetricData, CostMetric[]>;
		return this.getMetrics(id).data[key];
	}

	visible(id: string, type: MetricType): boolean {
		const showedData = this.getMetrics(id);
		if (!showedData) {
			return false;
		} else {
			return showedData.visible[type];
		}
	}

	loading(id: string): boolean {
		const showedData = this.getMetrics(id);
		if (!showedData) {
			return false;
		} else {
			return showedData.loading;
		}
	}

	show(id: string, type: MetricType) {
		const data = this.getMetrics(id);
		if (!data.data) {
			this.loadMetrics(id)
		}
		data.visible[type] = true;
	}

	async getExternalLink(id: string) {
		const data = this.getMetrics(id);
		if(!data.data) {
			await this.loadMetrics(id)
		}
		return data.data.externalLink
	}

	async loadMetrics(id: string) {
		const data = this.getMetrics(id);
		data.loading = true;
		const response = await apiFetch(getCostMetricData(id, this.budgetTableStore.budget.currency));
		data.data = response.data;
		data.loading = false;
	}

	getMetrics(id: string) {
		const data = this.metrics.get(id);
		if (data) {
			return data;
		} else {
			this.metrics.set(id, {
				loading: false,
				visible: {
					cost: false,
					generic: false,
				},
				data: null
			});
			return this.metrics.get(id);
		}
	}

	hide(id: string, type: MetricType) {
		this.getMetrics(id).visible[type] = false;
	}

	toggle(id: string, type: MetricType) {
		if (this.visible(id, type)) {
			this.hide(id, type);
		} else {
			this.show(id, type);
		}
	}
}
