import * as React from 'react';
import { translator } from "core";
import * as ace from 'brace';
import 'brace/mode/javascript';
import 'brace/theme/crimson_editor';
import 'brace/ext/language_tools';
import Window from "controls/react/kendoWrappers/window";
import {Toolbar, ToolbarItemPosition} from "controls/react/layout/toolbar";
import {ActionButtons} from "controls/react/form/actionButtons";

const b = require('b_').with('custom-metric-script-window');

import "./scriptWindow.less";
import {useCallback} from "react";

const i = translator({
  "Script Editor": {
    "no": "Skript editor",
    "en": "Script editor"
  },
  "Script reference": {
    "no": "Skript referanse"
  }
});

interface ScriptWindowProps {
	initialCode: string;
	onClose: () => void;
	onSave: (code: string) => void;
}

export const ScriptWindow: React.FunctionComponent<ScriptWindowProps> = (props) => {
	const [codeText, setCodeText] = React.useState();

	const editorRef = React.useRef(null);

	const handleRef = React.useCallback((node) => {
		if (!node) {
			return;
		}
		editorRef.current = ace.edit(node);

		editorRef.current.setOptions({
			enableBasicAutocompletion: true,
			// enableSnippets: true,
			enableLiveAutocompletion: false
		});

		setEditorValue(editorRef.current, props.initialCode);

		setCodeText(editorRef.current.getValue());
		editorRef.current.getSession().setMode('ace/mode/javascript');
		editorRef.current.setTheme('ace/theme/crimson_editor');
		editorRef.current.on("change", () => {
			setCodeText(editorRef.current.getValue());
		});
	}, []);

	const handleSave = React.useCallback(() => {
		props.onSave(codeText);
	}, [codeText]);

	const handleResize = useCallback(() => {
		editorRef.current.resize(true);
	}, []);

	const saveDisabled = React.useMemo(() => {
		try {
			JSON.parse(codeText);
			return false;
		} catch(e) {
			return true;
		}
	}, [codeText])

	return <div>
		<Window containerClass={b()} modal={true} onClose={props.onClose} onResize={handleResize} width={500} height={500} title={i('Script Editor')}>
			<div className={b('reference')}>
				{i('Script reference')} - <a href={"https://api.highcharts.com/highcharts/"}>https://api.highcharts.com/highcharts/</a>
			</div>
			<div className={b('content')} ref={handleRef} />
			<Toolbar>
				<ActionButtons onSave={handleSave} saveDisabled={saveDisabled} onCancel={props.onClose} mode="update"
				               position={ToolbarItemPosition.AT_THE_END}/>
			</Toolbar>
		</Window>
	</div>
};

export default ScriptWindow;


export function setEditorValue(editor:ace.Editor, value:string) {
	if (!editor) {
		return;
	}
	editor.setValue(JSON.stringify(value || {}, null, 4));
	editor.gotoLine(0, 0, true);
	// @ts-ignore
	editor.setAutoScrollEditorIntoView(true);
}
