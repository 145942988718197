import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition"

export function canApplyStringFilter(configuration: RulesConfiguration, field: string) {
	if (field == 'id')
		return false

	const fieldConfiguration = configuration[field]
	if (fieldConfiguration == null)
		return false

	return fieldConfiguration.operators.find(x => x.value == 'like') != null
}
