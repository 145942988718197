
export const getEmptyMetricItems = (metrics: string[]) => {
	if (!metrics?.length) {
		return []
	}
	return metrics.map(metricId => ({
		metricId,
		customUnit: '',
		conversion: '',
		unitTypeSymbol: '',
		color: ''
	}))
}

interface MetricItem {
	metricId: string
	advanced: boolean
	formula: string
	unitLabel: string
	unit: string
	color: string
	unitTypeSymbol: string
	conversion: string
	customUnit: string
	conversionUnit: string
}

export const getMetricsWithCorrectConversionFields = (metricsItems: MetricItem[]) => {
	return metricsItems?.map(oldItem => {
		const item = {...oldItem};
		if (!oldItem.advanced) {
			return item
		}
		item.color = oldItem.color;
		item.unitTypeSymbol = oldItem.unitLabel;
		if (oldItem.unit == 'CUSTOM') {
			item.conversion = oldItem.formula;
			item.customUnit = oldItem.unitLabel;
		} else {
			item.conversionUnit = oldItem.unit;
		}
		return item
	})
}
