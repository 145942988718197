import React, {useEffect} from "react";

import {AntSelect} from "controls/react/ant/antSelect";
import {useRemoteList} from "framework/api";
import {listCostProfiles} from "areas/cost/api";

const i = require('core/localization').translator()

export const BudgetDropDown = props => {
	const {accountId, onReady, ...others} = props;

	const [data, loading, loaded] = useRemoteList(listCostProfiles(accountId))
	useEffect(() => {
		if (loaded && onReady) {
			onReady(data);
		}
	}, [loaded])

	return (
		<AntSelect placeholder={i('Select...')}
		           disabled={!loaded}
		           loading={loading}
				   dataList={data}
				   {...others}
		/>
	);
}
