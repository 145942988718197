import React from 'react';
import {toJS} from "mobx";
import {createRoot} from 'react-dom/client';

import Utils from 'tools/utils';
import Settings from 'settings';
import ErrorHandler from 'core/errorHandler';
import SortingPanel from "./sortingPanel/sortingPanel";
import FilteringPanel from "areas/services/widgets/filterPanel/filteringPanel";
import {BoxView} from 'controls/react/layout/boxView';

import {getServicesLite} from "areas/services/api";
import {apiFetch} from "framework/api";
import { waitForRef } from 'tools/iosFixes';


export class SelectiveServicesWidgetConfiguration {

	initialConfig = null
	servicesByAccount = {}

	constructor(config) {

		Utils.apply(this, config);
		this.widgetConfigurationFormEl.empty().load('include/gsboard/SelectiveServicesForm.jsp', $.proxy(function () {
			this.initComponent();
		}, this));
	}

	initComponent() {
		this.initialConfig = toJS(this.widgetObj)
		$('.cw_widget_title').val(this.initialConfig.title);
		if (this.initialConfig.configuration.columns && this.initialConfig.configuration.showIncidentOverview) {

			$('.cw_selectiveservices_incident').removeClass('hide');
			//on edit reset all and set only if in configuration
			$('.cw_show_column').prop('checked', false);

			if (this.initialConfig.configuration.columns.status && !this.initialConfig.configuration.columns.status.hidden) {
				$('#cw_incidents_status').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.modified && !this.initialConfig.configuration.columns.modified.hidden) {
				$('#cw_incidents_modified').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.priorityName && !this.initialConfig.configuration.columns.priorityName.hidden) {
				$('#cw_incidents_priority').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.accountName && !this.initialConfig.configuration.columns.accountName.hidden) {
				$('#cw_incidents_account').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.incidentNumber && !this.initialConfig.configuration.columns.incidentNumber.hidden) {
				$('#cw_incidents_number').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.createdBy && !this.initialConfig.configuration.columns.createdBy.hidden) {
				$('#cw_incidents_reportedby').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.owner && !this.initialConfig.configuration.columns.owner.hidden) {
				$('#cw_incidents_owner').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.contentType && !this.initialConfig.configuration.columns.contentType.hidden) {
				$('#cw_incidents_type').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.subject && !this.initialConfig.configuration.columns.subject.hidden) {
				$('#cw_incidents_subject').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.assignedTo && !this.initialConfig.configuration.columns.assignedTo.hidden) {
				$('#cw_incidents_assignedto').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.created && !this.initialConfig.configuration.columns.created.hidden) {
				$('#cw_incidents_created').prop('checked', true);
			}
			if (this.initialConfig.configuration.columns.description && !this.initialConfig.configuration.columns.description.hidden) {
				$('#cw_incidents_description').prop('checked', true);
			}
		}
		$('#cw_incident_counter_check').prop('checked', this.initialConfig.configuration.showIncidentCounter);
		$('#cw_incident_overview_check').prop('checked', this.initialConfig.configuration.showIncidentOverview);
		$('#cw_show_closed_check').prop('checked', this.initialConfig.configuration.showClosedIncidents);

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();
	}

	/**
	 * Removes listeners
	 */
	removeListeners() {
		this.widgetConfigurationFormEl.off('click', '#cw_dynamic_services');
		this.widgetConfigurationFormEl.off('click', '#cw_incident_overview_check');
	}

	attachListeners() {
		this.widgetConfigurationFormEl.on('click', '#cw_dynamic_services', $.proxy(this.onDynamicServicesToggle, this));
		this.widgetConfigurationFormEl.on('click', '#cw_incident_overview_check', $.proxy(this.onOverviewClick, this));
	}

	initSortingPanel() {
		if (!this.sortingItems) {
			this.sortingItems = [{type: 'NAME', sorting: 'ASC'}]
		}

		this.sortingPanelRoot = createRoot(document.getElementById('sorting_panel'))
		this.sortingPanelRoot.render(<SortingPanel items={this.sortingItems}
		                                           onChange={(values) => this.sortingItems = values}/>)
	}

	initFilteringPanel() {
		const ref = React.createRef()
		this.filteringPanelRoot = createRoot(document.getElementById('formating_panel'))
		this.filteringPanelRoot.render(<FilteringPanel
			config={this.initialConfig.configuration}
			filterType={'selectiveServicesFilterTypes'}
			onChange={(values) => {
				this.filtering = values;
			}}
			ref={ref}
		/>)

		requestIdleCallback(async () => {
			await waitForRef(ref)
			let stateValues = [];
			let states = this.initialConfig.configuration.serviceStateList;
			if (states) {
				for (let i = 0; i < states.length; i++) {
					stateValues.push(states[i].value);
				}
			}
			let stateSelector = $('.selective-service__filtering-panel__reset-field').find('select').data('kendoSortedMultiSelect');
			let dataSource = stateSelector.dataSource.data();
			let newStates = [];
			for (let j = 0; j < dataSource.length; j++) {
				if (stateValues.indexOf(dataSource[j].value) !== -1) {
					newStates.push(dataSource[j]);
				}
			}
			stateSelector.value(newStates);
		});
	}

	saveAdditionalFields(config) {
		config.sorting = this.sortingItems;

		Object.assign(config, this.filtering);

		delete config.sortingOption;
		delete config.sortingDirection;
	}

	loadFields() {
		let config = this.initialConfig.configuration;

		if (config.sorting) {
			this.sortingItems = config.sorting;
		} else if (config.sortingOption && config.sortingDirection) {
			this.sortingItems = [{type: config.sortingOption, sorting: config.sortingDirection}]
		}

		this.initSortingPanel();
		this.initFilteringPanel();
	}

	/**
	 * Iniitalizes kendo components
	 */
	async initKendoComponents() {
		var widgetObj = this.initialConfig;

		this.loadFields();

		this.servicesAccounts = $('#cw_st_accountids').kendoSortedMultiSelect({
			index: 0,
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + this.serviceBoardAccountId + '/subaccounts/allLevels/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: $.proxy(function (data) {
						this.allAccountsId = Utils.guid();
						data.unshift({
							id: this.allAccountsId,
							name: '-All Accounts-'
						});
						return data;
					}, this)
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			placeholder: lang.reports.messages.SELECT_ACCOUNT,
			change: $.proxy(this.onAccountChange, this),
			itemTemplate: '<span data-id="${id}"> ${name}</span>',
			value: widgetObj.configuration.accountsList,
			dataBound: $.proxy(function () {
				$('#cw_st_accountids').data('kendoSortedMultiSelect').ul.find('li.k-item.k-selected').trigger('click').trigger('click');
			}, this)
		}).data('kendoSortedMultiSelect');

		this.servicesList = $('#cw_st_serviceids').kendoSortedMultiSelect({
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			tagTemplate: '<span title="${data.name}">${data.name}</span>',
			placeholder: lang.reports.messages.SELECT_SERVICES,
			select: $.proxy(function (e) {
				if (e.dataItem.id === this.allServicesId) {
					let currentDatasource = e.sender.dataSource.data();
					let ids = [];
					for (var i = 0; i < currentDatasource.length; i++) {
						if (currentDatasource[i].id !== this.allServicesId) {
							ids.push(currentDatasource[i].id);
						}
					}
					this.servicesList.value(ids);
				}
			}, this),
			change: () => this.setRequiredServices(),
			value: widgetObj.configuration.servicesList ? widgetObj.configuration.servicesList : [],
			filter: 'contains'
		}).data('kendoSortedMultiSelect');

		if (widgetObj.configuration.dynamicServices) {
			$('#cw_dynamic_services').prop('checked', true);
		}

		await this.onDynamicServicesToggle()

		this.initialConfig.configuration.servicesList
		&& this.initialConfig.configuration.servicesList.length
		&& this.servicesList.value(this.initialConfig.configuration.servicesList);

		this.showDescription = $('#cw_show_description');
		this.showInformation = $('#cw_show_information');
		this.showAccountName = $('#cw_show_account_name');
		if (this.mode === 'update' || this.wasUpdate) {
			this.showDescription.prop('checked', widgetObj.configuration.showDescription);
			this.showInformation.prop('checked', widgetObj.configuration.showInformation);
			this.showAccountName.prop('checked', widgetObj.configuration.showAccountName);
		}
		if (!this.servicesAccounts.value().length) {
			$('#cw_st_accountids').parent().find('.k-multiselect-wrap').addClass('required_multiselect');
		}
		if (!this.servicesList.value().length && !$('#cw_dynamic_services').is(':checked')) {
			$('#cw_st_serviceids').parent().find('.k-multiselect-wrap').addClass('required_multiselect');
		}
	}

	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues() {
		var title = $('.cw_widget_title').val().trim();
		var servicesListCombo = $('#cw_st_serviceids').data('kendoSortedMultiSelect');

		var statusCheck = $('#cw_incidents_status'),
			modifiedCheck = $('#cw_incidents_modified'),
			priorityCheck = $('#cw_incidents_priority'),
			accountCheck = $('#cw_incidents_account'),
			numberCheck = $('#cw_incidents_number'),
			reportedByCheck = $('#cw_incidents_reportedby'),
			ownerCkeck = $('#cw_incidents_owner'),
			typeCheck = $('#cw_incidents_type'),
			subjectCheck = $('#cw_incidents_subject'),
			assignedToCheck = $('#cw_incidents_assignedto'),
			createdCheck = $('#cw_incidents_created'),
			descriptionCheck = $('#cw_incidents_description');

		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.initialConfig.id : Utils.guid(),
			type: 'services',
			title: title,
			configuration: {
				accountsList: this.servicesAccounts.value(),
				servicesList: this.servicesList.value(),
				showDescription: $('#cw_show_description').is(":checked"),
				showInformation: $('#cw_show_information').is(":checked"),
				showAccountName: $('#cw_show_account_name').is(":checked"),
				showIncidentCounter: $('#cw_incident_counter_check').is(':checked'),
				showIncidentOverview: $('#cw_incident_overview_check').is(':checked'),
				showClosedIncidents: $('#cw_show_closed_check').is(':checked'),
				ignoreUserTags: $('#cw_ignore_user_tags').is(':checked'),
				showUntagged: $('#cw_untagged').is(':checked'),
				columns: {
					status: {
						hidden: statusCheck.is(':not(:checked)')
					},
					modified: {
						hidden: modifiedCheck.is(':not(:checked)')
					},
					priorityName: {
						hidden: priorityCheck.is(':not(:checked)')
					},
					accountName: {
						hidden: accountCheck.is(':not(:checked)')
					},
					incidentNumber: {
						hidden: numberCheck.is(':not(:checked)')
					},
					createdBy: {
						hidden: reportedByCheck.is(':not(:checked)')
					},
					owner: {
						hidden: ownerCkeck.is(':not(:checked)')
					},
					contentType: {
						hidden: typeCheck.is(':not(:checked)')
					},
					subject: {
						hidden: subjectCheck.is(':not(:checked)')
					},
					assignedTo: {
						hidden: assignedToCheck.is(':not(:checked)')
					},
					created: {
						hidden: createdCheck.is(':not(:checked)')
					},
					description: {
						hidden: descriptionCheck.is(':not(:checked)')
					}
				}
			}
		};

		if ($('#cw_dynamic_services').is(':checked')) {
			widgetObj.configuration.dynamicServices = true;
		} else {
			widgetObj.configuration.servicesList = servicesListCombo.value();
		}

		this.saveAdditionalFields(widgetObj.configuration);

		return widgetObj;
	}

	isValid() {
		var valid = true;
		this.validationMessage = '';

		var accountsListCombo = $('#cw_st_accountids').data('kendoSortedMultiSelect');
		let dynamicServicesCheck = $('#cw_dynamic_services').is(':checked');

		if (!accountsListCombo.value().length) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		} else if (!this.servicesList.value().length && !dynamicServicesCheck) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	}

	isDynamicServicesChecked() {
		return $('#cw_dynamic_services').is(':checked');
	}

	async onDynamicServicesToggle(e) {
		let dynamicServicesCheck = this.isDynamicServicesChecked()
		this.unmountDynamicServiceInfo()
		if (dynamicServicesCheck) {
			this.servicesList.value('');
			this.renderDynamicServiceInfo();
		} else {
			await this.updateServicesList()
		}
		this.setRequiredServices();

		this.servicesList.enable(!dynamicServicesCheck);
	}

	renderDynamicServiceInfo() {
		this.dynamicServiceInfoRoot = createRoot(document.getElementById('cw_dynamic_services_info'))
		let message = lang.widget.DYNAMIC_SERVICE_INFO;
		this.dynamicServiceInfoRoot.render(<BoxView type={"info-2"} border={true}>
			<div>{message}</div>
		</BoxView>);
	}

	unmountDynamicServiceInfo() {
		this.dynamicServiceInfoRoot?.unmount()
	}

	onOverviewClick(e) {
		var target = $(e.currentTarget), incidentColumns = $('.cw_selectiveservices_incident').closest('.cw_field');
		if (target.is(':checked')) {
			incidentColumns.removeClass('hide');
		} else {
			incidentColumns.addClass('hide');
		}
	}

	onAccountChange = async() =>  {
		if (this.isDynamicServicesChecked())
			return

		let selectedAccounts = this.servicesAccounts.value();
		if(selectedAccounts.indexOf(this.allAccountsId) != -1){
			this.addAllAccountsToSelectedList()
		}

		await this.updateServicesList()
	}

	addAllAccountsToSelectedList(){
		const allAccountsList = this.servicesAccounts.dataSource.data().toJSON().map(x => x.id);
		allAccountsList.splice(allAccountsList.indexOf(this.allAccountsId), 1)

		this.servicesAccounts.value(allAccountsList)
	}

	async updateServicesList() {
		let selectedAccounts = this.servicesAccounts.value();

		for (const accountId of Object.keys(this.servicesByAccount)) {
			if (selectedAccounts.indexOf(accountId) == -1) {
				delete this.servicesByAccount[accountId]
			}
		}

		const servicesList = this.servicesList.value()
		const actualizedServicesList = []

		for(const serviceId of servicesList) {
			for (const services of Object.values(this.servicesByAccount)) {
				if (services.find(x => x.id == serviceId) != null) {
					actualizedServicesList.push(serviceId)
					break
				}
			}
		}

		this.servicesList.value(actualizedServicesList)

		if (selectedAccounts.length == 0) {
			this.servicesList.setDataSource([])
			this.servicesList.enable(false)
		}else{
			const promises = selectedAccounts.map(async accountId => {
				if(this.servicesByAccount[accountId] == null) {
					const request = getServicesLite({
						accountId: accountId,
						hasModel: true,
						includeSubaccounts: false
					})

					const response = await apiFetch(request)
					if (response.success && response.data.length) {
						this.servicesByAccount[accountId] = [...response.data]
						this.servicesByAccount[accountId].forEach(service => {
							service.name = service.accountName + '/' + service.name
						})
					}else{
						return []
					}
				}

				return this.servicesByAccount[accountId]
			})

			const serviceGroups = await Promise.all(promises)

			this.servicesList.setDataSource(serviceGroups.flat())

			this.servicesList.enable(true)
		}

		this.setRequiredServices();
	}

	setRequiredServices() {
		let dynamicServicesCheck = $('#cw_dynamic_services').is(':checked');
		if (!this.servicesList.value().length && !dynamicServicesCheck) {
			$('#cw_st_serviceids').parent().find('.k-multiselect-wrap').addClass('required_multiselect');
		} else {
			$('#cw_st_serviceids').parent().find('.k-multiselect-wrap').removeClass('required_multiselect');
		}
	}

	destroy() {
		this.sortingPanelRoot?.unmount()
		this.filteringPanelRoot?.unmount()
		this.unmountDynamicServiceInfo()
	}
}
