import './dataSourceWindow.less'
import React, {useCallback} from "react"

import {AntModal, createContainer, ModalPosition} from "controls/react/ant/antModal"
import {GridColumnConfig} from "controls/grid/gridColumnConfig"
import {GridDataEntryWithId} from "controls/grid/gridDataEntry"
import {GridStore} from "controls/grid/gridStore"
import {ArrayDataProvider} from "controls/grid/arrayDataProvider"
import {Grid} from "controls/grid/grid"
import {Toolbar, ToolbarItemPosition} from "../../react/layout";
import {translator} from "../../../core";
import {AntSwitch} from "../../react/ant/antSwitch";
import {observer} from "mobx-react";
import {HelpIcon} from "../../react/form/formEntryNew";
import {DesignerStore} from "controls/designer/designerStore";

export const i = translator({
	'Filter selected': {
		no: 'Filter utvalgte'
	}
})
const b = require('b_').with('data-source-modal');

export type DataSourceWindowProps<DataEntry extends GridDataEntryWithId> = {
	columns: GridColumnConfig<DataEntry>[]
	data: DataEntry[]
	title: string
	selectedIds: string[]
	onOk: (selectedItems: DataEntry[]) => void
	useOnlySelectedFilter?: boolean
	onlySelectedHelpText?: string
	designerStore?: DesignerStore
}

let destroyDataSourceWindowHandler : (() =>void) = null

export function destroyDataSourceWindow(){
	destroyDataSourceWindowHandler?.()
}

export function showDataSourceWindow<DataEntry extends GridDataEntryWithId>(props: DataSourceWindowProps<DataEntry>) {
	destroyDataSourceWindow()

	const [root] = createContainer()

	const gridStore = new GridStore<DataEntry>({
		dataProvider: new ArrayDataProvider(props.data),
		columns: props.columns,
		defaults: {
			showInlineFilters: true,
			selectedIds: props.selectedIds ?? []
		}
	})

	const destroyByDesigner = () => {
		root.unmount()
		gridStore.destroy()
	}

	const destroy = () => {
		destroyByDesigner()
		props.designerStore?.onDestroyRemove(destroyByDesigner)
	}

	props.designerStore?.onDestroy(destroyByDesigner)

	const propertiesWindow = props.designerStore?.legacyDesigner.ui.format.formatWindow

	if (propertiesWindow) {
		propertiesWindow.bind('deactivate', () => {
			destroy()
		})
	}

	destroyDataSourceWindowHandler = destroy
	root.render(<DataSourceModal useOnlySelectedFilter={props.useOnlySelectedFilter ?? false}
								 title={props.title}
								 destroy={destroy}
								 onOk={props.onOk}
								 gridStore={gridStore}
								 onlySelectedHelpText={props.onlySelectedHelpText}/>)
}

type DataSourceModalProps<DataEntry extends GridDataEntryWithId> = {
	useOnlySelectedFilter: boolean
	onlySelectedHelpText?: string
	title: React.ReactNode
	destroy: () => void
	gridStore: GridStore<DataEntry>
	onOk: (selectedItems: DataEntry[]) => void
}

const DataSourceModal = observer(<DataEntry extends GridDataEntryWithId>(props: DataSourceModalProps<DataEntry>) => {
	const filterSelectedChange = useCallback((value: boolean) => {
		props.gridStore.arrayDataProvider.onlySelected = value
	}, [props.gridStore]);

	const title = !props.useOnlySelectedFilter
		? <Toolbar title={props.title}></Toolbar>
		: <Toolbar title={props.title}>
			<div position={ToolbarItemPosition.AT_THE_END} className={b('only-selected')}>
				<label>{i('Filter selected')} {!!props.onlySelectedHelpText && <HelpIcon tooltip={props.onlySelectedHelpText}/>}</label>
				<AntSwitch value={props.gridStore.arrayDataProvider.onlySelected}
													  size={'small'}
													  onChange={filterSelectedChange}/>
			</div>
		</Toolbar>

	return <AntModal width={800}
					 title={title}
					 positionType={ModalPosition.Centered}
					 open={true}
					 mask={false}
					 mode={"update"}
					 height={600}
					 onCancel={props.destroy}
					 onOk={() => {
						 props.onOk(props.gridStore.selection.dataset.items.map(x => x.data));
						 props.destroy()
					 }}>
		<Grid store={props.gridStore}/>
	</AntModal>
})
