import {GridMenu, PreferencesPanel} from "controls";
import {Settings} from "settings";
import {Cookies, ErrorHandler} from "core";
import {UserPrefs, Utils} from "tools";
import {Application} from "core";
import {NavigationStore} from "../../framework/navigationStore";
import {ManagementRouter} from "./bundleDescription";

export default function AgentConfiguration(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'AgentConfiguration',
		initialConfig: config
	};
	this.initComponent();
};
jQuery.extend(AgentConfiguration.prototype, Application.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		this.loadUserPreferences();
	},
	/**
	 * This is main init function
	 */
	initKendoComponents: function () {
		if (this.pageTitle) {
			$('.cw_configuration_title').text(this.pageTitle);
		}

		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/agents/' + this.id + '/configurations';
		var defaultFsView = UserPrefs.get('defaultFsView');

		this.defaultColumns = {
			id: {
				height: false,
				width: 40
			},
			name: {
				height: false,
				width: 400
			},
			description: {
				height: false,
			},
		};

		let filter, sort, pref;

		if (Utils.isGuid(defaultFsView)) {
			pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
					sort: [],
					filter: []
				};
			sort = pref.sort;
			filter = pref.filter;
			this.agentConfigurationColumns = pref.columns;
		} else {
			if (UserPrefs.get('agentconfigurationFilter')) {
				filter = JSON.parse(UserPrefs.get('agentconfigurationFilter'));
			} else {
				filter = [];
			}
			if (UserPrefs.get('agentconfigurationSort')) {
				sort = JSON.parse(UserPrefs.get('agentconfigurationSort'));
			} else {
				sort = [{
					field: 'name',
					dir: 'asc'
				}];
			}
		}

		var columns = JSON.parse(UserPrefs.get('agentconfigurationColumns')) || this.defaultColumns;
		columns = Utils.completeColumns(columns, this.defaultColumns);

		this.bundlesDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				data: function (response) {
					return response.data;
				}
			},
			error: ErrorHandler.kendoServerError,
			filter,
			sort
		});

		const filterMessages = lang.grid.filter;

		this.bundlesGrid = $('#cw_services_list').kendoCustomGrid({
			dataSource: this.bundlesDataSource,
			resizable: true,
			selectable: 'row',
			sortable: true,
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
			},
			columns: [{
				field: 'id',
				title: ' ',
				sortable: false,
				filterable: false,
				template: '<input type="checkbox" class="cw_bundle_checks" />',
				attributes: {
					"class": "text_center"
				},
				width: columns.id.width,
				hidden: columns.id.hidden
			}, {
				field: 'name',
				title: lang.NAME,
				template: '<a class="cw_configuration_name pointer ellipsis to_expand">#= name #</a>',
				sortable: true,
				width: columns.name.width,
				hidden: columns.name.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				width: columns.description.width,
				hidden: columns.description.hidden,
				sortable: true
			}],
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onBundlesDataBound, this)
		}).data('kendoCustomGrid');

		var searchValue = UserPrefs.get('agentconfigurationSearchPhrase') || '';

		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_agent_configuration_filters',
			grid: this.bundlesGrid,
			modulePrefName: 'AgentConfiguration',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'agentconfigurationFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});

		this.gridFilterPanel.searchRenderHandler.find('.cw_search_box').trigger('change');

		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.bundlesGrid);
		this.gridMenu = new GridMenu({
			renderTo: 'cw_bundle_grid_menu',
			items: [{
				id: 'cw_bundle_edit',
				icon: 'pencil',
				text: lang.EDIT,
				fn: this.onBundleEdit,
				scope: this,
				disabled: true
			}]
		});
	},

	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'AgentConfiguration',
			filterPanel: this.gridFilterPanel,
			grid: this.bundlesGrid,
			keys: {
				searchPhrase: 'agentconfigurationSearchPhrase',
				columns: 'agentconfigurationColumns',
				sort: 'agentconfigurationSort',
				filter: 'agentconfigurationFilter',
				FsViews: 'agentconfigurationFSViews'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('AgentConfiguration', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the change(select) event on the grid
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_services_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler the grid data bound event
	 * @param {Object} e The data bound event
	 */
	onBundlesDataBound: function (e) {
		$('#cw_services_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		$('.k-i-filter').removeClass('k-i-filter').addClass('k-i-arrowhead-s');
		$('.cw_bundle_checks').off().on('click', $.proxy(this.onBundleCheck, this));
		$('.cw_configuration_name').off().on('click', $.proxy(this.onBundleNameClick, this));
		if (!Utils.canEditAgent(this.id)) {
			$('.cw_bundle_checks').prop('disabled', 'disabled');
			$('.cw_configuration_name').off().addClass('cw_disabled_anchor');
		}
	},
	/**
	 * Handler for the sla checkbox click
	 * @param {Object} e The click event
	 */
	onBundleCheck: function (e) {
		var checkboxes = $('.cw_bundle_checks');
		var bundles = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				bundles.push(this.bundlesGrid.dataItem($(checkboxes[i]).closest('tr')));
			}
		}
		if (bundles.length === 1) {
			this.bundle = bundles[0];
			this.gridMenu.enableItem('cw_bundle_edit');
		} else {
			this.gridMenu.disableItem('cw_bundle_edit');
		}
	},
	/**
	 * Handler the click event on the bundle edit icon
	 * @param {Object} e The click event
	 */
	onBundleNameClick: function (e) {
		e.preventDefault();
		this.bundle = this.bundlesGrid.dataItem($(e.currentTarget).closest('tr'));
		this.onBundleEdit();
	},
	/**
	 * Handler the click event on the bundle edit icon
	 * @param {Object} e The click event
	 */
	onBundleEdit: function (e) {
		NavigationStore.go(ManagementRouter.bundleConfiguration(this.bundle.bundleId, this.id, this.bundle.id, this.bundle.name));
	},
	destroy: function () {
		this.saveUserPreferences();
	}
});
