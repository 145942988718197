import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import CustomMetricConfiguration from './customMetrics/customMetricConfiguration';
import {newGuid} from "tools/guid";
import {checkAccountExists} from "areas/administration/accounts/api";
import {ApplicationState} from "framework/applicationState";

export default class CustomMetricWidgetConfigurationWrapper {
	constructor(config) {
		this.config  = config;

		this.init()
	}

	async init(){
		if(this.config.widgetObj.configuration.accountId ) {
			let a = await checkAccountExists(this.config.widgetObj.configuration.accountId)
			if( !a) {
				this.config.widgetObj.configuration.accountId = ApplicationState.accountId
			}
		}

		this.completeWidgetObj()

		if (!this.config.widgetObj.id) {
			this.config.widgetObj.id = newGuid();
			this.config.widgetObj.isNew = true;
		}

		this.showAdvanced = false;
		if (this.config.widgetObj.instanceConfiguration) {
			for (let i = 0; i < this.config.widgetObj.instanceConfiguration.metricsItems.length; i++) {
				let current = this.config.widgetObj.instanceConfiguration.metricsItems[i];
				if (current.color || current.conversion || current.customUnit) {
					this.showAdvanced = true;
				}
			}
		}

		this.render();
	}

	completeWidgetObj() {
		let container = this.config.widgetConfigurationFormEl;
		container.empty().html('<div id="cw_metrics_form"></div>');
		this.renderTo = createRoot(container[0].querySelector('#cw_metrics_form'))
	}

	render() {
		this.renderTo.render(<CustomMetricConfiguration
			config={this.config.widgetObj}
			hideThreshold={this.config.hideThreshold}
			showAdvanced={this.showAdvanced}
			onChange={(values) => {
				this.values = values;
			}}/>);
	}

	getValues () {
		const values = JSON.parse(JSON.stringify(this.values));
		delete values.isNew;
		return values;
	}

	destroy(){
		requestIdleCallback(() => this.renderTo.unmount());
	}

	isValid () {
		const values = this.getValues();
		let validConfig = true;
		let message = "";

		try {
			JSON.parse(values.configuration.highchartConfig)
		}
		catch(e) {
			console.log(e);
			validConfig = false;
			message = "JSON is not valid";
		}

		return {
			valid: validConfig,
			validationMessage: message
		}
	}
}
