import {CustomDataEntry} from "controls/designer/customDataEntry";
import {ApiRequest} from "framework/api";

import {CustomDataListEntry} from "controls/designer/features/widgets/custom-data-list/customDataListEntry";

type GetDataSourcesListArgs = {
	dataSources: CustomDataEntry[]
}

export function getDataSourcesList(args: GetDataSourcesListArgs) {
	return new ApiRequest<CustomDataListEntry[]>({
		url: 'dashboards/customDataInfo',
		payload: args.dataSources
	});
}
