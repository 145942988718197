export class ChunkProgressBar {
	constructor(config) {
		Object.assign(this, config);
		this.initComponent();
	}

	initComponent() {
		let html = '';
		let count = 0;

		this.recalculateSmallerPercentages();

		for (let i = 0, length = this.items.length; i < length; i++) {
			if (this.items[i].percentageWidth) {
				html += '<div class="cw_progress_bar ' + this.items[i].cssClass + '" style="width:' + this.items[i].percentageWidth + '%;" title="' + (this.items[i].title || '') + '"></div>';
				count++;
			}
		}
		this.renderTo.empty().append(html);
	}

	recalculateSmallerPercentages(threshold = 5.0) {
		var excess = 0.0;
		var greaterItems = [];

		for (let i = 0, length = this.items.length; i < length; i++) {
			var percentage = this.items[i].percentageWidth;
			if(percentage) {
				if(percentage < threshold) {
					excess += (threshold - percentage);
					this.items[i].percentageWidth = threshold;
				} else if(percentage > 2.0 * threshold) {
					greaterItems.push(this.items[i]);
				}
			}
		}

		if(excess > 0 && greaterItems.length > 0) {
			var amount = excess / greaterItems.length;
			for (let i = 0, length = greaterItems.length; i < length; i++) {
				greaterItems[i].percentageWidth -= amount;
			}
		}
	}
};

export default ChunkProgressBar;
