import React from 'react';
import {computed, makeObservable, observable} from "mobx";
import {observer} from "mobx-react";

import {Toolbar, ToolbarItemPosition, ToolbarItemsSet} from 'controls/react/layout/toolbar';
import {Section} from 'controls/react/layout/section';
import {AntModal} from "controls/react/ant/antModal";
import {GridStore} from "controls/grid/gridStore";
import {Grid} from "controls/grid/grid";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {AntRadioGroup} from "controls/react/ant/antRadio";
import {AntSwitch} from "controls/react/ant/antSwitch";
import {linkModel, MobxManager} from "framework/mobx-integration";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import {GridSearchInput} from "controls/grid/gridSearchInput";
import {getDataRegistry, getDataRegistryFilters, getDataRegistrySelectedIds} from "areas/management/data-registry/api";
import {DataRegistryEntry} from "areas/management/data-registry/dataRegistryEntry";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {apiFetch} from "framework/api";
import {ViewSelector} from "controls/grid/viewManager/viewSelector";
import { i18n } from './localization';
import {ActionButtons} from "controls/react/form";
import {AntCheckbox} from "controls/react/ant/antCheckbox";


export const DataRegistryWindow = observer((props: DataRegistryWindowStoreProps) => {

	const [store] = React.useState(() => {
		return new DataRegistryWindowStore(props)
	})

	const footer = <Toolbar appearance={'transparent'}>
		<div position={ToolbarItemPosition.AFTER_TITLE}></div>
		<AntCheckbox position={ToolbarItemPosition.AFTER_TITLE}
		             {...linkModel(store, "showOnlySelected")}>{i18n('Show Selected metrics')}</AntCheckbox>
		<ActionButtons
			mode={"update"}
			onSave={store.save}
			onCancel={store.cancel}
		/>
	</Toolbar>

	return <AntModal width={1200}
	                 height={800}
	                 open={true}
	                 resizable={true}
	                 draggable={true}
	                 mode={'update'}
	                 onOk={store.save}
	                 footer={footer}
	                 onCancel={store.cancel}
	                 mask={true}>
		<Section contentPadding={false} height={'fit'}>
			<Toolbar title={"Metrics"}>
				<ToolbarItemsSet position={ToolbarItemPosition.AFTER_TITLE}>
					<div>{i18n('Categorize by')}</div>
					<AntRadioGroup options={store.categoryOptions}
					               optionType={'button'}
					               buttonStyle={'solid'}
					               size={"small"}
					               {...linkModel(store, "categorizeBy")}/>
				</ToolbarItemsSet>

				<AntSwitch position={ToolbarItemPosition.AFTER_TITLE}
				           {...linkModel(store, "includeSubaccounts")}
				           size={"small"}
				           checkedChildren={i18n('Include subaccounts')}
				           unCheckedChildren={i18n('Include subaccounts')}/>

				<DataSizeIndicator store={store.gridStore}/>
				<GridSearchInput store={store}/>
				<ViewSelector store={store.gridStore} createViewDisabled={true}/>
			</Toolbar>
			<Grid store={store.gridStore}/>
		</Section>
	</AntModal>
})

const getAssetNameColumn = (fixed?: boolean): GridColumnConfig<DataRegistryEntry> => {
	return {
		title: i18n('Asset'),
		field: 'assetName',
		width: 250,
		fixed: fixed ? 'left' : null,
		renderer: r => r.assetName
	}
}

const getCategoryNodeColumn = (): GridColumnConfig<DataRegistryEntry> => ({
	title: i18n('Category node'),
		field: 'categoryNode',
		width: 250,
		renderer: r => r.categoryNode
})

const getAccountNameColumn =  (): GridColumnConfig<DataRegistryEntry> => ({
	title: i18n('Account'),
		field: 'accountName',
		width: 250,
		renderer: r => r.accountName
})

const getCategoryPathColumn = (): GridColumnConfig<DataRegistryEntry> => ({
	title: i18n('Category path'),
		field: 'categoryPath',
		width: 250,
		renderer: r => r.categoryPath
})

const getInstanceNameColumn = (title?: string): GridColumnConfig<DataRegistryEntry> => {
	return {
		title: title ?? i18n('Instance'),
		field: 'instanceName',
		width: 250,
		renderer: r => r.instanceName
	}
}

const getSourceNameColumn = (title?: string, fixed?: boolean): GridColumnConfig<DataRegistryEntry> => {
	return {
		title: title ?? i18n('Source'),
		field: 'registryOwnerName',
		width: 250,
		fixed: fixed ? 'left' : null,
		renderer: r => r.registryOwnerName
	}
}

type DataRegistryWindowStoreProps = {
	accountId: string
	defaultSelectedMetricIds: string[]
	onAdd: (ids: string[]) => void
	onClose: () => void
	limit?: number
	singleMetricSelection?: boolean
}

enum DataRegistryCategoryLayout {
	Asset = 'asset',
	Service = 'service',
	Monitor = 'monitor'
}

class DataRegistryWindowStore {
	props: DataRegistryWindowStoreProps
	gridStore: GridStore<DataRegistryEntry>

	includeSubaccounts: boolean = false
	categorizeBy: DataRegistryCategoryLayout = DataRegistryCategoryLayout.Asset

	showOnlySelected: boolean = false

	mobx = new MobxManager()

	constructor(props: DataRegistryWindowStoreProps) {
		this.props = props
		this.init()

		makeObservable(this, {
			includeSubaccounts: observable,
			categoryOptions: computed,
			categorizeBy: observable,
			showOnlySelected: observable
		})
	}

	get categoryOptions(){
		return [{ label: i18n('Asset'), value: DataRegistryCategoryLayout.Asset },
		 		{ label: i18n('Monitor'), value: DataRegistryCategoryLayout.Monitor },
		 		{ label: i18n('Service'), value: DataRegistryCategoryLayout.Service }]
	}

	async init() {
		this.gridStore = new GridStore<DataRegistryEntry>({
			columns: this.getColumns(),
			dataProvider: new RemoteDataProvider({
				dataSource: () => getDataRegistry({
					accountId: this.props.accountId,
					includeSubaccounts: this.includeSubaccounts,
					selection: this.showOnlySelected ? this.gridStore.selection.payload : null
				}),
				filtersSource: getDataRegistryFilters()
			}),
			defaults: {
				selectedIds: this.props.defaultSelectedMetricIds,
				showInlineFilters: true
			},
			selection: this.props.singleMetricSelection ? GridSelectionType.One : GridSelectionType.Many
		})

		this.mobx.reaction(() => this.categorizeBy, () => {
			this.gridStore.config.columns = this.getColumns()
			this.gridStore.state.reset(this.gridStore)
		})
	}

	get limit(){
		return this.props.limit ?? 100
	}

	getColumns(){
		let columns: GridColumnConfig<DataRegistryEntry>[] = [{
			title: i18n('Agent'),
			field: 'agentName',
			width: 250,
			renderer: r => r.agentName
		},{
			title: i18n('Metric id'),
			field: 'metricId',
			width: 250,
			renderer: r => r.metricId
		},{
			title: i18n('Metric type'),
			field: 'metricType',
			width: 250,
			renderer: r => r.metricType
		},{
			title: i18n('Registry identifier'),
			field: 'registryIdentifier',
			width: 250,
			renderer: r => r.registryIdentifier
		},{
			title: i18n('Registry owner id'),
			field: 'registryOwnerId',
			width: 250,
			renderer: r => r.registryOwnerId
		},{
			title: i18n('Registry type'),
			field: 'registryType',
			width: 250,
			renderer: r => r.registryType
		},{
			title: i18n('Unit type'),
			field: 'unitType',
			width: 250,
			renderer: r => r.unitType
		}]

		if(this.categorizeBy == DataRegistryCategoryLayout.Service){
			columns.splice(0, 0, getSourceNameColumn(i18n('Service name'), true),
				getInstanceNameColumn(i18n('Servicequalifier name')), getAccountNameColumn(),
				getAssetNameColumn(), getCategoryNodeColumn(), getCategoryPathColumn())
		}else if( this.categorizeBy == DataRegistryCategoryLayout.Monitor){
			columns.splice(0, 0, getSourceNameColumn(i18n('Monitor name'), true),
				getAccountNameColumn(), getAssetNameColumn(), getCategoryNodeColumn(), getCategoryPathColumn(), getInstanceNameColumn())
		}else{
			columns.splice(0, 0, getAssetNameColumn(true), getCategoryNodeColumn(), getAccountNameColumn(),
				getCategoryPathColumn(), getInstanceNameColumn(), getSourceNameColumn())
		}

		return columns
	}

	save = async () => {
		const idsResponse = await apiFetch(getDataRegistrySelectedIds(this.gridStore))

		if (idsResponse.success) {
			this.props.onAdd(idsResponse.data)
		}
	}

	cancel = () => {
		this.props.onClose()
	}
}
