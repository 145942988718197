import React from 'react';
import injectSheet from "react-jss";
import classnames from 'classnames';
import {DropDownList} from "controls/react/form";

const styles = {
	container: {
		paddingLeft: "10px",
		paddingRight: "5px",
		paddingTop: "10px",
		'.k-widget .geSidebarContainer & *': {
			boxSizing: "border-box"
		}
	},
	multitoggle: {
		float: "left"
	},
	text: {
		whiteSpace: "normal"
	},
	margin: {
		marginBottom: "5px"
	}
};

const operators = [{
	text: lang.EQ,
	value: 'EQ'
}, {
	text: lang.GE,
	value: 'GE'
}, {
	text: lang.GT,
	value: 'GT'
}, {
	text: lang.LE,
	value: 'LE'
}, {
	text: lang.LT,
	value: 'LT'
}, {
	text: lang.NE,
	value: 'NE'
}];

class WeightProperties extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			type: 'Weighted',
			weightType: this.props.rule.weightType || 'Count',
			countWeight: this.props.rule.weight || 1,
			percentageWeight: this.props.rule.weight || 10,
			operator: this.props.rule.operator || 'GE'
		}
	}

	onUpdate(params) {
		this.setState(params, () => {
			this.props.onUpdate({
				type: 'Weighted',
				weightType: this.state.weightType,
				weight: this.state.weightType === 'Count' ?
					this.state.countWeight: this.state.percentageWeight,
				operator: this.state.operator
			});
		});
	}

	initNumericCounter() {
		const self = this;

		$(this.counterValue).kendoNumericTextBox({
			value: this.state.countWeight || 1,
			format: 'n0',
			min: 1,
			max: 1000,
			step: 1,
			spin: function () {
				self.onUpdate({countWeight: this.value()});
			},
			change: function () {
				self.onUpdate({countWeight: this.value()});
			}
		});
	}

	initNumericPercantage() {
		const self = this;

		this.percentageValue = $(this.percentageValue).kendoNumericTextBox({
			format: "# \\%",
			value: this.state.percentageWeight || 10,
			decimals: 2,
			min: 1,
			max: 100,
			step: 10,
			spin: function () {
				self.onUpdate({percentageWeight: this.value()});
			},
			change: function () {
				self.onUpdate({percentageWeight: this.value()});
			}
		});
	}

	componentDidMount() {
		this.initNumericCounter();
		this.initNumericPercantage();
		this.onUpdate({type: 'Weighted'});
	}

	render() {
		const {rule, classes} = this.props;

		const counterLabel = this.props.isAggregated ?
			lang.designer.MINIMUM_AVAILABLE_SERVICE_ELEMENTS :
			lang.designer.MINIMUM_AVAILABLE_SERVICE_QUALIFIERS;
		const percentageLabel = this.props.isAggregated ?
			lang.designer.PERCENTAGE_AVAILABLE_SERVICE_ELEMENTS :
			lang.designer.PERCENTAGE_AVAILABLE_SERVICE_QUALIFIERS;

		const weightType = this.state.weightType;
		const isCount = weightType === 'Count';
		const isPercent = weightType === 'Percent';

		const operator = <div className={classes.operator}>
			<label className="cw_inline">{lang.designer.OPERATOR}</label>
			<div className="cw_dropdown_container">
				<DropDownList dataSource={operators}
				              value={this.state.operator}
				              onChange={(value) => this.onUpdate({operator: value})}/>
			</div>
		</div>;

		return (
			<div className={classes.container}>
				<div>
					<label className="cw_inline">{lang.TYPE}</label>
					<ul id="cw_weighed_type" className={classnames("cw_multi_toggle", classes.multitoggle)} ref={(el) => this.toggle = el}>
						<li onClick={() => this.onUpdate({weightType: 'Count'})}
							className={classnames("item", "first_btn", isCount && "is_selected")}>
							{lang.designer.COUNTER}
							</li>
						<li onClick={() => this.onUpdate({weightType: 'Percent'})}
							className={classnames("item", "last_btn", isPercent && "is_selected")}>
							Percentage
						</li>
					</ul>
				</div>
				<div className="clear"></div>
				<div className={classnames(!isCount && "hide")}>
					<div className={classes.text}>
						{counterLabel}
					</div>
					<div>
						{operator}
						<div className={classnames("clear", classes.margin)}/>
						<label className="cw_inline">{lang.designer.OPERAND} #.</label>
						<div className="cw_dropdown_container">
							<input type="text" ref={(el) => this.counterValue = el}/>
						</div>
					</div>
				</div>
				<div className={classnames(!isPercent && "hide")}>
					<div className={classes.text}>
						{percentageLabel}
					</div>
					<div>
						{operator}
						<div className={classnames("clear", classes.margin)}/>
						<label className="cw_inline">{lang.designer.OPERAND} %.</label>
						<div className="cw_dropdown_container">
							<input type="text" ref={(el) => this.percentageValue = el}/>
						</div>
					</div>
				</div>
				<div className="clear"/>
			</div>
		)
	}
}

export default injectSheet(styles)(WeightProperties)
