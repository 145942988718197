import React from 'react';

import {Api} from "tools/api";
import {ApiRequest} from "../../framework/api";

export class PresentationApi {

	static baseUrl(accountId?: string, sessionId?: string) {
		return Api.sessionAndAccountRoot(accountId, sessionId) + 'dashboards';
	}

	static list(accountId: string) {
		return Api.fetch(PresentationApi.baseUrl(accountId));
	}

	static read(id: string, accountId?: string, sessionId?: string) {
		return Api.fetch(PresentationApi.baseUrl(accountId, sessionId) + "/" + id);
	}

	static save(presentation: object, accountId?: string) {
		return Api.fetchPost(PresentationApi.baseUrl(accountId), presentation);
	}

	static delete(ids : string[], accountId?: string) {
		return Api.fetchPost(PresentationApi.baseUrl(accountId) + "/delete", ids);
	}

	static getSearchUrl() {
		return Api.accountRoot() + 'dashboards/lite?name=';
	}
}

export function useDashboards(accountId?: string){
	const [list, setList] = React.useState([]);

	React.useEffect(() => {
		PresentationApi.list(accountId)
			.then(response => {
				if( response.success !== false){
					setList(response)
				}
			})
	}, [accountId])

	return list;
}

export class Dashboard{
	id: string
	name: string
}

export function getDashboardsLite(accountId?: string, includeSubaccounts: boolean = true) {
	return new ApiRequest<Dashboard[]>({
		url: `dashboards/lite?uiContext=WEB`,
		accountId,
		includeSubaccounts,
		accountBased: true
	})
}
