import {newGuid} from "tools/guid";
import {hex2rgba} from "tools/helpers/styles";

const b = require('b_').with('html-shape-container');

export function enableFeature(designer){
	registerShape(designer);
	addCopyPaste(designer);
}

function addCopyPaste(designer){
	const original = designer.graph.cloneCells;

	designer.graph.cloneCells = function() {
		let clones = original.apply(this, arguments);

		for(const cell of clones){
			if (!isHtmlContainerCell(designer.graph, cell)) {
				continue;
			}

			designer.graph.setCellStyles(
				'containerId',
				newGuid(),
				[cell]
			);
		}

		return clones;
	}
}

export function isHtmlContainerCell(graph, cell) {
	const styles = graph.getCellStyle(cell);
	return styles.shape == 'htmlContainerShape';
}

export function getHtmlContainer(graph, cell){
	const state = graph.view.getState(cell);
	return {
		id: state?.containerId,
		container: state?.containerId
			? document.getElementById(state.containerId)
			: null
	};
}

function hexToRgb(hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}

function registerShape(designer){
	if(mxCellRenderer.defaultShapes["htmlContainerShape"] != null)
		return;

	function HtmlContainerShape(){
		mxShape.call(this);
	}

	HtmlContainerShape.prototype = new mxShape();
	HtmlContainerShape.prototype.constructer = HtmlContainerShape;

	HtmlContainerShape.prototype.paintVertexShape =  function(c, x, y, w, h){
		this.initContainer(c);

		this.calculateTransform(c, x, y, w, h );

		this.updateSize(w, h);

		this.updateStyles();

		c.root.appendChild(this.container);
	}

	HtmlContainerShape.prototype.updateStyles = function() {
		let divStyle = this.div.style;
		let shapeStyle = this.state.style;

		divStyle.backgroundColor = mxStylesToBackgroundColor(shapeStyle) ?? 'transparent';

		let borderStyle = 'solid';

		if(shapeStyle.dashed == 1){
			if(shapeStyle.dashPattern == null){
				borderStyle = 'dashed';
			}else{
				borderStyle = 'dotted';
			}
		}

		if(shapeStyle.strokeColor){
			divStyle.border = (shapeStyle.strokeWidth || '1') + 'px ' + borderStyle + ' ' + shapeStyle.strokeColor;
		}else{
			divStyle.border = 'none';
		}
	}

	HtmlContainerShape.prototype.updateSize = function(w, h) {
		this.fo.setAttribute('width', Math.round(Math.max(1, w)));
		this.fo.setAttribute('height', Math.round(Math.max(1, h)));

		this.div.style.width = w + 'px';
		this.div.style.height = h + 'px';
	}

	HtmlContainerShape.prototype.calculateTransform = function(c, x, y, w, h) {
		const s = c.state;
		var tr = (s.scale != 1) ? 'scale(' + s.scale + ')' : '';

		if (s.rotation != 0 && c.rotateHtml)
		{
			tr += 'rotate(' + (s.rotation) + ',' + (w / 2) + ',' + (h / 2) + ')';
			var pt = c.rotatePoint((x + w / 2) * s.scale, (y + h / 2) * s.scale,
				s.rotation, s.rotationCx, s.rotationCy);
			x = pt.x - w * s.scale / 2;
			y = pt.y - h * s.scale / 2;
		}
		else
		{
			x *= s.scale;
			y *= s.scale;
		}

		this.container.setAttribute('transform', 'translate(' + (Math.round(x) + c.foOffset) + ',' +
			(Math.round(y) + c.foOffset) + ')' + tr);
	}

	HtmlContainerShape.prototype.initContainer = function(c) {
		if (this.container == null) {
			this.div = document.createElement('div');

			this.div.id = newGuid();
			this.state.containerId = this.div.id;
			this.div.style.display = 'flex';
			this.div.style.flexDirection = 'column';
			this.div.style.position = 'fixed';

			this.fo = c.createElement('foreignObject');
			this.fo.setAttribute('style', 'overflow:visible;');
			this.fo.setAttribute('pointer-events', 'all');
			this.fo.appendChild(this.div);

			this.container = c.createElement('g');
			this.container.appendChild(this.fo);
		}

		this.div.className = b({
			header: this.state.style.widgetHeader != null ? this.state.style.widgetHeader : 'default'
		})
	}

	mxCellRenderer.registerShape('htmlContainerShape', HtmlContainerShape);
}

export function mxStylesToBackgroundColor(shapeStyle){
	const opacity = shapeStyle.opacity != undefined
		? shapeStyle.opacity / 100.0
		: 1;

	if (!!shapeStyle.fillColor) {
		const rgb = hexToRgb(shapeStyle.fillColor);
		if (rgb) {
			return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
		}
	}

	return null
}
