import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import translator from 'core/localization/localization';

export function HistoryWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default HistoryWidgetConfiguration;

const i = translator ({
  "Information": {
    "no": "Informasjon"
  },
  "Select a valid timeperiod": {
    "no": "Velg en gyldig tidsperiode",
    "en": "Select a valid time period"
  }
});

jQuery.extend(HistoryWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_service">';
		html += '<label class="cw_inline">' + lang.widget.SERVICE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_sid" type="text" value="' + (widgetObj.configuration.serviceId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_se">';
		html += '<label class="cw_inline">' + lang.designer.SERVICE_ELEMENT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_seid" type="text" value="' + (widgetObj.configuration.serviceElementId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIOD + ': </label>';
		html += '<div id="cw_history_period_control" class="cw_multi_toggle right">';
		html += '</div>';
		html += '<div class="cw_hide_time_selector"><input type="checkbox" class="cw_include k-checkbox" id="cw_hide_time_selector"' + (widgetObj.configuration.hideTimeSelector ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_hide_time_selector" class="k-checkbox-label">' + lang.widget.HIDE_TIME_SELECTOR + '</label></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_timezone" type="text" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '"/></div></div>';
		html += '<div class="cw_field"><div class="cw_separator"><span class="cw_separator_title">' + i('Information') + '</span>';
		html += '<div class="cw_exclude_downtime left w50 clear"><input type="checkbox" class="cw_include k-checkbox" id="cw_exclude_downtime"' + (widgetObj.configuration.excludeDowntime ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_exclude_downtime" class="k-checkbox-label">' + lang.widget.SHOW_DOWNTIME + '</label></div>';
		html += '<div class="cw_exclude_breaches left w50"><input type="checkbox" class="cw_include k-checkbox" id="cw_exclude_breaches"' + (widgetObj.configuration.excludeBreaches ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_exclude_breaches" class="k-checkbox-label">' + lang.widget.SHOW_BREACHES + '</label></div>';
		html += '<div class="cw_show_footer left w50"><input type="checkbox" class="cw_include k-checkbox" id="cw_show_footer"' + (widgetObj.configuration.showFooter ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_show_footer" class="k-checkbox-label">' + lang.widget.HISTORY_SHOW_FOOTER + '</label></div>';
		html += '</div></div></div>';
		this.widgetConfigurationFormEl.empty().append(html);
		this.addTimeSelector(this.widgetObj.configuration, $('#cw_history_period_control')[0]);

		if (widgetObj.configuration.period) {
			$("input[value=" + widgetObj.configuration.period + "]").prop('checked', true);
			$("input[value=" + widgetObj.configuration.period + "]").parent().addClass('is_selected');
		}

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();

		const requiredFields = ['#cw_history_accountid', '#cw_history_sid', '#cw_history_seid'];
		applyRequired(requiredFields);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_history_accountid'),
			container: $('#cw_history_account'),
			nextElement: $('#cw_history_sid'),
			events: {
				change: (e) => {
					this.isInputChanged = true;
					this.currentAccountId = e.sender.value();
					this.serviceSelector.dataSource.read();
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_history_se'),
							label: lang.designer.SERVICE_ELEMENT
						}],
						length: e.sender.dataSource.data().length
					});
					widgetObj.configuration.serviceId = null;
				},
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_history_se'),
							label: lang.designer.SERVICE_ELEMENT
						}],
						length: length
					});
				}, this)
			}
		});

		this.serviceSelector = $('#cw_history_sid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_se'),
							label: lang.designer.SERVICE_ELEMENT
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/services/lite?hasModel=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(this.onServiceModelSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			autoBind: widgetObj.configuration.accountId,
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
				this.currentServiceId = e.sender.value();
				this.serviceElementSelector.dataSource.read();
				this.emptyComponent({
					containers: [{
						container: $('#cw_history_se'),
						label: lang.designer.SERVICE_ELEMENT
					}],
					length: e.sender.dataSource.data().length
				});
			}, this),
			dataBound: $.proxy(function () {
				if (widgetObj.configuration.serviceId) {
					$('#cw_history_sid').data('kendoComboBox').value(widgetObj.configuration.serviceId);
				}
			}, this)
		}).data('kendoComboBox');

		let seDataSource = [];
		if (widgetObj.configuration.serviceId) {
			seDataSource = new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_se'),
							label: lang.designer.SERVICE_ELEMENT
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + (this.currentServiceId || widgetObj.configuration.serviceId) + '/elements/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			});
		}

		this.serviceElementSelector = $('#cw_history_seid').kendoComboBox({
			dataSource:  seDataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			enable: widgetObj.configuration.serviceId,
			placeholder: lang.SELECT,
			clearButton: false,
			autoBind: true
		}).data('kendoComboBox');

		$('#cw_history_seid').data('kendoComboBox').value(widgetObj.configuration.serviceElementId);
		$('#cw_history_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			clearButton: false,
		}).data('kendoComboBox');
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var widgetObj = {
			id: this.widgetObj.id || Utils.guid(),
			type: 'history',
			title: title,
			configuration: {
				accountId: $('#cw_history_accountid').data('kendoComboBox').value(),
				serviceId: $('#cw_history_sid').data('kendoComboBox').value(),
				serviceElementId: $('#cw_history_seid').data('kendoComboBox').value(),
				...this.periodToggle,
				timezone: $('#cw_history_timezone').data('kendoComboBox').value(),
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				excludeBreaches: $('#cw_exclude_breaches').is(':checked'),
				excludeDowntime: $('#cw_exclude_downtime').is(':checked'),
				showFooter: $('#cw_show_footer').is(':checked')
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		var sCombo = $('#cw_history_sid').data('kendoComboBox');
		var seCombo = $('#cw_history_seid').data('kendoComboBox');
		var accountCombo = $('#cw_history_accountid').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(seCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE_ELEMENT;
		}
		if (!Utils.isGuid(sCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE_MODEL;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},
	/**
	 * Handler function for the select event of the metrics service dropdown list
	 * @param {Object} e The select event
	 */
	onServiceModelSelect: function (e) {
		var serviceElementCombo = $("#cw_history_seid").data('kendoComboBox');
		var smId = e.dataItem.id;
		if (smId) {
			serviceElementCombo.enable(true);
			serviceElementCombo.value('');
			var newSeDataSource = new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + smId + '/elements/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			});
			serviceElementCombo.setDataSource(newSeDataSource);
			serviceElementCombo.dataSource.read();

		} else {
			serviceElementCombo.text('');
			serviceElementCombo.enable(false);
		}
	},
});
