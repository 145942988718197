import React from 'react';
import ReactDOM from "react-dom";
import {Cookies, ErrorHandler, LocalEventsManager, StaticVars} from "core";
import {CustomNotification, ExpandableTextarea, Tags} from "controls";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {Settings} from "settings";
import {Utils} from "tools/utils";
import {AdministrationApi} from "areas/administration/api";
import {translator} from 'core/localization/localization';
import {TagsSelect} from "controls/react/tagsSelect";
import FormEntry from "controls/react/form/formEntry";
import Api from 'tools/api';
import Renderer from 'tools/renderer';
import {AntInput} from "../../controls/react/ant/antInput";
import {CloseCircleOutlined} from '@ant-design/icons'
import {makeAutoObservable} from 'mobx';
import {observer} from 'mobx-react';
import {upperFirst} from 'lodash';
import './authenticationForm.less'

const i = translator({
  "API key": {
    "no": "API nøkkel",
    "en": "API key"
  },
  "API secret": {
    "no": "API secret",
    "en": "API secret"
  },
  "Authentication server": {
    "en": "Auth server",
    "no": "Auth server"
  },
  "Token server": {
    "no": "Token server",
    "en": "Token server"
  },
  "Scope": {
    "no": "Scope"
  },
  "Validate start message": {
    "en": "Click validate button to start the validation process",
    "no": "Klikk på valider knappen for å starte valideringsprosessen"
  },
  "Valid authentication": {
    "en": "Authentication of current configuration is validated",
    "no": "Autentisering av gjeldende konfigurasjon er validert"
  },
  "Valid authentication changed": {
    "en": "Authentication configuration has been changed. New configuration should be validated before update",
    "no": "Autentisert konfigurasjon er endret. Ny konfigurasjon bør valideres før oppdatering"
  },
  "Code expires": {
    "en": "The code expires on {0}",
    "no": "Koden utløper {0}"
  },
  "Oauth needs validation": {
    "en": "You need to validate the Oauth-2 after create. Follow the instructions in the next screen",
    "no": "Du må validere Oauth-2 etter opprettelse. Følg instruksjonene i neste skjerm"
  },
  "Clear field": {
    "no": "Tøm felt"
  }
});

const clearablePasswordFields = {
	'SSH': ['password', 'privateKey'],
	'JDBC': ['password'],
	'WMI': ['password'],
	'SNMPv1': ['community'],
	'SNMPv2c': ['community'],
	'SNMPv3': ['privacyPassphrase', 'authPassphrase'],
	'GENERIC': ['password']
}

class PasswordsStore {
	data = {}
	constructor(mode) {
		this.mode = mode
		makeAutoObservable(this)
	}

	setValue(field, value) {
		const fieldData = this.fieldData(field)
		if (!value) {
			fieldData.clear = true
			fieldData.changed = true
			fieldData.value = null
		} else {
			fieldData.clear = false
			fieldData.value = value
		}
	}

	setHasPassword(field, value) {
		const fieldData = this.fieldData(field)
		if (value) {
			fieldData.value = '******'
			fieldData.clear = false
			fieldData.changed = false
			fieldData.locked = true
		} else {
			fieldData.value = ''
			fieldData.clear = true
			fieldData.changed = true
			fieldData.locked = false
		}
	}

	fieldData(field) {
		this.data[field] ||= {}
		return this.data[field]
	}

	getValue(field) {
		const fieldData = this.fieldData(field)
		return fieldData.value
	}

	getLocked(field) {
		const fieldData = this.fieldData(field)
		return fieldData.locked
	}

	unlock(field) {
		const fieldData = this.fieldData(field)
		fieldData.locked = false
		this.setValue(field, '')
	}

	getFields(type) {
		const fieldsNames = clearablePasswordFields[type]
		if (!fieldsNames) {
			return {}
		}
		return fieldsNames.reduce((acc, field) => {
			const fieldData = this.fieldData(field)
			if (fieldData.changed || this.mode == 'create') {
				if (fieldData.clear) {
					acc[`clear${upperFirst(field)}`] = true
				}
				if (fieldData.value) {
					acc[field] = fieldData.value
				}
			}
			return acc
		}, {})
	}

	fillInitialData(type, record) {
		const fieldsNames = clearablePasswordFields[type]
		if (!fieldsNames) {
			return
		}
		fieldsNames.forEach((key) => {
			this.setHasPassword(key, record[`has${upperFirst(key)}`])
		})
	}
}

const PasswordField = observer(({id, label, store}) => {
	const inputRef = React.useRef()
	const locked = store.getLocked(id)
	const value = store.getValue(id)
	return <FormEntry label={label}>
		<AntInput
			type='password'
			readOnly={locked}
			id={id}
			ref={inputRef}
			onChange={(value) => store.setValue(id, value)}
			value={value}
			autoComplete="new-password"
			suffix={<CloseCircleOutlined style={{display: value ? 'block' : 'none'}} className="auth-form__password-unlock" title={i('Clear field')} onClick={() => {store.unlock(id); inputRef.current.focus()}}/>}
		/>
	</FormEntry>
})

export let AuthenticationForm = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default AuthenticationForm;

jQuery.extend(AuthenticationForm.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: async function () {
		this.update = true;
		this.passwordsStore = new PasswordsStore(this.mode)
		this.accountId = this.accountId || Cookies.CeesoftCurrentAccountId;

		if (this.mode === 'view') {
			this.update = false;
		}
		var actionButtonText = lang.CREATE;

		this.modalHandler = this.modalId || 'modal';

		this.tagsList = [];

		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('#' + this.modalHandler + ' .cw_description')
		});

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#' + this.modalHandler,
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		if (this.mode === 'update') {
			actionButtonText = lang.UPDATE;
		}
		$('#save_authentication').text(actionButtonText);
		this.getAuthenticationTemplates();
		this.removeListeners();
		this.attachListeners();

		if (this.mode === 'update' || this.mode === 'view') {
			this.load();
		} else {
			$('#cw_cvid').closest('.cw_field').hide();
			$('#save_authentication').attr('disabled', true);
			this.onKeyUp();
			this.renderTags();
		}
		if (this.preselectedAuths?.length === 1) {
			this.updateFields(this.preselectedAuths[0]);
			this.selectedAuthType = this.preselectedAuths[0];
		}
	},
	renderTags() {
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical>
			<TagsSelect
				mode={'tags'}
				disabled={this.mode === 'view'}
				accountId={this.accountId}
				includeSubaccounts={false}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList}
				placeholder={lang.TAGS} />
		</FormEntry>,  $('.create_authentication #cw_tags').parent().get()[0]);
	},

	onTagsChange(value) {
		this.tagsList = value || [];
	},

	/**
	 * Defines the kendo controls for the form
	 */
	getAuthenticationTemplates: function () {
		let types = this.authTypes || [];

		this.authTemplatesDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/authentications/?' + types.map( v => 'types=' + v).join('&') ,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			sort: [{
				field: "authenticationTypeText",
				dir: "asc"
			}],
			change: $.proxy(function () {
				let authType = this.authType;
				//sync combo value
				if (authType) {
					var updateCombo = function () {
						if ($("#auth_list").length) {
							$("#auth_list").data('kendoDropDownList').text(authType);
						}

					};
					setTimeout($.proxy(updateCombo, this), 150);
				}
				if (this.preselectedAuths) {
					var allAuths = this.authTemplatesDataSource.data();
					for (var i = 0; i < allAuths.length; i++) {
						if (this.preselectedAuths.indexOf(allAuths[i].authenticationType) === -1) {
							this.authTemplatesDataSource.remove(this.authTemplatesDataSource.at(i));
						}
					}
					if (this.preselectedAuths.length === 1) {
						this.authList.value(this.preselectedAuths[0]);
					} else {
						this.authList.text(lang.account.AUTH_SELECT_TYPE);
					}
				}
			}, this),
			error: ErrorHandler.kendoServerError
		});

		//account parent combo list
		this.authList = $('#auth_list').kendoDropDownList({
			autoBind: (this.mode === 'update' || this.preselectedAuths) ? true : false,
			text: (this.mode === 'update') ? undefined : lang.account.AUTH_SELECT_TYPE,
			dataTextField: 'authenticationTypeText',
			dataValueField: 'authenticationType',
			enable: (this.mode === 'create') ? true : false,
			dataSource: this.authTemplatesDataSource,
			template: '<span>${authenticationTypeText}</span>',
			change: $.proxy(function (e) {
					Utils.setInvalidField(this.authList.value(), $('#auth_list'), false, 'required_select');
					$('#auth_form').find('.k-widget.k-dropdown').removeClass('invalid');
					var renderAuth = $.proxy(function () {
						this.selectedAuthType = e.sender.value();
						if ($('#auth_list').data('kendoDropDownList')) {
							this.updateFields($('#auth_list').data('kendoDropDownList').value());
						}
					}, this);
					renderAuth();
			}, this),
			select: function() {
				this.trigger('change');
			}
		}).data('kendoDropDownList');
		if (this.preselectedAuths?.length) {
			this.authList.value(this.preselectedAuths[0]);
		}
		if (this.modalId) {
			$('#' + this.modalId).find('.cw_actions').css({
				'position': 'relative',
				'float': 'right'
			});
		}
	},
	/**
	 * Handler function for making form readonly if user does not have update permission
	 */
	enableViewMode: function () {
		$('.cw_field input').addClass('is_readonly');
		$('#save_authentication').addClass('hide');
		this.descriptionHandler.readonly(true);
		$('.is_readonly').attr('disabled', true);
		this.removeListeners();
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#save_account').off();
		$('#cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_authentication').on('click', $.proxy(this.onSaveButton, this));
		$('#auth_form').on('keyup', $.proxy(this.onKeyUp, this));
		$('#auth_form').on('keyup', '.oauth_field', (e) => this.onOAuthFieldChange(e));
		$('#validate_authentication').on('click', () => this.onValidateAuthentication());
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		$('#auth_name').parent().removeClass('required_form');
		$('#auth_form').find('.k-widget.k-dropdown').removeClass('invalid');
	},
	onKeyUp: function () {
		const name = $('#auth_name').val();
		Utils.setInvalidField(name, $('#auth_name'), true);
		Utils.setPlaceholder($('#auth_name'), i('Please input Name...'));
		Utils.setInvalidField(this.authList.value(), $('#auth_list'), false, 'required_select');
		if(name.trim() !== '') {
			$('#save_authentication').attr('disabled', false);
		}
		else {
			$('#save_authentication').attr('disabled', true);
		}
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: function () {
		const loadUrl = AdministrationApi.getAuthenticationUrl(null, this.id, this.update);

		var oThis = this;
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.name = result.data.name;
				this.descriptionHandler.value(result.data.description);
				this.authType = result.data.authenticationType;
				$("#auth_list").data('kendoDropDownList').value(result.data.authenticationType);
				this.selectedAuthType = result.data.authenticationType;
				this.updateFields(result.data.authenticationType);
				this.updateValues(result.data);
				if (result.data.tags && result.data.tags.length) {
					this.tagsList = result.data.tags;
				}
				this.renderTags();
				if (result.data.authenticationType === 'OAUTH2') {
					if (result.data.hasRefreshToken) {
						this.onOAuthFinalValidateStep(result.data);
					} else if (result.data.userCode) {
						if (new Date(result.data.userCodeExpires) < new Date()) {
							let codeExpired = true;
							this.onOAuthFirstValidateStep(codeExpired);
						} else {
							this.onOAuthSecondValidateStep(result.data);
						}
					} else {
						this.onOAuthFirstValidateStep();
					}
				}
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},

	onOAuthFirstValidateStep(codeExpired) {
		this.modalNotification.setOptions({
			message: i('Validate start message'),
			status: 'info',
			animationTime: 0,
			hideOnClick: false
		}).show();
		$('#validate_authentication').removeClass('hide');
		this.codeExpired = codeExpired;
		this.oauthStep = 1;
	},

	onOAuthSecondValidateStep(authData) {
		let shownMessage = '';
		if (authData.message) {
			let urlIndexInMessage = authData.message.indexOf(authData.verificationURL);
			if (urlIndexInMessage !== -1) {
				let messageFirstPart = authData.message.substring(0, urlIndexInMessage);
				let messageUrlPart = `<a href=${authData.verificationURL} target="_blank">${authData.verificationURL}</a>`;
				let messageSecondPart = authData.message.substring(urlIndexInMessage + authData.verificationURL.length, authData.message.length);
				shownMessage = messageFirstPart + messageUrlPart + messageSecondPart;
			} else {
				shownMessage = authData.message;
			}
			if (authData.userCodeExpires) {
				let expireDate = Renderer.browserDateRenderer(authData.userCodeExpires, StaticVars.DATETIME);
				shownMessage += ` ${i('Code expires', expireDate)}`;
			}
		}
		this.modalNotification.setOptions({
			message: `<span>${shownMessage}</span>`,
			status: 'info',
			animationTime: 0,
			hideOnClick: false
		}).show();
		$('#validate_authentication').removeClass('hide');
		this.oauthStep = 2;
	},

	onOAuthFinalValidateStep(validatedData) {
		this.validatedData = JSON.parse(JSON.stringify(validatedData));
		this.modalNotification.setOptions({
			message: i('Valid authentication'),
			status: 'info',
			animationTime: 0,
			hideOnClick: false
		}).show();
		$('#validate_authentication').removeClass('hide').attr('disabled', true);
	},

	async getOAuthData() {
		let name = $('#auth_name').val();
		if (name.trim() !== '') {
			let searchUrl = Api.accountRoot(this.accountId) + 'authentications/list?name=';
			let nameExists = await Utils.checkIfNameExists(searchUrl, name, this.modalNotification, true, this.name);
			if (nameExists) {
				return null;
			}
		}
		return {
			id: this.id,
			name: $('#auth_name').val().trim(),
			description: this.descriptionHandler?.value().trim(),
			accountId: this.accountId,
			tags: this.tagsList,
			authenticationType: 'OAUTH2',
			apiKey: $('#api_key').val().trim(),
			apiSecret: $('#api_secret').val(),
			authServer: $('#auth_server').val(),
			tokenServer: $('#token_server').val(),
			scope: $('#scope').val()
		};
	},

	onOAuthFieldChange(e) {
		if (this.validatedData) {
			let currentOAuthConfig = {
				apiKey: $('#api_key').val().trim(),
				apiSecret: $('#api_secret').val(),
				authServer: $('#auth_server').val(),
				tokenServer: $('#token_server').val(),
				scope: $('#scope').val()
			};
			let validatedOAuthConfig = {
				apiKey: this.validatedData.apiKey,
				apiSecret: this.validatedData.apiSecret || '',
				authServer: this.validatedData.authServer,
				tokenServer: this.validatedData.tokenServer,
				scope: this.validatedData.scope
			}
			if (JSON.stringify(currentOAuthConfig) === JSON.stringify(validatedOAuthConfig)) {
				$('#validate_authentication').attr('disabled', true);
				$('#save_authentication').attr('disabled', false);
				this.modalNotification.setOptions({
					message: i('Valid authentication'),
					status: 'info',
					animationTime: 0,
					hideOnClick: false
				}).show();
			} else {
				this.oauthStep = 3;
				$('#validate_authentication').attr('disabled', false);
				$('#save_authentication').attr('disabled', true);
				this.modalNotification.setOptions({
					message: i('Valid authentication changed'),
					status: 'info',
					animationTime: 0,
					hideOnClick: false
				}).show();
			}
		}
	},

	async onValidateAuthentication() {
		let url = `${Api.serverRoot()}accounts/authentications/validateAuthentication`;
		let authData = await this.getOAuthData();
		if (!authData) {
			return;
		}
		let result = await Api.fetchPost(url, authData);
		this.validatedData = null;
		if (result.data.hasRefreshToken) {
			this.onOAuthFinalValidateStep(result.data);
			return;
		} else if (result.data.userCode) {
			this.onOAuthSecondValidateStep(result.data);
			return;
		}

		switch (this.oauthStep) {
			case 1:
				this.modalNotification.setOptions({
					message: this.codeExpired ? result.details[0] : result.message,
					status: 'error',
					animationTime: 0,
					hideOnClick: false
				}).show();
				this.codeExpired = false;
				break;
			case 2:
				this.modalNotification.setOptions({
					message: result.details[0],
					status: 'info',
					animationTime: 0,
					hideOnClick: false
				}).show();
				break;
			case 3:
				this.modalNotification.setOptions({
					message: result.message,
					status: 'error',
					animationTime: 0,
					hideOnClick: false
				}).show();
				break;
		}
	},

	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			//update status
			var successText = (this.mode === 'create' ? lang.account.messages.AUTH_SUCCESS_CREATED : lang.account.messages.AUTH_SUCCESS_UPDATED);
			this.statusNotification.setOptions({
				message: successText,
				status: 'success'
			}).show();

			LocalEventsManager.trigger('authenticationsaved', result);

			if (this.preselectedAuths && !this.skipAdd) {
				var authDropDown = $('[data-id=' + this.authId + ']').data('kendoDropDownList');

				if (authDropDown?.dataSource) {
					authDropDown.dataSource.add({
						text: this.data.name,
						value: result.data.id
					});
					authDropDown.value(result.data.id);
				}
			}

			if (this.modalId && !this.skipAdd) {
				let selector = $('.input-with-action').find('select').data('kendoComboBox');

				if (selector?.dataSource) {
					selector.dataSource.add({
						text: this.data.name,
						value: result.data.id
					});
					selector.value(result.data.id);
				}
			}

			if(this.onCreated){
				this.onCreated(result.data)
			}

			if (this.oauthCreate) {
				$('#save_authentication').text(lang.UPDATE);
				$('#save_authentication').closest('.k-window').find('.k-window-title').text(lang.account.AUTH_DETAILS);
				this.name = this.data.name;
				this.id = result.data.id;
				this.oauthCreate = false;
				this.mode = 'update';
				this.onOAuthFirstValidateStep();
			} else {
				//close window
				$('#cancel').trigger('click');
			}
		}
		else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: async function (e) {
		let name = $('#auth_name').val();
		if(name.trim() !== '') {
			let searchUrl = Api.accountRoot(this.accountId) + 'authentications/list?name=';
			let nameExists = await Utils.checkIfNameExists(searchUrl, name, this.modalNotification, true, this.name);
			if(nameExists) {
				return;
			}
		}
		else {
			this.modalNotification.setOptions({
				message: lang.account.messages.REQUIRED_FIELDS,
				status: 'error'
			}).show();
			return;
		}
		this.clearInvalid();

		this.data = {
			id: (this.id === 'new_authentication' ? null : this.id),
			name: name.trim(),
			description: this.descriptionHandler.value()?.trim(),
			accountId: this.accountId,
			tags: this.tagsList
		};
		this.data.authenticationType = this.selectedAuthType;

		Object.assign(this.data, this.passwordsStore.getFields(this.data.authenticationType))
		switch (this.data.authenticationType) {
			case 'WMI':
				this.data.domain = $('#auth_domain').val().trim();
				this.data.username = $('#auth_username').val().trim();
				break;
			case 'GENERIC':
				this.data.username = $('#auth_username').val().trim();
				this.data.param1 = $('#auth_param1').val().trim();
				this.data.param2 = $('#auth_param2').val().trim();
				this.data.param3 = $('#auth_param3').val().trim();
				this.data.param4 = $('#auth_param4').val().trim();
				this.data.param5 = $('#auth_param5').val().trim();
				break;
			case 'SSH':
				this.data.username = $('#auth_username').val().trim();
				this.data.port = $('#auth_port').val().trim();
				break;
			case 'JDBC':
				this.data.username = $('#auth_username').val().trim();
				this.data.host = $('#auth_host').val().trim();
				this.data.port = $('#auth_port').val().trim();
				this.data.database = $('#auth_database').val();
				this.data.driver = $('#auth_driver').val();
				this.data.connectionString = $('#auth_connection_string').val();
				break;
			case 'SNMPv1':
				break;
			case 'SNMPv2c':
				break;
			case 'SNMPv3':
				this.data.securityName = $('#auth_securityName').val().trim();
				this.data.privacyProtocol = $('#auth_privacyProtocol').find('.is_selected').find('input').attr('value');
				this.data.authProtocol = $('#auth_authProtocol').find('.is_selected').find('input').attr('value');
				this.data.engineId = $('#auth_engineId').val().trim();
				this.data.contextName = $('#auth_contextName').val().trim();
				this.data.contextEngineId = $('#auth_contextEngineId').val().trim();
				this.data.securityLevel = this.securityLevelList.value();
				break;
			case 'AMAZON':
				this.data.accessKeyId = $('#auth_access_key').val().trim();
				this.data.secretAccessKey = $('#auth_secret_access_key').val();
				this.data.awsRegion = $('#auth_aws_region').val().trim();
				break;
			case 'AZURE':
				this.data.subscriptionId = $('#azure_subscription_id').val().trim();
				this.data.tenant = $('#azure_tenant').val().trim();
				this.data.client = $('#azure_client').val().trim();
				this.data.key = $('#azure_key').val().trim();

				if (this.data.key === '') {
					delete this.data.key;
				}

				break;
			case 'GOOGLE_CLOUD':
				this.data.projectId = $('#google_project_id').val().trim();
				this.data.privateKey = $('#google_private_key').val().trim();
				this.data.dataset = $('#google_dataset').val().trim();

				if (this.data.privateKey === '') {
					delete this.data.privateKey;
				}

				break;

			case 'OAUTH2':
				this.data.apiKey = $('#api_key').val().trim();
				this.data.apiSecret = $('#api_secret').val();
				this.data.authServer = $('#auth_server').val();
				this.data.tokenServer = $('#token_server').val();
				this.data.scope = $('#scope').val();
				break;
		}

		//validation
		var valid = true, message;
		var errorCount = 0;
		if (this.data.name.trim() === '') {
			valid = false;
			message = lang.account.messages.AUTH_FORM_NAME;
			$('#auth_name').parent().addClass('required_form');
			errorCount++;
		}
		if (!this.data.authenticationType) {
			valid = false;
			message = lang.account.messages.AUTH_SELECT_AUTH_TYPE;
			$('#auth_form').find('.k-widget.k-dropdown').addClass('invalid');
			errorCount++;
		}
		if (errorCount > 1) {
			message = lang.account.messages.REQUIRED_FIELDS;
		}
		if (!valid) {
			this.modalNotification.setOptions({
				message: message,
				status: 'error'
			}).show();
		}
		else {
			var url = Settings.serverPath + 'accounts/authentications/';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#' + this.modalHandler).data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
		if (this.modalId) {
			let newAuthModalWindow = $('.input-with-action').closest('.k-window');
			let zIndex = parseInt(newAuthModalWindow.css('z-index'));
			newAuthModalWindow.css('z-index', zIndex + 2);
		}
	},
	/**
	 * Updates the form fields according to the authentication type
	 * @param {String} type The authentication type
	 */
	updateFields: function (type) {
		var form = $('#auth_form').find('.form_content');
		form.find('.cw_field:gt(3)').remove();
		var passPlaceholder = (this.mode === 'update' ? '******' : lang.PASSWORD);
		var communityPassphrasePlaceholder = (this.mode === 'update' ? '******' : lang.account.messages.AUTH_COMMUNITY_PASSPHRASE);
		var privacyPassphrasePlaceholder = (this.mode === 'update' ? '******' : lang.account.messages.AUTH_PRIVACY_PASSPHRASE);
		var authPassphrasePlaceholder = (this.mode === 'update' ? '******' : lang.account.messages.AUTH_PASSPHRASE);

		switch (type) {
			case 'WMI':
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_DOMAIN + '</label><input class="cw_inline k-input" id="auth_domain" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.USERNAME + '</label><input class="cw_inline k-input" autocomplete="new-password" id="auth_username" type="text" /></div>');
				form.append(`<div class="cw_field password-placeholder" data-id="password" data-label="${lang.PASSWORD}"></div>`);
				break;
			case 'GENERIC':
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.USERNAME + '</label><input class="cw_inline k-input" id="auth_username" autocomplete="new-password" type="text" /></div>');
				form.append(`<div class="cw_field password-placeholder" data-id="password" data-label="${lang.PASSWORD}"></div>`);
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_PARAM_1 + '</label><input class="cw_inline k-input" id="auth_param1" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_PARAM_2 + '</label><input class="cw_inline k-input" id="auth_param2" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_PARAM_3 + '</label><input class="cw_inline k-input" id="auth_param3" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_PARAM_4 + '</label><input class="cw_inline k-input" id="auth_param4" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_PARAM_5 + '</label><input class="cw_inline k-input" id="auth_param5" type="text" /></div>');
				break;
			case 'JDBC':
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.USERNAME + '</label><input class="cw_inline k-input" id="auth_username" autocomplete="new-password" type="text" /></div>');
				form.append(`<div class="cw_field password-placeholder" data-id="password" data-label="${lang.PASSWORD}"></div>`);
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_HOST + '</label><input class="cw_inline k-input" id="auth_host" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_PORT + '</label><input class="cw_inline k-input" id="auth_port" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_DATABASE + '</label><input class="cw_inline k-input" id="auth_database" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_DRIVER + '</label><input class="cw_inline k-input" id="auth_driver" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_CONNECTION_STRING + '</label><input class="cw_inline k-input" id="auth_connection_string" type="text" /></div>');
				break;
			case 'SSH':
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.USERNAME + '</label><input class="cw_inline k-input" id="auth_username" autocomplete="new-password" type="text" /></div>');
				form.append(`<div class="cw_field password-placeholder" data-id="password" data-label="${lang.PASSWORD}"></div>`);
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_PORT + '</label><input class="cw_inline k-input" id="auth_port" type="text" /></div>');
				form.append(`<div class="cw_field password-placeholder" data-id="privateKey" data-label="${lang.account.AUTH_PRIVATE_KEY}"></div>`);
				break;
			case 'SNMPv1':
				form.append(`<div class="cw_field password-placeholder" data-id="community" data-label="${lang.account.messages.COMMUNITY_PW}"></div>`);
				break;
			case 'SNMPv2c':
				form.append(`<div class="cw_field password-placeholder" data-id="community" data-label="${lang.account.messages.COMMUNITY_PW}"></div>`);
				break;
			case 'SNMPv3':
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.messages.SECURITY_LEVEL + '</label><div class="cw_dropdown_container"><input id="auth_security_level" type="text"</div></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AUTH_SECURITY_NAME + '</label><input class="cw_inline k-input" id="auth_securityName" type="text" /></div>');
				form.append('<div class="cw_field"><div class="cw_dropdown_container"><ul id="auth_authProtocol" class="cw_multi_toggle small left">' +
					'<li class="first_btn is_selected"><input type="radio" name="" id="" value="MD5" />MD5</li>' +
					'<li class="last_btn"><input type="radio" name="" id="" value="SHA" />SHA</li>' +
					'</ul></div></div>');
				form.append(`<div class="cw_field password-placeholder" data-id="authPassphrase" data-label="${lang.account.messages.AUTH}"></div>`);
				form.append('<div class="cw_field"><div class="cw_dropdown_container"><ul id="auth_privacyProtocol" class="cw_multi_toggle small left">' +
					'<li class="first_btn is_selected"><input type="radio" name="" id="" value="DES" />DES</li>' +
					'<li><input type="radio" name="" id="" value="AES128" />AES128</li>' +
					'<li><input type="radio" name="" id="" value="AES192" />AES192</li>' +
					'<li><input type="radio" name="" id="" value="AES256" />AES256</li>' +
					'<li class="last_btn"><input type="radio" name="" id="" value="3DES" />3DES</li>' +
					'</ul></div></div>');
				form.append(`<div class="cw_field password-placeholder" data-id="privacyPassphrase" data-label="${lang.account.messages.PRIVACY}"></div>`);
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.messages.ENGINE_ID + '</label><input class="cw_inline k-input" id="auth_engineId" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.messages.CONTEXT_ENGINE_ID + '</label><input class="cw_inline k-input" id="auth_contextEngineId" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.messages.CONTEXT_NAME + '</label><input class="cw_inline k-input" id="auth_contextName" type="text" /></div>');

				$('#auth_privacyProtocol li').off();
				$('#auth_privacyProtocol li').on('click', $.proxy(this.onToggleButtons, this));
				$('#auth_authProtocol li').off();
				$('#auth_authProtocol li').on('click', $.proxy(this.onToggleButtons, this));
				if (this.mode === 'view') {
					$('#auth_privacyProtocol li').off();
					$('#auth_authProtocol li').off();
				}
				this.securityLevelList = $('#auth_security_level').kendoDropDownList({
					autoBind: true,
					enable: true,
					dataTextField: 'text',
					dataValueField: 'value',
					dataSource: [{
						text: lang.NONE,
						value: ''
					}, {
						text: 'noAuthNoPriv',
						value: 'noAuthNoPriv'
					}, {
						text: 'authNoPriv',
						value: 'authNoPriv'
					}, {
						text: 'authPriv',
						value: 'authPriv'
					}]
				}).data('kendoDropDownList');
				break;
			case 'AMAZON':
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.ACCESS_KEY_ID + '</label><input class="cw_inline k-input" id="auth_access_key" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.SECRET_ACCESS_KEY + '</label><input type="password" autocomplete="new-password" class="cw_inline k-input password" id="auth_secret_access_key" placeholder="' + passPlaceholder + '" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AWS_REGION + '</label><input class="cw_inline k-input" id="auth_aws_region" type="text" /></div>');
				break;
			case 'AZURE':
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AZURE_SUBSCRIPTION_ID + '</label><input class="cw_inline k-input" id="azure_subscription_id" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AZURE_TENANT + '</label><input class="cw_inline k-input" id="azure_tenant" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AZURE_CLIENT + '</label><input class="cw_inline k-input" id="azure_client" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.AZURE_KEY + '</label><input type="password" autocomplete="new-password" class="cw_inline k-input password" id="azure_key" type="text"  placeholder="' + passPlaceholder + '" /></div>');
				break;
			case 'GOOGLE_CLOUD':
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.GOOGLE_PROJECT_ID + '</label><input class="cw_inline k-input" id="google_project_id" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline_full">' + lang.account.GOOGLE_PRIVATE_KEY + '</label><textarea type="password" autocomplete="new-password" class="cw_inline k-input password" id="google_private_key" placeholder="' + passPlaceholder + '" ></textarea></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + lang.account.GOOGLE_DATASET + '</label><input type="text" class="cw_inline k-input" id="google_dataset" /></div>');
				break;
			case 'OAUTH2':
				form.append('<div class="cw_field"><label class="cw_inline">' + i('API Key') + '</label><input class="cw_inline k-input oauth_field" id="api_key" type="text" autocomplete="new-password" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + i('API secret') + `</label><input class="cw_inline k-input password oauth_field" id="api_secret" type="password" autocomplete="new-password" placeholder="${this.mode == 'update' ? '******' : i('API secret') }"/></div>`);
				form.append('<div class="cw_field"><label class="cw_inline">' + i('Authentication server') + '</label><input class="cw_inline k-input oauth_field" id="auth_server" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + i('Token server') + '</label><input class="cw_inline k-input oauth_field" id="token_server" type="text" /></div>');
				form.append('<div class="cw_field"><label class="cw_inline">' + i('Scope') + '</label><input class="cw_inline k-input oauth_field" id="scope" type="text" /></div>');
				break;
		}

		if (this.mode === 'create') {
			if (type === 'OAUTH2') {
				this.modalNotification.setOptions({
					message: i('Oauth needs validation'),
					status: 'info',
					animationTime: 0,
					hideOnClick: false
				}).show();
				this.oauthCreate = true;
			} else {
				this.modalNotification.hide();
				this.oauthCreate = false;
			}
		}

		if (['update', 'view'].includes(this.mode)) {
			form.append('<div class="cw_field"><label class="cw_inline">CVID</label><input class="cw_inline k-input" id="cw_cvid" type="text" readonly="readonly"></div>')
		}

		if (this.mode === 'view') {
			this.removeListeners();
			this.enableViewMode();
		}
		$('.password, #auth_privateKey').focus(function () {
			$(this).removeAttr('placeholder');
		});
		$('.password, #auth_privateKey').blur(function () {
			$(this).attr('placeholder', passPlaceholder);
		});

		const _this = this
		$('.password-placeholder').each(function() {
			const element = $(this)
			const {id, label} = element.data()
			ReactDOM.render(<PasswordField label={label} id={id} store={_this.passwordsStore}/>, this)
		})
	},
	/**
	 * Updates the field values based on a record object
	 * @param {Object} record The object that contains the values
	 */
	updateValues: function (record) {
		$('#auth_name').val(record.name);
		$('#cw_cvid').val(record.id);

		this.descriptionHandler.value(record.description);
		this.passwordsStore.fillInitialData(record.authenticationType, record);

		switch (record.authenticationType) {
			case 'WMI':
				$('#auth_domain').val(record.domain);
				$('#auth_username').val(record.username);
				break;
			case 'GENERIC':
				$('#auth_username').val(record.username);
				$('#auth_param1').val(record.param1);
				$('#auth_param2').val(record.param2);
				$('#auth_param3').val(record.param3);
				$('#auth_param4').val(record.param4);
				$('#auth_param5').val(record.param5);
				break;
			case 'SSH':
				$('#auth_username').val(record.username);
				$('#auth_port').val(record.port);
				break;
			case 'JDBC':
				$('#auth_username').val(record.username);
				$('#auth_host').val(record.host);
				$('#auth_port').val(record.port);
				$('#auth_database').val(record.database);
				$('#auth_driver').val(record.driver);
				$('#auth_connection_string').val(record.connectionString);
				break;
			case 'SNMPv1':
				break;
			case 'SNMPv2c':
				break;
			case 'SNMPv3':
				$('#auth_securityName').val(record.securityName);
				$('#auth_engineId').val(record.engineId);
				$('#auth_contextName').val(record.contextName);
				$('#auth_contextEngineId').val(record.contextEngineId);
				$('#auth_privacyProtocol').find('.is_selected').removeClass('is_selected');
				$("input[value=" + record.privacyProtocol + "]").prop('checked', true);
				$("input[value=" + record.privacyProtocol + "]").parent().addClass('is_selected');
				$('#auth_authProtocol').find('.is_selected').removeClass('is_selected');
				$("input[value=" + record.authProtocol + "]").prop('checked', true);
				$("input[value=" + record.authProtocol + "]").parent().addClass('is_selected');
				this.securityLevelList.value(record.securityLevel);
				break;
			case 'AMAZON':
				$('#auth_access_key').val(record.accessKeyId);
				$('#auth_secret_access_key').val(record.secretAccessKey);
				$('#auth_aws_region').val(record.awsRegion);
				break;
			case 'AZURE':
				$('#azure_subscription_id').val(record.subscriptionId);
				$('#azure_tenant').val(record.tenant);
				$('#azure_client').val(record.client);
				break;
			case 'GOOGLE_CLOUD':
				$('#google_project_id').val(record.projectId);
				$('#google_dataset').val(record.dataset);
				break;
			case 'OAUTH2':
				$('#api_key').val(record.apiKey);
				$('#api_secret').val(record.apiSecret);
				$('#auth_server').val(record.authServer);
				$('#token_server').val(record.tokenServer);
				$('#scope').val(record.scope);
				break;
		}
		if ($("#auth_list").length) {
			$("#auth_list").data("kendoDropDownList").text(record.authenticationType);
		}

	},
	/**
	 * Handler function for the click event on radio toggle buttons
	 * @param {Object} e The click event
	 */
	onToggleButtons: function (e) {
		var li = $(e.currentTarget);
		var inputBtn = li.find('input');
		if (!li.hasClass('is_selected')) {
			li.parent().find('.is_selected').removeClass('is_selected');
			li.addClass('is_selected');
			inputBtn.prop('checked', true);
		}
	}
});
