export const openHelpWindow = (url: string) => {
	window.open(url, 'cw-help-tab')
}

const contextualHelpMap = new Map<string, string>(
	[
		['service', 'Service'],
		['service_details', 'Service'],
		['service_viewer', 'Service'],
		['service_designer_revision', 'Service'],
		['service_draft', 'Service'],
		['service_designer', 'Service/Servicequalifiers'],

		['slas', 'sla'],
		['slas_details', 'sla'],

		['asset_details', 'asset'],
		['assethealth_summary', 'asset'],
		['assethealth_states', 'asset'],
		['assethealth_group_details', 'asset/assetgroup'],
		['assethealth_group_summary', 'asset/assetgroup'],
		['assethealth_reasons', 'asset/healthreason'],
		['assethealth_filters', 'asset/eventfilter'],
		['assethealth_filters_details', 'asset/eventfilter'],

		['incidents', 'incidents'],
		['incidents_details', 'incidents'],

		['reports', 'reports'],
		['report_details', 'reports'],

		['automation', 'Automation'],
		['automation_details', 'Automation'],

		['administration_accounts', 'Administration/Account'],
		['administration_accounts_details', 'Administration/Account'],
		['administration_accounts_configuration', 'Administration/Account'],
		['administration_authentications', 'Administration/Authentication'],
		['administration_servicemodel_templates', 'Service/Servicequalifiers'],
		['administration_serviceelement_templates', 'Service/Servicequalifiers'],
		['administration_servicequalifier_templates', 'Service/Servicequalifiers'],
		['administration_teams', 'Administration/team'],
		['administration_users', 'Administration/user'],
		['administration_usercategories', 'Administration/userrole'],
		['administration_calendar', 'Administration/Calendar'],
		['administration_calendar_details', 'Administration/Calendar'],
		['administration_usercategories_details', 'administration_usercategories_details'],

		['management_agents', 'Managementx/#HAgents'],
		['management_agents_details', 'Managementx/#HAgents'],
		['management_agents_configuration', 'Managementx/#HAgents'],
		['bundle_configuration', 'Managementx/#HAgents'],
		['management_server', 'Managementx/#HServer'],
		['integration', 'Managementx/#HIntegration'],
		['management_repository', 'Managementx/#HRepository'],
		['management_audit', 'Managementx/#HAudit'],
		['management_users', 'Managementx/#HActiveusers'],
		['management_system_errors', 'Managementx/#HSystemmessages'],
		['management_modules', 'Managementx/#HModules'],
		['data_registry', 'Managementx/#HDataregistry'],
		['data_registry_legacy', 'Managementx/#HDataregistry'],

		['board', 'dashboards'],
		['board_new', 'dashboards'],
		['board_designer', 'dashboards'],
		['board_viewer', 'dashboards'],

		['home', 'Home'],
		['events', 'EventSummary'],
		['ruleset', 'Notification/Actiontrigger/'],
		['channels', 'Notification/Actiontrigger/'],
		['policy', 'Notification/Actiontrigger/'],
		['users_profile', 'Administration/user'],
		['teams_profile', 'Administration/team'],
		['users_profile', 'Administration/user'],
		['user_ruleset', 'Notification/Actiontrigger/'],
		['team_ruleset', 'Notification/Actiontrigger/'],
		['administration_usercategories_details', 'administration_usercategories_details'],
		['application', 'Application'],
		['application_details', 'Application'],
		['application_designer', 'Application/applicationdesigner'],

		['monitors', 'Infrastructure/Monitors'],
		['monitors_by_type', 'Infrastructure/Monitors'],
		['monitor_container', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.container'],
		['monitor_database', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.database'],
		['monitor_db-watch', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.dbwatch'],
		['monitor_eaton-ups', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.eatonups'],
		['monitor_file-search', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.filesearch'],
		['monitor_interface-traffic', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.interfacetraffic'],
		['monitor_processes-monitor', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.processmonitor'],
		['monitor_log-monitor', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.logmonitor'],
		['monitor_metric-monitor', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.metricmonitor'],
		['monitor_modbus', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.modbus'],
		['monitor_remote-ibm-iseries', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.remoteiseries'],
		['monitor_remote-system', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.remotesystem'],
		['monitor_script', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.script'],
		['monitor_snmp', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.snmp'],
		['monitor_snmp-trap', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.snmptrap'],
		['monitor_system', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.system'],
		['monitor_url', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.url'],
		['monitor_xen-server', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.xenserver'],
		['monitor_external', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.dataintegration'],
		['monitor_connectivity', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.connectivity'],
		['monitor_groovy', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.groovy'],
		['monitor_hyperv', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.hyperv'],
		['monitor_ibm-svc', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.ibmsvc'],
		['monitor_mail-gateway', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.mailgtw'],
		['monitor_ms-cluster', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.mscluster'],
		['monitor_nagios', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.nagios'],
		['monitor_nimsoft', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.nimsoft'],
		['monitor_processes', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.processes'],
		['monitor_prtg', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.prtg'],
		['monitor_nimsoft', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.nimsoft'],
		['monitor_solar-winds', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.solarwinds'],
		['monitor_vmm', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.vmm'],
		['monitor_vmware', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.vmware'],
		['monitor_windows-events', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.winevents'],
		['monitor_windows-service', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.winservices'],
		['monitor_webhook', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.webhook'],
		['monitor_icinga', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.icinga'],
		['monitor_azure', 'Infrastructure/Monitors/com.ceesoft.ceeview.healthmonitor.azure'],

		['cost_list', 'Cost'],
		['cost_model', 'Cost/cost-model'],
		['cost_profile', 'Cost/cost-model'],
		['cost_gateway', 'Cost/Cost Collection'],
		['cost_gateway_details', 'Cost/Cost Collection/Azure'],

		['kpi', 'kpi'],
		['kpi_details', 'kpi'],
	]
);

export default contextualHelpMap;
