import {translator} from "core";
export const i = translator({
	'Vertical': {no: 'Stående'},
	'Horizontal': {no: 'Liggende'}
});
export enum Orientation {
	Vertical = 'Vertical',
	Horizontal = 'Horizontal'
}

let orientation: {id: Orientation, name: string}[]
export const getOrientationDataSource = () => {
	if (!orientation) {
		orientation = [{
			id: Orientation.Vertical,
			name: i(Orientation.Vertical)
		}, {
			id: Orientation.Horizontal,
			name: i(Orientation.Horizontal)
		}];
	}
	return orientation;
}
