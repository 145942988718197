import React from "react";

import {Section} from "controls/react/layout/section";
import {FormEntry} from "controls/react/form/formEntry";
import {AntInput} from "controls/react/ant/antInput";
import {AccountDropDown} from "controls/react/dropdowns/accountDropDown";
import {CloudTypesDropDown} from "areas/assets/monitors/cloud-billing/cloudTypesDropDown";
import {MonitorsDropDown} from "areas/assets/monitors/cloud-billing/monitorsDropDown";
import {SubscriptionsDropDown} from "areas/assets/monitors/cloud-billing/subscriptionsDropDown";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {ResourceType, sharedLocalization} from "controls/designer/features/widgets/cloudServices/shared";
import {sharedLocalization as sharedLocalizationGlobal} from "controls/designer/features/widgets/localization";
import {ResourceTypesRadioGroup} from "areas/assets/monitors/cloud-billing/resourceTypesRadioGroup";
import {ResourcesDropDown} from "areas/assets/monitors/cloud-billing/resourcesDropDown";
import {Cookies} from "core/cookies";

const i = require('core/localization').translator({
	'Select from': {
		no: 'Velg fra'
	}
}, sharedLocalization, sharedLocalizationGlobal);

export const WidgetConfigurationTemplate = props => {
	const {configLink} = props;

	configLink.get('accountId')
		.changing(({rootStore}) => {
			let configStore = configLink.getLinkStore(rootStore);
			configStore.monitorId = '';
			configStore.cloudType = '';
			configStore.subscriptions = [];
			configStore.resources = [];
		})
		.required();

	configLink.get('cloudType')
		.changing(({rootStore}) => {
			let configStore = configLink.getLinkStore(rootStore);
			configStore.monitorId = '';
			configStore.subscriptions = [];
			configStore.resources = [];
		})
		.required();

	configLink.get('monitorId')
		.changing(({rootStore}) => {
			let configStore = configLink.getLinkStore(rootStore);
			configStore.subscriptions = [];
			configStore.resources = [];
		})
		.required();

	configLink.get('subscriptions')
		.changing(({rootStore}) => {
			let configStore = configLink.getLinkStore(rootStore);
			configStore.resources = [];
		})
		.required();

	configLink.get('resourceType')
		.changing(({rootStore}) => {
			let configStore = configLink.getLinkStore(rootStore);
			configStore.resources = [];
		})
		.required();

	configLink.get('resources').required();

	const dataSourceProps = props.children.length > 1 ? props.children.slice(1, props.children.length) : props.children;

	const resourceLabel = configLink.value.type == 'cloud-services-instances-grid'
		? i('Cloud Service')
		: i('Resource')

	return (
		<Section appearance={"none"} contentPadding={false} childrenPadding={true}>
			<Section appearance={"frame"} title={i('Configuration')} childrenPadding={true}>
				<FormEntry label={i('Title')} width={'fit'} valueLink={configLink.get('title')}>
					<AntInput/>
				</FormEntry>
				<FormEntry label={i('Account')} width={'fit'}>
					<AccountDropDown {...configLink.get('accountId').props}/>
				</FormEntry>
			</Section>

			{props.children[0]}

			<Section appearance={"frame"} title={i('Datasource')} childrenPadding={true}>
				<FormEntry label={i('Cloud Provider')} width={'fit'} valueLink={configLink.get('cloudType')}>
					<CloudTypesDropDown accountId={configLink.get('accountId').value}/>
				</FormEntry>
				<FormEntry label={i('Monitor')} width={'fit'} valueLink={configLink.get('monitorId')}>
					<MonitorsDropDown accountId={configLink.get('accountId').value}
					                  cloudType={configLink.get('cloudType').value}/>
				</FormEntry>
				<FormEntry label={i('Subscription')} width={'fit'}
				           valueLink={configLink.get('subscriptions')}>
					<SubscriptionsDropDown hideAll={configLink.get('hideAll').value}
					                       accountId={configLink.get('accountId').value}
					                       cloudType={configLink.get('cloudType').value}
					                       monitorId={configLink.get('monitorId').value}/>
				</FormEntry>

				{configLink.value.type != 'cloud-services-instances-grid' &&
					<FormEntry label={i('Select from')}>
						<ResourceTypesRadioGroup
							valueLink={configLink.get('resourceType')}
							cloudType={configLink.get('cloudType').value}/>
					</FormEntry>
				}

				<FormEntry label={resourceLabel} width={'fit'}
				           valueLink={configLink.get('resources')}>
					<ResourcesDropDown hideTotal={configLink.get('hideTotal').value}
					                   accountId={configLink.get('accountId').value}
					                   resourceType={configLink.get('resourceType').value}
					                   cloudType={configLink.get('cloudType').value}
					                   monitorId={configLink.get('monitorId').value}
					                   subscriptions={configLink.get('subscriptions').value}/>
				</FormEntry>


				{!props.hideDecimalNumbers && <FormEntry label={i('Decimal Numbers')} width={'fit'}>
					<AntInputNumber valueLink={configLink.get('decimalsNumber')}/>
				</FormEntry>}
				{dataSourceProps}
			</Section>
		</Section>
	)
}

export function updateConfigToTheLattestFormat(config){
	if(config.resources.length == 0 && config.cloudServices?.length > 0) {
		config.resources = config.cloudServices;
		delete config.cloudServices;
	}
	return config;
}

export function getDefaultConfig(){
	return {
		accountId: Cookies.CeesoftCurrentAccountId,
		cloudType: 'AMAZONBILLING',
		monitorId: '',
		subscriptions: [],
		resourceType: ResourceType.CloudService,
		resources: [],
		decimalsNumber: 0,
	}
}
