import React from 'react'
import b_ from 'b_'
import {translator} from "../../../../../core"
import {observer} from 'mobx-react'
import './costFilterEditor.less'
import classnames from 'classnames'
import {MobxManager, ModelValidator} from "../../../../../framework/mobx-integration"
import {makeAutoObservable} from 'mobx'
import {FormEntry} from 'controls/react/form'
import {BoxView} from "../../../../react/layout/boxView"
import {AntInputNumber} from "../../../../react/ant/antInputNumber"
import {AntSelect} from "../../../../react/ant/antSelect"
import {DeleteOutlined, QuestionCircleOutlined} from '@ant-design/icons'
import {costTranslates} from "../../../../../areas/cost/translations";
import {getCostProfileFilters} from "../../../../../areas/cost/api";
import {apiFetch} from "../../../../../framework/api";
import {RulesConfiguration} from "../../../../queryBuilder/ruleDefinition";
import {CostFilter} from "../../../../../areas/cost/models/costFilter";

const i = translator({
  "Filter value": {
    "no": "Filter verdi"
  },
  "header tooltip": {
    "en": "Filter value sets a threshold for what values to display in the costmodel widget.",
    "no": "Filter verdi angir en terskelverdi for hva som vises i kostmodell widget."
  },
  "Cost Item type": {
    "no": "Kostnadelement type",
    "en": "Costitem type"
  },
  "Click to add filter value": {
    "no": "Klikk for å legge til filterverdi"
  }
}, costTranslates);

const b = b_.with('cost-display-threshold-editor')

class CostFilterEditorStore {
	enabled: boolean = false
	filtersConfiguration: RulesConfiguration;
	filtersLoaded = false;

	filterType: string = null;
	filterOperator: string = null;
	filterValue: number = null;
	validator = new ModelValidator(this);

	onChange: (value: CostFilter) => void;

	mobx = new MobxManager()

	constructor() {
		makeAutoObservable(this)
		this.loadFiltersConfiguration();

		this.validator
			.required('filterType', () => this.enabled)
			.required('filterOperator', () => this.enabled)
			.required('filterValue', () => this.enabled)

		this.mobx.reaction(() => [this.filterType, this.filterOperator, this.filterValue], this.emitOnChange);
	}

	async loadFiltersConfiguration() {
		const result = await apiFetch(getCostProfileFilters())
		if(result.success){
			this.filtersConfiguration = result.data
			this.filtersLoaded = true
		}
	}

	fieldsList() {
		return Object.entries(this.filtersConfiguration)
		.map(([key, value]) => {
			return {id: key, name: value.label}
		})
	}

	operatorsList() {
		const operators = this.filtersConfiguration[this.filterType]?.operators
		if(!operators) {
			return []
		}
		return operators.map((operator) => {
			return {id: operator.value, name: operator.title}
		})
	}

	enable = () => {
		this.enabled = true
	}

	disable = () => {
		this.enabled = false
		this.emitOnChange()
	}

	emitOnChange = () => {
		if(!this.enabled) {
			return this.onChange?.(null);
		}
		const filter = {
			type: this.filterType,
			operator: this.filterOperator,
			value: this.filterValue
		}
		this.onChange?.(filter)
	}

	setFilter(value: CostFilter) {
		if(!value) {
			this.enabled = false
			return;
		}
		this.enabled = true
		this.filterType = value.type
		this.filterOperator = value.operator
		this.filterValue = value.value
	}

	setOnChange(value: (filter: CostFilter) => void) {
		this.onChange = value;
	}

	destroy() {
		this.mobx.destroy()
	}
}

interface CostDisplayThresholdEditorProps {
	onChange?: (value: CostFilter) => void
	value?: CostFilter
}

class CostDisplayThresholdEditorUnobserved extends React.PureComponent<CostDisplayThresholdEditorProps> {
	store = new CostFilterEditorStore();

	constructor(props: CostDisplayThresholdEditorProps) {
		super(props);
		this.store.setFilter(props.value);
		this.store.setOnChange(props.onChange);
	}
	componentDidUpdate(prevProps: CostDisplayThresholdEditorProps) {
		if(prevProps.value != this.props.value) {
			this.store.setFilter(this.props.value);
		}
		if(prevProps.onChange != this.props.onChange) {
			this.store.setOnChange(this.props.onChange);
		}
	}

	render() {
		return <div className={b()}>
			<div className={b('header')}>
				{i('Filter value')}
				<QuestionCircleOutlined className={b('question-sign')} title={i('header tooltip')}/>
			</div>
			<div className={b('content')}>
				{
					this.store.enabled && this.store.filtersLoaded
						? <div className={b('form')}>
								<FormEntry containerClass={b('field')} vertical={true} label={i('Cost Item type')} model={this.store} modelField={'filterType'}>
									<AntSelect dataList={this.store.fieldsList()}/>
								</FormEntry>
								<FormEntry containerClass={b('field')} vertical={true} label={i('Operator')} model={this.store} modelField={'filterOperator'}>
									<AntSelect dataList={this.store.operatorsList()}/>
								</FormEntry>
								<FormEntry containerClass={b('field')} vertical={true} label={i('Value')} model={this.store} modelField={'filterValue'}>
									<AntInputNumber/>
								</FormEntry>
								<div className={b('delete')} onClick={this.store.disable}><DeleteOutlined /></div>
							</div>
						: <BoxView type="info-2" containerClass={b('button')} onClick={this.store.enable}>
								{i('Click to add filter value')}
							</BoxView>
				}
			</div>
		</div>
	}
}

export const CostFilterEditor = observer(CostDisplayThresholdEditorUnobserved)
