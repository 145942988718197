import {MonitorType} from "areas/assets/monitors/monitorType";
import {makeAutoObservable} from "mobx";
import {createModelSchemaWrapper} from "framework/serializr-integration";
import {primitive} from "serializr";
import {newGuid} from "tools/guid";

export class DataRegistryEntry {
	id: string
	accountId: string
	accountName: string
	agentId: string
	agentName: string
	assetId: string
	assetName: string
	categoryId: string
	categoryNode: string
	categoryPath: string
	configuration: null
	dataType: string //long,
	instanceName: string
	maxValue: string
	metricId: string
	metricType: string// "Integer",
	monitorType: MonitorType
	persist: boolean
	registryIdentifier: string
	registryOwnerId: string
	registryOwnerName: string
	registryType: string //"COLLECTOR",
	time: number
	unitType: string
	unitTypeSymbol: string

	constructor() {
		makeAutoObservable(this)
	}
}

//
// createModelSchemaWrapper(DataRegistryEntry, {
// 	id: primitive({
// 		beforeDeserialize: (callback, jsonValue, jsonParentValue, propNameOrIndex, context, propDef) =>{
// 			debugger
// 			if(jsonValue == null ){
// 				jsonValue = newGuid()
// 			}
//
// 			callback(null, jsonValue)
// 		}
// 	}),
// 	accountId: primitive(),
// 	accountName: primitive(),
// 	agentId: primitive(),
// 	agentName: primitive(),
// 	assetId: primitive(),
// 	assetName: primitive(),
// 	categoryId: primitive(),
// 	categoryNode: primitive(),
// 	categoryPath: primitive(),
// 	configuration: primitive(),
// 	dataType: primitive(),
// 	instanceName: primitive(),
// 	maxValue: primitive(),
// 	metricId: primitive(),
// 	metricType: primitive(),
// 	monitorType: primitive(),
// 	persist: primitive(),
// 	registryIdentifier: primitive(),
// 	registryOwnerId: primitive(),
// 	registryOwnerName: primitive(),
// 	registryType: primitive(),
// 	time: primitive(),
// 	unitType: primitive(),
// 	unitTypeSymbol: primitive(),
// })
