import {Api} from "tools/api";
import Settings from "../../settings";
import {ApiRequest} from "framework/api";
import {TimePeriod, timePeriodToDates} from "controls/react/form/timePeriodSelector";
import moment from "moment/moment";
import {ApplicationState} from "framework/applicationState";

class ReportsApi {
	getBaseUrl() {
		return `${Api.accountRoot()}reports/`;
	}

	getReportUrl(reportId: string){
		return this.getBaseUrl() + reportId + '/';
	}

	copy(reportId: string, newAccountId: string, newReportName: string){
		return Api.fetch(`${Settings.serverPath}accounts/${newAccountId}/reports/${reportId}/copy?reportName=${newReportName}`);
	}

	copyReports (payload: object) {
		return Api.fetchPost(`${Settings.serverPath}reports/copy`, payload);
	}

	read(reportId: string, update = false){
		return Api.fetch(this.getReportUrl(reportId)+ '?update=' + update)
	}

	deploy(jobId: string, deployObj: object) {
		return Api.fetchPost(`${Api.accountRoot()}reports/jobs/${jobId}/deploy`, deployObj)

	}

	getExistingReportsUrl(accountId: string, includeSubaccounts: boolean) {
		return Api.serverRoot() + 'accounts/' + accountId + '/reports?includeSubaccounts=' + includeSubaccounts;
	}

	getMsTeams(msAuthId: string){
		return Api.fetchPost(`${Api.accountRoot()}reports/msteams`, {
			deliveryType: 'AS_TEAMS_DOCUMENT',
			msAuthId
		})
	}

	getMsDrives(msAuthId: string, msTeamId: string, msDriveItemId: string){
		return Api.fetchPost(`${Api.accountRoot()}reports/msdrives`, {
			deliveryType: 'AS_TEAMS_DOCUMENT',
			msAuthId,
			msTeamId,
			msDriveItemId
		})
	}
}

let api = new ReportsApi();
export default api;

export type ReportEntity = {
	id: string
	name: string
	timeZone: string
}

export type ListReportArgs = {
	accountId?: string
}

export function listReports(args?: ListReportArgs){
	return new ApiRequest<ReportEntity[]>({
		url: 'reports',
		accountBased: true,
		accountId: args?.accountId
	})
}

export type GetReportArgs = {
	accountId?: string
	reportId: string
}

export function getReport(args: GetReportArgs){
	return new ApiRequest<ReportEntity>({
		url: 'reports/' + args.reportId,
		accountId: args.accountId,
		accountBased: true
	})
}

export type StartReportGenerationArgs = {
	accountId?: string
	reportId: string
	timeZone: string
	timePeriod: TimePeriod
}

export type StartReportGenerationResponse = {
	fileName: string
	state: string
	type: string
	accountId: string
	report: null
	reportId: string
	reportName: string
	reportDescription: string
	timeFrom: number
	timeTo: number
	timeCreated: number
	timeReady: number
	referenceCode: string
	id: string
	userName: string
	userId: string
	accountName: string
	errorMessage: string
	reportScheduleId: string
	reportSchedule: null
}

export function startReportGeneration(args: StartReportGenerationArgs){
	const {startDate, endDate} = timePeriodToDates(args.timePeriod)
	return new ApiRequest<StartReportGenerationResponse>({
		url: 'reports/' + args.reportId + '/submitJob',
		method: 'POST',
		accountBased: true,
		accountId: args.accountId,
		payload: {
			timeZone: args.timeZone,
			startDate: startDate.format('YYYYMMDD'),
			endDate: endDate.format('YYYYMMDD'),
			override: false
		}
	})
}

export type DownloadReportArgs = {
	accountId?: string
	jobId: string
}

export function downloadReport(args: DownloadReportArgs){
	return new ApiRequest<Blob>({
		url: 'reports/jobs/' + args.jobId + '/download' + '?filename=report' + moment().format('YYYYDDMM'),
		responseType: 'blob',
		accountBased: true,
		accountId: args.accountId
	})
}

export function getEmbeddedUrl(jobId: string) {
	return `${Settings.serverPath}sessions/${ApplicationState.apiToken}/accounts/${ApplicationState.accountId}/reports/jobs/${jobId}/wait/CeeviewReport?filename=report` + moment().format('YYYYDDMM')
}
