import React from "react";
import {makeAutoObservable} from "mobx";
import {CostBudgetItem} from "../../models/costBudgetItem";
import {flatTree, getPath} from "../../../../tools/tree";
import {MobxManager} from "framework/mobx-integration";

export type ExpandedStateJson = {
	expandedRowKeys: string[]
}

export class ExpandedState {
	expandedRowKeys: React.Key[];
	records: CostBudgetItem[];

	private expandStateSubscriptions: Array<(state: ExpandedState) => void> = [];

	mobx = new MobxManager()

	constructor(records: CostBudgetItem[]) {
		this.records = records;
		this.expandedRowKeys = [];

		makeAutoObservable(this);

		this.mobx.reaction(() => this.expandedRowKeys, this.triggerSubscription)
	}

	get allExpanded() : boolean {
		return this.expandableRecords.length === this.expandedRowKeys.length;
	}

	expandAll() {
		this.expandedRowKeys = this.expandableRecords.map(r => r.uiId);
	}

	collapseAll() {
		this.expandedRowKeys = [];
	}

	rowExpandable(record: CostBudgetItem) {
		return record.items?.length > 0 || record.hasChildren;
	}

	get expandableRecords() {
		return this.allRecords.filter(this.rowExpandable);
	}

	get allRecords() {
		return flatTree(this.records, r => r.items);
	}

	expanded(record: CostBudgetItem) {
		return this.expandedRowKeys.includes(record.uiId);
	}

	toggleRow(record: CostBudgetItem) {
		if (this.expanded(record)) {
			this.expandedRowKeys = this.expandedRowKeys.filter(x => x != record.uiId);
		} else {
			this.addExpandedRowKey(record.uiId);
		}
	}

	toggleExpandAll() {
		if (this.allExpanded) {
			this.collapseAll();
		} else {
			this.expandAll();
		}
	}

	cleanupExpandedRowKeys() {
		const availableIds = this.expandableRecords.map(x => x.uiId);
		this.expandedRowKeys = this.expandedRowKeys.filter(x => availableIds.includes(x as string));
	}

	addExpandedRowKey(uiId: React.Key) {
		if(this.expandedRowKeys.includes(uiId)) return;

		this.expandedRowKeys = [...this.expandedRowKeys, uiId];
	}

	setExpandedRowKeys(keys: React.Key[]) {
		this.expandedRowKeys = [...keys];
	}

	toJSON(): ExpandedStateJson {
		const ids = this.expandedRowKeys.map(uiId => {
			const record = this.allRecords.find(x => x.uiId == uiId);
			return record.id.toString();
		})
		return {
			expandedRowKeys: ids
		}
	}

	fillExpandStateFromJson(state: ExpandedStateJson) {
		if (!state) {
			return;
		}
		const ids = state.expandedRowKeys.map(id => {
			const record = this.allRecords.find(x => x.id == id);
			return record?.uiId?.toString(); //filter old wrong data
		}).filter(x => x);

		this.expandedRowKeys = ids;
	}

	subscribeOnExpandedStateChange(action: (state: ExpandedState) => void) {
		if (!action) {
			return;
		}
		this.expandStateSubscriptions.push(action);
	}

	unsubscribeAll() {
		this.expandStateSubscriptions = [];
	}

	private triggerSubscription = () => {
		this.expandStateSubscriptions.forEach(x => x(this));
	}


	expandToRecord(expandToRecordId: string) {
		const record = this.allRecords.find(x => x.id === expandToRecordId);
		if (!record) {
			return;
		}
		const uiId = record.uiId;
		const path = getPath(this.records, uiId, {idKey: 'uiId', childrenKey: 'items'});
		this.expandedRowKeys = [...this.expandedRowKeys, ...path];
	}

	destroy(){
		this.unsubscribeAll()
		this.mobx.destroy()
	}
}
