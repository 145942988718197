import {sharedLocalization} from "controls/designer/features/widgets/localization";
import React, {useCallback} from "react";
import {Section} from "controls/react/layout/section";
import {FormEntry} from "controls/react/form";
import {AntInput} from "controls/react/ant/antInput";
import AccountDropDown from "controls/react/dropdowns/accountDropDown";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {TagsSelect} from "controls/react/tagsSelect";
import {AssetGroupMultiSelect} from "controls/react/assetGroupMultiSelect";
import {AssetStateSummary} from "areas/assets/widgets/stateWidget/assetStateSummary"
import {AssetStateDashboardWidgetConfig} from "./assetStateDashboardWidgetConfig"
import {AssetStateWidget} from 'areas/views/assetStateWidget'
import {AssetHealthEvent} from "framework/entities/events";
import {apiFetch, ApiRequest} from "framework/api"
import {Severity} from "framework/entities/healthData"
import State from 'tools/state'
import {
	SeverityStateConfigurator,
	StateWidgetProps,
	Subscription,
	WidgetState,
	withDashboardData,
	withDataLoad
} from "../../../../controls/react/barsStatesWidget";
import {ApplicationState} from "../../../../framework/applicationState";
import {AssetsRouter} from "areas/assets/bundleDescription";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: StateWidgetConfiguration,
		defaultConfig: {
			type: 'asset-state',
			title: i('State'),
			accountId: ApplicationState.accountId,
			isDashboard: true,
			assetGroupIds: [] as string[]
		},
		widget: Widget,
		fullTitle: i('Asset') + '/' + i('State')
	}
}

const StateWidgetConfiguration = (props: any) => {
	const {configLink} = props;
	let severities = configLink.get('severities').check(i('Select Severity'), (x: any) => !x || x?.length > 0).value;
	const displayOptions = configLink.get('displayOptions').value;
	const dataSource = [['CRITICAL', i('CRITICAL')], ['MAJOR', i('MAJOR')], ['MINOR', i('MINOR')], ['OK', i('OK')], ['INACTIVE', i('INACTIVE')]];
	severities ??= ['CRITICAL', 'MAJOR', 'MINOR', 'OK', 'INACTIVE'];

	const onSeveritiesChanged = useCallback((value: {displayOptions: {showBucket: boolean, showBar: boolean}, severities: (Severity | string)[]}) => {
		configLink.update((current: {displayOptions: {showBucket: boolean, showBar: boolean}, severities: (Severity | string)[]}) => {
			current.displayOptions = value.displayOptions;
			current.severities = value.severities;
		});
	}, []);

	return <Section appearance={"none"}
	                contentPadding={false}
	                childrenPadding={true}>
		<FormEntry label={i('Title')}>
			<AntInput {...configLink.get('title').props} />
		</FormEntry>
		<FormEntry label={i('Account')}>
			<AccountDropDown {...configLink.get('accountId').props}/>
		</FormEntry>
		<FormEntry>
			<AntCheckbox valueLink={configLink.get('includeSubaccounts')}>{i('Include subaccounts')}</AntCheckbox>
		</FormEntry>
		<Section title={i('Filters')} appearance={"frame"} childrenPadding={true}>
			<FormEntry>
				<AntCheckbox valueLink={configLink.get('showUntagged')}>{i('Show Untagged')}</AntCheckbox>
			</FormEntry>
			<FormEntry label={i('Tags')} vertical={true} width={"fit"}>
				<TagsSelect accountId={configLink.value.accountId}
				            {...configLink.get('tags').props} />
			</FormEntry>
			<FormEntry label={i('Asset Groups')} vertical={true} width={"fit"}>
				<AssetGroupMultiSelect accountId={configLink.value.accountId}
										placeholder={i('Choose an asset group...')}
				                       {...configLink.get('assetGroupIds').props} />
			</FormEntry>
		</Section>
		<Section title={i('Severities')} appearance={"frame"} childrenPadding={true}>
			<SeverityStateConfigurator displayOptions={displayOptions}
									   defaultValue={severities}
									   dataSource={dataSource}
									   onChange={onSeveritiesChanged}/>
		</Section>
	</Section>
}

type GetAssetStateParameters = {
	tags?: string[],
	includeSubaccounts?: boolean,
	accountId?: string
	showUntagged?: boolean
	ignoreUserTags?: boolean,
	assetGroupIds?: string[]
}


export function getAssetState(p: GetAssetStateParameters) {
	return new ApiRequest<AssetStateSummary>({
		url: 'summary/healthAggregate',
		accountBased: true,
		accountId: p.accountId,
		includeSubaccounts: p.includeSubaccounts,
		subaccountsFilter: true,
		method: 'POST',
		payload: {
			tags: p.tags,
			showUntagged: p.showUntagged,
			ignoreUserTags: p.ignoreUserTags === undefined ? true : p.ignoreUserTags,
			assetGroupIds: p.assetGroupIds
		}
	})
}

const WidgetInner = withDashboardData(withDataLoad(AssetStateWidget, config => apiFetch(getAssetState(config))));

class Widget extends React.Component<StateWidgetProps<AssetStateDashboardWidgetConfig, AssetStateSummary>, WidgetState<AssetStateSummary>> {
	subscriptions: Subscription[];

	constructor(props: StateWidgetProps<AssetStateDashboardWidgetConfig, AssetStateSummary>) {
		super(props);
		this.subscriptions = [
			AssetHealthEvent.subscription(this.props.config)
		];
	}

	render() {
		return <WidgetInner {...this.props} type={this.props.config.type} subscriptions={this.subscriptions}
		                    onClick={this.onClick}/>
	}

	onClick = (severity: Severity) => {
		// this.props.navigator.go({
		// 	action: (modifier) => {
		const healthIndexRangeType: string[] = [];
		const healthIndexMandatoryType: string[] = [];
		const addMonitorErrorFilter: boolean = false;

		switch (severity) {
			case Severity.Critical:
				healthIndexRangeType.push('CRITICAL');
				break;
			case Severity.Invalid:
				healthIndexRangeType.push('MAINTENANCE');
				healthIndexMandatoryType.push('IDLE');
				break;
			case Severity.Major:
				healthIndexRangeType.push('MAJOR');
				break;
			case Severity.None:
				healthIndexRangeType.push('OK');
				break;
			case Severity.Minor:
				healthIndexRangeType.push('MINOR');
				break;
		}

		const assetConfig = {
			source: 'buckets',
			healthIndexRangeType: healthIndexRangeType,
			healthIndexMandatoryType: healthIndexMandatoryType,
			includeSubaccounts: this.props.config.includeSubaccounts,
			addMonitorErrorFilter: addMonitorErrorFilter,
			isView: true,
			accountList: [this.props.config.accountId],
			showUntagged: this.props.config.showUntagged,
			assetGroupIds: this.props.config.assetGroupIds,
			filteringTags: this.props.config.tags
		};

		const url = AssetsRouter.assetHealthSummary(assetConfig)
		this.props.navigator.go({url})
	}
}
