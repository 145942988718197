import './gridWidget.less';
import React, { useMemo, useEffect, useRef } from "react";
import {addLinks} from "core/react/links";
import {sharedLocalization as widgetsSharedLocalization} from "controls/designer/features/widgets/localization";
import {ColumnsVisibility} from "controls/designer/features/widgets/columnsVisibility";
import {Section} from "controls/react/layout/section";
import {FormEntry} from "controls/react/form/formEntry";
import {AntInput} from "controls/react/ant/antInput";
import {AccountDropDown} from "controls/react/dropdowns/accountDropDown";
import {FilterResetButton} from "controls/react/form/filterResetButton";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {Cookies} from "core/cookies";
import {BudgetDropDown} from "./budgetDropDown";
import {CurrencyDropDown} from "./currencyDropDown";
import {BudgetItemsSelector} from "./budgetItemsSelector";
import {CostModelDropDown} from "./costModelDropDown";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {sharedLocalization} from "./../cloudServices/shared"
import {AntSelect} from "controls/react/ant/antSelect";
import PropTypes from 'prop-types';
import {debounce} from 'lodash';
import {SortDirection, getSortDirectionDataSource} from 'areas/cost/models/sortDirection';
import {TimeSettings} from './budget/timeSettings';
import {AntRadioGroup, AntRadioButton} from "controls/react/ant/antRadio";
import {AntPopover} from "controls/react/ant/antPopover";
import {CostSelection} from './costSelection';
import {costTranslates} from "../../../../../areas/cost/translations";
import {AntAlert} from "controls/react/ant/antAlert";
import {ToolbarItemPosition} from 'controls/react/layout';
import {CostGrid} from './costGridWidget';
import {ApplicationState} from "framework/applicationState";

const b = require('b_').with('cost-dashboard-grid');

const i = require('core/localization').translator({
  "Cost Grid": {
    "no": "Kosttabell",
    "en": "Cost grid"
  },
  "Show monthly budget": {
    "no": "Vis månedsbudsjett"
  },
  "Show monthly cost": {
    "no": "Vis månedskost"
  },
  "Cost Model Item": {
    "no": "Kostnadelement",
    "en": "Costmodel item"
  },
  "current": {
    "no": "nåværende"
  },
  "Time display": {
    "no": "Tidsvisning"
  },
  "Selection": {
    "no": "Utvalg"
  },
  "Dynamic": {
    "no": "Dynamisk"
  },
  "Static": {
    "no": "Statisk"
  },
  "Dynamic (Recursive)": {
    "no": "Dynamisk (rekursiv)",
    "en": "Dynamic (recursive)"
  },
  "Dynamic allows you to select 1:m groups. The group and content of group will be displayed.": {
    "no": "Dynamisk lar deg velge 1:m grupper. Gruppen og dens innhold vises."
  },
  "Static lets you select cost items from all levels.": {
    "no": "Statisk lar deg velge elementer fra alle nivåer."
  },
  "Dynamic (Recursive) you select 1:m groups. The group and all groups and children are displayed.": {
    "no": "Dynamisk lar deg velge 1:m grupper. Gruppen og dens grupper med innhold vises.",
    "en": "Dynamic (Recursive) you select 1:m groups. The group and all groups and children are displayed."
  },
  "Click here to clear the filter": {
    "no": "Klikk her for å fjerne filteret"
  },
  "Content is filtered": {
    "no": "Innholdet er filtrert"
  },
  "Year estimate": {
    "no": "Årsestimat"
  }
}, sharedLocalization, widgetsSharedLocalization, costTranslates);



export function getWidgetDescription(){
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'cost-grid',
			title: i('Cost Grid'),
			columns: FormColumns,
			accountId: ApplicationState.accountId,
			decimalsNumbers: 0,
			monthOrder: SortDirection.Asc,
			currentMonthFirst: false,
			budgetId: '',
			modelId: '',
			budgetItems: [],
			timeDisplay: 'annual',
			timeDisplayValue: null,
			costSelectionType: CostSelection.Static
		},
		minWidth: 800,
		widget: Widget,
		fullTitle: i('Cost Grid'),
	}
}

export const WidgetConfiguration = props => {
	const { configLink } = props;
	const profileLink = configLink.get('budgetId');
	const accountLink = configLink.get('accountId');
	const modelIdLink = configLink.get('modelId');
	const costSelectionType = configLink.get('costSelectionType');
	const skipInitial = useRef(false);

	accountLink.changed(() => {
			profileLink.update(undefined);
		})
		.required();
	profileLink.changed(() => {
		modelIdLink.update(undefined);
		})
		.required();
	modelIdLink.changed(() => {
			configLink.get('budgetItems').update([]);
		})
		.required();

	configLink.get('budgetItems').required();

	costSelectionType.changed(() => {
		if (![CostSelection.Static, CostSelection.Dynamic, CostSelection.Recursive].includes(costSelectionType.value)) {
			return;
		}
		if (skipInitial.current) {
			skipInitial.current = false;
			return;
		}
		configLink.get('budgetItems').update([]);
	});

	// backward compatibility. if in old widgets was selected something else
	useEffect(() => {
		if (![CostSelection.Static, CostSelection.Dynamic, CostSelection.Recursive].includes(costSelectionType.value)) {
			costSelectionType.update(CostSelection.Static);
			skipInitial.current = true;
		}
	});

	const allColumns = React.useMemo(() => FormColumns, []);

	const orderSelectionValues = useMemo(getSortDirectionDataSource, []);

	return (
		<Section appearance={"none"} contentPadding={false} childrenPadding={true}>
			<Section appearance={"frame"} title={i('Configuration')} childrenPadding={true}>
				<FormEntry label={i('Title')} width={'fit'} valueLink={configLink.get('title')}>
					<AntInput/>
				</FormEntry>
				<FormEntry label={i('Account')} width={'fit'}>
					<AccountDropDown {...accountLink.props}/>
				</FormEntry>
			</Section>

			<Section appearance={"frame"} title={i('Datasource')} childrenPadding={true}>
				<FormEntry label={i('Selection')} width={'fit'} valueLink={costSelectionType}>
					<AntRadioGroup buttonStyle={"solid"}>
						<AntRadioButton value={CostSelection.Static}>
							<AntPopover content={<Section>{i('Static lets you select cost items from all levels.')}</Section>}>
								{i('Static')}
							</AntPopover>
						</AntRadioButton>
						<AntRadioButton value={CostSelection.Dynamic}>
							<AntPopover content={<Section>{i('Dynamic allows you to select 1:m groups. The group and content of group will be displayed.')}</Section>}>
								{i('Dynamic')}
							</AntPopover>
						</AntRadioButton>
						<AntRadioButton value={CostSelection.Recursive}>
							<AntPopover content={<Section>{i('Dynamic (Recursive) you select 1:m groups. The group and all groups and children are displayed.')}</Section>}>
								{i('Dynamic (Recursive)')}
							</AntPopover>
						</AntRadioButton>
					</AntRadioGroup>
				</FormEntry>
				<FormEntry label={i('Cost Model')} width={'fit'} valueLink={profileLink}>
					<BudgetDropDown accountId={accountLink.value}/>
				</FormEntry>
				<FormEntry label={i('Cost Sheet')} width={'fit'} valueLink={modelIdLink}>
					<CostModelDropDown accountId={accountLink.value}
					                   profileId={profileLink.value}/>
				</FormEntry>
				<FormEntry label={i('Cost Model Item')} width={'fit'} valueLink={configLink.get('budgetItems')}>
					<BudgetItemsSelector accountId={accountLink.value}
					                     profileId={profileLink.value}
					                     modelId={modelIdLink.value}
					                     showResources={costSelectionType.value === CostSelection.Static}/>
				</FormEntry>
			</Section>
			<TimeSettings configLink = {configLink}
				accountId={accountLink.value}
            	profileId={profileLink.value}
            	modelId={modelIdLink.value}>
				<FormEntry label={i('Month Order')} width={'fit'} valueLink={configLink.get('monthOrder')}>
					<AntSelect dataList={orderSelectionValues} defaultValue={SortDirection.Asc}/>
				</FormEntry>
				<FormEntry width={'fit'}>
					<AntCheckbox valueLink={configLink.get('currentMonthFirst')}>{i('Current month first')}</AntCheckbox>
				</FormEntry>
			</TimeSettings>

			<Section appearance={"frame"} title={i('Display settings')} childrenPadding={true}>
				<FormEntry label={i('Currency')} width={'fit'} valueLink={configLink.get('currency')}>
					<CurrencyDropDown accountId={configLink.get('accountId').value}/>
				</FormEntry>
				<FormEntry label={i('Decimal Numbers')} width={'fit'}>
					<AntInput {...configLink.get('decimalsNumbers').props}/>
				</FormEntry>
				<FormEntry width={'fit'}>
					<AntCheckbox valueLink={configLink.get('displayAsThousands')}>{i('Display as 1000')}</AntCheckbox>
				</FormEntry>
			</Section>
			<ColumnsVisibility
				columnsLink={configLink.get('columns')}
				allColumns={allColumns}
			/>
		</Section>
	)
}

WidgetConfiguration.propTypes = {
	configLink: PropTypes.object
};

export const FormColumns = [{
	field: 'name',
	title: i('Name'),
	shown: true
}, {
	field: 'split',
	title: i('Split'),
	shown: true
}, {
	field: 'budget',
	title: i('Budget'),
	shown: true
}, {
	field: 'cost',
	title: i('Cost'),
	shown: true
}, {
	field: 'periodEstimate',
	title: i('Year Estimate'),
	shown: true
}, {
	field: 'currentEstimate',
	title: i('Current month estimate'),
	shown: true
}, {
	field: 'listingPrice',
	title: i('Listing price'),
	shown: true
}];

const monthColumns = ['budget', 'cost', 'listingPrice'];

@addLinks
class Widget extends React.PureComponent {
	static get propTypes() {
		return {
			persistedState: PropTypes.object,
			config: PropTypes.object
		};
	}

	constructor(props) {
		super(props);

		this.state = {
			searchValue: '',
			filtered: false,
			gridStore: undefined
		}
	}

	onSearchChanged = (value) => {
		this.setState({
			searchValue: value
		});
		this.startSearch();
	}

	startSearch = debounce(() => {
		this.state.gridStore.state.searchString = this.state.searchValue;
	}, 300)

	render() {
		let toolbarAtTheEnd = [
			<AntInput key={1} value={this.state.searchValue} onChange={this.onSearchChanged} placeholder={i('Search...')}/>,
			<FilterResetButton key={2} onReset={() => this.onFilterReset()}></FilterResetButton>
		];
		if (this.state.filtered) {
			toolbarAtTheEnd = [
				<AntAlert type={"info"}
						key={0}
						position={ToolbarItemPosition.AFTER_TITLE}
						onClick={this.onFilterReset}
						message={<div title={i('Click here to clear the filter')}>{i('Content is filtered')}</div>}
						className={b('alert')}
						showIcon={true}/>,
				...toolbarAtTheEnd];
		}

		return (
			<DefaultWidgetWrapper {...this.props} toolbarAtTheEnd={toolbarAtTheEnd} containerClass={b()}>
				<Section contentPadding={false} height={"fit"} overlaySpinner={this.state.loading}>
					<CostGrid config={this.props.config} persistedState={this.props.persistedState} onFilter={this.onFilter} navigator={this.props.navigator} />
				</Section>
			</DefaultWidgetWrapper>
		)
	}

	onFilter = (gridStore) => {
		this.setState({
			gridStore,
			filtered: gridStore?.filtered,
			searchValue: gridStore?.state?.searchString ?? ''
		});
	}

	onFilterReset = () => {
		this.setState({
			searchFilter: {},
			gridSort: [],
			searchValue: '',
			filtered: false
		});
		this.state.gridStore.clearFilters();
	}

	getStateForSaving() {
		return {
			grid: this.state.gridStore.state
		};
	}
}
