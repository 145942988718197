import 'controls/designer/features/widgets/custom/timeWidget.less'

import React from 'react';

import {Renderer} from "tools/renderer";
import {LocalEventsManager} from "core/localEventsManager";
import {StaticVars} from "core/staticVars";
import Lang from 'core/localization/lang';
import {Cookies} from "core/cookies";
import {Section} from "controls/react/layout/section";
import {FormEntry, TextBox} from "controls/react/form";
import {DateFormatDropDown, TimeFormatDropDown, TimeZonesDropDown} from "controls/react";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: TimeWidgetConfiguration,
		defaultConfig: {
			type: 'time',
			title: i('Time widget'),
			timezone: ApplicationState.timezone,
			dateformat: ApplicationState.dateFormat,
			timeformat: ApplicationState.timeFormat
		},
		widget: TimeWidget,
		fullTitle: i('Custom') + '/' + i('Time'),
	}
}

const TimeWidgetConfiguration = props => {
	const {configLink} = props;

	return <Section appearance={"none"}
	                contentPadding={false}
	                childrenPadding={true}>
		<FormEntry label={i('Title')}>
			<TextBox {...configLink.get('title').props}/>
		</FormEntry>
		<FormEntry label={i('Timezone')}>
			<TimeZonesDropDown {...configLink.get('timezone').props}/>
		</FormEntry>
		<FormEntry label={Lang.serviceBoard.DATEFORMAT}>
			<DateFormatDropDown {...configLink.get('dateformat').props}/>
		</FormEntry>
		<FormEntry label={Lang.serviceBoard.TIMEFORMAT}>
			<TimeFormatDropDown {...configLink.get('timeformat').props}/>
		</FormEntry>
	</Section>

}

const b = require('b_').with('time-widget');

class TimeWidget extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.getValues(), this.getBoardUpdateValue());

		LocalEventsManager.bind('boardUpdate', this.onBoardUpdated);
	}

	render() {
		const {classes} = this.props;

		let showWidgetHeader = this.props.dashboardSettings.showWidgetHeader
			|| !this.props.designer.config.chromeless;
		let isReadOnly = this.props.dashboardSettings.readOnly;

		return (
			<DefaultWidgetWrapper {...this.props}>
				<Section appearance={"none"}
				         direction={"column"}
				         contentClass={b()}>
					<div className={b('time')}>{this.state.time}</div>
					<div className={b('date')}>{this.state.date}</div>
					<div className={b('timezone')}>{this.state.timezone}</div>
					<div className={b('last-update')}>
						{Lang.serviceBoard.LAST_UPDATE_SERVICE_BOARD}<br/>
						{this.state.lastUpdate}
					</div>
				</Section>
			</DefaultWidgetWrapper>
		)
	}

	getValues() {
		const now = Date.now();

		const {timeformat, dateformat, timezone} = this.props.config;

		return {
			time: Renderer.browserDateRenderer(now, StaticVars.TIME, timeformat, timezone),
			date: Renderer.browserDateRenderer(now, StaticVars.DATE, dateformat, timezone),
			timezone
		}
	}

	getBoardUpdateValue() {
		const now = Date.now();

		const {timeformat, dateformat, timezone} = this.props.config;

		return {
			lastUpdate: Renderer.browserDateRenderer(now, StaticVars.TIME, timeformat, timezone) +
			' ' + Renderer.browserDateRenderer(now, StaticVars.DATE, dateformat, timezone)
		};
	}

	onBoardUpdated = () => {
		this.setState(this.getBoardUpdateValue());
	}

	update() {
		this.setState(this.getValues());
	}

	componentDidMount() {
		const update = this.update.bind(this);
		this.interval = setInterval(update, 1000);
	}

	componentWillUnmount() {
		LocalEventsManager.unbind('boardUpdate', this.onBoardUpdated);
		clearInterval(this.interval);
	}
}

