import './aiQueryWidget.less'

import React from 'react'
import {observer} from "mobx-react"
import {FileTextOutlined, MessageOutlined} from '@ant-design/icons'
import {sharedLocalization} from "controls/designer/features/widgets/localization"
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {AntButton} from "controls/react/ant/antButton";
import {
	AiQueryWidgetConfig,
	AiWidgetDisplayMode
} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetConfig";
import {
	AiQueryWidgetPersistedState
} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetPersistedState";
import {
	AiQueryWidgetConfiguration
} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetConfiguration";
import {AiQueryWidgetStore} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetStore";


const i18n = require('core/localization').translator({
	'Click icon to open AI query prompt': {
		no: 'Klikk ikon for å åpne AI spørring'
	},
	'Open chat': {}
},
sharedLocalization);

export function getWidgetDescription(){
	return {
		form: AiQueryWidgetConfiguration,
		defaultConfig: {
			type: 'ai-query',
			title: i18n('AI Query'),
			dataSources: [] as any[],
			displayMode: AiWidgetDisplayMode.Text,
			showChatIcon: true
		},

		widget: AiQueryWidget,
		fullTitle: i18n('AI Query'),
		containerStyle: (config: AiQueryWidgetConfig) => {
			if (config.displayMode == AiWidgetDisplayMode.Icon) {
				return {
					noBorder: true,
					transparent: true,
					header: 'on-hover'
				}
			}
			return {}
		}
	}
}

const b = require('b_').with('ai-query-widget');

type AiQueryWidgetOutputProps = {
	store: AiQueryWidgetStore
}

export const AiQueryWidgetOutput = observer((props: AiQueryWidgetOutputProps) => {
	return <div className={b("query-output")}>
		{props.store.config.showChatIcon &&
			<AntButton icon={<MessageOutlined />}
		           className={b('open-chat-button')}
		           title={i18n('Open chat')}
		           onClick={props.store.openChat}
		           type={"primary"} />
		}
		<div className={b("output-container")}
	            dangerouslySetInnerHTML={{__html: props.store.config.output}}></div>
	</div>
})

export const AiQueryWidget = observer(
	class InnerWidget extends React.Component<WidgetProps<AiQueryWidgetConfig, AiQueryWidgetPersistedState>> {
		private store: AiQueryWidgetStore

		constructor(props: WidgetProps<AiQueryWidgetConfig, AiQueryWidgetPersistedState>) {
			super(props)

			this.store = new AiQueryWidgetStore(props.config, props.designer.store, props.cell)
		}

		render() {
			const config = this.props.config
			if(config.displayMode == AiWidgetDisplayMode.Text){
				return <DefaultWidgetWrapper contentPadding={true} {...this.props}>
					<AiQueryWidgetOutput store={this.store}/>
				</DefaultWidgetWrapper>
			}

			return <DefaultWidgetWrapper appearance={"none"}
			                             {...this.props}
			                             showHeader={false}>
				<AntButton shape={"circle"}
				           title={i18n('Click icon to open AI query prompt')}
				           onClick={this.store.showOutput}
				           icon={<FileTextOutlined />} type={'primary'}/>

			</DefaultWidgetWrapper>
		}

		componentWillUnmount() {
			this.store.destroy()
		}

		// getStateForSaving() {
		// 	return {
		// 		dataSources: this.store.dataSources,
		// 		query: this.store.query
		// 	};
		// }
	})


