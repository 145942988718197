import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import MultiToggle from 'controls/multiToggle';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import aggregationTypeDataSource from './common/dataSources/aggregationTypeDataSource';


export function MetricsWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {MetricsWidgetConfiguration as default};

jQuery.extend(MetricsWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		//backward compatibility
		var widgetObj = this.widgetObj;
		var html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div>';
		html += '<div class="cw_field" id="cw_metrics_service">';
		html += '<label class="cw_inline">' + lang.widget.SERVICE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_sid" type="text" value="' + (widgetObj.configuration.serviceId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_se">';
		html += '<label class="cw_inline">' + lang.designer.SERVICE_ELEMENT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_seid" type="text" value="' + (widgetObj.configuration.serviceElementId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_sq">';
		html += '<label class="cw_inline">' + lang.designer.SERVICE_QUALIFIER + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_sqid" type="text" value="' + (widgetObj.configuration.serviceQualifierId || '') + '" /></div>';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIOD + ': </label>';
		html += '<div id="cw_metrics_period_control" class="cw_multi_toggle right"></div>';
		html += '<div class="cw_hide_time_selector"><input type="checkbox" class="cw_include k-checkbox" id="cw_hide_time_selector"' + (widgetObj.configuration.hideTimeSelector ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_hide_time_selector" class="k-checkbox-label">' + lang.widget.HIDE_TIME_SELECTOR + '</label></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_chart_type_container">';
		html += '<label class="cw_inline">' + lang.TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_chart_type" type="text" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.AGGREGATION_TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_aggregation_type" type="text" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field" style="width:50%">';
		html += '<input type="checkbox" class="cw_include k-checkbox" id="cw_ignore_missing_data" title="' + lang.serviceBoard.messages.MISSING_DATA_MESSAGE + '"></span>';
		html += '<label for="cw_ignore_missing_data" class="k-checkbox-label">' + lang.serviceBoard.IGNORE_MISSING_DATA + '</label>';
		html += '</div>';
		html += '<div class="cw_field" style="width:50%">';
		html += '<input type="checkbox" class="cw_show_threshold k-checkbox" id="cw_show_threshold" title="" checked></span>';
		html += '<label for="cw_show_threshold" class="k-checkbox-label">' + lang.SHOW_THRESHOLD + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="cw_show_regression k-checkbox" id="cw_show_regression" title=""></span>';
		html += '<label for="cw_show_regression" class="k-checkbox-label">' + lang.SHOW_REGRESSION + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_timezone" type="text" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '"/></div>';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">Selector type:</label>';
		html += '<ul id="cw_legend_type_toggle" class="cw_multi_toggle right"></ul>';
		html += '</div>';
		html += '<div id="cw_legend_container" class="cw_field hide">';
		html += '<span class="cw_inline_full">' + lang.LEGEND + ': </span>';
		html += '<input id="cw_metrics_legend" type="text" />';
		html += '</div>';
		html += '<div id="cw_informational_container" class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.INFORMATIONAL + ': </span>';
		html += '<input id="cw_metrics_informational" type="text" />';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(
			$(html).addClass('cw_field service-boards-metricsWidgetConfiguration')
		);
		this.addTimeSelector(widgetObj.configuration, document.getElementById("cw_metrics_period_control"));

		this.legendTypeToggle = new MultiToggle({
			id: 'cw_legend_type_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'line',
			type: 'label',
			items: [{
				id: 'cw_legend_type_legend',
				title: lang.LEGEND,
				label: lang.LEGEND,
				selected: false,
				value: 'legend',
				fn: this.onLegendTypeToggle
			}, {
				id: 'cw_legend_type_informational',
				title: lang.INFORMATIONAL,
				label: lang.INFORMATIONAL,
				selected: true,
				value: 'informational',
				fn: this.onLegendTypeToggle
			}]
		});

		this.legendDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<Service>',
			id: '<Service>'
		}, {
			name: '<Serviceelement>',
			id: '<Serviceelement>'
		}, {
			name: '<Servicequalifier>',
			id: '<Servicequalifier>'
		}];

		this.informationalDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<Service>',
			id: '<Service>'
		}, {
			name: '<Serviceelement>',
			id: '<Serviceelement>'
		}, {
			name: '<Servicequalifier>',
			id: '<Servicequalifier>'
		},{
			name: '<Period>',
			id: '<Period>'
		},{
			name: '<Timezone>',
			id: '<Timezone>'
		}];

		if (this.wasUpdate) {
			this.legendTypeToggle.setSelectedItem(widgetObj.configuration.legendType || 'informational');
			if (widgetObj.configuration.legendType === 'legend') {
				$('#cw_legend_container').removeClass('hide');
				$('#cw_informational_container').addClass('hide');
				if (!widgetObj.configuration.labelTemplate) {
					this.legendMultiList = this.legendDataSource;
				} else {
					this.legendMultiList = widgetObj.configuration.labelTemplate;
				}
				this.informationalMultiList = this.informationalDataSource;
			} else {
				if (!widgetObj.configuration.informationalTemplate) {
					this.informationalMultiList = this.informationalDataSource;
				} else {
					this.informationalMultiList = widgetObj.configuration.informationalTemplate;
				}
				this.legendMultiList = this.legendDataSource;
			}
			$('#cw_hide_time_selector').prop('checked', widgetObj.configuration.hideTimeSelector);
			$('#cw_ignore_missing_data').prop('checked', widgetObj.configuration.ignoreMissingData);
			$('#cw_show_regression').prop('checked', widgetObj.configuration.showRegression);
			$('#cw_show_threshold').prop('checked', widgetObj.configuration.showThreshold);
		}

		this.renderLegendMultiSelect();
		this.renderInformationalMultiSelect();

		this.initKendoComponents();

		const requiredFields = ['#cw_metrics_accountid', '#cw_metrics_sid', '#cw_metrics_seid', '#cw_metrics_sqid'];
		applyRequired(requiredFields);

		if (widgetObj.configuration.period === 'CUSTOM') {
			$('#cw_metrics_dates').removeClass('hide');
			$('#cw_metrics_startdate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.startDate));
			$('#cw_metrics_enddate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.endDate));
		}

	},
	renderLegendMultiSelect: function() {
		ReactDOM.render(<FormEntry label={lang.LEGEND} vertical={true}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_metrics_legend').parent().get()[0]);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	renderInformationalMultiSelect: function() {
		ReactDOM.render(<FormEntry label={lang.INFORMATIONAL} vertical={true}>
			<AntSelect
				dataList={this.informationalDataSource}
				mode={"multiple"}
				sortValues={true}
				onChange={value => this.onInformationalChange(value)}
				defaultValue={this.informationalMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>, $('#cw_metrics_informational').parent().get()[0]);
	},
	onInformationalChange: function(value) {
		this.informationalMultiList = value || [];
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_metrics_accountid'),
			container: $('#cw_metrics_account'),
			nextElement: $('#cw_metrics_sid'),
			otherElements: [$('#cw_metrics_seid'), $('#cw_metrics_sqid')],
			events: {
				change: (e) => {
					this.isInputChanged = true;
					this.currentAccountId = e.sender.value();
					this.serviceSelector.dataSource.read();
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_metrics_se'),
							label: lang.designer.SERVICE_ELEMENT
						}, {
							container: $('#cw_metrics_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: e.sender.dataSource.data().length
					});
				},
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_metrics_se'),
							label: lang.designer.SERVICE_ELEMENT
						}, {
							container: $('#cw_metrics_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: length
					});
				}, this)
			},
		});

		this.chartType = $('#cw_chart_type').kendoDropDownList({
			dataSource: [{
				text: lang.AREA_CHART,
				value: 'area'
			}, {
				text: lang.AREASPLINE_CHART,
				value: 'areaspline'
			}, {
				text: lang.LINE_CHART,
				value: 'line'
			}, {
				text: lang.RANGE_CHART,
				value: 'arearange'
			}, {
				text: lang.SPLINE_CHART,
				value: 'spline'
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.chartType || 'line'
		}).data('kendoDropDownList');

		this.aggregationType = $('#cw_aggregation_type').kendoDropDownList({
			dataSource: aggregationTypeDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.aggregationType || 'high'
		}).data('kendoDropDownList');

		this.serviceSelector = $('#cw_metrics_sid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_se'),
							label: lang.designer.SERVICE_ELEMENT
						}, {
							container: $('#cw_metrics_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/services/lite?hasModel=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(this.onMetricsServiceModelSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
				this.isInputChanged = true;
				this.emptyComponent({
					containers: [{
						container: $('#cw_metrics_se'),
						label: lang.designer.SERVICE_ELEMENT
					}, {
						container: $('#cw_metrics_sq'),
						label: lang.designer.SERVICE_QUALIFIER
					}],
					length: e.sender.dataSource.data().length
				});
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isAccountChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isAccountChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		if (widgetObj.configuration.serviceId) {
			var seDataSource = new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + (this.currentServiceId || this.serviceId || widgetObj.configuration.serviceId) + '/elements/nonAggregatedOnly/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			});
		}

		$('#cw_metrics_seid').kendoComboBox({
			dataSource: seDataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(this.onMetricsServiceElementSelect, this),
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			template: '<span id="${ data.id }">${ data.name }</span>',
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
				this.isInputChanged = true;
				this.emptyComponent({
					containers: [{
						container: $('#cw_metrics_sq'),
						label: lang.designer.SERVICE_QUALIFIER
					}],
					length: e.sender.dataSource.data().length
				});
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isServiceModelChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isServiceModelChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		if (widgetObj.configuration.serviceId) {
			if (widgetObj.configuration.serviceId && widgetObj.configuration.serviceElementId) {
				var sqDataSource = new kendo.ceeview.DataSource({
					transport: {
						read: {
							url: Settings.serverPath + 'services/' + (this.currentServiceId || widgetObj.configuration.serviceId) + '/elements/' + (this.currentServiceElementId || widgetObj.configuration.serviceElementId) + '/qualifiers/?update=false',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
			}
		}

		$('#cw_metrics_sqid').kendoComboBox({
			dataSource: sqDataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			placeholder: lang.SELECT,
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isServiceElementChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isServiceElementChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');


		$("#cw_metrics_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_metrics_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$('#cw_metrics_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			placeholder: lang.SELECT,
			clearButton: false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: function (e) {
				this.errorFields = Utils.comboBoxValueCheck(e);
			}
		});
	},
	/**
	 * Handler function for the click event on qualifier type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onPeriodToggle: function (value) {
		if (value === 'CUSTOM') {
			$('#cw_metrics_dates').removeClass('hide');
		} else {
			$('#cw_metrics_dates').addClass('hide');
		}
	},
	/**
	 * Handler function for the click event on legend type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onLegendTypeToggle: function (value) {
		if (value === 'legend') {
			$('#cw_legend_container').removeClass('hide');
			$('#cw_informational_container').addClass('hide');
		} else {
			$('#cw_legend_container').addClass('hide');
			$('#cw_informational_container').removeClass('hide');
		}
	},
	/**
	 * Handler function for the select event of the metrics service dropdown list
	 * @param {Object} e The select event
	 */
	onMetricsServiceModelSelect: function (e) {
		if (e.item && e.item.hasClass('k-hover')) {
			var serviceElementCombo = $("#cw_metrics_seid").data('kendoComboBox');
			var serviceQualifierCombo = $("#cw_metrics_sqid").data('kendoComboBox');
			var smId = e.item.find('.k-list-item-text span').attr('id');
			if (smId) {
				this.isServiceModelChanged = true;
				this.isInputChanged = true;
				serviceElementCombo.enable(true);
				serviceElementCombo.value('');
				var newSeDataSource = new kendo.ceeview.DataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_metrics_sq'),
								label: lang.designer.SERVICE_QUALIFIER
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'services/' + smId + '/elements/nonAggregatedOnly/lite',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				serviceElementCombo.setDataSource(newSeDataSource);
				serviceQualifierCombo.value('');
				serviceQualifierCombo.enable(false);
			} else {
				serviceElementCombo.value('');
				serviceElementCombo.enable(false);
			}
		}
	},
	/**
	 * Handler function for the select event of the metrics service element dropdown list
	 * @param {Object} e The select event
	 */
	onMetricsServiceElementSelect: function (e) {
		if (e.item && e.item.hasClass('k-hover')) {
			var serviceQualifierCombo = $("#cw_metrics_sqid").data('kendoComboBox');
			var smId = $("#cw_metrics_sid").data('kendoComboBox').value();
			var seId = e.item.find('.k-list-item-text span').attr('id');
			if (seId) {
				this.isServiceElementChanged = true;
				this.isInputChanged = true;
				serviceQualifierCombo.enable(true);
				serviceQualifierCombo.value('');
				var newSqDataSource = new kendo.ceeview.DataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_metrics_sq'),
								label: lang.designer.SERVICE_QUALIFIER
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'services/' + smId + '/elements/' + seId + '/qualifiers/lite?update=false',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				serviceQualifierCombo.setDataSource(newSqDataSource);
			} else {
				serviceQualifierCombo.value('');
				serviceQualifierCombo.enable(false);
			}
		}
	},
	findRecord: function (dataSource, id) {
		var record = null;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var r = dataSource.at(i);
			if (r.id === id) {
				record = r;
				break;
			}
		}
		return record;
	},
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var ignoreMissingData = $('#cw_ignore_missing_data').is(':checked'),
			showRegression = $('#cw_show_regression').is(':checked'),
			showThreshold = $('#cw_show_threshold').is(':checked'),
			timezone = $('#cw_metrics_timezone').data('kendoComboBox').value(),
			chartType = this.chartType.value(),
			aggregationType = this.aggregationType.value(),
			legendType = this.legendTypeToggle.getValue();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'metrics',
			title: title,
			configuration: {
				accountId: $('#cw_metrics_accountid').data('kendoComboBox').value(),
				...this.periodToggle,
				ignoreMissingData: ignoreMissingData,
				showRegression: showRegression,
				showThreshold: showThreshold,
				chartType: chartType,
				aggregationType: aggregationType,
				timezone: timezone || Cookies.CeesoftTimezone,
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				labelTemplate: this.legendMultiList,
				legendType: legendType,
				informationalTemplate: this.informationalMultiList
			}
		};

		var serviceCombo = $('#cw_metrics_sid').data('kendoComboBox');
		var serviceId = serviceCombo.value();
		var serviceName = serviceCombo.text();
		var serviceElementId = $('#cw_metrics_seid').data('kendoComboBox').value();
		var serviceQualifierId = $('#cw_metrics_sqid').data('kendoComboBox').value();
		var modelId = serviceCombo.dataSource.get(serviceId).modelId;
		var config = {
			serviceId: serviceId,
			modelId: modelId,
			serviceName: serviceName,
			serviceElementId: serviceElementId,
			serviceQualifierId: serviceQualifierId
		};
		jQuery.extend(widgetObj.configuration, config);
		return widgetObj;
	},
	isValid: function () {
		var valid = true;
		var validationMessage = '';
		var accountCombo = $('#cw_metrics_accountid').data('kendoComboBox');
		var sCombo = $('#cw_metrics_sid').data('kendoComboBox');
		var seCombo = $('#cw_metrics_seid').data('kendoComboBox');
		var sqCombo = $('#cw_metrics_sqid').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(sqCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_QUALIFIER;
		}
		if (!Utils.isGuid(seCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_SERVICE_ELEMENT;
		}
		if (!Utils.isGuid(sCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_SERVICE_MODEL;
		}
		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}
		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
