import Utils from 'tools/utils';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import Highcharts from 'highcharts';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import Configuration from 'configuration';
import ErrorCodes from 'tools/errorCodes';
import State from 'tools/state';
import {ManagementApi} from "areas/management/api";
import {extractQualifierSubscriptionFields} from '../widgets/customMetrics/metricSelector'
import {AssetsRouter} from "../../assets/bundleDescription";
import {apiFetch} from "framework/api";
import {searchMetrics} from "areas/metrics/api";
import {CssVariables} from "styles/cssVariables";

export default class GenericGaugeWidget extends Widget {
	constructor(config) {
		super(config);
		this.requestPath = Settings.serverPath;
		if (this.sessionId) {
			this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
		}
		this.initComponent();
	}
	/**
	 * Main init function
	 */
	async initComponent() {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		this.gaugeType = this.instanceConfiguration.gaugeType || 'gauge';
		this.useDynamicMax = false;
		this.errorCode = 'OK';
		this.value = 0;
		this.breachValue = this.instanceConfiguration.breachValue || 80;
		this.inverted = this.instanceConfiguration.inverted;
		this.minValue = this.instanceConfiguration.minValue || 0;
		if (this.minValue === 'null') {
			this.minValue = 0;
		}

		this.hasInfoSign = true;

		this.customControls = {
			target: '#' + this.id,
			toggleClick: function (value) {
				if ($('#' + this.id).find('.k-i-toggle').length) {
					$('#' + this.id).find('.k-i-toggle').trigger('click');
				} else {
					$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
				}
			}
		};
		this.createCustomControls();

		if (this.instanceConfiguration.maxValue === undefined || this.instanceConfiguration.maxValue === null) {
			this.useDynamicMax = true;
			this.maxValue = this.breachValue + 20 * this.breachValue / 100;
			this.instanceConfiguration.maxValue = this.maxValue;
		} else {
			this.maxValue = this.instanceConfiguration.maxValue;
		}

		this.gaugeNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: widgetContentDiv,
		});

		await this.getGaugeData();

		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));

		let title = lang.GAUGE + '/' + this.qualifier.categoryNode;
		this.setTitleIfEmpty(title);
	}

	showErrorMessage(message, status = 'error') {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		kendo.ui.progress(widgetContentDiv, false);
		this.gaugeNotification.setOptions({
			message: message,
			status: status
		}).show();
	}

	updateValue(value) {
		if (!this.gauge) {
			return;
		}

		this.gauge.series[0].points[0].update(value);
	}

	async getGaugeData() {


		let payload = [{
			metricId: this.instanceConfiguration.metricId
		}] || [];
		if (payload.length) {
			if (this.instanceConfiguration.advanced) {
				this.decimals = this.instanceConfiguration.decimals;
				if (this.instanceConfiguration.unit === 'CUSTOM') {
					payload[0].conversion = this.instanceConfiguration.formula;
					payload[0].customUnit = this.instanceConfiguration.unitLabel;
				} else {
					payload[0].conversionUnit = this.instanceConfiguration.unit;
				}
			}
		}
		if (this.instanceConfiguration.type === 'MONITOR') {
			payload = [{metricId: this.instanceConfiguration.qualifier.id}];
		}

		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		kendo.ui.progress(widgetContentDiv, true);


		let result = await ManagementApi.getQualifiersLastValue(payload);
			const isEmptyResult = !result || !result.length;

		if (isEmptyResult) {
			return this.showErrorMessage(result.message || lang.NO_DATA);
		}
		this.subscribe(result[0].qualifier);
		const {found, hasMetrics, data, qualifier} = result[0];
		const gaugeData = data && data[0];
		this.qualifier = qualifier;
		const noMetricOrData = !found || !hasMetrics || !gaugeData;

		if (noMetricOrData) {
			return this.showErrorMessage(result.message || lang.serviceBoard.messages.NO_DATA);
		}

		if (gaugeData.v) {
			this.value = gaugeData.v;
		}
		this.interval = gaugeData.interval;


		//if duration to last info is bigger then 3 times the interval show "no data"
		const now = Date.now();
		const isNoData = this.interval > -1 && now - 3 * this.interval * 1000 > gaugeData.t0;

		this.lastCheckTime = gaugeData.t;

		this.fullName = this.generateMultigraphLabel(qualifier);
		this.name = this.fullName.length > 60 ? this.fullName.substr(0, 57) + '...' : this.fullName;

		if(!isNoData){
			this.renderChart(this.gaugeType, {
				metric: {
					v: this.value,
					e: gaugeData.e,
					t: gaugeData.t
				}
			});
		}


		if (isNoData) {
			this.value = 0;
			this.updateValue(0);
			return this.showErrorMessage(lang.NO_DATA);
		}
	}
	/**
	 * Calls the appropiate method to render the chart based on its type
	 * @param {type} type
	 * @param {type} data
	 */
	renderChart(type, data, force = false) {
		if (!$('#' + this.id).length) {
			// we have no container yet
			return;
		}
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		if (this.gaugeNotification) {
			this.gaugeNotification.hide();
		}

		kendo.ui.progress(widgetContentDiv, false);
		const skipRender = !!this.gauge && !force;
		if (skipRender) {
			this.updateValue(this.value);
		}
		else if (type === 'gauge') {
			this.renderGauge(data);
		} else {
			this.renderSolidGauge(data);
		}

		this.updateChartTitle();

		this.dataSet = data;
		this.updateErrorCode();
	}

	getUnit() {
		let unitType;

		if (this.instanceConfiguration.type === 'MONITOR') {
			unitType = this.instanceConfiguration.qualifier.unitTypeSymbol;
		} else {
			if (this.instanceConfiguration.unit === 'CUSTOM') {
				unitType = this.qualifier.customUnit;
			} else {
				unitType = this.qualifier.unitTypeSymbol;
			}
		}
		return unitType;
	}

	getTitle() {
		let value = Utils.truncateDecimals(this.value, this.decimals);
		if (this.maxValue && this.value > this.maxValue) {
			return 'Over max: ' + value + (this.qualifier ? this.getUnit() : '');
		} else if (this.minValue && this.value < this.minValue) {
			return 'Below min: ' + value + (this.qualifier ? this.getUnit() : '');
		}
		else {
			return null;
		}
	}

	getLegendConfig() {
		return {
			title: {
				text: '',
				align: 'center'
			},
			x: 2,
			y: 20,
			floating: false,
			borderWidth: 0,
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'bottom',
			useHTML: true,
			itemDistance: 15,
			itemStyle: {
				fontSize: "10px"
			},
			style: {
				fontSize: "10px"
			},
			labelFormatter: function () {
				return '<span title="' + this.options.fullName + '">' + this.name + '</span>';
			}
		}
	}

	getTooltipConfig() {
		const oThis = this;

		return {
			backgroundColor: 'white',
			borderWidth: 0,
			shadow:{
				offsetX: 0,
				offsetY: 0,
				opacity: 1,
				width: 16,
				color: 'rgb(0,0,0,0.01)'
			},
			useHTML: true,
			formatter: function () {
				var s = Renderer.browserDateRenderer(oThis.eventTime, Constants.DATETIME);
				s += '<br />';
				if (oThis.errorCode === 'OK') {
					let value = Utils.truncateDecimals(oThis.value, oThis.decimals);
					s += lang.VALUE + ': <strong>' + value + ' ' + oThis.getUnit() + '</strong>';
				} else {
					s += oThis.errorCode;
					this.point.color = '#FF0000';
				}
				s += '<br />';
				s += lang.widget.BREACH_VALUE + ': <strong>' + oThis.breachValue + ' ' + oThis.getUnit() + '</strong>';
				s += '<br />';
				s += lang.widget.MAX_VALUE + ': <strong>' + oThis.maxValue + ' ' + oThis.getUnit() + '</strong>';
				return s;
			}
		}
	}

	getChartConfig(type, height, div) {
		return {
			type,
			animation: true,
			height: height,
			renderTo: div,
			plotBackgroundColor: null,
			plotBackgroundImage: null,
			plotBorderWidth: 0,
			plotShadow: false,
			backgroundColor: 'transparent'
		}
	}

	getSolidGaugeYAxis() {
		const min = this.minValue || 0;
		const max = this.maxValue;
		var stop1 = this.breachValue / this.maxValue;
		var stop2 = stop1 + 0.00001;

		return {
			min: min,
			max: max,
			tickPositions: [min, max],
			stops: [
				[stop1, this.inverted ? CssVariables.severityCritical : CssVariables.severityNone],
				[stop2, this.inverted ? CssVariables.severityNone : CssVariables.severityCritical]
			],
			labels: {
				y: 20
			},
			minorTickInterval: 0,
			tickInterval: max,
			tickWidth: 0,
			gridLineWidth: 0,
			gridLineColor: 'transparent',
			plotBands: [{
				from: min,
				to: this.breachValue,
				color: this.inverted ? CssVariables.severityCritical : CssVariables.severityNone,
				outerRadius: '107%',
				thickness: '3%'
			},
				{
					from: this.breachValue,
					to: max,
					color: this.inverted ? CssVariables.severityNone : CssVariables.severityCritical,
					outerRadius: '107%',
					thickness: '3%'
				}]
		}
	}

	updateChartTitle() {
		const title = this.getTitle();

		if (title) {
			this.showErrorMessage(title, 'info');
		}
	}

	updateErrorCode() {
		if (this.dataSet?.metric.e[0] && this.dataSet.metric.e[0] !== 0) {
			this.errorCode = this.getErrorCode(this.dataSet.metric.e[0]);
			this.showErrorMessage(this.errorCode);

			if (this.errorCode !== 'OK') {
				this.gauge.userOptions.series[0].dataLabels.borderColor = '#FF0000';
				$('.highcharts-data-label-box').css('stroke', '#FF0000');
			}
		}
	}

	/**
	 * Renders the solid gauge
	 * @param {Object} data The chart data
	 */
	renderSolidGauge(data) {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		widgetContentDiv.empty().append('<div class="cw_gauge"></div><div class="cw_widget_status hide"></div>');

		this.eventTime = data?.metric.t;

		const unit = this.getUnit();
		const oThis = this;
		const series = [
			{
				name: this.name,
				fullName: this.fullName,
				showInLegend: !!this.name,
				data: [this.value],
				dataLabels: {
					formatter: function () {
						var color = this.point.y === 0 ? '#FF0000' : '#333';
						return '<div style="text-align:center"><span style="font-size:20px;color:' + color + '">' + Utils.truncateDecimals(this.point.y, oThis.decimals) + ' ' + unit + '</span>';
					}
				},
				innerRadius: '70%'
			}
		]

		if (this.gauge) {
			this.gauge.destroy();
		}

		let container = widgetContentDiv.find('.cw_gauge');
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
		} else {
			height = container.closest('.cw_section_content').height();
		}

		this.gauge = new Highcharts.Chart({
			chart: this.getChartConfig(
				'solidgauge',
				height,
				widgetContentDiv.find('.cw_gauge')[0]
			),
			exporting: Configuration.highcharts.exporting,
			credits: {
				enabled: false
			},
			pane: {
				center: ['50%', '70%'],
				size: '100%',
				startAngle: -90,
				endAngle: 90,
				background: {
					backgroundColor: '#eee',
					innerRadius: '70%',
					outerRadius: '100%',
					shape: 'arc'
				}
			},
			legend: this.getLegendConfig(),
			plotOptions: {
				series: {
					marker: {
						enabled: false
					},
					events: {
						legendItemClick: $.proxy(function (e) {
							this.navigator.go({
								url: AssetsRouter.details(this.qualifier.assetId)
							});
						}, this)
					}
				},
				solidgauge: {
					dataLabels: {
						enabled: true,
						y: -20,
						borderWidth: 0,
						useHTML: true,
						formatter: () => {
							let value = Utils.truncateDecimals(this.value, this.decimals);
							return value + ' ' + unit;
						}
					}
				},
				borderColor: '#cacaca'
			},
			series: series,
			title: {
				text: '',
				y: 50
			},
			yAxis: this.getSolidGaugeYAxis(),
			tooltip: this.getTooltipConfig(),
			loading: false
		});
	}
	/**
	 * Renders the standard gauge
	 * @param {Object} data The chart's data
	 */
	renderGauge(data) {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content'), renderedValue, title = ' ';

		widgetContentDiv.empty().append('<div class="cw_gauge"></div><div class="cw_widget_status hide"></div>');
		this.eventTime = data?.metric.t;

		if (this.maxValue && this.value > this.maxValue) {
			renderedValue = this.maxValue;
		} else if (this.minValue && this.value < this.minValue) {
			renderedValue = this.minValue;
		} else {
			renderedValue = this.value;
		}

		const unit = this.getUnit();

		const series = [
			{
				name: this.name,
				fullName: this.fullName,
				showInLegend: !!this.name,
				data: [renderedValue],
				dataLabels: {
					borderColor: '#cacaca'
				}
			}
		]

		if (this.gauge) {
			this.gauge.destroy();
		}

		let container = widgetContentDiv.find('.cw_gauge');
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
		} else {
			height = container.closest('.cw_section_content').height();
		}

		this.gauge = new Highcharts.Chart({
			chart: this.getChartConfig(
				'gauge',
				height,
				widgetContentDiv.find('.cw_gauge')[0]
			),
			exporting: Configuration.highcharts.exporting,
			credits: {
				enabled: false
			},
			title: {
				text: ' ',
				style: {
					color: '#FF0000'
				}
			},
			legend: this.getLegendConfig(),
			plotOptions: {
				series: {
					marker: {
						enabled: false
					},
					events: {
						legendItemClick:() => {
							this.navigator.go({
								url: AssetsRouter.details(this.qualifier.assetId)
							});
						}
					}
				},
				gauge: {
					animation: true,
					dataLabels: {
						enabled: true,
						formatter: () => {
							let value = Utils.truncateDecimals(this.value, this.decimals);
							return value + ' ' + unit;
						}
					}
				}
			},
			pane: {
				startAngle: -150,
				endAngle: 150,
				background: [{
					backgroundColor: {
						linearGradient: {
							x1: 0,
							y1: 0,
							x2: 0,
							y2: 1
						},
						stops: [[0, '#FFF'], [1, '#333']]
					},
					borderWidth: 0,
					outerRadius: '109%'
				}, {
					backgroundColor: {
						linearGradient: {
							x1: 0,
							y1: 0,
							x2: 0,
							y2: 1
						},
						stops: [[0, '#333'], [1, '#FFF']]
					},
					borderWidth: 1,
					outerRadius: '107%'
				}, {// default background
				}, {
					backgroundColor: '#DDD',
					borderWidth: 0,
					outerRadius: '105%',
					innerRadius: '103%'
				}]
			},
			// the value axis
			yAxis: {
				min: this.minValue,
				max: this.maxValue,
				minorTickInterval: 'auto',
				minorTickWidth: 1,
				minorTickLength: 10,
				minorTickPosition: 'inside',
				minorTickColor: '#666',
				tickPixelInterval: 30,
				tickWidth: 2,
				tickPosition: 'inside',
				tickLength: 10,
				tickColor: '#666',
				labels: {
					step: 2,
					rotation: 'auto'
				},
				title: {
					text: ' '
				},
				plotBands: [{
					from: this.minValue,
					to: this.breachValue,
					color: this.inverted ? CssVariables.severityCritical : CssVariables.severityNone
				}, {
					from: this.breachValue,
					to: this.maxValue,
					color: this.inverted ? CssVariables.severityNone : CssVariables.severityCritical
				}]
			},
			series: series,
			tooltip: this.getTooltipConfig()
		});
	}
	/**
	 * Search the data for a timestamp point and returns its error code
	 * @param {Number} code
	 * @return {String} errorCode
	 */
	getErrorCode (code) {
		var errorCode = ErrorCodes.get(code).text;
		return errorCode;
	}
	/**
	 * Called when a metric event is received
	 * @param {Object} data The event data object
	 */
	onEvent (data) {
		var length = data.length, event;
		if (length) {
			event = data[length - 1];
		} else {
			event = data;
		}
		if (this.gauge) {
			this.value = event.metric.v;
			this.eventTime = event?.metric.t;
			this.renderChart(this.gaugeType, event);
		}
	}
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize (event, ui) {
		this.renderChart(this.gaugeType, this.dataSet, true);
		setTimeout(() => {
			this.createCustomControls();
		});
	}
	/**
	 * Handler function for the drop event
	 */
	onDrop () {
		this.renderChart(this.gaugeType, this.dataSet, true);
	}
	/**
	 * Subscribes to qualifier emtrics events
	 */
	subscribe (qualifier) {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'Metric',
			releaseEvents: true,
			qualifierId: this.instanceConfiguration.metricId || this.instanceConfiguration.qualifier.id,
			...extractQualifierSubscriptionFields(qualifier)
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	}
	/**
	 * Destroy
	 */
	destroy () {
		RemoteEventsManager.unsubscribe(this.subscriberId);
		$(window).off('resize', $.proxy(this.onResize, this));
		Widget.prototype.destroy.call(this);
	}
}
