import {makeAutoObservable, toJS, trace} from "mobx"
import _, {debounce} from "lodash"

import {executeUpdate, iterateEdges} from "controls/designer/utils"
import {DesignerStore} from "controls/designer/designerStore"
import {
	isMxGeometryChange,
	isMxTerminalChange,
	MxCell,
	MxEvent,
	MxEventObject,
	MxGraph,
	MxGraphModel
} from "../mxGraphInterfaces"
import {CellDataSourceType} from "controls/designer/dataSourcesManager/cellDataSourceType";
import {CellServiceDataSource} from "controls/designer/dataSourcesManager/serviceDataSourceEditor";
import {CellKpiDatasource} from "controls/designer/dataSourcesManager/kpiDataSourceEditor";
import {CellSlaDataSource} from "controls/designer/dataSourcesManager/slaDataSourceEditor";
import {CellAssetDataSource} from "controls/designer/dataSourcesManager/assetDataSourceEditor";
import {CellAssetGroupDataSource} from "controls/designer/dataSourcesManager/assetGroupDataSourceEditor";
import {CellMetricDatasource} from "controls/designer/dataSourcesManager/metricDataSourceEditor";
import {CellCostDataSource} from "controls/designer/dataSourcesManager/costDataSourceEditor";
import {CellDataSource} from "controls/designer/dataSourcesManager/cellDataSource";
import {MobxManager} from "framework/mobx-integration";
import {DataSourceElement} from "controls/designer/dataSourcesManager/dataSourceElement";
import {apiFetch} from "framework/api";
import {getHealthInfo} from "controls/designer/api";
import {RemoteEventsManager} from "core/remoteEventsManager";
import {ServiceDataSourceElement} from "controls/designer/dataSourcesManager/serviceDataSourceElement";
import {Event} from "framework/entities/events";
import {CustomDataEntry} from "controls/designer/customDataEntry"
import {destroyDataSourceWindow} from "controls/designer/dataSourcesManager/dataSourceWindow";


export type DataSourceMapEntry = {
	disposer?: () => void,
	element?: DataSourceElement,
	dataSource: CellDataSource
	cell: MxCell
	temporary?: boolean
}

export class DataSourcesManager {
	dataSourcesMap: DataSourceMapEntry[] = []

	mobx = new MobxManager()
	constructor(public store: DesignerStore) {
		makeAutoObservable(this)
	}

	get validDataSources(){
		return this.dataSourcesMap
			.filter(x => x.element)
	}

	async init() {
		this.addActions()

		this.store.legacyDesigner.registerForCleanUp(this.cleanUp);
		this.store.graph.getModel().addListener(MxEvent.EXECUTE, this.processExecuteEvent)
		this.store.graph.addListener(MxEvent.CELL_STATE_COLOR_CHANGE, this.onCellStateColorChanged)
		this.store.legacyDesigner.ui.addListener('styleChanged', this.stylesChanged)

		this.upgradeVersions()

		this.store.customData.forEach(this.checkForDataSource)

		await this.updateHealthInfo()

		if(!this.eventsSubscription && this.store.config.chromeless) {
			this.eventsSubscription = RemoteEventsManager.subscribeCallback(this.getSubscriptions(), this.consumeEvents);
		}
	}

	checkForDataSource = (entry: CustomDataEntry) => {
		if(!entry.dataSource)
			return

		convertDataSourceFromJsonToClassInstance(entry)

		this.dataSourcesMap.push({
			dataSource: entry.dataSource,
			cell: entry.cell
		})

		let mapEntry = this.dataSourcesMap[this.dataSourcesMap.length - 1]

		let reaction = debounce(async () => {
			mapEntry.element?.destroy?.()
			mapEntry.element = null

			if (mapEntry.dataSource.valid()) {
				mapEntry.element = mapEntry.dataSource.attachToCell(this.store.legacyDesigner, mapEntry.cell)
				await this.updateHealthInfo([mapEntry])
			}
		}, 500)

		mapEntry.disposer = this.mobx.reaction(() => toJS(entry.dataSource), reaction )

		if (mapEntry.dataSource.valid()) {
			mapEntry.element = mapEntry.dataSource.attachToCell(this.store.legacyDesigner, mapEntry.cell)
		}

		return mapEntry
	}

	findDataSourceForCell(cell: MxCell){
		return this.validDataSources
			.find(x => x.element.cell == cell)
	}

	get = (cell: MxCell) => {
		return this.getMapEntry(cell)?.dataSource
	}

	getMapEntry = (cell: MxCell) => {
		if (cell == null)
			return null

		return this.dataSourcesMap.find(x => x.cell == cell)
	}

	get selected(){
		return this.get(this.store.cell)
	}

	get selectedDataSourceType(){
		return this.selected?.type ?? CellDataSourceType.None
	}

	changeDataSourceType = (type: CellDataSourceType) => {
		if (this.store.cell == null)
			return

		destroyDataSourceWindow()

		this.deleteElement(this.store.cell)

		let customData = this.store.getOrCreateCustomData(this.store.cell)

		if(type != CellDataSourceType.None) {
			customData.dataSource = getDefaultDataSource(type)
			customData.dataSource.id = customData.id
		}else{
			customData.dataSource = null
		}

		this.checkForDataSource(customData)
	}

	cleanUp = () => {
		executeUpdate(this.store.graph, () => {
			Object.values(this.dataSourcesMap).forEach((e) => {
				e.element?.cleanUp();
			});
		})
	}

	onCustomDataAdded = async (customDataList: CustomDataEntry[]) => {
		let mapEntries = customDataList
			.map(x => this.checkForDataSource(x))
			.filter(x => x != null && x.element != null)

		if(mapEntries.length > 0) {
			await this.updateHealthInfo(mapEntries)
		}
	}

	onCustomDataRemoved = (customData: CustomDataEntry) => {
		this.deleteElement(customData.cell)
	}

	async addDataSource(dataSource: CellDataSource, cell: MxCell, temporary: boolean = false){
		const customData = this.store.getOrCreateCustomData(cell);
		customData.dataSource = dataSource
		customData.temporary = temporary
		await this.onCustomDataAdded([customData])
	}

	processExecuteEvent = async (graphModel: MxGraphModel, ev: MxEventObject) => {
		const change = ev.properties.change
		if(isMxGeometryChange(change)){
			this.cellMoved(change.cell);

			iterateEdges(change.cell, (edge: MxCell) => {
				//at the current stage the state of the edge cell is not updated yet
				//so if we call it right now the values will be old ones
				//We can substribe to the Notify event instead and thats where the values will be up to date
				//BUT at the same time if we perform changes the graph would not be updated an this time
				//So the only option I found is to save that edge is moved and trigger the moved event later when graph finishes
				setTimeout(() => {
					this.cellMoved(edge)
				}, 0)
			})
		}else if(isMxTerminalChange(change)){
			setTimeout(() => {
				this.cellMoved(change.cell)
			}, 0)
		}
	}

	cellMoved(cell: MxCell){
		let mapEntry = this.findDataSourceForCell(cell)
		mapEntry?.element?.cellMoved();
	}

	deleteElement(cell: MxCell) {
		let mapEntry = this.dataSourcesMap.find(x => x.cell == cell)
		if (mapEntry == null)
			return

		mapEntry.element?.destroy?.()
		mapEntry.disposer?.()

		let index = this.dataSourcesMap.findIndex(x => x.cell == cell)
		this.dataSourcesMap.splice(index, 1)
	}

	onCellStateColorChanged = (graph: MxGraph, e: MxEventObject) => {
		let mapEntry = this.findDataSourceForCell(e.properties.cell);
		mapEntry?.element?.refreshStateColor();
	}

	stylesChanged = (ui: any, e: MxEventObject) => {
		this.dataSourcesMap.forEach( mapEntry => {
			let element = mapEntry.element
			if(!element)
				return;
			if (e.properties.cells.findIndex(x => x == element.cell) == -1)
				return;

			executeUpdate(this.store.graph, () => {
				element.stylesChanged(e);
			});
		})
	}

	getSubscriptions() {
		let entitiesForSubscriptions: any = {
			services: [],
			agents: [],
			slas: [],
			assets: [],
			links: [],
			assetGroups: [],
			metrics: [],
			kpis: [],
			costs: []
		}

		let subscriptions: Record<string, any>[] = []

		this.validDataSources.forEach( (mapEntry) => {
			const e = mapEntry.element
			if(e.getSubscriptions) {
				let newEntities = e.getSubscriptions();
				for (let key in newEntities) {
					//@ts-ignore
					entitiesForSubscriptions[key] = entitiesForSubscriptions[key].concat(newEntities[key]);
				}
			}
			if(e.getSubscriptionsDirectly) {
				subscriptions = subscriptions.concat(e.getSubscriptionsDirectly());
			}

		});

		if( entitiesForSubscriptions.services.length != 0) {
			subscriptions.push({
				eventType: 'ServiceStatus',
				serviceIds: _.uniq(entitiesForSubscriptions.services),
				//reasons: ["MODEL_CHANGE", "ELEMENT_CHANGE", "QUALIFIER_CHANGE"]
			});
		}

		if(entitiesForSubscriptions.links.length != 0 || entitiesForSubscriptions.services.length != 0){
			subscriptions.push({
				eventType: 'ServiceSummary',
				serviceIds: _.uniq([...entitiesForSubscriptions.links, ...entitiesForSubscriptions.services])
			});
		}

		for(let slaId of _.uniq(entitiesForSubscriptions.slas)){
			subscriptions.push({
				eventType: 'Sla',
				slaId: slaId,
			});
		}

		if(entitiesForSubscriptions.kpis.length){
			subscriptions.push({
				eventType: 'Kpi',
				filters: entitiesForSubscriptions.kpis
			});
		}

		for(let metricObj of _.uniq(entitiesForSubscriptions.metrics)){
			//@ts-ignore
			const subscription = {
				eventType: 'Metric',
				//@ts-ignore
				qualifierId: metricObj.metricId,
				releaseEvents: true,
				//@ts-ignore
				unitType: metricObj.unitType,
				//@ts-ignore
				showTrend: metricObj.showTrend,
				//@ts-ignore
				timePeriod: metricObj.timePeriod
			};
			subscriptions.push(subscription);
		}


		for(let assetId of _.uniq(entitiesForSubscriptions.assets)){
			subscriptions.push({
				eventType: 'AssetHealth',
				assetId: assetId,
			});
		}

		if(entitiesForSubscriptions.assetGroups.length != 0) {
			subscriptions.push({
				eventType: 'AssetGroupHealth',
				assetGroupIds: _.uniq(entitiesForSubscriptions.assetGroups),
			});

			subscriptions.push({
				eventType: 'Administration',
				entityIds: _.uniq(entitiesForSubscriptions.assetGroups),
				actionTypes: [
					'ASSET_GROUP_UPDATE',
					'ASSET_GROUP_DELETE',
					'ASSET_GROUP_MEMBER_CREATE',
					'ASSET_GROUP_MEMBER_DELETE',
					'ASSET_GROUP_MEMBER_UPDATE'
				]
			});
		}

		if( entitiesForSubscriptions.agents.length != 0) {
			subscriptions.push({
				eventType: 'AgentState',
				agentIds: _.uniq(entitiesForSubscriptions.agents)
			});
		}

		return subscriptions;
	}

	consumeEvents = async (event: Event) => {
		let elementsToRedraw = [];
		let elementsToReload = [];
		for (const mapEntry of this.validDataSources) {
			let result = mapEntry.element.consumeEvent(event);

			if (result.reload)
				elementsToReload.push(mapEntry);
			else if (result.redraw)
				elementsToRedraw.push(mapEntry);
		}

		if (elementsToRedraw.length > 0) {
			if (this.store.config.mode == 'service') {
				await this.updateStates(this.dataSourcesMap)
			} else {
				await this.updateStates(elementsToRedraw);
			}
		}

		if (elementsToReload.length > 0) {
			await this.updateHealthInfo(elementsToReload);
		}

		if (this.store.config.features.presentationMode) {
			if (elementsToRedraw.some(x => x instanceof ServiceDataSourceElement)) {
				this.store.graph.refresh();
			}
		}
	}

	getTooltipForCell(cell: MxCell) {
		let mapEntry = this.findDataSourceForCell(cell);
		if(mapEntry == null && cell.parent != null && cell.parent.id > 2){
			cell = cell.parent
			mapEntry = this.findDataSourceForCell(cell)
		}

		let promise;
		if (mapEntry) {
			promise = mapEntry.element.getTooltip();
		}else{
			promise = Promise.resolve( "");
		}

		return promise;
	}

	getLabelForCell(cell: MxCell){
		let mapEntry = this.findDataSourceForCell(cell)
		if(mapEntry?.element) {
			return mapEntry.element.getLabel()
		}
	}

	eventsSubscription: {
		unsubscribe: () => void
	}

	async updateHealthInfo(entries?: DataSourceMapEntry[] ) {
		if(entries == null){
			entries = this.validDataSources
		}

		if(entries.length == 0)
			return

		const entriesToLoad = entries.reduce((result, mapEntry) => {
			const newEntries = mapEntry.element.getEntriesToLoad()
			newEntries.forEach(x => x.id = mapEntry.element.guid)
			return result.concat(newEntries)
		}, []);

		if (entriesToLoad.length) {
			const result = await apiFetch(getHealthInfo(entriesToLoad));
			if (result.success) {
				for(const entry of entries){
					if(!entry.element)
						continue

					let entriesForTheCell = result.data.filter(x => x.id == entry.element.guid);
					entry.element.onHealthInfoLoaded(entriesForTheCell);
				}
			}
		}

		this.updateStates(entries);
	}

	updateStates(mapEntries: DataSourceMapEntry[]) {
		if (!mapEntries.length)
			return;

		executeUpdate(this.store.graph, () => {
			for (const mapEntry of mapEntries) {
				try {
					if(!mapEntry.element)
						continue

					if (mapEntry.element.destroyed)
						continue;

					mapEntry.element.cleanUp();
					mapEntry.element.updateState();
				} catch (e) {
					console.error(e)
				}
			}
		})
	}

	addActions(){

	}

	upgradeVersions() {
		this.store.customData.forEach(x => {
			if (x.dataSource != null) {
				const ds = x.dataSource as any
				switch (ds.type) {
					case CellDataSourceType.Metric:

						//changing UI for CV-15217, 2.18 release in summer of 2024
						if (ds.metricId) {
							ds.metric = {
								metricId: ds.metricId,
								decimals: ds.decimalsNumber ?? 2
							}
						}

						if (ds.totalMetricId && ds.totalMetricType == 'metric') {
							ds.secondMetric = {
								metricId: ds.totalMetricId,
								decimals: ds.decimalsNumber ?? 2
							}
						}


						if (ds.displayUnitType == 'NONE' || ds.displayUnitType == 'CUSTOM_UNIT') {
							if (ds.metric) {
								ds.metric.unit = 'CUSTOM'
								ds.metric.unitLabel = ds.displayUnitType == 'CUSTOM_UNIT' ? ds.customUnit : ''
							}

							if (ds.secondMetric) {
								ds.secondMetric.unit = 'CUSTOM'
								ds.secondMetric.unitLabel = ds.displayUnitType == 'CUSTOM_UNIT' ? ds.customUnit : ''
							}
						}else {
							if (ds.metric && ds.displayUnitType != null) {
								ds.metric.unit = 'AUTOSCALING'
							}

							if (ds.secondMetric && ds.displayUnitType != null) {
								ds.secondMetric.unit = 'AUTOSCALING'
							}
						}

						delete ds.metricId
						delete ds.totalMetricId
						delete ds.displayUnitType
						delete ds.customUnit
						delete ds.decimalsNumber
					break
				}
			}
		})
	}

	destroy(){
		this.dataSourcesMap.forEach(x => {
			x.element?.destroy?.()
			x.disposer?.()
		})
		this.mobx.destroy()
	}
}

export function convertDataSourceFromJsonToClassInstance(customData: CustomDataEntry)
{
	let dataSourceClassInstance = getDefaultDataSource(customData.dataSource.type)
	Object.assign(dataSourceClassInstance, customData.dataSource)
	customData.dataSource = dataSourceClassInstance
}

export const getDefaultDataSource = (type: CellDataSourceType) => {
	switch (type){
		case CellDataSourceType.Asset:
			return new CellAssetDataSource()

		case CellDataSourceType.AssetGroup:
			return new CellAssetGroupDataSource()

		case CellDataSourceType.Sla:
			return new CellSlaDataSource()

		case CellDataSourceType.Metric:
			return new CellMetricDatasource()

		case CellDataSourceType.Kpi:
			return new CellKpiDatasource()

		case CellDataSourceType.Cost:
			return new CellCostDataSource()

		case CellDataSourceType.Service:
		default:
			return new CellServiceDataSource()
	}
}
