import './iconButton.less';

import React, {memo} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';


let b = require('b_').with('icon-button');

export class IconButton extends React.PureComponent {
	render() {
		const props = this.props;
		let {
			containerClass,
			title,
			iconsSet,
			iconName,
			embedded,
			onClick,
			onMouseEnter,
			onMouseLeave,
			size,
			style
		} = props
		iconsSet = iconsSet || 'glyphicons'

		const classes = classnames(
			b({embedded, disabled: props.disabled, clickable: onClick != null}),
			//{'k-button k-button-icon': onClick != null && !skipBorder},
			iconsSet == 'material' ? 'material-icons' : 'glyphicons',
			iconName,
			containerClass
		)

		style = style ?? {}
		if (size != null) {
			style.fontSize = size + 'px'
		}

		if (iconsSet == 'material') {
			return <i onClick={!props.disabled ? onClick : null}
			          style={style}
			          onMouseEnter={!props.disabled && onMouseEnter ? onMouseEnter : null}
			          onMouseLeave={!props.disabled && onMouseLeave ? onMouseLeave : null}
			          title={title}
			          className={classes}>
				{iconName}
			</i>
		}

		return (
			<div className={classes}
			     style={style}
			     onClick={!props.disabled ? onClick : null}
			     onMouseEnter={!props.disabled && onMouseEnter ? onMouseEnter : null}
			     onMouseLeave={!props.disabled && onMouseLeave ? onMouseLeave : null}
			     title={title}/>
		)
	}
}

export default IconButton;

IconButton.propTypes = {
	iconsSet: PropTypes.string,
	title: PropTypes.string,
	iconName: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	size: PropTypes.number,
	embedded: PropTypes.bool,
	containerClass: PropTypes.string,
	disabled: PropTypes.bool,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func
}
