import './eventsSummary.less'

import React from 'react'
import ReactDOM from 'react-dom'
import {makeAutoObservable, makeObservable, observable} from "mobx"
import {serialize} from "serializr"
import {Observer} from "mobx-react"

import {
	acknowledge,
	assign,
	deleteEvents,
	exportToCsv,
	GetEventsSummary,
	getEventsSummary,
	getRecipients,
	groupRecipients,
	resetHealthIndex,
	serviceCommand,
	setInformation,
	unacknowledge,
	unassign
} from "areas/summary/events/api"
import {AssigneeType, EntryType, EventsSummaryEntry} from "areas/summary/events/eventsSummaryEntry"
import {GridStore} from "controls/grid/gridStore"
import {GridColumnConfig} from "controls/grid/gridColumnConfig"
import {SeverityIndicator} from "controls/react/severityIndicator"
import {
	CheckOutlined, DeleteOutlined, TeamOutlined, UserOutlined,
	FormOutlined, CaretRightOutlined, StopOutlined, FilterOutlined,
	ExportOutlined, UndoOutlined, ExclamationCircleOutlined, FileTextOutlined,
	VerticalLeftOutlined, RollbackOutlined, RetweetOutlined
} from '@ant-design/icons'
import {AgentSecurity, AgentsRouter} from "areas/management/agents/bundleDescription"
import {dateToString} from "tools/dateTimeUtils"
import {Link} from "controls/react/link"
import {AssetsRouter} from "areas/assets/bundleDescription"
import {ServicesRouter} from "areas/services/bundleDescription"
import {SlaRouter} from "areas/sla/bundleDescription"
import {CostsRouter} from 'areas/cost/bundleDescription'
import {MonitorsRouter} from "areas/assets/monitors/bundleDescription"
import {ApplicationState} from "framework/applicationState"
import {Permission} from "framework/permission"
import {apiFetch, serverRoot} from "framework/api"
import {AutoReloadPlugin} from "controls/grid/plugins/autoReloadPlugin";
import {EventSummaryEvent} from "framework/entities/events";
import {createContainer, ModalPosition, openModal} from "controls/react/ant/antModal";
import {AntSelect} from "controls/react/ant/antSelect";
import {linkModel, MobxManager} from "framework/mobx-integration";
import {Section} from "controls/react/layout/section";
import FormEntry from 'controls/react/form/formEntry'
import {AntTextArea} from "controls/react/ant/antInput";
import {createLogEntry} from "areas/services/api";
import {SetInformationWindow} from "controls/setInformationWindow";
import {ResetHealthIndexWindow} from "controls/resetHealthIndexWindow";
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import {DataListEntry} from 'framework/entities/dataList'
import {NavigationStore} from "framework/navigationStore";
import {healthDataToFlags} from 'framework/entities/healthData'
import {showActionsViewer} from "areas/views/actionsViewer";
import {GroupConjunction, RuleDefinition} from "controls/queryBuilder/ruleDefinition";
import {performAction} from "controls/react/ant/antMessage";
import {CeeviewNavigator} from "tools/ceeviewNavigator";
import {GridSelection} from "controls/grid/gridSelection";
import {GridMenuPlugin} from "controls/grid/plugins/grid-menu/gridMenuPlugin";
import {GridDataItem} from "controls/grid/gridDataItem";
import {TimePeriodType} from "controls/react/form/timePeriodType";


const i18n = require('core/localization/localization').translator({
  "User: {0}": {
    "no": "Bruker: {0}"
  },
  "Team: {0}": {
    "no": "Team: {0}"
  },
  "Create Incident": {
    "no": "Lag sak",
    "en": "Create incident"
  },
  "Reset Health Index": {
    "no": "Tilbakestill helseindeks",
    "en": "Reset healthindex"
  },
  "Create Filter": {
    "no": "Opprett filter",
    "en": "Create filter"
  },
  "Start Service": {
    "no": "Start tjeneste",
    "en": "Start service"
  },
  "Stop Service": {
    "no": "Stopp tjeneste",
    "en": "Stop service"
  },
  "Add Log Entry": {
    "no": "Legg til loggmelding",
    "en": "Add logentry"
  },
  "Export to CSV": {
    "no": "Eksporter til CSV"
  },
  "Set Information": {
    "no": "Sett informasjon",
    "en": "Set information"
  },
  "Select user/team to assign event to": {
    "no": "Velg bruker/team som skal tildeles hendelsen"
  },
  "Assign to": {
    "no": "Tildel til"
  },
  "Create log entry": {
    "no": "Opprett loggmelding"
  },
  "Events are paused when historic mode is active": {
    "no": "Hendelser er pauset når historiske hendelser er aktiv"
  },
  "Event Details": {
    "no": "Hendelse detaljer",
    "en": "Event details"
  }
});

export const b = require('b_').with('event-summary')

export type EventsSummaryStoreProps = GetEventsSummary & {
	onFiltersCleared?: (store: GridStore<EventsSummaryEntry>) => void
}

export abstract class EventsSummaryStore{
	gridStore: GridStore<EventsSummaryEntry>

	mobx = new MobxManager()
	columns: GridColumnConfig<EventsSummaryEntry>[]

	showDetailsModal: boolean

	protected constructor() {
		makeObservable(this, {
			gridStore: observable,
			showDetailsModal: observable
		})

		this.mobx.when(() => this.gridStore?.initialized,() => {
			this.mobx.reaction(() => this.gridStore.state.customPayload.showHistoricEvents, (showHistoric) => {
				let autoReloadPlugin = this.gridStore.plugins.find(x => x.id == 'auto-reload') as AutoReloadPlugin<EventsSummaryEntry>
				if(autoReloadPlugin){
					autoReloadPlugin.disabled = !!showHistoric
					autoReloadPlugin.disabledReason = i18n('Events are paused when historic mode is active')
				}
			}, {
				fireImmediately: true
			})
		})
		this.showDetailsModal = false;
	}

	getCustomPayloadDefaults(timePeriod: TimePeriodType = TimePeriodType.Last24Hours){
		return {
			timePeriod: {
				period: timePeriod
			},
			showHistoricEvents: false
		}
	}

	getDefaultDatasource(props: EventsSummaryStoreProps){
		return getEventsSummary(props)
	}

	getGridMenuPlugin(){
		return new GridMenuPlugin<EventsSummaryEntry>({
			actions: [{
				id: 'createIncident',
				icon: <ExclamationCircleOutlined />,
				label: i18n('Create Incident'),
				visible: () => ApplicationState.hasPermissions(Permission.IncidentCreate),
				action: this.createIncident,
				enabled: (selection) => isAReasonSelected(selection)
			}, {
				id: 'ack',
				icon: <CheckOutlined />,
				label: i18n('Acknowledge'),
				action: this.acknowledge,
				visible: () => ApplicationState.hasPermissions(Permission.EventsUpdate),
				enabled: (selection) => selection.some( x => !x.data.acknowledged)
			}, {
				id: 'unack',
				icon: <UndoOutlined />,
				label: i18n('Unacknowledge'),
				action: this.unacknowledge,
				enabled: (selection) => selection.some( x => x.data.acknowledged)
			}, {
				icon: <RetweetOutlined />,
				id: 'resetHI',
				label: i18n('Reset Health Index'),
				action: this.resetHealthIndex,
				visible: () => ApplicationState.hasPermissions(Permission.MonitorUpdate)
			}, {
				id: 'createFilter',
				icon: <FilterOutlined />,
				label: i18n('Create Filter'),
				action: this.createFilter,
				enabled: (selection) => justOneHealthReasonSelected(selection),
				visible: () => ApplicationState.hasPermissions(Permission.FilterCreate)
			}, {
				id: 'startService',
				icon: <CaretRightOutlined/>,
				label: i18n('Start Service'),
				action: this.startService,
				visible: (selection) => ApplicationState.hasPermissions(Permission.MonitorUpdate)
					&& isAReasonSelected(selection)
			}, {
				id: 'stopService',
				icon: <StopOutlined/>,
				label: i18n('Stop Service'),
				action: this.stopService,
				visible: (selection) => ApplicationState.hasPermissions(Permission.MonitorUpdate)
					&& isAReasonSelected(selection)
			}, {
				id: 'addLogEntry',
				icon: <FileTextOutlined />,
				label: i18n('Add Log Entry'),
				action: this.addLogEntry,
				enabled: (selection) => justOneServiceEntrySelected(selection) != null,
				visible: () => ApplicationState.hasPermissions(Permission.ServiceUpdate)
			},{
				icon: <RollbackOutlined />,
				id: 'unassign',
				label: i18n('Unassign'),
				action: this.unassign,
				enabled: (selection) => selection.isAnythingSelected,
				visible: () => ApplicationState.hasPermissions(Permission.EventsUpdate)
			}, {
				icon: <VerticalLeftOutlined />,
				id: 'assign',
				label: i18n('Assign'),
				action: this.assign,
				enabled: (selection) => selection.isAnythingSelected,
				visible: () => ApplicationState.hasPermissions(Permission.EventsUpdate)
			}, {
				id: 'exportToCsv',
				icon: <ExportOutlined />,
				label: i18n('Export to CSV'),
				action: this.exportToCsv
			}, {
				id: 'delete',
				icon: <DeleteOutlined />,
				label: i18n('Delete'),
				action: this.deleteEvents,
				visible: () => ApplicationState.hasPermissions(Permission.EventsDelete),
				enabled: (selection) => selection.isAnythingSelected
			}, {
				id: 'setInformation',
				icon: <FormOutlined />,
				label: i18n('Set Information'),
				action: this.setInformation,
				enabled: (selection) => selection.isAnythingSelected,
				visible: () => ApplicationState.hasPermissions(Permission.EventsUpdate)
			}],
			mainMenu: 'delete | ack unack | assign unassign | startService stopService | addLogEntry createFilter createIncident exportToCsv resetHI setInformation',
			onActionFinished: () => {
				this.gridStore.remoteDataProvider.silentReload();
			}
		})
	}

	getAutoReloadPlugin(props: EventsSummaryStoreProps){
		return new AutoReloadPlugin<EventsSummaryEntry>({
			getSubscriptions: (store) => {
				return [EventSummaryEvent.subscription({
					filter: serialize(store.actualFilter),
					tags: props.tags,
					showUntagged: props.showUntagged,
					includeSubaccounts: props.includeSubaccounts
				})]
			}
		})
	}

	get title(){
		return i18n('Event summary')
	}

	openDetailsModal = (model: GridDataItem<EventsSummaryEntry>) => {
		this.gridStore.selection.clear();
		this.gridStore.selection.toggleSelected(model);
		this.showDetailsModal = true;
	}

	cleanUpUiOnActionCompleted = (selection?: GridSelection<EventsSummaryEntry>) => {
		if (this.showDetailsModal)
			return

		selection?.clear();
		this.gridStore.dataProvider.silentReload();
	}

	getColumns(navigator?: CeeviewNavigator){
		if(!this.columns){
			this.columns = [{
				title: i18n('Severity'),
				field: 'severity',
				align: 'center',
				renderer: (item, extra) => {
					return <a onClick={() => { this.openDetailsModal(extra.gridItem);}}>
						<SeverityIndicator className={b({'historic-row': item.historic})} {...healthDataToFlags(item)}/>
					</a>;
				},
				width: 32
			},{
				title: i18n('Time'),
				field: 'time',
				renderer: (item) => <span>{dateToString(item.time, 'datetime')}</span>,
				width: 150,
			}, {
				title: i18n('Target'),
				field: 'targetName',
				renderer: (item) => {
					if(!item.targetName)
						return i18n('Not available')

					const [url, enabled] = getTargetEntryDescription(item)

					return <Link url={url}
					             enabled={enabled}
					             children={item.targetName}
					             navigator={navigator}/>
				},
				width: 150
			}, {
				title: i18n('Message'),
				field: 'message',
				expandOnClick: true,
				renderer: (item) => <span title={item.message}>{item.message}</span>,
				width: 350,
			}, {
				title: i18n('Name'),
				field: 'sourceName',
				renderer: (item) => {
					if(!item.sourceName)
						return i18n('Not available')

					const [url, enabled] = getSourceEntryDescription(item)

					return <Link url={url}
					             enabled={enabled}
					             children={item.sourceName}
					             navigator={navigator}/>
				},
				width: 150
			}, {
				title: i18n('Suppression'),
				field: 'suppressionCount',
				align: 'right',
				renderer: (item) => item.suppressionCount,
				width: 90
			}, {
				title: i18n('Action Count'),
				field: 'actionCount',
				align: 'right',
				renderer: (item) => <Link
					onClick={(e) => {showActionsViewer(e, item.id)}}
					navigator={navigator}
					enabled={item.actionCount != 0}>
					{item.actionCount}
				</Link>,
				width: 90
			}, {
				title: i18n('Type'),
				field: 'type',
				renderer: (item) => item.typeText ?? item.type,
				width: 150
			}, {
				title: i18n('Acknowledged'),
				field: 'acknowledged',
				align: 'center',
				renderer: (item) => item.acknowledged ? <CheckOutlined/> : null,
				width: 40
			}, {
				title: i18n('Assigned Type'),
				field: 'assignedToType',
				align: 'center',
				className: b('assignee-type'),
				renderer: (item) => {
					switch (item.assignedToType) {
						case AssigneeType.User:
							return <UserOutlined title={i18n('User: {0}', item.assignedTo)}/>;
						case AssigneeType.Team:
							return <TeamOutlined title={i18n('Team: {0}', item.assignedTo)}/>;
						default:
							return null;
					}
				},
				width: 40
			}, {
				title: i18n('Account'),
				field: 'accountId',
				renderer: (item) => <span title={item.accountName}>{item.accountName}</span>,
				width: 150
			}, {
				title: i18n('Historic'),
				field: 'historic',
				align: 'center',
				renderer: (item) => item.historic ? <CheckOutlined/> : null,
				width: 30
			}, {
				field: 'agentName',
				title: i18n('Agent Name'),
				renderer: (item) => item.agentName
					? <Link url={AgentsRouter.details(item.agentId)}
					                 enabled={AgentSecurity.canRead(item.agentId)}
					                 navigator={navigator}
					                 children={item.agentName}/>
					: i18n('Not available'),
				width: 150
			}, {
				title: i18n('Tags'),
				field: 'tags',
				renderer: (item) => {
					const tags = item.tags.join();
					return <span title={tags}>{tags}</span>
				},
				width: 150
			}, {
				title: i18n('Assigned by'),
				field: 'assignedBy',
				renderer: (item) => item.assignedBy ?? '',
				width: 150
			},{
				title: i18n('Acknowledged by'),
				field: 'acknowledgedBy',
				renderer: (item) => item.acknowledgedBy,
				width: 150
			}, {
				title: i18n('Acknowledged at'),
				field: 'acknowledgedAt',
				renderer: (item) => <span>{dateToString(item.acknowledgedAt, 'datetime')}</span>,
				width: 150
			}, {
				title: i18n('Information'),
				field: 'information',
				renderer: (item) => item.information,
				width: 150
			}, {
				title: i18n('In Maintenance'),
				field: 'inMaintenance',
				renderer: (item) => item.inMaintenance,
				width: 150
			}, {
				title: i18n('Monitor Type'),
				field: 'monitorTypeText',
				renderer: (item) => item.monitorTypeText,
				width: 150
			}, {
				title: i18n('Assigned To'),
				field: 'assignedTo',
				renderer: (item) => item.assignedTo,
				width: 150
			}, {
				title: i18n('Details'),
				field: 'details',
				renderer: (item) => item.details,
				expandOnClick: true,
				width: 150
			}, {
				title: i18n('Created'),
				field: 'timeCreated',
				renderer: (item) => <span>{dateToString(item.timeCreated, 'datetime')}</span>,
				width: 150
			}, {
				title: i18n('Assigned Time'),
				field: 'assignedTime',
				renderer: (item) => <span>{dateToString(item.assignedTime, 'datetime')}</span>,
				width: 150
			}, {
				title: i18n('Asset Group'),
				field: 'groups',
				renderer: (item) => item.groups,
				width: 150
			}, {
				title: i18n('Agent ID'),
				field: 'agentId',
				renderer: (item) => item.agentId,
				visible: false,
				width: 150
			}, {
				title: i18n('External ID'),
				field: 'externalId',
				renderer: (item) => item.externalId,
				visible: false,
				width: 150
			}, {
				title: i18n('External Owner'),
				field: 'externalOwner',
				renderer: (item) => item.externalOwner,
				visible: false,
				width: 150
			}, {
				title: i18n('Identifier Instance'),
				field: 'identifierInstance',
				renderer: (item) => item.identifierInstance,
				visible: false,
				width: 150
			}, {
				title: i18n('Identifier Name'),
				field: 'identifierName',
				renderer: (item) => item.identifierName,
				visible: false,
				width: 150
			}, {
				title: i18n('Monitor Class'),
				field: 'monitorClassText',
				renderer: (item) => item.monitorClassText,
				visible: false,
				width: 150
			}, {
				title: i18n('Subsystem'),
				field: 'subsystem',
				renderer: (item) => item.subsystem,
				visible: false,
				width: 150
			}, {
				title: i18n('Source ID'),
				field: 'sourceId',
				renderer: (item) => item.sourceId,
				visible: false,
				width: 150
			}, {
				title: i18n('Target ID'),
				field: 'targetId',
				renderer: (item) => item.targetId,
				visible: false,
				width: 150
			}, {
				title: i18n('Time Deleted'),
				field: 'timeDeleted',
				renderer: (item) => <span>{dateToString(item.timeDeleted, 'datetime')}</span>,
				visible: false
			}]
		}

		return this.columns
	}

	exportToCsv = async (selection: GridSelection<EventsSummaryEntry>) => {
		const response = await apiFetch(exportToCsv(this.gridStore, selection))
		if (response.success) {
			window.open(serverRoot + 'sessions/exportCSV/' + response.data, '_blank');
			window.focus();
			this.cleanUpUiOnActionCompleted(selection);
		}
		return response.success;
	}

	createIncident = (selection: GridSelection<EventsSummaryEntry>) => {
		let assets: Record<string, DataListEntry<string>> = {}

		const healthEvents = selection.dataset.items
			.filter( x => x.data.type == EntryType.AssetHealthReason)

		const reasonsIds = healthEvents.map( x => x.id)

		healthEvents.reduce((result, event) => {
			result[event.data.targetId] = {
				name: event.data.targetName,
				id: event.data.targetId
			}
			return result
		}, assets)

		this.cleanUpUiOnActionCompleted(selection);

		NavigationStore.go(IncidentsRouter.createNew('ASSET'), {
			extra: {
				assets: Object.values(assets),
				reasonsIds: reasonsIds
			}
		});
	}

	resetHealthIndex = (selection: GridSelection<EventsSummaryEntry>) => {
		return new Promise<boolean>((resolve) => {
			const [root] = createContainer()

			const destroy = (result: boolean | undefined) => {
				root?.unmount();
				resolve(result ?? false);
			}

			const reset = async (value: string) => {
				const result = await performAction(() => apiFetch(resetHealthIndex(this.gridStore, selection, value)))

				if (result.success) {
					this.cleanUpUiOnActionCompleted(selection)
				}
				destroy(result.success);
			}

			root.render(<ResetHealthIndexWindow
				onClose={destroy}
				onReset={reset}
			/>)
		})
	}

	acknowledge = async (selection: GridSelection<EventsSummaryEntry>) => {
		const result = await apiFetch(acknowledge(this.gridStore, selection))

		if(result.success){
			this.cleanUpUiOnActionCompleted(selection)
		}
		return result.success;
	}

	unacknowledge = async (selection: GridSelection<EventsSummaryEntry>) => {
		const result = await apiFetch(unacknowledge(this.gridStore, selection))

		if(result.success){
			this.cleanUpUiOnActionCompleted(selection)
		}
		return result.success;
	}

	startService = async (selection: GridSelection<EventsSummaryEntry>) => {
		const result = await apiFetch(serviceCommand(this.gridStore, selection,'START'))

		if (result.success) {
			this.cleanUpUiOnActionCompleted(selection);
		}
		return result.success;
	}

	stopService = async (selection: GridSelection<EventsSummaryEntry>) => {
		const result = await apiFetch(serviceCommand(this.gridStore, selection, 'STOP'))

		if (result.success) {
			this.cleanUpUiOnActionCompleted(selection);
		}
		return result.success;
	}

	addLogEntry = (selection: GridSelection<EventsSummaryEntry>) => {
		let serviceEntry = justOneServiceEntrySelected(selection)
		if (serviceEntry == null)
			return

		let store = new LogEntryStore()

		return openModal({
			onOk: async () => {
				const result = await apiFetch(createLogEntry({
					message: store.message,
					state: store.state,
					serviceId: serviceEntry.data.targetId
				}))

				this.cleanUpUiOnActionCompleted(selection);
				if (!result.success) throw false;
			},
			positionType: this.showDetailsModal ? ModalPosition.Centered : ModalPosition.TopLeft,
			title: i18n('Create log entry')
		}, <Observer>{ () =>
			<Section childrenPadding={true}>
				<FormEntry label={i18n('State')}>
					<AntSelect dataList={this.logEntryStatusesDataList} {...linkModel(store, 'state')}/>
				</FormEntry>
				<FormEntry label={i18n('Message')} vertical={true}>
					<AntTextArea {...linkModel(store, 'message')} height={300}/>
				</FormEntry>
			</Section>
		}</Observer>)
	}

	createFilter = async () => {
		if(!this.isOnlyOneAssetHealthReasonSelected) {
			return
		}

		const record = this.gridStore.selection.dataset.items[0].data

		const url = AssetsRouter.createAssetHealthFilter({
			resetHealthIndex: true,
			populate: {
				assetId: record.targetId,
				monitorId: record.sourceId,
				monitorType: record.monitorType,
				severity: record.severity,
				reasonDescription: record.message,
				reasonSubsytem: record.subsystem,
				identifierName: record.identifierName || '',
				identifierInstance: record.identifierInstance || '',
				accountId: record.accountId,
				accountName: record.accountName
			}
		})
		NavigationStore.go(url)
	}

	assign = async (selection: GridSelection<EventsSummaryEntry>) => {
		let store = new AssignStore()

		const result = await apiFetch(getRecipients())
		if (!result.success)
			return

		const options = groupRecipients(result.data)
		return openModal({
			onOk: async () => {
				const recipient = result.data.find(x => x.id == store.id)

				const data = await apiFetch(assign(this.gridStore, selection, store.id, recipient.type));

				this.cleanUpUiOnActionCompleted(selection)
				if (!data.success) throw false;
			},
			positionType: this.showDetailsModal ? ModalPosition.Centered : ModalPosition.TopLeft,
			title: i18n('Assign to')
		}, <Section childrenPadding={true}>
			<FormEntry label={i18n('Select user/team to assign event to')} vertical={true}>
				<AntSelect
					{...linkModel(store, 'id')}
					showSearch={true}
					options={options}
					optionFilterProp="label"
				/>
			</FormEntry>
		</Section>)
	}

	unassign = async (selection: GridSelection<EventsSummaryEntry>) => {
		const result = await apiFetch(unassign(this.gridStore, selection))

		if(result.success){
			this.cleanUpUiOnActionCompleted(selection)
		}
		return result.success;
	}

	deleteEvents = async (selection: GridSelection<EventsSummaryEntry>) => {
		const result = await apiFetch(deleteEvents(this.gridStore, selection))

		if (result.success) {
			this.cleanUpUiOnActionCompleted(selection)
		}
		return result.success;
	}

	setInformation = (selection: GridSelection<EventsSummaryEntry>) => {
		return new Promise<boolean>((resolve) => {
			const [root] = createContainer();

			const destroy = (result: boolean | undefined) => {
				root.unmount();
				resolve(result ?? false);
			}

			const updateInformation = async (value: string) => {
				const result = await performAction( () => apiFetch(setInformation(this.gridStore, selection, value)))

				if(result.success){
					this.cleanUpUiOnActionCompleted(selection)
				}

				destroy(result.success);
			}

			root.render(<SetInformationWindow
				onClose={destroy}
				onUpdate={updateInformation}
				topLeftCorner={!this.showDetailsModal}
			/>)
		})
	}

	get isOnlyOneAssetHealthReasonSelected() {
		return this.gridStore.selection.exactlyOne(x => x.data.type == EntryType.AssetHealthReason) != null
	}

	get logEntryStatusesDataList (){
		return  [{
			id: 'OK',
			name: i18n('Ok')
		}, {
			id: 'ERROR',
			name: i18n('Error')
		}, {
			id: 'WARNING',
			name: i18n('Warning')
		}, {
			id: 'FAILED',
			name: i18n('Failed')
		}, {
			id: 'UNKNOWN',
			name: i18n('Unknown')
		}]
	}

	destroy() {
		this.mobx.destroy()
		this.gridStore?.destroy();
	}
}

export function getTargetEntryDescription(item: EventsSummaryEntry) : [string, boolean]{
	const has = ApplicationState.hasPermissions

	switch (item.type){
		case EntryType.MonitorError:
		case EntryType.AssetHealthReason:
			return [
				AssetsRouter.details(item.targetId),
				has(Permission.AggregatedHealthAssetRead) || has(Permission.AggregatedHealthMonitorRead)
			]

		case EntryType.AgentState:
			return [
				AgentsRouter.details(item.targetId),
				has(Permission.AggregatedManagementAgentRead) || has(Permission.AggregatedHealthAssetRead)
			]

		case EntryType.Service:
		case EntryType.ServiceWarning:
		case EntryType.ServiceElement:
		case EntryType.ServiceError:
		case EntryType.ServiceLink:
		case EntryType.ServiceModel:
		case EntryType.ServiceQualifier:
		case EntryType.ServiceQualifierWarning:
			let objectToHightlight: Record<string, string> = {}
			if( item.type == EntryType.ServiceQualifier || item.type == EntryType.ServiceQualifierWarning){
				objectToHightlight.element = item.sourceId
			}

			if(item.type == EntryType.ServiceElement || item.type == EntryType.ServiceLink){
				objectToHightlight.node = item.sourceId
			}

			return [
				ServicesRouter.viewer(item.targetId, {highlightObj: objectToHightlight}),
				has(Permission.AggregatedServiceRead)
			]

		case EntryType.Sla:
			return [SlaRouter.details(item.targetId), has(Permission.AggregatedServiceRead)]

		case EntryType.SystemError:
		case EntryType.AgentWarning:
			return [AgentsRouter.details(item.targetId), true]

		case EntryType.Cost:
			return [CostsRouter.detailsByProfile(item.sourceId, item.targetId), true]

		default:
			console.warn('Missing type', item.type, item)
			return ['', false]
	}
}

export function getSourceEntryDescription(item: EventsSummaryEntry) : [string, boolean]{
	const has = ApplicationState.hasPermissions

	switch (item.type){
		case EntryType.MonitorError:
		case EntryType.AssetHealthReason:
			return [
				MonitorsRouter.details(item.monitorType, item.sourceId),
				has(Permission.AggregatedHealthAssetRead) || has(Permission.AggregatedHealthMonitorRead)
			]

		case EntryType.AgentState: {
			const url = item.targetId == item.sourceId
				? AgentsRouter.details(item.sourceId)
				: AssetsRouter.details(item.sourceId);
			return [
				url,
				has(Permission.AggregatedManagementAgentRead) || has(Permission.AggregatedHealthAssetRead)
			]
		}

		case EntryType.Service:
		case EntryType.ServiceWarning:
		case EntryType.ServiceElement:
		case EntryType.ServiceError:
		case EntryType.ServiceLink:
		case EntryType.ServiceModel:
		case EntryType.ServiceQualifier:
		case EntryType.ServiceQualifierWarning:
			return [ServicesRouter.details(item.targetId), has(Permission.AggregatedServiceRead)]

		case EntryType.Sla:
			return [ServicesRouter.details(item.sourceId), true]

		case EntryType.SystemError:
			return [AssetsRouter.details(item.sourceId), true]

		case EntryType.AgentWarning: {
			const url = item.targetId == item.sourceId
				? AgentsRouter.details(item.sourceId)
				: AssetsRouter.details(item.sourceId);
			return [url, true]
		}

		case EntryType.Cost:
			return [CostsRouter.detailsByProfile(item.sourceId), true]

		default:
			console.warn('Missing type', item.type, item)
			return ['', false]
	}
}

export function getFilterByEntityId(entityId: string){
	let group = RuleDefinition.emptyGroup()

	let rule = RuleDefinition.emptyRule('sourceId', "multiselect_equals")
	rule.properties.value = [entityId]
	group.addOrUpdateRule(rule)

	rule = RuleDefinition.emptyRule('targetId', "multiselect_equals")
	rule.properties.value = [entityId]
	group.addOrUpdateRule(rule)

	group.properties.conjunction = GroupConjunction.Or

	return group
}

class LogEntryStore{
	state: string = 'OK'
	message: string

	constructor() {
		makeAutoObservable(this)
	}
}

class AssignStore{
	id: string
}

function justOneServiceEntrySelected(selection: GridSelection<EventsSummaryEntry>) {
	return selection.exactlyOne(x =>
		[EntryType.ServiceError, EntryType.Service, EntryType.ServiceElement, EntryType.ServiceQualifier].indexOf(x.data.type) != -1)
}

function justOneHealthReasonSelected(selection: GridSelection<EventsSummaryEntry>){
	return selection.exactlyOne(x => x.data.type == EntryType.AssetHealthReason) != null
}

function isAReasonSelected(selection: GridSelection<EventsSummaryEntry>) {
	return selection.some( x => x.data.type == EntryType.AssetHealthReason)
}
