import React, {ReactNode} from "react";
import {AntSelect} from "../../../../controls/react/ant/antSelect";
import {capitalize} from 'lodash';
import {AntInputNumber, NumberKeysPattern} from "../../../../controls/react/ant/antInputNumber";
import {AntInput} from "../../../../controls/react/ant/antInput";
import classnames from "classnames";
import {observer} from "mobx-react";
import {BudgetTableContext} from "./BudgetTableContext";
import {getValueOnPath, setValueOnPath} from "tools/helpers/math";

const b = require('b_').with('editable-cell');

export type ColumnInput = {
	type: 'enum' | 'number' | 'text',
	enum?: any
}

interface EditableCellProps<T> {
	editable: boolean,
	children: ReactNode,
	dataIndex: keyof T | string[],
	record: T,
	input: ColumnInput,
	setValue?: (record: T, value: any) => void
}

const editableCellPropsKeys = ['editable', 'children', 'dataIndex', 'record', 'input', 'setValue'];

type EditableCellState = {
	editing: boolean,
	value: any,
	invalid: boolean
}

export const EditableCell = observer(class EditableCell<T> extends React.Component<EditableCellProps<T> & React.HTMLAttributes<HTMLTableCellElement>, EditableCellState> {
	static contextType = BudgetTableContext;
	declare context: React.ContextType<typeof BudgetTableContext>;

	state: EditableCellState = {
		editing: false,
		value: null,
		invalid: false
	}

	render() {
		return <div {...this.getHtmlProps()}>{this.childNode()}</div>;
	}

	get path(): string {
		const {dataIndex} = this.props;
		return [].concat(dataIndex).join('.');
	}

	startEditing = () => {
		const {record} = this.props;
		let value = getValueOnPath(record, this.path);
		value = this.prepareValueForInput(value);
		this.setState({editing: true, value})
	}

	stopEditing = () => {
		this.setState({editing: false});
	}

	save = (inputValue: any = null) => {
		if (this.state.invalid) {
			return;
		}
		const {record} = this.props;
		try {
			this.stopEditing();
			let value = inputValue || this.state.value;
			value = this.prepareValueForSave(value);
			if (this.props.setValue) {
				this.props.setValue(record, value);
			} else {
				setValueOnPath(record, this.path, value);
			}
			// @ts-ignore
			record.modified = true;
		} catch (errInfo) {
			console.log('Save failed:', errInfo);
		}
	}

	onInvalidInput = () => {
		this.setState({invalid: true});
	}

	editorNode(): JSX.Element {
		const { input } = this.props;
		const inputProps = {
			onPressEnter: () => this.save(),
			onPressEsc: this.stopEditing,
			onBlur: () => this.save(),
			autoFocus: true,
			value: this.state.value,
			onChange: (value: any) => this.setState({value, invalid: false}),
			className: b('input', {invalid: this.state.invalid}),
			size: 'small'
		}
		switch (input.type) {
			case 'text':
				return <AntInput {...inputProps}/>
			case 'number':
				return <AntInputNumber {...inputProps} onInvalidInput={this.onInvalidInput} pattern={NumberKeysPattern.DecimalOrEmpty}/>
			case 'enum':
				return this.editorEnumNode();
		}
	}

	editorEnumNode(): JSX.Element {
		const { input } = this.props;
		return (
				<AntSelect
					dataList={this.enumDataList(input.enum)}
					onChange={(value) => { this.save(value); }}
					value={this.state.value}
					sortValues={false}
					defaultOpen={true}
					autoFocus={true}
					size={'small'}
				/>
		);
	}

	enumDataList(enumClass: any) {
		return Object.keys(enumClass).reduce((out, k) => {
			out.push({name: capitalize(k), id: k});
			return out;
			}, [])
	}

	viewerNode(): JSX.Element {
		const {children, editable} = this.props;
		return (
			<div
				className={b('value-wrap', {editable: editable})}
				onClick={this.startEditing}
			>
				{children}
			</div>
		);
	}

	childNode(): ReactNode {
		const {editable, children} = this.props;
		const {editing} = this.state;

		if (!editable) {
			return children;
		}

		if (editing) {
			return this.editorNode();
		} else {
			return this.viewerNode();
		}
	}

	private getHtmlProps(): React.HTMLAttributes<HTMLTableCellElement> {
		const {editable = false} = this.props;
		let htmlProps = {} as React.HTMLAttributes<HTMLTableCellElement>;

		for (let key in this.props) {
			if (!~editableCellPropsKeys.indexOf(key)) {
				htmlProps[key] = this.props[key];
			}
		}
		htmlProps.className = classnames(htmlProps.className, b({editable: editable.toString()}));
		return htmlProps;
	}

	// NOTE maybe move this two methods to props
	private prepareValueForInput(value: any) {
		if (this.props.input.type != 'number') {
			return value;
		}
		const {displayAsThousands} = this.context;
		if (displayAsThousands && value) {
			return value / 1000;
		}
		return value;
	}

	private prepareValueForSave(value: any) {
		if (this.props.input.type != 'number') {
			return value;
		}

		const {displayAsThousands} = this.context;
		if (value && displayAsThousands) {
			return value * 1000;
		}
		return value;
	}
});
