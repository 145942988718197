const chartDefaultColors = [
	"#7cb5ec",
	"#434348",
	"#90ed7d",
	"#f7a35c",
	"#8085e9",
	"#f15c80",
	"#e4d354",
	"#2b908f",
	"#f45b5b",
	"#91e8e1"
];

export function * getChartBarColor(){
    while(true)
        for(const color of chartDefaultColors)
            yield color;
}
const costBarChartDefaultColors = [
	"#03045E",
	"#031369",
	"#032174",
	"#03307F",
	"#023E8A",
	"#024D95",
	"#015BA0",
	"#0169AB",
	"#0077B6",
	"#0087BF",
	"#0096C7",
	"#00B4D8",
	"#24BFDE",
	"#48CAE4",
	"#6CD5EA",
	"#90E0EF",
	"#9FE4F2",
	"#ADE8F4",
	"#BCECF6",
	"#CAF0F8"
	];

export function * getCostBarChartColor(colorOption){
    if(colorOption === "BLUE")
        while(true)
            for(const color of costBarChartDefaultColors)
                yield color;
    else
        while(true)
            for(const color of chartDefaultColors)
                yield color;
}
