import {UrlBuilder} from "../../tools/urlBuilder";

export function getDescriptions() {
	return [
		{
			jspUrl: 'include/AssetHealth/Summary.jsp',
			url: '/assets',
			getModuleImport: () => import('./assetHealthSummary'),
			routeId: 'assethealth_summary'
		},
		{
			jspUrl: 'include/AssetHealth/AssetDetails.jsp',
			url: '/assets/:id',
			getModuleImport: () => import('./assetDetails'),
			routeId: 'asset_details'
		},
		{
			jspUrl: 'include/AssetHealth/FiltersConfiguration.jsp',
			url: '/asset-filters/:id',
			getModuleImport: () => import('./filtersConfigurationView.js'),
			routeId: 'assethealth_filters_details'
		},
		{
			jspUrl: 'include/AssetHealth/FiltersView.jsp',
			url: '/asset-filters',
			getModuleImport: () => import('./filtersView.js'),
			routeId: 'assethealth_filters'
		},
		{
			jspUrl: 'include/AssetHealth/Reasons.jsp',
			url: '/asset-health-reasons',
			getModuleImport: () => import('./assetHealthReasons'),
			routeId: 'assethealth_reasons'
		},
	];
}

export class AssetsRouter {
	static details(assetId, tab) {
		let url = '/assets/' + assetId;
		if (tab && tab=='metric') {
			url += '?tabIndex=4';
		}
		return url;
	}

	static assetHealthSummary(options = {}) {
		return new UrlBuilder('/assets').addObject(options).build();
	}

	static assetHealthReasons() {
		return '/asset-health-reasons'
	}

	static assetHealthFilters() {
		return '/asset-filters'
	}

	static assetHealthFilter(filterId) {
		return '/asset-filters/' + filterId
	}

	static createAssetHealthFilter(params = {}) {
		return new UrlBuilder('/asset-filters/new')
			.addObject(params)
			.build();
	}
}
