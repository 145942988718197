//if current date time is 2024-09-04 10:45 then (all periods are inclusive)

export enum TimePeriodType {
	//!!! in COST-related logic LAST24HOURS, LAST7DAYS, LAST30DAYS represent another periods
	//LASTDAY -- Prev day, LAST7DAYS - Last 7 days today not included, LAST30DAys - similar


	LastHour = 'LASTHOUR', //2024-09-04 9:45 - 2024-09-04 10:45 //current date minus exactly an hour
	Last24Hours = 'LASTDAY', //2024-09-03 9:45 - 2024-09-04 10:45 //current date minus exactly a day
	Last30Days = 'LAST30DAYS', // 2024-08-05 10:45 - 2024-09-04 10:45 //current date minus exactly 30 days
	Last7Days = 'LAST7DAYS', // - 2024-09-04 10:45 //current date minus exactly 7 days

	PreviousQuarter = 'PREVIOUS_QUARTER', //2024-04-01 - 2024-06-30
	PreviousDay = 'PREVIOUS_DAY', //2024-09-03 - 2024-09-04
	PreviousWeek = 'PREVIOUS_WEEK',  //2024-08-26 - 2024-09-01
	PreviousMonth = 'LAST_MONTH', //2024-08-01 - 2024-08-31
	PreviousYear = 'LAST_YEAR', //2023-01-01 - 2023-12-31

	CurrentDay = 'CURRENT_DAY', //2024-09-04 00:00:00 - 2024-09-04 10:45
	CurrentWeek = 'CURRENT_WEEK', //2024-09-02 - 2024-09-04 10:45
	CurrentMonth = 'CURRENT_MONTH', //2024-09-01 - 2024-09-04 10:45
	CurrentYear = 'CURRENT_YEAR', //2024-01-01 - 2024-09-04 10:45

	Month = 'MONTH', //is used to send a number of months
	Year = 'YEAR', //is used to send a number of years

	Custom = 'CUSTOM',
	DynamicInterval = 'DYNAMIC',
	LastN = 'LASTN',
	None = 'None',
	All = 'ALL'
}
