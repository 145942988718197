import {makeAutoObservable, toJS} from "mobx";
import {observer} from "mobx-react"
import React from "react"
import {UnorderedListOutlined} from "@ant-design/icons"

import {Section} from "controls/react/layout/section"
import {AccountsSelector} from "controls/react/accountSelector"
import {MobxManager} from "framework/mobx-integration"
import {AntButton} from "controls/react/ant/antButton"
import {AntSelect} from "controls/react/ant/antSelect"
import {loadChildEntities} from "areas/dashboards/graph-editor-extensions/datasources/loadChildEntities"
import {
	getServiceElementQualifiers,
	getServiceElementsLite,
	getServiceQualifiers,
	getServicesLite
} from "areas/services/api"
import {CellDataSourceType} from "controls/designer/dataSourcesManager/cellDataSourceType";
import {ShowAsLabel} from "controls/designer/dataSourcesManager/sharedProperties";
import {CellDataSourceBase} from "controls/designer/dataSourcesManager/cellDataSource";
import {Designer} from "controls/designer/designer";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {ServiceDataSourceElement} from "controls/designer/dataSourcesManager/serviceDataSourceElement";
import {showDataSourceWindow} from "controls/designer/dataSourcesManager/dataSourceWindow";
import {DataSourceEditorContext} from "controls/designer/dataSourcesManager/dataSourceEditorContext";
import {DesignerStore} from "controls/designer/designerStore";
import {ApplicationState} from "../../../framework/applicationState";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {useStore} from "core/react/useStore";

const i18n = require('core/localization/localization').translator({});

export class CellServiceDataSource implements CellDataSourceBase{
	id: string
	accounts: string[] = []
	showAsLabel: boolean
	type: CellDataSourceType.Service = CellDataSourceType.Service
	services: {
		id: string,
		accountId: string
	}[] = []
	serviceElements: {
		id: string,
		serviceId: string,
		accountId: string
	}[] = []
	serviceQualifiers: {
		id: string
		elementId: string
		serviceId: string
		accountId: string
	}[] = []

	constructor() {
		makeAutoObservable(this)
	}

	attachToCell(designer: Designer, cell: MxCell) {
		return new ServiceDataSourceElement(designer, cell)
	}

	valid(){
		return this.serviceElements.length > 0 || this.serviceQualifiers.length > 0 || this.services.length > 0
	}
}

type ServiceDataListEntry = {
	id: string
	name: string
	accountId: string
	accountName: string
}

type ServiceElementDataListEntry = ServiceDataListEntry & {
	serviceId: string
	serviceName: string
}

type ServiceQualifierDataListEntry = ServiceElementDataListEntry & {
	elementId?: string
	elementName?: string
}

export class ServiceDataSourceEditorStore {
	servicesDataList: ServiceDataListEntry[] = []
	elementsDataList: ServiceElementDataListEntry[] = []
	qualifiersDataList: ServiceQualifierDataListEntry[] = []
	mobx = new MobxManager()

	servicesListInvalid: boolean = true
	servicesListLoading: boolean = false
	elementsListInvalid: boolean = true
	elementsListLoading: boolean = false
	qualifiersListInvalid: boolean = true
	qualifiersListLoading: boolean = false

	dataSource: CellServiceDataSource

	constructor(public designerStore: DesignerStore) {
		makeAutoObservable(this)

		this.dataSource = this.designerStore.dataSourcesManager.selected as CellServiceDataSource
	}

	init(){
		this.mobx.reaction(() => toJS(this.dataSource.accounts), async () => {
			this.servicesListInvalid = true
			this.dataSource.services = []
			this.elementsListInvalid = true
			this.dataSource.serviceElements = []
			this.qualifiersListInvalid = true
			this.dataSource.serviceQualifiers = []
		})

		this.mobx.reaction(() => this.dataSource.services.length, async () => {
			this.elementsListInvalid = true
			this.dataSource.serviceElements = []
			this.qualifiersListInvalid = true
			this.dataSource.serviceQualifiers = []
		})

		this.mobx.reaction(() => this.dataSource.serviceElements.length, async () => {
			this.qualifiersListInvalid = true
			this.dataSource.serviceQualifiers = []
		})

		this.mobx.autorun(async () => {
			if (this.servicesListInvalid) {
				await this.loadServices()
			}
		})

		this.mobx.autorun(async () => {
			if (!this.servicesListInvalid && this.elementsListInvalid) {
				await this.loadElements()
			}
		})

		this.mobx.autorun(async () => {
			if (!this.servicesListInvalid && !this.elementsListInvalid && this.qualifiersListInvalid) {
				await this.loadQualifiers()
			}
		})

		if (!this.dataSource.accounts?.length) {
			this.dataSource.accounts = [ApplicationState.accountId]
		}
	}

	loadServices = async () => {
		if (!this.dataSource.accounts?.length ) {
			return;
		}

		this.servicesListLoading = true
		this.servicesDataList = await loadChildEntities(
			this.dataSource.accounts,
			accountId => getServicesLite({
				accountId,
				includeSubaccounts: false
			}),
			(element, accountId) => {
				return element
			}
		);

		this.servicesListLoading = false
		this.servicesListInvalid = false
	}

	loadElements = async () => {
		if (!this.dataSource?.services || this.dataSource?.services.length == 0) {
			return;
		}

		this.elementsListLoading = true
		this.elementsDataList = await loadChildEntities(
			this.dataSource.services,
			service => getServiceElementsLite(service.id, service.accountId),
			(element, service) => {
				let serviceObj = this.servicesDataList.find(x => x.id == service.id)
				return {
					id: element.id,
					name: element.name,
					serviceId: service.id,
					accountId: service.accountId,
					serviceName: serviceObj.name,
					accountName: serviceObj.accountName
				}
			}
		)

		this.elementsListLoading = false
		this.elementsListInvalid = false
	}

	loadQualifiers = async () => {
		if(!this.dataSource?.services.length && !this.dataSource?.serviceElements.length)
			return

		this.qualifiersListLoading = true

		if (this.dataSource.serviceElements?.length) {
			this.qualifiersDataList = await loadChildEntities(
				this.dataSource.serviceElements,
				element => getServiceElementQualifiers(element.serviceId, element.id, element.accountId),
				(qualifier, element) => {
					let elementObj = this.elementsDataList.find( x => x.id == element.id)
					return {
						id: qualifier.id,
						name: qualifier.name,
						serviceId: elementObj.serviceId,
						elementId: elementObj.id,
						accountId: elementObj.accountId,
						serviceName: elementObj.serviceName,
						accountName: elementObj.accountName,
						elementName: elementObj.name,
					}
				});
		}else if(this.dataSource.services?.length){
			this.qualifiersDataList = await loadChildEntities(
				this.dataSource.services,
				service => getServiceQualifiers(service.id, service.accountId),
				(qualifier, service) => {
					let serviceObj = this.servicesDataList.find(x => x.id == service.id)
					return {
						id: qualifier.id,
						name: qualifier.name,
						serviceId: serviceObj.id,
						accountId: serviceObj.accountId,
						accountName: serviceObj.accountName,
						serviceName: serviceObj.name,
					}
				})
		}

		this.qualifiersListInvalid = false
		this.qualifiersListLoading = false
	}

	destroy() {
		this.mobx.destroy()
	}

	showServicesWindow = () => {
		showDataSourceWindow({
			title: i18n('Service'),
			selectedIds: this.dataSource?.services?.map(x => x.id) ?? [],
			data: this.servicesDataList,
			columns: [{
				field: 'name',
				title: i18n('Name'),
				filterable: 'string'
			},{
				field: 'accountName',
				title: i18n('Account'),
				filterable: 'string'
			},{
				field: 'description',
				title: i18n('description'),
				filterable: 'string'
			}],
			onOk: (items) => {
				this.dataSource.services = items.map(x => ({
					id: x.id,
					accountId: x.accountId
				}))
			},
			designerStore: this.designerStore
		})
	}

	showElementsWindow = () => {
		showDataSourceWindow({
			title: i18n('Service Element'),
			selectedIds: this.dataSource?.serviceElements?.map(x => x.id) ?? [],
			data: this.elementsDataList,
			columns: [{
				field: 'name',
				title: i18n('Name'),
				filterable: 'string'
			},{
				field: 'accountName',
				title: i18n('Account'),
				filterable: 'string'
			},{
				field: 'serviceName',
				title: i18n('Service'),
				filterable: 'string'
			}],
			onOk: (items) => {
				this.dataSource.serviceElements = items.map(x => ({
					id: x.id,
					serviceId: x.serviceId,
					accountId: x.accountId
				}))
			},
			designerStore: this.designerStore
		})
	}

	showQualifiersWindow = () => {
		showDataSourceWindow({
			title: i18n('Servicequalifier'),
			selectedIds: this.dataSource?.serviceQualifiers?.map(x => x.id) ?? [],
			data: this.qualifiersDataList,
			columns: [{
				field: 'name',
				title: i18n('Name'),
				filterable: 'string'
			},{
				field: 'accountName',
				title: i18n('Account'),
				filterable: 'string'
			},{
				field: 'serviceName',
				title: i18n('Service'),
				filterable: 'string'
			},{
				field: 'elementName',
				title: i18n('Service element'),
				filterable: 'string'
			}],
			onOk: (items) => {
				this.dataSource.serviceQualifiers = items.map(x => ({
					id: x.id,
					serviceId: x.serviceId,
					accountId: x.accountId,
					elementId: x.elementId
				}))
			},
			designerStore: this.designerStore
		})
	}
}

export const ServiceDataSourceEditor = observer(() => {
	const designerStore = React.useContext(DataSourceEditorContext)

	const store = useStore(() => new ServiceDataSourceEditorStore(designerStore),{
		deps: [designerStore.dataSourcesManager.selected]
	})

	return <Section appearance={'none'} childrenPadding={true}>
		<FormEntryNew label={i18n('Account')} model={store.dataSource} modelField={"accounts"}>
			<AccountsSelector hidePlaceholder={true}/>
		</FormEntryNew>

		<ServicesSelector store={store}/>
		<ElementsSelector store={store}/>
		<QualifiersSelector store={store}/>

		<ShowAsLabel datasource={store.dataSource}/>

	</Section>
})

const ServicesSelector = observer( (props: {store: ServiceDataSourceEditorStore}) => {
	const store = props.store

	const icon = <AntButton icon={<UnorderedListOutlined/>}
	                        size={"small"}
	                        disabled={store.servicesListInvalid}
	                        onClick={store.showServicesWindow}/>

	return <FormEntryNew label={i18n('Service')} model={store.dataSource} modelField={'services'} headerAdditionalContent={icon}>
		<AntSelect disabled={store.servicesListInvalid}
		           dataList={store.servicesDataList}
		           loading={store.servicesListLoading}
		           triggerChangeOnClose={true}
		           valueAsObject={true}
		           objectFields={['id', 'accountId']}
		           mode={'multiple'}
		/>
	</FormEntryNew>;
})

const ElementsSelector = observer( (props: {store: ServiceDataSourceEditorStore}) => {
	const store = props.store

	const icon = <AntButton icon={<UnorderedListOutlined/>}
	                        size={"small"}
	                        disabled={store.elementsListInvalid}
	                        onClick={store.showElementsWindow}/>

	return <FormEntryNew label={i18n('Service Element')} model={store.dataSource} modelField={"serviceElements"} headerAdditionalContent={icon}>
		<AntSelect dataList={store.elementsDataList}
		           disabled={store.elementsListInvalid}
		           loading={store.elementsListLoading}
		           triggerChangeOnClose={true}
		           objectFields={['id', 'accountId', 'serviceId']}
		           mode={'multiple'}
		           valueAsObject={true}
		/>
	</FormEntryNew>
})

const QualifiersSelector = observer( (props: {store: ServiceDataSourceEditorStore}) => {
	const store = props.store

	const icon = <AntButton icon={<UnorderedListOutlined/>}
	                               size={"small"}
	                               disabled={store.qualifiersListInvalid}
	                               onClick={store.showQualifiersWindow}/>

	return <FormEntryNew label={i18n('Service Qualifier')} model={store.dataSource} modelField={"serviceQualifiers"} headerAdditionalContent={icon}>
		<AntSelect dataList={store.qualifiersDataList}
		           disabled={store.qualifiersListInvalid}
		           loading={store.qualifiersListLoading}
		           triggerChangeOnClose={true}
		           objectFields={['id', 'accountId', 'serviceId', 'elementId']}
		           placeholder={i18n('Select a Service Qualifier...')}
		           mode={'multiple'}
		           valueAsObject={true}
		/>
	</FormEntryNew>
})
