import {ModalWindow} from "controls/modalWindow";
import AuthenticationForm from "areas/administration/authenticationForm";
import React from "react";
import {translator} from "core";
import PropTypes from "prop-types";
import IconButton from "../form/iconButton";
import {AntButton} from "controls/react/ant/antButton";
import {PlusCircleOutlined} from "@ant-design/icons";

const i = translator({
  "Create new authentication": {
    "no": "Opprett ny autentisering",
    "en": "Create new authentication"
  }
});

export class AddAuthentication extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	onClick = () => {
		this.window = new ModalWindow({
			title: i('Create new authentication'),
			width: 400,
			minHeight: 400,
			maxHeight: 800,
			id: this.props.newAuthModalWindowId || null,
			url: 'include/Administration/AuthenticationForm.jsp',
			refresh: () => {
				this.newAuthForm = new AuthenticationForm({
					id: 'new_authentication',
					modalId: this.props.newAuthModalWindowId || null,
					mode: 'create',
					accountId: this.props.accountId,
					authTypes: this.props.authTypes || [],
					onCreated: this.props.onCreated,
					preselectedAuths: this.props.authTypes?.length == 1 ? this.props.authTypes : undefined
				});
			}
		});
		this.window.open();
	}

	render() {
		return <AntButton icon={<PlusCircleOutlined />}
		                  className={this.props.containerClass}
		                  title={i('Create new authentication')}
		                  onClick={this.onClick}
		                  disabled={this.props.disabled}
		                  type={"text"}/>
	}
}

AddAuthentication.propTypes = {
	types: PropTypes.array,
	onCreated: PropTypes.func,
	authTypes: PropTypes.array,
	newAuthModalWindowId: PropTypes.string,
	accountId: PropTypes.string,
};

export default AddAuthentication;
