import {makeAutoObservable, makeObservable, observable, set, action} from "mobx"
import {primitive, list, object } from "serializr";
import {createModelSchemaWrapper, rawCopy} from "framework/serializr-integration";
import {newGuid} from "tools/guid";
import {ModelValidator, ValidatableModel} from "framework/mobx-integration";
import {CostGatewayLevelTypeOption} from "./costGatewayCloudType";

export class CostGatewayLevel implements ValidatableModel<CostGatewayLevel> {
	uiId: string
	type?: string

	options: { [x: string]: any } = {}

	validator = new ModelValidator(this)

	constructor() {
		makeObservable(this, {
			uiId: observable,
			type: observable,
			options: observable,
			setOptionsValidations: action
		})
		this.uiId = newGuid()
		this.validator.required('type')
	}

	setOptionsValidations(options: CostGatewayLevelTypeOption[]) {
		this.options.validator ||= new ModelValidator(this.options)

		options
			.filter(option => option.required)
			.forEach(option => this.options.validator.required(option.fieldName))
	}

}

export type CostGatewaySheetDataType = 'COST' | 'ADVISOR'

export class CostGatewaySheet implements ValidatableModel<CostGatewaySheet> {
	id: string
	name: string
	description: string
	dataType: CostGatewaySheetDataType = 'COST'
	justCreated: boolean = false;

	settings: {[x: string]: boolean}

	levels: CostGatewayLevel[] = []

	validator = new ModelValidator(this)

	constructor() {
		makeAutoObservable(this)
		this.addLevel()
		this.id = newGuid()
		this.settings = {}

		this.validator.add('levels', {
			callback: () => {
				return this.levels.every(x => x.validator.valid)
			},
			reactionExpression: () => this.levels.every(x => x.validator.valid)
		})
	}

	addLevel = () => {
		this.levels.push(new CostGatewayLevel())
	}

	removeLevel = (level: CostGatewayLevel) => {
		this.levels = this.levels.filter(l => l != level)
	}

	changeLevelType = (level: CostGatewayLevel, type: string, options: CostGatewayLevelTypeOption[]) => {
		const newLevel = new CostGatewayLevel()
		newLevel.type = type
		newLevel.setOptionsValidations(options)
		const index = this.levels.indexOf(level)
		set(this.levels, index, newLevel)
	}

	clearLevels = () => {
		this.levels = []
		this.addLevel()
	}

	get levelsAreEmpty() {
		return this.levels.length == 1 && !this.levels[0].type
	}
}

createModelSchemaWrapper(CostGatewaySheet, {
	id: primitive(),
	name: primitive(),
	description: primitive(),
	dataType: primitive(),
	levels: list(object(CostGatewayLevel))
})

createModelSchemaWrapper(CostGatewayLevel, {
	type: primitive(),
	options: rawCopy({excludeFields: ['validator']})
})
