import GaugeWidgetConfiguration from './gaugeWidgetConfiguration';
import GaugeWidget from './gaugeWidget';
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: GaugeWidgetConfiguration,
		defaultConfig: {
			type: 'health-gauge',
			title: i('Gauge'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		widget: GaugeWidget,
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Health') + '/' + i('Gauge'),
	}
}
