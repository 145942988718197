import React from "react";
import {store} from "./designer/qualifierWizard/reduxStore";
import {
	SET_SERVICE_ID,
	SET_ELEMENT_ID,
	SET_QUALIFIER_ID,
	GET_TEST,
	SET_QUALIFIER_WINDOW_SIZE,
	generateAction,
	SET_IGNORE_RESULTS, SET_SHOW_VALIDATION
} from "./designer/qualifierWizard/reduxActions";
import {ConfigErrorWindow} from "./designer/qualifierWizard/configErrorWindow";

import {Provider} from "react-redux";
import {getInProgressStatus} from "./designer/qualifierWizard/reduxReducer";

export default class ExternalConfigErrorWindow extends React.PureComponent {
	interval;
    constructor(props) {
        super(props);

        const {serviceId, elementId, qualifierId, top, left, width, height} = this.props;

        store.dispatch(generateAction(SET_SERVICE_ID, {serviceId}));
        store.dispatch(generateAction(SET_ELEMENT_ID, {elementId}));
        store.dispatch(generateAction(SET_QUALIFIER_ID, {qualifierId}));
    }

    async componentDidMount() {
		store.dispatch(generateAction(SET_SHOW_VALIDATION, {status: true}));
		await this.waitRequestData();
		store.dispatch(generateAction(GET_TEST));
    }

	async waitRequestData() {
		if (this.interval) {
			clearInterval(this.interval);
		}
		let timeout = 20000;
		return new Promise((r) => {
			this.interval = setInterval(() => {
				const inProgress = store.getState().qualifier.get('inProgress');
				if (!inProgress || timeout <= 0) {
					clearInterval(this.interval);
					r(1);
				} else {
					const ignoreResults = store.getState().qualifier.get('ignoreResults');
					if (!ignoreResults) {
						store.dispatch(generateAction(SET_IGNORE_RESULTS, {status: true}));
					}
					timeout-=300;
				}
			}, 300);
		});
	}

    render() {
        const {name, top, left, width, height} = this.props;

        const params = {name, position: {top: +top, left: +left, width: +width, height: +height}};

        return <Provider store={store}>
            <ConfigErrorWindow {...params} />
        </Provider>
    }
}
