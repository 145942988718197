import React from "react";
import {SelectValue} from 'antd/lib/tree-select'
import {TreeSelect, TreeSelectProps} from 'antd'
import {observer} from "mobx-react";
import "./antTreeSelect.less"
import b_ from 'b_'
import classnames from "classnames";

const b = b_.with('ant-tree-select')

export type AntTreeSelectValue = SelectValue;

export interface AntTreeSelectProps<VT extends AntTreeSelectValue> extends TreeSelectProps<VT> {
	hideMaxTagsIndicator?: boolean;
}

const AntTreeSelectUnobserved = <VT extends AntTreeSelectValue>(props: AntTreeSelectProps<VT>) => {
	const className = classnames(props.rootClassName, b({'hide-max-tags': props.hideMaxTagsIndicator}))

	return <TreeSelect {...props} rootClassName={className}/>;
}

export const AntTreeSelect = observer(AntTreeSelectUnobserved);
