import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridStore} from "controls/grid/gridStore"
import {GridState} from "controls/grid/gridState"
import {getSettings, saveSettings} from "framework/userSettings"
import {UserSettings} from "tools/userSettings";
import {copyViaSerializr} from "framework/serializr-integration";
import {serialize} from "serializr";

export interface  GridStateProvider<DataItem extends GridDataEntry>{
	getState(store: GridStore<DataItem>) : Promise<GridState<DataItem>>
	saveState(state: GridState<DataItem>, store: GridStore<DataItem>): Promise<void>
}

type UserSettingsStateProviderProps<DataEntry extends GridDataEntry> = {
	category: string

	//in case a field gets renamed
	mappings?:  Record<string, string>
}

export class UserSettingsStateProvider<DataEntry extends GridDataEntry> implements GridStateProvider<DataEntry>{
	props: UserSettingsStateProviderProps<DataEntry>

	constructor(props: UserSettingsStateProviderProps<DataEntry>) {
		this.props = props
	}

	async getState(store: GridStore<DataEntry>){
		if(!this.props.category)
			return null

		let state = await getSettings<GridState<DataEntry>>("grid-settings-" + this.props.category, {
			clazz: GridState,
			trackable: false,
			ignoreCache: true
		})

		this.updateLegacyFields(state)

		return state
	}

	updateLegacyFields(state: GridState<DataEntry>){
		if(!this.props.mappings)
			return

		for(const oldName of Object.keys(this.props.mappings)){
			const newName = this.props.mappings[oldName]

			for(const view of state.views) {
				for (let i = 0; i < state.groupBy.length; i++) {
					if (view.groupBy[i] == oldName) {
						view.groupBy[i] = newName as (keyof DataEntry)
					}
				}

				for (let i = 0; i < state.sortingOrder.length; i++) {
					if (view.sortingOrder[i] == oldName) {
						view.sortingOrder[i] = newName
					}
				}

				for(const column of view.columns){
					if(column.field == oldName){
						column.field = newName
					}
				}

				for(const rule of view.filters.iterateRules()){
					if(rule.properties.field == oldName){
						rule.properties.field = newName
					}
				}
			}

		}
	}

	async saveState(state: GridState<DataEntry>, store: GridStore<DataEntry>){
		await saveSettings("grid-settings-" + this.props.category, serialize(GridState<DataEntry>, state))
	}
}

export class ExternalStateProvider<DataItem extends GridDataEntry> implements GridStateProvider<DataItem>{
	state: GridState<DataItem>

	constructor(state: GridState<DataItem>) {
		this.state = state
	}

	async getState(store: GridStore<DataItem>){
		if(!this.state)
			return null

		return copyViaSerializr(this.state)
	}

	async saveState(state: GridState<DataItem>, store: GridStore<DataItem>){
		this.state = copyViaSerializr(state)
	}
}
