import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {Section} from "controls/react/layout/section";
import translator from 'core/localization/localization';
import {Toolbar} from "controls/react/layout/toolbar";

import './filteringPanel.less';
import FilteringItem from "areas/services/widgets/filterPanel/filteringItem";

import reducer, {ACTIONS, stateToObject} from './reducer';

import {b} from './filteringPanelShared'

const i = translator({
  "Type": {
    "no": "Type"
  },
  "Click to add additional filtering option": {
    "no": "Klikk for å legge til flere filtreringsmuligheter"
  }
});

const FilteringPanel = forwardRef((props, ref) => {
	const [state, dispatch] = React.useReducer(reducer, {});

	React.useEffect(() => {
		dispatch({ type: ACTIONS.SET_CONFIG, value: props.config, filterType: props.filterType });
	}, [props.config]);

	const onAddNewItem = React.useCallback(() => {
		dispatch({ type: ACTIONS.ADD_ITEM, filterType: props.filterType } );
	}, []);

	React.useEffect(() => {
		props.onChange(stateToObject(state));
	}, [state]);

	if (!state || !state.items) {
		return null;
	}

	return <Section appearance={'frame'} ref={ref}>
		<Toolbar title={i('Filtering')}>
			<span className={"glyphicons pointer plus"} onClick={onAddNewItem} title={i('Click to add additional filtering option')} />
		</Toolbar>
		<div className={b('content')}>
			{state.items.map((item, index) => (
				<FilteringItem key={index} index={index} items={state.items} item={item} dispatch={dispatch} filterType={props.filterType}/>
			))}
		</div>
	</Section>
})

FilteringPanel.propTypes = {
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

export default FilteringPanel;
