export function getDescriptions(){
	return [
		{
			url: '/teams/:id/profile',
			getModuleImport: () => import('./legacyLoader'),
			params: {
				tab: 'profile'
			},
			react: true,
			routeId: 'teams_profile'
		},
	];
}

export class TeamRouter {
	static profile(id) {
		return `/teams/${id}/profile`;
	}
	static ruleset(id) {
		return `/teams/${id}/ruleset`;
	}
	static channels(id) {
		return `/teams/${id}/ruleset/channels`;
	}
	static policy(id) {
		return `/teams/${id}/ruleset/policy`;
	}
	static notification(type, id, index) {
		return `/${type}/${id}/notifications/rulesets/${index}`
	}
}
