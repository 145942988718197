//import './antAlert.less'

import React from "react"
import classnames from 'classnames'
import { observer } from 'mobx-react'
import {Tree, TreeProps} from 'antd'
import {DataNode} from "antd/lib/tree";


export interface AntTreeDataNode extends Omit<DataNode,'children'>{
	children?: AntTreeDataNode[]
}

interface AntTreeProps<T extends AntTreeDataNode = AntTreeDataNode> extends TreeProps<T>{
}

const b = require('b_').with('ant-tree-wrapper')

export const AntTree = observer( (props: TreeProps) => {
	let {className,...antNativeProps} = props

	const classes = classnames(b(), className)

	return <Tree className={classes} {...antNativeProps}/>
})
