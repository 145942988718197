import './homeViewSettings.less'

import {observer} from "mobx-react"
import {makeAutoObservable} from "mobx"
import React, {useState} from "react"
import {DownOutlined, SettingOutlined, UpOutlined} from "@ant-design/icons"

import {AntCheckbox} from "controls/react/ant/antCheckbox"
import {Section} from "controls/react/layout/section"
import {translator} from "core/localization/localization"


import { AntPopover } from "controls/react/ant/antPopover"
import {apiFetch} from "framework/api"
import {getSummary} from "areas/views/api"
import {EventStateSummary} from "areas/summary/events/eventStateSummary";
import {ApplicationStateSummary} from "areas/application/widgets/stateWidget/applicationStateSummary"
import {SlaStateSummary} from "areas/sla/widgets/stateWidget/slaStateSummary"
import {AssetStateSummary} from "areas/assets/widgets/stateWidget/assetStateSummary"

import {ServiceStateSummary} from "framework/entities/service";

const b = require('b_').with('home-view-settings');
const i = translator();

export const HomeViewSettings = (props: {store: HomeViewStore, onChange?: () => void}) => {
	const [visible, setVisible] = useState(false);
	return (
		<AntPopover placement="bottomRight"
		            overlayClassName={b('popup-content')}
		            trigger="click"
		            title={i('Display settings')}
		            content={<ViewSettings store={props.store} onChange={props.onChange} />}
		            onOpenChange={visible => setVisible(visible)}>
			<SettingOutlined />
			<span>{i('Display settings')}</span>
			<span className={b('dropdown-icon')}>
				{!visible && <DownOutlined />}
				{visible && <UpOutlined />}
			</span>
		</AntPopover>
	)
}

export class HomeViewStore {
	slaSummary: SlaStateSummary
	assetSummary: AssetStateSummary
	applicationSummary: ApplicationStateSummary
	serviceSummary: ServiceStateSummary
	eventsSummary: EventStateSummary

	widgets: HomeViewWidgetConfig[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	addConfig = (id: string, label: string, value: boolean): void => {
		const config = new HomeViewWidgetConfig(id, label, value);
		this.widgets.push(config);
	}

	get = (id: string): HomeViewWidgetConfig => {
		return this.widgets.find(x => x.id == id);
	}

	refresh = async () => {
		const response = await apiFetch(getSummary());

		if(!response.success)
			return

		this.slaSummary = response.data.accSla
		this.assetSummary = response.data.accAssetHealth
		this.applicationSummary = response.data.applicationHealth
		this.serviceSummary = response.data.accState
		this.eventsSummary = response.data.eventState
	}

	get eventsStateTotal(){
		return this.eventsSummary?.total;
	}

	get applicationStateTotal(){
		return this.applicationSummary?.total;
	}

	get slaStateTotal(){
		return this.slaSummary?.total;
	}

	get assetStateTotal(){
		return this.assetSummary?.total;
	}

	get serviceStateTotal(){
		return this.serviceSummary?.total;
	}
}

class HomeViewWidgetConfig {
	constructor(public id: string, public label: string, public visible: boolean) {
		makeAutoObservable(this);
	}
}

const ViewSettings = observer((props: {store: HomeViewStore, onChange?: () => void}) => {
	return <Section appearance="none">
		{props.store.widgets.map((data: HomeViewWidgetConfig) => {
			return <div key={data.id} className={b('item')}>
				<AntCheckbox checked={data.visible} id={data.id} onChange={value => {
					data.visible = value;
					props.onChange?.();
				}}>{data.label}</AntCheckbox>
			</div>
		})}
	</Section>
});
