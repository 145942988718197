import {Localization} from "core";

export const sharedLocalization = new Localization({
  "Log entry": {
    "no": "Loggmelding",
    "en": "Log entry"
  },
  "Create Log Entry": {
    "no": "Opprett loggmelding",
    "en": "Create log entry"
  }
});
