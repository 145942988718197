import {ApplicationState} from "framework/applicationState";

export class Session {

	hasRole(role) {
		let result = ApplicationState.hasPermissions(role)
		return result
	}

	hasRoles(roles) {
		if (Array.isArray(roles)) {
			return roles.every(x => this.hasRole(x));
		}
		return this.hasRole(roles);
	}
}
