import './addAssetForm.less';
import React, {Component, useMemo} from 'react';
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";
import {Section} from "controls/react/layout";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {AntInput, AntTextArea} from "controls/react/ant/antInput";
import {AccountsSelector} from "controls/react/accountSelector";
import {AntSelect, AntSelectPropsGeneric} from "controls/react/ant/antSelect";
import {AssetTargetsEditor} from "controls/assetTargetsEditor";
import {TagsSelect} from "controls/react/tagsSelect";
import {useAssetGroups} from "controls/react/assetGroupMultiSelect";
import {MobxManager, ModelValidator} from "framework/mobx-integration";


let b = require('b_').with('add-asset-form');
const i18n = require('core/localization/localization').translator(sharedLocalization, {
	'GENERIC': {
		en: 'Generic',
		no: 'Generisk'
	},
	'WINDOWS': {
		en: 'Windows',
		no: 'Windows'
	},
	'UNIX': {
		en: 'Unix',
		no: 'Unix'
	},
	'System type': {no: 'Systemtype'},
	'Asset target': {no: 'Ressursm\u00e5l'},
	'Assetgroup': {no: 'Assetgroup'},
});

export type AddAssetFormData = {
	accountId: string;
	name?: string;
	description?: string;
	assetType?: string;
	systemType?: string;
	targets?: any[];
	assetGroups?: string[];
	tags?: string[];
	id?: string;
}

export type AddAssetFormParams = AddAssetFormData & {
	onChange?: (value: AddAssetFormData) => void;
	mode: 'view' | 'update' | 'create';
}
class AddAssetFormStore {
	mobx = new MobxManager();
	validator = new ModelValidator(this);
	accountId: string;
	name: string;
	description: string;
	assetType: string;
	systemType: string = 'GENERIC';
	targets: any[] = [];
	assetGroups: string[];
	tags: string[];
	id: string;

	get data() {
		return {
			accountId: this.accountId,
			name: this.name,
			description: this.description,
			assetType: this.assetType,
			systemType: this.systemType,
			targets: this.targets,
			assetGroups: this.assetGroups,
			tags: this.tags,
		}
	}
	constructor(private params: AddAssetFormParams) {
		makeAutoObservable(this);
		this.accountId = this.params.accountId;
		this.mobx.reaction(x => this.data, () => {
			this.params.onChange?.(this.data);
		});
		this.validator.required('name');
	}

	destroy = () => {
		this.mobx.destroy();
		this.validator.destroy();
	}
}

export const AddAssetForm = observer(class AddAssetFormImpl extends Component<AddAssetFormParams> {
	store: AddAssetFormStore;
	constructor(params: AddAssetFormParams) {
		super(params);
		this.store = new AddAssetFormStore(params);
	}

	componentWillUnmount() {
		this.store.destroy();
	}

	render() {
		return <Section containerClass={b()} appearance={'none'} contentPadding={false} childrenPadding={true}>
			<FormEntryNew label={i18n('Account')} model={this.store} modelField={'accountId'} disabled={this.props.mode == 'view'}>
				<AccountsSelector mode={null} />
			</FormEntryNew>
			<FormEntryNew label={i18n('Name')} model={this.store} modelField={'name'} disabled={this.props.mode == 'view'}>
				<AntInput />
			</FormEntryNew>
			<FormEntryNew label={i18n('Description')} model={this.store} modelField={'description'} disabled={this.props.mode == 'view'}>
				<AntTextArea autoSize={{minRows: 1, maxRows: 3}}  />
			</FormEntryNew>
			{this.props.mode != 'create' && <FormEntryNew label={i18n('Asset type')} model={this.store} modelField={'assetType'} disabled={this.props.mode == 'view'}>
				<AntInput />
			</FormEntryNew>}
			<FormEntryNew label={i18n('System type')} model={this.store} modelField={'systemType'} disabled={this.props.mode == 'view'}>
				<SystemType />
			</FormEntryNew>
			<FormEntryNew label={i18n('Asset target')} model={this.store} modelField={'targets'} disabled={this.props.mode == 'view'}>
				<AssetTargetsEditor targets={this.store.targets} />
			</FormEntryNew>
			<FormEntryNew label={i18n('Assetgroup')} model={this.store} modelField={'assetGroups'} disabled={this.props.mode == 'view'}>
				<AssetGroupSelect accountId={this.store.accountId} />
			</FormEntryNew>
			<FormEntryNew label={i18n('Tags')} model={this.store} modelField={'tags'} disabled={this.props.mode == 'view'}>
				<TagsSelect />
			</FormEntryNew>
			{this.props.mode != 'create' && <FormEntryNew label={i18n('CVID')} model={this.store} modelField={'id'}>
				<AntInput disabled={true}/>
			</FormEntryNew>}
		</Section>
	}
})

const SystemType = observer((props: AntSelectPropsGeneric<string>) => {
	const dataSource = useMemo(() => {
		let options = ['GENERIC', 'WINDOWS', 'UNIX'].map(x => ({name: i18n(x), id: x}));
		if (props.value != 'GENERIC') {
			options = options.filter(x => x.id == props.value);
		}
		return options;
	}, []) // props.value not in deps because if we select something not 'GENERIC' options shouldn't be changed
	return <AntSelect placeholder={null} dataList={dataSource} {...props} />
})

const AssetGroupSelect = observer((props: {accountId: string, value?: string[], onChange?: (value: string[]) => void}) => {
	const {accountId, ...others} = props;
	const [allAssetGroups] = useAssetGroups(accountId, 'STATIC');
	return <AntSelect mode={'multiple'} dataList={allAssetGroups} {...others} />
})
