import {Api} from "tools/api"
import {Team} from './team';
import {ApiRequestObservable} from "framework/api";
import Settings from "settings";

export class TeamApi {
	static getLiteUrl(accountId: string) {
		return `${Api.accountRoot(accountId)}teams/responsibleTeams/lite`;
	}
	static getAboveTeams(id: string, teamId: string){
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/teams/responsibleTeams/${teamId}`)
	}

	static getLite(accountId: string = null): Promise<Team[]>{
		return Api.fetch(TeamApi.getLiteUrl(accountId));
	}

	static getResponsibleTeamsLite(accountId: string, teamIds: string[]): Promise<Team[]>{
		return Api.fetchPost(TeamApi.getLiteUrl(accountId), teamIds);
	}

	static getSearchUrl() {
		return Api.accountRoot() + 'teams/lite?name=';
	}
}

export type GetResponsibleTeamsLiteArgs = {
	accountId?: string
	includeTeamIds?: string[]
}

export function getResponsibleTeamsLite(args?: GetResponsibleTeamsLiteArgs){
	return new ApiRequestObservable<Team[]>({
		method: 'POST',
		url: 'teams/responsibleTeams/lite',
		accountId: args?.accountId,
		accountBased: true,
		payload: args?.includeTeamIds ?? []
	})
}
