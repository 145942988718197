import React from "react";

import {getApplications} from "areas/application/api";
import {AntSelect} from "controls/react/ant/antSelect";
import {apiFetchObservable} from "framework/api";
import {observer} from "mobx-react";

const i = require('core/localization').translator({
  "Select an application...": {
    "no": "Velg applikasjon"
  }
})

export const ApplicationsDropDown = observer(props => {
	const {accountId, ...others} = props;
	const applications = apiFetchObservable(getApplications({accountId: accountId}))

	return (
		<AntSelect placeholder={i('Select an application...')}
		           dataList={applications.data}
		           loading={applications.loading}
		           {...others}
		/>
	);
})
