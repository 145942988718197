import {newGuid} from "tools/guid";
import Settings from "settings";
import {Utils} from "tools/utils";
import ErrorHandler from "core/errorHandler";
import {toggleEllipsis} from "tools/utils";

let kendoWindow = null
let windowId = null
let container = null
let grid = null

export function showActionsViewer(e, eventId) {
	e.preventDefault();
	e.stopPropagation();
	let windowWidth = 1100;
	let windowHeight = 400;
	let target = $(e.target);
	let top = target.offset().top + 30;
	if (top + windowHeight > window.innerHeight) {
		top = target.offset().top - windowHeight - 35;
	}
	let left = target.offset().left - (windowWidth / 2) + 50;
	let navWidth = $('.aside').width();
	if (left < navWidth) {
		left = navWidth;
	} else if (left + windowWidth > window.innerWidth) {
		left = window.innerWidth - windowWidth - 50;
	}

	ensureWindowExists(left, top, windowWidth, windowHeight)
	renderActionsGrid(eventId)
}

export function destroyActionsViewer(){
	grid?.destroy()
	grid = null

	kendoWindow.destroy()
	kendoWindow = null

	windowId = null

	container = null
}

function ensureWindowExists(left, top, width, height){
	if(kendoWindow == null) {
		container = document.createElement('div')
		document.body.appendChild(container)

		windowId = newGuid()

		let resizeTimeout = null
		kendoWindow = $(container).kendoWindow({
			width: width,
			height: height,
			position: {
				top: top,
				left: left
			},
			title: lang.ACTIONS,
			modal: false,
			resize: () => {
				clearTimeout(resizeTimeout);
				resizeTimeout = setTimeout(() => {
					let currentHeight = kendoWindow.options.height.split('px')[0];
					grid.setOptions({
						height: currentHeight
					});
				}, 50)
			}
		}).data('kendoWindow')
		State.mainApp.registerForOnNavigate(() => destroyActionsViewer())
	}else{
		kendoWindow.setOptions({
			position: {
				top: top,
				left: left
			}
		})
		kendoWindow.open()
	}

	kendoWindow.content(`<div id="${windowId}"></div>`);
}

function renderActionsGrid(eventId) {
	let actionsDataSource = new kendo.ceeview.DataSource({
		transport: {
			read: {
				url: Settings.serverPath + 'notifications/' + eventId + '/triggerActions',
				contentType: "application/json; charset=utf-8",
				type: "GET",
				dataType: "json",
				cache: false
			}
		},
		schema: {
			parse: function (result) {
				if (result.success) {
					return result.data;
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}
		},
		error: ErrorHandler.kendoServerError
	});

	grid = $('#' + windowId).kendoCustomGrid({
		dataSource: actionsDataSource,
		height: 400,
		resizable: true,
		sortable: true,
		noRecords: {
			template: `<div class="cw_no_records_container"><span class="cw_no_records_info">${lang.messages.CLEARED_ACTION_INFORMATION}</span></div>`
		},
		columns: [{
			field: 'time',
			title: lang.TIME,
			template: '#= Renderer.browserDateRenderer(time, "datetime") #',
			width: 200
		}, {
			field: 'actionType',
			title: lang.ACTION_TYPE,
			width: 100
		}, {
			field: 'actionData',
			title: lang.ACTION_DATA,
			width: 180,
			attributes: {
				class: 'ellipsis to_expand'
			}
		}, {
			field: 'callerId',
			title: lang.NOTIFICATION_TRIGGER
		}, {
			field: 'status',
			title: lang.STATUS,
			width: 100
		}],
		selectable: 'row',
		change: toggleEllipsis,
		// dataBound() {
		// 	if (!this.dataSource.data().length) {
		// 		$('#cw_event_summary_action_grid').find('.k-grid-content').css('overflow', 'hidden');
		// 	}
		// }
	}).data('kendoCustomGrid');
}



