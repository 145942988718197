import './gridMenuButton.less'

import React from "react"
import {observer} from "mobx-react"
import {MenuOutlined} from "@ant-design/icons"

import {AntMenu} from "controls/react/ant/antMenu"
import {GridMenuPlugin} from "controls/grid/plugins/grid-menu/gridMenuPlugin"
import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridStore} from "controls/grid/gridStore"

export type GridMenuButtonProps<DataItem extends GridDataEntry> = {
	store: GridStore<DataItem>
}

const b = require('b_').with('grid-menu-button')

export const GridMenuButton =  observer(<DataItem extends GridDataEntry>(props: GridMenuButtonProps<DataItem>) => {
	const plugin = props.store.plugins
		.find(x => x.id == 'grid-menu') as GridMenuPlugin<DataItem>

	const items = plugin.generateMenu(plugin.store.selection)

	const rootItem = {
		key: 'root',
		popupClassName: b('menu-container'),
		label: <MenuOutlined />,
		children: items
	}

	return <AntMenu className={b()}
	                onClick={(item) => plugin.onMenuItemClick(item, plugin.store.selection)}
	                mode={"horizontal"}
	                items={[rootItem]}/>
})

//@ts-ignore
GridMenuButton.displayName = 'GridMenuButton'
