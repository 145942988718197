import React from "react";

import {useServices} from "./api"
import {AntSelect, AntSelectProps, IdObject} from "controls/react/ant/antSelect"

const i18n = require('core/localization').translator()

export type ServicesSelectorProps<TServiceObj extends IdObject = {id: string}> = AntSelectProps<string, TServiceObj> & {
	accountId?: string
}
export const ServicesSelector = <TServiceObj extends IdObject = {id: string}>(props: ServicesSelectorProps<TServiceObj>) => {
	const {accountId, ...others} = props;
	const [services, loading] = useServices(accountId, {makeRequest: props.disabled !== true});

	return (
		<AntSelect placeholder={i18n('Select a service...')}
		           dataList={services}
		           loading={loading}
		           {...others}
		/>
	);
}
