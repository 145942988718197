import {call, put, select, takeEvery} from 'redux-saga/effects';
import QualifierAPI from "tools/apis/qualifierAPI";
import Dialog from 'controls/dialog';
import {
	BACK_STEP,
	BEGIN_SAVE_QUALIFIER,
	generateAction,
	GET_TEST,
	LOAD_QUALIFIER,
	LOAD_TEMPLATE,
	LOAD_WINDOW_SIZE,
	NEXT_STEP,
	OPEN_HELP, PREVALIDATE, PREVALIDATE_COMPLETE,
	RESET,
	RESET_STORE, RESET_WINDOW_SIZE,
	SAVE_QUALIFIER,
	SAVE_SIZE_POSITION,
	SET_FIHISH_BUTTON_DISABLED, SET_IGNORE_RESULTS,
	SET_IN_PROGRESS,
	SET_QUALIFIER_DATA,
	SET_QUALIFIER_WINDOW_SIZE, SET_SERVICE_TIMEZONE,
	SET_SHOW_VALIDATION,
	SET_STEP,
	SET_VALIDATION_DATA,
	SET_VALIDATION_STATUS,
	SHOW_FIELD_VALIDATION,
	SHOW_QUALIFIER_ERROR,
	TEST_CONFIGURATION
} from './reduxActions';
import {
	getElementId,
	getInOriginal,
	getQualifierFieldValues,
	getQualifierId,
	getServiceId,
	getStep,
	getTemplateId,
	isFormError,
	hasPostValidate, getOriginal, getInProgressStatus, getIgnoreResults
} from "./reduxReducer";
import {reset} from 'redux-form';
import {qualifierWizardForm} from "./constants";
import {UserSettings} from "tools";
import {ServicesApi} from 'areas/services/api';
import lang from "core/localization/lang";
import qualifierCache from 'areas/services/designer/qualifierCache';
import {openHelpWindow} from "../../../../layout/helpMapping";

const ALGORITHM = new Set([
    'properties.algorithm.operator',
    'properties.warning'
]);

let shouldOperatorBeSet = true;
export function* loadQualifierTemplate() {
    const serviceId = yield select(getServiceId);
    const templateId = yield select(getTemplateId);

	yield loadService();

	let result = yield call(QualifierAPI.loadTemplate, serviceId, templateId, true);
	if (result.success) {
		yield put(generateAction(SET_QUALIFIER_DATA, result));
	} else {
		yield put(generateAction(SHOW_QUALIFIER_ERROR, result));
	}
}

export function* loadService() {
	const serviceId = yield select(getServiceId);

	const {data: serviceData} = yield call(ServicesApi.getService, serviceId);

	if (serviceData?.operatingSchedule?.timeZone) {
		yield put(generateAction(SET_SERVICE_TIMEZONE, {timeZone: serviceData.operatingSchedule.timeZone}));
	}
}

export function* loadQualifier(config) {
	const serviceId = yield select(getServiceId);
	const elementId = yield select(getElementId);
	const qualifierId = yield select(getQualifierId);

	yield loadService();

	if (qualifierCache.cache[qualifierId]) {
		yield put(generateAction(SET_QUALIFIER_DATA, {data: qualifierCache.cache[qualifierId]}));
	} else if (qualifierId) {
		let result = yield call(QualifierAPI.loadQualifier, serviceId, elementId, qualifierId, true);
		qualifierCache.cache[result.data.id] = result.data;
		if (result.success) {
			yield put(generateAction(SET_QUALIFIER_DATA, result));
		} else {
			yield put(generateAction(SHOW_QUALIFIER_ERROR, result));
		}
	} else {
		const {data} = yield call(QualifierAPI.loadClipboard, serviceId);
		yield put(generateAction(SET_QUALIFIER_DATA, {data}));
	}
}

export function* transition_1_2() {
    const templateId = yield select(getTemplateId);

    yield put(generateAction(SET_STEP, {step: 'transition_1_2'}));

    if (templateId) {
        yield loadQualifierTemplate();
    } else {
        yield loadQualifier();
    }
    yield put(generateAction(SET_STEP, {step: 'step_2'}));
    shouldOperatorBeSet = true;
}

export function* nextStep() {
    const step = yield select(getStep);

    switch (step) {
        case 'step_1':
            yield transition_1_2();
            break;
    }
}

export function* backStep() {
	yield put(generateAction(SET_SHOW_VALIDATION, {status: false}));
    yield put(generateAction(SET_STEP, {step: 'step_1'}));
    // yield put(generateAction(SELECT_CATEGORY_ITEM, {id: null}))
}


export function* resetStore() {
    try {
        yield put(generateAction(RESET));
        yield put(reset(qualifierWizardForm));
    } catch(e) {
        console.log('Cannot reset the store');
    }
}

export function* testConfiguration() {
    const hasValidationErrors = yield select(isFormError);

    if (hasValidationErrors) {
        yield put(generateAction(SHOW_FIELD_VALIDATION, {status: true}));
        return;
    }

    const properties = yield select(getQualifierFieldValues);

    yield validateQualifier('test', properties);
}

function mergeValidationData(data) {
    const moveMessageToDetails = data.data.details.length === 0;
    if (moveMessageToDetails) {
        data.data.details.push({
            message: data.message,
            "success": false
        })
    }

    if (!data.data.validations) {
        return data;
    }

    for (const [field, message] of Object.entries(data.data.validations)) {
        data.data.errorFields.push(field);

        data.data.details.push({
            message: `Field '${field}': ${message}`,
            "success": false
        })
    }

    return data;
}

export function* validateQualifier(type, properties) {
    const serviceId = yield select(getServiceId);
    const elementId = yield select(getElementId);
    const qualifierId = yield select(getQualifierId);

	console.log('validateQualifier', serviceId, elementId, qualifierId)

    yield put(generateAction(SET_SHOW_VALIDATION, {status: false}));
    yield put(generateAction(SHOW_FIELD_VALIDATION, {status: false}));
    yield put(generateAction(SET_IN_PROGRESS, {status: true}));

    try {
        const {warning, ...otherProperties} = properties;

        if (otherProperties.authTypes) {
            delete otherProperties.authTypes;
        }

        const data = yield QualifierAPI.validate(serviceId, elementId, qualifierId, type, {
            values: otherProperties
        });

        if (type === "postValidate" && data.success) {
            yield put(generateAction(SAVE_QUALIFIER));
        } else if (type === "preValidate" && data.success) {
            yield put(generateAction(PREVALIDATE_COMPLETE, {status: true}));
        } else {
            const vData = mergeValidationData(data);
            vData.type = type;

            if (type === "postValidate" || type === "preValidate") {
                yield put(generateAction(SET_FIHISH_BUTTON_DISABLED, {status: true}));
            }

            if (type === "preValidate") {
                yield put(generateAction(PREVALIDATE_COMPLETE, {status: false}));
            }

            yield put(generateAction(SET_VALIDATION_STATUS, {status: data.success}));
            yield put(generateAction(SET_VALIDATION_DATA, {data: vData}));
            yield put(generateAction(SHOW_FIELD_VALIDATION, {status: true}));

            return false;
        }
    } catch (e) {
        console.error(e);

        let message = e.message;

        try {
            message = JSON.parse(message).message;
        }
        catch(e) {
            // skip
        }

        if (type === "postValidate" || type === "preValidate") {
            yield put(generateAction(SET_FIHISH_BUTTON_DISABLED, {status: true}));
        }

        if (type === "preValidate") {
            yield put(generateAction(PREVALIDATE_COMPLETE, {status: false}));
        }

        yield put(generateAction(SET_VALIDATION_STATUS, {status: false}));
        yield put(generateAction(SET_VALIDATION_DATA, {
            data: {
                success: false,
                data: {
                    details: [{
                        "message": message,
                        "success": false
                    }]
                }
            }
        }));
    }
    finally {
        yield put(generateAction(SET_IN_PROGRESS, {status: false}));
    }
}

export function* getTest() {
    const serviceId = yield select(getServiceId);
    const elementId = yield select(getElementId);
    const qualifierId = yield select(getQualifierId);

    try {
		yield put(generateAction(SET_IN_PROGRESS, {status: true}));
        const data = yield QualifierAPI.getTest(serviceId, elementId, qualifierId);
		yield put(generateAction(SET_IN_PROGRESS, {status: false}));

		const ignoreResult = yield select(getIgnoreResults);
		if (!ignoreResult) {
			data.type = 'test';

			yield put(generateAction(SET_VALIDATION_STATUS, {status: data.success}));
			yield put(generateAction(SET_VALIDATION_DATA, {data}));
		} else {
			yield put(generateAction(SET_IGNORE_RESULTS, {status: false}));
		}
    } catch (e) {
        yield put(generateAction(SET_VALIDATION_STATUS, {status: false}));
        yield put(generateAction(SET_VALIDATION_DATA, {
            data: {
                success: false,
                data: {
                    details: [{
                        "message": e.message,
                        "success": false
                    }]
                }
            }
        }));
		yield put(generateAction(SET_IN_PROGRESS, {status: false}));
		yield put(generateAction(SET_IGNORE_RESULTS, {status: false}));
    }
}

export function* setFieldValue({meta: {field}, payload}) {
    if (!ALGORITHM.has(field)) {
        return;
    }
    if (field === 'properties.warning') {
        shouldOperatorBeSet = false;
    }
    if (!shouldOperatorBeSet) {
        return;
    }
    yield put(change(qualifierWizardForm, `properties.warning.operator`, payload));
}

export function* saveSizeAndPosition({top, left, width, height}) {
    const panelPos = $('.geDiagramContainer').offset();
    const panelWidth = $('.geDiagramContainer').width();
    const panelHeight = $('.geDiagramContainer').height();

	if (!panelPos || !panelWidth || !panelHeight) {
		return;
	}

    yield put(generateAction(SET_QUALIFIER_WINDOW_SIZE, {
        top,
        left,
        width,
        height
    }));

    try {
        yield call([UserSettings, UserSettings.set], 'ServiceQualifierWindowConfig', {
            sqwizard: {
                top,
                left,
                width,
                height
            }
        });
    } catch(e) {
        console.warn('Failed to save configuration: ' + e.message);
    }
}

export function* loadWindowSize(args) {
	let config

	if (args?.autoLayout !== true) {
		try {
			config = yield call([UserSettings, UserSettings.get], 'ServiceQualifierWindowConfig', 'sqwizard');
		} catch (e) {
		}
	}

	if (config) {
		yield put(generateAction(SET_QUALIFIER_WINDOW_SIZE, config));
	} else {
		yield put(generateAction(RESET_WINDOW_SIZE))
	}
}

export function* resetWindowSize(){
	const step = yield select(getStep);

	const {width, height, top, left} = getDefaultPosition(step)

	yield put(generateAction(SET_QUALIFIER_WINDOW_SIZE, {width, height, left, top}));
}

export function getDefaultPosition(step){
	const width = step === 'step_1' ? 400: 885;
	const height = 700;
	let left = 0;
	let top = 0;

	if ($('.geDiagramContainer').get(0)) {
		const panelPos = $('.geDiagramContainer').offset();
		left = panelPos.left + ($('.geDiagramContainer').width() - parseInt(width)) / 2;
		top = panelPos.top + ($('.geDiagramContainer').height() - parseInt(height)) / 2 - 100;
	}

	return {width, height, left, top}
}

function showSharedQualifierDialog() {
    return new Promise((resolve) => {
        var dialog = new Dialog({
            title: lang.INFO,
            msg: lang.service.sharedQualifiers.EDIT_SHARED_QUALIFIER,
            icon: 'WARNING',
            buttons: {
                ok: true,
                cancel: true
            },
            fn: (value, button) => {
                if (button === 'ok') {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            }
        });
        dialog.show();
    })
}

export function* beginSaveQualifier() {
    const shared = yield select(getInOriginal, 'shared');

    if (shared) {
        const pass = yield showSharedQualifierDialog();

        if (!pass) {
            return;
        }
    }

    const hasValidationErrors = yield select(isFormError);

    if (hasValidationErrors) {
        yield put(generateAction(SHOW_FIELD_VALIDATION, {status: true}));
        return;
    }

    const postValidate = yield select(hasPostValidate);

    if (postValidate) {
        const properties = yield select(getQualifierFieldValues);
        yield call(validateQualifier, 'postValidate', properties);
    }
    else {
        yield put(generateAction(SAVE_QUALIFIER));
    }
}

export function* openHelp() {
    const step = yield select(getStep);
    const serviceId = yield select(getServiceId);
    let className;
    if (step == 'step_1') {
        className = 'ALL';
    } else {
        className = yield select(getInOriginal, 'className');
    }
    const {data} = yield call(QualifierAPI.loadHelp, serviceId, className);
	openHelpWindow(data);
}

export function* preValidate({properties}) {
    return yield validateQualifier('preValidate', properties);
}

export default function* rootSaga() {
    yield takeEvery(LOAD_QUALIFIER, loadQualifier);
    yield takeEvery(LOAD_TEMPLATE, loadQualifierTemplate);
    yield takeEvery(NEXT_STEP, nextStep);
    yield takeEvery(BACK_STEP, backStep);
    yield takeEvery(RESET_STORE, resetStore);
    yield takeEvery(TEST_CONFIGURATION, testConfiguration);
    yield takeEvery(SAVE_SIZE_POSITION, saveSizeAndPosition);
    yield takeEvery(LOAD_WINDOW_SIZE, loadWindowSize);
    yield takeEvery(RESET_WINDOW_SIZE, resetWindowSize);
    yield takeEvery(GET_TEST, getTest);
    yield takeEvery(BEGIN_SAVE_QUALIFIER, beginSaveQualifier);
    yield takeEvery(OPEN_HELP, openHelp);
    yield takeEvery(PREVALIDATE, preValidate);
};
