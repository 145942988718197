import Utils from 'tools/utils'
import Cookies from 'core/cookies'
import {NavigationAction, NavigationStore} from "framework/navigationStore"

export default function Breadcrumb(config) {
	Utils.apply(this, config);
	this.initComponent();
};

Breadcrumb.prototype = {
	/**
	 * The html string of a breadcrumb item.
	 * @const
	 * @type {string}
	 */
	itemHtml: '<i class="cw_divider glyphicons arrow-right"></i><span class="cw_breadcrumb_item"></span>',
	/**
	 * Main init class
	 */
	initComponent: function () {
		this.module = {};
		this.items = [];
		this.currentItem = {};

		this.selector = $('.cw_breadcrumbb');
		this.moduleSelector = $('.cw_breadcrumb').find('.module');

		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Attaches listeners
	 */
	removeListeners: function () {
		this.selector.off();
	},
	/**
	 * Removes listeners
	 */
	attachListeners: function () {
		this.selector.on('click', '.cw_breadcrumb_item', $.proxy(this.onItemClick, this));
	},
	/**
	 * Sets the breadcrumb module (first item)
	 * @param {object} module The module configuration
	 * @param {boolean} activeLink Optional parameter used to set the link active or inactive
	 */
	setModule: function (module, activeLink) {

		var link = (typeof activeLink !== 'undefined') ? activeLink : true;
		if (module.name && module.application) {
			NavigationStore.push({
				title: module.name,
				config:{
					moduleName: module.application,
					...module
				}
			}, {
				action: module.config?.navigation ?? NavigationAction.Clear
			});
			this.module = module;
			this.moduleSelector.text(this.module.name);
			this.moduleSelector.addClass('current');
			if (link) {
				this.moduleSelector.removeClass('inactive');
			} else {
				this.moduleSelector.addClass('inactive');
			}
			this.moduleSelector.attr('data-application', this.module.application);
			this.moduleSelector.data('application', this.module.application);
			this.moduleSelector.data('ceeviewBreadcrumbItem', this.module);
			this.items = [];
			this.moduleSelector.nextAll().remove();
		} else {
		}
	},
	/**
	 * Adds an item to the breadcrumb
	 * @param {object} item The breadcrumb item configuration
	 * @param {boolean} activeLink Optional parameter used to set the link active or inactive
	 */
	addItem: function (item, activeLink) {
		var itemSelector, link = (typeof activeLink !== 'undefined') ? activeLink : true;
		//exception case for Services, because of two pages: one react the other legacy
		if(item.name == 'Service')
			return;
		if (item.name && item.application) {

			NavigationStore.push({
				title: item.name,
				config:{
					moduleName: item.application,
					...item.config
				}
			}, {
				action: NavigationAction.RewindToTheSame
			});


			this.currentItem = item;
			this.clean();
			this.items.push(this.currentItem);
			this.selector.append(this.itemHtml);
			this.selector.find('.cw_breadcrumb_item').removeClass('current');
			itemSelector = this.selector.find('.cw_breadcrumb_item').last();
			itemSelector.text(this.currentItem.name);
			itemSelector.addClass('current');
			if (!link) {
				itemSelector.addClass('inactive');
			}
			itemSelector.attr('data-application', this.currentItem.application);
			itemSelector.data('application', this.currentItem.application);
			itemSelector.data('ceeviewBreadcrumbItem', this.currentItem);
		} else {
		}
	},
	/**
	 * Cleans the breadcrumb of items starting at the current one
	 */
	clean: function () {
		var i, index = -1, length = this.items.length, itemHtml;
		for (let i = 0; i < length; i++) {
			if (this.items[i].application === this.currentItem.application) {
				index = i;
				break;
			}
		}
		if (index !== -1) {
			this.items.splice(index, length - index);
			itemHtml = this.selector.find('span[data-application="' + this.currentItem.application + '"]');
			itemHtml.prev().remove();
			itemHtml.nextAll().remove();
			itemHtml.remove();
		}
	},
	/**
	 * Handler function for the click event on an breadcrumb item
	 * @param {Object} e The click event object
	 */
	onItemClick: function (e) {
		var item = $(e.currentTarget),
			application = item.data('application'),
			data = item.data('ceeviewBreadcrumbItem'),
			config = data ? data.config || {} : {},
			id = config ? config.id || null : null,
			module;

		if (!item.hasClass('current') && !item.hasClass('inactive') && application) {
			if (item.hasClass('module')) {
				module = item.data('ceeviewBreadcrumbItem');
				if (module.name === lang.HOME) {
					application = 'Summary';
					config = {};
				}
				this.items = [];
				this.moduleSelector.addClass('current');
				this.moduleSelector.nextAll().remove();
			} else {
				this.clean();
			}
			config.name = item.text();
			var isFromBreadcrumb = true;
			State.mainApp.loadModule(application, id, config, e, isFromBreadcrumb);
		}
	}
}
