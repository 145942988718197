export enum TotalMetricType {
	None = 'none',
	Metric = 'metric',
	UserInput = 'user_input'
}

export enum DisplayUnitType {
	None = 'NONE',
	MetricUnit = 'METRIC_UNIT',
	TotalUnit = 'TOTAL_UNIT',
	CustomUnit = 'CUSTOM_UNIT'
}

export enum DisplayLabelType {
	None = 'NONE',
	Metric = 'METRIC_DISPLAY_LABEL',
	Total = 'TOTAL_DISPLAY_LABEL',
}
