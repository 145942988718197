import {createModelSchemaWrapper} from "framework/serializr-integration";
import {makeAutoObservable} from "mobx";
import {list, primitive} from "serializr";

export class SaveTagsModel {
	private initialValues: [string[], boolean, boolean];

	constructor(public tags: string[], public showUntagged: boolean, public ignoreTags: boolean) {
		this.initialValues = [this.tags, this.showUntagged, this.ignoreTags];
		makeAutoObservable(this);
	}

	get dirty() {
		return JSON.stringify(this.initialValues) != JSON.stringify([this.tags, this.showUntagged, this.ignoreTags]);
	}
}


createModelSchemaWrapper(SaveTagsModel, {
	ignoreTags: primitive(),
	showUntagged: primitive(),
	tags: list(primitive()),
})
