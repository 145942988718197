import {DataList} from "framework/entities/dataList";

const i18n = require('core/localization').translator()

export enum CostValueType{
	Budget = 'BUDGET',
	Estimate = 'ESTIMATE',
	Cost = 'COST'
}

let costTypeDataList: DataList<string>
export function getCostTypeDataList(){
	if(costTypeDataList == null) {
		return [{
			name: i18n('Budget'),
			id: 'BUDGET'
		}, {
			name: i18n('Cost'),
			id: 'COST'
		}, {
			name: i18n('Estimate'),
			id: 'ESTIMATE'
		}];
	}

	return costTypeDataList
}
