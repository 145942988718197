import * as React from 'react';
import CollapsibleFrame from "controls/react/layout/collapsibleFrame";
import Lang from "core/localization/lang";

import {useContextField} from 'core/react/reducerHelpers';
import {DropDownList, FormEntry} from "controls/react/form";
import chartTypeDataSource from "./chartTypeDataSource";
import { translator } from "core";
import {ScriptWindow, setEditorValue} from "./scriptWindow";

import "./customMetricEditor.less"
import TypeFactory from './types/typeFactory';

const b = require('b_').with('custom-metric-editor');

const i = translator({
  "Graph Type": {
    "no": "Graftype"
  },
  "SCRIPT_TOOLTIP": {
    "en": "By selecting a chart type above we provide a basic example script for the given chart type.",
    "no": "Editoren tillater brukeren å redigere diagraminnstillingene med Javascript. Ved å velge en diagramtype ovenfor gir vi et enkelt eksempel for den gitte diagramtypen."
  },
  "Script": {
    "no": "Skript"
  },
  "Click to open in editor": {
    "no": "Klikk for å åpne i editor"
  }
});

interface CustomMetricEditorProps {
	context: any;
	type: string;
	config: any;
	onChange: () => void;
}

function CustomMetricEditor(props: CustomMetricEditorProps) {
	const chartTypeLink = useContextField(props.context, "configuration.chartType");
	const highchartConfigLink = useContextField(props.context, "configuration.highchartConfig");

	const [parsedCode, setParsedCode] = React.useState(highchartConfigLink.value);
	const [showWindow, setShowWindow] = React.useState(false);

	const originalRef = React.useRef({
		type: chartTypeLink.value,
		config: highchartConfigLink.value
	});

	React.useEffect(() => {
		if (!chartTypeLink.value) {
			chartTypeLink.onChange("area")
		}
	}, [chartTypeLink.value])

	React.useEffect(() => {
		if (chartTypeLink.value !== originalRef.current.type) {
			const newType = TypeFactory.get(chartTypeLink.value);
			const currentType = TypeFactory.get(originalRef.current.type);

			const config = newType.defaultConfiguration();

			if (originalRef.current.type && highchartConfigLink.value) {
				const originalMetrics = currentType.getMetrics(JSON.parse(highchartConfigLink.value));
				newType.addOriginalMetrics(config, originalMetrics);
			}

			highchartConfigLink.onChange(JSON.stringify(config));

			originalRef.current.type = chartTypeLink.value;
		}
	}, [chartTypeLink.value, highchartConfigLink]);

	const handleOpenWindow = React.useCallback((e) => {
		setShowWindow(true);
		e.preventDefault();
		e.stopPropagation();
	}, [])

	const scriptTitle = React.useMemo(() => {
		return <div className={b('script-title')}>
			{i('Script')}
			<i className="glyphicons pointer question-sign" title={i('SCRIPT_TOOLTIP')}/>
			<div className={b('buffer')}/>
			<a onClick={handleOpenWindow} >{i('Click to open in editor')}</a>
		</div>;
	}, [])

	React.useEffect(() => {
		if (highchartConfigLink.value) {
			try {
				const code = JSON.parse(highchartConfigLink.value);
				setParsedCode(code);
			} catch(e) {
				console.log('Code is invalid, skip saving');
			}
		}
	}, [highchartConfigLink.value]);

	const handleSave = React.useCallback((value) => {
		highchartConfigLink.onChange(value)
		setShowWindow(false);
	}, [highchartConfigLink.onChange]);

	const handleClose = React.useCallback(() => {
		setShowWindow(false);
	}, []);

	return <React.Fragment>
		<CollapsibleFrame title={Lang.CONFIGURATION} expanded={props.expanded}>
			<FormEntry label={i('Graph Type')}>
				<DropDownList dataSource={chartTypeDataSource} {...chartTypeLink.props} />
			</FormEntry>
			{scriptTitle}
		</CollapsibleFrame>
		{showWindow && <ScriptWindow initialCode={parsedCode} onClose={handleClose} onSave={handleSave} />}
	</React.Fragment>
}

export default CustomMetricEditor;
