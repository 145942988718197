import { sharedLocalization } from "controls/designer/features/widgets/localization";
import { Renderer } from 'tools';

const i = require('core/localization').translator(sharedLocalization, {
	'Size': {
		no: 'Størrelse'
	},
	'Icon': {
		no: 'Ikon'
	}
});

export let ServiceDocumentColumns = {
	get() {
		return [{
			field: 'type',
			title: i('Icon'),
			shown: true,
			template: item => Renderer.fileIcon(item.type),
			width: 65,
		}, {
			field: 'name',
			title: i('Name'),
			shown: true,
			template: item => `<a class="cw_grid_link">${item.name}</a>`,
			width: 220,
		}, {
			field: 'created',
			title: i('Created'),
			shown: true,
			template: item => Renderer.browserDateRenderer(item.created, "datetime", ""),
			width: 140,
		}, {
			field: 'serviceName',
			title: i('Service'),
			shown: true,
			width: 140,
		}, {
			field: 'accountName',
			title: i('Account'),
			shown: true,
			width: 125,
		}, {
			field: 'size',
			title: i('Size'),
			shown: true,
			template: item => Renderer.fileSize(item.size, item.type),
			width: 70,
		}, {
			field: 'description',
			title: i('Description'),
			shown: true,
			width: 150,
		}]
	}
};

export default ServiceDocumentColumns;
