import {list, map, object, primitive, } from "serializr"
import {makeAutoObservable} from "mobx";

import {WidgetConfig} from "controls/designer/features/widgets/widgetConfig";
import {RedirectConfig} from "controls/designer/features/redirectOnClick/redirectConfig";
import {CellDataSource} from "controls/designer/dataSourcesManager/cellDataSource";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {createModelSchemaWrapper, optionalExt, rawCopy} from "framework/serializr-integration";


export class CustomDataEntry{
	id: string
	widgetConfig?: WidgetConfig
	redirectConfig?: RedirectConfig
	dataSource?: CellDataSource
	persistedState?: any
	temporary?: boolean
	cell?: MxCell
	notes: Record<string, string> = {}

	constructor(source?: Partial<CustomDataEntry>) {
		Object.assign(this, source)
		makeAutoObservable(this)
	}
}

createModelSchemaWrapper(CustomDataEntry, {
	id: primitive(),
	widgetConfig: optionalExt(rawCopy()),
	redirectConfig: optionalExt(rawCopy()),
	dataSource: optionalExt(rawCopy()),
	persistedState: rawCopy(),
	notes: optionalExt(map(primitive()))
});
