import './dependencies.less'

import {CustomNotification} from "controls/customNotification";

import Utils from 'tools/utils'

import {ServiceDesignerRouter} from "areas/services/designer/bundleDescription";
import {ApplicationsRouter} from "areas/application/bundleDescription";
import {SlaRouter} from 'areas/sla/bundleDescription';
import {apiFetch} from "framework/api";
import {getMonitorType} from "areas/assets/monitors/api";
import {NavigationStore} from "../framework/navigationStore";
import {ServicesRouter} from "../areas/services/bundleDescription";
import {AdministrationRouter} from "areas/administration/bundleDescription";
import {MonitorsRouter} from "areas/assets/monitors/bundleDescription";
import {IncidentsRouter} from "areas/incidents/bundleDescription";

const i = require('core/localization').translator({
	'Hard' : {no:'Hard'},
	'Soft': {no:'Soft'},
	'Manual': {no: 'Håndbok'},
	'Dependent component will not be deleted':{
		no:'Avhengige komponenter vil ikke bli slettet'
	},
	'Dependent component will be deleted' : {
		no: 'Avhengige komponenter blir slettet'
	},
	'Dependent component must be manually removed' : {
		no: 'Avhengig komponent må fjernes manuelt'
	}
});

export let Dependencies = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default Dependencies;

jQuery.extend(Dependencies.prototype, {
	/**
	 * @cfg {string} accountId Mandatory. The account from where the entity is removed
	 */
	accountId: null,
	/**
	 * @cfg {Array} dependencies The array with dependencies records
	 */
	dependencies: [],
	/**
	 * @cfg {String} container The container in which the control is displayed
	 */
	container: null,
	/**
	 * @cfg {String} selectedChecks The selected checkboxes at pop-up opening
	 */
	selectedChecks: null,
	/**
	 * @cfg {String} infoTemplate Top info message template. Must contain ${name} parameter inside it
	 */
	infoTemplate: '${name}',
	/**
	 * @cfg {String} name The entity name
	 */
	name: '',
	initComponent: function () {
		this.attachListeners();
		this.initKendoComponents();
		var messageTemplate = kendo.template(this.infoTemplate);
		var message = messageTemplate({
			name: this.name
		});
		$('.cw_dependencies_information').find('strong').html(message);
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: '.cw_modal',
			type: 'plain'
		});
	},
	attachListeners: function () {
		$('#cw_dependencies_close').off().on('click', $.proxy(this.onClose, this));
		$('#cw_dependencies_deleteall').off().on('click', $.proxy(this.onDeleteAll, this));
		$('.cw_dependencies_list').off().on('click', '.cw_grid_link', $.proxy(this.onDependencyName, this));
		$('.dependencies_form').closest('.k-window-content').data('kendoWindow').bind('resize', this.adjustTableHeight);
		var manualDependencies = this.dependencies.some(item => item.dependencyType === 'MANUAL');
		$('#cw_dependencies_deleteall').prop('disabled', manualDependencies);
	},
	initKendoComponents: function () {
		var length = this.dependencies.length, description;
		if (length > 0) {
			for (let i = 0; i < length; i++) {
				if (this.dependencies[i].type === 'SERVICE_QUALIFIER') {
					try {
						description = JSON.parse(this.dependencies[i].description);
						if (description.b) {
							this.dependencies[i].description = description.n + ' - ' + description.d;
						} else {
							this.dependencies[i].description = description.d;
						}
					} catch (Error) {
					}
				}
			}
		}
		this.dependenciesGrid = $('.cw_dependencies_list').kendoCustomGrid({
			dataSource: new kendo.ceeview.DataSource({
				data: this.dependencies
			}),
			sortable: {
				mode: "multiple"
			},
			resizable: true,
			columns: [{
				field: 'type',
				title: lang.TYPE,
				width: 150,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'name',
				title: lang.NAME,
				template: '<a class="cw_grid_link" data-id="${id}">${name}</a>',
				width: 240,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'dependencyType',
				title: 'Dependency Type',
				template: function(data){
					let cellContent =''
					if(data.dependencyType === 'SOFT')
						cellContent = `<span title='${i('Dependent component will not be deleted')}'>${i('Soft')}</span>`
					else if(data.dependencyType === 'HARD') {
						cellContent = `<span title='${i('Dependent component will be deleted')}'><b>${i('Hard')}</b></span>`
					} else if(data.dependencyType === 'MANUAL') {
						cellContent = `<span title='${i('Dependent component must be manually removed')}'><b>${i('Manual')}</b></span>`
					}
					return cellContent;
				},
				width: 240,
				attributes: {
					"class": "expand ellipsis"
				}
			},{
				field: 'accountName',
				title: lang.ACCOUNT,
				template: '#= accountName ? accountName : "N/A"#',
				width: 100,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'parentId',
				title: lang.PARENT,
				width: 150
			}, {
				field: 'id',
				title: lang.ID,
				width: 150
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				attributes: {
					"class": "expand ellipsis"
				}
			}],
			dataBound: $.proxy(this.adjustTableHeight, this),
			change: $.proxy(this.onRowExpand, this),
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.dependenciesGrid);
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},
	onClose: function (event) {
		$(event.currentTarget).closest('.k-window-content').data('kendoWindow').close();
		if (this.container) {
			kendo.ui.progress($(this.container), false);
		}
		if (this.selectedChecks) {
			$("input[data-id='" + this.selectedChecks + "']").prop('checked', true);
		}
		if(this.cancel) {
			this.cancel.call(this, event);
		}
	},
	onDeleteAll: function (e) {
		if (this.deleteAll) {
			this.deleteAll.call(this, e);
		}
	},
	onDependencyName: async function (event) {
		event.preventDefault();
		var dependencyId = $(event.currentTarget).attr('data-id'),
			dependency = this.dependenciesGrid.dataSource.get(dependencyId);
		var serviceName;
		$(event.currentTarget).closest('.k-window-content').data('kendoWindow').close();
		switch (dependency.type) {
			case 'SERVICE_QUALIFIER':
				try {
					serviceName = $(e.currentTarget).text().split('/')[0];
				} catch (ex) {
					serviceName = '';
				}
				if (State.mainApp.session.hasRole('SERVICE_MODEL_UPDATE')) {
					var config = {
						parentId: dependency.parentId,
						serviceId: dependency.parentId,
						serviceName: serviceName,
						serviceAccountId: this.accountId,
						serviceAccountName: dependency.accountName,
						highlight: {
							qualifierId: dependency.id
						},
						event: event
					};

					let utils = await import ( "areas/services/utils");
					if (!await utils.isServiceLocked(dependency.parentId)) {
						State.mainApp.navigate(ServiceDesignerRouter.root(dependency.parentId));
					}
				} else {
					this.showStatusMessage(lang.service.messages.SERVICE_MODEL_UPDATE_PERMISIONS, 'error');
				}
				break;
			case 'ACCOUNT':
				NavigationStore.go(AdministrationRouter.listAccounts());
				break;
			case 'AGENT':
			case 'SUBAGENT':
				State.mainApp.loadModule('AgentsView', null, {}, event);
				break;
			case 'MONITOR':
				var monitorId = dependency.id;
				let result = await apiFetch(getMonitorType(monitorId, dependency.accountId));
				if (result.success) {
					var type = result.data.type;
					NavigationStore.go(MonitorsRouter.details(type, monitorId))
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
				break;
			case 'SERVICE':
				NavigationStore.go(ServicesRouter.details(dependency.id));
				break;
			case 'SERVICE_ELEMENT':
				try {
					serviceName = $(e.currentTarget).text().split('/')[0];
				} catch (ex) {
					serviceName = '';
				}
				State.mainApp.navigate(ceeview.router.web.services.designer(dependency.parentId));
				break;
			case 'SLA':
				State.mainApp.navigate(SlaRouter.details(dependency.id));
				break;
			case 'APPLICATION':
				State.mainApp.navigate(ApplicationsRouter.details(dependency.id));
				break;
			case 'INCIDENT':
				State.mainApp.navigate(IncidentsRouter.details(dependency.id));
				break;
		}
	},
	adjustTableHeight: function () {
		let table = $('.cw_dependencies_list');
		let	tableHeight = $('.dependencies_form').closest('.k-window-content').parent().height() - 56 - 85;
		table.css('height', tableHeight);
		table.find('.k-grid-content').css('height', tableHeight - 27);
	},
	/**
	 * Slides-down a status with a given message
	 * @param {String} message The message to be displayed
	 * @param {String} statusClass The CSS class to apply to the status
	 */
	showStatusMessage: function (message, statusClass) {
		this.statusNotification.setOptions({
			message: message,
			status: statusClass
		}).show();
	},
});

