import {Api} from "tools/api"
import {ApiRequest} from "framework/api";
import {DataListTextValue} from "framework/entities/dataList";

export class AdministrationApi {
	static getUserUrl(id: string) {
		return Api.accountRoot() + `users/${id}/?update=true`;
	}

	static getAssetGroupUrl(accountId: string, id: string, update: boolean) {
		return Api.accountRoot(accountId) + `assetGroups/${id}/?update=${update}`;
	}

	static getAuthenticationUrl(accountId: string, id: string, update: boolean) {
		return Api.accountRoot(accountId) + `authentications/${id}/?update=${update}`;
	}

	static getTeamUrl(accountId: string, id: string, update: boolean) {
		return Api.accountRoot(accountId) + `teams/${id}/?update=${update}`;
	}
}

export function getAuthentications(accountId: string) {
	let url = 'authentications/list'
	return new ApiRequest({
		url,
		accountBased: true,
		accountId
	})
}
