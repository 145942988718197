import {makeAutoObservable, toJS} from "mobx"
import {observer} from "mobx-react"
import React from "react"

import {Section} from "controls/react/layout/section"
import {AccountSelector} from "controls/react/accountSelector"
import {MobxManager, SingleToArrayConverter} from "framework/mobx-integration"
import {AntSelect, AntSelectProps, textValueFields} from "controls/react/ant/antSelect"
import {CellDataSourceType} from "controls/designer/dataSourcesManager/cellDataSourceType"
import {useRemoteList} from "framework/api";
import {getKpiAccounts, getKpiCategories, getKpiProfiles, getKpiRuleTypes, getKpiSampleRates} from "areas/kpi/api"
import {
	MetricValueAsSeverity,
	MetricValueToSeverityHolder
} from "controls/designer/dataSourcesManager/metricValueAsSeverity";
import {ShowAsLabel} from "controls/designer/dataSourcesManager/sharedProperties";
import {CellDataSourceBase} from "controls/designer/dataSourcesManager/cellDataSource";
import {Designer} from "controls/designer/designer";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {KpiDataSourceElement} from "controls/designer/dataSourcesManager/kpiDataSourceElement";
import {DataSourceEditorContext} from "controls/designer/dataSourcesManager/dataSourceEditorContext";
import {DesignerStore} from "controls/designer/designerStore";
import {ApplicationState} from "framework/applicationState";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {useStore} from "core/react/useStore";

const i18n = require('core/localization/localization').translator({
	"Select a profile...": {
		"no": "Velg en profil..."
	},
	"Select a category...": {
		"no": "Velg en kategori..."
	},
	"Select a rule type...": {
		"no": "Velg en regeltype..."
	},
	"Rule Type": {
		"no": "Regeltype",
	},
	"Select a sample rate...": {
		"no": "Velg tid"
	},
	"Sample Rate": {
		"no": "tid"
	},
	"Select a KPI account...": {
		"no": "Velg KPI konto"
	},
	"KPI Account": {
		"no": "KPI konto"
	},
})

export class CellKpiDatasource implements MetricValueToSeverityHolder, CellDataSourceBase{
	id: string
	accounts: string[] = []
	showAsLabel: boolean
	type: CellDataSourceType.Kpi = CellDataSourceType.Kpi
	profileId: string
	categoryId: string
	ruleTypeId: string
	sampleRateId: string
	configurationTypeId: string
	accountId: string
	metricValueAsSeverity: boolean
	hideMetricValue: boolean
	metricValueToSeverity: {
		operator: 'gte'|'lte',
		threshold: number
	}[] = [{
		operator: "lte",
		threshold: null
	},{
		operator: "lte",
		threshold: null
	},{
		operator: "lte",
		threshold: null
	}]

	constructor() {
		makeAutoObservable(this)
	}

	attachToCell(designer: Designer, cell: MxCell) {
		return new KpiDataSourceElement(designer, cell)
	}

	valid(){
		return this.sampleRateId != null
	}
}

export class KpiDataSourceEditorStore {
	mobx = new MobxManager()

	dataSource: CellKpiDatasource

	constructor(public designerStore: DesignerStore) {
		makeAutoObservable(this)

		this.dataSource = this.designerStore.dataSourcesManager.selected as CellKpiDatasource
	}

	init(){
		this.mobx.reaction(() => toJS(this.dataSource.accounts), () => {
			this.dataSource.profileId = null
		})

		this.mobx.reaction(() => toJS(this.dataSource.profileId), () => {
			this.dataSource.categoryId = null
			this.dataSource.accountId = null
		})

		this.mobx.reaction(() => toJS(this.dataSource.categoryId), () => {
			this.dataSource.ruleTypeId = null
		})

		this.mobx.reaction(() => toJS(this.dataSource.ruleTypeId), () => {
			this.dataSource.sampleRateId = null
		})

		if (!this.dataSource.accounts?.length) {
			this.dataSource.accounts = [ApplicationState.accountId]
		}
	}

	destroy() {
		this.mobx.destroy()
	}
}

export type KpiProfileSelectorProps = AntSelectProps<string> & {
	accountId?: string
}

export const KpiProfileSelector = observer((props: KpiProfileSelectorProps) => {
	const {accountId, ...rest} = props
	const [profiles, loading, loaded] = useRemoteList(getKpiProfiles(accountId))

	return <AntSelect disabled={!loaded}
	                  dataList={profiles}
	                  loading={loading}
	                  {...rest}/>
})

export type KpiCategorySelectorProps = AntSelectProps<string> & {
	accountId?: string
	profileId: string
}

export const KpiCategorySelector = observer((props: KpiCategorySelectorProps) => {
	const {accountId, profileId, ...rest} = props
	const [categories, loading, loaded] = useRemoteList(getKpiCategories(props), {makeRequest: profileId != null})

	return <AntSelect disabled={!loaded}
	                  dataList={categories}
	                  loading={loading}
	                  {...textValueFields}
	                  {...rest}/>
})

export type KpiRuleTypeSelectorProps = AntSelectProps<string> & {
	accountId?: string
	profileId: string
	categoryId: string
}

export const KpiRuleTypeSelector = observer((props: KpiRuleTypeSelectorProps) => {
	const {accountId, profileId, categoryId, ...rest} = props
	const [ruleTypes, loading, loaded] = useRemoteList(getKpiRuleTypes(props), {makeRequest: categoryId != null && profileId != null})

	return <AntSelect disabled={!loaded}
	                  dataList={ruleTypes}
	                  loading={loading}
	                  {...textValueFields}
	                  {...rest}/>
})

export type KpiSampleRateSelectorProps = AntSelectProps<string> & {
	accountId?: string
	profileId: string
	ruleTypeId: string
}

export const KpiSampleRateSelector = observer((props: KpiSampleRateSelectorProps) => {
	const {accountId, profileId, ruleTypeId, ...rest} = props
	const [sampleRates, loading, loaded] = useRemoteList(getKpiSampleRates(props), {makeRequest: ruleTypeId != null && profileId != null})

	return <AntSelect disabled={!loaded}
	                  dataList={sampleRates}
	                  loading={loading}
	                  {...textValueFields}
	                  {...rest}/>
})

export type KpiAccountSelectorProps = AntSelectProps<string> & {
	accountId?: string
	profileId: string
}

export const KpiAccountSelector = observer((props: KpiAccountSelectorProps) => {
	const {accountId, profileId, ...rest} = props
	const [kpiAccounts, loading, loaded] = useRemoteList(getKpiAccounts(props), {makeRequest: profileId != null})

	const kpiAccountsActual = React.useMemo(() => {
		return [{
			value: '',
			text: i18n('Aggregated')
		},
			...kpiAccounts]
	}, [kpiAccounts])

	return <AntSelect disabled={!loaded}
	                  dataList={kpiAccountsActual}
	                  loading={loading}
	                  {...textValueFields}
	                  {...rest}/>
})

export const KpiDataSourceEditor = observer(() => {
	const designerStore = React.useContext(DataSourceEditorContext)

	const store = useStore(() => new KpiDataSourceEditorStore(designerStore), {
		deps: [designerStore.dataSourcesManager.selected]
	})

	const [converter] = React.useState(
		() => new SingleToArrayConverter(store.dataSource, "accounts"))

	let accountId = store.dataSource.accounts.length > 0 ? store.dataSource.accounts[0]: null

	return <Section appearance={'none'} childrenPadding={true}>
		<FormEntryNew label={i18n('Account')} model={converter} modelField={"value"}>
			<AccountSelector hidePlaceholder={true} />
		</FormEntryNew>

		<FormEntryNew label={i18n('Profile')} model={store.dataSource} modelField={"profileId"}>
			<KpiProfileSelector accountId={accountId}/>
		</FormEntryNew>

		<FormEntryNew label={i18n('Category')} model={store.dataSource} modelField={"categoryId"}>
			<KpiCategorySelector accountId={accountId}
			                     profileId={store.dataSource.profileId}/>
		</FormEntryNew>

		<FormEntryNew label={i18n('Rule Type')} model={store.dataSource} modelField={"ruleTypeId"}>
			<KpiRuleTypeSelector accountId={accountId}
			                     profileId={store.dataSource.profileId}
			                     categoryId={store.dataSource.categoryId}/>
		</FormEntryNew>

		<FormEntryNew label={i18n('Sample Rate')} model={store.dataSource} modelField={"sampleRateId"}>
			<KpiSampleRateSelector accountId={accountId}
			                     profileId={store.dataSource.profileId}
			                     ruleTypeId={store.dataSource.ruleTypeId}/>
		</FormEntryNew>

		<FormEntryNew label={i18n('KPI Account')} model={store.dataSource} modelField={"accountId"}>
			<KpiAccountSelector accountId={accountId}
			                    profileId={store.dataSource.profileId}/>
		</FormEntryNew>

		<MetricValueAsSeverity dataSource={store.dataSource}/>
		<ShowAsLabel datasource={store.dataSource}/>
	</Section>
})
