import {Api} from "tools/api";
import {Utils} from "tools/utils";
import Settings from 'settings'
import Cookies from 'core/cookies'

class UserSettings {
	userSettingsCache = new UserSettingsCache();

	async get(categoryName, key = null, defaultValue = null){
		let category =  await this.getCategory(categoryName);
		if( key == null ){
			return category;
		}

		return category[key] === undefined ? defaultValue : category[key];
	}

	//returns a copy of settings
	async getCategory(categoryName, force = false){
		return await this.userSettingsCache.load(categoryName, force);
	}

	async set(categoryName, newSettings, reset = false) {
		if(!reset){
			const currentSettings = await this.getCategory(categoryName);
			newSettings = {...currentSettings, ...newSettings};
			this.userSettingsCache.set(newSettings)
		}

		await Api.fetchPost(getUrl(categoryName), mapToArray(newSettings));
		this.userSettingsCache.reset(categoryName);
	}

	async clear(category){
		await this.userSettingsCache.clear(category);
	}

	//returns an updatable instance of category
	async forCategory(categoryName, defaultValue = null){
		let result =  new UserSettingsForCategory(categoryName, this);
		await result.load();
		return new Proxy(result, {
			get(target, prop){
				if( target[prop])
					return target[prop];

				return target.get(prop);
			},
			set(target, prop, value){
				target.set(prop, value);
				return true;
			}
		});
	}
}

class UserSettingsCache {
	cache = {};

	async load(category, force = false) {
		if (!force && this.cache[category]) {
			return this.getCached(category);
		}

		const {data} = await Api.fetch(getUrl(category));

		this.cache[category] = arrayToMap(data);

		return this.getCached(category);
	}

	reset(category) {
		this.cache[category] = null;
	}

	set(category, newValue) {
		this.cache[category] = newValue;
	}

	async clear(category){
		let result = await Api.fetch(clearUrl(category));
		this.reset(category);
		return result
	}

	getCached(category){
		return JSON.parse(JSON.stringify(this.cache[category]));
	}
}

class UserSettingsForCategory{
	categoryName = null;
	userSettings = null;
	cachedCategory = null;

	constructor(categoryName, userSettings) {
		this.categoryName = categoryName;
		this.userSettings = userSettings;
	}

	get(key, defaultValue = null) {
		return this.cachedCategory[key] == null ? defaultValue : this.cachedCategory[key];
	}

	set(key, value){
		return this.cachedCategory[key] = value;
	}

	async clear(){
		return await this.userSettings.clear(this.categoryName);
	}

	async save(){
		return await this.userSettings.set(this.categoryName, this.cachedCategory);
	}

	async load(){
		this.cachedCategory = await this.userSettings.getCategory(this.categoryName);
	}
}

function arrayToMap(items) {
	const out = {};

	for (const item of items) {
		try {
			out[item.key] = JSON.parse(item.value);
		} catch (e) {
			out[item.key] = item.value;
		}

		//adding lower-cased item to dictionary too
		out[item.key.charAt(0).toLowerCase() + item.key.slice(1)] = out[item.key];
	}

	return out;
}

function mapToArray(map) {
	const out = [];

	for (const key of Object.keys(map)) {
		let value = map[key];

		if (value === null) {
			continue;
		}

		out.push({
			key,
			value: JSON.stringify(value)
		});
	}

	return out;
}

function getUrl(category) {
	const sessionId = Cookies.sessionId || Cookies.guestSessionId;
	return `${Settings.serverPath}sessions/${sessionId}/userSettings/?category=${category}`;
}

function clearUrl(category){
	return `${Settings.serverPath}userSettings/categories/${category}/deleteAllKeys`;
}

const instance = new UserSettings();
export {instance as default, instance as UserSettings}
