
import classnames from 'classnames';
import Renderer from 'tools/renderer';

export function getAgentStateHome(agentsDown, agentsWarning, agentsTotal) {
	let tooltip = '';
	let count;
	let signClassesArray = ['glyphicons', 'status_icon', 'pointer'];
	if (agentsDown) {
		tooltip = agentsDown + ' ' + lang.OUT_OF + ' ' + agentsTotal + ' ' + lang.summary.AGENTS_DOWN;
		signClassesArray.push('remove-sign');
		count = agentsDown + '/' + agentsTotal;
	} else if (agentsWarning) {
		tooltip = agentsWarning + ' ' + lang.OUT_OF + ' ' + agentsTotal + ' ' + lang.summary.AGENTS_WARNING;
		signClassesArray.push('exclamation-sign');
		count = agentsWarning + '/' + agentsTotal;
	} else {
		tooltip = lang.summary.ALLAGENTS_UP;
		count = agentsTotal;
		if (agentsTotal) {
			signClassesArray.push('ok-sign');
		} else {
			signClassesArray = ['cw_status_indicator', 'cw_status_widget_color', 'cw_color6', 'pointer'];
		}
	}
	let signClasses = classnames(signClassesArray);
	let sign = `<span class="${signClasses}"></span>`;

	let classes = classnames(
		'cw_status_widget',
		'cw_agent_status',
		'pointer'
	);
	let html = `<div class="${classes}" title="${tooltip}">`
	html += sign;
	html += count;

	return html;
}

export function getAgentStateAgents(state, status, lastConnect) {
	let tooltip = '';
	let classesArray = ['cw_agent_indicator', 'cw_status'];
	let indicatorClassesArray = ['cw_indicator', 'glyphicons'];
	if (!isNaN(lastConnect)) {
		tooltip = Renderer.localDate(lastConnect, Constants.DATETIME);
	}
	if (status.includes('MAINTENANCE')) {
		classesArray.push('is_idle');
		indicatorClassesArray.push('wrench');
		tooltip = lang.account.messages.AGENT_MAINTENANCE;
	} else {
		indicatorClassesArray.push('status_icon');
		switch (state) {
			case 'OK':
				classesArray.push('is_ok');
				indicatorClassesArray.push('ok');
				break;
			case 'ERROR':
				classesArray.push('is_critical');
				indicatorClassesArray.push('remove');
				tooltip = lang.account.messages.AGENT_DOWN;
				break;
			case 'WARNING':
				classesArray.push('is_major');
				indicatorClassesArray.push('exclamation-mark');
				tooltip = lang.account.messages.AGENT_WARNING;
				break;
		}
	}

	let classes = classnames(classesArray);
	let indicatorClasses = classnames(indicatorClassesArray);
	let html = `<span class="${classes}">`;
	let indicator = `<span class="${indicatorClasses}" title="${tooltip}"></span>`;
	html += indicator;

	return html;
}
