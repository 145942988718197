import {ApiRequest, PagedList} from "framework/api";
import {Geotag} from "framework/entities/geotag";
import {HealthDataHolder} from "framework/entities/healthData";
import {TimeSelectionString, toStringTimeSelection} from "controls/react/form/timePeriodSelector";
import {MetricInfo} from "framework/entities/metricInfo";
import {TimePeriodType} from "controls/react/form/timePeriodType";


export interface AssetHealthRequestEntry{
	assetId: string
	accountId: string
}

export interface ServiceHealthRequestEntry{
	serviceId: string
	serviceQualifierId?: string
	accountId: string
}

export interface MetricHealthRequestEntry{
	metricId: string
	showTrend?: boolean
	trendPeriod?: TimePeriodType
}

export interface CostHealthRequestEntry{
	costProfileId: string
	costItemId: string
	valueType: string
	timePeriod: TimeSelectionString
	currency: string
	costModelId: string
}

//the others entries should be added on demand
export type HealthRequestEntry = {id: string } &
	(AssetHealthRequestEntry | ServiceHealthRequestEntry | MetricHealthRequestEntry | CostHealthRequestEntry)

export interface AssetHealthResponseEntry extends HealthDataHolder{
	assetId: string
	assetName: string
	geotag: Geotag
}

export interface MetricHealthResponseEntry{
	metricId: string
	metricInfo: MetricInfo
}

export interface CostHealthResponseEntry{
	id: string
	costProfileId: string
	costProfileName: string
	costModelId: string
	costModelName: string
	costItemId: string
	costItemName: string
	valueType: "BUDGET"
	timePeriod: {
		period: TimePeriodType
		startDate: number
		endDate: number
		year: number
		month: number
		months: number
		intervalType: string
		intervalValue: number
	},
	value: number
	dirty: boolean
	currencyText: string
	currencySymbol: string
	currencyCode: string
}

export type HealthResponseEntry = {id: string } &
	(AssetHealthResponseEntry | MetricHealthResponseEntry | CostHealthResponseEntry)

export function getHealthInfo(entries: HealthRequestEntry[], sessionId?: string){
	return new ApiRequest<HealthResponseEntry[]>({
		url: 'dashboards/datasourceInfo',
		payload: entries
	})
}
