import './sortingEditor.less'

import React from "react"
import {observer} from "mobx-react"
import {DeleteOutlined} from "@ant-design/icons"

import {GridDataEntry} from "controls/grid/gridDataEntry"
import {SortingIndicator} from "controls/grid/sortingIndicator"
import {AntButton} from "controls/react/ant/antButton"
import {GridColumn} from "controls/grid/gridColumnConfig"
import {Section} from "controls/react/layout/section"
import {ViewManagerProps} from "./viewManagerStore"
import { addDragAndDrop } from 'tools/helpers/dom'

const i = require('core/localization/localization').translator({
	'Remove sorting on the column': {
		no: 'Fjern sortering fra kolonnen'
	},
	'Click to change sorting direction or Drag to change sorting order': {}
})

const b = require('b_').with('grid-view-sorting-editor')


export const SortingEditor = observer(<DataItem extends GridDataEntry>(props: ViewManagerProps<DataItem>) => {
	const gridStore = props.store.gridStore

	const sortedColumns = gridStore.state.sortingOrder
		.map(x => gridStore.columns.all.find(c => c.field == x))
		.filter(x => x)

	return <Section contentPadding={false}>
		<div className={b()}>
			{sortedColumns.map(x => <SortingEditorEntry key={x.field} column={x} store={props.store}/>)}
		</div>
	</Section>
})

type SortingEditorEntryProps<DataItem extends GridDataEntry> = ViewManagerProps<DataItem> & {
	column: GridColumn<DataItem>
}

const SortingEditorEntry = observer(
	class SortingEditorEntryInner<DataItem extends GridDataEntry> extends React.Component<SortingEditorEntryProps<DataItem>> {
		ref: HTMLDivElement

		render() {
			return <div className={b('entry')}
			            ref={this.onRef}
			            title={i('Click to change sorting direction or Drag to change sorting order')}
			            draggable={true}
			            onClick={this.props.column.flipSorting}>
				<div className={b('name')}>{this.props.column.config.title}</div>
				<div className={b('sorting-indicator')}><SortingIndicator column={this.props.column}/></div>
				<div className={b('delete')}>
					<AntButton icon={<DeleteOutlined/>}
					           title={i('Remove sorting on the column')}
					           onClick={() => {
						           this.props.column.removeSorting()
					           }}/>
				</div>
			</div>
		}

		onRef = (element: HTMLDivElement) => {
			if (element == null) {
				this.ref = null
				return
			}

			this.ref = element

			addDragAndDrop(this.ref, {
				data: this.props.column.field,
				overClassName: b('entry') + '_over',
				onDrop: (draggedColumnName) => {
					if (draggedColumnName == null)
						return

					this.props.store.gridStore.columns.moveSortingBefore(this.props.column.field, draggedColumnName)
				}
			})
		}
	}
)
