import { makeAutoObservable } from "mobx";
import {ModelValidator, ValidatableModel} from "../../../../framework/mobx-integration";
import {CostGatewaySheet} from "./costGatewaySheet";
import {newGuid} from "../../../../tools/guid";
import {createModelSchemaWrapper} from "../../../../framework/serializr-integration";
import {custom, list, object, primitive } from "serializr";
import {CostGatewaySubscription} from "./costGatewayCloudType";
import { Moment } from "moment";
import moment from "moment";

export type CostGatewayScheduleName = 'costSchedule' | 'advisorSchedule' | 'resourcesSchedule'
const CostGatewaySchedules = ['costSchedule', 'advisorSchedule', 'resourcesSchedule'] as CostGatewayScheduleName[]

export class CostGatewayConnection implements ValidatableModel<CostGatewayConnection> {
	validator = new ModelValidator(this)

	id: string
	name: string
	accountId: string
	description: string
	cloudType: string = null
	accountName: string = ''
	authenticationId: string = null

	subscriptions: CostGatewaySubscription[] = []
	dynamicSubscriptions: boolean = true
	dynamicSubscriptionSkip: string

	costSchedule?: string
	advisorSchedule?: string
	office365Schedule?: string
	resourcesSchedule?: string

	defaultCurrency: string = 'NOK'
	defaultStartDate: Moment

	costSheets: CostGatewaySheet[] = []

	constructor() {
		makeAutoObservable(this)
		this.costSheets = [];
		this.validator
			.required('name')
			.required('cloudType')
			.required('authenticationId')
			.required('defaultCurrency')
			.required('defaultStartDate')
			.add('costSheets', {
				callback: () => {
					return this.costSheets.every(x => x.validator.valid)
				},
				reactionExpression: () => this.costSheets.every(x => x.validator.valid)
			})
	}

	createSheet() {
		const sheet = new CostGatewaySheet()
		sheet.id = newGuid()
		sheet.name = `Sheet ${this.costSheets.length + 1}`
		this.costSheets.push(sheet)
		return sheet
	}

	deleteSheet(id: string) {
		this.costSheets = this.costSheets.filter(x => x.id != id)
	}

	get valid() {
		return this.validator.valid
	}

	setScheduleValidations(names: CostGatewayScheduleName[]) {
		CostGatewaySchedules.forEach(x => {
			const fieldValidator = this.validator.getFieldValidator(x)
			if(names.includes(x) && !fieldValidator) {
				this.validator.required(x)
			} else if(!names.includes(x) && fieldValidator) {
				fieldValidator.destroy()
			}
		})
	}
}

createModelSchemaWrapper(CostGatewayConnection, {
	id: primitive(),
	name: primitive(),
	accountId: primitive(),
	accountName: primitive(),
	description: primitive(),
	cloudType: primitive(),
	authenticationId: primitive(),
	costSheets: list(object(CostGatewaySheet)),
	subscriptions: list(object(CostGatewaySubscription)),
	dynamicSubscriptions: primitive(),
	dynamicSubscriptionSkip: primitive(),
	costSchedule: primitive(),
	advisorSchedule: primitive(),
	resourcesSchedule: primitive(),
	office365Schedule: primitive(),
	defaultCurrency: primitive(),
	defaultStartDate: custom((value: Moment) => {
		return value?.format('YYYY-MM-DD');
	}, (jsonValue, context, oldValue) => {
		return jsonValue && moment(jsonValue);
	})
})
