import './calendarEventFormNew.less';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {AntModal, createContainer, ModalPosition} from "controls/react/ant/antModal";
import {Section, Toolbar} from "controls/react/layout";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AntInput} from "controls/react/ant/antInput";
import {AntSelect, fromStringsArray} from "controls/react/ant/antSelect";
import {translator} from "../../core/localization";
import {AntSwitch} from "controls/react/ant/antSwitch";
import {computed, makeAutoObservable, makeObservable, observable} from "mobx";
import {apiFetchObservable, CachedValue} from "framework/api";
import {getTimeZones} from "./calendar/api";
import {observer} from "mobx-react";
import moment from "moment";
import {AntDatePicker} from "controls/react/ant/antDatePicker";
import {ApplicationState} from "framework/applicationState";
import RRule, {Frequency as RruleFrequency} from "rrule";
import {AntTags} from "controls/react/ant/AntTags";
import {AntRadioGroup} from "controls/react/ant/antRadio";
import {Button, Radio} from "antd";
import {ALL_WEEKDAYS, Weekday} from "rrule/dist/esm/src/weekday";
import {newGuid} from "tools/guid";
import {MobxManager, ModelValidator} from "framework/mobx-integration";
import {getTzOffsetDiffs, parseDateFormat} from "tools/dateTimeUtils";
import {parseStringValueToEnum, parseStringValueToRrule} from "./calendar/calendarUtils";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {State} from "../../tools";

const momentDateTimeFormat = () => parseDateFormat(ApplicationState.dateTimeFormat).replace('.SSS', '').replace(':ss', '');

export const scheduleTranslates = {
	REPEAT: { en: 'Repeat', no: 'Gjenta' },
	EVERY: { en: 'every', no: 'Hver' },
	SECONDLY: { en: 'Secondly', no: 'Sekund' },
	MINUTELY: { en: 'Minutely', no: 'Minutt' },
	HOURLY: { en: 'Hourly', no: 'Time' },
	DAILY: { en: 'Daily', no: 'Daglig' },
	WEEKLY: { en: 'Weekly', no: 'Ukentlig' },
	MONTHLY: { en: 'Monthly', no: 'M\u00e5nedlig' },
	YEARLY: { en: 'Yearly', no: 'Årlig' },
	ON_DAY: { en: 'on day', no: 'P\u00e5 dag' },
	RRULE: { en: 'RRULE', no: 'Gjentagelse' },
	SECONDS: { en: 'second(s)', no: 'andre(r)' },
	MINUTE: { en: 'minute(s)', no: 'minutte(r)' },
	HOUR: { en: 'hour(s)', no: 'Time(r)' },
	DAY: { en: 'day(s)', no: 'dag(er)' },
	WEEK: { en: 'week(s)', no: 'Uke(r)' },
	MONTH: { en: 'month(s)', no: 'm\u00e5ned(er)' },
	YEAR: { en: 'year(s)', no: 'År' },
	START: { en: 'Start time', no: 'Starttid' },
	END: { en: 'End', no: 'Slutt' },
	NEVER: { en: 'Never', no: 'Aldri' },
	ON_DATE: { en: 'On date', no: 'P\u00E5 dato' },
	ON_THE: { en: 'on the', no: 'P\u00E5 den' },
	FIRST: { en: 'First', no: 'F\u00F8rste' },
	SECOND: { en: 'Second', no: 'Andre' },
	THIRD: { en: 'Third', no: 'Tredje' },
	FOURTH: { en: 'Fourth', no: 'Fjerde' },
	LAST: { en: 'Last', no: 'Siste' },
	WEEKDAY: { en: 'Weekday', no: 'Ukedag' },
	WEEKEND_DAY: { en: 'Weekend day', no: 'Helgedag' },
	ON: { en: 'On', no: 'P\u00E5' },
	MO: { en: 'Monday', no: 'Mandag' },
	TU: { en: 'Tuesday', no: 'Tirsdag' },
	WE: { en: 'Wednesday', no: 'Onsdag' },
	TH: { en: 'Thursday', no: 'Torsdag' },
	FR: { en: 'Friday', no: 'Fredag' },
	SA: { en: 'Saturday', no: 'L\u00F8rdag' },
	SU: { en: 'Sunday', no: 'S\u00F8ndag' },
	JAN: {en: 'Jan', no: 'Jan'},
	FEB: {en: 'Feb', no: 'Feb'},
	MAR: {en: 'Mar', no: 'Mar'},
	APR: {en: 'Apr', no: 'Apr'},
	MAY: {en: 'May', no: 'Mai'},
	JUN: {en: 'Jun', no: 'Jun'},
	JUL: {en: 'Jul', no: 'Jul'},
	AUG: {en: 'Aug', no: 'Aug'},
	SEP: {en: 'Sep', no: 'Sep'},
	OCT: {en: 'Okt', no: 'Okt'},
	NOV: {en: 'Nov', no: 'Nov'},
	DEC: {en: 'Des', no: 'Des'},
	'Time zone': { no: 'Tidssone' },
	'Recurring event': { no: 'Gjentatt hendelse' }
};
const i = translator(scheduleTranslates);

export const CeeViewHistoricMaintenanceEventStorageKey = 'cw.services.maintenance_historic_event';

export type DatesType = 'onlyStart' | 'range'

export class CalendarEventBase {
	start: number;
	end?: number;
	id?: string;
	title?: string;
	timezone: string;
	rruleObject?: RRule;
	created?: string;
	lastModified?: string;
	startIsCurrentTime: boolean;

	constructor(options?: object) {
		if (options) {
			Object.assign(this, options);
		}
	}

	getMaintenanceData() {
		this.updateCurrentTime();
		return {
			fromTime: moment(this.start).format('YYYY-MM-DDTHH:mm:ss'),
			toTime: moment(this.end).format('YYYY-MM-DDTHH:mm:ss'),
			reason: this.title,
			timZone: this.timezone
		};
	}

	updateCurrentTime() {
		if (this.startIsCurrentTime) {
			this.start = moment(moment().valueOf() - getTzOffsetDiffs(moment.tz.guess(), this.timezone)*60000).valueOf();
		}
	}
}

export class CalendarEvent extends CalendarEventBase {
	validator = new ModelValidator(this);

	constructor(options?: object) {
		super(options);
		makeObservable(this, {
			title: observable,
			timezone: observable,
			start: observable,
			end: observable
		});
		this.validator
			.required('title')
			.required('timezone');
	}
}

class CalendarEventFormStore {
	event: CalendarEvent;
	timezones: CachedValue<string[]>;
	isRecurring: boolean;
	validator = new ModelValidator(this);
	datesType: DatesType;

	constructor(private props: CalendarEventFormProps) {
		this.event = new CalendarEvent(this.props.event);
		this.event.startIsCurrentTime = props.startIsCurrentTime ?? false;
		this.datesType = props.datesType ?? 'range';
		if (!this.event.id) {
			this.event.id = newGuid();
		}
		this.timezones = apiFetchObservable(getTimeZones());
		this.setIsRecurring(!!this.event.rruleObject);
		makeAutoObservable(this, {
			updateDisabled: computed,
			start: computed,
			end: computed
		});

		this.validator
			.add('end', {
				callback: () => {
					if(this.datesType == 'onlyStart')
						return true

					return this.validatePeriod();
				},
				reactionExpression: () => [this.start, this.end]
			})
			.add('start', {
				callback: () => {
					if(this.datesType == 'onlyStart')
						return true

					return this.validatePeriod();
				},
				reactionExpression: () => [this.start, this.end]
			})
		State.mainApp.registerForOnNavigate(this.onCancel);
	}

	validatePeriod = () => {
		return this.start.isSame(this.end, 'day') && this.end.format("HH:mm") == '00:00'
			|| this.event.end - this.event.start > 0;
	}

	setIsRecurring = (value: boolean) => {
		this.isRecurring = value;
		if (!value) {
			this.event.rruleObject = undefined;
		}
	}

	set range(value: [moment.Moment, moment.Moment]) {
		this.event.start = value?.[0].valueOf();
		this.event.end = value?.[1].valueOf();
	}

	get start() {
		return moment(this.event.start)
	}

	set start(value: moment.Moment) {
		this.event.start = value?.valueOf()
		this.event.startIsCurrentTime = false;
	}

	get end() {
		return moment(this.event.end)
	}

	set end(value: moment.Moment) {
		this.event.end = value?.valueOf()
	}

	get updateDisabled() {
		return !this.validator.valid;
	}

	onCancel = () => {
		this.props.onCancel();
		this.destroy();
	}

	onDelete = () => {
		this.props.onDelete(this.event);
		this.destroy();
	}

	onOk = () => {
		if (!this.validator.valid) {
			return;
		}
		if (this.props.mode == 'create') {
			this.event.created = moment().utc().format('YYYYMMDDTHHmmssZ');
		}
		this.event.lastModified = moment().utc().format('YYYYMMDDTHHmmssZ');
		this.event.updateCurrentTime();
		this.props.onSave(this.event);
		this.destroy();
	}

	destroy = () => {
		this.validator.destroy();
	}
}

export class CalendarEventFormProps  {
	event: CalendarEventBase;
	mode: 'create' | 'update';
	datesType?: DatesType;
	disabled?: boolean;
	onSave: (event: CalendarEventBase) => void;
	onDelete?: (event: CalendarEventBase) => void;
	onCancel?: () => void;
	positionType?: ModalPosition
	positionAnchor?: React.MutableRefObject<HTMLElement>;
	startIsCurrentTime?: boolean
	disableRrule?: boolean
}

let bCal = require('b_').with('calendar-event');
export const CalendarEventFormNew = observer(class CalendarEventFormNew extends React.Component<CalendarEventFormProps> {
	store: CalendarEventFormStore;
	constructor(props: CalendarEventFormProps) {
		super(props);
		this.store = new CalendarEventFormStore(props);
	}

	render() {
		return <AntModal open={true}
						 title={i('Time')}
						 className={bCal()}
						 onCancel={this.store.onCancel}
						 width={500}
						 maskClosable={false}
						 draggableHandle={'.toolbar'}
						 positionType={this.props.positionType ?? ModalPosition.TopLeft}
						 positionAnchor={this.props.positionAnchor}
						 footer={<Footer mode={this.props.mode} onOk={this.store.onOk} onCancel={this.store.onCancel} onDelete={this.store.onDelete} disabled={this.props.disabled || this.store.updateDisabled} />}>
			<Section appearance={"none"} contentPadding height={'fit-expand-only'}>
				<FormEntryNew label={i('Name')} model={this.store.event} modelField={'title'} disabled={this.props.disabled}>
					<AntInput />
				</FormEntryNew>
			</Section>
			<Section appearance={"none"} contentPadding height={'fit-expand-only'}>
				<FormEntryNew label={i('Time zone')} model={this.store.event} modelField={'timezone'} disabled={this.props.disabled}>
					<AntSelect options={fromStringsArray(this.store.timezones.data)} loading={this.store.timezones.loading}/>
				</FormEntryNew>
			</Section>
			<Section appearance={"none"} contentPadding height={'fit-expand-only'} direction={'row'} contentClass={bCal('period')}>
				{this.store.datesType == 'range' && <FormEntryNew model={this.store} modelField={'start'} disabled={this.props.disabled}>
					<AntDatePicker className={bCal('period-start')} format={momentDateTimeFormat()} popupStyle={{zIndex: 100_000}} showTime={true} />
				</FormEntryNew>}
				{this.store.datesType == 'range' && <FormEntryNew model={this.store} modelField={'end'} disabled={this.props.disabled}>
					<AntDatePicker className={bCal('period-end')} format={momentDateTimeFormat()} popupStyle={{zIndex: 100_000}} showTime={true} />
				</FormEntryNew>}
				{this.store.datesType == 'onlyStart' && <FormEntryNew model={this.store} modelField={'start'} disabled={this.props.disabled}>
					<AntDatePicker className={bCal('period-start')} format={momentDateTimeFormat()} popupStyle={{zIndex: 100_000}} showTime={true}/>
				</FormEntryNew>}
			</Section>
			{this.props.disableRrule != true && <Section appearance={"frame-top-only"} title={i('Recurring event')} height={'fit-expand-only'}>
				<Toolbar>
					<AntSwitch
						size={'small'}
						value={this.store.isRecurring}
						onChange={(v) => this.store.setIsRecurring(v)}
						disabled={this.props.disabled}
					/>
				</Toolbar>
				{this.store.isRecurring && <FormEntryNew model={this.store.event} modelField={'rruleObject'} disabled={this.props.disabled}>
					<RecurringEventForm timeZone={this.store.event.timezone} />
				</FormEntryNew>}
			</Section>}
		</AntModal>
	}
})

class FooterProps {
	mode: 'create' | 'update'
	onOk: () => void;
	onDelete: () => void;
	onCancel: () => void;
	disabled?: boolean;
}

const Footer = (props: FooterProps) => {
	return <Toolbar>
		{props.mode == 'update' && <Button position={'before-title'} onClick={props.onDelete} key={'delete'} disabled={props.disabled}>{i('Delete')}</Button>}
		<Button onClick={props.onOk} position={'at-the-end'} type={'primary'} key={'ok'} disabled={props.disabled}>{props.mode == 'create' ? i('Create') : i('Update')}</Button>
		<Button onClick={props.onCancel} position={'at-the-end'} key={'cancel'}>{i('Cancel')}</Button>
	</Toolbar>
}

class RecurringEventFormProps {
	excludeFrequencies?: Frequency[] | string[];
	value?: RRule;
	timeZone: string;
	disabled?: boolean;
	onChange?: (value: RRule) => void;
}

export enum Frequency {
	YEARLY = 'YEARLY',
	MONTHLY = 'MONTHLY',
	WEEKLY = 'WEEKLY',
	DAILY = 'DAILY',
	HOURLY = 'HOURLY',
	MINUTELY = 'MINUTELY',
	SECONDLY = 'SECONDLY'
}

class RecurringEventFormStore {
	rrule: RruleType = {};
	frequencyDataSource: {label: string, value: Frequency}[];
	endDataSource: {label: string, value: string}[];
	frequency = Frequency.DAILY;
	frequencyValue = 1;
	endType: 'ON_DATE' | 'NEVER' = 'NEVER';
	until?: number;

	mobx = new MobxManager();
	validator = new ModelValidator(this);

	get isValid() {
		return this.validator.valid;
	}

	get endValue() {
		if (!this.until) {
			return undefined;
		}
		return moment(this.until);
	}

	set endValue(val: moment.Moment) {
		this.until = val?.valueOf();
	}

	get repeatUnit() {
		switch (this.frequency){
			case Frequency.MINUTELY: {
				return i('MINUTE')
			}
			case Frequency.HOURLY: {
				return i('HOUR')
			}
			case Frequency.DAILY: {
				return i('DAY')
			}
			case Frequency.WEEKLY: {
				return i('WEEK')
			}
			case Frequency.MONTHLY: {
				return i('MONTH')
			}
			case Frequency.YEARLY: {
				return i('YEAR')
			}
		}
	}

	constructor(private props: RecurringEventFormProps) {
		makeAutoObservable(this);
		this.mobx.reaction(x => this.rRule, result => {
			this.props.onChange(this.rRuleObject);
		});
		this.validator.required('frequencyValue')
			.required('endValue', () => this.endType != 'NEVER');
	}

	updateProps = (props: RecurringEventFormProps) => {
		this.props = props;
	}

	get rRuleObject() {
		let ruleObj: {[id: string]: any} = {
			freq: RRule[this.frequency],
			interval: this.frequencyValue,
			byweekday: this.rrule.byweekday?.map(x => parseStringValueToRrule(x)) ?? [],
			bymonthday: this.rrule.bymonthday,
			bymonth: this.rrule.bymonth,
			bysetpos: this.rrule.bysetpos
		}

		if (this.endValue) {
			ruleObj.until = moment(this.until + getTzOffsetDiffs(moment.tz.guess(), this.props.timeZone)*60000).toDate().toISOString();
		}

		return new RRule(ruleObj);
	}

	get rRule () {
		return this.rRuleObject.toString();
	}

	setRrule = (rrule?: RRule) => {
		if (!rrule) {
			return;
		}

		this.frequency = convertRruleFrequencyToFrequency(rrule.options.freq);
		this.frequencyValue = rrule.options.interval;
		if (rrule.options.byweekday?.length) {
			this.rrule.byweekday = rrule.options.byweekday.map(x => new Weekday(x).toString());
		}
		if (rrule.options.bymonthday?.length) {
			this.rrule.bymonthday = rrule.options.bymonthday[0];
		}
		if (rrule.options.bymonth?.length) {
			this.rrule.bymonth = rrule.options.bymonth[0];
		}
		if (rrule.options.bysetpos?.length) {
			this.rrule.bysetpos = rrule.options.bysetpos[0];
		}
		if (rrule.options.until) {
			this.until = moment.utc(rrule.options.until).valueOf() - getTzOffsetDiffs(moment.tz.guess(), this.props.timeZone)*60000;
			this.endType = 'ON_DATE';
		}
	}

	init = () => {
		this.frequencyDataSource = [{
			label: i('MINUTELY'),
			value: Frequency.MINUTELY
		}, {
			label: i('HOURLY'),
			value: Frequency.HOURLY
		}, {
			label: i('DAILY'),
			value: Frequency.DAILY
		}, {
			label: i('WEEKLY'),
			value: Frequency.WEEKLY
		}, {
			label: i('MONTHLY'),
			value: Frequency.MONTHLY
		}, {
			label: i('YEARLY'),
			value: Frequency.YEARLY
		}].filter(x => !this.props.excludeFrequencies?.includes(x.value));

		this.endDataSource = [{
			label: i('NEVER'),
			value: 'NEVER'
		}, {
			label: i('ON_DATE'),
			value: 'ON_DATE'
		}];

		this.setRrule(this.props.value);
		this.props.onChange(this.rRuleObject);
	}

	destroy() {
		this.mobx.destroy();
	}
}

const convertRruleFrequencyToFrequency = (val: number): Frequency => {
	for (let item in RruleFrequency) {
		if (Number(item) == val) {
			return parseStringValueToEnum(Frequency, RruleFrequency[item]);
		}
	}
}

let b = require('b_').with('recurring-event');

export class RecurringEventFormImpl extends React.Component<RecurringEventFormProps> {
	store: RecurringEventFormStore;

	constructor(props: RecurringEventFormProps) {
		super(props);
		this.store = new RecurringEventFormStore(props);
	}

	componentDidMount() {
		this.store.init();
	}

	componentDidUpdate(prevProps: Readonly<RecurringEventFormProps>, prevState: Readonly<{}>, snapshot?: any) {
		this.store.updateProps(this.props);
	}

	componentWillUnmount() {
		this.store.destroy();
	}

	render() {
		return <Section appearance={"none"} containerClass={b()}>
			<Section appearance={"none"} direction={'row'} contentPadding>
				<Section appearance={"none"} width={'oneThird'}>
					<FormEntryNew label={i('REPEAT')} model={this.store} modelField={'frequency'} disabled={this.props.disabled}>
						<AntSelect options={this.store.frequencyDataSource} />
					</FormEntryNew>
				</Section>
				<FormEntryNew label={i('EVERY')} model={this.store} modelField={'frequencyValue'} disabled={this.props.disabled}>
					<AntInputNumber className={b('every-input')}/>
					{this.store.repeatUnit}
				</FormEntryNew>
			</Section>
			{this.store.frequency == Frequency.WEEKLY && <Section appearance={"none"} direction={'row'} className={b('days-of-week')}>
				<FormEntryNew model={this.store} modelField={'rrule'} disabled={this.props.disabled}>
					<WeeklyForm />
				</FormEntryNew>
			</Section>}
			{this.store.frequency == Frequency.MONTHLY && <Section appearance={"none"}>
				<FormEntryNew model={this.store} modelField={'rrule'} disabled={this.props.disabled}>
					<MonthlyForm />
				</FormEntryNew>
			</Section>}
			{this.store.frequency == Frequency.YEARLY && <Section appearance={"none"} contentPadding direction={'row'} width={'full'}>
				<FormEntryNew label={i('ON_THE')} model={this.store} modelField={'rrule'} disabled={this.props.disabled}>
					<YearlyForm />
				</FormEntryNew>
			</Section>}
			<Section appearance={"none"} direction={'row'} contentPadding>
				<Section appearance={"none"} width={'oneThird'}>
					<FormEntryNew label={i('END')} model={this.store} modelField={'endType'} disabled={this.props.disabled}>
						<AntSelect options={this.store.endDataSource} />
					</FormEntryNew>
				</Section>
				{this.store.endType == 'ON_DATE' && <FormEntryNew model={this.store} modelField={'endValue'} disabled={this.props.disabled}>
					<AntDatePicker className={b('end')} popupStyle={{zIndex: 100000}} format={momentDateTimeFormat()} showTime />
				</FormEntryNew>}
			</Section>
		</Section>
	}
}
export const RecurringEventForm = observer(RecurringEventFormImpl);

type RruleType = {
	byweekday?: string[],
	bymonthday?: number,
	bymonth?: number,
	bysetpos?: number
}

class RruleProps {
	value?: RruleType
	disabled?: boolean;
	onChange?: (value: RruleType) => void
}

const WeeklyForm = (props: RruleProps) => {
	const [byWeekday, setByWeekday] = useState(props.value.byweekday);
	const options = useRef(ALL_WEEKDAYS.map(x => x.toString()));
	const onChange = useCallback((value: string[]) => {
		setByWeekday(value);
		props.onChange({byweekday: value});
	}, []);

	return <AntTags options={options.current} value={byWeekday} onChange={onChange} disabled={props.disabled} />
}

const MonthlyForm = (props: RruleProps) => {
	const [monthlyType, setMonthlyType] = useState(!props.value.bysetpos ? 'BYMONTHDAY' : 'BYSETPOS');
	const [byMonthDay, setByMonthDay] = useState(props.value.bymonthday);
	const [byStepPos, setByStepPos] = useState(props.value.bysetpos);
	const [byWeekday, setByWeekday] = useState(props.value.byweekday);

	const weekOptions = useRef(ALL_WEEKDAYS.map(x => ({
		name: i(x),
		id: x
	})));

	const daysOptions = useRef(new Array(31).fill(0)
		.reduce((result, current, index) => ([...result, index+1]), [])
		.map((x: number) => ({name: x.toString(), id: x})));

	const byStepPosOptions = useRef([
		{name: i('FIRST'), id: 1},
		{name: i('SECOND'), id: 2},
		{name: i('THIRD'), id: 3},
		{name: i('FOURTH'), id: 4},
		{name: i('LAST'), id: -1},
	]);

	useEffect(() => {
		if (!props.value.bymonthday && !props.value.bysetpos && !props.value.byweekday?.length) {
			resetForm(monthlyType);
		} else {
			props.onChange({
				byweekday: byWeekday,
				bymonthday: byMonthDay,
				bysetpos: byStepPos
			});
		}
	}, []);

	const resetForm = useCallback((currentMonthlyType: string) => {
		const result = {
			byweekday: currentMonthlyType == 'BYMONTHDAY' ? undefined : ['MO'],
			bymonthday: currentMonthlyType == 'BYMONTHDAY' ? 1 : undefined,
			bysetpos: currentMonthlyType == 'BYMONTHDAY' ? undefined : 1
		}
		setByMonthDay(result.bymonthday);
		setByStepPos(result.bysetpos);
		setByWeekday(result.byweekday);
		props.onChange(result);
	}, []);

	const onMonthlyTypeChange = useCallback((value: string) => {
		setMonthlyType(value);
		resetForm(value);
	}, []);

	const onMonthDayChange = useCallback((value: number) => {
		setByMonthDay(value);
		props.onChange({
			byweekday: byWeekday,
			bymonthday: value,
			bysetpos: byStepPos
		});
	}, [byWeekday, byStepPos]);

	const onStepPosChange = useCallback((value: number) => {
		setByStepPos(value);
		props.onChange({
			byweekday: byWeekday,
			bymonthday: byMonthDay,
			bysetpos: value
		});
	}, [byWeekday, byMonthDay]);

	const onWeeklyChange = useCallback((value: string) => {
		setByWeekday([value]);
		props.onChange({
			byweekday: [value],
			bymonthday: byMonthDay,
			bysetpos: byStepPos
		});
	}, [byMonthDay, byStepPos]);

	return <AntRadioGroup value={monthlyType} onChange={onMonthlyTypeChange} disabled={props.disabled}>
		<Section appearance={"none"} contentPadding direction={"row"}>
			<Radio value={'BYMONTHDAY'}>
				{monthlyType != 'BYMONTHDAY' && i('ON_DAY')}
			</Radio>
			{monthlyType == 'BYMONTHDAY' && <FormEntryNew label={i('ON_DAY')} validationOnly>
				<AntSelect value={byMonthDay} onChange={onMonthDayChange} dataList={daysOptions.current} disabled={props.disabled} />
			</FormEntryNew>}
		</Section>
		<Section appearance={"none"} contentPadding direction={"row"}>
			<Radio value={'BYSETPOS'}>
				{monthlyType != 'BYSETPOS' && i('ON_THE')}
			</Radio>
			{monthlyType == 'BYSETPOS' && <>
				<FormEntryNew label={i('ON_THE')} validationOnly>
					<AntSelect value={byStepPos} onChange={onStepPosChange} dataList={byStepPosOptions.current} disabled={props.disabled} />
				</FormEntryNew>
				<FormEntryNew validationOnly>
					<AntSelect dataList={weekOptions.current} value={byWeekday[0]} onChange={onWeeklyChange} disabled={props.disabled} />
				</FormEntryNew>
			</>}
		</Section>
	</AntRadioGroup>
}

const YearlyForm = (props: RruleProps) => {
	const [byMonth, setByMonth] = useState(props.value.bymonth);
	const [byMonthDay, setByMonthDay] = useState(props.value.bymonthday);

	const dayOptions = useRef(new Array(31).fill(0)
		.reduce((result, current, index) => ([...result, index+1]), [])
		.map((x: number) => ({name: x.toString(), id: x})));

	const monthOptions = useRef([
		{name: i('JAN'), id: 1},
		{name: i('FEB'), id: 2},
		{name: i('MAR'), id: 3},
		{name: i('APR'), id: 4},
		{name: i('MAY'), id: 5},
		{name: i('JUN'), id: 6},
		{name: i('JUL'), id: 7},
		{name: i('AUG'), id: 8},
		{name: i('SEP'), id: 9},
		{name: i('OCT'), id: 10},
		{name: i('NOV'), id: 11},
		{name: i('DEC'), id: 12},
	]);

	useEffect(() => {
		setByMonthDay(1);
		setByMonth(1);
		props.onChange({
			bymonthday: 1,
			bymonth: 1
		});
	}, [])

	const onDayChange = useCallback((value: number) => {
		setByMonthDay(value);
		props.onChange({
			bymonthday: value,
			bymonth: byMonth
		});
	}, [byMonth]);

	const onMonthChange = useCallback((value: number) => {
		setByMonth(value);
		props.onChange({
			bymonthday: byMonthDay,
			bymonth: value
		});
	}, [byMonthDay]);

	return <>
		<FormEntryNew validationOnly>
			<AntSelect dataList={monthOptions.current} value={byMonth} onChange={onMonthChange} disabled={props.disabled} />
		</FormEntryNew>
		<FormEntryNew validationOnly>
			<AntSelect dataList={dayOptions.current} value={byMonthDay} onChange={onDayChange} disabled={props.disabled} />
		</FormEntryNew>
	</>
}

export function openCalendarEventForm(options: CalendarEventFormProps) {
	const {onSave, onCancel, onDelete, ...others} = options;
	const [root, container] = createContainer();
	const onFormSave = (event: CalendarEventBase) => {
		onSave(event);
		root.unmount();
		container.remove();
	}
	const onFormDelete = (event: CalendarEventBase) => {
		onDelete(event);
		root.unmount();
		container.remove();
	}
	const onFormCancel = () => {
		root.unmount();
		container.remove();
		onCancel?.();
	}
	root.render(<CalendarEventFormNew onSave={onFormSave} onDelete={onFormDelete} onCancel={onFormCancel} {...others}/>);
}

