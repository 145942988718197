import './lastChangesView.less';

import Utils from 'tools/utils';
import Application from 'core/application';
import PreferencesPanel from 'controls/preferencesPanel';
import CustomNotification from 'controls/customNotification';
import UserPrefs from 'tools/userPrefs';
import State from 'tools/state';
import Settings from 'settings';
import Cookies from 'core/cookies';
import RemoteEventsManager from 'core/remoteEventsManager';
import EventsToolbar from 'controls/eventsToolbar';
import ErrorHandler from 'core/errorHandler';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {getSeverityStateLastChanges} from 'controls/stateRenderer/severityState';
import {NavigationStore} from "../../framework/navigationStore";
import {AssetsRouter} from "../assets/bundleDescription";
import {ServicesRouter} from "../services/bundleDescription";
import {renderIntoLegacy, timePeriodToUrl} from "../../controls/react/form/timePeriodSelector";
import {ApplicationState} from "../../framework/applicationState";
import {TimePeriodType} from "controls/react/form/timePeriodType";

export default function LastChangesView(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'LastChanges',
		initialConfig: config
	};
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

jQuery.extend(LastChangesView.prototype, Application.prototype, {
	timeSelectorRoot: null,
	timePeriod: {period: TimePeriodType.All},
	/**
	 * Main init function
	 */
	initComponent: async function () {
		this.subscribe();
		Application.prototype.initComponent.call(this);
		let item = $('.cw_last_changes_notifications');
		item.addClass('is_selected');
		this.showGridView = true;
		this.removeListeners();
		this.attachListeners();
		await this.loadUserPreferences();
		this.initTimeSelector();
		this.initKendoComponents();
		this.removeMask();
		this.actionNotification = new CustomNotification({
			appendToElement: '.cw_section_content',
		});
		$('.cw_last_changes_notifications').find('b').addClass('hide');
	},

	addTimeSelector: function(configuration, rootElement) {
		return renderIntoLegacy({
			size: 'small',
			valueHolder: configuration,
			container: rootElement,
			onChange: $.proxy(this.onTimeSettingsChanged, this),
			periods: [TimePeriodType.LastHour, TimePeriodType.Last24Hours, TimePeriodType.Last7Days, TimePeriodType.Last30Days, TimePeriodType.DynamicInterval, TimePeriodType.All, TimePeriodType.Custom]
		});
	},

	initTimeSelector: function() {
		const periodSettings = UserPrefs.get('timePeriod');
		if (periodSettings == 'NONE') {
			[this.timePeriod, this.timeSelectorRoot] = this.addTimeSelector(this.timePeriod, document.querySelector('.cw_last_changes_timerange'));
			return;
		}
		if ([TimePeriodType.LastHour, TimePeriodType.Last24Hours, TimePeriodType.Last7Days, TimePeriodType.Custom].includes(periodSettings)) {
			this.timePeriod = {period: periodSettings};
			let fromTime = UserPrefs.get('fromTime');
			let toTime = UserPrefs.get('toTime');
			if (fromTime && toTime) {
				this.timePeriod.fromTime = new Date(fromTime).getTime();
				this.timePeriod.toTime = new Date(toTime).getTime();
			}
		} else if (periodSettings) {
			this.timePeriod = JSON.parse(periodSettings);
		}
		[this.timePeriod, this.timeSelectorRoot] = this.addTimeSelector(this.timePeriod, document.querySelector('.cw_last_changes_timerange'));
	},

	onTimeSettingsChanged: function(newPeriod) {
		Object.assign(this.timePeriod, newPeriod);
		this.dataSource.options.transport.read.url = this.getDataSourceUrl();
		this.dataSource.read();
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#cw_last_changes').off();
	},
	/**
	 * Attaches the listeners
	 */
	attachListeners: function () {
		$('#cw_last_changes_list').on('click', '.cw_grid_link', $.proxy(this.onLastChangesNameClick, this));
	},

	/**
	 * Initialize kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter;
		var lastChangesSort, lastChangesFilter,
			defaultFsView = UserPrefs.get('defaultFsView'), pref;

		let eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 30,
			playing: true
		};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_last_changes_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});
		this.defaultColumns = {
			stateFilter: {
				hidden: false,
				width: 60
			},
			name: {
				hidden: false,
				width: 300
			},
			type: {
				hidden: false,
				width: 120
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 120
			},
			stateChangeTime: {
				hidden: false,
				width: 200
			},
			stateDuration: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};
		if (Utils.isGuid(defaultFsView)) {
			pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			lastChangesSort = pref.sort;
			lastChangesFilter = pref.filter;
			this.lastChangesColumns = pref.columns;
		} else {
			if (UserPrefs.get('lastChangesSort')) {
				lastChangesSort = JSON.parse(UserPrefs.get('lastChangesSort'));
			} else {
				lastChangesSort = [{
					field: 'date',
					dir: 'desc',
					compare: null
				}];
			}
			if (UserPrefs.get('lastChangesFilter')) {
				lastChangesFilter = JSON.parse(UserPrefs.get('lastChangesFilter'));
			} else {
				lastChangesFilter = [];
			}
		}
		const url = this.getDataSourceUrl();

		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			schema: {
				model: {
					fields: {
						stateFilter: {
							type: 'string'
						},
						stateDuration: {
							type: 'number'
						}
					}
				},
				parse: function (data) {
					var length = data.length;
					for (var i = 0; i < length; i++) {
						data[i].stateFilter = data[i].severity;
						if (data[i].agentState === 'MAINTENANCE' || data[i].operatingState === 'IN_MAINTENANCE') {
							data[i].stateFilter = 'MAINTENANCE';
						} else if (data[i].agentState === 'WARNING') {
							data[i].stateFilter = 'WARNING';
						} else if (data[i].agentState === 'ERROR') {
							data[i].stateFilter = 'ERROR';
						}
					}
					return data;
				}
			},
			sort: lastChangesSort,
			filter: lastChangesFilter,
			error: ErrorHandler.kendoServerError
		});

		var lastChangesColumns = this.lastChangesColumns || JSON.parse(UserPrefs.get('lastChangesColumns')) || this.defaultColumns;
		lastChangesColumns = Utils.completeColumns(lastChangesColumns, this.defaultColumns);
		var scope = this;
		this.grid = $('#cw_last_changes_list').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'stateFilter',
				title: ' ',
				//template: '#= Renderer.severityState(type, severity, operatingState, agentState)#'
				template: item => getSeverityStateLastChanges(item.type, item.severity, item.operatingState, item.agentState),
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'stateFilter',
							secondField: 'severity',
							grid: scope.grid,
							forceFilter: [{
								field: 'stateFilter',
								operator: 'eq',
								value: 'ERROR'
							}, {
								field: 'stateFilter',
								operator: 'eq',
								value: 'WARNING'
							}, {
								field: 'stateFilter',
								operator: 'eq',
								value: 'MAINTENANCE'
							}],
							dataSource: new kendo.data.DataSource({ data: [{
								text: lang.CRITICAL + '/' + lang.DOWN,
								icon: '<span class="cw_event_indicator cw_status is_critical"></span>',
								value: 'CRITICAL'
							}, {
								text: lang.MAJOR + '/' + lang.WARNING,
								icon: '<span class="cw_event_indicator cw_status is_major"></span>',
								value: 'MAJOR'
							}, {
								text: lang.MINOR,
								color: 'is_minor',
								icon: '<span class="cw_event_indicator cw_status is_minor"></span>',
								value: 'MINOR'
							}, {
								text: lang.OK + '/' + lang.UP,
								icon: '<span class="cw_event_indicator cw_status is_ok"></span>',
								value: 'NONE'
							}, {
								text: lang.ERROR,
								icon: '<span class="cw_status_indicator cw_status"><span class="cw_indicator glyphicons remove" style="background-color: #333; color: #fff; padding: 2px; font-size: 12px;"></span></span>',
								value: 'ERROR'
							}, {
								text: lang.WARNING,
								icon: '<span class="cw_status_indicator cw_status"><span class="cw_indicator glyphicons exclamation-sign"></span></span>',
								value: 'WARNING'
							}, {
								text: lang.MAINTENANCE,
								icon: '<span class="cw_status_indicator cw_status"><span class="cw_indicator glyphicons wrench"></span></span>',
								value: 'MAINTENANCE'
							}], serverSorting: true})
						});
					}
				},
				valueRenderer: function (value) {
					var data = [lang.CRITICAL, lang.MAJOR, lang.MINOR, lang.OK, '', '', lang.UNAVAILABLE];
					return '<span class="cw_status_indicator cw_status_widget_color cw_color' + Utils.severityToColor(value) + '"></span><span>' + data[value] + '</span>';
				},
				hidden: lastChangesColumns.stateFilter.hidden,
				attributes: {
					'class': 'text_center'
				},
				width: lastChangesColumns.stateFilter.width
			}, {
				field: 'name',
				title: lang.summary.NAME,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id#" data-link="#= type#">#= name#</a>',
				sortable: true,
				filterable: true,
				hidden: lastChangesColumns.name.hidden,
				width: lastChangesColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'type',
				title: lang.TYPE,
				sortable: true,
				filterable: true,
				hidden: lastChangesColumns.type.hidden,
				width: lastChangesColumns.type.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				hidden: !State.includeSubaccounts || lastChangesColumns.accountName.hidden,
				width: lastChangesColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'stateChangeTime',
				title: lang.service.STATE_CHANGE,
				template: '#=Renderer.browserDateRenderer(stateChangeTime, "datetime")#',
				sortable: true,
				filterable: false,
				hidden: lastChangesColumns.stateChangeTime.hidden,
				width: lastChangesColumns.stateChangeTime.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'stateDuration',
				title: lang.service.CURRENT_STATE,
				template: '#=Renderer.duration(stateDuration)#',
				sortable: true,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: '1 ' + lang.HOUR,
								value: 3600000
							}, {
								text: '5 ' + lang.HOURS,
								value: 18000000
							}, {
								text: '10 ' + lang.HOURS,
								value: 36000000
							}, {
								text: '1 ' + lang.DAY,
								value: 84600000
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE
						});
					}
				},
				hidden: lastChangesColumns.stateDuration.hidden,
				width: lastChangesColumns.stateDuration.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: true,
				hidden: lastChangesColumns.description.hidden,
				width: lastChangesColumns.description.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], lastChangesColumns),
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onGridDataBound, this),
			columnMenu: true
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('lastChangesSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_last_changes_filters',
			grid: this.grid,
			counter: true,
			modulePrefName: 'LastChanges',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'lastChangesFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});

		setTimeout($.proxy(function () {
			$('#cw_account_services').find('.k-grid-header-wrap th').first().empty().append('<span id="cw_collapse_services" class="cw_collapse_expand_toggle cw_groups_collapsed" title="' + lang.COLLAPSE_ALL + '"></span>');
			$('#cw_collapse_services').off();
			$('#cw_collapse_services').on('click', $.proxy(this.onGroupsToggle, this));
		}, this), 300);
		// removes loading mask
		this.removeMask();
	},
	/**
	 * Handler function for the click event on the name in last changes grid
	 * @param {Object} e The click event object
	 */
	onLastChangesNameClick: function (e) {
		var id = $(e.currentTarget).attr('data-id');
		var accountId = this.dataSource.get(id).accountId;
		var accountName = this.dataSource.get(id).accountName;
		var type = $(e.currentTarget).attr('data-link');
		if (type === 'SERVICE') {
			NavigationStore.go(ServicesRouter.details(id));
		} else {
			NavigationStore.go(AssetsRouter.details(id));
		}
		e.stopPropagation();
	},
	/**
	 * Handler function for the selection of one service log grid row
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_last_changes_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the selection of one system errors log grid row
	 */
	onGridDataBound: function () {
		var tooltip = $('#cw_last_changes_list').find('.k-grid-content').data('kendoTooltip');
		if (tooltip) {
			tooltip.destroy();
		}
		$('#cw_last_changes_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		$('.cw_last_changes_period').addClass('hide');
		this.adjustSectionHeight();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').first().css('height', sectionHeight - 40);
	},
	setGridSize: function () {
		//this.gridHeight = $('.cw_section_full').height();
		this.adjustSectionHeight();
		if (this.grid) {
			this.grid.refresh();
		}
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var timestamp = Date.now();
		var preferences = [{
			key: 'timePeriod',
			value: JSON.stringify(this.timePeriod)
		}, {
			key: 'lastChangesTimestamp',
			value: timestamp
		}];
		State.lastChangesTimestamp = timestamp;
		this.saveUserPrefs({
			category: 'LastChanges',
			preferences: preferences,
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'lastChangesSearchPhrase',
				columns: 'lastChangesColumns',
				sort: 'lastChangesSort',
				filter: 'lastChangesFilter',
				FsViews: 'lastChangesFSViews',
				defaultFsView: 'defaultFsView'
			}
		});
	},
	onEventsTimeout: function () {
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
		this.grid.dataSource.read();
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: async function () {
		this.userPref = [];
		return new Promise((resolve, reject) => {
			UserPrefs.load('LastChanges', $.proxy(function (result) {
				if (result.success) {
					this.userPref = result.data;
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
					reject();
					return;
				}
				resolve();
			}, this));
		});
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}, {
			eventType: 'ServiceSummary',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy method
	 */
	destroy: function () {
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		this.saveUserPreferences();
		this.timeSelectorRoot?.unmount();
		Application.prototype.destroy.call(this);
	},

	getDataSourceUrl: function () {
		const urlParams = timePeriodToUrl(this.timePeriod);
		return `${Settings.serverPath}accounts/${ApplicationState.accountId}/summary/lastChanges?includeSubaccounts=${ApplicationState.includeSubaccounts}&${urlParams}`;
	}
});
