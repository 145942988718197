import {State, Utils} from "tools";
import {Settings} from "settings";
import {Cookies, ErrorHandler, RemoteEventsManager, StaticVars} from "core";
import {Dialog, GridMenu, MultiToggle} from "controls";
import moment from "moment";
import {CeeViewDataSource} from "tools/ceeViewDataSource";


export let TeamScheduleView = function (config) {
	Utils.apply(this, config);
	this._events = [];
	this.langLocale = lang.momentsLangCode;
	this.days = lang.regionalSchedulerDays;
	moment.locale(this.langLocale);
	this.initComponent();
};

jQuery.extend(TeamScheduleView.prototype, kendo.Observable.prototype, {
	initComponent: function () {
		this.update = true;
		if (this.mode === 'view') {
			this.update = false;
		}
		this.schedulerMenu = new GridMenu({
			renderTo: 'cw_team_scheduler_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.ADD_NOTIFICATION,
				fn: this.onNotificationCreate,
				scope: this,
				disabled: this.mode === 'view' ? true : false
			}, {
				id: 'cw_disable_all',
				icon: 'ban-circle',
				text: lang.DISABLE_NOTIFICATION,
				fn: this.onNotificationsDisable,
				scope: this,
				disabled: this.mode === 'view' ? true : false
			}, {
				id: 'cw_delete_all',
				icon: 'bin',
				text: lang.DELETE_NOTIFICATION,
				fn: this.onNotificationsDelete,
				scope: this,
				disabled: this.mode === 'view' ? true : false
			}]
		});
		this.isSchedulerEnabled = true;
		this.weekdays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
		this.editorTemplate = '';
		this.markInboxAsRead = false;
		this.isEditWindowOpen = false;
		if (this.mode === 'update') {
			var onlyLoadData = true;
			this.getSchedulerPeriods(onlyLoadData);
		}

	},
	/**
	 * Updates the notifications data
	 */
	updateSchedulerDataSource: function () {
		var loadUrl = Settings.serverPath + 'accounts/' + (this.accountContext || Cookies.CeesoftCurrentAccountId) + '/teams/' + this.id + '/notificationPolicy?update=' + this.update;
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			var data, periods;
			if (result.success) {
				data = result.data;
				this.schedulerId = data.id;
				this.isSchedulerEnabled = data.isEnabled;
				this.markInboxAsRead = data.markInboxAsRead;
				if (data && data.periods) {
					this.schedulerData = data.periods;
					if (this.scheduler) {
						periods = this.getPeriods(data.periods);
						this.scheduler.dataSource.data(periods);
						this.scheduler.refresh();
						this.updateSchedulerMenu();
					}
				}
			}
		}, this));
	},
	/**
	 * Creates the notification panel (scheduler)
	 * @param {Array} periods
	 */
	createScheduler: function (periods) {
		var scheduler = $('#cw_team_scheduler'), startTime = new Date().setHours(0, 0, 0, 0),
			endTime = new Date().setHours(23, 59, 59, 999),
			eventTemplate = '',
			timeTemplate = '',
			timeFormat = '';
		if (Cookies.CeesoftUserTimeFormat.indexOf(' a') !== -1) {
			timeFormat += 'hh:mm tt';
		} else {
			timeFormat += 'HH:mm';
		}
		this.editorTemplate += '<div id="cw_notification_editor" class="cw_event_form form">';
		this.editorTemplate += '<div class="cw_field">';
		this.editorTemplate += '	<label class="cw_inline">' + lang.CHANNELS + '</label>';
		this.editorTemplate += '	<div class="cw_dropdown_container">';
		this.editorTemplate += '		<select id="cw_channels_select" data-role="multiselect" data-bind="value: channels, source: channelDataSource, events: { change: onChannelsChange }">';
		this.editorTemplate += '		</select>';
		this.editorTemplate += '	</div>';
		this.editorTemplate += '</div>';
		this.editorTemplate += '<div class="cw_field">';
		this.editorTemplate += '	<select id="cw_weekday_select" class="left" data-role="dropdownlist" name="day" data-bind="value: day" >';
		this.editorTemplate += '		<option selected value="MONDAY">' + lang.MONDAY + '</option>';
		this.editorTemplate += '		<option value="TUESDAY">' + lang.TUESDAY + '</option>';
		this.editorTemplate += '		<option value="WEDNESDAY">' + lang.WEDNESDAY + '</option>';
		this.editorTemplate += '		<option value="THURSDAY">' + lang.THURSDAY + '</option>';
		this.editorTemplate += '		<option value="FRIDAY">' + lang.FRIDAY + '</option>';
		this.editorTemplate += '		<option value="SATURDAY">' + lang.SATURDAY + '</option>';
		this.editorTemplate += '		<option value="SUNDAY">' + lang.SUNDAY + '</option>';
		this.editorTemplate += '		<option value="BUSINESSWEEK">' + lang.BUSINESSWEEK + '</option>';
		this.editorTemplate += '		<option value="WEEKEND">' + lang.WEEKEND + '</option>';
		this.editorTemplate += '		<option value="WEEK">' + lang.WEEK + '</option>';
		this.editorTemplate += '	</select>';
		this.editorTemplate += '<ul id="cw_all_day_toggle"></ul>';
		this.editorTemplate += '	<input id="cw_notification_start" class="cw_event_start" data-role="timepicker" name="start" data-bind="value: start, events: { change: onStartChange }" data-format="' + timeFormat + '" data-parse-formats="HH:mm" />';
		this.editorTemplate += '	<input id="cw_notification_end" class="cw_event_end" data-role="timepicker" name="end" data-bind="value: end, events: { change: onEndChange }" data-max="23:59" data-format="' + timeFormat + '" data-parse-formats="HH:mm" />';
		this.editorTemplate += '</div>';

		this.editorTemplate += '</div>';

		eventTemplate += '<span class="cw_event_channel" title="(#= kendo.toString(start, "' + timeFormat + '") # - #= kendo.toString(end, "' + timeFormat + '") #) ';
		eventTemplate += '# for (var i = 0; i < resources.length; i++) { #';
		eventTemplate += '#: resources[i].text #';
		eventTemplate += '# } #';
		eventTemplate += '">';
		eventTemplate += '# for (var i = 0; i < resources.length; i++) { #';
		eventTemplate += '#: resources[i].text #';
		eventTemplate += '# } #';
		eventTemplate += '</span>';

		timeTemplate += '<span class="cw_major_time">';
		timeTemplate += '#=kendo.toString(date, "' + timeFormat + '")#';
		timeTemplate += '</span>';

		scheduler.empty();

		this.schedulerSchema = {
			model: {
				id: 'id',
				fields: {
					id: {from: 'id'},
					title: {from: 'title', defaultValue: '', validation: {required: false}},
					start: {type: 'date', from: 'start'},
					end: {type: 'date', from: 'end'},
					day: {from: 'day'},
					isAllDay: {type: 'boolean', from: 'isAllDay'},
					channels: {from: 'channels'}
				},
				onChannelsChange: $.proxy(this.onChannelsChange, this),
				onStartChange: $.proxy(this.onStartChange, this),
				onEndChange: $.proxy(this.onEndChange, this),
				channelDataSource: new CeeViewDataSource({
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + (this.accountContext || Cookies.CeesoftCurrentAccountId) + '/teams/' + this.id + '/notificationPolicy/availableChannels',
							dataType: 'json',
							cache: false
						}
					},
					schema: {
						data: function (result) {
							return result.data;
						}
					}
				})
			}
		};

		this.scheduler = scheduler.kendoScheduler({
			date: new Date(),
			messages: {
				editor: {
					title: lang.NOTIFICATION_TITLE,
					editorTitle: lang.NOTIFICATION_TITLE
				},
				event: lang.EVENT,
				allDay: lang.service.SLA_ALL_DAY,
				save: lang.UPDATE,
				destroy: lang.DELETE,
				cancel: lang.CANCEL,
				deleteWindowTitle: lang.DELETE_NOTIFICATION
			},
			dateHeaderTemplate: kendo.template("<strong>#=kendo.toString(date, 'dddd')#</strong>"),
			dataSource: new kendo.data.SchedulerDataSource({
				data: periods || [],
				schema: this.schedulerSchema
			}),
			resources: [
				{
					field: 'channels',
					name: 'Channels',
					multiple: true,
					dataColorField: 'color',
					dataSource: [
						{text: 'SMS', value: 'SMS', color: StaticVars.BLUE},
						{text: "Email", value: 'EMAIL', color: StaticVars.GREEN},
						{text: "OpsGenie", value: 'OPSGENIE', color: StaticVars.RED},
						{text: "Twitter", value: 'TWITTER', color: StaticVars.YELLOW},
						{text: "RSS", value: 'RSS', color: StaticVars.ORANGE},
						{text: "MOBILE", value: 'MOBILE', color: StaticVars.CYAN},
						{text: "INBOX", value: 'INBOX', color: '#684198'}
					]
				}
			],
			editable: {
				template: this.editorTemplate,
				confirmation: lang.account.messages.DELETE_NOTIFICATION_CONFIRMATION,
				move: this.mode === 'view' ? false : true,
				resize: this.mode === 'view' ? false : true,
				destroy: this.mode === 'view' ? false : true,
				window: {
					activate: function () {
						$('#cw_notification_editor').kendoValidator({
							rules: {
								validTime: function (input) {
									if (input.is("#cw_notification_start") && !kendo.parseDate(input.val())) {
										return false;
									}

									if (input.is("#cw_notification_end") && !kendo.parseDate(input.val())) {
										return false;
									}

									if (kendo.parseDate($("#cw_notification_start").val()) >= kendo.parseDate($("#cw_notification_end").val())) {
										return false;
									}
									return true;
								}
							},
							messages: {
								validTime: lang.messages.INVALID_TIME
							}
						});
					}
				}
			},
			eventTemplate: eventTemplate,
			views: [{
				type: 'week',
				allDayEventTemplate: eventTemplate
			}],
			minorTickCount: 1,
			majorTimeHeaderTemplate: kendo.template(timeTemplate),
			workDayStart: new Date("2013/1/1 09:00 AM"),
			workDayEnd: new Date("2013/1/1 5:00 PM"),
			footer: false,
			edit: $.proxy(this.onNotificationEdit, this),
			save: $.proxy(this.onNotificationSave, this),
			cancel: $.proxy(this.onNotificationCancel, this),
			remove: $.proxy(this.onNotificationRemove, this),
			moveStart: $.proxy(this.checkIfDisabled, this),
			resizeStart: $.proxy(this.checkIfDisabled, this),
			moveEnd: $.proxy(this.onNotificationMoveEnd, this),
			dataBound: $.proxy(this.onNotificationsDataBound, this)
		}).data('kendoScheduler');
	},
	/*
	 * Handler function for rendering severity threshold
	 * */
	renderSeverityThresholdPreview: function () {
		$('.cw_severity_threshold_preview').empty();
		if (this.severityThreshold) {
			var severity = {
				'CRITICAL': {
					text: lang.CRITICAL,
					indicator: 1,
					value: 'CRITICAL'
				},
				'MAJOR': {
					text: lang.MAJOR,
					indicator: 2,
					value: 'MAJOR'
				},
				'MINOR': {
					text: lang.MINOR,
					indicator: 3,
					value: 'MINOR'
				},
				'NONE': {
					text: lang.OK,
					indicator: 5,
					value: 'NONE'
				}
			};
			var html = '<span class="cw_inline_status_indicator cw_status_widget_color cw_color' + severity[this.severityThreshold].indicator + '"></span> <span class="label">' + severity[this.severityThreshold].text + '</span>';
			$('.cw_severity_threshold_preview').removeClass('hide').empty().append(html);
			if (this.notificationTypes.length) {
				var typesDataSource = new CeeViewDataSource({
					change: $.proxy(function (e) {
						var title = '';
						var length = this.notificationTypes.length;
						for (var i = 0; i < length; i++) {
							for (var j = 0; j < e.items.length; j++) {
								if (this.notificationTypes[i] === e.items[j].value) {
									title += e.items[j].text;
								}
							}
							if (i < length - 1) {
								title += ', ';
							}
						}
						$('.cw_severity_threshold_preview').attr('title', title);
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + this.accountContext + '/teams/' + this.id + '/notificationPolicy/notificationTypes',
							contentType: 'application/json; charset=utf-8',
							type: 'GET',
							dataType: 'json',
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				typesDataSource.read();
			}
		}
	},
	/**
	 * Handler function for the resizeStart and moveStart events
	 * @param {Object} e
	 */
	checkIfDisabled: function (e) {
		if (!this.isSchedulerEnabled) {
			e.preventDefault();
		}
	},
	/**
	 * Handler function for the click event on the 'All day' checkbox
	 * @param {boolean} value The value of the clicked item
	 */
	onAllDayClick: function (value) {
		var start = $('#cw_notification_start').data('kendoTimePicker'),
			end = $('#cw_notification_end').data('kendoTimePicker'),
			now = new Date(),
			startDate = start.value(),
			endDate = end.value();

		if (value) {
			start.enable(false);
			end.enable(false);
			now.setHours(0, 0, 0);
			start.value(now);
			end.value(now);
		} else {
			start.enable(true);
			end.enable(true);
			endDate = new Date(startDate.getTime() + 30 * 60 * 1000);
			end.value(endDate);
		}
	},
	/**
	 * Handler function for the change event on the 'Start' time picker
	 * @param {Object} e The change event
	 */
	onStartChange: function (e) {
		var start = $('#cw_notification_start').data('kendoTimePicker'),
			end = $('#cw_notification_end').data('kendoTimePicker'),
			startDate = start.value(),
			endDate = end.value();
		this.editorClearInvalid();
		if (startDate) {
			if (endDate && startDate.getTime() > endDate.getTime()) {
				endDate = new Date(startDate.getTime() + 30 * 60 * 1000);
				end.value(endDate);
			}
		} else {
			endDate = new Date(kendo.date.today().getTime() + 30 * 60 * 1000);
			end.min(endDate);
		}
	},
	/**
	 * Handler function for the change event on the 'End' time picker
	 * @param {Object} e The change event
	 */
	onEndChange: function (e) {
		this.editorClearInvalid();
	},
	/**
	 * Handler function for the change event on the 'Channels' multiselect
	 * @param {Object} e The change event
	 */
	onChannelsChange: function (e) {
		this.editorClearInvalid();
	},
	/**
	 * Handler function for the edit event of the kendo scheduler
	 * @param {Object} e The cancel event
	 */
	onNotificationMoveEnd: function (e) {
		var event = e.event,
			day = e.start.getDay(),
			startDay = e.start.getDay(),
			endDay = e.end.getDay();
		if (startDay !== endDay) {
			e.preventDefault();
		} else {
			event.day = this.weekdays[day];
		}
	},
	/**
	 * Handler function for the cancel event of the kendo scheduler
	 * @param {Object} e The cancel event
	 */
	onNotificationEdit: function (e) {
		var event = e.event,
			day = event.start.getDay();
		this.isEditWindowOpen = true;
		this.allDayToggle = null;
		this.allDayToggle = new MultiToggle({
			id: 'cw_all_day_toggle',
			cssClass: 'cw_multi_toggle left',
			value: false,
			items: [{
				id: 'cw_not_all_day',
				title: lang.CUSTOM,
				selected: true,
				cssClass: 'glyphicons stopwatch',
				fn: this.onAllDayClick,
				scope: this,
				value: false
			}, {
				id: 'cw_all_day',
				title: lang.service.SLA_ALL_DAY,
				selected: false,
				cssClass: 'glyphicons calendar all_day',
				fn: this.onAllDayClick,
				scope: this,
				value: true
			}]
		});

		if (!event.day) {
			$('#cw_weekday_select').data('kendoDropDownList').value(this.weekdays[day]);
			event.day = this.weekdays[day];
		}
		if (event.title === 'new') {
			$('#cw_notification_start').data('kendoTimePicker').value('00:00');
			$('#cw_notification_end').data('kendoTimePicker').value('00:30');
		}
		if (event.isAllDay) {
			this.allDayToggle.select('cw_all_day');
			$('#cw_notification_start').data('kendoTimePicker').enable(false);
			$('#cw_notification_end').data('kendoTimePicker').enable(false);
		}
		$('.k-button.k-scheduler-delete').remove();
	},
	/**
	 * Handler function for the dataBound event of the kendo scheduler
	 * @param {Object} e The dataBound event
	 */
	onNotificationsDataBound: function (e) {
		var scheduler = e.sender,
			data = scheduler.dataSource.data();
		if (data.length && this.mode !== 'view') {
			this.schedulerMenu.enableItem('cw_delete_all');
		} else {
			this.schedulerMenu.disableItem('cw_delete_all');
		}
		this.adjustSchedulerSize();
		this.enableNotifications(this.isSchedulerEnabled);
	},
	/**
	 * Handler for the scheduler save event
	 * @param {Object} e The operation event
	 */
	onNotificationSave: function (e) {
		var event = e.event, start, end, dataSource = [], length, channelsLength, channelKey, item;

		if (this.isEditWindowOpen) {
			start = $('#cw_notification_start').data('kendoTimePicker');
			end = $('#cw_notification_end').data('kendoTimePicker');

			if (this.editorValidate()) {
				if (start && end) {
					event.start = start.value();
					event.end = end.value();
				}
				event.isAllDay = this.allDayToggle.select().value;
				if (event.isAllDay) {
					event.start = new Date(new Date().setHours(0, 0, 0));
					event.end = new Date(new Date().setHours(23, 59, 59));
				}

				dataSource = this.scheduler.dataSource.data();
				length = dataSource.length;
				channelsLength = event.channels.length;

				var days = [];
				if (event.day === 'WEEKEND') {
					//2 days
					days = ['SATURDAY', 'SUNDAY'];
				} else if (event.day === 'WEEK') {
					//7 days
					days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
				} else if (event.day === 'BUSINESSWEEK') {
					//5 days
					days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
				} else {
					days = [event.day];
				}

				var items = [];
				for (var i = 0, length = dataSource.length; i < length; i++) {
					if (!dataSource[i].dirty) {
						item = {
							id: dataSource[i].id,
							title: '',
							channels: dataSource[i].channels,
							day: dataSource[i].day,
							start: dataSource[i].start,
							end: dataSource[i].end,
							isAllDay: dataSource[i].isAllDay
						};
						items.push(new kendo.data.SchedulerEvent(item));
					}
				}

				for (var i = 0, daysLength = days.length; i < daysLength; i++) {
					start = moment(event.start).weekday(this.days[days[i]]).toDate();
					end = moment(event.end).weekday(this.days[days[i]]).toDate();

					for (var j = 0; j < channelsLength; j++) {
						item = {
							id: Utils.guid(),
							title: '',
							channels: [event.channels[j]],
							day: days[i],
							start: start,
							end: end,
							isAllDay: event.isAllDay
						};
						items.push(new kendo.data.SchedulerEvent(item));
					}
				}

				if (event.isAllDay) {
					for (var i = 0; i < channelsLength; i++) {
						for (var j = length - 1; j >= 0; j--) {
							if (items[j] && event.day === items[j].day && !items[j].isAllDay) {
								channelKey = items[j].channels.indexOf(event.channels[i]);
								if (channelKey !== -1) {
									if (items[j].channels.length === 1) {
										items.splice(j, 1);
									} else {
										items[j].channels.splice(channelKey, 1);
									}
								}
							}
						}
					}
				} else {
					for (var i = 0; i < channelsLength; i++) {
						for (var j = length - 1; j >= 0; j--) {
							if (items[j] && event.day === items[j].day && items[j].isAllDay) {
								channelKey = items[j].channels.indexOf(event.channels[i]);
								if (channelKey !== -1) {
									if (items[j].channels.length === 1) {
										items.splice(j, 1);
									} else {
										items[j].channels.splice(channelKey, 1);
									}
								}
							}
						}
					}
				}

				this.scheduler.setDataSource(new kendo.data.SchedulerDataSource({
					data: items,
					schema: this.schedulerSchema || {}
				}));

				//add the events by setting the datasource (above) and do not allow kendo behaviour to add the event
				//so cancel it
				e.preventDefault();
				this.scheduler.cancelEvent();
			} else {
				e.preventDefault();
			}
		} else {
			if (event.start.getDate() !== event.end.getDate()) {
				event.end = new Date(event.end.setDate(event.start.getDate()));
				e.preventDefault();
				e.sender.cancelEvent();
			}
		}
	},
	/**
	 * Validates editor form
	 */
	editorValidate: function () {
		var start = $('#cw_notification_start').data('kendoTimePicker'),
			end = $('#cw_notification_end').data('kendoTimePicker'),
			channels = $('#cw_channels_select').data('kendoSortedMultiSelect'),
			isValid = true;
		if (channels && !channels.value().length) {
			$('#cw_channels_select').prev('.k-multiselect-wrap').addClass('invalid');
			isValid = false;
		}
		if (start && !start.value()) {
			$('#cw_notification_start').addClass('invalid');
			isValid = false;
		}
		if (end && !end.value()) {
			$('#cw_notification_end').addClass('invalid');
			isValid = false;
		}

		return isValid;
	},
	/**
	 * Clears invalid class
	 */
	editorClearInvalid: function () {
		var start = $('#cw_notification_start').data('kendoTimePicker'),
			end = $('#cw_notification_end').data('kendoTimePicker'),
			channels = $('#cw_channels_select').data('kendoSortedMultiSelect');
		if (channels.value().length) {
			$('#cw_channels_select').prev('.k-multiselect-wrap').removeClass('invalid');
		}
		if (start.value()) {
			$('#cw_notification_start').removeClass('invalid');
		}
		if (end.value()) {
			$('#cw_notification_end').removeClass('invalid');
		}
	},
	/**
	 * Handler for the scheduler cancel event
	 * @param {Object} e The operation event
	 */
	onNotificationCancel: function (e) {
		this.isEditWindowOpen = false;
	},
	/**
	 * Handler for teh scheduler remove event
	 * @param {Object} e The operation event
	 */
	onNotificationRemove: function (e) {
		//this.saveScheduler();
	},
	/**
	 * Gets the notifications data
	 */
	getSchedulerPeriods: function (onlyLoadData) {
		var loadUrl = Settings.serverPath + 'accounts/' + (this.accountContext || Cookies.CeesoftCurrentAccountId) + '/teams/' + this.id + '/notificationPolicy?update=' + this.update;
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			var data, periods;
			if (result.success) {
				data = result.data;
				this.schedulerId = data.id;
				this.isSchedulerEnabled = data.isEnabled;
				this.markInboxAsRead = data.markInboxAsRead;
				this.notificationTypes = data.notificationTypes || [];
				this.severityThreshold = data.severityThreshold || '';
				this.excludeFilters = data.excludeFilters || [];
				this.schedulerData = data.periods;
				if (!onlyLoadData) {
					var periods = this.getPeriods(this.schedulerData);
					this.createScheduler(periods);
					this.updateSchedulerMenu();
					this.renderSeverityThresholdPreview();
					if (!State.currentApp.notificationPolicyDataLoaded) {
						State.currentApp.notificationPolicyDataLoaded = true;
						this.trigger('schedulerdataloaded', data);
					}
				}
			}
		}, this));
	},
	/**
	 * Saves the scheduler data
	 */
	saveScheduler: function (callback) {
		var scheduler = $('#cw_team_scheduler').data('kendoScheduler'),
			raw,
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/' + this.id + '/notificationPolicy?updateTriggers=false',
			periods = [];
		if (this.severityThreshold === '') {
			this.severityThreshold = null;
		}
		var policyData = {
			notificationTypes: [],
			excludeFilters: [],
			dbExcludeFilters: [],
			severityThreshold: null
		};
		if (State.currentApp.policyView) {
			policyData = State.currentApp.policyView.savePolicy();
		}
		var data = {
			id: this.schedulerId || State.currentApp.policyView.schedulerId,
			isEnabled: this.isSchedulerEnabled,
			markInboxAsRead: this.markInboxAsRead,
			notificationTypes: policyData.notificationTypes,
			severityThreshold: policyData.severityThreshold,
			excludeFilters: policyData.dbExcludeFilters || policyData.excludeFilters
		};
		setTimeout($.proxy(function () {
			if (scheduler) {
				raw = scheduler.dataSource.data();
				data.periods = this.setPeriods(raw);
			} else {
				data.periods = this.schedulerData;
			}
			Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
				if (result.success) {
					this.updateSchedulerDataSource();
					if (callback) {
						callback(result);
					}
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		}, this), 200);
	},
	/**
	 * Sets the periods in a format that will save notifications to the database
	 * @param {Array} raw Array of periods
	 */
	setPeriods: function (raw) {
		var i, j, length, l, period, periods = [], channels;
		raw = raw || [];
		for (i = 0, length = raw.length; i < length; i++) {
			period = {};
			channels = [];
			if (raw[i].channels.length) {
				for (j = 0, l = raw[i].channels.length; j < l; j++) {
					if (raw[i].channels[j].value) {
						channels.push(raw[i].channels[j].value);
					} else {
						channels.push(raw[i].channels[j]);
					}
				}
				period.channels = channels;
				period.day = raw[i].day || "MONDAY";
				period.start = kendo.toString(raw[i].start, 'yyyy/MM/dd HH:mm');
				period.end = kendo.toString(raw[i].end, 'yyyy/MM/dd HH:mm');
				period.isAllDay = raw[i].isAllDay;
				periods.push(period);
			}
		}
		return periods;
	},
	/**
	 * Gets the periods in a format friendly for the scheduler datasource
	 * @param {Array} raw Raw array of object periods
	 * @return {Array} periods
	 */
	getPeriods: function (raw) {
		var i, j, length, l, period, periods = [], channels;
		raw = raw || [];
		for (i = 0, length = raw.length; i < length; i++) {
			period = {};
			channels = [];
			if (raw[i].channels.length) {
				period.id = Utils.guid();
				period.title = '';
				period.channels = raw[i].channels;
				period.day = raw[i].day;
				period.start = kendo.parseDate(raw[i].start, 'yyyy/MM/dd HH:mm');
				period.end = kendo.parseDate(raw[i].end, 'yyyy/MM/dd HH:mm');
				period.isAllDay = raw[i].isAllDay;
				periods.push(period);
			}
		}
		return periods;
	},
	/**
	 * Handler function for the delete all periods button
	 */
	onNotificationsDelete: function () {
		var dialog = new Dialog({
			title: lang.INFO,
			msg: lang.account.messages.NOTIFICATIONS_REMOVE_CONFIRMATION,
			icon: 'ERROR',
			actionText: 'DELETE',
			fn: $.proxy(function (value, button) {
				if (button === 'ok') {
					this.scheduler.dataSource.data([]);
					this.scheduler.refresh();
				}
			}, this)
		});

		dialog.show();
	},
	/*
	 * Handler function for enabling menu items
	 * */
	setEnableAllMenuItem: function () {
		var item = {
			id: 'cw_enable_all',
			icon: 'restart',
			text: lang.ENABLE_NOTIFICATION,
			fn: this.onNotificationsEnable,
			scope: this,
			disabled: false
		};
		this.schedulerMenu.removeItem('cw_disable_all');
		this.schedulerMenu.addItem(item, 2);
	},
	/*
	 * Handler function for disabling menu items
	 * */
	setDisableAllMenuItem: function () {
		var item = {
			id: 'cw_disable_all',
			icon: 'ban-circle',
			text: lang.DISABLE_NOTIFICATION,
			fn: this.onNotificationsDisable,
			scope: this,
			disabled: false
		};
		this.schedulerMenu.removeItem('cw_enable_all');
		this.schedulerMenu.addItem(item, 2);
	},
	/**
	 * Handler function for the disable all periods button
	 */
	onNotificationsDisable: function () {
		this.setEnableAllMenuItem();
		this.isSchedulerEnabled = false;
		$('.cw_severity_threshold_preview').addClass('is_disabled');
		this.enableNotifications(false);
		//this.saveScheduler();
	},
	/**
	 * Handler function for the enable all periods button
	 */
	onNotificationsEnable: function () {
		this.setDisableAllMenuItem();
		this.isSchedulerEnabled = true;
		$('.cw_severity_threshold_preview').removeClass('is_disabled');
		this.enableNotifications(true);
		//this.saveScheduler();
	},
	/*
	 * Handler function for updating scheduler menu
	 * */
	updateSchedulerMenu: function () {
		if (this.isSchedulerEnabled && this.schedulerMenu.indexOfItem('cw_enable_all') !== -1) {
			this.setDisableAllMenuItem();

		} else if (!this.isSchedulerEnabled && this.schedulerMenu.indexOfItem('cw_disable_all') !== -1) {
			this.setEnableAllMenuItem();
		}
		if (this.markInboxAsRead && this.schedulerMenu.indexOfItem('cw_mark_read') !== -1) {
			//this.setMarkUnreadMenuItem();
		} else if (!this.markInboxAsRead && this.schedulerMenu.indexOfItem('cw_mark_unread') !== -1) {
			//this.setMarkReadMenuItem();
		}
		if (this.isSchedulerEnabled) {
			$('.cw_severity_threshold_preview').removeClass('is_disabled');
		} else {
			$('.cw_severity_threshold_preview').addClass('is_disabled');
		}
	},
	/**
	 * Enables or disables the scheduler notifications
	 * @param {boolean} isEnabled
	 */
	enableNotifications: function (isEnabled) {
		var schedulerDiv = $('#cw_team_scheduler'),
			notifications = schedulerDiv.find('.k-event'),
			overlayHtml = '<div class="cw_notification_overlay"></div>';
		if (isEnabled) {
			notifications.each(function (i, e) {
				$(e).removeClass('is_disabled');
				$(e).find('.cw_notification_overlay').first().remove();
			});
			$('#cw_team_scheduler .cw_content_mask').remove();
			$('#cw_team_scheduler .disabled_schedule').remove();
		} else {
			notifications.each(function (i, e) {
				$(e).addClass('is_disabled');
				$(e).append(overlayHtml);
			});
			$('#cw_team_scheduler').append("<span class='disabled_schedule'>" + lang.messages.DISABLED_SCHEDULE + "</span><div class='cw_content_mask'></div>");
		}
		$('.cw_actions_menu').removeClass('is_open');
	},
	/**
	 * Handler function for the create notification button
	 */
	onNotificationCreate: function () {
		this.scheduler.addEvent({title: "new"});
	},
	/**
	 * Method to resize the scheduler to its full height
	 */
	adjustSchedulerSize: function () {
		var section = $('.cw_scheduler');
		var sectionHeight = section.height();
		section.find('.k-scheduler, .cw_section_content, k-scheduler-layout').css('height', sectionHeight);
		section.find('.k-scheduler-content').css('height', sectionHeight - 96 - 40);
		section.find('.k-scheduler-times').last().css('height', sectionHeight - 96 - 40);
	},
	destroy: function () {
		this.schedulerId && this.mode === 'update' && RemoteEventsManager.discard(this.schedulerId);
	}
});
