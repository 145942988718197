import React from 'react'
import { observer } from 'mobx-react'
import b_ from 'b_'
import { translator } from 'core'
import {StarOutlined, StarFilled} from '@ant-design/icons'
import {FavoritesStore} from "./favoritesStore";
import './favoritesButton.less'

const b = b_.with('favorites-button')
const i = translator({
	'Add to favorites': {},
	'Remove from favorites': {}
})

export const FavoritesButton = observer(() => {
	if (!FavoritesStore.favoriteIconVisible) {
		return null
	}
	const added = FavoritesStore.currentPageIsFavorite
	const title = added ? i('Remove from favorites') : i('Add to favorites')
	return <div className={b({added})} onClick={FavoritesStore.toggleCurrentPage} title={title}>
		{!added && <StarOutlined />}
		{added && <StarFilled />}
	</div>
})
