import './antSelect.less'

import React, {CSSProperties, useCallback, useMemo, useState} from 'react'
import {observer} from 'mobx-react'
import {RefSelectProps, SelectProps, SelectValue} from 'antd/lib/select'
import {Select} from 'antd'
import {Input, Tag, Tooltip} from 'antd'

import {EditOutlined, HolderOutlined} from '@ant-design/icons'
import {renderSelectOptions} from "controls/react/ant/utils"
import {BaseOptionType, DefaultOptionType} from "rc-select/lib/Select"
import {CustomTagProps} from "rc-select/lib/BaseSelect";
import {DragDropContext, Draggable, DraggableProvided, DroppableProvided, DraggableStateSnapshot,  DraggableProvidedDraggableProps, Droppable, DropResult} from "react-beautiful-dnd";
import {LabeledValue} from "antd/es/select";
import classnames from "classnames";
import {useDefaultZIndex} from "framework/react-integration";
import {CssVariables} from "styles/cssVariables";

const {Option, OptGroup} = Select;


export type IdObject = {id: string}
export type AntSelectValue = SelectValue
export type AntSelectRefProps = RefSelectProps
export type AntDefaultOptionType = DefaultOptionType
export const AntTag = Tag

const b = require('b_').with('ceeview-ant-select')

export interface AntSelectPropsGeneric<VT extends AntSelectValue, OptionType extends BaseOptionType = DefaultOptionType> extends SelectProps<VT, OptionType>{
	sortValues?: boolean,
	sortDataList?: boolean
	nameField?: string,
	valueField?: string,
	groupField?: string,
	dataList?: Array<any>,
	grouping?: boolean,
	errors?: string[],
	invalid?: boolean,
	editable?: boolean,
	reorderable?: boolean
	wrapper?: (children: React.ReactNode) => React.ReactNode,
	nonRemovableMessage?: string,
	onBlur?: () => void
	componentRef?: React.Ref<AntSelectRefProps>
	closeOnMouseLeave?: boolean
	customRenderer?: (item: any) => React.ReactNode
	onLabelEdit?: (value: string, label: string) => void
	triggerChangeOnClose?: boolean
	width?: number
	layout?: 'default'|'one-line'
}

export type AntSelectPropsObject<VT extends AntSelectValue, VTObj extends IdObject = {id: string}, OptionType extends BaseOptionType = DefaultOptionType> = Omit<AntSelectPropsGeneric<VT, OptionType>, 'value'|'onChange'|'mode'|'wrapper'> & {
	valueAsObject: true
	value?: VTObj[]
	mode: 'multiple'
	onChange?: (value: VTObj[], option: OptionType|OptionType[]) => void
	objectFields?: (keyof VTObj)[]
}

export type AntSelectPropsPlain<VT extends AntSelectValue, OptionType extends BaseOptionType = DefaultOptionType> = Omit<AntSelectPropsGeneric<VT, OptionType>, 'value'|'onChange'|'mode'|'wrapper'> & ( AntSelectPropsGeneric<VT, OptionType> & {
	valueAsObject?: false | null | undefined}
)

export type AntSelectProps<VT extends AntSelectValue, VTObj extends IdObject = {id: string}, OptionType extends BaseOptionType = DefaultOptionType>
	= AntSelectPropsObject<VT, VTObj, OptionType> | AntSelectPropsPlain<VT, OptionType>

export const AntOption = Option
export const AntOptGroup = OptGroup
export const AntSelect = observer(<VT extends AntSelectValue, VTObj extends IdObject = {id: string}, OptionType extends BaseOptionType = DefaultOptionType>(propsWithObjectValue: AntSelectProps<VT, VTObj, OptionType>) => {
	let props = useObjectValue<VT, VTObj, OptionType, typeof propsWithObjectValue>(propsWithObjectValue)

	props = useCustomOptionFields(props)
	props = useCustomDefaults(props)
	props = useSortedValues(props)
	props = useOnVisibilityChangeToTriggerOnChange(props)
	props = useEditableTagRender(props)
	props = useCloseOnMouseLeave(props)
	props = useSortedDataList(props)
	props = useGroupedDataList(props)
	props = useCustomWidth(props)
	props = useReorderableMultiselect(props)

	let {wrapper, className, nameField, valueField, groupField, dataList, customRenderer, invalid, componentRef, ...restProps} = props

	className = classnames(className, b({
		layout: props.layout
	}))

	const select = <Select className={className} {...restProps} ref={componentRef}>
		{props.children}

		{!props.children && !props.grouping && dataList && renderSelectOptions(dataList, {nameField, valueField}, customRenderer)}
		{!props.children && props.grouping && dataList && renderGroupedSelectOptions(dataList)}

	</Select>

	return wrapper(select)
})

function useOnVisibilityChangeToTriggerOnChange<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>, 'onDropdownVisibleChange'|'onChange'|'value'|'triggerChangeOnClose'>>(props: P) {

	const {onDropdownVisibleChange, triggerChangeOnClose, value, onChange, ...rest} = props

	const [tempValue, setTempValue] = React.useState(() => ({
		value: value,
		option: null
	}))

	let openValueRef = React.useRef(false)

	React.useEffect(() => {
		setTempValue({
			value,
			option: null
		})
	}, [value])

	const onDropdownVisibleChangeWrapper = React.useCallback((open: boolean) => {
		openValueRef.current = open
		props.onDropdownVisibleChange?.(open)
		if(open == false){
			onChange(tempValue.value, tempValue.option)
		}
	}, [onDropdownVisibleChange, onChange, tempValue])

	const onChangeWrapper = React.useCallback((value: VT, option: (OptionType |OptionType[])) => {
		if(!openValueRef.current){
			onChange(value, option)
		}else {
			setTempValue({
				value,
				option
			})
		}
	}, [onChange])

	if (triggerChangeOnClose) {
		return {
			...rest,
			value: tempValue.value,
			onChange: onChangeWrapper,
			onDropdownVisibleChange: onDropdownVisibleChangeWrapper
		}

	} else {
		return props
	}
}

function useCustomWidth<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>, 'width'|'style'>>(props: P) {
	const {width, style, ...rest} = props

	const styleActual = React.useMemo(() => {
		const result = {...(style ?? {})}

		if (width != null) {
			result.width = width + 'px'
		}

		return result
	}, [style, width])

	return {
		style: styleActual,
		...rest
	}
}

function useObjectValue<VT extends AntSelectValue, VTObj extends IdObject, OptionType extends BaseOptionType,
	P extends AntSelectProps<VT, VTObj, OptionType>>(props: P): AntSelectPropsGeneric<VT, OptionType> {

	let fields = ''
	if (props.valueAsObject == true) {
		fields = props.objectFields?.join('') ?? ''
	}

	let processedValue = React.useMemo(() => {
		if (props.valueAsObject == true) {
			let valueObject = props.value as { id: string }[]
			return valueObject?.map(x => x.id) as VT
		} else {
			return props.value
		}
	}, [props.value])

	let onChangeWrapper = React.useCallback((value: VT, option: OptionType | OptionType[]) => {
		if (props.valueAsObject == true) {
			let ids = value as string[]
			let selectedItems = ids
				.map(id => props.dataList.find(x => x.id == id))
				.filter(x => x)
				.map(item => {
					if (props.objectFields?.length > 0) {
						let result = {} as Record<keyof VTObj, any>
						props.objectFields.forEach(field => {
							result[field] = item[field]
						})
						return result
					} else {
						return item
					}
				})
			props.onChange?.(selectedItems, option)
		} else {
			props.onChange?.(value, option)
		}
	}, [fields, props.onChange, props.valueAsObject, props.dataList])

	let propsCopy = {...props}
	if (propsCopy.valueAsObject == true) {
		delete propsCopy.objectFields
	}

	let {value, valueAsObject, onChange, ...rest} = propsCopy

	return {
		value: processedValue,
		onChange: onChangeWrapper,
		...rest
	}
}

function useCustomDefaults<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>, 'dropdownStyle'|'mode'|'options'|'dataList'|'defaultValue'|'wrapper'>>(props: P){

	const {dropdownStyle, ...rest} = props

	const dropDownStyleWrapped = useDefaultZIndex(dropdownStyle, CssVariables.zIndexControlPopover)

	return {
		allowClear: props.mode == 'multiple' || props.mode == "tags",
		optionFilterProp: props.dataList != null
			? 'children'
			: (props.options != null ? 'label' : null),
		showSearch: true,
		nameField: 'name',
		valueField: 'id',
		groupField: 'type',
		dropdownStyle: dropDownStyleWrapped,
		...rest,
		defaultValue: props.defaultValue === null ? undefined : props.defaultValue,
		wrapper: props.wrapper ?? ((children: React.ReactNode) => children)
	}
}

function useCustomOptionFields<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>, 'nameField'|'valueField'|'options'>>(props: P){

	const {options, ...rest} = props

	const optionsConverted = React.useMemo(() => {
		if(props.nameField == null || props.valueField == null || options == null){
			return options
		}

		return options.map(x => {
			let result: (OptionType & {value?: VT, label?: string}) = {...x}
			result.value = x[props.valueField]
			result.label = x[props.nameField]

			return result as (OptionType & {value: VT, label: string})
		})

	},[options, props.nameField, props.valueField] )

	return {
		options: optionsConverted,
		...rest
	}
}
function useCloseOnMouseLeave<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>, 'closeOnMouseLeave'|'onMouseEnter'|'onMouseLeave'|'onDropdownVisibleChange'>>(props: P)
{
	const {onMouseEnter, onMouseLeave, closeOnMouseLeave, onDropdownVisibleChange, ...rest} = props;

	const mouseEnteredDropdownRef = React.useRef(false)
	const mouseLeftTimeoutRef = React.useRef(null)
	const [open, setOpen] = React.useState(false)

	const onMouseEnterWrapper = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
		if(closeOnMouseLeave === true) {
			const target = e.target as HTMLElement
			if (target.closest('.ant-select-dropdown')) {
				mouseEnteredDropdownRef.current = true
				if (mouseLeftTimeoutRef.current) {
					clearTimeout(mouseLeftTimeoutRef.current)
					mouseLeftTimeoutRef.current = null
				}
			}
		}

		onMouseEnter?.(e)
	}, [onMouseEnter])

	const onMouseLeaveWrapper = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
		if(closeOnMouseLeave === true) {
			if (mouseLeftTimeoutRef.current == null) {
				mouseEnteredDropdownRef.current = false
				mouseLeftTimeoutRef.current = setTimeout(() => {
					setOpen(false)
					mouseLeftTimeoutRef.current = null
				}, 300)
			}
		}
		onMouseLeave?.(e)
	}, [onMouseLeave])

	const onDropdownVisibleChangeWrapper = React.useCallback((open: boolean) => {
		setOpen(open)
		onDropdownVisibleChange?.(open)
	}, [closeOnMouseLeave, onDropdownVisibleChange])

	return {
		onMouseEnter: onMouseEnterWrapper,
		onMouseLeave: onMouseLeaveWrapper,
		onDropdownVisibleChange: onDropdownVisibleChangeWrapper,
		open: closeOnMouseLeave === true ? open : undefined,
		...rest
	}
}

type AntGroupEntry = {
	name: string
	items: {
		name: string,
		id: string
	}[]
}

function useGroupedDataList<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>, 'nameField'|'valueField'|'groupField'|'grouping'|'dataList'>>(props: P){

	let {grouping, dataList, ...rest} = props

	let groupedDataList = React.useMemo(() => {
		if(!grouping || !dataList)
			return dataList

		let groups: string[] = [];
		let groupedItems: AntGroupEntry[] = [];
		dataList.forEach(x => {
			if (groups.indexOf(x[props.groupField]) === -1) {
				groups.push(x[props.groupField]);
				groupedItems.push({
					name: x[props.groupField],
					items: [{
						name: x[props.nameField],
						id: x[props.valueField]
					}]
				})
			} else {
				groupedItems.map(y => {
					if (y.name === x[props.groupField]) {
						y.items.push({
							name: x[props.nameField],
							id: x[props.valueField]
						});
					}
				})
			}
		});
	}, [dataList, props.nameField, props.valueField, props.groupField, grouping])

	return {
		dataList: groupedDataList,
		...rest
	}
}

const hiddenStyles = {display:'none'}

function useReorderableMultiselect<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>,
		'mode'|'tagRender'|'reorderable'|'value'|'labelInValue'|'wrapper'|'onChange'>>(props: P) {

	let {tagRender, reorderable, ...rest} = props
	const featureEnabled = props.mode == 'multiple' && props.reorderable == true

	let value = Array.isArray(props.value) ? props.value : [] as VT[]

	const customTagRender = React.useCallback((tagProps: CustomTagProps) => {
		const className = tagProps.closable ? 'ant-tag__closable' : null
		return <Draggable
			key={tagProps.value}
			draggableId={tagProps.value}
			index={value.findIndex((item: SelectValue) =>
				props.labelInValue ? (item as LabeledValue).value === props.value : item === tagProps.value,
			)}
		>
			{(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
					onMouseDown={(e) => {
						e.stopPropagation();
					}}
				>
					{tagRender && tagRender(tagProps)}
					{!tagRender && <Tag className={className} {...tagProps}>
						<HolderOutlined/>
						{tagProps.label}
					</Tag>}
				</div>
			)}
		</Draggable>
	}, [value]);


	let wrapper = React.useCallback((children: React.ReactNode) => {
		const onDragEnd = (result: DropResult) => {
			const { destination, source: {index: dragIndex}} = result

			if (destination) {
				const items = reorder(props.value, dragIndex, destination.index);

				props.onChange?.(items, null);
			}
		}

		return <DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="list" direction="horizontal">
				{(provided: DroppableProvided) => (

					<div className={"ant-select-droppable-wrapper"} {...provided.droppableProps} ref={provided.innerRef}>
						{/*the Droppable component fires an warning if {provided.placeholder} is not used but we dont need it*/}
						<div style={hiddenStyles}>{provided.placeholder}</div>
						{props.wrapper(children)}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	}, [props.value, props.onChange])

	return {
		...rest,
		tagRender: featureEnabled ? customTagRender : tagRender,
		wrapper: featureEnabled ? wrapper : props.wrapper,
	}
}

function useEditableTagRender<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>,
		'mode'|'tagRender'|'options'|'nonRemovableMessage'|'editable'|'onLabelEdit'|'disabled'>>(props: P){

	let {tagRender, nonRemovableMessage, editable, onLabelEdit, ...rest} = props

	const tagRenderForEditableTags = React.useCallback((item: CustomTagProps) => {
		//@ts-ignore
		let element = props.options.find(element => element.value === item.value) as AntSelectEditableTagOptionsEntry<VT>;
		return (
			<EditableTag {...item}
			             editable={props.disabled ? false : (props.editable !== false && (element?.editable ?? false) && props.onLabelEdit != null)}
			             removable={props.disabled ? false : (element ? element.removable : true)}
			             tooltip={element ? element.tooltip : ''}
			             nonRemovableMessage={nonRemovableMessage}
			             onLabelEdit={props.onLabelEdit}
			/>
		);

	}, [props.options]);

	return {
		tagRender: props.mode == 'tags' && tagRender == null ? tagRenderForEditableTags : tagRender,
		...rest
	}
}

function useSortedDataList<VT extends AntSelectValue, OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>, 'sortDataList'|'dataList'|'nameField'>>(props: P){

	let {sortDataList, dataList, ...rest} = props

	let sortedDataList = useMemo(() => {
		if(!sortDataList || !dataList)
			return dataList

		const dataListCopy = JSON.parse(JSON.stringify(dataList));
		dataListCopy.sort((a: any, b: any) =>
			a[props.nameField]?.toString().localeCompare(b[props.nameField]?.toString(), undefined, {sensitivity: 'accent'})
		);
		return dataListCopy;
	}, [sortDataList, dataList, props.nameField])

	return {
		dataList: sortedDataList,
		...rest
	}
}

//does not work in uncontrolled mode
function useSortedValues<
	VT extends AntSelectValue,
	OptionType extends BaseOptionType,
	P extends Pick<AntSelectPropsGeneric<VT, OptionType>, 'mode'|'dataList'|'nameField'|'valueField'|'value'|'sortValues'>>(
	props: P
){
	let {value, sortValues, ...rest} = props
	let sortedValue = React.useMemo(() => {
		if(!props.value){
			return props.value
		}

		if (!props.dataList || !props.sortValues || (props.mode != "tags" && props.mode != "multiple") ) {
			return value
		}

		let values = props.value as VT[]
		if(!values){
			values = []
		}

		//we need to sort selected values by names and not by values
		//so we are looking for names in children collection
		let selectedObjects = values.map(value => {
			let object = props.dataList.find(node => node[props.valueField] == value);
			return object == null ?
				{[props.valueField]: value}
				: object;
		});

		selectedObjects.sort((a,b) =>
			a[props.nameField]?.toString().localeCompare(b[props.nameField]?.toString(), undefined, {sensitivity: 'accent'})
		);

		return selectedObjects.map( x => x[props.valueField]);
	}, [props.value, props.dataList, props.nameField, props.value, props.sortValues])

	return {
		value: sortedValue as VT,
		...rest
	}
}

type AntSelectEditableTagProps<VT extends AntSelectValue> = CustomTagProps & {
	value: VT
	label: React.ReactNode
	editable?: boolean
	removable?: boolean
	nonRemovableMessage?: string
	tooltip?: string
	onLabelEdit?: (value: VT, newLabel: string) => void
}

export type AntSelectEditableTagOptionsEntry<VT extends AntSelectValue>
	= Pick<AntSelectEditableTagProps<VT>, 'value'|'label'|'editable'|'removable'|'tooltip'>


export const EditableTag = observer(<VT extends AntSelectValue>(props: AntSelectEditableTagProps<VT>) => {
	const [editMode, setEditMode] = useState(false)

	const setValue = useCallback((e:(React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>)) => {
		setEditMode(false);
		props.onLabelEdit?.(props.value, ('value' in e.target) ? e.target.value.trim() : null)
	}, []);

	const preventPropagationToParent = useCallback((e:React.KeyboardEvent<HTMLInputElement>) => {
		//ant select control removes tag on backspace
		//enter forces all tags list to be opened, we dont need it
		if (e.keyCode == 8 || e.keyCode == 13) { //backspace or enter
			e.stopPropagation();
		}
	}, []);

	let tooltip = props.tooltip
		? props.tooltip
		: props.removable
			? ''
			: props.nonRemovableMessage

	const defaultValue = Array.isArray(props.label) ? props.label.filter(x => !!x) : props.label

	return (
		<div onClick={e => e.stopPropagation()}>
			{!editMode && <Tag onClose={props.onClose} closable={props.removable !== undefined ? props.removable : true}>
				<Tooltip title={tooltip}
				         zIndex={10010}>{props.label}</Tooltip>
				{props.editable && <EditOutlined onClick={() => {
					setEditMode(true)
				}}/>}
			</Tag>}
			{editMode && <Input size="small"
			                    defaultValue={defaultValue.toString()}
			                    allowClear
			                    autoFocus={true}
			                    onKeyDown={preventPropagationToParent}
			                    onBlur={(e) => setValue(e)}
			                    onPressEnter={(e) => setValue(e)}
			                    onMouseDown={e => e.stopPropagation()} //changing position in input by clicking would not work without that
			                    style={{width: '100px'}}/>}
		</div>
	);
})

export type AntSelectOption = DefaultOptionType

export const textValueFields = Object.freeze({
	nameField: 'text',
	valueField: 'value',
	groupField: 'type'
});

export function renderGroupedSelectOptions(list: AntGroupEntry[]) {
	const {OptGroup, Option} = Select;
	return list.map(x => <OptGroup label={x.name} key={x.name}>
		{x.items.map(y => <Option value={y.id} key={y.id}>{y.name}</Option>)}
	</OptGroup>)
}

export function fromIdName<VT, T extends {id: VT, name: string}>(items: T[]){
	return items.map(x => {
		let result: (T & {value?: VT, label?: string}) = {...x}
		result.value = x.id
		result.label = x.name

		delete result.id
		delete result.name

		return result as (Omit<T, 'id'|'name'> & {value: VT, label: string})
	})
}

export function fromStringsArray(items: string[]){
	return items.map(x => ({
		label: x,
		value: x
	}))
}

const getItemStyle = (isDragging: boolean, draggableStyle: DraggableProvidedDraggableProps['style']): CSSProperties => ({
	userSelect: 'none',
	opacity: isDragging ? 0.5 : 1,
	...draggableStyle,
})

function reorder<VT extends AntSelectValue>(list: VT, startIndex: number, endIndex: number){
	if(!Array.isArray(list))
		return list

	const result = [...list]

	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result as VT
}

export const fieldNamesIdName = {
	label: 'name',
	value: 'id'
}
