import {TimeWidget} from "./timeWidget";
import {TimeWidgetConfiguration} from './timeWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: TimeWidgetConfiguration,
		defaultConfig: {
			type: 'sla-time',
			title: i('Time'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		widget: TimeWidget,
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('SLA') + '/' + i('Time'),
	}
}
