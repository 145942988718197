import {Severity} from "../../../framework/entities/healthData";
import {KeysMatching} from "../../../framework/typescript-integration";
import {ModelValidator, ValidatableModel} from "../../../framework/mobx-integration";
import {makeAutoObservable} from "mobx";

export class CostThresholdValue implements ValidatableModel<CostThresholdValue> {
	validator = new ModelValidator<CostThresholdValue>(this);
	enabled: boolean
	value: number
	severity: Severity

	constructor(props: Partial<CostThresholdValue>) {
		Object.assign(this, props);
		makeAutoObservable(this)
		this.validator
			.required('value', () => this.enabled)
			.required('severity', () => this.enabled)
	}

	get valid() {
		return this.validator.valid
	}
}

export class CostThreshold implements ValidatableModel<CostThreshold>{
	enabled: boolean
	warning: CostThresholdValue
	critical: CostThresholdValue
	validator = new ModelValidator<CostThreshold>(this);

	constructor() {
		makeAutoObservable(this)
	}

	get validRelation() {
		if(!(this.warning.enabled && this.critical.enabled)) {
			return true
		}
		const wv = this.warning.value
		const cv = this.critical.value
		if(wv < 0 == cv < 0) { // same sign
			return Math.abs(cv) > Math.abs(wv)
		}
		return wv >= 0 && cv <= 0; // only warning might be positive if different signs
	}

	get valid() {
		return this.warning.valid && this.critical.valid
	}
}

export interface CostThresholds {
	year: CostThreshold
	month: CostThreshold
}

export const costThresholdPeriods = ['year', 'month'] as KeysMatching<CostThresholds, CostThreshold>[]
export const costThresholdTypes = ['warning', 'critical'] as KeysMatching<CostThreshold, CostThresholdValue>[]
