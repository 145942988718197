import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";
import FilteringPanel from "areas/services/widgets/filterPanel/filteringPanel";

export function ReasonsWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {ReasonsWidgetConfiguration as default}

jQuery.extend(ReasonsWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="cw_field" id="cw_grid_sla_account"><label class="cw_inline">' + lang.ACCOUNT + ': </label><div class="cw_dropdown_container"><input id="cw_grid_reasons_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div></div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" id="cw_reasons_include_subaccounts_check" class="cw_show_column k-checkbox"/>';
		html += '<label for="cw_reasons_include_subaccounts_check" class="k-checkbox-label">' + lang.INCLUDE_SUBACCOUNTS + '</label>';
		html += '</div>';
		html += '<ul class="cw_list_columns">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head">' + lang.widget.COLUMN_VISIBILITY + '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_state" class="cw_show_column k-checkbox"><label for="cw_reasons_state" class="k-checkbox-label">' + lang.STATE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_assetname" class="cw_show_column k-checkbox"><label for="cw_reasons_assetname" class="k-checkbox-label">' + lang.ASSET + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_account" class="cw_show_column k-checkbox"><label for="cw_reasons_account" class="k-checkbox-label">' + lang.ACCOUNT + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_agent" class="cw_show_column k-checkbox"><label for="cw_reasons_agent" class="k-checkbox-label">' + lang.AGENT + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_time" class="cw_show_column k-checkbox"><label for="cw_reasons_time" class="k-checkbox-label">' + lang.TIME + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_reason" class="cw_show_column k-checkbox"><label for="cw_reasons_reason" class="k-checkbox-label">' + lang.REASON + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_action" class="cw_show_column k-checkbox"><label for="cw_reasons_action" class="k-checkbox-label">' + lang.ACTION + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_acknowledged" class="cw_show_column k-checkbox"><label for="cw_reasons_acknowledged" class="k-checkbox-label">' + lang.service.ACKNOWLEDGED + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_acknowledged_by" class="cw_show_column k-checkbox"><label for="cw_reasons_acknowledged_by" class="k-checkbox-label">' + lang.service.ACKNOWLEDGED_BY + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_acknowledged_at" class="cw_show_column k-checkbox"><label for="cw_reasons_acknowledged_at" class="k-checkbox-label">' + lang.incidents.TIME_OF_FLAG + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_assigned_to" class="cw_show_column k-checkbox"><label for="cw_reasons_assigned_to" class="k-checkbox-label">' + lang.ASSIGNED_TO + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_assigned_type" class="cw_show_column k-checkbox"><label for="cw_reasons_assigned_type" class="k-checkbox-label">' + lang.ASSIGNED_TO_TYPE + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_information" class="cw_show_column k-checkbox"><label for="cw_reasons_information" class="k-checkbox-label">' + lang.INFORMATION + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_monitor_name" class="cw_show_column k-checkbox"><label for="cw_reasons_monitor_name" class="k-checkbox-label">' + lang.MONITOR + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_monitor_type" class="cw_show_column k-checkbox"><label for="cw_reasons_monitor_type" class="k-checkbox-label">' + lang.assethealth.MONITOR_TYPE + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_subsystem" class="cw_show_column k-checkbox"><label for="cw_reasons_subsystem" class="k-checkbox-label">' + lang.assethealth.SUBSYSTEM + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_identifier_name" class="cw_show_column k-checkbox"><label for="cw_reasons_identifier_name" class="k-checkbox-label">' + lang.assethealth.IDENTIFIER_NAME + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_reasons_identifier_instance" class="cw_show_column k-checkbox"><label for="cw_reasons_identifier_instance" class="k-checkbox-label">' + lang.assethealth.IDENTIFIER_INSTANCE + '</label></div>';
		html += '</li>';
		html += '</ul>';
		html +='<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + lang.TAGS + '</span>';
		html += '<div class="w100 left">';
		html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_untagged" class="k-checkbox" /><label for="cw_untagged" class="k-checkbox-label">' + lang.SHOW_UNTAGGED + '</label></div></div>';
		html += '<div class="left w100" id="cw_tags"></div>';
		html += '</div>';
		html += '<div class="left w100" id="cw_filter_panel"></div>';

		this.widgetConfigurationFormEl.empty().append(html);

		if (widgetObj.configuration.columns) {
			//on edit reset all and set only if in configuration
			$('.cw_show_column').prop('checked', false);

			if (widgetObj.configuration.columns.severityIndex && !widgetObj.configuration.columns.severityIndex.hidden) {
				$('#cw_reasons_state').prop('checked', true);
			}
			if (widgetObj.configuration.columns.assetName && !widgetObj.configuration.columns.assetName.hidden) {
				$('#cw_reasons_assetname').prop('checked', true);
			}
			if (widgetObj.configuration.columns.accountName && !widgetObj.configuration.columns.accountName.hidden) {
				$('#cw_reasons_account').prop('checked', true);
			}
			if (widgetObj.configuration.columns.agentName && !widgetObj.configuration.columns.agentName.hidden) {
				$('#cw_reasons_agent').prop('checked', true);
			}
			if (widgetObj.configuration.columns.timestamp && !widgetObj.configuration.columns.timestamp.hidden) {
				$('#cw_reasons_time').prop('checked', true);
			}
			if (widgetObj.configuration.columns.text && !widgetObj.configuration.columns.text.hidden) {
				$('#cw_reasons_reason').prop('checked', true);
			}
			if (widgetObj.configuration.columns.action && !widgetObj.configuration.columns.action.hidden) {
				$('#cw_reasons_action').prop('checked', true);
			}
			if (widgetObj.configuration.columns.acknowledged && !widgetObj.configuration.columns.acknowledged.hidden) {
				$('#cw_reasons_acknowledged').prop('checked', true);
			}
			if (widgetObj.configuration.columns.acknowledgedByName && !widgetObj.configuration.columns.acknowledgedByName.hidden) {
				$('#cw_reasons_acknowledged_by').prop('checked', true);
			}
			if (widgetObj.configuration.columns.acknowledgedAt && !widgetObj.configuration.columns.acknowledgedAt.hidden) {
				$('#cw_reasons_acknowledged_at').prop('checked', true);
			}
			if (widgetObj.configuration.columns.assignedToName && !widgetObj.configuration.columns.assignedToName.hidden) {
				$('#cw_reasons_assigned_to').prop('checked', true);
			}
			if (widgetObj.configuration.columns.assignedToType && !widgetObj.configuration.columns.assignedToType.hidden) {
				$('#cw_reasons_assigned_type').prop('checked', true);
			}
			if (widgetObj.configuration.columns.information && !widgetObj.configuration.columns.information.hidden) {
				$('#cw_reasons_information').prop('checked', true);
			}
			if (widgetObj.configuration.columns.monitorName && !widgetObj.configuration.columns.monitorName.hidden) {
				$('#cw_reasons_monitor_name').prop('checked', true);
			}
			if (widgetObj.configuration.columns.monitorTypeText && !widgetObj.configuration.columns.monitorTypeText.hidden) {
				$('#cw_reasons_monitor_type').prop('checked', true);
			}
			if (widgetObj.configuration.columns.subsystem && !widgetObj.configuration.columns.subsystem.hidden) {
				$('#cw_reasons_subsystem').prop('checked', true);
			}
			if (widgetObj.configuration.columns.identifierName && !widgetObj.configuration.columns.identifierName.hidden) {
				$('#cw_reasons_identifier_name').prop('checked', true);
			}
			if (widgetObj.configuration.columns.identifierInstance && !widgetObj.configuration.columns.identifierInstance.hidden) {
				$('#cw_reasons_identifier_instance').prop('checked', true);
			}
		}

		$('#cw_reasons_include_subaccounts_check').prop('checked', widgetObj.configuration.includeSubaccounts);

		this.initKendoComponents();
		const requiredFields = ['#cw_grid_reasons_accountid'];
		applyRequired(requiredFields);
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.tagsList = this.widgetObj.configuration.tags;
		this.createAccountComponent({
			element: $('#cw_grid_reasons_accountid'),
			container: $('#cw_grid_reasons_account'),
			events: {
				change: function () {
					this.tagsList = [];
					this.setTagsDataSource();
				},
				dataBound: function () {
					this.initFilteringPanel();
					this.setTagsDataSource();
				}
			}
		});
	},
	setTagsDataSource: function () {
		let accountId = $('#cw_grid_reasons_accountid').data('kendoComboBox').value();
		let includeSubaccounts = $('#cw_reasons_include_subaccounts_check').is(':checked')
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical={true}>
			<TagsSelect
				mode={'tags'}
				accountId={accountId}
				includeSubaccounts={includeSubaccounts}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>, $('#cw_tags').get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},

	initFilteringPanel() {
		ReactDOM.render(<FilteringPanel
			config={this.widgetObj.configuration}
			filterType={'gridFilterTypes'}
			onChange={(values) => {
				this.filtering = values;
			}}/>, document.getElementById('cw_filter_panel'))
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var reasonsStateCheck = $('#cw_reasons_state'),
			reasonsAssetNameCheck = $('#cw_reasons_assetname'),
			reasonsAccountCheck = $('#cw_reasons_account'),
			reasonsAgent = $('#cw_reasons_agent'),
			reasonsTimeCheck = $('#cw_reasons_time'),
			reasonsReasonCheck = $('#cw_reasons_reason'),
			reasonsAction = $('#cw_reasons_action'),
			reasonsAcknowledgedCheck = $('#cw_reasons_acknowledged'),
			reasonsAcknowledgedBy = $('#cw_reasons_acknowledged_by'),
			reasonsAcknowledgedAt = $('#cw_reasons_acknowledged_at'),
			reasonsAssignedTo = $('#cw_reasons_assigned_to'),
			reasonsAssignedType = $('#cw_reasons_assigned_type'),
			reasonsInformation = $('#cw_reasons_information'),
			reasonsMonitorNameCheck = $('#cw_reasons_monitor_name'),
			reasonsMonitorType = $('#cw_reasons_monitor_type'),
			reasonsSubsystemCheck = $('#cw_reasons_subsystem'),
			reasonsIdentifierName = $('#cw_reasons_identifier_name'),
			reasonsIdentifierInstance = $('#cw_reasons_identifier_instance');
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'grid_reasons',
			title: title ? title : lang.REASONS,
			configuration: {
				accountId: $('#cw_grid_reasons_accountid').data('kendoComboBox').value(),
				includeSubaccounts: $('#cw_reasons_include_subaccounts_check').is(':checked'),
				columns: {
					severityIndex: {
						hidden: reasonsStateCheck.is(':not(:checked)')
					},
					assetName: {
						hidden: reasonsAssetNameCheck.is(':not(:checked)')
					},
					accountName: {
						hidden: reasonsAccountCheck.is(':not(:checked)')
					},
					agentName: {
						hidden: reasonsAgent.is(':not(:checked)')
					},
					timestamp: {
						hidden: reasonsTimeCheck.is(':not(:checked)')
					},
					text: {
						hidden: reasonsReasonCheck.is(':not(:checked)')
					},
					action: {
						hidden: reasonsAction.is(':not(:checked)')
					},
					acknowledged: {
						hidden: reasonsAcknowledgedCheck.is(':not(:checked)')
					},
					acknowledgedByName: {
						hidden: reasonsAcknowledgedBy.is(':not(:checked)')
					},
					acknowledgedAt: {
						hidden: reasonsAcknowledgedAt.is(':not(:checked)')
					},
					assignedToName: {
						hidden: reasonsAssignedTo.is(':not(:checked)')
					},
					assignedToType: {
						hidden: reasonsAssignedType.is(':not(:checked)')
					},
					information: {
						hidden: reasonsInformation.is(':not(:checked)')
					},
					monitorName: {
						hidden: reasonsMonitorNameCheck.is(':not(:checked)')
					},
					monitorTypeText: {
						hidden: reasonsMonitorType.is(':not(:checked)')
					},
					subsystem: {
						hidden: reasonsSubsystemCheck.is(':not(:checked)')
					},
					identifierName: {
						hidden: reasonsIdentifierName.is(':not(:checked)')
					},
					identifierInstance: {
						hidden: reasonsIdentifierInstance.is(':not(:checked)')
					}
				},
				tags: this.tagsList,
				showUntagged: $('#cw_untagged').is(':checked')
			}
		};
		Object.assign(widgetObj.configuration, this.filtering);

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		var accountCombo = $('#cw_grid_reasons_accountid').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
