import {Api} from "tools/api";
import {ApiRequest, ApiRequestObservable} from 'framework/api';

export default class CalendarsAPI {
	static getBaseUrl() {
		return `${Api.serverRoot()}calendars`
	}
	static getGenericCalendarConfiguration(calendarId: string, update: boolean) {
		return Api.fetch(`${CalendarsAPI.getBaseUrl()}/generic/${calendarId}?update=${update}`)
	}
	static getHolidayCalendarConfiguration(calendarId: string, update: boolean) {
		return Api.fetch(`${CalendarsAPI.getBaseUrl()}/holiday/${calendarId}?update=${update}`)
	}
	static generateHolidayCalendar(calendarId: string, year: number) {
		return Api.fetch(`${CalendarsAPI.getBaseUrl()}/holiday/${calendarId}/generate/${year}`)
	}
	static generateNewHolidayCalendar(year: number, country: string) {
		return Api.fetch(`${CalendarsAPI.getBaseUrl()}/holiday/generate/${year}?country=${country}`)
	}
	static saveCalendar(calendarType: string, calendarObj: object) {
		return Api.fetchPost(`${CalendarsAPI.getBaseUrl()}/${calendarType}`, calendarObj)
	}
	static getSearchUrl() {
		return Api.accountRoot() + 'calendars?name=';
	}
}

export {CalendarsAPI}

export function getTimeZones() {
	return new ApiRequestObservable<string[]>({
		url: 'sessions/timezones/',
		method: 'GET'
	})
}
