import {setValueOnPath} from "tools/helpers/math";

function guessValueType(value){
	if(value === 'true')
		return true

	if(value === 'false')
		return false

	return value
}

export class UrlBuilder{
	params = {}
	path = '';

	constructor(query){
		let parts = query.split('#');
		parts = parts[0].split('?')
		this.path = parts[0];

		if(this.path.length > 1 && this.path[this.path.length - 1] == '/'){
			this.path = this.path.substring(0, this.path.length - 1);
		}

		if(parts.length > 1){
			parts[1].split('&').forEach( p => {
				const parameterParts = p.split('=');
				setValueOnPath(this.params, parameterParts[0], guessValueType(parameterParts[1]));
			});
		}
	}

	add(key, value, condition = true) {
		if( typeof key == 'object'){
			return this.addObject(key);
		}

		if (condition === false || !key)
			return this;

		this.params[key] = value;

		return this;
	}

	addObject(object, prefix = '') {
		if(!object)
			return this;

		for(let [key, value] of Object.entries(object)){
			if(typeof value == 'object'){
				this.addObject(value, prefix + key + '.');
			}else{
				this.add(prefix + key, value);
			}
		}
		return this;
	}

	getQuery(){
		const inlineParams = this.inlineParams()
		return Object.entries(this.params).map(([key, value])=> {
			if (!(value === null || value === undefined || inlineParams.includes(key))) {
				return key + '=' + value;
			}
		}).filter(x => !!x).join('&');
	}

	addPath(pathEntry, condition = true) {
		if (condition) {
			this.path = this.path + '/' + pathEntry;
		}
		return this;
	}

	build() {
		let url = this.filledUrl();
		let query = this.getQuery();
		if(query != ''){
			url = url + '?' + query;
		}

		return url;
	}

	filledUrl() {
		let url = this.path
		const inlineParams = this.inlineParams()
		inlineParams.forEach(key => {
			url = url.replace(`:${key}`, this.params[key])
		})
		return url
	}

	inlineParams() {
		return this.path
			.split('/')
			.filter(x => x && x[0] === ':')
			.map(x => x.replace(':', ''))
	}
}

export function getUrlBuilderForCurrentHash(){
	return new UrlBuilder(window.location.hash.split('#').pop());
}

export function getUrlBuilderForCurrentUrl(){
	return new UrlBuilder(window.location.href);
}


export default UrlBuilder;
