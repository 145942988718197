import Utils from 'tools/utils';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import Highcharts from 'highcharts';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import Configuration from 'configuration';
import {AssetsRouter} from "../bundleDescription";
import {CeeviewNavigator} from "tools/ceeviewNavigator";
import {CssVariables} from "styles/cssVariables";

export function GaugeWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.initComponent();
};

export {GaugeWidget as default}

jQuery.extend(GaugeWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		if(this.navigator == null){
			this.navigator = new CeeviewNavigator()
		}
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		this.gaugeType = this.configuration.gaugeType || 'gauge';

		this.useDynamicMax = false;
		this.errorCode = 'OK';
		//widgetDiv.off();
		this.value = 0;
		this.breachValue = this.configuration.breachValue || 80;
		this.inverted = this.configuration.inverted;
		this.minValue = this.configuration.minValue || 0;
		if (this.minValue === 'null') {
			this.minValue = 0;
		}

		this.hasInfoSign = true;

		this.customControls = {
			target: '#' + this.id,
			toggleClick: function (value) {
				if ($('#' + this.id).find('.k-i-toggle').length) {
					$('#' + this.id).find('.k-i-toggle').trigger('click');
				} else {
					$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
				}
			}
		};
		this.createCustomControls();

		if (this.configuration.maxValue === undefined || this.configuration.maxValue === null) {
			this.useDynamicMax = true;
			this.maxValue = this.breachValue + 20 * this.breachValue / 100;
			this.configuration.maxValue = this.maxValue;
		} else {
			this.maxValue = this.configuration.maxValue;
		}
		//widgetDiv.on('drop', $.proxy(this.onDrop, this));
		this.subscribe();

		//Provided backward compatibility
		this.getInitialData();

		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));

		let title = lang.GAUGE + '/' + this.instanceConfiguration.monitorName;
		this.setTitleIfEmpty(title);
	},
	/**
	 * Gets the inistial data to populate the chart
	 */
	getInitialData: function () {
		//var interval = 24 * 60 * 60 * 1000;
		this.queryUrl = this.requestPath + 'accounts/' + this.configuration.accountId + '/monitors/healthIndexes/last1';
		var data;
		if (this.configuration.monitorId && this.configuration.assetId) {
			data = [{
				monitorId: this.configuration.monitorId,
				assetId: this.configuration.assetId
			}];
		} else {
			data = [];
		}
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		kendo.ui.progress(widgetContentDiv, true);

		Utils.ajax(this.queryUrl, 'POST', JSON.stringify(data), $.proxy(function (result) {
			this.gaugeNotification = new CustomNotification({
				animationTime: 0,
				appendToElement: $('#' + this.id).find('.section__content'),
			});

			if (result && result.length) {
				var data = result[0];
				if (data.metrics.length) {
					this.value = data.metrics[0].value;

					this.data = [];
					var fullName, name;

					for (var i = 0, length = result.length; i < length; i++) {
						this.seriesItem = {};
						fullName = this.generateMultigraphLabel(result[i]);
						name = fullName.length > 60 ? fullName.substr(0, 57) + '...' : fullName;

						this.seriesItem.name = name;
						this.seriesItem.fullName = fullName;
						this.seriesItem.visible = true;
					}

					this.renderChart(this.gaugeType, {
						metric: {
							v: this.value,
							e: [0],
							t: data.metrics[0].timestamp
						}
					});
				} else {
					this.gaugeNotification.setOptions({
						message: lang.NO_DATA,
						status: 'error'
					}).show();
				}
			} else {
				this.gaugeNotification.setOptions({
					message: result.message || lang.NO_DATA,
					status: 'error'
				}).show();
			}
			kendo.ui.progress(widgetContentDiv, false);
		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(widgetContentDiv, false);
			this.gaugeNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	/**
	 * Calls the appropiate method to render the chart based on the type
	 * @param {String} type
	 * @param {Object} data
	 * @param {Boolean} animation
	 */
	renderChart: function (type, data, animation) {
		if(!data)
			return;
		this.chartData = [];
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		kendo.ui.progress(widgetContentDiv, false);
		if (type === 'gauge') {
			this.renderGauge(data, animation);
		} else {
			this.renderSolidGauge(data, animation);
		}
	},
	/**
	 * Renders the solid gauge chart
	 * @param {Object} data The chart's data
	 * @param {Boolean} animation Default false
	 */
	renderSolidGauge: function (data, animation) {
		this.seriesItem.data = [];
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content'), renderedValue, title = ' ';

		widgetContentDiv.empty().append('<div class="cw_gauge"></div><div class="cw_widget_status hide"></div>');
		var widgetError = widgetContentDiv.find('.cw_widget_status');

		this.dataSet = data;
		this.eventTime = data.metric.t;
		if (this.maxValue !== undefined && this.maxValue !== null && this.value > this.maxValue) {
			renderedValue = this.maxValue;
			title = 'Over max: ' + this.value;
		} else if (this.minValue !== undefined && this.minValue !== null && this.value < this.minValue) {
			renderedValue = this.minValue;
			title = 'Below min: ' + this.value;
		} else {
			renderedValue = this.value;
		}

		this.seriesItem.data.push(this.value);
		this.data.push(this.seriesItem);

		var oThis = this;
		var animate = true;
		if (typeof animation !== 'undefined' && !animation) {
			animate = false;
		}

		var min = this.minValue || 0;
		var max = this.maxValue;

		var stop1 = this.breachValue / this.maxValue;
		var stop2 = stop1 + 0.00001;

		for (var i = 0, length = this.data.length; i < length; i++) {
			if (this.data[i].name) {
				this.data[i].showInLegend = true;
			}
			this.data[i].innerRadius = '70%';
			this.data[i].dataLabels = {
				formatter: function () {
					return '<div style="text-align:center"><span style="font-size:20px;color:#333">' + this.point.y + '</span>';
				}
			}
		}

		try {
			let container = widgetContentDiv.find('.cw_gauge');
			let height;
			let dashboardWindow = container.closest('.section__content');
			if (dashboardWindow.length) {
				height = dashboardWindow.height();
				//container.css('height', dashboardWindow.height());
			} else {
				height = container.closest('.cw_section_content').height();
			}

			this.gauge = new Highcharts.Chart({
				chart: {
					type: 'solidgauge',
					height: height,
					renderTo: widgetContentDiv.find('.cw_gauge')[0],
					plotBackgroundColor: null,
					plotBackgroundImage: null,
					plotBorderWidth: 0,
					plotShadow: false,
					backgroundColor: 'transparent',
					events: {
						load: function () {
						},
						click: $.proxy(this.onChartClick, this)
					}
				},
				exporting: Configuration.highcharts.exporting,
				credits: {
					enabled: false
				},
				pane: {
					center: ['50%', '70%'],
					size: '100%',
					startAngle: -90,
					endAngle: 90,
					background: {
						backgroundColor: '#eee',
						innerRadius: '70%',
						outerRadius: '100%',
						shape: 'arc'
					}
				},
				legend: {
					title: {
						text: '', //lang.assethealth.MONITOR_NAME,
						align: 'center'
					},
					x: 2,
					y: 20,
					floating: false,
					borderWidth: 0,
					layout: 'horizontal',
					align: 'center',
					verticalAlign: 'bottom',
					useHTML: true,
					itemDistance: 15,
					itemStyle: {
						fontSize: "10px"
					},
					style: {
						fontSize: "10px"
					},
					labelFormatter: function () {
						return '<span title="' + this.userOptions.fullName + '">' + this.name + '</span>';
					}
				},
				plotOptions: {
					series: {
						marker: {
							enabled: false
						},
						events: {
							legendItemClick: () => {
								this.navigator.go({
									url: AssetsRouter.details(this.instanceConfiguration.assetId)
								});
							}
						}
					},
					solidgauge: {
						dataLabels: {
							y: -20,
							borderWidth: 0,
							useHTML: true
						}
					},
					borderColor: '#cacaca'
				},
				series: this.data,
				title: {
					text: '',
					y: 50
				},
				yAxis: {
					min: min,
					max: max,
					tickPositions: [min, max],
					stops: [
						[stop1, this.inverted ? CssVariables.severityCritical : CssVariables.severityNone],
						[stop2, this.inverted ? CssVariables.severityNone : CssVariables.severityCritical]
					],
					labels: {
						y: 20
					},
					minorTickInterval: 0,
					tickInterval: max,
					tickWidth: 0,
					gridLineWidth: 0,
					gridLineColor: 'transparent',
					plotBands: [{
						from: min,
						to: this.breachValue,
						color: this.inverted ? CssVariables.severityCritical : CssVariables.severityNone,
						outerRadius: '107%',
						thickness: '3%'
					},
						{
							from: this.breachValue,
							to: max,
							color: this.inverted ? CssVariables.severityNone : CssVariables.severityCritical,
							outerRadius: '107%',
							thickness: '3%'
						}]
				},
				tooltip: {
					backgroundColor: 'white',
					borderWidth: 0,
					shadow:{
						offsetX: 0,
						offsetY: 0,
						opacity: 1,
						width: 16,
						color: 'rgb(0,0,0,0.01)'
					},
					useHTML: true,
					formatter: function () {
						var s = Renderer.browserDateRenderer(oThis.eventTime, Constants.DATETIME);
						s += '<br />';
						if (oThis.errorCode === 'OK') {
							s += lang.VALUE + ': <strong>' + oThis.value + '</strong>';
						} else {
							s += oThis.errorCode;
						}
						s += '<br />';
						s += lang.widget.BREACH_VALUE + ': <strong>' + oThis.breachValue + '</strong>';
						s += '<br />';
						s += lang.widget.MAX_VALUE + ': <strong>' + max + '</strong>';
						return s;
					}
				},
				loading: false
			});
		} catch (e) {
		}

		if (title !== ' ') {
			this.gaugeNotification.setOptions({
				message: title,
				status: 'info'
			}).show();
		}
		if (this.dataSet.metric.e[0] !== 0) {
			this.errorCode = this.getErrorCode(this.dataSet.metric.e[0]);
			this.gaugeNotification.setOptions({
				message: this.errorCode,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Renders the standard gauge
	 * @param {Object} data The chart's data
	 * @param {Boolean} animation Default false
	 */
	renderGauge: function (data, animation) {
		this.seriesItem.data = [];
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content'), renderedValue, title = ' ';
		widgetContentDiv.empty().append('<div class="cw_gauge"></div><div class="cw_widget_status hide"></div>');
		var widgetError = widgetContentDiv.find('.cw_widget_status');

		this.dataSet = data;
		this.eventTime = data.metric.t;
		if (this.maxValue !== undefined && this.maxValue !== null && this.value > this.maxValue) {
			renderedValue = this.maxValue;
			title = 'Over max: ' + this.value;
		} else if (this.minValue !== undefined && this.minValue !== null && this.value < this.minValue) {
			renderedValue = this.minValue;
			title = 'Below min: ' + this.value;
		} else {
			renderedValue = this.value;
		}
		this.seriesItem.data.push(renderedValue);
		this.data.push(this.seriesItem);

		var oThis = this;
		var animate = true;
		if (typeof animation !== 'undefined' && !animation) {
			animate = false;
		}
		for (var i = 0, length = this.data.length; i < length; i++) {
			if (this.data[i].name) {
				this.data[i].showInLegend = true;
			}
		}

		try {
			let container = widgetContentDiv.find('.cw_gauge');
			let height;
			let dashboardWindow = container.closest('.section__content');
			if (dashboardWindow.length) {
				height = dashboardWindow.height();
				//container.css('height', dashboardWindow.height());
			} else {
				height = container.closest('.cw_section_content').height();
			}

			this.gauge = new Highcharts.Chart({
				chart: {
					type: 'gauge',
					animation: animate,
					height: height,
					renderTo: widgetContentDiv.find('.cw_gauge')[0],
					plotBackgroundColor: null,
					plotBackgroundImage: null,
					plotBorderWidth: 0,
					plotShadow: false,
					backgroundColor: 'transparent',
					events: {
						load: function () {
						},
						click: $.proxy(this.onChartClick, this)
					}
				},
				exporting: Configuration.highcharts.exporting,
				credits: {
					enabled: false
				},
				title: {
					text: ' ',
					style: {
						color: '#FF0000'
					}
				},
				legend: {
					title: {
						text: '', //lang.assethealth.MONITOR_NAME,
						align: 'center'
					},
					x: 2,
					y: 20,
					floating: false,
					borderWidth: 0,
					layout: 'horizontal',
					align: 'center',
					verticalAlign: 'bottom',
					useHTML: true,
					itemDistance: 15,
					itemStyle: {
						fontSize: "10px"
					},
					style: {
						fontSize: "10px"
					},
					labelFormatter: function () {
						return '<span title="' + this.userOptions.fullName + '">' + this.name + '</span>';
					}
				},
				plotOptions: {
					series: {
						marker: {
							enabled: false
						},
						events: {
							legendItemClick:  () => {
								this.navigator.go({
									url: AssetsRouter.details(this.instanceConfiguration.assetId)
								});
							}
						}
					},
					gauge: {
						animation: animate,
						dataLabels: {
							enabled: true,
							//format: oThis.value + ' ' + unit
							formatter: function () {
								return oThis.value;
							}
						}
					}
				},
				pane: {
					startAngle: -150,
					endAngle: 150,
					background: [{
						backgroundColor: {
							linearGradient: {
								x1: 0,
								y1: 0,
								x2: 0,
								y2: 1
							},
							stops: [[0, '#FFF'], [1, '#333']]
						},
						borderWidth: 0,
						outerRadius: '109%'
					}, {
						backgroundColor: {
							linearGradient: {
								x1: 0,
								y1: 0,
								x2: 0,
								y2: 1
							},
							stops: [[0, '#333'], [1, '#FFF']]
						},
						borderWidth: 1,
						outerRadius: '107%'
					}, {// default background
					}, {
						backgroundColor: '#DDD',
						borderWidth: 0,
						outerRadius: '105%',
						innerRadius: '103%'
					}]
				},
				// the value axis
				yAxis: {
					min: this.minValue,
					max: this.maxValue,
					minorTickInterval: 'auto',
					minorTickWidth: 1,
					minorTickLength: 10,
					minorTickPosition: 'inside',
					minorTickColor: '#666',
					tickPixelInterval: 30,
					tickWidth: 2,
					tickPosition: 'inside',
					tickLength: 10,
					tickColor: '#666',
					labels: {
						step: 2,
						rotation: 'auto'
					},
					title: {
						text: ' '
					},
					plotBands: [{
						from: this.minValue,
						to: this.breachValue,
						color: this.inverted ? CssVariables.severityCritical : CssVariables.severityNone
					}, {
						from: this.breachValue,
						to: this.maxValue,
						color: this.inverted ? CssVariables.severityNone : CssVariables.severityCritical
					}]
				},
				series: this.data,
				tooltip: {
					backgroundColor: 'white',
					borderWidth: 0,
					shadow:{
						offsetX: 0,
						offsetY: 0,
						opacity: 1,
						width: 16,
						color: 'rgb(0,0,0,0.01)'
					},
					useHTML: true,
					formatter: function () {
						var s = Renderer.browserDateRenderer(oThis.eventTime, Constants.DATETIME);
						s += '<br />';
						if (oThis.errorCode === 'OK') {
							s += lang.VALUE + ': <strong>' + oThis.value + '</strong>';
						} else {
							s += oThis.errorCode;
						}
						s += '<br />';
						s += lang.widget.BREACH_VALUE + ': <strong>' + oThis.breachValue + '</strong>';
						s += '<br />';
						s += lang.widget.MAX_VALUE + ': <strong>' + oThis.maxValue + '</strong>';
						return s;
					}
				}
			});

		} catch (e) {
		}

		if (title !== ' ') {
			this.gaugeNotification.setOptions({
				message: title,
				status: 'info'
			}).show();
		}
		if (this.dataSet.metric.e[0] !== 0) {
			this.errorCode = this.getErrorCode(this.dataSet.metric.e[0]);
			this.gaugeNotification.setOptions({
				message: this.errorCode,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Search the data for a timestamp point and returns its error code
	 * @param {Number} code
	 * @return {String} errorCode
	 */
	getErrorCode: function (code) {
		var errorCode = '';
		switch (code) {
			case 1:
				errorCode = 'ERROR';
				break;
			case 10:
				errorCode = 'CONNECTION';
				break;
			case 20:
				errorCode = 'AUTHENTICATION';
				break;
			case 30:
				errorCode = 'CONFIGURATION';
				break;
			case 60:
				errorCode = 'METRICSTORE';
				break;
			default:
				errorCode = 'UNKNOWN ERROR CODE';
				break;
		}
		return errorCode;
	},
	/**
	 * Called when a metric event is received
	 */
	onEvent: function () {
		if (this.gauge) {
			this.getInitialData();
		}
	},
	/**
	 * Handler function for the chart point click
	 * @param {object} e The click event
	 */
	onChartClick: function (e) {

	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		this.renderChart(this.gaugeType, this.dataSet);
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	/**
	 * Handler function for the drop event
	 */
	onDrop: function () {
		this.renderChart(this.gaugeType, this.dataSet);
	},
	/**
	 * Subscribes to  metrics events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			assetId: this.configuration.assetId
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		$(window).off('resize', $.proxy(this.onResize, this));
		Widget.prototype.destroy.call(this);
	}
});
