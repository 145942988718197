import {Utils} from 'tools/utils';
import {State} from 'tools/state';

export let ModalView = function (config) {
	this.autoLoad = false;
	Utils.apply(this, config);
	this.initComponent();
};

export default ModalView;

ModalView.prototype = {
	/**
	 * @cfg {Boolean} autoLoad Default to true. Automatically requests data on init.
	 */
	/**
	 * @cfg {String} countId The id of the div that contains the number of the records.
	 */
	/**
	 * @cfg {String} createIds String with the ids that allows to create a new record i.e. '#id1, #id2'
	 */
	/**
	 * @cfg {String} createButtonText The text of the create button
	 */
	/**
	 * @cfg {String} cssClass The view's CSS class name. Used to identify in a unique way the edit and remove buttons
	 */
	/**
	 * @cfg {String} id The view's id. The kendo ListView is binded to it.
	 */
	/**
	 * @cfg {String} loadUrl The kendo ListView Url
	 */
	/**
	 * @cfg {String} template The view's template
	 */
	/**
	 * @cfg {String} title The view's title that is displayed on the title bar
	 */
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	emptyTemplate: '',
	/**
	 * Main init function. Creates the data source and the kendo listview.
	 */
	initComponent: function () {
		this.viewDataSource.bind('change', $.proxy(this.onDatasourceChange, this));
		this.viewDataSource.pageSize(this.pager ? 24 : undefined);

		this.viewSelector = '#' + this.id;

		this.listView = $(this.viewSelector).kendoListView({
			template: this.template,
			autoBind: this.autoLoad,
			dataSource: this.viewDataSource,
			dataBound: $.proxy(this._dataBound, this)
		}).data('kendoListView');

		if (this.pager) {
			try {
				let pagerElement = $('.list_content #' + this.id).parent().next('.list_paging');
				pagerElement.removeClass('hide');
				let pager = pagerElement.kendoPager({
					dataSource: this.viewDataSource,
					pageSizes: [10, 24, 48]
				}).data('kendoPager');

				if (State.currentApp && State.currentApp.pagers) {
					State.currentApp.pagers.push(pager);
				}
				pager.element.hide();
			}
			catch (e) {
			}
		}
		this.updateTitle();
		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Attach the listeners for edit, delete and create functions
	 */
	attachListeners: function () {
		var cardSelector = '.cw_card';
		$(this.viewSelector).on('click', cardSelector, $.proxy(this.onCardClick, this));
		//attach create new user handler
		if (this.createIds) {
			$(this.viewSelector).on('click', this.createIds, $.proxy(this.onCreateButton, this));
		}
		//attach delete handler
		var deleteSelector = '.remove_item';
		$(this.viewSelector).on('click', deleteSelector, $.proxy(this.onDeleteButton, this));
		var deleteSelector2 = '.bin';
		$(this.viewSelector).on('click', deleteSelector2, $.proxy(this.onDeleteButton, this));
		//attach edit handler
		var editSelector = '.edit_item';
		$(this.viewSelector).on('click', editSelector, $.proxy(this.onEditButton, this));
		var editSelector2 = '.pencil';
		$(this.viewSelector).on('click', editSelector2, $.proxy(this.onEditButton, this));
	},
	/**
	 * Removes all attached listeners.
	 */
	removeListeners: function () {
		$(this.viewSelector).off();
	},
	/*
	 * Handler function for the listview databound event
	 * @param {Object} e The databound event
	 */
	_dataBound: function (e) {
		if (this.dataBound) {
			this.dataBound.call(this, e);
		}
	},
	/**
	 * Handler function for the datasource change event
	 * @param {Object} e The change event
	 */
	onDatasourceChange: function (e) {
		try {
			var count = e.items.length, uid, uetemplate, setEmptyTemplate;
			var pagerControl = $('.list_content #' + this.id).parent().next('.list_paging').data('kendoPager');
			if (pagerControl) {
				var currentPage = pagerControl.page();
				if (this.countId) {
					var selector = '#' + this.countId;
					$(selector).find('span').text(count);
				}
				if (count === 0 && this.emptyTemplate && currentPage === 0) {
					uid = this.id;
					uetemplate = this.emptyTemplate;
					setEmptyTemplate = function () {
						$('#' + uid).empty().append(uetemplate);
					};
					setTimeout(setEmptyTemplate, 200);
				}
				var totalPages = e.sender.totalPages();

				if (totalPages <= 1) {
					pagerControl.element.hide();
				}
				else {
					pagerControl.element.show();
				}
				if (currentPage > totalPages) {
					pagerControl.page(totalPages);
				}
			} else {
				if (count === 0 && this.emptyTemplate) {
					uid = this.id;
					uetemplate = this.emptyTemplate;
					setEmptyTemplate = function () {
						$('#' + uid).empty().append(uetemplate);
					};
					setTimeout(setEmptyTemplate, 200);
				}
			}
		}
		catch (exception) {
		}
	},
	/**
	 * Handler function for the click on cw_card event
	 * @param {EventObject} e
	 */
	onCardClick: function (e) {
	},
	/**
	 * Handler function for the click on create button event
	 * @param {EventObject} e
	 */
	onCreateButton: function (e) {
	},
	/**
	 * Handler function for the click on edit button event
	 * @param {EventObject} e
	 */
	onEditButton: function (e) {
	},
	/**
	 * Handler function for the click on delete button event
	 * @param {EventObject} e
	 */
	onDeleteButton: function (e) {
	},
	updateTitle: function () {
		$('.titlebar_title').find('strong').text(this.title);
	}
};
