import React from "react"
import {computed, makeAutoObservable, runInAction} from "mobx"

import {AntRadioButton} from "controls/react/ant/antRadio"

import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridSelectionType} from "controls/grid/gridConfiguration"
import {getSelectorColumn} from "controls/grid/gridSelection"
import {GridColumn, GridColumnConfig} from "controls/grid/gridColumnConfig"
import {GridStore} from "controls/grid/gridStore"

const i18n = require('core/localization').translator();


export class ColumnsManager<DataItem extends GridDataEntry> {
	private disposed: boolean = false

	constructor(public store: GridStore<DataItem>) {
		makeAutoObservable(this, {
			config: computed({keepAlive: true}),
			all: computed({keepAlive: true}),
			visible: computed({keepAlive: true}),
		})
	}

	get config() {
		if(this.disposed){
			return [] as GridColumnConfig<DataItem>[]
		}

		const configColumns = typeof this.store.config.columns == 'function'
			? this.store.config.columns()
			: this.store.config.columns

		let result = [...configColumns]

		if (this.store.config.selection != GridSelectionType.None) {
			result.splice(0, 0, getSelectorColumn(this.store))
		}

		return result
	}

	get all() {
		if(this.disposed){
			return []
		}

		return this.store.state.columns
			.map(x => new GridColumn(x, this.config.find(sc => sc.field == x.field), this.store))
	}

	get visible() {
		if(this.disposed){
			return [] as GridColumn<DataItem>[]
		}

		return this.all.filter(x => x.state.visible)
	}

	get visibleWidth(){
		return this.visible.reduce((width, c) => {
			return width + c.actualWidth
		}, 0)
	}

	moveColumnAfter = (target: string, columnToMoveKey: string) => {
		let indexToResetFrom = null

		runInAction(() => {
			const columns = this.store.state.columns
			const columnToMoveIndex = columns.findIndex(x => x.field == columnToMoveKey)
			//do not move to the same place
			if (columnToMoveIndex > 0 && columns[columnToMoveIndex - 1].field == target)
				return;

			const targetIndex = columns.findIndex(x => x.field == target)

			const movedColumn = this.moveColumn(columnToMoveIndex,
				columnToMoveIndex < targetIndex ? targetIndex : targetIndex + 1 )

			//if inserting column between fixed ones then make it also fixed
			if (targetIndex + 1 < columns.length - 1) {
				if (columns[targetIndex + 2].fixed == 'left') {
					movedColumn.fixed = 'left'
				}
			}

			if (targetIndex > 0) {
				if (columns[targetIndex].fixed == 'right') {
					movedColumn.fixed = 'right'
				}
			}

			//if we moving fixed column besides another fixed then keep it fixed
			if (movedColumn.fixed == 'left' && columns[targetIndex].fixed == 'left'
				|| movedColumn.fixed == 'right' && targetIndex - 1 < columns.length && columns[targetIndex + 2].fixed == 'right') {

			} else {
				movedColumn.fixed = 'none'
			}
		})
	}

	moveSortingBefore = (target: string, columnToMove: string) => {
		if(target == columnToMove)
			return

		const sortingOrder = this.store.state.sortingOrder
		const indexToMove = sortingOrder.findIndex(x => x == columnToMove)
		sortingOrder.splice(indexToMove, 1)

		const indexToPlaceBefore = sortingOrder.findIndex(x => x == target)
		sortingOrder.splice(indexToPlaceBefore, 0, columnToMove)
	}

	fixOnLeft = (column: GridColumn<DataItem>) => {
		let firstNotFixedIndex = this.getFirstNotFixedLeft()

		if (column.state.fixed != 'left') {
			column.state.fixed = 'left'
		} else {
			column.state.fixed = 'none'
			firstNotFixedIndex--
		}

		if (column.state.fixed != 'none') {
			column.state.visible = true
		}

		if (column.index == firstNotFixedIndex)
			return

		this.moveColumn(column.index, firstNotFixedIndex)
	}

	fixOnRight = (column: GridColumn<DataItem>) => {
		let firstNotFixedIndex = this.getFirstNotFixedRight()

		if (column.state.fixed != 'right') {
			column.state.fixed = 'right'
		} else {
			column.state.fixed = 'none'
			firstNotFixedIndex++
		}

		if (column.state.fixed != 'none') {
			column.state.visible = true
		}

		if (column.index == firstNotFixedIndex)
			return

		this.moveColumn(column.index, firstNotFixedIndex)
	}

	isFixedOnRight = (column: GridColumn<DataItem>) => {
		return column.state.fixed == 'right'
	}

	isFixedOnLeft = (column: GridColumn<DataItem>) => {
		return column.state.fixed == 'left'
	}

	getFirstNotFixedRight = () => {
		const columns = this.store.state.columns

		let firstNotFixedIndex = columns.length - 1
		for (; firstNotFixedIndex > 0; firstNotFixedIndex--) {
			if (columns[firstNotFixedIndex].fixed != 'right')
				break
		}

		return firstNotFixedIndex
	}

	getFirstNotFixedLeft = () => {
		const columns = this.store.state.columns

		let firstNotFixedIndex = 0
		for (; firstNotFixedIndex < columns.length; firstNotFixedIndex++) {
			if (columns[firstNotFixedIndex].fixed != 'left')
				break
		}

		return firstNotFixedIndex
	}

	moveColumn = (currentIndex: number, targetIndex: number)  => {
		const columns = this.store.state.columns

		const [columnState] = columns.splice(currentIndex, 1)
		columns.splice(targetIndex, 0, columnState)

		return columnState
	}

	destroy(){
		this.disposed = true

		this.config
		try {
			this.visible
		}catch(e){

		}
		this.all
	}
}
