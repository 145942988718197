import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import RemoteEventsManager from 'core/remoteEventsManager';
import LocalEventsManager from 'core/localEventsManager';
import ExpandableTextarea from 'controls/expandableTextarea';
import moment from 'moment';
import {Settings} from 'settings';
import {MultiToggle} from 'controls/multiToggle';
import {convertTimezone} from 'tools/dateTimeUtils';
import {ApplicationState} from 'framework/applicationState';
import {combineDateAndTime} from 'tools/dateTimeUtils';
import {topLeftCornerPosition} from 'controls/modalWindow'

const i = require('core/localization/localization').translator({
	'Now': {
		no: 'Nå',
	}
});

export default function MaintenancePeriod(config) {
	Utils.apply(this, config);
	this.initComponent();
};

MaintenancePeriod.prototype = {
	/**
	 * @cfg {String} id The id of the maintenance period (only in update mode)
	 */
	/**
	 * @cfg {String} entityId The id of the entity to be set in maintenance
	 */
	/**
	 * @cfg {String} accountId The id of the service's account
	 */
	/**
	 * @cfg {String} layout 'window' or 'popup'
	 */
	/**
	 * @cfg {String} mode 'create' or 'update'
	 */
	/**
	 * @cfg {Array} services Optional. Array with entities IDs
	 */
	/**
	 * @cfg {String} type The type of the entity ("service/agent")
	 */
	/**
	 * @private
	 */
	_types: ['agent', 'service', 'asset'],
	_paths: ['agents', 'services', 'assets'],
	layout: 'popup',
	_multipleSavePaths: ['multipleAgents', 'multipleServices', 'multipleAssets'],
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		this.isSaved = false;
		this.windowId = Utils.guid();
		this.selector = '#' + this.windowId;
		if (!$(this.selector).length) {
			$('body').append('<div id="' + this.windowId + '"></div>');
		}

		this.modalNotification = new CustomNotification({
			appendToElement: '#cw_service_details_tab_nav-4',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		if (this.layout === 'window') {
			$(this.selector).kendoWindow({
				animation: false,
				width: '465px',
				height: '235px',
				position: topLeftCornerPosition,
				title: lang.MAINTENANCE,
				modal: true,
				visible: false,
				activate: $.proxy(function () {
					$(this.selector).find('input:text:not([readonly])').first().focus();
				}, this),
				close: $.proxy(function () {
					$(this.selector).data("kendoWindow").destroy();
					if (this.mode === 'update') {
						RemoteEventsManager.discard(this.id);
					}
				}, this)
			});
			html = '<div class="cw_maintenance">';
			html += '	<div class="form">';
			html += '		<div class="left w100">';
			html += '			<div class="cw_field">';
			html += '				<label class="cw_inline">' + lang.NAME + '</label>';
			html += '				<input id="cw_maintenance_name" class="cw_inline k-input"/>';
			html += '			</div>';
			html += '			<div class="cw_field">';
			html += '				<label class="cw_inline">' + lang.reports.DATE_FROM + '</label>';
			html += '				<input id="cw_event_from_day"/>';
			html += '				<input id="cw_event_from_hour" class="w25"/>';
			html += '			</div>';
			html += '			<div class="cw_field">';
			html += '				<label class="cw_inline">' + lang.reports.DATE_TO + '</label>';
			html += '				<input id="cw_event_to_day"/>';
			html += '				<input id="cw_event_to_hour" class="w25"/>';
			html += '			</div>';
			html += '		</div>';
			html += '	</div>';
			html += '	<div class="status"><p></p></div>';
			html += '</div>';
			html += '<div class="cw_actions">';
			html += '	<button class="k-button right" id="cancel_maintenance">' + lang.CANCEL + '</button>';
			html += '	<button class="k-button k-primary right" id="save_maintenance">' + lang.CREATE + '</button>';
			html += '</div>';
			this.window = $(this.selector).data('kendoWindow');
			this.window.content(html);

			this.window.open();
		} else {
			$('.cw_maintenance').removeClass('is_hidden');
			if (this.mode === 'update') {
				$('#save_maintenance').html(lang.UPDATE);
				$('#cw_add_exclude_periods').addClass('hide');
			} else if (this.mode === 'create') {
				$('#save_maintenance').html(lang.CREATE);
				$('#cw_add_exclude_periods').removeClass('hide');
			}
		}
		if (this.type === 'agent' || this.type === 'asset' || !State.mainApp.session.hasRole('SLA_LIST')) {
			$('#cw_add_exclude_periods').addClass('hide');
		}
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cancel_maintenance').off();
		$('#save_maintenance').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cancel_maintenance').on('click', $.proxy(this.onClose, this));
		$('#save_maintenance').on('click', $.proxy(this.onSave, this));
	},

	fromDateValue: function() {
		return this.fromDate.value() == null ? new Date() : this.fromDate.value();
	},

	ensureIntervalValid: function() {
		const fromDateValue = this.fromDateValue();
		this.toDate.min(fromDateValue);
		if (moment().isSame(fromDateValue, 'day')) {
			this.fromTime.min(new Date());
		} else {
			this.fromTime.min(new Date(1900, 1, 1));
		}
		if (this.toDate.value() < fromDateValue) {
			this.toDate.value(this.generateToTime(fromDateValue));
		}
		if (this.isTheSameDay(fromDateValue, this.toDate.value())) {
			const fromTime = combineDateAndTime(fromDateValue, this.fromTime.value());
			this.toTime.min(fromTime)
			if (this.toTime.value() < fromTime) {
				this.toTime.value(this.generateToTime(fromTime));
			}
		} else {
			const min = new Date(this.toDate.value());
			min.setHours(0,0,0,0);
			this.toTime.min(min);
		}
	},

	generateToTime(fromTime) {
		return new Date(fromTime.getFullYear(), fromTime.getMonth(), fromTime.getDate(), fromTime.getHours() + 1);
	},

	isTheSameDay(firstDate, secondDate) {
		if (firstDate.getDate() === secondDate.getDate()
			&& firstDate.getMonth() === secondDate.getMonth()
			&& firstDate.getFullYear() === secondDate.getFullYear()) {
			return true;
		}
	},

	initKendoComponents() {
		const disableTo = new Date();
		this.fromDate = $('#cw_event_from_day').kendoDatePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateFormat),
			value: new Date(),
			change: () => this.ensureIntervalValid(),
			disableDates: $.proxy(function (date) {
				return disableTo && moment(date).isBefore(disableTo, 'day');
			}, this)
		}).data('kendoDatePicker');

		this.fromTime = $('#cw_event_from_hour').kendoTimePicker({
			format: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
			value: new Date(),
			change: () => this.ensureIntervalValid()
		}).data('kendoTimePicker');

		this.fromTime.min(new Date());

		$('#cw_event_from_hour').closest('.k-timepicker').addClass('cw_event_form_hour_picker cw_event_form_hour_picker_no-margin');
		$('#cw_event_from_day').closest('.k-datepicker').addClass('cw_event_form_date_picker');
		$('#cw_from_all_day_toggle').addClass('cw_event_form_time_toggle');

		this.toDate = $('#cw_event_to_day').kendoDatePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateFormat),
			value: moment().add(1, 'hour').toDate(),
			change: () => this.ensureIntervalValid(),
			disableDates: $.proxy(function (date) {
				return disableTo && moment(date).isBefore(disableTo, 'day');
			}, this)
		}).data('kendoDatePicker');

		this.toTime = $('#cw_event_to_hour').kendoTimePicker({
			format: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
			value: moment().add(1, 'hour').toDate(),
			change: () => this.ensureIntervalValid()
		}).data('kendoTimePicker');

		$('#cw_event_to_hour').closest('.k-timepicker').addClass('cw_event_to_hour_picker cw_event_to_hour_picker_no-margin');
		$('#cw_event_to_day').closest('.k-datepicker').addClass('cw_event_form_date_picker');
		$('#cw_to_all_day_toggle').addClass('cw_event_form_time_toggle');

		if (this.fromDate.value()) {
			this.toDate.min(this.fromDateValue());
		}
	},

	load: function () {
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/' + this._paths[this._types.indexOf(this.type)] + '/' + this.entityId + '/maintenance/' + this.id + '?update=true';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				const fromDateTime = convertTimezone(result.data.fromTime, ApplicationState.timezone, moment.tz.guess());
				const toDateTime = convertTimezone(result.data.toTime, ApplicationState.timezone, moment.tz.guess());
				this.fromDate.value(fromDateTime);
				this.toDate.value(toDateTime);
				this.fromTime.value(fromDateTime);
				this.toTime.value(toDateTime);
				$('#cw_maintenance_name').val(result.data.reason);
				if (this.mode === 'update') {
					if (toDateTime < new Date()) {
						//historic period
						this.isHistoricPeriod = true;
						this.fromTime.enable(false);
						this.toTime.enable(false);
						this.modalNotification.setOptions({
							message: lang.messages.MAINTENANCE_HISTORIC_NOT_EDITABLE,
							status: 'info'
						}).show();
					} else {
						this.isHistoricPeriod = false;
						this.fromTime.enable(true);
						this.toTime.enable(true);
						this.fromTime.min(new Date(this.fromInMs));
						this.toTime.min(new Date(this.fromInMs));
					}
				}
			} else {
				this.modalNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();
			}
		}, this));
	},
	/**
	 * Handler function for the click event on the Cancel/close button
	 */
	onClose: function () {
		if (this.layout === 'window') {
			var modalWindow = $(this.selector).data("kendoWindow");
			modalWindow.close();
		} else {
			$('.cw_maintenance').addClass('is_hidden');
			LocalEventsManager.trigger('cancelperiod');

			if (this.mode === 'update') {
				RemoteEventsManager.discard(this.id);
			}
		}
	},
	/**
	 * Handler function for the click event on the save button
	 */
	onSave: function () {
		if(!this.fromDateValue() || !this.toDate.value())
			return;

		let fromTimestamp = combineDateAndTime(this.fromDateValue(), this.fromTime.value());
		let toTimestamp = combineDateAndTime(this.toDate.value(), this.toTime.value());

		if(toTimestamp.getHours() == 0 && toTimestamp.getMinutes() == 0 && (this.isTheSameDay(fromTimestamp, toTimestamp) || this.toAllDayToggle.getValue() == 'true')) {
			toTimestamp.setDate(toTimestamp.getDate() + 1);
		}

		if(moment(fromTimestamp).isSameOrAfter(toTimestamp)) {
			toTimestamp = moment(fromTimestamp).add('1', 'minutes').toDate();
		}

		const data = {
			ownerIds: this.entities,
			fromTime: moment(fromTimestamp).format('YYYY-MM-DDTHH:mm:ss'),
			toTime: moment(toTimestamp).format('YYYY-MM-DDTHH:mm:ss'),
			reason: $('#cw_maintenance_name').val().trim()
		};
		let saveUrl;
		if (this.entities) {
			saveUrl = Settings.serverPath + 'maintenance/' + this._multipleSavePaths[this._types.indexOf(this.type)];
			data.ownerIds = this.entities;
		} else {
			saveUrl = Settings.serverPath + 'accounts/' + this.accountId + '/' + this._paths[this._types.indexOf(this.type)] + '/' + this.entityId + '/maintenance';
			data.id = this.id ? this.id : null;
		}

		if (this.externalOnSave) {
			this.externalOnSave(data);
		} else {
			Utils.ajax(saveUrl, 'POST', JSON.stringify(data), $.proxy(function (result) {
				this.onMaintenanceSaved(result, data);
			}, this));
		}
	},

	onMaintenanceSaved(result, data) {
		if (result.success) {
			LocalEventsManager.trigger('maintenance_period_saved', {data: data});
			LocalEventsManager.trigger('summaryMaintenancePeriods');
			this.onClose();
		} else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	}
};
