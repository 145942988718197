import React from 'react'

import {DesignerStore} from "controls/designer/designerStore";
import {Action} from './action';
import {Actions} from "controls/designer/actionsManager/actions";

import { ZoomInOutlined, ZoomOutOutlined, UndoOutlined,	RedoOutlined, DeleteOutlined, VerticalAlignTopOutlined,
	VerticalAlignBottomOutlined, EyeOutlined, DesktopOutlined} from '@ant-design/icons'
import {makeObservable, observable} from "mobx";
import {ScaleSelector} from "controls/designer/components/scaleSelector";
import {MxCell} from "controls/designer/mxGraphInterfaces";

const i = require('core/localization/localization').translator({
  "Zoom in": {
    "no": "Zoom inn"
  },
  "Zoom out": {
    "no": "Zoom ut"
  },
  "Undo": {
    "no": "Angre"
  },
  "Redo": {
    "no": "Gjøre om"
  },
  "Delete": {
    "no": "Slett"
  },
  "To front": {
    "no": "Til front"
  },
  "To back": {
    "no": "Til baksiden"
  },
  "Properties": {
    "no": "Egenskaper"
  },
  "Show collections": {
    "no": "Vis samlinger"
  },
  "Preview": {
    "no": "Forhåndsvisning"
  },
  "Fill color": {
    "no": "Fyll farge"
  }
});


export class ActionsManager {
	store: DesignerStore;
	actions: Action[] = [];

	constructor(store: DesignerStore) {
		this.store = store;

		this.initActions();

		makeObservable(this, {
			actions: observable
		})
	}

	add(action: Action){
		if(!(action instanceof Action)){
			action = new Action(action);
		}

		this.actions.push(action);
		return action;
	}

	get(id: string){
		return this.actions.find( x => x.id == id);
	}

	initActions(){
		this.add(new Action({
			id: Actions.ZoomIn,
			title: i('Zoom in'),
			callback: () =>{
				this.store.graph.zoomIn();
			},
			icon: ZoomInOutlined
		}));

		this.add(new Action({
			id: Actions.ZoomOut,
			title: i('Zoom out'),
			callback: () =>{
				this.store.graph.zoomOut();
			},
			icon: ZoomOutOutlined
		}));

		this.add(new Action({
			id: Actions.Scale,
			title: i('Scale'),
			toolbarEntryRenderer: () => <ScaleSelector key={Actions.Scale} store={this.store}/>
		}));

		this.add(new Action({
			id: Actions.Undo,
			title: i('Undo'),
			callback: () =>{
				this.store.editor.undoManager.undo();
			},
			icon: UndoOutlined
		}));

		this.add(new Action({
			id: Actions.Redo,
			title: i('Redo'),
			callback: () =>{
				this.store.editor.undoManager.redo();
			},
			icon: RedoOutlined
		}));

		this.add(new Action({
			id: Actions.Delete,
			title: i('Delete'),
			callback: () =>{
				this.store.graph.removeCells();
			},
			icon: DeleteOutlined
		}));

		this.add(new Action({
			id: Actions.ToFront,
			title: i('To front'),
			callback: () =>{
				this.store.graph.orderCells(false);
			},
			icon: VerticalAlignTopOutlined
		}));


		this.add(new Action({
			id: Actions.ToBack,
			title: i('To back'),
			callback: () =>{
				this.store.graph.orderCells(true);
			},
			icon: VerticalAlignBottomOutlined
		}));

		this.add(new Action({
			id: Actions.OutlineWindowToggle,
			title: i('Preview'),
			callback: () =>{
				this.store.legacyDesigner.ui.actions.get('outline').funct()
			},
			icon: EyeOutlined
		}));
	}

	addToggleWindowAction(baseActionProps: Partial<Action>, window: kendo.ui.Window){
		const isOpened = () => {
			return !window.wrapper.is(":hidden");
		}

		const action = new Action();
		Object.assign(action, baseActionProps);
		action.toggled = isOpened();

		action.callback = () => {
			if (isOpened()) {
				action.toggled = false;
				window.close();
			} else {
				action.toggled = true;
				window.open();
			}
		};

		window.bind('deactivate', () => {
			action.toggled = false;
		});

		window.bind('activate', () => {
			action.toggled = true;
		});

		this.add(action);

		return action;
	}
}
