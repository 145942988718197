import React, {useMemo, useState, useEffect, useRef} from "react";
import {WidgetConfigurationContext} from './context';
import {fromJS} from "immutable";
import PropTypes from 'prop-types';
import {ConfigurationFrame} from "./configurationFrame";
import {DisplaySettingsFrame} from "./displaySettingsFrame";

import 'areas/service-boards/widgets/genericMetricsWidgetConfiguration/widgetConfiguration.less'
import "./widgetConfiguration.less"
import {MetricSelector} from "./metricSelector";
import {MetricInlineSelectorStore} from "../common/metricInlineSelector";
import {storeRef} from "./useStore";

import {newGuid} from "tools/guid";
import {stripConfiguration} from "./stripConfiguration";
import {SelectionFrame} from "./selectionFrame";

const b = require('b_').with('generic-metric-widget-configuration');

export function GenericMetricsWidgetConfiguration(props) {
	const [store, setStore] = useState(newGuid());

	const contextValue = useMemo(() => [store, setStore], [store, setStore]);
	const useDefaultAccount = useRef(!['multi_graph_assetgroup','metric-multi-graph-asset-group'].includes(props.config.type));

	useEffect(() => {
		if (props.config) {
			storeRef.current = fromJS(
				stripConfiguration(props.config)
			);
			setStore(newGuid());
		}
	}, [props.config]);

	useEffect(() => {
		props.onChange(storeRef.current.toJS());
	}, [storeRef.current]);

	return <div className={b()}>
		<WidgetConfigurationContext.Provider value={contextValue}>
			{!props.metricsOnly && <ConfigurationFrame expanded={!props.isExisting} useDefaultAccount={useDefaultAccount.current} />}
			{!props.metricsOnly && <DisplaySettingsFrame expanded={!props.isExisting}
								  hideThreshold={props.hideThreshold}
			/>}
			{props.config.type === 'metric-single-graph' || props.inlineMetricSelector
				? <MetricInlineSelectorStore showAdvanced={props.showAdvanced} useDefaultAccount={useDefaultAccount.current}/>
				: <MetricSelector useDefaultAccount={useDefaultAccount.current} />}

			<SelectionFrame />
		</WidgetConfigurationContext.Provider>
	</div>
}

GenericMetricsWidgetConfiguration.propTypes = {
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	isExisting: PropTypes.bool.isRequired,
	metricsOnly: PropTypes.bool,
	hideThreshold: PropTypes.bool,
	showAdvanced: PropTypes.bool,
	inlineMetricSelector: PropTypes.bool,
};
