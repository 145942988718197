import './layoutSplitter.less'

import React, {CSSProperties} from "react"
import {observer} from "mobx-react"
import {primitive} from "serializr";
import {makeAutoObservable} from "mobx"
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons'

import {getSettings} from "framework/userSettings"
import {createModelSchemaWrapper} from "framework/serializr-integration";


export type LayoutSplitterProps =  {
	userSettingsCategory?: string
	/**
	 * Custom CSS class name applied to the layout div.
	 * You can use this to customize layout style.
	 * Refers to the original stylesheet to see what you can customize.
	 */
	customClassName?: string | undefined;

	/**
	 * Determine whether the layout should be a horizontal split or a vertical split.
	 *
	 * @default false
	 */
	vertical?: boolean | undefined;

	/**
	 * Determine whether the width of each pane should be calculated in percentage or by pixels.
	 * The default value is false, which means width is calculated in pixels.
	 *
	 * @default false
	 */
	percentage?: boolean | undefined

	expandCollapseButton?: boolean

	/**
	 * Index of the primary pane.
	 * Since SplitterLayout supports at most 2 children, only 0 or 1 is allowed.
	 *
	 * A primary pane is used to show users primary content, while a secondary pane is the other pane.
	 * When window size changes and percentage is set to false, primary pane's size is flexible
	 * and secondary pane's size is kept unchanged. However, when the window size is not enough
	 * for showing both minimal primary pane and minimal secondary pane,
	 * the primary pane's size is served first.
	 *
	 * @default 0
	 */
	primaryIndex?: 0 | 1 | undefined;

	/**
	 * Minimal size of primary pane.
	 * When percentage is set to false, this value is pixel size (25 means 25px).
	 * When percentage is set to true, this value is percentage (25 means 25%).
	 *
	 * @default 0
	 */
	primaryMinSize?: number | undefined;

	/**
	 * Minimal size of secondary pane.
	 */
	secondaryMinSize?: number | undefined;

	/**
	 * Initial size of secondary pane when page loads.
	 * If this prop is not defined, SplitterLayout tries to split the layout with equal sizes.
	 * (Note: equal size may not apply when there are nested layouts.)
	 *
	 * @default undefined
	 */
	secondaryInitialSize?: number | undefined;

	/**
	 * Called when dragging is started.
	 *
	 * No parameter will be passed to event handlers.
	 */
	onDragStart?: (() => void) | undefined;

	/**
	 * Called when dragging finishes.
	 *
	 * No parameter will be passed to event handlers.
	 */
	onDragEnd?: (() => void) | undefined;

	/**
	 * Called when the size of secondary pane is changed.
	 *
	 * Event handlers will be passed with a single parameter of number type representing
	 * new size of secondary pane.
	 * When percentage is set to false, the value is in pixel size.
	 * When percentage is set to true, the value is in percentage.
	 */
	onSecondaryPaneSizeChange?: ((value: number) => void) | undefined;

	/**
	 * Placeholder of the panel(s) inside the splitter
	 */
	children?: any;
}


export class LayoutSplitterState {
	size: number

	constructor() {
		makeAutoObservable(this)
	}
}

const b = require('b_').with('layout-splitter')

type LayoutSplitterComponentState = {
	loaded: boolean
}

export const LayoutSplitter = observer(class NoName extends React.Component<LayoutSplitterProps, LayoutSplitterComponentState> {
	persistedState: LayoutSplitterState
	ref: { container: HTMLDivElement }

	constructor(props: LayoutSplitterProps) {
		super(props)

		this.state = {
			loaded: false
		}
	}

	render() {
		const {userSettingsCategory, secondaryInitialSize, ...restProps} = this.props

		if (!this.state.loaded)
			return null

		return <LayoutSplitterInner secondaryInitialSize={this.persistedState.size}
		                            onSecondaryPaneSizeChange={this.onSizeChanged}
		                            {...restProps}/>
	}

	async componentDidMount() {
		if (this.props.userSettingsCategory == null) {
			this.persistedState = new LayoutSplitterState()
		} else {
			this.persistedState = await getSettings<LayoutSplitterState>(this.props.userSettingsCategory, {
				clazz: LayoutSplitterState,
			})
		}

		if (this.persistedState.size == null) {
			this.persistedState.size = this.props.secondaryInitialSize
		}

		this.setState({loaded: true})
	}

	onSizeChanged = (size: number) => {
		this.persistedState.size = size
	}
})

type LayoutSplitterInnerComponentState = {
	secondaryPaneSize: number
	secondaryPaneSizeBeforeCollapse: number
	resizing: boolean
}

const LayoutSplitterInner = observer(class NoName extends React.Component<LayoutSplitterProps, LayoutSplitterInnerComponentState> {
	container: HTMLDivElement
	splitter: HTMLDivElement
	persistedState: LayoutSplitterState

	constructor(props: LayoutSplitterProps) {
		super(props);

		this.state = {
			secondaryPaneSize: 0,
			secondaryPaneSizeBeforeCollapse: null,
			resizing: false
		};
	}

	async componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		document.addEventListener('mouseup', this.handleMouseUp);
		document.addEventListener('mousemove', this.handleMouseMove);
		document.addEventListener('touchend', this.handleMouseUp);
		document.addEventListener('touchmove', this.handleTouchMove);

		let secondaryPaneSize;
		if (typeof this.props.secondaryInitialSize !== 'undefined') {
			secondaryPaneSize = this.props.secondaryInitialSize;
		} else {
			const containerRect = this.container.getBoundingClientRect();
			let splitterRect;
			if (this.splitter) {
				splitterRect = this.splitter.getBoundingClientRect();
			} else {
				// Simulate a splitter
				splitterRect = { width: DEFAULT_SPLITTER_SIZE, height: DEFAULT_SPLITTER_SIZE };
			}
			secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
				left: containerRect.left + ((containerRect.width - splitterRect.width) / 2),
				top: containerRect.top + ((containerRect.height - splitterRect.height) / 2)
			}, false);
		}
		this.setState({ secondaryPaneSize });
	}

	componentDidUpdate(prevProps: LayoutSplitterProps, prevState: LayoutSplitterInnerComponentState) {
		if (prevState.secondaryPaneSize !== this.state.secondaryPaneSize && this.props.onSecondaryPaneSizeChange) {
			this.props.onSecondaryPaneSizeChange(this.state.secondaryPaneSize);
		}
		if (prevState.resizing !== this.state.resizing) {
			if (this.state.resizing) {
				if (this.props.onDragStart) {
					this.props.onDragStart();
				}
			} else if (this.props.onDragEnd) {
				this.props.onDragEnd();
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		document.removeEventListener('mouseup', this.handleMouseUp);
		document.removeEventListener('mousemove', this.handleMouseMove);
		document.removeEventListener('touchend', this.handleMouseUp);
		document.removeEventListener('touchmove', this.handleTouchMove);
	}

	getSecondaryPaneSize(containerRect: DOMRect, splitterRect: Pick<DOMRect, 'width'|'height'>,
	                     clientPosition: {left: number, top: number}, offsetMouse: boolean) {
		let totalSize;
		let splitterSize;
		let offset;
		if (this.props.vertical) {
			totalSize = containerRect.height;
			splitterSize = splitterRect.height;
			offset = clientPosition.top - containerRect.top;
		} else {
			totalSize = containerRect.width;
			splitterSize = splitterRect.width;
			offset = clientPosition.left - containerRect.left;
		}
		if (offsetMouse) {
			offset -= splitterSize / 2;
		}
		if (offset < 0) {
			offset = 0;
		} else if (offset > totalSize - splitterSize) {
			offset = totalSize - splitterSize;
		}

		let secondaryPaneSize;
		if (this.props.primaryIndex === 1) {
			secondaryPaneSize = offset;
		} else {
			secondaryPaneSize = totalSize - splitterSize - offset;
		}
		let primaryPaneSize = totalSize - splitterSize - secondaryPaneSize;
		if (this.props.percentage) {
			secondaryPaneSize = (secondaryPaneSize * 100) / totalSize;
			primaryPaneSize = (primaryPaneSize * 100) / totalSize;
			splitterSize = (splitterSize * 100) / totalSize;
			totalSize = 100;
		}

		if (primaryPaneSize < this.props.primaryMinSize) {
			secondaryPaneSize = Math.max(secondaryPaneSize - (this.props.primaryMinSize - primaryPaneSize), 0);
		} else if (secondaryPaneSize < this.props.secondaryMinSize) {
			const primaryMinSize = this.props.primaryMinSize ?? 0;
			secondaryPaneSize = Math.min(totalSize - splitterSize - primaryMinSize, this.props.secondaryMinSize);
		}

		return secondaryPaneSize;
	}

	handleResize = () => {
		if (this.splitter && !this.props.percentage) {
			const containerRect = this.container.getBoundingClientRect();
			const splitterRect = this.splitter.getBoundingClientRect();
			const secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
				left: splitterRect.left,
				top: splitterRect.top
			}, false);
			this.setState({ secondaryPaneSize });
		}
	}

	handleMouseMove = (e:{clientX: number, clientY: number}) =>  {
		if (this.state.resizing) {
			const containerRect = this.container.getBoundingClientRect();
			const splitterRect = this.splitter.getBoundingClientRect();
			const secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
				left: e.clientX,
				top: e.clientY
			}, true);
			clearSelection();
			this.setState({ secondaryPaneSize });
		}
	}

	handleTouchMove = (e: TouchEvent) => {
		this.handleMouseMove(e.changedTouches[0]);
	}

	handleSplitterMouseDown = () => {
		clearSelection();
		this.setState({ resizing: true });
	}

	handleMouseUp = () => {
		this.setState(prevState => (prevState.resizing ? { resizing: false } : null));
	}

	render() {
		let containerClasses = 'splitter-layout';
		if (this.props.customClassName) {
			containerClasses += ` ${this.props.customClassName}`;
		}
		if (this.props.vertical) {
			containerClasses += ' splitter-layout-vertical';
		}
		if (this.state.resizing) {
			containerClasses += ' layout-changing';
		}

		const children = React.Children.toArray(this.props.children).slice(0, 2);
		if (children.length === 0) {
			children.push(<div />);
		}
		const wrappedChildren = [];
		const primaryIndex = (this.props.primaryIndex !== 0 && this.props.primaryIndex !== 1) ? 0 : this.props.primaryIndex;
		for (let i = 0; i < children.length; ++i) {
			let primary = true;
			let size = null;
			if (children.length > 1 && i !== primaryIndex) {
				primary = false;
				size = this.state.secondaryPaneSize;
			}
			wrappedChildren.push(
				<Pane vertical={this.props.vertical} percentage={this.props.percentage} primary={primary} size={size}>
					{children[i]}
				</Pane>
			);
		}

		return (
			<div className={containerClasses} ref={(c) => { this.container = c; }}>
				{wrappedChildren[0]}
				{wrappedChildren.length > 1 &&
					(
						<div
							role="separator"
							className="layout-splitter"
							ref={(c) => { this.splitter = c; }}
							onMouseDown={this.handleSplitterMouseDown}
							onTouchStart={this.handleSplitterMouseDown}
						>
							{this.props.expandCollapseButton && this.state.secondaryPaneSize > 1 &&
								<LeftCircleOutlined className={b('collapse')}
								                    onMouseDown={e => e.stopPropagation()}
								                    onClick={this.collapse}/>
							}
							{this.props.expandCollapseButton && this.state.secondaryPaneSize <= 1 &&
								<RightCircleOutlined className={b('expand')}
								                     onMouseDown={e => e.stopPropagation()}
								                     onClick={this.expand}
								/>
							}
						</div>
					)
				}
				{wrappedChildren.length > 1 && wrappedChildren[1]}
			</div>
		);
	}

	collapse = () => {
		this.setState({
			secondaryPaneSize: 0,
			secondaryPaneSizeBeforeCollapse: this.state.secondaryPaneSize
		});
	}

	expand = () => {
		let newSize = this.state.secondaryPaneSizeBeforeCollapse
		if(!newSize || newSize <= 1){
			newSize = this.props.secondaryInitialSize || (this.props.percentage ? 25 : 200)
		}

		this.setState({
			secondaryPaneSize: newSize,
			secondaryPaneSizeBeforeCollapse: null
		});
	}
})

createModelSchemaWrapper(LayoutSplitterState, {
	size: primitive()
});


type PaneProps = {
	size?: number,
	percentage: boolean
	primary: boolean
	vertical: boolean
	children?: React.ReactNode
}

function Pane(props: PaneProps) {
	const size = props.size || 0;
	const unit = props.percentage ? '%' : 'px';
	let classes = 'layout-pane';
	const style: Partial<CSSProperties> = {};
	if (!props.primary) {
		if (props.vertical) {
			style.height = `${size}${unit}`;
		} else {
			style.width = `${size}${unit}`;
		}
	} else {
		classes += ' layout-pane-primary';
	}
	return (
		<div className={classes} style={style}>{props.children}</div>
	);
}

function clearSelection() {
	// @ts-ignore
	if (document.body.createTextRange) {
		// https://github.com/zesik/react-splitter-layout/issues/16
		// https://stackoverflow.com/questions/22914075/#37580789
		// @ts-ignore
		const range = document.body.createTextRange();
		range.collapse();
		range.select();
	} else if (window.getSelection) {
		if (window.getSelection().empty) {
			window.getSelection().empty();
		} else if (window.getSelection().removeAllRanges) {
			window.getSelection().removeAllRanges();
		}
	} else { // @ts-ignore
		if (document.selection) {
			// @ts-ignore
			document.selection.empty();
		}
	}
}

const DEFAULT_SPLITTER_SIZE = 4;
