import './newServiceWindow.less';
import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import LocalEventsManager from 'core/localEventsManager';
import ErrorHandler from 'core/errorHandler';
import ExpandableTextarea from 'controls/expandableTextarea';
import State from 'tools/state';
import {Tags} from 'controls/tags';
import ModalWindow from 'controls/modalWindow';
import TeamForm from 'areas/administration/teamForm';
import {ServiceDesignerRouter} from 'areas/services/designer/bundleDescription';
import {topLeftCornerPosition} from 'controls/modalWindow';
import {translator} from "core";
import {ServicesApi} from "./api";
import {NavigationStore} from "../../framework/navigationStore";
import {ServicesRouter} from "./bundleDescription";

const t = translator ({
  "Name exists": {
    "en": "An identical name exist in this account, please use another name.",
    "no": "Et identisk navn finnes på denne kontoen, bruk et annet navn."
  }
});

export function NewServiceWindow(config) {
	Utils.apply(this, config);
	Utils.ajax(Settings.serverPath + 'accounts/' + Cookies.CeesoftAccountId, 'GET', {}, $.proxy(function (result) {
		this.responsibleTeamId = result.data.responsibleTeamId;
		this.initComponent();
	}, this));
};

jQuery.extend(NewServiceWindow.prototype, {
	/**
	 * Main init function
	 */
	initComponent: async function () {
		var html;
		this.isSaved = false;
		this.id = Utils.guid();
		this.selector = '#' + this.id;
		if (!$(this.selector).length) {
			$('body').append('<div id="' + this.id + '"></div>');
		}

		this.modalNotification = new CustomNotification({
			appendToElement: '.k-window-content',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		$(this.selector).kendoWindow({
			animation: false,
			width: '400px',
			height: '475px',
			minHeight: '495px',
			minWidth: '320px',
			position: topLeftCornerPosition,
			title: lang.service.CREATE_SERVICE,
			modal: true,
			visible: false,
			scrollable: true,
			activate: $.proxy(function () {
				$(this.selector).find('input:text:not([readonly])').first().focus();
			}, this),
			close: $.proxy(function () {
				$(this.selector).data("kendoWindow").destroy();
			}, this)
		});
		html = '<div class="cw_service cw-create-new-service">';
		html += '<div class="form">';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline"> ' + lang.NAME + '</label>';
		html += '<input id="cw_new_service_name" type="text" class="cw_inline k-input" />';
		html += '</div>';
		html += '<div class="cw_field cw_new_service_description"></div>';
		html += '<div class="cw_field_with_btn" id="cw_kendo_team">';
		html += '<label class="cw_inline">' + lang.service.RESPONSIBLE_TEAM + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_new_service_responsibleTeamId" type="text" />';
		html += '<span title="Create new team" class="glyphicons plus-sign pointer cw_team_new"></span>';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field hide" id="cw_team">';
		html += '<label class="cw_inline">' + lang.service.RESPONSIBLE_TEAM + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_new_service_responsibleTeam" type="text" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field cw_new_service_information"></div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline_full">' + lang.TAG + '</label>';
		html += '<select size="1" multiple class="left w100 cw_service_tags"></select>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline" for="cw_timezone">' + lang.TIMEZONE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input type="text" name="timezone" id="cw_timezone">';
		html += '</div>';
		html += '</div>';
		html += '</div>';
		html += '<div class="status"><p></p></div>';
		html += '</div>';
		html += '<div class="cw_actions">';
		html += '<button class="k-button right" id="cancel">' + lang.CANCEL + '</button>';
		html += '<button class="k-button k-primary right" id="save_service">' + lang.CREATE + '</button>';
		html += '</div>';
		this.window = $(this.selector).data('kendoWindow');
		this.window.content(html);
		this.window.open();

		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('.cw_new_service_description')
		});

		this.informationHandler = new ExpandableTextarea({
			label: lang.INFORMATION,
			renderTo: $('.cw_new_service_information')
		});
		$('#save_service').attr('disabled', true);

		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
		this.checkRequired();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cancel').off();
		$('#save_service').off();
		$('.cw_team_new').off();
		$('#cw_new_service_name').off();
		LocalEventsManager.unbind('teamSaved');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onClose, this));
		$('#save_service').on('click', $.proxy(this.onSave, this));
		$('.cw_team_new').on('click', $.proxy(this.onTeamNewClick, this));
		$('#cw_new_service_name').on('input', $.proxy(this.checkRequired, this));
		LocalEventsManager.bind('teamSaved', $.proxy(this.onTeamSavedHandler, this));
	},
	initKendoComponents: function () {
		this.serviceTeamList = $('#cw_new_service_responsibleTeamId').kendoSortedMultiSelect({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/responsibleTeams/lite',
						contentType: 'application/json; charset=utf-8',
						type: 'GET',
						dataType: 'json',
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			dataTextField: 'name',
			dataValueField: 'id',
			value: this.responsibleTeamId,
			template: Renderer.templates.responsibleTeam,
			valueTemplate: Renderer.templates.responsibleTeam,
			change: () => this.checkRequired(),
			dataBound: $.proxy(this.serviceTeamDataBound, this)
		}).data('kendoSortedMultiSelect');

		this.tagsControl = new Tags({
			renderEl: $('.cw_service_tags')
		});

		this.timeZoneDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'sessions/timezones',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			error: ErrorHandler.kendoServerError
		});

		this.serviceTimezoneDropDown = $('#cw_timezone').kendoDropDownList({
			dataSource: this.timeZoneDataSource,
			value: Cookies.CeesoftTimezone
		}).data('kendoDropDownList');
	},
	/**
	 * Handler for data bound event of the responsible team dropdown list
	 * @param {Object} e The data bound event
	 */
	serviceTeamDataBound: function (e) {
		var data = this.serviceTeamList.dataSource.data();
		var teamExists = false, i, length;
		for (let i = 0, length = data.length; i < length; i++) {
			if (data.length) {
				teamExists = true;
				break;
			}
		}
		if (!teamExists) {
			this.serviceTeamList.value(null);
		}
		if (!data.find(team => team.id === this.responsibleTeamId)) {
			this.serviceTeamList.value(data[0].id);
		}
		if (this.lastSavedItem) {
			var values = this.serviceTeamList.value();
			if (values.length) {
				values.push(this.lastSavedItem);
				this.serviceTeamList.value(values);
			} else {
				this.serviceTeamList.value(this.lastSavedItem);
			}
		}
	},
	checkRequired: function() {
		if ($('#cw_new_service_name').val().trim() === '') {
			Utils.setPlaceholder($('#cw_new_service_name'), t('Please input Name...'));
			$('#cw_new_service_name').parent().addClass('required_name');
		} else {
			$('#cw_new_service_name').parent().removeClass('required_name');
		}
		if (!this.serviceTeamList.value().length) {
			$('#cw_new_service_responsibleTeamId').parent().addClass('required_select');
		} else {
			$('#cw_new_service_responsibleTeamId').parent().removeClass('required_select');
		}
		if($('#cw_new_service_name').val().trim() === '' || !this.serviceTeamList.value().length) {
			$('#save_service').attr('disabled', true);
		}
		else {
			$('#save_service').attr('disabled', false);
		}
	},
	/**
	 * Handler function for the click event on the Cancel/close button
	 */
	onClose: function () {
		var modalWindow = $(this.selector).data("kendoWindow");
		modalWindow.close();
	},
	/**
	 * Handler function for the click event on the save button
	 */
	onSave: async function () {
		var status, name = $('#cw_new_service_name').val();
		this.mode = 'create';
		let nameExists = await Utils.checkIfNameExists(ServicesApi.getSearchUrl(), name, this.modalNotification, false);
		if (nameExists) {
			return;
		}
		var teamsSelectedItems = $('#cw_new_service_responsibleTeamId').data('kendoSortedMultiSelect').dataItems();
		var teams = [];
		if (teamsSelectedItems.length) {
			for (var i = 0, length = teamsSelectedItems.length; i < length; i++) {
				teams.push(teamsSelectedItems[i].id);
			}
		}
		if (name.trim() === '') {
			$('#cw_new_service_name').parent().addClass('required_name');
			this.modalNotification.setOptions({
				message: lang.service.messages.ENTER_NAME,
				status: 'error'
			}).show();
		}
		else {
			if ($('#cw_team').hasClass('hide')) {
				var tagsObj = this.tagsControl.getValues(), tags = [];
				for (var i = 0, length = tagsObj.length; i < length; i++) {
					tags.push(tagsObj[i].name);
				}
				var data = {
					name: name.trim(),
					description: this.descriptionHandler.value().trim() || null,
					information: this.informationHandler.value().trim(),
					teamIds: (teams !== null) ? teams : [],
					tags: tags,
					shared: false,
					operatingSchedule: {
						timeZone: this.serviceTimezoneDropDown.value()
					}
				};
				if (teams.length) {
					this.save(data);
				} else {
					this.modalNotification.setOptions({
						message: lang.service.TEAM_VALIDATE,
						status: 'error'
					}).show();
				}
			}
			else {
				var teamName = $('#cw_new_service_responsibleTeam').val();
				if (teamName.trim() === '') {
					this.modalNotification.setOptions({
						message: lang.service.messages.ENTER_TEAM,
						status: 'error'
					}).show();
				}
				else {
					this.saveNewTeam();
				}
			}
		}
	},
	/**
	 * Saves the service to the server
	 * @param {Object} data The data to be saved (name, description and teamId)
	 */
	save: function (data) {
		var saveUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/services';
		Utils.ajax(saveUrl, 'POST', JSON.stringify(data), $.proxy(function (result) {
			if (result.success) {
				this.isSaved = true;
				var modalWindow = $(this.selector).data("kendoWindow");
				modalWindow.close();
				if (this.mode === 'create' && State.mainApp.session.hasRole('SERVICE_MODEL_CREATE')) {
					State.mainApp.navigate(ServiceDesignerRouter.root(result.data.id));
				} else {
					NavigationStore.go(ServicesRouter.details(result.data.id));
				}
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Saves a new team
	 */
	saveNewTeam: function () {
		var teamData = {
			name: $('#cw_new_service_responsibleTeam').val(),
			id: null,
			description: ' ',
			information: ' ',
			members: (Cookies.CeesoftCurrentAccountId === Cookies.CeesoftAccountId) ? [Cookies.CeesoftUserId] : []
		};
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/';
		Utils.ajax(url, 'POST', JSON.stringify(teamData), $.proxy(function (result) {
			if (result.success) {
				var data = {
					name: $('#cw_new_service_name').val(),
					description: this.descriptionHandler.value(),
					information: this.informationHandler.value(),
					teamId: result.data.id,
					shared: false
				};
				this.save(data);
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler for creating a new team
	 */
	onTeamNewClick: function () {
		if (State.mainApp.session.hasRole('TEAM_CREATE')) {
			var width = 400;
			this.newTeamWindow = new ModalWindow({
				title: lang.account.TEAM_CREATE,
				width: width,
				minHeight: 515,
				height: 'auto',
				url: 'include/Administration/TeamForm.jsp',
				refresh: function () {
					new TeamForm({
						id: null,
						mode: 'create'
					});
				}
			});
			this.newTeamWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
	},
	onTeamSavedHandler: function (response) {
		this.lastSavedItem = response.result.data.id;
		$('#cw_new_service_responsibleTeamId').data('kendoSortedMultiSelect').dataSource.read();
	}
});

export default NewServiceWindow;
