import {orderBy} from "lodash";
import {KeysMatching} from "./types";


export interface SortSettings<T> {
	order: ('asc' | 'desc')[],
	sortFieldActions: ((x: T) => any)[]
}

export function flatTree<T>(
	items: T[],
	getChildren: (x: T) => T[] = (x: any) => x.children,
	expandedNode?: (x: T) => boolean,
	sortOption?: SortSettings<T>,
	onlyLeafs?: boolean
): T[] {
	let result : T[] = [];
	const source = sortOption?.order
		? orderBy(items, sortOption.sortFieldActions, sortOption.order) as T[]
		: items;

	source?.forEach(x => {
		const children = getChildren(x)
		const leaf = !(children?.length > 0);
		(!onlyLeafs || leaf) && result.push(x)
		if(!expandedNode || expandedNode(x)) {
			result = result.concat(flatTree(children, getChildren, expandedNode, sortOption, onlyLeafs));
		}
	});
	return result;
}

export const getPath = <T, I extends KeysMatching<T, string | number>, C extends KeysMatching<T, T[]>>(
	tree: T[], id: T[I], {idKey, childrenKey}: { idKey: I, childrenKey: C } = {
		idKey: 'id' as I,
		childrenKey: 'items' as C
	}
): T[I][] => {
	const found = tree.find(x => x[idKey] == id);
	if (found) {
		return []; // path not contains end node
	} else {
		for (let node of tree) {
			const childPath = getPath(node[childrenKey] as T[], id, {idKey, childrenKey});
			if (childPath) {
				return [node[idKey], ...childPath];
			}
		}
	}
	return null;
}

export const filterTree = <T, C extends KeysMatching<T, T[]>>(
	tree: T[],
	predicate: (item: T) => boolean,
	{includeParents, childrenKey} : { includeParents: boolean, childrenKey: C } = {
		includeParents: false,
		childrenKey: 'items' as C
	}
) : T[] => {
	if(!tree) {
		return null
	}
	let newTree = tree
	if(!includeParents) {
		newTree = newTree.filter(x => predicate(x))
	}
	newTree = newTree.map(x => ({
		...x,
		[childrenKey]: filterTree(x[childrenKey] as T[], predicate, {includeParents, childrenKey})
	}))
	if(includeParents) {
		newTree = newTree.filter(x => (x[childrenKey] as T[])?.length > 0 || predicate(x))
	}
	return newTree
}

export const sortTree = <T, C extends KeysMatching<T, T[]>>(
	tree: T[],
	sortFn: (a: T, b: T) => -1 | 0 | 1,
	{childrenKey} : { childrenKey: C } = {
		childrenKey: 'items' as C
	},
) : T[] => {
	if(!tree) {
		return tree
	}
	return tree
		.sort((a, b) => sortFn(a,b))
		.map((item) => ({...item, [childrenKey]: sortTree(item[childrenKey] as T[], sortFn, {childrenKey}) as T[C]}))
}

export const getItemByPath = <T, I extends KeysMatching<T, string | number>, C extends KeysMatching<T, T[]>>(
	tree: T[], path: I[], {idKey, childrenKey}: { idKey: I, childrenKey: C } = {
		idKey: 'id' as I,
		childrenKey: 'items' as C
	}
): T => {
	const [currentKey, ...restPath] = path
	const currentItem = tree.find(x => x[idKey] == currentKey)
	if(restPath.length == 0) {
		return currentItem
	} else {
		return getItemByPath(currentItem[childrenKey] as T[], restPath, {idKey, childrenKey})
	}
}

export const getItemByKey = <T, I extends KeysMatching<T, string | number>, C extends KeysMatching<T, T[]>>(
	tree: T[], id: T[I], {idKey, childrenKey}: { idKey: I, childrenKey: C } = {
		idKey: 'id' as I,
		childrenKey: 'items' as C
	}
): T => {
	const found = tree.find(x => x[idKey] == id);
	if (found) {
		return found
	} else {
		for (let node of tree) {
			const found = getItemByKey((node[childrenKey] ?? []) as T[], id, {idKey, childrenKey});
			if (found) {
				return found
			}
		}
	}
	return null;
}


export interface INode<TNode> {
	key: string,
	children?: TNode[]
}

export const findNode = <TNode extends INode<TNode>>(key: string, collection: TNode[], getChildren: (x: TNode) => TNode[] = x => x.children, getKey: (x: TNode) => string = (x) => x.key): TNode => {
	const result = collection.find(x => getKey(x) == key);
	if (result) {
		return result;
	}
	return findNode(key, collection.reduce((r, x) => [...r, ...(getChildren(x) ?? [])], []));
}
