import {ApiRequest} from "framework/api";
import {DataListTextValue, DataListTextValueEntry} from "framework/entities/dataList";

type GetIsChatEnabledArgs = {
	accountId?: string
}

export function getIsChatEnabled(args?: GetIsChatEnabledArgs){
	return new ApiRequest<boolean>({
		url: 'aiChat/isEnabled',
		accountBased: true,
		accountId: args?.accountId
	})
}

type GetAiPromptsArgs = {
	accountId?: string
}

export type ChatAiPrompt = DataListTextValueEntry<string>


export function getAiPrompts(args?: GetAiPromptsArgs){
	return new ApiRequest<ChatAiPrompt[]>({
		url: 'aiChat/listAiPrompts',
		accountBased: true,
		accountId: args?.accountId
	})
}

type SendChatMessageArgs = {
	accountId?: string
	message: string|ChatAiPrompt
}

export function sendChatMessage(args: SendChatMessageArgs){
	let payload : Record<string, string> = {}

	if( typeof args.message == 'string'){
		payload['prompt'] = args.message
	}else{
		payload['id'] = args.message.value
	}

	return new ApiRequest<string>({
		url: 'aiChat/chatWithAi',
		accountBased: true,
		accountId: args?.accountId,
		method: 'POST',
		payload: payload
	})
}
