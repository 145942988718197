import GenericMetricsWidgetConfigurationLegacy from './../genericMetricsWidgetConfiguration.legacy';
import WidgetReactContainer from './../../widgetReactContainer';
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import WidgetSettings from "areas/service-boards/widgets/widgetSettings";
import StackChartComponent from "areas/service-boards/widgets/stackChartWidget/stackChartComponent";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: GenericMetricsWidgetConfigurationLegacy,
		defaultConfig: {
			type: 'metric-stacked-chart',
			title: i('Stacked Chart'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		constructWidget: config => new WidgetReactContainer(StackChartComponent, WidgetSettings, config, 'stack_chart__wrapper'),
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Metric') + '/' + i('Stacked Chart'),
	}
}
