import "./metricCategoryWindow.less";

import React, {useCallback, useState, useEffect} from "react";
import PropTypes from "prop-types";

import { Button } from "controls/react/form";
import { Window } from "controls/react/kendoWrappers";
import { GridNative } from "controls/react/kendoWrappers/gridNative";
import {Section} from 'controls/react/layout/section';
import {Toolbar, ToolbarItemPosition} from 'controls/react/layout/toolbar';
import { translator } from "core";

import { columns } from "./dataSources/assetGroupsColumns";
import { TreeGrid } from "./treeGrid";
import { useMetricCategoryTree } from "./useMetricCategoryTree";
import { useStore, usePlainStore } from "./useStore";
import {useAssetGroups} from "controls/react/assetGroupMultiSelect";
import {LayoutSplitter} from "controls/react/layoutSplitter";

const b = require('b_').with('metric_category_window');

const i = translator({
  "Assetgroup": {
    "no": "Ressursgruppe",
    "en": "Assetgroup"
  },
  "Metric category": {
    "no": "Metrikk kategori",
    "en": "Metric category"
  }
});

MetricCategoryWindow.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	onAdd: PropTypes.func.isRequired
};

export function MetricCategoryWindow(props) {
	const [accountId] = useStore(["configuration", "accountId"]);

	const [assetGroups] = useAssetGroups();
	const [metricCategories] = useMetricCategoryTree(accountId)

	const [preSelectedAssetGroups] = usePlainStore(["configuration", "assetGroups"]);
	const [preSelectedMetricCategories] = usePlainStore(["configuration", "metricCategories"]);

	const [selectedAssetGroups, setSelectedAssetGroups] = useState();
	const [selectedMetricCategories, setSelectedMetricCategories] = useState();

	useEffect(() => setSelectedAssetGroups(preSelectedAssetGroups), [preSelectedAssetGroups]);
	useEffect(() => setSelectedMetricCategories(preSelectedMetricCategories), [preSelectedMetricCategories]);

	const onRowsSelected = useCallback((ids, items) => {
		setSelectedAssetGroups(ids);
	});

	const onTreeRowSelected = useCallback((ids) => {
		setSelectedMetricCategories(ids);
	})

	const onAdd = useCallback(() => {
		props.onAdd(selectedAssetGroups, selectedMetricCategories);
	}, [selectedAssetGroups, selectedMetricCategories]);

	if (!props.open || !assetGroups || !metricCategories) {
		return null;
	}

	return (
		<Window className={b()} modal={true} width={1000} height={500} onClose={props.onClose}>
			<LayoutSplitter percentage secondaryInitialSize={50}>
				<Section title={i("Assetgroup")} height={"fit"}>
					<GridNative
						columns={columns}
						dataSourceArray={assetGroups}
						selectedItems={preSelectedAssetGroups}
						onRowsSelected={onRowsSelected}
						height={"100%"}
						filterable={{mode: "row"}} />
				</Section>
				<Section title={i("Metric category")} height={"fit"} scrollable={true}>
					<TreeGrid data={metricCategories} selectedItems={preSelectedMetricCategories} onChange={onTreeRowSelected} />
				</Section>
			</LayoutSplitter>
			<Toolbar containerClass={b('toolbar')}>
				<Button title={lang.serviceBoard.ADD_METRIC}
					primary={true}
					onClick={onAdd}
					position={ToolbarItemPosition.AT_THE_END} />
				<Button title={i('Cancel')}
					primary={false}
					onClick={props.onClose}
					position={ToolbarItemPosition.AT_THE_END} />
			</Toolbar>
		</Window>
	);
}
