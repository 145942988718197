import 'controls/chat/chatHistory.less'

import {observer} from "mobx-react";
import React from "react";

import {Section} from "controls/react/layout/section";
import {AiChatEngine} from "controls/chat/aiChatEngine";
import {AntAlert} from "controls/react/ant/antAlert";

const b = require('b_').with('chat-history')


type ChatHistoryProps = {
	chatEngine: AiChatEngine
}

export const ChatHistory = observer( (props: ChatHistoryProps) => {
	return <Section appearance={"none"}
	                contentPadding={true}
	                childrenPadding={true}
	                className={b()}
	                direction={"column"}
	                height={"fit"}
	                contentRef={props.chatEngine.containerRef}
	                scrollable={true}>

		{props.chatEngine.messages.map(x =>	{
			if(x.type == 'error'){
				return <AntAlert type={"error"} message={x.text}/>
			}
			return <div key={x.time} className={b('message', {direction: x.direction})}
			     dangerouslySetInnerHTML={{__html: x.text}}></div>
		})}

		{props.chatEngine.sending != null &&
			<div className={b('typing-indicator')}>{props.chatEngine.typingMessage}</div>}
	</Section>
})
