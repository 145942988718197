import React, {useEffect} from "react";
import {useStore} from "./useStore";
import {CollapsibleFrame} from "../../../../controls/react/layout/collapsibleFrame";
import {FormEntry, TextBox} from 'controls/react/form';
import {translator} from "../../../../core";
import AccountDropDown from "../../../../controls/react/dropdowns/accountDropDown";
import {ApplicationState} from "../../../../framework/applicationState";

const i = translator({
});

export function ConfigurationFrame(props) {
	const [title, setTitle] = useStore(['title']);
	const [serviceBoardAccountId] = useStore(['serviceBoardAccountId']);
	const [accountId, setAccountId] = useStore(['configuration', 'accountId']);

	useEffect(() => {
		if (!accountId) {
			setAccountId(ApplicationState.accountId);
		}
	}, [])

	return <CollapsibleFrame title={lang.CONFIGURATION} expanded={props.expanded} childrenPadding={true}>
		<FormEntry label={i('Title')}>
			<TextBox value={title}
					 onChange={setTitle} />
		</FormEntry>
		{!props.useDefaultAccount && <FormEntry label={i('Account')}>
			<AccountDropDown accountId={serviceBoardAccountId}
							 value={accountId}
							 required={true}
							 onChange={setAccountId} />
		</FormEntry>}
	</CollapsibleFrame>
}
