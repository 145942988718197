import React, {useMemo} from 'react';
import ReactDOM from 'react-dom';

import {Cookies} from "core/cookies";
import {translator} from 'core/localization/localization';
import {useStateLink} from "core/react/links";
import {newGuid} from "tools/guid";

import {Section} from 'controls/react/layout/section';
import {Toolbar} from 'controls/react/layout/toolbar';

import {FormEntry} from "controls/react/form/formEntry"
import {Button} from "controls/react/form/button";
import {AccountDropDown, createAccountsDatasource} from "controls/react/dropdowns/accountDropDown";
import {DropDownList, nameIdDataSource} from "controls/react/kendoWrappers/dropDownList";

import {ServicesApi} from "areas/services/api";
import {useDynamicDataSource} from "areas/dashboards/graph-editor-extensions/datasources/useDynamicDataSource";
import {convertEmbeddedCustomDataToExternal, executeUpdate, iterateAllCells} from "../utils";
import {Checkbox} from "controls/react/form/checkbox";
import {stringToCells} from "controls/designer/features/shapesCollection/userShapes";
import {RemoteEventsManager} from "core/remoteEventsManager";
import {getAdminEvents} from "areas/services/eventsManager";

export const syncTypeLocalization = Object.freeze({

});

const i = translator({
  "Synchronize service model": {
    "no": "Synkroniser tjenestemodell",
    "en": "Synchronize servicemodel"
  },
  "Synchronization": {
    "no": "Synkroniser"
  },
  "Sync enabled tooltip": {
    "en": "Model is automaticaly updated and cannot be modified.",
    "no": "Modellen oppdateres automatisk og kan ikke endres."
  },
  "Sync disabled tooltip": {
    "en": "Model will not be synchronized and can be modified.",
    "no": "Modellen vil ikke bli synkronisert og kan endres."
  },
  "Synchronous": {
    "no": "Synkront"
  }
}, syncTypeLocalization);

export const ServiceModelSyncType = Object.freeze({
	No: "no",
	Manual: "manual",
	Automatic: "automatic"
});

export const ServiceModelImportingActions = Object.freeze({
	Import: "import-service-model",
	ShowImportDialog: "show-import-service-model-dialog",
	Sync: "service-model-sync"
});

export const ServiceModelImportingAttributes = Object.freeze({
	SyncType: "serviceModelSyncType",
	InstanceId: "serviceModelInstanceId"
})

export function enableFeature(designer){
	designer.serviceModelImporting = {
		subscriptions: {},
		presentationMode: {}
	}

	if(designer.config.disableDynamicContent)
		return;

	designer.registerForXmlLoaded(updateAutoSyncModels);

	addImportAction(designer);
	addManualSyncAction(designer);
	addShowImportDialogAction(designer);

	addCleanUpCode(designer);
}

function addImportAction(designer) {
	designer.ui.actions.addAction(ServiceModelImportingActions.Import, async ({serviceId, accountId, syncType, position}) => {
		insertServiceModel(designer, {serviceId, accountId, syncType, position});
	});
}

function addManualSyncAction(designer){
	designer.ui.actions.addAction(ServiceModelImportingActions.Sync, async () => {
		const {ui, graph} = designer;
		const cell = graph.getSelectionCell();
		if(!cell)
			return;

		const instanceId = cell.readValue(ServiceModelImportingAttributes.InstanceId);
		await updateServiceModelByInstanceId(designer, instanceId);

	}, null, null, null, i('Synchronize service model'));
}

function addShowImportDialogAction(designer) {
	let window = null;
	designer.ui.actions.addAction(ServiceModelImportingActions.ShowImportDialog, position => {
		if (!window) {
			window = new ImportServiceModelWindow(designer.ui);
		}

		const {graph} = designer;

		window.targetCellPosition = position || {
			x: Math.max(graph.popupMenuHandler.triggerX, 700),
			y: graph.popupMenuHandler.triggerY
		}
		window.show();
	}, null, null, null, i('Service Model'));
}

async function updateAutoSyncModels(designer) {
	const {graph} = designer;

	let dynamicModelRootNodes = [];
	iterateAllCells(graph, cell => {
		if (cell.readValue(ServiceModelImportingAttributes.SyncType) == ServiceModelSyncType.Automatic
			&& cell.isServiceRoot()) {
			dynamicModelRootNodes.push(cell);
		}
	});

	for (const cell of dynamicModelRootNodes) {
		await addServiceModelToRootCell(designer, cell);
	}
}

function addCleanUpCode(designer) {
	const {graph, ui} = designer;
	designer.registerForCleanUp(() => {
		graph.model.beginUpdate();
		try {
			//when in the next section an ungrouping is happening then all ungroupped cells are moved to the group parent to the end of the collection
			//but we need to put root cell to the same position where group cell is placed so the hierarchy is preserved
			//so we saving current index and then moving new cells there
			const serviceGroupCellPosition = new Map();
			iterateAllCells(graph, cell => {
				if (cell.readValue(ServiceModelImportingAttributes.SyncType) == ServiceModelSyncType.Automatic
					&& cell.isServiceRoot()) {
					serviceGroupCellPosition.set(cell, cell.parent.getPositionIndex());
				}
			});

			iterateAllCells(graph, cell => {
				if (cell.readValue(ServiceModelImportingAttributes.SyncType) == ServiceModelSyncType.Automatic
					&& !cell.isServiceRoot()) {

					removeGroupping(designer, cell);

					graph.getModel().remove(cell);
				}
			});

			for (const [cell, position] of serviceGroupCellPosition) {
				graph.model.add(cell.parent, cell, position);
			}

			for( const unsubscriber of Object.values(designer.serviceModelImporting.subscriptions)){
				unsubscriber.unsubscribe();
			}
		} finally {
			graph.model.endUpdate();
		}
	});
}

function generateNewCustomDataIds(customData, cells) {
	//we generate new customDataIds because the same model could be imported several times
	for (let cell of cells) {
		const customDataId = cell.getAttribute('customDataId')
		if (!customDataId == null)
			continue

		let customDataEntry = customData.find(x => x.id == customDataId)
		if (!customDataEntry)
			continue

		customDataEntry.id = newGuid()
		cell.setAttribute('customDataId', customDataEntry.id)
	}
}

async function insertServiceModel(designer, {serviceId, accountId, syncType, position, instanceId, index}) {
	const {graph} = designer;

	const response = await ServicesApi.getModel(serviceId, accountId, null, designer.config.sessionId);
	if (response.success === false)
		return;

	const [cellsToImport, doc] = stringToCells(designer, response.data.xml);

	if (!cellsToImport.length)
		return

	let customData = response.data.customData ?? []
	if (response.data.customData.length == 0) {
		customData = convertEmbeddedCustomDataToExternal(cellsToImport)
	}

	generateNewCustomDataIds(customData, cellsToImport);

	const serviceModelInstanceId = instanceId || newGuid();

	let {shiftX, shiftY} = calculateShiftOfRootCell(position, cellsToImport);

	let importedCells = null;
	graph.model.beginUpdate();
	try {
		importedCells = graph.importCells(cellsToImport, shiftX, shiftY, graph.getDefaultParent());

		if (syncType != ServiceModelSyncType.No && !designer.config.chromeless) {
			const groupCell = groupServiceModel(designer, importedCells);
			updateImportedCellValue(groupCell, serviceModelInstanceId, syncType);

			//moving group to the same position as original cell
			index != null && graph.model.add(groupCell.parent, groupCell, index);
		} else {
			//moving all cells to the position of original cell
			index != null && importedCells.forEach(x => graph.model.add(x.parent, x, index++));
		}

		for (const cell of importedCells) {
			updateImportedCellValue(cell, serviceModelInstanceId, syncType);
			if (syncType != ServiceModelSyncType.No) {
				makeCellsReadOnly(graph, cell, syncType);
			}
		}

		designer.store.addCustomData(customData)
	} finally {
		graph.model.endUpdate();
	}

	const presentationMode = doc.documentElement.getAttribute('presentationModeSettings.showIcons')
	designer.serviceModelImporting.presentationMode[serviceId] = presentationMode == "1";

	if (!designer.serviceModelImporting.subscriptions[serviceModelInstanceId] && syncType == ServiceModelSyncType.Automatic) {
		designer.serviceModelImporting.subscriptions[serviceModelInstanceId] = RemoteEventsManager.subscribeCallback(
			getAdminEvents(serviceId),
			() => {
				updateServiceModelByInstanceId(designer, serviceModelInstanceId);
			}
		)
	}
}

async function updateServiceModelByInstanceId(designer, instanceId) {
	const {ui, graph} = designer;
	let serviceRootCell = null;

	iterateAllCells(graph, cell => {
		if (cell.readValue(ServiceModelImportingAttributes.InstanceId) == instanceId) {

			if(!designer.config.chromeless) {
				removeGroupping(designer, cell);
			}

			if (!cell.isServiceRoot()) {
				graph.getModel().remove(cell);
			} else {
				serviceRootCell = cell;
			}
		}
	});

	await addServiceModelToRootCell(designer, serviceRootCell);
}

async function addServiceModelToRootCell(designer, serviceRootCell) {
	const {services} = designer.store.dataSourcesManager.get(serviceRootCell)

	await insertServiceModel(
		designer,
		{
			syncType: serviceRootCell.readValue(ServiceModelImportingAttributes.SyncType),
			serviceId: services[0].id,
			accountId: services[0].accountId,
			position: serviceRootCell.getGeometry(),
			instanceId: serviceRootCell.readValue(ServiceModelImportingAttributes.InstanceId),
			index: serviceRootCell.getPositionIndex()
		}
	);

	executeUpdate(designer.graph, () =>  designer.graph.getModel().remove(serviceRootCell) );
}

function calculateShiftOfRootCell(position, cells) {
	let shiftX = 50;
	let shiftY = 50;

	if (position != null && !isNaN(parseInt(position.x)) && !isNaN(parseInt(position.y))) {
		const importingRoot = cells.find(x => x.isServiceRoot());
		shiftX = position.x - importingRoot.getGeometry().x;
		shiftY = position.y - importingRoot.getGeometry().y;
	}
	return {shiftX, shiftY};
}

function updateImportedCellValue(cell, serviceModelInstanceId, syncType) {
	let value = cell.getValueAsXml();
	value.setAttribute(ServiceModelImportingAttributes.InstanceId, serviceModelInstanceId);
	value.setAttribute(ServiceModelImportingAttributes.SyncType, syncType);
	if (syncType != ServiceModelSyncType.No) {
		value.setAttribute("readOnlyDataSource", "true");
	}
	cell.setValue(value);
}

function makeCellsReadOnly(graph, cell) {
	let additionalStyles = ";editable=0;resizable=0;deletable=0;movable=0;rotatable=0;";
	graph.setCellStyle(cell.style + additionalStyles, [cell]);
}

function removeGroupping(designer, cell) {
	const {graph, ui} = designer;

	if (cell.parent.id != "1") {
		graph.setSelectionCells([cell.parent]);
		ui.actions.get('ungroup').funct(ui.editor);
	}
}

function groupServiceModel(designer, importedCells) {
	const {ui, graph} = designer;
	graph.setSelectionCells(importedCells);
	ui.actions.get('group').funct(ui.editor);

	const groupCell = importedCells[0].parent;
	graph.setCellStyle(groupCell.style + ";editable=0;resizable=0;", [groupCell]);

	let value = groupCell.getValueAsXml();
	value.setAttribute("disableDataSourceSettings", "true");
	value.setAttribute("generatedCell", "true");
	value.setAttribute('showDragHandle', '1')
	groupCell.setValue(value);

	return groupCell;
}

export class ImportServiceModelWindow{
	root = null;
	window = null;
	targetCellPosition = null;

	constructor(ui){
		this.ui = ui;
		this.init();
	}

	async init(){
		this.createRootNode();
		this.createWindow();
		this.mountComponent();
	}

	createRootNode() {
		this.root = document.createElement('div');
	}

	createWindow() {
		this.window = $(this.root).kendoWindow({
			width: 350,
			autoFocus: true,
			title: i('Select service'),
			actions: ['close'],
			modal: true
		}).data("kendoWindow");
	}

	mountComponent(){
		ReactDOM.render(
			<ImportServiceModelWindowContent closeWindow={() => this.window.close()}
			                                 importServiceModel={this.importServiceModel}/>,
			this.root);
	}

	importServiceModel = (serviceId, accountId, syncType) => {
		this.ui.actions.get(ServiceModelImportingActions.Import).funct({serviceId, accountId, syncType, position: this.targetCellPosition});
		this.window.close();
	}

	show() {
		this.window.center().open();
	}

	destroy(calledManually) {
		ReactDOM.unmountComponentAtNode(this.root);
		this.window.destroy();
	}
}

const ImportServiceModelWindowContent = props => {
	const accountLink = useStateLink(Cookies.CeesoftCurrentAccountId);
	const serviceLink = useStateLink();
	const syncEnabledLink = useStateLink(false);

	const accountDataSource = useMemo(() => createAccountsDatasource(), [Cookies.CeesoftCurrentAccountId]);
	const accountsFilter = useMemo(
		() =>({
			logic: 'and',
			filters: [{field: "accountId", operator: "eq", value: accountLink.value}]
		}),
		[accountLink.value]
	);

	const serviceDataSource = useDynamicDataSource(ServicesApi.getDynamicSearchUrl(), accountsFilter, {pageSize: 9999});

	return (<Section appearance={"none"}
	                 childrenPadding={true}
	                 contentPadding={true}>
		<FormEntry label={i('Account')} vertical>
			<AccountDropDown {...accountLink.props} />
		</FormEntry>

		<FormEntry label={i('Service')} vertical={true}>
			<DropDownList dataSource={serviceDataSource}
			              onDataBound={(e, data) => data?.length && serviceLink.update(data[0].id)}
			              {...nameIdDataSource()}
			              {...serviceLink.props} />
		</FormEntry>

		<FormEntry>
			<Checkbox {...syncEnabledLink.props}
			          label={i('Synchronous')}
			          tooltip={i(syncEnabledLink.value ? 'Sync enabled tooltip' : 'Sync disabled tooltip')}/>

		</FormEntry>

		<Toolbar appearance={"transparent"}>
			<Button title={i('Add')}
			        onClick={() => props.importServiceModel(serviceLink.value, accountLink.value,
				        syncEnabledLink.value ? ServiceModelSyncType.Automatic : ServiceModelSyncType.No)}
			        primary={true}/>
			<Button title={i('Close')}
			        onClick={props.closeWindow}/>
		</Toolbar>
	</Section>);
}
