import {
	autorun,
	computed,
	IAutorunOptions,
	IReactionDisposer,
	IReactionOptions,
	IReactionPublic,
	IWhenOptions,
	Lambda,
	makeAutoObservable,
	makeObservable,
	observable,
	reaction,
	when
} from "mobx";
import {debounce} from "lodash"

import {KeysMatching} from "tools/types"


const i = require('core/localization').translator();

export enum ValidationState{
	Valid = 'valid',
	Invalid = 'invalid',
	Validating = 'validating'
}

export interface ValidatableModel<TModel extends object>{
	validator: ModelValidator<TModel>
}

export interface BindingProps<TValue>{
	value: TValue,
	onChange: (value: TValue ) => void,
	invalid?: boolean,
	errors?: string[]
	validationState?: ValidationState
}

export type LinkModelOptions = {
	validation?: true
}

export function linkModel<TModel extends object, U extends keyof TModel>(model: TModel, property: U, options?: LinkModelOptions){
	let validatableModel = model as ValidatableModel<TModel>
	let result = {
		value: model[property],
		onChange: (value: TModel[U]) => model[property] = value
	} as BindingProps<TModel[U]>

	//validation should not be placed directly on a field, it is handled by FormEntry so the following fields should not matter
	//if something is broken please contact me before uncommenting this. Vasiliy

	if(options?.validation && validatableModel.validator){
		result.validationState = validatableModel.validator.getValidationState(property)
		result.invalid = validatableModel.validator.getValidationState(property) != ValidationState.Valid
		result.errors = validatableModel.validator.getErrors(property)
	}

	return result
}

export function linkModelSingleToArray<TModel extends object, P, U extends KeysMatching<TModel, P[]>>(model: TModel, property: U) {
	let validatableModel = model as ValidatableModel<TModel>
	let valueArray = model[property] as unknown as P[]

	let result = {
		value: valueArray.length > 0 ? valueArray[0] : null,
		onChange: (value: any) => valueArray[0] = value
	} as BindingProps<any>

	if (validatableModel.validator) {
		result.invalid = validatableModel.validator.getFieldValidator(property).validationState != ValidationState.Valid
		result.errors = validatableModel.validator.getErrors(property)
	}

	return result
}

class ValidationRule{
	callback: () => ValidationState | boolean
	callbackAsync: () => (Promise<ValidationState> | Promise<boolean>)
	reactionExpression?: () => any
	message: string
	validationState: ValidationState = ValidationState.Validating
	requiredRule: boolean = false
	private mobx = new MobxManager()
	runCount = -1

	constructor(init?: Partial<ValidationRule>) {
		Object.assign(this, init)

		makeObservable(this, {
			validationState: observable,
			revalidated: computed,
			runCount: observable
		})

		this.init()
	}

	//the property shows if validation has been re-run since initialization
	//we need it to not show validation errors on initial form load
	get revalidated(){
		return this.runCount > 0
	}

	init(){
		if(this.callbackAsync) {
			let bouncedCallback = debounce(async () => {
				let result = await this.callbackAsync()
				this.runCount ++
				this.setValidationResult(result)
			}, 1000)

			this.mobx.reaction(this.reactionExpression, async () => {
				this.validationState = ValidationState.Validating
				await bouncedCallback()
			}, {
				fireImmediately: true
			})
		}else{
			this.mobx.reaction(this.reactionExpression, async () => {
				let result = this.callback()
				this.runCount ++
				this.setValidationResult(result)
			}, {
				fireImmediately: true
			})
		}
	}

	async validate(){
		let result = this.callbackAsync ? await this.callbackAsync() : this.callback()
		this.setValidationResult(result)
	}

	setValidationResult(result: ValidationState | boolean) {
		if (typeof result == "boolean") {
			this.validationState = result ? ValidationState.Valid : ValidationState.Invalid
		} else {
			this.validationState = result
		}
	}

	destroy(){
		this.mobx.destroy()
	}
}

class FieldValidator {
	requiredRule?: ValidationRule
	rules: ValidationRule[] = []
	mobx = new MobxManager()

	get valid(){
		return this.validationState == ValidationState.Valid
	}

	get revalidated(){
		return this.rules.some(x => x.revalidated)
	}

	get validationState(){
		let result = ValidationState.Valid

		for(let rule of this.rules){
			result = getWorseValidationState(result, rule.validationState)
		}

		return result
	}

	get requiredValidationState(){
		if(!this.requiredRule)
			return ValidationState.Valid

		return this.requiredRule.validationState
	}

	get errors(){
		return this.rules.filter(x => x.validationState == ValidationState.Invalid).map(x => x.message);
	}

	constructor(init?: Partial<FieldValidator>) {
		Object.assign(this, init);

		makeObservable(this, {
			validationState: computed,
			requiredValidationState: computed,
			errors: computed,
			rules: observable,
			valid: computed,
			revalidated: computed
		})
	}

	addRule(options: AddRuleOptions){
		const rule = new ValidationRule(options)
		this.rules.push(rule)
		return rule
	}

	destroy(){
		this.mobx.destroy()
		this.rules.forEach(x => x.destroy())
	}
}

type AddRuleOptions = Partial<Pick<ValidationRule, 'message'|'reactionExpression'|'callback'|'callbackAsync'|'requiredRule'>> & {
}

export class ModelValidator<TModel extends object>{
	model: TModel;

	static emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	validations: {
		[TField in keyof TModel]?: FieldValidator
	}

	get validationState() : ValidationState{
		const selfState = Object.entries<FieldValidator>(this.validations)
			.reduce((result, [, validator]) => getWorseValidationState(validator.validationState, result ), ValidationState.Valid);

		return getWorseValidationState(selfState, this.subValidatorsState);
	}

	get subValidatorsState(){
		let result = ValidationState.Valid

		for(const subValidator of this.iterateSubValidators()){
			result = getWorseValidationState(subValidator.validationState, result)
		}

		return result
	}

	get errors(){
		const result: {field: string, errors: string[]}[] = []

		Object.entries<FieldValidator>(this.validations)
			.forEach(([field, validator]) => {
				if(validator.validationState == ValidationState.Invalid){
					return result.push({field,errors: validator.errors})
				}
			}, [])

		for(const subValidator of this.iterateSubValidators()){
			if(subValidator.errors.length) {
				result.splice(0, 0, ...subValidator.errors)
			}
		}

		return result
	}

	get valid(): boolean{
		return this.validationState == ValidationState.Valid
	}

	constructor(model: TModel) {
		this.validations = {};
		this.model = model;

		makeObservable(this, {
			validations: observable,
			valid: computed,
			validationState: computed,
			subValidatorsState: computed,
			errors: computed
		});
	}

	getFieldValidator<TField extends keyof TModel>(field: TField, createIfNotExists: boolean = false){
		if (this.validations[field] == null && createIfNotExists) {
			this.validations[field] = new FieldValidator();
		}

		return this.validations[field]
	}

	add<TField extends keyof TModel>(field: TField, options: AddRuleOptions = {}): ModelValidator<TModel> {
		options.reactionExpression ??= (() => this.model[field])

		this.getFieldValidator(field, true)
			.addRule(options);

		return this;
	}

	*iterateSubValidators() {
		for (const [, fieldValue] of Object.entries(this.model)) {
			if (fieldValue && typeof fieldValue == 'object' && ('validator' in fieldValue)) {
				yield fieldValue.validator
			}
		}
	}

	required<TField extends keyof TModel>(field: TField, applyIf?: () => boolean, message?: string, allowEmptyArrays?: boolean = true): ModelValidator<TModel> {
		if (message == null) {
			message = i('Required field')
		}

		this.add(field, {
			message,
			callback: () => {
				if (applyIf != null && !applyIf())
					return true;

				const value = this.model[field];
				if (value == null)
					return false;

				if (typeof value === 'string' && value.trim() == '') {
					return false;
				}

				if (!allowEmptyArrays && Array.isArray(value) && !value.length) {
					return false;
				}

				return true;
			},
			reactionExpression: () => [applyIf?.(), this.model[field]]
		})

		let rule = this.validations[field].rules[this.validations[field].rules.length - 1];
		this.getFieldValidator(field).requiredRule = rule

		return this;
	}

	between<TField extends KeysMatching<TModel, number>>(field: TField, from: number, to: number, includeBorders: boolean = true, message?: string) {
		if (!message) {
			message = i('The input value should be between {0} and {1}', from, to);
		}

		this.add(field, {
			message,
			callback: () => {
				const value = this.model[field] as number
				if(includeBorders) {
					return value >= from && value <= to;
				} else {
					return value > from && value < to;
				}
			}
		});
		return this;
	}

	getValidationState<TField extends  keyof TModel>(field: TField){
		if(this.validations[field] == null)
			return ValidationState.Valid

		return this.validations[field].validationState
	}

	getErrors<TField extends  keyof TModel>(field: TField) : string[] {
		if(this.validations[field] == null)
			return [];

		return this.validations[field].errors;
	}

	isRequired<TField extends  keyof TModel>(field: TField) : boolean {
		return this.validations[field]?.requiredRule?.validationState != ValidationState.Valid
	}

	destroy(){
		Object.entries<FieldValidator>(this.validations)
			.forEach(([, validator]) => validator.destroy());
	}
}

export class MobxManager{
	disposers: IReactionDisposer[] = []

	when(predicate: () => boolean, effect: Lambda, opts?: IWhenOptions){
		const disposer = when(predicate, effect, opts)
		this.saveDisposer(disposer)
	}

	reaction<T>( expression: (r: IReactionPublic) => T,
	             effect: (arg: T, prev: T, r: IReactionPublic) => void,
	             opts: IReactionOptions<T> = {}){
		let disposer = reaction(expression, effect, opts)
		return this.saveDisposer(disposer)
	}

	autorun(view: (r: IReactionPublic) => any, opts: IAutorunOptions = {}){
		const disposer = autorun(view, opts)
		return this.saveDisposer(disposer)
	}

	destroy(){
		this.disposers.forEach(x => x())
	}

	saveDisposer(disposer: IReactionDisposer) {
		this.disposers.push(disposer)

		return (() => {
			disposer()
			let index = this.disposers.indexOf(disposer)
			this.disposers.splice(index, 1)
		}) as IReactionDisposer
	}
}


let order = [ValidationState.Invalid, ValidationState.Validating, ValidationState.Valid]

export function getWorseValidationState(currentState: ValidationState, resultingState: ValidationState){
	for(const state of order){
		if(state == currentState || state == resultingState)
			return state
	}

	return ValidationState.Valid
}

export function addUniqueNameValidation<T extends {name: string, validator: ModelValidator<T>}>(model: T, isNameUniqueCallback: (name: string) => Promise<boolean>){
	let initialName = model.name

	model.validator.add("name", {
		callbackAsync: async () => {
			if (!model.name)
				return true

			if (initialName && initialName == model.name)
				return true

			return await isNameUniqueCallback(model.name)
		},
		message: i('Name exists')
	})
}

export class SingleToArrayConverter<T extends object, V> {
	constructor(private target: T, private targetField: KeysMatching<T, V[]>) {
		makeAutoObservable(this)
	}

	get value() {
		const fieldValue = this.target[this.targetField] as V[]
		if (fieldValue?.length > 0) {
			return fieldValue[0]
		}

		return null
	}

	set value(value: V) {
		(this.target[this.targetField] as V[]) = [value]
	}
}
