import './severitiesSelect.less';

import React, {useCallback, useEffect, useState} from 'react';
import {Select} from 'antd';
import FormEntry from "controls/react/form/formEntry";
import {AntCheckbox} from "./ant/antCheckbox";


const {Option} = Select;

const i = require('core/localization/localization').translator({
	"Choose a severity...": {
		"no": "Velg en alvorlighet"
	},
	'Buckets': {
		no: 'Bøtter'
	},
	'Bar': {
		no: 'Bar'
	},
	'Bucket labels': {
		no: 'Bøtte tekst'
	}
});

export class SeveritiesSelect extends React.PureComponent {
	constructor(props) {
		super(props);

		const dataSource = props.datasource || [['CRITICAL', lang.CRITICAL], ['MAJOR', lang.MAJOR], ['MINOR', lang.MINOR], ['OK', lang.OK], ['INACTIVE', lang.INACTIVE]];
		const defaultValue = props.defaultValue || ['CRITICAL', 'MAJOR', 'MINOR', 'OK', 'INACTIVE'];

		this.state = {
			dataSource: dataSource,
			selectedValue: props.defaultValue || defaultValue,
			required: props.selectedValue?.length === 0
		}
	}

	render() {
		const {onChange, value, invalid, ...others} = this.props;

		const severityColors = {
			CRITICAL: 1,
			MAJOR: 2,
			MINOR: 3,
			OK: 5,
			INACTIVE: 0,
			DOWN: 1,
			WARNING: 2,
			UP: 5,
			BREACHED: 1,
			COMPLIANT: 5
		}

		return (
			<FormEntry required={this.props.required && this.state.required}>
				<Select value={value || this.state.selectedValue}
						mode={'multiple'}
						onChange={this.onChange}
						placeholder={i('Choose a severity...')}
						{...others}>
					{this.state.dataSource.map(severity =>
						<Option key={severity[0]}>
							<span
								className={`cw_severity_circle cw_status_widget_color cw_color${severityColors[severity[0]]}`}></span>
							<span className="cw_severity_text">{severity[1]}</span>
						</Option>
					)}
				</Select>
			</FormEntry>
		);
	}

	onChange = newValue => {
		this.setState({
			selectedValue: newValue,
			required: newValue?.length === 0
		});
		this.props.onChange(newValue);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.defaultValue && this.props.defaultValue) {
			this.setState({selectedValue: this.props.defaultValue});
		}
	}
}

const bDisplaySettings = require('b_').with('severity-display-settings');
export const SeverityOptions = (props) => {
	const [showBucket, setShowBucket] = useState(props.showBucket ?? true);
	const [showBar, setShowBar] = useState(props.showBar ?? true);
	const [displayLabels, setDisplayLabels] = useState(props.displayLabels ?? true);

	useEffect(() => {
		props.onChange?.({showBucket: showBucket, showBar: showBar, displayLabels: displayLabels});
	}, [showBar, showBucket, displayLabels]);

	return <FormEntry width={'half'} containerClass={bDisplaySettings('options')}>
		<AntCheckbox value={showBucket} onChange={setShowBucket}>{i('Buckets')}</AntCheckbox>
		<AntCheckbox value={showBar} onChange={setShowBar}>{i('Bar')}</AntCheckbox>
		<AntCheckbox value={displayLabels} onChange={setDisplayLabels}>{i('Bucket labels')}</AntCheckbox>
	</FormEntry>
}
