import {ApiRequest, PagedList} from "framework/api"
import {EventsSummaryEntry} from "./eventsSummaryEntry"
import {getGridApiRequestPayload, GridSelection, SelectionArgs} from "controls/grid/gridSelection"
import {DataListTextValue} from "framework/entities/dataList"
import {EventStateSummary} from "./eventStateSummary"
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {GridStore} from "controls/grid/gridStore";
import {fromIdName} from "controls/react/ant/antSelect";
import {GridDataItem} from "controls/grid/gridDataItem";

const i18n = require('core/localization/localization').translator()

export type EventsSummaryFilter = {
	accountId?: string
}

export enum EventsType{
	All = 'ALL',
	Historic = 'HISTORIC',
	Current = 'CURRENT'
}

export type GetEventsSummary = {
	accountId?: string,
	includeSubaccounts?: boolean,

	showUntagged?: boolean
	tags?: string[]
	ignoreUserTags?: boolean
	assetIds?: string[]
	assetGroupIds?: string[]
	monitorIds?: string[]
	serviceIds?: string[]
	eventTypes?: string[]
}

export function getEventsSummary(args?: GetEventsSummary){
	return new ApiRequest<PagedList<GridDataItem<EventsSummaryEntry>>>({
		url: `summary/eventSummary`,
		subaccountsFilter: true,
		includeSubaccounts: args?.includeSubaccounts,
		accountBased: true,
		accountId: args?.accountId,
		method: 'POST',
		payload: {
			showUntagged: args?.showUntagged,
			tags: args?.tags,
			ignoreUserTags: args?.ignoreUserTags,
			assetIds: args?.assetIds,
			assetGroupIds: args?.assetGroupIds,
			monitorIds: args?.monitorIds,
			serviceIds: args?.serviceIds,
			eventTypes: args?.eventTypes
		}
	});
}

type GetEventSummaryFiltersArgs = {
	accountId?: string
}

export function getEventSummaryFilters(args?: GetEventSummaryFiltersArgs){
	return new ApiRequest<RulesConfiguration>({
		url: 'summary/eventSummary/ruleConfiguration',
		accountBased: true,
		accountId: args?.accountId,
		subaccountsFilter: true,
		method: 'GET'
	});
}

export function unacknowledge(store: GridStore<EventsSummaryEntry>, selection: GridSelection<EventsSummaryEntry>){
	return store.getSelectionApiRequest({
		url: 'summary/eventSummary/unacknowledge',
		selection
	})
}

export function acknowledge(store: GridStore<EventsSummaryEntry>, selection: GridSelection<EventsSummaryEntry>){
	return store.getSelectionApiRequest({
		url: 'summary/eventSummary/acknowledge',
		selection
	})
}

export function resetHealthIndex(store: GridStore<any>, selection: GridSelection<EventsSummaryEntry>, resetReason: string){
	return store.getSelectionApiRequest({
		url: 'summary/eventSummary/resetHealthIndexes',
		payload: {
			resetReason
		},
		selection
	})
}

export function serviceCommand(store: GridStore<any>, selection: GridSelection<EventsSummaryEntry>, command: 'START'|'STOP'){
	return store.getSelectionApiRequest({
		url: 'monitors/WINSERVICE/multipleServiceCommand/' + command,
		selection
	})
}

export function unassign(store: GridStore<EventsSummaryEntry>, selection: GridSelection<EventsSummaryEntry>){
	return store.getSelectionApiRequest({
		url: 'summary/eventSummary/unassign',
		selection
	})
}

export function assign(store: GridStore<any>, selection: GridSelection<EventsSummaryEntry>, targetId: string, type: 'USER' | 'TEAM'){
	const variableName = type == 'USER' ? 'userId' : 'teamId'
	return store.getSelectionApiRequest({
		url: `summary/eventSummary/assign?${variableName}=${targetId}`,
		selection
	})
}

export function assignLegacy(args: SelectionArgs & {targetId: string, type: 'USER' | 'TEAM'}){
	const variableName = args.type == 'USER' ? 'userId' : 'teamId'
	return new ApiRequest({
		url: `summary/eventSummary/assign?${variableName}=${args.targetId}`,
		...getGridApiRequestPayload(args)
	})
}

export function unassignLegacy(args: SelectionArgs){
	return new ApiRequest({
		url: 'summary/eventSummary/unassign',
		...getGridApiRequestPayload(args)
	})
}

export function deleteEvents(store: GridStore<EventsSummaryEntry>, selection: GridSelection<EventsSummaryEntry>){
	return store.getSelectionApiRequest({
		url: 'summary/eventSummary/delete',
		selection
	})
}

export function setInformation(store: GridStore<EventsSummaryEntry>, selection: GridSelection<EventsSummaryEntry>, information: string){
	return store.getSelectionApiRequest({
		url: 'summary/eventSummary/information',
		payload: {
			information
		},
		selection
	})
}

export function setInformationLegacy(args: SelectionArgs & {information: string}){
	return new ApiRequest({
		url: 'summary/eventSummary/information',
		...getGridApiRequestPayload(args, {
			information: args.information
		})
	})
}


export function exportToCsv(store: GridStore<EventsSummaryEntry>, selection: GridSelection<EventsSummaryEntry>){
	let request = store.getSelectionApiRequest({
		url: 'summary/eventSummary/exportCSV',
		selection
	})

	request.payload.fields = store.columns.visible
		.map(x => x.field)
		.filter(x => x != 'id')

	return request
}

export class Recipient{
	name: string
	id: string
	type: 'USER' | 'TEAM'
}

export function getRecipients(accountId?: string){
	return new ApiRequest<Recipient[]>({
		url: 'recipients',
		accountBased: true,
		accountId
	})
}

export function groupRecipients(recipientsList: Recipient[]){
	return [{
		label: i18n('Teams'),
		options: fromIdName(recipientsList.filter(x => x.type == 'TEAM'))
	},{
		label: i18n('Users'),
		options: fromIdName(recipientsList.filter(x => x.type == 'USER'))
	}]
}

type GetEventsStateParameters = {
	tags?: string[],
	eventTypes?: string[],
	includeSubaccounts?: boolean,
	accountId?: string
	showUntagged?: boolean
	ignoreUserTags?: boolean
}

export function getEventsState(p: GetEventsStateParameters) {
	return new ApiRequest<EventStateSummary>({
		url: 'summary/eventAggregate',
		accountBased: true,
		accountId: p.accountId,
		includeSubaccounts: p.includeSubaccounts,
		subaccountsFilter: true,
		method: 'POST',
		payload: {
			tags: p.tags,
			eventTypes: p.eventTypes,
			showUntagged: p.showUntagged,
			ignoreUserTags: p.ignoreUserTags === undefined ? true : p.ignoreUserTags
		}
	})
}

export function getEventTypes(accountId?: string) {
	return new ApiRequest<DataListTextValue<string>>({
		url: 'summary/eventSummary/types',
		accountId: accountId,
		accountBased: true
	})
}
