export enum OperatingState{
	InMaintenance = 'IN_MAINTENANCE',
	NonOperational = 'NON_OPERATIONAL',
	Operational = 'OPERATIONAL'
}

export const operatingStateToLabel = {
	'OPERATIONAL': 'Active',
	'NON_OPERATIONAL': 'Outside operating period',
	'IN_MAINTENANCE': 'In Maintenance'
}
