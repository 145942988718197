import {ApiRequest} from "framework/api";
import {Permission} from "framework/permission";
import {DataListTextValue} from "framework/entities/dataList";

type GetTagsArgs = {
	accountId?: string
}

export function getAccountTags(args?: GetTagsArgs){
	return new ApiRequest<string[]>({
		url: 'tags',
		accountBased: true,
		accountId: args?.accountId
	})
}


export function getPermissions() {
	return new ApiRequest<Permission[]>({
		url: 'sessions/roles/list',
	})
}

export type LoginArgs = {
	username: string
	password: string
}

export type LoginResponse = {
	userId: string
	username: string
	email: string
	sessionId: string
	accountId: string
	loginAccountId: string
	logoId: string
	pictureId: string
	accountName: string
	loginAccountName: string
	locale: 'en' | 'no'
	timeZone: string
	dateFormat: string
	timeFormat: string
	dateTimeFormat: string
	licenseInfo: {
		accountId: string
		licenseOwner: string
		expireTime: number
		status: 'OK'
	}
}

export function authenticateUser(args: LoginArgs){
	return new ApiRequest<LoginResponse>({
		url: 'sessions/login',
		payload: args,
		method: 'POST'
	})
}


export function listCountries(){
	return new ApiRequest<DataListTextValue<string>>({
		url: 'calendars/holiday/countries'
	})
}

