import './antTable.less'

import React from 'react';
import {observer} from 'mobx-react';
import {makeAutoObservable, reaction} from "mobx";
import {debounce} from "lodash";
import {Table, TableColumnProps, TableProps} from 'antd';
import classnames from 'classnames';

import {AntDefaultOptionType, AntOption, AntSelect, textValueFields} from "controls/react/ant/antSelect";
import {newGuid} from "tools/guid";
import {AntInput} from "controls/react/ant/antInput";
import {i} from "core/localization";
import {
	getSeverityDataList,
	HealthDataHolder,
	healthDataToFlags,
	healthIndexToSeverity,
	Severity
} from 'framework/entities/healthData';
import {AntInputNumber} from './antInputNumber';
import {SeverityLabel, SeverityPillbox} from '../severityIndicator';
import {linkModel} from 'framework/mobx-integration';
import {RenderedCell} from "rc-table/lib/interface";
import IconButton from '../form/iconButton';
import {ColumnsType} from "antd/lib/table";



export const antColumnBlock = require('b_').with('ant-table-cell-wrapper');


export interface AntColumnProps<RecordType extends object> extends TableColumnProps<RecordType>{
	onCell?: (data: RecordType, index?: number) => object;
	disabled?: (data: RecordType, context?: any) => boolean;
	onClick?: (data: RecordType, e?: React.MouseEvent<HTMLElement, MouseEvent>, triggerUpdate?: () => void) => void;
	editable?: boolean,
	render?:  (value: any, record: RecordType, index: number, triggerUpdate?: () => void) => React.ReactNode | RenderedCell<RecordType>;
	renderEditor?: (p: RenderEditorProps<any, RecordType>) => React.ReactNode,
	sortingCallback?: (lvalue:any, rvalue:any) => number
}

export function AntTableColumn<RecordType extends object>(_: AntColumnProps<RecordType>) : null {
	return null;
};

export interface AntTableProps<RecordType extends object> extends TableProps<RecordType>{
	context?: any;
}

export const AntTable = observer( <RecordType extends object = any>(props:AntTableProps<RecordType>) => {
	let {className, columns, children, context, ...rest} = props;
	className = classnames(className, 'ant-table-wrapper');

	const components = {
		body: {
			cell: AntCell
		}
	}

	const columnsFinal = buildColumnsList<RecordType>(columns, children, context)

	return <Table className={className} columns={columnsFinal} components={components} {...rest}></Table>
});

function buildColumnsList<RecordType extends object>(columns:ColumnsType<RecordType>, children:React.ReactNode, context: any){
	const triggerUpdate = useUpdateTrigger();

	let result : AntColumnProps<RecordType>[];
	if (columns) {
		result = columns as AntColumnProps<RecordType>[];
	} else {
		result = React.Children.map(children, (child) => {
			// @ts-ignore
			if(child?.props){
				// @ts-ignore
				return {...child.props}
			}
		}).filter( column => column != null);
	}

	return result.map(column => {
		if(column.render){
			const initialRender = column.render;
			column.render =  (value: any, record: RecordType, index: number)=>  {
				return initialRender(value, record, index, triggerUpdate);
			}
		}
		if(column.sortingCallback){
			let multiple;
			if(column.sorter != null && typeof column.sorter == 'object'){
				multiple = column.sorter.multiple
			}else{
				column.sorter = {}
			}

			column.sorter.multiple = multiple;
			column.sorter.compare = (rowA:RecordType, rowB) =>
				column.sortingCallback(rowA[column.dataIndex as (keyof RecordType)], rowB[column.dataIndex as (keyof RecordType)])
		}

		if(!column.renderEditor && !column.disabled && !column.onClick)
			return column;

		return {
			...column,
			onCell: (record: RecordType) => {
				let result: Partial<AntEditableCellProps<RecordType>> = {};

				result.disabled = column.disabled && column.disabled(record, context);
				if(column.renderEditor && !result.disabled){
					result.editable = true;
					result.dataIndex = column.dataIndex as (keyof RecordType);
					result.record = record;
					result.renderEditor = column.renderEditor;
					result.triggerUpdated = triggerUpdate;
					result.title = i('Click to edit');
				}

				if(column.onClick){
					result.onClick = e => column.onClick(record, e);
				}

				if (column.onCell) {
					result = {
						...result,
						...column.onCell(record)
					}
				}

				return result;
			}
		}
	});
}

interface AntEditableCellProps<RecordType extends object> extends React.HTMLAttributes<HTMLElement> {
	editable: boolean;
	children: React.ReactNode | React.ReactNode[],
	dataIndex: keyof RecordType,
	record: RecordType,
	renderEditor: (p: RenderEditorProps<RecordType[keyof RecordType], RecordType>) => React.ReactNode,
	triggerUpdated: () => void;
	disabled: boolean;
}

const AntCell = observer(<RecordType extends object, ValueType>(props: AntEditableCellProps<RecordType>) => {
	if(props.editable){
		return <AntEditableCell {...props}/>
	}else{
		return <AntNonEditableCell {...props}/>
	}
});

const AntNonEditableCell = observer(<RecordType extends object, ValueType>(props: AntEditableCellProps<RecordType>) => {
	const {editable, dataIndex, record, children, triggerUpdated, disabled, className, ...restProps} = props;

	const blockClasses = antColumnBlock({
		disabled: disabled,
		clickable: props.onClick != null
	});

	return <td className={classnames(blockClasses, className)} {...restProps}>{children}</td>;
})

export interface LeaveEditModeProps<RecordType extends object> {
	value?: RecordType[keyof RecordType],
	doNotUpdateValue?: boolean;
}

const AntEditableCell = observer(<RecordType extends object, ValueType>(props: AntEditableCellProps<RecordType>) => {
	const {editable, dataIndex, record, children, triggerUpdated, renderEditor, onClick, disabled, className, ...restProps} = props;

	const [editing, setEditing] = React.useState(false);
	const [value, setValue] = React.useState<RecordType[keyof RecordType]>();

	const enterEditMode = React.useCallback(() => {
		setValue(record[dataIndex]);
		setEditing(true);
	}, []);

	let childNode = children;

	const leaveEditMode = React.useCallback((props?: LeaveEditModeProps<RecordType>) => {
		props = props ?? {};

		setEditing(false);
		if(props.doNotUpdateValue !== true) {
			record[dataIndex] = props.value !== undefined ? props.value : value;
		}
		setValue(null);
		triggerUpdated();
	},[value])

	const editControlRef = React.useCallback(ref => {
		ref?.focus();
	}, []);


	childNode = !editing
		? children
		: renderEditor({
			value: value,
			record: record,
			// @ts-ignore
			setValue: setValue,
			leaveEditMode: leaveEditMode,
			editControlRef: editControlRef
		}
	);

	const onClickWrapper = React.useCallback(e => {
		if(!editing){
			enterEditMode();
		}

		onClick && onClick(e);

	}, [editing, onClick])

	const blockClasses = antColumnBlock({
		disabled: disabled,
		clickable: props.onClick != null,
		editable: true,
		editing: editing,
	});

	return <td className={classnames(blockClasses, className)} onClick={onClickWrapper} {...restProps}>{childNode}</td>;
});

export interface RenderEditorProps<TValue, TRecord extends object>{
	value: TValue;
	record: TRecord,
	setValue: (newValue: TValue) => void,
	leaveEditMode: (props?: LeaveEditModeProps<TRecord>) => void,
	editControlRef: any
}


function useUpdateTrigger(){
	const [updated, setUpdated] = React.useState<string>();
	return  React.useCallback(() => {
		setUpdated(newGuid())
	},[])
}

export const yesNoDataList = [{
	id: 1,
	name: 'No'
}, {
	id: 2,
	name: 'Yes'
}];

export function yesNoRender(value: boolean): React.ReactNode{
	return <span>{!!value ? i('Yes') : i('No')}</span>
}

export function yesNoEditorRender(props: RenderEditorProps<boolean, any>): React.ReactNode {
	return <AntSelect dataList={yesNoDataList}
	                  open={true}
	                  value={props.value === false ? 1 : 2}
	                  onChange={(newValue: number) => props.leaveEditMode({value: newValue == 2 ? true : false})}
	                  onBlur={() => props.leaveEditMode()}
	                  componentRef={props.editControlRef}
	/>
}

export function inputEditorRender(props: RenderEditorProps<string, any>) : React.ReactNode {
	return <AntInput value={props.value}
	                 onChange={newValue => props.setValue(newValue)}
	                 onBlur={() => props.leaveEditMode()}
	                 onPressEnter={() => props.leaveEditMode()}
	                 onPressEsc={() =>  props.leaveEditMode({doNotUpdateValue: true})}
	                 ref={props.editControlRef}
	/>
}

export function inputEditorNumberRender(props: RenderEditorProps<string|number, any>) : React.ReactNode {
	return <AntInputNumber value={props.value}
	                 onChange={newValue => props.setValue(newValue)}
	                 onBlur={() => props.leaveEditMode()}
	                 innerRef={props.editControlRef}
	/>
}

export function severityRender(severity: Severity){
	return <SeverityLabel severity={severity}/>
}

export function severityEditorRender(props: RenderEditorProps<Severity, any>): React.ReactNode {
	return <AntSelect value={props.value}
	                  open={true}
	                  onChange={(newValue: string) => props.leaveEditMode({value: newValue})}
	                  onBlur={() => props.leaveEditMode()}
	                  componentRef={props.editControlRef}>
		{getSeverityDataList().map(x =>
			<AntOption key={x.id} value={x.id}>
				<SeverityLabel severity={x.id}/>
			</AntOption>
		)}
	</AntSelect>
}

export function useDropDownColumn(options: AntDefaultOptionType[])
	: [(value:string) => string, (props: RenderEditorProps<any, any>) => React.ReactNode]{

	const render = React.useCallback((value: string) => {
		const entry = options.find( x => x.id == value);

		return entry?.name ?? value;
	}, [options]);

	const editor = React.useCallback((props: RenderEditorProps<string, any>): React.ReactNode =>
			<AntSelect options={options}
			           value={props.value?.toString()}
			           open={true}
			           onChange={(newValue: string) => props.leaveEditMode({value: newValue})}
			           onBlur={() => props.leaveEditMode()}
			           componentRef={props.editControlRef}
			/>
		, [options])

	return [render, editor];
}

export function healthIndexIndicatorRender(healthIndex: number|string, healthDataHolder: HealthDataHolder) : React.ReactNode{
	const healthFlags = healthDataToFlags(healthDataHolder);

	return <SeverityPillbox {...healthFlags}/>
}

export function stringFilter<T>(fields: (keyof T)[]){
	return (value:string|number|boolean, record:T) => {
		if(value == null)
			return true;

		value = value.toString().toLowerCase();

		for(const field of fields) {
			const recordValue = record[field];
			if (recordValue != null) {
				if( recordValue.toString().toLowerCase().includes(value)){
					return true;
				}
			}
		}

		return false
	}
}

export class SearchString<T extends object>{
	value: string;
	filter: [string];
	fields: (keyof T)[];

	constructor(fields: (keyof T)[]) {
		this.fields = fields;
		makeAutoObservable(this);

		reaction(() => this.value, () => {
			this.filter = this.value == '' ? null : [this.value];
		}, {
			delay: 500
		});
	}
	linkSearchField(){
		return linkModel(this, 'value');
	}

	linkColumn(){
		return {
			onFilter: stringFilter<T>(this.fields),
			filteredValue: this.filter
		}
	}
}

export interface SearchInputProps<T extends object>{
	searchString: SearchString<T>
}
export const SearchInput = observer(<T extends object>(props: SearchInputProps<T>) => {
	return <AntInput placeholder={i('Search...')}
			{...props.searchString.linkSearchField()}/>
})


export const stringSorter = (lvalue: string, rvalue: string) => lvalue?.localeCompare(rvalue) ?? -1;
export const numberSorter = (lvalue: number, rvalue: number) => lvalue - rvalue;
export const booleanSorter = (lvalue: boolean, rvalue: boolean) => {
	if (lvalue === rvalue)
		return 0;

	if (lvalue == false)
		return -1;

	return 1
}

export const TitleWithTooltip = (props: {title: string, tooltip: string}) => {
	return <span>
		{props.title}
		<IconButton iconName={"question-sign"}
		            title={props.tooltip}/>
	</span>
}
