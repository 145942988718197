import React from "react"
import {PlusSquareOutlined} from "@ant-design/icons";

import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {IconButton} from "controls/react/form/iconButton";
import {sortAlphabetically} from 'tools/utils';

const i = require('core/localization').translator({

}, sharedLocalization);


let menuStructure = null;
export function getWidgetsMenuStructure(){
	if(menuStructure == null) {
		menuStructure = [
			{
				title: i('Application'),
				icon: 'artificial-intelligence',
				widgets: [{
					type: 'application-preview',
					title: i('Preview'),
					icon: 'artificial-intelligence'
				}]
			}, {
				title: i('Presentation'),
				icon: 'picture',
				widgets: [{
					type: 'dashboard',
					title: i('Dashboard'),
					icon: 'picture'
				},{
					type: 'report',
					title: i('Report'),
					icon: 'picture'
				}]
			}, {
				title: i('Service'),
				icon: 'git-merge',
				widgets: [{
					type: 'service-preview',
					title: i('Preview'),
					icon: 'camera'
				}, {
					type: 'service-summary',
					title: i('Grouped summary'),
					icon: 'justify'
				}, {
					type: 'service-summary-grid',
					title: i('Summary grid'),
					icon: 'justify'
				}, {
					type: 'service-events',
					title: i('Event summary'),
					icon: 'table'
				}, {
					type: 'service-history',
					title: i('History'),
					icon: 'book-open'
				}, {
					type: 'service-selective',
					title: i('Selective Services'),
					icon: 'list'
				}, {
					type: 'service-grid',
					title: i('Grid'),
					icon: 'table'
				}, {
					type: 'service-single-graph',
					title: i('Single Graph'),
					icon: 'stats'
				}, {
					type: 'service-multi-graph',
					title: i('Multigraph'),
					icon: 'stats'
				}, {
					type: 'service-gauge',
					title: i('Gauge'),
					icon: 'dashboard'
				},{
					type: 'service-document',
					title: i('Document'),
					icon: 'file'
				}]
			}, {
				title: i('Metric'),
				icon: 'charts',
				widgets: [{
					type: 'metric-single-graph',
					title: i('Single Graph'),
					icon: 'stats'
				}, {
					type: 'metric-multi-graph-asset',
					title: i('Multigraph (Asset)'),
					icon: 'stats'
				}, {
					type: 'metric-multi-graph-asset-group',
					title: i('Multigraph (Asset Group)'),
					icon: 'stats'
				}, {
					type: 'metric-gauge',
					title: i('Gauge'),
					icon: 'dashboard'
				}, {
					type: 'metric-stacked-chart',
					title: i('Stacked Chart'),
					icon: 'stats'
				}, {
					type: 'metric-custom',
					title: i('Custom') + ' - (' + i('Experimental') + ')',
					icon: 'stats'
				}, {
					type: 'metric-barchart',
					title: i('Linear gauge') ,
					icon: 'dashboard'
				}, {
					type: 'metric-table',
					title: i('Table') ,
					icon: 'dashboard'
				}, {
					type: 'metric-sparkline-table',
					title: i('Sparkline Table'),
					icon: 'stats'
				}]
			}, {
				title: i('Health'),
				icon: 'heart-empty',
				widgets: [{
					type: 'health-single-graph',
					title: i('Single Graph'),
					icon: 'stats'
				}, {
					type: 'health-multi-graph',
					title: i('Multigraph'),
					icon: 'stats'
				}, {
					type: 'health-gauge',
					title: i('Gauge'),
					icon: 'dashboard'
				}]
			}, {
				title: i('Asset'),
				icon: 'heartbeat',
				widgets: [{
					type: 'asset-grid',
					title: i('Grid'),
					icon: 'table'
				}, {
					type: 'asset-console',
					title: i('Console'),
					icon: 'heart'
				}]
			}, {
				title: i('SLA'),
				icon: 'record',
				widgets: [{
					type: 'sla',
					title: i('SLA'),
					icon: 'justify'
				}, {
					type: 'sla-history',
					title: i('History'),
					icon: 'stats'
				}, {
					type: 'sla-time',
					title: i('Time'),
					icon: 'justify'
				}, {
					type: 'sla-grid',
					title: i('Grid'),
					icon: 'table'
				}]
			}, {
				title: i('KPI'),
				icon: 'equalizer',
				widgets: [{
					type: 'kpi-single-graph',
					title: i('Single Graph'),
					icon: 'stats'
				}, {
					type: 'kpi-multi-graph',
					title: i('Multi Graph'),
					icon: 'stats'
				}, {
					type: 'kpi-gauge',
					title: i('Gauge'),
					icon: 'dashboard'
				}, {
					type: 'kpi-history',
					title: i('History'),
					icon: 'stats'
				}]
			}, {
				title: i('Grid'),
				icon: 'table',
				widgets: [{
					type: 'summary-grid',
					title: i('Home'),
					icon: 'table'
				}, {
					type: 'reasons-grid',
					title: i('Health Reasons'),
					icon: 'table'
				}, {
					type: 'incidents-grid',
					title: i('Incidents'),
					icon: 'table'
				}, {
					type: 'event-summary-grid',
					title: i('Event summary'),
					icon: 'table'
				}, {
					type: 'data-sources-grid',
					title: i('Datasource grid'),
					icon: 'table'
				}]
			}, {
				title: i('Custom'),
				icon: 'text-size',
				widgets: [{
					type: 'text',
					title: i('Text'),
					icon: 'text-size',
				}, {
					type: 'time',
					title: i('Time'),
					icon: 'text-size'
				}, {
					type: 'web-page',
					title: i('Web Page'),
					icon: 'text-size'
				}, {
					type: 'gps',
					title: i('GPS'),
					icon: 'map-marker'
				},{
					type: 'ai-query',
					title: i(('AI Query')),
					icon: 'map-marker'
				}, {
					type: 'data-sources-chart',
					title: i('Datasource chart'),
					icon: 'table'
				}]
			},{
				title: i('Cloud'),
				icon: 'text-size',
				widgets: [{
					type: 'cloud-services-cost-bar-chart',
					title: i('Bar Chart'),
					icon: 'table'
				}, {
					type: 'cloud-services-cost-grid',
					title: i('Grid'),
					icon: 'table'
				}, {
					type: 'cloud-services-instances-grid',
					title: i('Instances Grid'),
					icon: 'table'
				}, {
					type: 'cloud-services-buggle-chart',
					title: i('Bubble Chart'),
					icon: 'credit'
				}]
			}, {
				title: i('Cost'),
				icon: 'euro',
				widgets: [{
					type: 'budget-cost-bar-chart',
					title: i('Bar Chart'),
					icon: 'table'
				}, {
					type: 'cost-grid',
					title: i('Cost Grid'),
					icon: 'table'
				}, {
					type: 'cost-profile',
					title: i('Cost Model'),
					icon: 'table'
				}]
			}, {
				title: i('State'),
				icon: 'list',
				widgets: [{
					type: 'application-state',
					title: i('Application'),
					icon: 'artificial-intelligence'
				}, {
					type: 'service-state',
					title: i('Service'),
					icon: 'git-merge'
				}, {
					type: 'asset-state',
					title: i('Asset'),
					icon: 'hospital'
				}, {
					type: 'sla-state',
					title: i('SLA'),
					icon: 'record'
				}, {
					type: 'event-state',
					title: i('Event'),
					icon: 'article'
				}]
			}
		];
	}

	menuStructure.sort(sortAlphabetically);

	for (let menuItem of menuStructure) {
		menuItem.widgets.sort(sortAlphabetically);
	}

	return menuStructure;
}

export function generateMenuItems(includeRoot, includeIcon, clickCallback) {
	const rootItem = {
		icon: <PlusSquareOutlined/>,
		key: 'root'
	}

	const menuItems = getWidgetsMenuStructure().map(section => {
		return {
			label: section.title,
			key: section.title,
			onTitleClick: event => {event.domEvent.stopPropagation()},
			children: section.widgets.map(widgetMenuEntry => {
				return {
					key: widgetMenuEntry.type,
					onClick: clickCallback,
					label: widgetMenuEntry.title,
					icon: (includeIcon && widgetMenuEntry.icon) ? <IconButton embedded={true} iconName={widgetMenuEntry.icon}/> : undefined
				}
			}
			)
		}
	})

	if(includeRoot){
		rootItem.children = menuItems;
		return [rootItem]
	}
	else {
		return menuItems;
	}
 }
