import './metricInlineSelector.less';

import React, {useCallback, useState}  from 'react';
import {FormEntry} from "controls/react/form/formEntry";
import {DataRegistryWindow} from "areas/management/data-registry/dataRegistryWindow";
import {AntInput} from "controls/react/ant/antInput";
import {useStore} from "../genericMetricsWidgetConfiguration/useStore";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {fromJS} from "immutable";
import {CoommonWidgetsApi} from './api';
import {CollapsibleFrame} from "controls/react/layout/collapsibleFrame";
import {DropDownList} from 'controls/react/form';
import {QuestionCircleFilled, UnorderedListOutlined} from '@ant-design/icons';
import {apiFetch} from 'framework/api';
import {getMetricUnits} from './dataSources/MetricSettingsApi';
import {useEffect, useRef} from 'react';
import {Button} from 'controls/react/form';
import {ToolbarItemPosition} from 'controls/react/layout/toolbar';
import {MetricConversionForm, validateMetricConversion} from './conversionWindow';
import {CustomNotification} from 'controls/customNotification';
import { AntSwitch } from 'controls/react/ant/antSwitch';
import { extractMetricFields } from '../customMetrics/metricSelector';
import {rgbToHex} from 'tools/utils';
import {AntButton} from "controls/react/ant/antButton";
import {MetricConversionForm, validateMetricConversion} from "areas/service-boards/widgets/common/metricConversionForm";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization, {
    'Metric Settings' : {
        no : 'Metrikkinnstillinger'
    },
	'Metric selector': {
		no: 'Metrikkvalg'
	},
	'Advanced': {
		no: 'Avansert'
	},
	'Validate': {
		no: 'Validering'
	},
	'Unit Label': {
		no: 'Enhet Navn'
	},
	'Unit selector': {
		no: 'Enhetvalg'
	},
	'Formula is only available if Unit is set to Custom.': {
		no: 'Formel er kun tilgjengelig om tilpasset er valgt i Enhet.'
	}
});

const b = require('b_').with('advanced-metric-settings');

export function MetricInlineSelector(props) {
	const [showMetricsWindow, setShowMetricsWindow] = React.useState(false);
	const [advanced, setAdvanced] = useState(props.advanced ?? false);

	const onAdd = (items) => {
		setShowMetricsWindow(false);
		if (props.onAdd)
			props.onAdd(items);

		onAdvancedSwitchChange(false);
	}

	const onAdvancedSwitchChange = useCallback((value) => {
		props.onAdvancedChange?.(value);
		if (!value) {
			onUnitChanged(undefined);
		}
	});

	const onUnitChanged = useCallback((data) => {
		const value = data?.value
		props.unitChanged?.(value);
		if(!value || value !== 'CUSTOM') {
			props.onFormulaChange?.(undefined)
		}
	});

	const onUnitLabelChanged = useCallback((value) => {
		props.unitLabelChanged?.(value);
	});

	useEffect(() => {
		setAdvanced(props.advanced ?? false);
	}, [props.advanced]);

	return <CollapsibleFrame expanded={props.expanded}
	                         title={i('Metric Settings')}>
		<FormEntry label={i('Metric')}>
			<AntButton icon={<UnorderedListOutlined/>}
			           className={b('action')}
			           size={"small"}
			           title={i('Metric selector')}
			           onClick={() => setShowMetricsWindow(true)}
			/>
			<AntInput value={props.text} readOnly={true}/>
		</FormEntry>
		{(props.type == 'metric-single-graph' || props.type == 'metric-gauge' || props.showAdvanced) && <CollapsibleFrame expanded={advanced}
						  title={i('Advanced')}
						  switcher={FrameSwitcher}
						  onChange={onAdvancedSwitchChange}
						  className={b()}
						  hideCollapsedPlaceholder={true}
						  childrenPadding={true}
						  disabled={!props.text || !props.metrics?.length}>
			{props.metrics?.[0] && <UnitConversions
							metricUnitType={props.metrics?.[0].unitType}
							formula={props.formula}
							decimals={props.decimals}
							unitLabel={props.unitLabel}
							unit={props.unit || 'AUTOSCALING'}
							onFormulaChange={props.onFormulaChange}
							onDecimalsChange={props.onDecimalsChange}
							unitChanged={onUnitChanged}
							unitLabelChanged={onUnitLabelChanged} />
			}
		</CollapsibleFrame>}
		{showMetricsWindow && props.accountId != null &&
			<DataRegistryWindow
				open={true}
				onClose={() => setShowMetricsWindow(false)}
				onAdd={onAdd}
				accountId={props.accountId}
				singleMetricSelection={props.type === 'metric-single-graph' || props.type === 'single_graph' || props.singleMetricSelection}
				defaultSelectedMetricIds={props.plainSelectedIds}
			/>
		}
	</CollapsibleFrame>;

}

export const UnitConversions = (props) => {
	const [units, setUnits] = useState([]);
	const [colors, setColors] = useState([]);
	const actionNotification = useRef(null);
	const [unit, setUnit] = useState(props.unit);
	const [unitLabel, setUnitLabel] = useState(props.unitLabel);
	const [color, setColor] = useState(props.color);

	const unitChanged = useCallback((value) => {
		const unit = units.find(x => x.value == value);
		props.unitChanged?.(unit);
		if (unit.value !== 'CUSTOM' || (unitLabel && units.some(x => x.text == unitLabel))) {
			props.unitLabelChanged?.(unit.text);
			props.onFormulaChange?.(undefined);
		}
	});

	const validate = useCallback(async () => {
		const result = await validateMetricConversion(props.formula, props.decimals);
		actionNotification.current.setOptions(result).show();
	});

	useEffect(() => {
		setUnit(props.unit);
	}, [props.unit]);

	useEffect(() => {
		setUnitLabel(props.unitLabel);
	}, [props.unitLabel]);

	useEffect(() => {
		setColor(props.color);
	}, [props.color]);

	useEffect(() => {
		if (!unit || !units.length) {
			return;
		}
		unitChanged(unit);
	}, [units]);

	useEffect(() => {
		if (!unit) {
			setUnit(props.metricUnitType);
		}
		actionNotification.current = new CustomNotification({
			appendToElement: '.notification-container',
		});
		const getUnits = async () => {
			const unitType = !unit || ['CUSTOM', 'AUTOSCALING'].includes(unit) ? props.metricUnitType : unit;
			const unitList = await apiFetch(getMetricUnits(unitType));
			unitList.data = [{
				description: 'Autoscaling',
				text: '',
				value: 'AUTOSCALING'
			}, {
				description: 'Custom',
				text: '',
				value: 'CUSTOM'
			}, ...unitList.data];
			const defaultIndex = unitList.data.findIndex(x => x.value == props.metricUnitType);
			if (defaultIndex >= 0) {
				unitList.data[defaultIndex].description += ' (' + i('default') +')';
			}
			setUnits(unitList.data);
		}
		getUnits();
	}, []);

	useEffect(() => {
		const colorsDataSource = [{
			value: "#7cb5ec"
		}, {
			value: "#434348"
		}, {
			value: "#90ed7d"
		}, {
			value: "#8085e9"
		}, {
			value: "#f15c80"
		}, {
			value: "#e4d354"
		}, {
			value: "#2b908f"
		}, {
			value: "#f45b5b"
		}, {
			value: "#91e8e1"
		}];
		setColors(colorsDataSource);
	}, []);

	return <>
		<div className="notification-container"></div>
		<FormEntry label={i('Unit')} >
			<QuestionCircleFilled title={i('Unit selector')} className={b('unit-selector-info')} />
			<DropDownList dataTextField="description" dataSource={units} onChange={unitChanged} value={unit} disabled={props.readOnlyFields} />
		</FormEntry>
		<FormEntry label={i('Unit Label')}>
			<AntInput value={unitLabel} onChange={props.unitLabelChanged} readOnly={unit != 'CUSTOM' || props.readOnlyFields} />
		</FormEntry>
		<MetricConversionForm formula={unit === 'CUSTOM' ? props.formula : null}
							  decimals={props.decimals}
							  onFormulaChange={props.onFormulaChange}
							  onDecimalsChange={props.onDecimalsChange}
							  readOnly={unit != 'CUSTOM'}
							  disableFields={props.readOnlyFields}
							  additionalPlaceholder={i('Formula is only available if Unit is set to Custom.')}
							  onValidate={validate} />
		{props.isFromMultigraph && <FormEntry label={i('Color')}>
			<DropDownList dataTextField="color"
						  dataSource={colors}
						  onChange={props.onColorChange}
						  disabled={props.readOnlyFields}
			              value={props.color}
						  config={{
						  	template: (data) => {
						  		return `<div style="background-color: ${data.value}; width: 20px; height: 20px;"></div>`
							},
							valueTemplate: ({value}) => {
						  		let shownColor = value ?? props.color;
								let selectedColor = $('.k-hover').find('div').css('background-color');
								if (selectedColor) {
									shownColor = rgbToHex(selectedColor);
								}
						  		return `<div style="background-color: ${shownColor}; width: 20px; height: 20px;"></div>`
							}
						  }}
			/>
		</FormEntry>}
	</>
}

const FrameSwitcher = (props) => {
	return <AntSwitch size='small'
					  onChange={props.onChange}
					  value={props.enabled}
					  disabled={props.disabled} />
}

function withStore(WrappedComponent) {
	function WithStoreComponent(props) {
		const [type] = useStore(["type"]);
		const [selectedMetrics, setSelectedMetrics] = useStore(["configuration", "metricsItems"]);
		const [selectedIds, setIds] = useStore(["configuration", "metrics"]);
		const [accountId] = useStore(["configuration", "accountId"]);

		const [advanced, setAdvanced] = useStore(["configuration", "advanced"]);
		const [unit, setUnit] = useStore(["configuration", "unit"]);
		const [unitLabel, setUnitLabel] = useStore(["configuration", "unitLabel"]);
		const [formula, setFormula] = useStore(["configuration", "formula"]);
		const [decimals, setDecimals] = useStore(["configuration", "decimals"]);

		const [currentAccountId, setCurrentAccountId] = useState(accountId);
		useEffect(() => {
			setCurrentAccountId(props.useDefaultAccount ? ApplicationState.accountId : accountId ?? ApplicationState.accountId);
		}, [accountId, props.useDefaultAccount]);

		const onAdd = useCallback((items) => {
			async function fetchSelectedItems() {
				const result = await CoommonWidgetsApi.fetchSelectedMetrics(currentAccountId, items);
				// temporary fix till metricWindow will be converted to new Grid
				setSelectedMetrics(fromJS(result.items.map(x => x.data)));
				setIds(result.items.map((metric) => metric.data.metricId));
			};

			if (items.length || type !== 'metric-single-graph') {
				fetchSelectedItems();
			} else {
				setSelectedMetrics(fromJS([]));
			}
		});

		const metricInputText = selectedMetrics?.toJS().map(x => {
			let text = '';
			if (x.assetName) {
				text += x.assetName + '/';
			}
			text += x.categoryNode + '/' + x.instanceName;
			return text;
		}).join(', ');
		const plainSelectedIds = React.useMemo(() => {
			if (selectedMetrics) {
				return selectedMetrics.toJS().map((metric) => metric.metricId);
			}
		}, [selectedMetrics]);

		return <WrappedComponent
			accountId={currentAccountId}
			onAdd={onAdd}
			type={type}
			plainSelectedIds={plainSelectedIds}
			text={metricInputText}
			advanced={advanced}
			formula={formula}
			decimals={decimals}
			unit={unit}
			unitLabel={unitLabel}
			metrics={selectedMetrics?.toJS()}
			unitChanged={setUnit}
			unitLabelChanged={setUnitLabel}
			onFormulaChange={setFormula}
			onDecimalsChange={setDecimals}
			onAdvancedChange={setAdvanced}
			showAdvanced={props.showAdvanced}
		/>
	}

	return WithStoreComponent;
}
const MetricInlineSelectorStore = withStore(MetricInlineSelector)
export {MetricInlineSelectorStore}



function withState(WrappedComponent) {
	function WithStateComponent(props) {
		 const [metricInputText, setMetricInputText] = useState('')

		React.useEffect(() => {
			fetchSelectedItems(props.selectedMetrics?.map(x => x.metricId))
		},[])

		const [selectedMetricIds, setSelectedMetricIds] = useState(props.selectedMetrics?.map((metric) => metric.metricId));
		const [selectedMetrics, setSelectedMetrics] = useState(props.selectedMetrics);

		const onAdd = React.useCallback((ids) => {
			if (ids.length || props.type !== 'metric-single-graph') {
				fetchSelectedItems(ids, (loadedItems) => {
					if (props.onAdd)
						props.onAdd(loadedItems.map(m => extractMetricFields(m.data)));
				});
			} else {
				setSelectedMetrics([])
			}
		}, [props.type, props.onAdd]);

		async function fetchSelectedItems(metricIds, callback) {
			if (!metricIds?.length)
				return

			const result = await CoommonWidgetsApi.fetchSelectedMetrics(props.accountId, metricIds);
			setSelectedMetricIds(result.items.map(m => m.data.metricId))
			setSelectedMetrics(result.items.map(m => m.data))
			setMetricInputText(result.items.map(x => {
				let text = '';
				if (x.data.assetName) {
					text += x.data.assetName + '/';
				}
				text += x.data.categoryNode + '/' + x.data.instanceName;
				return text;
			}).join(', '))

			callback?.(result.items)
		}

		const [advanced, setAdvanced] = useState(props.configObj?.advanced);
		const [unit, setUnit] = useState(props.configObj?.unit);
		const [unitLabel, setUnitLabel] = useState(props.configObj?.unitLabel);
		const [formula, setFormula] = useState(props.configObj?.formula);
		const [decimals, setDecimals] = useState(props.configObj?.decimals);

		return <WrappedComponent
			accountId={props.accountId}
			onAdd={onAdd}
			type={props.type}
			plainSelectedIds={selectedMetricIds}
			metrics={selectedMetrics}
			text={metricInputText}
			advanced={advanced}
			formula={formula}
			decimals={decimals}
			unit={unit}
			unitLabel={unitLabel}
			showAdvanced={props.showAdvanced}
			unitChanged={(value) => {
			 if (props.retrieveConfig) {
				 props.retrieveConfig('unit', value);
			 }
			 setUnit(value);
			}}
			unitLabelChanged={(value) => {
			 if (props.retrieveConfig) {
				 props.retrieveConfig('unitLabel', value);
			 }
			 setUnitLabel(value);
			}}
			onFormulaChange={(value) => {
			if (props.retrieveConfig) {
				props.retrieveConfig('formula', value);
			}
			setFormula(value);
			}}
			onDecimalsChange={(value) => {
			 if (props.retrieveConfig) {
				 props.retrieveConfig('decimals', value);
			 }
			 setDecimals(value);
			}}
			onAdvancedChange={(value) => {
			 if (props.retrieveConfig) {
				 props.retrieveConfig('advanced', value);
			 }
			 setAdvanced(value);
			}}
			singleMetricSelection={true}
		/>
	}

	return WithStateComponent;
}
const MetricInlineSelectorState = withState(MetricInlineSelector)
export {MetricInlineSelectorState}
