import { i } from "../translations";

export enum SortDirection {
	Asc = 'Asc',
	Desc = 'Desc'
}

let sortDirections: {id: SortDirection,name: string}[]
export const getSortDirectionDataSource = () => {
	if (!sortDirections) {
		sortDirections = [{
			id: SortDirection.Asc,
			name: i(SortDirection.Asc)
		}, {
			id: SortDirection.Desc,
			name: i(SortDirection.Desc)
		}];
	}
	return sortDirections;
}
