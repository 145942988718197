import UrlBuilder from "tools/urlBuilder";

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Incidents/IncidentForm.jsp',
			bodyCssClass: 'cw_incident_module',
			url: '/incidents/:id',
			getModuleImport: () => import('./details'),
			routeId: 'incidents_details'
		}, {
			jspUrl: 'include/Incidents.jsp',
			url: '/incidents',
			getModuleImport: () => import('./incidents'),
			routeId: 'incidents'
		}
	];
}

export class IncidentsRouter {
	static details(incidentId) {
		return new UrlBuilder(`/incidents/${incidentId}`)
			.build();
	}

	static createNew(contentCategory){
		return new UrlBuilder(`/incidents/new`)
			.add("contentCategory", contentCategory)
			.build();
	}

	static list(filters) {
		return new UrlBuilder(`/incidents`)
			.addObject(filters)
			.build();
	}
}
