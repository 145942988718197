import './cloudServices.less';

import React from "react";
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

import {Section} from "controls/react/layout/section";
import {AntInput} from "controls/react/ant/antInput";
import {IconButton} from "controls/react/form/iconButton";
import {Grid, fullTextSearch} from "controls/react/kendoWrappers/grid";
import {addLinks} from "core/react/links";
import {
	processApiResponse,
	formatBudgetColumn,
	subscribe,
	getGridFilterable,
	hideColumns,
	showReasonsGrid,
	onGridDataBound,
	editBudget, getResourceTypeLabel
} from "./shared";
import {FormEntry} from "controls/react/form";
import {CloudBillingMonitorsApi} from "areas/assets/monitors/cloud-billing/api";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import Utils from 'tools/utils';
import {getGridStateForSaving} from "controls/react/kendoWrappers/grid";
import {sharedLocalization, throttleLimit} from "./shared";
import {sharedLocalization as widgetsSharedLocalization} from "controls/designer/features/widgets/localization";
import {ColumnsVisibility} from "controls/designer/features/widgets/columnsVisibility";
import {CostGridColumns} from "controls/designer/features/widgets/cloudServices/costGridColumns";
import {renderSeverityTag} from "tools/states";
import {AssetsRouter} from "areas/assets/bundleDescription";
import {FilterResetButton} from "controls/react/form/filterResetButton";
import {formatNumber} from "tools/helpers/math";
import {
	getDefaultConfig, updateConfigToTheLattestFormat,
	WidgetConfigurationTemplate
} from "controls/designer/features/widgets/cloudServices/widgetConfigurationTemplate";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";

const i = require('core/localization').translator(sharedLocalization, widgetsSharedLocalization);
export function getWidgetDescription(){
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'cloud-services-cost-grid',
			title: i('Cost'),
			...getDefaultConfig(),
			historicMonths: 12,
			enableBudgetEditing: true,
			showSeverities: false,
			columns: CostGridColumns.get(),
			showHiSeverity: false,
			showBudgetSeverity: false,
		},
		minWidth: 800,
		preProcessConfig: updateConfigToTheLattestFormat,
		widget: Widget,
		fullTitle: i('Cloud') + '/' + i('Grid'),
	}
}


export const WidgetConfiguration = props => {
	const {configLink} = props;

	const allColumns = React.useMemo(() => CostGridColumns.get(), []);

	return <WidgetConfigurationTemplate {...props}>
		<ColumnsVisibility
			columnsLink={configLink.get('columns')}
			allColumns={allColumns}
		/>
		<FormEntry label={i('Historic months')} width={'fit'}>
			<IconButton iconName={"question-sign"} title={i('Historic months tooltip')}/>
			<AntInputNumber valueLink={configLink.get('historicMonths')}/>
		</FormEntry>
		<FormEntry label={i('HI severity')} width={'fit'}>
			<IconButton iconName={"question-sign"} title={i('Show health index for the asset')}/>
			<AntCheckbox valueLink={configLink.get('showHiSeverity')}/>
		</FormEntry>
		<FormEntry label={i('Budget Severity')} width={'fit'}>
			<IconButton iconName={"question-sign"} title={i('Show budget severity')}/>
			<AntCheckbox valueLink={configLink.get('showBudgetSeverity')}/>
		</FormEntry>
		<Section appearance={"frame"} title={i('Public mode')} childrenPadding={true}>
			<FormEntry label={i('Budget edit')} width={'fit'}>
				<IconButton iconName={"question-sign"} title={i('Budget edit tooltip')}/>
				<AntCheckbox valueLink={configLink.get('enableBudgetEditing')}/>
			</FormEntry>
		</Section>
	</WidgetConfigurationTemplate>
}

const b = require('b_').with('cloud-services-cost-widget');

@addLinks
class Widget extends React.PureComponent{
	chartContainerRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			loading: true,
			gridHeight: "auto",
			searchFilter: this.props.persistedState?.filter || {},
			gridSort: this.props.persistedState?.sort || [],
			searchValue: this.props.persistedState?.searchValue || '',
		}

		this.availableSearchColumns = ['serviceName', 'subscription', 'assetName', 'cloudTypeText']
			.filter(x => props.config.columns.includes(x));
	}

	render() {
		const searchLink = this.linkState('searchValue')
			.changing(({rootStore, value}) => {
				rootStore.searchFilter = fullTextSearch(
					this.availableSearchColumns,
					value
				);
			});

		const toolbarAtTheEnd = [
			<AntInput key={1} valueLink={searchLink} placeholder={i('Search...')}/>,
			<FilterResetButton key={2} onReset={() => this.onFilterReset()}></FilterResetButton>
		];
		return (
			<DefaultWidgetWrapper {...this.props} toolbarAtTheEnd={toolbarAtTheEnd}>
				<Section contentPadding={false} height={"fit"} overlaySpinner={this.state.loading}>
					{this.state.loaded &&
						<Grid dataSource={this.state.gridDataSource}
							  filterable={getGridFilterable(this)}
						      filter={this.state.searchFilter}
							  onFilter={this.onGridFilter}
							  height={this.state.gridHeight}
							  setHeightOnResize={true}
							  sort={this.state.gridSort}
						      sortable={{mode: 'multiple'}}
						      skipSelectorColumn={true}
						      fit={true}
							  resizable={true}
							  reorderable={true}
						      columns={this.state.gridColumns}
							  dataBound={(e) => this.onGridDataBound(e)}
							  onRowClicked={this.onRowClicked}
							  ref={grid => this.grid = grid}
					/>}
					{this.state.noDataAvailable &&
						<p>{i('Data is not available')}</p>
					}
				</Section>
			</DefaultWidgetWrapper>
		)
	}

	async componentDidMount(){
		await this.initializeGrid();

		this.throttledUpdate = _.throttle(this.initializeGrid, throttleLimit, {trailing: false});
		this.subscription = subscribe(this.gridData, this.props.config.monitorId, this.throttledUpdate);
	}

	componentWillUnmount() {
		this.subscription?.unsubscribe();

		if (this.reasonsWindowId) {
			ReactDOM.unmountComponentAtNode(document.getElementById(this.reasonsWindowId));
		}
	}

	initializeGrid = async () => {
		const response = await this.loadData();
		if(!response.success){
			this.setState({
				loading: false,
				noDataAvailable: true
			});
			return;
		}

		this.gridData = response.data.items;

		let gridColumns = this.getStaticGridColumns();
		let dynamicColumns = [];
		let gridRows = [];

		processApiResponse(response.data.items, dataRow => {
			let gridRow = {
				resourceName: dataRow.resourceName,
				subscription: dataRow.subscription,
				yearToDate: dataRow.yearToDate,
				yearlyEstimate: dataRow.yearProjection,
				monthlyEstimate: dataRow.estimate,
				monthlyBudget: dataRow.monthlyBudget,
				monthToDate: dataRow.monthToDate,
				editable: dataRow.editable,
				name: dataRow.name,
				cloudTypeText: dataRow.cloudTypeText,
				budgetSeverity: dataRow.budgetSeverity,
				severity: dataRow.severity,
				assetId: dataRow.assetId,
				assetName: dataRow.assetName,
				cloudBudget: dataRow.cloudBudget,
				spent: dataRow.spent,
				spentPercent: dataRow.spentPercent
			};

			for (var month of dataRow.months) {
				gridRow['month' + month.month] = month.amount;
				gridRow['month' + month.month + 'diff'] = month.diff;
			}
			gridRows.push(gridRow);
		}, (monthNumber, monthName) => {
			gridColumns.push({
				field: 'month' + monthNumber,
				title: monthName,
				width: 70,
				template: item => this.formatNumber(item['month' + monthNumber]),
				attributes: {
					style: 'text-align: right'
				},
				doNotHide: true
			});
			dynamicColumns.push('month' + monthNumber);

			gridColumns.push({
				field: 'month' + monthNumber + 'diff',
				title: i('{0} diff', monthName),
				width: 100,
				template: item => this.formatNumber(item['month' + monthNumber + 'diff']) ?? '',
				attributes: {
					style: 'text-align: right'
				},
				doNotHide: true
			});
			dynamicColumns.push('month' + monthNumber + 'diff');
		}, 'sort-backwards');


		if (this.props.persistedState?.columns) {
			let persistedColumns = this.props.persistedState.columns;
			for (const column of gridColumns) {
				if(persistedColumns[column.field]) {
					column.width = persistedColumns[column.field].width;
				}
			}
			gridColumns = Utils.rearrangeColumns(gridColumns, persistedColumns);
		}

		let staticFieldsType = {
			monthToDate: {
				type: 'number'
			},
			monthlyBudget: {
				type: 'number'
			},
			monthlyEstimate: {
				type: 'number'
			},
			yearToDate: {
				type: 'number'
			},
			yearlyEstimate: {
				type: 'number'
			},
		};

		let dynamicFieldsType = {};
		for (let column of dynamicColumns) {
			dynamicFieldsType[column] = {
				type: 'number'
			}
		}

		let fieldsType = {...staticFieldsType, ...dynamicFieldsType};

		const gridDataSource =  new kendo.ceeview.DataSource({
			data: gridRows,
			schema: {
				model: {
					fields: fieldsType
				}
			}
		});

		gridColumns = hideColumns(this.props.config.columns, gridColumns);

		this.setState({
			gridColumns,
			gridDataSource,
			loaded: true,
			loading: false
		})
	}

	componentDidUpdate() {
		this.attachEditBudgetListener();
		this.applyExpand();
		this.fixSearch();
	}

	onGridDataBound = (e) => {
		onGridDataBound(e);
		let wrapper = e.sender.wrapper;
		if (wrapper) {
			wrapper.find('.k-grid-filter .k-i-filter').removeClass('k-i-filter').addClass('k-i-arrowhead-s');
			if (!wrapper.find('.edit-budget-container').length) {
				wrapper.find('.non-editable-budget').removeClass('non-editable-budget');
			}
		}
	}

	onRowClicked = async (dataItem, e) => {
		this.reasonsWindowId = Utils.guid();
		let accountId = this.props.config.accountId;
		showReasonsGrid(dataItem, e, 'Cost HI', this.reasonsWindowId, accountId);
	}

	onGridFilter = () => {
		this.attachEditBudgetListener();
	}

	onFilterReset() {
		this.setState({
			searchFilter: {},
			gridSort: [],
			searchValue: ''
		});
	}

	formatNumber = value => {
		return formatNumber(value, this.props.config.decimalsNumber)
	}

	formatBudgetColumn = item => {
		return formatBudgetColumn(item, this.props);
	}

	getStaticGridColumns() {
		let columns = [];
		if(this.props.config.showHiSeverity){
			columns.push({
				field: 'severity',
				title: i('Severity'),
				width: 36,
				template: item => renderSeverityTag(item.severity, item, 'hiSeverity'),
				attributes: {
					style: 'text-align: right'
				}
			});
		}

		if(this.props.config.showBudgetSeverity){
			columns.push({
				field: 'budgetSeverity',
				title: i('Budget Severity'),
				width: 36,
				template: item => item.serviceName == 'Other' ? '' : renderSeverityTag(item.budgetSeverity, item, 'budgetSeverity'),
				attributes: {
					style: 'text-align: right'
				}
			});
		}

		columns = columns.concat([{
			field: 'resourceName',
			title: getResourceTypeLabel(this.props.config.resourceType),
			width: 100,
			template: item => item.resourceName,
			attributes: {
				class: 'to_expand'
			}
		}, {
			field: 'assetName',
			title: i('Asset'),
			width: 150,
			template: item => `<a class="cw_grid_link" ${this.props.navigator.renderLink(AssetsRouter.details(item.assetId))}>${item.assetName}</a>`,
			attributes: {
				class: 'to_expand'
			}
		},{
			field: 'cloudBudget',
			title: i('Cloud Budget'),
			width: 100,
			template: item => this.formatNumber(item.cloudBudget),
			attributes: {
				style: 'text-align: right'
			}
		},{
			field: 'spent',
			title: i('Spent'),
			width: 100,
			template: item => this.formatNumber(item.spent),
			attributes: {
				style: 'text-align: right'
			}
		},{
			field: 'spentPercent',
			title: i('Spent %'),
			width: 100,
			template: item => this.formatNumber(item.spentPercent),
			attributes: {
				style: 'text-align: right'
			}
		}, {
			field: 'cloudTypeText',
			title: i('Cloud Provider'),
			width: 150,
			attributes: {
				class: 'to_expand'
			}
		}, {
			field: 'subscription',
			title: i('Subscription'),
			width: 150,
			attributes: {
				class: 'to_expand'
			}
		}, {
			field: 'monthToDate',
			title: i('Month to date'),
			width: 100,
			template: item => this.formatNumber(item.monthToDate),
			attributes: {
				style: 'text-align: right'
			}
		},{
			field: 'monthlyEstimate',
			title: i('Monthly Estimate'),
			width: 100,
			template: item => this.formatNumber(item.monthlyEstimate),
			attributes: {
				style: 'text-align: right'
			}
		}])

		columns = columns.concat([{
			field: 'monthlyBudget',
			title: i('Monthly Budget'),
			template: item => this.formatBudgetColumn(item),
			width: 100,
			attributes: {
				style: 'text-align: right'
			}
		},{
			field: 'yearToDate',
			title: i('Year to date'),
			width: 100,
			template: item => this.formatNumber(item.yearToDate),
			attributes: {
				style: 'text-align: right'
			}
		},{
			field: 'yearlyEstimate',
			title: i('Yearly estimate'),
			width: 100,
			template: item => this.formatNumber(item.yearlyEstimate),
			attributes: {
				style: 'text-align: right'
			}
		}]);

		return columns;
	}

	applyExpand() {
		let scope = this;
		this.grid?.kendoGrid.wrapper.find('.k-grid-content table').off().on('click', function(e) {
			let selectedRow = $(e.target).closest('tr');
			let directCheck = true;
			scope.grid.checkExpanded(selectedRow, directCheck);
		})
	}

	fixSearch() {
		//workaround for search interfering with potential text shape
		this.grid?.kendoGrid.wrapper.closest('g').find('.ant-input').on('click', function() {
			$(this).blur();
			$(this).focus();
		})
	}

	attachEditBudgetListener() {
		this.grid?.kendoGrid.wrapper.find('.edit-budget-container').off().on('click', async e => {
			const saved = await editBudget(this.grid.kendoGrid.dataSource, e,
				this.props.config.monitorId, this.props.config.cloudType);

			if(saved){
				this.throttledUpdate.flush();
			}
		});
	}

	async loadData() {
		return await CloudBillingMonitorsApi.getCost(this.props.config);
	}

	onResize() {
		let wrapper = this.grid?.kendoGrid.wrapper;
		let gridContainerHeight = wrapper.closest('.section__content').height();
		this.setState({
			gridHeight: gridContainerHeight
		});
	}

	getStateForSaving() {
		return {
			...getGridStateForSaving(this.grid?.kendoGrid),
			searchValue: this.state.searchValue
		};
	}
}
