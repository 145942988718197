import {
	isMxStyleChange,
	MxCell,
	MxEvent,
	MxGraphModel,
	MxGraphModelChangeEventObject
} from "controls/designer/mxGraphInterfaces";
import {makeAutoObservable} from "mobx";
import {DesignerStore} from "controls/designer/designerStore";

export class MxGraphCellTracker {
	styles: Record<string, string> = {}

	constructor(public designerStore: DesignerStore, public cell: MxCell) {
		designerStore.graph.getModel().addListener(MxEvent.CHANGE, this.updateStyles);

		this.styles = designerStore.graph.getCellStyle(cell)

		makeAutoObservable(this)
	}

	updateStyles = (sender: MxGraphModel, ev: MxGraphModelChangeEventObject) => {
		for (const change of ev.properties.changes) {
			if (isMxStyleChange(change)) {
				if (change.cell != this.cell)
					return

				this.styles = this.designerStore.graph.getCellStyle(this.cell)
			}
		}
	}

	destroy() {
		this.designerStore.graph.getModel().removeListener(this.updateStyles)
	}
}
