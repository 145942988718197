import {LocalEventsManager} from "core";
import {
	getCommonFieldValues,
	getInOriginal,
	getOriginal,
	getQualifierFieldValue,
	getQualifierFieldValues,
	getQualifierId,
	getQualifierInfo,
	getTemplateId,
	getFields,
	getAssetForm,
	getServiceId
} from "./reduxReducer";
import {isEqual} from "lodash";
import {Utils} from 'tools';
import {itemToForm, preparedProperties} from "./reducers/setQualifierData";
import QualifierAPI from "tools/apis/qualifierAPI";
import {generateAction, SET_QUALIFIER_DATA} from "./reduxActions";
import qualifierCache from 'areas/services/designer/qualifierCache';

export class QualifierSaver {
	constructor(store, cell, isCreateMode, isShared) {
		this.cell = cell;
		this.isCreateMode = isCreateMode;
		this.isShared = isShared;

		const state = store.getState();

		this.store = store;

		this.getQualifierFieldValues = getQualifierFieldValues.bind(this, state);
		this.getQualifierFieldValue = getQualifierFieldValue.bind(this, state);
		this.getQualifierId = getQualifierId.bind(this, state);
		this.getInOriginal = getInOriginal.bind(this, state);
		this.getTemplateId = getTemplateId.bind(this, state);
		this.getQualifierInfo = getQualifierInfo.bind(this, state);
		this.getAssetForm = getAssetForm.bind(this, state);
		this.getServiceId = getServiceId.bind(this, state);
	}

	getTemplateValues(targets) {
		const {warning, ...currentValues} = this.getQualifierFieldValues();

		return {
			...currentValues,
			assetId: targets.assetId,
			targetId: targets.targetId
		}
	}

	getTemplateWarning() {
		const {warning} = this.getQualifierFieldValues();

		return warning;
	}

	updateTemplateProperties(properties, targets) {
		const info = this.getQualifierInfo().toJS();
		const finalInfo = this.mergeInfo(info, targets);

		this.updateProperties(properties, finalInfo);
	}

	updateProperties(properties, finalInfo) {
		for (const property of properties) {
			if (property.properties) {
				this.updateProperties(property.properties, finalInfo);
			} else {
				const infoItem = finalInfo[property.id];

				property.value = infoItem.value;
				property.text = infoItem.text;
			}
		}
	}

	async save() {
		const assetForm = this.getAssetForm();

		if (assetForm && assetForm.asset && assetForm.asset.selectedAssetTargets) {
			const serviceId = this.getServiceId();
			const templateId = this.getTemplateId();
			const qualifierId = this.getQualifierId();

			this.templates = [];
			this.assetTargets = [];

			for (const [key, item] of Object.entries(assetForm.asset.selectedAssetTargets)) {
				let data;
				this.assetTargets.push(item);

				if (this.isCreateMode) {
					({data} = await QualifierAPI.loadTemplate(serviceId, templateId));
				} else {
					({data} = await QualifierAPI.copyQualifier(serviceId, qualifierId));
				}

				this.templates.push(data);

				data.configuration.values = this.getTemplateValues(item);
				data.configuration.warning = this.getTemplateWarning();

				this.updateTemplateProperties(data.configuration.properties, item);

				data = this.addAssetName(data, item);

				this.store.dispatch(generateAction(SET_QUALIFIER_DATA, {data}));
			}
		}

		this.performSaving();
	}

	addAssetName(data, item) {
		let assetId = data.configuration.values.assetId;
		if (assetId === item.assetId) {
			data.name += ' - ' + item.assetName;
			data.configuration.configurationName += ' - ' + item.assetName;
			data.configuration.values.name += ' - ' + item.assetName;
		}
		return data;
	}

	triggerStoreEvent(accountId, agentId, assetId, assetAccountId) {
		LocalEventsManager.trigger('store_qualifier_settings', {
			settings: {
				accountId, agentId, assetId, assetAccountId
			}
		});
	}

	storeQualifierSettings() {
		this.triggerStoreEvent(
			this.getQualifierFieldValue('accountId'),
			this.getQualifierFieldValue('agentId'),
			this.getQualifierFieldValue('assetId'),
			this.getQualifierFieldValue('assetAccountId')
		)

		if (this.templates) {
			for (const template of this.templates) {
				const {configuration: values} = template;

				this.triggerStoreEvent(
					values.accountId,
					values.agentId,
					values.assetId,
					values.assetAccountId
				)
			}
		}
	}

	getConfigFromCurrent() {
		const {warning, ...values} = this.getQualifierFieldValues();

		return {
			id: this.getQualifierId(),
			className: this.getInOriginal('className'),
			name: values.name,
			description: values.description,
			agentId: values.agentId,
			configuration: {
				...this.getInOriginal('configuration'),
				values,
				warning
			},
			shared: this.getInOriginal('shared'),
			state: "ACTIVE",
			templateId: this.getTemplateId()
		};
	}

	createConfigFromTemplate(current, template, targets) {
		const {id, className, shared, templateId} = template;
		const {values, warning} = current.configuration;
		const name = template.name;

		return {
			id,
			className,
			name: name,
			description: values.description,
			agentId: values.agentId,
			configuration: {
				...current.configuration,
				values: {
					...values,
					assetId: targets.assetId,
					targetId: targets.targetId,
					name: template.name
				},
				warning
			},
			shared,
			state: "ACTIVE",
			templateId
		};
	}

	mergeInfo(info, targets) {
		return {
			...info,
			assetId: {
				text: targets.assetName,
				value: targets.assetId
			},
			targetId: {
				text: targets.targetName,
				value: targets.targetId
			}
		}
	}

	createInfo(template, targets) {
		const {configuration: {forms, properties}} = template;

		const {info} = preparedProperties(properties, forms);

		return this.mergeInfo(info, targets);
	}

	performSaving() {
		this.storeQualifierSettings();

		const configuration = this.getInOriginal('configuration');

		this.updateQualifier(this.getConfigFromCurrent(), configuration.properties, this.getQualifierInfo().toJS());

		if (this.assetTargets) {
			for (const [index, targets] of this.assetTargets.entries()) {
				const template = this.templates[index];
				const {configuration: {properties}} = template;

				this.updateQualifier(this.createConfigFromTemplate(this.getConfigFromCurrent(), template, targets), properties, this.createInfo(template, targets));
			}
		}
	}

	updateQualifier(config, properties, info) {
		const {qualifiers} = this.cell.customData;

		let found = false;

		for (let i = 0; i < qualifiers.length; i++) {
			const qualifier = qualifiers[i];

			if (qualifier.id === config.id) {
				found = true;
				qualifiers[i] = config;
				break;
			}
		}

		if (!found) {
			qualifiers.push(config);
		}

		qualifierCache.cache[config.id] = config;

		this.prepareDataForDetails(config, properties, info);

		$(document).trigger('qualifier_added.sd', [this.cell, config]);
	}

	convertPropertiesToFields(properties, out = {}) {
		for (const property of properties) {
			if (property.properties) {
				this.convertPropertiesToFields(property.properties, out);
			} else {
				out[property.id] = property;
			}
		}

		return out;
	}

	prepareDataForDetails(config, properties, info) {
		if (!this.cell.customData.sqData) {
			this.cell.customData.sqData = {};
		}

		const {sqData} = this.cell.customData;
		const {id, className} = config;

		const data = {
			id,
			className,
			dirty: true
		};

		sqData[id] = data;

		data.fields = this.convertPropertiesToFields(properties);

		const {warning, values} = config.configuration;

		const details = {
			warning
		};

		const templateValues = {};

		data.details = details;
		data.templateValues = templateValues;

		for (const field of Object.keys(data.fields)) {
			if (info[field] !== undefined) {
				if (values[field] !== undefined) {
					if (isEqual(info[field].value, values[field])) {
						details[field] = {...info[field]}
						templateValues[field] = info[field].value;
					} else {
						details[field] = {value: values[field], text: values[field]};
						templateValues[field] = values[field];
					}
				} else {
					details[field] = {...info[field]};
					templateValues[field] = info[field].value;
				}
			}
		}

		$(document).trigger(`qualifier_info_updated::${id}`, data);
	}
}

export async function loadQualifierDetails(url) {
	const {result} = await Utils.ajaxPromise(url, 'GET', {});
	const staticProperties = {
		configurationName: result.data.configuration.configurationName,
		configurationDescription: result.data.configuration.configurationDescription
	};
	const {properties, forms: originalForms, warning, values} = result.data.configuration;
	const {plain: fields, info: details} = preparedProperties(properties, itemToForm(originalForms));

	return {
		id: result.data.id,
		className: result.data.className,
		fields,
		details: {
			warning,
			...details
		},
		templateValues: values,
		staticProperties
	}
}
