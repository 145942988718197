import {makeAutoObservable, toJS, trace} from "mobx"
import {ListOnItemsRenderedProps, VariableSizeList} from "react-window"
import React from "react"
import {deserialize, serialize} from "serializr"
import {debounce} from "lodash";

import {linkModel, MobxManager} from "framework/mobx-integration"
import {GridState} from "controls/grid/gridState"
import {GridConfiguration, GridPlugin} from "controls/grid/gridConfiguration"
import {GridSelection} from "controls/grid/gridSelection"
import {GridDataEntry} from "controls/grid/gridDataEntry"
import {ColumnsManager} from "controls/grid/columnsManager"
import {AutoWidthPlugin} from "controls/grid/plugins/autoWidthPlugin"
import {
	GroupConjunction,
	RuleConfigurationBuilder,
	RuleDefinition,
	RuleDefinitionType
} from "controls/queryBuilder/ruleDefinition"
import {canApplyStringFilter} from "controls/queryBuilder/utils"
import {RemoteDataProvider} from "controls/grid/remoteDataProvider"
import {ArrayDataProvider} from "controls/grid/arrayDataProvider"
import {GridStateProvider} from "controls/grid/stateProviders"
import {copyViaSerializr} from "framework/serializr-integration"
import {GridViewState} from "controls/grid/gridViewState"
import {ApiRequest} from "framework/api";
import {GridDataItem} from "controls/grid/gridDataItem";
import {PlainResponseRemoteDataProvider} from "./plainResponseRemoteDataProvider";


export class GridStore<DataEntry extends GridDataEntry> {
	config: GridConfiguration<DataEntry>

	width: number

	state: GridState<DataEntry>

	selection: GridSelection<DataEntry>

	plugins: GridPlugin<DataEntry>[] = []

	headerRef = React.createRef<HTMLDivElement>()
	listRef: HTMLElement
	forceSyncListPositionWithHeader = false

	columns: ColumnsManager<DataEntry>

	initializationCallbacks: (() => boolean)[] = []

	selfInitialized: boolean = false

	listControl: VariableSizeList<GridStore<DataEntry>>
	rowsCustomHeight: Record<string, number> = {}

	//this property is used to destroy layout when <Grid/> component receives a new store
	initialRenderHappened: boolean = false

	private stateProvider: GridStateProvider<DataEntry>

	mobx = new MobxManager()

	constructor(config: GridConfiguration<DataEntry>) {
		this.config = config

		makeAutoObservable(this, {})

		this.init()
	}

	async init() {
		this.selection = new GridSelection(this)
		this.plugins = this.config.plugins ? [...this.config.plugins] : []

		if (this.config.autoWidth !== false) {
			this.plugins.push(new AutoWidthPlugin())
		}

		this.plugins.forEach(x => x.attach(this))

		this.columns = new ColumnsManager<DataEntry>(this)

		await this.initState()

		await this.dataProvider.attach(this)

		this.plugins.forEach(x => x.afterStoreInitialized?.())

		this.selfInitialized = true
	}

	_height: number
	get height() {
		if(this.config.heightByContent !== true)
			return this._height

		const defaultRowHeight = this.config.customization?.styling?.defaultRowHeight ?? 30;
		const customHeights = Object.values(this.rowsCustomHeight)
		let height = (this.dataProvider.data.length - customHeights.length) * defaultRowHeight
			+ customHeights.reduce((total, height) => total + (height == -1 ? defaultRowHeight : height), 0);
		// magic fix to increase height if exists horizontal scrollbar.
		if (this.width < this.columns.visibleWidth + 22) {
			height += 20;
		}
		return height
	}

	set height(value: number) {
		this._height = value
	}

	get dataProvider() {
		return this.config.dataProvider
	}

	get arrayDataProvider() {
		//@ts-ignore
		return this.dataProvider as ArrayDataProvider<DataEntry>
	}

	get remoteDataProvider() {
		return this.dataProvider as RemoteDataProvider<DataEntry>
	}

	get plainResponseRemoteDataProvider() {
		return this.dataProvider as PlainResponseRemoteDataProvider<DataEntry>
	}

	get initialized() {
		return this.selfInitialized && this.initializationCallbacks.every(x => x())
	}

	get filtered() {
		return !!this.state?.searchString || Object.keys(this.state?.filters.children1 ?? {}).length > 0
	}

	get groupByEnabled(){
		return this.dataProvider.groupBySupported && this.config.groupBy !== false
	}

	get filtersConfigurationEffective() {
		let result = toJS(this.dataProvider.filtersConfiguration)

		const builder = RuleConfigurationBuilder.create<DataEntry>(result)

		this.columns.config.forEach(x => {
			if (x.filterDropDownRenderer && result[x.field]) {
				result[x.field].customMultiSelectRenderer = x.filterDropDownRenderer
			}

			if(x.filterable == 'string'){
				builder.addText(x.field as keyof DataEntry, x.title)
			}

			if(x.filterable == 'number'){
				builder.addNumber(x.field as keyof DataEntry, x.title)
			}
		})

		return builder.build()
	}

	get customFiltering() {
		const f = this.state.filters

		const fieldsCount: Record<string, boolean> = {}

		return Object.values(f.children1).some(x => {
			if (!x.properties.field)
				return true

			if (x.type == RuleDefinitionType.Group)
				return true

			if (fieldsCount[x.properties.field]) {
				return true
			}

			fieldsCount[x.properties.field] = true
		})
	}

	async loadState() {
		for (const stateProvider of this.config.stateProviders ?? []) {
			const state = await stateProvider.getState(this)

			if (state) {
				state.validate(this)
				this.stateProvider = stateProvider
				return state
			}
		}

		return null
	}

	async initState(){
		this.state = await this.loadState()

		if (!this.state) {
			this.state = new GridState<DataEntry>()
			this.state.ensureDefaultViewExists()
			this.state.views[0].resetToDefault(this)
		}

		this.state.createViewsSnapshot()

		this.mobx.reaction(() => serialize(GridViewState<DataEntry>, this.state.defaultView), this.flushCurrentViewChangesDebounced )
		this.mobx.reaction(() => ({
				initialViewId: this.state.initialViewId,
				deletedViewCount: this.state.deletedViews.length
			}),
			this.flushOtherChangesDebounced
		)
	}

	clearFilters = () => {
		this.state.searchString = ''
		this.state.filters = RuleDefinition.emptyGroup()
		this.config.onFiltersCleared?.(this)
	}

	itemsRendered = (props: ListOnItemsRenderedProps) => {
		this.dataProvider.informVisibleRangeChanged(props.overscanStartIndex, props.overscanStopIndex)

		if (this.forceSyncListPositionWithHeader) {
			this.syncListPositionWithHeader()
			this.forceSyncListPositionWithHeader = false
		}
	}

	get actualFilter() {
		let filters = this.state.filters

		if (this.state.searchString) {
			filters = deserialize(RuleDefinition, serialize(filters))

			let searchStringGroup = filters.addEmptyGroup()
			searchStringGroup.properties.conjunction = GroupConjunction.Or

			for (let field of Object.keys(this.filtersConfigurationEffective)) {
				if (!canApplyStringFilter(this.filtersConfigurationEffective, field))
					continue

				let rule = searchStringGroup.addEmptyRule()
				rule.properties.value[0] = this.state.searchString
				rule.properties.field = field
				rule.properties.operator = 'like'
			}
		}

		return filters
	}

	get actualSorting() {
		return this.state.sortingOrder
			.map(field => this.state.columns.find(c => c.field == field))
			.filter(x => x?.sorting != null)
			.map(x => ({
				field: x.field,
				dir: x.sorting
			}))
	}

	setListRef = (ref: HTMLDivElement) => {
		if (this.listRef) {
			this.listRef.removeEventListener('scroll', this.listScrolled)
		}

		this.listRef = ref?.parentElement

		if (this.listRef) {
			this.listRef.addEventListener('scroll', this.listScrolled)
		}
	}

	listScrolled = () => {
		const {scrollLeft} = this.listRef

		if (this.headerRef.current) {
			this.headerRef.current.style.marginLeft = -scrollLeft + 'px'
		}
	}

	syncListPositionWithHeader(){
		let marginLeft = parseInt(this.headerRef.current?.style.marginLeft)
		if(isNaN(marginLeft))
			return

		if(this.listRef){
			this.listRef.scrollLeft = -marginLeft
		}
	}

	getSelectionApiRequest<T = any>(args: { url: string, payload?: Record<string, any>, selection?: GridSelection<DataEntry> }) {
		if (!(this.dataProvider instanceof RemoteDataProvider)) {
			console.warn('Selection api request works only with Remote data provider')
			return
		}

		let request = this.dataProvider.getBaseApiRequest() as unknown as ApiRequest<T>
		request.payload.selection = {
			mode: args.selection?.mode ?? this.selection.mode,
			ids: args.selection?.ids ?? this.selection.ids
		}

		delete request.responseType
		delete request.deserializationCallback
		delete request.responseTypeArray
		delete request.payload.sort

		request.url = args.url

		if (args.payload) {
			Object.assign(request.payload, args.payload)
		}

		return request
	}

	registerInitializationDoneSource(done: () => boolean) {
		this.initializationCallbacks.push(done)
	}

	updateRowHeight(item: GridDataItem<DataEntry>, index: number, element: HTMLDivElement) {
		if (this.rowsCustomHeight[item.id] != -1)
			return

		this.rowsCustomHeight[item.id] = element.getBoundingClientRect().height
		this.listControl?.resetAfterIndex(index)
	}

	getRowHeight(index: number) {
		const item = this.dataProvider.get(index)
		const defaultHeight = this.config.customization?.styling?.defaultRowHeight ?? 30;
		if (item && this.rowsCustomHeight[item.id]) {
			const height = this.rowsCustomHeight[item.id]
			return height == -1 || !height ? defaultHeight : height //when we click on a row we remove height constraint and put -1 as a custom vaule.
			//then on a resize event we put a real row height there and use it later. But it might be that a real height row == 30 so resize event
			//is not triggered and value remains -1. So on the next rerender the list component will calculate wrong offset
		}
		return defaultHeight
	}

	flushCurrentViewChanges = async () => {
		await this.flushStateChanges((actualState) => {
			const view = copyViaSerializr<GridViewState<DataEntry>>(this.state.currentView)
			const index = actualState.views.findIndex(x => x.id == view.id)
			if (index == -1) {
				actualState.views.push(view)
			} else {
				actualState.views[index] = view
			}

			actualState.initialViewId = this.state.initialViewId

			this.state.currentView.calculateHash()
		})
	}

	flushCurrentViewChangesDebounced = debounce(this.flushCurrentViewChanges, 1000)

	flushOtherChanges = async () => {
		await this.flushStateChanges((actualState) => {
			actualState.initialViewId = this.state.currentViewId
		})
	}

	flushOtherChangesDebounced = debounce(this.flushOtherChanges, 1000)

	flushStateChanges = async (callback: (state: GridState<DataEntry>) => void) => {
		if(!this.stateProvider)
			return

		let actualState = await this.stateProvider.getState(this)

		this.state.deletedViews.forEach(id => {
			let index = actualState.views.findIndex(v => v.id == id)
			if(index != -1){
				actualState.views.splice(index, 1)
			}
		})

		callback(actualState)

		await this.stateProvider.saveState(actualState, this)
	}

	isSelectionDisabled(item: GridDataItem<DataEntry>): {disabled: boolean, reason?: string} {
		let callbackResult = this.config.customization?.isSelectionDisabled?.(item, this)
		if(!callbackResult){
			return {
				disabled: false
			}
		}

		if(callbackResult === true){
			return {
				disabled: callbackResult
			}
		}

		return callbackResult
	}

	destroy() {
		this.mobx.destroy()
		this.plugins.forEach(x => x.destroy && x.destroy())
		this.state?.destroy()
		this.dataProvider?.destroy()
		this.columns?.destroy()
	}
}

export type ApiRequestPayload = {
	[p: string]: any,
	filter: RuleDefinition,
	sort: {field: string, dir: "asc" | "desc"}[]
}

export function linkGridAdditionalPayload(holder: GridStoreHolder<any>, name: string){
	const store = getStore(holder)
	return linkModel(store.state.customPayload, name)
}

export type GridStoreHolder<T extends GridDataEntry> = GridStore<T> | {gridStore: GridStore<T>}
export function getStore<T extends GridDataEntry>(holder: GridStoreHolder<T>) {
	if (holder == null)
		return null

	return 'gridStore' in holder ? holder.gridStore : holder
}
