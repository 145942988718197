import './antSpin.less'

import React, {ChangeEventHandler} from "react"
import classnames from 'classnames'
import { observer } from 'mobx-react'
import {Spin, SpinProps} from 'antd'


interface AntSwitchProps extends SpinProps{
	centered?: boolean
}

const b = require('b_').with('ant-spin-wrapper')

export const AntSpin = observer( (props: AntSwitchProps) => {
	let {centered, className, ...antNativeProps} = props

	const classes = classnames(b({'centered': centered}), className)

	return <div className={classes}><Spin {...antNativeProps}/></div>
})
