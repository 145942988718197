import './thresholdWindow.less'

import React from "react";
import {CostBudgetItem} from "../models/costBudgetItem";
import {Window} from "../../../controls/react/kendoWrappers";
import {observer} from 'mobx-react';
import {
	CostThreshold,
	costThresholdPeriods,
	CostThresholds,
	costThresholdTypes,
	CostThresholdValue
} from "../models/costThreshold";
import {action, computed, makeObservable, observable} from 'mobx';
import {getSeverityDataList, Severity} from "../../../framework/entities/healthData";
import {AntOption, AntSelect, AntSelectProps, AntSelectValue} from "../../../controls/react/ant/antSelect";
import {SeverityLabel} from "../../../controls/react/severityIndicator";
import {i} from "../translations";
import {Section} from 'controls/react/layout/section';
import {KeysMatching} from 'framework/typescript-integration';
import {Toolbar} from "../../../controls/react/layout";
import b_ from 'b_';
import {AntSwitch} from "../../../controls/react/ant/antSwitch";
import {ActionButtons, FormEntry} from "../../../controls/react/form";
import {capitalize} from 'lodash';
import {AntInputNumber} from "../../../controls/react/ant/antInputNumber";
import {getCostAlarm} from "../api";
import {apiFetch} from "../../../framework/api";
import {CostAlarm, CostAlarmValue} from "../models/costAlarm";
import {BoxView} from "../../../controls/react/layout/boxView";
import {MobxManager} from "../../../framework/mobx-integration";
import {DataListEntry} from "../../../framework/entities/dataList";
import {deserialize, serialize } from 'serializr';

const b = b_.with('cost-threshold-window')

interface ThresholdWindowProps {
	record: CostBudgetItem,
	onClose: () => void,
	currency: string,
	readonly?: boolean
}

class ThresholdWindowStore {
	record: CostBudgetItem
	costAlarm: CostAlarm
	initialSerializedCostAlarm: string
	thresholds: CostThresholds
	loading: boolean
	mode: 'update' | 'create'
	onSave: () => void
	currency: string
	showRelationValidation: boolean = false
	static defaultSeverities = {
		warning: Severity.Major,
		critical: Severity.Critical,
	}

	mobx = new MobxManager()


	constructor(record: CostBudgetItem, onSave: () => void, currency: string) {
		makeObservable(this, {
			thresholds: observable,
			loading: observable,
			mode: observable,
			onSave: observable,
			showRelationValidation: observable,
			setValue: action,
			setPeriodEnabled: action,
			setRecord: action,
			setOnSave: action,
			setCurrency: action,
			fullSeveritiesList: computed
		})

		this.setRecord(record)
		this.setOnSave(onSave)
		this.setCurrency(currency)
		this.setupReactions()
	}

	setValue = <K extends keyof CostThresholdValue, V extends CostThresholdValue[K]>(
		period: KeysMatching<CostThresholds, CostThreshold>,
		threshold: KeysMatching<CostThreshold, CostThresholdValue>,
		key: K,
		value: V
	) => {
		this.thresholds[period][threshold][key] = value
	}

	setThresholdEnabled = (
		period: KeysMatching<CostThresholds, CostThreshold>,
		threshold: KeysMatching<CostThreshold, CostThresholdValue>,
		value: boolean
	) => {
		this.thresholds[period][threshold].enabled = value
		if(!value) {
			this.thresholds[period][threshold].value = null
			this.thresholds[period][threshold].severity = ThresholdWindowStore.defaultSeverities[threshold]
		}
	}

	setPeriodEnabled(period: KeysMatching<CostThresholds, CostThreshold>, value: boolean) {
		this.thresholds[period].enabled = value
		costThresholdTypes.forEach(x => this.setThresholdEnabled(period, x, value))
	}

	async setRecord(record: CostBudgetItem) {
		this.mode = 'update'
		this.record = record
		if(record.costAlarm) {
			this.costAlarm = deserialize(CostAlarm, serialize(CostAlarm, record.costAlarm))
			this.initialSerializedCostAlarm = JSON.stringify(serialize(CostAlarm, this.costAlarm))
			this.thresholds = ThresholdWindowStore.alarmToThresholds(this.costAlarm)
			return
		}
		if(record.costAlarmId) {
			this.loading = true
			const alarm = await apiFetch(getCostAlarm(record.costAlarmId))
			this.costAlarm = alarm.data
			this.initialSerializedCostAlarm = JSON.stringify(serialize(CostAlarm, this.costAlarm))
			this.thresholds = ThresholdWindowStore.alarmToThresholds(this.costAlarm)
			this.loading = false
			return
		}
		// costAlert is not defined
		this.mode = 'create'
		this.thresholds = ThresholdWindowStore.defaultThresholds()
	}

	setOnSave(onSave: () => void) {
		this.onSave = onSave
	}

	setCurrency(currency: string) {
		this.currency = currency
	}

	setupReactions() {
		costThresholdPeriods.forEach(period => {
			costThresholdTypes.forEach(type => {
				this.mobx.reaction(() => this.severitiesList(period, type), (list) => {
					if (!this.thresholds) {
						return
					}
					const value = this.thresholds[period][type]
					const index = list.findIndex(x => x.id == value.severity)
					if(index < 0 && value.enabled) {
						value.severity = list[0].id
					}
				})
			})
		})
	}

	get valid() {
		return costThresholdPeriods.every(p => this.thresholds?.[p].valid)
	}

	static alarmToThresholds(alarm: CostAlarm) {
		const thresholds = ThresholdWindowStore.defaultThresholds()
		costThresholdPeriods.forEach(period => {
			const warning = alarm[`${period}LowThreshold` as keyof CostAlarm] as CostAlarmValue;
			const critical = alarm[`${period}HighThreshold` as keyof CostAlarm] as CostAlarmValue;
			thresholds[period].enabled = !!(warning || critical)
			if (warning) {
				thresholds[period].warning = new CostThresholdValue({
					enabled: true,
					value: warning.threshold,
					severity: warning.severity
				})
			}
			if (critical) {
				thresholds[period].critical = new CostThresholdValue({
					enabled: true,
					value: critical.threshold,
					severity: critical.severity
				})
			}
		});
		return thresholds;
	}

	get fullSeveritiesList() {
		return getSeverityDataList()
	}

	severitiesList = (period: KeysMatching<CostThresholds, CostThreshold>, type: KeysMatching<CostThreshold, CostThresholdValue>) => {
		if (!this.thresholds) {
			return this.fullSeveritiesList;
		}
		const threshold = this.thresholds[period];
		switch(type) {
			case 'warning':
				if(threshold.critical.enabled) {
					return this.fullSeveritiesList.filter(x => x.id != Severity.Critical)
				}
				return this.fullSeveritiesList
			case 'critical':
				const warningSeverityIndex = this.fullSeveritiesList.findIndex(x => x.id == threshold.warning.severity)
				return this.fullSeveritiesList.slice(warningSeverityIndex + 1)
		}

	}

	static costAlarmKey(period: KeysMatching<CostThresholds, CostThreshold>, threshold: KeysMatching<CostThreshold, CostThresholdValue>) : KeysMatching<CostAlarm, CostAlarmValue> {
		const thresholdKey = {warning: 'Low', critical: 'High'}[threshold] as 'Low' | 'High';
		return `${period}${thresholdKey}Threshold`
	}

	static thresholdsToAlarm(thresholds: CostThresholds, initialAlarm: CostAlarm) {
		const alarm = initialAlarm || new CostAlarm();
		costThresholdPeriods.forEach(period => {
			const threshold = thresholds[period];
			if(!threshold.enabled) {
				costThresholdTypes.forEach(type => {
					alarm[ThresholdWindowStore.costAlarmKey(period, type)] = null
				})
				return
			}

			costThresholdTypes.forEach(type => {
				if(threshold[type].enabled) {
					alarm[ThresholdWindowStore.costAlarmKey(period, type)] = {
						threshold: threshold[type].value,
						severity: threshold[type].severity
					}
				} else {
					alarm[ThresholdWindowStore.costAlarmKey(period, type)] = null
				}
			})
		})
		return alarm;
	}

	destroy() {
		this.mobx.destroy()
	}

	save = () => {
		if(costThresholdPeriods.some(p => !this.thresholds?.[p].validRelation)) {
			this.showRelationValidation = true
			this.mobx.when(() => costThresholdPeriods.every(p => this.thresholds?.[p].validRelation), () => {
				this.showRelationValidation = false
			})
			return
		}
		this.costAlarm = ThresholdWindowStore.thresholdsToAlarm(this.thresholds, this.costAlarm)
		this.costAlarm.currency ||= this.currency

		if(JSON.stringify(serialize(CostAlarm, this.costAlarm)) != this.initialSerializedCostAlarm) {
			this.record.costAlarm = this.costAlarm
		}

		this.onSave?.();
	}

	static defaultThresholds() {
		return costThresholdPeriods.reduce((thresholds: CostThresholds, period) => {
			thresholds[period] = costThresholdTypes.reduce((threshold: CostThreshold, type) => {
				threshold[type] = new CostThresholdValue({
					enabled: false,
					value: null,
					severity: ThresholdWindowStore.defaultSeverities[type]
				})
				return threshold
			}, new CostThreshold())
			thresholds[period].enabled = false
			return thresholds
		}, {} as CostThresholds)
	}
}

const SeveritySelect = observer(<VT extends AntSelectValue>(props: AntSelectProps<VT> & {list: DataListEntry<Severity>[]}) => {
	const {list, ...rest} = props
	return <AntSelect {...rest}>
		{list.map(x =>
			<AntOption key={x.id} value={x.id}>
				<SeverityLabel severity={x.id}/>
			</AntOption>
		)}
	</AntSelect>
})

class ThresholdWindowUnobserved extends React.Component<ThresholdWindowProps> {
	store: ThresholdWindowStore

	constructor(props: ThresholdWindowProps) {
		super(props)
		this.store = new ThresholdWindowStore(props.record, props.onClose, props.currency)
	}

	componentDidUpdate(prevProps: ThresholdWindowProps) {
		if(prevProps.record != this.props.record) {
			this.store.setRecord(this.props.record)
		}

		if(prevProps.onClose != this.props.onClose) {
			this.store.setOnSave(this.props.onClose)
		}

		if(prevProps.currency != this.props.currency) {
			this.store.setCurrency(this.props.currency)
		}
	}

	componentWillUnmount() {
		this.store.destroy()
	}

	render() {
		return <Window title={`${i('Threshold')} ${this.store.record.name}`} modal onClose={this.props.onClose} titleIcon={this.titleIcon}>
			<Section childrenPadding={true}>
				{!this.store.loading && costThresholdPeriods.map(this.period)}
			</Section>
			<Toolbar>
				<ActionButtons
					showSave={this.props.readonly != true}
					mode={this.store.mode}
					onSave={this.store.save}
					onCancel={this.props.onClose}
					saveDisabled={!this.store.valid || this.store.showRelationValidation}
				/>
			</Toolbar>
		</Window>
	}

	get titleIcon() {
		return {
			class: 'question-sign',
			tooltip: i('Breach of a threshold give an Event.')
		}
	}

	period = (period: KeysMatching<CostThresholds, CostThreshold>) => {
		const { readonly } = this.props;
		const periodValue = this.store.thresholds[period]
		const { validRelation } = periodValue
		const { showRelationValidation } = this.store
		return <Section key={period} appearance={'frame'} title={this.sectionTitle(period)} childrenPadding={true}>
			<Toolbar>
				<AntSwitch disabled={readonly} size={'small'} value={periodValue.enabled} onChange={(v) => this.store.setPeriodEnabled(period, v)}/>
			</Toolbar>

			<div className={b('header')}>
				<div className={b('h', {cost: true})}>{i('Cost')}</div>
				<div className={b('h', {severity: true})}>{i('Severity')}</div>
			</div>
			{costThresholdTypes.map((x) => this.threshold(period, x))}
			{showRelationValidation && !validRelation && <BoxView type="error">{i('The Warning threshold cant be equal or larger than the Critical threshold')}</BoxView>}
		</Section>
	}

	threshold = (period: KeysMatching<CostThresholds, CostThreshold>, threshold: KeysMatching<CostThreshold, CostThresholdValue>) => {
		const { readonly } = this.props;

		const periodEnabled = this.store.thresholds[period].enabled
		const value = this.store.thresholds[period][threshold]

		return <div key={threshold} className={b('entry')}>
			<FormEntry label={i(capitalize(threshold))}>{null}</FormEntry>
			<FormEntry containerClass={b('input', {cost: true})} model={value} modelField={'value'} onChange={(v) => this.store.setValue(period, threshold, 'value', v as number)}>
				<AntInputNumber addonAfter={this.store.currency} disabled={readonly || !periodEnabled || !value.enabled}/>
			</FormEntry>
			<FormEntry containerClass={b('input', {severity: true})} model={value} modelField={'severity'} onChange={(v) => this.store.setValue(period, threshold, 'severity', v as Severity)}>
				<SeveritySelect disabled={readonly || !periodEnabled || !value.enabled} list={this.store.severitiesList(period, threshold)}/>
			</FormEntry>
			<div className={b('input', {enabled: true})}>
				<AntSwitch size={'small'} value={value.enabled} disabled={readonly || !periodEnabled} onChange={(v) => this.store.setThresholdEnabled(period, threshold, v)}/>
			</div>
		</div>
	}

	sectionTitle(period: KeysMatching<CostThresholds, CostThreshold>) {
		return {
			year: i('Year'),
			month: i('Month')
		}[period]
	}
}

export const ThresholdWindow = observer(ThresholdWindowUnobserved);
