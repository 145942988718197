import React from "react";
import produce from "immer";


import {getValueOnPath} from "tools/helpers/math";

export class Store extends React.PureComponent{
	#whenCallbacks = [];

	constructor(props, contextType){
		super(props);
		this.state = {
			context: {
				change: this.changeContext
			}
		};
		this._contextType = contextType;
	}

	render(){
		let ContextProvider = this._contextType.Provider;
		return (
			<ContextProvider value={this.state.context}>
				{this.props.children}
			</ContextProvider>
		);
	}

	changeContext = (stateMutator, callback) => {
		if( this._newState != null ){
			//means that changeContext called inside changeContext
			//so we dont need new 'produce' here
			stateMutator(this._newState.context);
		}else {
			this.setState(produce(this.state, state => {
				try {
					this._newState = state;

					stateMutator(this._newState.context);

					this.#whenCallbacks = [];
					if (this.onAfterContextChanging) {
						this.onAfterContextChanging(this._newState.context);
					}
					this.#whenCallbacks.forEach(c => c(this._newState.context));

					this.#whenCallbacks = [];
					if (this.onAfterContextChanged) {
						this.onAfterContextChanged(this._newState.context);
					}
				}catch(e){
					console.error(e);
				}
				finally {
					this._newState = null
				}
			}), () => {
				this.#whenCallbacks.forEach( c => c());
				callback && callback(this.state);
			});
		}
	}

	whenChanged(propertyPaths, callback) {
		this.resolvePropertyValues(propertyPaths, (oldValue, newValue) =>{
			if( oldValue != newValue){
				this.#whenCallbacks.push(callback);
			}
		});
	}

	whenGotValue(propertyPaths, callback){
		this.resolvePropertyValues(propertyPaths, (oldValue, newValue) =>{
			if( (oldValue === null || oldValue === undefined)
				&& newValue ){
				this.#whenCallbacks.push(callback)
			}
		});
	}

	pushWhenCallback(callback) {
		this.#whenCallbacks.push(callback)
	}

	resolvePropertyValues(propertyPaths, callback){
		if( !Array.isArray(propertyPaths) ){
			propertyPaths = [propertyPaths];
		}

		for(const path of propertyPaths) {
			callback(
				getValueOnPath(this.state.context, path),
				getValueOnPath(this._newState.context, path)
			);
		}
	}

	setInitialContext(context){
		this.state.context = {
			...this.state.context,
			...context
		}

		const decoratedMethods = this.decoratedMethods || [];
		for(const methodName of decoratedMethods){
			this.state.context[methodName] = this[methodName];
		}
	}
}

export function contextAction(target, name, descriptor){
	if(target.decoratedMethods == null){
		target.decoratedMethods = [];
	}

	target.decoratedMethods.push(name);
}

export function addContextActions(target){
	target.prototype.changeContext = function(mutator, callback){
		if(this.context && this.context.change){
			this.context.change(mutator, callback);
		}
	}
}
