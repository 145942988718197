import React from "react"
import {translator} from "core";
import {Button, TextArea} from "controls/react/form";
import {Window} from 'controls/react/kendoWrappers/window';
import {ToolbarItemPosition} from 'controls/react/layout/toolbar';
import {Toolbar} from 'controls/react/layout/toolbar';
import {getTopLeftPagePosition} from 'controls/modalWindow'
import {ZIndex} from "tools/utils";

let i = translator({
  "Set information placeholder": {
    "en": "Add the text you want to be displayed in the information field. Note that this text will overwrite existing information, if present...",
    "no": "Legg til tekst du ønsker skal vises i informasjonsfeltet. Merk at det overskriver eksisterende informasjonstekst."
  },
  "Set information": {
    "no": "Sett informasjon",
    "en": "Set information"
  }
});

export class SetInformationWindow extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			information: '',
			topLeftCornerPosition: getTopLeftPagePosition()
		};
	}

	render() {
		return (
			<Window height={240}
					width={500}
					minHeight={200}
					modal
					title={i('Set information')}
					ref={window => this.window = window}
					onClose={this.props.onClose}
					top={this.props.topLeftCorner ? this.state.topLeftCornerPosition.top : undefined}
					left={this.props.topLeftCorner ? this.state.topLeftCornerPosition.left : undefined}
					zIndex={ZIndex.nextValue}>
				<TextArea containerClass='cw_set_information'
						  placeholder={i('Set information placeholder')}
						  value={this.state.information}
						  onChange={(value) => this.onInformationChange(value)}
				/>
				<Toolbar>
					<Button title={i('Update')}
							primary={true}
							onClick={() => this.onUpdate()}
							position={ToolbarItemPosition.AT_THE_END} />
					<Button title={i('Cancel')}
							primary={false}
							onClick={() => this.props.onClose()}
							position={ToolbarItemPosition.AT_THE_END} />
				</Toolbar>
			</Window>
		)
	}

	onInformationChange(value) {
		this.setState({
			information: value
		})
	}

	onUpdate() {
		this.props.onUpdate(this.state.information);
	}
}

