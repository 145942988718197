import React from "react";

import {
	BarsStatesWidget,
	summaryToDataListConverter,
	withStateDataSource
} from "controls/react/barsStatesWidget";
import {Severity} from "framework/entities/healthData";
import {AssetStateSummary} from "areas/assets/widgets/stateWidget/assetStateSummary"
import {ApplicationSeverity} from "./applicationStateWidget";

const i = require('core/localization/localization').translator({
	BarTitle: {
		en: 'Total asset in {0} state: {1}',
		no: 'Totalt antall ressurser i {0} tilstand: {1}'
	}
});

const severityMap = new Map<ApplicationSeverity, Severity>([
	[ApplicationSeverity.Critical, Severity.Critical],
	[ApplicationSeverity.Major, Severity.Major],
	[ApplicationSeverity.Minor, Severity.Minor],
	[ApplicationSeverity.None, Severity.None],
	[ApplicationSeverity.Invalid, Severity.Invalid],
]);

const summaryFieldsMap = new Map<ApplicationSeverity, (summary: AssetStateSummary, isWarning?: boolean) => number | boolean>([
	[ApplicationSeverity.Critical, (summary: AssetStateSummary, isWarning?: boolean) => isWarning ? summary.criticalFlag : summary.critical],
	[ApplicationSeverity.Major, (summary: AssetStateSummary, isWarning?: boolean) => isWarning ? summary.majorFlag : summary.major],
	[ApplicationSeverity.Minor, (summary: AssetStateSummary, isWarning?: boolean) => isWarning ? summary.minorFlag : summary.minor],
	[ApplicationSeverity.None, (summary: AssetStateSummary, isWarning?: boolean) => isWarning ? summary.okFlag : summary.ok],
	[ApplicationSeverity.Invalid, (summary: AssetStateSummary, isWarning?: boolean) => isWarning ? summary.inactiveFlag : summary.maintenance],
]);

const availableSeverities = [ApplicationSeverity.Critical, ApplicationSeverity.Major, ApplicationSeverity.Minor, ApplicationSeverity.None, ApplicationSeverity.Invalid];
const getDataList = summaryToDataListConverter(availableSeverities, severityMap, summaryFieldsMap, i);
const dataSourceGetter = (summary: AssetStateSummary, severities: ApplicationSeverity[]) => getDataList(summary, severities);

export const AssetStateWidget = withStateDataSource<AssetStateSummary, ApplicationSeverity>(BarsStatesWidget, dataSourceGetter);
