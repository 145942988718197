import "./bubbleChart.less"
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { ResourceType, sharedLocalization, subscribe, throttleLimit } from "./shared";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import { addLinks } from "core/react/links";
import { Section } from "controls/react/layout/section";
import { FormEntry } from "controls/react/form";
import { LegendPositionSelect } from "./legendPositionSelect";
import {WidgetConfigurationTemplate } from "controls/designer/features/widgets/cloudServices/widgetConfigurationTemplate";
import { sharedLocalization as widgetsSharedLocalization } from "controls/designer/features/widgets/localization";
import { CloudBillingMonitorsApi } from "areas/assets/monitors/cloud-billing/api";
import _ from 'lodash';
import { round } from "tools/helpers/math";
import Configuration from "configuration";
import { AntRadio } from "controls/react/ant/antRadio";
import { IconButton } from "controls/react/form/iconButton";
import { Alert, Tooltip, Popover } from 'antd';
import DropDownList from 'controls/react/kendoWrappers/dropDownList';
import { AntInputNumber, NumberKeysPattern } from "controls/react/ant/antInputNumber";
import {ApplicationState} from "framework/applicationState";

export function getWidgetDescription() {
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'cloud-services-buggle-chart',
			title: i('Bubble Chart'),
			accountId: ApplicationState.accountId,
			resourceType: ResourceType.CloudService,
			cloudType: 'AMAZONBILLING',
			legendPosition: 'TOP',
			legendItems: ['subscription', 'cloudService'],
			historicMonths: 'year',
			calculation: 'estimate'
		},
		widget: Widget,
		fullTitle: i('Cloud') + '/' + i('Bubble Chart'),
	}
}
const i = require('core/localization').translator({
  "Legend Settings": {
    "no": "Beskrivelse",
    "en": "Legend settings"
  },
  "Bubble chart": {
    "no": "Boble diagramm",
    "en": "Bubble chart"
  },
  "Historic months": {
    "en": "Historic months",
    "no": "historiske måneder"
  },
  "Historic months tooltip": {
    "en": "Number of historic months to display. If the field is empty the current year will be displayed.",
    "no": "Antall historiske måneder som vises. Vis feltet er tomt vil nåværende månederr vises."
  },
  "No data": {
    "no": "Ingen data"
  },
  "Custom": {
    "no": "Tilpasset"
  },
  "The input value should be between 1 and 24.": {
    "no": "Sett inn en verdi mellom 1 og 24"
  },
   "Consumed": {
    "no": "Forbruk"
  },
  "calculation tooltip": {
    "en": "Estimated gives estimate for current month + total for historic months\nConsumed give current months total (up to this date) + total for historic months",
    "no": "Estimert gir estimatet for inneværende måned + totalt for historiske måneder\nForbruk gir sum for inneværende måned (frem til denne dato) + totalt for historiske måneder"
  },
  "Number of months": {
    "no": "Antall måneder",
    "en": "Number of months"
  },
  "# months": {
    "no": "# måneder",
    "en": "# months"
  },
  "Calculation": {
    "no": "Kalkulering"
  },
  "Display Count": {
    "no": "Visningsantall",
    "en": "Display count"
  },
  "Year": {
    "no": "År"
  },
  "Month": {
    "no": "Måned"
  },
  "EstimateTooltip": {
    "en": "Show estimated cost for current selection period",
    "no": "Vis estimert kostnad for gjeldende valgperiode"
  },
  "ConsumedTooltip": {
    "en": "Show consumed total for current selection period",
    "no": "Vis totalforbruket for den nåværende valgperioden"
  },
  "YearTooltip": {
    "en": "Show cost for current year",
    "no": "Vis kostnaden for inneværende år"
  },
  "MonthTooltip": {
    "en": "Show cost for current month",
    "no": "Vis kostnad for inneværende måned"
  },
  "CustomTooltip": {
    "en": "Show current month + X historic months",
    "no": "Vis nåværende måned + X historiske måneder"
  },
  "displayCount tooltip": {
    "en": "Display count number limits number of bubbles shown, only the largest values number up to count will be displayed.",
    "no": "Visningsnummer begrenser hvor mange bobler som vises, bare de med høyest verdi opp til gitt antall blir vist."
  }
}, sharedLocalization, widgetsSharedLocalization);


const WidgetConfiguration = props => {
	const { configLink } = props;
	const historicMonthsLink = configLink.get('historicMonths');
	const historicMonthsCustomLink = configLink.get('historicMonthsCustom').check(i('The input value should be between 1 and 24.'), x => {
		if (!historicMonthsCustomLink.value || historicMonthsLink.value !== 'custom')
			return true;
		return x >= 1 && x <= 24
	})

	useEffect(() => {
		if (historicMonthsLink.value != 'custom')
			historicMonthsCustomLink.update(null)
	}, [historicMonthsLink])

	return <WidgetConfigurationTemplate {...props} hideDecimalNumbers={true}>
		<Section appearance={"frame"} title={i('Display settings')} childrenPadding={true}>
			<FormEntry label={i('Historic months')} width={'fit'}>
				<IconButton iconName={"question-sign"} title={i('Historic months tooltip')} />
				<AntRadio.Group buttonStyle={"solid"} valueLink={historicMonthsLink}>
					<Tooltip title={i('YearTooltip')} zIndex={20000}>
						<AntRadio.Button value={'year'}>{i('Year')}</AntRadio.Button>
					</Tooltip>
					<Tooltip title={i('MonthTooltip')} zIndex={20000}>
						<AntRadio.Button value={'month'}>{i('Month')}</AntRadio.Button>
					</Tooltip>
					<Tooltip title={i('CustomTooltip')} zIndex={20000}>
						<AntRadio.Button value={'custom'}>{i('Custom')}</AntRadio.Button>
					</Tooltip>
				</AntRadio.Group>
				{historicMonthsLink.value == 'custom' && <FormEntry valueLink={historicMonthsCustomLink}>
					<AntInputNumber valueLink={historicMonthsCustomLink} pattern={NumberKeysPattern.PositiveInteger}
						placeholder = {i('# months')} title={i('Number of months')}/>
				</FormEntry>}
			</FormEntry>
			<FormEntry label={i('Calculation')} width={'fit'}>
				<IconButton iconName={"question-sign"} title={i('calculation tooltip')} />
				<AntRadio.Group buttonStyle={"solid"} valueLink={configLink.get('calculation')}>
					<Tooltip title={i('EstimateTooltip')} zIndex={20000}>
						<AntRadio.Button value={'estimate'}>{i('Estimate')}</AntRadio.Button>
					</Tooltip>
					<Tooltip title={i('ConsumedTooltip')} zIndex={20000}>
						<AntRadio.Button value={'consumed'}>{i('Consumed')}</AntRadio.Button>
					</Tooltip>
				</AntRadio.Group>
			</FormEntry>
			<FormEntry label={i('Display Count')} width={'fit'}>
				<IconButton iconName={"question-sign"} title={i('displayCount tooltip')} />
				<AntInputNumber valueLink={configLink.get('displayCount')} pattern={NumberKeysPattern.PositiveInteger}/>
			</FormEntry>
		</Section>
		<Section appearance={"frame"} title={i('Legend Settings')} childrenPadding={true}>
			<FormEntry label={i('Position')} width={'fit'}
				valueLink={configLink.get('legendPosition')}>
				<LegendPositionSelect />
			</FormEntry>
		</Section>
	</WidgetConfigurationTemplate>
}


const b = require('b_').with('bubble-chart-toolbar');
const historicMonthsList = [
	{
		text: i('Year'),
		value: 'year'
	}, {
		text: i('Month'),
		value: 'month'
	}];
const toolbarPopoverContent = <div>
		<div><b>{i('Estimate')}:</b> {i('EstimateTooltip')}</div>
		<div><b>{i('Consumed')}:</b> {i('ConsumedTooltip')}</div>
		<div><b>{i('Year')}:</b> {i('YearTooltip')}</div>
		<div><b>{i('Month')}:</b> {i('MonthTooltip')}</div>
		<div><b>{i('Custom')}:</b> {i('CustomTooltip')}</div>
	</div>
@addLinks
class Widget extends React.PureComponent {
	chartContainerRef = React.createRef();
	state = {
		userDefinedHistoricMonths: this.props.config.historicMonths,
		userDefinedHistoricMonthsCustom: this.props.config.historicMonthsCustom,
		userDefinedCalculation: this.props.config.calculation
	}

	render() {
		let toolbarAtTheEndDD = <></>;
		if (this.state.showDropDownForTimeSelector) {
			const monthList = [...historicMonthsList]
			let monthListValue = this.state.userDefinedHistoricMonths;
			if (this.props.config.historicMonths == 'custom') {
				monthList.push({ text: i('Custom') + `(${this.props.config.historicMonthsCustom})`, value: this.props.config.historicMonthsCustom })
			}
			if(this.state.userDefinedHistoricMonths == 'custom'){
				monthListValue = this.props.config.historicMonthsCustom
			}

			toolbarAtTheEndDD = <DropDownList containerClass={b("toolbar-dropdown-monthSelect")}
									value={monthListValue}
									onChange={this.onHistoricMonthsChange}
									dataSource={monthList}/>
		}

		const toolbarAtTheEnd = <>
		{this.state.showCalculation && <AntRadio.Group buttonStyle={"solid"}
			className={b("toolbar-radio-group")}
			value={this.state.userDefinedCalculation}
			onChange={this.onCalculationChange}>
			<Tooltip title={i('EstimateTooltip')}>
				<AntRadio.Button value={'estimate'}>{i('Estimate')}</AntRadio.Button>
			</Tooltip>
			<Tooltip title={i('ConsumedTooltip')}>
				<AntRadio.Button value={'consumed'}>{i('Consumed')}</AntRadio.Button>
			</Tooltip>
		</AntRadio.Group>}

		{this.state.showDropDownForTimeSelector==false && <><AntRadio.Group
			className={b("toolbar-radio-group")} buttonStyle={"solid"}
			value={this.state.userDefinedHistoricMonths}
			onChange={this.onHistoricMonthsChange}>
			<Tooltip title={i('YearTooltip')}>
				<AntRadio.Button value={'year'}>{i('Year')}</AntRadio.Button>
			</Tooltip>
			<Tooltip title={i('MonthTooltip')}>
				<AntRadio.Button value={'month'}>{i('Month')}</AntRadio.Button>
			</Tooltip>
			<Tooltip title={i('CustomTooltip')}>
				<AntRadio.Button value={'custom'}>{i('Custom')}</AntRadio.Button>
			</Tooltip>
		</AntRadio.Group>
			{this.state.userDefinedHistoricMonths == 'custom' &&
				<AntInputNumber pattern={NumberKeysPattern.PositiveInteger}
					value={this.state.userDefinedHistoricMonthsCustom}
					onChange={this.onHistoricMonthsCustomChange}
					placeholder = {i('# months')} title={i('Number of months')}/>}

		</>
		}
		{this.state.showDropDownForTimeSelector && toolbarAtTheEndDD}
		<Popover content={toolbarPopoverContent} placement="rightTop">
			<IconButton iconName={"question-sign"}
			embedded={true} containerClass={b('info-icon', {dd: this.state.showDropDownForTimeSelector} )}/>
		</Popover>

		</>;

		return (
			<DefaultWidgetWrapper {...this.props} toolbarAtTheEnd={toolbarAtTheEnd}>
				{this.state.error && <Alert type="error" message={this.state.error} banner />}
					<div className={b('bubble-chart-container')} ref={this.chartContainerRef}>

					</div>
			</DefaultWidgetWrapper>
		)
	}

	async componentDidMount() {
		this.setToolbarItemsVisibility();
		await this.initializeChart();
		this.subscription = subscribe(
			this.chartData,
			this.props.config.monitorId,
			_.throttle(this.initializeChart, throttleLimit, {trailing: false})
		);
	}

	componentWillUnmount() {
		this.subscription?.unsubscribe();
	}

	initializeChart = async () => {
		const res = await this.loadData();
		if(res.success === false)
			return;

		this.chartData = res.data.items;
		this.setState({ error: (!res.data || res.data.length == 0) ? i("No data") : null }, async () => {
			const [chartDataSeries, zMaxValue] = this.processData();
			this.drawBubbleChart(chartDataSeries, zMaxValue)
		});
	}
	async loadData() {
		let hm;
		if (this.state.userDefinedHistoricMonths == 'custom') {
			hm = { historicMonths: this.state.userDefinedHistoricMonthsCustom - 1};
		}
		else {
			hm = { historicMonths: null};
		}
		return await CloudBillingMonitorsApi.getCost({ ...this.props.config, ...hm });
	}

	onHistoricMonthsChange = async (value) => {
		this.setState({ userDefinedHistoricMonths: value, userDefinedHistoricMonthsCustom: null }, async () => {
			if (value != 'custom')
				await this.initializeChart();
		})
	}

	onHistoricMonthsCustomChange = async (value) => {
		if (value < 1 || value > 24 || value == null) {
			this.setState({ userDefinedHistoricMonthsCustom: value, error: [i('The input value should be between 1 and 24.')] });
		}
		else {
			this.setState({ userDefinedHistoricMonthsCustom: value, error: null }, async () => {
				await this.initializeChart();
			});
		}
	}

	onCalculationChange = async (value) =>{
		this.setState({ userDefinedCalculation: value })
		await this.initializeChart();
	}

	processData = () => {
		const chartDataSeries = [];
		const subs = []
		for (let item of this.chartData) {
			if (subs.indexOf(item.subscription) == -1) {
				subs.push(item.subscription)
			}
		}
		let zMaxValue = 0;
		for (let sub of subs) {
			let seriesData = []
			for (let resource of this.chartData.filter(x => x.subscription == sub)) {
				const name = resource.resourceName;
				let value = 0;
				if(this.state.userDefinedCalculation === 'estimate'){
					if(this.state.userDefinedHistoricMonths=== 'year'){
						value = resource.yearProjection;
					}
					else if(this.state.userDefinedHistoricMonths=== 'month'){
						value = resource.estimate;
					}
					else if(this.state.userDefinedHistoricMonths=== 'custom'){
						value = resource.estimate + round(resource.months.reduce((t, n) => t + n.amount ?? 0, 0), 2)
					}
				}
				else if(this.state.userDefinedCalculation === 'consumed'){
					if(this.state.userDefinedHistoricMonths=== 'year'){
						value = resource.yearToDate;
					}
					else if(this.state.userDefinedHistoricMonths=== 'month'){
						value = resource.monthToDate;
					}
					else if(this.state.userDefinedHistoricMonths=== 'custom'){
						value = resource.monthToDate + round(resource.months.reduce((t, n) => t + n.amount ?? 0, 0), 2)
					}
				}
				seriesData.push({ name, value });
			}
			if(this.props.config.displayCount && this.props.config.displayCount > 0){
				seriesData.sort((a, b) => b.value - a.value);
				seriesData = seriesData.slice(0, this.props.config.displayCount);
			}
			const maxInSeries = _.maxBy(seriesData, function(o) { return o.value; }).value;
			if(zMaxValue < maxInSeries)
				zMaxValue = maxInSeries;
			chartDataSeries.push({ name: sub, data: seriesData })
		}
		return [chartDataSeries, zMaxValue]
	}

	drawBubbleChart = (chartDataSeries, zMaxValue) => {
		var component = this;
		const plotOptions = {
			packedbubble: {
				minSize: '10%',
				maxSize: '60%',
				zMin: 0,
				zMax: zMaxValue*0.9,
				draggable: false,
				layoutAlgorithm: {
					gravitationalConstant: 0.05,
					splitSeries: true,
					seriesInteraction: false,
					dragBetweenSeries: false,
					parentNodeLimit: true,
					enableSimulation: false,
				},
				dataLabels: {
					enabled: true,
					format: '{point.name}',
					filter: {
						property: 'y',
						operator: '>',
						value: -1
					},
					style: {
						color: 'black',
						textOutline: 'none',
						fontWeight: 'normal'
					}
				},
			}
		}
		if(!this.chart){
			this.chart = Highcharts.chart(this.chartContainerRef.current, {
				chart: {
					type: 'packedbubble',
					height: '80%',
					backgroundColor: 'transparent'
				},
				title: {
					text: null
				},
				credits: {
					enabled: false
				},
				legend: {
					enabled: component.props.config.legendPosition !== 'NONE',
					align: 'right',
					verticalAlign: component.props.config.legendPosition === 'TOP' ? 'top' : 'bottom',
					backgroundColor:
						Highcharts.defaultOptions.legend.backgroundColor || 'white',
					shadow: false,
					labelFormatter: function () {
						return this.userOptions.name;
					}
				},
				tooltip: {
					backgroundColor: 'white',
					borderWidth: 0,
					shadow:{
						offsetX: 0,
						offsetY: 0,
						opacity: 1,
						width: 16,
						color: 'rgb(0,0,0,0.01)'
					},
					useHTML: true,
					pointFormat: '<b>{point.name}:</b> {point.value}'
				},
				exporting: Configuration.highcharts.exporting,
				plotOptions: plotOptions,
				series: chartDataSeries
			});
			setTimeout(()=>{
				this.chart?.reflow();
			},0);
		}
		else{
			this.chart.update({series:chartDataSeries, plotOptions});
		}
	}

	setToolbarItemsVisibility = () => {
		const offsetWidth = this.chartContainerRef.current?.offsetWidth;
		this.setState({
			showDropDownForTimeSelector: offsetWidth < 480,
			showCalculation: (offsetWidth > 660) || (offsetWidth < 480 && offsetWidth > 470)
		});
	}

	onResize = () => {
		setTimeout(()=>{
			this.setToolbarItemsVisibility();
			this.chart?.reflow();
		},0);
	}
}
