import {DataSourceElement} from 'controls/designer/dataSourcesManager/dataSourceElement';
import {AssetsRouter} from "areas/assets/bundleDescription";
import {compareByOperator} from "tools/helpers/math";
import {indicatorToState} from "tools/states";
import {formatNumber} from "tools/helpers/math";
import {dateToString} from "tools/dateTimeUtils";
import {DisplayLabelType, TotalMetricType} from "controls/designer/dataSourcesManager/metricShared";
import {MetricInfo} from "framework/entities/metricInfo";
import {
	MetricConfiguration,
	MetricConfigurationUnitType
} from "areas/service-boards/widgets/common/metricSelectorAdvanced";
import moment from "moment";
import {MetricHealthResponseEntry} from "controls/designer/api";
import {Event, MetricEvent} from "framework/entities/events";
import {CeeviewNavigator} from "tools/ceeviewNavigator";
import {CellMetricDatasource} from "controls/designer/dataSourcesManager/metricDataSourceEditor";
import {TimePeriodType} from "controls/react/form/timePeriodType";

const i = require('core/localization').translator({
  "Metric: {0}": {
    "no": "Metrikk: {0}"
  },
  "Qualifier not found": {
    "no": "Tjenestepunkt ble ikke funnet",
    "en": "Servicequalifier not found"
  },
  "No metric on qualifier": {
    "no": "Ingen metrikker for tjenestepunktet",
    "en": "No metric for this servicequalifier"
  },
  "No data": {
    "no": "Ingen data",
  },
  'Trend: {0}': {
	no: 'Trend: {0}'
  }
});

function getMetricValue(metric: MetricInfo, decimalsNumber?: number) {
	let value = null;

	if (metric && metric.data?.length > 0 && metric.data[0] !== null && metric.data[0].e.length === 1 && metric.data[0].e[0] === 0) {
		value = parseFloat(metric.data[0].v as unknown as string);
	}

	return value;
}

export class MetricDataSourceElement extends DataSourceElement {
	metricsToLoad: {
		metricId: string,
		showTrend?: boolean,
		trendPeriod?: TimePeriodType
	}[]

	metric: MetricHealthResponseEntry
	secondMetric: MetricHealthResponseEntry

	declare datasource: CellMetricDatasource

	getEntriesToLoad() {
		this.metricsToLoad = [{
			metricId: this.datasource.metric.metricId,
			showTrend: this.datasource.metricTrendPeriod?.period && this.datasource.metricTrendPeriod.period != TimePeriodType.None,
			...this.getUnitOrConversion((this.datasource.metric))
		}];


		if (this.datasource.metricTrendPeriod?.period && this.datasource.metricTrendPeriod?.period != TimePeriodType.None) {
			this.metricsToLoad[0].trendPeriod = this.datasource.metricTrendPeriod.period;
		}

		if (this.datasource.totalMetricType === TotalMetricType.Metric && this.datasource.secondMetric?.metricId) {
			this.metricsToLoad.push({
				metricId: this.datasource.secondMetric.metricId,
				...this.getUnitOrConversion((this.datasource.secondMetric))
			});
		}

		return this.metricsToLoad;
	}

	getUnitOrConversion(metricConfiguration: MetricConfiguration) {
		const result: Record<string, string> = {}

		if (metricConfiguration.unit == MetricConfigurationUnitType.Custom){
			result.conversion = metricConfiguration.formula
			result.customUnit = metricConfiguration.unitLabel
		}else if(metricConfiguration.unit != MetricConfigurationUnitType.Autoscaling){
			result.conversionUnit = metricConfiguration.unit
		}

		return result
	}

	onHealthInfoLoaded(entries: MetricHealthResponseEntry[]) {
		if (entries.length > 0) {
			this.metric = entries[0]
		}

		if (entries.length > 1) {
			this.secondMetric = entries[1]
		}
	}

	updateState() {
		if(this.metric == null)
			return

		let label = i('No data');

		const metric = this.getMetricInfo();
		const secondMetric = this.getSecondMetricInfo();

		const value = getMetricValue(metric);

		let secondMetricValue = null;
		switch (this.datasource.totalMetricType){
			case TotalMetricType.Metric:
				secondMetricValue = getMetricValue(secondMetric);
				break;
			case TotalMetricType.UserInput:
				secondMetricValue = parseFloat(this.datasource.totalMetricCustomValue);
				break;
		}

		if(value !== null) {
			label = this.formatValue(this.datasource.metric, value)

			const metricUnit = this.getUnitType(this.datasource.metric, metric)
			if (metricUnit) {
				label += " " + metricUnit
			}

			if (secondMetricValue) {
				label += ' / ' + this.formatValue(this.datasource.secondMetric, secondMetricValue)
			}

			if (secondMetric) {
				const secondMetricUnit = this.getUnitType(this.datasource.secondMetric, secondMetric)
				if (secondMetricUnit) {
					label += " " + secondMetricUnit
				}
			}

			if (this.datasource.metricValueAsSeverity) {
				const valueToSeverity = this.datasource.metricValueToSeverity;

				let severityIndicator = valueToSeverity.findIndex(x => compareByOperator(x.operator, value, x.threshold));

				if (severityIndicator === -1) {
					severityIndicator = 3
				}
				this.setState(indicatorToState(severityIndicator));
			}
		}

		if (this.datasource.showTrend
			&& this.datasource.metricTrendPeriod?.period
			&& this.datasource.metricTrendPeriod?.period != TimePeriodType.None
			&& metric
		) {
			const firstPoint = metric.intercept + metric.slope * moment().valueOf()

			const secondPoint = metric.intercept + metric.slope * moment().add(this.getOffsetInHours(), 'hour').valueOf();
			const delta = firstPoint - secondPoint;

			// this needed to show same trend as in tooltip
			let deltaValue = 0;
			if (Math.abs(delta) > 0.001) {
				deltaValue = +formatNumber(delta, 3).replace(/\s/ig, '');
			}
			if (deltaValue > 0) {
				label += ' <span style="color: green; font-weight: bold; position: relative; top: -2px;">↑</span>';
			}
			if (deltaValue < 0) {
				label += ' <span style="color: red; font-weight: bold; position: relative; top: -1px;">↓</span>';
			}
			if (deltaValue == 0) {
				label += ' <span style="font-weight: bold">-</span>';
			}
		}

		this.removeIcon();
		if (!this.datasource.hideMetricValue) {
			this.addContentLabel(label);
		}
	}

	getUnitType(metricConfiguration: MetricConfiguration, metric: MetricInfo){
		const unitType = metricConfiguration.unit

		if (unitType == MetricConfigurationUnitType.Custom) {
			return metricConfiguration.unitLabel;
		}

		return metric.qualifier.unitTypeSymbol
	}

	getOffsetInHours() {
		switch (this.datasource.metricTrendPeriod?.period) {
			case TimePeriodType.LastHour:
				return -1

			case TimePeriodType.Last24Hours:
				return -24

			case TimePeriodType.Last7Days:
				return -24 * 7

			case TimePeriodType.Last30Days:
				return -24 * 30
		}
	}

	formatValue(metricConfiguration: MetricConfiguration, value: number){
		return formatNumber(value, metricConfiguration.decimals)
	}

	getSubscriptions() {
		let trendPeriod = this.datasource.metricTrendPeriod ?? {period: TimePeriodType.None}

		let showTrend = this.datasource.showTrend && trendPeriod.period != TimePeriodType.None

		let metrics = [this.metric]
		if (this.secondMetric) {
			metrics.push(this.secondMetric)
		}

		return {
			metrics: metrics.map(x => {
				let result: Record<string, any> = {
					metricId: x.metricId,
					unitType: x.metricInfo.qualifier.unitType
				}
				if (showTrend) {
					result.showTrend = true
					result.timePeriod = {
						period: trendPeriod.period,
						from: trendPeriod.startDate,
						to: trendPeriod.endDate
					}
				}

				return result
			})
		};
	}

	consumeEvent(event: Event) {
		if (!MetricEvent.is(event))
			return {
				redraw: false,
				reload: false
			};

		const metric = this.getMetricInfo();
		if(metric == null)
			return {
				redraw: false,
				reload: false
			};

		if (event.slope && event.intercept) {
			metric.slope = event.slope;
			metric.intercept = event.intercept;
		}

		if(this.metric.metricId === event.qualifierId) {
			if(metric.data[0].v !== event.metric.v) {
				metric.data[0] = event.metric;
				return {
					redraw: true,
					reload: false
				};
			}
			else {
				return {
					redraw: false,
					reload: false
				};
			}
		}

		if(this.secondMetric?.metricId === event.qualifierId) {
			if(this.secondMetric.metricInfo.data[0].v !== event.metric.v) {
				this.secondMetric.metricInfo.data[0] = event.metric;
				return {
					redraw: true,
					reload: false
				};
			}
			else {
				return {
					redraw: false,
					reload: false
				};
			}
		}

		return {
			redraw: false,
			reload: false
		};
	}

	getTooltipInternal(accountName: string) {
		const metric = this.getMetricInfo();
		if(!metric)
			return null;

		const value = getMetricValue(metric);
		if(value == null)
			return null

		let valueLabel = this.formatValue(this.datasource.metric, value);
		let result = i('Metric: {0}', metric.qualifier.assetName + '/' + metric.qualifier.instanceName);
		result += "\n" + valueLabel + " " + metric.qualifier.unitTypeSymbol;
		if (this.datasource.metricTrendPeriod?.period && this.datasource.metricTrendPeriod?.period != TimePeriodType.None && metric) {
			let value = metric.slope * 60 * 1000;
			let unit = 'minute';
			switch (this.datasource.metricTrendPeriod?.period) {
				case 'LASTDAY':
					value *= 60;
					unit = 'hour';
					break;
				case 'LAST7DAYS':
				case 'LAST30DAYS':
					value *= 60 * 24;
					unit = 'day';
					break;
			}
			let valueWithUnit = '0';
			if (Math.abs(value) > 0.001) {
				valueWithUnit = formatNumber(value, 3) + '/' + unit;
			}
			result += "\n";
			result += i(`Trend: {0}`, valueWithUnit);
		}

		result += "\n\n";
		let timetstamp = new Date(metric.data[0].t);
		result += dateToString(timetstamp, "datetime");
		return result
	}

	redirect() {
		const metric = this.getMetricInfo();
		if(metric) {
			this.navigator.go({url: AssetsRouter.details(metric.qualifier.assetId)});
		}
	}

	getMetricInfo() {
		if( this.metric?.metricInfo?.found
			&& this.metric.metricInfo.hasMetrics) {
			return this.metric.metricInfo;
		}
	}

	getSecondMetricInfo() {
		if (this.datasource.totalMetricType === TotalMetricType.Metric
			&& this.secondMetric != null
			&& this.secondMetric.metricInfo.found
			&& this.secondMetric.metricInfo.hasMetrics) {
			return this.secondMetric.metricInfo;
		}

		return null;
	}

	empty() {
		return this.datasource.metric?.metricId == null;
	}

	getLabel() {
		let metric;
		if (this.datasource.displayLabelType == DisplayLabelType.Metric) {
			metric = this.getMetricInfo();
		} else if (this.datasource.displayLabelType == DisplayLabelType.Total) {
			metric = this.getSecondMetricInfo();
		}

		if (!metric) {
			return null;
		}

		return metric.qualifier.assetName + '/' + metric.qualifier.instanceName;
	}
}
