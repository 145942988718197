import './costBarChart.less'

import React from "react";
import ReactDOM from "react-dom";

import {formatNumber} from "tools/helpers/math";
import Utils from 'tools/utils';
import RemoteEventsManager from 'core/remoteEventsManager';
import {Localization} from "core/localization";
import {Cookies} from "core/cookies";
import {ReasonsGridApi} from "areas/application/reasonsGridApi";
import {showReasonsGridWindow} from "areas/application/reasonsGridWindow";
import {EditBudgetWindow} from "controls/designer/features/widgets/cloudServices/editBudgetWindow";
import {State} from "tools/state";
import {CloudBillingMonitorsApi} from "areas/assets/monitors/cloud-billing/api";

export const throttleLimit = 60000;

export const sharedLocalization = new Localization({
	"Monitor": {
		"no": "Monitor"
	},
	"Cloud Service": {
		"no": "Skytjeneste",
		"en": "Cloud service"
	},
	"no entries message": {
		"no": "Ingen melding"
	},
	"Monthly Budget": {
		"no": "Månedlig budsjett",
		"en": "Monthly budget"
	},
	"Yearly estimate": {
		"no": "Årlig budsjett",
		"en": "Yearly estimate"
	},
	"Monthly Estimate": {
		"no": "Månedlig estimat",
		"en": "Monthly estimate"
	},
	"Year to date": {
		"no": "År til nå",
		"en": "Year to date"
	},
	"Month to date": {
		"no": "Måned til nå",
		"en": "Month to date"
	},
	"Subscription": {
		"no": "Abonnement"
	},
	"cost": {
		"no": "kostnad"
	},
	"{0} diff": {
		"no": "{0} er månedens navn"
	},
	"Show Threshold": {
		"no": "Vis terskelverdi",
		"en": "Show threshold"
	},
	"Decimal Numbers": {
		"no": "Desimalnummer",
		"en": "Decimal numbers"
	},
	"Cloud Provider": {
		"no": "Skyleverandør",
		"en": "Cloud provider"
	},
	"Historic months": {
		"en": "Historic months",
		"no": "historiske måneder"
	},
	"Historic months tooltip": {
		"en": "Number of historic months displayed. If field is empty current year is displayed.",
		"no": "Antall historiske måneder vist. Vis feltet er tomt vil nåværende år vises."
	},
	"Budget edit": {
		"en": "Allow budget edit",
		"no": "Tillatt budsjettendring"
	},
	"Budget edit tooltip": {
		"en": "Allow edit budget in public dashboards",
		"no": "Tillatter endring av budsjett i offentlige dashboards"
	},
	"Last Month": {
		"no": "Sist måned",
		"en": "Last month"
	},
	"Public mode": {
		"no": "Felles modus"
	},
	"Edit budget tooltip": {
		"en": "Click to edit budget",
		"no": "Klikk for å redigere budsjett"
	},
	"Budget Severity": {
		"no": "budsjettalvorlighet",
		"en": "Budget severity"
	},
	"Show health index for the asset": {
		"no": "Vis helseindeks for ressurs",
		"en": "Show healthindex for asset"
	},
	"Show budget severity": {
		"no": "Vis budsjettalvorlighet",
		"en": "Show budget severity"
	},
	"HI severity": {
		"no": "HI alvorlighet",
		"en": "HI severity"
	},
	"Resource": {
		"no": "Ressurs"
	},
	"Cloud Tags": {
		"no": "Skyetiketter",
		"en": "Cloud tags"
	},
	"Utilization": {
		"no": "Forbruk"
	},
	"Calculated": {
		"no": "Kalkulert"
	},
	"show trend": {
		"no": "vis trend",
		"en": "Show trend"
	},
	'Costmodel/store/sheet is not found or has been deleted.': {
		no: 'Kostmodell/base/fane ble ikke funnet/er slettet.'
	}
});

const i = require('core/localization').translator(sharedLocalization);


export function processApiResponse(data, rowCallback, monthsCallback, sortingOption, lastMonthCallback) {
	let loadedMonthYear = [];
	for (var dataRow of data) {
		for (var item of dataRow.months) {
			if (JSON.stringify(loadedMonthYear).indexOf(JSON.stringify([item.month, item.year])) === -1) {
				loadedMonthYear.push([item.month, item.year]);
			}
		}
		rowCallback(dataRow);
	}

	if (sortingOption === 'sort-backwards') {
		loadedMonthYear.reverse();
	}

	if (lastMonthCallback && loadedMonthYear.length) {
		lastMonthCallback(loadedMonthYear[loadedMonthYear.length - 1][0]);
	}

	for (let item of loadedMonthYear) {
		const date = new Date(item[1], item[0] - 1, 1);
		const monthName = date.toLocaleString(Cookies.CeesoftUserLocale, {month: 'long'}) + ' ' + item[1];
		monthsCallback(item[0], monthName);
	}
}

export function subscribe(data, monitorId, onEvent) {
	if (!data)
		return;

	let metrics = [];
	for (let item of data) {
		metrics = metrics.concat(item.metrics);
	}

	let subscriptionObj = [{
		eventType: 'MultipleMetric',
		metricIds: metrics
	}, {
		eventType: 'Administration',
		actionTypes: ['MONITOR_UPDATE'],
		entityIds: [monitorId]
	}];

	let subscription = RemoteEventsManager.subscribeCallback(subscriptionObj, onEvent);

	let intervals = {};
	for (let metric of metrics) {
		intervals[metric] = 0;
	}
	RemoteEventsManager.releaseEvents(subscription.subscriberId, {
		intervals: intervals
	});

	return subscription;
}

export function formatBudgetColumn(item, props) {
	let budgetEditable;
	if (item.editable === false) {
		return '';
	}
	if (props.config.enableBudgetEditing) {
		budgetEditable = true;
	} else {
		budgetEditable = !props.dashboardSettings.readOnly;
	}

	let formattedNumber = '';
	let budget = item.monthlyBudget;
	if (budget == null) {
		formattedNumber = '';
	} else {
		formattedNumber = formatNumber(budget, props.config.decimalsNumber);
	}

	if (budgetEditable) {
		return `<div id="${item.name}" class="pointer edit-budget-container">
						<span>${formattedNumber}</span>
						<span class="glyphicons pencil edit-budget-icon" title="${i('Edit budget tooltip')}"></span>
					</div>`
	} else {
		return `<span class="non-editable-budget">${formattedNumber}</span>`;
	}
}

export function editBudget(dataSource, e, monitorId, cloudType) {
	return new Promise(resolve => {
		const windowId = Utils.guid();

		const budgetName = e.currentTarget.getAttribute('id');

		let editItem = dataSource.data().find(x => x.name == budgetName).toJSON();
		$('#content_area').append('<div class="edit_budget_window" id="' + windowId + '"></div>');


		const hideEditBudgetWindow = (saved = false) => {
			let container = document.getElementById(windowId);
			ReactDOM.unmountComponentAtNode(container);
			container.remove();

			resolve(saved);
		}

		const updateBudget = async newBudget => {
			let budgetPayload = {
				monitorId: monitorId,
				name: budgetName,
				value: newBudget,
				publicBoardId: Object.keys(State.loadedDashboards)[0]
			};

			const result = await CloudBillingMonitorsApi.updateBudget(cloudType, budgetPayload);

			if (result.success) {
				hideEditBudgetWindow(true);
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}

		let windowOffset = e.currentTarget.getBoundingClientRect();
		ReactDOM.render(<EditBudgetWindow windowId={windowId}
										  editItem={editItem}
										  offset={windowOffset}
										  onUpdate={updateBudget}
										  hideWindow={hideEditBudgetWindow}
		/>, document.getElementById(windowId));
	})
}

export function getGridFilterable(scope) {
	const filterMessages = lang.grid.filter;
	return {
		extra: false,
		operators: {
			string: {
				startswith: filterMessages.STARTS_WITH,
				neq: filterMessages.NEQ,
				eq: filterMessages.EQ,
				contains: filterMessages.CONTAINS
			},
			number: {
				eq: filterMessages.EQ,
				neq: filterMessages.NEQ,
				gte: filterMessages.GTE,
				gt: filterMessages.GT,
				lte: filterMessages.LTE,
				lt: filterMessages.LT
			}
		},
		messages: scope.gridMessages
	}
}

export function hideColumns(configColumns, gridColumns) {
	if (configColumns.length > 0 && typeof configColumns[0] != 'string') {
		configColumns = configColumns.filter(x => x.shown).map(x => x.field);
	}

	for (let gridColumn of gridColumns) {
		if (configColumns.indexOf(gridColumn.field) === -1 && !gridColumn.doNotHide) {
			gridColumn.hidden = true;
		}
	}
	return gridColumns;
}

export async function showReasonsGrid(dataItem, e, gridTitle, windowId, accountId) {
	let severityClicked, filter;
	let windowTitle = '';
	if ($(e.target).hasClass('hiSeverity')) {
		severityClicked = true;
		windowTitle += gridTitle;
	} else if ($(e.target).hasClass('budgetSeverity')) {
		severityClicked = true;
		filter = {logic: "and", filters: [{field: "subsystem", operator: "eq", value: "BUDGET"}]};
		windowTitle = 'Budget HI';
	}

	if (!severityClicked) {
		return;
	}

	const {items} = await ReasonsGridApi.getReasonsGeneric({assetIds: [dataItem.assetId], filter}, accountId);

	if (!items) {
		return;
	}

	windowTitle += ': ' + dataItem.cloudTypeText + '/' + dataItem.subscription + '/' + dataItem.serviceName + '/' + (dataItem.instance || dataItem.assetName);

	let targetOffset = $(e.target).offset();
	let windowLeft = targetOffset.left + 10;
	let windowTop = targetOffset.top + 10;

	await showReasonsGridWindow(() => [items, false], windowTitle, windowLeft, windowTop, windowId);
}

export function onGridDataBound(e) {
	let wrapper = e.sender.wrapper;
	if (wrapper) {
		let headerCells = wrapper.find('th');
		$.each(headerCells, function () {
			let cellText = $(this).find('.k-link').text();
			$(this).attr('title', cellText);
		});
		let cells = wrapper.find('td');
		$.each(cells, function () {
			let cellText = $(this).text();
			$(this).attr('title', cellText);
		});
		if (!wrapper.find('.edit-budget-container').length) {
			wrapper.find('.non-editable-budget').removeClass('non-editable-budget');
		}
	}
}

export const ResourceType = Object.freeze({
	ResourceGroup: 'GROUP',
	CloudService: 'SERVICE',
	Tag: 'TAG'
});

export function getResourceTypeLabel(resourceType) {
	if (resourceType == ResourceType.ResourceGroup)
		return i('Resource group');

	if (resourceType == ResourceType.CloudService)
		return i('Cloud Service');

	if (resourceType == ResourceType.Tag)
		return i('Tag');

	return 'Unknow type';
}

export function initResourceTypeLabels() {

}

export function putItemFirst(array, criterion) {
	const currentMonthIndex = array.findIndex(criterion);
	if (currentMonthIndex < 0) {
		return [...array];
	}
	const currentMonth = array.splice(currentMonthIndex, 1);
	return [...currentMonth, ...array];
}
