import {Api} from "tools/api";

export default class QualifierAPI {
	static getTemplateUrl(serviceId, templateId) {
		return `${Api.accountRoot()}services/${serviceId}/mxmodel/qualifiers/templates/${templateId}`;
	}

	static getUrl(serviceId, elementId, qualifierId) {
		return `${Api.accountRoot()}services/${serviceId}/elements/${elementId}/qualifiers/${qualifierId}`;
	}

	static getClipboardUrl(serviceId) {
		return `${Api.accountRoot()}services/${serviceId}/qualifiers/clipboard`;
	}

	static getCopyPostUrl(serviceId, qualifierId) {
		return `${Api.accountRoot()}services/${serviceId}/qualifiers/copy`;
	}

	static getCopyUrl(serviceId, qualifierId) {
		return `${Api.accountRoot()}services/${serviceId}/qualifiers/${qualifierId}/servicequalifier`;
	}

	static getHelpUrl(serviceId, className) {
		return `${Api.accountRoot()}services/${serviceId}/qualifierTemplates/dcc/${className}/documentation`;
	}

	static getCommonUrl(serviceId, elementId, qualifierId, value) {
		return `${Api.accountRoot()}services/${serviceId}/elements/${elementId}/qualifiers/${qualifierId}/properties/${value}/data`;
	}

	static getValidationUrl(serviceId, elementId, qualifierId, type) {
		return `${QualifierAPI.getUrl(serviceId, elementId, qualifierId)}/${type}`
	}

	static getCategoryUrl(serviceId, categoryId) {
		const params = categoryId ? `?id=${categoryId}`: ``;
		return `${Api.accountRoot()}services/${serviceId}/qualifierTemplates/categories/${params}`;
	}

	static commonFetch(url, options = {}, bypassError) {
		 return Api.fetch(url, {
		 	...options,
			 throwOnError: !bypassError
		 });
	}

	static loadQualifier(serviceId, elementId, qualifierId, bypassError) {
		return QualifierAPI.commonFetch(QualifierAPI.getUrl(serviceId, elementId, qualifierId), {}, bypassError);
	}

	static loadClipboard(serviceId) {
		return QualifierAPI.commonFetch(QualifierAPI.getClipboardUrl(serviceId));
	}

	static loadHelp(serviceId, className) {
		return QualifierAPI.commonFetch(QualifierAPI.getHelpUrl(serviceId, className));
	}

	static loadTemplate(serviceId, templateId, bypassError) {
		return QualifierAPI.commonFetch(QualifierAPI.getTemplateUrl(serviceId, templateId), {}, bypassError);
	}

	static copyQualifier(serviceId, qualifierId) {
		return QualifierAPI.commonFetch(QualifierAPI.getCopyUrl(serviceId, qualifierId));
	}

	static copyQualifierPost(serviceId, payload) {
		return QualifierAPI.commonFetch(QualifierAPI.getCopyPostUrl(serviceId), {
			method: "POST",
			body: JSON.stringify(payload)
		});
	}

	static validate(serviceId, elementId, qualifierId, type, data) {
		return QualifierAPI.commonFetch(QualifierAPI.getValidationUrl(serviceId, elementId, qualifierId, type), {
			method: "POST",
			body: JSON.stringify(data)
		});
	}

	static getTest(serviceId, elementId, qualifierId) {
		return QualifierAPI.commonFetch(QualifierAPI.getValidationUrl(serviceId, elementId, qualifierId, 'test'));
	}

	static getCategory(serviceId, categoryId) {
		return QualifierAPI.commonFetch(QualifierAPI.getCategoryUrl(serviceId, categoryId));
	}

	static getCommonData(serviceId, elementId, qualifierId, value, params) {
		return QualifierAPI.commonFetch(QualifierAPI.getCommonUrl(serviceId, elementId, qualifierId, value, params), {
			method: "POST",
			body: JSON.stringify(params)
		});
	}

	static getDataUrl(url, value) {
		return `${url}${value}/data`;
	}

	static getDataByUrl(url, field, params) {
		return QualifierAPI.commonFetch(QualifierAPI.getDataUrl(url, field, params), {
			method: "POST",
			body: JSON.stringify(params)
		});
	}
}
