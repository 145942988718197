import './newBudgetWindow.less'

import React, {useRef, useState} from "react";
import {Window} from "../../../controls/react/kendoWrappers";
import {topLeftCornerPosition} from "../../../controls/modalWindow";
import {Section} from "../../../controls/react/layout/section";
import {BudgetConfigurationForm} from "areas/cost/budget/budgetConfigurationForm";
import {Toolbar} from "../../../controls/react/layout";
import {ActionButtons} from "../../../controls/react/form";
import {CostBudgetForCreate, CostsApi} from "../api";
import {CostsRouter} from "../bundleDescription";
import State from "../../../tools/state";
import {ModelValidator} from "../../../framework/mobx-integration";
import {observer} from "mobx-react";
import {InfoCircleOutlined} from "@ant-design/icons";
import {BoxView} from "controls/react/layout/boxView";
import {NewBudgetModel} from "areas/cost/budget/newBudgetModel";
import {ApplicationState} from "framework/applicationState";
import {AntInput} from "controls/react/ant/antInput";
import {ModelType} from "./budgetTypes";

const b = require('b_').with('new-budget-window')

export const ValidationErrorPopup = observer(<T extends object>(props: {validator: ModelValidator<T>, fields: Array<keyof T>}) => {
	const [display, setDisplay] = useState(true);
	let timeoutRef = useRef(null);
	const errors = props.fields.reduce((result: string[], current: keyof T) => ([...result, ...props.validator.getErrors(current)]), []);
	const valuesIsEmpty = props.fields.map(key => props.validator.model[key]).every(x => !x);
	if (valuesIsEmpty || !errors.length) {
		if (!display) {
			setDisplay(true);
		}
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
		return null;
	}
	if (!timeoutRef.current && display) {
		timeoutRef.current = setTimeout(() => {
			setDisplay(false);
			timeoutRef.current = null;
		}, 3000);
	}
	return <>
		{display &&
			<BoxView containerClass={b('validation-message')} type={"error"} rounded={false} border={false}>
				<Section direction="row" childrenPadding={true} contentPadding={true} appearance="none">
					<InfoCircleOutlined />
					<ul>
						{errors.map(x => <li key={x}>{x}</li>)}
					</ul>
				</Section>
			</BoxView>
		}
	</>
});

interface NewBudgetWindowProps {
	modelType: ModelType,
	onCancel: () => void
}

interface NewBudgetWindowState {
	budget: NewBudgetModel
}

class NewBudgetWindowUnobserved extends React.Component<NewBudgetWindowProps, NewBudgetWindowState> {
	store: NewBudgetModel
	nameRef =  React.createRef<(typeof AntInput) & {focus: () => void}>()

	constructor(props: any, context: any) {
		super(props, context);
		this.store = new NewBudgetModel(props.modelType);
	}

	render() {
		const {windowTitle} = this.store;
		return (
			<Window
				title={windowTitle}
				modal
				position={topLeftCornerPosition}
				notCenter={true}
				height={200} //for minimal height
				resizable={false}
				onClose={this.props.onCancel}
				width={400}
				autoFocus={false}
				onInit={() => this.nameRef.current.focus()}
				contentClass={b('content')}
			>
				<ValidationErrorPopup validator={this.store.validator} fields={['name']} />
				<Section childrenPadding={true}>
					<BudgetConfigurationForm store={this.store} nameRef={this.nameRef}/>
				</Section>
				<Toolbar>
					<ActionButtons onSave={this.onSave}
								   onCancel={this.props.onCancel}
								   saveDisabled={!this.store.valid}
					/>
				</Toolbar>
			</Window>
		)
	}

	onSave = async () => {
		const store = this.store;
		const budget = {
			id: store.costModelId,
			accountId: store.accountId ?? ApplicationState.accountId,
			name: store.name,
			description: store.description,
			currency: store.currency,
			startDate: store.startDate.format('YYYY-MM-DD')
		} as CostBudgetForCreate

		let result;
		if (store.creationType === 'NEW' || store.creationType === 'DUPLICATE') {
			result = await CostsApi.saveLink(budget, store.creationType);
		} else {
			const link = {
				...budget
			} as CostBudgetForCreate
			if (!!store.cloudConnection) {
				link.costModelIds = store.costConnectionModels.find(x => x.id === store.cloudConnection).costModelIds;
			}
			result = await CostsApi.saveLink(link, store.creationType);
		}

		if (result.success) {
			this.props.onCancel();
			let url = CostsRouter.details(result.data);
			State.mainApp.navigate(url);
		}
	}

	componentWillUnmount() {
		this.store?.destroy()
	}
}

export const NewBudgetWindow = observer(NewBudgetWindowUnobserved);
