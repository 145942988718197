export function getDescriptions(){
	return [{
		url: '/users/:userId/notifications/rulesets/:id',
		getModuleImport: () => import('./rulesetDetails'),
		params: {
			entity: 'user'
		},
		react: true,
		routeId: 'user_ruleset'
	},{
		url: '/teams/:teamId/notifications/rulesets/:id',
		getModuleImport: () => import('./rulesetDetails'),
		params: {
			entity: 'team'
		},
		react: true,
		routeId: 'team_ruleset'
	}];
}

export class NotificationsRouter{
	static userRulesetDetails(userId, triggerId){
		return '/users/' + userId + '/notifications/rulesets/' + triggerId;
	}

	static userRulesetNew(userId){
		return '/users/' + userId + '/notifications/rulesets/new';
	}

	static teamRulesetDetails(teamId, triggerId){
		return '/teams/' + teamId + '/notifications/rulesets/' + triggerId;
	}

	static teamRulesetNew(teamId){
		return '/teams/' + teamId + '/notifications/rulesets/new';
	}
}
