import {Api} from "tools/api";
import {translator} from "core";

export default class FilteringPanelApi {
	static getMonitors() {
		return Api.fetchPost(`${Api.accountRoot()}monitors/search/lite`, {})
	}
	static getAssets() {
		return Api.fetchPost(`${Api.accountRoot()}assets/search/lite`, {})
	}
	static getAssetGroups() {
		return Api.fetchPost(`${Api.accountRoot()}assetGroups/search/lite`, {})
	}
}
