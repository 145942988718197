import React from "react"
import {Button} from "controls/react/form";
import {Window, Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import {Section} from "controls/react/layout/section";
import {translator} from "core";
import {UnitConversions} from 'areas/service-boards/widgets/common/metricInlineSelector';
import {rgbToHex} from 'tools/utils';
import {CustomNotification} from 'controls/customNotification';
import {CollapsibleFrame} from "controls/react/layout/collapsibleFrame";
import { AntSwitch } from 'controls/react/ant/antSwitch';
import {AntModal} from "../../../../controls/react/ant/antModal";
import {validateMetricConversion} from "areas/service-boards/widgets/common/metricConversionForm";

let i = translator({

});

export class AdvancedMetricFieldsWindow extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			unit: !props.metric.advanced ? 'AUTOSCALING' : props.metric.unit || props.metric.unitType,
			unitLabel: props.metric.unitLabel || props.metric.unitTypeSymbol,
			formula: props.metric.formula,
			decimals: props.metric.decimals,
			color: props.metric.color,
			advanced: props.metric.advanced || false
		}
	}

	render() {
		return (
			<AntModal
				height={420}
				width={480}
				open={true}
				closable={true}
				top={this.props.offset.top - 400}
				left={this.props.offset.left + 5}
				title={' '}
				resizable={true}
				draggable={true}
				maskClosable={false}
				onCancel={this.props.onClose}
				footer={null}
			>
				<Section containerClass="generic-metric-widget-configuration">
					<CollapsibleFrame
						title={i('Advanced')}
						expanded={this.state.advanced}
						switcher={FrameSwitcher}
						alwaysShowFields={true}
						onChange={(value) => {
							this.setState({
								advanced: value
							})
						}}
						hideCollapsedPlaceholder={true}
						childrenPadding={true}
					>
						<UnitConversions isFromMultigraph={true}
										 metricUnitType={this.props.metricUnitType}
										 readOnlyFields={!this.state.advanced}
										 unit={this.state.unit}
										 unitChanged={(value) => {
											 this.setState({
												 unit: value.value
											 })
										 }}
										 unitLabel={this.state.unitLabel}
										 unitLabelChanged={(value) => {
											 this.setState({
												 unitLabel: value
											 })
										 }}
										 formula={this.state.formula}
										 onFormulaChange={(value) => {
											 this.setState({
												 formula: value
											 })
										 }}
										 decimals={this.state.decimals}
										 onDecimalsChange={(value) => {
											 this.setState({
												 decimals: value
											 })
										 }}
										 color={this.state.color}
										 onColorChange={() => {
											 let selectedColor = $('.k-hover').find('div').css('background-color');
											 let hexColor = rgbToHex(selectedColor);
											 this.setState({
												 color: hexColor
											 })
										 }}
						/>
					</CollapsibleFrame>
				</Section>
				<Toolbar containerClass="conversion_actions">
					<Button title={'Validate'}
							primary={true}
							disabled={!this.state.formula}
							onClick={() => this.validate()}
							position={ToolbarItemPosition.BEFORE_TITLE} />
					<Button title={'Update'}
							primary={true}
							onClick={() => this.onUpdate()}
							position={ToolbarItemPosition.AT_THE_END} />
					<Button title={i('Cancel')}
							primary={false}
							onClick={() => this.props.onClose()}
							position={ToolbarItemPosition.AT_THE_END} />
				</Toolbar>
			</AntModal>
		)
	}

	componentDidMount() {
		this.actionNotification = new CustomNotification({
			appendToElement: '.notification-container',
		});
	}

	async validate() {
		const result = await validateMetricConversion(this.state.formula, this.state.decimals);
		this.actionNotification.setOptions(result).show();
	}

	onUpdate() {
		this.props.onUpdate(this.state);
	}

	getFrameSwitcher(props) {
		return <AntSwitch size='small'
						  onChange={props?.onChange}
						  value={props?.enabled}
						  disabled={props?.disabled} />
	}
}

const FrameSwitcher = (props) => {
	return <AntSwitch size='small'
					  onChange={props.onChange}
					  value={props.enabled}
					  disabled={false} />
}
