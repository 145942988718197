import React from "react";

import {useResourceTypes} from "./api";
import {AntSelect} from "controls/react/ant/antSelect";
import {AntRadio} from "controls/react/ant/antRadio";
import {ResourceType} from "controls/designer/features/widgets/cloudServices/shared";


const i = require('core/localization').translator({
	'Select a tag...':{}
})

export const ResourceTypesRadioGroup = props => {
	const {accountId, cloudType, ...others} = props;
	const resourceTypes = useResourceTypes(accountId, cloudType);

	return (
		<AntRadio.Group
			buttonStyle={"solid"}
			dataList={resourceTypes}
			nameField={"text"}
			valueField={"value"}
			disabled={!cloudType}
			{...others}
		/>
	);
}
