import React, { useEffect } from "react"

import {CostBudgetItem} from "../../../models/costBudgetItem"
import {i} from "../../../translations"

import {showEventsSummaryPopup, WindowDisposer} from "areas/summary/events/eventsSummaryPopup"
import {TimePeriodType} from "controls/react/form/timePeriodType";

interface EventSummaryButtonProps {
    record: CostBudgetItem
}

export const EventSummaryButton = (props: EventSummaryButtonProps & React.HTMLAttributes<HTMLAnchorElement>) => {
	const windowRef = React.useRef<WindowDisposer>()

	useEffect(() => {
		return () => {
			if(windowRef.current)
				windowRef.current.destroy();
		}
	},[])

	const onClick = React.useCallback(() => {
		const modalConfig = {
			eventTypes: ['COST'],
			filters: [{field: 'targetId', operator: 'multiselect_equals', value: [props.record.id]}],
			columns: ['id', 'severity', 'sourceName', 'targetName', 'type', 'time', 'message'],
			showViewsSelector: false,
			timePeriod: TimePeriodType.Last30Days,
			width: 850,
			height: 300
		}

		windowRef.current = showEventsSummaryPopup(modalConfig)
	}, [props.record.id])

	return (<a className={props.className} title={i('Event summary')} onClick={onClick}>
				<span className="glyphicons article"></span>
			</a>);
}
