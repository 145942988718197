window.CEEVIEW_LOGGING_ENABLED = true;
window.CEEVIEW_LOGGING_EVENTS_ENABLED = false;
window.CEEVIEW_LOGGING_ROUTEID_ENABLED = false;
window.CEEVIEW_LOGGING_CALENDAR_CONFIG_ENABLED = false;

export function clog(...args){
	if(!CEEVIEW_LOGGING_ENABLED || args.length == 0)
		return;

	console.log(getLogTimestamp(), ...args);
}

export function getLogTimestamp(){
	const currentDate = new Date();

	return currentDate.getMinutes() + ':' + currentDate.getSeconds() + '.' + currentDate.getMilliseconds();
}

let loggingContainer = null
export function logOnLayout(message){
	if(loggingContainer == null) {
		loggingContainer = document.createElement('div')
		loggingContainer.style.width = '200px'
		loggingContainer.style.height = '100px'
		loggingContainer.style.overflow = 'auto'
		loggingContainer.style.position = 'absolute'
		loggingContainer.style.top = '0'
		loggingContainer.style.left = '0'
		loggingContainer.style.background = 'white'
		loggingContainer.style.border = '1px solid black'
		loggingContainer.style.zIndex = 99999999999;

		document.body.appendChild(loggingContainer)
	}

	let entry = document.createElement('div')
	entry.innerText = message
	loggingContainer.prepend(entry)
}
