import React from 'react';
import PropTypes from 'prop-types';

import {Section} from "controls/react/layout/section";
import {FormEntry} from "controls/react/form/formEntry";
import {AntInput, AntTextArea} from "controls/react/ant/antInput";
import {useStateLink} from "core/react/links";
import {Toolbar} from "controls/react/layout";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {TagsSelect} from "controls/react/tagsSelect";
import {AntSelect} from "controls/react/ant/antSelect";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {IconButton} from "controls/react/form/iconButton";
import {Window} from "controls/react/kendoWrappers/window";
import {ActionButtons} from "controls/react/form/actionButtons";
import {topLeftCornerPosition} from 'controls/modalWindow';
import CustomNotification from 'controls/customNotification';
import {LayoutType, getLayoutTypeDataSource} from "controls/designer/features/adaptiveLayout/layoutType"
import {PresentationApi} from "areas/dashboards/api";
import {AdaptiveAlgorithm, getAdaptiveAlgorithmDataSource} from "controls/designer/features/adaptiveLayout/adaptiveAlgorithm";

const i = require('core/localization').translator({
  "Lock to screen": {
    "no": "Fest til skjerm",
    "en": "Lock to screen"
  },
  "Slide": {
    "no": "Skyv"
  },
  "Off": {
    "no": "Av"
  },
  "Visibility Settings": {
    "no": "Innstillinger for synlighet",
    "en": "Visibility settings"
  },
  "Position": {
    "no": "Posisjon"
  },
  "Information Options": {
    "no": "Informasjonsinnstillinger",
    "en": "Information options"
  },
  "Show name": {
    "no": "Vis navn",
    "en": "Show name"
  },
  "Show event time": {
    "no": "Vis oppdateringsstid",
    "en": "Show eventtime"
  },
  "Widget Default Settings": {
    "no": "Widget standard innstillinger",
    "en": "Widget default settings"
  },
  "Width (px)": {
    "no": "Bredde (px)"
  },
  "Height (px)": {
    "no": "Høyde (px)"
  },
  "Show headers": {
    "no": "Vis overskrift",
    "en": "Show header"
  },
  "Mode": {
    "no": "Modus",
    "en": "Mode"
  },
  "Public mode tooltip": {
    "en": "Public mode: Gives access to dashboard with dashboard URL without credentials.\nPrivate (unique session id): Gives access to dashboard with dashboard URL with credentials. Session id is unique and will not be reused in other ceeview instances within the browser. \nPrivate (reuse session id): Gives access to dashboard with dashboard URL with credentials. Session id is browser wide and will be reused in other ceeview sessions within the browser",
    "no": "Offentlig modus: Gir tilgang til dashbord med dashboard-URL uten innlogging.\nPrivat (unik sesjons id): Gir tilgang til dashboard med dashboard-URL med innlogging. Sesjons-ID er unik og vil ikke bli gjenbrukt i andre ceeview instanser i nettleseren. \nPrivat (gjenbruk sesjons-ID): Gir tilgang til dashbord med dashbord-URL med innlogging. Sesjons-ID er nettleseromfattende og vil bli gjenbrukt i andre ceeview instanser i nettleseren"
  },
  "Dashboard info": {
    "en": "Dashboard replace our serviceboards as a presentation tool. It provides the same widgets, in addition to the shapes our presentation component gave. It gives greater flexibility in terms of design and layout.",
    "no": "Dashboard erstatter tjenestetavle og presentasjon som presentasjonsverktøy. Det gir de samme widgetene som tjenestetavler, i tillegg til figurene som presentasjonskomponenten ga. Det gir større fleksibilitet når det gjelder design og utforming."
  },
  "Allow redirect in public/private mode": {
    "no": "Tillatt omdirigering i public/privat visning",
    "en": "Allow redirect in public/private mode"
  },
  "Allow redirect tooltip": {
    "en": "Login is required in public mode",
    "no": "Det kreves login i offentlig visning"
  },
  "Name exists": {
    "en": "An identical name exist in this account, please use another name.",
    "no": "Et identisk navn finnes på denne kontoen, bruk et annet navn."
  },
  "Show update time": {
    "no": "Vis oppdateringstid",
    "en": "Show update time"
  },
  "Layout tooltip": {
    "en": "Flow will based on available vertical resolution fit the widgets to screen\nNumber Ordering will fit the widget based on value (from low to high) position the widget in available resolution\nSet value in the Widget/Shape property Arrange tab, field Layout Index",
    "no": "Flyt vil basert på tilgjengelig vertikal oppløsning tilpasse widgetene etter tilgjengelig oppløsning\nNummer Rekkefølge vil tilpasse til widgeten basert på tallverdi (fra lav til høy) plassere widgeten etter tilgjengelig oppløsning\nAngi verdi i Widget/Shape-egenskapen Arrangere fanen, felt Oppsett indeks"
  },
  "Algorithm tooltip": {
    "en": "Scrollable: If content do not fit resolution scrollbars are added\nScale: Content is scaled to fit the resolution\nResponsive: Content will be shifted depending on width of resolution to show all widgets vertically",
    "no": "Skroll: Hvis innholdet ikke passer oppløsningen, legges det til skroll\nSkaler: Innholdet er skalert til passe inn i oppløsningen\nResponsiv: Innholdet vil bli forskjøvet avhengig av oppløsningsbredden for å kunne vise alle widgets vertikalt"
  },
  "Layout Algorithm": {
    "no": "Oppsett algoritme",
    "en": "Layout algorithm"
  },
  "Dashboard property": {
    "no": "Dashboard innstillinger"
  },
  "Public (no login required)": {
    "no": "Offentlig (krever ikke innlogging)"
  },
  "Private (Shared session)": {
    "no": "Privat (delt sesjon)"
  },
  "Private (Unique session)": {
    "no": "Privat (unik sesjon)"
  }
});


export const PropertiesForm = props => {
	let modalNotification = React.useRef(new CustomNotification({
		appendToElement: '#popup',
		status: 'success',
		type: 'icon',
		hideOnClick: true
	}));

	const propertiesLink = useStateLink(props.properties || {});

	propertiesLink.get('layoutType')
		.changing(({rootStore, value, e}) => {
			if(!rootStore.adaptiveAlgorithm ){
				rootStore.adaptiveAlgorithm = AdaptiveAlgorithm.Flow
			}

			if(props.properties.id)
				return;

			if(value == LayoutType.Adaptive && rootStore.widgetWidth == 400 && rootStore.widgetHeight == 320){
				rootStore.widgetWidth = 360;
				rootStore.widgetHeight = 360;
			}else if(rootStore.widgetWidth == 360 && rootStore.widgetHeight == 360){
				rootStore.widgetWidth = 400;
				rootStore.widgetHeight = 320;
			}
		});

	const nameLink = propertiesLink.get('tag')
		.required();

	const onOk = React.useCallback(async () => {
		if (nameLink.invalid){
			modalNotification.current.setOptions({
				message: lang.account.messages.REQUIRED_FIELDS,
				status: 'error'
			}).show();
			return;
		}

		let name = propertiesLink.value.tag;
		let nameExists = await Utils.checkIfNameExists(PresentationApi.getSearchUrl(), name, modalNotification.current, true, props.properties?.tag);
		if(nameExists) {
			return;
		}
		props.onSave(propertiesLink.value);
	}, [props.onSave]);

	const titleIcon = {
		class: 'question-sign',
		tooltip: i('Dashboard info')
	}

	const canSave = React.useCallback(() => {
		if(propertiesLink.get('tag').value?.trim() !== '') {
			return true;
		}
		return false;
	}, [])

	const onPublicModeChange = React.useCallback((value) => {
		if(value == 'public'){
			propertiesLink.update((v) => {
				v.isPublic = true
				v.sharedSession = false
			})
		}else if(value == 'private'){
			propertiesLink.update((v) => {
				v.isPublic = false
				v.sharedSession = false
			})
		}else{
			propertiesLink.update((v) => {
				v.isPublic = false
				v.sharedSession = true
			})
		}
	}, [])

	const publicModeValue = propertiesLink.get('isPublic').value == true
		? 'public'
		: (propertiesLink.get('sharedSession').value == true
			? 'shared'
			: 'private')

	return (
		<Window title={i('Dashboard property')}
				titleIcon={titleIcon}
		        width={900}
				position={topLeftCornerPosition}
		        modal={true}
		        onClose={props.onCancel}>
			<Section appearance={"none"}>

				<Section layout={"equal"} direction={"row"}>
					<Section appearance={"frame"} title={i('Configuration')} childrenPadding={true}>
						<div id="popup"></div>
						<FormEntry valueLink={nameLink} label={i('Name')}>
							<AntInput placeholder={i('Please input Name...')}/>
						</FormEntry>
						<FormEntry label={i('Description')} vertical={true}>
							<AntTextArea valueLink={propertiesLink.get('description')}/>
						</FormEntry>
						<FormEntry label={i('Tags')} vertical={true}>
							<TagsSelect {...propertiesLink.get('tags').props}/>
						</FormEntry>
						<FormEntry label={i('Layout')} title={i('Layout tooltip')}>
							<IconButton iconName={"question-sign"}
										title={i('Layout tooltip')}/>
							<AntSelect dataList={getLayoutTypeDataSource()}
									   {...propertiesLink.get('layoutType').props} />
						</FormEntry>
						{propertiesLink.get('layoutType').value == LayoutType.Adaptive &&
							<FormEntry label={i('Layout Algorithm')} title={i('Layout tooltip')}>
								<IconButton iconName={"question-sign"}
								            title={i('Layout tooltip')}/>
								<AntSelect dataList={getAdaptiveAlgorithmDataSource()}
										   {...propertiesLink.get('adaptiveAlgorithm').props} />
							</FormEntry>
						}
						<FormEntry title={i('Allow redirect tooltip')}>
							<AntCheckbox
								valueLink={propertiesLink.get('allowRedirects')}>{i('Allow redirect in public/private mode')}</AntCheckbox>
						</FormEntry>
					</Section>
					<Section appearance={"none"}>
						<Section appearance={"frame"} title={i('Visibility Settings')} childrenPadding={true}>
							<FormEntry label={i('Mode')}>
								<IconButton iconName={"question-sign"}
								            title={i('Public mode tooltip')}/>
								<AntSelect dataList={visibilityDataList}
								           value={publicModeValue}
								           onChange={onPublicModeChange}/>
							</FormEntry>
							{/*<FormEntry label={i('Public Mode')}>*/}
							{/*	<IconButton iconName={"question-sign"}*/}
							{/*	            title={i('Public mode tooltip')}*/}
							{/*	            embedded/>*/}
							{/*	<AntCheckbox valueLink={propertiesLink.get('isPublic')}>{i('Enabled')}</AntCheckbox>*/}
							{/*</FormEntry>*/}
							{/*<FormEntry>*/}
							{/*	<IconButton iconName={"question-sign"}*/}
							{/*	            title={i('Shared session tooltip')}*/}
							{/*	            embedded/>*/}
							{/*	<AntCheckbox valueLink={propertiesLink.get('sharedSession')}>{i('Shared session')}</AntCheckbox>*/}
							{/*</FormEntry>*/}
							<FormEntry label={i('Information Options')}>
								<AntSelect dataList={informationDataList}
										   {...propertiesLink.get('informationOption').props}/>
							</FormEntry>
							<FormEntry label={i('Position')}>
								<AntSelect dataList={positionDataList} {...propertiesLink.get('position').props}/>
							</FormEntry>
							<FormEntry>
								<AntCheckbox valueLink={propertiesLink.get('showName')}>{i('Show name')}</AntCheckbox>
							</FormEntry>
							<FormEntry>
								<AntCheckbox
									valueLink={propertiesLink.get('showUpdateTime')}>{i('Show update time')}</AntCheckbox>
							</FormEntry>
						</Section>
						<Section appearance={"frame"} title={i('Widget Default Settings')} childrenPadding={true}>
							<FormEntry>
								<AntCheckbox valueLink={propertiesLink.get('showWidgetHeader')}>{i('Show headers')}</AntCheckbox>
							</FormEntry>
							<FormEntry label={i('Width (px)')}>
								<AntInputNumber valueLink={propertiesLink.get('widgetWidth')}/>
							</FormEntry>
							<FormEntry label={i('Height (px)')}>
								<AntInputNumber valueLink={propertiesLink.get('widgetHeight')}/>
							</FormEntry>
						</Section>
					</Section>
				</Section>

				<Toolbar>
					<ActionButtons onSave={onOk}
					               onCancel={props.onCancel}
					               update={props.properties.id != null}
								   saveDisabled={!canSave()}/>
				</Toolbar>

			</Section>

		</Window>
	)
}

PropertiesForm.propTypes = {
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
}

const visibilityDataList = [{
	name: i('Public (no login required)'),
	id: 'public'
},{
	name: i('Private (Unique session)'),
	id: 'private'
},{
	name: i('Private (Shared session)'),
	id: 'shared'
}];

const informationDataList = [{
	name: i('Lock to screen'),
	id: 'locked'
},{
	name: i('Slide'),
	id: 'slide'
},{
	name: i('Off'),
	id: 'off'
}];

const positionDataList = [{
	name: i('Left'),
	id: 'left'
},{
	name: i('Right'),
	id: 'right'
},{
	name: i('Center'),
	id: 'center'
}];
