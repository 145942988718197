import {Cookies} from "core";
import {HistoryWidget} from "./historyWidget";
import {HistoryWidgetConfiguration} from './historyWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: HistoryWidgetConfiguration,
		defaultConfig: {
			type: 'sla-history',
			title: i('History'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		widget: HistoryWidget,
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('SLA') + '/' + i('History'),
	}
}
