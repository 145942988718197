import './queryBuilder.less'

import React from 'react'
import {observer} from 'mobx-react'
import moment from 'moment'

import {i18n} from "controls/queryBuilder/localization"
import {
	GroupConjunction,
	RuleDefinition,
	RuleDefinitionType,
	RulesConfiguration
} from "controls/queryBuilder/ruleDefinition"
import {AntSwitch} from "controls/react/ant/antSwitch"
import {AntRadioGroup} from "controls/react/ant/antRadio"
import {AntButton} from "controls/react/ant/antButton"
import {DeleteOutlined} from '@ant-design/icons'
import {AntSelect} from "controls/react/ant/antSelect"
import {linkModel} from "framework/mobx-integration"
import {AntInput} from "controls/react/ant/antInput"
import {AntInputNumber} from "controls/react/ant/antInputNumber"
import {AntDatePicker} from "controls/react/ant/antDatePicker"

const bBuilder = require('b_').with('query-builder')
export const QueryBuilder = observer((props: QueryBuilderProps) => {
	return <div className={bBuilder()}>
		<QueryBuilderGroup {...props}/>
	</div>
})

const bGroup = require('b_').with('query-builder-group')
export const QueryBuilderGroup = observer((props: QueryBuilderProps& { deleteEntry?: () => void }) => {
	const rule = props.rule

	const andOrDataList = React.useMemo(() => [{
		name: i18n('AND'),
		id: GroupConjunction.And
	}, {
		name: i18n('OR'),
		id: GroupConjunction.Or
	}], [])

	return (
		<div className={bGroup()}>
			<div className={bGroup('buttons-toolbar')}>
				<AntSwitch className={bGroup('not-switch')}
				           checkedChildren={i18n('Not')}
				           unCheckedChildren={i18n('Not')}
				           value={rule.properties.not}
				           onChange={v => rule.properties.not = v}
				/>

				<AntRadioGroup<GroupConjunction> className={bGroup('and-or-switch')}
				                                 dataList={andOrDataList}
				                                 value={rule.properties.conjunction}
				                                 onChange={(v) => rule.properties.conjunction = v as GroupConjunction}/>

				<AntButton className={bGroup('add-rule-button')}
				           type={'primary'}
				           onClick={rule.addEmptyRule}>{i18n('Add Rule')}</AntButton>

				<AntButton className={bGroup('add-group-button')}
				           type={'primary'} onClick={rule.addEmptyGroup}>{i18n('Add Group')}</AntButton>
				{props.deleteEntry &&
					<AntButton className={bGroup('delete-group-button')} icon={<DeleteOutlined/>} onClick={props.deleteEntry}/>
				}
			</div>
			<div className={bGroup('rules')}>
				{Object.values(rule.children1).map(subRule =>
					subRule.type == RuleDefinitionType.Rule
						? <QueryBuilderRule rule={subRule}
						                    config={props.config}
						                    key={subRule.id}
						                    deleteEntry={() => rule.removeRule(subRule)}/>
						: <QueryBuilderGroup rule={subRule}
						                     config={props.config}
						                     key={subRule.id}
						                     deleteEntry={() => rule.removeRule(subRule)}/>
				)}

			</div>
		</div>)
})

const bRule = require('b_').with('query-builder-rule')
export const QueryBuilderRule = observer((props: QueryBuilderProps & { deleteEntry: () => void }) => {
	return (
		<div className={bRule()}>
			<div className={bRule('field')}><QueryBuilderRuleField {...props}/></div>
			<div className={bRule('operator')}><QueryBuilderRuleOperator {...props}/></div>
			<div className={bRule('value')}><QueryBuilderRuleValue bounced={1000} {...props}/></div>
			<div className={bRule('delete')}><AntButton icon={<DeleteOutlined/>} onClick={props.deleteEntry}/></div>
		</div>)
});

const bField = require('b_').with('query-builder-rule-field')
export const QueryBuilderRuleField = observer((props: QueryBuilderProps) => {
	const rule = props.rule

	const fields = React.useMemo(() => {
		return Object.keys(props.config).map(x => ({
			name: props.config[x].label,
			id: x
		}))
	}, [props.config])

	return <AntSelect dataList={fields}
	                  className={bField()}
	                  showSearch={true}
	                  {...linkModel(rule.properties, "field")}
	/>
})

const bOperator = require('b_').with('query-builder-rule-operator')
export const QueryBuilderRuleOperator = observer((props: QueryBuilderProps) => {
	if (!props.rule.properties.field)
		return null

	const config = props.config[props.rule.properties.field]

	if (config == null)
		return null

	const linkedModel = linkModel(props.rule.properties, "operator")

	const onChangeWrapper = (value: string) => {
		if(config.type == 'multiselect' && value == 'like' || props.rule.properties.operator == 'like'){
			props.rule.properties.value = []
		}

		linkedModel.onChange(value)
	}

	return <AntSelect dataList={config.operators}
	                  className={bOperator()}
	                  value={linkedModel.value}
	                  onChange={onChangeWrapper}
	                  nameField={"title"}
	                  valueField={"value"}
	/>
})


const bValue = require('b_').with('query-builder-rule-value')
type QueryBuilderRuleValueProps = QueryBuilderProps & {
	onPressEnter?: () => void
	onPressEsc?: () => void
	bounced?: number
}

export const QueryBuilderRuleValue = observer((props: QueryBuilderRuleValueProps) => {
	const config = props.config[props.rule.properties.field]

	const setSingleValue = React.useCallback((newValue: string) => {
		props.rule.properties.value[0] = newValue
	}, [props.rule])

	const setSingleNumberValue = React.useCallback((value: string | number) => {
		props.rule.properties.value[0] = value
	}, [props.rule])

	const setDateTimeValue = React.useCallback((value: moment.Moment) => {
		if (value) {
			props.rule.properties.value[0] = value.valueOf()
		} else {
			setMultipleValue([])
		}
	}, [props.rule])

	const setMultipleValue = React.useCallback((newValue: string[]) => {
		props.rule.properties.value = newValue
	}, [props.rule])

	let firstValue = props.rule.properties.value.length > 0
		? props.rule.properties.value[0]
		: ''

	let firstStringValue: string
	let firstNumberValue: number

	if (typeof firstValue == 'number') {
		firstNumberValue = firstValue
	} else {
		firstStringValue = firstValue
	}

	if (config == null || !props.rule.properties.operator)
		return null

	switch (config.type) {
		case 'select':
			return <AntSelect className={bValue()}
			                  dataList={config.fieldSettings.listValues}
			                  nameField={"title"}
			                  onMouseEnter={() => console.log('enter')}
			                  onMouseLeave={() => console.log('leave')}
			                  valueField={"value"}
			                  closeOnMouseLeave={true}
			                  value={firstStringValue}
			                  onChange={setSingleValue}/>

		case "boolean":
			return <BooleanEditor {...props}/>

		case 'date':
			let date = firstValue ? moment(firstNumberValue) : null
			return <AntDatePicker className={bValue()}
			                      showTime={true}
			                      allowClear={true}
			                      value={date}
			                      onChange={setDateTimeValue}/>

		case 'integer':
			return <AntInputNumber className={bValue()}
			                       value={firstValue}
			                       bounced={props.bounced}
			                       onPressEnter={props.onPressEnter}
			                       onPressEsc={props.onPressEsc}
			                       onChange={setSingleNumberValue}/>

		case 'multiselect':
			//not a mistake. If operator is LIKE then a text editor should be used
			if (props.rule.properties.operator != 'like') {
				let value = props.rule.properties.value as string[]
				return <AntSelect className={bValue()}
				                  dataList={config.fieldSettings.listValues}
				                  mode={"multiple"}
				                  nameField={"title"}
				                  valueField={"value"}
				                  closeOnMouseLeave={true}
				                  customRenderer={config.customMultiSelectRenderer}
				                  value={value}
				                  onChange={setMultipleValue}/>
			}

		case 'text':
		default:
			return <AntInput className={bValue()}
			                 value={firstStringValue}
			                 bounced={props.bounced}
			                 onPressEnter={props.onPressEnter}
			                 onPressEsc={props.onPressEsc}
			                 onChange={setSingleValue}/>
	}
})

const BooleanEditor = observer((props: QueryBuilderProps) => {
	const value = props.rule.properties.value.length > 0
		? props.rule.properties.value[0]
		: ''

	const dataListShort = React.useMemo(() => [{
		id: 'true',
		name: i18n('Yes')
	}, {
		id: 'false',
		name: i18n('No')
	}], [])

	return <AntSelect value={value}
	                  className={bValue()}
	                  placeholder={i18n('Select...')}
	                  dataList={dataListShort}
	                  onChange={(value) => {
		                  props.rule.properties.value[0] = value
	                  }}
	/>
})

export type QueryBuilderProps = {
	rule: RuleDefinition
	config: RulesConfiguration
}
