import {DesignerStore} from "controls/designer/designerStore";
import {makeAutoObservable, trace} from "mobx";
import {MxCell} from "controls/designer/mxGraphInterfaces"
import {
	convertLegacyConfig,
	defaultRedirectConfig, noRedirectConfig,
	RedirectConfig
} from "controls/designer/features/redirectOnClick/redirectConfig"
import {iterateParents} from "controls/designer/utils"
import {RedirectType} from "controls/designer/features/redirectOnClick/redirectType";
import {NavigatorForRedirectConfig} from "controls/designer/features/redirectOnClick/navigatorForRedirectConfig";
import {CeeviewNavigator} from "tools/ceeviewNavigator";

export class RedirectConfigsManager {
	constructor(public store: DesignerStore) {
		makeAutoObservable(this)
	}

	getEffectiveConfig(cell: MxCell) {
		const customData = this.store.getCustomData(cell)

		this.store.customData.find(x => x.cell == cell)

		if (customData == null)
			return null

		let redirectConfig = customData?.redirectConfig
		if (redirectConfig) {
			return convertLegacyConfig(redirectConfig)
		}

		customData.redirectConfig = JSON.parse(JSON.stringify(defaultRedirectConfig))

		return customData.redirectConfig
	}

	getNavigator(cell: MxCell){
		let [redirectConfig] = iterateParents(cell, (currentCell: MxCell) => {
			const redirectConfig = this.getEffectiveConfig(currentCell)

			if(!redirectConfig)
				return

			if(!redirectConfig.type)
				return

			if (redirectConfig.type == RedirectType.Default)
				return

			if (redirectConfig.type == RedirectType.Custom && redirectConfig.link == '')
				return

			return redirectConfig
		});

		if (redirectConfig == null) {
			redirectConfig = this.getEffectiveConfig(cell) ?? defaultRedirectConfig
		}


		if (this.store.config.chromeless != true) {
			redirectConfig = noRedirectConfig
		}

		return new NavigatorForRedirectConfig(
			this.store.config.navigator ?? new CeeviewNavigator(), redirectConfig)
	}

	getDefaultConfig() {
		return JSON.parse(JSON.stringify(defaultRedirectConfig))
	}

	get selected() {
		return this.getEffectiveConfig(this.store.cell)
	}
}
