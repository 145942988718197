import React from "react"
import { observer } from "mobx-react"

import {GridDataEntry} from "controls/grid/gridDataEntry"
import {getStore, GridStoreHolder} from "controls/grid/gridStore"
import { AntAlert } from "controls/react/ant/antAlert"
import { ApplicationState } from "framework/applicationState"


const i = require('core/localization/localization').translator({
	"External filter applied. Click to go to default view": {
		no: "Eksternt filter satt. klikk for å gå til standardvisning"
	},
	'Click here to clear the filter':{
		no: 'Klikk her for å fjerne filteret'
	},
	"Tags are active": {
		"no": "Etiketter er aktive"
	  },
	  "Tags are active and content is filtered": {
		"no": "Etiketter er aktive og innholdet er filtrert"
	  },
});

type GridFilteredIndicator<T extends GridDataEntry> = {
	store: GridStoreHolder<T>
}

export const GridFilteredIndicator = observer(<T extends GridDataEntry,>(props: GridFilteredIndicator<T>) => {
	const store = getStore(props.store)

	if (store?.selfInitialized != true)
		return null

	if (!store.filtered && !ApplicationState.tagsEnabled)
		return null;
	let onClick, message;

	if (store.state.currentView.isTemporary()) {
		onClick = () => store.state.switchToDefaultView();
		message = i('External filter applied. Click to go to default view')
	} else if (ApplicationState.tagsEnabled) {
		if (store.filtered) {
			onClick = () => store.clearFilters();
			message = i('Tags are active and content is filtered');
		} else {
			message = i('Tags are active');
			onClick = () => {
				store.clearFilters();
			}
		}
	} else if (store.filtered) {
		onClick = () => store.clearFilters();
		message = <div title={i('Click here to clear the filter')}>{i('Content is filtered')}</div>
	}
	return <AntAlert type={"info"}
	                 showIcon={true}
	                 message={message}
	                 onClick={onClick}/>
})
