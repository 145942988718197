import moment from 'moment'
import 'moment/locale/en-gb';
export const addUnknownTimeZonesToMoment = () => {
	const map = new Map<string, string>([
		["ACT","Australia/Darwin"],
		["AET","Australia/Sydney"],
		["AGT","America/Argentina/Buenos_Aires"],
		["ART","Africa/Cairo"],
		["AST","America/Anchorage"],
		["America/Ciudad_Juarez","America/Mexico_City"],
		["America/Nuuk","Etc/GMT+1"],
		["BET","America/Sao_Paulo"],
		["BST","Asia/Dacca"],
		["CAT","Atlantic/Cape_Verde"],
		["CNT","America/St_Johns"],
		["CST","America/Chicago"],
		["CTT","Asia/Shanghai"],
		["EAT","Asia/Riyadh"],
		["ECT","Europe/Paris"],
		["Europe/Kyiv","Europe/Istanbul"],
		["IET","America/Indianapolis"],
		["IST","Asia/Calcutta"],
		["JST","Asia/Tokyo"],
		["MIT","Pacific/Apia"],
		["NET","Asia/Yerevan"],
		["NST","Pacific/Fiji"],
		["PLT","Asia/Karachi"],
		["PNT","America/Phoenix"],
		["PRT","America/Caracas"],
		["PST","America/Los_Angeles"],
		["Pacific/Kanton","America/New_York"],
		["SST","Pacific/Guadalcanal"],
		["SystemV/AST4","America/Anchorage"],
		["SystemV/AST4ADT","America/Anchorage"],
		["SystemV/CST6","America/Chicago"],
		["SystemV/CST6CDT","America/Chicago"],
		["SystemV/EST5","America/New_York"],
		["SystemV/EST5EDT","America/New_York"],
		["SystemV/HST10","Pacific/Honolulu"],
		["SystemV/MST7","America/Denver"],
		["SystemV/MST7MDT","America/Denver"],
		["SystemV/PST8","America/Los_Angeles"],
		["SystemV/PST8PDT","America/Los_Angeles"],
		["SystemV/YST9","America/Anchorage"],
		["SystemV/YST9YDT","America/Anchorage"],
		["VST","Asia/Bangkok"],
		["GMT-06:00", "Etc/GMT+6"]
	]);

	Array.from(map.entries()).forEach(x => {
		moment.tz.link(`${x[0]}|${x[1]}`)
	})
}

export const fixMomentLocale = () => {
	moment().locale('en-gb');
}
