import React, {useEffect} from "react";
import PropTypes from "prop-types";
import CollapsibleFrame from "controls/react/layout/collapsibleFrame";
import {FormEntry, TextBox} from 'controls/react/form';
import {translator} from "core";
import AccountDropDown from "controls/react/dropdowns/accountDropDown";
import {useContextField} from 'core/react/reducerHelpers';
import Lang from 'core/localization/lang'
import {ApplicationState} from "../../../../framework/applicationState";

const i = translator({
});

function ConfigurationFrame(props) {
	const titleLink = useContextField(props.context, 'title');
	const accountLink = useContextField(props.context, 'configuration.accountId');

	useEffect(() => {
		if (!accountLink.props.value) {
			accountLink.props.onChange(ApplicationState.accountId);
		}
	}, []);

	return <CollapsibleFrame title={Lang.CONFIGURATION} expanded={props.expanded}>
		<FormEntry label={i('Title')}>
			<TextBox {...titleLink.props} />
		</FormEntry>
		{!props.useDefaultAccount && <FormEntry label={i('Account')}>
			<AccountDropDown required={true}
							 {...accountLink.props} />
		</FormEntry>}
	</CollapsibleFrame>
}

ConfigurationFrame.propTypes = {
	context: PropTypes.object.isRequired,
	expanded: PropTypes.bool,
	useDefaultAccount: PropTypes.bool
}

ConfigurationFrame.defaultProps = {
	expanded: true
}

export default ConfigurationFrame;
