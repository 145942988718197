import React from 'react'

import {sharedLocalization} from "controls/designer/features/widgets/localization"
import {ApplicationState} from "framework/applicationState"
import {
	EventsSummaryGridWidget, EventsSummaryGridWidgetConfig,
	EventsSummaryWidgetConfiguration
} from "areas/service-boards/widgets/grids/eventsSummary"
import {WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {observer} from "mobx-react";
import {ServicesSelector} from "areas/services/servicesSelector";
import {FormEntry} from "controls/react/form";

const i = require('core/localization').translator(sharedLocalization, {
	'Select the services you want Events for...' : {
		no: 'Velg tjenestene du vil ha Hendelser for…'
	}
})

export function getWidgetDescription(){
	return {
		form: ServiceEventsSummaryWidgetConfiguration,
		defaultConfig: {
			type: 'service-events',
			title: i('Event summary'),
			accountId: ApplicationState.accountId,
			showMenu: true,
			showFilters: true,
			showTimeSettings: true,
		},
		widget: EventsSummaryGridWidget,
		minWidth: 930,
		fullTitle: i('Service') + '/' + i('Event summary'),
		bypassRedirect: true
	}
}

const ServiceEventsSummaryWidgetConfiguration = observer((props: WidgetConfigurationProps<EventsSummaryGridWidgetConfig>) => {
	const {configLink} = props;
	configLink.get('serviceIds').required()

	return <EventsSummaryWidgetConfiguration {...props}>
		<FormEntry label={i('Service')} valueLink={configLink.get('serviceIds')}>
			<ServicesSelector mode={'multiple'}
			                  placeholder={i('Select the services you want Events for...')}/>
		</FormEntry>
	</EventsSummaryWidgetConfiguration>
} )
