import React from "react";

import {StateWidgetConfiguration} from './stateWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {AssetStateDashboardWidgetConfig} from "areas/services/widgets/stateWidget/serviceStateDashboardWidgetConfig"
import {ServiceStateWidget} from 'areas/views/serviceStateWidget'
import {ServiceAdminEvent, ServiceStatusEvent, ServiceSummaryEvent} from "framework/entities/events";
import {apiFetch, ApiRequest} from "framework/api"
import {Severity} from "framework/entities/healthData"
import State from 'tools/state'
import {ApplicationState} from "../../../../framework/applicationState";
import {
	StateWidgetProps,
	Subscription,
	WidgetState,
	withDashboardData,
	withDataLoad
} from "../../../../controls/react/barsStatesWidget";
import {ServiceStateSummary} from "framework/entities/service";
import {ServicesRouter} from "areas/services/bundleDescription";
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";
import {ServicesModal, ServicesModalProps} from "./servicesListPopup";


import {RedirectModifier} from "tools/ceeviewNavigator";

const i = require('core/localization').translator(sharedLocalization, {
	'Service Warning message': {
		en: 'There are 1 or more services that have a system warning. These warnings can be agent in error, agent in warning, agent/asset in maintenance and qualifier error. Click to see the affected services.',
		no: 'Det er 1 eller flere tjenester som har en systemadvarsel. Disse advarslene kan vçre feil på agent, agent i advarsel, agent/ressurs i vedlikeholds- og tjenestepunktfeil. Klikk for å se de berørte tjenestene.'
	}
});

export function getWidgetDescription() {
	return {
		form: StateWidgetConfiguration,
		defaultConfig: {
			type: 'service-state',
			title: i('State'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true,
				severities: ['DOWN', 'WARNING', 'UP', 'INACTIVE'],
			}
		},
		widget: Widget,
		legacyForm: true,
		fullTitle: i('Service') + '/' + i('State'),
	}
}


type GetServiceStateParameters = {
	accountId?: string,
	tags?: string[],
	includeSubaccounts?: boolean,
	showUntagged?: boolean,
	ignoreUserTags?: boolean
}

class ServiceStateWidgetStore {
	modalConfig: ServicesModalProps;
	constructor() {
		makeAutoObservable(this);
	}
	closeModal = () => {
		this.modalConfig = undefined;
	}
}

const WidgetInner = withDashboardData(withDataLoad(ServiceStateWidget, config => apiFetch(getServiceState(config))), i('Service Warning message'));

const Widget = observer(class Widget extends React.Component<StateWidgetProps<AssetStateDashboardWidgetConfig, ServiceStateSummary>, WidgetState<ServiceStateSummary>> {
	subscriptions: Subscription[];
	store = new ServiceStateWidgetStore();

	constructor(props: StateWidgetProps<AssetStateDashboardWidgetConfig, ServiceStateSummary>) {
		super(props);
		this.subscriptions = [
			ServiceStatusEvent.subscription({
				accountId: this.props.config.accountId,
				includeSubaccounts:  this.props.config.includeSubaccounts
			}),
			ServiceAdminEvent.subscription(['SERVICE_DELETE'], this.props.config.accountId, this.props.config.includeSubaccounts),
			ServiceSummaryEvent.subscription()
		];
	}

	render() {
		return <>
			<WidgetInner {...this.props} type={this.props.config.type} subscriptions={this.subscriptions}
							onClick={this.onClick}/>
			{this.store.modalConfig && <ServicesModal {...this.store.modalConfig} onClose={this.store.closeModal} />}
		</>
	}

	onClick = (severity: Severity) => {
		let serviceState: string = '';
		switch (severity) {
			case Severity.Critical:
				serviceState = 'DOWN';
				break;
			case Severity.Invalid:
				serviceState = 'UNAVAILABLE';
				break;
			case Severity.Major:
				serviceState = 'WARNING';
				break;
			case Severity.None:
				serviceState = 'UP';
				break;
		}
		const moduleConfig: ServicesModalProps = {
			serviceState: serviceState,
			tags: this.props.config.tags
		};

		if (this.props.config.openEventsInPopup) {
			this.props.navigator.go({
				url: ServicesRouter.list({ ...moduleConfig, showGridView: true}),
				action: () => {
					this.store.modalConfig = moduleConfig;
				}
			})
		} else {
			this.props.navigator.go({
				url: ServicesRouter.list({ ...moduleConfig, showGridView: true})
			});
		}
	}
})

export function getServiceState(p: GetServiceStateParameters) {
	return new ApiRequest<ServiceStateSummary>({
		url: 'summary/stateAggregate',
		accountBased: true,
		accountId: p.accountId,
		includeSubaccounts: p.includeSubaccounts,
		subaccountsFilter: true,
		method: 'POST',
		payload: {
			tags: p.tags,
			showUntagged: p.showUntagged,
			ignoreUserTags: p.ignoreUserTags === undefined ? true : p.ignoreUserTags
		}
	})
}

