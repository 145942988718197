import {Cookies} from "core/cookies";
import {DashboardWidget} from "controls/designer/features/widgets/custom/dashboardWidget";
import {DashboardWidgetConfiguration} from 'controls/designer/features/widgets/custom/dashboardWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: DashboardWidgetConfiguration,
		defaultConfig: {
			type: 'dashboard',
			title: i('Dashboard'),
			configuration: {
				accountId: ApplicationState.accountId
			}
		},
		widget: DashboardWidget,
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Dashboard'),
	}
}
