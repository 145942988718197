import './sortingIndicator.less'

import {observer} from "mobx-react"
import React from "react"
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons"

import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridColumn} from "controls/grid/gridColumnConfig"

const b = require('b_').with('grid-column-sorting-indicator')
type SortingIndicatorProps<DataItem extends GridDataEntry> = {
	column: GridColumn<DataItem>
}

export const SortingIndicator = observer(<DataItem extends GridDataEntry, >(props: SortingIndicatorProps<DataItem>) => {
	const columnState = props.column.state

	return <div className={b()}>
		<CaretUpOutlined className={b('caret-up', {selected: columnState.sorting == "asc"})}/>
		<CaretDownOutlined
			className={b('caret-down', {selected: columnState.sorting == "desc"})}/>
	</div>
})
