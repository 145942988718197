import Utils from 'tools/utils';
import State from 'tools/state';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import RemoteEventsManager from 'core/remoteEventsManager';
import {ServicesApi} from 'areas/services/api';
import Renderer from 'tools/renderer';
import {ServicesRouter} from 'areas/services/bundleDescription';
import {getServiceState} from 'controls/stateRenderer/serviceState';
import {translator} from 'core';

const i = translator({
	"Summary service » {0}": {
		"no": "Sammendrag tjeneste » {0}",
		"en": "Summary service » {0}"
	}
});

export function SummaryWidget(config) {
	Widget.call(this, config);

	this.subscriptionId = null;
	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.initComponent();
};

export {SummaryWidget as default}

jQuery.extend(SummaryWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.removeListeners();
		this.attachListeners();
		if (!this.title) {
			this.getServiceName();
		}
		this.load();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#' + this.id).on('drop', $.proxy(this.onResize, this));
		if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			$('#' + this.id).on('click', '.cw_node_se', $.proxy(this.onServiceElementClick, this));
			$('#' + this.id).on('click', '.seh_qualifier', $.proxy(this.onServiceQualifierClick, this));
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#' + this.id).off();
	},
	/**
	 * Retrieves the service name from the server and updates the title
	 */
	getServiceName: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/name';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.setTitleIfEmpty(i('Summary service » {0}', result.data));
				this.serviceName = result.data;
			} else {
				this.showErrorMessage(result.message);
			}
		}, this));
	},
	/**
	 * Retrieves a service model from the server
	 */
	load: async function () {
		const result = await ServicesApi.getModel(this.configuration.serviceId,
			this.configuration.accountId, null, this.sessionId )
		this.onServiceModelLoaded(result);
	},
	onServiceModelLoaded: function (result) {
		if (result.success) {
			this.serviceModel = result.data.model;
			this.renderContent(result.data.model);
			if (!this.subscriptionId) {
				this.subscribe();
			}
		} else {
			this.showErrorMessage(result.message);
		}
	},
	/**
	 * Renders a service model
	 * @param {Object} serviceModel The service model object
	 */
	renderContent: function (serviceModel) {
		$('#' + this.id).find('.cw_section_content').empty().append('<ul class="cw_summary go_left"></ul>');
		var root = null,
			exploredEdges = [],
			exploredNodes = [],
			oThis = this,
			padding = 0,
			maxLevel = 0;

		function dfs(list, v, level) {
			function containsNode(list, nodeId) {
				var i, length = list.length;
				for (i = 0; i < length; i++) {
					if (list[i] === nodeId) {
						return true;
					}
				}
				return false;
			}

			function contains(list, edge) {
				var i, length = list.length;
				for (i = 0; i < length; i++) {
					if (list[i].destinationNodeId === edge.destinationNodeId && list[i].sourceNodeId === edge.sourceNodeId) {
						return true;
					}
				}
				return false;
			}

			function findNeighbours(list, v) {
				var neighbours = [],
					i,
					length = list.length;
				for (i = 0; i < length; i++) {
					if (list[i].sourceNodeId === v) {
						neighbours.push(list[i]);
					}
				}
				return neighbours;
			}

			if (level > maxLevel) {
				maxLevel = level;
			}
			exploredNodes.push(v);
			var neighbours = findNeighbours(list, v);
			for (var j = 0; j < neighbours.length; j++) {
				var w = neighbours[j].destinationNodeId;
				exploredEdges.push(neighbours[j]);
				var currentLevel = level;
				currentLevel++;
				oThis.renderNode(w, currentLevel);
				dfs(list, w, currentLevel);
			}
			return true;
		}

		//end dfs

		for (var i = 0, length = serviceModel.nodes.length; i < length; i++) {
			if (serviceModel.nodes[i].type === 'ROOT') {
				root = serviceModel.nodes[i];
				break;
			}
		}
		this.renderNode(root.id, 0);
		dfs(serviceModel.edges, root.id, 0);
		this.maxLevel = maxLevel;
		this.applyExpanded();

		Utils.applyTooltip();
	},

	renderTitle: function (node) {
		let title = '';

		if (node.qualifierError || node.agentStatus === 'AGENT_MAINTENANCE' || node.agentStatus === 'AGENT_DOWN' || node.assetMaintenance || node.operatingState === 'IN_MAINTENANCE') {
			let messages = [];

			if (node.qualifierError) {
				messages.push(lang.account.messages.QUALIFIER_ERROR);
			}

			if (node.assetMaintenance) {
				messages.push(lang.account.messages.ASSET_MAINTENANCE);
			}

			if (node.agentStatus === 'AGENT_DOWN') {
				messages.push(lang.account.messages.AGENT_WARNING);
			}
			else if (node.agentStatus === 'AGENT_MAINTENANCE') {
				messages.push(lang.account.messages.AGENT_MAINTENANCE)
			}
			if (node.operatingState === 'IN_MAINTENANCE') {
				messages.push(lang.SERVICE_MAINTENANCE);
			}

			title = messages.join(' / ');
		}

		return title;
	},

	/**
	 * Renders a node
	 * @param {String} nodeId
	 * @param {Number} level
	 */
	renderNode: function (nodeId, level) {
		var node, className, width, nodeHtml, glyphStatusClass;
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		var container = widgetContentDiv.find('ul:first');
		for (var i = 0, length = this.serviceModel.nodes.length; i < length; i++) {
			if (this.serviceModel.nodes[i].id === nodeId) {
				node = this.serviceModel.nodes[i];
				width = 100 - level * 2;
				nodeHtml = '';
				nodeHtml += '<li class="cw_node_se" data-id="' + node.id + '" style="width:' + width + '%">' + node.name;
				//var elementSummaryState = Renderer.renderSummaryState(node.state, '', null, node.agentStatus, node.qualifierError, node.assetMaintenance);
				var elementSummaryState = getServiceState(node.state, '', node.operatingState, node.agentStatus, node.qualifierError, node.assetMaintenance);
				nodeHtml += elementSummaryState;
				if (node.qualifiers.length) {
					nodeHtml += '<ul class="seh_qualifiers">';
					for (var k = 0; k < node.qualifiers.length; k++) {
						let qualifier = node.qualifiers[k];
						if (!this.configuration.showQualifiers) {
							//backwards compatibility; showQualifers introduced in 2.10
							nodeHtml += this.renderQualifierState(qualifier);
						} else if (this.configuration.showQualifiers === 'all') {
							nodeHtml += this.renderQualifierState(qualifier);
						} else if (this.configuration.showQualifiers === 'breachedWarning') {
							if (qualifier.state === 'INACTIVE' || qualifier.state === 'WARNING' || qualifier.agentStatus === 'AGENT_DOWN' ||
								qualifier.agentStatus === 'AGENT_MAINTENANCE' || qualifier.qualifierError || qualifier.assetMaintenance) {
								nodeHtml += this.renderQualifierState(qualifier);
							}
						}
					}
					nodeHtml += '</ul>';
				}
				nodeHtml += '</li>';
				container.append(nodeHtml);
				break;
			}
		}
	},

	renderQualifierState(qualifier) {
		let html = '<li data-id="' + qualifier.id + '" class="cw_item seh_qualifier">' + qualifier.name;
		//let qualifierSummaryState = Renderer.renderSummaryState(qualifier.state, '', null, qualifier.agentStatus, qualifier.qualifierError, qualifier.assetMaintenance);
		let qualifierSummaryState = getServiceState(qualifier.state, '', qualifier.operatingState, qualifier.agentStatus, qualifier.qualifierError, qualifier.assetMaintenance);
		html += qualifierSummaryState;
		return html;

	},

	/**
	 * Checks if a node is valid from the structure point of view
	 * @param {Object} node
	 */
	isValidNode: function (node) {
		var valid = true;
		if (node.type !== 'LINK') {
			if (node.isAggregated) {
				valid = false;
				for (var i = 0, length = this.serviceModel.edges.length; i < length; i++) {
					if (node.id === this.serviceModel.edges[i].sourceNodeId) {
						valid = true;
						break;
					}
				}
			} else {
				valid = node.qualifiers.length ? true : false;
			}
		}
		return valid;
	},
	/**
	 * Apply expanded/collapsed configuration
	 */
	applyExpanded: function () {
		let elements = $('#' + this.id).find('.cw_node_se');
		let expandedState = this.configuration.expandElements;
		let firstElementSkipped = false;
		$(elements).each(function() {
			if(!firstElementSkipped){
				firstElementSkipped = true;
				return;
			}

			let showElement = false;
			if (expandedState === true || expandedState === 'true' || expandedState === 'all') {
				showElement = true;
			} else if (expandedState === 'downWarning') {
				let elementIndicator = $(this).find('.cw_service_indicator').first();
				let elementWarning = elementIndicator.find('.exclamation-mark');

				showElement = elementIndicator.hasClass('is_critical') || elementIndicator.hasClass('is_major') || elementWarning.length;
			}

			if(showElement){
				$(this).removeClass('hide');
			}else{
				$(this).addClass('hide');
			}
		});
	},
	/**
	 * handler function for the click event on the node
	 * @param {Object} e
	 */
	onServiceElementClick: function (e) {
		e.stopPropagation();
		var elementId = $(e.target).data('id');
		this.navigator.go({
			url: ServicesRouter.viewer(this.configuration.serviceId, {serviceElementId: elementId })
		});
	},
	/**
	 * handler function for the click event on the node
	 * @param {Object} e
	 */
	onServiceQualifierClick: function (e) {
		e.stopPropagation();
		var qualifierId = $(e.target).data('id'),
			elementId = $(e.target).closest('.cw_node_se').data('id');

		this.navigator.go({
			url: ServicesRouter.viewer(this.configuration.serviceId, {
				serviceElementId: elementId,
				serviceQualifierId: qualifierId,
			})
		})

	},
	/**
	 * Called when multiple events are received from the server
	 * @param {Array} data The events data
	 */
	onEvents: function (data) {
		let reload = false;
		for (var i = 0, length = data.length; i < length; i++) {
			if (data[i].eventType === 'ServiceStatus' || data[i].eventType === 'ServiceModel') {
				reload = true;
			}
		}
		if (reload) {
			this.load();
		}
	},
	/**
	 * Updates a Service Element by its state
	 * @param {Object} serviceElementId
	 * @param {Object} state
	 */
	updateServiceElement: function (element) {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		var container = widgetContentDiv.find('ul:first');
		var nodes = this.serviceModel.nodes;
		for (var i = 0, length = nodes.length; i < length; i++) {
			var node = nodes[i];
			if (node.id === element.sourceId) {
				var nodeContainer = container.find('li[data-id="' + element.sourceId + '"]');
				var stateContainer = nodeContainer.find('span').first();
				stateContainer.empty().removeClass();
				//var elementSummaryState = Renderer.renderSummaryState(element.status, '', null, element.agentStatus, element.qualifierError, element.assetMaintenance);
				var elementSummaryState = getServiceState(element.status, '', null, element.agentStatus, element.qualifierError, element.assetMaintenance);
				stateContainer.html(elementSummaryState);
				break;
			}
		}
	},
	/**
	 * Updates a Service Qualifier by its state
	 * @param {Object} serviceQualifierId
	 * @param {Object} state
	 */
	updateServiceQualifier: function (qualifier) {
		var node, nodes = this.serviceModel.nodes;
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		var container = widgetContentDiv.find('ul:first');
		for (var i = 0, length = nodes.length; i < length; i++) {
			node = nodes[i];
			if (node.qualifiers) {
				for (let j = 0; j < node.qualifiers.length; j++) {
					if (node.qualifiers[j].id === qualifier.sourceId) {
						var nodeContainer = container.find('li[data-id="' + qualifier.sourceId + '"]');
						var stateContainer = nodeContainer.find('span').first();
						stateContainer.empty().removeClass();
						//var qualifierSummaryState = Renderer.renderSummaryState(qualifier.status, '', null, qualifier.agentStatus, qualifier.qualifierError, qualifier.assetMaintenance);
						var qualifierSummaryState = getServiceState(qualifier.status, '', null, qualifier.agentStatus, qualifier.qualifierError, qualifier.assetMaintenance);
						stateContainer.html(qualifierSummaryState);
						break;
					}
				}
			}
		}
	},
	/**
	 * Resize handler function
	 */
	onResize: function () {

	},
	/**
	 * Subscribes to service model changes
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'ServiceModel',
			actionTypes: ['PROPERTIES_UPDATE', 'MODEL_DELETE'],
			serviceId: this.configuration.serviceId
		}, {
			eventType: 'ServiceStatus',
			serviceId: this.configuration.serviceId,
			includeElements: true,
			includeQualifiers: true
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
