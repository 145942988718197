import {makeAutoObservable, reaction} from 'mobx'
import UserPrefs from "../tools/userPrefs"
import {ApplicationState} from "../framework/applicationState";
import {getLastEntry} from "../tools/helpers/array";
import {NavigationStore} from "../framework/navigationStore";
import { isEqual } from 'lodash/fp';
import State from "../tools/state";
import {newGuid} from "../tools/guid";
import { translator } from 'core';
import {MonitorType} from "../areas/assets/monitors/monitorType";

const FAVORITES_CATEGORY = 'FavoriteItems'

const i = translator({
	'Cost Gateway': {no: 'Kostnad Gateway'},
	'Designer': {no: 'Designer'},
	'Viewer': {no: 'Visning'},
	'Actiontrigger': {no: 'Handlingsutløser'},
})

const monitorName = (value: MonitorType) => {
	return ApplicationState.enabledMonitors.find(x => x.value == value)?.text.trim()
}
export const buildPrefixes = () => new Map<string, string[]>([
	['administration_usercategories_details', [i('Role')]],
	['administration_accounts_details', [i('Account')]],
	['administration_calendar_details', [i('Calendar')]],
	['application_details', [i('Application')]],
	['assethealth_group_details', [i('Asset Group')]],
	['asset_details', [i('Asset')]],
	['assethealth_filters_details', [i('Filter')]],
	['automation_details', [i('Automation')]],
	['cost_gateway_details', [i('Cost Gateway')]],
	['incidents_details', [i('Incident')]],
	['kpi_details', [i('KPI')]],
	['management_agents_details', [i('Agent')]],
	['server_details', [i('Server')]],
	['report_details', [i('Report')]],
	['service_details', [i('Service')]],
	['slas_details', [i('SLA')]],
	['service_designer', [i('Service'), i('Designer')]],
	['service_viewer', [i('Service'), i('Viewer')]],
	['board_viewer', [i('Dashboard')]],
	['board_designer', [i('Dashboard'), i('Designer')]],
	['cost_model', [i('Cost')]],
	['application_designer', [i('Application'), i('Designer')]],
	['users_profile', [i('User')]],
	['teams_profile', [i('Team')]],
	['team_ruleset', [i('Team'), i('Actiontrigger')]],
	['user_ruleset', [i('User'), i('Actiontrigger')]],
	['administration_accounts_configuration', [i('Accounts'), i('Configuration')]],

	['monitor_webhook', [i('Monitors'), monitorName(MonitorType.Webhook)]],
	['monitor_icinga', [i('Monitors'), monitorName(MonitorType.Icinga)]],
	['monitor_windows-service', [i('Monitors'), monitorName(MonitorType.WindowsServices)]],
	['monitor_windows-events', [i('Monitors'), monitorName(MonitorType.WindowsEvents)]],
	['monitor_vmware', [i('Monitors'), monitorName(MonitorType.VmWare)]],
	['monitor_vmm', [i('Monitors'), monitorName(MonitorType.Vmm)]],
	['monitor_solar-winds', [i('Monitors'), monitorName(MonitorType.SolarWinds)]],
	['monitor_prtg', [i('Monitors'), monitorName(MonitorType.Prtg)]],
	['monitor_processes', [i('Monitors'), monitorName(MonitorType.Processes)]],
	['monitor_nimsoft', [i('Monitors'), monitorName(MonitorType.NimSoft)]],
	['monitor_nagios', [i('Monitors'), monitorName(MonitorType.Nagios)]],
	['monitor_ms-cluster', [i('Monitors'), monitorName(MonitorType.MsCluster)]],
	['monitor_mail-gateway', [i('Monitors'), monitorName(MonitorType.MailGateway)]],
	['monitor_ibm-svc', [i('Monitors'), monitorName(MonitorType.IbmSvc)]],
	['monitor_hyperv', [i('Monitors'), monitorName(MonitorType.HyperV)]],
	['monitor_groovy', [i('Monitors'), monitorName(MonitorType.Groovy)]],
	['monitor_connectivity', [i('Monitors'), monitorName(MonitorType.Connectivity)]],
	['monitor_external', [i('Monitors'), monitorName(MonitorType.External)]],
	['monitor_xen-server', [i('Monitors'), monitorName(MonitorType.XenServer)]],
	['monitor_url', [i('Monitors'), monitorName(MonitorType.Url)]],
	['monitor_system', [i('Monitors'), monitorName(MonitorType.System)]],
	['monitor_snmp-trap', [i('Monitors'), monitorName(MonitorType.SnmpTrap)]],
	['monitor_snmp', [i('Monitors'), monitorName(MonitorType.Snmp)]],
	['monitor_script', [i('Monitors'), monitorName(MonitorType.Script)]],
	['monitor_remote-system', [i('Monitors'), monitorName(MonitorType.RemoteSystem)]],
	['monitor_remote-ibm-iseries', [i('Monitors'), monitorName(MonitorType.RemoteSeries)]],
	['monitor_modbus', [i('Monitors'), monitorName(MonitorType.Modbus)]],
	['monitor_metric-monitor', [i('Monitors'), monitorName(MonitorType.Metric)]],
	['monitor_log-monitor', [i('Monitors'), monitorName(MonitorType.Log)]],
	['monitor_processes-monitor', [i('Monitors'), monitorName(MonitorType.ProcessesNew)]],
	['monitor_interface-traffic', [i('Monitors'), monitorName(MonitorType.InterfaceTraffic)]],
	['monitor_file-search', [i('Monitors'), monitorName(MonitorType.FileSearch)]],
	['monitor_eaton-ups', [i('Monitors'), monitorName(MonitorType.EatonUps)]],
	['monitor_db-watch', [i('Monitors'), monitorName(MonitorType.DbWatch)]],
	['monitor_database', [i('Monitors'), monitorName(MonitorType.Database)]],
	['monitor_container', [i('Monitors'), monitorName(MonitorType.Container)]],
	['monitor_azure', [i('Monitors'), monitorName(MonitorType.Azure)]],
]);

export interface Favorite {
	routeId: string
	params: Record<string, string>
	title: string,
	key: string
}

class FavoritesStoreInner {
	favorites: Favorite[] = []
	loaded: boolean = false
	currentPageTitleOverride: string
	currentPagePrefixOverride: string
	private _prefixes: Map<string, string[]>

	constructor() {
		makeAutoObservable(this)
		reaction(() => ApplicationState.enabledMonitors.length, () => {
			this._prefixes = buildPrefixes()
		})
	}

	async load() {
		if(this.loaded) {
			return
		}
		const result = await UserPrefs.load(FAVORITES_CATEGORY)
		if(result.success != true) {
			throw new Error(result.message)
		}
		this.loaded = true
		const data = UserPrefs.get('favorites')

		if(!data) {
			return
		}

		if(!data.includes('routeId') && data.length > 2) {
			// hack for ignoring old favorites
			this.favorites = []
			await this.save()
			return
		}

		this.favorites = JSON.parse(data) || []
		this.favorites.forEach((x) => x.key = newGuid())
	}

	async save() {
		const preferences = [{
			key: 'favorites',
			value: JSON.stringify(this.favorites)
		}];

		UserPrefs.save(FAVORITES_CATEGORY, preferences);
	}

	toggleCurrentPage = () => {
		if(this.currentPageIsFavorite) {
			this.removeCurrentPageFromFavorites()
		} else {
			this.addCurrentPageToFavorites()
		}
	}

	removeCurrentPageFromFavorites = () => {
		const {routeId, params} = this.currentPageFavorite

		this.favorites = this.favorites.filter(x => {
			return !(routeId == x.routeId && isEqual(params, x.params))
		})
		this.save()
	}

	removeFromFavorites = (favorite: Favorite) => {
		this.favorites = this.favorites.filter(x => {
			return favorite.key != x.key
		})
		this.save()
	}

	addCurrentPageToFavorites = () => {
		this.favorites.unshift({
			key: newGuid(),
			...this.currentPageFavorite
		})
		this.save()
	}

	get currentPageIsFavorite() {
		const {routeId, params} = this.currentPageFavorite
		return this.favorites.some(x => {
			return routeId == x.routeId && isEqual(params, x.params)
		})
	}

	get currentPageFavorite() {
		const route = State.mainApp?._routesTable.find(window.location.hash.split('#').pop())

		return {
			routeId: ApplicationState.currentRouteId,
			params: route?.params,
			title: this.currentPageTitle
		}
	}

	get currentPageTitle() {
		return [
			(this.currentPagePrefixOverride || this.prefixes.get(ApplicationState.currentRouteId)?.join('/'))?.trim(),
			(this.currentPageTitleOverride || getLastEntry(NavigationStore.items)?.title)?.trim()
		].filter(x => x)
			.join('/')
	}

	get favoriteIconVisible() {
		return ApplicationState.currentRouteId != 'home'
	}

	private get prefixes() {
		return this._prefixes ??= buildPrefixes()
	}
}

export const FavoritesStore = new FavoritesStoreInner()
