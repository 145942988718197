import {ApiRequest} from "../../framework/api";
import {IGridStorePayloadProvider} from "../../controls/grid/gridStore";
import {RecalculationStatus} from "../services/services-list/api";

export class SlaGridPayloadProvider implements IGridStorePayloadProvider {
	constructor(private selectedSlas: string[]) {
	}
	getSelectionApiRequest<RecalculationStatus>(args: { url: string, payload?: Record<string, any> }): ApiRequest<RecalculationStatus> {
		const payload = {
			slaIds: this.selectedSlas
		};
		return new ApiRequest<RecalculationStatus>({
			url: args.url,
			method: 'POST',
			accountBased: true,
			payload: Object.assign(payload, args.payload)
		});
	}
}
