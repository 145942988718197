import React, {createElement, ReactElement} from "react";
import { VariableSizeGrid as Grid } from "react-window";
import {FixedType} from "rc-table/lib/interface";
import './gridWithStickyColumns.less'
import classnames from "classnames";
import { observer } from "mobx-react";
const b = require('b_').with('virtual-grid');

type SizeFn = (index: number) => number;
type ItemKeyFn = ({rowIndex, columnIndex}: { rowIndex: number; columnIndex: number }) => string;
type OnRowClickHandler = (event:  React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: number) => void;

function getCellIndices(child: JSX.Element) {
	return { row: child.props.rowIndex, column: child.props.columnIndex };
}

function getShownIndices(children: JSX.Element[]) {
	let minRow = Infinity;
	let maxRow = -Infinity;
	let minColumn = Infinity;
	let maxColumn = -Infinity;

	React.Children.forEach(children, (child) => {
		const { row, column } = getCellIndices(child);
		minRow = Math.min(minRow, row);
		maxRow = Math.max(maxRow, row);
		minColumn = Math.min(minColumn, column);
		maxColumn = Math.max(maxColumn, column);
	});

	return {
		from: {
			row: minRow,
			column: minColumn
		},
		to: {
			row: maxRow,
			column: maxColumn
		}
	};
}

const sumSizeBeforeFactory = (size: SizeFn) => (index: number) => {
	let sum = 0;

	while (index >= 1) {
		sum += size(index - 1);
		index -= 1;
	}

	return sum;
};

const sumSizeAfterFactory = (size: SizeFn, columnCount: number) => (index: number) => {
	let sum = 0;

	while (index < columnCount - 1) {
		sum += size(index + 1);
		index += 1;
	}

	return sum;
};

const stickyColumnIndices = (columns: {fixed?: FixedType}[]) => {
	return columns.reduce((out, column, i) => {
		if(columns[i].fixed) {
			out.push(i)
		}
		return out;
	}, []);
}

// we need this wrapper to run rowClass inside observed component
const ObservedRow = observer((props: any) => {
	const {rowClass, rowIndex, ...rest} = props;
	const className = classnames(b('row'), rowClass?.(rowIndex));
	return createElement('div', {...rest, className});
});

const rowWrapperFactory = (rowHeight: SizeFn, width: number, rowKey: (rowIndex: number) => string, rowClass: (rowIndex: number) => string, onRowClick: OnRowClickHandler) => {
	return ({rowIndex, children, top}: { rowIndex: number, children: JSX.Element[], top: number }) => {
		const props = {
			key: rowKey(rowIndex),
			style: {
				top,
				width,
				height: rowHeight(rowIndex),
				position: "absolute",
				left: 0,
				zIndex: 2
			},
			children,
			rowClass,
			rowIndex,
		} as React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>;
		if(onRowClick) {
			props.onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
				onRowClick(event, rowIndex);
			}
		}
		return createElement(ObservedRow, props);
	}
}

function stickyCell(
	columnWidth: (index: number) => number,
	columnIndex: number,
	rowHeight: (index: number) => number,
	rowIndex: number,
	columns: { fixed?: FixedType }[],
	sumColumnWidthsBefore: (index: number) => number,
	sumColumnWidthsAfter: (index: number) => number,
	Cell: React.ComponentClass | React.FunctionComponent,
	itemKey: ItemKeyFn
) {
	const cellHeight = rowHeight(rowIndex);
	const key = itemKey({rowIndex, columnIndex});
	const cell = createElement(Cell, {
		key: key,
		rowIndex,
		columnIndex,
		style: {
			height: cellHeight,
			width: key.endsWith('action') ? columnWidth(columnIndex) : undefined
		},
		real: true
	});
	return cell;
}

function useInnerElementType(
	Cell: React.ComponentType,
	columnWidth: SizeFn,
	rowHeight: SizeFn,
	columns: {fixed?: FixedType}[],
	rowKey: (rowIndex: number) => string,
	rowClass: (rowIndex: number) => string,
	itemKey: ({rowIndex, columnIndex}: {rowIndex: number, columnIndex: number}) => string,
	width: number,
	onRowClick: OnRowClickHandler
) {
	return React.useMemo(
		() =>
			React.forwardRef((props, ref) => {
				const columnCount = columns.length;
				const sumColumnWidthsBefore = sumSizeBeforeFactory(columnWidth);
				const sumColumnWidthsAfter = sumSizeAfterFactory(columnWidth, columnCount);
				const shownIndices = getShownIndices(props.children as JSX.Element[]);
				const stickyIndices = stickyColumnIndices(columns);
				const fullWidth = columns.reduce((width, column, i) => width + columnWidth(i), 0);
				const createRowWrapper = rowWrapperFactory(rowHeight, fullWidth, rowKey, rowClass, onRowClick);


				let wrapperRowIndex = shownIndices.from.row - 1;
				let rows: JSX.Element[] = [];
				let currentRowCells: JSX.Element[] ;

				React.Children.forEach(props.children, (child) => {
					const {rowIndex, columnIndex, style} = child.props;
					if (wrapperRowIndex != rowIndex) {
						currentRowCells = [];
						rows.push(createRowWrapper({rowIndex, children: currentRowCells, top: style.top}));
						wrapperRowIndex = rowIndex;
					}
					if(!stickyIndices.includes(columnIndex)) {
						const cell = createElement(Cell, {
							key: itemKey({rowIndex, columnIndex}),
							rowIndex,
							columnIndex,
							style: {...style, top: 0},
							real: true
						});
						currentRowCells.push(cell);
					}
				});

				const shownRowsCount = shownIndices.to.row - shownIndices.from.row;

				//NOTE works only for one right fixed column
				for (let i = 0; i <= shownRowsCount; i += 1) {
					const rowIndex = i + shownIndices.from.row;
					stickyIndices.forEach(columnIndex => {
						const cell = stickyCell(columnWidth, columnIndex, rowHeight, rowIndex, columns, sumColumnWidthsBefore, sumColumnWidthsAfter, Cell, itemKey);
						rows[i].props.children.push(cell);
					});
				}

				return (
					<div ref={ref} {...props}>
						{rows}
					</div>
				);
			}),
		[Cell, rowHeight, columnWidth, rowKey, rowClass, itemKey, columns, width, onRowClick]
	);
}

export const GridWithStickyColumns = React.forwardRef((props: any, ref) => {
	return (
		<Grid
			{...props}
			columnCount={props.columns.length}
			ref={ref}
			innerElementType={useInnerElementType(
				props.children,
				props.columnWidth,
				props.rowHeight,
				props.columns,
				props.rowKey,
				props.rowClass,
				props.itemKey,
				props.tableWidth,
				props.onRowClick
			)}
		/>
	);
});
