import {ApiRequest, PagedList} from "framework/api";
import {DataRegistryEntry} from "areas/management/data-registry/dataRegistryEntry";
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {GridStore} from "controls/grid/gridStore";
import {NamedItem} from "areas/assets/monitors/api";
import {DataListEntry} from "framework/entities/dataList";
import {ApplicationState} from "framework/applicationState";
import {GridDataItem} from "controls/grid/gridDataItem";
import {GridSelection, GridSelectionPayload} from "controls/grid/gridSelection";

export type GetDataRegistryArgs = {
	accountId?: string
	includeSubaccounts?: boolean
	selection?: GridSelectionPayload
}

export function getDataRegistry(args?: GetDataRegistryArgs) {
	return new ApiRequest<PagedList<GridDataItem<DataRegistryEntry>>>({
		url: 'dataRegistry/registeredMetrics',
		accountId: args?.accountId,
		accountBased: true,
		includeSubaccounts: args?.includeSubaccounts,
		method: 'POST',
		responseTypeArray: DataRegistryEntry,
		payload: {
			selection: args?.selection
		}
	})
}

export function getDataRegistrySelectedIds(store: GridStore<DataRegistryEntry>){
	return store.getSelectionApiRequest<string[]>({
		url: 'dataRegistry/selectedIds?limit=100'
	})
}

export function getDataRegistryFilters() {
	return new ApiRequest<RulesConfiguration>({
		url: 'dataRegistry/ruleConfiguration',
		accountBased: true,
		subaccountsFilter: true,
		method: 'GET'
	});
}

export function deleteDataRegistry(store: GridStore<DataRegistryEntry>){
	return store.getSelectionApiRequest({
		url: 'dataRegistry/delete',
	});
}

export function setPersistDataFlag(store: GridStore<DataRegistryEntry>, persistData: boolean){
	return store.getSelectionApiRequest({
		url: 'dataRegistry/setPersistDataFlag?value=' + persistData,
	});
}

export function getLatestTimestamp(ids: string[]){
	return new ApiRequest< {id: string, value: number}[]>({
		url: 'dataRegistry/getLatestTime',
		accountBased: true,
		payload: ids
	});
}
