import {makeAutoObservable} from "mobx"

import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridStore} from "controls/grid/gridStore"


export type ViewManagerProps<T extends GridDataEntry> = {
	store: ViewManagerStore<T>
}

export class ViewManagerStore<T extends GridDataEntry> {
	gridStore: GridStore<T>
	showDetails: boolean
	nameEditing: boolean
	originalName: string
	currentTab: string = "filters"

	constructor(gridStore: GridStore<T>) {
		this.gridStore = gridStore

		makeAutoObservable(this)
	}

	get isFavorite() {
		return this.gridStore.state.initialViewId == this.gridStore.state.currentViewId
	}

	addView = () => {
		this.gridStore.state.duplicateCurrentView()
		this.showDetails = true
		this.currentTab = 'enabled-columns'
	}

	deleteView = () => {
		this.gridStore.state.deleteView()
		this.showDetails = false
	}

	setFavorite = () => {
		this.gridStore.state.initialViewId = this.gridStore.state.currentViewId
	}

	editName = () => {
		this.originalName = this.gridStore.state.currentView.name
		this.nameEditing = true
	}

	cancelEditing = () => {
		this.gridStore.state.currentView.name = this.originalName
		this.confirmNameChange()
	}

	confirmNameChange = () => {
		if (!this.gridStore.state.currentView.name.trim()) {
			this.gridStore.state.currentView.name = this.originalName
		}

		this.originalName = null
		this.nameEditing = false
	}

	toggleDetails = () => {
		this.showDetails = !this.showDetails
	}

	closeDetails = () => {
		this.showDetails = false
	}
}
