import './viewSelector.less'

import React from "react"
import {observer} from "mobx-react"
import {Divider, Typography} from "antd"
import {EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons"
import {AntSelect} from "controls/react/ant/antSelect"
import {linkModel} from "framework/mobx-integration"
import {GridStore} from "controls/grid/gridStore"
import {Section} from 'controls/react/layout/section'
import {AntButton} from 'controls/react/ant/antButton'
import {GridDataEntry} from "controls/grid/gridDataEntry"
import {ViewManagerStore} from "controls/grid/viewManager/viewManagerStore";
import {SaveViewButton, ViewManager} from "controls/grid/viewManager/viewManager";
import {AntModal, ModalPosition} from "controls/react/ant/antModal";
import classnames from "classnames";


export type GridViewSelectorProps<T extends GridDataEntry> = {
	store: GridStore<T>
	createViewDisabled?: boolean
	editViewDisabled?: boolean
}

const i = require('core/localization/localization').translator({
	'Add View': {no: 'Legg visning'},
	'Open view settings': {no: 'Åpne visning innstillinger'},
	'Reset to Defaults': {no: 'Tilbakestill'},
	'Select View...': {no: 'Velg Visning...'},
})

const b = require('b_').with('grid-view-selector')
export const ViewSelector = observer(<T extends GridDataEntry, >(props: GridViewSelectorProps<T>) => {
	const [store, setStore] = React.useState<ViewManagerStore<T>>()

	React.useEffect(() => {
		setStore(new ViewManagerStore<T>(props.store))
	}, [])

	const dropdownRender = React.useCallback((menu: React.ReactElement) => {
		return <>
			{menu}

			{props.createViewDisabled !== true && <>
				<Divider className={b('divider')}/>

				<Typography.Link className={b('add-button')} onClick={store?.addView}>
					<PlusOutlined/> {i('Add View')}
				</Typography.Link>
			</>}
		</>
	}, [props.createViewDisabled, store?.addView])

	if(props.store?.selfInitialized != true)
		return null

	if (!store)
		return null

	const gridStore = store.gridStore


	let a = classnames(["test", "a"])
	return <Section appearance={"none"} direction={"row"} containerClass={b()}>
		<AntSelect dataList={props.store.state.views}
		           placeholder={i('Select View...')}
		           className={b('views-list')}
		           size={"small"}
		           {...linkModel(props.store.state, "currentViewId")}
		           nameField={'nameEffective'}
		           dropdownRender={dropdownRender}/>
				<AntButton icon={<EditOutlined/>}
				           onClick={store.toggleDetails}
						   size={"small"}
						   title={i('Open view settings')}/>

			<SaveViewButton store={store} size={"small"}/>
		<AntButton icon={<ReloadOutlined/>}
		           size={"small"}
		           title={i('Reset to Defaults')}
		           onClick={() => gridStore.state.reset(gridStore)}/>

		{store.showDetails &&
			<AntModal width={900}
			          height={400}
			          resizable={true}
			          positionType={ModalPosition.Mouse}
			          open={true}
			          footer={null}
			          onCancel={store.closeDetails}>
				<ViewManager store={store}/>
			</AntModal>
		}
	</Section>
})
