import React from "react";
import PropTypes from "prop-types";
import {Section} from "controls/react/layout/section";
import translator from 'core/localization/localization';
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {newGuid} from "tools/guid";
import {Toolbar} from "controls/react/layout/toolbar";

import './sortingPanel.less';
import SortingItem from "./sortingItem";

const i = translator({
	'Sorting': {
		'no': 'Sortering'
	},
	'Type': {
		'no': 'Type'
	},
	'Click to add additional sorting option': {
		'no': 'Klikk for å legge til flere sorteringsmuligheter'
	}
});

const DEFAULT_ITEM = {
	type: '',
	sorting: 'ASC'
};

function SortingPanel(props) {
	const [items, setItems] = React.useState(null);
	const [order, setOrder] = React.useState([]);

	React.useEffect(() => {
		if (!props.items) {
			return;
		}

		const order = [];

		const out = props.items.reduce((acc, item) => {
			const itemId = newGuid();

			order.push(itemId);

			acc[itemId] = item;
			return acc;
		}, {});

		setOrder(order);
		setItems(out);
	}, [props.items]);

	const onDragEnd = React.useCallback((v) => {
		order.splice(order.indexOf(v.draggableId), 1);
		order.splice(v.destination.index, 0, v.draggableId);
		setOrder([...order]);
	}, [order]);

	React.useEffect(() => {
		props.onChange(order.map((key) => items[key]));
	}, [items, order]);

	const onItemChange = React.useCallback((id, item) => {
		setItems({
			...items,
			[id]: item
		})
	}, [items]);

	const onAddNewItem = React.useCallback(() => {
		if (order.length >= 4) {
			return;
		}

		const itemId = newGuid();

		items[itemId] = {...DEFAULT_ITEM};
		order.push(itemId);

		setItems({...items});
		setOrder([...order]);
	}, [order, items]);

	const onRemove = React.useCallback((id) => {
		order.splice(order.indexOf(id), 1);

		delete items[id];

		setOrder([...order]);
		setItems({...items});
	}, [order, items]);

	if (!items) {
		return null;
	}

	return <Section appearance={'frame'}>
		<Toolbar title={i('Sorting')}>
			<span className={"glyphicons pointer plus"} onClick={onAddNewItem} title={i('Click to add additional sorting option')} />
		</Toolbar>

		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId={'sortingId'}>
				{(provided) => (
					<div {...provided.droppableProps} className={'sorting-panel'} ref={provided.innerRef}>
						{order.map((key, index) => (
							<SortingItem key={key} id={key} index={index} items={items} item={items[key]} onChange={onItemChange} onRemove={onRemove}/>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	</Section>
}

SortingPanel.propTypes = {
	items: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired
};

export default SortingPanel;
