import { ApiRequest } from "framework/api";
import { ApplicationStateSummary } from "areas/application/widgets/stateWidget/applicationStateSummary"
import {EventStateSummary} from "areas/summary/events/eventStateSummary";
import {AssetStateSummary} from "areas/assets/widgets/stateWidget/assetStateSummary";
import {SlaStateSummary} from "areas/sla/widgets/stateWidget/slaStateSummary";
import {ServiceStateSummary} from "framework/entities/service";

export function getSummary(accountId?: string){
	return new ApiRequest<HomeViewSummary>({
		url: 'summary',
		accountBased: true,
		accountId: accountId,
		subaccountsFilter: true
	})
}

export type HomeViewSummary =
{
	"id": string
	"name": string
	"description": string
	"accountName": string
	"applicationHealth": ApplicationStateSummary,
	"eventState": EventStateSummary,
	"accAssetHealth": AssetStateSummary,
	"srvHealth": ServiceStateSummary,
	"accSla": SlaStateSummary,
	"serviceState": "INACTIVE",
	"agentStatus": "ACTIVE",
	"incidents": {
		"low": 0,
		"medium": 552,
		"high": 1,
		"total": 553,
		"open": 553,
		"closed": 0
	},
	"agentsDown": 0,
	"agentsWarning": 0,
	"agentsMaintenance": 0,
	"agentsTotal": 1,
	"qualifierError": true,
	"assetMaintenance": false,
	"accId": "ccbd2952-bed2-4788-bad1-844bbd1d1b6a",
	"accDesc": "",
	"accState": {
		"down": 5,
		"total": 14,
		"warning": 0,
		"inactive": 0,
		"upWithError": 0,
		"warningWithError": 0,
		"downWithError": 4
	},
	"accIncidents": 553,
	"accHealthWarning": true,
	"srvHealthWarning": true,
	"accAssetHealthIndex": 3,
	"srvHealthIndex": 3,
	"accStateIndex": 0,
	"accSlaIndex": 3
}

