import './filterResetButton.less'

import React from 'react';
import classes from 'classnames';
import PropTypes from 'prop-types';
import {translator} from "core";

let i = translator({
  "Reset filter": {
    "no": "Nullstill filter",
    "en": "Reset filter"
  }
});

export const FilterResetButton = (props) => {
	let disabled = true;
	if (props.grid) {
		//effective only after the grid will have implemented on filter change
		let filter = props.grid.kendoGrid.dataSource.filter();
		disabled = filter ? false : true;
	}

	let iconStyle = {
		'fontSize': '17px'
	};

	return (
		<button className={classes(props.className, 'filter-button', 'k-button', 'k-button-icon')}
				onClick={() => {
					if (props.grid) {
						props.grid.kendoGrid.dataSource.filter({});
						props.grid.kendoGrid.dataSource.sort([]);
					}
					if (props.searchInput) {
						$(props.searchInput).val('');
					}
					if (props.onReset) {
						props.onReset();
					}
				}}
				title={i('Reset filter')}
		>
			<span style={iconStyle} className="glyphicons filter-remove"></span>
		</button>
	);
};

export default FilterResetButton;
