import {makeAutoObservable} from "mobx"
import React from "react"

import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridPlugin} from "controls/grid/gridConfiguration"
import {GridStore} from "controls/grid/gridStore"
import {MobxManager} from "framework/mobx-integration"
import {GridMenuAction} from "controls/grid/plugins/grid-menu/gridMenuAction"
import {ActionsColumn} from "controls/grid/plugins/grid-menu/actionsColumn";
import {AntMenuInfo} from "controls/react/ant/antMenu";
import {GridSelection} from "controls/grid/gridSelection";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import {runAsAsync, ZIndex} from "tools/utils";
import {performAction} from "../../../react/ant/antMessage";


export type GridMenuPluginConfig<DataItem extends GridDataEntry> = {
	actions: GridMenuAction<DataItem>[]
	mainMenu: string
	showActionColumn?: boolean
	contextMenu?: string
	onActionFinished?: () => void;
}

const i18n = require('core/localization/localization').translator({})

export class GridMenuPlugin<DataItem extends GridDataEntry> implements GridPlugin<DataItem> {
	store: GridStore<DataItem>

	initialized: boolean = false

	mobx = new MobxManager()
	id = 'grid-menu'

	constructor(public config: GridMenuPluginConfig<DataItem>) {
		makeAutoObservable(this)
	}

	async attach(store: GridStore<DataItem>) {
		this.store = store

		if(this.config.showActionColumn == false) {
			return;
		}

		const actionsColumn: GridColumnConfig<DataItem> =  {
			title: i18n('Actions'),
			field: 'actions',
			sortable: false,
			fixed: 'left',
			renderer: (item, extra) => {
				return <ActionsColumn item={extra.gridItem} store={store}/>
			},
			width: 20
		}

		const columns = [...this.store.config.columns]
		columns.splice(0,0, actionsColumn)
		this.store.config.columns = columns
	}

	generateMenu(selection: GridSelection<DataItem>) {
		const convertedItems = this.config.actions
			.filter(x => x.visible?.(selection) ?? true)
			.map(action => ({
				id: action.id,
				icon: action.icon,
				key: action.id,
				label: action.label,
				disabled: action.enabled
					? !action.enabled(selection)
					: false
			}))

		let lastItemWasDivider = false

		return this.config.mainMenu
			.split(' ')
			.map(x => {
				if (x == "|") {
					if (lastItemWasDivider) {
						return null
					}

					lastItemWasDivider = true

					return {
						type: 'divider'
					}
				}

				lastItemWasDivider = false
				return convertedItems.find(item => item.id == x)
			})
			.filter(x => x != null)
	}


	onMenuItemClick = async (item: AntMenuInfo, selection: GridSelection<DataItem>) => {
		ZIndex.updateZIndex(item.domEvent.target)
		const menuItem = this.config.actions.find(x => x.id == item.key)
		const wrapper = <T,>(action: () => Promise<T>) => menuItem.disableSuccessNotification
			? action()
			: performAction(action, undefined, true);
		await runAsAsync(menuItem.action, [selection], wrapper);
		this.config.onActionFinished?.();
	}
}
