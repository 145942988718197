import React from 'react';

export default class ReactHelpers{
	static setNullsToEmptyString(object){
		for(let key in object){
			if( !object.hasOwnProperty(key))
				continue;

			if( object[key] === null ){
				object[key] = '';
			}
		}

		return object;
	}

	static copy(obj){
		return JSON.parse(JSON.stringify(obj));
	}

	static sortOutContent(children, config){
		config = config || {header: true};

		let others = React.Children.toArray(children);
		let header = null, footer = null;

		if( others.length > 0 && config.header){
			if( others[0].type?.name == "Toolbar"){
				header = others.shift();
			}
		}

		if( others.length > 1 && config.footer){
			footer = others.pop();
		}

		return {header, others, footer};
	}
}
