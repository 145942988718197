export function round(value, decimalsNumber){
	if(decimalsNumber == null || isNaN(decimalsNumber) || decimalsNumber > 10)
		return value;

	const divider = Math.pow(10, decimalsNumber);
	return Math.round((value + Number.EPSILON) * divider)/divider;
}

export function formatNumber(value, decimalsNumber, displayAsThousands = false) {
	if(!value && value !== 0)
		return '';

	displayAsThousands && (value = value / 1000);

	value = round(value, decimalsNumber).toLocaleString('en', {maximumFractionDigits: 20}).replace(/,/g, ' ');

	if(decimalsNumber <= 0)
		return value;

	let parts = value.split('.');
	let fractional = '';
	if(parts.length > 1)
		fractional = parts[1];

	return [parts[0], fractional].filter(x => x).join('.');
}

export function compareByOperator(operator, left, right){
	left = parseFloat(left);
	right = parseFloat(right);

	if(isNaN(left) || isNaN(right))
		return false;

	switch (operator){
		case 'gt': return left > right;
		case 'lt': return left < right;
		case 'lte': return left <= right;
		case 'gte': return left >= right;
		case 'eq': return left == right;
		default: return false;
	}
}

export function setValueOnPath(object, path, value) {
	let [targetObject, lastPathEntry] = getObjectByPath(object, path);
	targetObject[lastPathEntry] = value;
}

export function getValueOnPath(object, path, shift = 0) {
	if (path == "") {
		return object;
	}

	let [targetObject, lastPathEntry] = getObjectByPath(object, path, shift, true);
	if (targetObject == null)
		return null;

	return targetObject[lastPathEntry];
}

function getObjectByPath(object, path, shift = 0, doNotBuildUp = false) {
	if (object == null)
		return [{}, ''];

	//converting a.b[1].c to a.b.1.c
	path = path.replace('[', '.')
		.replace(']', '')
		.split('.');

	path.splice(-shift, shift);

	const lastPathEntry = path.pop();

	let currentObject = object;
	for (let i = 0; i < path.length; i++) {
		let pathEntry = path[i];

		if (currentObject[pathEntry] == null) {
			if (doNotBuildUp) {
				return [null, lastPathEntry];
			}

			let nextPathEntry = i == path.length - 1 ? lastPathEntry : path[i]

			currentObject[pathEntry] = isNaN(nextPathEntry) ? {} : [];
		}

		currentObject = currentObject[pathEntry];
	}

	return [currentObject, lastPathEntry];
}
