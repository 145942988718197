import './aiQueryWidgetConfiguration.less'

import React from "react"
import {observer} from "mobx-react"

import {WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {
	AiQueryWidgetConfig,
	getAiWidgetDisplayModeOptions
} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetConfig";
import {Section} from "controls/react/layout/section";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AntInput} from "controls/react/ant/antInput";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {AntRadioGroup} from "controls/react/ant/antRadio";
import {AntButton} from "controls/react/ant/antButton";
import {Toolbar} from "controls/react/layout/toolbar";
import {DatabaseOutlined} from "@ant-design/icons";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {
	AiQueryWidgetStore
} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetStore";
import {AntAlert} from "controls/react/ant/antAlert";

const i18n = require('core/localization').translator({
	'Settings': {},
	'Display mode': {},
	'Query form popup': {},
	'Run query': {},
	'Query': {},
	'Show AI Chat icon': {},
	'Text output': {},
	'Select a datasource': {
		no: 'Velge en datakilde'
	},
	'Click Run query to generate Text output': {}
},
sharedLocalization);

const b = require('b_').with('ai-query-widget-configuration')

export const AiQueryWidgetConfiguration = observer(
	class InnerWidget extends React.Component<WidgetConfigurationProps<AiQueryWidgetConfig>> {
		private store: AiQueryWidgetStore
		showTextOutputInfo: boolean

		constructor(props: WidgetConfigurationProps<AiQueryWidgetConfig>) {
			super(props)

			this.store = new AiQueryWidgetStore(props.config, props.designerStore)
			if(!props.config.output){
				this.showTextOutputInfo = true
			}
		}

		render() {
			return <Section appearance={"none"}
			                contentPadding={false}
			                childrenPadding={true}>
				<Section title={i18n('General')} appearance={"frame-top-only"}>
					<FormEntryNew label={i18n('Title')} model={this.props.config} modelField={"title"}>
						<AntInput/>
					</FormEntryNew>
				</Section>

				<Section title={i18n('Settings')} appearance={"frame-top-only"}>
					<FormEntryNew label={i18n('Display mode')} model={this.props.config} modelField={"displayMode"}>
						<AntRadioGroup size={"small"} optionType={"button"} buttonStyle={"solid"}
						               options={getAiWidgetDisplayModeOptions()}/>
					</FormEntryNew>

					<FormEntryNew model={this.props.config} modelField={"showChatIcon"}>
						<AntCheckbox>{i18n('Show AI Chat icon')}</AntCheckbox>
					</FormEntryNew>
				</Section>
				<Section title={i18n('AI Query')} appearance={"frame-top-only"} childrenPadding={true}>
					<Section title={i18n('Query')} contentPadding={false}>
						<Toolbar >
							<AntButton icon={<DatabaseOutlined/>}
							           title={i18n('Select a datasource')}
							           onClick={this.store.showCustomDataList}
							/>
						</Toolbar>
						<div ref={this.store.setQueryContainerRef}></div>
					</Section>

					<Toolbar appearance={"transparent"}>
						<AntButton type={"primary"}
						           loading={this.store.updatingQuery}
						           onClick={this.store.openChat}>{i18n('Run query')}</AntButton>
					</Toolbar>
					<Section className={b('text-output-container')} title={i18n('Text output')} contentPadding={false}>
						{this.store.showTextOutputInfo && <AntAlert type={"info"}
					          rootClassName={b('text-output-info')}
					          message={i18n('Click Run query to generate Text output')}
					          closable={true}/>
						}
						<div ref={this.store.setOutputContainerRef}></div>
					</Section>
				</Section>
			</Section>
		}
	})
