import './antMenu.less'

import React from "react"
import classnames from 'classnames'
import {observer} from 'mobx-react'
import {Menu, MenuProps} from 'antd'
import {MenuInfo} from "rc-menu/lib/interface";

interface AntMenuProps extends MenuProps{
}

const b = require('b_').with('ant-menu-wrapper')

export const AntMenuItem = Menu.Item
export const AntMenuSubmenu = Menu.SubMenu

export type AntMenuInfo = MenuInfo

export const AntMenu = observer( (props: AntMenuProps) => {
	let { className,...antNativeProps} = props

	const classes = classnames(b(), className)

	return <Menu onClick={item => {}} className={classes} {...antNativeProps}/>
})
