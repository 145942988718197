import {translator} from "../../../../core";

export const serviceSummaryTranslator = translator({
	DOWN: {no: 'Nede', en: 'Down'},
	WARNING: {no: 'Advarsel', en: 'Warning'},
	UP: {no: 'Oppe', en: 'Up'},
	INACTIVE: {no: 'Inaktiv', en: 'Inactive'},
	HELPTEXT: {
		en: 'Dynamic selects all children from above selection. Eg. if you select dynamic for Service qualfier all qualifers from\n selected services and serviceelements is shown.  Note that dynamic is also inherited from above selections. ',
		no: 'Dynamisk velger alle barn fra valget ovenfor. F.eks. hvis du velger dynamisk for Tjenestekvalifiserer alle kvalifiserer fra utvalgte tjenester og tjenesteelementer vises. Merk og at dynamisk arves fra valg ovenfor.'
	},
	'Dynamic selection. All services from account is shown': {
		no: 'Dynamisk utvalg. Alle tjenester fra konto vises'
	},
	'Dynamic selection. All serviceelement from service selection is shown': {
		no: 'Dynamisk utvalg. Alle tjenesteelementer fra service utvalg vises'
	},
	'Dynamic selection. All servicequalifiers from service and serviceelement is selection is shown': {
		no: 'Dynamisk utvalg. Alle tjenestepunkt fra tjeneste og tjenesteelementer utvalg vises'
	},
	'Service element': {
		no: 'Tjeneste element'
	},
	'Service qualifier': {
		no: 'Tjenestepunkt'
	},
	'Order': {
		no: 'Rekkefølge'
	},
	'Dynamic': {
		no: 'Dynamisk'
	},
	'Open grid': {
		no: 'Åpne tabell'
	},
	'When active only servicequalifier from selected services and/or service elements is shown.': {
		no: 'Når aktiv vises bare tjenestepunkt fra valgte tjenester og/eller tjeneste element.'
	},
	'When active only service element from selected services is shown': {
		no: 'Når aktiv vises bare tjenesteelement fra valgte tjenester.'
	}
});
