import {i} from "core/localization/localization";
import {AntDefaultOptionType} from "controls/react/ant/antSelect";


let entityDataSource: {label: string, value: string}[]
export function getEntityDataSource() {
	if (entityDataSource == null) {
		entityDataSource = [{
			label: i('Asset'),
			value: 'asset'
		},{
			label: i('Asset Group'),
			value: 'assetGroup'
		},{
			label: i('Service'),
			value: 'service'
		},{
			label: i('Sla'),
			value: 'sla'
		},{
			label: i('Metric'),
			value: 'metric'
		},{
			label: i('Kpi'),
			value: 'kpi'
		},{
			label: i('Cost'),
			value: 'cost'
		}];

		entityDataSource.sort((a, b) => a.label.localeCompare(b.label))

		entityDataSource.splice(0, 0, {
			label: i('None'),
			value: 'none'
		})
	}

	return entityDataSource;
}
