import {Api} from "tools/api";
import {getSeverityState} from 'controls/stateRenderer/severityState';
import {dateToString} from "tools/dateTimeUtils";

export async function setMonitorErrorTooltipText(tooltip, monitor, url, target) {
	let tooltipContent = "";
	if (monitor.agentState === 'ERROR'){
		tooltipContent += '<p class="">' + lang.account.messages.AGENT_ERROR + '</p>';
	}
	if (monitor.assetMaintenance) {
		tooltipContent += '<p class="">' + lang.account.messages.ASSET_MAINTENANCE + '</p>';
	}
	if (monitor.monitorMaintenance) {
		tooltipContent += '<p class="">' + lang.account.messages.MONITOR_MAINTENANCE + '</p>';
	}
	if(monitor.monitorDataUnavailable){
		tooltipContent += '<p class="">' + lang.account.messages.MONITOR_DATA_UNAVAILABLE + '</p>';
	}
	if (monitor.agentState === 'WARNING') {
		if (monitor.monitorError) {
			tooltipContent += '<p class="">' + lang.account.messages.AGENT_WARNING + ' | ' + lang.account.messages.MONITOR_ERROR + '</p>';
		} else {
			tooltipContent += '<p class="">' + lang.account.messages.AGENT_WARNING + '</p>';
		}
	} else if (monitor.monitorError) {
		tooltipContent += '<p class="">' + lang.account.messages.MONITOR_ERROR + '</p>';
	}
	
	if(monitor.monitorError){
		const result = await Api.fetch(url);
		if (result && result.length) {
			for (let reason of result) {
				tooltipContent += '<div class="cw_asset_tooltip">';
				tooltipContent += '<span class="cw_severity">' + getSeverityState(reason.severity) + '</span>';
				tooltipContent += '<span class="cw_timestamp">' + dateToString(reason.timestamp, 'datetime') + '</span> - ';
				tooltipContent += '<span class="cw_text">' + reason.text.replace(/\r?\n|\r/g, ' ') + '</span> - ';
				tooltipContent += '<span class="cw_monitorname cw_link" data-id="' + reason.monitorId + '" data-type="' + reason.monitorType + '">' + reason.monitorName + '</span> - ';
				tooltipContent += '<span class="cw_monitortype">' + reason.monitorType + '</span> - ';
				tooltipContent += '<span class="cw_subsystem">' + reason.subsystem + '</span> - ';
				tooltipContent += reason.agentName ? '<span class="cw_agentname cw_link" data-agentid="' + reason.agentId + '">' + reason.agentName + '</span>' : '';
				tooltipContent += '</div>';
			}		
		} else {
			tooltip.options.content = target.attr('cached-title') || '';
		}
	}
	let length = tooltipContent.length;
	let finalContent = '<div style="width: ' + length * 1.2 + 'px;min-width: 200px; max-width: 1000px; ">' + tooltipContent + '</div>';
	tooltip.options.content = finalContent;
	tooltip.refresh();
}
