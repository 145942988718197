import State from 'tools/state'
import Utils from 'tools/utils'
import ContextMenuConstants from 'controls/contextMenuConstants';

export default function ContextMenu(config) {
	Utils.apply(this, config);
	this.initComponent();
};

ContextMenu.prototype = {
	/**
	 * @config {jQuery} element The jQuery element that contains the menu
	 */
	element: $('.cw_multi_content'),
	/**
	 * Main init class
	 */
	initComponent: function () {
		this.updateOnRoles();
	},

	updateOnRoles: function () {
		// top menu items
		if (!State.mainApp.session.hasRole('SERVICE_CREATE')) {
			$('#cw_multi_service').off().remove();
		}
		if (!State.mainApp.session.hasRole('ACCOUNT_CREATE')) {
			$('#cw_multi_account').off().remove();
		}
		if (!State.mainApp.session.hasRole('USER_CREATE')) {
			$('#cw_multi_user').off().remove();
		}
		if (!State.mainApp.session.hasRole('TEAM_CREATE')) {
			$('#cw_multi_team').off().remove();
		}
		if (!State.mainApp.session.hasRole('ASSET_CREATE')) {
			$('#cw_multi_asset').off().remove();
		}
		if (!State.mainApp.session.hasRole('AUTHENTICATION_CREATE')) {
			$('#cw_multi_auth').off().remove();
		}
		if (!State.mainApp.session.hasRole('SERVICEBOARD_CREATE')) {
			$('#cw_multi_dashboard').off().remove();
		}
		if (!State.mainApp.session.hasRole('SLA_CREATE')) {
			$('#cw_multi_sla').off().remove();
		}
		if (!State.mainApp.session.hasRole('REPORT_CREATE')) {
			$('#cw_multi_report').off().remove();
		}
		if (!State.mainApp.session.hasRole('GROUP_CREATE')) {
			$('#cw_multi_asset_group').off().remove();
		}
		if (!State.mainApp.session.hasRole('CALENDAR_CREATE')) {
			$('#cw_multi_calendar').off().remove();
		}
		if (!State.mainApp.session.hasRole('APPLICATION_CREATE')) {
			$('#cw_multi_application').off().remove();
		}
		if (!State.mainApp.session.hasRole('AUTOMATION_CREATE')) {
			$('#cw_multi_automation').off().remove();
		}
		if (!State.mainApp.session.hasRole('HEALTHFILTER_CREATE')) {
			$('#cw_multi_filter').off().remove();
		}
		if (!State.mainApp.session.hasRole('USER_CATEGORY_CREATE')) {
			$('#cw_multi_role').off().remove();
		}
		if (!State.mainApp.session.hasRole('COST_CREATE')) {
			$('#cw_multi_cost').off().remove();
		}
	},
	setActionButton: function (entity) {
		console.warn('setActionButton isn\'t used now, need to implement new solution');
		return

		const entities = ContextMenuConstants.entities;
		const texts = ContextMenuConstants.texts;
		const functions = ContextMenuConstants.functions;
		const monitorEntities = ContextMenuConstants.monitorEntities;
		const monitorTexts = ContextMenuConstants.monitorTexts;

		let isMonitor = false;
		let monitorAddAction;
		if (entity.isMonitor) {
			isMonitor = true;
			monitorAddAction = entity.addAction;
			entity = entity.monitorType;
		}

		let button = $('.cw_context_action');
		let canCreate = false;
		let length = entities.length;

		button.off();
		for (let i = 0; i < length; i++) {
			if (State.mainApp.session.hasRole(entities[i] + '_CREATE')) {
				canCreate = true;
				break;
			}
		}

		if (canCreate) {
			$('.cw_multi_content button').removeClass('no_rights');
			let hasRole = false;
			let roleEntity = entity;
			if (entity === 'COST_MODEL' || entity === 'COST_STORE') {
				roleEntity = 'COST';
			}
			if (State.mainApp.session.hasRole(roleEntity + '_CREATE')) {
				hasRole = true;
				button.text(texts[entities.indexOf(entity)]);
				button.on('click', $.proxy(State.mainApp[functions[entities.indexOf(entity)]], State.mainApp));
			}
			if (isMonitor && State.mainApp.session.hasRole('MONITOR_CREATE')) {
				hasRole = true;
				button.text(monitorTexts[monitorEntities.indexOf(entity)]);
				button.on('click', monitorAddAction);
			}
			if (!hasRole) {
				button.text(lang.CREATE + '...').on('click', $.proxy(State.mainApp.onToggleMenu, State.mainApp));
			}
		} else {
			$('.cw_multi_content button').addClass('no_rights').removeClass('pointer');
			$('.cw_multi_caret').off('click');
			button.off('click');
		}
	}
};

