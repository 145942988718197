
import Utils from 'tools/utils';
import classnames from 'classnames';
import React from "react";

export function getHealthStateHome(serviceHealth, cssClass, warning) {
	let color = 6;
	let warningClass;
	let warningContainer;
	let messages = [];
	if (serviceHealth.total > 0) {
		if (serviceHealth.critical > 0) {
			color = 1;
			warningClass = 'critical';
			messages.push(lang.account.messages.MONITOR_ERROR_UNAVAILABLE);
		} else if (serviceHealth.major > 0) {
			color = 2;
			warningClass = 'major';
		} else if (serviceHealth.minor > 0) {
			color = 3;
			warningClass = 'minor';
		} else if (serviceHealth.ok > 0) {
			color = 5;
			warningClass = 'ok';
		} else if (serviceHealth.noHealth > 0 || serviceHealth.maintenance) {
			color = 6;
			warningClass = 'maintenance';
			messages.push(lang.account.messages.AGENT_MAINTENANCE);
		}
	}

	let classes = classnames(
		'cw_service_indicator',
		'js_asset_status',
		'pointer',
		'cw_status_widget_color',
		'cw_color' + color,
		cssClass || '',
		warningClass
	);

	if (warning) {
		messages.push(lang.account.messages.AGENT_WARNING);
		let warningClasses = classnames(
			'glyphicons',
			'exclamation-mark',
			'qualifier-error'
		);
		warningContainer = `<span class="${warningClasses}">`;
	}

	let html = `<span class="${classes}" title="${messages.join(' / ')}">`;
	if (warningContainer) {
		html += warningContainer;
	}
	return html;
}

export function getHealthStateService(srvHealthIndex, text, reactResult) {
	let colorIndex = Utils.severityToColor(srvHealthIndex);
	let colorClass = 'cw_color' + colorIndex;
	let classArray = ['cw_status_indicator', 'cw_status_widget_color', colorClass];
	if (srvHealthIndex !== 6) {
		classArray.push('pointer');
	}
	let classes = classnames(classArray);
	text = text || '';
	let html = `<span class="${classes}"></span>`;
	if (text) {
		let textContainer = `<span>${text}`;
		html += textContainer;
	}

	if (reactResult) {
		return <span>
				<span className={classes}></span>
				<span>{text}</span>
			</span>
	} else {
		return html;
	}
}
