import {AntSelect, AntSelectPropsPlain, AntSelectValue} from "../../../../react/ant/antSelect";
import React, {useEffect, useState} from "react";
import {apiFetch} from "../../../../../framework/api";
import {listCurrencies} from "areas/cost/api";

const i = require('core/localization').translator({})

export type CurrencyDropDownProps = AntSelectPropsPlain<AntSelectValue> & {
	accountId?: string;
	defaultValue?: string;
}

export const CurrencyDropDown = (props: CurrencyDropDownProps) => {
	const {accountId, defaultValue, ...others} = props;
	const [currencies, setCurrencies] = useState([]);
	useEffect(() => {
		const load = async () => {
			const result = await apiFetch(listCurrencies(accountId));
			if (result.success) {
				setCurrencies(result.data);
			}
		};
		load();
	}, [accountId]);
	useEffect(() => {
		if(!others.value) {
			others.onChange?.(defaultValue ?? 'NOK', currencies.find(x => x.value == defaultValue))
		}
	}, [currencies, others.value, defaultValue])
	return (
		<AntSelect
			placeholder={i('Select...')}
			dataList={currencies}
			nameField={'text'}
			valueField={'value'}
			defaultValue={defaultValue ?? 'NOK'}
			filterOption={(inputValue: string, option: {children: string[], value: string}) => {
				const search = inputValue.toLowerCase()
				return [option.children[0], option.value].some(x => x.toLowerCase().includes(search))
			}}
			{...others}
		/>
	);
}
