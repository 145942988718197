import React from "react";

import {
	BarsStatesWidget,
	summaryToDataListConverter,
	withStateDataSource
} from "controls/react/barsStatesWidget";

import {Severity} from "framework/entities/healthData";
import {EventStateSummary} from "./eventStateSummary";

const i = require('core/localization/localization').translator({
  "BarTitle": {
    "en": "Total events in {0} state: {1}",
    "no": "Totalt antall hendelser i {0}: {1}"
  }
});

const severityMap = new Map<Severity, Severity>([
	[Severity.Critical, Severity.Critical],
	[Severity.Major, Severity.Major],
	[Severity.Minor, Severity.Minor],
]);

const summaryFieldsMap = new Map<Severity, (summary: EventStateSummary, isWarning?: boolean) => number | boolean>([
	[Severity.Critical, (summary: EventStateSummary, isWarning?: boolean) => isWarning ? summary.criticalFlag : summary.critical],
	[Severity.Major, (summary: EventStateSummary, isWarning?: boolean) => isWarning ? summary.majorFlag : summary.major],
	[Severity.Minor, (summary: EventStateSummary, isWarning?: boolean) => isWarning ? summary.minorFlag : summary.minor],
]);

const availableSeverities = [Severity.Critical, Severity.Major, Severity.Minor];
const getDataList = summaryToDataListConverter(availableSeverities, severityMap, summaryFieldsMap, i);
const dataSourceGetter = (summary: EventStateSummary, severities: Severity[]) => getDataList(summary, severities);

export const EventStateGenericWidget = withStateDataSource<EventStateSummary, Severity>(BarsStatesWidget, dataSourceGetter);
