import React from 'react';
import ReactDOM from "react-dom";

import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import MultiToggle from 'controls/multiToggle';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import aggregationTypeDataSource from 'areas/service-boards/widgets/common/dataSources/aggregationTypeDataSource';
import {renderIntoLegacy} from "controls/react/form/timePeriodSelector";


export function MetricsWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {MetricsWidgetConfiguration as default};

jQuery.extend(MetricsWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */

	/**
	 * Main init function
	 */
	initComponent: function () {
		this.widgetObj.configuration.type = this.widgetObj.configuration.type || 'SERVICE';
		var widgetObj = this.widgetObj;
		var html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_asset">';
		html += '<label class="cw_inline">' + lang.ASSET + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_assetid" type="text" value="' + (widgetObj.configuration.assetId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_mon_type">';
		html += '<label class="cw_inline">' + lang.assethealth.MONITOR_TYPE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_monitor_type" type="text" value="' + (widgetObj.configuration.monitorType || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_monitor">';
		html += '<label class="cw_inline">' + lang.assethealth.MONITOR_NAME + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_monitor_id" type="text" value="' + (widgetObj.configuration.monitorId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIOD + ': </label>';
		html += '<ul id="cw_metrics_period_control" class="cw_multi_toggle right">';
		html += '</ul>';
		html += '<div class="cw_hide_time_selector"><input type="checkbox" class="cw_include k-checkbox" id="cw_hide_time_selector"' + (widgetObj.configuration.hideTimeSelector ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_hide_time_selector" class="k-checkbox-label">' + lang.widget.HIDE_TIME_SELECTOR + '</label></div>';
		html += '</div>';
		html += '<div class="cw_field cw_no_label hide" id="cw_metrics_dates">';
		html += '<input id="cw_metrics_startdate" type="text" /> - <input id="cw_metrics_enddate" type="text" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_chart_type_container">';
		html += '<label class="cw_inline">' + lang.TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_chart_type" type="text" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.AGGREGATION_TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_aggregation_type" type="text" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="cw_include k-checkbox" id="cw_ignore_missing_data" title="' + lang.serviceBoard.messages.MISSING_DATA_MESSAGE + '"></span>';
		html += '<label for="cw_ignore_missing_data" class="k-checkbox-label">' + lang.serviceBoard.IGNORE_MISSING_DATA + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="cw_show_regression k-checkbox" id="cw_show_regression" title=""></span>';
		html += '<label for="cw_show_regression" class="k-checkbox-label">' + lang.SHOW_REGRESSION + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_timezone" type="text" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '"/></div>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.LEGEND + ': </span>';
		html += '<input id="cw_metrics_legend" type="text" />';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(
			$(html).addClass('assets-metricsWidgetConfiguration')
		);

		const [timePeriod, timePeriodRoot] = renderIntoLegacy({
			container: document.getElementById("cw_metrics_period_control"),
			valueHolder: widgetObj.configuration,
			onChange: (v) => this.timePeriod = v,
			dynamicOnly: true
		})

		this.timePeriod = timePeriod
		this.timePeriodRoot = timePeriodRoot

		this.legendDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<Asset>',
			id: '<Asset>'
		}, {
			name: '<MonitorType>',
			id: '<MonitorType>'
		}, {
			name: '<Monitorname>',
			id: '<Monitorname>'
		}];


		this.renderLegendMultiSelect();
		this.initKendoComponents();

		const requiredFields = ['#cw_metrics_accountid', '#cw_metrics_assetid', '#cw_metrics_monitor_type', '#cw_metrics_monitor_id'];
		applyRequired(requiredFields);
	},

	renderLegendMultiSelect: function() {
		ReactDOM.render(<FormEntry label={lang.LEGEND} vertical={true}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_metrics_legend').parent().get()[0]);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_metrics_accountid'),
			container: $('#cw_metrics_account'),
			nextElement: $('#cw_metrics_assetid'),
			otherElements: [$('#cw_metrics_monitor_type'), $('#cw_metrics_monitor_id')],
			events: {
				change: (e) => {
					this.isInputChanged = true;
					this.currentAccountId = e.sender.value();
					this.assetSelector.dataSource.read();
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_asset'),
							label: lang.ASSET
						}, {
							container: $('#cw_metrics_mon_type'),
							label: lang.assethealth.MONITOR_TYPE
						}, {
							container: $('#cw_metrics_monitor'),
							label: lang.assethealth.MONITOR_NAME
						}],
						length: e.sender.dataSource.data().length
					});
				},
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_asset'),
							label: lang.ASSET
						}, {
							container: $('#cw_metrics_mon_type'),
							label: lang.assethealth.MONITOR_TYPE
						}, {
							container: $('#cw_metrics_monitor'),
							label: lang.assethealth.MONITOR_NAME
						}],
						length: length
					});
				}, this)
			}
		});

		this.chartType = $('#cw_chart_type').kendoDropDownList({
			dataSource: [{
				text: lang.AREA_CHART,
				value: 'area'
			}, {
				text: lang.AREASPLINE_CHART,
				value: 'areaspline'
			}, {
				text: lang.LINE_CHART,
				value: 'line'
			}, {
				text: lang.RANGE_CHART,
				value: 'arearange'
			}, {
				text: lang.SPLINE_CHART,
				value: 'spline'
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.chartType || 'line'
		}).data('kendoDropDownList');

		this.aggregationType = $('#cw_aggregation_type').kendoDropDownList({
			dataSource: aggregationTypeDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.aggregationType || 'high'
		}).data('kendoDropDownList');

		this.assetSelector = $('#cw_metrics_assetid').kendoComboBox({
			dataSource: new CeeViewDataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_mon_type'),
							label: lang.assethealth.MONITOR_TYPE
						}, {
							container: $('#cw_metrics_monitor'),
							label: lang.assethealth.MONITOR_NAME
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/assets/lite';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(this.onMetricsAssetSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			//autoBind: false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
				this.currentAssetId = e.sender.value();
				this.monitorTypeSelector.dataSource.read();
				this.emptyComponent({
					containers: [{
						container: $('#cw_metrics_mon_type'),
						label: lang.assethealth.MONITOR_TYPE
					}, {
						container: $('#cw_metrics_monitor'),
						label: lang.assethealth.MONITOR_NAME
					}],
					length: e.sender.dataSource.data().length
				});
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isAccountChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isAccountChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		if (this.mode === 'update' || this.wasUpdate) {
			if (widgetObj.configuration.accountId && widgetObj.configuration.assetId) {
				var monitorTypesDataSource = new CeeViewDataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_metrics_monitor'),
								label: lang.assethealth.MONITOR_NAME
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + (this.currentAccountId || widgetObj.configuration.accountId) + '/assets/' + (this.currentAssetId || widgetObj.configuration.assetId) + '/health/monitors/types/',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
			}
		}

		this.monitorTypeSelector = $('#cw_metrics_monitor_type').kendoComboBox({
			dataSource: (this.mode === 'update' || this.wasUpdate) ? monitorTypesDataSource : [],
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(this.onMetricsMonitorTypeSelect, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			placeholder: lang.SELECT,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			//autoBind: true,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
				this.currentMonitorType = e.sender.value();
				this.monitorSelector.dataSource.read();
				this.emptyComponent({
					containers: [{
						container: $('#cw_metrics_monitor'),
						label: lang.assethealth.MONITOR_NAME
					}],
					length: e.sender.dataSource.data().length
				});
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isAssetSelected) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isAssetSelected) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		if (this.mode === 'update' || this.wasUpdate) {
			if (widgetObj.configuration.accountId && widgetObj.configuration.assetId && widgetObj.configuration.monitorType) {
				var monitorsDataSource = new CeeViewDataSource({
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + (this.currentAccountId || widgetObj.configuration.accountId) + '/assets/' + (this.currentAssetId || widgetObj.configuration.assetId) + '/health/monitors/types/' + (this.currentMonitorType || widgetObj.configuration.monitorType) + '/lite',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
			}

		}
		this.monitorSelector = $('#cw_metrics_monitor_id').kendoComboBox({
			dataSource: (this.mode === 'update' || this.wasUpdate) ? monitorsDataSource : [],
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(this.onMetricsMonitorSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			placeholder: lang.SELECT,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isMonitorTypeSelected) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isMonitorTypeSelected) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');


		$("#cw_metrics_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_metrics_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$('#cw_metrics_timezone').kendoComboBox({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			clearButton: false,
		});
	},
	/**
	 * Handler function for the click event on qualifier type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onPeriodToggle: function (value) {
		if (value === 'CUSTOM') {
			$('#cw_metrics_dates').removeClass('hide');
		} else {
			$('#cw_metrics_dates').addClass('hide');
		}
	},
	/**
	 * Handler function for the select event of the metrics asset dropdown list
	 * @param {Object} e The select event
	 */
	onMetricsAssetSelect: function (e) {
		if (e.item) {
			var monitorTypeCombo = $("#cw_metrics_monitor_type").data('kendoComboBox');
			var monitorCombo = $("#cw_metrics_monitor_id").data('kendoComboBox');
			var assetId = e.dataItem.id;
			if (assetId) {
				this.isAssetSelected = true;
				this.isInputChanged = true;
				monitorTypeCombo.enable(true);
				monitorTypeCombo.value('');
				var newMonitorTypeDataSource = new CeeViewDataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_metrics_mon_type'),
								label: lang.assethealth.MONITOR_TYPE
							}, {
								container: $('#cw_metrics_monitor'),
								label: lang.assethealth.MONITOR_NAME
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + this.widgetAccountId + '/assets/' + assetId + '/health/monitors/types/',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				monitorTypeCombo.setDataSource(newMonitorTypeDataSource);
				monitorTypeCombo.dataSource.read();
				monitorCombo.input.attr("placeholder", lang.SELECT);
				monitorCombo.enable(false);
			} else {
				monitorTypeCombo.input.attr("placeholder", lang.SELECT);
				monitorTypeCombo.enable(false);
				monitorCombo.input.attr("placeholder", lang.SELECT);
				monitorCombo.enable(false);

			}
		}
	},
	/**
	 * Handler function for the select event of the metrics monitor type dropdown list
	 * @param {Object} e The select event
	 */
	onMetricsMonitorTypeSelect: function (e) {
		if (e.item) {
			var monitorCombo = $("#cw_metrics_monitor_id").data('kendoComboBox');
			var monitorType = e.dataItem.value;
			var assetId = $("#cw_metrics_assetid").data('kendoComboBox').value();
			if (monitorType) {
				this.isMonitorTypeSelected = true;
				this.isInputChanged = true;
				monitorCombo.enable(true);
				monitorCombo.value('');
				var newMonitorDataSource = new CeeViewDataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_metrics_monitor'),
								label: lang.assethealth.MONITOR_NAME
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + this.widgetAccountId + '/assets/' + assetId + '/health/monitors/types/' + monitorType + '/lite',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				monitorCombo.setDataSource(newMonitorDataSource);
				monitorCombo.dataSource.read();
			} else {
				monitorCombo.input.attr("placeholder", lang.SELECT);
				monitorCombo.enable(false);
			}
		}
	},
	/**
	 * Handler function for the select event of the metrics monitor  dropdown list
	 * @param {Object} e The select event
	 */
	onMetricsMonitorSelect: function (e) {
		var monitorId = e.item.find('span').attr('id');
		var assetId = $("#cw_metrics_assetid").data('kendoComboBox').value();

	},
	findRecord: function (dataSource, id) {
		var record = null;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var r = dataSource.at(i);
			if (r.id === id) {
				record = r;
				break;
			}
		}
		return record;
	},
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var ignoreMissingData = $('#cw_ignore_missing_data').is(':checked'),
			showRegression = $('#cw_show_regression').is(':checked'),
			timezone = $('#cw_metrics_timezone').data('kendoComboBox').value();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'asset_metrics',
			title: title,
			configuration: {
				accountId: $('#cw_metrics_accountid').data('kendoComboBox').value(),
				ignoreMissingData: ignoreMissingData,
				showRegression: showRegression,
				timezone: timezone || Cookies.CeesoftTimezone,
				chartType: this.chartType.value(),
				aggregationType: this.aggregationType.value(),
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				labelTemplate: this.legendMultiList
			}
		};

		Object.assign(widgetObj.configuration, this.timePeriod)

		var assetCombo = $('#cw_metrics_assetid').data('kendoComboBox');
		var assetId = assetCombo.value();
		var assetName = assetCombo.text();
		var monitorTypeCombo = $('#cw_metrics_monitor_type').data('kendoComboBox');
		var monitorType = monitorTypeCombo.value();
		var monitorTypeName = monitorTypeCombo.text();
		var monitorCombo = $('#cw_metrics_monitor_id').data('kendoComboBox');
		var monitorId = monitorCombo.value();
		var monitorName = monitorCombo.text();
		var config = {
			assetId: assetId,
			assetName: assetName,
			monitorType: monitorType,
			monitorTypeName: monitorTypeName,
			monitorId: monitorId,
			monitorName: monitorName
		};
		jQuery.extend(widgetObj.configuration, config);
		return widgetObj;
	},
	isValid: function () {
		var valid = true;
		var validationMessage = '';
		var accountCombo = $('#cw_metrics_accountid').data('kendoComboBox');
		var assetCombo = $('#cw_metrics_assetid').data('kendoComboBox');
		var monitorTypeCombo = $('#cw_metrics_monitor_type').data('kendoComboBox');
		var monitorCombo = $('#cw_metrics_monitor_id').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(monitorCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_MONITOR;
		}
		if (!monitorTypeCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_MONITOR_TYPE;
		}
		if (!Utils.isGuid(assetCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ASSET;
		}
		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	},

	destroy: function(){
		this.timePeriodRoot?.unmount()
	}
});
