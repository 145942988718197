import {UrlBuilder} from "tools";

const root = '/services';

const i = require('core/localization').translator({
	'No service details permissions': {
		en: 'You don\'t have enough permissions to view this service',
		no: 'Du har ikke rettigheter til \u00e5 se tjenesten',
	}
})

export function getDescriptions(){
	return [{
		url: '/services',
		getModuleImport: () => import('./services-list/servicesListPage'),
		loadingIndicator: false,
		routeId: 'service'
	}, {
		name: 'service details',
		jspUrl: 'include/Service/ServiceDetails.jsp',
		url: root + '/:id',
		checkAccess: (app: any) => {
			if (!app.session.hasRole('SERVICE_READ')) {
				return i('No service details permissions')
			}
		},
		getModuleImport: () => import('./details'),
		routeId: 'service_details'
	}, {
		name: 'service designer',
		jspUrl: 'include/Service/Viewer.jsp',
		url: root + '/:id/viewer',
		bodyCssClass: 'cw_viewer_module',
		getModuleImport: () => import('./viewer'),
		routeId: 'service_viewer'
	}];
}

export type ServicesListPageArgs = {
	accountId?: string
	assetId?: string
	linkServiceId?: string
	serviceState?: string
	tags?: string[]
	showUntagged?: boolean,
	showGridView?: boolean,
}

export class ServicesRouter {
	static list(args?: ServicesListPageArgs) {
		let url = new UrlBuilder('/services')

		if (args) {
			url = url.add(args)
		}

		return url.build();
	}

	static details(serviceId: string) {
		return new UrlBuilder(root + '/' + serviceId)
			.build();
	}

	static viewer(serviceId: string, filters = {}) {
		return new UrlBuilder(root + '/' + serviceId + '/viewer')
			.add(filters)
			.build();
	}
}
