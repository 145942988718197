import {ApiRequest, ApiRequestObservable} from "framework/api";
import {ApplicationState} from 'framework/applicationState';
import {createModelSchemaWrapper} from "framework/serializr-integration";
import {list, primitive} from "serializr";
import {SaveTagsModel} from "./tagsModel";

export class TagsData {
	forceTeamTags: boolean;
	ignoreTags: boolean;
	showUntagged: boolean;
	tags: string[]
}

createModelSchemaWrapper(TagsData, {
	forceTeamTags: primitive(),
	ignoreTags: primitive(),
	showUntagged: primitive(),
	tags: list(primitive()),
})

export function getUserTags(){
	return new ApiRequest<TagsData>({
		url: `users/${ApplicationState.userId}/tags`,
		accountBased: true,
		method: 'GET',
		responseType: TagsData
	});
}

export function getTagList(){
	return new ApiRequestObservable<string[]>({
		url: `tags`,
		accountBased: true,
		method: 'GET',
		subaccountsFilter: true
	});
}

export function saveTags(model: SaveTagsModel) {
	return new ApiRequest<string[]>({
		url: `users/${ApplicationState.userId}/tags`,
		accountBased: true,
		method: 'POST',
		payload: model
	});
}
