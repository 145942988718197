import React from "react";
import PropTypes from 'prop-types';

import {DropDownList} from "controls/react/form";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {TeamApi} from "areas/administration/teams/api";
import {ErrorHandler, translator} from "core";
import ModalWindow from 'controls/modalWindow';
import TeamForm from 'areas/administration/teamForm';
import Utils from 'tools/utils';
import {Renderer} from 'tools/renderer';

const i = translator();


export default class TeamDropDown extends React.PureComponent {
	constructor(props) {
		super(props);
		this.config = {
			dataSource: TeamDropDown.getDataSource(this.props.accountId),
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: true,
			template: Renderer.templates.responsibleTeam,
			dataBound: () => {
				 if (!this.props.value) {
					 this.ref.select(0);
					 this.ref.trigger('change');
				 }else {
					 this.ensureValueIsInDataSource();
				 }
			 }
		};
	}

	onAdd = () => {
		this.props.getTeamDataSource(this.config.dataSource);
		if (State.mainApp.session.hasRole('TEAM_CREATE')) {
			var width = 400;
			this.newTeamWindow = new ModalWindow({
				title: lang.account.TEAM_CREATE,
				width: width,
				height: 567,
				minHeight: 567,
				url: 'include/Administration/TeamForm.jsp',
				refresh: $.proxy(function () {
					new TeamForm({
						id: null,
						mode: 'create',
						accountMode: this.props.accountMode,
						isFromAccount: true,
						accountId: this.props.accountId,
						accountName: this.props.accountName
					});
				}, this)
			});
			this.newTeamWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
	}

	static getDataSource(accountId) {
		return new CeeViewDataSource({
			transport: {
				read: {
					url: TeamApi.getLiteUrl(accountId),
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			sort: {field: 'name', dir: 'asc'},
			error: ErrorHandler.kendoServerError
		});
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		var currentDataSource = this.ref.dataSource.data();
		if (prevProps.accountId !== this.props.accountId) {
			this.ref.setDataSource(TeamDropDown.getDataSource(this.props.accountId))
		}

		if(prevProps.value != this.props.value ) {
			this.ref.value(this.props.value);
			await this.ensureValueIsInDataSource();
		}
	}

	async ensureValueIsInDataSource(){
		if(!this.props.value)
			return;

		let itemInDataSource = this.ref.dataSource.data().toJSON()
			.find( x => this.props.value == x.id);

		if(!itemInDataSource){
			//an item might not be in data source if a user from parent account set team id from his account and
			//then user from subaccount enters the form
			const result = await TeamApi.getAboveTeams(this.props.accountId, this.props.value)
			if (result.success && result.data) {
				itemInDataSource = result.data;
				this.ref.dataSource.add(result.data);
				this.ref.value(result.data.id);
			}
		}

		this.ref.value(this.props.value);
	}

	render() {
		const {config, combo, ...others} = this.props;
		return <DropDownList kendoRef={(ref) => this.ref = ref}
		                     config={this.config}
		                     onAdd={this.onAdd}
		                     combo={true}
		                     {...others}/>
	}
}

TeamDropDown.propTypes = {
	accountId: PropTypes.string,
	value: PropTypes.string,
}

TeamDropDown.defaultProps = {
	dataBound: () => {}
}
