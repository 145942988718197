import Cookies from "core/cookies";
import Lang from "./lang";

let sharedDictionary = {
  "create": {
    "no": "opprett"
  },
  "cancel": {
    "no": "avbryt"
  },
  "update": {
    "no": "oppdater"
  },
  "name": {
    "no": "navn"
  },
  "type": {
    "no": "type"
  },
  "value": {
    "no": "verdi"
  },
  "selector": {
    "no": "selektor"
  },
  "select": {
    "no": "velg"
  },
  "select...": {
    "no": "velg..."
  },
  "description": {
    "no": "beskrivelse"
  },
  "search": {
    "no": "søk"
  },
  "search...": {
    "no": "søk..."
  },
  "get": {
    "no": "hent"
  },
  "minimize": {
    "no": "minimere"
  },
  "maximize": {
    "no": "maksimere"
  },
  "default": {
    "no": "standard"
  },
  "next": {
    "no": "neste"
  },
  "previous": {
    "no": "forrige"
  },
  "create new": {
    "no": "opprett ny"
  },
  "favorites": {
    "no": "favoritter"
  },
  "delete": {
    "no": "slett"
  },
  "operator": {
    "no": "operator"
  },
  "from": {
    "no": "fra"
  },
  "to": {
    "no": "til"
  },
  "severity": {
    "no": "alvorlighetsgrad"
  },
  "authenticate": {
    "no": "autentiser"
  },
  "authenticating": {
    "no": "autentiserer"
  },
  "authentication": {
    "no": "autentisering"
  },
  "Asset Target": {
    "no": "ressursmål"
  },
  "Asset Group": {
    "no": "ressursgruppe",
    "en": "Assetgroup"
  },
  "Asset Groups": {
    "no": "ressursgruppe",
    "en": "Assetgroups"
  },
  "url": {
    "no": "url"
  },
  "port": {
    "no": "port"
  },
  "uptime": {
    "no": "oppetid"
  },
  "source": {
    "no": "kilde"
  },
  "reload": {
    "no": "last på nytt"
  },
  "configuration": {
    "no": "konfigurasjon"
  },
  "profile": {
    "no": "profil"
  },
  "clear results": {
    "no": "fjern resultater"
  },
  "there is no data available": {
    "no": "ingen data tilgjengelig"
  },
  "Service Element": {
    "no": "tjenesteelement",
    "en": "Service element"
  },
  "Service Qualifier": {
    "no": "tjenestepunkt",
    "en": "Servicequalifier"
  },
  "application": {
    "no": "applikasjon"
  },
  "Dirty check confirmation": {
    "en": "You have unsaved content. You will lose the changes if you click Close. Click Cancel and save the changes if you want to keep them.",
    "no": "Du har ulagret innhold. Du vil miste endringen hvis du klikker Lukk. Trykk avbryt og lagre endringen hvis du vil beholde dem."
  },
  "Service Model": {
    "no": "Tjenestemodell",
    "en": "Servicemodel"
  },
  "database": {
    "no": "database"
  },
  "driver": {
    "no": "driver"
  },
  "Connection String": {
    "no": "tilkoblingstreng",
    "en": "Connection string"
  },
  "generic": {
    "no": "generisk"
  },
  "general": {
    "no": "generell"
  },
  "monitor general": {
    "en": "general",
    "no": "standard"
  },
  "Monitor Settings": {
    "no": "Monitorinstillinger",
    "en": "Monitor settings"
  },
  "reason message": {
    "no": "årsaksmelding"
  },
  "Authentication Type": {
    "no": "autentiseringstype",
    "en": "Authentication type"
  },
  "select service": {
    "no": "Velg tjeneste"
  },
  "Exclude": {
    "no": "Eksluder"
  },
  "padding": {
    "no": "padding"
  },
  "dashboard": {
    "no": "dashboard"
  },
  "dashboards": {
    "no": "dashboards"
  },
  "authentications": {
    "no": "autentisering"
  },
  "calendar": {
    "no": "kalender"
  },
  "roles": {
    "no": "roller"
  },
  "templates": {
    "no": "maler"
  },
  "team": {
    "no": "gruppe"
  },
  "teams": {
    "no": "grupper"
  },
  "user": {
    "no": "Bruker"
  },
  "users": {
    "no": "Brukere"
  },
  "asset": {
    "no": "ressurs"
  },
  "assets": {
    "no": "ressurser"
  },
  "Health Reason": {
    "no": "helseårsak",
    "en": "Health reason"
  },
  "Health Reasons": {
    "no": "helseårsaker",
    "en": "Health reasons"
  },
  "monitor": {
    "no": "monitor"
  },
  "monitors": {
    "no": "monitorer"
  },
  "filter": {
    "no": "filter"
  },
  "filters": {
    "no": "filtre"
  },
  "management": {
    "no": "system"
  },
  "accounts": {
    "no": "kontoer"
  },
  "account": {
    "no": "konto"
  },
  "agent": {
    "no": "agent"
  },
  "agents": {
    "no": "agenter"
  },
  "integration": {
    "no": "integrasjon"
  },
  "repository": {
    "no": "pakkearkiv"
  },
  "modules": {
    "no": "komponenter"
  },
  "audit": {
    "no": "revisjon"
  },
  "Active Users": {
    "no": "aktive brukere",
    "en": "Active users"
  },
  "system messages": {
    "no": "systemmeldinger"
  },
  "incident": {
    "no": "sak"
  },
  "incidents": {
    "no": "saker"
  },
  "home": {
    "no": "hjem"
  },
  "infrastructure": {
    "no": "infrastruktur"
  },
  "about": {
    "no": "om"
  },
  "advanced": {
    "no": "avansert"
  },
  "enabled": {
    "no": "Aktiv"
  },
  "scale": {
    "no": "skaler"
  },
  "data is not available": {
    "no": "data is not available"
  },
  "loading...": {
    "no": "laster..."
  },
  "top": {
    "no": "topp"
  },
  "bottom": {
    "no": "bottom"
  },
  "none": {
    "no": "Ingen"
  },
  "position": {
    "no": "ingen"
  },
  "Include subaccounts": {
    "no": "Inkluder underkontoer"
  },
  "Show Untagged": {
    "no": "Vis uten etikett",
    "en": "Show untagged"
  },
  "Select Team": {
    "no": "Velg team",
    "en": "Select team"
  },
  "no data": {
    "no": "ingen data"
  },
  "Measuring Agent": {
    "no": "måleagent",
    "en": "Measuring agent"
  },
  "Measuring Target": {
    "no": "Mål",
    "en": "Measuring target"
  },
  "please fill in the field": {
    "no": "fyll inn feltet"
  },
  "The input value should be between {0} and {1}": {
    "no": "Sett inn en verdi mellom {0} og {1}"
  },
  "Template Asset": {
    "no": "ressursmal",
    "en": "Template asset"
  },
  "Warning threshold": {
    "no": "advarsel terskel"
  },
  "Critical threshold": {
    "no": "kritisk terskel"
  },
  "Warning severity": {
    "no": "Advarsel alvorlighet"
  },
  "Critical severity": {
    "no": "Kritisk alvorlighet"
  },
  "threshold": {
    "no": "terskelverdi"
  },
  "new monitor": {
    "no": "ny monitor"
  },
  "click to edit": {
    "no": "Klikk for å redigere"
  },
  "interface traffic": {
    "no": "interface traffic"
  },
  "trend": {
    "no": "trend"
  },
  "timeout (sec)": {
    "no": "tidsavbrudd (sek)"
  },
  "disable": {
    "no": "deaktiver"
  },
  "enable": {
    "no": "aktiver"
  },
  "Monitor Error": {
    "no": "monitorfeil",
    "en": "Monitor error"
  },
  "Please input Name...": {
    "no": "Skriv inn navn...",
    "en": "Please input name..."
  },
  "cost": {
    "no": "kost"
  },
  "Unexpected server error": {
    "no": "Uventet server feil"
  },
  "Not available": {
    "no": "Ikke tilgjengelig"
  },
  "Set in Maintenance": {
    "no": "Sett i vedlikehold",
    "en": "Set in maintenance"
  },
  "End Maintenance": {
    "no": "Avslutt vedlikehold",
    "en": "End maintenance"
  },
  "Set Tags": {
    "no": "Sett etikett",
    "en": "Set tag"
  },
  "Agent Name": {
    "no": "Agentnavn",
    "en": "Agent name"
  },
  "Measuring Asset": {
    "no": "Måle ressurs",
    "en": "Measuring asset"
  },
  "Asset Name": {
    "no": "Ressursnavn",
    "en": "Asset name"
  },
  "Monitor name": {
    "no": "Monitornavn"
  },
  "Monitor Type": {
    "no": "Monitortype",
    "en": "Monitor type"
  },
  "Target Name": {
    "no": "Målnavn"
  },
  "Target Type": {
    "no": "Måltype",
    "en": "Target type"
  },
  "Measuring Type": {
    "no": "Måletype",
    "en": "Measuring type"
  },
  "Monitor": {
    "no": "Monitor"
  },
  "Select Asset Group...": {
    "no": "Velg ressursgruppe...",
    "en": "Select assetgroup..."
  },
  "Select Asset...": {
    "no": "Velg ressurs...",
    "en": "Select asset..."
  },
  "Event State": {
    "no": "Hendelse",
    "en": "Event"
  },
  "Content is filtered": {
    "no": "Innholdet er filtrert"
  },
  "Agent ID": {
    "no": "Agent ID"
  },
  "External ID": {
    "no": "Ekstern ID"
  },
  "External Owner": {
    "no": "Ekstern eier",
    "en": "External owner"
  },
  "Identifier Name": {
    "no": "Identifikator navn",
    "en": "Identifier name"
  },
  "Subsystem": {
    "no": "Subsystem"
  },
  "Identifier Instance": {
    "no": "Identifikator instans",
    "en": "Identifier instance"
  },
  "Monitor Class": {
    "no": "Monitorklasse",
    "en": "Monitor class"
  },
  "Source ID": {
    "no": "Source ID"
  },
  "Target ID": {
    "no": "Target ID"
  },
  "Time Deleted": {
    "no": "Tid slettet",
    "en": "Time deleted"
  },
  "Time": {
    "no": "Tid"
  },
  "Date": {
    "no": "Dato"
  },
  "Message": {
    "no": "Melding"
  },
  "Suppression": {
    "no": "Undertrykk"
  },
  "Acknowledged at": {
    "no": "Bekreftet tid"
  },
  "Acknowledged by": {
    "no": "Bekreftet av"
  },
  "Assigned by": {
    "no": "Tildelt av"
  },
  "Acknowledged": {
    "no": "Bekreftet"
  },
  "Acknowledge": {
    "no": "Bekreft"
  },
  "Unacknowedge": {
    "no": "Ubekreft"
  },
  "Action Count": {
    "no": "Handlingsantall",
    "en": "Action count"
  },
  "In Maintenance": {
    "no": "Vedlikehold",
    "en": "In maintenance"
  },
  "Assigned Type": {
    "no": "Tildelt type",
    "en": "Assigned type"
  },
  "Assigned To": {
    "no": "Tildelt til",
    "en": "Assigned to"
  },
  "Assigned Time": {
    "no": "Tildelt tid",
    "en": "Assigned time"
  },
  "Assign": {
    "no": "Tildele"
  },
  "Unassign": {
    "no": "Fjern tildeling"
  },
  "New Monitor": {
    "no": "Ny monitor",
    "en": "New monitor"
  },
  "Asset target is in use": {
    "no": "Ressursmålet er i bruk"
  },
  "Please add targets to your asset": {
    "no": "Legg til mål for din ressurs"
  },
  "Select an Asset Group...": {
    "no": "Velg ressursgruppe...",
    "en": "Select assetgroup..."
  },
  "Select a Service...": {
    "no": "Velg en tjeneste...",
    "en": "Select service..."
  },
  "Select a Service Element...": {
    "no": "Velg tjenesteelement...",
    "en": "Select service element..."
  },
  "Select a Service Qualifier...": {
    "no": "Velg et tjenestepunkt",
    "en": "Select servicequalifier..."
  },
  "Select an Asset...": {
    "no": "Velg ressurs...",
    "en": "Select asset..."
  },
  "decimals": {
    "no": "Desimal"
  },
  "Cost": {
    "no": "Kostnad"
  },
  "Cost Model": {
    "no": "Kost Modell",
    "en": "Cost modell"
  },
  "Cost Store": {
    "no": "Kostnad Base",
    "en": "Cost store"
  },
  "Budget": {
    "no": "Budsjett"
  },
  "Expand": {
    "no": "Ekspander"
  },
  "Cost Sheet": {
    "no": "Kost ark",
    "en": "Cost sheet"
  },
  "Cost Type": {
    "no": "Kost type",
    "en": "Cost type"
  },
  "Time Range": {
    "no": "Tidsramme",
    "en": "Time range"
  },
  "Currency": {
    "no": "Valuta"
  },
  "Estimate": {
    "no": "Estimate"
  },
  "Select All": {
    "no": "Velg alle",
    "en": "Select all"
  },
  "Deselect All": {
    "no": "Fjern valgte",
    "en": "deselect all"
  },
  "Interval": {
    "no": "Intervall"
  },
  "Schedule": {
    "no": "Tidsplan"
  },
  "Collapse": {
    "no": "Kollaps"
  },
  "Select Severity": {
    "no": "Velg alvorlighet",
    "en": "Select severity"
  },
  "activate": {
    "no": "aktiver"
  },
  "deactivate": {
    "no": "inaktiver"
  },
  "Profile Name": {
    "no": "Profilnavn",
    "en": "Profile name"
  },
  "Edit Profile Name": {
    "no": "Rediger profilnavn",
    "en": "Edit profile name"
  },
  "Delete Profile": {
    "no": "Slett profil",
    "en": "Delete profile"
  },
  "Alert Message": {
    "no": "Varselmelding",
    "en": "Alert message"
  },
  "Default Configuration": {
    "no": "Standard konfigurasjon",
    "en": "Default donfiguration"
  },
  "Process Name": {
    "no": "Prosess navn",
    "en": "Process name"
  },
  "Command Line": {
    "no": "Kommandolinje",
    "en": "Command line"
  },
  "Process State": {
    "no": "Prosesstilstand",
    "en": "Process state"
  },
  "Profiles": {
    "no": "Profiler"
  },
  "N/A": {
    "no": "N/A"
  },
  "Menu": {
    "no": "Meny"
  },
  "Refresh Timer": {
    "no": "Oppdateringstid",
    "en": "Refresh time"
  },
  "Filter": {
    "no": "Filter"
  },
  "Timebar": {
    "no": "Tidsvelger"
  },
  "Required field": {
    "no": "Påkrevd felt"
  },
  "Time settings": {
    "no": "Tidsinnstillinger"
  },
  "Time Zone": {
    "no": "Tidssone",
    "en": "Time zone"
  },
  "Name exists": {
    "en": "An identical name exist on this account, please use another name.",
    "no": "Et identisk navn finnes på denne kontoen, bruk et annet navn."
  },
  "Agent in warning": {
    "no": "Agent har advarsel"
  },
  "Agent is down": {
    "no": "Agenten er nede"
  },
  "Qualifier error": {
    "no": "Tjenestepunkt feil"
  },
  "Asset in maintenance": {
    "no": "Ressursen er satt i vedlikehold"
  },
  "Agent in maintenance": {
    "no": "Agent er i vedlikehold"
  },
  "Service in maintenance": {
    "no": "Tjenesten er i vedlikehold"
  },
  "Monitor in maintenance": {
    "no": "Monitor in vedlikehold"
  },
  "Monitor error": {
    "no": "Monitorfeil"
  },
  "Outside operating period": {
    "no": "Utenfor driftstid"
  },
  "Monitor error: data unavailable": {
    "no": "Monitorfeil: Data er ikke tilgjengelig",
    "en": "Monitor error: Data unavailable"
  },
  "Account Name": {
    "no": "Kontonavn",
    "en": "Account name"
  },
  "custom": {
    "no": "Tilpasset"
  },
  "Unit type": {
    "no": "Enhetstype"
  },
  "Metric type": {
    "no": "Metrikktype"
  },
  "Registry identifier": {
    "no": "Register id"
  },
  "Registry type": {
    "no": "Registertype"
  },
  "Registry owner id": {
    "no": "Registereier id"
  },
  "Metric id": {
    "no": "Metrikk id"
  },
  "Data registry": {
    "no": "Data register"
  },
  "Max value": {
    "no": "Maks verdi"
  },
  "metrics": {
    "no": "metrikker"
  },
  "instance": {
    "no": "instans"
  },
  "datasource": {
    "no": "datakilde"
  },
  "Event summary": {
    "no": "Hendelse oversikt"
  },
"Display settings": {
	"no": "Skjerminnstillinger",
},
	"Presentation": {
		"no": "Presentasjon"
	},
	'Time selector': {
		no: 'Tidsvelger'
	},
}; //do not remove the semicolon or Localization parser would not pick up the dictionary

export class Localization {
	constructor(...args) {
		this.dictionary = {};
		for(let dictionary of args){
			if( dictionary?.dictionary ){
				this.dictionary = {...this.dictionary, ...dictionary.dictionary};
			}else{
				this.dictionary = {...this.dictionary, ...dictionary};
			}
		}
	}

	get() {
		let key = arguments[0];

		let replacementsIndex = 1;

		let options = {};
		if( arguments.length > 1 && typeof arguments[1] === 'object'){
			options = arguments[1];
			replacementsIndex ++;
		}

		const lowerCaseKey = key.toLowerCase();
		const keyCapitalized = lowerCaseKey != key;

		options = Object.assign({
			capitalize: keyCapitalized
		}, options);


		let value = this.tryDictionary(this.dictionary, key);

		if (value == null) {
			value = this.tryDictionary(this.dictionary, lowerCaseKey)
		}

		if (value == null) {
			value = this.tryDictionary(sharedDictionary, key)
		}

		if (value == null) {
			value = this.tryDictionary(sharedDictionary, lowerCaseKey)
		}

		if (value == null) {
			value = Lang[key.replace('-', '_').toUpperCase()];
		}


		if (value != null) {
			if (options.capitalize) {
				value = value.charAt(0).toUpperCase() + value.slice(1);
			}
		}

		if( value == null ){
			value = key;
			console.error('No localization found for ' + key);
		}

		if( arguments.length > replacementsIndex){
			value = this.formatString(value, Array.prototype.slice.call(arguments, replacementsIndex));
		}

		return value;
	}

	tryDictionary(dictionary, key){
		if (dictionary[key] == null)
			return null;

		const entry = dictionary[key];
		if( entry[this.getLanguageKey()] != null )
			return entry[this.getLanguageKey()];

		if (this.getLanguageKey() == 'en'){
			return key;
		}

		return null;
	}

	getLanguageKey() {
		return Cookies.CeesoftUserLocale == 'no' ? 'no' : 'en';
	}

	formatString(value, replacements){
		return value.replace(/{(\d+)}/g, function(match, number) {
			return typeof replacements[number] != 'undefined'
				? replacements[number]
				: match;
		});
	}

	getTranslator(){
		return this.get.bind(this);
	}
}

export let translator = function (...args) {
	const localization = new Localization(...args);
	return localization.getTranslator();
}

export default translator;

export let i = translator();

export function getNumericTranslation(count, singularForm, pluralForm){
	return count == 1 ? singularForm : pluralForm;
}

export function getNumericTranslationStart(count, singularForm, pluralForm){
	return count + ' ' + getNumericTranslation(count, singularForm, pluralForm)
}

