import React from "react";
import {createRoot} from 'react-dom/client';
import {translator} from "core";
import {Grid} from "controls/react/form";
import {Window} from 'controls/react/kendoWrappers/window';
import Settings from 'settings';
import Cookies from 'core/cookies';
import Api from 'tools/api';
import WidgetInfoContainer from "controls/react/widgetInfoContainer";
import {MetricLegendContainer} from './metricLegendContainer'
import RemoteEventsManager from 'core/remoteEventsManager';
import {ApplicationState} from 'framework/applicationState';

import './metricTableDataWindow.less'
import { extractQualifierSubscriptionFields } from "areas/service-boards/widgets/customMetrics/metricSelector";
import {
	areTheSame,
	renderIntoLegacy,
	timePeriodToUrl
} from "./react/form/timePeriodSelector";
import {newGuid} from "../tools/guid";
import {formatNumber} from "../tools/helpers/math";
import {TimePeriodType} from "controls/react/form/timePeriodType";

let i = translator({
  "Metrics data": {
    "no": "Metrikk data",
    "en": "Metric data"
  }
});

const b = require('b_').with('metric-table-data-window');

export class MetricTableDataWindow extends React.PureComponent {
	timePeriod = {period: TimePeriodType.LastHour}
	timePeriodSetValue = (value) => {}
	infoContainerRoot = {}

	gridDataSource;
	constructor(props) {
		super(props);
		this.state = {
			gridShown: true
		};
		this.metricId = this.props.metricId;
		this.windowActions = this.props.actions || ['Close']
		this.timePeriod = this.props.defaultTimeSelectorValue ?? {period: TimePeriodType.LastHour};
	}

	updateTimePeriod = (period, periodZoomed) => {
		const effectiveTimePeriod = periodZoomed ?? period
		if(areTheSame(this.timePeriod, effectiveTimePeriod))
			return

		this.timePeriodSetValue(effectiveTimePeriod);
		this.onTimePeriodChange(effectiveTimePeriod);
	}

	render() {
		let content;
		if (this.props.metricId && this.state.gridShown) {
			content = <div className={b('content')}>
			<Grid
				dataSource={this.gridDataSource}
				columns={this.getGridColumns()}
				containerClass={b('grid-container')}
				scrollable={{virtual: true}}
				dynamicFit={true}
				serverSorting={true}
				serverPaging={true}
				serverFiltering={true}
				skipSelectorColumn={true}
				noRecords={{template: i('No data')}}
				ref={grid => this.grid = grid}
			>
			</Grid>
			<MetricLegendContainer className={b('legend')} data={this.state.legendData} timeZone={this.props.timeZone}/>
			</div>
		} else {
			content = <div className="no-data-placeholder"><span>{i('No data')}</span></div>;
		}

		if(this.props.window != true)
			return content;

		if (this.props.context) {
			let openedTableWindows = this.props.context.openedTableDataWindowIds || [];
			let index = openedTableWindows.indexOf(this.metricId);
			this.isWindowOpened = index !== -1 ? true : false;
		} else {
			this.isWindowOpened = true;
		}

		let windowDimensions = this.getWindowDimensions();

		return (
			<div>
				{this.isWindowOpened && <Window height={windowDimensions.windowHeight} flexContent={true}
						width={windowDimensions.windowWidth}
						title={this.props.title}
						top={windowDimensions.windowTop}
						left={windowDimensions.windowLeft}
						minHeight={100}
						minWidth={300}
						actions={this.windowActions}
						className={b()}
						ref={window => this.window = window}
						onClose={() => this.hideTableDataWindow()}>
					{(!this.metricId || !!this.state.legendData) && content}
				</Window>}
			</div>
		)
	}

	async componentDidMount() {
		this.gridDataSource = this.getGridDataSource();
		if (this.metricId) {
			await this.renderInfoContainer();
		}

		if (!this.props.window || this.window) {
			this.renderTimeSelector();
			this.renderInfoSign();
		}
		if(this.window) {
			let htmlClass = 'table' + this.metricId;
			this.window.window.wrapper.addClass(htmlClass);
			if (this.props.cacheWindow) {
				this.props.cacheWindow(this.window.window, this.props.metricId);
			}
		}
		this.subscribe();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.defaultTimeSelectorValue != this.props.defaultTimeSelectorValue) {
			this.renderTimeSelector();
		}
		if(prevProps.timeSelectorAppearance != this.props.timeSelectorAppearance) {
			this.renderTimeSelector();
		}
	}

	getWindowDimensions() {
		let windowWidth = this.props.width || 600;
		let windowHeight = this.getContentHeight() + 62;
		let windowTop = this.props.coordinates?.top || 150;
		let windowLeft = this.props.coordinates?.left || 350;

		if (!this.props.skipDimensionsAdjust) {
			let maxLeft = 0;
			let maxTop = 0;
			$('.k-widget.k-window').each(function() {
				let currentTop = $(this).offset().top;
				let currentLeft = $(this).offset().left;
				if (currentTop >= maxTop) {
					maxTop = currentTop;
					maxLeft = currentLeft;
					if (currentLeft > maxLeft) {
						maxLeft = currentLeft;
					}
				}
			});

			if (maxLeft && maxTop) {
				windowTop = maxTop;
				windowLeft = maxLeft + windowWidth;
				if (windowLeft + windowWidth > window.innerWidth) {
					windowLeft = 350;
					windowTop = maxTop + windowHeight;
					if (windowTop + windowHeight > window.innerHeight) {
						windowTop = window.innerHeight - windowHeight;
					}
				}
			}
		}

		return {
			windowTop,
			windowLeft,
			windowHeight,
			windowWidth
		}
	}

	getContentHeight(){
		return this.props.height || 220;
	}

	getConversion() {
		const {conversion} = this.props;
		if(!conversion) {
			return {}
		}
		const result = {
			conversionUnit: conversion.unit
		}
		if(conversion.unit === 'CUSTOM') {
			result.conversion = conversion.formula
			result.customUnit = conversion.unitLabel
		}
		return result
	}

	dataSource = null
	getGridDataSource() {
		if(this.dataSource == null) {
			let periodTranslationObj = {
				'LASTHOUR': 'LASTDAY',
				'LASTDAY': 'LAST7DAYS',
				'LAST7DAYS': 'LAST30DAYS'
			};

			this.dataSourceUrl = this.getDataSourceUrl();
			const oThis = this;
			this.dataSource = new kendo.ceeview.DataSource({
				transport: {
					read: (options) => {
						$.ajax({
							url: this.dataSourceUrl,
							contentType: "application/json; charset=utf-8",
							type: "POST",
							dataType: "json",
							cache: false,
							data: kendo.stringify({
								...this.getConversion(),
								take: options.data.take,
								skip: options.data.skip,
								page: options.data.page,
								pageSize: options.data.pageSize,
								sort: [{
									field: 't', dir: 'desc'
								}]
							}),
							processData: false,
							success(result) {
								options.success(result);

								oThis.setState({
									unitType: result.unitType
								}, () => {
									oThis.subscribe()
								})
							},
							error: () => {
								this.setState({
									gridShown: false
								});
							}
						});
					},
				},
				pageSize: 200,
				serverPaging: true,
				serverSorting: true,
				schema: {
					data: $.proxy(function (response) {
						if (response.items && !response.items.length && !this.doNotTranslatePeriod) {
							let newTimeSelector = periodTranslationObj[this.currentTimeSelector];
							if (newTimeSelector) {
								this.timePeriod = {period: newTimeSelector};
								this.timePeriodSetValue(newTimeSelector)
								this.dataSourceUrl = this.getDataSourceUrl();
								this.grid.kendoGrid.dataSource.read();
							}
						}
						return response.items || [];
					}, this),
					total: function (response) {
						this.visibleItems = response.visible;
						this.totalItems = response.total;
						return response.visible;
					}
				},
				sort: {
					field: 't',
					dir: 'desc'
				},
				useRanges: true,
				virtual: true
			});
		}

		return this.dataSource
	}

	columns = null
	getGridColumns() {
		if (this.columns == null) {
			this.columns = [{
				field: 't',
				title: lang.TIME,
				template: '${Renderer.browserDateRenderer(t, "datetime", null, "' + this.props.timeZone + '")}',
				width: 190
			}, {
				field: 'v',
				title: lang.VALUE,
				template: (data) => {
					if (data.err) {
						if (data.err[0] !== 0) {
							return '<span class="cw_label cw_label_error">' + lang.ERROR + '</span>';
						}
					}
					if (data.e) {
						if (data.e[0] !== 0) {
							return '<span class="cw_label cw_label_error">' + lang.ERROR + '</span>';
						}
					}
					if (data.v === null) {
						return lang.NA;
					} else {
						const decimals = this.props.conversion?.decimals
						const number = (decimals || decimals === 0) ? formatNumber(data.v, decimals) : data.v
						return `${number} ${data.u}`
					}
				},
				width: 100
			}];
		}
		return this.columns
	}

	async renderInfoContainer() {
		let url = `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/metrics/registeredMetrics`;
		let result = await Api.fetchPost(url, [this.metricId]);

		return new Promise(resolve => {
			this.setState({
				legendData: result[0]
			}, () => {
				resolve()
			})
		})
	}

	onTimePeriodChange = (value) => {
		const defaultPeriod = typeof this.props.defaultTimeSelectorValue == 'string'
			? {period: this.props.defaultTimeSelectorValue}
			: this.props.defaultTimeSelectorValue;

		this.timePeriod = Object.assign({}, defaultPeriod ?? {}, value);
		this.dataSourceUrl = this.getDataSourceUrl();
		this.grid?.kendoGrid?.dataSource.read();
		if (this.props.onWidgetPeriodChanged) {
			this.props.onWidgetPeriodChanged?.(this.timePeriod)
		} else {
			this.renderTimeSelector();
		}
	}

	getDataSourceUrl = () => {
		const urlParams = timePeriodToUrl(this.timePeriod);
		return `${Settings.serverPath}sessions/${ApplicationState.apiToken}/metrics/${this.metricId}?${urlParams}`;
	}

	renderTimeSelector() {
		let periodToggleTarget
		this.target = this.window?.window.wrapper ?? $('.metric-table-widget');
		if(!this.timePeriodRoot) {
			if(this.props.timeSelectorContainer) {
				periodToggleTarget = $(this.props.timeSelectorContainer)
				periodToggleTarget.empty()
			} else {
				const windowTitle = this.target.find('.k-window-titlebar')
				windowTitle
					.find(".period_multi_toggle, #period_multi_toggle")
					.remove();

				windowTitle.append('<div class="period_multi_toggle"></div>');
				periodToggleTarget = windowTitle.find(".period_multi_toggle");
			}
		}

		const [timePeriod, timePeriodRoot] = renderIntoLegacy({
			periods: [TimePeriodType.LastHour, TimePeriodType.Last24Hours, TimePeriodType.Last7Days, TimePeriodType.Last30Days, TimePeriodType.DynamicInterval, TimePeriodType.Custom],
			container: periodToggleTarget?.get(0),
			size: 'small',
			appearance: this.props.timeSelectorAppearance,
			valueHolder: this.timePeriod,
			onChange: this.onTimePeriodChange,
			setValueCallback: this.setTimePeriodSetValue,
			root: this.timePeriodRoot
		})

		this.timePeriod = timePeriod;
		this.timePeriodRoot = timePeriodRoot;
	}

	setTimePeriodSetValue = (value) => {
		this.timePeriodSetValue = value
	}

	infoSignContainer() {
		if (this.props.infoSignContainer) {
			return $(this.props.infoSignContainer);
		}
		return this.target;
	}

	renderInfoSign() {
		const target = this.infoSignContainer();
		target.append('<span class="cw_widget_info_sign glyphicons info-sign"></span>');
		target.find('.cw_widget_info_sign').on('mouseover', (e) => this.onInfoSignHover(e));
		target.find('.cw_widget_info_sign').on('mouseout', this.onInfoSignHoverOut);
		this.truncateWindowTitle();
	}

	onInfoSignHover(e) {
		let coordinates = e.target.getBoundingClientRect();
		const infoContainerRoot = createRoot(e.target);
		const rootId = newGuid();
		$(e.target).data('root-id', rootId);
		this.infoContainerRoot[rootId] = infoContainerRoot;
		infoContainerRoot.render(<WidgetInfoContainer
			data={this.state.legendData}
			type={'preloaded'}
			coordinates={coordinates}
		/>)
	}

	truncateWindowTitle() {
		if(!this.props.window) {
			const toolbar = $('.metric-table-widget>.toolbar')
			if (toolbar.length) {
				toolbar.css({paddingLeft: '20px'})
			}
			return;
		}

		let timeSelectorPosition = this.target.position().left;
		let titleContainer = this.target.find('.k-window-title');
		titleContainer.css('width', timeSelectorPosition - 30);
		this.target.find('.cw_widget_info_sign').css({right: 55, top: 7});
	}

	onInfoSignHoverOut = (e) => {
		const rootId = $(e.target).data('root-id');
		this.infoContainerRoot[rootId]?.unmount();
		this.infoContainerRoot[rootId] = undefined;
	}

	hideTableDataWindow () {
		this.doNotTranslatePeriod = false;
		if (this.props.hideWindow) {
			this.window.window.wrapper.find('.k-grid').remove();
			this.props.hideWindow(this.metricId);
		}
		if (this.props.destroyWindow) {
			this.props.destroyWindow(this.props.containerId);
		}
		if (this.props.unsubscribeWindow) {
			this.props.unsubscribeWindow();
		}
		if (this.props.context) {
			this.props.context.hideTableDataWindow(this.props.metricData);
		}
	}

	resizeTableDataWindow() {
		let windowContainer = this.target.find('.k-window-content');
		let windowHeight = windowContainer.height();
		let gridContainer = this.target.find('.k-grid');
		let grid = gridContainer.data('kendoCustomGrid');
		grid.setOptions({
			height: windowHeight - 30
		});
		this.truncateWindowTitle();
	}

	subscribe() {
		if(this.subscription)
			return

		if(!this.state.legendData || !this.state.unitType)
			return

		let subscriptionObj = [{
			eventType: 'Metric',
			releaseEvents: true,
			qualifierId: this.metricId,
			...extractQualifierSubscriptionFields({...this.state.legendData, unitType: this.state.unitType})
		}];
		this.subscription = RemoteEventsManager.subscribeCallback(subscriptionObj, _.throttle(this.onEvents, 2000));
	}

	onEvents = events => {
		if(!events?.metric)
			return;
		this.grid?.kendoGrid.dataSource.insert(0, events.metric);
	}

	onEventsTimeout() {
		this.refreshGridOnEvent();
	}

	refreshGridOnEvent() {
		this.grid?.kendoGrid.dataSource.read();
	}

	removeTimeSelector() {
		this.timePeriodRoot?.unmount();
	}

	destroy () {
		this.removeTimeSelector();
		Object.keys(this.infoContainerRoot).forEach(key => {
			this.infoContainerRoot[key].unmount();
		});

		this.startTimePicker?.destroy();
		this.endTimePicker?.destroy();
		this.customTimeSelectorContainer?.remove();
	}
}
