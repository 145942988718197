export function handleCheckAll(grid, props) {
	return (e) => {
		grid.wrapper.find('.cw_grid_check_all').on('click', function(ev) {
			var checked = ev.target.checked;
			grid.wrapper.find('.cw_grid_check').each(function (idx, item) {
				if (checked) {
					if (!($(item).is(':checked'))) {
						$(item).click();
					}
				} else {
					if ($(item).is(':checked')) {
						$(item).click();
					}
				}
			});
		});
	}
}
