import React from 'react';
import { Toolbar, ToolbarItemPosition } from 'controls/react/layout/toolbar';
import { Section } from 'controls/react/layout/section';
import { AntInput, AntTextArea } from 'controls/react/ant/antInput';
import { FormEntryNew } from 'controls/react/form/formEntryNew';
import { SeverityIndicator } from 'controls/react/severityIndicator';
import { healthDataToFlags } from 'framework/entities/healthData';
import { NavigationStore } from 'framework/navigationStore';
import { AntAlert } from 'controls/react/ant/antAlert';
import { EventsSummaryPageContext } from './eventsSummaryPageStore';
import { observer } from 'mobx-react';
import { getSourceEntryDescription, getTargetEntryDescription } from './eventsSummaryStore';
import './eventsSummaryDetails.less'
import { AgentsRouter } from 'areas/management/agents/bundleDescription';
import {GridMenuButton} from "controls/grid/plugins/grid-menu/gridMenuButton";
import {AntModal} from "controls/react/ant/antModal";
import {EventsSummaryEntry} from "areas/summary/events/eventsSummaryEntry";
import moment from "moment/moment";
import {ApplicationState} from "framework/applicationState";
import {getTzOffsetDiffs} from "tools/dateTimeUtils";

const b = require('b_').with('event-summary-details');

const i18n = require('core/localization/localization').translator({
  "Assigned": {
    "no": "Tildelt"
  },
  "Counters": {
    "no": "Tellere"
  },
  "Event Details": {
    "no": "Hendelse detaljer",
    "en": "Event details"
  }
});

class EventSummaryDetailsModel extends EventsSummaryEntry {
	constructor(data: EventsSummaryEntry) {
		super();
		Object.assign(this, data);
	}

	get userTime() {
		return this.convertToUserTime(this.time);
	}
	get userCreated() {
		return this.convertToUserTime(this.timeCreated);
	}
	get userDeleted() {
		return this.convertToUserTime(this.timeDeleted);
	}
	get userAssignedTime() {
		return this.convertToUserTime(this.assignedTime);
	}
	get userAcknowledgedAt() {
		return this.convertToUserTime(this.acknowledgedAt);
	}

	private convertToUserTime(time?: number): string {
		if (!time) {
			return '';
		}
		return moment(time)
			.add(getTzOffsetDiffs(ApplicationState.timezone, moment.tz.guess()), 'minutes')
			.format(ApplicationState.dateTimeFormat);
	}
}


const EventSummaryDetails = observer(() => {
	const store = React.useContext(EventsSummaryPageContext);
	const selectedItem = store.gridStore.selection.dataset.items[0];
	const pageModel = new EventSummaryDetailsModel(selectedItem?.data);
	if (!store.gridStore.selection.isSelected(selectedItem) || store.gridStore.selection.selectedItemsCount > 1) {
		store.gridStore.selection.clear();
		store.gridStore.selection.toggleSelected(selectedItem);
	}
	const navigate = (url: string) => {
		store.showDetailsModal = false;
		store.cleanUpUiOnActionCompleted();
		NavigationStore.go(url);
	}

	return <AntModal
		maskClosable={false}
		open={true}
		title={i18n("Event Details")}
		width={1000}
		height={700}
		cancelText={i18n('Close')}
		onCancel={() => {
			store.showDetailsModal = false;
			store.cleanUpUiOnActionCompleted(store.gridStore.selection);
		}}
		defaultFooterButtons={['cancel']}
		rootClassName={b()}
	>
		<Section containerClass={b('content')} childrenPadding={true} height={"fit-expand-only"} scrollable direction={"row"} width={"full"} margin={"top"}>
			<Toolbar title={"Event Details"}>
				<GridMenuButton store={store.gridStore}/>
				<SeverityIndicator position={ToolbarItemPosition.BEFORE_TITLE} {...healthDataToFlags(pageModel)}/>
				{pageModel?.inMaintenance &&  <AntAlert type={"info"}
														showIcon={true}
														message={i18n('In Maintenance')}/>}
				{pageModel?.historic && <AntAlert type={"info"}
												  showIcon={true}
												  message={i18n('Historic Event')}/>}
			</Toolbar>
			<Section appearance={"none"} childrenPadding={true} width={"threeQuarter"}>
				<Section appearance={"none"} direction={"row"} childrenPadding={true}>
					<Section appearance={"frame-top-only"} title={i18n('General')} childrenPadding={true} width={"oneThird"}>
						<FormEntryNew label={i18n('Name')} model={pageModel} modelField={'sourceName'}>
							<AntInput className={b('link')} readOnly={true}  onClick={() => {navigate(getSourceEntryDescription(pageModel)[0])}}/>
						</FormEntryNew>
						<FormEntryNew label={i18n('Type')} model={pageModel} modelField={'typeText'}>
							<AntInput readOnly={true}/>
						</FormEntryNew>
						<FormEntryNew label={i18n('Target')} model={pageModel} modelField={'targetName'} >
							<AntInput className={b('link')} readOnly={true} onClick={() => {navigate(getTargetEntryDescription(pageModel)[0])}}/>
						</FormEntryNew>
						<FormEntryNew label={i18n('Agent Name')} model={pageModel} modelField={'agentName'}>
							<AntInput className={b('link')} readOnly={true} onClick={() => pageModel.agentId ? navigate(AgentsRouter.details(pageModel.agentId)) : false}/>
						</FormEntryNew>
						<FormEntryNew label={i18n('Account')} model={pageModel} modelField={'accountName'}>
							<AntInput readOnly={true}/>
						</FormEntryNew>
						<FormEntryNew label={i18n('Tags')} model={pageModel} modelField={'tags'}>
							<AntInput readOnly={true}/>
						</FormEntryNew>
					</Section>
					<Section appearance={"none"}  width={"oneThird"} childrenPadding={true}>
						<Section appearance={"frame-top-only"} title={i18n('Time')} childrenPadding={true} >
							<FormEntryNew label={i18n('Time')} model={pageModel} modelField={'userTime'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
							<FormEntryNew label={i18n('Created')} model={pageModel} modelField={'userCreated'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
							<FormEntryNew label={i18n('Time Deleted')} model={pageModel} modelField={'userDeleted'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
						</Section>
						<Section appearance={"frame-top-only"} title={i18n('Assigned')} childrenPadding={true} >
							<FormEntryNew label={i18n('Assigned To')} model={pageModel} modelField={'assignedTo'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
							<FormEntryNew label={i18n('Assigned Time')} model={pageModel} modelField={'userAssignedTime'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
							<FormEntryNew label={i18n('Assigned Type')} model={pageModel} modelField={'assignedToType'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
						</Section>
					</Section>
					<Section appearance={"none"}  width={"oneThird"} childrenPadding={true}>
						<Section appearance={"frame-top-only"} title={i18n('Acknowledged')} childrenPadding={true} >
							<FormEntryNew label={i18n('Acknowledged By')} model={pageModel} modelField={'acknowledgedBy'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
							<FormEntryNew label={i18n('Acknowledged At')} model={pageModel} modelField={'userAcknowledgedAt'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
							<FormEntryNew label={i18n('Acknowledged')} model={pageModel} modelField={'acknowledged'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
						</Section>
						<Section appearance={"frame-top-only"} title={i18n('Counters')} childrenPadding={true} >
							<FormEntryNew label={i18n('Suppression')} model={pageModel} modelField={'suppressionCount'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
							<FormEntryNew label={i18n('Action Count')} model={pageModel} modelField={'actionCount'}>
								<AntInput readOnly={true}/>
							</FormEntryNew>
						</Section>
					</Section>
				</Section>
				<Section appearance={"frame-top-only"} title={i18n('Description')} childrenPadding={true} direction={"row"}>
					<FormEntryNew label={i18n('Description')} model={pageModel} modelField={'details'} className='w100'>
						<AntTextArea height={80} readOnly={true}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Information')} model={pageModel} modelField={'information'} className='w100'>
						<AntTextArea height={80} readOnly={true}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Message')} model={pageModel} modelField={'message'} className='w100'>
						<AntTextArea height={80} readOnly={true}/>
					</FormEntryNew>
				</Section>
			</Section>
			<Section appearance={"none"}  width={"quarter"}>
				<Section appearance={"frame-top-only"} title={i18n('Advanced')} childrenPadding={true}>
					<FormEntryNew label={i18n('Target ID')} model={pageModel} modelField={'targetId'}>
						<AntInput readOnly={true}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Agent ID')} model={pageModel} modelField={'agentId'}>
						<AntInput readOnly={true}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Identifier Name')} model={pageModel} modelField={'identifierName'}>
						<AntInput readOnly={true}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Identifier Instance')} model={pageModel} modelField={'identifierInstance'}>
						<AntInput readOnly={true}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Source ID')} model={pageModel} modelField={'sourceId'}>
						<AntInput readOnly={true}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('External Owner')} model={pageModel} modelField={'externalOwner'}>
						<AntInput readOnly={true}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('External ID')} model={pageModel} modelField={'externalId'}>
						<AntInput readOnly={true}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Asset Group')} model={pageModel} modelField={'groups'}>
						<AntInput readOnly={true}/>
					</FormEntryNew>
				</Section>
			</Section>
		</Section>
	</AntModal>
})
export default EventSummaryDetails
