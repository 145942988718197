import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import {BoxView} from "controls/react/layout/boxView";

import {translator} from "core/localization/localization";

import "./tagFilterEnabledIndicator.less";
import IconButton from "../controls/react/form/iconButton";
import classnames from 'classnames';
import { ApplicationState } from "framework/applicationState";

const i = translator({
  "Tags are active": {
    "no": "Etiketter er aktive"
  },
  "Tags are active and content is filtered": {
    "no": "Etiketter er aktive og innholdet er filtrert"
  },
  "Click to clear filtering": {
    "no": "Klikk for å fjerne filtrering"
  },
  "Active tags": {
    "no": "Aktive etiketter",
    "en": "Active tags"
  }
});

const b = require('b_').with('tags-filter-indicator');

export default function TagFilterEnabledIndicator(props) {
	const activeTagsString = ApplicationState.userTags.join(', ');
	let icon, message;
	let tooltip = '';

	if (ApplicationState.tagsEnabled) {
		icon = 'tags';
		if (props.filtersEnabled) {
			message = i('Tags are active and content is filtered');
			tooltip = i('Click to clear filtering');
			if (activeTagsString) {
				tooltip += '. ' + i('Active tags') + ': ' + activeTagsString;
			}
		} else {
			message = i('Tags are active');
			if (activeTagsString) {
				tooltip = i('Active tags') + ': ' + activeTagsString;
			}
		}
	} else {
		if (props.filtersEnabled) {
			icon = 'filter';
			message = i('Content is filtered');
			tooltip = i('Click to clear filtering');
		} else {
			return null;
		}
	}


	const containerClasses = classnames(b(), 'pointer', 'tagFilterEnabledIndicator');

	return <BoxView type={"info-2"}
	                rounded={true}
	                border={true}
					containerClass={containerClasses}
					title={tooltip}
					onClick={props.onClick}>
		<IconButton iconName={icon}
					embedded={true}/>
		<div>{message}</div>
	</BoxView>
}

TagFilterEnabledIndicator.propTypes = {
	filtersEnabled: PropTypes.bool.isRequired,
	onClick: PropTypes.func
}
