import './homeView.less'

import {throttle} from 'lodash';
import {autorun} from 'mobx';
import React from "react";

import {Application, Cookies, ErrorHandler, RemoteEventsManager} from 'core'
import {Renderer, State, UserPrefs, Utils} from 'tools';
import {Settings} from "settings";
import {PreferencesPanel, EventsToolbar} from "controls";
import {translator} from "core";
import {createDataSource} from "tools/utils";
import {ServicesRouter} from 'areas/services/bundleDescription';
import {getServiceState} from 'controls/stateRenderer/serviceState';
import {getHealthStateHome} from 'controls/stateRenderer/healthState';
import {getSlaState} from 'controls/stateRenderer/slaState'
import {getAgentStateHome} from 'controls/stateRenderer/agentState';
import {ApplicationsRouter} from "areas/application/bundleDescription";

import {HomeViewWidgets} from "areas/views/homeViewWidgets";
import {HomeViewSettings, HomeViewStore} from "areas/summary/homeViewSettings";
import {NavigationStore} from "../../framework/navigationStore";
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import {createRoot} from "react-dom/client";
import {AssetsRouter} from "../assets/bundleDescription";
import {SlaRouter} from "../sla/bundleDescription";
import {CeeviewNavigator} from "tools/ceeviewNavigator";

const i = translator({
  "Untagged": {
    "no": "ingen etikett"
  },
  "Total number of services: {0}": {
    "no": "Totalt antall tjenester: {0}",
    "en": "Total number of services: {0}"
  },
  "Accounts": {
    "no": "Kontoer"
  },
  "Application": {
    "no": "Applikasjon",
    "en": "Application"
  },
  "Asset": {
    "no": "Ressurs",
    "en": "Asset"
  },
  "SLA": {
    "no": "SLA",
    "en": "SLA"
  },
  "Service": {
    "no": "Tjeneste",
    "en": "Service"
  },
  "State label": {
    "no": "Tilstand tekst"
  },
  "Show bar": {
    "no": "Vis rad"
  },
  "Show bucket": {
    "no": "Vis bøtte"
  }
});

export function createGroupByDropDown(selector, currentValue, onChange, keepWidgetHeader, releaseWidgetHeader){
	return $(selector).kendoDropDownList({
		dataSource: [
			{text: i('Accounts'), value: 'accounts'},
			{text: i('Tags'), value: 'tags'}],
		change: onChange,
		dataTextField: 'text',
		dataValueField: 'value',
		value: currentValue || "accounts",
		open: keepWidgetHeader,
		close: releaseWidgetHeader
	}).data('kendoDropDownList');
}

export function postProcessAccountsData(data){
	for (let item of data) {
		item.agentsUp = item.agentsTotal - item.agentsDown;
		item.agentsState = (item.agentsDown === 0);
		item.name =  item.name == '__UNGROUPED'
			? i('Untagged')
			: item.name;
	}
	return data
}

export default class HomeView extends Application {
	store = new HomeViewStore()

	constructor() {
		super();

		this.onEventsTimeout = throttle(this.onEventsTimeout, 10000);

		this.module = {
			name: 'Summary'
		};

		// initializing data inside this class
		this.subscriberId = Utils.guid();
		this.initComponent();
	}

	/**
	 * // TODO: Test if still in use
	 * @param {String} src The url of the source html
	 */
	loadModule(src) {
		this.settingsContainer.load(src);
	}

	initComponent() {
		super.initComponent();
		this.renderWidgets();

		$('#nav li').removeClass('current').parent().find('#service_summary').addClass('current');
		this.serviceGrids = [];
		this.isDataSourceSubscribed = false;
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		this.settingsContainer = $('#settings_content');
		this.filtersOn = false;
		this.lastAction = '';
		this.widgetsRendered = false;
		this.isEventDriven = false;
		this.rowsState = null;
		this.skipRowsStateSaving = false;
		this.isHomeView = true;
		this.removeListeners();
		this.attachListeners();
		this.currentAccountsList = [];


		this.renderHomeView();

		this.defaultColumns = {
			name: {
				hidden: false,
				width: 240
			},
			accStateIndex: {
				hidden: false,
				width: 120
			},
			srvHealthIndex: {
				hidden: false,
				width: 150
			},
			accSlaIndex: {
				hidden: false,
				width: 120
			},
			accIncidents: {
				hidden: false,
				width: 130
			},
			accAssetHealthIndex: {
				hidden: false,
				width: 120
			},
			agentsState: {
				hidden: false,
				width: 180
			},
			id: {
				hidden: false
			}
		}
		this.loadUserPreferences();
		State.mainApp.contextMenu.setActionButton('SERVICE');
	}

	async waitLayoutLoaded() {
		const maxTime = 10000;
		let waitedTime = 0;
		const wait = async (time) => {
			return new Promise((r) => {
				setTimeout(() => r(), time);
			});
		}
		return new Promise(async (res, rej) => {
			while(waitedTime < maxTime) {
				if (document.getElementById('screen_options')) {
					res(1);
					break;
				}
				waitedTime += 300;
				await wait(300);
			}
			rej(1);
		});
	}

	async renderHomeView() {
		await this.waitLayoutLoaded();
		this.screenOptionsRoot = createRoot(document.getElementById('screen_options'))
		this.screenOptionsRoot.render(<HomeViewSettings store={this.store} onChange={this.checkBuckets} />);
	}

	removeListeners() {
		$('.cw_service_summary_list').off();
		$('#cw_account_health_chart').off();
		$('#cw_account_services').off();
		$('.onscreen_service_state_box').off();
	}

	checkBuckets = () => {
		const element = document.querySelector('.home-view__widgets');
		const showBars = this.store.get('onscreen_widget_show_bucket').visible;
		element.classList.remove('home-view__widgets_only-bars');
		if (!showBars) {
			element.classList.add('home-view__widgets_only-bars');
		}
	}

	attachListeners() {
		if (State.mainApp.session.hasRole('INCIDENT_LIST')) {
			$('.cw_service_summary_list').on('click', '.cw_incidents_link', $.proxy(this.onIncidentsLink, this));
		}


		if (State.mainApp.session.hasRole('SERVICE_READ')) {
			$('#cw_account_services').on('click', '.js_service_state', $.proxy(this.onGridServiceStateClick, this));
			$('.cw_service_summary_list').on('click', 'a[data-link="service"]', e => this.onServiceLink(e));
		}
		$('#cw_account_services').on('click', 'a.k-link', $.proxy(this.onAccountSort, this));
		$('#cw_account_services').on('click', 'a[data-link="account"]', $.proxy(this.onNameClick, this));
		$('#cw_account_services').on('click', '.k-filter-menu .k-button', $.proxy(this.onFilterButton, this));
		if (State.mainApp.session.hasRole('AGENT_LIST')) {
			$('#cw_account_services').on('click', '.cw_agent_status', $.proxy(this.onAgentStatusClick, this));
		}

		$(window).off('resize');
		$(window).on('resize', $.proxy(function () {
			this.adjustSectionHeight();
		}, this));
	}

	onEventsTimeout = events => {
		this.isEventDriven = true;
		this.accountsDataSource.read();
		this.store.refresh()
	}

	initKendoComponents() {
		var defaultFsView = UserPrefs.get('defaultFsView'), accountSort, accountFilter;
		var filterMessages = lang.grid.filter, oThis;
		if (State.firstTimeInSummary && Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			accountSort = pref.sort;
			accountFilter = pref.filter;
			this.accountColumns = pref.columns;
		} else {
			accountSort = JSON.parse(UserPrefs.get('accountSort'));
			accountFilter = JSON.parse(UserPrefs.get('accountFilter'));
		}


		this.groupBy = createGroupByDropDown('#cw_services_grouped_by',
			JSON.parse(UserPrefs.get('GroupBy')) , this.onGroupByChanged );

		this.accountsDataSource = createDataSource(
			this.getServicesFirstLevelUrl(),
			'GET',
			{
				schema: {
					model: {
						id: 'id',
						fields: {
							srvHealthIndex: {
								type: 'number'
							},
							accAssetHealthIndex: {
								type: 'number'
							},
							accSlaIndex: {
								type: 'number'
							},
							accStateIndex: {
								type: 'number'
							},
							accIncidents: {
								type: 'number'
							},
							agentsDown: {
								type: 'number'
							},
							agentsUp: {
								type: 'number'
							},
							agentsState: {
								type: 'boolean'
							}
						}
					},
					parse: postProcessAccountsData
				},
				sort: accountSort || [{
					field: 'accStateIndex',
					dir: 'asc',
					compare: null
				}, {
					field: 'srvHealthIndex',
					dir: 'asc',
					compare: null
				}, {
					field: 'accSlaIndex',
					dir: 'asc',
					compare: null
				}],
				filter: accountFilter || [],
			}
		);

		var accountColumns = this.accountColumns || JSON.parse(UserPrefs.get('accountColumns')) || this.defaultColumns;
		accountColumns = Utils.completeColumns(accountColumns, this.defaultColumns);

		if (State.mainApp.session.hasRole('ACCOUNT_LIST') && State.mainApp.session.hasRole('SUMMARY_LIST')) {
			this.grid = $('#cw_account_services').kendoCustomGrid({
				dataSource: this.accountsDataSource,
				resizable: true,
				sortable: {
					mode: "multiple",
					allowUnsort: true
				},
				filterable: {
					extra: false,
					operators: {
						string: {
							startswith: filterMessages.STARTS_WITH,
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						},
						number: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ,
							gte: filterMessages.GTE,
							gt: filterMessages.GT,
							lte: filterMessages.LTE,
							lt: filterMessages.LT
						}
					},
					messages: this.gridMessages
				},
				detailInit: $.proxy(this.onAccountRowExpand, this),
				columns: [{
					field: 'name',
					title: lang.summary.NAME,
					template: (item) => Renderer.accountName(item.name, item.id),
					filterable: {
						ui: $.proxy(function (element) {
							return Renderer.filterGridByOwnElementDS.call(this, element, 'name');
						}, this),
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								neq: filterMessages.NEQ,
								eq: filterMessages.EQ
							}
						}
					},
					sortable: true,
					hidden: accountColumns.name.hidden,
					width: accountColumns.name.width
				}, {
					field: 'serviceState',
					title: lang.summary.SERVICE_STATE,
					//template: (item) => Renderer.renderSummaryState(item.serviceState, null, null, item.agentStatus, item.qualifierError, item.assetMaintenance),
					template: item => getServiceState(item.serviceState, null, null, item.agentStatus, item.qualifierError, item.assetMaintenance),
					sortable: {
						compare: $.proxy(function (a, b) {
							return Utils.compareStates(a, b, 'serviceState', this.accountsDataSource.sortNow);
						}, this)
					},
					filterable: {
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						},
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: lang.DOWN,
									value: 'INVALID'
								}, {
									text: lang.WARNING,
									value: 'WARNING'
								}, {
									text: lang.UP,
									value: 'ACTIVE'
								}, {
									text: lang.UNAVAILABLE,
									value: 'INACTIVE'
								}],
								dataTextField: 'text',
								dataValueField: 'value',
								optionLabel: lang.grid.FILTER_SELECT_VALUE,
								change: Utils.onFilterDropDownChange,
								//template: (item) => Renderer.renderSummaryState(item.value, item.text, null, null)
								template: (item) => getServiceState(item.value, item.text)
							});
						}
					},
					valueRenderer: function (value) {
						//return Renderer.renderSummaryState(value);
						return getServiceState(value);
					},
					attributes: {
						'class': 'cw_service_state text_center'
					},
					hidden: accountColumns.accStateIndex.hidden,
					width: accountColumns.accStateIndex.width

				}, {
					field: 'srvHealthIndex',
					title: lang.summary.SERVICE_HEALTH,
					sortable: {
						compare: $.proxy(function (a, b) {
							return Utils.customCompare(a, b, 'srvHealthIndex', 6, this.accountsDataSource.sortNow);
						}, this)
					},
					filterable: {
						operators: {
							number: {
								eq: filterMessages.ISIN,
								neq: filterMessages.ISNOTIN
							}
						},
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: lang.CRITICAL,
									value: 3
								}, {
									text: lang.MAJOR,
									value: 2
								}, {
									text: lang.MINOR,
									value: 1
								}, {
									text: lang.OK,
									value: 0
								}, {
									text: lang.UNAVAILABLE,
									value: 6
								}],
								// optionLabel: "--Select Value--",
								dataTextField: 'text',
								dataValueField: 'value',
								optionLabel: lang.grid.FILTER_SELECT_VALUE,
								change: Utils.onFilterDropDownChange,
								template: (item) => {
									return `<span class="cw_status_indicator pointer cw_status_widget_color cw_color${Utils.severityToColor(item.value)}"></span><span>${item.text}</span>`
								}
							});
						}
					},
					//template: (item) => Renderer.summaryWorstCase(item.srvHealth, "cw_account_services", item.srvHealthWarning),
					template: item => getHealthStateHome(item.srvHealth, "cw_account_services", item.srvHealthWarning),
					hidden: accountColumns.srvHealthIndex.hidden,
					width: accountColumns.srvHealthIndex.width,
					valueRenderer: function (value) {
						var data = [lang.CRITICAL, lang.MAJOR, lang.MINOR, lang.OK, '', '', lang.UNAVAILABLE];
						return '<span class="cw_status_indicator cw_status_widget_color cw_color' + Utils.severityToColor(value) + '"></span><span>' + data[value] + '</span>';
					},
					attributes: {
						'class': 'text_center'
					}

				}, {
					field: 'accSlaIndex',
					title: lang.summary.SLA_STATUS,
					attributes: {
						'class': 'cw_account_sla text_center'
					},
					sortable: {
						compare: $.proxy(function (a, b) {
							return Utils.customCompare(a, b, 'accSlaIndex', 4, this.accountsDataSource.sortNow);
						}, this)
					},
					filterable: {
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: lang.service.IN_COMPLIANCE,
									icon: 'glyphicons status_icon circle-arrow-top pointer',
									value: 1
								}, {
									text: lang.WARNING,
									icon: 'glyphicons status_icon circle-arrow-right pointer',
									value: 2
								}, {
									text: lang.service.BREACHED,
									icon: 'glyphicons status_icon circle-arrow-down pointer',
									value: 3
								}, {
									text: lang.summary.NO_SLA_AVAILABLE,
									icon: 'cw_status_indicator cw_status_widget_color status_icon cw_color6',
									value: 4
								}],
								dataTextField: 'text',
								dataValueField: 'value',
								optionLabel: lang.grid.FILTER_SELECT_VALUE,
								change: Utils.onFilterDropDownChange,
								template: '<span class="${data.icon}"></span> <span>${data.text}</span>'
							});
						}
					},
					//template: (item) => Renderer.summarySla(item.accSla),
					template: item => getSlaState(item.accSla),
					valueRenderer: function (value) {
						var data = [{}, {
							text: lang.service.IN_COMPLIANCE,
							icon: 'glyphicons status_icon circle-arrow-top'
						}, {
							text: lang.WARNING,
							icon: 'glyphicons status_icon circle-arrow-right'
						}, {
							text: lang.service.BREACHED,
							icon: 'glyphicons status_icon circle-arrow-down'
						}, {
							text: lang.summary.NO_SLA_AVAILABLE,
							icon: 'cw_status_indicator cw_status_widget_color status_icon cw_color6'
						}];
						return '<span class="' + data[value].icon + '"></span> <span>' + data[value].text + '</span>';
					},
					hidden: accountColumns.accSlaIndex.hidden,
					width: accountColumns.accSlaIndex.width
				}, {
					field: 'accIncidents',
					title: lang.service.INCIDENT,
					template: (item) => {
						if (item.accIncidents) {
							return `<a class="pointer cw_incidents_link" data-filter="ACCOUNT">${item.accIncidents}</a>`
						} else {
							return 0;
						}
					},
					attributes: {
						'class': 'text_center'
					},
					sortable: true,
					hidden: accountColumns.accIncidents.hidden,
					width: accountColumns.accIncidents.width
				}, {
					field: 'accAssetHealthIndex',
					title: lang.summary.HEALTH_STATUS,
					sortable: {
						compare: $.proxy(function (a, b) {
							return Utils.customCompare(a, b, 'accAssetHealthIndex', 6, this.accountsDataSource.sortNow);
						}, this)
					},
					filterable: {
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: lang.CRITICAL,
									value: 3
								}, {
									text: lang.MAJOR,
									value: 2
								}, {
									text: lang.MINOR,
									value: 1
								}, {
									text: lang.OK,
									value: 0
								}, {
									text: lang.UNAVAILABLE,
									value: 6
								}],
								// optionLabel: "--Select Value--",
								dataTextField: 'text',
								dataValueField: 'value',
								optionLabel: lang.grid.FILTER_SELECT_VALUE,
								change: Utils.onFilterDropDownChange,
								template: (item) => `<span class="cw_status_indicator pointer cw_status_widget_color cw_color${Utils.severityToColor(item.value)}"></span><span>${item.text}</span>`
							});
						}
					},
					//template: (item) => Renderer.summaryWorstCase(item.accAssetHealth, "cw_account_assets", item.accHealthWarning),
					template: item => getHealthStateHome(item.accAssetHealth, "cw_account_assets", item.accHealthWarning),
					valueRenderer: function (value) {
						var data = [lang.CRITICAL, lang.MAJOR, lang.MINOR, lang.OK, '', '', lang.UNAVAILABLE];
						return '<span class="cw_status_indicator cw_status_widget_color cw_color' + Utils.severityToColor(value) + '"></span><span>' + data[value] + '</span>';
					},
					attributes: {
						'class': 'text_center'
					},
					hidden: accountColumns.accAssetHealthIndex.hidden,
					width: accountColumns.accAssetHealthIndex.width
				}, {
					field: 'agentsState',
					title: lang.AGENTS,
					sortable: {
						compare: $.proxy(function (a, b) {
							if (a.agentsTotal === 0 && b.agentsTotal === 0) {
								return 0;
							}
							if (this.accountsDataSource.sortNow === 'asc') {
								if (a.agentsTotal === 0) {
									return 1;
								}
								if (b.agentsTotal === 0) {
									return -1;
								}
							} else {
								if (a.agentsTotal === 0) {
									return -1;
								}
								if (b.agentsTotal === 0) {
									return 1;
								}
							}
							if (a.agentsDown === b.agentsDown) {
								if (a.agentsTotal < b.agentsTotal) {
									return -1;
								}
								if (a.agentsTotal > b.agentsTotal) {
									return 1;
								}
								return 0;
							}
							return a.agentsDown - b.agentsDown;
						}, this)
					},
					filterable: true,
					attributes: {
						'class': 'text_center'
					},
					//template: (item) => Renderer.summaryAgentsStatus(item.agentsDown, item.agentsWarning, item.agentsTotal),
					template: item => getAgentStateHome(item.agentsDown, item.agentsWarning, item.agentsTotal),
					hidden: accountColumns.agentsState.hidden,
					width: accountColumns.agentsState.width
				}, {
					title: '&nbsp;',
					field: 'id',
					menu: false,
					sortable: false,
					filtrable: false,
					template: '&nbsp;',
					hidden: accountColumns.id.hidden,
					width: accountColumns.id.width
				}],
				columnMenu: true,
				dataBound: $.proxy(this.onAccountsGridDataBound, this),
				columnResize: $.proxy(this.onAccountGridResize, this),
				columnShow: $.proxy(this.onAccountColumnShow, this),
				columnHide: $.proxy(this.onAccountColumnHide, this),
				detailExpand: $.proxy(this.saveAccountRowsState, this),
				detailCollapse: $.proxy(this.saveAccountRowsState, this)
			}).data('kendoCustomGrid')

			// Add Kendo tooltip to the header of the columns
			Utils.gridColumnHeaderTooltip(this.grid);
			this.adjustSectionHeight();
			this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
			var servicesView = this;
			oThis = this;
			var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('accountSearchPhrase') || '');
			this.gridFilterPanel = new PreferencesPanel({
				renderTo: 'cw_summary_filters',
				grid: this.grid,
				modulePrefName: 'Summary',
				defaultPrefViewKey: 'defaultFsView',
				prefListKey: 'accountFSViews',
				userPref: this.userPref,
				onRemove: $.proxy(this.saveUserPreferences, this),
				onClear: function (e) {
					var target = $(e.currentTarget).find('.glyphicons');
					if (!target.hasClass('inactive')) {
						this.grid.dataSource.filter([]);
						this.grid.dataSource.sort([]);
						this.filterList.value('1');
						this.filterList.text(lang.grid.filter.SELECT_VIEW);
						target.addClass('inactive');
						this.setSearchValue('');
						for (var i = 0, length = servicesView.serviceGrids.length; i < length; i++) {
							servicesView.serviceGrids[i].dataSource.filter([]);
							servicesView.serviceGrids[i].dataSource.sort([]);
						}
					}
					if (oThis.servicesGrid) {
						oThis.servicesGrid.dataSource.filter([]);
						oThis.servicesGrid.dataSource.sort([]);
					}
					Utils.setGridColumns(this.grid, this.defaultColumns);
				},
				searchValue: searchValue,
				searchFields: ['name'],
				defaultColumns: this.defaultColumns
			});

			let eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
				startFrom: 0,
				playing: true
			};

			this.eventsToolbar = new EventsToolbar({
				renderTo: $('#cw_home_event_toolbar'),
				callBackFn: (events) => this.onEventsTimeout(events),
				subscriberId: this.subscriberId,
				startFrom: eventStatus.startFrom,
				playing: eventStatus.playing
			});

			setTimeout($.proxy(function () {
				$('#cw_account_services').find('.k-grid-header-wrap th').first().empty().append('<span id="cw_collapse_services" class="cw_collapse_expand_toggle cw_groups_collapsed" title="' + lang.COLLAPSE_ALL + '"></span>');
				$('#cw_collapse_services').off();
				$('#cw_collapse_services').on('click', $.proxy(this.onGroupsToggle, this));
			}, this), 300);
		}
		// removes loading mask
		this.removeMask();
	}

	onGroupByChanged = e => {
		this.accountsDataSource.transport.options.read.url = this.getServicesFirstLevelUrl();
		this.accountsDataSource.read();
	}

	getServicesFirstLevelUrl(){
		return Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId
			+ `/summary/${this.groupBy.value()}?includeSubaccounts=${State.includeSubaccounts.toString()}`;
	}

	getServicesSecondLevelUrl(group){
		if(this.groupBy.value() == 'accounts') {
			return Settings.serverPath + 'accounts/' + group.id + '/summary/services/';
		}else {
			return Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + `/summary/tags/${encodeURIComponent(group.id)}/services?includeSubaccounts=${State.includeSubaccounts.toString()}`;
		}
	}

	saveAccountRowsState(e) {
		var scope = this;
		if (this.skipRowsStateSaving || this.skipRowsStateSaving === 'undefined') {
			return;
		}
		e.sender.tbody.children().map(function (index, element) {
			const el = e.sender.dataItem($(element).closest('tr'));
			if(el?.id){
				var elementId = el.id;
				if (scope.rowsState && scope.rowsState.length) {
					for (var i = 0; i < scope.rowsState.length; i++) {
						if (elementId === scope.rowsState[i].id) {
							scope.rowsState.splice(i, 1);
						}
					}
				}
				scope.rowsState.push({
					id: elementId,
					isExpanded: $(element).find('.k-hierarchy-cell .k-icon').hasClass('k-i-collapse')
				});
			}
		});
	}

	onFilterButton(e) {
		var button = $(e.currentTarget);
		var gridId = button.closest('form').data().handler.options.anchor.closest('table').closest('.k-grid').attr('id');
		if (gridId === 'cw_account_services') {
			this.lastAction = 'filter';
		}
	}

	onAgentStatusClick(e) {
		let target = $(e.currentTarget);
		let icon = target.find('span.glyphicons.status_icon');

		if (icon.hasClass('ok-sign') || icon.hasClass('exclamation-sign') || icon.hasClass('remove-sign')) {
			let groupType = this.groupBy.value();
			let byAccountId, byTagName;
			if (groupType === 'accounts') {
				byAccountId = target.closest('tr').find('.cw_grid_link').data('uid');
			} else if (groupType === 'tags') {
				byTagName = target.closest('tr').find('.cw_grid_link').data('uid');
			}
			State.mainApp.loadModule('ManagementAgents', '', {
				byAccountId: byAccountId,
				byTagName: byTagName
			}, e);
		}
	}

	onAccountRowExpand(e) {
		var serviceGridPrefs = {
			sort: [],
			filter: []
		};
		var filterMessages = lang.grid.filter;
		this.expandedDivId = Utils.guid();
		var prefs = JSON.parse(UserPrefs.get('ServiceGridPrefs'));
		var uid = e.masterRow.data('uid');

		let groupId = this.accountsDataSource.getByUid(uid).id;
		var groupName = this.accountsDataSource.getByUid(uid).name;

		if (prefs) {
			for (var i = 0, length = prefs.length; i < length; i++) {
				if (prefs[i].accountId === groupId) {
					serviceGridPrefs = {
						sort: prefs[i].sort,
						filter: prefs[i].filter
					};
					break;
				}
			}
		}
		this.hasServiceReadRole = State.mainApp.session.hasRole('SERVICE_READ');
		if (State.mainApp.session.hasRole('SERVICE_LIST')) {
			this.servicesGrid = $('<div id="' + this.expandedDivId + '" class="cw_summary_inner_grid" data-accountid="' + groupId + '"></div>').appendTo(e.detailCell).kendoCustomGrid({
				dataSource: createDataSource( this.getServicesSecondLevelUrl(this.accountsDataSource.getByUid(uid)),
					'GET',
					{
					sort: serviceGridPrefs.sort,
					filter: serviceGridPrefs.filter,
					schema: {
						model: {
							id: 'srvId',
							fields: {
								srvHealthIndex: {
									type: 'number'
								},
								srvSlaIndex: {
									type: 'number'
								},
								srvIncidents: {
									type: 'number'
								},
								stateDuration: {
									type: 'number'
								},
								coverage: {
									type: 'number'
								}
							}
						},
						parse: function (response) {
							for (var i = 0, length = response.length; i < length; i++) {
								response[i].timestamp = Utils.timestamp();
								var states = ['OPERATIONAL', 'NON_OPERATIONAL', 'IN_MAINTENANCE'];
								var labels = [lang.ACTIVE, lang.SERVICE_OUTOFOPERATION, lang.service.IN_MAINTENANCE];
								var index = states.indexOf(response[i].operatingState);
								if (index > -1) {
									response[i].Operational = labels[index];
								} else {
									response[i].Operational = '';
								}

							}
							return response;
						}
					},
					error: ErrorHandler.kendoServerError
				}),
				filterable: {
					extra: false,
					operators: {
						string: {
							startswith: filterMessages.STARTS_WITH,
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						},
						number: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ,
							gte: filterMessages.GTE,
							gt: filterMessages.GT,
							lte: filterMessages.LTE,
							lt: filterMessages.LT
						}
					},
					messages: this.gridMessages
				},
				sortable: {
					mode: 'multiple',
					allowUnsort: true
				},
				resizeable: false,
				columns: [{
					field: 'srvName',
					title: lang.NAME,
					menu: false,
					width: this.grid.columns[0].width - 5,
					template: (item) => this.hasServiceReadRole ? `<a class="cw_grid_link" data-link="service">${_.escape(item.srvName)}</a>` : _.escape(item.srvName),
					filterable: true,
					sortable: true
				}, {
					field: 'serviceState',
					title: lang.summary.SERVICE_STATE,
					//template: (item) => Renderer.renderSummaryState(item.serviceState, null, item.operatingState, item.agentStatus, item.qualifierError, item.assetMaintenance),
					template: item => getServiceState(item.serviceState, null, item.operatingState, item.agentStatus, item.qualifierError, item.assetMaintenance),
					sortable: {
						compare: $.proxy(function (a, b) {
							let value = Utils.compareStates(a, b, 'serviceState', this.data('kendoCustomGrid').dataSource.sortNow);
							return value;
						}, $('#' + this.expandedDivId))
					},
					menu: false,
					width: this.grid.columns[1].width,
					attributes: {
						'class': 'text_center js_srv_state_index'
					},
					filterable: {
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: lang.DOWN,
									value: 'INVALID'
								}, {
									text: lang.WARNING,
									value: 'WARNING'
								}, {
									text: lang.UP,
									value: 'ACTIVE'
								}, {
									text: lang.UNAVAILABLE,
									value: 'INACTIVE'
								}],
								dataTextField: 'text',
								dataValueField: 'value',
								optionLabel: lang.grid.FILTER_SELECT_VALUE,
								change: Utils.onFilterDropDownChange,
								//template: (item) => Renderer.renderSummaryState(item.value, item.text, null, null)
								template: (item) => getServiceState(item.value, item.text)
							});
						}
					}
				}, {
					field: 'srvHealthIndex',
					// hidden: this.noServiceHealth,
					title: lang.summary.SERVICE_HEALTH,
					sortable: {
						compare: $.proxy(function (a, b) {
							return Utils.customCompare(a, b, 'srvHealthIndex', 6, this.data('kendoCustomGrid').dataSource.sortNow);
						}, $('#' + this.expandedDivId))
					},
					menu: false,
					filterable: {
						operators: {
							number: {
								eq: filterMessages.ISIN,
								neq: filterMessages.ISNOTIN
							}
						},
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: lang.CRITICAL,
									value: 3
								}, {
									text: lang.MAJOR,
									value: 2
								}, {
									text: lang.MINOR,
									value: 1
								}, {
									text: lang.OK,
									value: 0
								}, {
									text: lang.UNAVAILABLE,
									value: 6
								}],
								// optionLabel: "--Select Value--",
								dataTextField: 'text',
								dataValueField: 'value',
								optionLabel: lang.grid.FILTER_SELECT_VALUE,
								change: Utils.onFilterDropDownChange,
								template: (data) => `<span class="cw_status_indicator pointer cw_status_widget_color cw_color${Utils.severityToColor(data.value)}"></span><span>${data.text}</span>`
							});
						}
					},
					attributes: {
						'class': 'text_center'
					},
					//template: (item) => Renderer.summaryWorstCase(item.srvHealth, "cw_service_assets"),
					template: item => getHealthStateHome(item.srvHealth, "cw_service_assets"),
					width: this.grid.columns[2].width
				}, {
					field: 'srvSlaIndex',
					// hidden: this.noSla,
					title: lang.summary.SLA_STATUS,
					//template: (item) => Renderer.summarySla(item.srvSla),
					template: item => getSlaState(item.srvSla),
					menu: false,
					attributes: {
						'class': 'cw_service_sla text_center'
					},
					sortable: {
						compare: $.proxy(function (a, b) {
							return Utils.customCompare(a, b, 'srvSlaIndex', 4, this.data('kendoCustomGrid').dataSource.sortNow);
						}, $('#' + this.expandedDivId))
					},
					filterable: {
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: lang.FAILED,
									icon: 'glyphicons status_icon circle-arrow-down pointer',
									value: 3
								}, {
									text: lang.WARNING,
									icon: 'glyphicons status_icon circle-arrow-right pointer',
									value: 2
								}, {
									text: lang.OK,
									icon: 'glyphicons status_icon circle-arrow-top pointer',
									value: 1
								}, {
									text: lang.summary.NO_SLA_AVAILABLE,
									icon: 'cw_status_indicator cw_status_widget_color status_icon cw_color6',
									value: 4
								}],
								optionLabel: lang.grid.FILTER_SELECT_VALUE,
								dataTextField: 'text',
								dataValueField: 'value',
								change: Utils.onFilterDropDownChange,
								template: '<span class="${data.icon}"></span> <span>${data.text}</span>'
							});
						}
					},
					width: this.grid.columns[3].width
				}, {
					field: 'srvIncidents',
					title: lang.service.INCIDENT,
					template: '# if (srvIncidents) {#<a class="pointer cw_incidents_link" data-filter="SERVICE">#= srvIncidents#</a># } else { # 0 # } #',
					attributes: {
						'class': 'text_center'
					},
					sortable: true,
					width: this.grid.columns[4].width
				}, {
					field: 'coverage',
					title: lang.service.COVERAGE,
					sortable: true,
					filterable: true,
					attributes: {
						'class': 'text_center'
					},
					width: this.grid.columns[5].width
				}, {
					field: 'Operational',
					title: lang.service.MODE,
					sortable: true,
					filterable: true,
					width: this.grid.columns[6].width,
					attributes: {
						'class': '#if (operatingState === "OPERATIONAL") {# operational #} else if (operatingState !== "NON_OPERATIONAL") {# maintenance #} # text_center'
					}
				}, {
					field: 'stateDuration',
					title: lang.service.CURRENT_STATE,
					sortable: true,
					filterable: {
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: '1 ' + lang.HOUR,
									value: 3600000
								}, {
									text: '5 ' + lang.HOURS,
									value: 18000000
								}, {
									text: '10 ' + lang.HOURS,
									value: 36000000
								}, {
									text: '1 ' + lang.DAY,
									value: 84600000
								}],
								dataTextField: 'text',
								dataValueField: 'value',
								optionLabel: lang.grid.FILTER_SELECT_VALUE
							});
						}
					},
					template: (item) => Renderer.duration(item.stateDuration),
					//width: this.grid.columns[5].width + this.grid.columns[6].width
					width: 160
				}, {
					field: 'stateChangeTime',
					title: lang.service.STATE_CHANGE,
					template: (item) => Renderer.browserDateRenderer(item.stateChangeTime, "datetime"),
					sortable: true,
					filterable: false,
					width: 300
				}, {
					title: '&nbsp;',
					menu: false,
					sortable: false,
					filtrable: false
				}],
				columnMenu: true,
				dataBound: $.proxy(function (e) {
					if (State.mainApp.session.hasRole('ASSET_READ')) {
						$('.cw_service_assets, .cw_account_services, .cw_account_assets').off().on('click', $.proxy(this.onAssetHealthClick, this));
					} else {
						$('.cw_service_assets, .cw_account_services, .cw_account_assets').removeClass('pointer');
					}
					if (State.mainApp.session.hasRole('SLA_READ')) {
						$('.cw_service_sla').off().on('click', '.sla_status_icon', $.proxy(this.onSlaIndicatorClick, this));
					} else {
						$('.cw_service_sla').removeClass('pointer');
					}
					if (!State.mainApp.session.hasRole('SERVICE_READ') || !State.mainApp.session.hasRole('SERVICE_MODEL_READ')) {
						e.sender.element.find('.js_service_state').off().removeClass('pointer');
					}
					if (!State.mainApp.session.hasRole('INCIDENT_LIST')) {
						$('#cw_account_services').find('.cw_incidents_link').off().removeClass('pointer');
					}
					this.initTooltip();
				}, this),
			}).data('kendoCustomGrid');
			// Add Kendo tooltip to the header of the columns
			Utils.gridColumnHeaderTooltip(this.serviceGrids);
			this.serviceGrids.push(this.servicesGrid);
		}
	}

	onIncludeSubaccounts(includeSubaccounts) {
		var urlSplitter = [];
		var currentUrl = this.grid.dataSource.options.transport.read.url;
		var url = '';
		if (includeSubaccounts) {
			if (currentUrl.indexOf('includeSubaccounts=true') === -1) {
				if (currentUrl.indexOf('includeSubaccounts=false') !== -1) {
					url = currentUrl.replace('includeSubaccounts=false', 'includeSubaccounts=true');
				} else {
					urlSplitter = currentUrl.split('?');
					url = urlSplitter[0] + '?includeSubaccounts=true' + urlSplitter[1];
				}
			} else {
				url = currentUrl;
			}
		} else {
			if (currentUrl.indexOf('includeSubaccounts=true') !== -1) {
				url = currentUrl.replace('includeSubaccounts=true', 'includeSubaccounts=false');
			}
		}

		if (url) {
			this.grid.dataSource.options.transport.read.url = url;
			this.grid.dataSource.read();
		}
	}

	//When modifing this method please keep in mind that it is used in summaryWidget via prototype
	onServiceLink(e) {
		const navigator = this.navigator ?? new CeeviewNavigator()

		e.stopPropagation();
		let servicesGrid = $(e.currentTarget).closest('table').closest('.k-grid').data('kendoCustomGrid');

		let serviceItemRowId = $(e.currentTarget).parent().parent().attr('data-uid');
		let serviceItemRow = servicesGrid.dataItem('tr[data-uid=' + serviceItemRowId + ']');
		let serviceId = serviceItemRow.srvId;

		navigator.go({url: ServicesRouter.details(serviceId)});
	}

	onIncidentsLink(e) {
		e.stopPropagation();
		var target = $(e.currentTarget),
			filter = target.data('filter'),
			accountGrid = this.grid,
			serviceGrid, accountId, serviceId, accountRowId, itemId, tags = [], showUntagged = false;
		if (target.text() !== '0') {
			switch (filter) {
				case 'ACCOUNT':
					accountRowId = target.closest('tr').data('uid');
					if (this.groupBy.value() == 'accounts') {
						accountId = accountGrid.dataSource.getByUid(accountRowId).id;
					} else {
						tags.push(accountGrid.dataSource.getByUid(accountRowId).id);
						showUntagged = tags.indexOf('__UNGROUPED') != -1;
					}
					break;
				case 'SERVICE':
					itemId = target.closest('tr').data('uid');
					serviceGrid = target.closest('table').closest('.k-grid').data('kendoCustomGrid');
					accountId = serviceGrid.dataItem('tr[data-uid=' + itemId + ']').accountId;
					serviceId = serviceGrid.dataItem('tr[data-uid=' + itemId + ']').srvId;
					break;
			}

			State.mainApp.navigate(IncidentsRouter.list(), {
				extra: {
					filter: filter,
					serviceAccountId: accountId,
					serviceId: serviceId || null,
					tags: tags,
					showUntagged: showUntagged
				}
			});
		}
	}

	onGroupsToggle() {
		var rowButtons = $("#cw_account_services").find('.k-i-collapse');
		var length = rowButtons.length;
		for (var j = 0; j < length; j++) {
			$(rowButtons[j]).trigger('click');
		}
	}

	onAccountSort(e) {
		this.lastAction = 'sort';
	}

	onAccountsGridDataBound() {
		$('.cw_account_assets, .cw_account_services').off();
		if (State.mainApp.session.hasRole('SLA_READ')) {
			$('.cw_account_sla').off().on('click', '.sla_status_icon', $.proxy(this.onSlaIndicatorClick, this));
		}

		this.store.refresh()
		var accounts = [], length = this.accountsDataSource.total(), record;
		for (var i = 0; i < length; i++) {
			record = this.accountsDataSource.at(i);
			accounts.push(record.id);
		}
		if (!this.isDataSourceSubscribed) {
			this.currentAccountsList = accounts;
			this.subscribe();
		} else {
			if (!this.isEventDriven) {
				if (!Utils.areSimilarArrays(accounts, this.currentAccountsList)) {
					this.unsubscribe();
					this.subscribe();
				}
				this.currentAccountsList = accounts;

			} else {
				this.isEventDriven = false;
			}
		}
		// }
		var grid = this.grid;

		let rowsState = this.rowsState || JSON.parse(UserPrefs.get('ServiceGridRows')) || [];
		this.rowsState = rowsState;

		this.skipRowsStateSaving = true;
		if (rowsState && rowsState.length) {
			for (var i = 0; i < rowsState.length; i++) {
				var model = grid.dataSource.get(rowsState[i].id);
				if (model) {
					if (rowsState[i].isExpanded) {
						grid.expandRow(grid.tbody.find('[data-uid=' + model.uid + ']'));
					} else {
						grid.collapseRow(grid.tbody.find('[data-uid=' + model.uid + ']'));
					}
				}
			}
		}
		this.skipRowsStateSaving = false;

		var warningStatuses = $('.cw_status_indicator');
		$.each(warningStatuses, function() {
			var bgColor = $(this).css('background-color');
			$(this).find('.glyphicons').css('background-color', bgColor);
		});
		var warningGlyphs = $('.cw_status_indicator .glyphicons');


		if (!State.mainApp.session.hasRole('SERVICE_LIST')) {
			$('#cw_account_services').find('.js_service_state').off().removeClass('pointer');
		}
		if (!State.mainApp.session.hasRole('SLA_READ')) {
			$('.cw_account_sla .cw_status_indicator').removeClass('pointer');
		}
		if (!State.mainApp.session.hasRole('ASSET_READ')) {
			$('.cw_account_services').removeClass('pointer');
			$('.cw_account_assets').removeClass('pointer');
		}
		if (!State.mainApp.session.hasRole('INCIDENT_LIST')) {
			$('#cw_account_services').find('.cw_incidents_link').off().removeClass('pointer');
		}
		if (!State.mainApp.session.hasRole('AGENT_LIST')) {
			$('#cw_account_services').find('.cw_agent_status').off().removeClass('pointer');
		}
		this.initTooltip();
	}

	initTooltip() {
		Utils.applyTooltip();
	}

	onAccountGridResize(e) {
		if (this.servicesGrid) {
			var index = $('#' + this.expandedDivId + ' .k-grid-header-wrap').find('th:contains(' + e.column.title + ')').index();
			// header
			if (index >= 0) {
				if (index === 0) {
					e.newWidth -= 5;
				}
				$('.cw_summary_inner_grid .k-grid-header-wrap').find('colgroup col').eq(index).css({
					width: e.newWidth
				});
				// content
				$('.cw_summary_inner_grid .k-grid-content').find('colgroup col').eq(index).css({
					width: e.newWidth
				});
			}
		}
	}

	onAccountColumnHide(e) {
		if (this.servicesGrid) {
			var index = $('#' + this.expandedDivId + ' .k-grid-header-wrap').find('th:contains(' + e.column.title + ')').index();
			// header
			if (index >= 0) {
				$('.cw_summary_inner_grid .k-grid-header-wrap').find('colgroup col').eq(index).css({
					display: 'none'
				});
				// content
				$('.cw_summary_inner_grid .k-grid-content').find('colgroup col').eq(index).css({
					display: 'none'
				});
			}
		}
	}

	onAccountColumnShow(e) {
		if (this.servicesGrid) {
			var index = $('#' + this.expandedDivId + ' .k-grid-header-wrap').find('th:contains(' + e.column.title + ')').index();
			// header
			if (index >= 0) {
				$('.cw_summary_inner_grid .k-grid-header-wrap').find('colgroup col').eq(index).css({
					display: 'block'
				});
				// content
				$('.cw_summary_inner_grid .k-grid-content').find('colgroup col').eq(index).css({
					display: 'block'
				});
			}
		}
	}



	onAssetHealthClick(e) {
		e.stopPropagation();
		var target = e.currentTarget;
		var actualTarget = e.target;
		var healthIndexRangeType = [];
		var healthIndexMandatoryType = [];
		var tags = [];
		var serviceId = null;
		var addMonitorErrorFilter = false;
		var accountName = '';
		var data, length, i, accounts = [], grid, gridDiv, rowUid, masterRowUid, dataSource;
		var assetGrid = $('#cw_account_services').data('kendoCustomGrid');
		var openAssetTab = true;
		var source = '';
		if ($(target).hasClass('cw_bar') || $(target).hasClass('cw_progress_bar')) {
			if ($(target).find('.value').text() === '0' || $('#onscreen_asset_health_box').find('.home-view-widget__footer').text() === '0') {
				openAssetTab = false;
			} else {
				if ($(actualTarget).hasClass('exclamation-mark-circle')) {
					healthIndexMandatoryType.push('DOWN');
					healthIndexMandatoryType.push('WARNING');
					addMonitorErrorFilter = true;
				}
				if ($(target).hasClass('is_critical')) {
					healthIndexRangeType.push('CRITICAL');
				} else if ($(target).hasClass('is_major')) {
					healthIndexRangeType.push('MAJOR');
				} else if ($(target).hasClass('is_minor')) {
					healthIndexRangeType.push('MINOR');
				} else if ($(target).hasClass('is_ok')) {
					healthIndexRangeType.push('OK');
				} else if ($(target).hasClass('is_idle')) {
					healthIndexRangeType.push('MAINTENANCE');
					healthIndexMandatoryType.push('IDLE');
				}
				data = this.grid.dataSource.data();
				length = data.length;
				for (i = 0; i < length; i++) {
					accounts.push(data[i].id);
				}
				source = 'buckets';
			}
		} else if ($(target).hasClass('home-view-widget__footer')) {
			if ($('#onscreen_asset_health_box').find('.home-view-widget__footer').text() === '0') {
				openAssetTab = false;
			} else {
				data = this.grid.dataSource.data();
				length = data.length;
				for (i = 0; i < length; i++) {
					accounts.push(data[i].id);
				}
				source = 'buckets_footer';
			}
		} else {
			if ($(target).hasClass('cw_color6')) {
				openAssetTab = false;
			} else {
				rowUid = $(target).closest('tr').attr('data-uid');
				gridDiv = $(target).closest('.k-grid');
				if ($(gridDiv).attr('id') === 'cw_account_services') {
					dataSource = assetGrid.dataSource;
					accounts.push(dataSource.getByUid(rowUid).id);
					let id = dataSource.getByUid(rowUid).id;
					tags.push(dataSource.getByUid(rowUid).id);

					accountName = dataSource.getByUid(rowUid).accountName;
					if ($(target).hasClass('cw_account_services')) {
						source = 'account_services';
					} else if ($(target).hasClass('cw_account_assets')) {
						source = 'account_assets';
					}

					if ($(actualTarget).hasClass('exclamation-mark')) {
						healthIndexRangeType.push('WARNING');
					}
					if ($(target).hasClass('critical')) {
						healthIndexRangeType.push('CRITICAL');
					} else if ($(target).hasClass('major')) {
						healthIndexRangeType.push('MAJOR');
					} else if ($(target).hasClass('minor')) {
						healthIndexRangeType.push('MINOR');
					} else if ($(target).hasClass('ok')) {
						healthIndexRangeType.push('OK');
					} else if ($(target).hasClass('idle')) {
						healthIndexRangeType.push('MAINTENANCE');
					}

				} else {
					masterRowUid = $(target).closest('.k-detail-row').prev().attr('data-uid');
					grid = $(gridDiv).data('kendoCustomGrid');
					dataSource = grid.dataSource;
					serviceId = dataSource.getByUid(rowUid).srvId;
					accounts.push(assetGrid.dataSource.getByUid(masterRowUid).id);
					accountName = assetGrid.dataSource.getByUid(masterRowUid).accountName;
					source = 'service_assets';

					if ($(gridDiv).hasClass('cw_summary_inner_grid')) {
						if ($(actualTarget).hasClass('exclamation-mark')) {
							healthIndexRangeType.push('WARNING');
						}
						if ($(target).hasClass('critical')) {
							healthIndexRangeType.push('CRITICAL');
						} else if ($(target).hasClass('major')) {
							healthIndexRangeType.push('MAJOR');
						} else if ($(target).hasClass('minor')) {
							healthIndexRangeType.push('MINOR');
						} else if ($(target).hasClass('ok')) {
							healthIndexRangeType.push('OK');
						} else if ($(target).hasClass('idle')) {
							healthIndexRangeType.push('MAINTENANCE');

						}
					}
				}
			}
		}

		if (openAssetTab) {
			if (!Utils.isGuid(accounts[0])) {
				accounts = [Cookies.CeesoftCurrentAccountId];
			}
			var assetConfig = {
				source: source,
				healthIndexRangeType: healthIndexRangeType,
				healthIndexMandatoryType : healthIndexMandatoryType,
				accountList: accounts,
				serviceId: serviceId,
				accountName: accountName,
				includeSubaccounts: State.includeSubaccounts,
				isView: true,
				addMonitorErrorFilter: addMonitorErrorFilter,
				filteringTags: tags,
				showUntagged: tags.indexOf('__UNGROUPED') != -1
			};


			//if ($(target).attr('id') === 'cw_account_assets') {
			if (source === 'account_assets') {
				assetConfig.preferences = {
					sort: [{field: 'assetIndicator', dir: 'asc'}],
					filter: []
				};
			}

			assetConfig.isView = true;
			const url = AssetsRouter.assetHealthSummary(assetConfig);
			NavigationStore.go(url);
		}
	}

	onApplicationHealthClick(e) {
		let target = e.currentTarget;
		let stateFilter;
		let redirectToApplication = true;
		if ($(target).hasClass('cw_bar') || $(target).hasClass('cw_progress_bar')) {
			if ($(target).find('.value').text() === '0' || $('#onscreen_application_health_box').find('.home-view-widget__footer').text() === '0') {
				redirectToApplication = false;
			} else {
				if ($(target).hasClass('is_critical')) {
					stateFilter = 'CRITICAL';
				} else if ($(target).hasClass('is_major')) {
					stateFilter = 'MAJOR';
				} else if ($(target).hasClass('is_minor')) {
					stateFilter = 'MINOR';
				} else if ($(target).hasClass('is_ok')) {
					stateFilter = 'NONE';
				} else if ($(target).hasClass('is_idle')) {
					stateFilter = 'IDLE';
				}
			}
		} else if ($(target).hasClass('home-view-widget__footer')) {
			if ($('#onscreen_application_health_box').find('.home-view-widget__footer').text() === '0') {
				redirectToApplication = false;
			} else {
				stateFilter = 'ALL';
			}
		}
		if (redirectToApplication) {
			let filterObj = {};
			if (stateFilter) {
				filterObj = {
					extra: {
						stateFilter: stateFilter,
						isView: true
					}
				};
			}
			State.mainApp.navigate(ApplicationsRouter.list(), filterObj);
		}
	}

	onAgentsStateClick(e) {
		State.mainApp.loadModule('ManagementAgents', '', {
			customFilter: {
				field: 'agentState',
				operator: 'eq',
				value: $(e.currentTarget).data('state')
			}
		}, e);
	}

	saveUserPreferences() {
		var preferences = [{
			key: 'AccountsPanelOn',
			value: this.store.get('onscreen_accounts').visible
		}, {
			key: 'ServiceStatePanelOn',
			value: this.store.get('onscreen_service_state').visible
		}, {
			key: 'ApplicationHealthPanelOn',
			value: this.store.get('onscreen_application_health').visible
		}, {
			key: 'AssetHealthPanelOn',
			value: this.store.get('onscreen_asset_health').visible
		}, {
			key: 'SlaPanelOn',
			value: this.store.get('onscreen_sla').visible
		}, {
			key: 'ServiceGridPrefs',
			value: JSON.stringify(this.getServiceGridPrefs())
		},{
			key: 'EventStatePanelOn',
			value: this.store.get('onscreen_event_state').visible
		},{
			key: 'ServiceGridRows',
			value: JSON.stringify(this.rowsState),
		},{
			key: 'GroupBy',
			value: JSON.stringify(this.groupBy.value())
		},{
			key: 'ShowBar',
			value: this.store.get('onscreen_widget_show_bar').visible
		},{
			key: 'ShowLabel',
			value: this.store.get('onscreen_widget_show_label').visible,
		},{
			key: 'ShowBucket',
			value: this.store.get('onscreen_widget_show_bucket').visible
		}];

		this.saveUserPrefs({
			category: 'Summary',
			preferences: preferences,
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			keys: {
				searchPhrase: 'accountSearchPhrase',
				columns: 'accountColumns',
				sort: 'accountSort',
				filter: 'accountFilter',
				FsViews: 'accountFSViews'
			}
		});
	}

	getServiceGridPrefs() {
		var servicePrefs = [];
		for (var i = 0, length = this.serviceGrids.length; i < length; i++) {
			var accountId = this.serviceGrids[i].element.data('accountid');
			//var accountId = this.accountsDataSource.getByUid(uid).id;
			servicePrefs.push({
				accountId: accountId,
				columns: Utils.getGridColumns(this.serviceGrids[i]),
				sort: this.serviceGrids[i].dataSource.sort() || [],
				filter: this.serviceGrids[i].dataSource.filter() || []
			});
		}
		return servicePrefs;
	}

	loadUserPreferences() {
		this.userPref = [];
		UserPrefs.load('Summary', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.updatePanels();
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}

	updatePanels() {
		var accountsPanelOn = UserPrefs.get('AccountsPanelOn') != 'false';
		var serviceStatePanelOn = UserPrefs.get('ServiceStatePanelOn') != 'false';
		var applicationHealthPanelOn = UserPrefs.get('ApplicationHealthPanelOn') != 'false';
		var assetHealthPanelOn = UserPrefs.get('AssetHealthPanelOn') != 'false';
		var slaPanelOn = UserPrefs.get('SlaPanelOn') != 'false';
		let eventsPanelOn = UserPrefs.get('EventStatePanelOn') != 'false';
		let showLabel = UserPrefs.get('ShowLabel') != 'false';
		let showBar = UserPrefs.get('ShowBar') != 'false';
		let showBucket = UserPrefs.get('ShowBucket') != 'false';

		if (!accountsPanelOn && !serviceStatePanelOn && !assetHealthPanelOn && !slaPanelOn && !applicationHealthPanelOn && !eventsPanelOn && !showBar && !showBucket && !showLabel) {
			accountsPanelOn = true;
			serviceStatePanelOn = true;
			assetHealthPanelOn = true;
			slaPanelOn = true;
			applicationHealthPanelOn = true;
			eventsPanelOn = true;
			showBar = true;
			showBucket = true;
			showLabel = true;
		}

		if (State.mainApp.session.hasRole('ACCOUNT_LIST') || !State.mainApp.session.hasRole('SUMMARY_LIST')) {
			this.store.addConfig('onscreen_accounts', i('Accounts'), accountsPanelOn);
		}
		this.store.addConfig('onscreen_application_health', i('Application'), applicationHealthPanelOn);

		if (State.mainApp.session.hasRole('ASSET_LIST')) {
			this.store.addConfig('onscreen_asset_health', i('Asset'), assetHealthPanelOn);
		}
		if (State.mainApp.session.hasRole('SLA_LIST')) {
			this.store.addConfig('onscreen_sla', i('SLA'), slaPanelOn);
		}
		if (State.mainApp.session.hasRole('SERVICE_LIST')) {
			this.store.addConfig('onscreen_service_state', i('Service'), serviceStatePanelOn);
		}
		if (State.mainApp.session.hasRole('SERVICE_LIST')) {
			this.store.addConfig('onscreen_event_state', i('Event'), eventsPanelOn);
		}
		this.store.addConfig('onscreen_widget_show_bar', i('Show bar'), showBar);
		this.store.addConfig('onscreen_widget_show_bucket', i('Show bucket'), showBucket);
		this.store.addConfig('onscreen_widget_show_label', i('Show label'), showLabel);

		this.store.widgets.forEach(c => autorun(() => this.toggleWidget(c)));
	}

	toggleWidget = (widget) => {
		if (widget.visible) {
			$('#' + widget.id + '_box').fadeIn(250);
		} else {
			$('#' + widget.id + '_box').hide();
		}
	}

	onSlaIndicatorClick(e) {
		var target = $(e.currentTarget);
		var hasIndicator = false, hasSlas = true, accountName, serviceId, accountId, tags = [], showUntagged = false;
		var indicator;
		if (target.hasClass('cw_bar') || target.hasClass('cw_progress_bar')) {
			hasIndicator = true;
			if (target.hasClass('is_critical')) {
				indicator = 'FAILED';
			}
			if (target.hasClass('is_major')) {
				indicator = 'WARNING';
			}
			if (target.hasClass('is_ok')) {
				indicator = 'OK';
			}
		}

		if (target.text() === '0' || target.find('.value').text() === '0') {
			hasSlas = false;
		}
		if (hasSlas) {
			if (hasIndicator) {
				const options = {
					indicator: indicator,
					isView: true
				}
				NavigationStore.go(SlaRouter.list(options))
			} else {
				if (target.hasClass('sla_status_icon')) {
					if (target.parent().hasClass('cw_service_sla')) {
						if(this.groupBy.value() == 'accounts') {
							const uid = target.closest('tr').data('uid');
							const grid = target.closest('table').closest('.k-grid').data('kendoCustomGrid');
							serviceId = grid.dataSource.getByUid(uid).srvId;
							accountId = grid.dataSource.getByUid(uid).accountId;
						}
					} else {
						if(this.groupBy.value() == 'accounts') {
							accountName = this.accountsDataSource.getByUid(target.closest('tr').data('uid')).accountName;
						} else {
							tags.push(this.accountsDataSource.getByUid(target.closest('tr').data('uid')).id);
							showUntagged = tags.indexOf('__UNGROUPED') != -1;
						}
					}
					const options = {
						isView: true,
						accountName: accountName,
						serviceId: serviceId,
						accountId: accountId,
						tags: tags,
						showUntagged: showUntagged,
						preferences: {
							sort: [{
								field: 'statusIndex',
								dir: 'desc'
							}],
							filter: []
						}
					}
					NavigationStore.go(SlaRouter.list(options))
				} else {
					NavigationStore.go(SlaRouter.list({isView: true}))
				}
			}
		}
	}

	onNameClick(e) {
		if(this.groupBy.value() != 'accounts')
			return;

		e.preventDefault();
		var accountId = $(e.currentTarget).attr('data-uid');
		var accountName = $(e.currentTarget).text();
		var timeout = Settings.COOKIE_TIMEOUT;
		Cookies.erase('CeesoftCurrentAccountId');
		Cookies.erase('CeesoftCurrentAccountName');
		Cookies.create('CeesoftCurrentAccountId', accountId, timeout);
		Cookies.create('CeesoftCurrentAccountName', accountName, timeout);
		Cookies.erase('CeesoftParentAccountId');
		Cookies.erase('CeesoftParentAccountName');
		Cookies.erase('CeesoftAccountLogoId');
		window.location.reload();
	}

	onGridServiceStateClick(e) {
		var target = $(e.currentTarget);
		if (target.parent().hasClass('js_srv_state_index')) {
			var serviceGrid = target.closest('table').parent().parent().data('kendoCustomGrid');
			var record = serviceGrid.dataSource.getByUid(target.closest('tr').data('uid'));
			State.mainApp.navigate(ServicesRouter.viewer(record.id));
		} else {

			const id = this.accountsDataSource.getByUid(target.closest('tr').data('uid')).id;

			let args = {
				tags: []
			};

			if( this.groupBy.value() == 'accounts'){
				args.accountId = id;
			}else{
				args.tags.push(id);
				args.showUntagged = (id === '__UNGROUPED');
			}

			if (target.hasClass('is_critical')) {
				args.serviceState = 'DOWN';
			} else if (target.hasClass('is_ok')) {
				args.serviceState = 'UP';
			} else if (target.hasClass('is_major')) {
				args.serviceState = 'WARNING';
			} else if (target.hasClass('is_idle')) {
				args.serviceState = 'UNAVAILABLE';
			}
			args.showGridView = true;
			State.mainApp.navigate(ServicesRouter.list(args));
		}
	}

	renderWidgets = () => {
		this.wigetsRoot = createRoot(document.querySelector('.home-view__widgets'));
		this.wigetsRoot.render(<HomeViewWidgets store={this.store}/>)
	}

	reloadByTags() {
		this.grid.dataSource.read();
		this.store.refresh()
	}

	subscribe() {
		this.isDataSourceSubscribed = true;

		var subscriptionObj = [{
			eventType: 'AccountSummary',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}, {
			eventType: 'Administration',
			entityType: 'ACCOUNT',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	}

	destroy() {
		this.widgetsRoot?.unmount()
		$(window).off('resize');
		State.firstTimeInSummary = false;
		if (!State.isUnloading) {
			this.saveUserPreferences();
		}
		this.unsubscribe();
		Application.prototype.destroy.call(this);
	}

	adjustSectionHeight() {
		const section = document.querySelector('.home-view__main-content');
		const mainContent = document.getElementById('cw_service_summary').getBoundingClientRect();
		const widgetContent = document.querySelector('.home-view__widgets').getBoundingClientRect();
		let gridContent = section.querySelector('.k-grid-content');
		gridContent.style.height = (mainContent.height - widgetContent.height - 100) + 'px'
	}
}

export {HomeView}
