import React from "react";

import FormEntry from "controls/react/form/formEntry";
import {AntInput} from "controls/react/ant/antInput";
import {linkModel, ModelValidator, ValidatableModel} from "framework/mobx-integration";
import {makeAutoObservable} from "mobx";
import {Section} from "controls/react/layout/section";
import {observer} from "mobx-react";

import {sharedLocalization} from "vendor-init/tinymce/plugins/localization";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {useDashboards} from "areas/dashboards/api";
import {ApplicationState} from "framework/applicationState";
import {AntSelect} from "controls/react/ant/antSelect";


const i = require('core/localization').translator({
  "Full path of chrome or empty for auto.": {
    "no": "Full path of chrome or empty for auto."
  }
}, sharedLocalization);

export class DashboardConfig implements ValidatableModel<DashboardConfig> {
	id: string;
	title: string;
	path: string = "";
	options: string = '';
	wait: number = 30;

	validator = new ModelValidator(this);

	constructor() {
		makeAutoObservable(this)

		this.validator.required("id");
	}
}

const DashboardForm = observer((props: { config: DashboardConfig }) => {
	const dashboards = useDashboards(ApplicationState.accountId);

	return <Section childrenPadding={true}>
		<FormEntry label={i('Title')}>
			<AntInput {...linkModel(props.config, "title")}/>
		</FormEntry>
		<FormEntry label={i('Dashboard')} model={props.config} modelField={"id"}>
			<AntSelect dataList={dashboards} nameField={"tag"}/>
		</FormEntry>
		<FormEntry label={i('Path')}>
			<AntInput {...linkModel(props.config, "path")}
			          placeholder={i('Full path of chrome or empty for auto.')}/>
		</FormEntry>
		<FormEntry label={i('Options')}>
			<AntInput {...linkModel(props.config, "options")}/>
		</FormEntry>
		<FormEntry label={i('Wait')}>
			<AntInputNumber {...linkModel(props.config, "wait")}/>
		</FormEntry>
	</Section>
});

export const dashboardComponentDescription = {
	id: 'ceeview/dashboard',
	title: i('Dashboard'),
	config: DashboardConfig,
	form: DashboardForm,
	preview: require('./dashboard.png'),
	defaultSize: {
		width: '300px',
		height: '150px'
	}
}
