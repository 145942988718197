import {Api} from "tools/api";
import Settings from 'settings';
import { Cookies } from "core/cookies";

export class ServiceDocumentWidgetApi {
	static async getDocuments(dynamicServices, accountIds, tags, serviceIds, documentLimit) {
		const url = `${Settings.serverPath}services/documents`
		let payload;
		if (dynamicServices) {
			payload = {
				accountIds: accountIds,
				tags: tags
			}
		}
		else {
			payload = {
				serviceIds: serviceIds
			}
		}
		let resp = await Api.fetchPost(url, payload);
		if (documentLimit) {
			resp = resp.sort((a,b) => b.created - a.created)
			resp = resp.slice(0, documentLimit)
		}
		return resp;
	}

	static async getDownloadUrl(doc){
		let downloadUrl;
		if (doc.type === 'application/x-url') {
			downloadUrl = (await Api.fetch(Settings.serverPath + 'sessions/' + Cookies.sessionId + '/services/' + doc.serviceId + '/documents/' + doc.id + '/content/')).data;
		} else {
			downloadUrl = Settings.serverPath + 'sessions/' + Cookies.sessionId + '/services/' + doc.serviceId + '/documents/' + doc.id + '/preview/';
		}
		return downloadUrl; 
	}

	static async getServiceListLite(accountId){
		return await Api.fetch(Settings.serverPath + 'accounts/' + accountId + '/services/lite?hasModel=true')
	}
}
export default ServiceDocumentWidgetApi;