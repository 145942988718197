import {ApiRequest, PagedList} from "framework/api";
import {ServicesListEntry} from "./servicesListEntry";
import {RuleDefinition, RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {GridStore} from "controls/grid/gridStore"
import {GridDataItem} from "controls/grid/gridDataItem";

export type GetServicesArgs = {
	accountId?: string,
	assetId?: string,
	linkServiceId?: string,
	tags?: string[],
	showUntagged?: boolean,
	includeSubaccounts?: boolean
	ignoreUserTags?: boolean
	filter?: RuleDefinition
	referenceServiceId?: string
}

export function getServices(args?: GetServicesArgs){
	return new ApiRequest<PagedList<GridDataItem<ServicesListEntry>>>({
		url: 'services/serviceDetails',
		accountBased: true,
		accountId: args?.accountId,
		subaccountsFilter: true,
		includeSubaccounts: args?.includeSubaccounts,
		method: 'POST',
		payload: {
			assetId: args?.assetId,
			linkServiceId: args?.linkServiceId,
			tags: args?.tags,
			showUntagged: args?.showUntagged,
			ignoreUserTags: args?.ignoreUserTags,
			filter: args?.filter,
			referenceServiceId: args?.referenceServiceId
		}
	});
}

type GetServicesFiltersArgs = {
	accountId?: string
}

export function getServicesListFilters(args?: GetServicesFiltersArgs){
	return new ApiRequest<RulesConfiguration>({
		url: 'services/serviceDetails/ruleConfiguration',
		accountBased: true,
		subaccountsFilter: true,
		method: 'GET'
	});
}

export type NamedItem = {
	id: string,
	name: string
}

export function deleteServices(store: GridStore<any>){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/delete',
	});
}

export function setServicesInMaintenance(store: GridStore<any>, maintenanceData: object){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/startMaintenance',
		payload: {
			bulkMaintenance: maintenanceData
		}
	});
}

export function endMaintenance(store: GridStore<any>){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/endMaintenance',
	});
}

export function exportCSV(store: GridStore<any>){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/exportCSV',
	});
}

export function setInformation(store: GridStore<any>, informationText: string){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'services/summaryList/setInformation',
		payload: {
			bulkInformation: informationText
		}
	});
}

export function setTags(store: GridStore<any>, tags: string[]){
	return store.getSelectionApiRequest({
		url: 'services/summaryList/setTags',
		payload: {
			bulkTags: tags
		}
	});
}

export class RecalculationStatus {
	total: number;
	completed: number;
	errors: number;
	details?: RecalculationDetail[] | undefined;
}

export class RecalculationDetail {
	id: string;
	name: string;
	details: string[];
}

export interface IGridStorePayloadProvider {
	getSelectionApiRequest<T>(args: { url: string, payload?: Record<string, any> }): ApiRequest<T>;
}

export function startRecalculation(store: IGridStorePayloadProvider, startTime: Date | number, recalculateSla: boolean) {
	const recalculateTime = typeof startTime == 'number' ? startTime : startTime.getTime();
	return store.getSelectionApiRequest<string>({
		url: 'services/summaryList/recalculateStates',
		payload: {
			recalculateTime: recalculateTime,
			recalculateSla: recalculateSla
		}
	});
}

export function recalculationStatus(jobId: string) {
	return new ApiRequest<RecalculationStatus>({
		url: `sessions/scheduledJobs/${jobId}/status`,
		method: 'GET'
	});
}

export function cancelRecalculation(jobId: string) {
	return new ApiRequest<RecalculationStatus>({
		url: `sessions/scheduledJobs/${jobId}/cancel`,
		method: 'GET'
	});
}

export type SummaryDocument = {
	id: string,
	type: string,
	name: string,
	description: string,
	fileName: string,
	content: string,
	size: number,
	summaryDocument: boolean,
	created: number
}

export function summaryDocuments(id: string) {
	return new ApiRequest<SummaryDocument[]>({
		url: `services/${id}/documents/summaryDocuments`,
		method: 'GET'
	});
}
