import 'react'

declare module 'react' {
	interface CSSProperties {
		//[key: `--ceeview-${string}`]: string | number
	}
}

export const initCssVariables = () => {
	document.body.classList.add("css-var-r0")
	CssVariables.loadVariablesFromLayout()
}

class CssVariablesInternal{
	severityCritical: string = '#da1e28' //default values. So the application does not fail if loading not happened yet
	severityMajor: string = '#ff832b'
	severityMinor: string = '#ff832b'
	severityNone: string = '#24A148'
	severityInvalid: string = '#6f6f6f'

	zIndexModal: number
	zIndexPopover: number
	zIndexControlPopover: number

	currentZIndex: number = 10000

	loadVariablesFromLayout(){
		let style = getComputedStyle(document.body)
		this.severityCritical = style.getPropertyValue("--ceeview-severity-critical")
		this.severityMajor = style.getPropertyValue("--ceeview-severity-major")
		this.severityMinor = style.getPropertyValue("--ceeview-severity-minor")
		this.severityNone = style.getPropertyValue("--ceeview-severity-none")
		this.severityInvalid = style.getPropertyValue("--ceeview-severity-invalid")

		this.zIndexModal = Number.parseInt(style.getPropertyValue("--ceeview-z-index-modal"))
		this.zIndexPopover = Number.parseInt(style.getPropertyValue("--ceeview-z-index-popover"))
		this.zIndexControlPopover = Number.parseInt(style.getPropertyValue("--ceeview-z-index-control-popover"))
	}
}

export const CssVariables = new CssVariablesInternal()
