import React from "react";
import Settings from 'settings';
import {Window} from "controls/react/kendoWrappers";

import {translator} from "core";
import {
	getQualifierWidth,
	getQualifierHeight,
	getQualifierLeft,
	getQualifierTop,
	getValidationDetails,
	showValidation,
	getValidationMessage,
	getValidationStatus,
	getQualifierFieldValue,
	getValidationType, getValidationAttachment
} from "./reduxReducer";
import {connect} from "react-redux";

import classnames from "classnames";
import {generateAction, HIDE_VALIDATION} from "./reduxActions";

import './configErrorWindow.less';
import PageSourceWindow from "./pageSourceWindow";
import {Spin} from "antd";
import {Section} from "controls/react/layout/section";

const i = translator({
  "Test": {
    "no": "Test"
  },
  "Post validate": {
    "no": "Post validering",
    "en": "Post validate"
  },
  "Click here to view page source": {
    "no": "Klikk for å se sidekilde",
  }
});

const mapStateToProps = (state, ownProps) => ({
	width: getQualifierWidth(state),
    height: getQualifierHeight(state),
    top: getQualifierTop(state),
    left: getQualifierLeft(state),
    showValidation: showValidation(state),
    details: getValidationDetails(state),
	attachment: getValidationAttachment(state),
    message: getValidationMessage(state),
    status: getValidationStatus(state),
    qualifierName: getQualifierFieldValue(state, 'name'),
    type: getValidationType(state)
});
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        hide: () => dispatch(generateAction(HIDE_VALIDATION))
    }
};
@connect(mapStateToProps, mapDispatchToProps)
export class ConfigErrorWindow extends React.PureComponent {
    ref = React.createRef();
	state = {
		showSourceWindow: false,
		loading: true,
		details: undefined
	};

    componentDidUpdate(prevProps) {
        if ((!prevProps.details && this.props.details) || (prevProps.details !== this.props.details)) {
			this.setState({details: this.props.details});
            this.hideSpinner();
        }
        else if(!this.props.details) {
            this.showSpinner();
        }
    }

    showSpinner() {
		this.setState({loading: true});
    }

    hideSpinner() {
		this.setState({loading: false});
    }

    onShowSourceWindow = () => {
    	this.setState({
			showSourceWindow: true
		})
	};

	onHideSourceWindow = () => {
		this.setState({
			showSourceWindow: false
		})
	};

    render() {
	    let {width, height, top, left, showValidation, qualifierName, name, type, position} = this.props;
		let details = this.state.details;
	    width = width || position?.width ;
	    height = height || position?.height;
	    top = top || position?.top ;
	    left = left || position?.left;

		let windowWidth = 450;
		let windowHeight = 300;

		let windowTop = top + height / 2;
		let contentHeight = window.innerHeight;
		if (windowTop + windowHeight + 32 > contentHeight) {//32px addes by kendo window
			windowTop = contentHeight - windowHeight - 60;
		}

		let asideWidth = $('.aside').width();
		let contentWidth = window.innerWidth;
		//to the left
		let windowLeft = left - windowWidth - 10;
		if (windowLeft < asideWidth) {
			if (left + width + windowWidth < contentWidth) {
				//to the right
				windowLeft = left + width + 10;
			} else {
				//inside qualifier window as not possible to be either left or right
				windowLeft = left + width - windowWidth - 20;
			}
		}

        if (!showValidation || height === undefined || top === undefined || left === undefined) {
            return null;
        }

        let title;

        switch(type) {
            case 'test':
                title = `${i('Test')} ${qualifierName || name}`;
                break;
            case 'postValidate':
                title = `${i('Post validate')} ${qualifierName || name}`;
                break;
        }

        let url = '';

        if (this.props.attachment) {
        	const {id, name, mimeType} = this.props.attachment;

			url = Settings.serverPath + `services/qualifiers/attachments/${id}?mimeType=${mimeType}&name=${name}`;
		}
		const Content = () => {
			return <Section appearance={"none"} height={"fit"}>
				{this.props.attachment &&
					<div className={'cw_configuration_attachment_notification'} onClick={this.onShowSourceWindow}>
						{i('Click here to view page source')}
					</div>}
				<div className="cw_configuration_error_content" ref={this.ref}>
					<ul>
						{details && details.map((item, index) => {
							return <li key={index}>{item.message}<span
								className={classnames('glyphicons', 'cw_error_status', {
									ok: item.success,
									'remove-circle': !item.success
								})}/></li>;
						})}
					</ul>
				</div>
				{this.state.showSourceWindow && <PageSourceWindow url={url} onClose={this.onHideSourceWindow}/>}
			</Section>
		};

        return <Window
			className='high-zindex config-editor-window'
			contentClass="flex"
			title={title}
			modal={false}
			width={windowWidth}
			height={windowHeight}
			top={windowTop}
			left={windowLeft}
			onClose={this.props.hide} >
			{this.state.loading &&
				<Spin tip={i('Loading...')}>
					<Content />
				</Spin>}
			{!this.state.loading && <Content />}
		</Window>;
    }
}
