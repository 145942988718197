import './servicesListPopup.less';
import {ServicesListStore} from "../../services-list/servicesListStore";
import {apiFetch} from "../../../../framework/api";
import {getServicesListFilters} from "../../services-list/api";
import {GridState} from "../../../../controls/grid/gridState";
import {ServicesListEntry} from "../../services-list/servicesListEntry";
import {RuleDefinition, RuleDefinitionType, RulesConfiguration} from "../../../../controls/queryBuilder/ruleDefinition";
import {GridStore} from "../../../../controls/grid/gridStore";
import {ExternalStateProvider} from "../../../../controls/grid/stateProviders";
import {RemoteDataProvider} from "../../../../controls/grid/remoteDataProvider";
import {GridSelectionType} from "../../../../controls/grid/gridConfiguration";
import {observer} from "mobx-react";
import React from "react";
import {AntModal} from "../../../../controls/react/ant/antModal";
import {RefreshTimer} from "../../../../controls/grid/refreshTimer";
import {DataSizeIndicator} from "../../../../controls/grid/dataSizeIndicator";
import {GridSearchInput} from "../../../../controls/grid/gridSearchInput";
import {ViewSelector} from "../../../../controls/grid/viewManager/viewSelector";
import {Grid} from "../../../../controls/grid/grid";
import {Section, Toolbar} from "../../../../controls/react/layout";

export class ServicesModalProps {
	serviceState: string;
	tags: string[];
	onClose?: () => void;
}

export class ServicesPopupGridStore extends ServicesListStore {
	props: ServicesModalProps;

	constructor(props: ServicesModalProps) {
		super();
		this.props = props
		this.init()
	}

	async init() {
		const filtersResult = await apiFetch(getServicesListFilters())
		if (!filtersResult.success) {
			return
		}
		const filtersConfiguration = filtersResult.data;
		const state = new GridState<ServicesListEntry>();
		state.ensureDefaultViewExists();
		this.addServiceStateFilter(state, filtersConfiguration);
		this.addTagsFilter(state, filtersConfiguration)

		this.gridStore = new GridStore<ServicesListEntry>({
			columns: this.getColumns(),
			stateProviders: [
				new ExternalStateProvider(state),
			],
			dataProvider: new RemoteDataProvider({
				dataSource: this.getDefaultDatasource(this.props),
				filtersSource: getServicesListFilters()
			}),
			plugins: [
				this.getAutoReloadPlugin(),
			],
			selection: GridSelectionType.Many
		})
	}

	addServiceStateFilter = (state: GridState<ServicesListEntry>, filtersConfiguration: RulesConfiguration) => {
		if (!this.props.serviceState) {
			return;
		}
		const filterConf = filtersConfiguration['serviceState'];
		if (filterConf) {
			const filterRuleDefinition = RuleDefinition.emptyRule('serviceState', filterConf.operators[0].value);
			filterRuleDefinition.properties.value = [this.props.serviceState];
			filterRuleDefinition.type = RuleDefinitionType.Rule;
			state.filters.addOrUpdateRule(filterRuleDefinition);
		}
	}

	addTagsFilter = (state: GridState<ServicesListEntry>, filtersConfiguration: RulesConfiguration) => {
		if (!this.props.tags?.length) {
			return;
		}
		const filterConf = filtersConfiguration['tags'];
		if (filterConf) {
			const filterRuleDefinition = RuleDefinition.emptyRule('tags', filterConf.operators[0].value);
			filterRuleDefinition.properties.value = [...this.props.tags];
			filterRuleDefinition.type = RuleDefinitionType.Rule;
			state.filters.addOrUpdateRule(filterRuleDefinition);
		}
	}
}

const b = require('b_').with('service-list-popup');

export const ServicesModal = observer(
	class InnerWidget extends React.PureComponent<ServicesModalProps> {
		store: ServicesPopupGridStore;

		constructor(props: ServicesModalProps) {
			super(props)
			this.store = new ServicesPopupGridStore(props);
		}

		render(){
			if(!this.store.gridStore?.selfInitialized)
				return null;

			return <AntModal open={true}
							 width={1300}
							 height={720}
							 mask={true}
							 destroyOnClose={true}
							 onCancel={this.props.onClose}
							 footer={<></>}
							 closable={true}
							 draggableHandle={'.' + b('toolbar')}
							 className={b()}>
				<Section appearance="none" height={"fit"}>
					<Toolbar containerClass={b('toolbar')}>
						<RefreshTimer store={this.store.gridStore}/>
						<DataSizeIndicator store={this.store.gridStore}/>
						<GridSearchInput store={this.store.gridStore}/>
						<ViewSelector store={this.store.gridStore}/>
					</Toolbar>
					<Grid store={this.store.gridStore}/>
				</Section>
			</AntModal>
		}

		componentWillUnmount() {
			this.store?.destroy()
		}
	}
)
