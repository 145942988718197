import React from "react";
import PropTypes from "prop-types";
import DropDownList, {propTypes} from "controls/react/kendoWrappers/dropDownList";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {ErrorHandler} from "core";
import {AccountsApi} from "areas/administration/accounts/api";

export class AccountDropDown extends React.PureComponent {
	constructor(props) {
		super(props);

		this.dataSource = createAccountsDatasource(props.accountId);

		this.config = {
			dataSource: this.dataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: true
		};
	}

	render() {
		const {config, combo, ...others} = this.props;
		return <DropDownList config={this.config}
							 combo={combo ?? true}
							 {...others}/>
	}
}

export default AccountDropDown;

export function createAccountsDatasource(accountId){
	return new CeeViewDataSource({
		transport: {
			read: {
				url: AccountsApi.getLiteUrl(accountId),
				contentType: 'application/json; charset=utf-8',
				type: 'GET',
				dataType: 'json',
				cache: false
			}
		},
		error: ErrorHandler.kendoServerError
	});
}

AccountDropDown.propTypes = {
	...propTypes,
	accountId: PropTypes.string
};
