import classnames from 'classnames';
import {severityToCssClass} from 'areas/assets/indicators';

export function getSeverityStateLastChanges(type, severity, operatingState, agentState) {
	let icon = '';
	const containerClasses = classnames(
		'cw_event_indicator',
		'cw_status',
		severityToCssClass[severity]
	);
	let html = `<span class="${containerClasses}">`;

	if (type === 'ASSET') {
		let glyphClass;
		let tooltip = '';
		if (agentState === 'MAINTENANCE' || operatingState === 'IN_MAINTENANCE') {
			glyphClass = 'wrench';
			tooltip = lang.account.messages.AGENT_MAINTENANCE;
		}
		if (agentState === 'WARNING') {
			glyphClass = 'exclamation-mark';
			tooltip = lang.account.messages.AGENT_WARNING;
		}
		if (agentState === 'ERROR') {
			glyphClass = 'remove';
			tooltip = lang.account.messages.AGENT_DOWN;
		}
		const assetClass = classnames(
			'cw_indicator',
			'glyphicons',
			glyphClass
		);
		icon = `<span class="${assetClass}" title="${tooltip}">`;
	}

	html += icon;
	html += '</span>';
	return html;
}

export function getMaintenanceTooltip(maintenanceDetails) {
	if (!maintenanceDetails.length) {
		return [];
	}
	let tooltip = [];
	maintenanceDetails.forEach((item) => {
		switch (item) {
			case 'ASSET':
				tooltip.push(lang.account.messages.ASSET_MAINTENANCE);
				break;
			case 'MONITOR':
				tooltip.push(lang.account.messages.MONITOR_MAINTENANCE);
				break;
			case 'SERVICE':
				tooltip.push(lang.SERVICE_MAINTENANCE);
				break;
			case 'AGENT':
				tooltip.push(lang.account.messages.AGENT_MAINTENANCE);
				break;
		}
	});
	return tooltip;
}

export function getSeverityStateEvents(severity, agentDown, inMaintenance, monitorError, historic, monitorMaintenance, maintenanceDetails) {
	let tooltip = getMaintenanceTooltip(maintenanceDetails);
	let icon = '';

	if (inMaintenance || monitorMaintenance) {
		icon = 'wrench';
	} else if (agentDown || monitorError) {
		icon = "exclamation-mark";
	}
	if (agentDown) {
		tooltip.push(lang.widget.errorCodes.AGENT_DOWN);
	}
	if (monitorError) {
		tooltip.push(lang.account.messages.MONITOR_ERROR);
	}
	tooltip = tooltip.join(' | ');

	const containerClasses = classnames(
		'cw_event_indicator',
		'cw_status',
		severityToCssClass[severity]
	);


	let html = `<span class="${containerClasses}" title='${tooltip}'>`;

	if (historic) {
		const historicClass = classnames(
			'cw_historic_event'
		);
		html += `<span class="${historicClass}"></span>`;
	} else if (icon) {
		const iconClasses = classnames(
			'cw_indicator',
			'glyphicons',
			icon
		);
		html += `<span class="${iconClasses}"></span>`;
	}

	html += '</span>';
	return html;
}

export function getSeverityClass(severity){
	var severityClass = {
		NONE: 'is_ok',
		MINOR: 'is_minor',
		MAJOR: 'is_major',
		CRITICAL: 'is_critical'
	};

	if (!severityClass[severity]) {
		return 'is_idle';
	}

	return severityClass[severity];
}


export function getSeverityState(severity, item, showHistoric = true) {
	let html;
	if (!severity && severity !== null) {
		html = '';
	} else {
		let cssClass = getSeverityClass(severity);
		let exclamationClass = '';
		let tooltip = [];
		let exclamation = '';
		let relativePos = '';
		let historic;
		let showWrench = false;
		if (item) {
			if (item.maintenanceDetails) {
				tooltip = getMaintenanceTooltip(item.maintenanceDetails);
			} else {
				if ((item.inMaintenance || item.assetMaintenance)) {
					tooltip.push(lang.account.messages.ASSET_MAINTENANCE);
				}
				if (item.monitorMaintenance) {
					tooltip.push(lang.account.messages.MONITOR_MAINTENANCE);
				}
			}
			if (item.monitorError) {
				exclamationClass = 'exclamation-mark';
				tooltip.push(lang.account.messages.MONITOR_ERROR);
				exclamation = 'data-reason="true" data-monitortype="' + (item.monitorType || '') + '" data-monitorerror="true"';
			}
			if (item.dataUnavailable) {
				exclamationClass = 'exclamation-mark';
				tooltip.push(lang.account.messages.MONITOR_ERROR_UNAVAILABLE);
			}
			if (showHistoric === true && item.hasEvent === false && severity !== 'NONE') {
				let historicClass = classnames('cw_historic_event');
				historic = `<span class="${historicClass}"></span>`;
				relativePos = 'relative';
			}
			if ((item.inMaintenance || item.monitorMaintenance || item.assetMaintenance) && !historic && exclamationClass === '') {
				showWrench = true;
			}
		}
		tooltip = tooltip?.join(' | ');

		let classes = classnames(
			'cw_service_indicator',
			'cw_status',
			showWrench ? 'glyphicons wrench white' : '',
			cssClass
		);
		html = `<span title="${tooltip || ''}" class="${classes}">`;

		if (historic) {
			html += historic;
		}
		if (exclamationClass) {
			let iconClasses = classnames(
				'cw_indicator',
				'glyphicons',
				exclamationClass,
				relativePos
			);

			let icon;
			if (exclamation) {
				let monitorType = item.monitorType || '';
				icon = `<span class="${iconClasses}" title="${tooltip}" data-reason="true" data-monitortype=${monitorType} data-monitorerror="true"></span>`;
			} else {
				icon = `<span class="${iconClasses}" title="${tooltip}"></span>`;
			}
			html += icon;
		}
		html += '</span>';
	}

	return html;
}

export function getSeverityStateApplication(severity, item) {
	let html;
	if (!severity && severity !== null) {
		html = '';
	} else {
		let cssClass = getSeverityClass(severity);
		let tooltip = [];
		let showWrench = false;
		if (item) {
			if (item.assetMaintenance) {
				tooltip.push(lang.account.messages.ASSET_MAINTENANCE);
				showWrench = true;
			}
			if (item.monitorMaintenance) {
				tooltip.push(lang.account.messages.MONITOR_MAINTENANCE);
				showWrench = true;
			}
		}
		tooltip = tooltip.join(' | ');

		let pillboxClasses = classnames(
			'cw_pillbox',
			'pointer',
			cssClass
		);
		html = `<div class="${pillboxClasses}">`;
		html += `<div class="cw_status">`;
		if (showWrench) {
			let wrenchClasses = classnames(
				'glyphicons',
				'wrench',
				'white'
			);
			html += `<span class="${wrenchClasses}" title="${tooltip}"></span>`;
		}
		html += `</div>`;
		let healthIndex = item.applicationHI || item.healthIndex;
		let index = healthIndex >= 0 ? healthIndex : 'N/A';
		html += `<span class="cw_value">${index}</span>`;
		html += `</div>`;
	}

	return html;
}
