import React from 'react';
import {Utils} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';

import aggregationTypeDataSource from 'areas/service-boards/widgets/common/dataSources/aggregationTypeDataSource';


export function MultigraphWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {MultigraphWidgetConfiguration as default}

jQuery.extend(MultigraphWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */

	/**
	 * Main init function
	 */
	initComponent: function () {
		this.selectedMetrics = [];
		var widgetObj = this.widgetObj;
		var html = `<!-- kpi-multigraph -->
			<div class="cw_field">
				<label class="cw_inline">${lang.TITLE}: </label>
				<input type="text" class="cw_widget_title cw_inline" value="${((widgetObj.title || ''))}" />
			</div>
			<div class="cw-separator-frame selection">
				<div class="cw-separator-frame__title">${lang.SELECTION}</div>
				<div class="cw_field" id="cw_multigraph_account">
					<label class="cw_inline">${(lang.ACCOUNT)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_accountid" type="text" />
					</div>
				</div>

				<div class="cw_field" id="cw_multigraph_profile">
					<label class="cw_inline">${(lang.PROFILE)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_profileid" type="text" value="" />
					</div>
				</div>
				<div class="cw_field" id="cw_multigraph_category">
					<label class="cw_inline">${(lang.CATEGORY)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_categoryid" type="text" value="" />
					</div>
				</div>
				<div class="cw_field" id="cw_multigraph_rule_type">
					<label class="cw_inline">${(lang.kpi.RULE_TYPE)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_ruletypeid" type="text" value="" />
					</div>
				</div>
				<div class="cw_field" id="cw_multigraph_sample_rate">
					<label class="cw_inline">${(lang.kpi.SAMPLE_RATE)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_sample_rateid" type="text" value="" />
					</div>
				</div>
				<div class="cw_field" id="cw_multigraph_kpi_accounts">
					<label class="cw_inline">${(lang.kpi.KPI_ACCOUNTS)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_kpi_accountsids" type="text" />
					</div>
				</div>

				<div class="cw_field cw_multigraph_button" id="cw_multigraph_metrics_button">
					<button class="k-button k-primary" id="cw_add_metrics_button" disabled>${(lang.ADD)}</button>
				</div>
			</div>`;

		html += '<div class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.kpi.KPI_METRICS + ': </span>';
		html += '<div class="left w100">';
		html += '<select id="cw_multigraph_metrics_list" type="text" class="w100"></select>';
		html += '</div>';
		html += '</div>';

		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIOD + ': </label>';
		html += '<ul id="cw_multigraph_period_control" class="cw_multi_toggle right">';
		html += '</ul>';
		html += '<div class="cw_hide_time_selector"><input type="checkbox" class="cw_include k-checkbox" id="cw_hide_time_selector"' + (widgetObj.configuration.hideTimeSelector ? 'checked="checked"' : '') + '/>';
		html += '<label for="cw_hide_time_selector" class="k-checkbox-label">' + lang.widget.HIDE_TIME_SELECTOR + '</label></div>';
		html += '</div>';
		html += '<div class="cw_field cw_no_label hide" id="cw_multigraph_dates">';
		html += '<input id="cw_multigraph_startdate" type="text" /> - <input id="cw_multigraph_enddate" type="text" />';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="cw_include k-checkbox" id="cw_ignore_missing_data" title="' + lang.serviceBoard.messages.MISSING_DATA_MESSAGE + '"></span>';
		html += '<label for="cw_ignore_missing_data" class="k-checkbox-label">' + lang.serviceBoard.IGNORE_MISSING_DATA + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="k-checkbox" id="cw_show_regression">';
		html += '<label for="cw_show_regression" class="k-checkbox-label">' + lang.SHOW_REGRESSION + '</label>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.AGGREGATION_TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_aggregation_type" type="text" />';
		html += '</div>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_multigraph_timezone" type="text" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '"/></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.LEGEND + ': </span>';
		html += '<input id="cw_multigraph_legend" type="text" />';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append($(html).addClass('kpi-multigraphWidgetConfiguration'));
		if (this.mode === 'update' || this.wasUpdate) {
			this.widgetAccountId = this.widgetObj.configuration.accountId;
			this.selectedMetrics = this.widgetObj.configuration.metrics;
		}

		this.addTimeSelector(this.widgetObj.configuration, $('#cw_multigraph_period_control')[0]);

		this.legendDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<KPI account>',
			id: '<KPI account>'
		}, {
			name: '<Profile>',
			id: '<Profile>'
		}, {
			name: '<RuleType>',
			id: '<RuleType>'
		}, {
			name: '<SampleRate>',
			id: '<SampleRate>'
		}];

		if (this.mode === 'update' || this.wasUpdate) {
			this.updateTimePeriodSelectorControlValue?.(widgetObj.configuration.period);
			if (!widgetObj.configuration.labelTemplate) {
				this.legendMultiList = this.legendDataSource;
			} else {
				this.legendMultiList = widgetObj.configuration.labelTemplate;
			}
			this.loadSelectedMetrics();
		}
		this.renderLegendMultiSelect($('#cw_multigraph_legend').parent().get()[0]);
		this.initKendoComponents();

		if (widgetObj.configuration.period === 'CUSTOM') {
			$('#cw_multigraph_dates').removeClass('hide');
			$('#cw_multigraph_startdate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.startDate));
			$('#cw_multigraph_enddate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.endDate));
		}
		$('#cw_add_metrics_button').off().on('click', $.proxy(this.onAddMetricsClick, this));
	},

	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;
		if (this.wasUpdate) {
			$('#cw_show_regression').prop('checked', widgetObj.configuration.showRegression);
			$('#cw_hide_time_selector').prop('checked', widgetObj.configuration.hideTimeSelector);
			$('#cw_ignore_missing_data').prop('checked', widgetObj.configuration.ignoreMissingData);
		}

		this.aggregationType = $('#cw_aggregation_type').kendoDropDownList({
			dataSource: aggregationTypeDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.aggregationType || 'high'
		}).data('kendoDropDownList');

		this.createAccountComponent({
			element: $('#cw_multigraph_accountid'),
			container: $('#cw_multigraph_account'),
			nextElement: $('#cw_multigraph_profileid'),
			otherElements: [$('#cw_multigraph_categoryid'), $('#cw_multigraph_ruletypeid'), $('#cw_multigraph_sample_rateid'), $('#cw_multigraph_kpi_accountsids')],
			events: {
				change: (e) => {
					this.isInputChanged = true;
					this.currentAccountId = e.sender.value();
					this.profileSelector.dataSource.read();
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_profile'),
							label: lang.PROFILE
						}, {
							container: $('#cw_multigraph_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_multigraph_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_multigraph_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_multigraph_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.sender.dataSource.data().length
					});
				},
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_profile'),
							label: lang.PROFILE
						}, {
							container: $('#cw_multigraph_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_multigraph_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_multigraph_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_multigraph_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: length
					});
				}, this)
			},
			isMultigraph: true
		});

		this.profileSelector = $('#cw_multigraph_profileid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/lite?ignoreTags=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_multigraph_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_multigraph_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_multigraph_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(function (e) {
				if (e.item) {
					this.isProfileChanged = true;
					this.isInputChanged = true;
					this.selectedProfileId = e.dataItem.id;
					var categoryHandler = $('#cw_multigraph_categoryid').data('kendoComboBox');
					var ruleTypeHandler = $('#cw_multigraph_ruletypeid').data('kendoComboBox');
					var sampleRateHandler = $('#cw_multigraph_sample_rateid').data('kendoComboBox');
					var kpiAccountsHandler = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox');
					if (this.selectedProfileId) {
						categoryHandler.enable(true);
						categoryHandler.dataSource.read();
						categoryHandler.text('');
					} else {
						categoryHandler.enable(false);
					}
					ruleTypeHandler.text('');
					ruleTypeHandler.enable(false);
					sampleRateHandler.text('');
					sampleRateHandler.enable(false);
					kpiAccountsHandler.text('');
					kpiAccountsHandler.enable(false);
				}
				var multigraphKpiAccountsCombo = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox');
				if (multigraphKpiAccountsCombo.value() === lang.SELECT) {
					$('#cw_add_metrics_button').prop('disabled', 'disabled');
				}
			}, this),
			template: '<span data-id="${ data.id }">${ data.name }</span>',
			enable: this.mode === 'update',
			autoBind: false,
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isAccountChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		$('#cw_multigraph_categoryid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/categories';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_multigraph_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_multigraph_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				if (e.item) {
					this.isCategoryChanged = true;
					this.isInputChanged = true;
					this.selectedCategoryId = e.dataItem.value;
					var ruleTypeHandler = $('#cw_multigraph_ruletypeid').data('kendoComboBox');
					var sampleRateHandler = $('#cw_multigraph_sample_rateid').data('kendoComboBox');
					var kpiAccountsHandler = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox');
					if (this.selectedCategoryId) {
						ruleTypeHandler.enable(true);
						ruleTypeHandler.dataSource.read();
						ruleTypeHandler.text('');
					} else {
						ruleTypeHandler.enable(false);
					}
					sampleRateHandler.text('')
					sampleRateHandler.enable(false);
					kpiAccountsHandler.text('');
					kpiAccountsHandler.enable(false);
				}
				var multigraphKpiAccountsCombo = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox');
				if (multigraphKpiAccountsCombo.value() === lang.SELECT) {
					$('#cw_add_metrics_button').prop('disabled', 'disabled');
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			autoBind: false,
			enable: false,
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isProfileChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
		}).data('kendoComboBox');

		$('#cw_multigraph_ruletypeid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/categories/' + this.selectedCategoryId + '/types';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					model: {
						id: "value"
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_multigraph_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				if (e.item) {
					this.isRuleTypeChanged = true;
					this.isInputChanged = true;
					this.selectedRuleTypeId = e.dataItem.value;
					//var ruleType = e.sender.dataSource.get(this.selectedRuleTypeId);
					var sampleRateHandler = $('#cw_multigraph_sample_rateid').data('kendoComboBox');
					var kpiAccountsHandler = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox');
					if (this.selectedRuleTypeId) {
						sampleRateHandler.enable(true);
						sampleRateHandler.dataSource.read();
						sampleRateHandler.text('');
					} else {
						sampleRateHandler.enable(false);
					}
					kpiAccountsHandler.text('')
					kpiAccountsHandler.enable(false);
				}
				var multigraphKpiAccountsCombo = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox');
				if (multigraphKpiAccountsCombo.value() === lang.SELECT) {
					$('#cw_add_metrics_button').prop('disabled', 'disabled');
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			autoBind: false,
			enable: false,
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isCategoryChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
		}).data('kendoComboBox');

		$('#cw_multigraph_sample_rateid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/types/' + this.selectedRuleTypeId + '/sampleRates';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				if (e.item) {
					this.isSampleRateChanged = true;
					this.isInputChanged = true;
					this.selectedSampleRateId = e.dataItem.value;
					var kpiAccountsHandler = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox');
					if (this.selectedSampleRateId) {
						kpiAccountsHandler.enable(true);
						kpiAccountsHandler.text(lang.SELECT);
						kpiAccountsHandler.dataSource.read();
						kpiAccountsHandler.text('');
					} else {
						kpiAccountsHandler.enable(false);
					}
				}
				var multigraphKpiAccountsCombo = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox');
				if (multigraphKpiAccountsCombo.value() === lang.SELECT) {
					$('#cw_add_metrics_button').prop('disabled', 'disabled');
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			autoBind: false,
			enable: false,
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isRuleTypeChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		$('#cw_multigraph_kpi_accountsids').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/accounts';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: function (data) {
						data.unshift({
							value: 'aggregated',
							text: lang.AGGREGATED
						});
						data.unshift({
							value: 'all',
							text: lang.ALL
						});

						return data;
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				if (e.item) {
					this.selectedKpiAccountId = e.dataItem.value;
					var addButton = $('#cw_add_metrics_button');
					addButton.prop('disabled', !this.selectedKpiAccountId);
				}
			}, this),
			template: '<span data-value="${value}">${text}</span>',
			autoBind: false,
			enable: false,
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isSampleRateChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		this.multigraphMetricsList = $('#cw_multigraph_metrics_list').kendoSortedMultiSelect({
			dataSource: [],
			dataTextField: 'name',
			dataValueField: 'id',
			tagTemplate: '<span title="${name}">${name}</span>',
			placeholder: lang.kpi.messages.NO_ACCOUNTS
		}).data('kendoSortedMultiSelect');

		$("#cw_multigraph_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_multigraph_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$('#cw_multigraph_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			clearButton: false,
		});
	},
	/*
	* Handler function for loading selected metrics
	* */
	loadSelectedMetrics: function () {
		var url = Settings.serverPath + 'accounts/' + (this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/text',
			data = this.widgetObj.configuration.metrics || {};

		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (response) {
			var metricsList = $('#cw_multigraph_metrics_list').data('kendoSortedMultiSelect');
			var metrics = [], selectedMetrics = [], accountText, guid, item;
			for (var i = 0, length = response.length; i < length; i++) {
				item = response[i];
				if (item.aggregated) {
					accountText = lang.AGGREGATED;
				} else if (item.kpiAccountName === null) {
					accountText = lang.ALL;
				} else {
					accountText = item.kpiAccountName;
				}
				guid = Utils.guid();
				metrics.push({
					id: guid,
					profileId: item.profileId,
					type: item.type,
					sampleRate: item.sampleRate,
					accountId: item.kpiAccountId,
					aggregated: item.aggregated,
					name: item.profileText + '/' + item.typeText + '/' + item.sampleRateText + '/' + accountText
				});
				selectedMetrics.push(guid);
			}

			metricsList.setDataSource(metrics);
			metricsList.value(selectedMetrics);
		}, this));
	},
	/**
	 * Handler function for the click event on qualifier type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onPeriodToggle: function (value) {
		if (value === 'CUSTOM') {
			$('#cw_multigraph_dates').removeClass('hide');
		} else {
			$('#cw_multigraph_dates').addClass('hide');
		}
	},
	/**
	 * Handler function for the click event on the ignore missing data chckbox
	 * @param {type} e
	 */
	onIgnoreCheckbox: function (e) {
		var checkbox = $(e.currentTarget);
		checkbox.toggleClass('check unchecked');
	},
	findRecord: function (dataSource, id) {
		var record = null;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var r = dataSource.at(i);
			if (r.id === id) {
				record = r;
				break;
			}
		}
		return record;
	},
	getValues: function () {
		var title = $('.cw_widget_title').val().trim() || lang.MULTIGRAPH;
		var showRegression = $('#cw_show_regression').is(':checked');
		var ignoreMissingData = $('#cw_ignore_missing_data').is(':checked');
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'kpi_multigraph',
			title: title,
			configuration: {
				accountId: this.widgetAccountId,
				...this.periodToggle,
				startDate: $('#cw_multigraph_startdate').data('kendoDateTimePicker').value(),
				endDate: $('#cw_multigraph_enddate').data('kendoDateTimePicker').value(),
				timezone: $('#cw_multigraph_timezone').data('kendoComboBox').value(),
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				labelTemplate: this.legendMultiList,
				ignoreMissingData: ignoreMissingData,
				showRegression: showRegression,
				aggregationType: this.aggregationType.value()
			}
		};
		var dataItems = $('#cw_multigraph_metrics_list').data('kendoSortedMultiSelect').dataItems(), metrics = [], item;
		for (var i = 0, length = dataItems.length; i < length; i++) {
			item = dataItems[i];
			metrics.push({
				profileId: item.profileId,
				type: item.type,
				sampleRate: item.sampleRate,
				aggregated: item.aggregated
			});
		}
		widgetObj.configuration.metrics = metrics;
		widgetObj.configuration.metricsList = dataItems;

		return widgetObj;
	},
	/*
	 * Handler function for the click event of the Add metrics button (Multigraph)
	 * @param {type} e
	 */
	onAddMetricsClick: function (e) {
		var kpiAccount = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox').value(), kpiAccounts;
		var kpiAccountId, currentSelection, profileText = $('#cw_multigraph_profileid').data('kendoComboBox').text(),
			aggregated = false;

		if (kpiAccount === 'all') {
			kpiAccountId = null;
		} else if (kpiAccount === 'aggregated') {
			kpiAccountId = null;
			aggregated = true;
		} else {
			kpiAccountId = kpiAccount;
		}

		currentSelection = {
			profileId: this.selectedProfileId,
			type: this.selectedRuleTypeId || null,
			sampleRate: this.selectedSampleRateId || null,
			accountId: kpiAccountId,
			aggregated: aggregated
		};

		this.addToSelectedMetrics(currentSelection);
	},
	/*
	* Handler function for adding new items to selected metrics
	* @param {Object} selection The object with selected properties
	* */
	addToSelectedMetrics: function (selection) {
		var item, data = this.multigraphMetricsList.dataItems(), length = data.length, addItem = true;
		if (length) {
			for (var i = 0; i < length; i++) {
				item = data[i];
				if (item.profileId === selection.profileId && item.type === selection.type && item.sampleRate === selection.sampleRate && item.aggregated === selection.aggregated && (item.accountId === null || item.accountId === selection.accountId)) {
					this.selectMetricItem(item.id);
					addItem = false;
				}
			}
		}

		if (addItem) {
			this.addMetricItem(selection);
		}
	},
	/*
	* Handler function for adding item to datasource and select it for kpi metric multiselect
	* @param {Object} item The item
	* */
	addMetricItem: function (item) {
		var accountText, guid,
			profileText = $('#cw_multigraph_profileid').data('kendoComboBox').text(),
			ruleType = $('#cw_multigraph_ruletypeid').data('kendoComboBox'),
			ruleTypeText = ruleType.value() ? ruleType.text() : lang.ALL,
			sampleRate = $('#cw_multigraph_sample_rateid').data('kendoComboBox'),
			sampleRateText = sampleRate.value() ? sampleRate.text() : lang.ALL;

		if (item.aggregated) {
			accountText = lang.AGGREGATED;
		} else if (!item.accountId) {
			accountText = lang.ALL;
		} else {
			accountText = $('#cw_multigraph_kpi_accountsids').data('kendoComboBox').text();
		}

		guid = Utils.guid();
		this.multigraphMetricsList.dataSource.add({
			id: guid,
			profileId: item.profileId,
			type: item.type,
			sampleRate: item.sampleRate,
			accountId: item.accountId,
			aggregated: item.aggregated,
			name: profileText + '/' + ruleTypeText + '/' + sampleRateText + '/' + accountText
		});

		this.selectMetricItem(guid);
	},
	/*
	* Handler function for adding item KPI metrics as selected
	* @param {Guid} id The KPI metrics item ID
	* */
	selectMetricItem: function (id) {
		var values = this.multigraphMetricsList.value();
		values.push(id);
		this.multigraphMetricsList.value(values);
	},
	/**
	 * Validates the widget config form
	 * @return {Boolean} valid True if the form is valid, false otherwise
	 */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		if (!$('#cw_multigraph_metrics_list').data('kendoSortedMultiSelect').dataItems().length) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_KPI_ACCOUNTS;
		}

		var account = $('#cw_multigraph_accountid').data('kendoComboBox');
		if (!Utils.isGuid(account.value())) {
			valid = false;
			this.validationMessage = lang.SELECT_ACCOUNT;
		}
		var profileCombo = $('#cw_multigraph_profileid').data('kendoComboBox');
		if (!profileCombo.value()) {
			valid = false;
			this.validationMessage = lang.SELECT_SERVICE;
		}
		var categoryCombo = $('#cw_multigraph_categoryid').data('kendoComboBox');
		if (!categoryCombo.value()) {
			valid = false;
			this.validationMessage = lang.SELECT_SERVICE_ELEMENT;
		}
		var ruleTypeCombo = $('#cw_multigraph_ruletypeid').data('kendoComboBox');
		if (!ruleTypeCombo.value()) {
			valid = false;
			this.validationMessage = lang.SELECT;
		}
		var sampleRateCombo = $('#cw_multigraph_sample_rateid').data('kendoComboBox');
		if (!sampleRateCombo.value()) {
			valid = false;
			this.validationMessage = lang.SELECT;
		}
		if(this.mode == 'update' && $('#cw_multigraph_metrics_list').data('kendoSortedMultiSelect').dataItems().length > 0)
			valid = true
		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},
	resetDropdowns: function (list) {
		for (var i = 0; i < list.length; i++) {
			//list[i].value(null);
			if (i >= 1) {
				list[i].enable(false);
			} else {
				list[i].enable(true);
			}
		}
	}
});
