import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';

import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import State from 'tools/state';
import GridSearch from 'controls/gridSearch';
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import {getGridStateForSaving, updateHiddenColumns} from "controls/react/kendoWrappers/grid";

export function IncidentsWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	/*
	 * @property hasConfiguration - set to true to import widget specific configuration into serviceboard
	 */
	this.hasConfiguration = true;
	this.instanceConfiguration.includeSubaccounts = this.instanceConfiguration.includeSubaccounts || false;
	this.subscriberId = this.id;
};

export {IncidentsWidget as default}

jQuery.extend(IncidentsWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	init: function () {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		widgetContentDiv.parent().addClass('cw_section_incidentsgrid');
		this.gridId = Utils.guid();
		widgetContentDiv.empty().append('<div id="' + this.gridId + '" ></div>');
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		this.currentAccountsList = [];
		this.isEventDriven = false;
		this.initKendoComponents();
		this.subscribe();
		this.updateTitle();
		if (!this.sessionId) {
			$('#' + this.gridId).off();
			if (State.mainApp && State.mainApp.session.hasRole('INCIDENT_READ')) {
				$('#' + this.gridId).on('click', '.cw_incidents_link', $.proxy(this.onIncidentsClick, this));
			}
		}
		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));
	},
	/**
	 * Initializes Kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter;
		var gridSort = this.instanceConfiguration.sort || this.persistedState?.sort || [{
			field: 'created',
			dir: 'desc',
			compare: null
		}];
		var gridFilter = this.instanceConfiguration.filter || this.persistedState?.filter;
		var savedColumns = updateHiddenColumns(this.persistedState?.columns, this.instanceConfiguration.columns);

		let tags = [];
		if (this.instanceConfiguration.tags) {
			for (let i = 0; i < this.instanceConfiguration.tags.length; i++) {
				tags.push(this.instanceConfiguration.tags[i].name);
			}
		}

		var data = {
			accountIds: this.instanceConfiguration.accountsList,
			tags: tags,
			includeSubaccounts: this.instanceConfiguration.includeSubaccounts
		};
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: $.proxy(function () {
						return this.requestPath + 'incidents/accountIncidents';
					}, this),
					contentType: "application/json; charset=utf-8",
					type: "POST",
					dataType: "json",
					cache: false
				},
				parameterMap: $.proxy(function () {
					return JSON.stringify(data);
				}, this)
			},
			schema: {
				model: {
					id: 'id',
					fields: {
						created: {
							type: 'date'
						},
						modified: {
							type: 'date'
						}
					}
				},
				parse: function (data) {
					for (var i = 0, length = data.length; i < length; i++) {
						data[i].created = new Date(data[i].created);
						data[i].modified = new Date(data[i].modified);
						if (data[i].tags) {
							data[i].tags = data[i].tags.join(', ');
						}
					}
					return data;
				}
			},
			sort: gridSort,
			filter: gridFilter,
			change: $.proxy(function (e) {
				var sort, gridHandler = $('#' + this.gridId);
				if (e.items.length === 0) {
					if (gridHandler.find('.status').length) {
						gridHandler.find('.status').remove();
					}
				} else {
					if (gridHandler.find('.status').length) {
						gridHandler.find('.status').remove();
					}
					gridHandler.find('.k-grid-content').removeClass('hide');
				}
			}, this)
		});

		this.grid = $('#' + this.gridId).kendoCustomGrid({
			reorderable: true,
			dataSource: this.dataSource,
			resizable: true,
			scrollable: true,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			height: this.isDashboardMode() ? '100%' : null,
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'status',
				title: lang.service.STATUS,
				sortable: true,
				filterable: true,
				template: '#: status || "" #',
				width: 80
			}, {
				field: 'incidentNumber',
				title: lang.incidents.NUMBER,
				template: '<a class="cw_link cw_incidents_link ellipsis to_expand">#= incidentNumber #</a>',
				sortable: true,
				filterable: true,
				width: 110,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'subject',
				title: lang.incidents.SUBJECT,
				sortable: true,
				filterable: true,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				width: 240
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							contains: lang.grid.filter.ISIN,
							doesnotcontain: lang.grid.filter.ISNOTIN
						}
					}
				},
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'contentType',
				title: lang.TYPE,
				sortable: true,
				filterable: true,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'priorityName',
				title: lang.assethealth.PRIORITY,
				sortable: true,
				filterable: true,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'impactName',
				title: lang.assethealth.IMPACT,
				sortable: true,
				filterable: true,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'owner',
				title: lang.incidents.OWNER,
				sortable: true,
				filterable: true,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'created',
				title: lang.incidents.CREATED,
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				template: '#= Renderer.browserDateRenderer(created, "datetime")#',
				width: 220,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'modified',
				title: lang.incidents.MODIFIED,
				template: '#= Renderer.browserDateRenderer(modified, "datetime")#',
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				width: 220,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'createdBy',
				title: lang.incidents.REPORTED_BY,
				sortable: true,
				filterable: true,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assignedTo',
				title: lang.incidents.ASSIGNED_TO,
				sortable: true,
				filterable: true,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], savedColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onReasonsDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);

		var searchId = $('#' + this.id).find('.cw_search_box').attr('id');
		this.gridSearch = new GridSearch({
			id: searchId,
			dataSource: this.dataSource,
			fields: ['status', 'subject', 'incidentNumber', 'accountName', 'contentType', 'owner', 'createdBy', 'assignedTo']
		});
	},
	/**
	 * Handler function for the click event on the incidents click
	 * @param {Object} e The click event
	 */
	onIncidentsClick: function (e) {
		var target = $(e.currentTarget);
		var uid = target.closest('tr').attr('data-uid');
		var data = this.dataSource.getByUid(uid);

		this.navigation.go({
			url: IncidentsRouter.details(data.id)
		})
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $('#' + this.gridId).select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_automation_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the Reasons grid data bound event
	 */
	onReasonsDataBound: function () {
		$('#' + this.gridId).find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		setTimeout(() => this.adjustSectionHeight(this.id), 200);
	},
	/**
	 * Called when an event is received from the server
	 */
	onEvent: function () {
		this.isEventDriven = true;
		this.refresh();
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function () {
		if (!this.title) {
			var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
			titleSpan.html(lang.account.ACCOUNT_STATUS);
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		// TODO the resize functionality
		this.adjustSectionHeight(this.id);
	},
	/**
	 * Refresh the widget
	 */
	refresh: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function (id) {
		let heightAdjust;
		if (this.isDashboardMode() && !this.isWithoutHeader()) {
			heightAdjust = 79;
		} else {
			heightAdjust = 39;
		}
		let section = $('#' + id);
		let sectionHeight = section.height();
		let contentHeight = sectionHeight - heightAdjust;
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', contentHeight);

		let shownContent = section.find('.k-virtual-scrollable-wrap');
		let contentWidth = section.find('.k-grid-content').width();
		let actualContentHeight = section.find('.k-grid-content table').height();
		let shownContentHeight = shownContent.height();
		if (actualContentHeight < shownContentHeight) {
			section.find('.k-scrollbar-vertical').addClass('hide');
			shownContent.css('width', contentWidth + 18 + 'px');
		} else {
			section.find('.k-scrollbar-vertical').removeClass('hide');
		}
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		let subscriptionObj = [{
			eventType: 'Incident',
			accountIds: this.instanceConfiguration.accountsList,
			includeSubaccounts: this.instanceConfiguration.includeSubaccounts
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		$('#' + this.gridId).off();
		$(window).off('resize', $.proxy(this.onResize, this));
		Widget.prototype.destroy.call(this);
	},

	getStateForSaving(){
		return getGridStateForSaving(this.grid);
	}
});
