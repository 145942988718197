import React from "react";

export type StoreBase<TProps extends object> = {
	init?: () => void
	destroy?: () => void
	props?: TProps
}

export type UseStoreExtra<TProps> = {
	props?: TProps,
	deps?: any[]
}

export function useStore<TProps extends object, TStore extends StoreBase<TProps>>(createFunction: () => TStore, extra?: UseStoreExtra<TProps>) {
	const initializedRef = React.useRef(false)
	const firstRenderRef = React.useRef(true)
	const [store, setStore] = React.useState(createFunction)

	React.useEffect(() => {
		let newStore = store

		if (initializedRef.current == false && firstRenderRef.current == false) {
			newStore = createFunction()
			setStore(newStore)
		}

		if (initializedRef.current == false) {
			newStore.init?.()
		}

		firstRenderRef.current = false

		return () => {
			newStore.destroy?.()
			initializedRef.current = false
		}
	}, extra?.deps ?? [])

	const deps = extra?.props ? Object.values(extra.props) : []
	deps.push(store)

	React.useEffect(() => {
		if (extra?.props) {
			if(store.props == null){
				//@ts-ignore
				store.props = {}
			}
			Object.assign(store.props, extra.props)
		}
	}, deps)

	return store
}
