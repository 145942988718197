import React, {useState} from 'react';

import {Radio, RadioGroupProps} from 'antd';
import {RadioButtonProps} from 'antd/lib/radio/radioButton';

import {captureValueLink, ChangeEventHandlerWrapped, wrapOnChange} from "controls/react/ant/utils";

interface AntRadioButtonProps extends RadioButtonProps{

}

export const AntRadioButton:React.FunctionComponent<AntRadioButtonProps> = props => {
	return <Radio.Button {...props}/>
}


interface AntRadioGroupProps<VT> extends Omit<RadioGroupProps,'onChange'> {
	valueLink?: any,
	nameField?: string,
	valueField?: string,
	dataList?: Array<any>,
	onChange?: ChangeEventHandlerWrapped<VT, any>
}

export const AntRadioGroup = <VT,>(props: AntRadioGroupProps<VT>) => {
	props = captureValueLink(props);

	const wrappedProps = wrapOnChange<VT, any, AntRadioGroupProps<VT>>(props);

	let {dataList, nameField, valueField,  ...antNativeProps} = wrappedProps;

	nameField = nameField || 'name';
	valueField = valueField || 'id';

	dataList = React.useMemo(()=> {
		if(!dataList)
			return null;

		if(Array.isArray(dataList))
			return dataList;

		return Object.keys(dataList).map( (key:any) => ({
			[nameField]: dataList[key],
			[valueField]: key
		}));

	}, [dataList]);

	return <Radio.Group {...antNativeProps}>
		{props.children}
		{dataList && renderRadioButtons(dataList, {nameField, valueField})}
	</Radio.Group>
}

export function renderRadioButtons(list: any[], {nameField = 'name', valueField = 'id'}) {
	return list.map(x => <AntRadioButton key={x[valueField]} value={x[valueField]}>{x[nameField]}</AntRadioButton>);
}

export const AntRadio = {
	Button: AntRadioButton,
	Group: AntRadioGroup
}

