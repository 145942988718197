import './reasonsGrid.less'

import React from "react";

import {translator, Cookies} from "core";
import {addLinks} from "core/react/links";

import {FormEntry, Grid, TextBox, TextArea, ActionButtons} from "controls/react/form";
import {Page} from "controls/react/layout/page";
import {Toolbar, ToolbarItemsSet, ToolbarItemPosition} from "controls/react/layout/toolbar";
import {Section} from "controls/react/layout/section";
import {GridMenu} from "controls";
import {MenuButton, TeamDropDown, StateWidget, FilterPanel} from "controls/react";
import Renderer from "tools/renderer";
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import State from 'tools/state'
import Utils from 'tools/utils';
import AssignReasonsWindow from "controls/assignReasonsWindow";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {UserPrefs, saveUserPrefs} from "tools/userPrefs";

import {ReasonsGridApi} from "./reasonsGridApi";
import {ApplicationsApi} from "./api";
import {AssetsRouter} from 'areas/assets/bundleDescription';
import {openMonitorView} from "core/application";
import {apiFetch} from "framework/api";
import {unassignLegacy} from "areas/summary/events/api";

import Lang from 'core/localization/lang'
import {BoxView} from "controls/react/layout/boxView";
import {IconButton} from "controls/react/form/iconButton";
import {getSeverityState} from 'controls/stateRenderer/severityState';
import {topLeftCornerPosition} from 'controls/modalWindow'
import {NavigationStore} from "../../framework/navigationStore";

const b = require('b_').with('reasons-grid');
const i = translator({
  "Create Incident": {
    "no": "Lag sak",
    "en": "Create incident"
  },
  "Acknowledge": {
    "no": "Bekreft"
  },
  "Unacknowledge": {
    "no": "Ubekreft"
  },
  "Assign": {
    "no": "Tildele"
  },
  "Delete Health Reason": {
    "no": "Slett helseårsak",
    "en": "Delete health reason"
  },
  "Create Filter": {
    "no": "Opprett filter",
    "en": "Create filter"
  },
  "Subsystem": {
    "no": "Delsystem"
  },
  "External ID": {
    "no": "Ekstern ID"
  },
  "Acknowledged": {
    "no": "Bekreftet"
  },
  "Acknowledged by": {
    "no": "Bekreftet av"
  },
  "Acknowledged at": {
    "no": "Bekreftet tid"
  },
  "Assigned to": {
    "no": "Tildelt"
  },
  "Assigned Type": {
    "no": "Tildelt type",
    "en": "Assigned type"
  },
  "Reset Health Index": {
    "no": "Tilbakestill helseindeks",
    "en": "Reset healthindex"
  },
  "The selected object has no Health reasons.": {
    "no": "Valgt objekt har ingen Helseårsaker.",
    "en": "The selected object has no health reasons."
  }
});

@addLinks
export class ReasonsGrid extends React.PureComponent {
	ref = React.createRef();
	constructor(props) {
		super(props);

		this.setDefaultColumns();

		this.state = {
			userPrefs: {},
			columns: [],
			reasons: [],
			reasonsAreFiltere: false,
			gridSort: {},
			gridFilter: [],
			gridLoaded: false
		}

		this.reasonsMenuItems = this.getReasonsMenuItems();
	}

	render() {
		return (
			<React.Fragment>
				<Section height={'fit'}
				         contentPadding={false}
				         containerClass={b()}
				         containerRef={this.ref}>
					<Toolbar title={i('Health Reasons')}>
						<MenuButton ref={ref => this.menuRef = ref}
						            items={this.reasonsMenuItems}/>
						{ this.state.reasonsAreFiltered &&
							<BoxView containerClass={b('warning')} type={"info-2"} rounded={true} border={true}
											 position={ToolbarItemPosition.AFTER_TITLE}
											 onClick={this.props.clearReasonsFilter}>
								<IconButton embedded={true} iconName={"filter"}/>
								<div>{i('Content is filtered')}</div>
							</BoxView>
						}
						{this.state.gridLoaded &&
							<FilterPanel ref={ref => this.filterPanelRef = ref}
							             grid={this.grid}
							             userPref={this.userPref}
							             modulePrefName={this.props.userPrefsKey}
							             defaultPrefViewKey={'defaultFsView'}
							             prefListKey={'applicationDetailsFSViews'}
							             onRemove={this.saveUserPreferences}
							             searchFields={['assetName', 'text', 'monitorName', 'subsystem']}
							             searchValue={UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('applicationDetailsSearchPhrase') || '')}
							             defaultColumns={this.defaultColumns}
							             activateResetFilterIcon={this.activateResetFilterIcon}
							/>
						}
					</Toolbar>
					{this.state.gridLoaded &&
						<Grid columns={this.state.columns}
						      selectable={true}
						      fit
						      reorderable={true}
						      filterable={filterable}
						      filter={this.state.gridFilter}
						      sort={this.state.gridSort}
						      sortable={{mode: "multiple", allowUnsort: true}}
						      resizable={true}
						      scrollable={true}
						      dataSourceArray={this.state.reasons}
						      dataBound={this.onGridDataBound}
						      ref={this.setGridRef}
						/>
					}
				</Section>
			</React.Fragment>
		);
	}

	async componentDidMount() {
		$(this.ref.current).on('click', "._monitor-name-link", e => {
			var target = $(e.currentTarget);
			var monitorId = target.data('id');
			var monitorType = target.data('type');
			openMonitorView(monitorId, monitorType);
		});

		await this.initializeGrid();
	}

	setGridRef = ref => {
		this.grid = ref;
		if(!this.grid)
			return;

		this.menuRef?.setGrid(this.grid);
		this.filterPanelRef?.setGrid(this.grid);
	}

	async componentDidUpdate(prevProps) {
		if(prevProps.loadReasonsCallback != this.props.loadReasonsCallback){
			const [reasons, reasonsAreFiltered] = await this.props.loadReasonsCallback();
			this.setState({
				reasons,
				reasonsAreFiltered
			});
		}
	}

	componentWillUnmount() {
		this.saveUserPreferences();
	}

	onGridDataBound = (e) => {
		let grid = e.sender;
		Utils.greyReason(grid);
		if (this.grid) {
			this.showCounters(grid);
			if (this.grid.kendoGrid.dataSource.data().length==0) {
				grid.wrapper.find('.k-grid-norecords').empty().append(i('The selected object has no Health reasons.'))
			}
		}
	}

	showCounters(grid) {
		let wrapper = grid.wrapper;
		let reasonsContainer = wrapper.closest('.applications-details__reasons');
		let visibleItems = reasonsContainer.find('.cw_visible_items');
		let totalItems = reasonsContainer.find('.cw_total_items');
		let rows = wrapper.find('tr').length - 1;
		$('.cw_items_separator').removeClass('hide');
		visibleItems.text(rows);
		totalItems.text(this.grid.kendoGrid.dataSource.data().length);
	}

	getReasonsMenuItems = () => {
		return[{
			icon: 'construction-cone',
			text: i('Create Incident'),
			role: 'INCIDENT_UPDATE',
			disabled: this.props.readOnly,
			fn: () => this.onIncidentCreate()
		}, {
			icon: 'check',
			text: i('Acknowledge'),
			fn: () => this.onAckUnack('acknowledge'),
			disabled: this.props.readOnly,
			enabledIf: GridMenu.AtLeastOneItemSelected
		}, {
			icon: 'unchecked',
			text: i('Unacknowledge'),
			fn: () => this.onAckUnack('unacknowledge'),
			disabled: this.props.readOnly,
			enabledIf: GridMenu.AtLeastOneItemSelected
		}, {
			icon: 'object-align-horizontal',
			text: i('Assign'),
			fn: () => this.onAssign(),
			role: 'EVENT_SUMMARY_LIST',
			disabled: this.props.readOnly,
			enabledIf: GridMenu.AtLeastOneItemSelected
		}, {
			icon: 'undo',
			text: i('Unassign'),
			fn: () => this.onUnassign(),
			role: 'EVENT_SUMMARY_LIST',
			disabled: this.props.readOnly,
			enabledIf: GridMenu.AtLeastOneItemSelected
		}, {
			icon: 'remove-circle',
			text: i('Delete Health Reason'),
			fn: () => this.onRemove(),
			disabled: this.props.readOnly,
			enabledIf: GridMenu.AtLeastOneItemSelected
		}, {
			icon: 'filter',
			text: i('Create Filter'),
			fn: this.onCreateFilter,
			role: 'HEALTHFILTER_CREATE',
			disabled: this.props.readOnly,
			enabledIf: GridMenu.ExactlyOneItemSelected
		}, {
			icon: 'restart',
			text: i('Reset Health Index'),
			fn: () => this.onResetHealthIndexButton(),
			disabled: this.props.readOnly,
			enabledIf: GridMenu.AtLeastOneItemSelected,
			role: 'MONITOR_UPDATE'
		}];
	}

	onIncidentCreate = () => {
		let reasonsIds = [], selectedItems = this.grid.getCheckedItems();
		for (let item of selectedItems) {
			reasonsIds.push(item.id);
		}
		State.mainApp.navigate(IncidentsRouter.createNew('ASSET'),
			{
				extra: {
					reasonsIds
				},
			});
	}

	onRemove = async () => {
		let reasonsIds = [], selectedItems = this.grid.getCheckedItems();
		for (let item of selectedItems) {
			reasonsIds.push({
				id: item.id,
				accountId: item.accountId
			});
		}
		const result = await ReasonsGridApi.delete(reasonsIds);
		if(result.success){
			await this.refresh();
		}
	}

	onResetHealthIndexButton = async () => {
		let data = [], selectedItems = this.grid.getCheckedItems();
		for (let item of selectedItems) {
			data.push({
				monitorId: item.monitorId,
				assetId: item.assetId,
				agentId: item.agentId
			});
		}
		Utils.resetMonitorsHI(data,  async () => {
			await this.refresh();
		});
	}

	onAssign = async () => {
		let selectedReasons = [];
		for (let item of this.grid.getCheckedItems()) {
			if (Utils.isGuid(item.id)) {
				selectedReasons.push(item.id);
			}
		}
		this.assignSelector = new AssignReasonsWindow({
			renderTo: this.grid,
			selectedItems: selectedReasons,
			position: topLeftCornerPosition,
			onAssigned: async () => {
				await this.refresh();
			}
		});

	}

	onUnassign = async () => {
		let selectedEventIds = [], selectedItems = this.grid.getCheckedItems();
		for (let item of selectedItems) {
			if (Utils.isGuid(item.id)) {
				selectedEventIds.push(item.id);
			}
		}
		const result = await apiFetch(unassignLegacy({
			selection: {
				mode: 'INCLUDE',
				ids: selectedEventIds,
			}
		}));
		if(result.success){
			await this.refresh();
		}
	}

	onCreateFilter = (e) => {
		let items = this.grid.getCheckedItems();
		let record = items[0];
		const url = AssetsRouter.createAssetHealthFilter({
			populate: {
				monitorId: record.monitorId,
				monitorType: record.monitorType,
				severity: record.severity,
				reasonDescription: record.text,
				reasonSubsytem: record.subsystem,
				identifierName: record.identifierName || '',
				identifierInstance: record.identifierInstance || '',
				accountId: record.accountId,
				accountName: record.accountName
			}
		})
		NavigationStore.go(url)
	}

	onAckUnack = async type => {
		let items = this.grid.getCheckedItems();
		let payload = {
			assetIds: [],
			eventIds: []
		};
		for (let item of items) {
			if (Utils.isGuid(item.id)) {
				payload.eventIds.push(item.id);
			}
			if (Utils.isGuid(item.assetId)) {
				payload.assetIds.push(item.assetId);
			}
		}

		let result = type == 'acknowledge'
			? await ReasonsGridApi.acknowledge(payload)
			: await ReasonsGridApi.unacknowledge(payload);

		if (result.success) {
			await this.refresh();
		}
	}

	refresh = async () => {
		const [result, reasonsAreFiltered] = await this.props.loadReasonsCallback();
		this.grid.dataSource().data(result);
	}

	saveUserPreferences = () => {
		saveUserPrefs({
			category: this.props.userPrefsKey,
			filterPanel: this.filterPanelRef.filterPanel,
			grid: this.grid.kendoGrid,
			keys: {
				searchPhrase: 'applicationDetailsSearchPhrase',
				columns: 'applicationDetailsColumns',
				sort: 'applicationDetailsSort',
				filter: 'applicationDetailsFilter',
				FsViews: 'applicationDetailsFSViews'
			}
		}, null, this.userPref);
	}

	setDefaultColumns() {
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			severity: {
				hidden: false,
				width: 45
			},
			assetName: {
				hidden: false,
				width: 100
			},
			timestamp: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			text: {
				hidden: false,
				width: 400
			},
			monitorName: {
				hidden: false,
				width: 150
			},
			subsystem: {
				hidden: false,
				width: 90
			},
			externalId: {
				hidden: false,
				width: 45
			},
			acknowledged: {
				hidden: false,
				width: 45
			},
			acknowledgedByName: {
				hidden: false,
				width: 100
			},
			acknowledgedAt: {
				hidden: false,
				width: 180
			},
			assignedToName: {
				hidden: false,
				width: 45
			},
			assignedToType: {
				hidden: false,
				width: 50
			},
			information: {
				hidden: false,
				width: 200
			}
		};
	}

	getReasonsGridColumns = (columnsSettings) => {
		let columns = [{
			field: 'severity',
			title: i('Severity'),
			filterable: {
				extra: false,
				operators: {
					string: {
						eq: Lang.grid.filter.ISIN,
						neq: Lang.grid.filter.ISNOTIN
					}
				},
				ui: (element) => {
					let multiselect = new MultiSelectGridFilter({
						element: element,
						field: 'severity',
						grid: this.grid.kendoGrid,
						dataSource: new kendo.data.DataSource({ data: [{
							text: lang.CRITICAL,
							icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_critical"></span>',
							value: 'CRITICAL'
						}, {
							text: lang.MAJOR,
							icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_major"></span>',
							value: 'MAJOR'
						}, {
							text: lang.MINOR,
							color: 'is_minor',
							icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_minor"></span>',
							value: 'MINOR'
						}, {
							text: lang.OK,
							icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_ok"></span>',
							value: 'NONE'
						}], serverSorting: true})
					});
				}
			},
			template: item => getSeverityState(item.severity, item),
			attributes: {
				'class': 'text_center'
			},
			width: columnsSettings.severity.width,
			hidden: columnsSettings.severity.hidden
		}, {
			field: 'assetName',
			title: i('Name'),
			filterable: true,
			template: (item) => {
				if (!State.mainApp.session.hasRole('ASSET_READ')) {
					return item.assetName;
				} else {
					return `<a class="cw_grid_link" href="#${AssetsRouter.details(item.assetId)}">${item.assetName}</a>`;
				}
			},
			width: columnsSettings.assetName.width,
			hidden: columnsSettings.assetName.hidden
		}, {
			field: 'timestamp',
			title: i('Time'),
			filterable: {
				extra: true,
				ui: function (el) {
					el.kendoDateTimePicker({
						format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
						timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
					});
				}
			},
			template: '#=Renderer.browserDateRenderer(timestamp, Constants.DATETIME)#',
			width: columnsSettings.timestamp.width,
			hidden: columnsSettings.timestamp.hidden,
		}, {
			field: 'text',
			title: i('Health Reasons'),
			filterable: true,
			width: columnsSettings.text.width,
			hidden: columnsSettings.text.hidden,
			attributes: {
				'class': 'tooltip ellipsis to_expand reason_text'
			},
		}, {
			field: 'monitorName',
			title: i('Monitor name'),
			template: item => {
				if (!State.mainApp.session.hasRole('MONITOR_UPDATE')) {
					return item.monitorName;
				} else {
					return `<a class="cw_grid_link _monitor-name-link" data-id="${item.monitorId}" data-type="${item.monitorType}">${item.monitorName}</a>`;
				}
			},
			filterable: true,
			width: columnsSettings.monitorName.width,
			hidden: columnsSettings.monitorName.hidden
		}, {
			field: 'subsystem',
			title: i('Subsystem'),
			filterable: true,
			width: columnsSettings.subsystem.width,
			hidden: columnsSettings.subsystem.hidden
		}, {
			field: 'externalId',
			title: i('External ID'),
			filterable: true,
			width: columnsSettings.externalId.width,
			hidden: columnsSettings.externalId.hidden
		}, {
			field: 'acknowledged',
			title: i('Acknowledged'),
			filterable: true,
			template: '#if(acknowledged){#<span class="glyphicons ok"></span>#}#',
			width: columnsSettings.acknowledged.width,
			hidden: columnsSettings.acknowledged.hidden
		}, {
			field: 'acknowledgedByName',
			title: i('Acknowledged by'),
			filterable: true,
			width: columnsSettings.acknowledgedByName.width,
			hidden: columnsSettings.acknowledgedByName.hidden,
			attributes: {
				'class': 'tooltip ellipsis to_expand reason_text'
			}
		}, {
			field: 'acknowledgedAt',
			title: i('Acknowledged at'),
			filterable: {
				extra: true,
				ui: function (el) {
					el.kendoDateTimePicker({
						format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
						timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
					});
				}
			},
			template: '#=Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME)#',
			width: columnsSettings.acknowledgedAt.width,
			hidden: columnsSettings.acknowledgedAt.hidden
		}, {
			field: 'assignedToName',
			title: i('Assigned to'),
			filterable: true,
			width: columnsSettings.assignedToName.width,
			hidden: columnsSettings.assignedToName.hidden
		}, {
			field: 'assignedToType',
			title: i('Assigned Type'),
			width: columnsSettings.assignedToType.width,
			hidden: columnsSettings.assignedToType.hidden,
			template: item => {
				const icon = item.assignedToType === 'ASSIGN_USER' ? 'user' : '';
				return `<span title="${lang.USER}: ${item.assignedToName}" class="glyphicons ${icon}"></span>`;
			},
		}, {
			field: 'information',
			title: i('Information'),
			filterable: true,
			width: columnsSettings.information.width,
			hidden: columnsSettings.information.hidden,
			attributes: {
				'class': 'tooltip ellipsis to_expand reason_text'
			}
		}];

		return Utils.rearrangeColumns(columns, columnsSettings);
	}

	getGridUserPrefs() {
		let sort = this.props.defaultSort || JSON.parse(UserPrefs.get('applicationDetailsSort'));
		let filter = JSON.parse(UserPrefs.get('applicationDetailsFilter'));
		let defaultFsView = UserPrefs.get('defaultFsView');
		let searchPhrase = UserPrefs.get('applicationDetailsSearchPhrase');
		let columns = JSON.parse(UserPrefs.get('applicationDetailsColumns'));
		return {
			sort: sort,
			filter: filter,
			defaultFsView: defaultFsView,
			searchPhrase: searchPhrase,
			columns: columns
		};
	}

	async initializeGrid() {
		const userPrefs = await UserPrefs.load(this.props.userPrefsKey);
		const filterUserPrefs = userPrefs.success ? userPrefs.data : [];

		let gridUserPrefs = this.getGridUserPrefs();

		let columnsSettings, userPrefSort, userPrefFilter;
		if (Utils.isGuid(gridUserPrefs.defaultFsView)) {
			let pref = JSON.parse(UserPrefs.get(gridUserPrefs.defaultFsView));
			if (pref) {
				columnsSettings = pref.columns;
				userPrefSort = pref.sort;
				userPrefFilter = pref.filter;
			}
		} else {
			columnsSettings = gridUserPrefs.columns;
		}

		if (userPrefSort) {
			gridUserPrefs.sort = userPrefSort;
		}
		if (userPrefFilter) {
			gridUserPrefs.filter = userPrefFilter;
		}

		columnsSettings = Utils.completeColumns(columnsSettings, this.defaultColumns);
		let columns = this.getReasonsGridColumns(columnsSettings);

		const [reasons, reasonsAreFiltered] = await this.props.loadReasonsCallback();

		this.userPref = filterUserPrefs;

		this.setState({
			gridLoaded: true,
			reasons,
			reasonsAreFiltered,
			columns,
			gridSort: gridUserPrefs.sort || [],
			gridFilter: gridUserPrefs.filter || {},
		});

	}
}


const filterable = {
	extra: false,
	operators: {
		string: {
			startswith: Lang.grid.filter.STARTS_WITH,
			neq: Lang.grid.filter.NEQ,
			eq: Lang.grid.filter.EQ,
			contains: Lang.grid.filter.CONTAINS
		}
	}
};

export default ReasonsGrid;
