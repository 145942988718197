import React from "react";
import {computed, makeObservable, observable} from "mobx";

export class Action{
	id: string
	title: string
	tooltip?: string

	callback?: (...args: any[]) => void

	icon?: React.ComponentType
	toggleable?: boolean;

	toggled?: boolean;
	toggledCallback: () => boolean;

	toolbarEntryRenderer?: () => React.ReactNode

	constructor(action?: Partial<Action>) {
		Object.assign(this, action);

		makeObservable(this, {
			toggled: observable,
			toggledWrapper: computed
		});
	}

	get toggledWrapper(){
		if(this.toggledCallback){
			return this.toggledCallback();
		}else{
			return this.toggled;
		}
	}
}
