import Utils from 'tools/utils'
import Cookies from 'core/cookies'
import Widget from 'areas/service-boards/widget'
import Settings from 'settings'
import CustomNotification from 'controls/customNotification'
import Highcharts from 'highcharts'
import State from 'tools/state'
import {SlaRouter} from 'areas/sla/bundleDescription';

import {translator} from 'core';
import {ServicesRouter} from "../../services/bundleDescription";
import {CssVariables} from "styles/cssVariables";

const i = translator({
  "SLA » {0}": {
    "no": "SLA » {0}"
  }
});

export function TimeWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	if (!this.accountName) {
		this.accountName = this.configuration.accountName || Cookies.CeesoftCurrentAccountName;
	}
	this.initComponent();
};

export {TimeWidget as default};

jQuery.extend(TimeWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.widgetContentDiv = $('#' + this.id);
		this.serviceNameLoaded = false;
		this.slaNameLoaded = false;
		this.isDetailsView = this.isDetailsView || false;
		this.widgetContentDiv.off();
		if (this.renderTo) {
			this.getData();
		} else {
			this.getServiceName();
			this.getSlaName();
		}
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: this.widgetContentDiv,
		});
		setTimeout(this.adjustSectionHeight, 200, this.id);
	},
	/**
	 * Retrieves data from the server
	 */
	getData: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/slas/' + this.configuration.slaId + '/calculation';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.slaIndicator = result.data.indicator;
				this.historyStartDate = result.data.start;
				this.historyEndDate = result.data.end;
				this.dataSet = result.data;
				this.render(this.dataSet);
			} else {
				kendo.ui.progress($('#cw_time_to_breach_' + this.id).parent(), false);
				this.showErrorMessage(result.message, $('#cw_time_to_breach_' + this.id).parent());
				this.showErrorMessage(lang.messages.NO_DATA_AVAILABLE, $('#cw_service_history_area'));
			}
		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(this.widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},

	attachListeners: function () {
		if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			this.widgetContentDiv.on('click', '#cw_service_name', $.proxy(this.onServiceNameClick, this));
			this.widgetContentDiv.on('click', '.sla-name', $.proxy(this.onSlaNameClick, this));
		}
	},

	onServiceNameClick: function (e) {
		this.navigator.go({
			url:ServicesRouter.details(this.configuration.serviceId)
		});
	},

	onSlaNameClick: function (e) {
		this.navigator.go({
			url: SlaRouter.details(this.configuration.slaId)
		});
	},
	/**
	 * Renders the gauge
	 * @param {Object} data The chart's data
	 */
	render: function (slaObj) {
		var html = this.createWidgetContent();
		this.attachListeners();
		this.widgetContentDiv.find('.cw_section_content').empty().append(html);
		var colors = [CssVariables.severityCritical, CssVariables.severityNone];
		var maxAllowedContainer = $('.cw_max_allowed', this.widgetContentDiv);
		var currentDowntimeContainer = $('.cw_current_downtime', this.widgetContentDiv);
		var remainingDowntimeContainer = $('.cw_remaining_downtime', this.widgetContentDiv);
		var totalPeriod = Math.floor(slaObj.totalTime / slaObj.periodCompletion * 100);
		var maxDowntime = Math.floor((100 - slaObj.threshold) * totalPeriod / 100);
		var remainingDowntime = Math.floor(maxDowntime - slaObj.downTime);
		var percentDowntime, percentRemaining;
		if (parseInt(slaObj.downTime) > 0 && remainingDowntime <= 0) {
			percentDowntime = 100.00;
			percentRemaining = 0.00;
		} else {
			percentDowntime = maxDowntime ? parseFloat((slaObj.downTime / maxDowntime * 100).toFixed(2)) : 0.00;
			percentRemaining = maxDowntime ? parseFloat((100.00 - percentDowntime).toFixed(2)) : 100.00;
		}
		maxAllowedContainer.find('.label').html(lang.slas.MAX_DOWNTIME);
		var formattedMaxDowntime = Utils.formatDowntime(maxDowntime, true);
		maxAllowedContainer.find('.value').html(formattedMaxDowntime.text).attr('title', formattedMaxDowntime.hoverText);
		if (parseInt(slaObj.downTime) === 0 && remainingDowntime === 0) {
			currentDowntimeContainer.find('.value')
				.text(lang.slas.NO_DOWNTIME)
				.attr('title', lang.slas.NO_DOWNTIME);
			remainingDowntimeContainer
				.find('.value')
				.text(lang.slas.NO_DOWNTIME)
				.attr('title', lang.slas.NO_DOWNTIME);

			currentDowntimeContainer.addClass('no-downtime');
		} else {
			var formattedRemainingDowntime = Utils.formatDowntime(remainingDowntime, false);
			var remainingDowntimeText = formattedRemainingDowntime.text;
			var formattedDowntime = Utils.formatDowntime(slaObj.downTime, true);
			currentDowntimeContainer.find('.value').text(formattedDowntime.text).attr('title', formattedDowntime.hoverText);
			remainingDowntimeContainer.find('.value').text(remainingDowntimeText).attr('title', formattedRemainingDowntime.hoverText);
			if (remainingDowntimeText === lang.BREACHED) {
				remainingDowntimeContainer.find('.label').html('');
				remainingDowntimeContainer.find('.value').addClass('breached');
			}
			if(formattedDowntime.text == lang.slas.NO_DOWNTIME){
				currentDowntimeContainer.addClass('no-downtime');
			}
		}

		var chart = new Highcharts.Chart({
			chart: {
				renderTo: 'cw_time_to_breach_' + this.id,
				type: 'pie',
				spacingBottom: 0,
				spacingTop: 10,
				spacingLeft: 10,
				spacingRight: 10,
				marginTop: -60,
				marginBottom: -30,
				marginLeft: -40,
				marginRight: -40,
				backgroundColor: 'transparent'
			},
			title: {
				text: null
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			colors: colors,
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false
					},
					innerSize: '60%',
					startAngle: -90,
					endAngle: 90,
					center: ['50%', '85%']
				}

			},
			tooltip: {
				crosshairs: false,
				backgroundColor: 'white',
				borderWidth: 0,
				shadow:{
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				formatter: function () {
					var label = lang.PERCENTAGE + ': ' + this.y + '%' + '<br/>';
					return label;
				}
			},
			series: [{
				data: [['Downtime', percentDowntime], ['Remaining', percentRemaining]]
			}],
			exporting: {
				enabled: false
			}
		});
		kendo.ui.progress($('#cw_time_to_breach_' + this.id).parent(), false);
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function () {
		if (this.serviceNameLoaded && this.slaNameLoaded) {
			if (!this.title) {
				this.setTitleIfEmpty(i('SLA » {0}', this.slaName));
			}
			this.getData();
		}
	},
	/**
	 * Retrieves the service name from the server and updates the title
	 */
	getServiceName: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/name';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.serviceName = result.data;
				this.serviceNameLoaded = true;
				this.updateTitle();
			} else {
				this.showErrorMessage(result.message);
			}
		}, this));
	},
	/**
	 * Gets from the server the SLA name
	 */
	getSlaName: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/slas/' + this.configuration.slaId + '/name';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.slaName = result.data;
				this.slaNameLoaded = true;
				this.updateTitle();
			} else {
				this.showErrorMessage(result.message);
			}
		}, this));
	},
	/**
	 * Function for creating the header in the root cause view
	 */
	createWidgetContent: function () {
		var html = '';
		html += '<div id="cw_time_to_breach_' + this.id + '" class="cw_time_to_breach"></div>';
		html += '<div class="cw_time_to_breach_values"><div id="cw_legend_' + this.id + '"><span><strong>' + lang.widget.SLA + '</strong>: <span class="sla-name cw_link">' + this.slaName + '</span></span><span><strong>  ' + lang.SERVICE + '</strong>: <span id="cw_service_name" class="cw_link">' + this.serviceName + '</span></span></div><div class="cw_current_downtime"><div class="value"></div><div class="label">' + lang.slas.DOWNTIME + '</div></div>';
		html += '<div class="cw_remaining_downtime"><div class="value"></div><div class="label">' + lang.slas.DOWN_BEFORE_BREACHED + '</div></div>';
		html += '<div class="cw_max_allowed"><div class="value"></div><div class="label"></div></div></div>';
		return html;
	},

/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		this.render(this.dataSet);
		// State.currentApp.saveServiceBoard();
	},
	adjustSectionHeight: function (id) {
		var section = $('#' + id);
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 30);
	},
});
