import React from "react";

import {ServiceModelImportingActions}
	from "controls/designer/features/serviceModelImport";
import {WidgetsActions} from "controls/designer/features/widgets/widgetsActions";
import {DesignerActions} from "controls/designer/shared";


const i = require('core/localization').translator({
  "Service model": {
    "no": "Tjenestemodell",
    "en": "Servicemodel"
  },
  "Window": {
    "no": "Vindu"
  }
});


export default class PresentationDesignerExtension {
	model = null;

	attach(designer) {
		const ui = designer.ui;
		this.ui = ui
		this.editor = this.ui.editor;
		this.graph = this.editor.graph;
	}

	editorLoaded(){
		this.setupRightClickMenu();
		const propertiesMenuItem = document.querySelector('.geToolbar .geButton .cogwheel')?.parentNode;
		propertiesMenuItem?.parentNode.appendChild(propertiesMenuItem);
	}

	setModel(model) {
		this.model = model;
	}

	setupRightClickMenu() {
		var original = this.ui.menus.createPopupMenu;
		this.ui.menus.createPopupMenu = function (menu, cell, evt) {
			menu.smartSeparators = true;
			const graph = this.editorUi.editor.graph;
			const designer = graph.designer

			const selectedCell = graph.getSelectionCell();


			if (selectedCell == null){
				this.addMenuItems(menu, ['undo', '-', 'paste']);
				this.addMenuItems(menu, ['-' + i('Select'), DesignerActions.SelectConnectors,
					DesignerActions.SelectShapes, DesignerActions.SelectAll]);
				this.addMenuItems(menu, ['-' + i('Add'), DesignerActions.InsertImage,
					ServiceModelImportingActions.ShowImportDialog]);
				this.addMenuItems(menu, ['-'])
				this.addMenuItems(menu, ['-' + i('Window'), "outline", "formatPanelShow", 'formatPanel', 'shapes-collection']);
			}else{
				this.addMenuItems(menu, ['undo','-', 'delete', 'cut', 'copy']);

				if(graph.getSelectionCount() == 1) {
					this.addMenuItems(menu,
						designer.store.widgetsManager.get(selectedCell) != null
							? [WidgetsActions.ShowEditWidgetFormForSelectedCell]
							: ['edit']);
				}

				this.addMenuItems(menu, ['-', 'toFront', 'toBack']);

				if (graph.getSelectionCount() == 1 &&!graph.getModel().isEdge(cell) && !graph.isSwimlane(cell) &&
					graph.getModel().getChildCount(cell) > 0) {
					this.addMenuItems(menu, ['-', 'ungroup'], null, evt);
				}

				if (graph.getSelectionCount() > 1) {
					 this.addMenuItems(menu, ['-', 'group'], null, evt);
				}

				if(!designer.store.widgetsManager.get(selectedCell))
				{
					this.addMenuItems(menu, ["user-shapes-add"], null, evt);
				}

				this.addMenuItems(menu, ['-', 'formatPanelShow'], null, evt);
			}
		}
	}

	destroy(){
		this.selectServiceWindow?.destroy();
	}
}
