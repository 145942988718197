import {BudgetSplit, budgetValuesKeys} from "areas/cost/budget/budgetTypes";
import React from "react";
import {ColumnType, Key, SortOrder} from "antd/lib/table/interface";
import {ColumnInput} from "./EditableCell";
import {CostBudgetItem} from "../../models/costBudgetItem";
import {Month, MonthEnum, months} from "areas/cost/budget/month";
import {ApplicationState} from "../../../../framework/applicationState";
import {isOneOf} from "../../../../tools/utils";
import {capitalize, uniq} from "lodash";
import moment, {Moment} from "moment";
import {
	ActionCell,
	MetricActionCell,
	NameCell,
	NumberCell,
	StatusCell,
	SimpleCell,
	RateCell,
} from "./BudgetTableCells";
import {BudgetTableContext} from "./BudgetTableContext";
import {CostTableViewType} from "./CostTableViewType";
import {ExpandCell} from "./ExpandCell";
import {ShowColumns} from "./BudgetTableStore";
import {SortDirection} from "../../models/sortDirection";
import classnames from "classnames";
import {BudgetTableSorter} from "./BudgetTable";
import {costTranslates} from "../../translations";
import {translator} from "../../../../core";
import {getValueOnPath} from "tools/helpers/math";
import {CostType} from "areas/cost/budget/budgetTypes";

const b = require('b_').with('budget-table');

const i = translator(costTranslates, {
	'Average value': {no: 'Gjennomsnittsverdi'}
});

const iMonth = (month: Month) => {
	const date = new Date(2000, MonthEnum[month] - 1, 1);
	return date.toLocaleString(ApplicationState.locale, {month: 'short'});
}

interface EditableColumnType<RecordType> extends ColumnType<RecordType> {
	editable?: boolean | ((x: RecordType) => boolean),
	input?: ColumnInput,
	titleOverride?: string,
	cellTitle?: string,
	minWidth?: number,
	setValue?: (record: RecordType, value: any) => void
}

export type BudgetEditableColumnType = EditableColumnType<CostBudgetItem>;

const renderNumberColumnFactory = (dataIndex: string, renderer: 'number' | 'rate' = 'number', ratePeriod: 'year' | 'month' = 'month') => {
	switch (renderer) {
		case 'number':
			return (text: string, record: CostBudgetItem) => {
				if(record.costTargetType == CostType.METRIC && dataIndex.includes('total')) {
					return <NumberCell text={() => getValueOnPath(record, dataIndex)} title={i('Average value')}/>
				}
				return <NumberCell text={() => getValueOnPath(record, dataIndex)}/>
			}
		case 'rate':
			return (text: string, record: CostBudgetItem) => <RateCell value={() => getValueOnPath(record, dataIndex)} period={ratePeriod} />
	}
}

const actionColumn = (viewType: CostTableViewType, compact: boolean) : BudgetEditableColumnType => {
	let actionColumnRender = viewType == CostTableViewType.Default ?
		(text: string, record: CostBudgetItem) => <ActionCell record={record}/> :
		(text: string, record: CostBudgetItem) => <MetricActionCell record={record}/>;

	return {
		title: i('Action'),
		key: 'action',
		fixed: 'right',
		width: compact ? 70 : 140,
		className: b('cell', {column: 'action'}),
		render: actionColumnRender
	}
}

const minWidths = ({compact}: {compact: boolean}) => {
	if(compact) {
		return {
			'budget': 78,
			'cost': 78,
			'costRate': 74,
			'listingPrice': 78,
		}
	} else {
		return {
			'budget': 120,
			'cost': 100,
			'costRate': 74,
			'listingPrice': 160,
		}
	}
}

function addEstimateColumns(showColumns: ShowColumns, sortOrder: BudgetTableSorter, compact: boolean, mainColumns: BudgetEditableColumnType[], monthColumns: BudgetEditableColumnType[], showTitleForValueColumns: boolean) {
	const {estimate, yearEstimate, currentMonthEstimate, costRate} = showColumns;

	if(!isOneOf(true, [estimate, yearEstimate, currentMonthEstimate])) {
		return [mainColumns, monthColumns];
	}

	let nameColIndex = mainColumns.findIndex(x => x.key == 'total');
	nameColIndex = nameColIndex == -1 ? 1 : nameColIndex;

	const estimateColumns = getEstimateColumns(sortOrder, compact, showTitleForValueColumns);

	if (yearEstimate || estimate) {
		mainColumns.splice(nameColIndex, 0, estimateColumns.year);
		if(costRate) {
			mainColumns.splice(nameColIndex+1, 0, estimateColumns.yearRate);
		}
	}

	if (currentMonthEstimate || estimate) {
		monthColumns = [estimateColumns.month, ...monthColumns];
		if(costRate) {
			monthColumns.splice(1, 0, estimateColumns.monthRate);
		}
	}

	return [mainColumns, monthColumns];
}

export const columns = (context: React.ContextType<typeof BudgetTableContext>) => {
	const {
		startMonthIndex,
		lockStructure,
		lockValues,
		displayDecimals,
		displayAsThousands,
		monthOrder,
		currentMonthFirst,
		viewType,
		sortOrder,
		showColumns,
		compact
	} = context;

	const showTitleForValueColumns = displayAsThousands
	const startDate = moment(context.budget.startDate); // clone
	let monthColumns = monthsColumns(startMonthIndex, monthOrder, currentMonthFirst, startDate, showTitleForValueColumns);
	let mainColumns = [...getMainColumns({lockStructure, sortOrder, showColumns, compact, showTitleForValueColumns})];

	[mainColumns, monthColumns] = addEstimateColumns(showColumns, sortOrder, compact, mainColumns, monthColumns, showTitleForValueColumns);

	let columns = [...mainColumns, ...monthColumns] as BudgetEditableColumnType[];

	showColumns.action && columns.push(actionColumn(viewType, compact));

	const rules = duplicateRules(showColumns);
	columns = duplicateColumns(columns, ['total', ...months], rules, sortOrder, minWidths({compact}));

	if (lockValues) {
		columns.forEach(x => x.editable = false);
	} else {
		columns.filter(c => months.some(m => c.key.toString().indexOf(m) != -1)).forEach(c => {
			c.setValue = (record, value) => {
				// HACK
				record[c.dataIndex[0]].setMonth(c.dataIndex[1], value, {displayDecimals});
			}
		})

		columns.filter(c =>  c.key.toString().indexOf('total') != -1).forEach(c => {
			c.setValue = (record, value) => {
				record[c.dataIndex[0]].setTotal(value, {displayDecimals,  reset: false, startMonth: months[startMonthIndex]});
			}
		})

		// TODO move to column definition
		columns.filter(c =>  c.key.toString().indexOf('split') != -1).forEach(c => {
			c.setValue = (record, value) => {
				record.setSplit(value, {displayDecimals, startMonth: months[startMonthIndex]});
			}
		})
		columns = columns.map(mapEditableColumns);
	}

	return columns;
}

interface DuplicateRule {
	postfix: string,
	path: string[],
	renderer: 'number' | 'rate'
}

const customizations = {
	costRate: (column: BudgetEditableColumnType, minWidths: {[x: string]: number}) => {
		if (column.key == 'total') {
			return
		}
		column.titleOverride = i('Percentage change from previous month')
		column.showSorterTooltip = false
	},
	listingPrice: (column: BudgetEditableColumnType, minWidths: {[x: string]: number}) => {
		column.cellTitle = i('Read only, Listing price is only received from Cost store link')
		column.editable = false // listing price is always readonly for now
		column.minWidth = minWidths['listingPrice'] // here we override 'total' column only
	}
}

const duplicateColumns = (columns: BudgetEditableColumnType[], keys: Key[], rules: DuplicateRule[], sortOrder: {columnKey: string, order: SortOrder}, minWidths: {[x: string]: number}) => {
	return columns.reduce((result, initialColumn) => {
		if (keys.indexOf(initialColumn.key) != -1) {
			rules.forEach((rule, index) => {
				const column = {...initialColumn};
				const lastRule = index == (rules.length-1);
				const stringPath = [].concat(rule.path).join('.');
				const classNames = uniq([...(column.className?.split(' ') ?? []), ...b('cell', {column: rule.path.join('-')}).split(' ')].filter(x => x));
				if (lastRule) {
					classNames.push('accent-border');
				}
				customizations[rule.path[0] as keyof typeof customizations]?.(column, minWidths);
				result.push({
					...column,
					minWidth: column.minWidth || minWidths[rule.path[0]],
					title: <span title={column.titleOverride ? column.titleOverride : `${column.title} ${rule.postfix}`}>{`${column.title} ${rule.postfix}`}</span>,
					dataIndex: rule.path.concat(column.dataIndex as string[] | string),
					key: `${rule.path.join('_')}_${column.dataIndex}`,
					className: classnames(...classNames),
					editable: column.editable && ((record) => getValueOnPath(record, stringPath)?.editable || false),
					// HACK, because duplicateColumns used only for numeric cells
					render: renderNumberColumnFactory(`${stringPath}.${column.dataIndex}`, rule.renderer),
					setValue: (record: CostBudgetItem, value: any) => {
						const yearValues = getValueOnPath(record, stringPath);
						yearValues.set(column.dataIndex, value);
					},
					sorter: sorterForByField(`${stringPath}.${column.dataIndex}`),
					sortOrder: sortOrder?.columnKey === `${rule.path.join('_')}_${column.dataIndex}` ? sortOrder?.order : undefined
				});
			});
		} else {
			result.push(initialColumn);
		}
		return result;
	}, [] as BudgetEditableColumnType[])
}

const duplicateRules = (showColumns: ShowColumns): DuplicateRule[] => {
	const keys = budgetValuesKeys.filter(x => showColumns[x]);
	return keys.reduce((out, prefix) => {
		const renderer = prefix == 'costRate' ? 'rate' : 'number';
		out.push({postfix: i(prefix).toLowerCase(), path: [prefix], renderer});
		return out;
	}, []);
}

const monthsColumns = (startMonthIndex: number, order: SortDirection, currentMonthFirst: boolean, startDate: Moment, showTitle: boolean): BudgetEditableColumnType[] => {
	const baseMonthsColumns = months.map((m) => {
		return {
			title: capitalize(iMonth(m)),
			dataIndex: m,
			key: m,
			align: 'right',
			input: {type: 'number'},
			ellipsis: showTitle,
			editable: true
		}
	})
	const columns = [
		...baseMonthsColumns.slice(startMonthIndex),
		...baseMonthsColumns.slice(0, startMonthIndex)
	] as BudgetEditableColumnType[];

	if (order === SortDirection.Desc) {
		columns.reverse();
	}

	if (currentMonthFirst) {
		const currentDate = moment();
		const currentMonthIsPresented = currentDate.isBetween(startDate, startDate.clone().add(1, 'y'));
		if (currentMonthIsPresented) {
			const currentMonthColIndex = columns.findIndex(x => x.key === months[currentDate.month()]);
			const currentMonth = columns[currentMonthColIndex];
			columns.splice(currentMonthColIndex, 1);
			return [currentMonth, ...columns];
		}
	}

	return columns;
}

const mapEditableColumns = (col: BudgetEditableColumnType): BudgetEditableColumnType => {
	if (!col.editable) {
		return col;
	}

	return {
		...col,
		onCell: (record: CostBudgetItem) => ({
			record: record,
			editable: col.editable instanceof Function ? col.editable(record) : col.editable,
			dataIndex: col.dataIndex,
			title: col.title,
			input: col.input,
			setValue: col.setValue
		}),
	};
};

const getMainColumns = ({
	lockStructure,
	sortOrder,
	showColumns,
	compact,
	showTitleForValueColumns
}: {
	lockStructure: boolean,
	sortOrder: any,
	showColumns: ShowColumns,
	compact: boolean,
	showTitleForValueColumns: boolean
}): BudgetEditableColumnType[] => {
	return ([{
		title: '',
		dataIndex: null,
		key: 'expand',
		className: b('cell', {column: 'expand'}),
		width: compact ? 40 : 50,
		fixed: 'left',
		render: (text, record) => {
			return <ExpandCell record={record}/>
		}
	}, showColumns.name && {
		title: i('Name'),
		dataIndex: 'name',
		key: 'name',
		className: b('cell', {column: 'name'}),
		editable: (record) => !lockStructure && record.id != 'total' && !record.isCostLink && !record.inLink,
		align: 'left',
		fixed: 'left',
		ellipsis: true,
		width: compact ? 170 : 250,
		input: {
			type: 'text'
		},
		render: (text, record) => {
			return <NameCell record={record}/>
		},
		sorter: sorterForByField('name'),
		sortOrder: sortOrder?.columnKey === 'name' && sortOrder?.order,
		setValue: (record, value) => {
			value = value.trim();
			if (value) {
				record.name = value;
			}
		}
	}, showColumns.status && {
		title: 'Status',
		dataIndex: 'statusSortString', // for custom sorting
		key: 'status',
		className: b('cell', {column: 'status'}),
		width: compact ? 65 : 95,
		sorter: () => 0, // we don't use ant's sorting
		sortOrder: sortOrder?.columnKey === 'status' && sortOrder?.order,
		render: (text, record) => {
			return <StatusCell record={record}/>
		},
		ellipsis: {showTitle: false}
	}, showColumns.information && {
		title: i('Information'),
		dataIndex: 'informationString', // we don't have data for now
		key: 'information',
		className: b('cell', {column: 'information'}),
		editable: false,
		align: 'left',
		width: compact ? 150 : 220,
		sorter: () => 0,
		sortOrder: sortOrder?.columnKey == 'information' && sortOrder?.order,
		ellipsis: true
	}, showColumns.split && {
		title: i('Split'),
		dataIndex: 'split',
		key: 'split',
		className: b('cell', {column: 'split'}),
		editable: (record) => record.splitEnabled,
		align: 'center',
		width: compact ? 70 : 80,
		render: (text, record) => {
			return <SimpleCell content={() => (record.splitEnabled && record.split != BudgetSplit.NONE) ? record.split : null}/>
		},
		input: {
			type: 'enum',
			enum: BudgetSplit
		},
		sorter: sorterForByField('split'),
		sortOrder: sortOrder?.columnKey === 'split' && sortOrder?.order
	}, {
		title: i('Total year'),
		dataIndex: 'total',
		key: 'total',
		editable: true,
		ellipsis: showTitleForValueColumns,
		className: b('cell', {column: 'total'}),
		align: 'right',
		minWidth: compact ? 78 : 120,
		input: {type: 'number'},
		sorter: sorterForByField('total'),
		sortOrder: sortOrder?.columnKey === 'total' && sortOrder?.order
	}] as BudgetEditableColumnType[]).filter(x => x);
}

const getEstimateColumns = (sortOrder: any, compact: boolean, showTitleForValueColumns: boolean) => {
	return {
		year: {
			title: i('Year Estimate'),
			dataIndex: 'periodEstimate',
			key: 'periodEstimate',
			className: classnames(b('cell', {column: 'year'}), b('cell', {column: 'estimate'})),
			align: 'right' as const,
			width: compact ? 80 : 100,
			render: renderNumberColumnFactory('periodEstimate'),
			sorter: sorterForByField('periodEstimate'),
			sortOrder: sortOrder?.columnKey === 'periodEstimate' && sortOrder?.order,
			ellipsis: showTitleForValueColumns
		},
		month: {
			title: i('Current month estimate'),
			dataIndex: 'currentEstimate',
			key: 'currentEstimate',
			className: b('cell', {column: 'estimate'}),
			align: 'right' as const,
			width: compact ? 100 : 155,
			render: renderNumberColumnFactory('currentEstimate'),
			sorter: sorterForByField('currentEstimate'),
			sortOrder: sortOrder?.columnKey === 'currentEstimate' && sortOrder?.order,
			ellipsis: showTitleForValueColumns
		},
		yearRate: {
			title: <span title={i('Percentage change from previous period')}>{`${i('Year Estimate')} %`}</span>,
			dataIndex: 'periodEstimateRate',
			key: 'periodEstimateRate',
			className: classnames(b('cell', {column: 'year'}), b('cell', {column: 'estimate'}, b('cell', {column: 'rate'}))),
			align: 'right' as const,
			width: compact ? 80 : 110,
			render: renderNumberColumnFactory('periodEstimateRate', 'rate', 'year'),
			sorter: sorterForByField('periodEstimateRate'),
			sortOrder: sortOrder?.columnKey === 'periodEstimateRate' && sortOrder?.order,
			ellipsis: showTitleForValueColumns
		},
		monthRate: {
			title: <span title={i('Percentage change from previous period')}>{`${i('Current month estimate')} %`}</span>,
			dataIndex: 'currentEstimateRate',
			key: 'currentEstimateRate',
			className: classnames(b('cell', {column: 'estimate'}), b('cell', {column: 'rate'})),
			align: 'right' as const,
			width: compact ? 100 : 165,
			render: renderNumberColumnFactory('currentEstimateRate', 'rate'),
			sorter: sorterForByField('currentEstimateRate'),
			sortOrder: sortOrder?.columnKey === 'currentEstimateRate' && sortOrder?.order,
			ellipsis: showTitleForValueColumns
		}
	}
};

const sorterForByField = (path: string) => (a: CostBudgetItem, b: CostBudgetItem) => {
	if (a.id == 'total' || b.id == 'total') {
		return 0;
	}
	const av = getValueOnPath(a, path) || 0;
	const bv = getValueOnPath(b, path) || 0;
	return av == bv
		? 0
		: av < bv ? -1 : 1;
}
