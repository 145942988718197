import {Api, Utils} from 'tools';
import Settings from 'settings';
import {
	timePeriodToUrl,
	timePeriodUrlParams
} from "../../../../controls/react/form/timePeriodSelector";

export default async function getChartData({
	accountId,
 	ignoreMissingData,
	timezone,
	period,
	width,
	useRawData,
	metricsItems}) {

	const {interval} = Utils.getPeriodInterval({
		...period,
		width: width,
	});
	const queryParams = {
		ignoreMissingData,
		timeZone: timezone,
		interval: interval,
		...timePeriodUrlParams(period)
	};

	if (useRawData) {
		delete queryParams.interval;
	}

	const metrics = metricsItems || [];

	const urlBase = `${Settings.serverPath}accounts/${accountId}/metrics/registeredMetrics/data`;

	const url = Api.compileUrlWithParams(urlBase, queryParams);

	const {result} = await Utils.ajaxPromise(url, 'POST', JSON.stringify(metrics));

	return result;
}

