export async function enableFeatures(designer){
	const features = designer.config.features ?? {};

	if(features.htmlContainerShape || designer.config.features?.widgets){
		await enableFeature(designer, () => import ('./htmlContainerShape'))
	}

	if(features.serviceModelImporting) {
		await enableFeature(designer, () => import ('./serviceModelImport'))
	}

	//if(features.widgets) {
		await enableFeature(designer, () => import('./widgets/widgets'));
	//}

	if(features.shapesCollection) {
		await enableFeature(designer, () => import('./shapesCollection/shapesCollection'));
	}

	if(features.presentationMode){
		await enableFeature(designer, () => import('./presentationMode'));
	}

	if(features.previewPaddings){
		await enableFeature(designer, () => import('./previewPaddings'));
	}

	if(features.moveToFrontOnExpand) {
		await enableFeature(designer, () => import('./moveToFrontOnExpand'));
	}

	if(features.scrollToBottomIfExpanded) {
		await enableFeature(designer, () => import('./scrollToBottomIfExpanded'));
	}

	if(features.redirectOnClick) {
		await enableFeature(designer, () => import('./redirectOnClick/redirectOnClick'));
	}

	if(features.adaptiveLayout){
		await enableFeature(designer, () => import('./adaptiveLayout/adaptiveLayout'))
	}

	if(features.snapToGrid){
		await enableFeature(designer, () => import('./snapToGrid'))
	}

	if(features.preview){
		await enableFeature(designer, () => import('./preview/preview'));
	}

	if(features.autoLayout){
		await enableFeature(designer, () => import('./autoLayout'));
	}

	if(features.resolutionSelector) {
		await enableFeature(designer, () => import('./resolutionSelector'))
	}

	//if(features.customDataWindow){
		await enableFeature(designer, () => import('controls/designer/features/widgets/custom-data-list/customDataList'))
	//}

	//if(features.dropTriggers){
		await enableFeature(designer, () => import('./dropTriggers'))
	//}
}

async function enableFeature(designer, importCallback){
	const module = await importCallback();
	module.enableFeature(designer);
}
