import {serialize} from "serializr";

import {apiFetch, ApiRequest, Constructor, PagedList} from "framework/api"
import {MonitorsListEntry} from "areas/assets/monitors/monitors-list/monitorsListEntry"
import {MonitorType} from "areas/assets/monitors/monitorType"
import {RuleDefinition, RulesConfiguration} from "controls/queryBuilder/ruleDefinition"
import {GridStore} from "controls/grid/gridStore"
import {ApiDocumentationSection} from "controls/react/apiDocumentation";
import {MonitorBase} from "areas/assets/monitors/shared-components/monitorBase";
import {ApplicationState} from "framework/applicationState";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {ErrorHandler} from "../../../core";
import Settings from "../../../settings";
import {AssetDropDown} from "../../../controls/react/dropdowns/assetDropDown";
import {GridDataItem} from "controls/grid/gridDataItem";
import {AzureMonitor} from "./azure/model/azureMonitor";


export type GetMonitorsPayload = {
	monitorType?: MonitorType
	agentId?: string
}

export type NamedItem = {
	id: string,
	name: string
}

export type Dependency = {
	accountId: string,
	accountName: string,
	description: string,
	id: string,
	name: string,
	parentId: string,
	dependencyType: string,
	type: string
}

export function getMonitors(payload?: GetMonitorsPayload){
	return new ApiRequest<PagedList<GridDataItem<MonitorsListEntry>>>({
		url: 'monitors',
		accountBased: true,
		subaccountsFilter: true,
		method: 'POST',
		payload
	});
}

type GetMonitorsFiltersArgs = {
	accountId?: string
}

export function getMonitorsFilters(args?: GetMonitorsFiltersArgs){
	return new ApiRequest<RulesConfiguration>({
		url: 'monitors/ruleConfiguration',
		accountBased: true,
		subaccountsFilter: true,
		method: 'GET'
	});
}

export function deleteMonitors(store: GridStore<any>){
	return store.getSelectionApiRequest<NamedItem[]>({
		url: 'monitors/delete',
	});
}


export function setInMaintenance(store: GridStore<any>, inMaintenance: boolean){
	return store.getSelectionApiRequest({
		url: 'monitors/setMaintenance/' + inMaintenance,
	});
}
export function resetMonitorIndex(store: GridStore<any>){
	return store.getSelectionApiRequest({
		url: 'monitors/resetAgentHealthIndexes',
		payload: {
			resetIndexes: {
				user: ApplicationState.userName,
				description: "Reset agent health indexes"
			}
		}
	});
}

export function setTags(store: GridStore<any>, tags: string[]){
	return store.getSelectionApiRequest({
		url: 'monitors/tags',
		payload: {
			tags
		}
	});
}

export function checkDependencies(monitorId: string) {
	return new ApiRequest<Dependency[]>({
		url: 'monitors/' + monitorId + '/dependencies',
		accountBased: true
	});
}

export function copyMonitor<TMonitor>(accountId: string, monitorId: string, responseType: Constructor<TMonitor>) {
	return new ApiRequest<TMonitor>({
		url: `accounts/${accountId}/monitors/${monitorId}/copy`,
		responseType: responseType
	});
}

export function getMonitorsWithTheSameName(name: string, type: MonitorType) {
	const group = RuleDefinition.fieldEqualRule('name', name)

	let filterObj = {
		monitorType: type,
		filter: serialize(group)
	}

	return new ApiRequest<PagedList<MonitorsListEntry>>({
		url: 'monitors/search/lite',
		accountBased: true,
		includeSubaccounts: false,
		method: 'POST',
		payload: filterObj
	})
}

export function getApiDocumentation(accountId: string = null){
	return new ApiRequest<ApiDocumentationSection[]>({
		url: 'monitors/apiHelp',
		accountId: accountId,
		accountBased: true,
		responseTypeArray: ApiDocumentationSection
	})
}

export type MonitorTypeResult = {
	type: MonitorType
}

export function getMonitorType(monitorId: string, accountId?: string){
	return new ApiRequest<MonitorTypeResult>({
		url: `monitors/${monitorId}/type`,
		accountBased: true,
		accountId: accountId
	})
}

export type LoadableMonitorStore<TMonitor extends MonitorBase<TMonitor>> = {
	id: string
	accountId: string
	sourceId: string
	monitor: TMonitor
	loaded: boolean
	name: string
}

export async function loadMonitor<TMonitor extends MonitorBase<TMonitor>>(
	monitorStore: LoadableMonitorStore<TMonitor>,
	monitorConstructor: Constructor<TMonitor>,
	getExistingMonitor: (id: string) => ApiRequest<TMonitor>,
	getNewMonitor: () => ApiRequest<TMonitor>) {

	const apiRequest = monitorStore.sourceId
		? copyMonitor<TMonitor>(monitorStore.accountId, monitorStore.sourceId, monitorConstructor)
		: monitorStore.id
			? getExistingMonitor(monitorStore.id)
			: getNewMonitor()

	let result = await apiFetch(apiRequest);

	if (result.success) {
		if (!monitorStore.id) {
			result.data.id = null
		}

		monitorStore.monitor = result.data;

		if (monitorStore.monitor.accountId == null) {
			monitorStore.monitor.accountId = ApplicationState.accountId;
		}

		monitorStore.monitor.enableValidation()

		if(monitorStore.sourceId){
			monitorStore.monitor.id = null
			monitorStore.monitor.name = monitorStore.name
		}
	}

	monitorStore.loaded = true
}

export const getAgentDataSource = (sharedAgents: boolean, accountId: string = ApplicationState.accountId) => {
	return new CeeViewDataSource({
		transport: {
			read: {
				url: Settings.serverPath + 'accounts/' + accountId + '/agents/lite?includeShared=' + sharedAgents + '&includeSubAgents=true',
				contentType: 'application/json; charset=utf-8',
				type: 'GET',
				dataType: 'json',
				cache: false
			}
		},
		schema: {
			data: function(response: {name: string, id: string, shared?: boolean}[]) {
				response.forEach(x => {
					if (x.shared) {
						x.name += ' (*)';
					}
				});
				return response;
			}
		},
		sort: {field: 'name', dir: 'asc'},
		error: ErrorHandler.kendoServerError
	});
}

export const getAssetDataSource = (sharedAgents: boolean = true, accountId: string = ApplicationState.accountId, assetType: string = 'AGENT') => {
	return AssetDropDown.getDataSource(accountId, false, assetType == 'AGENT', undefined, sharedAgents);
}
