import '../../details.less'

import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import React from 'react';
import {translator} from "core/localization";
import {BoxView} from "controls/react/layout/boxView";

import {AssetGroupsWindow, AssetsWindow} from './assetsWindow'
import {Actions} from "controls/designer/actionsManager/actions";
import {HeartOutlined, FolderOutlined} from "@ant-design/icons";

const i = translator({
  "Drag out an asset or asset group to get started designing your application": {
    "no": "Trekk ut en ressurs eller ressursgruppe for å komme igang med applikasjonsbygging",
    "en": "Drag out an asset or assetgroup to get started designing the application"
  },
  "Show/hide asset group window": {
    "no": "Vis/Skjul ressursgruppe vindu",
    "en": "Show/hide assetgroup window"
  },
  "Show/hide asset window": {
    "no": "Vis/Skjul ressurs vindu",
    "en": "Show/hide asset window"
  }
});

export default class ExtensionsManager{

	attach(designer) {
		this.designer = designer;
		const ui = designer.ui;

		this.ui = ui
		this.editor = this.ui.editor;
		this.graph = this.editor.graph;

		this.graph.getSelectionModel().addListener(mxEvent.CHANGE, this.selectionChanged);
	}

	editorLoaded(){
		if(!this.editor.chromeless) {
			this.addAssetsWindow();
			this.addAssetGroupsWindow();
			this.setUpRightClickMenu();
			if (Object.keys(this.graph.getModel().cells).length < 3) { //that means that xml is empty
				this.renderInfoMessage();
			}
		}
	}

	addAssetGroupsWindow(){
		this.assetGroupsRoot = document.createElement('div');
		document.querySelector('body').append(this.assetGroupsRoot);
		ReactDOM.render(<AssetGroupsWindow ref={r => this.assetGroupsWindow = r}
		                                   onItemSelected={this.onAssetGroupSelected}
		                                   hideInfoMessage={this.removeInfoMessage}
		                                   windowRef={this.onAssetsGroupWindowCreated}
		                                   designer={this.designer}/>, this.assetGroupsRoot);
	}

	onAssetsGroupWindowCreated = windowRef => {
		this.ui.designer.store.am.addToggleWindowAction({
			id: Actions.AssetsGroupsWindowToggle,
			title: i('Show/hide asset group window'),
			icon: FolderOutlined
		}, windowRef);

		this.ui.addWindowButtonToToolbar(
			"ad-asset-groups-window-toggle",
			i('Show/hide asset group window'),
			windowRef,
			() =>  $('<a href="javascript:void(0)" class="geButton geToolbar__asset-group"><i class="glyphicons folder-heart"></i></a>')[0]
		);
	}

	renderInfoMessage() {
		this.infoRoot = document.createElement('div');
		this.infoRoot.className='application-info-message';
		document.querySelector('body').append(this.infoRoot);
		ReactDOM.render(<BoxView type={"info-2"}
		                         rounded={true}
		                         border={true}>
							{i('Drag out an asset or asset group to get started designing your application')}
						</BoxView>, this.infoRoot);
	}

	onAssetGroupSelected = assetGroup => {
		this.assetsWindow.setAssetGroupFilter(assetGroup);
	}

	selectionChanged = () => {
		if (!this.assetsWindow)
			return;

		const cell = this.editor.graph.getSelectionCell();

		if (!cell)
			return;

		const datasource = this.designer.store.dataSourcesManager.get(cell)

		if (datasource?.assetGroups?.length > 0 && cell.getAttribute('generatedCell') != 'true') {
			this.assetsWindow.setAssetGroupFilter({
				id: datasource.assetGroups[0].id,
				name: this.ui.designer.store.dataSourcesManager.findDataSourceForCell(cell)?.element?.getLabel()
			})
		} else {
			this.assetsWindow.setAssetGroupFilter(null);
		}
	}

	removeAssetGroupsWindow(){
		if(this.assetGroupsRoot) {
			ReactDOM.unmountComponentAtNode(this.assetGroupsRoot);
		}
	}

	removeInfoMessage = () => {
		if(this.infoRoot) {
			ReactDOM.unmountComponentAtNode(this.infoRoot);
		}
	}

	addAssetsWindow(){
		this.assetsRoot = document.createElement('div');
		document.querySelector('body').append(this.assetsRoot);
		ReactDOM.render(<AssetsWindow ref={r => this.assetsWindow = r}
		                              hideInfoMessage={this.removeInfoMessage}
		                              windowRef={this.onAssetsWindowCreated}
		                              designer={this.designer}/>, this.assetsRoot);
	}

	onAssetsWindowCreated = windowRef => {
		this.ui.designer.store.am.addToggleWindowAction({
			id: Actions.AssetsWindowToggle,
			title: i('Show/hide asset window'),
			icon: HeartOutlined
		}, windowRef);

		this.ui.addWindowButtonToToolbar(
			"ad-assets-window-toggle",
			i('Show/hide asset window'),
			windowRef,
			() =>  $('<a href="javascript:void(0)" class="geButton geToolbar__asset"><i class="glyphicons heart"></i></a>')[0]
		);
	}

	removeAssetsWindow(){
		if(this.assetsRoot) {
			ReactDOM.unmountComponentAtNode(this.assetsRoot);
		}
	}

	setUpRightClickMenu() {
		var original = this.ui.menus.createPopupMenu;
		this.ui.menus.createPopupMenu = function (menu, cell, evt) {
			menu.smartSeparators = true;
			if (this.editorUi.editor.graph.isSelectionEmpty()){
				this.addMenuItems(menu, ["-" + i('Create'),"insert-asset", "insert-asset-group", "insert-image"]);
			}else{
				this.addMenuItems(menu, ['delete'], null, evt);
			}

			this.addMenuItems(menu, ['-', 'selectAll'], null, evt);

			this.addMenuItems(menu, ['-' + lang.designer.labels.WINDOWS,
				"ad-assets-window-toggle", "ad-asset-groups-window-toggle", "outline", "formatPanelShow", "formatPanel"]);
		}
	}

	destroy = () => {
		this.removeAssetGroupsWindow();
		this.removeAssetsWindow();
		this.removeInfoMessage();
	}
}
