import {newGuid} from "tools/guid";

export function getDescriptions(){
	return [
		{
			url: '/dashboards/new',
			getModuleImport: () => import('./designer'),
			params: {
				mode: 'designer'
			},
			routeId: 'board_new'
		},
		{
			url: '/dashboards/:id/designer',
			getModuleImport: () => import('./designer'),
			params: {
				mode: 'designer'
			},
			routeId: 'board_designer'
		},
		{
			url: '/dashboards/:id',
			getModuleImport: () => import('./designer'),
			params: {
				mode: 'viewer'
			},
			routeId: 'board_viewer'
		},
		// {
		// 	url: '/dashboards',
		// 	getModuleImport: () => import('./list')
		// }
	];
}

export class DashboardsRouter {
	static list(){
		return '#/dashboards';
	}

	static viewer(id) {
		sessionStorage.setItem('isCurrentHomeDashboard', false);
		return `/dashboards/${id}`;
	}

	static designer(id) {
		sessionStorage.setItem('isCurrentHomeDashboard', false);
		return `#/dashboards/${id}/designer`;
	}

	static create() {
		return `#/dashboards/new`;
	}

	static guestMode(id){
		return `#/dashboards/${id}?guestMode=1&standalone=1&sessionId=${newGuid()}`
	}

	static standalone(id, sessionId = null){
		return `/dashboards/${id}?standalone=1&sessionId=${sessionId ?? newGuid()}`
	}

	static getPropertiesUrl(id) {
		return Api.serverRoot() + 'dashboards/' + id + '/properties'
	}
}

// export class DashboardsRouter {
// 	static list(){
// 		return '/dasboards';
// 	}
//
// 	static details(id) {
// 		return `/dashboards/${id}`;
// 	}
//
// 	static create() {
// 		return `/dashboards/new`;
// 	}
// }
