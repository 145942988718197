import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import TaskManager from 'tools/taskManager';

import Highcharts from 'highcharts';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import State from 'tools/state';
import moment from 'moment';
import Configuration from 'configuration';
import Application from 'core/application';
import {getTzOffsetDiffs, zonedTimeToUtc} from "tools/dateTimeUtils";

import {translator} from 'core';
import {timePeriodToUrl} from "controls/react/form/timePeriodSelector";
import {ServicesRouter} from "areas/services/bundleDescription";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {CssVariables} from "styles/cssVariables";

const i = translator({
  "Service history service » {0}": {
	"no": "Tjenestehistory tjeneste » {0}",
	"en": "Service history service » {0}"
  }
});

export class HistoryWidget extends Widget{
	constructor(config) {
		super(config)

		this.taskManager = new TaskManager();
		this.firstLoad = true;
		this.DOWN_COLOR = CssVariables.severityCritical;
		this.UP_COLOR = CssVariables.severityNone;
		this.EXCLUDE_COLOR = CssVariables.severityInvalid;
		this.lastUpdate = new Date().getTime();
		this.lastState = '';
		this.requestPath = Settings.serverPath;
		if (this.sessionId) {
			this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
		}
		this.initComponent();
	}

	get timePeriodEffective(){
		return this.timePeriodZoomed ?? this.instanceConfiguration
	}

	initComponent() {
		var widgetDiv = $('#' + this.id);
		widgetDiv.off();
		widgetDiv.on('drop', $.proxy(this.onDrop, this));
		this.isViewer = this.isViewer || false;
		this.serviceNameLoaded = false;
		this.serviceElementNameLoaded = false;
		this.hasToggleTimeSelector = true;

		if (this.isDashboard) {
			this.isServiceboardHistoryWidget = true;
		}

		if (!this.customControls) {
			this.customControls = {
				target: '#' + this.id
			}
		}

		if (!this.noTitleNeeded) {
			if (!this.title) {
				if (this.instanceConfiguration.serviceElementId) {
					this.getServiceElementName();
				} else {
					this.serviceElementNameLoaded = true;
				}
				this.getServiceName();
			}
		}

		this.getData();

		if (this.instanceConfiguration.period !== 'CUSTOM') {
			this.subscribe(true);
		}

		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: widgetDiv,
		});
		this.historyEvents = [];

		this.timeSelectorTranslateObj = {
			'LASTHOUR': 'LASTDAY',
			'LASTDAY': 'LAST7DAYS',
			'LAST7DAYS': 'LAST30DAYS',
			'LAST30DAYS': null
		}
	}
	/**
	 * Retrieves the service name from the server and updates the title
	 */
	getServiceName() {
		var url = this.requestPath + 'services/' + this.instanceConfiguration.serviceId + '/name';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.serviceName = result.data;
				this.serviceNameLoaded = true;
				this.updateTitle();
			} else {
				this.showErrorMessage(result.message);
			}
		}, this));
	}
	/**
	 * Retrieves the service element name from the server and updates the title
	 */
	getServiceElementName() {
		var url = this.requestPath + 'services/' + this.instanceConfiguration.serviceId + '/elements/' + this.instanceConfiguration.serviceElementId + '/name';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.serviceElementName = result.data;
				this.serviceElementNameLoaded = true;
				this.updateTitle();
			} else {
				this.showErrorMessage(result.message);
			}
		}, this));
	}
	/**
	 * Updates the widget title
	 */
	updateTitle() {
		if (this.serviceNameLoaded && this.serviceElementNameLoaded) {
			this.setTitleIfEmpty(i('Service history service » {0}', this.serviceName));
		}
	}

	/**
	 * Renders the widget
	 */
	getData() {
		var url, fromTime, toTime, scope = this;
		if (!this.renderTo) {
			this.widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			this.widgetContentDiv = $('#' + this.renderTo);
		}

		var width = this.widgetContentDiv.width() - 150;
		// Quickfix by bogdan because in the viewer the widgetContentDiv.width() is zero.
		if (width <= 0) {
			width = 300;
		}

		// var period = Utils.getPeriodInterval({
		// 	period: this.zoomPeriod || this.instanceConfiguration.period,
		// 	startDate: this.zoomStartDate || this.instanceConfiguration.startDate,
		// 	endDate: this.zoomEndDate || this.instanceConfiguration.endDate,
		// 	width: width
		// });

		this.up = 0;
		this.down = 0;
		this.instanceConfiguration.slaId = this.instanceConfiguration.slaId ? this.instanceConfiguration.slaId : '';

		// if (this.zoomPeriod === 'CUSTOM' || this.instanceConfiguration.period === 'CUSTOM') {
		// 	fromTime = zonedTimeToUtc(new Date(this.zoomStartDate || this.instanceConfiguration.startDate), this.instanceConfiguration.timezone);
		// 	toTime = zonedTimeToUtc(new Date(this.zoomEndDate || this.instanceConfiguration.endDate), this.instanceConfiguration.timezone);
		// 	url = this.requestPath + 'services/' + this.instanceConfiguration.serviceId + '/statehistory?showExcludes=true&slaId=' + this.instanceConfiguration.slaId + '&timeZone=' + this.instanceConfiguration.timezone + '&interval=' + this.interval + '&fromTime=' + fromTime.getTime() + '&toTime=' + toTime.getTime() + '&showUptime=true';
		// } else {
		// 	url = this.requestPath + 'services/' + this.instanceConfiguration.serviceId + '/statehistory?showExcludes=true&slaId=' + this.instanceConfiguration.slaId + '&timeZone=' + this.instanceConfiguration.timezone + '&interval=' + this.interval + '&timeSelector=' + this.instanceConfiguration.period + '&showUptime=true';
		// }

		//this.urlWithoutViewerCalc = url;

		url = this.requestPath + 'services/' + this.instanceConfiguration.serviceId
			+ '/statehistory?showExcludes=true&slaId=' + this.instanceConfiguration.slaId
			+ '&showUptime=true&' + timePeriodToUrl(this.timePeriodEffective)
			+ '&maxSamples=' + width
			+ 'timezone=' + this.instanceConfiguration.timezone;

		//TODO
		// if (this.viewerFirstRender) {
		// 	this.viewerTimeSelector = 'LASTHOUR';
		// 	url = this.requestPath + 'services/' + this.instanceConfiguration.serviceId + '/statehistory?showExcludes=true&slaId=' + this.instanceConfiguration.slaId + '&timeZone=' + this.instanceConfiguration.timezone + '&interval=' + this.interval + '&timeSelector=LASTHOUR&showUptime=true';
		// }

		if (this.instanceConfiguration.serviceElementId && !this.instanceConfiguration.isRoot) {
			url += '&elementId=' + this.instanceConfiguration.serviceElementId;
			this.urlWithoutViewerCalc = url;
		}

		if (this.isDashboard) {
			this.windowTitlebar = $('#' + this.id).find('.toolbar').first();
		} else {
			this.windowTitlebar = $('#' + this.id).find('.cw_section_titlebar');
		}

		kendo.ui.progress(this.widgetContentDiv, true);

		this.fetchWidgetData(url);

	}

	fetchWidgetData(url) {
		this.createCustomControls(this.instanceConfiguration.slaMode);
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				let isErrorPresent = false;
				for (let entry of result.data) {
					if (entry.s === false) {
						isErrorPresent = true;
					}
				}

				//TODO
				if (false && this.viewerFirstRender && !isErrorPresent) {
					// let timeSplitUrl = url.split('timeSelector=');
					// let parametersSplitUrl = timeSplitUrl[1].split('&');
					// this.viewerTimeSelector = this.timeSelectorTranslateObj[this.viewerTimeSelector];
					// if (this.viewerTimeSelector) {
					// 	let translatedUrl = timeSplitUrl[0] + 'timeSelector=' + this.viewerTimeSelector + '&' + parametersSplitUrl[1];
					// 	this.fetchWidgetData(translatedUrl);
					// } else {
					// 	this.viewerFirstRender = false;
					// 	this.fetchWidgetData(this.urlWithoutViewerCalc);
					// }
				} else {
					//TODO
					// if (this.viewerTimeSelector) {
					// 	this.periodMultiToggle.setSelectedItem(this.viewerTimeSelector);
					// }
					//this.viewerFirstRender = false;
					//this.viewerTimeSelector = null;
					this.render(result.data);
					this.lastUpdate = new Date().getTime();
					if (result.data[0].stats) {
						const stats = result.data[0].stats;
						this.stats = stats;
						const downtime = (stats.down / (stats.down + stats.up)) * 100;
						const downtimeTimer = Renderer.timer(stats.down).timer;

						this.up = stats.up;
						this.down = stats.down;

						const header = `
							<div class="cw_history_counters">
								<span class="hide cw_service_breaches" title="${lang.SERVICE_BREACHES}"></span>
								<span class="hide cw_service_downtime" title="${lang.SERVICE_DOWNTIME}"></span>
							</div>`;

						const updateCounters = (selector, label, value) => {
							$(selector).removeClass('hide').html(`<strong>${label}</strong>: ${value}`);
						};

						if (this.isViewer) {
							$('.cw_history_counters').replaceWith(header);
							$('.cw_history_counters').addClass('in-viewer')
							updateCounters('.cw_service_breaches', lang.SERVICE_BREACHES, stats.breaches);
							updateCounters('.cw_service_downtime', lang.SERVICE_DOWNTIME, `${downtimeTimer} (${downtime.toFixed(2)}%)`);
						} else {
							this.target.find('.cw_history_counters').remove()
							this.target.append(header);
							const widget = $(`#${this.id}`);

							if (this.instanceConfiguration.excludeBreaches) {
									updateCounters(widget.find('.cw_service_breaches'), lang.SERVICE_BREACHES, stats.breaches);
							} else {
									widget.find('.cw_service_breaches').addClass('hide');
							}

							if (this.instanceConfiguration.excludeDowntime) {
									updateCounters(widget.find('.cw_service_downtime'), lang.SERVICE_DOWNTIME, `${downtimeTimer} (${downtime.toFixed(2)}%)`);
							} else {
									widget.find('.cw_service_downtime').addClass('hide');
							}
							this.resizeHeader();
						}
					}

					var lastData = result.data.slice(-1)[0];
					var beforeLastData = result.data.slice(-2)[0];
					var data = result.data;
					var lastStateChangeTime;
					var currentState = data[data.length - 1].s;
					for (var i = data.length - 2; i >= 0; i--) {
						if (currentState === data[i].s) {
							lastStateChangeTime = data[i].s0 || data[i].t0 || data[i].t;
						} else {
							break;
						}
					}

					this.visibleChangeTime = beforeLastData.t;
					if (this.firstLoad && this.stateChangeTime == null) {
						this.stateChangeTime = lastStateChangeTime;

						if (lastData.s) {
							this.lastState = 'UP';
						} else {
							this.lastState = 'DOWN';
						}
					}
					if (this.timePeriodEffective.period && this.dataSet && this.dataSet.length) {
						this.updateChangeDuration();
					}
					if (this.getServiceStatus && !this.zoomed) {
						this.getServiceStatus.call(this, result.data.slice(-1)[0]);
					}
					this.firstLoad = false;
				}
			} else {
				kendo.ui.progress(this.widgetContentDiv, false);
				this.showErrorMessage(result.message);
			}
		}, this), () => {
			// this.showErrorMessage(lang.serviceBoard.messages.NO_DATA);
		}, 60000, $.proxy(function () {
			kendo.ui.progress(this.widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	}

	getServiceStatus(result) {
		var state, widgetDiv = $('#' + this.id);
		if (result.s) {
			state = 'ACTIVE';
		} else {
			state = 'INACTIVE';
		}
		var titlebar = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : this.windowTitlebar;
		if (titlebar.find('.cw_service_indicator')) {
			titlebar.find('.cw_service_indicator').remove();
		}
		titlebar.prepend(Renderer.serviceDetailsState({state: state}, 'go_left'));
	}

	/**
	 * Creates the widget chart based on received data
	 * @param {Object} data
	 */
	render (inputData) {
		if(this.destroyed)
			return;

		if (this.firstLoad) {
			this.widgetContentDiv.empty().append('<div class="metric_chart"></div>');

			if (this.instanceConfiguration.showFooter) {
				this.widgetContentDiv.closest('.cw_widget').addClass('has_footer');
				this.widgetContentDiv.append(this.footer());
			} else {
				this.widgetContentDiv.closest('.cw_widget').removeClass('has_footer');
			}
		}

		this.widgetContentDiv.addClass('services-historyWidget');

		kendo.ui.progress(this.widgetContentDiv, false);
		var series = [], id, length;
		const timeZonDiff = getTzOffsetDiffs(moment.tz.guess(), this.instanceConfiguration.timezone)*60000;

		const data = inputData.map(x => {
			const newItem = JSON.parse(JSON.stringify(x));
			newItem.t = newItem.t - timeZonDiff;
			newItem.t0 = newItem.t0 - timeZonDiff;
			return newItem;
		});

		this.dataSet = data;
		this.times = [];
		this.descriptions = [];
		this.orderedTimes = [];
		this.errorSerie = {
			data: [],
			type: 'scatter',
			name: 'errors'
		};
		if (!data.length) {
			data.length = Object.keys(data).length;
		}
		if (data.length) {
			this.lastPoint = data[data.length - 1];
			id = Utils.guid();
			this.times[id] = this.lastPoint.t;
			this.descriptions[id] = this.lastPoint.d;
			this.orderedTimes.push(this.lastPoint.t);

			let lastProccesed = false;
			for (var i = data.length - 1; i > 0; i--) {
				id = Utils.guid();

				if (this.instanceConfiguration.slaMode) {
					const completionTime = this.instanceConfiguration.completionTime - timeZonDiff;
					if (data[i].t > completionTime && lastProccesed) {
						continue;
					} else if (data[i].t > completionTime) {
						data[i].t = completionTime;
						lastProccesed = true;
					}
				}


				const newLastItem = data[i].t;
				const newBeforeLastItem = data[i - 1].t;
				this.lastInterval = newLastItem - newBeforeLastItem;

				if (this.lastInterval < 0) {
					continue;
				}

				let color = '';
				if (data[i - 1].x) {
					if (data[i - 1].xt[0] === 'UP') {
						color = this.UP_COLOR;
					} else if (data[i - 1].xt[0] === 'DOWN') {
						color = this.DOWN_COLOR;
					} else {
						color = this.EXCLUDE_COLOR;
					}
				} else if (data[i - 1].s) {
					color = this.UP_COLOR;
				} else {
					color = this.DOWN_COLOR;
				}
				this.lastSerie = {
					id: id,
					name: id,
					color:  color,
					data: [this.lastInterval],
					status: data[i - 1].xt
				};
				series.push(this.lastSerie);
				if (data[i - 1].t0) {
					this.times[id] = data[i - 1].t0;
					this.orderedTimes.push(data[i - 1].t0);
				} else {
					this.times[id] = data[i - 1].t;
					this.orderedTimes.push(data[i - 1].t);
				}

				if (!data[i].s) {
					if (data[i + 1]) {
						this.errorSerie.data.push(parseInt((data[i].t + data[i + 1].t) / 2, 10));
					} else {
						this.errorSerie.data.push(parseInt((data[i].t + (new Date().getTime() - timeZonDiff)) / 2, 10));
					}
				}

				if (data[i - 1].d) {
					this.descriptions[id] = data[i - 1].d;
				}
			}
			var newFirstItem = data[0].t;
			series.push({
				name: 'firstSeries',
				color: 'rgba(255,255,0,0)',
				tooltip: false,
				data: [newFirstItem]
			});
			this.lastSerie = series[0];
			/*
			 * if (this.errorSerie.data.length){ series.push(this.errorSerie); }
			 */
			if (data[0].t0) {
				this.orderedTimes.push(data[0].t0);
			} else {
				this.orderedTimes.push(data[0].t);
			}
			this.orderedTimes.sort();
		}
		var oThis = this;
		var exporting = jQuery.extend(true, {}, Configuration.highcharts.exporting);
		// pass default configurations
		// Utils.apply(exporting, Configuration.highcharts.exporting);

		if (this.zoomed) {
			exporting.enabled = true;
			exporting.buttons.popUpBtn = {
				onclick: () => {
					if (this.instanceConfiguration.slaMode) {
						this.configuration.onReset();
					}

					if (this.onZoom) {
						this.onZoom(null)
					} else {
						this.zoomed = false
						this.timePeriodZoomed = null
						if (this.instanceConfiguration.period !== 'CUSTOM') {
							this.subscribe(true);
						}
						this.getData();
					}
				},
				align: 'left',
				width: 5,
				x: 2,
				y: 1,
				text: lang.RESET,
				theme: {
					'stroke-width': 1,
					stroke: '#aaa',
					fill: '#fff',
					r: 0,
					states: {
						hover: {
							fill: '#eee'
						},
						select: {
							fill: '#ccc'
						}
					}
				}
			};
		}

		let container = this.widgetContentDiv.find('.metric_chart');
		if (container.length === 0) {
			return;
		}
		let height;
		if (container.closest('.cw_section_content').length) {
			height = container.closest('.cw_section_content').height();
		} else {
			height = this.widgetContentDiv.height();
		}

		if (this.isDashboard && this.instanceConfiguration.showFooter) {
			container.closest('.section__content').css('overflow', 'hidden');
			height -= 30;
		}

		this.chart = new Highcharts.Chart({
			chart: {
				renderTo: container[0],
				height: height,
				type: 'bar',
				zoomType: 'y',
				backgroundColor: 'transparent',
				events: {
					load: (e) => {
						if (this.events && this.events.load) {
							this.events.load.call(this, e);
						}
					},
					selection: event => {
						event.preventDefault();
						if (event.yAxis) {
							const timePeriod = {
								period: TimePeriodType.Custom,
								startDate: parseInt(event.yAxis[0].min, 10),
								endDate: parseInt(event.yAxis[0].max, 10)
							}
							if (this.onZoom) {
								this.onZoom(timePeriod)
								//this.handleZoom(this.onZoom, this.zoomStartDate, this.zoomEndDate);
							}else{
								this.zoomed = true
								this.timePeriodZoomed = timePeriod
								this.getData();
							}
							this.unsubscribe();
						}
					}
				},
				resetZoomButton: {
					enable: false
				}
			},
			exporting: exporting,
			title: {
				text: ' '
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			xAxis: {
				categories: ['State'],
				labels: {
					enabled: false
				}
			},
			tooltip: {
				backgroundColor: 'white',
				borderWidth: 0,
				shadow:{
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				formatter (e) {
					var s = '';
					if (this.series.name !== 'firstSeries') {
						let time = oThis.times[this.series.name];
						let description = oThis.descriptions[this.series.name];
						let status = '';
						let index = Utils.getLastAppearance(oThis.orderedTimes, time);
						let duration;
						if (index > 0) {
							duration = oThis.orderedTimes[index + 1] - oThis.orderedTimes[index];
							if (index === oThis.orderedTimes.length - 2 && oThis.tooltopDuration) {
								duration = oThis.tooltopDuration;
							}
						}
						if (description) {
							if(oThis.instanceConfiguration.slaId)
								s += lang.service.PERIOD_IS_EXCLUDED;
							else
								s += lang.service.SERVICE_PERIOD_IS_EXCLUDED;
							s += '<br/>' + lang.service.PERIOD + ': ';
							if (duration) {
								s += Renderer.duration(duration);
							} else {
								s += 0;
							}
							s += '<br/>' + lang.DETAILS + ': ';
							s += '<br/>' + description;
						} else {
							if (this.series.userOptions.status) {
								status += this.series.userOptions.status.join('/');
							}

							s = Renderer.browserDateRenderer(time, 'datetime', '', oThis.configuration.timezone) + '<br />';
							s += lang.STATE + ': ' + (this.series.color === oThis.EXCLUDE_COLOR ? status : (this.series.color === oThis.UP_COLOR ? lang.UP : lang.DOWN));

							if (index > 0) {
								//var durationObj = Utils.getDuration(duration);
								s += '<br/>' + lang.service.PERIOD + ': ';
								if (duration) {
									s += Renderer.duration(duration);
								} else {
									s += 0;
								}
							}
						}
					}
					return s !== '' ? s : false;
				}
			},
			plotOptions: {
				series: {
					stacking: 'normal',
					shadow: false,
					cursor: 'pointer',
					events: {
						click: $.proxy(this.onChartPointClick, this)
					}
				},
				scatter: {
					color: '#FF0000',
					marker: {
						symbol: 'diamond'
					}
				},
				bar: {
					minPointLength: 2,
					borderWidth: 0,
					pointWidth: Math.round(this.widgetContentDiv.height() * 2 / 5) //110
				}
			},
			yAxis: {
				title: {
					text: null
				},
				type: 'datetime',
				min: (data.length ? data[0].t - (moment.tz.zone(this.instanceConfiguration.timezone).utcOffset(data[0].t) - new Date(data[0].t).getTimezoneOffset()) * 1000 : 0),
				labels: {
					staggerLines: 1
				},
				dateTimeLabelFormats: {
					millisecond: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'millisecond'),
					second: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'second'),
					minute: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'minute'),
					hour: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'hour'),
					day: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'day'),
					week: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'week'),
					month: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'month'),
					year: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'year')
				},
				minRange: 30000
			},
			series: series

		}, (chart) => {
			if (this.instanceConfiguration.slaMode) {
				setTimeout(() => {
					if (!this.zoomed) {
						this.hideReset(chart);
					}
					else {
						this.showReset(chart);
					}
				}, 100);
			}
		});

		if (this.instanceConfiguration.period !== 'CUSTOM' && this.dataSet.length) {
			this.taskManager.stopAll();
			this.refreshTask = {
				run: $.proxy(this.extendCurrentState, this),
				interval: 10000,
				useInterval: true
			};
			this.taskManager.start(this.refreshTask);
		}
	}
	hideReset(highchart) {
		const renderTo = highchart.renderTo;

		$('.highcharts-button', renderTo).hide();
	}

	showReset(highchart) {
		const renderTo = highchart.renderTo;
		$('.highcharts-button', renderTo).show();
	}

	processSeries (series, widgetContentDiv) {
		var length = series.length;
		var total = 0;
		for (var i = length - 2; i >= 0; i--) {
			total += series[i].data[0];
		}
		var timePerPixel = Math.round(total / (widgetContentDiv.width() - 120));
		for (var i = length - 2; i >= 0; i--) {
			if (series[i].data[0] < 5 * timePerPixel && series[i].color === this.DOWN_COLOR) {
				series[i].data[0] = 5 * timePerPixel;
			}
		}
		return series;
	}
	/**
	 * Handler function for the chart point click
	 * @param {object} e The click event
	 */
	onChartPointClick(e) {
		var name = e.currentTarget.name;
		if (!this.isViewer && name !== 'NODATA' && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			this.navigator.go({
				url: ServicesRouter.details(this.instanceConfiguration.serviceId)
			})
		}
	}

	extendCurrentState() {
		if(this.destroyed)
			return;

		//clicking multitoggle makes the lastSeries to be null
		var lastSerie;
		if (this.lastSerie) {
			lastSerie = this.chart.get(this.lastSerie.id);
		}
		if (lastSerie) {
			if (!this.zoomed) {
				lastSerie.setData([new Date().getTime() - this.visibleChangeTime], true);
			}
			this.updateChangeDuration();
		}

		if (!this.zoomed) {
			var currentTime = new Date().getTime();
			var interval = currentTime - this.lastUpdate;
			if (this.lastState === 'UP') {
				this.up += interval;
			} else {
				this.down += interval;
			}
			this.lastUpdate = currentTime;
			var percent = this.down / (this.down + this.up) * 100;
			var downtimeTimer = Renderer.timer(this.down).timer;
			if (this.isViewer) {
				$('.cw_service_downtime').removeClass('hide').html('<strong>' + lang.SERVICE_DOWNTIME + '</strong>: ' + downtimeTimer + ' (' + percent.toFixed(2) + '%)');
			} else {
				var widget = $('#' + this.id);
				if (this.instanceConfiguration.excludeDowntime) {
					$(widget).find('.cw_service_downtime').removeClass('hide').html('<strong>' + lang.SERVICE_DOWNTIME + '</strong>: ' + downtimeTimer + ' (' + percent.toFixed(2) + '%)');
				} else {
					$(widget).find('.cw_service_downtime').addClass('hide');
				}
				this.resizeHeader();
			}
		}
	}
	updateChangeDuration() {
		var duration, addClass, removeClass;

		duration = Math.max(new Date().getTime() - this.stateChangeTime, 1000);
		//duration = Math.max(new Date().getTime() - this.visibleChangeTime, 1000);

		if (this.widgetContentDiv.find('.cw_service_state_duration').length) {
			if (this.lastState === 'DOWN') {
				addClass = 'red';
				removeClass = 'green';
			} else {
				addClass = 'green';
				removeClass = 'red';
			}

			var selectedTimezone = this.instanceConfiguration.timezone;
			var durationRenderer = Renderer.timer(duration);
			this.widgetContentDiv.find('.cw_service_state_duration').find('.value').removeClass(removeClass).addClass(addClass).html(durationRenderer.timer);
			this.widgetContentDiv.find('.cw_service_state_time').find('.value').html(Renderer.browserDateRenderer(this.stateChangeTime, 'datetime', null, selectedTimezone));
		}

		if (this.zoomed) {
			var length = this.orderedTimes.length;
			if (length) {
				this.tooltopDuration = this.orderedTimes[length - 1] - this.visibleChangeTime;
			}
		} else {
			this.tooltopDuration = null;
		}
	}
	onEvent (events) {
		var data = {}, length = events.length;
		if (length) {
			data = events[length - 1];
		} else {
			data = events;
		}
		this.historyEvents.push(data);

		if (!this.historyWidgetIntervalStarted) {
			this.reloadHistoryWidget = setInterval($.proxy(function() {
				if (this.historyEvents.length) {
					var eventsLength = this.historyEvents.length;
					var lastEvent = this.historyEvents[eventsLength - 1];
					this.stateChangeTime = lastEvent.time;

					if (lastEvent.eventType === 'State') {
						this.lastState = lastEvent.state ? 'UP' : 'DOWN';
					}
					else {
						this.lastState = lastEvent.serviceState === "ACTIVE" ? 'UP' : 'DOWN';;
					}
					if (!this.zoomed) {
						if (this.customControls && typeof this.customControls.event === 'function') {
							this.customControls.event.call(this, lastEvent);
						}
						delete this.lastSerie;
						this.getData();
					} else {
						this.updateChangeDuration();
					}
					if (this.getServiceStatus) {
						this.getServiceStatus.call(this, {
							s: lastEvent.state
						});
					}

					this.configuration.lastChangeDuration = 0;
					this.configuration.stateChangeTime = lastEvent.time;
					this.historyEvents = [];
				} else {
					clearInterval(this.reloadHistoryWidget);
					this.historyWidgetIntervalStarted = false;
				}
			}, this), 10000)
		}
		this.historyWidgetIntervalStarted = true;
	}

	setTimePeriod(value){
		this.zoomed = null;
		this.timePeriodZoomed = null;
		this.instanceConfiguration.period = value;
		this.unsubscribe();
		this.subscribe(true);
	}

	onWidgetPeriodChanged(value) {
		this.zoomed = null;
		this.timePeriodZoomed = null;
		Object.assign(this.instanceConfiguration, value);
		this.unsubscribe();
		this.subscribe(false);
	}

	onResize() {
		setTimeout(()=>{
			const chartCont = this.widgetContentDiv.find('.metric_chart').closest('.cw_section_content');
			this.chart?.setSize(chartCont.width(), chartCont.height());
			this.createCustomControls(this.instanceConfiguration.slaMode, () => {
				this.resizeHeader();
			});
		},0)
	}
	/**
	 * Called when the the widget is dropped
	 */
	onDrop() {
		this.resizeHeader();
		this.render(this.dataSet);
	}
	resizeHeader() {
		const widget = $('#' + this.id);
		const width = this.windowTitlebar.width();
		const timePeriodSelector = $(`#${this.id} .period_multi_toggle`)
		const right = Number(timePeriodSelector.css('right').replace('px', '')) + timePeriodSelector.outerWidth() + 20
		const counters = $(widget).find('.cw_history_counters')
		if ((this.isServiceBoard && width < 420) || (this.isDashboard && width < 700)) {
			counters.removeAttr('float');
			$(widget).find('.cw_service_breaches').addClass('cw_history_counters_resize').find('strong').empty().addClass('glyphicons remove-sign');
			$(widget).find('.cw_service_downtime').addClass('cw_history_counters_resize').find('strong').empty().addClass('glyphicons circle-arrow-down');
		} else {
			$(widget).find('.cw_service_breaches').removeClass('cw_history_counters_resize').find('strong').removeClass('glyphicons remove-sign').html(lang.SERVICE_BREACHES);
			$(widget).find('.cw_service_downtime').removeClass('cw_history_counters_resize').find('strong').removeClass('glyphicons circle-arrow-down').html(lang.SERVICE_DOWNTIME);
			counters.css('float', 'right');
		}
		if (this.isDashboard) {
			counters.addClass('cw_dashboard_history_counters')
		}
		counters.css('right', `${right}px`);
	}
	/*
	 * Set footer
	 */
	footer() {
		var HTML = '' +
			'<div class="cw_section_footer">' +
			'<div class="left w50 cw_service_state_duration">' +
			'<span class="value red"></span>' +
			'<span class="label">' + lang.widget.LAST_CHANGE_DURATION + '</span>' +
			'</div>' +
			'<div class="left w50 cw_service_state_time">' +
			'<span class="value"></span>' +
			'<span class="label">' + lang.widget.LAST_CHANGE_TIME + '</span>' +
			'</div>' +
			'</div>';

		return HTML;
	}
	/**
	 * Subscribes to the events
	 */
	subscribe (doNotLoadData) {
		if (this.instanceConfiguration.period === 'CUSTOM')
			return;

		var subscriptionObj = [{
			eventType: 'State',
			serviceId: this.instanceConfiguration.serviceId,
			includeQualifiers: false
		}, {
			eventType: 'ServiceSummary',
			serviceId: this.instanceConfiguration.serviceId
		}];
		if (this.instanceConfiguration.serviceElementId) {
			subscriptionObj[0].elementId = this.instanceConfiguration.serviceElementId;
		} else {
			subscriptionObj[0].includeElements = false;
		}
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
		this.isDataSourceSubscribed = true;

		// used in services
		if (!doNotLoadData) {
			this.getData();
		}
	}
	/**
	 * Unsubscribe
	 */
	unsubscribe() {
		if (this.subscriberId) {
			RemoteEventsManager.unsubscribe(this.subscriberId);
		}
		this.isDataSourceSubscribed = false;
	}
	/**
	 * Destroy
	 */
	destroy() {
		if(this.destroyed)
			return

		this.destroyed = true;

		Widget.prototype.destroy.call(this);


		if (this.chart && $(this.chart.renderTo).length) {
			this.chart.destroy();
		}
		this.taskManager && this.taskManager.stopAll();

		clearInterval(this.reloadHistoryWidget);

		if (this.extendState) {
			clearInterval(this.extendState);
		}
		Application.prototype.destroy.call(this);
	}
}
