import { WidgetConfigurationType } from "./widgetConfigurationType";

export function stripConfiguration(config: any): WidgetConfigurationType {
	return {
		id: config.id,
		title: config.title,
		type: config.type,
		configuration: JSON.parse(JSON.stringify(config.instanceConfiguration || config.configuration))
	}
}
