import {MobxManager, ModelValidator, ValidatableModel} from "../../../framework/mobx-integration";
import {ApplicationState} from "../../../framework/applicationState";
import {CostBudgetItem, CostBudgetLink, CostItemDetails, LinkSelectionType} from "../models/costBudgetItem";
import {CostsApi} from "../api";
import {costTranslates} from "../translations";
import {BudgetTableStore} from "./components/BudgetTableStore";
import {makeAutoObservable, makeObservable, observable} from "mobx";
import {LinkCostProfileRowWindowProps} from './linkCostProfileRowWindow'
import {CostBudget} from "../models/costBudget";
import {translator} from "core/localization/localization";
import {LabeledValue} from "antd/lib/tree-select";
import {AntTreeSelectValue} from "../../../controls/react/ant/antTreeSelect";
import {Key, LegacyDataNode } from "rc-tree-select/lib/interface";
import {DefaultOptionType} from "rc-tree-select/lib/TreeSelect";
import {getCurrencyConversionErrorString, getDuplicateLinkErrorString} from "../costHelper";
import {apiFetch} from "framework/api";
import {getCostLinkInfo} from "areas/cost/api";
import { CheckStrategy, TreeCache } from "tools/treeCache";

const i = translator({
	"The input value should be between {0} and {1}.": {
		"no": "Sett inn en verdi mellom {0} og {1}."
	},
	"Could not fetch cost data. There might be an issue with your cost database.": {
		"no": "Klarte ikke å hente kost data. Det kan være et problem med kost databasen."
	}
}, costTranslates);

export class LinkCostProfileRowWindowStore implements ValidatableModel<LinkCostProfileRowWindowStore>, LinkCostProfileRowWindowProps {
	accounts: any[];
	costProfiles: any[];
	costModels: any[];
	targetId?: string = null;
	costModelId?: string = null;
	accountId?: string = ApplicationState.accountId;
	costProfileId?: string = null;
	linkName: string;
	error?: string;
	errorSeverity?: 'error' | 'warning-error' | null;
	mode: 'create' | 'update';
	selectionType: LinkSelectionType = LinkSelectionType.Single;
	percentage: number = 100;
	searchValue: string;

	onSave: (link: CostBudgetLink) => void;
	onCancel: () => void;
	initLink?: CostBudgetItem;
	tableStore: BudgetTableStore;
	budget: CostBudget;
	parent: CostBudgetItem;

	value: AntTreeSelectValue;

	validator = new ModelValidator<LinkCostProfileRowWindowStore>(this);

	treeCache: TreeCache<CostItemDetails>;
	expandedKeys: string[] = [];
	loadedKeys: string[] = [];

	mobx = new MobxManager()

	constructor(props: LinkCostProfileRowWindowProps) {
		Object.assign(this, props);
		makeAutoObservable(this);

		if(this.initLink) {
			this.fillDataFromInitLink(() => {
				this.setupReactions();
			});
		} else {
			this.setupReactions();
			this.loadProfiles();
			this.loadAccounts();
		}

		this.validator
			.required('linkName')
			.required('accountId')
			.required('costProfileId')
			.required('costModelId')
			.required('targetId')
			.required('percentage')
			.between('percentage', 0, 100, true)
			.add('value', { callback: () => this.duplicatedIds.length == 0 });
	}

	private setupReactions() {
		this.mobx.reaction(() => this.accountId, (value) => {
			this.costProfileId = null;
			value && this.loadProfiles();
		});

		this.mobx.reaction(() => this.costProfileId, (value) => {
			this.costModelId = null;
			value && this.loadModels();
		});

		this.mobx.reaction(() => this.costModelId, (value) => {
			this.targetId = null;
			this.value = [];
			if (value) {
				this.loadTree();
			} else {
				this.treeCache = null;
			}
		});

		this.mobx.reaction(() => this.targetId, (value, prevValue) => {
			if (value) {
				this.linkName = this.treeCache?.getOriginalNode(value)?.name;
			} else {
				const prevLabel = this.treeCache?.getOriginalNode(prevValue)?.name;
				if (prevLabel == this.linkName) {
					this.linkName = null;
				}
			}
		});

		this.mobx.reaction(() => this.selectionType, (value, prevValue) => {
			if (!this.treeCache) {
				return
			}
			this.treeCache.changeStrategy(this.treeCacheCheckStrategy)
			this.setValueFromTreeCache()
		})
	}

	get treeCacheCheckStrategy() {
		return {
			[LinkSelectionType.GroupAndChild]: CheckStrategy.GroupAndChild,
			[LinkSelectionType.Single]: CheckStrategy.Single,
		}[this.selectionType]
	}

	get treeData() {
		return this.treeCache?.treeData;
	}

	get currency() {
		return this.budget.currency;
	}

	get startDate() {
		return this.budget.startDate;
	}

	get parentCostModelId() {
		return this.budget.parent?.id;
	}

	get parentProfileId() {
		return this.budget.costBudget.profileId;
	}

	get valid() {
		return this.validator.valid;
	}

	get topCheckedNode() {
		return this.treeCache.topCheckedNode();
	}

	get budgetLinkedIds() {
		let { linkedIds } = this.budget.costBudget;
		if(this.mode != 'update') {
			return linkedIds;
		}
		// remove initialLink ids from all linkedIds
		const initialLinkLinkedIds = this.initLink.linkedIds;
		return linkedIds.filter(x => !initialLinkLinkedIds.includes(x));
	}

	get duplicatedIds() {
		if(this.selectionType == LinkSelectionType.Single) {
			return []
		}
		return this.checkedChildrenIds.filter(x => this.budgetLinkedIds.includes(x));
	}

	get selectionTypes() {
		return [LinkSelectionType.Single, LinkSelectionType.GroupAndChild]
			.map(x => ({text: i(x), value: x}))
	}

	setValue = (value: AntTreeSelectValue, valueArray: string[]) => {
		const labeledValue = value as LabeledValue[];
		// set value must be after setting checked
		this.treeCache.setChecked(labeledValue.map(x => x.value as string));
		this.setValueFromTreeCache();
		this.targetId = this.treeCache?.topCheckedNode()?.value;
		const valueName = labeledValue[0]?.label;

		if (this.duplicatedIds.length) {
			this.error = i('{0} is already linked in this Cost Model', valueName);
			this.errorSeverity = 'warning-error';
		} else {
			this.error = null;
			this.errorSeverity = null;
		}
	}

	setValueFromTreeCache = () => {
		const newValue: LabeledValue[] = this.treeCache.checkedNodes.map(x => {
			return {
				label: x.label,
				value: x.value,
				key: x.key as string,
			};
		});
		this.value = this.sortValue(newValue)
	}

	sortValue(value: LabeledValue[]) : LabeledValue[] {
		const topNodeId = this.topCheckedNode?.value;
		if(!topNodeId) {
			return value;
		}
		return value.sort((a, b) => {
			if (a.value == topNodeId) return -1;
			if (b.value == topNodeId) return 1;
			return 0;
		})
	}

	get checkedChildrenIds() {
		const topId = this.treeCache?.topCheckedNode()?.value
		return this.treeCache?.allCheckedIds.filter(x => x != topId)
	}

	get deletedItemIds() {
		const ids = this.budget.costBudget.deletedItemIds
		if(this.mode != 'update') {
			return ids
		}
		return ids.concat([this.initLink.id])
	}

	save = async () => {
		const costBudgetItem = this.treeCache.getOriginalNode(this.targetId)

		const request = getCostLinkInfo({
			data: {
				costTargetId: costBudgetItem.id,
				costTargetType: costBudgetItem.costType,
				costModelId: this.costModelId,
				targetAccountId: this.accountId,
				targetProfileId: this.costProfileId,
				selectionType: this.selectionType,
				percentage: this.percentage,
				deletedItemIds: this.deletedItemIds
			},
			currency: this.budget.currency,
			startDate: this.budget.startDate.format('YYYY-MM-DD'),
			accountId: this.accountId,
			modelId: this.budget.costBudget.id
		})

		const result = await apiFetch(request)

		if(result.success) {
			const newCostLink = result.data
			newCostLink.name = this.linkName;
			this.onSave(newCostLink)
		} else {
			const { message } = result
			this.errorSeverity = 'error'

			const conversionError = getCurrencyConversionErrorString(message)
			const duplicateLinkError = getDuplicateLinkErrorString(message)

			this.error = conversionError ||
				duplicateLinkError ||
				i('Could not fetch cost data. There might be an issue with your cost database.')
		}
	}

	loadings: boolean[] = [];
	withLoading = (fn: () => Promise<void>) => {
		const n = this.loadings.length;
		this.loadings[n] = false;
		return async () => {
			const timeout = setTimeout(() => {
				this.loadings[n] = true;
			}, 1000);
			await fn();
			if(timeout) {
				clearTimeout(timeout);
			}
			this.loadings[n] = false;
		}
	}

	get loading() {
		return this.loadings.some(x => x);
	}

	loadAccounts = this.withLoading(async () => {
		const result = await CostsApi.subAccountList(this.accountId);
		this.accounts = result;
	})

	loadProfiles = this.withLoading(async () => {
		const result = await CostsApi.subAccountProfilesList(this.accountId);
		const profiles = result.data.filter((x: { id: string }) => x.id != this.parentProfileId);
		this.costProfiles = profiles;
	});

	loadModels = this.withLoading(async () => {
		const result = await CostsApi.subAccountProfileModelsList(this.accountId, this.costProfileId, this.parentCostModelId);
		this.costModels = result.data;
	});

	loadTree = this.withLoading(async () => {
		const result = await CostsApi.costModelDetails(this.costModelId);
		if (!result.success) {
			return;
		}
		this.error = null;
		this.errorSeverity = null;
		this.expandedKeys = []
		this.treeCache = new TreeCache([result.data], this.treeCacheCheckStrategy);
		this.expandedKeys = [result.data.id]
		this.loadedKeys = []
	});

	get treeCheckable() {
		return true
	}


	async fillDataFromInitLink(callback: () => void) {
		const {initLink} = this;
		this.mode = 'update';
		this.accountId = initLink.targetAccountId;
		this.costProfileId = initLink.targetProfileId;
		this.costModelId = initLink.costModelId;
		this.targetId = initLink.costTargetId;
		this.linkName = initLink.name;
		this.percentage = initLink.percentage;
		this.selectionType = initLink.selectionType;

		const promises = [
			this.loadAccounts(),
			this.loadProfiles(),
			this.loadModels(),
			this.loadTree()
		];

		await Promise.all(promises);

		this.treeCache.changeStrategy(this.treeCacheCheckStrategy)
		const ids = [this.targetId];
		this.treeCache.loadAndCheckNecessaryNodes(ids);
		this.setValueFromTreeCache();
		this.expandedKeys = this.treeCache.loadedNodesIds;

		callback();
	}

	loadChildren = async (node: LegacyDataNode) => {
		this.treeCache.loadChildren(node);
		this.loadedKeys.push(node.key as string)
		this.setValueFromTreeCache();
	}

	searchTree = (value: string) => {
		this.searchValue = value;
		if(value.length < 2) {
			return;
		}
		this.treeCache.loadForSearch(value);
		this.expandedKeys = this.treeCache.loadedNodesIds;
	}

	static filterTreeNode(inputValue: string, treeNode: DefaultOptionType) {
		if(inputValue.length < 2) {
			return true;
		}
		return (treeNode.label as string).toLowerCase().includes(inputValue.toLowerCase());
	}

	setExpandedKeys = (keys: Key[]) => { this.expandedKeys = keys as string[] };
	setLoadedKeys = (keys: Key[]) => { this.loadedKeys = keys as string[] };

	destroy(){
		this.mobx.destroy()
	}
}
