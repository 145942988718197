import {SlaWidget} from "./slaWidget";
import {SlaWidgetConfiguration} from './slaWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: SlaWidgetConfiguration,
		defaultConfig: {
			type: 'sla',
			title: i('SLA'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true,
				decimals: 2
			}
		},
		widget: SlaWidget,
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('SLA'),
	}
}
