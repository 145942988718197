import {formatNumber} from "tools/helpers/math";

import translator from "core/localization";

const i18n = translator({
	'Trend: {0}': {
		no: 'Trend: {0}'
	}
});

export function getTrendValueWidgetWrapper(formatterCallbackThis, widget){
	let userOptions = formatterCallbackThis.series?.userOptions;
	if(userOptions == null){
		userOptions = formatterCallbackThis.points.find(x => x.series != null)?.series;
	}

	if(userOptions == null)
		return '';

	return getTrendValue(userOptions, {
		period: widget.instanceConfiguration?.period ?? widget.configuration.period,
		zoomStartDate:  widget.zoomStartDate,
		zoomEndDate: widget.zoomEndDate
	})
}

export function getLegendLabelFormatter(context){
	if (context.userOptions.isRegressionLine) {
		return '<span title="' + (context.userOptions.fullName || context.userOptions.name) + '">'+ i18n('Trend') + ': ' + context.name + '</span>';
	} else {
		return '<span title="' + (context.userOptions.fullName || context.userOptions.name) + '">' + context.name + '</span>';
	}
}

export function getTrendValue(userOptions, {period, zoomStartDate, zoomEndDate}) {
	if (!userOptions.regressionOutputs) {
		return '';
	}

	//equation contains coefficients of equation for selected algorythim
	//in our case that is a linear one: x = Ax + B
	//so equation[0] is A and equation [1] is B
	//We are interested in A since it shows a grow per millisecond

	let valueWithUnit = '0';
	if (userOptions.regressionOutputs.equation[0]) {
		var {value,unit} = convertValuePerMillisecondToUsableScale(
			userOptions.regressionOutputs.equation[0], period, zoomStartDate, zoomEndDate);

		if (Math.abs(value) > 0.001) {
			valueWithUnit = formatNumber(value, 3) + '/' + unit;
		}
	}

	return i18n('Trend: {0}', valueWithUnit);
}

function convertValuePerMillisecondToUsableScale(valuePerMs, period, zoomStart, zoomEnd) {
	let value;
	let unit;
	if (zoomStart && zoomEnd) {
		let zoomPeriod = (zoomEnd - zoomStart) / 1000; //zoom period in seconds
		value = valuePerMs * 60;
		unit = 'second';

		if (zoomPeriod > 60 * 3) {
			value *= 60;
			zoomPeriod /= 60;
			unit = 'minute';
		}

		if (zoomPeriod > 60 * 3 && unit == 'minute') {
			zoomPeriod /= 60;
			value *= 60;
			unit = 'hour';
		}

		if (zoomPeriod > 24 * 3 && unit == 'hour') {
			value *= 24;
			zoomPeriod /= 24;
			unit = 'day'
		}

	} else {
		value = valuePerMs * 60 * 1000;
		unit = 'minute';

		switch (period) {
			case 'LASTDAY':
				value *= 60;
				unit = 'hour';
				break;
			case 'LAST7DAYS':
			case 'LAST30DAYS':
				value *= 60 * 24;
				unit = 'day';
				break;
		}
	}

	return {value, unit}
}
