import UrlBuilder from "tools/urlBuilder";

export function getDescriptions(){
	return [{
		url: '/applications',
		getModuleImport: () => import('./list'),
		react: true,
		routeId: 'application'
	},{
		url: '/applications/:id',
		getModuleImport: () => import('./details'),
		react: true,
		routeId: 'application_details'
	},{
		url: '/applications/:id/designer',
		getModuleImport: () => import('./designer'),
		react: true,
		routeId: 'application_designer'
	}];
}

export class ApplicationsRouter{
	static list(filter){
		return new UrlBuilder('/applications')
			.addObject(filter)
			.build()
	}

	static details(applicationId){
		return '/applications/' + applicationId;
	}

	static designer(applicationId){
		return '/applications/' + applicationId + '/designer';
	}
}
