import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import MultiToggle from 'controls/multiToggle';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import {translator} from "core";

const i = translator({
	"Breach value can not be below minimum": {
	  "no": "Bruddverdi kan ikke være mindre enn minimum"
	}
  });

export function GaugeWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {GaugeWidgetConfiguration as default};

jQuery.extend(GaugeWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	initComponent: function () {
		var minValue;
		if (this.wasUpdate) {
			minValue = this.widgetObj.configuration.minValue || 0;
		} else {
			minValue = this.widgetObj.configuration.minValue || '';
		}
		this.widgetObj.configuration.minValue = minValue;
		this.widgetObj.configuration.maxValue = this.widgetObj.configuration.maxValue === undefined ? '' : this.widgetObj.configuration.maxValue;
		this.widgetObj.configuration.breachValue = this.widgetObj.configuration.breachValue === undefined ? '' : this.widgetObj.configuration.breachValue;

		var widgetObj = this.widgetObj;
		var inverted = widgetObj.configuration.inverted;
		var html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div>';
		html += '<div class="cw_field" id="cw_gauge_service">';
		html += '<label class="cw_inline">' + lang.widget.SERVICE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_sid" type="text" value="' + (widgetObj.configuration.serviceId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_se">';
		html += '<label class="cw_inline">' + lang.designer.SERVICE_ELEMENT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_seid" type="text" value="' + (widgetObj.configuration.serviceElementId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_sq">';
		html += '<label class="cw_inline">' + lang.designer.SERVICE_QUALIFIER + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_sqid" type="text" value="' + (widgetObj.configuration.serviceQualifierId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field"><label class="cw_inline">' + lang.TYPE + ': </label><span id="cw_gauge_type"></span></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_MIN_VALUE + '" id="cw_gauge_min_field" class="' + (inverted ? "" : "hide") + '"><label class="cw_inline">' + lang.widget.MIN_VALUE + ': </label><div class="cw_numeric_container"><input id="cw_gauge_min" type="text" value="' + widgetObj.configuration.minValue + '" /></div></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_MAX_VALUE + '"><label class="cw_inline">' + lang.widget.MAX_VALUE + ': </label><div class="cw_numeric_container"><input id="cw_gauge_max" type="text" value="' + widgetObj.configuration.maxValue + '" /></div></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_BREACH_VALUE + '"><label class="cw_inline">' + lang.widget.BREACH_VALUE + ': </label><div class="cw_numeric_container"><input id="cw_gauge_breach" type="text" value="' + widgetObj.configuration.breachValue + '" /></div></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_DATA_REPRESENTATION + '">';
		html += '<input type="checkbox" id="cw_gauge_invert" class="k-checkbox" ' + (inverted ? 'checked' : '') + ' />';
		html += '<label for="cw_gauge_invert" class="k-checkbox-label">' + lang.widget.INVERT + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.LEGEND + ': </span>';
		html += '<input id="cw_gauge_legend" type="text" />';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		this.gaugeType = new MultiToggle({
			id: 'cw_gauge_type',
			type: 'label',
			cssClass: 'cw_multi_toggle true',
			value: 'gauge',
			items: [{
				id: 'cw_toggle_gauge',
				title: lang.GAUGE,
				label: lang.GAUGE,
				selected: this.mode === 'update' || this.wasUpdate ? (widgetObj.configuration.gaugeType === 'gauge' ? true : false) : true,
				fn: this.onGaugeTypeToggle,
				scope: this,
				value: 'gauge'
			}, {
				id: 'cw_toggle_solidgauge',
				title: lang.SOLID_GAUGE,
				label: lang.SOLID_GAUGE,
				selected: this.mode === 'update' || this.wasUpdate ? (widgetObj.configuration.gaugeType === 'solidgauge' ? true : false) : false,
				fn: this.onGaugeTypeToggle,
				scope: this,
				value: 'solidgauge'
			}]
		});
		this.initKendoComponents();
		const requiredFields = ['#cw_gauge_accountid', '#cw_gauge_sid', '#cw_gauge_seid', '#cw_gauge_sqid', '#cw_gauge_min', '#cw_gauge_max', '#cw_gauge_breach'];
		applyRequired(requiredFields);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_ignore_missing_data').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_ignore_missing_data').on('click', $.proxy(this.onIngnoreCheckbox, this));
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_gauge_accountid'),
			container: $('#cw_gauge_account'),
			nextElement: $('#cw_gauge_sid'),
			otherElements: [$('#cw_gauge_seid'), $('#cw_gauge_sqid')],
			events: {
				change: (e) => {
					this.isInputChanged = true;
					this.currentAccountId = e.sender.value();
					this.serviceSelector.dataSource.read();
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_gauge_se'),
							label: lang.designer.SERVICE_ELEMENT
						}, {
							container: $('#cw_gauge_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: e.sender.dataSource.data().length
					});
				},
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_gauge_se'),
							label: lang.designer.SERVICE_ELEMENT
						}, {
							container: $('#cw_gauge_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: length
					});
				}, this)
			}
		});

		this.serviceSelector = $('#cw_gauge_sid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_se'),
							label: lang.designer.SERVICE_ELEMENT
						}, {
							container: $('#cw_gauge_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/services/lite?hasModel=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				requestStart: $.proxy(function (e) {
					if (!this.widgetAccountId && !widgetObj.configuration.accountId) {
						e.preventDefault();
					}
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(this.onGaugeServiceSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
				this.currentServiceId = e.sender.value();
				this.serviceElementSelector.dataSource.read();
				this.emptyComponent({
					containers: [{
						container: $('#cw_gauge_se'),
						label: lang.designer.SERVICE_ELEMENT
					}, {
						container: $('#cw_gauge_sq'),
						label: lang.designer.SERVICE_QUALIFIER
					}],
					length: e.sender.dataSource.data().length
				});
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isAccountChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isAccountChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		this.serviceElementSelector = $('#cw_gauge_seid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'services/' + (this.currentServiceId || this.serviceId || widgetObj.configuration.serviceId) + '/elements/nonAggregatedOnly/lite';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				requestStart: $.proxy(function (e) {
					if (!this.serviceId && !widgetObj.configuration.serviceId) {
						e.preventDefault();
					}
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(this.onGaugeServiceElementSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			placeholder: lang.SELECT,
			value: widgetObj.configuration.serviceElementId,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
				this.currentServiceElementId = e.sender.value();
				this.serviceQualifierSelector.dataSource.read();
				this.emptyComponent({
					containers: [{
						container: $('#cw_gauge_sq'),
						label: lang.designer.SERVICE_QUALIFIER
					}],
					length: e.sender.dataSource.data().length
				});
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isServiceChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isServiceChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		if (this.mode === 'update' || this.wasUpdate) {
			if (widgetObj.configuration.serviceId && widgetObj.configuration.serviceElementId) {
				var sqDataSource = new kendo.ceeview.DataSource({
					transport: {
						read: {
							url: $.proxy(function () {
								return Settings.serverPath + 'services/' + (this.currentServiceId || widgetObj.configuration.serviceId) + '/elements/' + (this.currentServiceElementId || widgetObj.configuration.serviceElementId) + '/qualifiers/?update=false'
							}, this),
							//url: Settings.serverPath + 'services/' + (this.currentServiceId || widgetObj.configuration.serviceId) + '/elements/' + (this.currentServiceElementId || widgetObj.configuration.serviceElementId) + '/qualifiers/?update=false',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
			}
		}

		this.serviceQualifierSelector = $('#cw_gauge_sqid').kendoComboBox({
			dataSource: (this.mode === 'update' || this.wasUpdate) ? sqDataSource : [],
			dataTextField: 'name',
			dataValueField: 'id',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			placeholder: lang.SELECT,
			value: widgetObj.configuration.serviceQualifierId,
			clearButton: false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isServiceElementChanged) {
						//this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isServiceElementChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		this.minValue = $('#cw_gauge_min').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		this.maxValue = $('#cw_gauge_max').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		this.breachValue = $('#cw_gauge_breach').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		$("#cw_gauge_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_gauge_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$('#cw_gauge_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			clearButton: false,
		});
		this.legendDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<Service>',
			id: '<Service>'
		}, {
			name: '<Serviceelement>',
			id: '<Serviceelement>'
		}, {
			name: '<Servicequalifier>',
			id: '<Servicequalifier>'
		}];
		if (this.wasUpdate) {
			if (!widgetObj.configuration.labelTemplate) {
				this.legendMultiList = this.legendDataSource.map(x => x.id);
			} else {
				this.legendMultiList = widgetObj.configuration.labelTemplate;
			}
		}
		this.renderLegendMultiSelect();
	},
	renderLegendMultiSelect: function() {
		ReactDOM.render(<FormEntry label={lang.LEGEND} vertical={true}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_gauge_legend').parent().get()[0]);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	/**
	 * Handler function for the select event of the gauge service dropdown list
	 * @param {Object} e The select event
	 */
	onGaugeServiceSelect: function (e) {
		if (e.item) {
			this.isServiceChanged = true;
			this.isInputChanged = true;
			var serviceElementList = $('#cw_gauge_seid').data('kendoComboBox');
			var serviceQualifierList = $('#cw_gauge_sqid').data('kendoComboBox');
			this.serviceId = e.dataItem.id;
			//serviceElementList.dataSource.read();
			serviceElementList.enable(true);
			//serviceElementList.value('');
			//serviceQualifierList.value('');
			serviceQualifierList.enable(false);
		}
	},
	/**
	 * Handler function for the select event of the gauge service element dropdown list
	 * @param {Object} e The select event
	 */
	onGaugeServiceElementSelect: function (e) {
		if (e.item) {
			var serviceQualifierCombo = $("#cw_gauge_sqid").data('kendoComboBox');
			var smId = $("#cw_gauge_sid").data('kendoComboBox').value();
			var seId = e.dataItem.id;
			if (seId) {
				this.isServiceElementChanged = true;
				this.isInputChanged = true;
				serviceQualifierCombo.enable(true);
				//serviceQualifierCombo.value('');
				var newSqDataSource = new kendo.ceeview.DataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_gauge_sq'),
								label: lang.designer.SERVICE_QUALIFIER
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'services/' + smId + '/elements/' + seId + '/qualifiers/lite?update=false',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				serviceQualifierCombo.setDataSource(newSqDataSource);
			} else {
				//serviceQualifierCombo.value('');
				serviceQualifierCombo.enable(false);
			}
		}
	},
	findRecord: function (dataSource, id) {
		var record = null;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var r = dataSource.at(i);
			if (r.id === id) {
				record = r;
				break;
			}
		}
		return record;
	},
	/*
	* Handler function for getting the widget configuration values
	* */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'gauge',
			title: title,
			configuration: {
				accountId: $('#cw_gauge_accountid').data('kendoComboBox').value(),
				gaugeType: this.gaugeType.getValue(),
				inverted: $('#cw_gauge_invert').is(':checked'),
				minValue: parseFloat($('#cw_gauge_min').val() || 0),
				breachValue: parseFloat($('#cw_gauge_breach').val()),
				maxValue: parseFloat($('#cw_gauge_max').val()),
				labelTemplate: this.legendMultiList
			}
		};
		var serviceCombo = $('#cw_gauge_sid').data('kendoComboBox');
		var serviceId = serviceCombo.value();
		var serviceName = serviceCombo.text();
		var serviceElementId = $('#cw_gauge_seid').data('kendoComboBox').value();
		var serviceQualifierId = $('#cw_gauge_sqid').data('kendoComboBox').value();
		var modelId = serviceCombo.dataSource.get(serviceId).modelId;
		var config = {
			serviceId: serviceId,
			modelId: modelId,
			serviceName: serviceName,
			serviceElementId: serviceElementId,
			serviceQualifierId: serviceQualifierId
		};

		jQuery.extend(widgetObj.configuration, config);

		return widgetObj;
	},
	isValid: function () {
		var valid = true;
		var validationMessage = '';
		var accountCombo = $('#cw_gauge_accountid').data('kendoComboBox');
		var sCombo = $('#cw_gauge_sid').data('kendoComboBox');
		var seCombo = $('#cw_gauge_seid').data('kendoComboBox');
		var sqCombo = $('#cw_gauge_sqid').data('kendoComboBox');
		var min = $('#cw_gauge_min').val();
		var breach = $('#cw_gauge_breach').val();
		var max = $('#cw_gauge_max').val();
		var inverted = $('#cw_gauge_invert').is(':checked');
		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(sqCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_QUALIFIER;
		}
		if (!Utils.isGuid(seCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_SERVICE_ELEMENT;
		}
		if (!Utils.isGuid(sCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_SERVICE_MODEL;
		}
		if (inverted && (isNaN(min) || min.trim() === '')) {
			valid = false;
			validationMessage = lang.widget.messages.FILL_IN_NUMERIC_MIN;
		}
		if (isNaN(breach) || breach.trim() === '') {
			valid = false;
			validationMessage = lang.widget.messages.FILL_IN_NUMERIC_BREACH;
		}
		if (isNaN(max) || max.trim() === '') {
			valid = false;
			validationMessage = lang.widget.messages.FILL_IN_NUMERIC_MAX;
		}
		if (!isNaN(max) && !isNaN(breach)) {
			max = parseFloat(max);
			breach = parseFloat(breach);
			if (max < breach) {
				valid = false;
				validationMessage = lang.widget.messages.BREACH_VALUE_MAX;
			}
			if(!isNaN(min) && breach < min) {
				valid = false;
				validationMessage = i("Breach value can not be below minimum");
			}
			if (inverted && !isNaN(min)) {
				if (max <= min) {
					valid = false;
					validationMessage = lang.widget.messages.MIN_VALUE_MAX;
				}
			}
		}
		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}
		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
