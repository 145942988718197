import React from "react";

import {
	BarsStatesWidget,
	summaryToDataListConverter,
	withStateDataSource
} from "controls/react/barsStatesWidget";
import {Severity, SlaSeverity} from "framework/entities/healthData";
import {SlaStateSummary} from "areas/sla/widgets/stateWidget/slaStateSummary"

const i = require('core/localization/localization').translator({
	BarTitle: {
		en: 'Total SLA in {0} state: {1}',
		no: 'Totalt antall SLA i {0} tilstand: {1}'
	}
});

const severityMap = new Map<SlaSeverity, Severity>([
	[SlaSeverity.Breached, Severity.Critical],
	[SlaSeverity.Warning, Severity.Major],
	[SlaSeverity.Compliant, Severity.None],
]);

const summaryFieldsMap = new Map<SlaSeverity, (summary: SlaStateSummary, isWarning?: boolean) => number>([
	[SlaSeverity.Breached, (summary: SlaStateSummary, isWarning?: boolean) => isWarning ? undefined : summary.breaches],
	[SlaSeverity.Warning, (summary: SlaStateSummary, isWarning?: boolean) => isWarning ? undefined : summary.warnings],
	[SlaSeverity.Compliant, (summary: SlaStateSummary, isWarning?: boolean) => isWarning ? undefined : summary.ok],
]);

const availableSeverities = [SlaSeverity.Breached, SlaSeverity.Warning, SlaSeverity.Compliant];
const getDataList = summaryToDataListConverter(availableSeverities, severityMap, summaryFieldsMap, i);
const dataSourceGetter = (summary: SlaStateSummary, severities: SlaSeverity[]) => getDataList(summary, severities);

export const SlaStateWidget = withStateDataSource<SlaStateSummary, SlaSeverity>(BarsStatesWidget, dataSourceGetter);
