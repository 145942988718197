import {BarsStatesWidget, summaryToDataListConverter, withStateDataSource} from "controls/react/barsStatesWidget";
import {Severity} from "framework/entities/healthData";
import {ApplicationStateSummary} from "areas/application/widgets/stateWidget/applicationStateSummary";

const i = require('core/localization/localization').translator({
	BarTitle: {
		en: 'Total application in {0} state: {1}',
		no: 'Totalt antall applikasjoner i {0} tilstand: {1}'
	}
});

export enum ApplicationSeverity {
	None = 'OK',
	Minor = 'MINOR',
	Major = 'MAJOR',
	Critical = 'CRITICAL',
	Invalid = 'INACTIVE'
}
let applicationStateDatasource: [ApplicationSeverity, string][];
export function getApplicationStateDatasource() {
	if (!applicationStateDatasource) {
		applicationStateDatasource = [
			[ApplicationSeverity.Critical, i(ApplicationSeverity.Critical)],
			[ApplicationSeverity.Major, i(ApplicationSeverity.Major)],
			[ApplicationSeverity.Minor, i(ApplicationSeverity.Minor)],
			[ApplicationSeverity.None, i(ApplicationSeverity.None)],
			[ApplicationSeverity.Invalid, i(ApplicationSeverity.Invalid)],
		];
	}
	return applicationStateDatasource;
}

const severityMap = new Map<ApplicationSeverity, Severity>([
	[ApplicationSeverity.Critical, Severity.Critical],
	[ApplicationSeverity.Major, Severity.Major],
	[ApplicationSeverity.Minor, Severity.Minor],
	[ApplicationSeverity.None, Severity.None],
	[ApplicationSeverity.Invalid, Severity.Invalid],
]);

const summaryFieldsMap = new Map<ApplicationSeverity, (summary: ApplicationStateSummary, isWarning?: boolean) => number | boolean>([
	[ApplicationSeverity.Critical, (summary: ApplicationStateSummary, isWarning?: boolean) => isWarning ? summary.criticalFlag : summary.critical],
	[ApplicationSeverity.Major, (summary: ApplicationStateSummary, isWarning?: boolean) => isWarning ? summary.majorFlag : summary.major],
	[ApplicationSeverity.Minor, (summary: ApplicationStateSummary, isWarning?: boolean) => isWarning ? summary.minorFlag : summary.minor],
	[ApplicationSeverity.None, (summary: ApplicationStateSummary, isWarning?: boolean) => isWarning ? summary.okFlag : summary.ok],
	[ApplicationSeverity.Invalid, (summary: ApplicationStateSummary, isWarning?: boolean) => isWarning ? summary.inactiveFlag : summary.maintenance],
]);

const availableSeverities = [ApplicationSeverity.Critical, ApplicationSeverity.Major, ApplicationSeverity.Minor, ApplicationSeverity.None, ApplicationSeverity.Invalid];
const getDataList = summaryToDataListConverter(availableSeverities, severityMap, summaryFieldsMap, i);
const dataSourceGetter = (summary: ApplicationStateSummary, severities: ApplicationSeverity[]) => getDataList(summary, severities);

export const ApplicationStateWidget = withStateDataSource<ApplicationStateSummary, ApplicationSeverity>(BarsStatesWidget, dataSourceGetter);
