export function getGridFilterable(scope) {
	const filterMessages = lang.grid.filter;
	return {
		extra: false,
		operators: {
			string: {
				startswith: filterMessages.STARTS_WITH,
				neq: filterMessages.NEQ,
				eq: filterMessages.EQ,
				contains: filterMessages.CONTAINS
			},
			number: {
				eq: filterMessages.EQ,
				neq: filterMessages.NEQ,
				gte: filterMessages.GTE,
				gt: filterMessages.GT,
				lte: filterMessages.LTE,
				lt: filterMessages.LT
			}
		},
		messages: scope.gridMessages
	}
}