import Lang from "core/localization/lang";

const shortColumns = [
	{
		field: 'metricId',
		title: Lang.SELECTOR,
		template: function (data) {
			return `<input type="checkbox" metric-id=${data.metricId} class="cw_grid_check" />`
		},
		attributes: {
			'class': 'text_center'
		},
		headerAttributes: {
			'class': 'text_center'
		},
		sortable: false,
		width: 40,
		filterable: false,
	},
	{
		field: 'assetName',
		title: Lang.ASSET,
		editable: function() {
			return false;
		},
		width: 80,
	}, {
		field: 'categoryNode',
		title: Lang.NAME,
		editable: function() {
			return false;
		},
		width: 80,
	}, {
		field: 'registryType',
		title: Lang.TYPE,
		editable: function() {
			return false;
		},
		width: 80,
	}, {
		field: 'registryOwnerName',
		title: Lang.serviceBoard.SOURCENAME,
		editable: function() {
			return false;
		},
		width: 80,
	},{
		field: 'instanceName',
		title: Lang.serviceBoard.INSTANCE_NAME,
		editable: function() {
			return false;
		},
		width: 120
	}, {
		field: 'unitType',
		title: Lang.UNIT,
		editable: function() {
			return false;
		},
		width: 80
	}, {
		field: '',
		editable: function() {
			return false;
		},
		template: '<span class="cw_metric_item_delete pointer glyphicons bin"></span>',
		width: 30
	}
];


const fullColumns = [
	...shortColumns,
	{
		field: 'customUnit',
		title: Lang.CUSTOM_UNIT,
		editable: function() {
			return true;
		},
		width: 90,
	}, {
		field: 'conversion',
		title: Lang.CONVERSION,
		editable: function() {
			return false;
		},
		template: function (data) {
			return `<span class="cw_conversion ellipsis" decimals="${data.decimals}" title="${data.conversion || ''}">${data.conversion || ''} </span>`
		},
		width: 90,
	}, {
		field: 'color',
		title: Lang.COLOR,
		editable: function() {
			return true;
		},
		editor: function (container, options) {
			$('<input data-bind="value:' + options.field + '"/>')
				.appendTo(container)
				.kendoDropDownList({
					suggest: true,
					dataSource: ["",
						"#7cb5ec",
						"#434348",
						"#90ed7d",
						"#8085e9",
						"#f15c80",
						"#e4d354",
						"#2b908f",
						"#f45b5b",
						"#91e8e1"],
					template: `<div style="background-color: #=data#; width: 20px; height: 20px;"></div>`,
					valueTemplate: `<div style="background-color: #=data#; width: 20px; height: 20px;"></div>`
				});
		},
		width: 90,
		template: '<div class="cw_selected_color" style="width: 20px; height: 20px; background-color: #=data.color#;" data-color="#=data.color#" data-id="#=data.metricId#"></div>'
	}
];

export function metricSelectorGridColumns(full = false) {
	if (full) {
		let storedColumn;
		for (let i = 0; i < fullColumns.length; i++) {
			if (!fullColumns[i].field) {
				storedColumn = fullColumns[i];
				fullColumns.splice(i, 1);
			}
		}
		fullColumns.push(storedColumn);
	}
	return full ? fullColumns: shortColumns;
}
