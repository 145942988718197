import {Cookies} from "core/cookies";
import {SelectiveServicesWidget} from "./selectiveServicesWidget";
import {SelectiveServicesWidgetConfiguration} from './selectiveServicesWidgetConfiguration';
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator({
  "Selective Services": {
    "no": "Utvalgte tjenester",
    "en": "Selective services"
  }
});

export function getWidgetDescription(){
	return {
		form: SelectiveServicesWidgetConfiguration,
		defaultConfig: {
			type: 'service-selective',
			title: i('Selective Services'),
			configuration: {
				accountsList: [ApplicationState.accountId],
				isDashboard: true
			}
		},
		containerClass: "selective-services",
		constructWidget: config => new SelectiveServicesWidget(config),
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Service') + '/' + i('Selective Services'),
	}
}
