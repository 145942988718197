import Api from 'tools/api';
import {ApiRequest} from "framework/api";

export default class BudgetApi {
	static getResourceCost(payload: {accountId: string}) {
		return Api.fetchPost(Api.accountRoot(payload.accountId) + 'cost/resourceCost', payload);
	}

	static getAvailableYears(payload: GetAvailableYearsPayload){
		return Api.fetch(Api.accountRoot(payload.accountId) + `cost/profiles/${payload.profileId}/models/${payload.modelId}/years`);
	}
}

type GetAvailableYearsPayload = {
	accountId: string
	profileId: string
	modelId: string
}
