import {Cookies} from "core/cookies";
import {HistoryWidget} from "./historyWidget";
import {HistoryWidgetConfiguration} from './historyWidgetConfiguration';
import {sharedLocalization} from "controls/designer/features/widgets/localization";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: HistoryWidgetConfiguration,
		defaultConfig: {
			type: 'service-history',
			title: i('History'),
			configuration: {
				accountId: Cookies.CeesoftCurrentAccountId,
				isDashboard: true,
				reactTimePeriodSelector: true
			}
		},
		constructWidget: config => new HistoryWidget(config),
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Service') + '/' + i('History'),
	}
}
