import './assetForm.less';
import React from 'react';
import {Application, LocalEventsManager, translator} from "core";
import {ImageUploader} from "controls/imageUploader";
import {CustomNotification} from "controls/customNotification";
import Settings from "settings";
import {Api} from "tools/api";
import {Utils} from "tools/utils";
import {AssetsApi} from "api";
import {createRoot} from "react-dom/client";
import {AddAssetForm} from "areas/administration/addAssetForm";
import {ApplicationState} from "framework/applicationState";

const i = translator();

export let AssetForm = function (config) {
	Utils.apply(this, config);
	this.recentlyUsedIcons = [];
	this.initComponent();
};

export default AssetForm;

jQuery.extend(AssetForm.prototype, Application.prototype, {
	assetData: {},
	/**
	 * This is main init function
	 */
	initComponent: async function () {
		this.accountId = this.accountId || ApplicationState.accountId;
		this.update = this.mode !== 'view';

		this.imageId = '';
		this.formEl = $('#cw_asset_form');

		this.selected = false;

		let actionButtonText = lang.CREATE;
		if (this.mode === 'update') {
			actionButtonText = lang.UPDATE;
		}
		$('#save_asset').text(actionButtonText);

		this.initKendoComponents();

		this.removeListeners();
		this.attachListeners();

		this.assetData = {accountId: this.topAccountId ?? this.accountId};

		if (this.mode === 'update' || this.mode === 'view') {
			this.assetData = await this.load();
		}

		this.validate();
		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
		if (this.mode === 'view') {
			this.removeListeners();
			this.enableViewMode();
		}
		this.renderForm();
	},
	/**
	 * Handler function for making form readonly if user does not have update permission
	 */
	enableViewMode: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_asset').addClass('hide');
		$('.is_readonly').attr('disabled', true);
	},
	/**
	 * Launches Media Library.
	 */
	onMediaLibraryButton: function () {
		if ($('.cw_media_library_content').hasClass('hide')) {
			if (!this.uploaderImagesControl) {
				this.renderUploaderImagesControl();
				//TODO: not sure if this is still relevant because the way of getting image is changed
				// if (this.imageId) {
				// 	this.uploaderImagesControl.setSelectedImageId(this.imageId);
				// }
			}
			var modalWindow = $('#modal').data("kendoWindow");
			/*$('.form_content').slideUp(1, function(){
			 $('.form_content_minimal').fadeIn(1);
			 });*/
			this.formEl.find('.form_content').addClass('hide');
			this.formEl.find('.form_content_minimal').show();
			this.formEl.parent().find('.cw_media_library_content').removeClass('hide');
			this.formEl.parent().parent().find('.actions button').hide();
			$('#back').removeClass('hide');
			this.formEl.parent().find('.cw_media_library').addClass('hide');
		}
	},
	/**
	 * Handler function for the click event on the form expand button
	 */
	onFormExpandButton: function () {
		this.formEl.find('.form_content_minimal').hide();
		var modalWindow = $('#modal').data("kendoWindow");
		this.formEl.parent().find('.cw_media_library_content').addClass('hide');
		this.formEl.find('.form_content').removeClass('hide');
		this.formEl.parent().find('.cw_media_library').removeClass('hide');
		this.formEl.parent().parent().find('.actions button').show();
		$('#back').addClass('hide');
	},
	/**
	 * Renders the uploader images control.
	 */
	renderUploaderImagesControl: function () {
		this.uploaderImagesControl = new ImageUploader({
			id: 'asset_glyph_icons',
			context: 'assets',
			livePreviewSelector: '#cw_asset_card_avatar',
			height: '344px'
		});
	},

	/**
	 * Initialize Kendo UI controls
	 */
	initKendoComponents: function () {

	},

	renderForm() {
		if (!this.form) {
			this.form = createRoot(this.formEl.find('.form_content')[0]);
		}
		this.form.render(<AddAssetForm mode={this.mode}
		                               {...this.assetData}
		                               onChange={(data) => {
			                               this.assetData = data;
										   this.validate();
		                               }}
		                               disabled={this.disabled}/>);
	},

	getSelectedAccount() {
		return this.assetData.accountId ?? this.accountId;
	},

	removeListeners: function () {
		$('#save_asset').off();
		$('#cancel').off();
		$('.cw_media_library').off();
		$('#back').off();
		LocalEventsManager.unbind('loadRecentIcons');
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_asset').on('click', $.proxy(this.onSaveButton, this));
		$('.cw_avatar_asset').find('.close_minibtn').on('click', $.proxy(this.onRemovePicture, this));
		$('.cw_media_library').on('click', $.proxy(this.onMediaLibraryButton, this));
		$('#back').on('click', $.proxy(this.onFormExpandButton, this));
		LocalEventsManager.bind('loadRecentIcons', $.proxy(this.loadRecentIcons, this));
	},

	validate: function () {
		const name = this.assetData.name ?? '';
		$('#save_asset').attr('disabled', name.trim() == '');
	},

	/**
	 * Handler function for the click event on image remove button
	 */
	onRemovePicture: function (e) {
		if (this.uploaderImagesControl && this.uploaderImagesControl.getSelectedImage().id) {
			//TODO: not sure if this is still relevant because the way of getting image is changed
			//this.uploaderImagesControl.setSelectedImageId('');
		}
		this.imageId = null;
		var assetModal = $(e.target).parents().eq(6);
		var id = assetModal.find('.assetId').attr('id');
		// remove on modal
		var previewDiv = assetModal.find('.cw_avatar_asset');
		previewDiv.find('.close_minibtn').addClass('hide');
		previewDiv.removeClass('no_bg');
		previewDiv.find('img, span').remove();
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: async function () {
		const loadUrl = Settings.serverPath + 'accounts/' + this.getSelectedAccount() + '/assets/' + this.id + '/?update=' + this.update;
		const result = await Utils.ajax(loadUrl, 'GET', {});
		if (!result.success) {
			Utils.showInfo(lang.ALERT, result.message, result.details);
			return;
		}

		this.initialName = result.data.name;
		this.imageId = result.data.imageId;
		this.iconPack = result.data.iconPack;

		if (result.data.imageId) {
			var imagePreviewDiv = $('#cw_asset_card_avatar .cw_avatar_asset');
			imagePreviewDiv.addClass('no_bg');
			if (Utils.isGuid(this.imageId)) {
				imagePreviewDiv.prepend('<img src="' + Api.images.urls.image(this.imageId) + '" />');
			} else {
				if (this.iconPack === 'glyph') {
					imagePreviewDiv.prepend('<span class="glyphicons ' + this.imageId + '"></span>');
				} else {
					imagePreviewDiv.prepend('<i class="material-icons">' + this.imageId + '</i>');
				}
			}
			imagePreviewDiv.find('.close_minibtn').removeClass('hide');
			//TODO: not sure if this is still relevant because the way of getting image is changed
			// if (this.uploaderImagesControl) {
			// 	this.uploaderImagesControl.setSelectedImageId(result.data.imageId);
			// }
		}

		this.fields = {
			asset_name: this.formEl.find('.js_asset_name')
		};
		return result.data;
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			//close window
			$('#cancel').trigger('click');

			this.pictureId = result.data.imageId;
			this.iconPack = result.data.iconPack;
			if (!Utils.isGuid(this.pictureId)) {
				for (var i = 0; i < this.recentlyUsedIcons.length; i++) {
					if (this.pictureId === this.recentlyUsedIcons[i].id) {
						this.recentlyUsedIcons.splice(i, 1);
					}
				}
				this.recentlyUsedIcons.unshift({
					id: this.pictureId,
					iconPack: this.iconPack
				});
				if (this.recentlyUsedIcons.length === 9) {
					this.recentlyUsedIcons.pop();
				}
				var preferences = [{
					key: 'recentIcons',
					value: JSON.stringify(this.recentlyUsedIcons)
				}];
				this.saveUserPrefs({
					category: 'RecentlyUsedIcons',
					preferences: preferences
				});
			}
			//update status
			var successText = (this.mode === 'create' ? lang.account.messages.ASSET_SUCCESS_CREATED : lang.account.messages.ASSET_SUCCESS_UPDATED);
			this.statusNotification.setOptions({
				message: successText,
				status: 'success'
			}).show();

			var selectedTargets = this.assetData.targets || [];
			var lastTarget = selectedTargets.pop();

			LocalEventsManager.trigger('assetsaved', {
				mode: this.mode,
				asset: {
					id: result.data.id,
					accountId: this.getSelectedAccount(),
					name: this.assetData.name ?? ''
				},
				identifier: this.identifier,
				targets: result.data.targets,
				lastSelectedTarget: lastTarget != null
					? result.data.targets.find( t => t.address == lastTarget.address)
					: {},
				lastAddedTarget: this.lastAddedTarget != null
					? result.data.targets.find( t => t.address == this.lastAddedTarget.text)
					: null
			});
			if (this.onCreateNew) {
				this.onCreateNew(result.data.id);
			}
		}
		else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: async function (e) {
		let name = this.assetData.name ?? '';
		let nameExists = await Utils.checkIfNameExists(AssetsApi.getDynamicSearchUrl('false'), name, this.modalNotification, false, this.initialName);
		if(nameExists) {
			return;
		}
		var imageId, iconPack;
		if (this.uploaderImagesControl && this.uploaderImagesControl.getSelectedImage()) {
			imageId = this.uploaderImagesControl.getSelectedImage().id || '';
			iconPack = this.uploaderImagesControl.getSelectedImage().iconPack || '';
		} else {
			iconPack = this.iconPack;
			imageId = this.imageId;
		}

		this.data = {
			id: this.assetData.id,
			name: this.assetData.name?.trim() ?? '',
			description: this.assetData.description?.trim() ?? '',
			systemType: this.assetData.systemType,
			assetGroups: this.assetData.assetGroups ?? [],
			targets: this.assetData.targets ?? [],
			imageId: imageId,
			iconPack: iconPack,
			tags: this.assetData.tags ?? []
		};
		const url = Settings.serverPath + 'accounts/' + this.getSelectedAccount() + '/assets';
		Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
		this.destroy();
	},

	destroy: function() {
		this.form?.unmount();
	},
	/**
	 * Loads recent icons
	 * @param {Object} e The object sent by event manager
	 */
	loadRecentIcons: function (e) {
		this.recentlyUsedIcons = e.recentIcons;
	}
});
