import {Api} from 'tools/api'
import {NotificationsRouter} from './bundleDescription';
import Utils from "tools/utils";
import produce from 'immer';
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition";
import {ApiRequest, PagedList} from "framework/api";
import {EventsSummaryEntry} from "areas/summary/events/eventsSummaryEntry";

export abstract class NotificationPolicyLoader {
	async loadPolicy() {
		return await Api.fetch(this.getPolicyUrl(false));
	}

	async loadPolicyForEditing(type: string) {
		const [policyResponse, fields, operators, channelsResponse] = await Promise.all([
			Api.fetch(this.getPolicyUrl(true)),
			Api.fetch(this.getFieldsUrl(type)),
			Api.fetch(this.getOperatorsUrl()),
			Api.fetch(this.getChannelsUrl())
		]);

		let result = {
			enums: {
				fields: fields,
				operators: operators,
				channels: channelsResponse.data,
			},
			policy: policyResponse.data
		};

		return result
	}

	async savePolicy(policy) {
		policy = updateIndexes(policy);

		return await Api.fetchPost(this.getPolicyUrl(true), updateIndexes(policy));
	}

	abstract getBaseUrl();

	abstract getDetailsUrl(triggerIndex);

	abstract getCreateUrl();

	abstract getEventSummaryDatasource(trigger: any)

	getPolicyUrl(update: boolean) {
		return `${this.getBaseUrl()}notificationPolicy?update=${update}`;
	}

	getFieldsUrl(type) {
		return `${this.getBaseUrl()}notificationPolicy/fieldNames?triggerType=${type}`;
	}

	getFieldValuesUrl(fieldName, type) {
		return `${this.getBaseUrl()}notificationPolicy/fieldNames/${fieldName}?triggerType=${type}`;
	}

	getOperatorsUrl() {
		return `${this.getBaseUrl()}notificationPolicy/ruleOperators`;
	}

	getChannelsUrl() {
		return `${this.getBaseUrl()}notificationPolicy/availableChannels`;
	}
}


export class TeamNotificationPolicyLoader extends NotificationPolicyLoader{
	teamId: string;

	constructor(id) {
		super();
		this.teamId = id;
	}

	getBaseUrl() {
		return Api.teamRoot(this.teamId);
	}

	getDetailsUrl(triggerIndex){
		return NotificationsRouter.teamRulesetDetails(this.teamId, triggerIndex)
	}

	getCreateUrl() {
		return NotificationsRouter.teamRulesetNew(this.teamId);
	}

	getEventSummaryDatasource(trigger: any){
		return getEventsSummaryPreview('teams', {
			entityId: this.teamId,
			trigger: trigger
		})
	}
}

export class UserNotificationPolicyLoader extends NotificationPolicyLoader{
	userId: string;

	constructor(id) {
		super();
		this.userId = id;
	}

	getBaseUrl() {
		return Api.userRoot(this.userId);
	}

	getDetailsUrl(triggerIndex){
		return NotificationsRouter.userRulesetDetails(this.userId, triggerIndex)
	}

	getCreateUrl() {
		return NotificationsRouter.userRulesetNew(this.userId);
	}

	getEventSummaryDatasource(trigger: any){
		return getEventsSummaryPreview('users', {
			entityId: this.userId,
			trigger: trigger
		})
	}
}

export function updateIndexes(data) {
	return produce(data, (draft) => {
		draft.triggers.forEach((trigger) => {
			if (!Utils.isGuid(trigger.id)) {
				trigger.id = Utils.guid();
			}
		})
	})
}

export type GetEventsSummaryPreviewArgs = {
	entityId: string
	trigger: any
}

function getEventsSummaryPreview(url: 'users'|'teams', args: GetEventsSummaryPreviewArgs){
	return new ApiRequest<PagedList<EventsSummaryEntry>>({
		url: `${url}/${args.entityId}/notificationPolicy/validateRules/EVENT_SUMMARY`,
		subaccountsFilter: true,
		accountBased: true,
		method: 'POST',
		payload: {
			triggerRules: args.trigger
		}
	});
}
