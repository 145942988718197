import {UrlBuilder} from './../../tools/urlBuilder';
import {Severity} from "framework/entities/healthData";
import {EntryType} from "areas/summary/events/eventsSummaryEntry";

export function getDescriptions(){
	return [{
		url: '/summary/events',
		getModuleImport: () => import('areas/summary/events/eventsSummaryPage'),
		react: true,
		loadingIndicator: false,
		routeId: 'events'
	}, {
		url: '/',
		getModuleImport: () => import('./homeTabsView'),
		react: true,
		routeId: 'home'
	}];
}

export type EventsSummaryPageArgs = {
	accountId?: string
	includeSubaccounts?: boolean
	showUntagged?: boolean
	tags?: string[]
	type?: EntryType[]
	severity?: (Severity | string)[]
	entityId?: string
	assetGroupIds?: string[]
}

export class SummaryRouter {
	static events(args?: EventsSummaryPageArgs) {
		let url = new UrlBuilder('/summary/events')
		if(args) {
			url = url.add(args)
		}
		return url.build()
	}

	static home() {
		return '/';
	}
}
