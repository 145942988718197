import {apiFetch, ApiRequest, ApiResponse, copyApiRequest, PagedList} from "framework/api"
import {
	get,
	IObservableArray,
	makeAutoObservable,
	observable,
	runInAction,
	set,
	toJS
} from "mobx"
import {debounce} from "lodash"

import {DataProvider} from "./gridConfiguration"
import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridStore} from "controls/grid/gridStore"
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition"
import {MobxManager} from "framework/mobx-integration"
import {ApplicationState} from "framework/applicationState"
import {GridDataItem} from "controls/grid/gridDataItem";


interface RemoteDataProviderConfig<DataEntry>{
	dataSource: ApiRequest<PagedList<GridDataItem<DataEntry>>>| (() => ApiRequest<PagedList<GridDataItem<DataEntry>>>)
	minBatchSize?: number
	filtersSource?: ApiRequest<RulesConfiguration>
	onBatchLoaded?: (response: ApiResponse<PagedList<GridDataItem<DataEntry>>>) => void
}

export class RemoteDataProvider<DataEntry extends GridDataEntry> implements DataProvider<DataEntry>{
	store: GridStore<DataEntry>
	config: RemoteDataProviderConfig<DataEntry>

	apiRequest: ApiRequest<PagedList<GridDataItem<DataEntry>>>

	visibleRowsCount: number
	scrollableRowsCount: number
	totalRowsCount: number

	//false until the first batch is loaded. Also will be set to false when normal reloading is happening
	initialized: boolean

	//true when data reloading is happening either silent or normal way
	reloading: boolean

	//set to true when a request is going, it might be either initial or any of the subsequential requests
	loading: boolean

	data: IObservableArray<GridDataItem<DataEntry>>

	filtersConfiguration: RulesConfiguration

	mobxManager = new MobxManager();

	onFilterChanged: boolean = false;

	lastVisibleRange: {
		start: number
		finish: number
	}

	responseMessage: string
	failToLoad = false

	groupBySupported = true

	constructor(config: RemoteDataProviderConfig<DataEntry>) {
		this.config = config

		makeAutoObservable(this)
	}

	get minBatchSize(){
		return this.config.minBatchSize ?? 70
	}

	attach = async (store: GridStore<DataEntry>) => {
		this.store = store

		await this.loadFiltersConfiguration()

		this.mobxManager.reaction(() => ({
			dataSource: this.dataSource,
			sorting: this.store.actualSorting,
			filter: toJS(this.store.actualFilter),
			payload: toJS(this.store.state.customPayload),
			tags: ApplicationState.userTags,
			ignoreTags: ApplicationState.ignoreTags,
			showUntagged: ApplicationState.showUntagged,
			groupBy: toJS(this.store.state.groupBy),
			collapsedGroups: toJS(this.store.state.collapsedGroups)
		}), () => {
			this.store.forceSyncListPositionWithHeader = true
			this.reload()
		}, {
			fireImmediately: true
		})
	}

	get dataSource(){
		if(typeof this.config.dataSource === 'function'){
			return this.config.dataSource()
		}
		return this.config.dataSource
	}

	reload = async () => {
		if(!this.store.actualFilter.valid)
			return

		this.onFilterChanged = !this.onFilterChanged;
		this.data = observable.array()

		if(this.dataSource == null) {
			this.initialized = true
			this.visibleRowsCount = 0
			this.totalRowsCount = 0
			this.apiRequest = null
			return
		}

		this.data = observable.array()
		this.visibleRowsCount = null
		this.totalRowsCount = null
		this.initialized = false
		this.reloading = true
		this.apiRequest = this.dataSource

		await this.informVisibleRangeChanged(0, this.minBatchSize)
	}

	silentReload = async () => {
		if(!this.lastVisibleRange)
			return

		this.reloading = true

		await this.loadBatch(
			Math.max(0, this.lastVisibleRange.start),
			this.lastVisibleRange.finish - this.lastVisibleRange.start,
			true
		)
	}

	get(index: number): GridDataItem<DataEntry> {
		if(this.data.length <= index )
			return null

		return get(this.data, index);
	}

	async informVisibleRangeChanged(start: number, finish: number) {
		this.lastVisibleRange = {
			start, finish
		}

		let firstMissingIndex = null

		//going forward 20 rows behind visible to check if we have enough data to show in case user scrolls further down
		for (let i = start; i < finish + 20; i++) {
			if(i == this.visibleRowsCount - 1)
				break

			if (!this.get(i)) {
				firstMissingIndex = i;
				break
			}
		}

		if (firstMissingIndex != null) {
			await this.loadBatchBounced(firstMissingIndex, finish - start)
		}
	}

	batchInQueue: {
		firstMissingIndex: number,
		visibleCount: number,
		clearExistingData: boolean
	}

	loadBatch = async (firstMissingIndex: number, visibleCount: number, clearExistingData: boolean = false) => {
		if (this.loading) {
			this.batchInQueue = {
				firstMissingIndex, visibleCount, clearExistingData
			}
			return
		}

		this.loading = true
		this.responseMessage = null

		const [startIndex, batchSize] = this.calculateBatch(firstMissingIndex, visibleCount)

		let request = this.getBaseApiRequest()

		request.payload.skip = startIndex
		request.payload.take = batchSize

		const response = await apiFetch(request)

		this.config.onBatchLoaded?.(response)

		runInAction(() => {
			if (response.success) {
				if (clearExistingData) {
					this.data = observable.array()
				}

				for (let i = 0; i < response.data.items.length; i++) {
					set(this.data, i + startIndex, response.data.items[i])
				}

				this.visibleRowsCount = response.data.visible
				this.scrollableRowsCount = response.data.scrollable
				this.totalRowsCount = response.data.total

				if (clearExistingData) {
					this.store.listControl?.resetAfterIndex(0)
				}

				if (this.batchInQueue) {
					const q = this.batchInQueue
					this.batchInQueue = null
					this.loadBatch(q.firstMissingIndex, q.visibleCount, q.clearExistingData)
				}
			} else {
				this.visibleRowsCount = 0
				this.totalRowsCount = 0
				this.data = observable.array()
				this.responseMessage = response.message
			}
			this.failToLoad = !response.success

			this.initialized = true
			this.reloading = false
			this.loading = false
		})
	}

	loadBatchBounced = debounce(this.loadBatch, 500)

	calculateBatch(firstMissingIndex: number, visibleCount: number ){
		let batchSize = Math.max(visibleCount, this.minBatchSize) + 100
		//in case data will be scrolled top we also load 40 records before the first one if they are missing
		let startIndex = firstMissingIndex

		for(; startIndex > firstMissingIndex - 50 && startIndex > 0; startIndex--){
			if(this.get(startIndex) != null ){
				startIndex++
				break;
			}
		}

		return [startIndex, batchSize + firstMissingIndex - startIndex];
	}

	async loadFiltersConfiguration() {
		if (this.filtersConfiguration)
			return

		if (this.config.filtersSource) {
			const result = await apiFetch(this.config.filtersSource)
			if (result.success) {
				this.filtersConfiguration = result.data
			}
		}

		if (!this.filtersConfiguration) {
			this.filtersConfiguration = {}
		}
	}

	getBaseApiRequest(){
		let request = copyApiRequest(this.apiRequest)

		if(request.payload == null){
			request.payload = {}
		}

		Object.assign(request.payload, this.store.state.customPayload)
		request.payload.sort = this.store.actualSorting
		request.payload.filter = this.store.actualFilter
		request.payload.groupBy = this.store.state.groupBy
		request.payload.collapsedGroups = this.store.state.collapsedGroups

		return request
	}

	destroy(){
		this.mobxManager.destroy();
	}
}
