import {SummaryWidget} from "./summaryWidget";
import {SummaryWidgetConfiguration} from './summaryWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: SummaryWidgetConfiguration,
		defaultConfig: {
			type: 'summary-grid',
			title: i('Home'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		minWidth: 800,
		widget: SummaryWidget,
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Grid') + '/' + i('Home'),
	}
}
