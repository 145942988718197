import './metricValueAsSeverity.less'

import {observer} from "mobx-react"
import React from "react";

import {Toolbar} from "controls/react/layout/toolbar"
import {Section} from "controls/react/layout/section"
import {Button, IconButton} from "controls/react/form"
import FormEntry from "controls/react/form/formEntry"
import {AntCheckbox} from "controls/react/ant/antCheckbox"

import {linkModel} from "framework/mobx-integration";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {makeAutoObservable} from "mobx";
import {useMemoPermanent} from "framework/react-integration";
import {Severity} from "framework/entities/healthData";
import {SeverityIndicator} from "controls/react/severityIndicator";
import {debounce} from "lodash";
import {FormEntryNew} from "controls/react/form/formEntryNew";

const b = require('b_').with('metric-value-as-severity')

const i18n = require('core/localization/localization').translator({
  "Hide Metric Value": {
    "no": "Skjul metrisk verdi",
    "en": "Hide metric value"
  },
  "Value as Severity": {
    "no": "Verdi som alvorlighet",
    "en": "value as severity"
  },
  "metric-value-as-severity-tooltip": {
    "en": "If selected you can use metric value to custom set severity of shape. Severity will match first value that matches.",
    "no": "Hvis dette er valgt, kan du bruke metrikkverdien til å sette formens alvorlighetsgrad. Alvorlighetsgraden vil matche første verdi som samsvarer."
  },
  "To set severity for the values above set the below numbers in the inputs. \n\nCurrent configuration: \n": {
    "no": "For å angi alvorlighetsgrad for verdiene ovenfor, sett inn verdier under. \n\nGjeldende konfigurasjon: \n"
  },
  "Everything above {0} will give a CRITICAL in the shape": {
    "no": "Alt over {0} vil gi en KRITISK i formen",
    "en": "Everything above {0} will give a CRITICAL in shape"
  },
  "Everything above {0} will give a OK in the shape": {
    "no": "Alt over {0} vil gi en OK i formen",
    "en": "Everything above {0} will give an OK in shape"
  },
  "Everything will give a CRITICAL in the shape": {
    "no": "Alt vil gi en KRITISK i formen",
    "en": "Everything will give a CRITICAL in shape"
  }
});

export type MetricValueToSeverityHolder = {
	metricValueAsSeverity: boolean
	hideMetricValue: boolean
	metricValueToSeverity: {
		operator: 'gte'|'lte',
		threshold: number
	}[]
}

export type MetricValueAsSeverityProps = {
	dataSource: MetricValueToSeverityHolder
}

class MetricValueAsSeverityStore{
	constructor(public dataSource: MetricValueToSeverityHolder) {
		makeAutoObservable(this)
	}

	get criticalSeverity() {
		return this.dataSource.metricValueToSeverity[2].threshold;
	}

	set criticalSeverity(value: number | undefined) {
		this.updateSeverityThreshold(2, value);
	}

	get majorSeverity() {
		return this.dataSource.metricValueToSeverity[1].threshold;
	}

	set majorSeverity(value: number | undefined) {
		this.updateSeverityThreshold(1, value);
	}

	get minorSeverity() {
		return this.dataSource.metricValueToSeverity[0].threshold;
	}

	set minorSeverity(value: number | undefined) {
		this.updateSeverityThreshold(0, value);
	}

	get operator(): string {
		return this.dataSource.metricValueToSeverity[0].operator;
	}

	set operator(value: 'lte' | 'gte') {
		this.dataSource.metricValueToSeverity.forEach(x => {
			x.operator = value
		});
	}

	flipOperator = () => {
		const operators = {'lte': 'gte', 'gte': 'lte'} as const

		this.dataSource.metricValueToSeverity.forEach(x => {
			x.operator = (operators[x.operator] ?? 'lte')
		});
	}

	private updateSeverityThreshold = debounce((index: 0 | 1 | 2, value: number | undefined) => {
		this.dataSource.metricValueToSeverity[index].threshold = value;
	}, 1000)

	get tooltip(){
		if (!this.dataSource.metricValueToSeverity) {
			return '';
		}

		const severityLevels = ['OK', 'Minor', 'Major', 'Critical']

		const severityMessages = [i18n('To set severity for the values above set the below numbers in the inputs. \n\nCurrent configuration: \n')];

		let first: number = null;
		let last: number = null;
		let operator = null;
		let previousThreshold: number = null;

		this.dataSource.metricValueToSeverity.forEach((severity, index) => {
			operator = severity.operator;

			if (!severity.threshold) {
				return;
			}

			last = severity.threshold;

			const severityName = i18n(severityLevels[index])

			if (first === null && operator === 'gte') {
				severityMessages.push(i18n('Everything above {0} will give a OK in the shape', severity.threshold));
				first = severity.threshold;
			} else {
				severityMessages.push(severityName + ' ' + '<' + ' ' + (operator === 'gte' ? previousThreshold: severity.threshold));
			}

			previousThreshold = severity.threshold;
		});

		if (last === null) {
			severityMessages.push(i18n('Everything will give a CRITICAL in the shape'))
		}
		else if (operator === 'gte') {
			severityMessages.push(i18n('Critical') + ' < ' + last)
		}
		else if (operator === 'lte') {
			severityMessages.push(i18n('Everything above {0} will give a CRITICAL in the shape', last))
		}

		return severityMessages.join('\n');
	}

	destroy(){

	}
}

export const MetricValueAsSeverity = observer((props: MetricValueAsSeverityProps) => {
	const store: MetricValueAsSeverityStore = useMemoPermanent(() => new MetricValueAsSeverityStore(props.dataSource), [props.dataSource])
	const dataSource = store?.dataSource;

	if(!store)
		return null

	return <Section title={i18n('Value as Severity')}
	                margin={"top"}
	                appearance={"frame-top-only"}
	                childrenPadding={true}
	                className={b()}>
		<Toolbar>
			<IconButton embedded={true}
			            iconName={'question-sign'}
			            title={i18n('metric-value-as-severity-tooltip') + ' ' + store.tooltip}/>
		</Toolbar>
		<FormEntry>
			<AntCheckbox {...linkModel(dataSource, "metricValueAsSeverity")}>{i18n('Enabled')}</AntCheckbox>
			{dataSource.metricValueAsSeverity &&
				<AntCheckbox {...linkModel(dataSource, 'hideMetricValue')}>{i18n('Hide Metric Value')}</AntCheckbox>
			}
		</FormEntry>

		{dataSource.metricValueAsSeverity && <>
			<FormEntry>
				<Button onClick={store.flipOperator} title={"Flip operator"}/>
			</FormEntry>
			<Section appearance={'none'} contentClass={b('indicators')} direction={'row'}>
				<SeverityIndicator severity={Severity.Critical} title={i18n('Critical')}/>
				<CompareOperator operator={dataSource.metricValueToSeverity[2].operator} />
				<FormEntryNew model={store} modelField={"criticalSeverity"}>
					<AntInputNumber size={'small'} />
				</FormEntryNew>
				<CompareOperator operator={store.operator} />
				<SeverityIndicator severity={Severity.Major} title={i18n('Major')}/>
				<CompareOperator operator={store.operator} />
				<FormEntryNew model={store} modelField={"majorSeverity"}>
					<AntInputNumber size={'small'} />
				</FormEntryNew>
				<CompareOperator operator={store.operator} />
				<SeverityIndicator severity={Severity.Minor} title={i18n('Minor')}/>
				<CompareOperator operator={store.operator} />
				<FormEntryNew model={store} modelField={"minorSeverity"}>
					<AntInputNumber size={'small'} />
				</FormEntryNew>
				<CompareOperator operator={store.operator} />
				<SeverityIndicator severity={Severity.None} title={i18n('OK')}/>
			</Section>
		</>}
	</Section>
})


const CompareOperator = observer((props: {operator: string}) => {
	return props.operator === 'lte' ? <span className={b('severity-operator')}>&lt;</span> : <span className={b('severity-operator')}>&gt;</span>
})
