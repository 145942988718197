import 'controls/chat/chat.less'

import {observer} from "mobx-react";
import React from "react";
import {Section} from "controls/react/layout/section";
import {AntTextArea} from "controls/react/ant/antInput";
import {AntModal, ModalPosition} from "controls/react/ant/antModal";
import type {ChatStore} from "controls/chat/chatStore";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {ChatHistory} from "controls/chat/chatHistory";
import {SendOutlined} from "@ant-design/icons";
import {AntButton} from "controls/react/ant/antButton";


const b = require('b_').with('chat')

const i18n = require('core/localization').translator({
	'Ceechat': {},
	'Ceeview queries': {
		no: 'Ceeview spørringer'
	},
});

type ChatProps = {
	onClose: () => void
	height: number
	width: number
	top: number
	left: number
	store: ChatStore
}

export const Chat = observer((props: ChatProps) => {
	const style = React.useMemo(() => {
		return {
			top: props.top,
			left: props.left
		}
	}, [props.top, props.left])

	return <AntModal footer={null}
	                 title={i18n('Ceechat')}
	                 width={props.width}
	                 height={props.height}
	                 open={true}
	                 resizable={true}
	                 style={style}
	                 positionType={ModalPosition.Custom}
	                 onCancel={props.onClose}>
		<Section appearance={"none"} height={"fit"}>
			<ChatHistory chatEngine={props.store.chatEngine}/>
			<Section appearance={"none"}
			         contentPadding={true}
			         childrenPadding={true}>
				<div className={b('prompts')}>
					{props.store.prompts.map(x =>
						<div className={b('prompt-entry')}
						     onClick={() => props.store.chatEngine.send(x)}
						     key={x.value}>
							{x.text}
						</div>)}
				</div>

				<Section contentPadding={false}
				         childrenPadding={false}
				         appearance={"none"}
				         containerClass={b('message-section')}>
					<FormEntryNew label={i18n('Message')} model={props.store} modelField={"replyText"}>
						<AntTextArea onPressEnter={props.store.onPressEnter}
						             height={80}
						             className={b("reply-text")}/>
					</FormEntryNew>
					<AntButton icon={<SendOutlined />}
					           className={b('send-button')}
					           title={i18n('Generate')}
					           disabled={props.store.chatEngine.sending || !props.store.replyText}
					           onClick={props.store.send}
					           type={"primary"} />
				</Section>

			</Section>
		</Section>
	</AntModal>
})
