import Lang from "core/localization/lang";

const shortColumns = [
	{
		field: 'metricId',
		title: Lang.SELECTOR,
		template: function (data) {
			return `<input type="checkbox" metric-id=${data.metricId} class="cw_grid_check" />`
		},
		attributes: {
			'class': 'text_center'
		},
		headerAttributes: {
			'class': 'text_center'
		},
		sortable: false,
		width: 40,
		filterable: false,
	},
	{
		field: 'assetName',
		title: Lang.ASSET,
		editable: function() {
			return false;
		},
		width: 80,
	}, {
		field: 'categoryNode',
		title: Lang.NAME,
		editable: function() {
			return false;
		},
		width: 80,
	}, {
		field: 'registryType',
		title: Lang.TYPE,
		editable: function() {
			return false;
		},
		width: 80,
	}, {
		field: 'registryOwnerName',
		title: Lang.serviceBoard.SOURCENAME,
		editable: function() {
			return false;
		},
		width: 80,
	},{
		field: 'instanceName',
		title: Lang.serviceBoard.INSTANCE_NAME,
		editable: function() {
			return false;
		},
		width: 120
	}, {
		field: 'advanced',
		title: lang.ADVANCED,
		editable: function() {
			return false;
		},
		template: '<span class="cw_advanced_column">${advanced ? lang.CUSTOM : lang.NONE}</span>'
	}, {
		field: '',
		editable: function() {
			return false;
		},
		template: '<span class="cw_metric_item_delete pointer glyphicons bin"></span>',
		width: 30
	}
];

export function multigraphMetricSelectorGridColumns() {
	return shortColumns;
}
