import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Settings from 'settings';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";

export function SummaryWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {SummaryWidgetConfiguration as default}

jQuery.extend(SummaryWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="cw_field" id="cw_grid_summary_account"><label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_grid_summary_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div></div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" id="cw_summary_include_subaccounts_check" class="cw_show_column k-checkbox" />';
		html += '<label for="cw_summary_include_subaccounts_check" class="k-checkbox-label">' + lang.INCLUDE_SUBACCOUNTS + '</label>';
		html += '</div>';
		html += '<ul class="cw_list_columns">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head">' + lang.widget.COLUMN_VISIBILITY + '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_acc_agents" class="cw_show_column k-checkbox" /><label for="cw_acc_agents" class="k-checkbox-label">' + lang.AGENTS + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_acc_name" class="cw_show_column k-checkbox" /><label for="cw_acc_name" class="k-checkbox-label">' + lang.summary.NAME + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_acc_sla" class="cw_show_column k-checkbox" /><label for="cw_acc_sla" class="k-checkbox-label">' + lang.summary.SLA_STATUS + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_acc_asset" class="cw_show_column k-checkbox" /><label for="cw_acc_asset" class="k-checkbox-label">' + lang.summary.HEALTH_STATUS + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_health" class="cw_show_column k-checkbox" /><label for="cw_srv_health" class="k-checkbox-label">' + lang.summary.SERVICE_HEALTH + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_acc_incidents" class="cw_show_column k-checkbox" /><label for="cw_acc_incidents" class="k-checkbox-label">' + lang.service.INCIDENT + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_acc_state" class="cw_show_column k-checkbox" /><label for="cw_acc_state" class="k-checkbox-label">' + lang.summary.SERVICE_STATE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"></div>';
		html += '</li>';
		html += '</ul>';
		html +='<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + lang.TAGS + '</span>';
		html += '<div class="w100 left">';
		html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_untagged" class="k-checkbox" /><label for="cw_untagged" class="k-checkbox-label">' + lang.SHOW_UNTAGGED + '</label></div></div>';
		html += '<div class="left w100" id="cw_tags"></div>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		if (widgetObj.configuration.columns) {
			//on edit reset all and set only if in configuration
			$('.cw_show_column').prop('checked', false);

			if (widgetObj.configuration.columns.name && !widgetObj.configuration.columns.name.hidden
				|| widgetObj.configuration.columns.accountName && !widgetObj.configuration.columns.accountName.hidden) {
				$('#cw_acc_name').prop('checked', true);
			}
			if (widgetObj.configuration.columns.accStateIndex && !widgetObj.configuration.columns.accStateIndex.hidden) {
				$('#cw_acc_state').prop('checked', true);
			}
			if (widgetObj.configuration.columns.srvHealthIndex && !widgetObj.configuration.columns.srvHealthIndex.hidden) {
				$('#cw_srv_health').prop('checked', true);
			}
			if (widgetObj.configuration.columns.accSlaIndex && !widgetObj.configuration.columns.accSlaIndex.hidden) {
				$('#cw_acc_sla').prop('checked', true);
			}
			if (widgetObj.configuration.columns.accIncidents && !widgetObj.configuration.columns.accIncidents.hidden) {
				$('#cw_acc_incidents').prop('checked', true);
			}
			if (widgetObj.configuration.columns.accAssetHealthIndex && !widgetObj.configuration.columns.accAssetHealthIndex.hidden) {
				$('#cw_acc_asset').prop('checked', true);
			}
			if (widgetObj.configuration.columns.agentsState && !widgetObj.configuration.columns.agentsState.hidden) {
				$('#cw_acc_agents').prop('checked', true);
			}
		}

		$('#cw_summary_include_subaccounts_check').prop('checked', widgetObj.configuration.includeSubaccounts);

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();

		const requiredFields = ['#cw_grid_summary_accountid'];
		applyRequired(requiredFields);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_summary_include_subaccounts_check').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_summary_include_subaccounts_check').on('click', $.proxy(this.onIncludeSubaccountsToggle, this));
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.tagsList = this.widgetObj.configuration.tags;
		this.createAccountComponent({
			element: $('#cw_grid_summary_accountid'),
			container: $('#cw_grid_summary_account'),
			avoidDefaultAccount: true,
			events: {
				change: () => {
					this.tagsList = [];
					this.setTagsDataSource();
				},
				dataBound: () => {
					this.setTagsDataSource();
				}
			}
		});
	},

	setTagsDataSource: function () {
		let accountId = $('#cw_grid_summary_accountid').data('kendoComboBox').value();
		let includeSubaccounts = $('#cw_summary_include_subaccounts_check').is(':checked');

		ReactDOM.render(<FormEntry label={lang.TAGS} vertical={true}>
			<TagsSelect
				mode={'tags'}
				accountId={accountId}
				includeSubaccounts={includeSubaccounts}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>, $('#cw_tags').get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},

	onIncludeSubaccountsToggle: function () {
		this.tagsList = [];
		this.setTagsDataSource();
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var accNameCheck = $('#cw_acc_name'),
			accStateCheck = $('#cw_acc_state'),
			srvHealthCheck = $('#cw_srv_health'),
			accSlaCheck = $('#cw_acc_sla'),
			accIncidentCheck = $('#cw_acc_incidents'),
			accAgentsCheck = $('#cw_acc_agents'),
			accAssetCheck = $('#cw_acc_asset');
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'grid_summary',
			title: title || lang.home,
			configuration: {
				accountId: $('#cw_grid_summary_accountid').data('kendoComboBox').value(),
				includeSubaccounts: $('#cw_summary_include_subaccounts_check').is(':checked'),
				columns: {
					name: {
						hidden: accNameCheck.is(':not(:checked)')
					},
					accStateIndex: {
						hidden: accStateCheck.is(':not(:checked)')
					},
					srvHealthIndex: {
						hidden: srvHealthCheck.is(':not(:checked)')
					},
					accSlaIndex: {
						hidden: accSlaCheck.is(':not(:checked)')
					},
					accIncidents: {
						hidden: accIncidentCheck.is(':not(:checked)')
					},
					accAssetHealthIndex: {
						hidden: accAssetCheck.is(':not(:checked)')
					},
					agentsState: {
						hidden: accAgentsCheck.is(':not(:checked)')
					}
				},
				tags: this.tagsList,
				showUntagged: $('#cw_untagged').is(':checked')
			}
		};
		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		var accountCombo = $('#cw_grid_summary_accountid').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
