import './antConfirmModal.less';
import React, {FunctionComponent} from 'react';
import {AntModal, createContainer, ModalPosition, AntModalProps} from './antModal';
import {Section} from 'controls/react/layout/section';
import classnames from "classnames";
import {CloseCircleFilled, InfoCircleFilled, QuestionCircleFilled, WarningFilled} from '@ant-design/icons';
import {Observer} from "mobx-react";
import {runAsAsync} from "tools/utils";
import {State} from "tools/state";

const b = require('b_').with('ant-confirm-modal');
const i = require('core/localization').translator();

export type IconType = 'INFO' | 'WARNING' | 'ERROR' | 'QUESTION';

export interface AntConfirmModalProps {
	onCancel?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
	onOk: (e?: React.MouseEvent<HTMLButtonElement>) => Promise<boolean> | void;
	title: string;
	okText: string;
	icon: IconType;
	message: string;
	className?: string;
	positionType?: ModalPosition;
	draggableHandle?: string;
	positionAnchor?: React.MutableRefObject<HTMLElement>;
	defaultFooterButtons?: ('ok' | 'cancel')[]
}


export const AntConfirmModal: FunctionComponent<AntConfirmModalProps & AntModalProps> = props => {
	const {className, okText, title, onCancel, onOk, positionType, icon, message, ...others} = props;
	const classes = classnames(className ?? '', b());
	return <AntModal okText={okText}
					 title={title}
					 open={true}
					 onCancel={onCancel}
					 onOk={onOk}
					 positionType={positionType}
					 className={classes}
					 {...others}>
				<Section>
					<Icon icon={icon} /> {message}
				</Section>
			</AntModal>
}

const Icon: FunctionComponent<{icon: IconType}> = (props: {icon: IconType}) => {
	const mod: {[id: string]: boolean} = {};
	mod[props.icon.toLowerCase()] = true;
	switch(props.icon) {
		case 'INFO': {
			return <InfoCircleFilled className={b('icon', mod)} />
		}
		case 'QUESTION': {
			return <QuestionCircleFilled className={b('icon', mod)} />
		}
		case 'WARNING': {
			return <WarningFilled className={b('icon', mod)} />
		}
		case 'ERROR': {
			return <CloseCircleFilled className={b('icon', mod)} />
		}
	}
	return null;
}

export function openConfirmMessage(props: (() => AntConfirmModalProps) | AntConfirmModalProps) {
	return new Promise<boolean>((resolve) => {
		const [root] = createContainer();

		root.render(<>
			<Observer>{() => {
				const getProps = (p: (() => AntConfirmModalProps) | AntConfirmModalProps) => {
					if (typeof p == 'function') {
						return p();
					}
					return p;
				};
				const {onCancel, onOk, ...restProps} = getProps(props);

				const onCancelWrapper = (e: React.MouseEvent<HTMLButtonElement>) => {
					root.unmount();
					onCancel?.();
					resolve(false);
				}

				const onOkWrapper = async (e: React.MouseEvent<HTMLButtonElement>) => {
					root.unmount();
					if (onOk) {
						try {
							const result = await runAsAsync(onOk, [e]);
							resolve(result);
							return result;
						} catch {
							resolve(false);
						}
					}
					return false;
				}
				return <AntConfirmModal onOk={onOkWrapper}
								 onCancel={onCancelWrapper}
								 {...restProps} />
			}}</Observer>
		</>)

		State.mainApp.registerForOnNavigate(() => {
			root.unmount();
			resolve(false);
		});
	});
}


export const antModal = {
	info: (message: string, onOk?: () => void) => {
		onOk ??= () => {};
		return openConfirmMessage({
			positionType: ModalPosition.Centered,
			okText: i('Ok'),
			title: i('Info'),
			message: message,
			icon: 'INFO',
			defaultFooterButtons: ['ok'],
			onOk:onOk
		});
	}
}
