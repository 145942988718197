import './dataSourceEditor.less';

import React from 'react'
import {observer} from "mobx-react"

import {designerLocalizations} from 'areas/dashboards/graph-editor-extensions/designerLocalizations'
import {Section} from 'controls/react/layout/section'
import {getEntityDataSource} from 'areas/dashboards/graph-editor-extensions/datasources/entityDataSource'
import {AntSelect} from 'controls/react/ant/antSelect'
import {DesignerStore} from "controls/designer/designerStore"
import {ServiceDataSourceEditor} from "controls/designer/dataSourcesManager/serviceDataSourceEditor"
import {CellDataSourceType} from "controls/designer/dataSourcesManager/cellDataSourceType"
import {KpiDataSourceEditor} from "controls/designer/dataSourcesManager/kpiDataSourceEditor"
import {SlaDataSourceEditor} from "controls/designer/dataSourcesManager/slaDataSourceEditor"
import {AssetDataSourceEditor} from "controls/designer/dataSourcesManager/assetDataSourceEditor"
import {AssetGroupDataSourceEditor} from "controls/designer/dataSourcesManager/assetGroupDataSourceEditor"
import {MetricDataSourceEditor} from "controls/designer/dataSourcesManager/metricDataSourceEditor"

import {CostDataSourceEditor} from "controls/designer/dataSourcesManager/costDataSourceEditor"
import {RedirectConfigEditor} from "controls/designer/features/redirectOnClick/redirectConfigEditor"
import {
	DataSourceEditorContext
} from "controls/designer/dataSourcesManager/dataSourceEditorContext";
import {FormEntryNew} from "controls/react/form/formEntryNew"

const i18n = require('core/localization/localization').translator({
  "This will remove the current datasource selection type": {
    "no": "Dette vil fjerne gjeldende datakilde"
  },
  "Custom link": {
    "no": "Tilpasset link",
    "en": "Custom Link"
  },
  "Custom link tooltip": {
    "en": "Setting custom url will override the link to the datasource.",
    "no": "Om du angir en tilpasset url vil det overstyre lenken til datakilden."
  },
  "Show unit": {
    "no": "Vis enhet",
    "en": "Show unit"
  },
  "Open custom link in a new tab": {
    "no": "Åpne tilpasset link i ny fane"
  },
  "Metric value as severity": {
    "no": "Metrikkverdi som alvorlighet",
    "en": "Metric value as severity"
  },
  "Value to severity": {
    "no": "Verdi til alvorlighet",
    "en": "Value to severity"
  },
  "Total Metric": {
    "en": "Total Metric",
    "no": "Total metrikk"
  },
  "User Input": {
    "no": "Bruker inndata",
    "en": "User input"
  },
  "Display unit": {
    "no": "Visningsenhet",
    "en": "Display unit"
  },
  "Display label": {
    "no": "Visningsnavn"
  },
  "none-total-metric-tooltip": {
    "en": "Metric is displayed as normal",
    "no": "Metrikker vises som normalt"
  },
  "metric-total-metric-tooltip": {
    "en": "You can select a second metric. Second metric will be placed next to metric selected above.",
    "no": "Du kan velge en ekstra metrikk. Denne metrikk plasseres ved siden av den første metrikken."
  },
  "user_input-total-metric-tooltip": {
    "en": "You can input a string. The string will be placed next to the first metric. Eg. 100%.",
    "no": "Du kan legge inn en streng, den blir plassert ved siden av den første metrikken. F.eks. 100%."
  },
  "NONE-display-unit-tooltip": {
    "en": "We dont display unit",
    "no": "Vi viser ikke enhet"
  },
  "METRIC_UNIT-display-unit-tooltip": {
    "en": "We use the unit of the selected metric",
    "no": "Vi bruker enheten til den valgte metrikken."
  },
  "CUSTOM_UNIT-display-unit-tooltip": {
    "en": "You can input a custom unit to be displayed",
    "no": "Du kan legge inn en tilpasset enhet."
  },
  "TOTAL_UNIT-display-unit-tooltip": {
    "en": "Use unit of total metric.",
    "no": "Bruk enheten til total metrikk."
  },
  "NONE-display-label-tooltip": {
    "en": "No label is set. You can still add a custom label by double clicking shape.",
    "no": "Navn settes ikke. Du kan fortsatt legge til et navn ved å dobbeltklikke på formen."
  },
  "TOTAL_DISPLAY_LABEL-display-label-tooltip": {
    "en": "We use the name of total metric in shape.",
    "no": "Vi bruker navnet på total metrikk i formen."
  },
  "METRIC_DISPLAY_LABEL-display-label-tooltip": {
    "en": "We use the name of metric in shape.",
    "no": "Vi bruker navnet på metrikken i formen."
  },
  "decimal-tooltip": {
    "en": "Number of decimals we will display metrics with. If empty we display metrics as is.",
    "no": "Antall desimaler vi viser metrikken med. Hvis det er tomt, viser vi metrikken som den er."
  },
  "metric-value-as-severity-tooltip": {
    "en": "If selected you can use metric value to custom set severity of shape. The severity will match the first value that matches.",
    "no": "Hvis dette er valgt, kan du bruke metrikkverdien til å sette formens alvorlighetsgrad. Alvorlighetsgraden vil matche den første verdi som samsvarer."
  },
  "Show Health reasons": {
    "no": "Vis årsåker",
    "en": "Show health reasons"
  },
  "Show summary information": {
    "no": "Vis sammendragsinformasjon",
    "en": "Show summary information"
  },
  "Open link in a new tab": {
    "no": "Åpne link i ny fane"
  },
  "To set severity for the values above set the below numbers in the inputs. \n\nCurrent configuration: \n": {
    "no": "For å angi alvorlighetsgrad for verdiene ovenfor, sett inn verdier under. \n\nGjeldende konfigurasjon: \n"
  },
  "Everything will give a CRITICAL in the shape": {
    "no": "Alt vil gi en KRITISK i formen",
    "en": "Everything will give a CRITICAL in shape"
  },
  "Everything above": {
    "no": "Alt over"
  },
  "will give a CRITICAL in the shape": {
    "no": "Vil gi en KRITISK i formen",
    "en": "will give a CRITICAL in shape"
  },
  "Select metric placeholder": {
    "en": "Select a metric",
    "no": "Velg en metrikk"
  },
  "Custom unit": {
    "no": "Tilpasset enhet",
    "en": "Custom unit"
  },
  "Hide metric value": {
    "no": "Skjul metrisk verdi",
    "en": "Hide metric value"
  }
}, designerLocalizations);

const b = require('b_').with('data-source-properties');

export type DataSourceEditorProps = {
	store: DesignerStore
}

export const DataSourceEditor = observer((props: DataSourceEditorProps) => {
	const store = props.store

	React.useEffect(() => {
		if(store.cells.length == 1){
			store.getOrCreateCustomData(store.cell)
		}
	},[store.cell])

	if(store.cells.length > 1)
		return <div>There are several cells selected</div>

	if(store.cells.length == 0)
		return <div>No cells selected</div>

	const dataSource = store.dataSourcesManager.selected
	const redirectConfig = store.redirectConfigsManager.selected

	return <DataSourceEditorContext.Provider value={store}>
		<Section className={b()} childrenPadding={true}>
			{!store.isApplicationMode &&
				<FormEntryNew label={i18n('Datasource Type')}
				              model={store.dataSourcesManager}
				              modelField={"selectedDataSourceType"}
				              onChange={store.dataSourcesManager.changeDataSourceType}>
					<AntSelect options={getEntityDataSource()}/>
				</FormEntryNew>
			}

			{dataSource?.type == CellDataSourceType.Service
				&& <ServiceDataSourceEditor/>}

			{dataSource?.type == CellDataSourceType.Kpi
				&& <KpiDataSourceEditor/>}

			{dataSource?.type == CellDataSourceType.Sla
				&& <SlaDataSourceEditor/>}

			{dataSource?.type == CellDataSourceType.Asset
				&& <AssetDataSourceEditor/>}

			{dataSource?.type == CellDataSourceType.AssetGroup
				&& <AssetGroupDataSourceEditor/>}

			{dataSource?.type == CellDataSourceType.Metric
				&& <MetricDataSourceEditor />}

			{dataSource?.type == CellDataSourceType.Cost
				&& <CostDataSourceEditor />}

			{!store.isApplicationMode && redirectConfig && <RedirectConfigEditor config={redirectConfig} />}
		</Section>
	</DataSourceEditorContext.Provider>
})
