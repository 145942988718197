export enum CellDataSourceType {
	Asset = 'asset',
	AssetGroup = 'assetGroup',
	Service = 'service',
	Sla = 'sla',
	Metric = 'metric',
	Kpi = 'kpi',
	Cost = 'cost',
	None = 'none'
}
