import React from 'react'
import {observer} from "mobx-react"
import {Section} from "controls/react/layout/section"

import {AssetStateWidget} from './assetStateWidget'
import {ApplicationStateWidget} from './applicationStateWidget'
import {ServiceStateWidget} from './serviceStateWidget'
import State from 'tools/state'
import {HomeViewStore} from "areas/summary/homeViewSettings"
import {EventsSummaryPageArgs, SummaryRouter} from "areas/summary/bundleDescription"
import {EventStateGenericWidget} from 'areas/summary/events/eventStateGenericWidget'
import {Severity} from "framework/entities/healthData";
import {ApplicationsRouter} from "areas/application/bundleDescription";
import {ServicesRouter} from 'areas/services/bundleDescription';
import {SlaStateWidget} from "./slaStateWidget";
import {AssetsRouter} from "../assets/bundleDescription";
import {NavigationStore} from "../../framework/navigationStore";
import {SlaRouter} from "../sla/bundleDescription";

const i = require('core/localization').translator({
  "Service State": {
    "no": "Tjeneste",
    "en": "Service"
  },
  "Asset Health": {
    "no": "Ressurs",
    "en": "Asset"
  },
  "Application Health": {
    "no": "Applikasjon",
    "en": "Application"
  },
  "Service Level Agreement": {
    "no": "SLA",
    "en": "SLA"
  },
  "Total number of Events": {
    "no": "Totalt antall hendelser",
    "en": "Total number of events"
  },
  "Total number of Applications": {
    "no": "Totalt antall applikasjoner",
    "en": "Total number of applications"
  },
  "Total number of SLA": {
    "no": "Totalt antall SLA"
  },
  "Total number of Assets": {
    "no": "Totalt antall ressurser",
    "en": "Total number of assets"
  },
  "Total number of Services": {
    "no": "Totalt antall tjenester",
    "en": "Total number of services"
  },
  "Asset Warning message": {
    "en": "There are 1 or more assets that have a system warning. These warnings can be agent in error, agent in warning and monitor error. Click to see the affected assets.",
    "no": "Det er 1 eller flere ressurser som har en system advarsel. Disse advarslene kan vçre feil på agent, agent i advarsel og monitorfeil. Klikk for å se de berørte ressursene."
  },
  "There is at least one event that have a system warning": {
    "no": "Det er minst en hendelse som har et systemvarsel"
  },
  "Service Warning message": {
    "en": "There are 1 or more services that have a system warning. These warnings can be agent in error, agent in warning, agent/asset in maintenance and qualifier error. Click to see the affected services.",
    "no": "Det er 1 eller flere tjenester som har en systemadvarsel. Disse advarslene kan vçre feil på agent, agent i advarsel, agent/ressurs i vedlikeholds- og tjenestepunktfeil. Klikk for å se de berørte tjenestene."
  }
});

const b = require('b_').with('home-view-widget')

export type HomeViewWidgetsProps = {
	store: HomeViewStore
}

export const HomeViewWidgets = observer( (props: HomeViewWidgetsProps) => {
	const store = props.store;

	const displayOptions = {
		showBar: store.get('onscreen_widget_show_bar')?.visible,
		displayLabels: store.get('onscreen_widget_show_label')?.visible,
		showBucket: store.get('onscreen_widget_show_bucket')?.visible,
	}

	return <>
		<div id="onscreen_service_state_box" className={b()}>
			<Section title={i('Service State')} hasFooter={true} contentPadding={false} height={"fit"}>
				<ServiceStateWidget summary={store.serviceSummary}
				                    onClick={redirectToService}
				                    warningIconMessage={i('Service Warning message')}
									displayOptions={displayOptions}/>
				<div className={b('footer')}
					 onClick={() => redirectToService()}
					 title={i('Total number of Services')}
				>{store.serviceStateTotal}</div>
			</Section>
		</div>
		<div id="onscreen_asset_health_box" className={b()}>
			<Section title={i('Asset Health')} hasFooter={true} contentPadding={false} height={"fit"}>
				<AssetStateWidget displayOptions={displayOptions} summary={store.assetSummary} onClick={redirectToAsset} warningIconMessage={i('Asset Warning message')} />
				<div className={b('footer')}
					 onClick={() => redirectToAsset()}
					 title={i('Total number of Assets')}
				>{store.assetStateTotal}</div>
			</Section>
		</div>
		<div id="onscreen_application_health_box" className={b()}>
			<Section title={i('Application Health')} hasFooter={true} contentPadding={false} height={"fit"}>
				<ApplicationStateWidget displayOptions={displayOptions} summary={store.applicationSummary} onClick={redirectToApplication} />
				<div className={b('footer')}
					 onClick={() => redirectToApplication()}
					 title={i('Total number of Applications')}>{store.applicationStateTotal}</div>
			</Section>

		</div>
		<div id="onscreen_sla_box" className={b()}>
			<Section title={i('Service Level Agreement')} hasFooter={true} contentPadding={false} height={"fit"}>
				<SlaStateWidget displayOptions={displayOptions} summary={store.slaSummary} onClick={redirectToSla}/>
				<div className={b('footer')}
					 onClick={() => redirectToSla()}
					 title={i('Total number of SLA')}>{store.slaStateTotal}</div>
			</Section>
		</div>
		<div id="onscreen_event_state_box" className={b()}>
			<Section title={i('Event State')} hasFooter={true} height={"fit"} contentPadding={false}>
				<EventStateGenericWidget displayOptions={displayOptions}
										 summary={store.eventsSummary}
										 onClick={redirectToEventSummary}
										 warningIconMessage={i('There is at least one event that have a system warning')}
				/>
				<div className={b('footer')}
				     onClick={() => redirectToEventSummary()}
				     title={i('Total number of Events')}>{store.eventsStateTotal}</div>
			</Section>
		</div>
	</>
})

const redirectToEventSummary = (severity?: Severity, isWarning?: boolean) => {
	let args: EventsSummaryPageArgs = {}

	if(severity){
		args.severity = [severity]
		if(isWarning){
			args.severity.push('WARNING')
		}
	}

	State.mainApp.navigate(SummaryRouter.events(args))
}

const redirectToApplication = (severity?: Severity) => {
	let stateFilter = '';
	switch(severity){
		case Severity.Critical: stateFilter = 'CRITICAL'; break;
		case Severity.Invalid: stateFilter = 'IDLE'; break;
		case Severity.Major: stateFilter = 'MAJOR'; break;
		case Severity.Minor: stateFilter = 'MINOR'; break;
		case Severity.None: stateFilter = 'NONE'; break;
	}
	let filterObj = {};
	if (stateFilter) {
		filterObj = {
			extra: {
				stateFilter: stateFilter,
				isView: true
			}
		};
	}
	State.mainApp.navigate(ApplicationsRouter.list(), filterObj);
}

const redirectToSla = (severity?: Severity) => {
	let indicator = '';
	switch(severity){
		case Severity.Critical: indicator = 'FAILED'; break;
		case Severity.Major: indicator = 'WARNING'; break;
		case Severity.None: indicator = 'OK'; break;
	}
	const options = {
		indicator: indicator,
		isView: true
	}
	NavigationStore.go(SlaRouter.list(options))
}

const redirectToAsset = (severity?: Severity) => {
	const healthIndexRangeType: string[] = [];
	const healthIndexMandatoryType: string[] = [];
	const addMonitorErrorFilter: boolean = false;
	switch(severity) {
		case Severity.Critical: healthIndexRangeType.push('CRITICAL'); break;
		case Severity.Invalid:
			healthIndexRangeType.push('MAINTENANCE');
			healthIndexMandatoryType.push('IDLE'); break;
		case Severity.Major: healthIndexRangeType.push('MAJOR'); break;
		case Severity.None: healthIndexRangeType.push('OK'); break;
		case Severity.Minor: healthIndexRangeType.push('MINOR'); break;
	}

	const assetConfig = {
		source: 'buckets',
		healthIndexRangeType: healthIndexRangeType,
		healthIndexMandatoryType: healthIndexMandatoryType,
		addMonitorErrorFilter: addMonitorErrorFilter,
		includeSubaccounts: State.includeSubaccounts,
		isView: true
	};

	const url = AssetsRouter.assetHealthSummary(assetConfig)
	NavigationStore.go(url)
}

const redirectToService = (severity?: Severity) => {
	let serviceState: string = undefined

	switch(severity) {
		case Severity.Critical: serviceState = 'DOWN'
			break

		case Severity.Invalid: serviceState = 'UNAVAILABLE'
			break

		case Severity.Major: serviceState = 'WARNING'
			break

		case Severity.None: serviceState = 'UP'
			break
	}

	State.mainApp.navigate(ServicesRouter.list({
		serviceState: serviceState,
		showGridView: true,
	}));
}
