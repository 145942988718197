import './serviceSummaryWidgetConfiguration.less';
import React, {Component} from 'react';
import {makeAutoObservable, makeObservable, observable} from "mobx";
import {Section} from "controls/react/layout";
import {FormEntryNew, HelpIcon} from "controls/react/form/formEntryNew";
import {AntInput} from "controls/react/ant/antInput";
import {observer} from "mobx-react";
import {AntSelect} from "controls/react/ant/antSelect";
import {apiFetch, ApiRequest} from "framework/api";
import {DataListItem, getServicesByAccountIds, ServiceSummaryWidgetSettings} from "../api";
import {MobxManager, ModelValidator} from "framework/mobx-integration";
import {AccountsSelector} from "controls/react/accountSelector";
import {AntSwitch} from "controls/react/ant/antSwitch";
import {UnorderedListOutlined} from "@ant-design/icons";
import {AntButton} from "controls/react/ant/antButton";
import {showDataSourceWindow} from "controls/designer/dataSourcesManager/dataSourceWindow";
import {BoxView} from "controls/react/layout/boxView";
import {WidgetConfig, WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {serviceSummaryTranslator} from "./localizations";
import {BooleanKeys, KeysMatching} from "framework/typescript-integration";
import {ApplicationState} from "framework/applicationState";


const i = serviceSummaryTranslator;
export class ServiceSummaryWidgetConfig extends ServiceSummaryWidgetSettings implements WidgetConfig {
	title: string;
	accountId: string;
	id: string;
	type: string;

	static getPayload(config: ServiceSummaryWidgetConfig) {
		const data = {...config} as any
		const validKeys = Object.keys(new ServiceSummaryWidgetSettings());
		Object.keys(data).forEach(key => {
			if (validKeys.includes(key))
				return;

			delete data[key];
		});
		return data;
	}
}

class ServiceSummaryWidgetConfigurationModel {
	validator: ModelValidator<ServiceSummaryWidgetConfig>;
	mobx = new MobxManager();
	config: ServiceSummaryWidgetConfig

	constructor(props: WidgetConfigurationProps<ServiceSummaryWidgetConfig>) {
		this.config = props.config;
		makeObservable(this, {
			config: observable
		});

		this.validator = new ModelValidator(this.config);
		this.validator.required('title')
			.required('accountIds') // to show asterisk
			.add('accountIds', {callback: () => !!this.config.accountIds?.length}) // to make correct check
	}

	destroy() {
		this.validator.destroy();
		this.mobx.destroy();
	}
}

type DynamicFlag = BooleanKeys<ServiceSummaryWidgetConfig>
type CollectionField = KeysMatching<ServiceSummaryWidgetConfig, Array<string>>;

export class ServiceSummaryWidgetConfigurationStore {
	model: ServiceSummaryWidgetConfigurationModel;
	accounts: string[] = [];

	// this needs to make requests only when we select all account not for every
	allAccountSelected = () => {
		this.accounts = this.model.config.accountIds;
	}

	constructor(private props: WidgetConfigurationProps<ServiceSummaryWidgetConfig>) {
		this.model = new ServiceSummaryWidgetConfigurationModel(props);
		if (!this.model.config?.id) {
			this.model.config.accountIds = [ApplicationState.accountId];
		}
		if (this.model.config.accountIds) {
			this.allAccountSelected();
		}
		makeAutoObservable(this);
	}

	getDynamic = (key: DynamicFlag): boolean => {
		return this.model.config[key];
	}

	setDynamic = (key: DynamicFlag, value: boolean) => {
		this.model.config[key] = value;
	}

	clearValue = (key: CollectionField) => {
		this.model.config[key] = [];
	}

	getRequest = (accounts: string[], type: string) => {
		if (!accounts?.length) {
			return null;
		}
		return getServicesByAccountIds({accounts, type});
	}

	destroy() {
		this.model.destroy();
	}
}

export let ServiceSummaryWidgetConfigurationContext = React.createContext<ServiceSummaryWidgetConfigurationStore>(null)

export const ServiceSummaryWidgetConfiguration = observer(class ServiceSummaryWidgetConfiguration extends Component<WidgetConfigurationProps<ServiceSummaryWidgetConfig>> {
	store: ServiceSummaryWidgetConfigurationStore;

	constructor(props: WidgetConfigurationProps<ServiceSummaryWidgetConfig>) {
		super(props);
		this.store = new ServiceSummaryWidgetConfigurationStore(props);
	}

	componentWillUnmount() {
		this.store.destroy();
	}

	render() {
		return <ServiceSummaryWidgetConfigurationContext.Provider value={this.store}>
			<Section appearance={"none"}>
				<Section appearance={"frame-top-only"} title={i('General')} childrenPadding>
					<FormEntryNew label={i('Title')} model={this.store.model.config} modelField={'title'}>
						<AntInput/>
					</FormEntryNew>
				</Section>
				<Section appearance={"frame-top-only"} title={i('Data')} childrenPadding>
					<FormEntryNew label={i('Account')} model={this.store.model.config} modelField={'accountIds'}>
						<AccountsSelector onBlur={() => this.store.allAccountSelected()}/>
					</FormEntryNew>
					<DynamicItemSelector label={i('Service')} modelField={'serviceIds'} flagKey={'dynamicServices'}
										 dynamicText={i('Dynamic selection. All services from account is shown')}>
						<SelectorByAccounts accounts={this.store.accounts} request={this.store.getRequest} flagKey={'dynamicServices'}
											type={'services'} />
					</DynamicItemSelector>
					<DynamicItemSelector label={i('Service element')} modelField={'elementIds'}
										 flagKey={'dynamicElements'}
										 dynamicText={i('Dynamic selection. All serviceelement from service selection is shown')}>
						<SelectorByAccounts accounts={this.store.accounts} request={this.store.getRequest} flagKey={'dynamicElements'}
											type={'elements'}
											onlySelectedTooltip={i('When active only service element from selected services is shown')} />
					</DynamicItemSelector>
					<DynamicItemSelector label={i('Service qualifier')} modelField={'qualifierIds'}
										 flagKey={'dynamicQualifiers'}
										 dynamicText={i('Dynamic selection. All servicequalifiers from service and serviceelement is selection is shown')}>
						<SelectorByAccounts accounts={this.store.accounts} request={this.store.getRequest} flagKey={'dynamicQualifiers'}
											type={'qualifiers'}
											onlySelectedTooltip={i('When active only servicequalifier from selected services and/or service elements is shown.')} />
					</DynamicItemSelector>
				</Section>
			</Section>
		</ServiceSummaryWidgetConfigurationContext.Provider>;
	}
})

class DynamicItemSelectorProps {
	flagKey: DynamicFlag
	children: React.ReactNode
	label: string
	modelField: CollectionField
	dynamicText: string
}

const bSelector = require('b_').with('dynamic-item-selector')

const DynamicItemSelector = observer((props: DynamicItemSelectorProps) => {
	const store = React.useContext(ServiceSummaryWidgetConfigurationContext)
	const {children, flagKey, label, modelField} = props;
	return <FormEntryNew
		label={label}
		model={store.model.config}
		modelField={modelField}
		className={bSelector()}
		headerAdditionalContent={<div className={bSelector('extra')}>
			<label>{i('Dynamic')} <HelpIcon tooltip={i('HELPTEXT')}/></label>
			<AntSwitch
				value={store.getDynamic(flagKey)}
				onChange={val => {
					if (val) {
						store.clearValue(modelField)
					}
					store.setDynamic(flagKey, val)
				}}
				size={'small'}
			/>
		</div>}>
		{children}
		{store.getDynamic(flagKey) && <BoxView border type={"info-2"}>{props.dynamicText}</BoxView>}
	</FormEntryNew>
})


class ServiceSelectorProps {
	value?: string[];
	onChange?: (val: string[]) => void;
	dataList?: Array<{ id: string, name: string }>
	wrapper?: (children: React.ReactNode) => React.ReactNode
}

const ServiceSelector = (props: ServiceSelectorProps) => {
	return <AntSelect nameField={'name'}
					  valueField={'id'}
					  mode={'multiple'}
					  {...props}/>
}
const SelectorByAccounts = withTableSelector(ServiceSelector);
type SelectorByAccountsProps = Omit<ServiceSelectorProps, 'wrapper' | 'dataList'> & {
	accounts: string[],
	request: (accounts: string[], type: string) => ApiRequest<{ items: Array<DataListItem> }>,
	type: 'services' | 'elements' | 'qualifiers',
	onlySelectedTooltip?: string,
	flagKey?: DynamicFlag
};

const tsWrapper = require('b_').with('selector-with-table-wrapper')

export function withTableSelector(WrappedComponent: typeof ServiceSelector) {
	return observer(class extends React.Component<SelectorByAccountsProps, {dataSource: Array<DataListItem>}> {
		static contextType = ServiceSummaryWidgetConfigurationContext;
		declare context: React.ContextType<typeof ServiceSummaryWidgetConfigurationContext>
		getNameByType = (type: string, item: DataListItem) => {
			switch (type) {
				case 'services':
					return item.serviceName;
				case 'elements':
					return item.elementName;
				case 'qualifiers':
					return item.qualifierName;
			}
			return item.accountName;
		}

		constructor(props: SelectorByAccountsProps) {
			super(props, ServiceSummaryWidgetConfigurationContext)
			this.state = {
				dataSource: []
			}
		}

		async componentDidMount() {
			await this.getData();
		}

		async componentDidUpdate(prevProps: Readonly<SelectorByAccountsProps>, prevState: Readonly<{}>, snapshot?: any) {
			if (!this.props.accounts?.length || !!prevProps.accounts && JSON.stringify(prevProps.accounts) == JSON.stringify(this.props.accounts)) {
				return
			}
			await this.getData();
		}

		getData = async () => {
			const request = this.props.request(this.props.accounts, this.props.type);
			if (!request) {
				return;
			}
			const services = await apiFetch(request);
			if (services.success) {
				this.setState({dataSource: services.data.items.sort((a:{ id: string, name: string }, b: { id: string, name: string }) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)});
			}
		};

		showSelector = () => {
			const dataSource = this.state.dataSource
				.map(x => {
					const name = this.getNameByType(this.props.type, x);
					return {
						id: x.id,
						name: name,
						accountName: x.accountName,
						service: x.serviceName,
						element: x.elementName
					};
				});
			const columns = [{
				field: 'name',
				title: i('Name'),
				filterable: 'string' as const
			}, {
				field: 'accountName',
				title: i('Account'),
				filterable: 'string' as const
			}];
			if (dataSource.some(x => x.name != x.service && x.service)) {
				columns.push({
					field: 'service',
					title: i('Service'),
					filterable: 'string' as const
				});
			}
			if (dataSource.some(x => x.name != x.element && !!x.element)) {
				columns.push({
					field: 'element',
					title: i('Element'),
					filterable: 'string' as const
				});
			}
			showDataSourceWindow({
				title: i('Service'),
				selectedIds: this.props.value ?? [],
				data: dataSource,
				columns: columns,
				useOnlySelectedFilter: !!this.props.onlySelectedTooltip,
				onlySelectedHelpText: this.props.onlySelectedTooltip,
				onOk: (items) => {
					this.props.onChange?.(items.map(x => x.id));
				}
			})
		}

		wrapper = (children: React.ReactNode) => {
			const disabled = this.context.getDynamic(this.props.flagKey)
			return <Section appearance={"none"} containerClass={tsWrapper()} contentPadding={false}
							childrenPadding={false} direction={"row"}>
				{children}
				<AntButton icon={<UnorderedListOutlined/>}
						   onClick={this.showSelector}
						   title={i('Open grid')}
						   disabled={disabled}
				/>
			</Section>
		}

		render() {
			const {request, onlySelectedTooltip, ...props} = this.props; // this needs to fix warning unexpected attribute request on div element
			return <WrappedComponent wrapper={this.wrapper} dataList={this.state.dataSource} {...props}/>
		}
	});
}
