import React, { useState } from "react"
import { observer } from "mobx-react"
import { CostBudgetItem } from "../../models/costBudgetItem"
import { BudgetTableContext } from "./BudgetTableContext"
import { i } from "../../translations"
import { MinusOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons"

const b = require("b_").with("budget-table")

export interface ExpandCellProps<RecordType> {
	record: RecordType
}

export const ExpandCell = observer(({ record }: ExpandCellProps<CostBudgetItem>) => {
	const budgetTableStore = React.useContext(BudgetTableContext)
	const { expandedState } = budgetTableStore
	const expanded = expandedState.expanded(record)
	const expandable = expandedState.rowExpandable(record) && record.id != 'total'
	const [loading, setLoading] = useState(false)

	const onIconClick = async (e: React.MouseEvent<HTMLElement>) => {
		if(!expandedState.expanded(record) && record.hasChildren && record.items.length == 0) {
			setLoading(true)
			await budgetTableStore.loadItemChildren(record)
			setLoading(false)
		}
		expandedState.toggleRow(record)
	}

	const suffix = record.id == "total" ? ` ${i("all")}` : ""
	const iconPrefix = `ant-table-row-expand-icon`

	const label = (expanded ? i("Collapse") : i("Expand")) + suffix
	const icon = loading ? <LoadingOutlined/> :
		expanded ? <MinusOutlined /> : <PlusOutlined />
	return (
		<>
			{expandable && (
				<button
					type="button"
					onClick={(e) => {
						onIconClick(e!)
						e.stopPropagation()
					}}
					className={b("expand-button")}
					aria-label={label}
					title={label}
				>
					{icon}
				</button>
			)}
		</>
	)
})
