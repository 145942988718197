import React from "react";

import FormEntry from "controls/react/form/formEntry";
import {AntInput} from "controls/react/ant/antInput";
import {linkModel, ModelValidator, ValidatableModel} from "framework/mobx-integration";
import {makeAutoObservable, reaction} from "mobx";
import {Section} from "controls/react/layout/section";
import {observer} from "mobx-react";

import {sharedLocalization} from "vendor-init/tinymce/plugins/localization";
import {MultipleMetricsSelector} from "controls/react/metricsSelector";
import {DataListEntry} from "framework/entities/dataList";
import {AntSelect} from "controls/react/ant/antSelect";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {PeriodEditor} from "./periodEditor";

const i = require('core/localization').translator(sharedLocalization)

export class MetricChartConfig implements ValidatableModel<MetricChartConfig>{
	title: string;
	selector: string = 'period';
	aggregateLevel: string = "auto";
	unit: string = "";
	series: {id: string}[] = [];
	showLabel: string = "";
	showLegend: boolean;
	chartType: string = "LINE";
	min: string = "";
	max: string = "";

	get seriesWrapper(){
		return this.series.map(x => x.id);
	}

	set seriesWrapper(value: string[]){
		this.series = value.map(x => ({
			id: x,
			name: ''
		}));
	}

	validator = new ModelValidator(this);

	constructor() {
		makeAutoObservable(this);

		this.validator
			.required("series")
	}
}

let chartTypes: DataListEntry<string>[] = null;
const getChartTypes = () => {
	if (chartTypes == null) {
		chartTypes = [
			{ id: 'LINE', name: 'Line' },
			{ id: 'LINE_3D', name: 'Line 3D' },
			{ id: 'BAR', name: 'Bar' },
			{ id: 'BAR_3D', name: 'Bar 3D'},
			{ id: 'BAR_STACKED', name: 'Bar Stacked' },
			{ id: 'BAR_STACKED_3d', name: 'Bar Stacked 3D' },
			{ id: 'PIE', name: 'Pie' },
			{ id: 'PIE_3D', name: 'Pie 3D'},
			{ id: 'AREA', name: 'Area' },
			{ id: 'AREA_STACKED', name: 'Area Stacked' },
			{ id: 'RING', name: 'Ring' },
		]
	}
	return chartTypes;
}

let aggregateLevels: DataListEntry<string>[] = null;
const getAggregateLevels = () => {
	if (aggregateLevels == null) {
		aggregateLevels = [{ id: 'auto', name: 'Automatic' },
			{ id: 'hour', name: 'Hour' },
			{ id: 'day', name: 'Day'},
			{ id: 'week', name: 'Week' },
			{ id: 'month', name: 'Month' }]
	}
	return aggregateLevels;
}

const MetricChartForm = observer((props: { config: MetricChartConfig }) => {
	return <Section childrenPadding={true}>
		<FormEntry label={i('Title')}>
			<AntInput {...linkModel(props.config, "title")}/>
		</FormEntry>
		<FormEntry label={i('Source')} model={props.config} modelField={"seriesWrapper"}>
			<MultipleMetricsSelector/>
		</FormEntry>
		<FormEntry label={i('Aggregate level')}>
			<AntSelect dataList={getAggregateLevels()} {...linkModel(props.config, "aggregateLevel")}/>
		</FormEntry>
		<PeriodEditor config={props.config}/>
		<FormEntry label={i('Unit')}>
			<AntInput {...linkModel(props.config, "unit")}/>
		</FormEntry>
		<FormEntry label={i('Show label')}>
			<AntCheckbox {...linkModel(props.config, "showLabel")}/>
		</FormEntry>
		<FormEntry label={i('Show legend')}>
			<AntCheckbox {...linkModel(props.config, "showLegend")}/>
		</FormEntry>
		<FormEntry label={i('Chart type')}>
			<AntSelect dataList={getChartTypes()} {...linkModel(props.config, "chartType")}/>
		</FormEntry>
		<FormEntry label={i('Min')}>
			<AntInput {...linkModel(props.config, "min")}/>
		</FormEntry>
		<FormEntry label={i('Max')}>
			<AntInput {...linkModel(props.config, "max")}/>
		</FormEntry>
	</Section>
});

export const metricChartComponentDescription = {
	id: 'ceeview/chart',
	title: i('Metric chart'),
	config: MetricChartConfig,
	form: MetricChartForm,
	preview: require('./metricChart.png'),
	defaultSize: {
		width: '300px',
		height: '150px'
	}
}
