import './severityIndicatorOverlay.less'

import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

import {HealthDataFlags, HealthDataHolder, healthDataToFlags} from "framework/entities/healthData";
import {State} from "tools/state";
import {AssetsRouter} from "areas/assets/bundleDescription";
import {AssetHealthResponseEntry} from "controls/designer/api";
import {Geotag} from "framework/entities/geotag";
import {Overlay, Map} from "ol";
import {fromLonLat} from "ol/proj";
import {SeverityIndicator, SeverityPillbox} from "controls/react/severityIndicator";
import {CeeviewNavigator} from "tools/ceeviewNavigator";

const b = require('b_').with('osm-severity-indicator-overlay');

export type SeverityIndicatorOverlayProps = {
	asset: AssetHealthResponseEntry
	showHealthIndex: boolean
	showAssetName: boolean
	map: Map
	geotag: Geotag
	healthData: HealthDataHolder
	disableContent?: boolean
	navigator: CeeviewNavigator
}

export class SeverityIndicatorOverlay{
	overlayContainer: HTMLDivElement;
	private assetNameContainer: HTMLDivElement;
	private severityIndicatorContainer: HTMLDivElement;
	private navigator: CeeviewNavigator

	private healthFlags: HealthDataFlags;
	private position: number[];
	private asset: AssetHealthResponseEntry;
	private showHealthIndex: boolean;
	private showAssetName: boolean;
	private disableContent: boolean

	private overlay: Overlay;
	private map: Map;

	constructor(props: SeverityIndicatorOverlayProps) {
		this.asset = props.asset;
		this.showHealthIndex = props.showHealthIndex;
		this.showAssetName = props.showAssetName;
		this.map = props.map
		this.disableContent = props.disableContent
		this.navigator = props.navigator

		this.createOverlay();

		this.updatePosition(props.geotag);
		this.updateHealthData(props.healthData);
	}

	private createOverlay() {
		this.overlayContainer = document.createElement("div");
		this.overlayContainer.className = b();
		$(this.overlayContainer).kendoTooltip({
			content: this.asset.assetName
		});

		this.overlay = new Overlay({
			element: this.overlayContainer,
			positioning: 'top-left',
			position: this.position
		});

		this.map.addOverlay(this.overlay);
	}

	updateHealthData(healthData: HealthDataHolder){
		this.healthFlags = healthDataToFlags(healthData);
		this.renderIndicator();
	}

	updatePosition(geotag: Geotag){
		this.position = fromLonLat([geotag.longitude, geotag.latitude]);
		this.overlay?.setPosition(this.position);
	}

	private renderIndicator(){
		if(this.severityIndicatorContainer == null) {
			this.severityIndicatorContainer = document.createElement("div");
			this.overlayContainer.appendChild(this.severityIndicatorContainer);
			this.severityIndicatorContainer.addEventListener('click', this.onClicked)
		}

		if(this.disableContent !== true) {
			if (this.showHealthIndex) {
				ReactDOM.render(<SeverityPillbox {...this.healthFlags}/>, this.severityIndicatorContainer);
			} else {
				ReactDOM.render(<SeverityIndicator {...this.healthFlags}/>, this.severityIndicatorContainer);
			}
		}

		if(this.showAssetName && this.assetNameContainer == null){
			this.assetNameContainer = document.createElement("div");
			this.assetNameContainer.className = b('asset-name');
			this.assetNameContainer.addEventListener('click', this.onClicked)
			this.assetNameContainer.innerHTML = this.asset.assetName;

			this.overlayContainer.appendChild(this.assetNameContainer);
		}
	}

	private onClicked = () => {
		this.navigator.go({
			url: AssetsRouter.details(this.asset.assetId)
		})
	}

	destroy() {
		if(this.severityIndicatorContainer != null){
			ReactDOM.unmountComponentAtNode(this.severityIndicatorContainer);
		}

		if (this.overlayContainer) {
			this.overlayContainer.parentElement.removeChild(this.overlayContainer);
			this.overlayContainer = null;
		}
	}
}
