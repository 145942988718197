import { App } from "antd"
import type { ModalStaticFunctions } from "antd/lib/modal/confirm"
import type { MessageInstance } from "antd/lib/message/interface"
import type { NotificationInstance } from "antd/lib/notification/interface"

let message: MessageInstance
let notification: NotificationInstance
let modal: Omit<ModalStaticFunctions, "warn">

export const AntAppWrapper = () : void => {
	const fn = App.useApp()
	message = fn.message
	notification = fn.notification
	modal = fn.modal
	return null
}

export { message, notification, modal }
