import CustomMetricConfiguration from './../customMetricWidgetConfigurationWrapper';
import WidgetReactContainer from './../../widgetReactContainer';
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import CustomMetricWidget from 'areas/service-boards/widgets/customMetrics/customMetricWidget';
import WidgetSettings from "areas/service-boards/widgets/widgetSettings";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: CustomMetricConfiguration,
		defaultConfig: {
			type: 'metric-custom',
			title: i('Custom') + ' - (' + i('Experimental') + ')',
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			},
			hideTimeSelector: false
		},
		widget: WidgetReactContainer,
		constructWidget: config => new WidgetReactContainer(CustomMetricWidget, WidgetSettings,
			config, 'custom_metric_widget__wrapper'),
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Metric') + '/' + i('Custom') + ' - (' + i('Experimental') + ')'
	}
}
