import { makeObservable, observable } from "mobx"
import {DataNode} from "antd/lib/tree"
import {sortBy} from "lodash"
import {LegacyDataNode} from "rc-tree-select/lib/interface"
import {flatTree} from "./tree"


export enum CheckStrategy {
	Free = 'FREE',
	Single = 'SINGLE',
	GroupAndChild = 'GROUP_AND_CHILD',
	DependOnLeaf = 'DEPEND_ON_LEAF'
}

export interface TreeNode<TData> extends DataNode {
	checked: boolean
	children?: TreeNode<TData>[]
	value: string
	label: string
	parent: TreeNode<TData>
	childrenLoaded: boolean
	data: TData
}

export interface TreeDataItem<T extends TreeDataItem<T>> {
	items: T[]
	id: string
	name: string
}

interface TreeStrategy<T extends TreeDataItem<T>> {
	checkNode: (node: TreeNode<T>) => void
	uncheckNode: (node: TreeNode<T>) => void
	childrenDefaults: (node: TreeNode<T>) => Partial<TreeNode<T>>
	setDisabled: (node: TreeNode<T>, value: boolean) => void
}

class FreeStrategy<T extends TreeDataItem<T>> implements TreeStrategy<T> {
	treeCache: TreeCache<T>
	constructor(treeCache: TreeCache<T>) {
		this.treeCache = treeCache
	}

	childrenDefaults = (node: TreeNode<T>) => {
		return {checked: false, disabled: false}
	}
	checkNode = (node: TreeNode<T>) => {}
	uncheckNode = (node: TreeNode<T>) => {}
	setDisabled = (node: TreeNode<T>, value: boolean) => {}
}

class GroupAndChildStrategy<T extends TreeDataItem<T>> extends FreeStrategy<T> {
	childrenDefaults = (node: TreeNode<T>) => {
		return {checked: node.checked, disabled: node.checked || node.disabled}
	}

	checkNode = (node: TreeNode<T>) => {
		this.treeCache.setDescendantsChecked(node, true)
		this.treeCache.treeMap.forEach(x => {
			if (x != node) {
				x.disabled = true
			}
		})
	}

	uncheckNode = (node: TreeNode<T>) => {
		this.treeCache.setDescendantsChecked(node, false)
		this.treeCache.treeMap.forEach(x => x.disabled = false)
	}

	setDisabled = (node: TreeNode<T>, value: boolean) => {
		this.treeCache.setDisabled(node.children || [], value)
	}
}

class SingleStrategy<T extends TreeDataItem<T>> extends FreeStrategy<T> {
	checkNode = (node: TreeNode<T>) => {
		this.treeCache.treeMap.forEach(x => {
			if (x != node) {
				x.disabled = true
			}
		})
	}

	uncheckNode = (node: TreeNode<T>) => {
		this.treeCache.treeMap.forEach(x => x.disabled = false)
	}

	childrenDefaults = (node: TreeNode<T>) => {
		return {checked: false, disabled: node.checked || node.disabled}
	}
}

class DependOnLeafStrategy<T extends TreeDataItem<T>> extends FreeStrategy<T> {
	uncheckNode = (node: TreeNode<T>) => {
		this.treeCache.setDescendantsChecked(node, false)
	}

	checkNode = (node: TreeNode<T>) => {
		if(!node.parent?.checked && node.isLeaf) {
			this.treeCache.changeToAncestor(node, node.parent)
		}
	}

	setDisabled = (node: TreeNode<T>, value: boolean) => {
		value && (node.checked = false)
		this.treeCache.setDisabled(node.children || [], value)
	}
}

export class TreeCache<T extends TreeDataItem<T>> {
	originalData: T[]
	originalMap: Map<string, T> = new Map()
	treeData: TreeNode<T>[] = []
	treeMap: Map<string | number, TreeNode<T>> = new Map()
	strategy: TreeStrategy<T>

	constructor(data: T[], checkStrategy: CheckStrategy = CheckStrategy.Free) {
		this.changeStrategy(checkStrategy)
		makeObservable(this, {
			treeData: observable
		})
		this.originalData = data
		flatTree(this.originalData, (x) => x.items).forEach((x) => {
			this.originalMap.set(x.id, x)
		})
		this.treeData = this.mapLevel(this.originalData)
		this.addToTreeMap(this.treeData)
	}

	buildStrategy(checkStrategy: CheckStrategy) {
		switch(checkStrategy) {
			case CheckStrategy.Free:
				return new FreeStrategy(this)
			case CheckStrategy.GroupAndChild:
				return new GroupAndChildStrategy(this)
			case CheckStrategy.Single:
				return new SingleStrategy(this)
			case CheckStrategy.DependOnLeaf:
				return new DependOnLeafStrategy(this)
		}
	}

	changeStrategy(checkStrategy: CheckStrategy) {
		this.strategy = this.buildStrategy(checkStrategy)
		this.treeMap.forEach(x => {
			x.checked = false
			x.disabled = false
		})
	}

	mapLevel(nodes: T[], parent: TreeNode<T> = null, defaults: Partial<{checked: boolean, disabled: boolean}> = {}) : TreeNode<T>[] {
		return sortBy(nodes, x => x.name).map(node => ({
			key: node.id,
			value: node.id,
			label: node.name,
			isLeaf: !(node.items || []).length,
			disabled: false,
			checked: false,
			children: null,
			childrenLoaded: false,
			parent,
			...defaults
		}))
	}

	loadChildren(node: LegacyDataNode | TreeNode<T>, defaults: Partial<{checked: boolean, disabled: boolean}> = null) {
		if(node.childrenLoaded) {
			return
		}

		const originalNode = this.originalMap.get(node.key as string)
		const treeNode = this.treeMap.get(node.key)

		const childProps = defaults || this.strategy.childrenDefaults(treeNode)
		treeNode.children = this.mapLevel(originalNode.items, treeNode, childProps)
		treeNode.childrenLoaded = true

		this.addToTreeMap(treeNode.children)
		this.treeData = [...this.treeData] // force reload
	}

	loadForSearch(value: string) {
		const ids = this.idsToLoadForSearch(this.originalData[0], value)
		this.loadNodes(this.treeData[0], ids)
	}

	idsToLoadForSearch(node: T, search: string) : string[] {
		let ids : string[] = []
		let added = false
		for(let child of (node.items || [])) {
			const childIds = this.idsToLoadForSearch(child, search)
			const needToAdd = childIds.length > 0 || child.name.toLowerCase().includes(search.toLowerCase())
			if(!added && needToAdd) {
				ids.push(node.id)
				added = true
			}
			//should be after we add node, to be sure that parent is loaded when we load child
			ids = ids.concat(childIds)
		}
		return ids
	}

	allNodeChildIds(node: TreeNode<T>) {
		const id = node.value
		const initialNode = this.originalMap.get(id)
		return flatTree(initialNode.items || [], x => x.items).map(x => x.id)
	}

	addToTreeMap(nodes: TreeNode<T>[]){
		nodes.forEach((x => {
			this.treeMap.set(x.key, x)
		}))
	}

	topCheckedNode(parentNode: TreeNode<T> = null) : TreeNode<T> {
		parentNode ||= this.treeData[0]
		if (parentNode.checked) {
			return parentNode
		}
		for(let child of parentNode.children || []) {
			const childTopCheckedNode = this.topCheckedNode(child)
			if(childTopCheckedNode) {
				return childTopCheckedNode
			}
		}
		return null
	}

	setChecked(values: string[]) {
		const checked: TreeNode<T>[] = []
		const unchecked: TreeNode<T>[] = []
		this.treeMap.forEach((x) => {
			if (values.includes(x.value)) {
				if (!x.checked) checked.push(x)
			} else {
				if(x.checked) unchecked.push(x)
			}
		})
		// we must do it after first iteration, to prevent overriding checked state of descendants
		checked.forEach(this.checkNode)
		unchecked.forEach(this.uncheckNode)
		this.treeData = [...this.treeData] // force reload
	}

	get checkedNodes() {
		const result: TreeNode<T>[] = []
		this.treeMap.forEach((x) => {
			if (x.checked) result.push(x)
		})
		return result
	}

	checkNode = (node: TreeNode<T>) => {
		if (node.checked || node.disabled) {
			return
		}
		node.checked = true
		this.strategy.checkNode(node)
	}

	changeToAncestor(node: TreeNode<T>, ancestor: TreeNode<T>, value = true) {
		let parent = node
		while(parent && parent != ancestor.parent) {
			parent.checked = value
			parent = parent.parent
		}
	}

	uncheckNode = (node: TreeNode<T>) => {
		if(!node.checked) {
			return
		}

		node.checked = false
		this.strategy.uncheckNode(node)
	}

	oneChildChecked = (node: TreeNode<T>) => {
		return node.children?.filter(x => x.checked).length == 1
	}

	anySiblingChecked = (node: TreeNode<T>) => {
		if (!node.parent) {
			return false
		}
		return node.parent.children.some(x => x.checked)
	}

	anyDescendantChecked = (node: TreeNode<T>) => {
		for(let child of (node.children || [])) {
			if (child.checked) return true
			if (this.anyDescendantChecked(child)) return true
		}
		return false
	}

	allDescendantsChecked = (node: TreeNode<T>) => {
		for(let child of (node.children || [])) {
			if (!child.checked) return false
			if (!this.allDescendantsChecked(child)) return false
		}
		return true
	}

	setDescendantsChecked(node: TreeNode<T>, value: boolean) {
		(node.children || []).forEach(x => {
			x.checked = value
			this.setDescendantsChecked(x, value)
		})
	}

	setDisabled(nodes: TreeNode<T>[], value: boolean) {
		nodes.forEach(x => {
			x.disabled = value
			this.strategy.setDisabled(x, value)
		})
	}

	disableSiblingsOfAncestors(node: TreeNode<T>) {
		let parent = node.parent
		while (parent.parent != null) {
			this.setDisabled(parent.parent.children.filter(x => x.value != parent.value), true)
			parent = parent.parent
		}
	}

	topCheckedAncestor(node: TreeNode<T>) {
		let parent = node.parent
		let top: TreeNode<T> = null
		while(parent) {
			if(parent.checked) {
				top = parent
			}
			parent = parent.parent
		}
		return top
	}

	nodeCheckedDescendants(node: TreeNode<T>) {
		let result: string[] = []
		if(node.isLeaf) {
			return []
		}
		//if node children are not loaded & node.checked we decide that all children are checked too
		if(!node.childrenLoaded) {
			if(this.strategy.childrenDefaults(node)?.checked) {
				const initialNode = this.originalMap.get(node.value)
				const children = flatTree(initialNode.items, x => x.items)
				return children.map(x => x.id)
			} else {
				return []
			}
		}

		node.children.forEach(x => {
			if(x.checked) {
				result.push(x.value)
			}
			result = result.concat(this.nodeCheckedDescendants(x))
		})
		return result
	}

	getOriginalNode(targetId: string) {
		return this.originalMap.get(targetId)
	}

	loadAndCheckNecessaryNodes(checkedIds: string[]) {
		const loadIds = this.nodesToLoadIds(this.originalData[0], checkedIds)
		this.loadAndCheckNode(this.treeData[0], loadIds, checkedIds)
	}

	get loadedNodesIds() {
		const result: string[] = []
		this.treeMap.forEach(x => {
			if (x.childrenLoaded) {
				result.push(x.value)
			}
		})
		return result
	}

	loadNodes(node: TreeNode<T>, loadIds: string[]) : boolean {
		if (node.isLeaf) {
			return
		}

		if(loadIds.includes(node.value)) {
			this.loadChildren(node)
			node.children.forEach(child => this.loadNodes(child, loadIds))
		}
	}

	loadAndCheckNode(node: TreeNode<T>, loadIds: string[], checkedIds: string[]) {
		if(checkedIds.includes(node.value)) {
			this.checkNode(node)
		}

		if (node.isLeaf) {
			return
		}

		if(loadIds.includes(node.value)) {
			this.loadChildren(node, {checked: false, disabled: false})
			node.children.forEach(child => this.loadAndCheckNode(child, loadIds, checkedIds))
		}
	}

	// we load node if it has meaningful checked state
	// if it has children with state that not the same as parent's state
	// if any descendant should be loaded
	nodesToLoadIds(node: T, checkedIds: string[]) : string[] {
		if (!(node.items || []).length) {
			return []
		}
		const isChecked = (x: T) => checkedIds.includes(x.id)
		const currentNodeIsChecked = isChecked(node)
		let result: string[] = []
		let nodeHasChildWithDifferentChecked = false
		// we don't need to load node if no any child checked
		// because we think that all this nodes selected and will selected when node loaded
		let anyChildChecked = false
		for (let child of node.items) {
			result = result.concat(this.nodesToLoadIds(child, checkedIds))

			const childChecked = isChecked(child)
			anyChildChecked ||= childChecked
			nodeHasChildWithDifferentChecked ||= currentNodeIsChecked != childChecked
		}

		if((nodeHasChildWithDifferentChecked && anyChildChecked) || result.length > 0) {
			result.push(node.id)
		}

		return result
	}

	get allCheckedIds() {
		const node = this.topCheckedNode()
		if(!node) {
			return [];
		}
		return [node.value, ...this.nodeCheckedDescendants(node)];
	}
}

// for debug purposes
// const printTree = (tree: TreeNode<any>[], level = 0) =>  {
// 	if(!tree || tree.length < 1) {
// 		return;
// 	}
// 	const tabs = Array.from(Array(level).keys()).map(x => "\t").join('');
// 	tree.forEach(x => {
// 		console.log(
// 			`${tabs}${x.label}, checked: %c${x.checked}%c, disabled: %c${x.disabled}`,
// 			`color:${x.checked ? 'green' : 'blue'}`,
// 			'color: inherit',
// 			`color:${x.disabled ? 'red' : 'green'}`
// 		);
// 		printTree(x.children, level+1);
// 	});
// }
