import Utils from 'tools/utils'
import Cookies from 'core/cookies'
import Widget from 'areas/service-boards/widget'
import Settings from 'settings'
import CustomNotification from 'controls/customNotification'
import RemoteEventsManager from 'core/remoteEventsManager'
import Highcharts from 'highcharts'
import Configuration from 'configuration';
import moment from 'moment';
import State from 'tools/state';
import Renderer from 'tools/renderer';
import {translator as i} from "core";
import {getLegendLabelFormatter, getTrendValueWidgetWrapper} from "vendor-init/hightcharts-intergation";
import {timePeriodToUrl} from "../../../controls/react/form/timePeriodSelector";

export function MultigraphWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}

	this.instanceConfiguration.timezone = this.instanceConfiguration.timezone === "<User Time>" ? Cookies.CeesoftTimezone : this.instanceConfiguration.timezone;
	this.subscriberId = this.id;
	this.initComponent();
};

export {MultigraphWidget as default}

jQuery.extend(MultigraphWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		if (!this.renderTo) {
			this.renderTo = $('#' + this.id).find('.cw_section_content');
		}

		this.hasTimeSelector = true;
		this.hasMissingData = true;
		this.hasRegression = true;

		this.customControls = {
			target: '#' + this.id,
			toggleClick: function (value) {
				if ($('#' + this.id).find('.k-i-toggle').length) {
					$('#' + this.id).find('.k-i-toggle').trigger('click');
				} else {
					$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
				}
			}
		};

		this.intervals = {};
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: this.renderTo,
		});
		this.getData();
	},
	/**
	 * Get chart data
	 */
	getData: function () {
		var url, width = this.renderTo.width() * 0.6, period;
		this.setTitleIfEmpty(i('Multigraph'));

		//width = Math.floor(86400000 / width);
		//width = Math.max(width, 300);
		this.roundRobinPoints = width;

		const urlParams = timePeriodToUrl(this.getPeriodIntervalConfig());
		url = this.requestPath + 'kpi/data?ignoreMissingData=' + this.instanceConfiguration.ignoreMissingData
			+ '&' + urlParams + '&maxSamples=' + width
			+ '&timeZone=' + this.instanceConfiguration.timezone;

		var data = this.instanceConfiguration.metrics || [];

		var widgetContentDiv;
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = this.renderTo;
		}

		widgetContentDiv.addClass('kpi-multigraphWidget')

		kendo.ui.progress(this.renderTo, true);
		this.createCustomControls();
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			if (result.success || result.data || Object.keys(result).length) {
				if (result.data && result.data.length) {
					this.render(result.data);
				} else if (result.length) {
					this.render(result);
				} else {
					if (this.onZoom) {
						kendo.ui.progress(widgetContentDiv, false);
						this.statusNotification.setOptions({
							message: lang.widget.messages.NO_ZOOM_AVAILABLE,
							status: 'error',
							style: 'left: 70px'
						}).show();
					} else {
						widgetContentDiv.empty().append('<div class="metric_chart"></div>');
						kendo.ui.progress(widgetContentDiv, false);
						this.statusNotification.setOptions({
							message: result.message,
							status: 'error'
						}).show();
					}
				}
			} else {
				if (!this.onZoom) {
					widgetContentDiv.empty().append('<div class="metric_chart"></div>');
				}
				kendo.ui.progress(widgetContentDiv, false);
				this.statusNotification.setOptions({
					message: result.message || lang.serviceBoard.messages.NO_DATA,
					status: 'error'
				}).show();
			}
		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	/*
	* Handler function for getting the serie color
	* @param {Integer} index Get the index of the metric color
	* */
	getColor: function (index) {
		return State.metricColors[index];
	},
	/*
	 * Handler function which renders the chart
	 */
	render: function (dataSet, noDataMessage) {
		noDataMessage = noDataMessage || '';
		var oThis = this, item, data, widgetContentDiv, zoom, seriesItem, name;
		var exporting = jQuery.extend(true, {}, Configuration.highcharts.exporting);
		if (this.onZoom) {
			exporting.enabled = true;
			exporting.buttons.popUpBtn = {
				onclick: $.proxy(function () {
					this.zoomStartDate = null;
					this.zoomEndDate = null;
					this.zoomPeriod = null;
					this.onZoom = false;
					this.getData();

					if (this.customControls && typeof this.customControls.zoom === 'function') {
						this.customControls.zoom.call(this, {
							startDate: this.instanceConfiguration.startDate,
							endDate: this.instanceConfiguration.endDate,
							period: this.instanceConfiguration.period
						});
					}
				}, this),
				align: 'left',
				width: 5,
				x: 5,
				y: 5,
				text: lang.RESET,
				theme: {
					'stroke-width': 1,
					stroke: '#aaa',
					fill: '#fff',
					r: 0,
					states: {
						hover: {
							fill: '#eee'
						},
						select: {
							fill: '#ccc'
						}
					}
				}
			}
		}
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = this.renderTo;
		}
		if (!widgetContentDiv.length) {
			// we have no container yet
			return;
		}
		widgetContentDiv.empty().append('<div class="metric_chart"></div>');
		this.data = [];
		var subscribers = [], name, fullName, accountName;
		for (var i = 0, length = dataSet.length; i < length; i++) {
			item = dataSet[i], data = item.data, seriesItem = {};

			fullName = this.generateMultigraphLabel(dataSet[i]);
			name = fullName.length > 60 ? fullName.substr(0, 57) + '...' : fullName;

			seriesItem.id = item.configurationId;

			seriesItem.name = name;
			seriesItem.fullName = fullName;
			seriesItem.regression = this.instanceConfiguration.showRegression;
			seriesItem.marker = {
				enabled: false
			};
			seriesItem.regressionSettings = {
				marker: {
					enabled: false
				},
				name: name,
				fullName: fullName,
				guid: Utils.guid(),
				type: 'linear',
				color: 'rgba(255,165,0, 1)'
			};
			seriesItem.color = this.getColor();
			seriesItem.visible = true;
			seriesItem.data = [];
			if (!seriesItem.name) {
				seriesItem.showInLegend = false;
			}
			for (var j = 0, lng = data.length; j < lng; j++) {
				seriesItem.data.push([data[j].time, data[j].value]);
			}

			seriesItem.dataGrouping = {
				approximation: this.instanceConfiguration.aggregationType || 'high'
			};

			this.data.push(seriesItem);

			subscribers.push({
				profileId: item.profileId,
				accountId: item.accountId,
				configurationId: item.configurationId
			});
		}

		this.subscribe(subscribers);

		// remove mask
		kendo.ui.progress(widgetContentDiv, false);
		widgetContentDiv.find('.metric_chart').empty();

		Highcharts.setOptions({
			global: {
				useUTC: true,
				timezoneOffset: -moment.tz(this.instanceConfiguration.timezone).utcOffset()
			}
		});

		let container = widgetContentDiv.find('.metric_chart');
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
			container.css('height', height);
		} else {
			height = widgetContentDiv.height();
		}

		let header = dashboardWindow.closest('.html-shape-container').find('.toolbar_appearance_section-header').first();
		let missingHeader = false;
		if (header.css('display') === 'none' || header.width() === -30) {
			missingHeader = true;
		}
		if (this.instanceConfiguration.timeSelectorPosition === 'BOTTOM') {
			height = height - 30;
			this.moveTimeSelectorBottom(missingHeader);
		} else if (this.instanceConfiguration.timeSelectorPosition === 'TOP' && missingHeader)  {
			height = height - 30;
			this.renderTo.css('margin-top', '35px');
		}

		this.chart = new Highcharts.Chart({
			chart: {
				renderTo: container[0],
				type: 'line',
				zoomType: 'x',
				backgroundColor: 'transparent',
				events: {
					selection: $.proxy(function (event) {
						if (event.xAxis) {
							this.onZoom = true;
							this.zoomStartDate = parseInt(event.xAxis[0].min, 10);
							this.zoomEndDate = parseInt(event.xAxis[0].max, 10);
							this.zoomPeriod = 'CUSTOM';

							this.unsubscribe();

							if (this.customControls && typeof this.customControls.zoom === 'function') {
								this.customControls.zoom.call(this, {
									startDate: this.instanceConfiguration.startDate,
									endDate: this.instanceConfiguration.endDate,
									period: this.instanceConfiguration.period
								});
							}
						}

						event.preventDefault();
						this.getData();
					}, this)
				},
				height: widgetContentDiv.height()
			},
			title: {
				text: ' '
			},
			legend: {
				title: {
					text: '',
					align: 'center'
				},
				x: 2,
				y: 20,
				floating: false,
				borderWidth: 0,
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				useHTML: true,
				itemDistance: 15,
				itemStyle: {
					fontSize: "10px"
				},
				style: {

					fontSize: "10px"
				},
				labelFormatter: function () {
					return getLegendLabelFormatter(this);
				}
			},
			credits: {
				enabled: false
			},
			exporting: exporting,
			xAxis: {
				type: 'datetime',
				labels: {
					staggerLines: 1
				},
				dateTimeLabelFormats: {
					millisecond: '%H:%M:%S',
					second: '%H:%M:%S',
					minute: '%H:%M',
					hour: '%H:%M',
					day: '%e. %b',
					week: '%e. %b',
					month: '%b \'%y',
					year: '%Y'
				},
				visible: this.instanceConfiguration.showXAxis
			},
			yAxis: {
				softMin: 0,
				minPadding: 0,
				title: {
					text: null
				},
				visible: this.instanceConfiguration.showYAxis
			},
			tooltip: {
				crosshairs: true,
				snap: 1,
				backgroundColor: 'white',
				borderWidth: 0,
				shadow:{
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				formatter: function (e) {
					const points = this.points ?? [this.point];
					if (points && points.some(x => !!x)) {
						var length = points.length;
						var v = '';
						for (var i = 0; i < length; i++) {
							v = (points[i].series.name ?? 'Value') + ': ' + this.y;
						}
						var s = Renderer.browserDateRenderer(this.x, 'datetime', '', oThis.configuration.timezone);

						if (oThis.configuration.showRegression) {
							const trindString = getTrendValueWidgetWrapper(this, oThis);
							if(trindString){
								v += '<br />' + trindString
							}
						}
						return s + '<br />' + v;
					}
					return '';
				}
			},
			plotOptions: {
				series: {
					marker: {
						enabled: false
					}
				},
				line: {
					events: {
						//click: $.proxy(this.onHealthHistoryPoint, this),
						//legendItemClick: $.proxy(this.onHealthHistoryLegend, this)
					}
				}
			},
			series: this.data
		});

		Highcharts.setOptions({
			global: {
				useUTC: false
			}
		});
	},
	/*
	 * handler for clicking a point
	 */
	onPointClick: function (e) {
		var timeStamp = e.point.options.x, serieName = e.point.series.name;
	},
	/*
	 * Handler for clicking legend
	 */
	onLegendClick: function (e) {
		var clickedLegend = e.target.name;
	},
	/**
	 * Called when a metric event is received
	 * @param {Object} data The event data object
	 */
	onEvent: function (data) {
		if (this.chart) {
			var doRoundRobin, dataSet;

			dataSet = this.chart.get(data.configurationId);

			doRoundRobin = true;
			if (dataSet.length < this.roundRobinPoints) {
				doRoundRobin = false;
			}

			dataSet.addPoint({
				x: data.time,
				y: data.value,
				marker: {
					enabled: true,
					radius: 4,
					states: {
						hover: {
							radius: 6
						}
					}
				}
			}, true, doRoundRobin);

			if (doRoundRobin) {
				this.data.splice(0, 1);
			}
		} else {
			this.getData();
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		kendo.ui.progress(this.renderTo, true);
		this.unsubscribe();
		this.getData();
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	/**
	 * Subscribes to qualifier emtrics events
	 * @param {Object} data
	 */
	subscribe: function (data) {
		//custom controls 'Period multi toggle' is subscribing with boolean parameter
		if (typeof data === 'boolean') {
			return;
		}

		var subscriptionObj = [{
			eventType: 'Kpi',
			filters: data
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
