import Api from 'tools/api';
import Settings from "settings";
import {ApiRequest, PagedList} from "framework/api";
import {GridStore} from "controls/grid/gridStore";
import DateTime from "controls/react/queryBuilder/types/dateTime";

class SlaApi {
	static getSlaUrl(accountId: string, id: string, update: boolean) {
		return Api.accountRoot(accountId) + `slas/${id}/?update=${update}`;
	}

	static getSlaLiteUrl(accountId: string) {
		return Api.accountRoot(accountId) + `slas/lite?includeSubaccounts=true`;
	}

	static getDynamicSearchUrl(includeSubaccounts: boolean, accountId: string) {
		return Api.accountRoot(accountId) + `slas/search/lite?includeSubaccounts=` + includeSubaccounts;
	}

	static list(accountId: string, cache: Record<string, any>) {
		return Api.fetch(
			SlaApi.getSlaLiteUrl(accountId),
			null,
			cache,
			'sla' + accountId
		);
	}

	static calculation(slaId: string, accountId: string, sessionId: string) {
		return Api.fetch(
			Api.sessionAndAccountRoot(accountId, sessionId) + 'slas/' + slaId + '/calculation'
		);
	}

	static getName(slaId: string, accountId: string, sessionId: string) {
		return Api.fetch(Api.sessionAndAccountRoot(accountId, sessionId) + 'slas/' + slaId + '/name');
	}

	static createExcludePeriod(accountContext: string, slaId: string, data: object) {
		const url = Settings.serverPath + 'accounts/' + accountContext + '/slas/' + slaId + '/excludePeriods';
		return Api.fetchPost(url, data);
	}
}

export {SlaApi};

export class SlaEntry{
	id: string
	name: string
	accountId: string
}

export type SearchSlasLiteArgs = {
	accountIds: string[]
	includeSubaccounts?: boolean
}

export function searchSlasLite(args: SearchSlasLiteArgs) {
	return new ApiRequest<PagedList<SlaEntry>>({
		method: 'POST',
		url: 'slas/search/lite',
		payload: {
			filter: {
				logic: 'or',
				filters: args.accountIds.map( x => ({field: 'accountId', operator: 'eq', value: x}))
			},
			page: 1,
			pageSize: 9999,
			skip: 0,
			sort: [{field: "name", dir: "asc"}],
			take: 9999
		},
		accountBased: true,
		subaccountsFilter: true,
		includeSubaccounts: args.includeSubaccounts
	})
}

export function startSlaRecalculation(store: GridStore<any>, startTime: Date ) {
	return store.getSelectionApiRequest({
		url: 'slas/recalculate',
		payload: {
			recalculateTime: startTime.getTime()
		}
	});
}

