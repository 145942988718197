import {ReasonsWidget} from "./reasonsWidget";
import {ReasonsWidgetConfiguration} from './reasonsWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: ReasonsWidgetConfiguration,
		defaultConfig: {
			type: 'reasons-grid',
			title: i('Health Reasons'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		minWidth: 800,
		widget: ReasonsWidget,
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Grid') + '/' + i('Health Reasons'),
	}
}
