import './severityIndicator.less';

import React from "react";
import { observer } from 'mobx-react';
import classnames from 'classnames';

import {HealthDataFlags, severityToText} from "framework/entities/healthData";
import { ExclamationOutlined, ToolFilled, ClockCircleOutlined} from '@ant-design/icons';


interface SeverityIndicatorProps extends Partial<HealthDataFlags>{
	className?: string
	disableTooltip?: boolean
	customIcon?: React.ReactNode
	onClick?: () => void
}

const b1 = require('b_').with('severity-indicator');
export const SeverityIndicator = observer((props: SeverityIndicatorProps) => {
	const classes = classnames(b1({
		severity: props.severity?.toLowerCase(),
		clickable: props.onClick != null
	}), props.className);

	return <div onClick={props.onClick} className={classes} title={props.disableTooltip === true ? null : props.title}>
		{props.exclamationMark && <ExclamationOutlined />}
		{props.wrench && <ToolFilled />}
		{props.clock && <ClockCircleOutlined />}
		{props.customIcon}
	</div>
})


const b2 = require('b_').with('severity-label');
export const SeverityLabel = observer((props: SeverityIndicatorProps) => {
	const {onClick, ...restProps} = props;

	return <div className={b2({clickable: onClick != null})} onClick={onClick}>
		<SeverityIndicator {...restProps}/>
		<div className={b2('label')}>{severityToText(props.severity)}</div>
	</div>
})


const b3 = require('b_').with('severity-pillbox');
export const SeverityPillbox = observer((props: SeverityIndicatorProps) => {
	const {onClick, ...restProps} = props;

	return <div className={b3({clickable: onClick != null})} onClick={onClick}>
		<SeverityIndicator className={b3('severity-indicator')} {...restProps}/>
		<div className={b3('content')}>{props.label}</div>
	</div>
});
