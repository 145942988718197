import {makeAutoObservable} from 'mobx';

class Configuration {
	installedMonitors = [];
	installedMonitorsNames = {};
	installedMonitorSettings = {};
	tagsEnabled = false;
	highcharts = {
		exporting: {
			buttons: {
				contextButton: {
					enabled: false
				},
				printButton: {
					enabled: false
				},
				exportButton: {
					enabled: false
				}
			},
			enabled: false
		}
	}

	constructor() {
		makeAutoObservable(this);
	}

	setMonitors(monitors) {
		monitors.forEach((monitor) => {
			this.installedMonitors.push(monitor.value);
			this.installedMonitorsNames[monitor.value] = monitor.text;
			this.installedMonitorSettings[monitor.value] = monitor;
		});
	}
};

const configuration = new Configuration();
export default configuration;
