import { observer } from "mobx-react"
import React from "react"

import {GridDataEntry} from "controls/grid/gridDataEntry"
import {ViewManagerProps} from "controls/grid/viewManager/viewManagerStore"
import {AntInput} from "controls/react/ant/antInput"
import {linkModel} from "framework/mobx-integration"


export const NameEditor = observer(<T extends GridDataEntry, >(props: ViewManagerProps<T>) => {
	if (!props.store.nameEditing)
		return null

	return <AntInput {...linkModel(props.store.gridStore.state.currentView, "name")}
	                 onPressEsc={props.store.cancelEditing}
	                 onPressEnter={props.store.confirmNameChange}
	                 onBlur={props.store.confirmNameChange}
	/>
})
