import React from "react";
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import {debounce} from 'lodash'

import {Cookies} from "core/cookies";
import {Section} from "controls/react/layout/section";
import {Toolbar} from "controls/react/layout/toolbar";
import {AntInput} from "controls/react/ant/antInput";
import {IconButton} from "controls/react/form/iconButton";
import {Grid, fullTextSearch, buildColumns} from "controls/react/kendoWrappers/grid";
import {addLinks} from "core/react/links";
import {
	processApiResponse,
	formatBudgetColumn,
	subscribe,
	getGridFilterable,
	showReasonsGrid,
	onGridDataBound,
	throttleLimit, editBudget, ResourceType
} from "./shared";
import {FormEntry} from "controls/react/form";
import {AccountDropDown} from "controls/react/dropdowns/accountDropDown";
import {AntSelect} from "controls/react/ant/antSelect";
import {MonitorsDropDown} from "areas/assets/monitors/cloud-billing/monitorsDropDown";
import {CloudBillingMonitorsApi} from "areas/assets/monitors/cloud-billing/api";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import Api from 'tools/api';
import ErrorHandler from 'core/errorHandler';
import {sharedLocalization} from "./shared";
import {sharedLocalization as widgetsSharedLocalization} from "controls/designer/features/widgets/localization";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {renderSeverityTag} from "tools/states";
import Utils from 'tools/utils'
import State from 'tools/state';
import {ColumnsVisibility} from "controls/designer/features/widgets/columnsVisibility";
import {getInstancesGridColumns} from "./instancesGridColumns";
import {AssetsRouter} from "areas/assets/bundleDescription";
import {EditBudgetWindow} from "controls/designer/features/widgets/cloudServices/editBudgetWindow"
import {getGridStateForSaving} from "controls/react/kendoWrappers/grid";
import {FilterResetButton} from "controls/react/form/filterResetButton";
import {formatNumber} from "tools/helpers/math";
import gridColumns from "tools/helpers/gridColumns";
import {
	getDefaultConfig, updateConfigToTheLattestFormat,
	WidgetConfigurationTemplate
} from "controls/designer/features/widgets/cloudServices/widgetConfigurationTemplate";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";

const i = require('core/localization').translator(sharedLocalization, widgetsSharedLocalization);

export function getWidgetDescription() {
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'cloud-services-instances-grid',
			title: i('Instances'),
			...getDefaultConfig(),
			hideAll: true,
			hideTotal: true,
			showHiSeverity: false,
			showBudgetSeverity: false,
			enableBudgetEditing: true,
			columns: getInstancesGridColumns().filter( x => x.field!='utilization').map( x => x.field),
		},
		minWidth: 800,
		widget: Widget,
		preProcessConfig: updateConfigToTheLattestFormat,
		fullTitle: i('Cloud') + '/' + i('Instances'),
	}
}

export const WidgetConfiguration = props => {
	const {configLink} = props;
	const allColumns = React.useMemo( () => getInstancesGridColumns(), []);

	return <WidgetConfigurationTemplate {...props}>
		<ColumnsVisibility
			columnsLink={configLink.get('columns')}
			allColumns={allColumns}
		/>
		<FormEntry label={i('HI severity')} width={'fit'}>
			<IconButton iconName={"question-sign"} title={i('Show health index for the asset')}/>
			<AntCheckbox valueLink={configLink.get('showHiSeverity')}/>
		</FormEntry>
		<FormEntry label={i('Budget Severity')} width={'fit'}>
			<IconButton iconName={"question-sign"} title={i('Show budget severity')}/>
			<AntCheckbox valueLink={configLink.get('showBudgetSeverity')}/>
		</FormEntry>
		<Section appearance={"frame"} title={i('Public mode')} childrenPadding={true}>
			<FormEntry label={i('Budget edit')} width={'fit'}>
				<IconButton iconName={"question-sign"} title={i('Budget edit tooltip')}/>
				<AntCheckbox valueLink={configLink.get('enableBudgetEditing')}/>
			</FormEntry>
		</Section>
	</WidgetConfigurationTemplate>
}

const b = require('b_').with('cloud-services-instances-widget');

@addLinks
class Widget extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			searchFilter: this.props.persistedState?.filter || {},
			gridSort: this.props.persistedState?.sort || [],
			searchValue: this.props.persistedState?.searchValue || '',
			gridHeight: 'auto'
		};

		this.gridDataSource = this.getDataSource();
		this.gridColumns = this.getGridColumns();

		this.availableSearchColumns = ['serviceName', 'subscription', 'instance', 'cloudTypeText', 'assetName', 'tags']
			.filter(x => props.config.columns.includes(x));

		this.updateSearchFilter = debounce(this.updateSearchFilter, 1000);
	}

	render() {
		const searchLink = this.linkState('searchValue')
			.changed(this.updateSearchFilter);

		const toolbarAtTheEnd = [
			<AntInput key={1} valueLink={searchLink} placeholder={i('Search...')}/>,
			<FilterResetButton key={2} onReset={() => this.onFilterReset()}></FilterResetButton>
		];

		return (
			<DefaultWidgetWrapper {...this.props} toolbarAtTheEnd={toolbarAtTheEnd}>
				<Section contentPadding={false} height={"fit"}>
					<Grid dataSource={this.gridDataSource}
					      scrollable={{
						      virtual: true
					      }}
					      height={this.state.gridHeight}
					      setHeightOnResize={true}
					      filter={this.state.searchFilter}
						  filterable={getGridFilterable(this)}
					      sortable={{mode: 'multiple'}}
						  sort={this.state.gridSort}
					      skipSelectorColumn={true}
					      fit={true}
					      resizable={true}
						  reorderable={true}
					      columns={this.gridColumns}
						  onRowClicked={this.onRowClicked}
						  dataBound={(e) => this.onGridDataBound(e)}
					      ref={grid => this.grid = grid}
					/>
				</Section>
			</DefaultWidgetWrapper>
		)
	}

	componentDidMount() {
		this.applyExpand();
		this.fixSearch();
	}

	componentDidUpdate() {
		this.applyExpand();
		this.fixSearch();
	}

	updateSearchFilter = () => {
		this.setState({
			searchFilter: fullTextSearch(
				this.availableSearchColumns,
				this.state.searchValue
			)
		});
	}

	applyExpand() {
		let scope = this;
		this.grid?.kendoGrid.wrapper.find('.k-grid-content table').off().on('click', function (e) {
			let selectedRow = $(e.target).closest('tr');
			let directCheck = true;
			scope.grid?.checkExpanded(selectedRow, directCheck);
		})
	}

	fixSearch() {
		// workaround for search interfering with potential text shape
		this.grid?.kendoGrid.wrapper.closest('g').find('.ant-input').on('click', function () {
			$(this).blur();
			$(this).focus();
		})
	}

	attachEditBudgetListener() {
		this.grid?.kendoGrid.wrapper.find('.edit-budget-container').off().on('click', async e => {
			const saved = await editBudget(this.grid.kendoGrid.dataSource, e,
				this.props.config.monitorId, this.props.config.cloudType);

			if(saved){
				this.throttledUpdate.flush();
			}
		});
	}

	onGridDataBound = (e) => {
		onGridDataBound(e);
		this.attachEditBudgetListener();
	}

	onFilterReset() {
		this.setState({
			searchFilter: {},
			gridSort: [],
			searchValue: ''
		});
	}

	formatNumber = value => {
		return formatNumber(value, this.props.config.decimalsNumber)
	}

	formatBudgetColumn = item => {
		return formatBudgetColumn(item, this.props);
	}

	onRowClicked = async (dataItem, e) => {
		this.reasonsWindowId = Utils.guid();
		let accountId = this.props.config.accountId;
		showReasonsGrid(dataItem, e, 'Instance HI', this.reasonsWindowId, accountId);
	}

	getGridColumns() {
		return buildColumns(getInstancesGridColumns(this),
			{
				enabledColumns: this.props.config.columns,
				gridColumnsLastSnapshot: this.props.persistedState?.columns,
				hiddenCallback: field => {
					return field == 'severity' && !this.props.config.showHiSeverity
						|| field == 'budgetSeverity' && !this.props.config.showBudgetSeverity
				}
			});
	}

	getGrid(){
		return this.grid.kendoGrid;
	}

	getDataSource() {
		let config = this.props.config;
		let cloudType = config.cloudType;

		return new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: () => {
						return `${Api.sessionRoot()}monitors/${cloudType}/instanceCost`
					},
					contentType: "application/json; charset=utf-8",
					type: "POST",
					dataType: "json",
					cache: false
				},
				parameterMap: (data, type) => {
					data.monitorId = config.monitorId;
					data.subscriptions = config.subscriptions;
					data.resources = config.resources;
					data.showHiSeverity = config.showHiSeverity;
					data.showBudgetSeverity = config.showBudgetSeverity;
					return kendo.stringify(data);
				},
			},
			requestStart(e) {
				$('.k-grid-filter .k-i-filter').removeClass('k-i-filter').addClass('k-i-arrowhead-s');

				//prevent double request generated by kendo/jquery
				if (this.requestInProgress) {
					e.preventDefault();
				}
				this.requestInProgress = true;
				setTimeout(() => {
					this.requestInProgress = false;
				}, 1000)
			},
			pageSize: 100,
			schema: {
				model: {
					fields: {
						estimate: {
							type: 'number'
						},
						monthToDate: {
							type: 'number'
						},
						monthlyBudget: {
							type: 'number'
						},
						lastMonth: {
							type: 'number'
						}
					}
				},
				data: (response) => {
					this.filterOptions = response.filterOptions;

					if (response.items) {
						if (!this.alreadySubscribed) {
							this.throttledUpdate = _.throttle(() => this.gridDataSource.read(), throttleLimit, {trailing: false});
							this.subscription = subscribe(response.items, this.props.config.monitorId, this.throttledUpdate);
							this.alreadySubscribed = true;
						}
						response.items.forEach(x => x.tags = x.tags.join(','))
						return response.items;
					} else if (response.success === false) {
						return [];
					}
				},
				total: function (response) {
					this.visibleItems = response.visible;
					this.totalItems = response.total;
					return response.visible;
				}
			},
			serverSorting: true,
			serverPaging: true,
			serverFiltering: true,
			error: ErrorHandler.kendoServerError
		})
	}

	componentWillUnmount() {
		this.subscription?.unsubscribe();

		if (this.reasonsWindowId) {
			ReactDOM.unmountComponentAtNode(document.getElementById(this.reasonsWindowId));
		}
	}

	onResize() {
		let wrapper = this.grid?.kendoGrid.wrapper;
		let gridContainerHeight = wrapper.closest('.section__content').height();
		this.setState({
			gridHeight: gridContainerHeight
		});
	}

	getStateForSaving() {
		return {
			...getGridStateForSaving(this.grid?.kendoGrid),
			searchValue: this.state.searchValue
		};
	}
}
