import Utils, {createDataSource} from 'tools/utils';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import RemoteEventsManager from 'core/remoteEventsManager';
import ErrorHandler from 'core/errorHandler';
import {ServicesRouter} from 'areas/services/bundleDescription';
import {createGroupByDropDown, postProcessAccountsData, HomeView} from "areas/views/homeView";
import {Renderer, State} from "tools";
import {translator, Cookies} from "core";
import {getServiceState} from 'controls/stateRenderer/serviceState';
import {getHealthStateHome} from 'controls/stateRenderer/healthState';
import {getSlaState} from 'controls/stateRenderer/slaState';
import {getAgentStateHome} from 'controls/stateRenderer/agentState';
import {getGridStateForSaving, updateHiddenColumns} from "controls/react/kendoWrappers/grid";
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import {SlaRouter} from "../../../sla/bundleDescription";
import {NavigationStore} from "../../../../framework/navigationStore";


import {RedirectModifier} from "tools/ceeviewNavigator";

const i = translator({
	'Group by': {no: 'Gruppering'}
});


export class SummaryWidget extends Widget  {

	constructor(config) {
		super(config)
		this.requestPath = Settings.serverPath;
		if (this.sessionId) {
			this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
		}

		this.hasConfiguration = true;
		this.instanceConfiguration.includeSubaccounts = this.instanceConfiguration.includeSubaccounts || false;
	}

	init(){
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		widgetContentDiv.parent().addClass('cw_section_summarygrid');
		this.gridId = Utils.guid();
		widgetContentDiv.empty().append('<div id="' + this.gridId + '" ></div>');
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		this.currentAccountsList = [];
		this.isEventDriven = false;
		this.initKendoComponents();
		this.subscribe();
		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));
		setTimeout(() => this.adjustSectionHeight(this.id), 200);
	}

	initKendoComponents() {
		var filterMessages = lang.grid.filter;

		var gridSort = this.instanceConfiguration.sort || this.persistedState?.sort || [{
			field: 'accStateIndex',
			dir: 'asc',
			compare: null
		}, {
			field: 'srvHealthIndex',
			dir: 'asc',
			compare: null
		}, {
			field: 'accSlaIndex',
			dir: 'asc',
			compare: null
		}];
		var gridFilter = this.instanceConfiguration.filter || this.persistedState?.filter;
		let savedColumns = this.getColumnsFromConfig();

		let groupByInput = $('<input/>');

		let window = $('#' + this.id).closest('.k-window');
		if (window.length) {
			//free mode
			window.find('.k-window-title').html(i('Group by') + ':&nbsp;&nbsp;')
			window.prepend(groupByInput);

		} else {
			//gridster
			$('.cw_section_title, .toolbar__entry.toolbar__entry_title', '#' + this.id)
				.html(i('Group by') + ':&nbsp;&nbsp;')
				.css('width', 'auto');
			$('.cw_section_titlebar, .after-title-placeholder', '#' + this.id)
				.append(groupByInput);
		}

		this.groupBy = createGroupByDropDown(groupByInput, this.persistedState?.groupBy, () => this.onGroupByChanged(), this.keepWidgetHeader, this.releaseWidgetHeader);

		if (window.length) {
			window.find('span.k-dropdown').addClass('cw_home_widget_selector');
			window.find('.k-dropdown-wrap').css('height', '27px');
		}

		this.accountsDataSource = createDataSource(
			this.getServicesFirstLevelUrl(), 'POST',
			{
				schema: {
					model: {
						id: 'id',
						fields: {
							srvHealthIndex: {
								type: 'number'
							},
							accAssetHealthIndex: {
								type: 'number'
							},
							accSlaIndex: {
								type: 'number'
							},
							accStateIndex: {
								type: 'number'
							},
							agentsState: {
								type: 'boolean'
							}
						}
					},
					parse: postProcessAccountsData
				},
				sort: gridSort,
				filter: gridFilter,
				error: ErrorHandler.kendoServerError
			},
			(data) => {
				data.showUntagged = this.configuration.showUntagged;

				let tags = this.configuration.tags;
				let tagsArray = [];
				if (tags) {
					for (let i = 0; i < tags.length; i++) {
						if (tags[i].name) {
							tagsArray.push(tags[i].name);
						} else if (typeof tags[i] === 'string') {
							tagsArray.push(tags[i]);
						}
					}
				}
				this.configuration.tags = tagsArray;
				data.tags = this.configuration.tags;

				return kendo.stringify(data);
			}
		);

		this.grid = $('#' + this.gridId).kendoCustomGrid({
			reorderable: true,
			scrollable: true,
			dataSource: this.accountsDataSource,
			resizable: true,
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			height: '100%',
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			detailInit: $.proxy(this.onAccountRowExpand, this),
			columns: Utils.rearrangeColumns([{
				field: 'name',
				title: lang.summary.NAME,
				template: (item) => Renderer.accountName(item.name, item.id),
				filterable: true,
				sortable: true,
				width: 240
			}, {
				field: 'accStateIndex',
				title: lang.summary.SERVICE_STATE,
				//template: '#= Renderer.summaryState(accStateIndex)#',
				template: item => getServiceState(item.accStateIndex),
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'accStateIndex', 3, this.accountsDataSource.sortNow);
					}, this)
				},
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.DOWN,
								value: 0
							}, {
								text: lang.WARNING,
								value: 1
							}, {
								text: lang.UP,
								value: 2
							}, {
								text: lang.UNAVAILABLE,
								value: 3
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							//template: '#=Renderer.summaryState(value, text)#'
							template: (item) => getServiceState(item.value, item.text)
						});
					}
				},
				attributes: {
					'class': 'cw_service_state text_center'
				},
				width: 120

			}, {
				field: 'srvHealthIndex',
				title: lang.summary.SERVICE_HEALTH,
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'srvHealthIndex', 6, this.accountsDataSource.sortNow);
					}, this)
				},
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.CRITICAL,
								value: 3
							}, {
								text: lang.MAJOR,
								value: 2
							}, {
								text: lang.MINOR,
								value: 1
							}, {
								text: lang.OK,
								value: 0
							}, {
								text: lang.UNAVAILABLE,
								value: 6
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							template: '<span class="cw_status_indicator pointer cw_status_widget_color cw_color#=Utils.severityToColor(data.value)#"></span><span>${data.text}</span>'
						});
					}
				},
				//template: '#= Renderer.summaryWorstCase(srvHealth, "cw_account_services")#',
				template: item => getHealthStateHome(item.srvHealth, "cw_account_services"),
				width: 140
			}, {
				field: 'accSlaIndex',
				title: lang.summary.SLA_STATUS,
				attributes: {
					'class': 'cw_account_sla text_center'
				},
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'accSlaIndex', 4, this.accountsDataSource.sortNow);
					}, this)
				},
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.service.IN_COMPLIANCE,
								icon: 'glyphicons status_icon circle-arrow-top pointer',
								value: 1
							}, {
								text: lang.WARNING,
								icon: 'glyphicons status_icon circle-arrow-right pointer',
								value: 2
							}, {
								text: lang.service.BREACHED,
								icon: 'glyphicons status_icon circle-arrow-down pointer',
								value: 3
							}, {
								text: lang.summary.NO_SLA_AVAILABLE,
								icon: 'cw_status_indicator cw_status_widget_color status_icon cw_color6',
								value: 4
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							template: '<span class="${data.icon}"></span> <span>${data.text}</span>'
						});
					}
				},
				//template: '#= Renderer.summarySla(accSla)#',
				template: item => getSlaState(item.accSla),
				width: 120
			}, {
				field: 'accIncidents',
				title: lang.service.INCIDENT,
				template: '# if (accIncidents) {#<a class="pointer cw_incidents_link" data-filter="ACCOUNT">#= accIncidents#</a># } else { # 0 # } #',
				attributes: {
					'class': 'text_center'
				},
				sortable: true,
				width: 120
			}, {
				field: 'accAssetHealthIndex',
				title: lang.summary.HEALTH_STATUS,
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'accAssetHealthIndex', 6, this.accountsDataSource.sortNow);
					}, this)
				},
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.CRITICAL,
								value: 3
							}, {
								text: lang.MAJOR,
								value: 2
							}, {
								text: lang.MINOR,
								value: 1
							}, {
								text: lang.OK,
								value: 0
							}, {
								text: lang.UNAVAILABLE,
								value: 6
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							template: '<span class="cw_status_indicator pointer cw_status_widget_color cw_color#=Utils.severityToColor(data.value)#"></span><span>${data.text}</span>'
						});
					}
				},
				//template: '#= Renderer.summaryWorstCase(accAssetHealth, "cw_account_assets")#',
				template: item => getHealthStateHome(item.accAssetHealth, "cw_account_assets"),
				width: 120
			}, {
				field: 'agentsState',
				title: lang.AGENTS,
				sortable: {
					compare: $.proxy(function (a, b) {
						if (a.agentsTotal === 0 && b.agentsTotal === 0) {
							return 0;
						}
						if (this.accountsDataSource.sortNow === 'asc') {
							if (a.agentsTotal === 0) {
								return 1;
							}
							if (b.agentsTotal === 0) {
								return -1;
							}
						} else {
							if (a.agentsTotal === 0) {
								return -1;
							}
							if (b.agentsTotal === 0) {
								return 1;
							}
						}
						if (a.agentsDown === b.agentsDown) {
							if (a.agentsTotal < b.agentsTotal) {
								return -1;
							}
							if (a.agentsTotal > b.agentsTotal) {
								return 1;
							}
							return 0;
						}
						return a.agentsDown - b.agentsDown;
					}, this)
				},
				filterable: true,
				attributes: {
					'class': 'text_center'
				},
				template: item => getAgentStateHome(item.agentsDown, item.agentsWarning, item.agentsTotal),
				width: 160
			}, {
				field: '',
				title: '',
				filterable: false
			}], savedColumns),
			columnMenu: true,
			detailExpand: $.proxy(this.saveAccountRowsState, this),
			detailCollapse: $.proxy(this.saveAccountRowsState, this),
			dataBound: $.proxy(this.onAccountsGridDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();

		setTimeout($.proxy(function () {
			$('#' + this.gridId).find('.k-grid-header-wrap th').first().empty().append('<span id="cw_collapse_services" class="cw_collapse_expand_toggle cw_groups_collapsed" title="' + lang.COLLAPSE_ALL + '"></span>');
			$('#cw_collapse_services').off().on('click', $.proxy(this.onGroupsToggle, this));
		}, this), 300);
	}

	getColumnsFromConfig() {
		let savedColumns = updateHiddenColumns(this.persistedState?.columns, this.instanceConfiguration.columns);

		//column name initially was 'accountName', now it is just 'name'
		if (savedColumns.accountName) {
			savedColumns.name = savedColumns.accountName;
			delete savedColumns.accountName;
		}

		if (!savedColumns.accIncidents) {
			savedColumns.accIncidents = {
				hidden: false
			};
		}

		return savedColumns;
	}

	keepWidgetHeader = () => {
		$(`#${this.id}`).addClass('preserve-widget-header');
	}
	releaseWidgetHeader = () => {
		$(`#${this.id}`).removeClass('preserve-widget-header');
	}

	onGroupsToggle(e) {
		let rowButtons = $('#' + this.gridId).find('.k-i-collapse');
		let length = rowButtons.length;
		for (let j = 0; j < length; j++) {
			$(rowButtons[j]).trigger('click');
		}
	}

	onAccountsGridDataBound() {
		if (!this.sessionId && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			$('.cw_account_assets, .cw_account_services').off().on('click', $.proxy(this.onAssetHealthClick, this));
			$('.cw_account_sla').off().on('click', '.status_icon', $.proxy(this.onSlaIndicatorClick, this));
			$('#' + this.gridId).off().on('click', '.k-detail-row .cw_grid_link', e => HomeView.prototype.onServiceLink.call(this, e) );
			$('#' + this.gridId).on('click', '.k-master-row .cw_grid_link', $.proxy(this.onAccountLink, this));
			$('#' + this.gridId).on('click', '.cw_incidents_link', $.proxy(this.onIncidentsLink, this));
			$('#' + this.gridId).on('click', '.cw_agent_status', $.proxy(this.onAgentStatusClick, this));
			$('#' + this.gridId).on('click', '.js_service_state', $.proxy(this.onGridServiceStateClick, this));
		}

		var dataSource = this.grid.dataSource;
		if (dataSource.filter()) {
			$('.cw_window_actions').find('.restart').removeClass('inactive');
		}
		if (dataSource.total() === 1) {
			this.grid.expandRow(this.grid.tbody.find("tr.k-master-row").first());
			setTimeout(function () {
				$('.k-detail-row').css('display', 'none');
			}, 30);

			setTimeout(function () {
				$('.k-detail-row').css('display', 'table-row');
			}, 60);
		}
		var accounts = [], length = this.accountsDataSource.total(), record;

		for (var i = 0; i < length; i++) {
			record = this.accountsDataSource.at(i);
			accounts.push(record.id);
		}
		if (!this.isDataSourceSubscribed) {
			this.currentAccountsList = accounts;
			//this.subscribe(); Commented out by Bogdan; subscription is done using accountId only.
		} else {
			if (!this.isEventDriven) {
				if (!Utils.areSimilarArrays(accounts, this.currentAccountsList)) {
					this.unsubscribeAndSubscribe();
				}
				this.currentAccountsList = accounts;
			} else {
				this.isEventDriven = false;
			}
		}

		let grid = this.grid;
		let rowsState = this.rowsState || [];
		this.rowsState = rowsState;
		this.skipRowsStateSaving = true;

		if (rowsState && rowsState.length) {
			for (var i = 0; i < rowsState.length; i++) {
				var model = grid.dataSource.get(rowsState[i].id);
				if (model) {
					if (rowsState[i].isExpanded) {
						grid.expandRow(grid.tbody.find('[data-uid=' + model.uid + ']'));
					} else {
						grid.collapseRow(grid.tbody.find('[data-uid=' + model.uid + ']'));
					}
				}
			}
		}
		this.skipRowsStateSaving = false;
	}

	saveAccountRowsState(e) {
		var scope = this;
		if (this.skipRowsStateSaving || this.skipRowsStateSaving === 'undefined') {
			return;
		}
		e.sender.tbody.find('.k-master-row').map(function (index, element) {
			var elementId = e.sender.dataItem($(element).closest('tr')).id;
			if (scope.rowsState && scope.rowsState.length) {
				for (var i = 0; i < scope.rowsState.length; i++) {
					if (elementId === scope.rowsState[i].id) {
						scope.rowsState.splice(i, 1);
					}
				}
			}
			if (scope.rowsState) {
				scope.rowsState.push({
					id: elementId,
					isExpanded: $(element).find('.k-hierarchy-cell .k-icon').hasClass('k-i-collapse')
				});
			}
		});
	}

	onAccountRowExpand(e) {
		var filterMessages = lang.grid.filter;
		this.expandedDivId = Utils.guid();
		let uid = e.masterRow.data('uid');
		let scope = this;
		this.servicesGrid = $('<div id="' + this.expandedDivId + '" class="cw_summary_inner_grid"></div>').appendTo(e.detailCell).kendoCustomGrid({
			dataSource: createDataSource(this.getServicesSecondLevelUrl(this.accountsDataSource.getByUid(uid)),
				this.groupBy.value() == 'accounts' ? 'POST' : 'GET',
				{
					schema: {
						model: {
							id: 'srvId',
							fields: {
								srvStateIndex: {
									type: 'number'
								},
								srvHealthIndex: {
									type: 'number'
								},
								srvSlaIndex: {
									type: 'number'
								},
								srvIncidents: {
									type: 'number'
								},
								coverage: {
									type: 'number'
								},
								stateDuration: {
									type: 'number'
								}
							}
						}
					},
					change: $.proxy(function (e) {
						if (e.items.length === 0) {
							if ($('#' + this.expandedDivId).next().length) {
								$('#' + this.expandedDivId).next().remove();
							}
							$('#' + this.expandedDivId).addClass('hide');
							$('#' + this.expandedDivId).after('<div class="cw_excerpt"><p>' + lang.summary.messages.NO_SERVICES + '</p></div>');
						} else {
							if ($('#' + this.expandedDivId).next().length) {
								$('#' + this.expandedDivId).next().remove();
							}
							$('#' + this.expandedDivId).removeClass('hide');
						}
					}, this),
					error: ErrorHandler.kendoServerError
				}, (data) => {
					if (this.groupBy.value() != 'accounts')
						return null;

					data.showUntagged = this.configuration.showUntagged;
					data.tags = this.configuration.tags;
					return kendo.stringify(data);
				}),
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			scrollable: true,
			resizeable: false,
			columns: [{
				field: 'srvName',
				title: lang.NAME,
				menu: false,
				width: 233,
				template: '<a class="cw_grid_link">#= srvName#</a>',
				filterable: true,
				sortable: true
			}, {
				field: 'srvStateIndex',
				title: lang.summary.SERVICE_STATE,
				//template: '#= Renderer.summaryState(srvStateIndex, "", operatingState)#',
				template: item => getServiceState(item.srvStateIndex, '', item.operatingState),
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'srvStateIndex', 3, this.data('kendoCustomGrid').dataSource.sortNow);
					}, $('#' + this.expandedDivId))
				},
				menu: false,
				width: 120,
				attributes: {
					'class': 'text_center js_srv_state_index'
				},
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.DOWN,
								value: 0
							}, {
								text: lang.WARNING,
								value: 1
							}, {
								text: lang.UP,
								value: 2
							}, {
								text: lang.UNAVAILABLE,
								value: 3
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							//template: '#=Renderer.summaryState(value, text)#'
							template: (item) => getServiceState(item.value, item.text)
						});
					}
				}
			}, {
				field: 'srvHealthIndex',
				title: lang.summary.SERVICE_HEALTH,
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'srvHealthIndex', 6, this.data('kendoCustomGrid').dataSource.sortNow);
					}, $('#' + this.expandedDivId))
				},
				menu: false,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.CRITICAL,
								value: 3
							}, {
								text: lang.MAJOR,
								value: 2
							}, {
								text: lang.MINOR,
								value: 1
							}, {
								text: lang.OK,
								value: 0
							}, {
								text: lang.UNAVAILABLE,
								value: 6
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							template: '<span class="cw_status_indicator pointer cw_status_widget_color cw_color#=Utils.severityToColor(data.value)#"></span><span>${data.text}</span>'
						});
					}
				},
				//template: '#= Renderer.summaryWorstCase(srvHealth, "cw_service_assets")#',
				template: item => getHealthStateHome(item.srvHealth, "cw_service_assets"),
				width: 140
			}, {
				field: 'srvSlaIndex',
				title: lang.summary.SLA_STATUS,
				//template: '#= Renderer.summarySla(srvSla)#',
				template: item => getSlaState(item.srvSla),
				menu: false,
				attributes: {
					'class': 'cw_service_sla text_center'
				},
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'srvSlaIndex', 4, this.data('kendoCustomGrid').dataSource.sortNow);
					}, $('#' + this.expandedDivId))
				},
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.FAILED,
								icon: 'glyphicons status_icon circle-arrow-down pointer',
								value: 3
							}, {
								text: lang.WARNING,
								icon: 'glyphicons status_icon circle-arrow-right pointer',
								value: 2
							}, {
								text: lang.OK,
								icon: 'glyphicons status_icon circle-arrow-top pointer',
								value: 1
							}, {
								text: lang.summary.NO_SLA_AVAILABLE,
								icon: 'cw_status_indicator cw_status_widget_color status_icon cw_color6',
								value: 4
							}],
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							dataTextField: 'text',
							dataValueField: 'value',
							change: Utils.onFilterDropDownChange,
							template: '<span class="${data.icon}"></span> <span>${data.text}</span>'
						});
					}
				},
				width: 120
			}, {
				field: 'srvIncidents',
				title: lang.service.INCIDENT,
				template: '# if (srvIncidents) {#<a class="pointer cw_incidents_link" data-filter="SERVICE">#= srvIncidents#</a># } else { # 0 # } #',
				attributes: {
					'class': 'text_center'
				},
				sortable: true,
				width: 120
			}, {
				field: 'coverage',
				title: lang.service.COVERAGE,
				sortable: true,
				filterable: true,
				attributes: {
					'class': 'text_center'
				},
				width: 120
			}, {
				field: 'stateDuration',
				title: lang.service.CURRENT_STATE,
				sortable: true,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: '1 ' + lang.HOUR,
								value: 3600000
							}, {
								text: '5 ' + lang.HOURS,
								value: 18000000
							}, {
								text: '10 ' + lang.HOURS,
								value: 36000000
							}, {
								text: '1 ' + lang.DAY,
								value: 84600000
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE
						});
					}
				},
				template: '#=Renderer.duration(stateDuration)#',
				width: 160
			}, {
				field: 'stateChangeTime',
				title: lang.service.STATE_CHANGE,
				template: '#=Renderer.browserDateRenderer(stateChangeTime, "datetime")#',
				sortable: true,
				filterable: false,
				width: 180
			}, {
				title: '&nbsp;',
				menu: false,
				sortable: false,
				filtrable: false
			}],
			columnMenu: true,
			dataBound: function (e) {
				scope.adjustNestedGridHeight(this.wrapper);
				if (!scope.sessionId) {
					$('.cw_service_assets').off().on('click', $.proxy(scope.onAssetHealthClick, scope));
					$('.cw_service_sla').off().on('click', '.status_icon', $.proxy(scope.onSlaIndicatorClick, scope));
				}
			}
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.servicesGrid);
	}

	onAgentStatusClick (e) {
		var target = $(e.currentTarget), next = false;

		if (target.find('span.glyphicons.status_icon').length == 0)
			return;

		this.navigator.go({action: (modifier) => {
			 State.mainApp?.loadModule('ManagementAgents', '', {
				byAccountId: target.closest('tr').find('.cw_grid_link').data('uid')
			}, null, null, modifier == RedirectModifier.NewTab);
		}})
	}

	onAssetHealthClick (e) {
		e.stopPropagation();
		var target = e.currentTarget;
		var healthIndexRangeType = null;
		var serviceId = null;
		var accountName = '';
		var data, length, i, accounts = [], grid, gridDiv, rowUid, masterRowUid, dataSource;
		var assetGrid = $('#' + this.gridId).data('kendoCustomGrid');
		var openAssetTab = true;
		var source = '';
		if ($(target).hasClass('cw_color6')) {
			openAssetTab = false;
		} else {
			rowUid = $(target).closest('tr').attr('data-uid');
			gridDiv = $(target).closest('.k-grid');
			if ($(gridDiv).attr('id') === this.gridId) {
				dataSource = assetGrid.dataSource;
				accounts.push(dataSource.getByUid(rowUid).id);
				accountName = dataSource.getByUid(rowUid).name;
				if ($(target).hasClass('cw_account_services')) {
					source = 'account_services';
				} else if ($(target).hasClass('cw_account_assets')) {
					source = 'account_assets';
				}
			} else {
				masterRowUid = $(target).closest('.k-detail-row').prev().attr('data-uid');
				grid = $(gridDiv).data('kendoCustomGrid');
				dataSource = grid.dataSource;
				serviceId = dataSource.getByUid(rowUid).srvId;
				accounts.push(assetGrid.dataSource.getByUid(masterRowUid).id);
				accountName = assetGrid.dataSource.getByUid(masterRowUid).name;
				source = 'service_assets';
			}
		}
		if (openAssetTab) {
			var assetConfig = {
				source: source,
				healthIndexRangeType: healthIndexRangeType,
				accountList: accounts,
				serviceId: serviceId,
				accountName: accountName,
				includeSubaccounts: this.instanceConfiguration.includeSubaccounts,
				isView: true
			};
			const url = AssetsRouter.assetHealthSummary(assetConfig)
			this.navigator.go({url})
		}
	}

	onSlaIndicatorClick (e) {

		var target = $(e.currentTarget);
		var hasSlas = true;
		if (target.children('.is_idle').length) {
			hasSlas = false;
		}
		if (hasSlas) {
			this.navigator.go({
				url:SlaRouter.list({isView: true})
			})
		}
	}

	onGridServiceStateClick (e) {

		var target = $(e.currentTarget);
		if (target.parent().hasClass('js_srv_state_index')) {
			var serviceGrid = target.closest('table').parent().parent().data('kendoCustomGrid');
			var record = serviceGrid.dataSource.getByUid(target.closest('tr').data('uid'));
			this.navigator.go({url: ServicesRouter.viewer(record.id)})
		} else {
			var accountId = this.accountsDataSource.getByUid(target.closest('tr').data('uid')).id;

			this.navigator.go({
				url: ServicesRouter.list({
					accountId: accountId,
					showGridView: true,
				})
			});
		}
	}

	onAccountLink(e){
		if(this.groupBy.value() != 'accounts')
			return;

		//
		if(e.currentTarget.closest('.k-detail-row').length != 0)
			return

		e.stopPropagation();
		let itemRow = $(e.currentTarget).parent().parent()
		let dataItem = this.grid.dataItem(itemRow);

		this.navigator.go({
			action: (modifier) => {
				Utils.changeAccount(dataItem.id, dataItem.name);
				Utils.redirectTo(Settings.httpRoot, false, modifier == RedirectModifier.NewTab)
			}
		})
	}

	onIncidentsLink(e) {

		e.stopPropagation();
		var target = $(e.currentTarget),
			filter = target.data('filter'),
			accountGrid = this.grid,
			serviceGrid, accountId, serviceId, accountRowId, itemId;
		if (target.text() !== '0') {
			switch (filter) {
				case 'ACCOUNT':
					accountRowId = target.closest('tr').data('uid');
					accountId = accountGrid.dataItem('tr[data-uid=' + accountRowId + ']').id;
					break;
				case 'SERVICE':
					itemId = target.closest('tr').data('uid');
					accountRowId = target.closest('table').closest('tr').prev().data('uid');
					serviceGrid = target.closest('table').closest('.k-grid').data('kendoCustomGrid');
					accountId = accountGrid.dataItem('tr[data-uid=' + accountRowId + ']').id;
					serviceId = serviceGrid.dataItem('tr[data-uid=' + itemId + ']').srvId;
					break;
			}

			this.navigator.go({url: IncidentsRouter.list({
				filter: filter,
				serviceAccountId: accountId,
				serviceId: serviceId || null
			})});
		}
	}

	onEvent (data) {
		this.isEventDriven = true;
		this.refresh();
	}

	onResize(event, ui) {
		// TODO the resize functionality
		this.adjustSectionHeight(this.id);
	}

	refresh () {
		this.grid.dataSource.read();
	}

	adjustSectionHeight(id){
		let heightAdjust;
		if (this.isDashboardMode()) {
			heightAdjust = 79;
		} else {
			heightAdjust = 39;
		}
		var section = $('#' + id);
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		this.grid.setOptions({height: sectionHeight});
		let nestedGrids = $('.cw_summary_inner_grid');
		let nestedGridsHeight = 0;
		$(nestedGrids).each(function() {
			nestedGridsHeight += $(this).height();
		});
		nestedGridsHeight = nestedGridsHeight - sectionHeight / 2;
		if (nestedGridsHeight < 0) {
			nestedGridsHeight = 0;
		}
		let mainGridSection = section.find('.k-grid-content').first();
		mainGridSection.css('height', sectionHeight + nestedGridsHeight - heightAdjust).css('position', 'absolute');
	}

	adjustNestedGridHeight(wrapper) {
		let mainSection = $('#' + this.id);
		let mainSectionHeight = mainSection.height();
		let rows = $(wrapper).find('.k-grid-content tr');
		let height = rows.length * 26; //26 height of a row;
		if (height > mainSectionHeight) {
			height = 700;
		}
		wrapper.css('height', height + 29 + 17); //29 height of grid header
		wrapper.find('.k-grid-content').css('height', height + 17); //17 height scrollbar
	}

	onGroupByChanged = e => {
		this.accountsDataSource.transport.options.read.url = this.getServicesFirstLevelUrl();
		this.accountsDataSource.read();
	}

	getServicesFirstLevelUrl(){
		return this.requestPath + 'accounts/' + this.instanceConfiguration.accountId
			+ `/summary/${this.groupBy.value()}?includeSubaccounts=${this.instanceConfiguration.includeSubaccounts.toString()}`;
	}

	getServicesSecondLevelUrl(group){
		group.id = encodeURIComponent(group.id)
		if(this.groupBy.value() == 'accounts') {
			return Settings.serverPath + 'accounts/' + group.id + '/summary/services/';
		}else {
			return Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + `/summary/tags/${group.id}/services`;
		}
	}

	subscribe() {
		this.isDataSourceSubscribed = true;

		var subscriptionObj = [{
			eventType: 'AccountSummary',
			accountId: this.instanceConfiguration.accountId,
			includeSubaccounts: this.instanceConfiguration.includeSubaccounts
		}, {
			eventType: 'Administration',
			entityType: 'ACCOUNT',
			accountId: this.instanceConfiguration.accountId
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	}

	unsubscribeAndSubscribe() {
		this.unsubscribe();
		this.subscribe();
	}

	destroy() {
		this.groupBy.destroy();
		this.groupBy.wrapper.remove();

		$('#' + this.gridId).off();
		$(window).off('resize', $.proxy(this.onResize, this));
		Widget.prototype.destroy.call(this);
	}

	getStateForSaving(){
		return {
			...getGridStateForSaving(this.grid),
			groupBy: this.groupBy.value()
		}
	}
}

export {SummaryWidget as default}
