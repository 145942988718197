import React from "react"

import {observer} from "mobx-react";
import FormEntry from "controls/react/form/formEntry";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {linkModel} from "framework/mobx-integration";

import {designerLocalizations} from "areas/dashboards/graph-editor-extensions/designerLocalizations";

const i18n = require('core/localization').translator(designerLocalizations)

export const ShowAsLabel = observer((props: { datasource: { showAsLabel: boolean } }) => {
	return <FormEntry>
		<AntCheckbox {...linkModel(props.datasource, 'showAsLabel')}>{i18n('Set datasource as label')}</AntCheckbox>
	</FormEntry>
})
