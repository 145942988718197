import './antModal.less'

import {Observer} from "mobx-react";
import React, {MutableRefObject, ReactNode, useCallback, useState} from 'react';
import {Modal, ModalProps} from 'antd';
import {Button} from "antd"
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';

import {State} from 'tools'
import {ResizableBox, ResizeCallbackData, ResizeHandle} from "react-resizable";
import {createRoot} from 'react-dom/client';
import {Toolbar} from "controls/react/layout/toolbar";
import {runAsAsync} from "tools/utils";
import {newGuid} from "tools/guid";
import classNames from "classnames";
import {getTopLeftPagePosition} from "../../modalWindow";
import {CssVariables} from "styles/cssVariables";

const i = require('core/localization').translator();

export enum ModalPosition {
	Centered = 'centered',
	TopLeft = 'topLeft',
	Default = 'default',
	TopRight = 'topRight',
	Mouse = 'mouse',
	Anchor = 'anchor',
	Custom = 'custom'
}

export interface AntModalProps extends Omit<ModalProps, 'centered'> {
	mode?: 'update' | 'create'
	positionType?: ModalPosition
	width?: number
	draggable?: boolean
	height?: number
	draggableHandle?: string
	draggableExclusion?: string
	resizable?: boolean
	defaultFooterButtons?: ('ok' | 'cancel')[]
	cancelText?: string
	footerButtons?: Array<ReactNode>
	useFooterToolbar?: boolean
	positionAnchor?: React.MutableRefObject<HTMLElement>,
}

let mouseCurrentPosition = {
	left: 0,
	top: 0
}

document.addEventListener('mousemove', e => {
	mouseCurrentPosition.left = e.x
	mouseCurrentPosition.top = e.y
})

const defaultAntModalWidth = 520

const b = require('b_').with('ant-modal-wrapper')
const resizable = require('b_').with('react-resizable')


export const AntModal: React.FunctionComponent<AntModalProps> = (props => {
	let props1 = useInitialModalPosition(props)
	props1 = useOnScreenPosition(props1)
	let props2 = userResizableDraggableRenderer(props1)

	let props3 = useCreateUpdateMode(props2)

	let propsFinal = useDefaultFooter(props3)


	const contentClassNames = b('content', {
		draggable: propsFinal.draggable
	});

	const {title, children, rootClassName, ...rest} = propsFinal

	const rootClassNameDerived = classNames(b({
		'custom-footer': props.footer != null,
	}), rootClassName)

	const [contentDivRef, restFinal] = useZIndexManager(rest)

	return <Modal rootClassName={rootClassNameDerived} {...restFinal}>
		<div ref={contentDivRef} className={contentClassNames} id={propsFinal.id}>
			{title && (title.type?.name == 'Toolbar' ? title : <Toolbar title={title}/>)}
			{children}
		</div>
	</Modal>
});

type OpenModalProps = Omit<AntModalProps, 'open'> & { closeTriggerExtractor?: (close: () => void) => void }

type UseHeightProps = {
	height?: number
	bodyStyle?: React.CSSProperties
	resizable?: boolean
}

function useZIndexManager(props: Pick<AntModalProps, 'zIndex'>) {
	const ref = React.useRef<HTMLDivElement>()
	const [zIndex, setZIndex] = React.useState<number | null>(null)
	const zIndexRef = React.useRef<number | null>(null)

	const onClick = React.useCallback(() => {
		if(props.zIndex && !isNaN(props.zIndex)) {
			setZIndex(props.zIndex)
		} else {
			if(!zIndexRef.current || CssVariables.currentZIndex > zIndexRef.current) {
				CssVariables.currentZIndex++
				zIndexRef.current = CssVariables.currentZIndex
				setZIndex(CssVariables.currentZIndex)
			}
		}
	}, [props.zIndex])

	React.useEffect(() => {
		if (ref.current) {
			const localRef = ref.current
			localRef.addEventListener('click', onClick)
			onClick()
			return () => localRef.removeEventListener('click', onClick)
		}
	}, [ref.current, onClick])

	return [ref, {...props, zIndex}] as const
}

function useCreateUpdateMode<T extends AntModalProps>(props: T) {
	const {mode, okText, cancelText, ...rest} = props
	if (props.okText === undefined) {
		props.okText = props.mode == 'update' ? i('Update') : i('Create');
	}
	if (props.cancelText === undefined) {
		props.cancelText = i('Cancel');
	}

	return {
		...rest,
		okText: okText ?? (mode == 'update' ? i('Update') : i('Create')),
		cancelText: cancelText ?? i('Cancel')
	}
}

function useDefaultFooter<T extends Pick<AntModalProps,
	'footer' | 'defaultFooterButtons' | 'onOk' | 'okButtonProps' | 'okText' | 'onCancel' | 'cancelButtonProps' | 'cancelText' | 'footerButtons' | 'useFooterToolbar'>>(props: T) {

	if (props.footer !== undefined)
		return props

	//ant modal comes with cancel/ok buttons but we use ok/cancel in the app so we swap them here
	const footer = [...props.footerButtons ?? []];
	if (!props.defaultFooterButtons || props.defaultFooterButtons.includes('ok')) {
		footer.push(<Button onClick={props.onOk} type={'primary'}
							key={'ok'} {...props.okButtonProps}>{props.okText}</Button>);
	}
	if (!props.defaultFooterButtons || props.defaultFooterButtons.includes('cancel')) {
		footer.push(<Button onClick={props.onCancel}
							key={'cancel'} {...props.cancelButtonProps}>{props.cancelText}</Button>);
	}
	if (props.useFooterToolbar) {
		return {
			...props,
			footer: <Toolbar appearance={"transparent"}>{footer}</Toolbar>
		}
	}

	return {
		...props,
		footer
	}
}

export function userResizableDraggableRenderer<T extends AntModalProps>(props: T) {
	const [size, setSize] = useState({height: props.height, width: props.width});
	let props1 = useHeight(props, size);

	let {resizable, ...others} = props1

	const draggableRef = React.useRef<HTMLDivElement>(null);
	let {wrapDraggable, bounds, draggable, id} = useDraggableWrapper(props1, draggableRef);
	const wrapResizable = useResizableWrapper(props1, [size, setSize])

	React.useEffect(() => {
		setSize({width: props.width, height: size.height})
	}, [props.width])

	React.useEffect(() => {
		setSize({width: size.width, height: props.height})
	}, [props.height])

	return {
		...others,
		draggable,
		width: size.width,
		mask: draggable && props.mask === undefined ? false : props.mask,
		id,
		modalRender: React.useMemo(() => {
			if (!draggable && !resizable) {
				return null;
			}
			return (modal: React.ReactNode) => {
				return wrapDraggable(wrapResizable(<div ref={draggableRef} className={b('wrapper')}>{modal}</div>));
			}
		}, [draggable, resizable, bounds, size.width, size.height, wrapResizable, wrapDraggable])
	};
}

export function useInitialModalPosition<T extends AntModalProps>(props: T) {
	let {style, positionType, ...others} = props;
	positionType ??= ModalPosition.Default;

	const styleEffective = React.useMemo(() => {
		if (positionType == ModalPosition.Default || positionType == ModalPosition.Centered) {
			return style
		}

		let result: React.CSSProperties = {
			...(style ?? {})
		}

		const topLeftPosition = getTopLeftPagePosition()
		if (positionType == ModalPosition.TopLeft) {
			result.top = topLeftPosition.top
			result.left = topLeftPosition.left
		} else if (positionType == ModalPosition.TopRight) {
			result.top = topLeftPosition.top
			result.left = window.innerWidth - topLeftPosition.left - (others.width ?? defaultAntModalWidth)
		} else if (positionType == ModalPosition.Mouse) {
			result.top = Math.max(mouseCurrentPosition.top, topLeftPosition.top)
			result.left = mouseCurrentPosition.left

		} else if (positionType == ModalPosition.Anchor && !!others.positionAnchor) {
			const rect = others.positionAnchor.current.getBoundingClientRect();
			result.top = rect.top + rect.height;
			result.left = rect.left + rect.width;
		}

		result.margin = '0'

		return result
	}, [])

	return {
		...others,
		style: styleEffective,
		centered: positionType == 'centered'
	};
}

function useOnScreenPosition<T extends AntModalProps>(props: T) {
	let {style, width, height, ...others} = props;

	const positioning = React.useMemo(() => {
		let result = {
			style: style,
			width: width,
			height: height
		}

		if (window.innerWidth < props.width){
			result.width = window.innerWidth - 20
		}

		if (window.innerHeight < props.height){
			result.height = window.innerHeight - 20
		}

		if (style == null || (typeof style.top != 'number' && typeof style.left != 'number'))
			return result

		const footerHeight = () => {
			if (props.footer === undefined || props.footer === null) {
				return 0
			}
			return 60
		}

		if (typeof result.style.left == 'number'){
			if (window.innerWidth < result.width + result.style.left) {
				result.style.left = window.innerWidth - result.width - 10
			}
		}

		if (typeof result.style.top == 'number'){
			if (window.innerHeight < result.height + result.style.top) {
				result.style.top = window.innerHeight - result.height - 10 - footerHeight()
			}
		}

		return result

	}, [props.style, props.width, props.height])

	return {
		...others,
		style: positioning.style,
		width: positioning.width,
		height: positioning.height
	}
}

const CustomResizeHandle = (props: any) => {
	const {resizeHandle, innerRef, ...others} = props;
	const modificator: { [id: string]: boolean } = {};
	modificator[resizeHandle] = true;
	return <span className={resizable('resize-handle', modificator)} ref={innerRef} {...others} />
}

export function useResizableWrapper(props: AntModalProps, sizeState: [{ height: number, width: number }, React.Dispatch<React.SetStateAction<{ height: number, width: number }>>]) {
	let {resizable} = props;
	const [{width, height}, setSize] = sizeState;

	const onResize = useCallback((e: React.SyntheticEvent, data: ResizeCallbackData) => {

		setSize({height: data.size.height, width: data.size.width})
	}, [setSize]);

	return React.useMemo(() => (content: React.ReactNode) => {
		if (!resizable) {
			return content;
		}
		return <div>
			<ResizableBox width={width ?? 0}
						  height={height ?? 0}
						  resizeHandles={['se', 'e', 's']}
						  handle={(resizeHandle: ResizeHandle, ref: React.RefObject<any>) => <CustomResizeHandle
							  resizeHandle={resizeHandle} innerRef={ref}/>}
						  onResize={onResize}>
				{content}
			</ResizableBox>
		</div>
	}, [resizable, width, height]);
}

export function useDraggableWrapper(props: AntModalProps, draggableRef: MutableRefObject<HTMLDivElement>) {
	const [bounds, setBounds] = useState({left: 0, top: 0, bottom: 0, right: 0});
	const [id] = useState(newGuid())
	let {draggable, draggableHandle, draggableExclusion} = props

	draggable ??= true;
	draggableHandle ??= `.ant-modal-wrapper__content_draggable[id="${id}"] > .section > .toolbar, .ant-modal-wrapper__content_draggable[id="${id}"] > .toolbar`;
	draggableExclusion ??= 'input';

	const onStart = useCallback((_event: DraggableEvent, uiData: DraggableData) => {
		const {clientWidth, clientHeight} = window.document.documentElement;
		const targetRect = draggableRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}

		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	}, []);

	return {
		wrapDraggable: React.useMemo(() => (content: React.ReactNode) => {
			if (!draggable) {
				return content;
			}
			return <Draggable handle={draggableHandle} bounds={bounds} cancel={draggableExclusion}
							  onStart={(event, uiData) => onStart(event, uiData)}>
				{content}
			</Draggable>
		}, [draggable, bounds]),
		bounds,
		draggable,
		id
	};
}

export function useHeight<T extends UseHeightProps>(props: T, size: { height: number, width: number }) {
	const {bodyStyle, resizable, height, ...rest} = props

	let bodyStyleMerged = React.useMemo(() => {
		if (height == null || resizable) {
			return bodyStyle
		}

		let result: React.CSSProperties = {
			...(bodyStyle ?? {})
		}

		result.height = height + 'px'

		return result
	}, [bodyStyle, height, resizable])

	return {
		styles: {body: bodyStyleMerged},
		resizable,
		height,
		...rest
	}
}

export function openModal(props: (() => OpenModalProps) | OpenModalProps, content: React.ReactNode) {
	return new Promise<boolean>((resolve) => {
		const [root] = createContainer();

		root.render(<>
			<Observer>{() => {
				const getProps = (p: (() => OpenModalProps) | OpenModalProps) => {
					if (typeof p == 'function') {
						return p();
					}
					return p;
				};
				const {onCancel, onOk, ...restProps} = getProps(props);

				const onCancelWrapper = (e: React.MouseEvent<HTMLButtonElement>) => {
					root.unmount();
					onCancel?.(e);
					resolve(false);
				}

				const onOkWrapper = async (e: React.MouseEvent<HTMLButtonElement>) => {
					if (onOk) {
						try {
							resolve(await runAsAsync(onOk, [e]));
						} catch {
							resolve(false);
						}
					}
					if (!e.isDefaultPrevented()) {
						root.unmount();
					}
				}
				restProps.closeTriggerExtractor?.(() => onCancelWrapper(null))
				return <AntModal onOk={onOkWrapper}
								 onCancel={onCancelWrapper}
								 open={true}
								 {...restProps}>
					{content}
				</AntModal>
			}}</Observer>
		</>)

		State.mainApp.registerForOnNavigate(() => {
			root.unmount();
			resolve(false);
		});
	});
}

export function createContainer() {
	const container = document.createElement('div')
	document.body.appendChild(container)

	const root = createRoot(container)

	return [root, container] as const
}

export function createContainerRoot() {
	return createContainer()[0];
}
