const urls = {
	messageCenter: '/message-center'
}

export const getDescriptions = () => {
	return [{
		url: urls.messageCenter,
		jspUrl: 'include/MessageCenter.jsp',
		getModuleImport: () => import('./messageCenter'),
		routeId: 'message_center'
	}]
}

export class ViewsRouter {
	static messageCenter() {
		return urls.messageCenter
	}
}
