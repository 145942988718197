import {apiFetch, ApiRequest} from "framework/api";

export async function loadChildEntities<TParent, TResponse, TChild = TResponse>(
	parentEntities: TParent[],
	getLoadPromiseCallback: (parentEntity: TParent) => ApiRequest<TResponse[]>,
	copyCallback: (responseEntry: TResponse, parent: TParent, result: TChild[]) => TChild){

	if (!parentEntities?.length) {
		return []
	}

	const loadedPromises = await Promise.all(
		parentEntities.map(entry => getLoadPromiseCallback(entry))
			.map(request => apiFetch(request))
	)

	const result: TChild[] = [];

	for (const [index, parentEntity] of parentEntities.entries()) {
		let response = loadedPromises[index]
		if(response?.success) {
			for (const childEntity of response.data) {
				let newEntry = copyCallback(childEntity, parentEntity, result)
				if(newEntry != null) {
					result.push(newEntry)
				}
			}
		}
	}

	return result
}
