import {CeeViewDataSource, Utils} from "tools";
import {Cookies} from 'core'
import {Settings} from "settings";
import {Dialog} from 'controls';


export let AssetMergeForm = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default AssetMergeForm;

AssetMergeForm.prototype = {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		$('.cw_search_box').attr('placeholder', lang.SEARCH);
		this.assetsList = this.assetsList || [];
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.firstTimeLoad = true;
		this.parentAsset = $('#cw_parent_asset').kendoComboBox({
			dataTextField: 'name',
			dataValueField: 'id',
			template: item => {
				return `<span style="font-weight: bold">${item.name}</span>/${item.id}`;
			},
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				}
			}),
			autoBind: true,
			enable: true,
			clearButton: false,
			placeholder: lang.assethealth.SELECT_ASSET,
			change: $.proxy(function (e) {
				$('#save_merged_assets').removeAttr('disabled');
				$('.cw_merge_grid_check').removeAttr('disabled', 'disabled');
				$('.cw_merge_grid_check_all').prop('checked', true);
				$('.cw_merge_grid_check').prop('checked', true);
				$('.cw_merge_grid_check[data-id="' + e.sender.value() + '"]').prop('checked', false).attr('disabled', 'disabled');
				if ($('.cw_merge_grid_check').length === 1 && $('.cw_merge_grid_check:disabled').length === 1) {
					$('#save_merged_assets').attr('disabled', 'disabled');
				}
			}, this)
		}).data('kendoComboBox');
		this.selectedAssetsGrid = $('.cw_selected_assets_list').kendoCustomGrid({
			dataSource: new CeeViewDataSource({
				data: this.assetsList,
				schema: {
					model: {
						id: 'id'
					}
				}
			}),
			resizable: true,
			sortable: true,
			scrollable: true,
			filterable: false,
			selectable: 'single',
			columns: [{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_merge_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_merge_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				width: 40
			}, {
				field: 'name',
				title: lang.NAME,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'id',
				title: lang.CVID,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'assetType',
				title: lang.TYPE,
				width: 120,
				attributes: {
					"class": "expand ellipsis"
				}
			}],
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(function () {
				$('.cw_merge_grid_check_all').prop('checked', true);
				$('.cw_merge_grid_check').prop('checked', true);
				$('.cw_merge_grid_check[data-id="' + this.parentAsset.value() + '"]').prop('checked', false).attr('disabled', 'disabled');
				$('#save_merged_assets').removeAttr('disabled');
				if ($('.cw_merge_grid_check').length === 1 && $('.cw_merge_grid_check:disabled').length === 1) {
					$('#save_merged_assets').attr('disabled', 'disabled');
				}
				if (this.mode === 'view') {
					$('.cw_merge_grid_check').attr('disabled', 'disabled');
					$('.cw_merge_grid_check_all').attr('disabled', 'disabled');
				}
			}, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.selectedAssetsGrid);
		this.adjustGridHeight();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('.cw_selected_assets_list').off();
		$('.cw_selected_assets').find('.cw_search_box').off();
		$('#save_merged_assets').off();
		$('#cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('.cw_selected_assets_list').on('click', '.cw_merge_grid_check', $.proxy(this.onSelectedAssetsGridCheck, this));
		$('.cw_selected_assets_list').on('click', '.cw_merge_grid_check_all', $.proxy(this.onSelectedAssetsGridCheckAll, this));
		$('.cw_selected_assets').find('.cw_search_box').on('keyup', $.proxy(this.onSelectedAssetsSearch, this));
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_merged_assets').on('click', $.proxy(this.onSaveButton, this));
		var modalWindow = $('#modal').data("kendoWindow");
		if (modalWindow) {
			modalWindow.bind('resize', $.proxy(this.onModalResize, this));
		}
	},
	/**
	 * Handler for selecting assets from the merge assets grid
	 */
	onSelectedAssetsGridCheck: function () {
		var checkboxes = $('.cw_selected_assets_list').find('.cw_merge_grid_check:checked');
		if (checkboxes.length) {
			//this.selectedAssetsMenu.enableItem('cw_remove_selected');
			$('#cw_remove_selected').removeClass('is_disabled');
			$('#cw_remove_selected').addClass('pointer');
		} else {
			//this.selectedAssetsMenu.disableItem('cw_remove_selected');
			$('#cw_remove_selected').addClass('is_disabled');
			$('#cw_remove_selected').removeClass('pointer');
		}
	},
	/**
	 * Handler for selecting all assets from the merge assets grid
	 */
	onSelectedAssetsGridCheckAll: function (e) {
		var checked = $(e.currentTarget).is(':checked');
		$('.cw_selected_assets_list').find('.cw_merge_grid_check:not(:disabled)').prop('checked', checked);
		this.onSelectedAssetsGridCheck();
	},
	/**
	 * Handler for searching through the merge assets grid
	 */
	onSelectedAssetsSearch: function (e) {
		var value = $(e.currentTarget).val();
		if (value) {
			this.selectedAssetsGrid.dataSource.filter({
				field: 'name',
				operator: 'contains',
				value: value
			});
		} else {
			this.selectedAssetsGrid.dataSource.filter({});
		}
	},
	getValue: function () {
		this.assetsList = [];
		var dataSource = this.selectedAssetsGrid.dataSource;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var checkbox = $('.cw_merge_grid_check[data-id="' + dataSource.at(i).id + '"]');
			if (!checkbox.prop('disabled') && checkbox.prop('checked')) {
				this.assetsList.push(dataSource.at(i).id);
			}
		}
		return this.assetsList;
	},
	/**
	 * Handler function for the click event on Cancel button
	 */
	onCancelButton: function () {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: function () {
		var assets = this.getValue();
		var parentAsset = this.parentAsset.value();
		var parentAssetText = this.parentAsset.text();
		var message = (lang.MERGE_ASSETS_CONFIRMATION).replace('ASSETNAME', parentAssetText);
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftAccountId + '/assets/' + parentAsset + '/validate';
		Utils.ajax(url, 'POST', JSON.stringify(assets), $.proxy(function (result) {
			if (result.data.length) {
				message = '';
				for (var i = 0; i < result.data.length; i++) {
					message += result.data[i] + '<br />';
				}
			}
			var dialog = new Dialog({
				title: lang.INFO,
				msg: message,
				icon: 'ERROR',
				actionText: 'UPDATE',
				buttons: {
					ok: true,
					cancel: true
				},
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						if (parentAsset && assets.length > 0) {
							var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + parentAsset + '/merge';
							Utils.ajax(url, 'POST', JSON.stringify(assets), $.proxy(this.onAssetsMerged, this));
						}
					}
				}, this)
			});
			dialog.show();
		}, this));
	},

	onAssetsMerged: function (result) {
		if (result.success) {
			this.onCancelButton();
			$('.cw_grid_check').prop('checked', false);
		}else{
			let dialog = new Dialog({
				title: lang.ERROR,
				msg: result.message,
				details: result.details,
				icon: 'ERROR',
				buttons: {
					ok: true
				},
				actionText: lang.OK
			});
			dialog.show();
		}
	},
	/**
	 * Resize event for Modal Window
	 */
	onModalResize: function () {
		this.adjustGridHeight();
	},
	/**
	 * Adjust Grids height based on the modal resize event
	 */
	adjustGridHeight: function () {
		var gridsWrapper = $('.cw_asset_merge_container');
		var grids = gridsWrapper.find('.k-grid');
		var gridHeight = gridsWrapper.find('.cw_section').outerHeight();
		grids.css('height', gridHeight - 42 - 2);
		grids.find('.k-grid-content').css('height', gridHeight - 42 - 29);
	}
};
