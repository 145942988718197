export function createNode(html: string, parent?: Element) : HTMLElement{
	const container = document.createElement('div');
	container.innerHTML = html;

	const newNode =  container.children[0] as HTMLElement

	if(parent){
		parent.appendChild(newNode);
	}

	return newNode;
}

type AddDragAndDropOptions = {
	onDrop: (data: string) => void
	overClassName: string
	data: string
}

export function addDragAndDrop(element: HTMLElement, options: AddDragAndDropOptions){
	let counter = 0

	element.addEventListener('dragstart', e => {
		if (e.currentTarget instanceof HTMLDivElement) {
			e.currentTarget.style.opacity = '0.4';
			e.dataTransfer.setData('text/plain', options.data)
		}
	})

	element.addEventListener('dragend', e => {
		if (e.currentTarget instanceof HTMLDivElement) {
			e.currentTarget.style.opacity = '1';
		}
	})

	element.addEventListener('dragover', e => {
		e.preventDefault()
	})

	element.addEventListener('dragenter', e => {
		counter++
		if (e.currentTarget instanceof HTMLDivElement) {
			element.classList.add(options.overClassName)
		}
	})

	element.addEventListener('dragleave', e => {
		counter--
		if (e.currentTarget instanceof HTMLDivElement) {
			if(counter <= 0) {
				element.classList.remove(options.overClassName)
			}
		}
	})

	element.addEventListener('drop', e => {
		if (e.currentTarget instanceof HTMLDivElement) {
			counter = 0
			element.classList.remove(options.overClassName)
			let data = e.dataTransfer.getData("text/plain")

			options.onDrop(data)

			e.preventDefault()
		}
	})
}

export function htmlToText(html: string){
	const div = document.createElement('div');
	div.innerHTML = html
	return div.innerText
}

export function emulateFileDownload(name: string, content: Blob){
	const fileName = decodeURI(name)
	const url = window.URL.createObjectURL(content)
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', fileName)
	document.body.appendChild(link)
	link.click()
	window.URL.revokeObjectURL(url)
	link.remove()
}
