import {Api} from "tools/api";
import {ApiRequest} from "framework/api";
import {Qualifier} from "framework/entities/qualifier";
import {MetricValue} from "framework/entities/metricValue";
import {GridStore} from "../../controls/grid/gridStore";
import {NamedItem} from "../services/services-list/api";
import {GridSelectionMode} from "../../controls/grid/gridSelection";
import {RuleDefinition} from "../../controls/queryBuilder/ruleDefinition";

export class ManagementApi {
	static getAgentUrl(accountId: string, id: string) {
		return Api.accountRoot(accountId) + `agents/${id}/details`;
	}

	static getQualifiersLastValue(data: object) {
		return Api.fetchPost(`${Api.serverRoot()}metrics/registeredMetrics/lastinfo`, data)
	}

	static getSearchUrl() {
		return Api.accountRoot() + 'agents/lite?name=';
	}
}


export type GetQualifiersLastValueArgs = {
	targets: {
		metricId: string
	}[]
}

export type GetQualifiersLastValueEntry = {
	found: boolean
	hasMetrics: boolean
	intercept: number
	slope: number
	qualifier: Qualifier
	data: MetricValue[]
}

export function getQualifiersLastValue(args: GetQualifiersLastValueArgs){
	return new ApiRequest<GetQualifiersLastValueEntry[]>({
		url: 'metrics/registeredMetrics/lastinfo',
		method: 'POST',
		payload: args.targets
	})
}

export type GridSelectionPayload<DataEntry> = {
	sort: {field: string, dir: "asc" | "desc"}[],
	filter: RuleDefinition,
	groupBy: (keyof DataEntry)[],
	collapsedGroups: string[],
	selection: {
		mode: GridSelectionMode,
		ids: string[]
	}
}
export function deleteDataRegistry<T>(payload: GridSelectionPayload<T>) {
	const url = `dataRegistry/delete`;
	return new ApiRequest({
		url,
		method: 'POST',
		payload: payload
	})
}

export function setManagementElementInMaintenance(type: string, maintenanceData: object){
	const multipleSavePaths: {[id:string]: string} = {agent: 'multipleAgents', service: 'multipleServices', asset: 'multipleAssets'};
	return new ApiRequest<GetQualifiersLastValueEntry[]>({
		url: 'maintenance/' + multipleSavePaths[type],
		method: 'POST',
		payload: maintenanceData
	})
}
