import React from "react";

import FormEntry from "controls/react/form/formEntry";
import {AntInput} from "controls/react/ant/antInput";
import {linkModel, ModelValidator, ValidatableModel} from "framework/mobx-integration";
import {makeAutoObservable} from "mobx";
import {Section} from "controls/react/layout/section";
import {observer} from "mobx-react";

import {sharedLocalization} from "vendor-init/tinymce/plugins/localization";
import {PeriodEditor} from "./periodEditor";
import {SingleMetricSelector} from "controls/react/metricsSelector";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
const i = require('core/localization').translator(sharedLocalization)

export class MetricVariableConfig implements ValidatableModel<MetricVariableConfig> {
	id: string;
	title: string;
	threshold: string = "";
	selector: string = 'period';
	unit: string = "";
	source: string = 'metric';

	validator = new ModelValidator(this);

	constructor() {
		makeAutoObservable(this)

		this.validator
			.required("id")
	}
}

const MetricVariableForm = observer((props: { config: MetricVariableConfig }) => {
	return <Section childrenPadding={true}>
		<FormEntry label={i('Title')}>
			<AntInput {...linkModel(props.config, "title")}/>
		</FormEntry>
		<FormEntry label={i('Datasource')} model={props.config} modelField={"id"}>
			<SingleMetricSelector/>
		</FormEntry>
		<PeriodEditor config={props.config}/>
		<FormEntry label={i('Threshold')}>
			<AntInputNumber {...linkModel(props.config, "threshold")}/>
		</FormEntry>
		<FormEntry label={i('Unit')}>
			<AntInput {...linkModel(props.config, "unit")}/>
		</FormEntry>
	</Section>
});


export const metricVariableComponentDescription = {
	id: 'ceeview/metric',
	title: i('Metric timeline'),
	config: MetricVariableConfig,
	form: MetricVariableForm,
	preview: require('./metricVariable.png'),
	defaultSize: {
		width: '300px',
		height: '150px'
	}
}

