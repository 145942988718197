import React from "react";

import {AntSelect} from "controls/react/ant/antSelect";
import {translator} from 'core/localization/localization';
import {sharedLocalization} from './shared';

const i = require('core/localization').translator({
  "Select legend position...": {
    "no": "Velg navninformasjon plassering"
  }
}, sharedLocalization);

export const LegendPositionSelect = props => {
	const items = [{
		text: i('Top'),
		value: 'TOP'
	}, {
		text: i('Bottom'),
		value: 'BOTTOM'
	}, {
		text: i('None'),
		value: 'NONE'
	}];
	const {...others} = props;

	return (
		<AntSelect placeholder={i('Select legend position...')}
				   dataList={items}
				   nameField={"text"}
				   valueField={"value"}
				   {...others}
		/>
	);
}
