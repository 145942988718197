import React from "react";

import {Cookies} from "core/cookies";
import {Section} from "controls/react/layout/section";
import {Toolbar} from "controls/react/layout/toolbar";
import {FormEntry} from "controls/react/form";
import {AntInput} from "controls/react/ant/antInput";
import {IconButton} from "controls/react/form/iconButton";
import State from 'tools/state';

import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";

const i = require('core/localization').translator(sharedLocalization,{
	"Refresh (seconds)": {
		"no": "Oppdater (sekunder)"
	},
	"Please provide an url": {
		"no": "Legg til URL"
	}
});

export function getWidgetDescription(){
	return {
		form: WebPageWidgetConfiguration,
		defaultConfig: {
			type: 'web-page',
			title: 'Web Page',
			refreshRate: 3600
		},
		widget: WebPageWidget,
		fullTitle: i('Custom') + '/' + i('Web Page'),
	}
}

export const WebPageWidgetConfiguration = props => {
	const {configLink} = props;
	const urlLink = configLink.get('url')
		.check(i('Please provide an url'), x => x != '');

	return (
		<Section appearance={"none"} contentPadding={false} childrenPadding={true}>
			<FormEntry label={i('Title')} valueLink={configLink.get('title')}>
				<AntInput/>
			</FormEntry>
			<FormEntry label={i('Url')} valueLink={urlLink.required()}>
				<AntInput valueLink={urlLink}/>
			</FormEntry>
			<FormEntry label={i('Refresh (seconds)')}>
				<AntInput valueLink={configLink.get('refreshRate')}/>
			</FormEntry>
		</Section>
	)
}

export class WebPageWidget extends React.PureComponent {
	iframeRef = React.createRef();

	componentDidMount() {
		let refreshRate = parseInt(this.props.config.refreshRate);
		if(isNaN(refreshRate)){
			refreshRate = 3600;
		}

		if( refreshRate < 5){
			refreshRate = 5
		}

		this.interval = setInterval(this.reloadIframe, refreshRate*1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	reloadIframe = () => {
		this.iframeRef.current.src = this.iframeRef.current.src;
	}

	render() {
		return (
			<DefaultWidgetWrapper {...this.props}>
				<iframe src={this.getUrl()} ref={this.iframeRef} style={{flex: 1}}/>
			</DefaultWidgetWrapper>
		)
	}

	getUrl(){
		let url = this.props.config.url || this.props.config.configuration.url;
		if( url.indexOf('http') != 0 ){
			url = 'http://' + url;
		}
		return url;
	}
}
