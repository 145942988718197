import './textBox.less'

import React, {useRef, useState, useCallback, useEffect} from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";

const b = require('b_').with('c-textbox')

export function StateTextBox(props) {
	const ref = props.ref || useRef();
	const wrapperRef = props.wrapperRef || useRef();

	const [value, setValue] = useState(props.value || "");

	const onChange = useCallback((e) => {
		setValue(e.target.value);
	}, [setValue]);

	useEffect(() => {
		setValue(props.value);
	}, [setValue, props.value]);

	const onBlur = useCallback(() => {
		if (props.value !== value) {
			props.onChange(value);
		}
	}, [props.onChange, props.value, value]);

	useEffect(() => {
		const handler = () => {
			ref.current.select();
		};

		if (ref && props.readOnly) {
			ref.current.addEventListener('click', handler);

			return ref.current.removeEventListener('click', handler);
		}
	}, [ref, props.readOnly]);

	const {numeric, containerClass, invalid} = props;

	return <div ref={wrapperRef} className={b("wrapper")}>
			<input className={classnames(b({invalid}), 'k-input', 'k-textbox', containerClass)}
				type={numeric ? 'number': 'text'}
				value={value}
				onChange={onChange}
				title={value}
				onBlur={onBlur}
				ref={ref}
				onClick={props.onClick}/>
		</div>
}

StateTextBox.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	invalid: PropTypes.bool,
	onChange: PropTypes.func,
	containerClass: PropTypes.string,
	disabled: PropTypes.bool,
	readonly: PropTypes.bool,
	numeric: PropTypes.bool
}

export default StateTextBox;
