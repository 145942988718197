import React from "react";

import FormEntry from "controls/react/form/formEntry";
import {AntInput} from "controls/react/ant/antInput";
import {linkModel, ModelValidator, ValidatableModel} from "framework/mobx-integration";
import {makeAutoObservable} from "mobx";
import {Section} from "controls/react/layout/section";
import {observer} from "mobx-react";

import {sharedLocalization} from "vendor-init/tinymce/plugins/localization";
import {AntSelect} from "controls/react/ant/antSelect";
import { DataListEntry } from "framework/entities/dataList";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {SingleMetricSelector} from "controls/react/metricsSelector";
const i = require('core/localization').translator(sharedLocalization)

export class TextMetricConfig implements ValidatableModel<TextMetricConfig> {
	id: string;
	format: string = "%.2f";
	fontName: string = 'Default';
	fontSize: string = "16";
	fontStyle: string = "normal";
	fontColor: string = "#000000";
	source: string = "metric";
	title: string;

	validator = new ModelValidator(this);

	constructor() {
		makeAutoObservable(this)

		this.validator
			.required("id");
	}
}

let fontStyles: DataListEntry<string>[] = null;
const getFontStyles = () => {
	if(fontStyles == null){
		fontStyles = [{
			id: "normal",
			name: i('Normal')
		},{
			id: "bold",
			name: i('Bold')
		},{
			id: "italic",
			name: i('Italic')
		}]
	}

	return fontStyles;
}

let fontFamilies: DataListEntry<string>[] = null;
const getFontFamilies = () => {
	if (fontFamilies == null) {
		fontFamilies = [
			{name: "Andale Mono", id: "andale mono,times"},
			{name: "Arial", id: "arial,helvetica,sans-serif"},
			{name: "Arial Black", id: "arial black,avant garde"},
			{name: "Book Antiqua", id: "book antiqua,palatino"},
			{name: "Comic Sans MS", id: "comic sans ms,sans-serif"},
			{name: "Courier New", id: "courier new,courier"},
			{name: "Georgia", id: "georgia,palatino"},
			{name: "Helvetica", id: "helvetica"},
			{name: "Impact", id: "impact,chicago"},
			{name: "Tahoma", id: "tahoma,arial,helvetica,sans-serif"},
			{name: "Terminal", id: "terminal,monaco"},
			{name: "Times New Roman", id: "times new roman,times"},
			{name: "Trebuchet MS", id: "trebuchet ms,geneva"},
			{name: "Verdana", id: "verdana,geneva"},
			{name: "zapf dingbats", id: "wingdings,zapf dingbats"}]
	}
	return fontFamilies;
}

const TextMetricForm = observer((props: { config: TextMetricConfig }) => {
	return <Section childrenPadding={true}>
		<FormEntry label={i('Metric')} model={props.config} modelField={"id"}>
			<SingleMetricSelector/>
		</FormEntry>
		<FormEntry label={i('Format')}>
			<AntInput {...linkModel(props.config, "format")}/>
		</FormEntry>
		<FormEntry label={i('Font family')}>
			<AntSelect dataList={getFontFamilies()} {...linkModel(props.config, "fontName")}/>
		</FormEntry>
		<FormEntry label={i('Font size')}>
			<AntInputNumber {...linkModel(props.config, "fontSize")}/>
		</FormEntry>
		<FormEntry label={i('Font style')}>
			<AntSelect dataList={getFontStyles()} {...linkModel(props.config, "fontStyle")}/>
		</FormEntry>
		<FormEntry label={i('Font color')}>
			<AntInput {...linkModel(props.config, "fontColor")}/>
		</FormEntry>
	</Section>
});

export const textMetricComponentDescription = {
	id: 'ceeview/text',
	title: i('Metric text'),
	config: TextMetricConfig,
	form: TextMetricForm,
	preview: require('./textMetric.png'),
	defaultSize: {
		width: '70px',
		height: '50px'
	}
}
