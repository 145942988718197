import React from "react";
import PropTypes from "prop-types";

import {DropDownList} from "controls/react/kendoWrappers/dropDownList";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {AssetAPI} from "tools/apis/assetAPI";
import {ErrorHandler} from "core/errorHandler";
import {translator} from "core/localization";
import {AddAsset} from "controls/react/windows/addAsset";
import withRequired from 'controls/react/dropdowns/withRequired';
import {inputWithAction} from 'controls/react/form/common';

const i = translator({
  "Select Asset Target...": {
    "no": "Velg ressursmål...",
    "en": "Select asset target..."
  }
});

export class AssetTargetDropDown extends React.PureComponent {
	constructor(props) {
		super(props);

		this.config = {
			dataSource: this.getDataSource(props.assetId),
			autoBind: true,
			dataTextField: 'address',
			dataValueField: 'id',
			placeholder: i('Select Asset Target...'),
			delay: 500,
			dataBound: (e) => this.props.onDataBound && this.props.onDataBound(e)
		};
	}

	getDataSource(assetId) {
		if (!assetId) {
			return [];
		}

		return new CeeViewDataSource({
			transport: {
				read: {
					url: AssetAPI.getTargetsUrl(assetId),
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			error: ErrorHandler.kendoServerError
		});
	}

	updateSource() {
		this.kendoRef.setDataSource(this.getDataSource(this.props.assetId));
	}

	updateValue() {
		this.kendoRef.value(this.props.value);
		this.kendoRef.trigger('change');
	}

	componentDidUpdate(prevProps) {
		if (this.props.assetId !== prevProps.assetId) {
			this.updateSource();
			this.updateValue();
		}
		else if (this.props.value !== prevProps.value) {
			this.updateValue();
		}
	}

	render() {
		const {config, combo, kendoRef, assetId, ...others} = this.props;
		return <div className={inputWithAction()}>
			<DropDownList config={this.config}
			              kendoRef={(ref) => this.kendoRef = ref}
			              combo={true}
			              containerClass={inputWithAction('input')}
			              {...others}/>
			{<AddAsset assetId={assetId}
			           containerClass={inputWithAction('action')}
			           disabled={!assetId}
			           onClose={() => this.kendoRef.dataSource.read()}/>}
		</div>
	}
}

AssetTargetDropDown.propTypes = {
	assetId: PropTypes.string
};

export default withRequired(AssetTargetDropDown);
