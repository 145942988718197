import React from "react"
import {translator} from "core";
import {Button, FormEntry, NumericTextBox} from "controls/react/form";
import {Window} from 'controls/react/kendoWrappers/window';
import {Toolbar, ToolbarItemPosition} from 'controls/react/layout/toolbar';
import {FormLayout} from 'controls/react/layout/formLayout';
import {Section} from "controls/react/layout/section";
import {IconButton} from "controls/react/form/iconButton";

let i = translator({
  "Edit budget": {
    "en": "Edit budget",
    "no": "Rediger budsjett"
  },
  "Empty value tooltip": {
    "en": "Leave empty to reset budget to default value",
    "no": "La feltet stå tomt for å tilbakestille til standardverdi"
  },
  "Current budget": {
    "en": "Current budget",
    "no": "Nåværende budsjett"
  },
  "New budget": {
    "en": "New budget",
    "no": "Nytt budsjett"
  }
});

export class EditBudgetWindow extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			newBudget: null
		};
	}

	render() {
		let windowTitle = i('Edit budget') + ' ' + this.props.editItem.subscription + '/' + this.props.editItem.resourceName;
		return (
			<div>
				<Window height={145}
						width={300}
						modal
						top={this.props.offset.top + 20}
						left={this.props.offset.left - 100}
						title={windowTitle}
						ref={window => this.window = window}
						onClose={this.props.onClose}>
					<Section>
						<FormLayout>
							<FormEntry label={i('Current budget')}>
								<NumericTextBox value={this.props.editItem.monthlyBudget}
												spinners={false}
												enabled={false}
								/>
							</FormEntry>
							<FormEntry label={i('New budget')}>
								<IconButton iconName={"question-sign"} title={i('Empty value tooltip')}/>
								<NumericTextBox value={this.state.newBudget}
												spinners={true}
												onChange={(value) => this.onBudgetChange(value)}
												onSpin={(value) => this.onBudgetChange(value)}
								/>
							</FormEntry>
						</FormLayout>
					</Section>
					<Toolbar>
						<Button title={'Update'}
								primary={true}
								onClick={() => this.onUpdate()}
								position={ToolbarItemPosition.AT_THE_END} />
						<Button title={i('Cancel')}
								primary={false}
								onClick={() => this.props.hideWindow()}
								position={ToolbarItemPosition.AT_THE_END} />
					</Toolbar>
				</Window>
			</div>
		)
	}

	onBudgetChange = (value) => {
		this.setState({
			newBudget: value
		});
	}

	onUpdate = () => {
		this.props.onUpdate(this.state.newBudget);
	}
}

