import './antCollapse.less'

import React from "react"
import classnames from 'classnames'
import { observer } from 'mobx-react'
import {Collapse, CollapseProps, CollapsePanelProps} from 'antd'

const {Panel} = Collapse

interface AntCollapseProps extends CollapseProps{
	size?: 'small' | 'middle' | 'large'
}

const b = require('b_').with('ant-collapse-wrapper')

export const AntCollapse = observer( (props: AntCollapseProps) => {
	let { className,...antNativeProps} = props

	const classes = classnames(b({size: props.size}), className)

	return <Collapse className={classes} {...antNativeProps}/>
})

interface AntCollapsePanelProps extends CollapsePanelProps{

}

export const AntCollapsePanel = observer((props: AntCollapsePanelProps) => {
	return <Panel {...props}/>
})
