const chartDefaultColors = ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];

export function * getChartBarColor(){
    while(true)
        for(const color of chartDefaultColors)
            yield color;
}
const costBarChartDefaultColors = ["#03045E", "#023E8A", "#0077B6", "#0096C7", "#00B4D8", "#48CAE4", "#90E0EF", "#ADE8F4", "#CAF0F8"];

export function * getCostBarChartColor(colorOption){
    if(colorOption === "BLUE")
        while(true)
            for(const color of costBarChartDefaultColors)
                yield color;
    else
        while(true)
            for(const color of chartDefaultColors)
                yield color;
}