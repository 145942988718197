import {fromJS} from "immutable";
import {
	HIDE_VALIDATION,
	RESET,
	SAVE_VALUES,
	SELECT_CATEGORY_ITEM, SET_ASSET_FORM,
	SET_ELEMENT_ID,
	SET_FORM_VALID, SET_IGNORE_RESULTS,
	SET_IN_PROGRESS,
	SET_IS_CREATE_MODE,
	SET_QUALIFIER_DATA,
	SET_QUALIFIER_ID,
	SET_QUALIFIER_INFO,
	SET_QUALIFIER_VALUES,
	SET_QUALIFIER_WINDOW_SIZE,
	SET_SERVICE_ID, SET_SERVICE_TIMEZONE,
	SET_SHOW_VALIDATION,
	SET_STEP,
	SET_VALIDATION_DATA,
	SET_VALIDATION_STATUS,
	SHOW_MULTIPLE_QUALIFIERS_WINDOW,
	SHOW_QUALIFIER_ERROR
} from './reduxActions';
import {formValueSelector} from "redux-form";
import {qualifierWizardForm} from "./constants";
import {setQualifierData} from "./reducers/setQualifierData";
import {SESSION_PERSISTED} from "../../../../controls/react/form/formBuilder/constants";
import Utils from 'tools/utils';
import lang from "core/localization/lang";
import React from "react";
import ReactDom from "react-dom";

const ADDABLE_SELECTORS = new Set(['assetId', 'targetId', 'authId']);

const initial = fromJS({
    step: 'step_1',
    showFieldValidation: false
});

const globalSelector = (selector) => {
    return (state, ...params) => {
        return selector(state.qualifier, ...params);
    }
};

const selectCategoryItem = (state, {id, category}) => {
	return state.setIn(['templateId'], id)
				.setIn(['category'], category);
};

const setStep = (state, {step}) => {
    return state.setIn(['step'], step);
};

const setIsCreateMode = (state, {isCreateMode}) => {
    return state.setIn(['isCreateMode'], isCreateMode);
};

const _isCreateMode = (state) => {
    return !!_getTemplateId(state) || state.get('isCreateMode');
};
export const isCreateMode = globalSelector(_isCreateMode);

const setServiceId = (state, {serviceId}) => {
    return state.setIn(['serviceId'], serviceId);
};

const setElementId = (state, {elementId}) => {
    return state.setIn(['elementId'], elementId);
};

const setQualifierId = (state, {qualifierId}) => {
    return state.setIn(['qualifierId'], qualifierId);
};

const reset = (state) => {
    return initial.setIn(['serviceId'], state.get('serviceId'))
        .setIn(['elementId'], state.get('elementId'));
};

const setInProgress = (state, {status}) => {
    return state.setIn(['inProgress'], status);
};

const setIgnoreResults = (state, {status}) => {
	return state.setIn(['ignoreResults'], status);
};

const setValidationStatus = (state, {status}) => {
    return state.setIn(['validationStatus'], status);
};

const setValidationData = (state, {data}) => {
    const qualifierId = _getQualifierId(state);
    state = state.setIn(['qualifierMap', qualifierId, 'validation', 'state'], data.success)
        .setIn(['qualifierMap', qualifierId, 'validation', 'message'], data.message)
        .setIn(['qualifierMap', qualifierId, 'validation', 'details'], data.data.details)
        .setIn(['qualifierMap', qualifierId, 'validation', 'attachment'], data.data.attachment)
        .setIn(['qualifierMap', qualifierId, 'validation', 'type'], data.type)
        .setIn(['qualifierMap', qualifierId, 'validation', 'show'], true);

    if (data.data.errorFields) {
        for (const field of data.data.errorFields) {
            state = state.setIn(['qualifierMap', qualifierId, 'validation', 'fieldInError', field], true);
        }
    }

    return state;
};

const setShowValidation = (state, {status}) => {
    const qualifierId = _getQualifierId(state);
    return state.setIn(['qualifierMap', qualifierId, 'validation', 'show'], status);
}

const saveValues = (state, {values}) => {
    const qualifierId = _getQualifierId(state);

    return state.setIn(['qualifierMap', qualifierId, 'savedValues'], {...values});
};

const setQualifierWindowSize = (state, {top, left, width, height}) => {
    return state.setIn(['windowSize'], {
        top: Math.round(top),
        left: Math.round(left),
        width: Math.round(width),
        height: Math.round(height)
    });
};

const hideValidation = (state) => {
    const qualifierId = _getQualifierId(state);

    return state.setIn(['qualifierMap', qualifierId, 'validation', 'show'], false);
};

const setShowMultipleQualifierWindow = (state, {status}) => {
    const qualifierId = _getQualifierId(state);
    return state.setIn(['qualifierMap', qualifierId, 'multipleQualifierWindow'], status);
}

function setQualifierValues(state, {values}) {
    const qualifierId = _getQualifierId(state);

    if (!values) {
    	return state;
	}

    for (const [key, value] of Object.entries(values)) {
        if (value && SESSION_PERSISTED.has(key)) {
            state = state.setIn(['persisted', key], value);
        }
    }

    return state.setIn(['qualifierMap', qualifierId, 'values'], values);
}

function setFormValid(state, {isValid}) {
    const qualifierId = _getQualifierId(state);
    return state.setIn(['qualifierMap', qualifierId, 'isFormValid'], isValid);
}

function setQualifierInfo(state, {info}) {
    const qualifierId = _getQualifierId(state);
    return state.setIn(['qualifierMap', qualifierId, 'info'], info);
}

const setAssetForm = (state, {asset}) => {
    const qualifierId = _getQualifierId(state);
    return state.setIn(['qualifierMap', qualifierId, 'assetForm'], {asset});
};

function setServiceTimezone(state, {timeZone}) {
	return state.setIn(['timezone'], timeZone)
}

function showQualifierError(state, result) {
	Utils.showInfo(lang.ALERT, result.message, result.details, null, () => {
		let wizardContainer = document.getElementById('qualifier-wizard-container');
		ReactDom.unmountComponentAtNode(wizardContainer);
		wizardContainer.remove();
	});
	return state;
}

export const qualifierReducer = (state = initial, action) => {
    const {type} = action;

    const map = {
    	[SET_SERVICE_TIMEZONE]: setServiceTimezone,
        [SET_QUALIFIER_DATA]: setQualifierData,
        [SELECT_CATEGORY_ITEM]: selectCategoryItem,
        [SET_STEP]: setStep,
        [SET_SERVICE_ID]: setServiceId,
        [SET_ELEMENT_ID]: setElementId,
        [SET_QUALIFIER_ID]: setQualifierId,
        [RESET]: reset,
        [SET_IN_PROGRESS]: setInProgress,
		[SET_IGNORE_RESULTS]: setIgnoreResults,
        [SET_VALIDATION_STATUS]: setValidationStatus,
        [SET_VALIDATION_DATA]: setValidationData,
        [HIDE_VALIDATION]: hideValidation,
        [SAVE_VALUES]: saveValues,
        [SET_QUALIFIER_WINDOW_SIZE]: setQualifierWindowSize,
        [SET_SHOW_VALIDATION]: setShowValidation,
        [SHOW_MULTIPLE_QUALIFIERS_WINDOW]: setShowMultipleQualifierWindow,
        [SET_QUALIFIER_VALUES]: setQualifierValues,
        [SET_FORM_VALID]: setFormValid,
        [SET_QUALIFIER_INFO]: setQualifierInfo,
        [SET_ASSET_FORM]: setAssetForm,
        [SET_IS_CREATE_MODE]: setIsCreateMode,
		[SHOW_QUALIFIER_ERROR]: showQualifierError
    };

    const fn = map[type];

    if (fn) {
        try {
            return fn(state, action);
        } catch(e) {
            console.log(e);
            return state;
        }
    }

    return state;
};

export const _isFormError = (state) => {
    const qualifierId = _getQualifierId(state);
    return !state.getIn(['qualifierMap', qualifierId, 'isFormValid']);
};
export const isFormError = globalSelector(_isFormError);

const _getSavedValues = (state) => {
    const qualifierId = _getQualifierId(state);

    return state.getIn(['qualifierMap', qualifierId, 'savedValues']);
};
export const getSavedValues = globalSelector(_getSavedValues);

const _getPersistedValues = (state) => {
    return state.get('persisted');
};
export const getPersistedValues = globalSelector(_getPersistedValues);

const _getStep = (state) => {
    return state.get('step');
};
export const getStep = globalSelector(_getStep);

const _hasTest = (state) => {
    const original = _getOriginal(state);

    if (original) {
        return original.configuration.hasTest;
    }
};
export const hasTest = globalSelector(_hasTest);

const _hasPostValidate = (state) => {
    const original = _getOriginal(state);

    if (original) {
        return original.configuration.hasPostValidate;
    }
};
export const hasPostValidate = globalSelector(_hasPostValidate);

const _hasPreValidate = (state) => {
    const original = _getOriginal(state);

    if (original) {
        return original.configuration.hasPreValidate;
    }
}
export const hasPreValidate = globalSelector(_hasPreValidate);

const _getTemplateId = (state) => {
    return state.get('templateId');
};
export const getTemplateId = globalSelector(_getTemplateId);

const _getCategory = (state) => {
    return state.get('category');
};
export const getCategory = globalSelector(_getCategory);

const _getServiceId = (state) => {
    return state.get('serviceId');
};
export const getServiceId = globalSelector(_getServiceId);

export const _getQualifierId = (state) => {
    return state.getIn(['qualifierId']);
};
export const getQualifierId = globalSelector(_getQualifierId);

const _getElementId = (state) => {
    return state.get('elementId');
};
export const getElementId = globalSelector(_getElementId);

export const isNextButtonDisabled = (state) => {
    const step = getStep(state);
    const templateId = getTemplateId(state);

    const isDisabled = isFinishButtonDisabled(state);

    if (isDisabled) {
        return true;
    }

    if (step === 'step_1') {
        return !templateId;
    }
    else if (step === 'step_2') {
        return false;
    }
};

export const getNextTitle = (state) => {
    const step = getStep(state);
	const сreateMode = isCreateMode(state);

    if (step === 'step_1') {
        return lang.NEXT;
    }

	if (сreateMode) {
		return lang.CREATE;
	}

	return lang.UPDATE;
};

export const isTestButtonHidden = (state) => {
    const step = getStep(state);

    const hasTestButton = hasTest(state);

    if (step === 'step_2') {
        return !hasTestButton;
    }

    return true;
};

export const isBackButtonHidden = (state) => {
    const step = getStep(state);
    const templateId = getTemplateId(state);
    const savedValues = getSavedValues(state);

    if (step === 'step_1') {
        return true;
    }
    else if (step === 'step_2') {
        if (templateId && !savedValues) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        return true;
    }
};

export const getCommonFieldValues = (state, field) => {
    return formValueSelector(qualifierWizardForm)(state, field);
};

export const _getQualifierFieldValues = (state) => _getQualifierItem(state, 'values');
export const getQualifierFieldValues = globalSelector(_getQualifierFieldValues);


export const _getQualifierFieldValue = (state, field) => {
    const values = _getQualifierItem(state, 'values') || {};
    return values[field];
};
export const getQualifierFieldValue = globalSelector(_getQualifierFieldValue);

// export const getQualifierFieldValue = (state, field) => {
//     return getCommonFieldValues(state, `properties.${field}`);
// };

const _getQualifierItem = (state, item) => {
    const qualifierId = _getQualifierId(state);

    if (!Array.isArray(item)) {
        item = [item];
    }

    return state.getIn(['qualifierMap', qualifierId, ...item]);
};

const _getFields = (state) => _getQualifierItem(state, 'fields');
export const getFields = globalSelector(_getFields);

const _getValidationStatus = (state) => _getQualifierItem(state, ['validation', 'state']);
export const getValidationStatus = globalSelector(_getValidationStatus);

const _showValidation = (state) => !!_getQualifierItem(state, ['validation', 'show']);
export const showValidation = globalSelector(_showValidation);

const _qualifierInfo = (state) => _getQualifierItem(state, 'info');
export const getQualifierInfo = globalSelector(_qualifierInfo);

const _getValidationDetails = (state) => _getQualifierItem(state, ['validation', 'details']);
export const getValidationDetails = globalSelector(_getValidationDetails);

const _getValidationAttachment = (state) => _getQualifierItem(state, ['validation', 'attachment']);
export const getValidationAttachment = globalSelector(_getValidationAttachment);

const _getValidationMessage = (state) => _getQualifierItem(state, ['validation', 'message']);
export const getValidationMessage = globalSelector(_getValidationMessage);

const _getValidationType = (state) => _getQualifierItem(state, ['validation', 'type']);
export const getValidationType = globalSelector(_getValidationType);

const _isFinishButtonDisabled = (state) => _getQualifierItem(state, ['finishButtonDisabled']);
export const isFinishButtonDisabled = globalSelector(_isFinishButtonDisabled);

const _getFieldData = (state, field) => _getQualifierItem(state, ['fieldData', field]);
export const getFieldData = globalSelector(_getFieldData);

const _getInOriginal = (state, field) => {
    const original = _getOriginal(state) || {};
    return original[field];
}
export const getInOriginal = globalSelector(_getInOriginal);

const _getOriginal = (state) => _getQualifierItem(state, ['original']);
export const getOriginal = globalSelector(_getOriginal);

export function _getTimezone(state) {
	return state.get('timezone')
}

export const getTimezone = globalSelector(_getTimezone);

const _getFieldConfiguration = (state, field) => _getQualifierItem(state, ['fields', field]);
export const getFieldConfiguration = globalSelector(_getFieldConfiguration);

const _isFormEnabled = (state, formIndex) => _getQualifierItem(state, ['formEnabled', formIndex]);
export const isFormEnabled = globalSelector(_isFormEnabled);

const _getAssetForm = (state) => _getQualifierItem(state, ['assetForm']);
export const getAssetForm = globalSelector(_getAssetForm);

const _showMultipleQualifiersWindow = (state) => _getQualifierItem(state, ['multipleQualifierWindow']);
export const showMultipleQualifiersWindow = globalSelector(_showMultipleQualifiersWindow);

const _getQualifierWidth = (state) => {
    const windowSize = state.get('windowSize');

    if (_getStep(state) === 'step_1') {
        return 400;
    }

    return windowSize ? windowSize.width: 830;
};
export const getQualifierWidth = globalSelector(_getQualifierWidth);

const _getQualifierHeight = (state) => {
    const windowSize = state.get('windowSize');

    return windowSize ? windowSize.height: 600;
};
export const getQualifierHeight = globalSelector(_getQualifierHeight);

const _getQualifierLeft = (state) => {
    const windowSize = state.get('windowSize');

    return windowSize ? Math.max(0, windowSize.left): undefined;
};
export const getQualifierLeft = globalSelector(_getQualifierLeft);

const _getQualifierTop = (state) => {
    const windowSize = state.get('windowSize');

    return windowSize ? Math.max(0, windowSize.top): undefined;
};
export const getQualifierTop = globalSelector(_getQualifierTop);

const _showFieldValidation = (state) => {
    return state.get('showFieldValidation');
};
export const showFieldValidation = globalSelector(_showFieldValidation);

const _getFieldsInError = (state) => {
    return _getQualifierItem(state,['validation', 'fieldInError']);
};

export const getFieldsInError = globalSelector(_getFieldsInError);

const _getInProgressStatus = (state) => {
    return state.get('inProgress');
};
export const getInProgressStatus = globalSelector(_getInProgressStatus);

const _getIgnoreResults = (state) => {
	return state.get('ignoreResults');
};
export const getIgnoreResults = globalSelector(_getIgnoreResults);

export const isAddable = (field) => ADDABLE_SELECTORS.has(field);

export default qualifierReducer;
