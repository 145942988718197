import {translator} from "../../core"
import {costTranslates} from "./translations"

const i = translator(costTranslates)

export const getCurrencyConversionErrorString = (message: string) => {
	const regex = /Currency\snot\sfound:\s(\w{3})\/(\w{3})/gm
	const matches = regex.exec(message)
	if (matches != null) {
		const [_, from, to] = matches
		return i('Missing conversion rate for {0} to {1}. Please add conversion in Account/Currency tab.', from, to)
	}
	return null
}

export const getDuplicateLinkErrorString = (message: string) => {
	// "Duplicate hierarchy element: Level 3.2.1"
	const regex = /Duplicate\shierarchy\selement:\s(.*)/gm
	const matches = regex.exec(message)
	if (matches != null) {
		const [_, name] = matches
		return i('{0} is already linked in this Cost Model', name)
	}
	return null
}
