import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import tinymce from 'tinymce';
import {Editor} from 'tinymce';

import {toJS} from "mobx";
import _ from 'lodash';

import {AntModal, ModalPosition} from "controls/react/ant/antModal";

import {components, getComponentDescription} from "vendor-init/tinymce/plugins/components";

import {sharedLocalization} from "vendor-init/tinymce/plugins/localization";
import {ComponentDescription} from "vendor-init/tinymce/plugins/componentDescription";
import {observer} from 'mobx-react';
import {ValidatableModel} from "framework/mobx-integration";

const i = require('core/localization').translator(sharedLocalization)

const isNotDataAttribute = (name: string) => {
	return ["title", 'id', 'type'].find(x => x == name) != null;
}

function getObjectAttributeName(imgAttributeName: string){
	const pureName = imgAttributeName.startsWith('data') ? imgAttributeName.substring(5) : imgAttributeName;
	if(isNotDataAttribute(pureName))
		return pureName;

	return imgAttributeName
}

function getImgAttributeName(objectAttributeName: string){
	if(isNotDataAttribute(objectAttributeName))
		return 'data-' + objectAttributeName;

	return objectAttributeName
}

function getPreviewUrl(imageUrl: string){
	return imageUrl;
}

function updateElementFromConfig(element: HTMLImageElement, config: Record<string, any>){
	const configPlain = toJS(config);
	Object.keys(configPlain).forEach( x => {
		if(x == 'validator')
			return;

		let value = configPlain[x];
		if(Array.isArray(value)){
			value = JSON.stringify(value)
		}

		const attributeName = _.kebabCase('data-' + x);

		if(value === '' || value === null || value === undefined) {
			element.removeAttribute(attributeName);
		}else{
			element.setAttribute(attributeName, value);
		}
	})
}

function updateConfigFromElement(element: HTMLImageElement, config: Record<string, any>){
	element.getAttributeNames().forEach(x => {
		if(!x.startsWith('data'))
			return;

		const propertyName = _.camelCase(x.replace('data-', ''));

		try {
			config[propertyName] = JSON.parse(element.getAttribute(x));
		}catch{
			config[propertyName] = element.getAttribute(x);
		}
	})
}

function editSelectedComponent(editor: Editor){
	const element = editor.selection.getNode() as HTMLImageElement;

	const componentDescription = getComponentDescription(element);
	if(componentDescription == null)
		return;

	const config = new componentDescription.config();
	updateConfigFromElement(element, config);

	// @ts-ignore
	openForm(componentDescription, config, 'update', () => {
		//editor.selection.dom.getAttrib()

		updateElementFromConfig(element, config);

		editor.nodeChanged();
	});
}

tinymce.PluginManager.add('ceeview-components', function(editor, url) {
	editor.on('init', () => {
		const container = editor.getWin().document.body;
		container.addEventListener('dblclick', e => {
			const element = e.target as Element;

			if(element.tagName == 'IMG' && element.getAttribute('data-type')){
				editSelectedComponent(editor);
			}
		})
	})

	for(const componentDescription of components) {
		//const componentDescription = a as ComponentDescription<object>
		editor.ui.registry.addMenuItem('component-' + componentDescription.id, {
			text: componentDescription.title,
			onAction: function () {
				const config = new componentDescription.config();

				// @ts-ignore
				openForm(componentDescription, config, 'create', () => {
					const element = document.createElement("img")
					element.style.width = componentDescription.defaultSize.width;
					element.style.height = componentDescription.defaultSize.height;
					element.setAttribute("data-type", componentDescription.id);
					element.src = getPreviewUrl(componentDescription.preview);
					element.title = componentDescription.title;

					updateElementFromConfig(element, config);

					editor.insertContent(element.outerHTML);
				});
			}
		});
	}

	editor.ui.registry.addMenuItem('component-edit', {
		text: 'Edit component',
		onAction: function() {
			editSelectedComponent(editor);
		}
	});

	editor.ui.registry.addContextMenu('ceeview-components', {
		update: function(element){
			if( !element.getAttribute('data-type')
				|| !getComponentDescription(element)) {
				return '';
			}

			return 'component-edit';
		}
	})

	editor.on('GetContent', function(e) {
		if(!e.content)
			return;

		const container = document.createElement('div');
		container.innerHTML = e.content;

		container.querySelectorAll('img[data-type]')
			.forEach(img => {
				const obj = document.createElement('object');
				img.getAttributeNames().forEach(x => {
					if(x == 'src' || x.startsWith('data-mce') || x == 'title')
						return;

					obj.setAttribute(getObjectAttributeName(x), img.getAttribute(x))
				})

				img.parentNode.appendChild(obj)
				img.parentNode.removeChild(img);
			})

		e.content = container.innerHTML;

		e.content = e.content.replaceAll(/<(img|hr|br)(.*?)>/gm, '<$1$2/>');
	})

	editor.on('BeforeSetContent', function(e) {
		if(!e)
			return;

		const container = document.createElement('div');
		container.innerHTML = e.content;

		container.querySelectorAll('object[type]')
			.forEach(obj => {
				const img = document.createElement('img');
				obj.getAttributeNames().forEach(x => {
					img.setAttribute(getImgAttributeName(x), obj.getAttribute(x))
				})

				const description = getComponentDescription(img);

				if(description == null){
					return
				}

				img.src = getPreviewUrl(description.preview);

				obj.parentNode.appendChild(img)
				obj.parentNode.removeChild(obj);
			});

		e.content = container.innerHTML;
	})
});


const b = require('b_').with('builder-form');
function openForm<T extends ValidatableModel<T>>(description: ComponentDescription<T>, config: T,
                                                 mode: 'create'|'update', onOk: () => void ){
	const container = document.createElement('div')
	container.className = b();

	document.body.appendChild(container);

	const destroyWindow = () => {
		ReactDOM.unmountComponentAtNode(container);
		document.body.removeChild(container);
	}

	ReactDOM.render(
		<ConfigEditor
			description={description}
			mode={mode}
			config={config}
			onCancel={destroyWindow}
			onOk={() => {destroyWindow(), onOk()}}
		/>,
		container );
}

type ConfigEditorProps<T extends ValidatableModel<T>> = {
	description: ComponentDescription<T>,
	config: T,
	onCancel: () => void,
	onOk:() => void;
	mode: 'create'|'update'
}

const ConfigEditor = observer(<T extends ValidatableModel<T>>(props: ConfigEditorProps<T>) =>{
	const Form = props.description.form;
	return <AntModal mode={props.mode}
	                 title={props.description.title}
	                 visible={true}
	                 positionType={ModalPosition.Centered}
	                 onOk={props.onOk}
	                 okButtonProps={{disabled: !props.config.validator.valid}}
	                 onCancel={props.onCancel}>
		<Form config={props.config}/>
	</AntModal>
})
