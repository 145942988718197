import {UrlBuilder} from "tools/urlBuilder";

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/AssetHealth/GroupSummary.jsp',
			url: '/assetgroups',
			getModuleImport: () => import('./assetGroupSummaryView.js'),
			routeId: 'assethealth_group_summary'
		},
		{
			jspUrl: 'include/AssetHealth/AssetGroupDetails.jsp',
			bodyCssClass: 'cw_assethealth_module',
			url: '/assetgroups/:id',
			getModuleImport: () => import('./assetGroupDetails'),
			routeId: 'assethealth_group_details'
		}
	];
}

export class AssetGroupRouter {
	static list(options = {}) {
		return new UrlBuilder('/assetgroups')
			.addObject(options)
			.build();
	}

	static details(assetGroupId) {
		return`/assetgroups/${assetGroupId}`;
	}

	static createNew(){
		return '/assetgroups/new';
	}
}
