import {Utils, applyRequired} from 'tools/utils';
import ConfigurationBase from 'areas/service-boards/configurationBase'
import ErrorHandler from 'core/errorHandler'
import Settings from 'settings'

export function SlaWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {SlaWidgetConfiguration as default};

jQuery.extend(SlaWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_sla_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_sla_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_sla_service">';
		html += '<label class="cw_inline">' + lang.widget.SERVICE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_sla_serviceid" type="text" value="' + (widgetObj.configuration.serviceId || '') + '"/></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_sla_sla">';
		html += '<label class="cw_inline">' + lang.widget.SLA + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_sla_slaid" type="text" value="' + (widgetObj.configuration.slaId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.DECIMALS + ': </label>';
		html += '<div class="cw_numeric_container"><input id="cw_sla_decimals" type="text" value="' + (widgetObj.configuration.decimals || '') + '" /></div>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();
		const requiredFields = ['#cw_sla_accountid', '#cw_sla_serviceid', '#cw_sla_slaid'];
		applyRequired(requiredFields);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {

	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {

	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_sla_accountid'),
			container: $('#cw_sla_account'),
			nextElement: $('#cw_sla_serviceid'),
			events: {
				change: (e) => {
					this.isInputChanged = true;
					this.currentAccountId = e.sender.value();
					this.serviceSelector.dataSource.read();
					this.emptyComponent({
						containers: [{
							container: $('#cw_sla_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_sla_sla'),
							label: lang.widget.SLA
						}],
						length: e.sender.dataSource.data().length
					});
				},
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_sla_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_sla_sla'),
							label: lang.widget.SLA
						}],
						length: length
					});
				}, this)
			}
		});
		this.serviceSelector = $('#cw_sla_serviceid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_sla_sla'),
							label: lang.widget.SLA
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/services/lite?hasModel=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(this.onServiceSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			autoBind: this.mode === true || this.wasUpdate,
			placeholder: lang.SELECT,
			change: (e) => {
				this.isInputChanged = true;
				this.currentServiceId = e.sender.value();
				this.slaSelector.dataSource.read();
				this.emptyComponent({
					containers: [{
						container: $('#cw_sla_history_sla'),
						label: lang.widget.SLA
					}],
					length: e.sender.dataSource.data().length
				});
			}
		}).data('kendoComboBox');

		if (widgetObj.configuration.serviceId) {
			var slaDataSource = new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + (this.currentServiceId || widgetObj.configuration.serviceId) + '/slas/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			});
		}
		this.slaSelector = $('#cw_sla_slaid').kendoComboBox({
			dataSource: (this.mode === 'update' || this.wasUpdate) ? slaDataSource : [],
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			enable: this.mode === 'update' || this.wasUpdate ? true : false,
			placeholder: lang.SELECT,
			autoBind: true
		}).data('kendoComboBox')

		$('#cw_sla_decimals').kendoNumericTextBox({
			decimals: 0,
			min: 0,
			max: 3,
			value: this.mode === 'update' || this.wasUpdate ? undefined : 2,
			format: 'n0',
		});
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var slaCombo = $('#cw_sla_slaid').data('kendoComboBox');
		var slaServiceCombo = $('#cw_sla_serviceid').data('kendoComboBox');
		var decimals = $('#cw_sla_decimals').data('kendoNumericTextBox');
		var slaName = slaCombo.text();
		var title = $('.cw_widget_title').val();
		var serviceName = slaServiceCombo.text().trim();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'sla',
			title: title,
			configuration: {
				accountId: $('#cw_sla_accountid').data('kendoComboBox').value(),
				accountName: this.widgetAccountName,
				slaId: slaCombo.value(),
				serviceId: slaServiceCombo.value(),
				decimals: decimals.value(),
				slaName: slaName,
				serviceName: serviceName
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		var accountCombo = $('#cw_sla_accountid').data('kendoComboBox');
		var slaCombo = $('#cw_sla_slaid').data('kendoComboBox');
		var sCombo = $('#cw_sla_serviceid').data('kendoComboBox');
		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(slaCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SLA;
		}
		if (!Utils.isGuid(sCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE_MODEL;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},
	/**
	 * Handler function for the select event of the SLA service dropdown list
	 * @param {Object} e The select event
	 */
	onServiceSelect: function (e) {
		var slaCombo = $("#cw_sla_slaid").data('kendoComboBox');
		if (e.item) {
			var serviceId = e.dataItem.id;
			if (serviceId && Utils.isGuid(serviceId)) {
				slaCombo.enable(true);
				slaCombo.value('');
				var newSeDataSource = new kendo.ceeview.DataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_sla_sla'),
								label: lang.widget.SLA
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'services/' + serviceId + '/slas/lite',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				slaCombo.setDataSource(newSeDataSource);
				slaCombo.dataSource.read();
			} else {
				slaCombo.text('');
				slaCombo.enable(false);
			}
		}

	}
});
