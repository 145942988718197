const root = '/services';

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Designer/ServiceDesigner.jsp',
			url: root + '/:id/designer',
			getModuleImport: () => import('./designer'),
			routeId: 'service_designer'
		},
		{
			jspUrl: 'include/Designer/ServiceDesigner.jsp',
			url: root + '/:id/designer/revisions/:revisionId',
			getModuleImport: () => import('./designer'),
			routeId: 'service_designer_revision'
		},
		{
			jspUrl: 'include/Designer/ServiceDesigner.jsp',
			url: root + '/:id/designer/draft',
			params: {
				draft: true
			},
			getModuleImport: () => import('./designer'),
			routeId: 'service_draft'
		}
	];
}

export class ServiceDesignerRouter {
	static root(serviceId) {
		return `${root}/${serviceId}/designer`;
	}

	static revision(serviceId, revisionId) {
		return `${root}/${serviceId}/designer/revisions/${revisionId}`;
	}

	static draft(serviceId) {
		return `${root}/${serviceId}/designer/draft`;
	}
}
