import {IncidentsWidget} from "./incidentsWidget";
import {IncidentsWidgetConfiguration} from './incidentsWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: IncidentsWidgetConfiguration,
		defaultConfig: {
			type: 'incidents-grid',
			title: i('Incidents'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		minWidth: 800,
		widget: IncidentsWidget,
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Grid') + '/' + i('Incidents'),
	}
}
