import React from "react"

import {StateWidgetConfiguration} from './stateWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {SlaStateSummary} from "areas/sla/widgets/stateWidget/slaStateSummary";
import {SlaStateDashboardWidgetConfig} from "./slaStateDashboardWidgetConfig"
import {SlaEvent} from "framework/entities/events";
import {apiFetch} from "framework/api"
import {Severity} from "framework/entities/healthData"
import {ApiRequest} from "framework/api"
import {ApplicationState} from "../../../../framework/applicationState";
import {
	StateWidgetProps,
	Subscription, WidgetState,
	withDashboardData,
	withDataLoad
} from "../../../../controls/react/barsStatesWidget";
import {SlaStateWidget} from "../../../views/slaStateWidget";
import {SlaRouter} from "../../bundleDescription";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: StateWidgetConfiguration,
		defaultConfig: {
			type: 'sla-state',
			title: i('State'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		widget: Widget,
		legacyForm: true,
		fullTitle: i('SLA') + '/' + i('State'),
	}
}

const WidgetInner = withDashboardData(withDataLoad(SlaStateWidget, config => apiFetch(getSlaState(config))));

class Widget extends React.Component<StateWidgetProps<SlaStateDashboardWidgetConfig, SlaStateSummary>, WidgetState<SlaStateSummary>> {
	subscriptions: Subscription[];
	constructor(props: StateWidgetProps<SlaStateDashboardWidgetConfig, SlaStateSummary>) {
		super(props);
		this.subscriptions = [
			SlaEvent.subscription(this.props.config)
		];
	}

	render() {
		return <WidgetInner {...this.props} type={this.props.config.type} subscriptions={this.subscriptions} onClick={this.onClick} />
	}

	onClick = (severity: Severity) => {
		let indicator = '';
		switch(severity){
			case Severity.Critical: indicator = 'FAILED'; break;
			case Severity.Major: indicator = 'WARNING'; break;
			case Severity.None: indicator = 'OK'; break;
		}
		const options = {
			indicator: indicator,
			isView: true,
			accountId: this.props.config.accountId,
			includeSubaccounts: this.props.config.includeSubaccounts,
			tags: this.props.config.tags,
			showUntagged: this.props.config.showUntagged
		}
		this.props.navigator.go({url: SlaRouter.list(options)})
	}
}

type GetSlaStateParameters = {
	includeSubaccounts?: boolean,
	tags?: string[],
	showUntagged?: boolean
	accountId?: string,
	ignoreUserTags?: boolean
}

export function getSlaState(p: GetSlaStateParameters) {
	return new ApiRequest<SlaStateSummary>({
		url: 'summary/slaAggregate',
		accountBased: true,
		accountId: p.accountId,
		includeSubaccounts: p.includeSubaccounts,
		subaccountsFilter: true,
		method: 'POST',
		payload: {
			tags: p.tags,
			showUntagged: p.showUntagged,
			ignoreUserTags: p.ignoreUserTags ?? true
		}
	})
}
