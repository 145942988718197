import './datasourceChartWidget.less';
import {ApplicationState} from "framework/applicationState";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {observer} from "mobx-react";
import {WidgetConfig, WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {apiFetch, ApiRequest, ApiResponse, PagedList} from "framework/api";
import {Section, Toolbar} from "controls/react/layout";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AntInput} from "controls/react/ant/antInput";
import {AccountSelector} from "controls/react/accountSelector";
import {AntSelect} from "controls/react/ant/antSelect";
import React from "react";
import {
	DataSource,
	getDataSourceData,
	getDataSourceFiltersConfiguration,
	getDataSources
} from "controls/designer/features/widgets/datasources/dataSourceGridWidget";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {makeAutoObservable} from "mobx";
import {BoxView} from "controls/react/layout/boxView";
import b_ from "b_";
import Highcharts, {Chart, DrilldownEventObject, DrillupEventObject, Options} from "highcharts";
import Configuration from "configuration";
import {formatNumber} from "tools/helpers/math";
import {getCostBarChartColor,} from "controls/designer/features/widgets/cost/budget/chartDefaultColors";
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {GridDataItem} from "controls/grid/gridDataItem";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {MobxManager} from "framework/mobx-integration";
import {ColorResult} from "react-color";
import {LegendColorSettings} from "controls/designer/features/widgets/legendColorSettings";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {Link} from "controls/react/link";
import {throttle} from "lodash";
import {EventsTimePeriods} from "areas/summary/events/eventsSummaryPage";
import {GridStore, linkGridAdditionalPayload} from "controls/grid/gridStore";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {GridSearchInput} from "controls/grid/gridSearchInput";
import {Grid} from "controls/grid/grid";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import moment from "moment/moment";
import {ModalPosition, openModal} from "controls/react/ant/antModal";
import {TimePeriod, TimePeriodAppearance, TimePeriodSelector} from "controls/react/form/timePeriodSelector";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {debounce} from "lodash";
import {AntCheckbox} from "controls/react/ant/antCheckbox";

const i18n = require('core/localization').translator({
	'Select the desired columns from datasource. Only the selected columns will appear in the chart, allowing for customized data visualization.': {
		no: 'Velg de ønskede kolonnene fra datakilden. Bare de valgte kolonnene vil vises i diagrammet, noe som gir mulighet for tilpasset datavisualisering.'
	},
	'Pie': {no: 'Pie'},
	'3D Pie': {no: '3D Pie'},
	'Vertical': {no: 'Vertikal'},
	'Horizontal': {no: 'Horisontal'},
	'Chart type': {no: 'Diagramtype'},
	'Chart sub type': {no: 'Diagram undertype'},
	'Headers column': {no: 'Headers column'},
	'Bar chart': {no: 'Søylediagram'},
	'Pie chart': {no: 'Sektordiagram'},
}, sharedLocalization)

const b = b_.with('data-sources-chart');

const getChartTypeOptions = () => {
	return [
		{name: i18n('Bar chart'), id: 'bar'},
		{name: i18n('Pie chart'), id: 'pie'}
	]
}

const getPieSubTypeOptions = () => {
	return [
		{name: i18n('Pie'), id: '2d-pie', type: 'pie'},
		{
			name: i18n('3D Pie'),
			id: '3d-pie',
			type: 'pie',
			chart3DOptions: {
				enabled: true,
				alpha: 45,
				beta: 0
			}
		},
		{
			name: i18n('Donut'),
			id: 'donut',
			type: 'pie',
			series: {innerSize: '75%'}
		},
	]
}

const getBarSubTypeOptions = () => {
	return [
		{name: i18n('Vertical'), id: 'column', type: 'column'},
		{name: i18n('Horizontal'), id: 'bar', type: 'bar'},
		{name: i18n('Vertical stacked'), id: 'column-stacked', type: 'column'},
		{name: i18n('Horizontal stacked'), id: 'bar-stacked', type: 'bar'},
	]
}

export function getWidgetDescription() {
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'data-sources-chart',
			title: i18n('Datasource chart'),
			accountId: ApplicationState.accountId
		},
		minWidth: 800,
		widget: DataSourcesChartWidget,
		fullTitle: i18n('Datasource chart'),
	}
}
type ColorRow = {name: string, color: ColorResult, key: string};

export interface DataSourcesChartWidgetConfig extends Omit<WidgetConfig, 'accountId'> {
	dataSourceId: string
	accountId: string
	chartType: string
	columns: string[]
	chartSubType: string
	legendColumn: string
	legendColors: ColorRow[]
	decimalsNumber: number,
	pivotData: boolean
}

export const WidgetConfiguration = observer(class InnerConfig extends React.Component<WidgetConfigurationProps<DataSourcesChartWidgetConfig>> {
	store: DataSourcesChartWidgetConfigurationStore;
	constructor(props: WidgetConfigurationProps<DataSourcesChartWidgetConfig>) {
		super(props);
		this.store = new DataSourcesChartWidgetConfigurationStore(props);
	}

	async componentDidMount() {
		await this.store.init();
	}

	componentWillUnmount() {
		this.store?.destroy()
	}
	render() {
		return <Section appearance={"none"}>
			<Section appearance={"frame-top-only"} title={i18n('General')} childrenPadding={true}>
				<FormEntryNew label={i18n('Title')} model={this.store.props.config} modelField={'title'}>
					<AntInput/>
				</FormEntryNew>
				<FormEntryNew label={i18n('Chart type')} model={this.store.props.config} modelField={'chartType'}>
					<AntSelect nameField={"name"} dataList={this.store.chartTypes}/>
				</FormEntryNew>
				{this.props.config.chartType == 'pie' &&
					<FormEntryNew label={i18n('Chart sub type')} model={this.store.props.config} modelField={'chartSubType'}>
						<AntSelect nameField={"name"} dataList={this.store.pieSubTypes}/>
					</FormEntryNew>}
				{this.props.config.chartType == 'bar' &&
					<FormEntryNew label={i18n('Chart sub type')} model={this.store.props.config} modelField={'chartSubType'}>
						<AntSelect nameField={"name"} dataList={this.store.barSubTypes}/>
					</FormEntryNew>}
			</Section>
			<Section appearance={"frame-top-only"} title={i18n('Data')} childrenPadding={true}>
				<FormEntryNew label={i18n('Account')} model={this.store.props.config} modelField={'accountId'}>
					<AccountSelector/>
				</FormEntryNew>
				<FormEntryNew label={i18n('Datasource')} model={this.store.props.config} modelField={'dataSourceId'}>
					<AntSelect dataList={this.store.dataSources} nameField={"name"} valueField={'dataSourceId'}/>
				</FormEntryNew>
				<FormEntryNew label={i18n('Category')} model={this.props.config} modelField={'legendColumn'}>
					<AntSelect dataList={this.store.legendOptions} nameField={"name"} valueField={'id'}/>
				</FormEntryNew>
				<FormEntryNew model={this.props.config} modelField={'pivotData'}>
					<AntCheckbox>{i18n('Pivot datasource')}</AntCheckbox>
				</FormEntryNew>
				<FormEntryNew label={i18n('Selection')}
							  model={this.props.config}
							  modelField={'columns'}
							  headerAdditionalContent={<QuestionCircleOutlined
								  title={i18n("Select the desired columns from datasource. Only the selected columns will appear in the chart, allowing for customized data visualization.")}/>}>
					<AntSelect mode={"multiple"} nameField={"name"} dataList={this.store.columnsOptions}/>
				</FormEntryNew>
				<FormEntryNew label={i18n('Decimal Numbers')} model={this.store.props.config} modelField={'decimalsNumber'}>
					<AntInputNumber min={0} max={8} defaultValue={0} />
				</FormEntryNew>
			</Section>
			<FormEntryNew model={this.store.props.config} modelField={'legendColors'}>
				<LegendColorSettings legend={this.store.columnsOptions}
									 columns={this.store.props.config.columns}/>
			</FormEntryNew>
		</Section>
	}
})

const TimePeriods = [TimePeriodType.Last24Hours, TimePeriodType.Last7Days,
	TimePeriodType.Last30Days, TimePeriodType.Custom, TimePeriodType.DynamicInterval, TimePeriodType.All, TimePeriodType.LastN];

export const DataSourcesChartWidget = observer(class InnerWidget extends React.Component<WidgetProps<DataSourcesChartWidgetConfig>> {
	store: DataSourcesChartWidgetStore

	constructor(props: WidgetProps<DataSourcesChartWidgetConfig>) {
		super(props)
		this.store = new DataSourcesChartWidgetStore(props)
	}

	render() {
		return (
			<DefaultWidgetWrapper {...this.props} appearance={"none"}>
				<Section appearance={"none"} height={"fit"} overlaySpinner={this.store.loading}
						 contentOverlay={this.store.loading} contentClass={b()}>
					{this.store.noDataAvailable && <BoxView type={'error'}>{this.store.errorMessage}</BoxView>}
					<Toolbar>
						<TimePeriodSelector periods={TimePeriods}
											appearance={TimePeriodAppearance.Buttons}
											size={"small"}
											value={this.store.timePeriod}
											onChange={this.store.onPeriodChange} />
					</Toolbar>
					<Link navigator={this.props.navigator} onClick={() => {}}>
						<div ref={this.store.chartContainerRef} className={b('chart-container')}></div>
					</Link>
				</Section>
			</DefaultWidgetWrapper>
		)
	}

	async componentDidMount() {
		await this.store.init();
	}

	componentWillUnmount() {
		this.store?.destroy()
	}

	onGeometryChanged() {
		this.store.drawChart();
	}
})

export class DataSourcesChartWidgetStore {
	loading: boolean = false;
	noDataAvailable: boolean = false;
	errorMessage: string;
	chartContainerRef = React.createRef<HTMLDivElement>();
	chart: Highcharts.Chart;
	dataBuilders: { [id: string]: (e?: DrilldownEventObject) => Promise<any[]> } = {}
	drillDownRequestPayloadBuilders: { [id: string]: (key: string, e: DrilldownEventObject) => {drillDownField: string, drillDownData: {[key: string]: any}} } = {}
	datasourceRuleConfig: RulesConfiguration;
	data: PagedList<GridDataItem<any>>;

	drilledDownDatasourceRuleConfig: RulesConfiguration[] = []
	drilledDownData: PagedList<GridDataItem<any>>[] = []
	timePeriod: TimePeriod = {period: TimePeriodType.Last24Hours};

	gridStore: GridStore<any>

	private subTypes: any[];

	constructor(private props: WidgetProps<DataSourcesChartWidgetConfig>) {
		this.dataBuilders['column'] = this.buildBarData
		this.dataBuilders['bar'] = this.buildBarData
		this.dataBuilders['pie'] = this.buildPieData
		this.drillDownRequestPayloadBuilders['column'] = this.buildBarDataPayload
		this.drillDownRequestPayloadBuilders['bar'] = this.buildBarDataPayload
		this.drillDownRequestPayloadBuilders['pie'] = this.buildPieDataPayload
		this.subTypes = [...getPieSubTypeOptions(), ...getBarSubTypeOptions()];
		makeAutoObservable(this);
	}

	init = async () => {
		const confRequest = this.getDataSourceFiltersConfiguration();
		const dataRequest = this.getDataRequest();

		const datasourceRuleConfig = await apiFetch(confRequest);
		const data = await apiFetch(dataRequest);

		if (datasourceRuleConfig.success && data.success) {
			this.datasourceRuleConfig = datasourceRuleConfig.data;
			this.data = data.data;
			await this.drawChart();
		} else {
			this.errorMessage = [datasourceRuleConfig.message, data.message].filter(x => !!x).join(';\r\n');
		}
	}

	drawChart = async () => {
		const series = await this.dataBuilders[this.props.config.chartType]?.() ?? []
		const categoryKey = Object.keys(this.datasourceRuleConfig).find(key => this.datasourceRuleConfig[key].label === (this.props.config.legendColumn || 'rowHeader'));
		const categories = this.data.items.map(row => row.data[categoryKey ?? (this.props.config.legendColumn || 'rowHeader')]);
		this.chart = new Highcharts.Chart(this.chartContainerRef.current, this.commonChartSettings(series, categories));
		//workaround to remove all option color. Seems like highcharts does not support that out of the box
		$(this.chartContainerRef.current).find('.chart-all-option').closest('g').find('rect').remove();
	}

	onPeriodChange = debounce((period: TimePeriod) => {
		this.timePeriod = period;
		this.chart?.destroy();
		this.init();
	}, 1000)

	get chartType() {
		return this.subTypeData?.type ?? 'column';
	}

	get options3d() {
		return this.subTypeData?.chart3DOptions;
	}

	get subTypeData() {
		return this.subTypes.find(x => x.id === this.props.config.chartSubType);
	}

	get ignoreColumnFiltering() {
		return this.props.config.pivotData || this.drilledDownData.length > 0;
	}

	destroy() {
		this.chart?.destroy();
	}

	commonChartSettings = (chartSeries: any[], categories: string[]): Options => {
		const store = this;
		return {
			chart: {
				type: this.chartType,
				options3d: this.options3d,
				backgroundColor: null,
				events: {
					drilldown: function (this: Chart, e: DrilldownEventObject) {
						// store.drilledDownDatasourceRuleConfig.push(store.datasourceRuleConfig)
						// store.drilledDownData.push(store.data)
						// store.onDrillDown(this, e);
						store.onDrillDownGridEditionThrottled(this, e);
					},
					// invokes for every series item, but we need only one
					drillup: throttle(function(this: Chart, e: DrillupEventObject) {
						// store.datasourceRuleConfig = store.drilledDownDatasourceRuleConfig.pop();
						// store.data = store.drilledDownData.pop();
					}, 500, {trailing: false})
				}
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			exporting: Configuration.highcharts.exporting,
			legend: {
				enabled: true,
				align: 'right',
				verticalAlign: 'top',
				backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'none',
				shadow: false,
				labelFormatter: function () {
					return `${this.name}`;
				}
			},
			xAxis: {
				categories: categories,
			},
			yAxis: {
				title: {text: ''}
			},
			tooltip: {
				backgroundColor: 'white',
				borderWidth: 0,
				shadow: {
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				headerFormat: '<b>{point.x}</b><br/>',
				formatter: function (e) {
					return `${this.point.name ?? this.series.name}<br/>
					${i18n('Value')}: ${formatNumber(this.point.y, store.props.config.decimalsNumber,)}`;
				}
			},
			plotOptions: {
				bar: {
					pointWidth: 20,
					groupPadding: 0.001,
				},
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					depth: 35,
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				},
				column: {
					stacking: this.chartType == 'pie' ? undefined : 'normal',
					dataLabels: {
						formatter: function (e) {
							let formattedNumber = formatNumber(this.y, store.props.config.decimalsNumber,);
							if (
								parseInt(formattedNumber) === 0 &&
								formattedNumber % 1 === 0
							) {
								return null;
							} else {
								return formattedNumber;
							}
						},
						style: {
							textOutline: 'none'
						}
					}
				},
				series: {
					stacking: this.chartType == 'pie' ? undefined : 'normal',
					dataLabels: {
						formatter: function (e) {
							let formattedNumber = formatNumber(this.y, store.props.config.decimalsNumber,);
							if (
								parseInt(formattedNumber) === 0 &&
								formattedNumber % 1 === 0
							) {
								return null;
							} else {
								return formattedNumber;
							}
						},
						style: {
							textOutline: 'none'
						}
					}
				},
			},
			series: chartSeries,
			drilldown: {
				drillUpButton: {
					relativeTo: 'spacingBox',
					position: {
						align: 'left',
						y: 0,
						x: 0
					}
				}
			},
			lang: {
				drillUpText: i18n('Back')
			}
		}
	}

	onDrillDownGridEditionThrottled = throttle((chart: Chart, e: DrilldownEventObject) => {
		this.onDrillDownGridEdition(chart, e);
		console.log('onDrillDownGridEdition')
	}, 300, {trailing: false})

	onDrillDownGridEdition = async (chart: Chart, e: DrilldownEventObject) => {
		let key = Object.keys(this.datasourceRuleConfig)
			.filter(key => this.datasourceRuleConfig[key].type == "integer" && (this.props.config.columns.includes(this.datasourceRuleConfig[key].label) || this.props.config.columns.includes(key)))
			.find(key => this.datasourceRuleConfig[key].label == (e.point.name ?? e.point.series.name));

		const confRequest = this.getDataSourceFiltersConfiguration(key, e);
		const filtersResponse = await apiFetch(confRequest)

		this.gridStore = new GridStore<any>({
			columns: this.getColumns(filtersResponse.data),
			dataProvider: new RemoteDataProvider({
				dataSource: this.getDataRequest(key, e),
				filtersSource: confRequest
			}),
			defaults:{
				payload: {
					timePeriod: {
						period: TimePeriodType.Last24Hours
					},
					showHistoricEvents: false
				}
			},
			selection: GridSelectionType.None
		})
		await openModal({
			title: e.point.name,
			width: 800,
			height: 350,
			resizable: true,
			defaultFooterButtons: [],
			mask: false,
			positionType: ModalPosition.Mouse,
			hideFooter: true
		}, <DrilledDownModal store={this} />);
	}

	getColumns(rules: RulesConfiguration){
		return Object.keys(rules)
			.filter(x => x != 'id')
			.map(columnName => {
			let column : GridColumnConfig<any> = {
				field: columnName,
				// hack from Bernt
				title: rules[columnName].label != 'rowHeader' ? rules[columnName].label : '',
				renderer: item => {
					if (rules[columnName].type == 'integer') {
						return item[columnName]?.toFixed(this.props.config.decimalsNumber ?? 0) ?? '';
					}
					if (rules[columnName].type == 'date' && !!item[columnName]) {
						return moment(item[columnName]).format(ApplicationState.momentDateTimeFormat);
					}
					return item[columnName] ?? '';
				}
			}

			return column
		})
	}

	onDrillDown = async (chart: Chart, e: DrilldownEventObject) => {
		let key = Object.keys(this.datasourceRuleConfig)
			.filter(key => this.datasourceRuleConfig[key].type == "integer" && (this.props.config.columns.includes(this.datasourceRuleConfig[key].label) || this.props.config.columns.includes(key) || this.ignoreColumnFiltering))
			.find(key => this.datasourceRuleConfig[key].label == e.point.name);

		const confRequest = this.getDataSourceFiltersConfiguration(key, e);
		this.datasourceRuleConfig = (await apiFetch(confRequest))?.data ?? JSON.parse(JSON.stringify(this.datasourceRuleConfig));

		const dataRequest = this.getDataRequest(key, e);
		this.data = (await apiFetch(dataRequest))?.data;

		const chartData = await this.dataBuilders[this.props.config.chartType]?.(e);
		const series = chartData.find(x => x.name == e.point.series.name);
		chart.addSeriesAsDrilldown(e.point, series);
	}

	buildPieData = async (e?: DrilldownEventObject) => {
		const barColorFn = getCostBarChartColor('BLUE');
		let keys = Object.keys(this.datasourceRuleConfig)
			.filter(key => this.datasourceRuleConfig[key].type == "integer" && (this.props.config.columns.includes(this.datasourceRuleConfig[key].label) || this.props.config.columns.includes(key) || this.ignoreColumnFiltering));

		const seriesData = this.data.items.map((item) => {
			const color = barColorFn.next().value;
			return {
				name: item.data[this.props.config.legendColumn || 'rowHeader'],
				y: keys.reduce((r, key) => {
					return r + item.data[key]
				}, 0),
				drilldown: true,
				color: color
			};
		})

		const data = {
			type: 'pie',
			colorByPoint: true,
			data: seriesData
		};
		const seriesOptions = this.subTypes.find(x => x.id === this.props.config.chartSubType)?.series;
		if (seriesOptions) {
			Object.assign(data, seriesOptions);
		}
		return [data];
	}
	buildBarData = async (e?: DrilldownEventObject) => {
		const barColorFn = getCostBarChartColor('BLUE');
		const keys = Object.keys(this.datasourceRuleConfig)
			.filter(key => this.datasourceRuleConfig[key].type == "integer" && (this.props.config.columns.includes(this.datasourceRuleConfig[key].label) || this.props.config.columns.includes(key) || this.ignoreColumnFiltering));


		return keys.map((key, colIndex) => {
			const barColor = this.props.config.legendColors?.find(x => x.name == this.datasourceRuleConfig[key].label)?.color.hex ?? barColorFn.next().value;
			const sData = this.data.items.map((row, rowIndex) => ({
				y: row.data[key],
				drilldown: true,
				id: rowIndex
			}))
			return {
				name: this.datasourceRuleConfig[key].label,
				data: sData,
				stack: (this.subTypeData?.id ?? '').endsWith('-stacked') ? 'main' : barColor,
				color: barColor
			}
		});
	}

	getDataRequest = (key?: string, e?: DrilldownEventObject) => {
		const dataRequest = getDataSourceData(this.props.config);
		dataRequest.payload = {}
		dataRequest.payload.filter = {}
		dataRequest.payload.sort = []
		dataRequest.payload.skip = 0
		dataRequest.payload.take = 999
		dataRequest.payload.timePeriod = this.timePeriod
		const additionalPayload = this.drillDownRequestPayloadBuilders[this.props.config.chartType]?.(key, e)
		Object.assign(dataRequest.payload, additionalPayload);
		return dataRequest;
	}

	getDataSourceFiltersConfiguration = (key?: string, e?: DrilldownEventObject) => {
		const payload: any = {};
		const additionalPayload = this.drillDownRequestPayloadBuilders[this.props.config.chartType]?.(key, e)
		Object.assign(payload, additionalPayload);

		return new ApiRequest<RulesConfiguration>({
			url: `dataSources/${this.props.config.dataSourceId}/ruleConfiguration`,
			accountId: this.props.config.accountId,
			accountBased: true,
			method: 'POST',
			payload
		})
	}

	buildPieDataPayload = (key?: string, e?: DrilldownEventObject) => {
		const columnHeader = this.props.config.legendColumn ?? 'rowHeader';
		const initial: {[key: string]: any} = {};
		if (e) {
			initial[columnHeader] = e.point.name;
		}

		return {
			drillDownField: key,
			drillDownData: key ? this.data.items.reduce((r: {[key: string]: any}, c) => {
				r[c.data[columnHeader]] = c.data[key];
				return r;
			}, initial) : undefined,
			pivotData: this.props.config.pivotData,
			categoryColumn: this.props.config.legendColumn
		};
	}

	buildBarDataPayload = (key?: string, e?: DrilldownEventObject) => {
		return {
			drillDownField: key,
			drillDownData: key ? this.data.items[e.point.x].data : undefined,
			pivotData: this.props.config.pivotData,
			categoryColumn: this.props.config.legendColumn
		};
	}
}

type ColumnOptions = {id: string, name: string}
type ChartTypeOptions = {id: string, name: string, type?: string, chart3DOptions?: any}

export class DataSourcesChartWidgetConfigurationStore {
	columnsOptions: ColumnOptions[];
	legendOptions: ColumnOptions[];
	datasourceRuleConfig: RulesConfiguration;
	chartTypes: ChartTypeOptions[];
	pieSubTypes: ChartTypeOptions[];
	barSubTypes: ChartTypeOptions[];
	dataSources: DataSource[];

	mobx = new MobxManager();
	constructor(public props: WidgetConfigurationProps<DataSourcesChartWidgetConfig>) {
		makeAutoObservable(this);
		this.mobx.reaction(x => this.props.config.accountId, () => this.props.config.dataSourceId = undefined);
		this.mobx.reaction(x => this.props.config.dataSourceId, () => {
			this.props.config.columns = [];
			this.loadColumns();
		});
		this.mobx.reaction(x => this.props.config.columns, x => this.props.config.legendColors = [])
		this.mobx.reaction(x => this.props.config.chartType, x => this.props.config.chartSubType = undefined);
	}

	init = async () => {
		this.props.config.decimalsNumber ??= 0;
		await this.loadColumns();

		this.chartTypes = getChartTypeOptions();
		this.pieSubTypes = getPieSubTypeOptions();
		this.barSubTypes = getBarSubTypeOptions();
		this.dataSources = (await apiFetch(getDataSources({accountId: this.props.config.accountId}))).data;
	}

	loadColumns = async () => {
		if (!this.props.config.dataSourceId || !this.props.config.accountId) {
			this.columnsOptions = []
			return;
		}
		const datasourceRuleConfig = await apiFetch(getDataSourceFiltersConfiguration(this.props.config));
		if (datasourceRuleConfig.success) {
			this.datasourceRuleConfig = datasourceRuleConfig.data;

			this.columnsOptions = Object.keys(datasourceRuleConfig.data)
				.filter(key => datasourceRuleConfig.data[key].type == "integer")
				.map(x => ({
					name: datasourceRuleConfig.data[x].label,
					id: x
				}));
			this.legendOptions = Object.keys(datasourceRuleConfig.data)
				.filter(key => datasourceRuleConfig.data[key].type == "text" && key != 'id')
				.map(x => ({
						name: datasourceRuleConfig.data[x].label || (x == 'rowHeader' ? 'Default' : '< Empty name >'),
						id: x
					}));
		} else {
			this.columnsOptions = []
		}
	}

	destroy() {
		this.mobx.destroy();
	}
}

type DrilledDownModalProps = {
	store: DataSourcesChartWidgetStore
};

export const DrilledDownModal = observer(class InnerModal extends React.Component<DrilledDownModalProps> {
	store: DataSourcesChartWidgetStore;
	constructor(props: DrilledDownModalProps) {
		super(props);
		this.store = props.store;
	}

	render() {
		return <Section appearance="none" height={"fit"}>
			<Toolbar>
				<TimePeriodSelector periods={EventsTimePeriods}
									appearance={TimePeriodAppearance.Buttons}
									size={"small"}
									{...linkGridAdditionalPayload(this.store.gridStore, "timePeriod")}/>
				<DataSizeIndicator store={this.store.gridStore}/>
				<GridSearchInput store={this.store.gridStore}/>
			</Toolbar>
			<Grid store={this.store.gridStore}/>
		</Section>
	}
})
