export enum MonitorType {
	FileSearch = 'FILESEARCH',
	Snmp = 'SNMP',
	SnmpTrap = 'SNMPTRAP',
	Vmm = "VMM",
	XenServer = 'XEN_SERVER',
	RemoteSystem = 'REMOTESYSTEM',
	RemoteSeries = 'REMOTEISERIES',
	Metric = 'METRICMONITOR',
	Log = 'LOGMONITOR',
	Database = 'DATABASE',
	EatonUps = 'EATONUPS',
	Modbus = 'MODBUS',
	Url = 'URL',
	DbWatch = 'DBWATCH',
	Script = 'SCRIPT',
	Container = 'CONTAINER',
	InterfaceTraffic = 'INTERFACETRAFFIC',
	System = 'SYSTEM',
	VmWare = 'VMWARE',
	External = 'EXTERNAL',
	NimSoft = 'NIMSOFT',
	WindowsServices = 'WINSERVICE',
	WindowsEvents = 'WINEVENTS',
	HyperV = 'HYPERV',
	Nagios = 'NAGIOS',
	Groovy = 'GROOVY',
	IbmSvc = 'IBMSVC',
	MsCluster = 'MSCLUSTER',
	MailGateway = 'MAILGTW',
	Prtg = 'PRTG',
	SolarWinds = 'SOLARWINDS',
	Connectivity = 'CONNECTIVITY',
	AzureBilling = 'AZUREBILLING',
	AmazonBilling = 'AMAZONBILLING',
	Processes = 'PROCESSES',
	Webhook = 'WEBHOOK',
	Icinga = 'ICINGA',
	ProcessesNew = 'PROCESSMONITOR',
	Azure = 'AZURE',
	LinuxServices = 'LINUXSERVICES'
}
