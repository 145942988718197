import './dataSizeIndicator.less'

import {observer} from "mobx-react"
import {Spin} from "antd"
import React from "react"

import {GridStore} from "controls/grid/gridStore"
import {GridDataEntry} from "controls/grid/gridDataEntry"

export type DataSizeIndicatorProps<DataItem extends GridDataEntry> = {
	store: GridStore<DataItem>
}

const b = require('b_').with('data-size-indicator')

export const DataSizeIndicator = observer(<DataItem extends GridDataEntry, >(props: DataSizeIndicatorProps<DataItem>) => {
	const {dataProvider} = props.store
	const initialized = dataProvider.initialized == true

	return <div className={b()}>
		{!initialized && <Spin spinning={true}/>}
		{initialized && <>{dataProvider.visibleRowsCount}/{dataProvider.totalRowsCount}</>}
	</div>
})
