import {Api} from "tools/api";
import {useEffect, useState} from 'react';
import {ApiRequest, apiFetch, ApiRequestObservable} from "framework/api";
import {Account} from "areas/assets/monitors/system/api";
import {Account as SelectorAccount} from 'controls/accountSelector';
import {AccountLite} from "framework/entities/account";

export class AccountsApi {
	static getBaseUrl(accountId: string = null, sessionId?: string) {
		return Api.sessionAndAccountRoot(accountId, sessionId);
	}

	static getLiteUrl(accountId: string, sessionId?: string) {
		return `${AccountsApi.getBaseUrl(accountId, sessionId)}subaccounts/allLevels/lite`;
	}

	static getAccount(accountId: string, sessionId: string) {
		return Api.fetch(`${AccountsApi.getBaseUrl(accountId, sessionId)}`);
	}

	static getAccountsLite(accountId: string, sessionId?: string, cache?: Record<string, any>){
		return Api.fetch(AccountsApi.getLiteUrl(accountId, sessionId), null, cache, "accounts-lite" + accountId);
	}

	static getAccountConfigurationUrl(id: string) {
		return `${Api.serverRoot()}accounts/${id}?update=true`;
	}

	static getAccountConfiguration(id: string) {
		return Api.fetch(AccountsApi.getAccountConfigurationUrl(id));
	}

	static saveAccountConfiguration(accountObj: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts`, accountObj)
	}

	static getConfigurations(id: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations`)
	}

	static deleteSingleConfiguration(id: string, configurationId: string) {
		return Api.fetchDelete(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}`)
	}

	static deleteMultipleConfigurations(id: string, ids: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/delete`, ids)
	}

	static getRegistrations(id: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/registrations`)
	}

	static getConfigurationDetails(id: string, factoryName: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/registrations/${factoryName}`)
	}

	static getProfiles(id: string, configurationId: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/registrations`)
	}

	static getProfileDetails(id: string, configurationId: string, factoryName: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/registrations/${factoryName}`)
	}

	static saveProfileDetails(id: string, configurationId: string, profileObj: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles`, profileObj)
	}

	static deleteSingleProfile(id: string, configurationId: string, profileId: object) {
		return Api.fetchDelete(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${profileId}`)
	}

	static deleteMultipleProfiles(id: string, configurationId: string, ids: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/delete`, ids)
	}

	static saveConfiguration(accountId: string, continueEditing: boolean, configObj: object) {
		var url = `${Api.serverRoot()}accounts/${accountId}/configurations`;
		if (continueEditing) {
			url += '?continueEditing=true';
		}
		return Api.fetchPost(url, configObj)
	}

	static getPropertyData(id: string, configurationId: string, propertyId: string, propertyObj: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/properties/${propertyId}/data`, propertyObj)
	}

	static refreshProfilesGrid(id: string, configurationId: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles`)
	}

	static getConfigurationData(id: string, configurationId: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}?update=true`)
	}

	static getAuthToken(id: string) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/authenticationToken`)
	}

	static deleteAuthToken(id: string) {
		return Api.fetchDelete(`${Api.serverRoot()}accounts/${id}/authenticationToken`)
	}

	static editProfile(id: string, configurationId: string, profileId: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${profileId}?update=true`)
	}

	static testConfiguration(id: string, configurationId: string, configurationObj: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/test`, configurationObj)
	}

	static postValidateConfiguration(id: string, configurationId: string, configurationObj: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/postValidate`, configurationObj)
	}

	static testProfile(id: string, configurationId: string, profileId: string, profileObj: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${profileId}/test`, profileObj)
	}

	static postValidateProfile(id: string, configurationId: string, profileId: string, profileObj: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${[profileId]}/postValidate`, profileObj)
	}

	static releaseConfiguration(id: string, configurationId: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/release`)
	}

	static releaseProfile(id: string, configurationId: string, profileId: string) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${profileId}/release`)
	}
	static integrateAdModule() {
		return Api.fetchPost(`management/modules/install?INTEGRATION_LDAP`, ['INTEGRATION_LDAP'])
	}
	static getLicenseStatus(license: object) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/licenseInfo`, license)
	}
}

export type GetAccountsLiteArgs = {
	accountId?: string
}

export function getAccountsLite(args?: GetAccountsLiteArgs){
	return new ApiRequestObservable<Account[]>({
		url: 'subaccounts/allLevels/lite',
		accountId: args?.accountId,
		accountBased: true,
	})
}


export type GetAccountsFirstLevelOnlyArgs = {
	accountId?: string
}

export function getAccountsFirstLevelOnly(args?: GetAccountsLiteArgs){
	return new ApiRequestObservable<Account[]>({
		url: 'subaccounts/firstLevel',
		accountId: args?.accountId,
		accountBased: true,
	})
}

export function useAccountsLite(accountId?: string) {
	const [accountsList, setAccountsList] = useState<AccountLite[]>([]);
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		apiFetch(getAccountsLite({accountId}))
			.then(response => {
				if(response.success) {
					setAccountsList(response.data)
				}
				setLoading(false)
			})
	}, [accountId])

	return [accountsList, loading] as const;
}

export function getAccountsForAccountSelect(accountId?: string) {
	return new ApiRequest<SelectorAccount>({
		url: 'picker',
		accountBased: true,
		accountId: accountId
	})
}

export type GetAccountLogoIdArgs = {
	accountId?: string
}
export function getAccountLogoId(args?: GetAccountLogoIdArgs){
	return new ApiRequest<string[]>({
		url: 'logoId',
		accountBased: true,
		accountId: args?.accountId
	})
}
