import React from "react";
import {Window} from "controls/react/kendoWrappers";
import {Section} from "controls/react/layout/section";
import {Toolbar} from "controls/react/layout/toolbar";
import {ActionButtons} from "controls/react/form";
import {AntSelect, AntSelectRefProps} from "controls/react/ant/antSelect";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {costTranslates} from '../translations'
import {AntInput} from "controls/react/ant/antInput";
import {BoxView} from "controls/react/layout/boxView";
import {observer} from "mobx-react";
import {AntTreeSelect} from "controls/react/ant/antTreeSelect";
import {CostBudgetItem, CostBudgetLink} from "../models/costBudgetItem";
import {LinkCostProfileRowWindowStore} from "./linkCostProfileRowWindowStore";
import {CostBudget} from "../models/costBudget";
import {AntPopover} from "controls/react/ant/antPopover";
import {LoadingOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {AntInputNumber, NumberKeysPattern} from "../../../controls/react/ant/antInputNumber";
import {TreeSelect} from "antd";
import {translator} from "core/localization/localization";
import b_ from 'b_';
import "./linkCostProfileRowWindow.less";

const b = b_.with('link-cost-profile-row-window');

const i = translator({
	"LinkTypeHelp.Header": {
		"en": "Link types",
		"no": "Link typer"
	},
	"LinkTypeHelp.GroupAndChild": {
		"en": "Group and child: Everything in selected tree will be linked",
		"no": "Gruppe og barn: Alt i valgt tre blir linket"
	},
	"LinkTypeHelp.Single": {
		"en": "Single: Only selected entry will be linked.",
		"no": "Kun gruppe: Alt i valgt tre med unntak av siste nivå blir linket"
	},
	"LinkTypeHelp.GroupOnly": {
		"en": "Group only: Everything in selected tree excluding last level will be linked.",
		"no": "Singel: Kun valgt blir linket"
	},
}, costTranslates)

export interface LinkCostProfileRowWindowProps {
	onSave: (target: CostBudgetItem, linkData: CostBudgetLink) => void;
	onCancel: () => void;
	budget: CostBudget;
	initLink?: CostBudgetItem;
	parent: CostBudgetItem;
}

class LinkCostProfileRowWindowUnobserved extends React.Component<LinkCostProfileRowWindowProps> {
	private readonly store: LinkCostProfileRowWindowStore;
	profileRef =  React.createRef<AntSelectRefProps>()

	constructor(props: LinkCostProfileRowWindowProps) {
		super(props);
		this.store = new LinkCostProfileRowWindowStore(props);
	}

	render() {
		const {accounts, costProfiles, costModels, treeData, error, errorSeverity, loading} = this.store;

		const linkTypeHelp = <AntPopover
				content={
					<Section title={i('LinkTypeHelp.Header')} childrenPadding={true} contentPadding={true}>
						<div>{i('LinkTypeHelp.GroupAndChild')}</div>
						<div>{i('LinkTypeHelp.Single')}</div>
					</Section>
				}
			>
					<QuestionCircleOutlined style={{marginLeft: '10px'}} />
			</AntPopover>

		return (
			<Window title={i('Link')} modal onClose={this.props.onCancel} width={600} titleIcon={this.titleIcon} autoFocus={false} onInit={() => this.profileRef.current?.focus()}>
				{loading && <div className={b('loader')}><LoadingOutlined /></div>}
				{error && <BoxView type={errorSeverity}>{error}</BoxView>}
				<Section childrenPadding={true}>
					<FormEntryNew label={i('Account')} model={this.store} modelField={'accountId'}>
						<AntSelect dataList={accounts}/>
					</FormEntryNew>
					<FormEntryNew label={i('Cost Store/Model')} model={this.store}
										 modelField={'costProfileId'}>
						<AntSelect dataList={costProfiles} componentRef={this.profileRef}/>
					</FormEntryNew>
					<FormEntryNew label={i('Cost Sheet')} model={this.store} modelField={'costModelId'}>
						<AntSelect dataList={costModels}/>
					</FormEntryNew>
					<FormEntryNew label={i('Link type')} model={this.store} modelField={'selectionType'} headerAdditionalContent={linkTypeHelp}>
						<AntSelect dataList={this.store.selectionTypes} nameField={"text"} valueField={"value"}/>
					</FormEntryNew>
					<FormEntryNew model={this.store} modelField={'value'} onChange={this.store.setValue}>
						<AntTreeSelect
							showSearch
							error={errorSeverity || ''}
							dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
							placeholder={i("Please select") + ' *'}
							allowClear = {false}
							maxTagCount={1}
							hideMaxTagsIndicator={true}
							treeData={[...(treeData || [])]}
							treeCheckable={this.store.treeCheckable}
							showCheckedStrategy={TreeSelect.SHOW_PARENT}
							treeCheckStrictly={this.store.treeCheckable}
							loadData={this.store.loadChildren}
							treeExpandedKeys={this.store.expandedKeys}
							onTreeExpand={this.store.setExpandedKeys}
							treeLoadedKeys={this.store.loadedKeys}
							virtual={true}
							onSearch={this.store.searchTree}
							searchValue={this.store.searchValue}
							treeNodeFilterProp={'label'}
							filterTreeNode={LinkCostProfileRowWindowStore.filterTreeNode}
						/>
					</FormEntryNew>
					<FormEntryNew label={i('Percentage')} model={this.store} modelField={'percentage'}>
						<AntInputNumber
							pattern={NumberKeysPattern.PositiveDecimal}
							formatter={value => `${value}%`}
							parser={value => value!.replace('%', '')}
						/>
					</FormEntryNew>
					<FormEntryNew label={i('Link name')} model={this.store} modelField={'linkName'}>
						<AntInput/>
					</FormEntryNew>
				</Section>
				<Toolbar>
					<ActionButtons
						mode={this.store.mode}
						onSave={this.store.save}
						onCancel={this.props.onCancel}
						saveDisabled={!this.store.valid}
					/>
				</Toolbar>
			</Window>
		)
	}

	get titleIcon() {
		return {
			class: 'question-sign',
			tooltip: i('Creates a link to a row in another Cost Profile. Note that only the cost and name is linked. Budget is set per Cost Profile.')
		}
	}

	componentWillUnmount() {
		this.store.destroy()
	}
}

export const LinkCostProfileRowWindow = observer(LinkCostProfileRowWindowUnobserved);
