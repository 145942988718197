import State from 'tools/state';
import Cookies from 'core/cookies';
import Settings from "settings";
import Api from 'tools/api';
import lang from "core/localization/lang";
import {PresentationApi} from "areas/dashboards/api";

export function getDescriptions(){
	return [
		{
			url: '/dashboards',
			jspUrl: 'include/ServiceBoard.jsp',
			bodyCssClass: 'cw_serviceboard_module',
			getModuleImport: () => import('./serviceBoard'),
			routeId: 'board'
		}
	];
}

export class ServiceBoardRouter {
	static details(serviceBoardId) {
		return`/serviceboard/${serviceBoardId}`;
	}

	static list(){
		return '/dashboards'
	}
}
