import './section.less'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {Toolbar} from 'controls/react/layout/toolbar'
import {Overlay} from 'controls/react/layout/overlay'

import ReactHelpers from 'tools/helpers/react'

let b = require('b_').with('section');

export class Section extends React.PureComponent{
	constructor(props){
		super(props);
		this.containerRef = props.containerRef || React.createRef();
		this.contentRef = props.contentRef || React.createRef();
	}

	render() {
		let hasFooter = this.props.hasFooter || false;
		let {header, footer, others} = ReactHelpers.sortOutContent(this.props.children, {
			header: this.props.hasHeader, footer: hasFooter
		});

		if (header != null) {
			header = React.cloneElement(header, {
				containerClass: classnames(header.props.containerClass, b('header')),
				title: this.props.title || header.props.title
			});
		}

		if (header == null && this.props.title != null) {
			header = <Toolbar title={this.props.title} containerClass={b('header')}/>;
		}

		// if (footer != null) {
		// 	footer = React.cloneElement(footer, {
		// 		//containerClass: classnames(footer.props.containerClass, b('footer'))
		// 	});
		// }

		const componentClasses = b({
			'content-padding': this.getContentPadding(),
			height: this.getHeight(),
			width: !Number.isInteger(this.props.width) ? this.props.width : null,
			appearance: this.getAppearance(),
			half: this.props.width == 'half',
			threeQuarter: this.props.width == 'threeQuarter',
			quarter: this.props.width == 'quarter',
			oneThird: this.props.width == 'oneThird',
			// 'border-top-only': this.props.appearance == 'frame-top-only',
			margin: this.props.margin,
			hidden: this.props.hidden,
			'has-overlay': this.props.containerOverlay,
		});

		const contentClasses = classnames(
			b('content', {
				scrollable: this.props.scrollable,
				direction: this.getDirection(),
				layout: this.props.layout,
				'children-padding': this.getChildrenPadding(),
				hidden: this.props.contentHidden,
				display: this.props.display,
				'align-items': this.props.alignItems,
				'has-overlay': this.props.contentOverlay == true && !this.props.containerOverlay
			}),
			{[this.props.contentClass] : this.props.contentClass != null},
		);

		return (
			<div className={classnames(componentClasses, this.props.containerClass, this.props.className)}
			     style={this.getContainerStyle()}
			     onClick={this.props.onClick}
				 ref={this.containerRef}>

				{header}

				<div className={contentClasses}
				     ref={this.contentRef}>
					{this.props.contentOverlay == true && !this.props.containerOverlay &&
						<Overlay show={this.props.contentOverlay}
					         spinner={this.props.overlaySpinner}
					         message={this.props.overlayMessage}
						/>
					}
					{others}
				</div>

				{footer}

				{this.props.containerOverlay == true &&
					<Overlay show={this.props.containerOverlay}
				         spinner={this.props.overlaySpinner}
				         message={this.props.overlayMessage}/>
				}
			</div>
		);
	}

	getAppearance() {
		if(this.props.appearance)
			//return this.props.appearance == 'frame-top-only' ? 'frame' : this.props.appearance;
			return this.props.appearance

		if(this.isSidebarLayout())
			return 'none';

		return 'card';
	}

	getHeight() {
		if(this.props.height != null)
			return this.props.height;

		if(this.isSidebarLayout())
			return 'fit-expand-only';

		return undefined;
	}

	getContainerStyle(){
		const style = {}

		if(Number.isInteger(this.props.width)){
			style.width = this.props.width + 'px'
		}

		return style
	}

	getDirection() {
		if(this.isSidebarLayout())
			return 'row';

		if(this.props.direction != null)
			return this.props.direction;

		return 'column';
	}

	getContentPadding() {
		if(this.props.contentPadding != null)
			return this.props.contentPadding;

		return this.getAppearance() != 'none'
	}

	getChildrenPadding(){
		if(this.props.childrenPadding != null)
			return this.props.childrenPadding;

		return this.props.layout == 'equal';
	}

	isSidebarLayout(){
		return this.props.layout == 'sidebar-left' || this.props.layout == 'sidebar-right'
	}
}

Section.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	appearance: PropTypes.oneOf(['card', 'frame', 'frame-top-only', 'none']),
	layout: PropTypes.oneOf(['stack', 'equal', 'sidebar-left', 'sidebar-right']),
	direction: PropTypes.oneOf(['row', 'column']),
	containerClass: PropTypes.string,
	className: PropTypes.string,
	contentClass: PropTypes.string,
	containerRef: PropTypes.object,
	alignItems: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'baseline', 'stretch']),
	hasFooter: PropTypes.bool,
	contentPadding: PropTypes.bool,
	childrenPadding: PropTypes.bool,
	scrollable: PropTypes.oneOf([true, false, 'y', 'x']),
	contentHidden: PropTypes.bool,
	height: PropTypes.oneOf(['size1', 'fit', 'default', 'fit-expand-only', 'full']),
	width: PropTypes.oneOfType([PropTypes.oneOf(['half', 'full', 'threeQuarter', 'quarter', 'oneThird']), PropTypes.number]),
	margin: PropTypes.oneOf(['top', 'bottom', "none"]),
	contentOverlay: PropTypes.bool,
	containerOverlay: PropTypes.bool,
	overlaySpinner: PropTypes.bool,
	overlayMessage: PropTypes.string,
	expand: PropTypes.bool,
	shrink: PropTypes.bool,
	onClick: PropTypes.func,
	hasHeader: PropTypes.bool
};

Section.defaultProps = {
	margin: "none",
	layout: 'stack',
	contentHidden: false,
	shrink: false,
	expand: false,
	hasHeader: true,
	alignItems: 'stretch'
}
