import React from 'react';
import ReactDOM from "react-dom";
import {Utils} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import State from 'tools/state';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import aggregationTypeDataSource from 'areas/service-boards/widgets/common/dataSources/aggregationTypeDataSource';
import {
	renderIntoLegacy
} from "controls/react/form/timePeriodSelector";
import {createRoot} from "react-dom/client";


export function MultigraphWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {MultigraphWidgetConfiguration as default};

jQuery.extend(MultigraphWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */

	/**
	 * Main init function
	 */
	initComponent: function () {
		//backward compatibility
		this.widgetObj.configuration.type = this.widgetObj.configuration.type || 'SERVICE';
		var widgetObj = this.widgetObj;
		var html = `<!-- cw_health_multigraph -->
			<div class="cw_field">
				<label class="cw_inline">${(lang.TITLE)}: </label>
				<input type="text" class="cw_widget_title cw_inline" value="${((widgetObj.title || ''))}" />
			</div>
			<div class="cw-separator-frame selection">
				<div class="cw-separator-frame__title">${lang.SELECTION}</div>
				<div class="cw_field" id="cw_multigraph_account">
					<label class="cw_inline">${(lang.ACCOUNT)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_accountid" type="text" />
					</div>
				</div>
				<div class="cw_field" id="cw_multigraph_asset">
					<label class="cw_inline">${(lang.ASSET)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_assetid" type="text" value="${((widgetObj.configuration.assetId || ''))}" />
					</div>
				</div>
				<div class="cw_field" id="cw_multigraph_mon_type">
					<label class="cw_inline">${(lang.assethealth.MONITOR_TYPE)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_monitor_type" type="text" value="${((widgetObj.configuration.monitorType || ''))}" />
					</div>
				</div>
				<div class="cw_field" id="cw_multigraph_monitor">
					<label class="cw_inline">${(lang.assethealth.MONITOR_NAME)}: </label>
					<div class="cw_dropdown_container">
						<input id="cw_multigraph_monitor_id" type="text" value="${((widgetObj.configuration.monitorId || ''))}" />
					</div>
				</div>
				<div class="cw_field cw_multigraph_button" id="cw_multigraph_monitor_button">
					<button class="k-button k-primary" id="cw_add_monitor_button" disabled>${(lang.ADD)}</button>
				</div>
			</div>
			<div class="cw_field">
				<span class="cw_inline_full">${(lang.MONITORS)}: </span>
				<div class="left w100">
					<input id="cw_multigraph_monitors_list" type="text" class="w100"/>
				</div>
			</div>`;

		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIOD + ': </label>';
		html += '<ul id="cw_multigraph_period_control" class="cw_multi_toggle right"></ul>';
		html += '<div class="cw_hide_time_selector"><input type="checkbox" class="cw_include k-checkbox" id="cw_hide_time_selector"' + (widgetObj.configuration.hideTimeSelector ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_hide_time_selector" class="k-checkbox-label">' + lang.widget.HIDE_TIME_SELECTOR + '</label></div>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="cw_include k-checkbox" id="cw_ignore_missing_data" title="' + lang.serviceBoard.messages.MISSING_DATA_MESSAGE + '"></span>';
		html += '<label for="cw_ignore_missing_data" class="k-checkbox-label">' + lang.serviceBoard.IGNORE_MISSING_DATA + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="cw_include k-checkbox" id="cw_show_regression">';
		html += '<label for="cw_show_regression" class="k-checkbox-label">' + lang.SHOW_REGRESSION + '</label>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.AGGREGATION_TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_aggregation_type" type="text" />';
		html += '</div>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_multigraph_timezone" type="text" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '"/></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.LEGEND + ': </span>';
		html += '<input id="cw_multigraph_legend" type="text" />';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(
			$(html).addClass('assets-multigraphWidgetConfiguration')
		);

		const [timePeriod, timePeriodRoot] = renderIntoLegacy({
			container: document.getElementById("cw_multigraph_period_control"),
			valueHolder: widgetObj.configuration,
			onChange: (v) => this.timePeriod = v,
			dynamicOnly: true
		})

		this.timePeriod = timePeriod
		this.timePeriodRoot = timePeriodRoot

		this.legendDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<Asset>',
			id: '<Asset>'
		}, {
			name: '<MonitorType>',
			id: '<MonitorType>'
		}, {
			name: '<Monitorname>',
			id: '<Monitorname>'
		}];

		this.renderLegendMultiSelect();
		this.initKendoComponents();

		$('#cw_add_monitor_button').on('click', $.proxy(this.onMonitorAddClick, this));

	},
	renderLegendMultiSelect: function() {
		this.legendReactRoot = createRoot($('#cw_multigraph_legend').parent().get()[0])
		this.legendReactRoot.render(<FormEntry label={lang.LEGEND} vertical={true}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;
		if (this.wasUpdate) {
			$('#cw_hide_time_selector').prop('checked', widgetObj.configuration.hideTimeSelector);
			$('#cw_ignore_missing_data').prop('checked', widgetObj.configuration.ignoreMissingData);
			$('#cw_show_regression').prop('checked', widgetObj.configuration.showRegression);
		}

		this.aggregationType = $('#cw_aggregation_type').kendoDropDownList({
			dataSource: aggregationTypeDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.aggregationType || 'high'
		}).data('kendoDropDownList');

		this.multigraphAccounts = $('#cw_multigraph_accountid').kendoComboBox({
			dataSource: new CeeViewDataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_asset'),
							label: lang.ASSET
						}, {
							container: $('#cw_multigraph_mon_type'),
							label: lang.assethealth.MONITOR_TYPE
						}, {
							container: $('#cw_multigraph_monitor'),
							label: lang.assethealth.MONITOR_NAME
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/subaccounts/allLevels/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			placeholder: lang.SELECT,
			enable: true,
			autoBind: true,
      		clearButton: false,
      		value: this.widgetAccountId || Cookies.CeesoftCurrentAccountId,
			select: $.proxy(function (e) {
				if (e.item) {
					var id = e.dataItem.id;
					this.widgetAccountId = id;

					if (id) {
						this.isAccountChanged = true;
						this.multigraphFormObject = this.multigraphFormObject || {};
						this.multigraphFormObject.accountId = id;
						var assetsUrl = Settings.serverPath + 'accounts/' + this.widgetAccountId + '/assets/lite';
						this.assetCombo.dataSource.options.transport.read.url = assetsUrl;
						this.assetCombo.dataSource.read();
						this.assetCombo.value('');
						this.resetDropdowns([this.assetCombo, this.monitorTypeCombo, this.monitorCombo]);
					}
				}
				var multigraphMonitorNameCombo = $('#cw_multigraph_monitor_id').data('kendoComboBox');
				if (multigraphMonitorNameCombo.value() === lang.SELECT) {
					$('#cw_add_monitor_button').prop('disabled', 'disabled');
				}
			}, this),
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
		}).data('kendoComboBox');


		this.multigraphFormObject = {
			accountId: 0
		};
		$("#cw_multigraph_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_multigraph_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$('#cw_multigraph_timezone').kendoComboBox({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json"
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			clearButton: false,
		});

		this.assetCombo = $('#cw_multigraph_assetid').kendoComboBox({
			dataSource: new CeeViewDataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_mon_type'),
							label: lang.assethealth.MONITOR_TYPE
						}, {
							container: $('#cw_multigraph_monitor'),
							label: lang.assethealth.MONITOR_NAME
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || Cookies.CeesoftCurrentAccountId) + '/assets/lite';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				data: [],
				error: ErrorHandler.kendoServerError
			}),
			enable: true,
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(this.onMultigraphAssetSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			autoBind: false,
			// text: lang.SELECT,
			placeholder: lang.SELECT,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isAccountChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
		}).data('kendoComboBox');

		this.monitorTypeCombo = $('#cw_multigraph_monitor_type').kendoComboBox({
			dataSource: [], //(this.mode === 'update' || this.wasUpdate) ? monitorTypesDataSource : [],
			dataTextField: 'text',
			dataValueField: 'value',
			select: $.proxy(this.onMultigraphMonitorTypeSelect, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: false,
			// text: lang.SELECT,
			placeholder: lang.SELECT,
			autoBind: false,
			clearButton: false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isAssetSelected) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		this.monitorCombo = $('#cw_multigraph_monitor_id').kendoComboBox({
			dataSource: [], //(this.mode === 'update' || this.wasUpdate) ? monitorsDataSource : [],
			dataTextField: 'name',
			dataValueField: 'id',
			select: $.proxy(this.onMultigraphMonitorSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			//enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			enable: false,
			// text: lang.SELECT,
			placeholder: lang.SELECT,
			//autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: false,
			clearButton: false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isMonitorTypeSelected) {
					Utils.focusNextComboBox(e);
				}
				$('#cw_multigraph_monitor_id').data('kendoComboBox').value(null);
			}, this)
		}).data('kendoComboBox');


		if (this.mode === 'update' || this.wasUpdate) {
            this.monitorsListDataSource = new CeeViewDataSource({
                data: this.widgetObj.configuration.monitors,
                schema: {
                    parse: function (response) {
                        for (var i = 0, length = response.length; i < length; i++) {
                            if (!response[i].monitorId) {
                                response[i].monitorId = response[i].id;
                            }
                        }
                        return response;
                    }
                }
            });
		} else {
			this.monitorsListDataSource = new CeeViewDataSource({
				data: []
			});
		}

		var scope = this;
		this.monitorsCombosList = $('#cw_multigraph_monitors_list').kendoSortedMultiSelect({
			dataSource: this.monitorsListDataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			placeholder: lang.assethealth.NO_MONITORS,
            tagTemplate: '<span title="${assetName}/${name}">${assetName}/${name}</span>',
			change: function (e) {
				var previous = this.savedOld;
				var current = this.value();
				var diff = [];
				if (previous) {
					diff = $(previous).not(current).get();
				}
				this.savedOld = current.slice(0);
				if (diff.length > 0) {
					var data = scope.monitorsListDataSource.data();
					var dataLength = data.length;
					for (var i = 0; i < dataLength; i++) {
						if (data[i].id === diff[0]) {
							var dataItem = scope.monitorsListDataSource.at(i);
							scope.monitorsListDataSource.remove(dataItem);
							break;
						}
					}
				}
			},
			dataBound: $.proxy(function (e) {
				if (this.newMonitorAdded) {
					this.newMonitorAdded = false;
					$('#cw_multigraph_monitors_list_listbox').find('li:last').trigger('click');
				} else if (this.mode === 'update' || this.wasUpdate) {
					var data = e.sender.dataSource.data(), length = data.length, values = [];
					for (var i = 0; i < length; i++) {
						values.push(data[i].id);
					}
					e.sender.value(values);
					if (!values.length) {
						e.sender.wrapper.css('border', '1px solid red');
					}
				}
			}, this)
		}).data('kendoSortedMultiSelect');
	},
	/**
	 * Handler function for the select event of the metrics asset dropdown list
	 * @param {Object} e The select event
	 */
	onMultigraphAssetSelect: function (e) {
		if (e.item) {
			var monitorTypeCombo = $("#cw_multigraph_monitor_type").data('kendoComboBox');
			var monitorCombo = $("#cw_multigraph_monitor_id").data('kendoComboBox');
			var assetId = e.dataItem.id;
			if (assetId) {
				this.isAssetSelected = true;
				this.isInputChanged = true;
				monitorTypeCombo.enable(true);
				monitorTypeCombo.value(null);
				monitorTypeCombo.input.attr("placeholder", lang.SELECT);
				this.resetDropdowns([this.monitorTypeCombo, this.monitorCombo]);
				var newMonitorTypeDataSource = new CeeViewDataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_multigraph_monitor'),
								label: lang.assethealth.MONITOR_NAME
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + this.widgetAccountId + '/assets/' + assetId + '/health/monitors/types/',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				monitorTypeCombo.setDataSource(newMonitorTypeDataSource);
				monitorTypeCombo.dataSource.read();
				monitorCombo.value(null);
				monitorCombo.input.attr("placeholder", lang.SELECT);
				monitorCombo.enable(false);
			} else {
				monitorTypeCombo.value(null);
				monitorTypeCombo.input.attr("placeholder", lang.SELECT);
				monitorTypeCombo.enable(false);
				monitorCombo.value(null);
				monitorCombo.input.attr("placeholder", lang.SELECT);
				monitorCombo.enable(false);
				$('#cw_add_monitor_button').prop('disabled', 'disabled');
			}
		}
		var multigraphMonitorNameCombo = $('#cw_multigraph_monitor_id').data('kendoComboBox');
		if (multigraphMonitorNameCombo.value() === lang.SELECT) {
			$('#cw_add_monitor_button').prop('disabled', 'disabled');
		}
	},
	/**
	 * Handler function for the select event of the metrics monitor type dropdown list
	 * @param {Object} e The select event
	 */
	onMultigraphMonitorTypeSelect: function (e) {
		if (e.item && e.item.hasClass('k-hover')) {
			var monitorCombo = $("#cw_multigraph_monitor_id").data('kendoComboBox');
			var monitorType = e.dataItem.value
			var assetId = $("#cw_multigraph_assetid").data('kendoComboBox').value();
			if (monitorType) {
				this.isMonitorTypeSelected = true;
				this.isInputChanged = true;
				monitorCombo.enable(true);
				monitorCombo.value(null);
				monitorCombo.input.attr("placeholder", lang.SELECT);
				this.resetDropdowns([this.monitorCombo]);
				var newMonitorDataSource = new CeeViewDataSource({
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + this.widgetAccountId + '/assets/' + assetId + '/health/monitors/types/' + monitorType + '/lite',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				monitorCombo.setDataSource(newMonitorDataSource);
				monitorCombo.dataSource.read();
			} else {
				monitorCombo.value(null);
				monitorCombo.input.attr("placeholder", lang.SELECT);
				monitorCombo.enable(false);
				$('#cw_add_monitor_button').prop('disabled', 'disabled');
			}
		}
		var multigraphMonitorNameCombo = $('#cw_multigraph_monitor_id').data('kendoComboBox');
		if (multigraphMonitorNameCombo.value() === lang.SELECT) {
			$('#cw_add_monitor_button').prop('disabled', 'disabled');
		}
	},
	/**
	 * Handler function for the select event of the metrics monitor  dropdown list
	 * @param {Object} e The select event
	 */
	onMultigraphMonitorSelect: function (e) {
		if (e.item) {
			if (e.dataItem.id) {
				$('#cw_add_monitor_button').removeAttr('disabled');
			} else {
				$('#cw_add_monitor_button').prop('disabled', 'disabled');
			}
		}
	},
	findRecord: function (dataSource, id) {
		var record = null;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var r = dataSource.at(i);
			if (r.id === id) {
				record = r;
				break;
			}
		}
		return record;
	},
	getValues: function () {
		var title = $('.cw_widget_title').val().trim() || lang.MULTIGRAPH;
		var ignoreMissingData = $('#cw_ignore_missing_data').is(':checked');
		var showRegression = $('#cw_show_regression').is(':checked');
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'asset_multigraph',
			title: title,
			configuration: {
				timezone: $('#cw_multigraph_timezone').data('kendoComboBox').value(),
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				labelTemplate: this.legendMultiList,
				ignoreMissingData: ignoreMissingData,
				showRegression: showRegression,
				aggregationType: this.aggregationType.value(),
			}
		};

		Object.assign(widgetObj.configuration, this.timePeriod)

		var monitorIds = [], monitors = [], length, values;
		var monitorsListCombo = $('#cw_multigraph_monitors_list').data('kendoSortedMultiSelect');
		values = monitorsListCombo.dataItems();
		length = values.length;
		for (var i = 0; i < length; i++) {
			monitorIds.push(values[i].id);
			monitors.push({
				name: values[i].name,
				id: values[i].id,
                monitorId: values[i].monitorId,
				accountId: values[i].accountId,
				assetName: values[i].assetName,
				assetId: values[i].assetId,
				monitorType: values[i].monitorType
			});
		}
		widgetObj.configuration.monitorsList = monitorIds;
		widgetObj.configuration.monitors = monitors;
		return widgetObj;
	},
	/*
	 * Handler function for the click event of the Add service qualifier button (Metrics/Multigraph)
	 * @param {type} e
	 */
	onMonitorAddClick: function (e) {
		var monitorCombo = $("#cw_multigraph_monitor_id").data('kendoComboBox');
		var monitorTypeCombo = $("#cw_multigraph_monitor_type").data('kendoComboBox');
		var assetCombo = $("#cw_multigraph_assetid").data('kendoComboBox');
		var monitorId = monitorCombo.value();
        var assetId = assetCombo.value();
		if (monitorCombo && Utils.isGuid(monitorId)) {
			var data = this.monitorsListDataSource.data(), length = data.length, i, canAdd = true, record;
			if (length) {
				for (let i = 0; i < length; i++) {
                    if (data[i].monitorId === monitorId && data[i].assetId === assetId) {
						canAdd = false;
						break;
					}
				}
			}
			if (canAdd) {
				record = {
					name: monitorCombo.text(),
                    id: Utils.guid(),
                    monitorId: monitorId,
					assetName: assetCombo.text(),
					assetId: assetCombo.value(),
					accountId: this.multigraphAccounts.value(),
					color: this.getNextColor(), //'#' + Math.floor(Math.random() * 16777215).toString(16),
					monitorType: monitorTypeCombo.text()
				};
				this.newMonitorAdded = true;
				this.monitorsListDataSource.add(record);
				this.monitorsCombosList.refresh();
			}
		} else {
			var status = $('#create_widget').find('.status');
			status.find('p').text(lang.widget.SELECT_QUALIFIER);

			status.find('p').addClass('error');
			status.slideDown().delay(4000).slideUp();
		}
	},
	isValid() {
		let valid = true;
		let validationMessage = '';
		const monitorsListCombo = $('#cw_multigraph_monitors_list').data('kendoSortedMultiSelect');
		if (!monitorsListCombo.value().length) {
			valid = false;
			validationMessage = lang.widget.SELECT_MONITOR;
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	},
	resetDropdowns: function (list) {
		for (var i = 0; i < list.length; i++) {
			//list[i].value(null);
			list[i].input.attr("placeholder", lang.SELECT);
			if (i >= 1) {
				list[i].enable(false);
			} else {
				list[i].enable(true);
			}
		}
	},
	getNextColor: function () {
		var totalMonitors = this.monitorsListDataSource.total();
		if (totalMonitors < 25) {
			for (var i = 0; i < State.metricColors.length; i++) {
				var color = State.metricColors[i];
				var found = false;
				for (var j = 0; j < totalMonitors; j++) {
					var record = this.monitorsListDataSource.at(j);
					if (record.color === color) {
						found = true;
						break;
					}
				}
				if (!found) {
					return color;
				}
			}
		} else {
			return State.serviceboard.metricColors[Math.floor(Math.random() * 24)];
		}
	},

	destroy: function(){
		this.timePeriodRoot?.unmount()
		this.legendReactRoot?.unmount()
	}
});
