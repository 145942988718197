import {makeObservable, observable} from "mobx";
import {View, Map} from 'ol';
import Interaction from 'ol/interaction/Interaction'
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM'

import {GpsWidgetConfig} from "controls/designer/features/widgets/custom/gps/gpsWidgetConfig";
import {apiFetch} from "framework/api";
import {SeverityIndicatorOverlay} from "./severityIndicatorOverlay";
import {GpsWidgetPersistedState} from "controls/designer/features/widgets/custom/gps/gpsWidgetPersistedState";
import {RemoteEventsManager} from "core/remoteEventsManager";
import {AssetHealthResponseEntry, getHealthInfo} from "controls/designer/api";
import {AssetHealthEvent, GeolocationEvent} from "framework/entities/events";
import {fromLonLat} from "ol/proj";
import {executeUpdate, iterateAllCells, iterateEdges} from "controls/designer/utils";
import {Designer} from "controls/designer/designer";
import {
	isMxChildChange,
	MxCell, MxEvent,
	MxEventObject, MxGraph, MxGraphModel, MxGraphMoveCellsEventObject,
} from "controls/designer/mxGraphInterfaces";
import {throttle} from "lodash";
import {showAtElement} from "controls/react/ant/antPopconfirm"
import {LodashThrottle} from "lodash/fp";
import {getAssetGroupAssets, getAssetGroupsAssetsLite} from "areas/assets/api";
import {CeeviewNavigator} from "tools/ceeviewNavigator";
import {newGuid} from "tools/guid";

const i18n = require('core/localization').translator({
	'Confirm attachment message': {
		en: 'There is a geoTag on the moved shape. Do you want to attach the shape to the widget?'
	}
})

type OverlayDescription = {
	assetId: string
	accountId: string
	linkedCell?: MxCell
	assetHealth?: AssetHealthResponseEntry
	overlay?: SeverityIndicatorOverlay
}

export class GpsWidgetStore {
	loadingError: boolean = false;
	loaded: boolean = false;
	assets: AssetHealthResponseEntry[];

	config: GpsWidgetConfig;
	persistedState: GpsWidgetPersistedState;

	map: Map;
	mapContainer: HTMLElement;

	overlays: OverlayDescription[] = [];

	unsubscriber: {
		unsubscribe: () => void
	}

	constructor(config: GpsWidgetConfig,
	            persistedState: GpsWidgetPersistedState,
	            public designer: Designer,
	            public cell: MxCell,
	            public navigator: CeeviewNavigator) {
		this.config = config;
		this.persistedState = persistedState;

		if (!this.persistedState.linkedCells) {
			this.persistedState.linkedCells = []
		}

		makeObservable(this, {
			assets: observable,
			loadingError: observable,
			loaded: observable
		})
	}

	async init() {
		await this.generateOverlaysList()

		this.createDescriptionsFromLinkedCells()

		this.createMap()

		await this.createOverlays(this.overlays)

		this.loaded = true

		if (this.designer.config.chromeless) {
			this.subscribe();
		}

		//this.designer.graph.getModel().addListener(MxEvent.NOTIFY, this.processExecuteEvent)
		this.designer.graph.addListener(MxEvent.MOVE_CELLS, this.cellMoved)
		this.designer.graph.getModel().addListener(MxEvent.NOTIFY, this.processNotifyEvent)
	}

	async generateOverlaysList() {
		this.overlays = this.config.assets.map(assetId => ({
			assetId: assetId,
			accountId: this.config.accountId
		}))

		if (this.config.assetGroups.length == 0)
			return

		const response = await apiFetch(getAssetGroupsAssetsLite({
			assetGroupIds: this.config.assetGroups,
			accountId: this.config.accountId,
			geotag: true
		}))

		if (!response.success)
			return

		response.data.forEach(asset => {
			this.overlays.push({
				assetId: asset.id,
				accountId: this.config.accountId
			})
		})
	}

	cellMoved = async (graph: MxGraph, ev: MxGraphMoveCellsEventObject) => {
		//handling moving of a set of cells is complicated - we need to track a mouse position instead of a cell position
		//which makes usage of MxEvent.Move_Cells event impossible and requires a custom mouse move event implementation
		if (ev.properties.cells.length != 1)
			return

		const movedCell = ev.properties.cells[0]

		if (this.cell == movedCell) { //a widget cell is moved, we need to update positions of all attached cells
			setTimeout(this.moveAssets, 0)
		} else if (this.cell.getGeometry().isInside(movedCell.getGeometry())) {

			if (this.overlays.find(x => x.linkedCell == movedCell)) {
				return
			}

			const dataSource = this.designer.store.dataSourcesManager.getMapEntry(movedCell)
			const geoTag = dataSource?.element?.getGeoTag()

			if (geoTag != null) {
				if (!await this.askUserIfWeShouldLinkMovedCell(movedCell)) {
					return
				}

				const newDescription = {
					...geoTag,
					dataSource,
					linkedCell: movedCell
				}

				await this.addNewDescription(newDescription)
			}
		} else {
			this.removeAttachment(movedCell)
		}
	}

	removeAttachment(cell: MxCell) {
		const existingOverlayIndex = this.overlays.findIndex(x => x.linkedCell == cell)
		if (existingOverlayIndex != -1) {
			const existingOverlay = this.overlays[existingOverlayIndex]
			this.overlays.splice(existingOverlayIndex, 1)
			existingOverlay.overlay.destroy()
		}
	}

	async askUserIfWeShouldLinkMovedCell(cell: MxCell) {
		let left = this.designer.containerBoundingRect.left + cell.getGeometry().x
		let top = this.designer.containerBoundingRect.top + cell.getGeometry().y

		return await showAtElement(left, top, {
			title: i18n('Confirm attachment message'),
			type: 'question',
		})
	}

	async addNewDescription(newDescription: OverlayDescription) {
		this.overlays.push(newDescription)

		await this.createOverlays([newDescription])

		this.moveAssets()
	}

	createDescriptionsFromLinkedCells() {
		this.persistedState.linkedCells.forEach(cellId => {
			iterateAllCells(this.designer.graph, (cell: MxCell) => {
				if (cell.id != cellId) {
					return;
				}

				//const {geoTagAssetId, accountId} = this.scanForGeoTag(cell)
				const geoTag = this.scanForGeoTag(cell)

				if(!geoTag)
					return

				this.overlays.push({
					assetId: geoTag.assetId,
					accountId: geoTag.accountId,
					linkedCell: cell
				})
			})
		})
	}

	private scanForGeoTag(cell: MxCell) {
		const element = this.designer.store.dataSourcesManager.getMapEntry(cell)?.element
		return element?.getGeoTag()
	}

	subscribe() {
		let validIds = this.overlays.filter(x => x.assetHealth != null)
			.map(x => x.assetId)

		this.unsubscriber = RemoteEventsManager.subscribeCallback([
				AssetHealthEvent.subscription({assetIds: validIds}),
				GeolocationEvent.subscription(validIds)
			],
			this.onEvent)
	}

	onEvent = (e: AssetHealthEvent | GeolocationEvent) => {
		if (AssetHealthEvent.is(e)) {
			const {assetHealth} = e
			for(const marker of this.overlays){
				if(marker.assetHealth.assetId == assetHealth.assetId){
					marker.overlay.updateHealthData(assetHealth);
				}
			}
		} else {
			for(const marker of this.overlays){
				if(marker.assetHealth.assetId == e.assetId){
					marker.overlay.updatePosition(e.geotag)
					this.moveAssetsThrottled()
				}
			}
		}
	}

	setMapContainer = async (node: HTMLElement) => {
		if (node == null)
			return;

		this.mapContainer = node;

		await this.init()
	}

	createMap() {
		const lockView = this.config.lockView && this.designer.config.chromeless

		const {center, zoom} = this.persistedState;
		this.map = new Map({
			target: this.mapContainer,
			layers: [
				new TileLayer({
					source: new OSM()
				})
			],
			controls: lockView ? [] : undefined,
			interactions: lockView ? [] : undefined,
			view: new View({
				center: center ?? fromLonLat([10.757933, 59.911491]),
				zoom: zoom ?? 10
			})
		})

		const moveAssetsThrottled = throttle(this.moveAssets, 10, {
			'leading': false
		})

		this.map.on("rendercomplete", () => {
			moveAssetsThrottled()
		})
	}

	async createOverlays(descriptions: OverlayDescription[]) {
		if (descriptions.length == 0)
			return

		const response = await apiFetch(getHealthInfo(descriptions.map(o => ({
			id: newGuid(),
			assetId: o.assetId,
			accountId: o.accountId
		}))));

		if (!response.success) {
			this.loadingError = true;
			return;
		}

		this.overlays.forEach(x => {
			let responseEntry = response.data.find(o => o.assetId == x.assetId)
			if (responseEntry) {
				x.assetHealth = responseEntry
			}
		})

		descriptions.forEach(o => {
			if (o.assetHealth.geotag?.latitude == null)
				return

			o.overlay = new SeverityIndicatorOverlay({
				asset: o.assetHealth,
				showHealthIndex: this.config.showHealthIndex,
				showAssetName: this.config.showAssetName,
				map: this.map,
				geotag: o.assetHealth.geotag,
				healthData: o.assetHealth,
				disableContent: o.linkedCell != null,
				navigator: this.navigator
			})
		})
	}

	moveAssets = () => {
		let linkedCellOverlays = this.overlays.filter(o => o.linkedCell && o.overlay)
		if (linkedCellOverlays.length == 0)
			return

		let overlaysToRemove: OverlayDescription[] = []

		executeUpdate(this.designer.graph, () => {
			linkedCellOverlays.forEach((o) => {
				//let dataSource = this.designer.store.dataSourcesManager.get(o.linkedCell)

				const element = this.designer.store.dataSourcesManager.getMapEntry(o.linkedCell)?.element
				const geoTag = element.getGeoTag()

				if (geoTag == null) {
					overlaysToRemove.push(o)
					return
				}

				this.designer.graph.orderCells(false, [o.linkedCell])
				iterateEdges(o.linkedCell, (edge: MxCell) => {
					this.designer.graph.orderCells(false, [edge])
				})

				const overlayPosition = o.overlay.overlayContainer.getBoundingClientRect()
				const initialGeometry = o.linkedCell.getGeometry()
				const widgetCellGeometry = this.cell.getGeometry()

				//we are matching an overlay (assuming it is a pixel with a center of a shape)
				const newGeometry = initialGeometry.copy(
					overlayPosition.x - initialGeometry.width / 2 - this.designer.containerBoundingRect.x,
					overlayPosition.y - initialGeometry.height / 2 - this.designer.containerBoundingRect.y)

				const assetCellOverlapsWidgetCellBorder = widgetCellGeometry.x > newGeometry.x
					|| widgetCellGeometry.x + widgetCellGeometry.width < newGeometry.x + newGeometry.width
					|| widgetCellGeometry.y > newGeometry.y
					|| widgetCellGeometry.y + widgetCellGeometry.height < newGeometry.y + newGeometry.height

				o.linkedCell.visible = !assetCellOverlapsWidgetCellBorder
				this.designer.graph.getModel().setGeometry(o.linkedCell, newGeometry)
			})
		})

		overlaysToRemove.forEach(o => {
			let index = this.overlays.indexOf(o)
			this.overlays.splice(index, 1)
			o.overlay.destroy()
		})
	}

	moveAssetsThrottled = throttle(this.moveAssets, 1000)

	processNotifyEvent = (graphModel: MxGraphModel, ev: MxEventObject) => {
		ev.properties.changes.forEach(change => {
			if (isMxChildChange(change)) {
				if (change.parent == null) {
					let index = this.overlays.findIndex(x => x.linkedCell == change.child)
					if (index != -1) {
						this.overlays[index].overlay.destroy()
						this.overlays.splice(index, 1)
					}
				}
			}
		})
	}

	destroy() {
		this.designer.graph.getModel().removeListener(this.processNotifyEvent)
		this.designer.graph.removeListener(this.cellMoved)
		this.overlays.forEach(x => x.overlay?.destroy())
		this.map.dispose();
		this.unsubscriber?.unsubscribe();
	}
}
