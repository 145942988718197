import './editableCell.less'

import React from "react"
import {observer} from "mobx-react"
import {
	EqualityOperator,
	getEqualityOperators,
	getNumericOperators,
	NumericOperator
} from "framework/entities/operators";
import {AntDefaultOptionType, AntSelect, AntSelectValue} from "controls/react/ant/antSelect";
import {getSeverityDataList, Severity} from "framework/entities/healthData";
import {SeverityLabel} from "controls/react/severityIndicator";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {AntInput, AntTextArea} from "controls/react/ant/antInput";
import {AntPopover} from "controls/react/ant/antPopover";
import {GridColumnRendererExtra} from "controls/grid/gridColumnConfig";
import {GridDataEntry} from "controls/grid/gridDataEntry";
import {Section} from "controls/react/layout/section";
import {Toolbar} from "controls/react/layout/toolbar";
import {Link} from "controls/react/link";

const i18n = require('core/localization').translator()

export const b = require('b_').with('grid-editable-cell')

export type EditableCellBasicProps = {
	disabled?: boolean
	onClick: (e: React.MouseEvent) => void
	children: React.ReactNode
}

export const EditableCellBasic = observer((props: EditableCellBasicProps) => {
	const onClickWrapper = React.useCallback((e: React.MouseEvent) => {
		if (props.disabled)
			return

		e.preventDefault()
		e.stopPropagation()

		props.onClick && props.onClick(e)
	}, [props.onClick, props.disabled])

	return <div className={b({disabled: props.disabled})} onClick={onClickWrapper}>
		{props.children}
	</div>
})


export type EditableCellProps<T extends object, K extends keyof T, TEditorOptions extends object> = {
	disabled?: boolean
	model: T
	field: K
	renderer?: (value: any, model: T, field: K) => React.ReactNode
	rendererEditor: (props: RenderEditorProps<T, T[K], TEditorOptions>) => React.ReactNode
	rendererOptions?: TEditorOptions
	extra?: GridColumnRendererExtra<T>
	onClick?: (e: React.MouseEvent) => void
}

export const EditableCell = observer(<T extends object, K extends keyof T, TEditorOptions extends object> (props: EditableCellProps<T, K, TEditorOptions>) => {
	let {model, field} = props
	const [editing, setEditing] = React.useState(false)

	const [value, setValue] = React.useState<T[K]>(null)

	const enterEditingMode = React.useCallback((e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()

		setValue(model[field])
		setEditing(true)

		props.onClick?.(e)
	}, [])

	const leaveEditingMode = React.useCallback((o: LeaveEditModeProps<T[K]>) => {
		o ??= {}

		setEditing(false)

		if(o.doNotUpdateValue !== true) {
			model[field] = o.value !== undefined ? o.value : value
		}

		setValue(null)
	}, [value])

	return <EditableCellBasic disabled={props.disabled} onClick={!editing ? enterEditingMode : props.onClick}>
		{!editing && (props.renderer?.(model[field], model, field) ?? (model[field] as string|number))}
		{editing && props.rendererEditor({
			value: value,
			item: model,
			setValue,
			leaveEditMode: leaveEditingMode,
			extra: props.extra,
			options: props.rendererOptions
		}) }
	</EditableCellBasic>
})

export function numericOperatorsRenderer(value: NumericOperator) {
	return operatorsRenderer(getNumericOperators, value)
}

export function equalityOperatorsRenderer(value: EqualityOperator){
	return operatorsRenderer(getEqualityOperators, value)
}

export function operatorsRenderer(operators: () => Array<AntDefaultOptionType>, value: string){
	return operators()
		.find(x => x.value == value)
		?.label ?? i18n("N/A")
}

export interface LeaveEditModeProps<TValue> {
	value?: TValue,
	doNotUpdateValue?: boolean;
}

export type RenderEditorProps<TRecord, TValue, TEditorOptions extends object = {}> = {
	value: TValue
	item: TRecord,
	setValue: (newValue: TValue) => void,
	leaveEditMode: (props?: LeaveEditModeProps<TValue>) => void
	extra: GridColumnRendererExtra<TRecord>
	options: TEditorOptions
}

export type GetSelectEditorArgs<DataEntry extends GridDataEntry, TValue extends AntSelectValue> = {
	options?: AntDefaultOptionType[]
	onChange?: (value: TValue, props: RenderEditorProps<DataEntry, TValue>, ) => void
}

export function getSelectEditor<DataItem extends GridDataEntry, TValue extends AntSelectValue>(args: GetSelectEditorArgs<DataItem, TValue>){
	return (props: RenderEditorProps<DataItem, TValue>) => {
		// const onChange = args.onChange != null
		// 	? ((newValue: TValue) => args.onChange(newValue, props))
		// 	: ((newValue: TValue) => props.leaveEditMode({value: newValue}))

		const onChange = (newValue: TValue) => {
			args.onChange
				? args.onChange(newValue, props)
				: props.leaveEditMode({value: newValue})
		}

		return <AntSelect options={args.options}
		                  value={props.value}
		                  defaultOpen={true}
		                  autoFocus={true}
		                  onChange={onChange}
		                  onDropdownVisibleChange={open => !open && props.leaveEditMode({doNotUpdateValue: true}) }
		                  onBlur={() => props.leaveEditMode({doNotUpdateValue: true})}
		/>
	}
}

export function severityRender(severity: Severity){
	return <SeverityLabel severity={severity}/>
}

export function severityEditorRender(props: RenderEditorProps<any, Severity>) {
	return <AntSelect dataList={getSeverityDataList()}
	                  value={props.value}
	                  defaultOpen={true}
	                  autoFocus={true}
	                  customRenderer={item => <SeverityLabel severity={item.id}/>}
	                  onChange={(newValue: Severity) => props.leaveEditMode({value: newValue})}
	                  onDropdownVisibleChange={open => !open && props.leaveEditMode({doNotUpdateValue: true}) }
	                  onBlur={() => props.leaveEditMode({doNotUpdateValue: true})}
	/>
}

export function inputNumberEditorRender(props: RenderEditorProps<any, number>) : React.ReactNode {
	return <AntInputNumber value={props.value}
	                       onChange={(newValue: number) => props.setValue(newValue)}
	                       onBlur={() => props.leaveEditMode()}
	                       onFocus={(e) => e.target.select()}
	                       autoFocus={true}
	/>
}

export function inputEditorRender(props: RenderEditorProps<any, string>) : React.ReactNode {
	return <AntInput value={props.value}
	                 onChange={(newValue: string) => props.setValue(newValue)}
	                 onBlur={() => props.leaveEditMode()}
	                 onFocus={(e) => e.target.select()}
	                 autoFocus={true}
	/>
}

type TextAreaEditorOptions = {
	defaultValue?: string
}

export function textAreaEditorRenderer(props: RenderEditorProps<any, string, TextAreaEditorOptions>) : React.ReactNode {
	const resetToDefault = () => {
		props.leaveEditMode({
			value: props.options?.defaultValue
		})
	}

	const content = <Section>
		<Toolbar title={props.extra.column.config.title}>
			{props.options?.defaultValue && <Link onClick={resetToDefault}>{i18n('Reset to default')}</Link>}
		</Toolbar>
		<AntTextArea value={props.value}
		             style={{width: Math.max(props.extra.column.actualWidth, 200) + 'px', height: '200px'}}
		             onChange={newValue => props.setValue(newValue)}
		             autoFocus={true}/>
	</Section>


	return <AntPopover defaultOpen={true} trigger={"click"} onOpenChange={() => props.leaveEditMode()}
	                   content={content}>
		<div style={{width: '100%', height: '100%'}}></div>
	</AntPopover>
}

