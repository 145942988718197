import './widgetForm.less';

import {Menu} from "antd"
import React, {useCallback, useMemo} from "react";
import {CaretLeftOutlined, CaretRightOutlined} from '@ant-design/icons'

import {linkObservable} from "core/react/links";
import {Section} from "controls/react/layout/section";
import {ValidationSummary} from "controls/react/validationSummary";
import {getWidgetsMenuStructure, generateMenuItems} from "controls/designer/features/widgets/widgetsMenu";
import {loadDesignerSettings, updateDesignerSetting} from "controls/designer/shared";
import {AntModal} from "controls/react/ant/antModal";
import {Toolbar} from "controls/react/layout/toolbar";
import {AntButton} from "controls/react/ant/antButton";
import {RedirectConfigEditor} from "controls/designer/features/redirectOnClick/redirectConfigEditor";
import {observer} from "mobx-react";
import {MobxManager} from "framework/mobx-integration";
import {ApplicationState} from "framework/applicationState";
import {toJS} from "mobx";
import {newGuid} from "tools/guid";

const i = require('core/localization').translator({
  "widget-description-service-preview": {
    "en": " Widget displays the preview of a service model with its states.",
    "no": "Modulen viser en tjenestemodell og dens tilstander"
  },
  "widget-description-application-preview": {
    "en": "Widget displays an application model.",
    "no": "Modulen viser en applikasjonsmodell"
  },
  "widget-description-application-state": {
    "en": "Widget displays aggregated application states for an account and subaccounts. Filtering with tags is available.",
    "no": "Modulen viser de samlede applikasjonsstatusene for en konto og underkontoer. Filtrering med etiketter er tilgjengelig"
  },
  "widget-description-dashboard": {
    "en": "Widget displays a live dashboard data.",
    "no": "Modulen viser et live dashbord i modulen"
  },
  "widget-description-service-summary": {
    "en": "Widget displays service elements and servicequalifiers in a list format with its states. Multiple display settings are available.",
    "no": "Modulen viser tjenesteelementer og tjenestepunkt en listeform med tilstander. Ulike innstillinger er tilgjengelige"
  },
  "widget-description-service-history": {
    "en": "Widget displays a timeline with a service state history. Multiple display settings are available.",
    "no": "Komponenten viser en tidslinje med en status for tjenesten. Ulike innstillinger er tilgjengelige"
  },
  "widget-description-service-selective": {
    "en": "Widget displays a selection of service states. Filtering, sorting and presentation options are available.",
    "no": "Komponenten viser et utvalg av tjenestetilstander. Forskjellige filtrering, sortering og presentasjons muligheter er tilgjengelig"
  },
  "widget-description-service-grid": {
    "en": "Widget displays service grid. Filtering with tags and selection of columns to display are available.",
    "no": "Komponenten viser tjenestetabellen. Filtrering med etiketter og valg av hvilke kolonner som skal vises er mulig"
  },
  "widget-description-service-state": {
    "en": "Widget displays the aggregated service states for an account and subaccounts. Filtering with tags is available.",
    "no": "Komponenten viser de aggregerte tjenestetilstander for en konto og subkontoer. Filtrering med etiketter er mulig"
  },
  "widget-description-service-single-graph": {
    "en": "Widget displays a servicequalifier metric. Multiple display options are available.",
    "no": "Komponenten viser en dataserie for et tjenestepunkt. Det er mulig å velge forskjellige grafer og tidsintervaller"
  },
  "widget-description-service-multi-graph": {
    "en": "Widget displays multiple servicequalifier metrics. Multiple display options are available.",
    "no": "Komponenten viser flere dataserier fra tjenestepunkter. Det er mulig å velge forskjellige grafer og tidsintervaller"
  },
  "widget-description-service-gauge": {
    "en": "Widget displays a gauge with the newest metric for a servicequalifier. You can set minimum, maximum and breach values.",
    "no": "Komponenten viser en måler av siste metrikk serie for et tjenestepunkt. Du kan sette egne verdier for minimum, maksimum og  bruddverdi"
  },
  "widget-description-metric-single-graph": {
    "en": " Widget displays a metric graph. Multiple display options are available.",
    "no": "Komponenten viser en dataserie. Det er mulig å velge forskjellige grafer og tidsintervaller"
  },
  "widget-description-metric-multi-graph-asset": {
    "en": "Widget displays metric series for assets. Multiple display options are available.",
    "no": "Komponenten viser multiple dataserie. Det er mulig å velge forskjellige grafer og tidsintervaller"
  },
  "widget-description-metric-multi-graph-asset-group": {
    "en": "Widget displays metric series for assetgroups. Filtering on metric categories and multiple display options are available.",
    "no": "Komponenten viser metrikker fra ressursegrupper. Det er mulig å filtre på metrikk kategorier, og man kan velge forskjellige grafer og tidsintervaller"
  },
  "widget-description-metric-gauge": {
    "en": "Widget displays a gauge with the newest metric. You can set minimum, maximum and breach values.",
    "no": "Komponenten viser en måler med siste metrikk. Du kan sette egne verdier for minimum, maksimum og  bruddverdi"
  },
  "widget-description-metric-barchart": {
    "en": "Widget displays a bar with the newest metric. You can set minimum, maximum and breach values.",
    "no": "Komponenten viser en måler med siste metrikk. Du kan sette egne verdier for minimum, maksimum og  bruddverdi"
  },
  "widget-description-metric-table": {
    "en": "Widget displays selected metric in a table format. Multiple time settings options are available.",
    "no": "Widgeten viser en metrikk serie i tabellformat. Ulike alternativer for tidsinnstillinger er tilgjengelige"
  },
  "widget-description-metric-stacked-chart": {
    "en": "Widget displays single graphs stacked above each other of multiple metrics. Multiple display options are available.",
    "no": "Komponenten viser enkeltgrafer stablet over hverandre av flere metrikker. Ulike visningsalternativer er tilgjengelige"
  },
  "widget-description-metric-custom": {
    "en": "Widget is experimental. It displays graphs for metrics. Multiple graph options are available and configurable via script editor.",
    "no": "Denne komponenten er eksperimentell. Den viser grafer for metrikker. Flere graf alternativer er tilgjengelige og konfigurerbare via skripteditor"
  },
  "widget-description-health-single-graph": {
    "en": "Widget displays a single graph of a health metric. Multiple display options are available.",
    "no": "Komponenten viser en enkelt graf av en helse metrikk. Det er mulig å velge forskjellige grafer og tidsintervaller"
  },
  "widget-description-health-multi-graph": {
    "en": "Widget displays multiple graphs for health metrics. Multiple display options are available.",
    "no": "Komponenten viser multiple helse metrikker. Det er mulig å velge forskjellige grafer og tidsintervaller"
  },
  "widget-description-health-gauge": {
    "en": "Widget displays the newest health metric. You can set minimum, maximum and breach values.",
    "no": "Komponenten viser siste helsemetrikk. Du kan sette egne verdier for minimum, maksimum og bruddverdi."
  },
  "widget-description-asset-grid": {
    "en": "Widget displays infrastructure/asset grid. Filtering with tags and selection of visible columns are available.",
    "no": "Komponenten viser infrastruktur/ressurs. Filtrering med etiketter og valg av hvilke kolonner som skal vises er mulig"
  },
  "widget-description-asset-console": {
    "en": "Widget displays CPU, memory, disk and other information collected with system monitor for a selected asset.",
    "no": "Komponenten viser CPU, minne og disk informasjon som system monitoren samler for en valgt ressurs"
  },
  "widget-description-asset-state": {
    "en": "Widget displays the aggregated asset states for an account and subaccounts. Filtering with tags is available.",
    "no": "Komponenten viser de aggregerte ressurstilstander for en konto og subkontoer. Filtrering med etiketter er mulig"
  },
  "widget-description-sla": {
    "en": "Widget displays the current compliance state of a SLA",
    "no": "Komponenten viser gjeldende samsvarstilstand for en SLA"
  },
  "widget-description-sla-history": {
    "en": "Widget displays SLA historic periods in a bar chart format. Clicking on a bar displays more details and the root causes for the selected period.",
    "no": "Komponenten viser historiske perioder for en SLA. Klikk på en av periodene for å se helseårsaker for nedetiden"
  },
  "widget-description-sla-state": {
    "en": " Widget displays aggregated SLA states for an account and subaccounts. Filtering with tags is available.",
    "no": "Komponenten viser de aggregerte SLA tilstandende for en konto og subkontoer. Filtrering med etiketter  er tilgjengelig"
  },
  "widget-description-sla-time": {
    "en": "Widget displays current downtime, downtime before breaching compliance threshold and the total available downtime for the SLA period.",
    "no": "Komponeten viser gjeldende nedetid, nedetid før brudd på samsvarsgrensen og total mulig nedetid for SLA perioden"
  },
  "widget-description-sla-grid": {
    "en": "Widget displays SLA grid. Filtering with tags is available.",
    "no": "Komponenten viser SLA tabellen. Filter med etiketter er mulig"
  },
  "widget-description-kpi-single-graph": {
    "en": "Widget displays a single graph of a KPI metric. Multiple display options are available.",
    "no": "Komponenten viser en enkelt graf av en helse metrikk. Det er mulig å velge forskjellige grafer og tidsintervaller."
  },
  "widget-description-kpi-multi-graph": {
    "en": "Widget displays multiple graphs for selected KPI metrics. Multiple display options are available.",
    "no": "Komponenten viser multiple KPI metrikker. Det er mulig å velge forskjellige grafer og tidsintervaller."
  },
  "widget-description-kpi-gauge": {
    "en": "Widget displays the newest KPI metric. You can set minimum, maximum and breach values.",
    "no": "Komponenten viser siste KPI metrikk. Du kan sette egne verdier for minimum, maksimum og  bruddverdi."
  },
  "widget-description-kpi-history": {
    "en": " Widget displays historic KPI calculations in bar chart format. Multiple display options are available. Use display rate and period to adjust the time period for the bars.",
    "no": "Komponenten viser historiske KPI perioder. Flere visningsalternativer er tilgjengelige. Visningsrate og periode justerer tidsperioden for stolpene."
  },
  "widget-description-summary-grid": {
    "en": "Widget displays home grid. The grid contain account and service information. Filtering with tags and selection of columns to display are available.",
    "no": "Komponenten viser hjem tabellen. Den inneholder konto og tjenesteinformasjon. Filtrering med etiketter, subkontoer og valg av kolonner er tilgjengelig"
  },
  "widget-description-reasons-grid": {
    "en": "Widget displays healthreasons for assets. Filtering with tags, inclusion of subaccounts and selection of columns to display are available.",
    "no": "Komponenten viser helseårsaker. Filtrering med etiketter, subkontoer og valg av kolonner er tilgjengelig"
  },
  "widget-description-incidents-grid": {
    "en": "Widget displays incident grid. Filtering with tags and selection of columns to display are available.",
    "no": "Komponenten viser sakstabell. Filtrering med etiketter, subkontoer og valg av kolonner er tilgjengelig"
  },
  "widget-description-event-summary-grid": {
    "en": " Widget displays events for selected services. You can also filter and sort in edit mode with the common controls of the grid.",
    "no": "Komponenten viser hendelsetabellen. Filtrering med tabellfiltere kan gjøres i oppdateringsmodus"
  },
  "widget-description-text": {
    "en": " Widget displays text, hyperlinks and supports simple formatting.",
    "no": "Komponenten viser tekst, hyperlinker og støtter enkel formattering"
  },
  "widget-description-time": {
    "en": " Widget displays current time and time when dashboard last received an event.",
    "no": "Komponenten viser gjeldende tid og når dashboardet sist fikk en hendelse"
  },
  "widget-description-web-page": {
    "en": "Widget displays an embedded webpage.",
    "no": "Komponent viser en integrert nettside"
  },
  "widget-description-cloud-services-cost-bar-chart": {
    "en": " Widget displays bar chart for cloud service cost.",
    "no": "Komponenten viser kostnadene for et utvalg av skytjenester"
  },
  "widget-description-cloud-services-cost-grid": {
    "en": "Widget displays cost grid for cloud services.",
    "no": "Komponenten viser kostnadene for et utvalg av skytjenester"
  },
  "widget-description-cloud-services-instances-grid": {
    "en": "Widget displays cost grid for cloud instances",
    "no": "Komponenten viser kostnadene for et utvalg av skyinstanser"
  },
  "widget-description-cost-grid": {
    "en": " Widget displays selected items from cost model",
    "no": "Komponenten viser et utvalg fra en kostmodell"
  },
  "Hide information": {
    "no": "Skjul informasjon",
    "en": "Hide nformation"
  },
  "Show information": {
    "no": "Vis informasjon",
    "en": "Show information"
  },
  "widget-description-service-document": {
    "en": "Widget displays service documents. Multiple selection options are available.",
    "no": "Widgeten viser tjenestedokumenter. Flere valgmuligheter er tilgjengelig"
  },
  "widget-description-cloud-services-buggle-chart": {
    "en": "Widget displays a bubble chart for cloud services cost",
    "no": "Komponenten viser kostnadene for skytjenester"
  },
  "widget-description-budget-cost-bar-chart": {
    "en": "Widget displays bar chart for cost model.",
    "no": "Komponenten viser et utvalg fra en kostmodell."
  },
  "widget-description-gps": {
    "en": "Widget displays a map with asset or service shapes that can be moved with GPS positions.",
    "no": "Widget viser et kart med ressurs eller tjeneste former som kan flyttes med GPS posisjoner.."
  },
  "widget-description-cost-profile": {
    "en": "Widget displays selected cost model.",
    "no": "Viser en kostmodell"
  },
  "widget-description-metric-sparkline-table": {
    "en": "Widget displays sparkline metric table with unit, last Metric sample time, mean value and graph for the last hour.",
    "no": "Sparkline viser metrikk i tabell med enhet, siste metrikktid, verdi og graf for den siste timen."
  },
  "widget-description-event-state": {
    "en": "Widget displays event states for an account. Filtering with tags and severity is available.",
    "no": "Widget viser hendelse tilstand for konto. Filtering med etiketter og alvorlighet er tilgjengelig."
  },
  "widget-description-service-events": {
    "en": "Widget display events for selected services. You can also filter and sort when in edit mode with the common controls of the grid.",
    "no": " Widget viser hendelser for de valgte tjenestene. Du kan filtrer og sorter også når du er i redigeringsmodus med de vanlige kontrollene i tabellen."
  },
  "widget-description-data-sources-grid": {
	  en: 'Widget displays datasource grid which uses a datamatrix datasource to build it’s content.',
	  no: 'Datakilde tabell bruker en datamatrix datakilde for å generere tabellinnhold.'
  },
	"widget-description-service-summary-grid": {
		en: 'Widget displays summary grid with states for any servicemodel, element and/or qualifier. You can control filtering and view settings.',
		no: 'Sammendragstabell viser tilstand for utvalgte tjeneste, tjeneste element og/eller tjenestepunkt. I tabellen kan du endre filtre og kolonne oppsett.'
	},
	"widget-description-ai-query": {
	  en: "Widget displays AI reports with selected metrics or cost values using AI query.",
		no: "Lag AI-rapporter ved å velge metrikker eller kostnadsverdier fra dashbordet,"
	},
	"widget-description-report": {
	  en: 'Widget allows to generate a selected report on click. You can define time period for data generated.',
		no: 'Lager en rapport på klikk. Du kan velge tidsperiod og hvordan rapporten blir presentert.'
	}
});

export const WidgetForm = observer(class WidgetForm extends React.Component{
	state = {}
	legacyFormRef = React.createRef();

	constructor(props){
		super(props);
	}

	render() {
		const store = this.props.store

		// hack to make work Links through mobx
		toJS(store.widgetConfig)

		const link = linkObservable(this, store.widgetConfig)
		let form = null;

		if (this.isLegacyForm()) {
			form = <LegacyWidgetForm ref={this.legacyFormRef}
			                         store={store}/>
		} else {
			const ReactForm = store.widgetDescription.form
			form = <ReactForm designerStore={store.designerStore} configLink={link} widgetDescription={store.widgetDescription} config={store.widgetConfig} />
		}

		return <>
			{this.state.validationMessage && <ValidationSummary message={this.state.validationMessage} type="error" />}
			{form}
			<RedirectConfigEditor config={store.redirectConfig}/>
		</>
	}

	isValid(){
		let isValid = false, validationMessage = '';
		if(this.isLegacyForm()){
			validationMessage = this.legacyFormRef.current.isValid().validationMessage;
			isValid = this.legacyFormRef.current.isValid().valid;
		}else{
			validationMessage = linkObservable(this, this.props.store.widgetConfig).errorSubtree?.join(', ');
			isValid = !linkObservable(this, this.props.store.widgetConfig).invalidSubtree;
		}

		this.setState({
			validationMessage
		});
		return isValid;
	}

	isLegacyForm(){
		return this.props.store.widgetDescription.legacyForm != null;
	}

	getConfig() {
		if(!this.isLegacyForm())
			return null

		const config = this.legacyFormRef.current.getConfig();

		//legacy form hardcoded types are not always the same as the current ones
		config.type = this.props.store.widgetConfig.type;

		return config
	}
})

class LegacyWidgetForm extends React.Component{
	containerRef = React.createRef();
	mobx = new MobxManager()

	render() {
		return (
			<Section appearance={"none"}
			         contentPadding={false}
			         childrenPadding={true}>
				<div ref={this.containerRef}></div>
			</Section>
		)
	}

	componentWillUnmount() {
		this.mobx.destroy()
		this.formInstance?.destroy && this.formInstance.destroy()
	}

	componentDidMount() {
		this.mobx.reaction(() => this.props.store.widgetConfig.type, x => {
			if(this.props.store.widgetDescription.legacyForm) {
				setTimeout(() =>this.reloadForm(), 0)
			}
		}, {
			fireImmediately: true
		})
	}

	reloadForm(){
		this.containerRef.current.innerHTML = '';

		this.formInstance?.destroy?.()

		this.formInstance = new this.props.store.widgetDescription.form({
			serviceBoardAccountId: ApplicationState.accountId,
			widgetObj: this.props.store.widgetConfig,
			widgetConfigurationFormEl: $(this.containerRef.current),
			mode: this.props.store.mode,
			wasUpdate: this.props.store.mode != 'create',
			widget: this.props.store.widgetDescription.widget
		});
	}

	isValid(){
		return this.formInstance.isValid();
	}

	getConfig(){
		return this.formInstance.getValues();
	}
}

const b = require('b_').with('widgets-wizard');

export const WidgetsWizard = observer(props => {
	const store = props.store

	const widgetFormRef = React.useRef()

	const onOk = () => {
		const form = widgetFormRef.current;
		if(!form.isValid() || !store.redirectConfig.isValid)
			return;

		//let cell = store.cell //cell will be cleared in the end of saveWidget

		store.saveWidget(form.getConfig())

		const graph = store.designerStore.legacyDesigner.graph

		graph.setCellStyles(
			'forceInvalidate',
			newGuid(),
			[store.cell]
		);

		store.closeWizard()
	}

	const onCancel = () => {
		store.closeWizard()
	}

	const onSelect = async item =>{
		store.changeType(item.key)
	}

	const [height, top] = useMemo(() => {
		const designerContainer = store.designerStore.config.container;
		const rect = designerContainer.getBoundingClientRect();
		return [rect.height - 40, rect.y + 20];
	}, [store]);

	const [openKeys, onOpenChange] = useMenuNavigation(props);

	let [preview, showPreview, hidePreview] = useExpandCollapsePreview();

	return (
		<AntModal open={true}
			mask={true}
			maskClosable={false}
			resizable={true}
			height={height}
			mode={store.mode}
			onOk={onOk}
			onCancel={onCancel}
			width={store.width}
			style={{top: top}}
		>
			<Section direction={"row"}
				contentPadding={true}
				containerClass={b()}
				appearance={"none"}
				childrenPadding={true}
				scrollable={'y'}
			>
				<Toolbar title={store.title}>
					{preview
						? <AntButton icon={<CaretRightOutlined/>} type={"text"} size={"small"} onClick={hidePreview} />
						: <AntButton icon={<CaretLeftOutlined />} type={"text"} size={"small"} onClick={showPreview} />
					}
				</Toolbar>
				{store.mode == 'create' &&
					<Section containerClass={b('menu')}
					         appearance={"none"}
					         scrollable={true}>
						<Menu mode="inline"
						      onSelect={onSelect}
						      defaultSelectedKeys={[store.widgetConfig.type]}
						      openKeys={openKeys}
						      onOpenChange={onOpenChange}
						      inlineIndent={25}
						      style={{ width: 256 }}
							  items={generateMenuItems(false, true)}>
						</Menu>
					</Section>
				}
				<Section containerClass={b('form')}
				         appearance={"none"}>
					<WidgetForm store={store}
					            ref={widgetFormRef}/>
				</Section>
				{!preview && <Section appearance={"none"}
				                      containerClass={b('preview-container')}
				                      childrenPadding={true}>
					<div className={b('preview-container')}>
						<img className={b('preview')}
						     src={'assets-static/dashboard-designer/previews/' + store.widgetConfig.type + '.png'}
					         alt={'Preview'}/>
					</div>
					<p dangerouslySetInnerHTML={{__html: i('widget-description-' + store.widgetConfig.type)}}></p>
				</Section>
				}
			</Section>
		</AntModal>
	)
})

function useExpandCollapsePreview(){
	const [preview, setPreview] = React.useState(true);

	let updateUserSettings = async function(value){
		setPreview(value)
		await updateDesignerSetting('preview', value);
	}

	const hidePreview = React.useCallback(() => {
		updateUserSettings(false)
	}, []);

	const showPreview = React.useCallback(() => {
		updateUserSettings(true);
	}, []);

	React.useEffect(() => {
		var body = async function(){
			var settings = await loadDesignerSettings();
			setPreview(settings.previewCollapsed);
		}

		body();
	}, [])

	return [preview, showPreview, hidePreview];
}

function useMenuNavigation(props){
	const defaultOpenKeys = React.useMemo(() => {
		return [getWidgetsMenuStructure()
			.find( section => section.widgets.find(widget => widget.type == props.store.widgetConfig.type))?.title
		]
	},[])

	const [openKeys, setOpenKeys] = React.useState(defaultOpenKeys);

	const onOpenChange = useCallback( newOpenKeys => {
		const latestOpenKey = newOpenKeys.find(key => openKeys.indexOf(key) === -1);
		if (!getWidgetsMenuStructure().find(section => section.title == latestOpenKey)) {
			setOpenKeys( newOpenKeys );
		} else {
			setOpenKeys( latestOpenKey ? [latestOpenKey] : []);
		}
	}, [openKeys]);


	return [openKeys, onOpenChange];
}
