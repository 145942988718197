import React from "react";

import {BarsStatesWidget, summaryToDataListConverter, withStateDataSource} from "controls/react/barsStatesWidget";
import {Severity} from "framework/entities/healthData";

import {ServiceStateSummary} from "framework/entities/service";

const i = require('core/localization/localization').translator({
	BarTitle: {
		en: '{1} services are {0}',
		no: '{1} tjenestene er {0}'
	}
});

enum ServiceSeverity {
	Critical= 'DOWN',
	Major= 'WARNING',
	None= 'UP',
	Invalid= 'INACTIVE'
}

const severityMap = new Map<ServiceSeverity, Severity>([
	[ServiceSeverity.Critical, Severity.Critical],
	[ServiceSeverity.Major, Severity.Major],
	[ServiceSeverity.None, Severity.None],
	[ServiceSeverity.Invalid, Severity.Invalid],
]);

const valueGetter = new Map<ServiceSeverity, (summary: ServiceStateSummary, isWarning?: boolean) => number | boolean>([
	[ServiceSeverity.Critical, (summary: ServiceStateSummary, isWarning?: boolean) => isWarning ? summary.downWithError != 0 : summary.down],
	[ServiceSeverity.Major, (summary: ServiceStateSummary, isWarning?: boolean) => isWarning ? summary.warningWithError != 0 : summary.warning],
	[ServiceSeverity.None, (summary: ServiceStateSummary, isWarning?: boolean) => isWarning ? summary.upWithError != 0 : summary.total - summary.down - summary.warning - summary.inactive],
	[ServiceSeverity.Invalid, (summary: ServiceStateSummary, isWarning?: boolean) => isWarning ? undefined : summary.inactive],
]);

const availableSeverities = [ServiceSeverity.Critical, ServiceSeverity.Major, ServiceSeverity.None, ServiceSeverity.Invalid];
const getDataList = summaryToDataListConverter(availableSeverities, severityMap, valueGetter, i);
const dataSourceGetter = (summary: ServiceStateSummary, severities: ServiceSeverity[]) => getDataList(summary, severities);

export const ServiceStateWidget = withStateDataSource<ServiceStateSummary, ServiceSeverity>(BarsStatesWidget, dataSourceGetter);
