import { primitive, list, object } from "serializr";
import {createModelSchemaWrapper} from "../../../../framework/serializr-integration";
import {CostGatewayScheduleName} from "./costGatewayConnection";

export class CostGatewaySubscription {
	id: string
	name: string
}

export class CostGatewayLevelTypeOption {
	value: string
	text: string
	description: string
	fieldName: string
	fieldType: string
	topLevel: boolean
	bottomLevel: boolean
	required: boolean
}

export class CostGatewayLevelType {
	value: string
	text: string
	description: string
	leafNodeOnly: boolean
	typeOptions: CostGatewayLevelTypeOption[]
}

export class CostGatewayScheduleType {
	name: CostGatewayScheduleName
	text: string
	schedule: string
}

export class CostGatewayCloudType {
	value: string
	text: string
	authTypes: string[]
	scheduleTypes: CostGatewayScheduleType[]
	elementTypes: CostGatewayLevelType[] //todo: rename to levelTypes
	dataTypes: {value: string, text: string}[]
}

createModelSchemaWrapper(CostGatewaySubscription, {
	id: primitive(),
	name: primitive()
})

createModelSchemaWrapper(CostGatewayScheduleType, {
	name: primitive(),
	schedule: primitive(),
	text: primitive()
})

createModelSchemaWrapper(CostGatewayLevelTypeOption, {
	value: primitive(),
	text: primitive(),
	description: primitive(),
	fieldName: primitive(),
	fieldType: primitive(),
	topLevel: primitive(),
	bottomLevel: primitive()
})

createModelSchemaWrapper(CostGatewayLevelType, {
	value: primitive(),
	text: primitive(),
	description: primitive(),
	leafNodeOnly: primitive(),
	typeOptions: list(object(CostGatewayLevelTypeOption))
})

createModelSchemaWrapper(CostGatewayCloudType, {
	value: primitive(),
	text: primitive(),
	authTypes: list(primitive()),
	scheduleTypes: list(object(CostGatewayScheduleType)),
	elementTypes: list(object(CostGatewayLevelType)),
	dataTypes: list(primitive())
})
