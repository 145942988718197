import PropTypes from "prop-types";
import React, {useState, useCallback, useEffect, useRef} from "react";

import {Toolbar} from "controls/react/layout/toolbar";
import {Section} from "controls/react/layout/section";
import {IconButton} from "../form/iconButton";

import './collapsibleFrame.less';
import classnames from 'classnames';

export function CollapsibleFrame(props) {
	const [state, setState] = useState(props.expanded);

	const onToggle = useCallback(() => {
		if (props.disabled) {
			return;
		}
		setState(!state);
		props.onChange?.(!state);
	}, [state, props.disabled]);

	useEffect(() => {
		if (state != props.expanded) {
			setState(props.expanded);
		}
	}, [props.expanded]);

	const Switcher = props.switcher;
	const classes = classnames(...([props.className, props.hideCollapsedPlaceholder && !state ? 'hide-collapsed-placeholder': ''].filter(x => x != undefined)));
	return <Section appearance={"frame"} className={classes} childrenPadding={props.childrenPadding}>
		<Toolbar title={props.title} >
			{!props.switcher && <IconButton iconName={state ? 'minus' : 'unchecked'}
			            onClick={onToggle}
			            embedded={true}/>}
			{props.switcher && <Switcher disabled={props.disabled} enabled={state} onChange={onToggle}></Switcher>}
		</Toolbar>
		{state && !props.disabled && props.children}
		{!state && props.alwaysShowFields && props.children}
		{(!state || props.disabled) && !props.hideCollapsedPlaceholder && <span onClick={onToggle} className={"collapsible-frame-maximize"}>{lang.widget.messages.MAXIMIZE_SETTINGS}</span>}
	</Section>
}

CollapsibleFrame.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	expanded: PropTypes.bool,
	hideCollapsedPlaceholder: PropTypes.bool,
	onChange: PropTypes.func,
	childrenPadding: PropTypes.bool
};

CollapsibleFrame.defaultProps = {
	expanded: true,
	hideCollapsedPlaceholder: false
}

export default CollapsibleFrame;
