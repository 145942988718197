import {Designer} from "controls/designer/designer";
import {CustomDataEntry} from "controls/designer/customDataEntry";

export interface AddListenerFunc<T extends object> {
	(name: MxEvent.CHANGE, callback: (graphModel: T, ev: MxGraphModelChangeEventObject) => void): void
	(name: MxEvent.NOTIFY, callback: (graphModel: T, ev: MxGraphModelNotifyEventObject) => void): void
	(name: MxEvent.MOVE_CELLS, callback: (graphModel: T, ev: MxGraphMoveCellsEventObject) => void): void
	(name: MxEvent.SCALE, callback: () => void): void
	(name: MxEvent.EXECUTE, callback: (graphModel: T, ev: MxEventObject) => void): void
	(name: MxEvent.CELL_STATE_COLOR_CHANGE, callback: (graphModel: T, ev: MxEventObject) => void): void
}


export interface MxEventSource<T extends object>  {
	addListener: AddListenerFunc<T>
	removeListener: (callback: any) => void
	fireEvent: (evt: string, sender: any) => void
}

export interface MxGraph extends MxEventSource<MxGraph>{
	designer: Designer
	zoomIn: () => {},
	zoomOut: () => {},
	zoom: (factor: number) => {},
	removeCells: (cells?: MxCell[], includeEdges?: boolean) => {},
	orderCells:  (back: boolean, cells?: MxCell[]) => {}
	view: MxView
	container: HTMLElement
	getSelectionModel: () => MxGraphSelectionModel
	getSelectionCells: () => MxCell[]
	getModel: () => MxGraphModel

	refresh: () => void

	insertVertex: (parent: MxCell, id: string, value: HTMLObjectElement|string,
	               x: number, y: number, width: number, height: number,
	               style: string, relative?: boolean) => MxCell

	setSelectionCells: (cells: MxCell[]) => void

	getDefaultParent: () => MxCell

	getCellStyle: (cell: MxCell) => Record<string,string>

	setCellStyles: (key: string, value: string, cells: MxCell[]) => void
}

export interface MxGraphSelectionModel extends MxEventSource<MxGraphSelectionModel> {

}

export class MxClipboard{
	static get customDataList(){
		//@ts-ignore
		return mxClipboard.customDataList as CustomDataEntry[]
	}

}

export type MxCell = {
	id: number
	getDatasource: () => any//CellDataSource
	getValue:() => HTMLObjectElement
	value: HTMLObjectElement
	getAttribute(name: string): string
	setAttribute(name: string, value: string): void
	removeAttribute(name: string): void
	getValueAsXml: () => HTMLObjectElement
	getGeometry: () => MxGeometry
	parent: MxCell
	visible: boolean
}

export type MxGeometry = {
	width: number
	height: number
	x: number
	y: number

	copy: (x?: number, y?: number, width?: number, height?: number) => MxGeometry
	isInside: (geometry: MxGeometry) => boolean
}

export type MxUndoManager = {
	undo: () => {},
	redo: () => {}
}

export type MxEditor = {
	undoManager: MxUndoManager
	readGraphState: (node: HTMLObjectElement) => void
	getGraphXml: () => HTMLObjectElement
}

export interface MxView extends MxEventSource<MxView>{
	scale: number
	setScale: (scale: number) => void
	invalidate: (cell: MxCell, recursive?: boolean , includeEdges?: boolean) => void
	validate: (cell: MxCell) => void
}

export interface MxGraphModel extends MxEventSource<MxGraphModel> {
	cells: MxCell[]
	setGeometry: (cell: MxCell, geometry: MxGeometry) => void

}

export type MxEventObject = MxGraphModelNotifyEventObject

export type MxGraphModelNotifyEventObject = {
	name: MxEvent.NOTIFY
	properties: {
		change?: MxChange
		cell?: MxCell
		cells?: MxCell[]
		changes?: MxChange[]
	}
}

export type MxGraphModelChangeEventObject = {
	name: MxEvent.EXECUTE
	properties: {
		changes?: MxChange[]
		edit: any
	}
}

export type MxGraphMoveCellsEventObject = {
	name: MxEvent.MOVE_CELLS
	properties: {
		cells: MxCell[]
	}
}

export class MxChange {
}

export type MxChildChange = MxChange & {
	model: MxGraphModel
	parent: MxCell
	previous: MxCell
	child: MxCell
	index: number
	previousIndex: number
}

export type MxGeometryChange = MxChange & {
	cell: MxCell
}

export type MxTerminalChange = MxChange & {
	cell: MxCell
}

export type MxStyleChange = MxChange & {
	cell: MxCell
	model: MxGraphModel
	previous: string
	style: string
}

export function isMxChildChange(change: MxChange): change is MxChildChange {
	// @ts-ignore
	return change instanceof mxChildChange
}

export function isMxGeometryChange(change: MxChange): change is MxGeometryChange {
	// @ts-ignore
	return change instanceof mxGeometryChange
}

export function isMxTerminalChange(change: MxChange): change is MxTerminalChange {
	// @ts-ignore
	return change instanceof mxTerminalChange
}

export function isMxStyleChange(change: MxChange): change is MxStyleChange{
	//@ts-ignore
	return change instanceof mxStyleChange
}

export enum MxConstants{
	STYLE_ICON_COLOR = 'iconColor',
	STYLE_FILLCOLOR = 'fillColor',
	STYLE_STATE_COLOR = 'ceeviewStateColor',
	STYLE_IMAGE_BACKGROUND = 'imageBackground',
}

export class MxUtils{
	static getValue(style: string, name: string, defaultValue: string){
		//@ts-ignore
		return mxUtils.getValue(style, name, defaultValue)
	}
}


export enum MxEvent{
	/**
	 * Variable = MOUSE_DOWN
	 *
	 * Specifies the event name for mouseDown.
	 */
	MOUSE_DOWN = 'mouseDown',

	/**
	 * Variable = MOUSE_MOVE
	 *
	 * Specifies the event name for mouseMove.
	 */
	MOUSE_MOVE = 'mouseMove',

	/**
	 * Variable = MOUSE_UP
	 *
	 * Specifies the event name for mouseUp.
	 */
	MOUSE_UP = 'mouseUp',

	/**
	 * Variable = ACTIVATE
	 *
	 * Specifies the event name for activate.
	 */
	ACTIVATE = 'activate',

	/**
	 * Variable = RESIZE_START
	 *
	 * Specifies the event name for resizeStart.
	 */
	RESIZE_START = 'resizeStart',

	/**
	 * Variable = RESIZE
	 *
	 * Specifies the event name for resize.
	 */
	RESIZE = 'resize',

	/**
	 * Variable = RESIZE_END
	 *
	 * Specifies the event name for resizeEnd.
	 */
	RESIZE_END = 'resizeEnd',

	/**
	 * Variable = MOVE_START
	 *
	 * Specifies the event name for moveStart.
	 */
	MOVE_START = 'moveStart',

	/**
	 * Variable = MOVE
	 *
	 * Specifies the event name for move.
	 */
	MOVE = 'move',

	/**
	 * Variable = MOVE_END
	 *
	 * Specifies the event name for moveEnd.
	 */
	MOVE_END = 'moveEnd',

	/**
	 * Variable = PAN_START
	 *
	 * Specifies the event name for panStart.
	 */
	PAN_START = 'panStart',

	/**
	 * Variable = PAN
	 *
	 * Specifies the event name for pan.
	 */
	PAN = 'pan',

	/**
	 * Variable = PAN_END
	 *
	 * Specifies the event name for panEnd.
	 */
	PAN_END = 'panEnd',

	/**
	 * Variable = MINIMIZE
	 *
	 * Specifies the event name for minimize.
	 */
	MINIMIZE = 'minimize',

	/**
	 * Variable = NORMALIZE
	 *
	 * Specifies the event name for normalize.
	 */
	NORMALIZE = 'normalize',

	/**
	 * Variable = MAXIMIZE
	 *
	 * Specifies the event name for maximize.
	 */
	MAXIMIZE = 'maximize',

	/**
	 * Variable = HIDE
	 *
	 * Specifies the event name for hide.
	 */
	HIDE = 'hide',

	/**
	 * Variable = SHOW
	 *
	 * Specifies the event name for show.
	 */
	SHOW = 'show',

	/**
	 * Variable = CLOSE
	 *
	 * Specifies the event name for close.
	 */
	CLOSE = 'close',

	/**
	 * Variable = DESTROY
	 *
	 * Specifies the event name for destroy.
	 */
	DESTROY = 'destroy',

	/**
	 * Variable = REFRESH
	 *
	 * Specifies the event name for refresh.
	 */
	REFRESH = 'refresh',

	/**
	 * Variable = SIZE
	 *
	 * Specifies the event name for size.
	 */
	SIZE = 'size',

	/**
	 * Variable = SELECT
	 *
	 * Specifies the event name for select.
	 */
	SELECT = 'select',

	/**
	 * Variable = FIRED
	 *
	 * Specifies the event name for fired.
	 */
	FIRED = 'fired',

	/**
	 * Variable = FIRE_MOUSE_EVENT
	 *
	 * Specifies the event name for fireMouseEvent.
	 */
	FIRE_MOUSE_EVENT = 'fireMouseEvent',

	/**
	 * Variable = GESTURE
	 *
	 * Specifies the event name for gesture.
	 */
	GESTURE = 'gesture',

	/**
	 * Variable = TAP_AND_HOLD
	 *
	 * Specifies the event name for tapAndHold.
	 */
	TAP_AND_HOLD = 'tapAndHold',

	/**
	 * Variable = GET
	 *
	 * Specifies the event name for get.
	 */
	GET = 'get',

	/**
	 * Variable = RECEIVE
	 *
	 * Specifies the event name for receive.
	 */
	RECEIVE = 'receive',

	/**
	 * Variable = CONNECT
	 *
	 * Specifies the event name for connect.
	 */
	CONNECT = 'connect',

	/**
	 * Variable = DISCONNECT
	 *
	 * Specifies the event name for disconnect.
	 */
	DISCONNECT = 'disconnect',

	/**
	 * Variable = SUSPEND
	 *
	 * Specifies the event name for suspend.
	 */
	SUSPEND = 'suspend',

	/**
	 * Variable = RESUME
	 *
	 * Specifies the event name for suspend.
	 */
	RESUME = 'resume',

	/**
	 * Variable = MARK
	 *
	 * Specifies the event name for mark.
	 */
	MARK = 'mark',

	/**
	 * Variable = ROOT
	 *
	 * Specifies the event name for root.
	 */
	ROOT = 'root',

	/**
	 * Variable = POST
	 *
	 * Specifies the event name for post.
	 */
	POST = 'post',

	/**
	 * Variable = OPEN
	 *
	 * Specifies the event name for open.
	 */
	OPEN = 'open',

	/**
	 * Variable = SAVE
	 *
	 * Specifies the event name for open.
	 */
	SAVE = 'save',

	/**
	 * Variable = BEFORE_ADD_VERTEX
	 *
	 * Specifies the event name for beforeAddVertex.
	 */
	BEFORE_ADD_VERTEX = 'beforeAddVertex',

	/**
	 * Variable = ADD_VERTEX
	 *
	 * Specifies the event name for addVertex.
	 */
	ADD_VERTEX = 'addVertex',

	/**
	 * Variable = AFTER_ADD_VERTEX
	 *
	 * Specifies the event name for afterAddVertex.
	 */
	AFTER_ADD_VERTEX = 'afterAddVertex',

	/**
	 * Variable = DONE
	 *
	 * Specifies the event name for done.
	 */
	DONE = 'done',

	/**
	 * Variable = EXECUTE
	 *
	 * Specifies the event name for execute.
	 */
	EXECUTE = 'execute',

	/**
	 * Variable = EXECUTED
	 *
	 * Specifies the event name for executed.
	 */
	EXECUTED = 'executed',

	/**
	 * Variable = BEGIN_UPDATE
	 *
	 * Specifies the event name for beginUpdate.
	 */
	BEGIN_UPDATE = 'beginUpdate',

	/**
	 * Variable = START_EDIT
	 *
	 * Specifies the event name for startEdit.
	 */
	START_EDIT = 'startEdit',

	/**
	 * Variable = END_UPDATE
	 *
	 * Specifies the event name for endUpdate.
	 */
	END_UPDATE = 'endUpdate',

	/**
	 * Variable = END_EDIT
	 *
	 * Specifies the event name for endEdit.
	 */
	END_EDIT = 'endEdit',

	/**
	 * Variable = BEFORE_UNDO
	 *
	 * Specifies the event name for beforeUndo.
	 */
	BEFORE_UNDO = 'beforeUndo',

	/**
	 * Variable = UNDO
	 *
	 * Specifies the event name for undo.
	 */
	UNDO = 'undo',

	/**
	 * Variable = REDO
	 *
	 * Specifies the event name for redo.
	 */
	REDO = 'redo',

	/**
	 * Variable = CHANGE
	 *
	 * Specifies the event name for change.
	 */
	CHANGE = 'change',

	/**
	 * Variable = NOTIFY
	 *
	 * Specifies the event name for notify.
	 */
	NOTIFY = 'notify',

	/**
	 * Variable = LAYOUT_CELLS
	 *
	 * Specifies the event name for layoutCells.
	 */
	LAYOUT_CELLS = 'layoutCells',

	/**
	 * Variable = CLICK
	 *
	 * Specifies the event name for click.
	 */
	CLICK = 'click',

	/**
	 * Variable = SCALE
	 *
	 * Specifies the event name for scale.
	 */
	SCALE = 'scale',

	/**
	 * Variable = TRANSLATE
	 *
	 * Specifies the event name for translate.
	 */
	TRANSLATE = 'translate',

	/**
	 * Variable = SCALE_AND_TRANSLATE
	 *
	 * Specifies the event name for scaleAndTranslate.
	 */
	SCALE_AND_TRANSLATE = 'scaleAndTranslate',

	/**
	 * Variable = UP
	 *
	 * Specifies the event name for up.
	 */
	UP = 'up',

	/**
	 * Variable = DOWN
	 *
	 * Specifies the event name for down.
	 */
	DOWN = 'down',

	/**
	 * Variable = ADD
	 *
	 * Specifies the event name for add.
	 */
	ADD = 'add',

	/**
	 * Variable = REMOVE
	 *
	 * Specifies the event name for remove.
	 */
	REMOVE = 'remove',

	/**
	 * Variable = CLEAR
	 *
	 * Specifies the event name for clear.
	 */
	CLEAR = 'clear',

	/**
	 * Variable = ADD_CELLS
	 *
	 * Specifies the event name for addCells.
	 */
	ADD_CELLS = 'addCells',

	/**
	 * Variable = CELLS_ADDED
	 *
	 * Specifies the event name for cellsAdded.
	 */
	CELLS_ADDED = 'cellsAdded',

	/**
	 * Variable = MOVE_CELLS
	 *
	 * Specifies the event name for moveCells.
	 */
	MOVE_CELLS = 'moveCells',

	/**
	 * Variable = CELLS_MOVED
	 *
	 * Specifies the event name for cellsMoved.
	 */
	CELLS_MOVED = 'cellsMoved',

	/**
	 * Variable = RESIZE_CELLS
	 *
	 * Specifies the event name for resizeCells.
	 */
	RESIZE_CELLS = 'resizeCells',

	/**
	 * Variable = CELLS_RESIZED
	 *
	 * Specifies the event name for cellsResized.
	 */
	CELLS_RESIZED = 'cellsResized',

	/**
	 * Variable = TOGGLE_CELLS
	 *
	 * Specifies the event name for toggleCells.
	 */
	TOGGLE_CELLS = 'toggleCells',

	/**
	 * Variable = CELLS_TOGGLED
	 *
	 * Specifies the event name for cellsToggled.
	 */
	CELLS_TOGGLED = 'cellsToggled',

	/**
	 * Variable = ORDER_CELLS
	 *
	 * Specifies the event name for orderCells.
	 */
	ORDER_CELLS = 'orderCells',

	/**
	 * Variable = CELLS_ORDERED
	 *
	 * Specifies the event name for cellsOrdered.
	 */
	CELLS_ORDERED = 'cellsOrdered',

	/**
	 * Variable = REMOVE_CELLS
	 *
	 * Specifies the event name for removeCells.
	 */
	REMOVE_CELLS = 'removeCells',

	/**
	 * Variable = CELLS_REMOVED
	 *
	 * Specifies the event name for cellsRemoved.
	 */
	CELLS_REMOVED = 'cellsRemoved',

	/**
	 * Variable = GROUP_CELLS
	 *
	 * Specifies the event name for groupCells.
	 */
	GROUP_CELLS = 'groupCells',

	/**
	 * Variable = UNGROUP_CELLS
	 *
	 * Specifies the event name for ungroupCells.
	 */
	UNGROUP_CELLS = 'ungroupCells',

	/**
	 * Variable = REMOVE_CELLS_FROM_PARENT
	 *
	 * Specifies the event name for removeCellsFromParent.
	 */
	REMOVE_CELLS_FROM_PARENT = 'removeCellsFromParent',

	/**
	 * Variable = FOLD_CELLS
	 *
	 * Specifies the event name for foldCells.
	 */
	FOLD_CELLS = 'foldCells',

	/**
	 * Variable = CELLS_FOLDED
	 *
	 * Specifies the event name for cellsFolded.
	 */
	CELLS_FOLDED = 'cellsFolded',

	/**
	 * Variable = ALIGN_CELLS
	 *
	 * Specifies the event name for alignCells.
	 */
	ALIGN_CELLS = 'alignCells',

	/**
	 * Variable = LABEL_CHANGED
	 *
	 * Specifies the event name for labelChanged.
	 */
	LABEL_CHANGED = 'labelChanged',

	/**
	 * Variable = CONNECT_CELL
	 *
	 * Specifies the event name for connectCell.
	 */
	CONNECT_CELL = 'connectCell',

	/**
	 * Variable = CELL_CONNECTED
	 *
	 * Specifies the event name for cellConnected.
	 */
	CELL_CONNECTED = 'cellConnected',

	/**
	 * Variable = SPLIT_EDGE
	 *
	 * Specifies the event name for splitEdge.
	 */
	SPLIT_EDGE = 'splitEdge',

	/**
	 * Variable = FLIP_EDGE
	 *
	 * Specifies the event name for flipEdge.
	 */
	FLIP_EDGE = 'flipEdge',

	/**
	 * Variable = START_EDITING
	 *
	 * Specifies the event name for startEditing.
	 */
	START_EDITING = 'startEditing',

	/**
	 * Variable = EDITING_STARTED
	 *
	 * Specifies the event name for editingStarted.
	 */
	EDITING_STARTED = 'editingStarted',

	/**
	 * Variable = EDITING_STOPPED
	 *
	 * Specifies the event name for editingStopped.
	 */
	EDITING_STOPPED = 'editingStopped',

	/**
	 * Variable = ADD_OVERLAY
	 *
	 * Specifies the event name for addOverlay.
	 */
	ADD_OVERLAY = 'addOverlay',

	/**
	 * Variable = REMOVE_OVERLAY
	 *
	 * Specifies the event name for removeOverlay.
	 */
	REMOVE_OVERLAY = 'removeOverlay',

	/**
	 * Variable = UPDATE_CELL_SIZE
	 *
	 * Specifies the event name for updateCellSize.
	 */
	UPDATE_CELL_SIZE = 'updateCellSize',

	/**
	 * Variable = ESCAPE
	 *
	 * Specifies the event name for escape.
	 */
	ESCAPE = 'escape',

	/**
	 * Variable = DOUBLE_CLICK
	 *
	 * Specifies the event name for doubleClick.
	 */
	DOUBLE_CLICK = 'doubleClick',

	/**
	 * Variable = START
	 *
	 * Specifies the event name for start.
	 */
	START = 'start',

	/**
	 * Variable = RESET
	 *
	 * Specifies the event name for reset.
	 */
	RESET = 'reset',

	CELL_STATE_COLOR_CHANGE = 'cell-state-color-changed'
}
