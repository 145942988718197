import Lang from 'core/localization/lang'

export function setBasicChartConfig(config, node, type, height) {
	Object.assign(config.chart, {
		renderTo: node,
		type: type,
		height,
		zoomType: 'x',
		events: {}
	});
}

export function setSeriesData(config, data) {
	Object.assign(config.series[0], {data});
}

export function setChartEvent(chartConfig, eventName, func) {
	chartConfig.events = chartConfig.events || {};
	chartConfig.events[eventName] = func;
}

export function setLegend(chartConfig, labelTemplate, metric, period, timezone) {
	if (labelTemplate && labelTemplate.length) {
		Object.assign(chartConfig.series[0], {name: compileName(labelTemplate, metric, period, timezone)});
	} else {
		chartConfig.legend.enabled = false;
	}
}

export function setValueSuffix(chartConfig, {customUnit, unitTypeSymbol}) {
	Object.assign(chartConfig.series[0], { tooltip: {valueSuffix: customUnit || unitTypeSymbol || ''} });
}

export function setValueDecimals(chartConfig, {decimals}) {
	Object.assign(chartConfig.series[0], { tooltip: {valueDecimals: decimals} });
}

export function setMinMax(config, min, max) {
	Object.assign(config.xAxis, {min, max});
}

export function setMetricConfig(config, {color}) {
	color && Object.assign(config.series[0], {color});
}

export function compileName(labelTemplate, metric, period, timezone) {
	const {assetName, categoryNode, registryType, identifier, instanceName} = metric;

	var periodMap = {
		'LAST30DAYS': lang.MONTH,
		'LAST7DAYS': lang.WEEK,
		'LASTDAY': lang.DAY,
		'LASTHOUR': lang.HOUR,
		'CUSTOM': lang.CUSTOM
	};

	var labelMap = {
		'<Asset>': `${assetName || ''}`,
		'<Name>': `${categoryNode || ''}`,
		'<Type>': `${registryType || ''}`,
		'<Category>': `${identifier || ''}`,
		'<Instance>': `${instanceName || ''}`,
		'<Period>': `${periodMap[period] || ''}`,
		'<Timezone>': `${timezone || ''}`
	};

	let labelArray = [];
	labelTemplate.map((label) => {
		if (labelMap[label]) {
			labelArray.push(labelMap[label]);
		}
	});
	let labelString = labelArray.length ? labelArray.join('/') : '';
	return labelString;
}

export function setResetButton(config, onReset) {
	config.exporting.buttons.popUpBtn = {
		onclick: function() {
			onReset(this)
		},
		align: 'left',
		width: 5,
		x: 5,
		y: 5,
		text: Lang.RESET,
		_titleKey: "resetTooltip",
		theme: {
			'stroke-width': 1,
			stroke: '#aaa',
			fill: '#fff',
			r: 0,
			states: {
				hover: {
					fill: '#eee'
				},
				select: {
					fill: '#ccc'
				}
			}
		},
		enabled: true
	};
};
