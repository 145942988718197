import Utils from 'tools/utils'
import Widget from 'areas/service-boards/widget'
import Settings from 'settings'
import CustomNotification from 'controls/customNotification'
import RemoteEventsManager from 'core/remoteEventsManager'
import Highcharts from 'highcharts'
import Configuration from 'configuration';
import Renderer from 'tools/renderer';
import State from 'tools/state';
import {KpiRouter} from 'areas/kpi/bundleDescription';
import {CssVariables} from "styles/cssVariables";


export function GaugeWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.initComponent();
};

export {GaugeWidget as default};

jQuery.extend(GaugeWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		this.gaugeType = this.configuration.gaugeType || 'gauge';

		this.useDynamicMax = false;
		this.errorCode = 'OK';
		this.value = 0;
		this.breachValue = this.configuration.breachValue || 80;
		this.inverted = this.configuration.inverted;
		this.minValue = this.configuration.minValue || 0;
		if (this.minValue === 'null') {
			this.minValue = 0;
		}

		this.hasInfoSign = true;

		this.customControls = {
			target: '#' + this.id,
			toggleClick: function (value) {
				if ($('#' + this.id).find('.k-i-toggle').length) {
					$('#' + this.id).find('.k-i-toggle').trigger('click');
				} else {
					$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
				}
			}
		};
		this.createCustomControls();

		if (this.configuration.maxValue === undefined || this.configuration.maxValue === null) {
			this.useDynamicMax = true;
			this.maxValue = this.breachValue + 20 * this.breachValue / 100;
			this.configuration.maxValue = this.maxValue;
		} else {
			this.maxValue = this.configuration.maxValue;
		}

		//Provided backward compatibility
		this.getInitialData();

		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));

		let title = lang.GAUGE + '/' + this.instanceConfiguration.profileText;
		this.setTitleIfEmpty(title);
	},
	/**
	 * Gets the inistial data to populate the chart
	 */
	getInitialData: function () {
		var data = this.configuration.metrics || [];
		var url = this.requestPath + 'kpi/lastData';

		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		kendo.ui.progress(widgetContentDiv, true);
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			this.gaugeNotification = new CustomNotification({
				animationTime: 0,
				appendToElement: $('#' + this.id).find('.section__content'),
			});

			if (result && result.length && result[0].data) {
				var data = result[0].data[0];
				this.data = data;
				this.subscribe();

				this.chartData = [];
				var fullName, name;
				this.seriesItem = {};
				fullName = this.generateMultigraphLabel(result[0]);
				name = fullName.length > 60 ? fullName.substr(0, 57) + '...' : fullName;

				this.seriesItem.name = name;
				this.seriesItem.fullName = fullName;
				//seriesItem.color = this.getColor(dataSet[i].monitorId);
				this.seriesItem.visible = true;

				if (data) {
					this.value = data.value;

					this.renderChart(this.gaugeType, {
						metric: {
							v: this.value,
							e: [0],
							t: data.time
						}
					});
				} else {
					this.gaugeNotification.setOptions({
						message: lang.NO_DATA,
						status: 'error'
					}).show();
				}
			} else {
				this.gaugeNotification.setOptions({
					message: lang.NO_DATA,
					status: 'error'
				}).show();
			}
			kendo.ui.progress(widgetContentDiv, false);
		}, this), $.proxy(function (result) {
			kendo.ui.progress(widgetContentDiv, false);
			this.gaugeNotification.setOptions({
				message: result.message || lang.NO_DATA,
				status: 'error'
			}).show();
		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(widgetContentDiv, false);
			this.gaugeNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	/*
	* Handler function for rendering chart
	* */
	renderChart: function (type, data, animation) {
		if(!data)
			return;
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		this.chartData = [];
		kendo.ui.progress(widgetContentDiv, false);
		if (type === 'gauge') {
			this.renderGauge(data, animation);
		} else {
			this.renderSolidGauge(data, animation);
		}
	},
	/**
	 * Renders the gauge
	 */
	renderSolidGauge: function (data, animation) {
		this.seriesItem.data = [];
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content'), renderedValue, title = ' ';

		widgetContentDiv.empty().append('<div class="cw_gauge"></div><div class="cw_widget_status hide"></div>');
		var widgetError = widgetContentDiv.find('.cw_widget_status');

		this.dataSet = data;
		this.eventTime = data.metric.t;
		if (this.maxValue !== undefined && this.maxValue !== null && this.value > this.maxValue) {
			renderedValue = this.maxValue;
			title = 'Over max: ' + this.value;
		} else if (this.minValue !== undefined && this.minValue !== null && this.value < this.minValue) {
			renderedValue = this.minValue;
			title = 'Below min: ' + this.value;
		} else {
			renderedValue = this.value;
		}

		this.seriesItem.data.push(this.value);
		this.chartData.push(this.seriesItem);

		var oThis = this;
		var animate = true;
		if (typeof animation !== 'undefined' && !animation) {
			animate = false;
		}

		var min = this.minValue || 0;
		var max = this.maxValue;

		var stop1 = this.breachValue / this.maxValue;
		var stop2 = stop1 + 0.00001;

		for (var i = 0, length = this.chartData.length; i < length; i++) {
			if (this.chartData[i].name) {
				this.chartData[i].showInLegend = true;
			}
			this.chartData[i].innerRadius = '70%';
			this.chartData[i].dataLabels = {
				formatter: function () {
					return '<div style="text-align:center"><span style="font-size:20px;color:#333">' + this.point.y + '</span>';
				}
			}
		}

		try {
			let container = widgetContentDiv.find('.cw_gauge');
			let height;
			let dashboardWindow = container.closest('.section__content');
			if (dashboardWindow.length) {
				height = dashboardWindow.height();
				container.css('height', height);
			} else {
				height = widgetContentDiv.height();
			}

			this.gauge = new Highcharts.Chart({
				chart: {
					type: 'solidgauge',
					height: height,
					renderTo: container[0],
					plotBackgroundColor: null,
					plotBackgroundImage: null,
					plotBorderWidth: 0,
					plotShadow: false,
					backgroundColor: 'transparent',
					events: {
						load: function () {
						}
					}
				},
				exporting: Configuration.highcharts.exporting,
				credits: {
					enabled: false
				},
				pane: {
					center: ['50%', '70%'],
					size: '100%',
					startAngle: -90,
					endAngle: 90,
					background: {
						backgroundColor: '#eee',
						innerRadius: '70%',
						outerRadius: '100%',
						shape: 'arc'
					}
				},
				legend: {
					title: {
						text: '', //lang.assethealth.MONITOR_NAME,
						align: 'center'
					},
					x: 2,
					y: 20,
					floating: false,
					borderWidth: 0,
					layout: 'horizontal',
					align: 'center',
					verticalAlign: 'bottom',
					useHTML: true,
					itemDistance: 15,
					itemStyle: {
						fontSize: "10px"
					},
					style: {
						fontSize: "10px"
					},
					labelFormatter: function () {
						return '<span title="' + this.userOptions.fullName + '">' + this.name + '</span>';
					}
				},
				plotOptions: {
					series: {
						marker: {
							enabled: false
						},
						events: {
							legendItemClick: () => {
								this.navigator?.go({
									url: KpiRouter.details(this.instanceConfiguration.profileId)
								});
							}
						}
					},
					solidgauge: {
						dataLabels: {
							y: -20,
							borderWidth: 0,
							useHTML: true
						}
					},
					borderColor: '#cacaca'
				},
				series: this.chartData,
				title: {
					text: '',
					y: 50
				},
				yAxis: {
					min: min,
					max: max,
					tickPositions: [min, max],
					stops: [
						[stop1, this.inverted ? CssVariables.severityCritical : CssVariables.severityNone],
						[stop2, this.inverted ? CssVariables.severityNone : CssVariables.severityCritical]
					],
					labels: {
						y: 20
					},
					minorTickInterval: 0,
					tickInterval: max,
					tickWidth: 0,
					gridLineWidth: 0,
					gridLineColor: 'transparent',
					plotBands: [{
						from: min,
						to: this.breachValue,
						color: this.inverted ? CssVariables.severityCritical : CssVariables.severityNone,
						outerRadius: '107%',
						thickness: '3%'
					},
						{
							from: this.breachValue,
							to: max,
							color: this.inverted ? CssVariables.severityNone : CssVariables.severityCritical,
							outerRadius: '107%',
							thickness: '3%'
						}]
				},
				tooltip: {
					backgroundColor: 'white',
					borderWidth: 0,
					shadow:{
						offsetX: 0,
						offsetY: 0,
						opacity: 1,
						width: 16,
						color: 'rgb(0,0,0,0.01)'
					},
					useHTML: true,
					formatter: function () {
						var s = Renderer.browserDateRenderer(oThis.eventTime, Constants.DATETIME);
						s ? s += '<br />' : s = '';
						if (oThis.errorCode === 'OK') {
							s += lang.VALUE + ': <strong>' + oThis.value + '</strong>';
						} else {
							s += oThis.errorCode;
						}
						s += '<br />';
						s += lang.widget.BREACH_VALUE + ': <strong>' + oThis.breachValue + '</strong>';
						s += '<br />';
						s += lang.widget.MAX_VALUE + ': <strong>' + max + '</strong>';
						return s;
					}
				},
				loading: false
			});
		} catch (e) {
		}

		if (title !== ' ') {
			this.gaugeNotification.setOptions({
				message: title,
				status: 'info'
			}).show();
		}
		if (this.dataSet.metric.e[0] !== 0) {
			this.errorCode = this.getErrorCode(this.dataSet.metric.e[0]);
			this.gaugeNotification.setOptions({
				message: this.errorCode,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Renders the gauge
	 */
	renderGauge: function (data, animation) {
		this.seriesItem.data = [];
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content'), renderedValue, title = ' ';
		widgetContentDiv.empty().append('<div class="cw_gauge"></div><div class="cw_widget_status hide"></div>');
		var widgetError = widgetContentDiv.find('.cw_widget_status');

		this.dataSet = data;
		this.eventTime = data.time;
		if (this.maxValue !== undefined && this.maxValue !== null && this.value > this.maxValue) {
			renderedValue = this.maxValue;
			title = 'Over max: ' + this.value;
		} else if (this.minValue !== undefined && this.minValue !== null && this.value < this.minValue) {
			renderedValue = this.minValue;
			title = 'Below min: ' + this.value;
		} else {
			renderedValue = this.value;
		}
		this.seriesItem.data.push(renderedValue);
		this.chartData.push(this.seriesItem);

		var oThis = this;
		var animate = true;
		if (typeof animation !== 'undefined' && !animation) {
			animate = false;
		}

		for (var i = 0, length = this.chartData.length; i < length; i++) {
			if (this.chartData[i].name) {
				this.chartData[i].showInLegend = true;
			}
		}

		try {
			let container = widgetContentDiv.find('.cw_gauge');
			let height;
			let dashboardWindow = container.closest('.section__content');
			if (dashboardWindow.length) {
				height = dashboardWindow.height();
				container.css('height', height);
			} else {
				height = widgetContentDiv.height();
			}

			this.gauge = new Highcharts.Chart({
				chart: {
					type: 'gauge',
					animation: animate,
					height: height,
					renderTo: container[0],
					plotBackgroundColor: null,
					plotBackgroundImage: null,
					plotBorderWidth: 0,
					plotShadow: false,
					backgroundColor: 'transparent',
					events: {
						load: function () {
						}
					}
				},
				exporting: Configuration.highcharts.exporting,
				credits: {
					enabled: false
				},
				title: {
					text: ' ',
					style: {
						color: '#FF0000'
					}
				},
				legend: {
					title: {
						text: '', //lang.assethealth.MONITOR_NAME,
						align: 'center'
					},
					x: 2,
					y: 20,
					floating: false,
					borderWidth: 0,
					layout: 'horizontal',
					align: 'center',
					verticalAlign: 'bottom',
					useHTML: true,
					itemDistance: 15,
					itemStyle: {
						fontSize: "10px"
					},
					style: {
						fontSize: "10px"
					},
					labelFormatter: function () {
						return '<span title="' + this.userOptions.fullName + '">' + this.name + '</span>';
					}
				},
				plotOptions: {
					series: {
						marker: {
							enabled: false
						},
						events: {
							legendItemClick: $.proxy(function () {
								this.navigator?.go({
									url: KpiRouter.details(this.instanceConfiguration.profileId)
								})
							}, this)
						}
					},
					gauge: {
						animation: animate,
						dataLabels: {
							enabled: true,
							//format: oThis.value + ' ' + unit
							formatter: function () {
								return oThis.value;
							}
						}
					}
				},
				pane: {
					startAngle: -150,
					endAngle: 150,
					background: [{
						backgroundColor: {
							linearGradient: {
								x1: 0,
								y1: 0,
								x2: 0,
								y2: 1
							},
							stops: [[0, '#FFF'], [1, '#333']]
						},
						borderWidth: 0,
						outerRadius: '109%'
					}, {
						backgroundColor: {
							linearGradient: {
								x1: 0,
								y1: 0,
								x2: 0,
								y2: 1
							},
							stops: [[0, '#333'], [1, '#FFF']]
						},
						borderWidth: 1,
						outerRadius: '107%'
					}, {// default background
					}, {
						backgroundColor: '#DDD',
						borderWidth: 0,
						outerRadius: '105%',
						innerRadius: '103%'
					}]
				},
				// the value axis
				yAxis: {
					min: this.minValue,
					max: this.maxValue,
					minorTickInterval: 'auto',
					minorTickWidth: 1,
					minorTickLength: 10,
					minorTickPosition: 'inside',
					minorTickColor: '#666',
					tickPixelInterval: 30,
					tickWidth: 2,
					tickPosition: 'inside',
					tickLength: 10,
					tickColor: '#666',
					labels: {
						step: 2,
						rotation: 'auto'
					},
					title: {
						text: ' '
					},
					plotBands: [{
						from: this.minValue,
						to: this.breachValue,
						color: this.inverted ?  CssVariables.severityCritical : CssVariables.severityNone
					}, {
						from: this.breachValue,
						to: this.maxValue,
						color: this.inverted ? CssVariables.severityNone : CssVariables.severityCritical
					}]
				},
				series: this.chartData,
				tooltip: {
					backgroundColor: 'white',
					borderWidth: 0,
					shadow:{
						offsetX: 0,
						offsetY: 0,
						opacity: 1,
						width: 16,
						color: 'rgb(0,0,0,0.01)'
					},
					useHTML: true,
					formatter: function () {
						var s = Renderer.browserDateRenderer(oThis.eventTime, Constants.DATETIME);
						s ? s += '<br />' : s = '';
						if (oThis.errorCode === 'OK') {
							s += lang.VALUE + ': <strong>' + oThis.value + '</strong>';
						} else {
							s += oThis.errorCode;
						}
						s += '<br />';
						s += lang.widget.BREACH_VALUE + ': <strong>' + oThis.breachValue + '</strong>';
						s += '<br />';
						s += lang.widget.MAX_VALUE + ': <strong>' + oThis.maxValue + '</strong>';
						return s;
					}
				}
			});
		} catch (e) {
		}

		if (title !== ' ') {
			this.gaugeNotification.setOptions({
				message: title,
				status: 'info'
			}).show();
		}
		if (this.dataSet.metric.e[0] !== 0) {
			this.errorCode = this.getErrorCode(this.dataSet.metric.e[0]);
			this.gaugeNotification.setOptions({
				message: this.errorCode,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Search the data for a timestamp point and returns its error code
	 * @param {Number} timestamp
	 * @return {String} errorCode
	 */
	getErrorCode: function (code) {
		var errorCode = '';
		switch (code) {
			case 1:
				errorCode = 'ERROR';
				break;
			case 10:
				errorCode = 'CONNECTION';
				break;
			case 20:
				errorCode = 'AUTHENTICATION';
				break;
			case 30:
				errorCode = 'CONFIGURATION';
				break;
			case 60:
				errorCode = 'METRICSTORE';
				break;
			default:
				errorCode = 'UNKNOWN ERROR CODE';
				break;
		}

		return errorCode;
	},
	/**
	 * Called when a metric event is received
	 * @param {Object} event The event data object
	 */
	onEvent: function (data) {
		var length = data.length, event;
		if (length) {
			event = data[length - 1];
		} else {
			event = data;
		}
		if (this.gauge) {
			var point = this.gauge.series[0].points[0], title = ' ';
			this.value = Math.floor(event.value * 100) / 100;
			this.eventTime = event.time;
			var renderedValue = this.value;
			var widgetError = $('#' + this.id).find('.cw_section_content').find('.cw_widget_status');
			this.dataSet = event;
			if (this.useDynamicMax) {
				if (!this.inverted) {
					if (this.value > this.maxValue) {
						this.maxValue = this.value + Math.round(this.value * 5 / 100);
						this.renderChart(this.gaugeType, event);
					}
				} else if (this.value < this.minValue) {
					this.minValue = this.value - Math.round(this.value * 5 / 100);
					this.renderChart(this.gaugeType, event);
				}
			} else {
				if (this.maxValue !== undefined && this.maxValue !== null && this.value > this.maxValue) {
					renderedValue = this.maxValue;
					title = 'Over max: ' + this.value;
				} else if (this.minValue !== undefined && this.minValue !== null && this.value < this.minValue) {
					renderedValue = this.minValue;
					title = 'Below min: ' + this.value;
				} else {
					renderedValue = this.value;
				}
				if (title !== ' ') {
					this.gaugeNotification.setOptions({
						message: title,
						status: 'info'
					}).show();
				}
			}

			var currentPoint = this.gauge.series[0].points[0];
			currentPoint.update(renderedValue);
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		this.renderChart(this.gaugeType, this.dataSet);
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	/*
	* Handler function for on drop event
	* */
	onDrop: function () {
		this.renderChart(this.gaugeType, this.dataSet);
	},
	/**
	 * Subscribes to  metrics events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'Kpi',
			filters: [{
				profileId: this.configuration.profileId,
				accountId: this.configuration.kpiAccountId === 'aggregated' ? null : this.configuration.kpiAccountId, //kpi account id
				configurationId: this.data.configurationId
			}]
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		$(window).off('resize', $.proxy(this.onResize, this));
		Widget.prototype.destroy.call(this);
	}
});
