import MultiSelect from "controls/react/form/multiSelect";
import React from "react";
import AssetGroupAPI from "tools/apis/assetGroupAPI";
import {AssetGroup} from "framework/entities/assetGroup";


const options = {
	dataTextField: "name",
	dataValueField: "id"
};

export function useAssetGroups(accountId: string = null, groupType: string = null) {
	const [assetGroups, setAssetGroups] = React.useState<AssetGroup[]>([]);
	const [loading, setLoading] = React.useState(false)

	React.useEffect(() => {
		const fetchAssetGroups = async () => {
			setLoading(true)
			let assetGroups = await AssetGroupAPI.getAssetGroups(accountId);
			if (groupType) {
				assetGroups = assetGroups.filter((x: {groupType: string}) => x.groupType == groupType);
			}
			setAssetGroups(assetGroups);
			setLoading(false)
		};
		fetchAssetGroups();
	}, [accountId]);

	return [assetGroups, loading] as const
}

type AssetGroupMultiSelectProps = {
	accountId: string,
	value: string[],
	onChange: (value: string[]) => void,
	required?: boolean
	options: any
	placeholder?: string
}

export function AssetGroupMultiSelect(props: AssetGroupMultiSelectProps) {
	const [allAssetGroups] = useAssetGroups(props.accountId);

	if (!allAssetGroups) {
		return null;
	}

	return (
		<MultiSelect value={props.value}
		             data={allAssetGroups}
		             onChange={props.onChange}
					 placeholder={props.placeholder}
		             options={{...options,...props.options}}/>
	);
}
