import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

import Utils from 'tools/utils';
import {GenericMetricsWidgetConfiguration} from "./genericMetricsWidgetConfiguration/widgetConfiguration";
import Settings from 'settings';
import Cookies from 'core/cookies'
import Api from 'tools/api';

export function GenericMetricsWidgetConfigurationWrapper(config) {
	Utils.apply(this, config);

	this._initComponent();
	return this;
};

export {GenericMetricsWidgetConfigurationWrapper as default};

jQuery.extend(GenericMetricsWidgetConfigurationWrapper.prototype, {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the form is rendered
	 */
	renderTo: null,
	/*
	 * Init the component
	 * */
	_initComponent: async function () {
		if (!this.widgetObj.widgetObjComplete) {
			await this.completeWidgetObj();
		}
		let container = this.widgetConfigurationFormEl
		container.empty().html('<div id="cw_metrics_form"></div>');
		this.renderTo = $('#cw_metrics_form');
		this._render();
	},

	async completeWidgetObj () {
		this.widgetObj.id = this.widgetObj.id || Utils.guid();
		this.hideThreshold = this.widgetObj.type === 'multi_graph_asset';
	},

	/*
	 * Renders the form
	 * */
	_render: function () {
		this.reactRoot = createRoot(this.renderTo.get(0))

		this.reactRoot.render(<GenericMetricsWidgetConfiguration
			config={this.widgetObj}
			isExisting={!!this.widgetObj.widgetContentDiv}
			hideThreshold={this.hideThreshold}
			onChange={(values) => {
				this.values = values;
			}}/>);
	},

	getValues: function () {
		return this.values;
	},

	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		var accountCombo = $('#cw_account').data('kendoComboBox');

		const {type, configuration: {accountId, metrics, assetGroups, metricCategories, period, startDate, endDate}} = this.values;

		if (!Utils.isGuid(accountId)) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}

		if (type === 'multi_graph_assetgroup' || type == 'metric-multi-graph-asset-group') {
			if (!assetGroups || !assetGroups.length) {
				valid = false;
				this.validationMessage = lang.widget.SELECT_ASSET_GROUPS;
			}

			if (!metricCategories || !metricCategories.length) {
				valid = false;
				this.validationMessage = lang.widget.SELECT_METRIC_CATEGORIES;
			}
		} else {
			if (!metrics || !metrics.length) {
				valid = false;
				this.validationMessage = lang.widget.SELECT_METRICS;
			} else if (period === 'CUSTOM' && (!startDate || !endDate)) {
				valid = false;
				this.validationMessage = lang.widget.SELECT_CUSTOM_PERIOD_DATE;
			}
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},

	destroy: function(){
		requestIdleCallback(() => {
			this.reactRoot?.unmount()
		})
	}
});
