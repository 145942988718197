import {GridDataEntry} from "controls/grid/gridDataEntry";
import {GridPlugin} from "controls/grid/gridConfiguration";
import {MobxManager} from "framework/mobx-integration";
import {MxGraphCellTracker} from "controls/designer/mxGraphCellTracker";
import {DesignerStore} from "controls/designer/designerStore";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {GridStore} from "controls/grid/gridStore";
import {toJS} from "mobx";
import {mxStylesToBackgroundColor} from "controls/designer/features/htmlContainerShape";

export class StylesFromMxGraphCellPlugin<DataItem extends GridDataEntry> implements GridPlugin<DataItem> {
	id: string;

	mobx = new MobxManager()
	tracker: MxGraphCellTracker

	constructor(public designerStore: DesignerStore, public cell: MxCell) {
	}

	async attach(store: GridStore<DataItem>) {
		this.tracker = new MxGraphCellTracker(this.designerStore, this.cell)

		this.mobx.reaction(() => JSON.stringify(this.tracker.styles), () => {
			let fontStyle = Number.parseInt(this.tracker.styles.fontStyle)
			if(Number.isNaN(fontStyle) ){
				fontStyle = 0
			}

			store.config.customization = {
				styling: {
					fontSize: this.tracker.styles.fontSize,
					color: this.tracker.styles.fontColor,
					backgroundColor: mxStylesToBackgroundColor(this.tracker.styles),
					transparent: !this.tracker.styles.fillColor,
					fontFamily: this.tracker.styles.fontFamily,
					fontWeight: (fontStyle & 1) == 1 ? 'bold' : undefined,
					fontStyle: (fontStyle & 2) == 2 ? 'italic' : undefined,
					textDecoration: (fontStyle & 4) == 4 ? 'underline' : undefined,
				}
			}
		}, {
			fireImmediately: true
		})
	}

	destroy = () => {
		this.mobx.destroy()
		this.tracker.destroy()
	}
}
