import './columnsVisibility.less';

import React from "react"
import PropTypes from 'prop-types';

import {translator} from "core";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {Section} from "controls/react/layout/section";

const i = require('core/localization').translator({
	'Columns': {
		no: 'Kolonner'
	}
});

const b = require('b_').with('columns-visibility');

export const ColumnsVisibility = props => {
	const columnsList =
		<ul className={b('columns-container')}>
			{props.allColumns.map(column => {
				const [enabled, toggleColumn] = getColumnToggler(props.columnsLink, column);
				return <li className={b('column')} key={column.field}>
					<AntCheckbox value={enabled} onChange={toggleColumn}>
						{column.title}
					</AntCheckbox>
				</li>
			})}
		</ul>

	const columnsContainer = props.hideSection ? columnsList :
		<Section contentPadding={false} title={i('Columns')} appearance={"frame"}>
			{columnsList}
		</Section>

	return columnsContainer
}

const getColumnToggler = (columnsLink, column ) => {
	const columnIndex = columnsLink.value.findIndex( x => x == column.field || x.field == column.field); //the last condition for legacy structure

	return [columnIndex != -1, newEnabledFlag => {
		let selectedColumns = JSON.parse(JSON.stringify(columnsLink.value));
		if(selectedColumns.length > 0 && selectedColumns[0].field != null){
			selectedColumns = selectedColumns.map(x => x.field);
		}

		if(newEnabledFlag){
			selectedColumns.push(column.field);
		}else{
			selectedColumns.splice(columnIndex, 1);
		}
		columnsLink.update( selectedColumns);
	}]
}

ColumnsVisibility.propTypes = {
	columnsLink: PropTypes.any,
	allColumns: PropTypes.any
}

