import './designer.less'

import React from "react";

import {Page} from "controls/react/layout/page";
import {Toolbar, ToolbarItemPosition} from "controls/react/layout/toolbar";
import {ActionButtons} from "controls/react/form/actionButtons";
import {DashboardDesigner} from "areas/dashboards/dashboard-designer/dashboardDesigner";
import {PresentationApi} from "areas/dashboards/api";
import {DashboardsRouter} from "areas/dashboards/bundleDescription";
import {Renderer} from "tools/renderer";
import {Utils} from "tools/utils";
import {BoxView} from "controls/react/layout/boxView";
import {State} from "tools/state";
import {RemoteEventsManager} from "core/remoteEventsManager";
import {convertServiceBoardToDashboard} from "areas/dashboards/serviceBoardConverter";
import {NavigationStore} from "framework/navigationStore";

const b = require('b_').with('dashboards-designer')
const i = require('core/localization').translator({
  "A new dashboard": {
    "no": "Nytt dashboard",
    "en": "New dashboard"
  },
  "View Mode: Click edit to modify dashboard": {
    "no": "Visning: Klikk rediger for å endre dashboardet",
    "en": "View mode: Click edit to modify dashboard"
  },
  "Click here if you want to return to original home page": {
    "no": "Klikk her om du vil gå til orginal hjemside"
  },
  "Last event: {0}": {
    "no": "Sist hendelse: {0}",
    "en": "Last event: {0}"
  },
  "Resolution: {0}": {
    "no": "Oppløsning: {0}"
  },
  "Properties": {
    "no": "Egenskaper"
  },
  "Convert": {
    "no": "Konverter"
  }
});

export class Designer extends React.Component{
	resolutionRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			lastUpdate: Renderer.dateRenderer(new Date().getTime(), 'datetime'),
			title: this.getTitle()
		}
	}

	render() {
		const hasPermission = State.mainApp.session.hasRole('SERVICEBOARD_UPDATE');
		const { hideActionButtons, hideToolbar } = this.props;
		return <Page noContentPaddings={true}>
			{!this.props.standalone && !hideToolbar &&
				<Toolbar title={this.state.title}>
					{this.props.mode == 'viewer' &&
						<span className={b('last-update')} position={ToolbarItemPosition.AFTER_TITLE}>{i('Last event: {0}', this.state.lastUpdate)}</span>
					}

					{this.props.mode == 'designer' &&
						<BoxView type={"info"}
						         contentRef={this.resolutionRef}
						         rounded={true}
						         border={true}
						         position={ToolbarItemPosition.AFTER_TITLE}/>
					}

					{this.props.mode == 'viewer' && hasPermission && !hideActionButtons &&
						<BoxView type={"info"}
						         rounded={true}
						         border={true}>{i('View Mode: Click edit to modify dashboard')}</BoxView>
					}

					{this.props.mode == 'viewer' && !hideActionButtons &&
						<ActionButtons onSave={DashboardsRouter.designer(this.props.id)}
					               popOnCancel={true}
					               showSave={hasPermission}
					               mode={'edit'}/>
					}

					{this.props.mode == 'designer' &&
						<ActionButtons onSave={this.saveDashboard}
						               onCancel={this.cancelDashboard}
						               actionButtonLabel={this.dashboard?.type == 'LEGACY' ? i('Convert') : null}
						               update={this.props.id != null}/>
					}
				</Toolbar>
			}

			<DashboardDesigner
				id={this.props.id}
				mode={this.props.mode}
				onLoaded={this.onDesignerLoaded}
				dashboardLoaded={this.onDashboardLoaded}
			/>

			{!this.state.loading && this.props.standalone && (this.dashboard.showName || this.dashboard.showUpdateTime) &&
				<div className={b('status-bar',
					{mode: this.dashboard.informationOption, position: this.dashboard.position})}>
					<Toolbar appearance={"transparent"}>
						{this.dashboard.showName && <div position={ToolbarItemPosition.BEFORE_TITLE}>{this.dashboard.tag}</div>}
						{this.dashboard.showUpdateTime && <div position={ToolbarItemPosition.AT_THE_END}>{i('Last event: {0}', this.state.lastUpdate)}</div>}
					</Toolbar>
				</div>
			}
		</Page>;
	}

	componentDidMount() {
		State.loadedDashboards = {[this.props.id]: true};
		this.onEventUnsubscriber = RemoteEventsManager.onEvent(() => {
			this.setState({lastUpdate: Renderer.dateRenderer(new Date().getTime(), 'datetime')})
		});
	}

	async componentWillUnmount() {
		this.onEventUnsubscriber();

		if(this.props.mode == 'designer' && this.dashboard?.id){
			Utils.returnToPrevAccount();
		}
	}

	getTitle(){
		return this.dashboard?.tag || i('A new dashboard');
	}

	onDashboardLoaded = (dashboard) => {
		this.dashboard = dashboard;

		if(this.props.mode == 'designer' && this.dashboard?.id) {
			Utils.changeAccount(dashboard.accountId, dashboard.accountName || 'No account name');
		}
	}

	onDesignerLoaded = async (dashboardDesigner) => {
		this.dashboardDesigner = dashboardDesigner;

		if(this.dashboard.type == 'LEGACY'){
			await convertServiceBoardToDashboard(this.dashboard, dashboardDesigner);
		}

		this.props.initialized?.({
			title: this.getTitle()
		});

		if(this.resolutionRef.current){
			dashboardDesigner.designer.graph.getModel().addListener(mxEvent.CHANGE, this.updateResolutionLabel);
			this.updateResolutionLabel();
		}

		this.setState({
			loading: false,
			title: this.getTitle()
		});

		if(this.props.id == null){
			const saved = await this.dashboardDesigner.editProperties();
			if(!saved){
				NavigationStore.pop();
			}
			else{
				this.setState({title: dashboardDesigner.dashboard.tag})
			}
		}

		if(this.props.standalone && !this.dashboardDesigner.designer.dashboardSettings.allowRedirects){
			State.mainApp._navigationEnabled = false;
		}

		if(this.props.mode != 'designer'){
			onAdminEvent(this.dashboardDesigner, this.props.id, e => {
				if(this.selfChange)
					return;

				State.mainApp.reloadCurrentModule();
			});
		}
	}

	updateResolutionLabel = () => {
		const {graph} = this.dashboardDesigner.designer;
		const {width, height} = graph.view.getGraphBounds();
		if(this.resolutionRef.current) {
			this.resolutionRef.current.innerText = i('Resolution: {0}', width + 'x' + height);
		}
	}

	saveDashboard = async () => {
		this.bypassDirtyCheck = true;
		const isStillPresentation = this.dashboard.type == 'PRESENTATION';

		const dashboard = this.dashboardDesigner.getDashboardForSaving();
		if (dashboard == null)
			return;

		this.selfChange = true;
		await PresentationApi.save(dashboard);

		//if it is still a presentation then a conversion happens during a saving.
		//it takes time, but we are already redirecting a user to a viewer again and it fails.
		// So we redirect user to a grid instead
		if (isStillPresentation) {
			State.mainApp.navigate(DashboardsRouter.list())
		} else {
			NavigationStore.pop();
		}
	}

	cancelDashboard = () => {
		this.bypassDirtyCheck = true;
		NavigationStore.pop();
	}

	dirtyCheck = () => {
		return {
			isDirty: !this.bypassDirtyCheck && this.dashboardDesigner?.isDashboardDirty(),
			message: lang.dashboard.EXIT_DIRTY_CONFIRMATION
		}
	}
}

export default Designer;

export function onAdminEvent(dashboardDesigner, dashboardId, callback){
	const originalDestroy = dashboardDesigner?.designer?.destroy;
	if(originalDestroy == null)
		return;

	const unsubscriber = RemoteEventsManager.subscribeCallback([{
		eventType: 'ServiceBoard',
		actionTypes: ['UPDATE', 'DELETE'],
		serviceBoardId: dashboardId
	}], e => {
		callback(e);
	});

	dashboardDesigner.designer.destroy = function() {
		unsubscriber.unsubscribe();
		originalDestroy.apply(this, arguments)
	}
}
