export enum Permission{
	AggregatedManagementAgentRead = 'AGGR_MGMTAGENT_READ',
	AggregatedServiceRead = 'AGGR_SERVICE_READ',
	AggregatedHealthAssetRead = 'AGGR_HEALTHASSET_READ',
	AggregatedHealthMonitorRead = 'AGGR_HEALTHMONITOR_READ',
	AggregatedAdministrationAccountMenu = 'AGGR_ADMINACCOUNT_MENU',

	AgentRead = 'AGENT_READ',
	AgentUpdate = 'AGENT_UPDATE',

	ServiceModelUpdate = 'SERVICE_MODEL_UPDATE',

	EventSummaryList = 'EVENT_SUMMARY_LIST',
	SummaryList = 'SUMMARY_LIST',

	RepositoryRead = 'REPOSITORY_READ',

	ModuleList = 'MODULE_LIST',

	HomeMenu = 'HOME_MENU',
	AssetMenu = 'ASSET_MENU',
	AssetGroupMenu = 'GROUP_MENU',
	ApplicationMenu = 'APPLICATION_MENU',
	FilterMenu = 'HEALTHFILTER_MENU',
	FilterCreate = 'HEALTHFILTER_CREATE',
	MonitorMenu = 'MONITOR_MENU',
	MonitorDelete = 'MONITOR_DELETE',
	MonitorCreate = 'MONITOR_CREATE',
	MonitorUpdate = 'MONITOR_UPDATE',
	MonitorMaintenanceCreate = 'MONITOR_MAINTENANCE_CREATE',
	MonitorMaintenanceDelete = 'MONITOR_MAINTENANCE_DELETE',
	ReasonMenu = 'HEALTHREASON_MENU',
	IncidentMenu = 'INCIDENT_MENU',
	IncidentCreate = 'INCIDENT_CREATE',
	ServiceMenu = 'SERVICE_MENU',
	ServiceCreate = 'SERVICE_CREATE',
	ServiceDelete = 'SERVICE_DELETE',
	ServiceUpdate = 'SERVICE_UPDATE',
	ServiceMaintenanceCreate = 'SERVICE_MAINTENANCE_CREATE',
	ServiceMaintenanceUpdate = 'SERVICE_MAINTENANCE_UPDATE',
	SlaMenu = 'SLA_MENU',
	TemplateMenu = 'TEMPLATE_MENU',
	CostMenu = 'COST_MENU',
	CloudCreate = 'CLOUD_CREATE',
	CloudDelete = 'CLOUD_DELETE',
	DashboardMenu = 'SERVICEBOARD_MENU',
	ReportMenu = 'REPORT_MENU',
	AccountMenu = 'ACCOUNT_MENU',
	AuthenticationMenu = 'AUTHENTICATION_MENU',
	RolesMenu = 'USER_CATEGORY_MENU',
	TeamMenu = 'TEAM_MENU',
	UserMenu = 'USER_MENU',
	ServerMenu = 'SERVER_MENU',
	AgentMenu = 'AGENT_MENU',
	AutomationMenu = 'AUTOMATION_MENU',
	CalendarMenu = 'CALENDAR_MENU',
	KpiMenu = 'KPI_MENU',
	ActiveUserMenu = 'ACTIVEUSER_MENU',
	SystemMessagesMenu = 'MESSAGE_MENU',
	IntegrationMenu = 'INTEGRATION_MENU',
	AuditMenu = 'AUDIT_MENU',
	EventsMenu = 'EVENTS_MENU',
	EventsUpdate = 'EVENTS_UPDATE',
	EventsDelete = 'EVENTS_DELETE',
	RegistryMenu = 'REGISTRY_MENU',
	CeebotMenu = 'CEEBOT_MENU',

	AccountRead = 'ACCOUNT_READ',
	AccountProfile = 'ACCOUNT_PROFILE',
	AccountTraverse = 'ACCOUNT_TRAVERSE',
	AccountList = 'ACCOUNT_LIST',

	MessageList = 'MESSAGE_LIST',
	SystemErrorList = 'SYSTEM_ERROR_LIST',

	CurrencyMenu = 'CURRENCY_MENU',
	CurrencyCreate = 'CURRENCY_CREATE',
	CurrencyRead = 'CURRENCY_READ',
	CurrencyUpdate = 'CURRENCY_UPDATE',
	CurrencyDelete = 'CURRENCY_DELETE',
	CurrencyList = 'CURRENCY_LIST'
}
