import './antInputNumber.less';
import {InputNumber, InputNumberProps} from "antd";
import { observer } from "mobx-react";
import React, {ChangeEventHandler, KeyboardEventHandler} from "react";

import {useEnterEscHandlers, captureValueLink, useBounced} from "controls/react/ant/utils";
import {Link} from "core/react/links";
import {ValidationState} from "framework/mobx-integration";


export enum NumberKeysPattern {
	PositiveInteger = '^[0-9]+$',
	Integer = '^(\-)?[0-9]+$',
	PositiveDecimal = '^([\.0-9]+)(\.)?([0-9]*)$',
	Decimal = '^(\-)?([0-9]+)(\.)?([0-9]*)$',
	DecimalOrEmpty = '^((\-)?([0-9]+)(\.)?([0-9]*))$|^$',
	PositiveDecimalOrEmpty = '^(([0-9]+)(\.[0-9]+)?)$|^$'
}

export interface AntInputNumberProps<T extends string|number> extends Omit<InputNumberProps<T>, 'onChange'> {
	valueLink?: Link<T>,
	invalid?: boolean,
	errors?: Array<any>,
	onChange?: ((newValue: string | number, e: ChangeEventHandler<string | number>) => void)
		| ((newValue: number, e: ChangeEventHandler<number>) => void)
		| ((newValue: string, e: ChangeEventHandler<string>) => void),
	ref?: any,
	pattern?: string,
	onKeyUp?: KeyboardEventHandler<HTMLInputElement>,
	onPressEnter?: () => void,
	onPressEsc?: () => void,
	onInvalidInput?: () => void,
	bounced?: number
	validationState?: ValidationState
	innerRef?: React.Ref<HTMLInputElement>
}

export const AntInputNumber = observer( <T extends string|number>(props: AntInputNumberProps<T>) => {
	let {errors, invalid, pattern, validationState, onChange, onKeyDown, onInvalidInput, ...antNativeProps} = captureValueLink(props);

	const validateKeyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(!e.ctrlKey && e.key.length == 1 && !e.key.match(/^[\-\.0-9]$/)) {
			e.preventDefault();
			return;
		}
		if (onKeyDown) {
			onKeyDown(e);
		}
	}

	const validateNumber = React.useCallback((e: T) => {
		if (pattern === undefined) {
			pattern = NumberKeysPattern.DecimalOrEmpty;
		}

		if(e && !e.toString().match(pattern)) {
			onInvalidInput?.();
			return;
		}
		if (onChange) {
			onChange(e, null);
		}
	}, [onChange])

	let {value, ...wrappedProps} = useEnterEscHandlers(antNativeProps)

	const [bouncedValue, bouncedOnChange] = useBounced(props.bounced, value, validateNumber)

	return <InputNumber ref={props.innerRef}
	                    onKeyDown={validateKeyNumber}
	                    onChange={bouncedOnChange}
	                    value={bouncedValue}
	                    {...wrappedProps}/>
})
