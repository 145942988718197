import {addLinks} from "core/react/links";
import React from "react";
import {Window} from "controls/react/kendoWrappers/window";
import {ActionButtons, FormEntry} from "controls/react/form";
import {Toolbar} from "controls/react/layout/toolbar";
import {Section} from "controls/react/layout/section";
import {translator} from "../../../../../core";
import {AntInput} from "controls/react/ant/antInput";

const i = translator();

export interface NewBudgetEntryWindowProps {
	onCancel: () => void,
	onSave: Function,
	title?: string
}

interface NewBudgetEntryWindowState {
	name: string
}

@addLinks
export class NewBudgetEntryWindow extends React.PureComponent<NewBudgetEntryWindowProps, NewBudgetEntryWindowState> {
	state: NewBudgetEntryWindowState = {
		name: ''
	}

	nameRef = React.createRef<(typeof AntInput) & {focus: () => void}>()

	onWindowInit = () => {
		this.nameRef.current.focus();
	}

	onKeyPress = (e: KeyboardEvent) => {
		if (e.key === 'Enter' && this.canSave()) {
			this.onSave();
		}
	}

	render() {
		const nameLink = this.linkState('name');

		return (
			<Window title={this.props.title} modal onClose={this.props.onCancel} onInit={this.onWindowInit} width={400} autoFocus={false}>
				<Section childrenPadding={true}>
					<FormEntry label={i('Name')} valueLink={nameLink.required()}>
						<AntInput autoFocus={true} {...nameLink.props} ref={this.nameRef} onKeyPress={this.onKeyPress}/>
					</FormEntry>
				</Section>
				<Toolbar>
					<ActionButtons onSave={this.onSave} onCancel={this.props.onCancel}
						saveDisabled={!this.canSave()}
					/>
				</Toolbar>
			</Window>
		);
	}

	onSave = () => {
		const name = this.state.name.trim();
		if (name) {
			this.props.onSave(name);
		}
		this.setState({name: ''});
	}

	canSave = () => {
		return !!this.state.name
	}
}
