import ReactDOM from 'react-dom';
import React from 'react';

import Widget from 'areas/service-boards/widget';
import {translator} from 'core';
import {iterateAllCells} from "controls/designer/utils";
import {DashboardDesigner} from "areas/dashboards/dashboard-designer/dashboardDesigner";
import {State} from "tools/state";
import {onAdminEvent} from "areas/dashboards/designer";

const i = translator({
  "Presentation » {0}": {
    "no": "Presentasjon » {0}"
  },
  "The presentation is not found": {
    "no": "Fant ikke presentasjonen",
    "en": "Presentation not found"
  },
  "Already loaded": {
    "en": "Can not have nested dashboards",
    "no": "Du kan ikke ha rekursive dashboard"
  }
});

export class DashboardWidget extends Widget{
	sp = null;
	editorUi = null;
	serviceElementToCellMap = null;
	services = null;
	onZoom = false;

	init() {
		this.createDesigner();
	}

	createDesigner(){
		var widgetDiv = $('#' + this.id);
		widgetDiv.off();

		this.graphContainer = $('.cw_section_content', widgetDiv);
		if(!this.graphContainer.hasClass("presentation")) {
			this.graphContainer.addClass("presentation");
		}

		let container = this.graphContainer.get(0);

		if(!State.loadedDashboards[this.configuration.servicePresentationId])
		{
			State.loadedDashboards[this.configuration.servicePresentationId] = true;
			ReactDOM.render(<DashboardDesigner id={this.configuration.servicePresentationId}
			                                   mode={"viewer"}
			                                   navigator={this.navigator}
			                                   config={{fit: this.configuration.zoom !== false, padding: this.configuration.contentPadding}}
			                                   onLoaded={this.onDesignerLoaded}
			                                   ref={ref => this.dashboardDesigner = ref}
				/>,
				container);
		}else{
			ReactDOM.render(<div>{i('Already loaded')}</div>,
				container);
		}
	}

	destroyDesigner(){
		if(this.graphContainer) {
			delete State.loadedDashboards[this.configuration.servicePresentationId];
			ReactDOM.unmountComponentAtNode(this.graphContainer.get(0))
		}
	}

	onDesignerLoaded = (dashboardDesigner, dashboard) => {
		this.editorUi = dashboardDesigner.designer.editorUi;

		this.editorUi.editor.graph.addListener(mxEvent.CELLS_FOLDED, this.onCellFolded);

		onAdminEvent(this.dashboardDesigner, this.configuration.servicePresentationId, e => {
			this.destroyDesigner();
			this.createDesigner();
		});
	}

	onCellFolded = (graph, e) => {
		//that code handles the case when user have a service board with absolute layout.
		//He creates a dashboard with just one expandable shape and when puts the dashboard as a widget an the service board
		//Then he opens the service board and expand\collapse the shap - widget increases in size according to the new graph size
		let foldableCellsCount = 0;
		let foldableCell = null;
		iterateAllCells(graph, cell => {
			if (graph.isCellFoldable(cell) && cell.parent == graph.getDefaultParent()) {
				foldableCellsCount++;
				foldableCell = cell;
			}
		});

		const window = $('#' + this.id).parent().data('kendoWindow');

		if (foldableCellsCount != 1 || !window)
			return;

		let newSize = {
			width: foldableCell.getGeometry().width,
			height: foldableCell.getGeometry().height
		};

		if (e.properties.collapse && this.initialSize) {
			newSize = this.initialSize;
		}

		//we are storing collapsed size here so we can collapse later to the same size
		if (!e.properties.collapse && !this.initialSize) {
			this.initialSize = {
				height: window.element.height(),
				width: window.element.width()
			};
		}

		if (this.serviceBoard.updateWindowOptions) {
			this.serviceBoard.updateWindowOptions($('#' + this.id).parent(), newSize);
		}
		window.toFront();

		setTimeout(this.onResize, 0);
	}

	onPresentationNotLoaded(data) {
		this.showErrorMessage(i('The presentation is not found'));
	}

	onResize = () => {
		this.dashboardDesigner?.designer.fit();
	}

	destroy(dontUnsubscribe) {
		this.destroyDesigner();

		if (!dontUnsubscribe) {
			Widget.prototype.destroy.call(this);
		}
	}
}
