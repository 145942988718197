import {translator} from "../../core";

const i = translator ({

});

interface Monitor {
	assetMaintenance: boolean,
	agentStatus: string,
	agentState: string,
	qualifierError: boolean,
	monitorError: boolean,
	monitorDataUnavailable: boolean,
	monitorMaintenance: boolean,
	operatingState: string
}

interface Options {
	backgroundColor?: string | undefined
}

enum StatusIcons {
	Warning = 'exclamation-mark',
	Maintenance = 'wrench',
	Error = 'times'
}

export class StateIconParser {
	static parse(monitors: Monitor[], options?: Options | undefined) {
		return new StateIconParser(monitors, options);
	}

	private monitors: Monitor[];
	private _icon: StatusIcons | undefined;
	private monitor: Monitor;
	private toolTips: string[] = [];
	private _options: Options;

	public get iconEnabled() {
		return !!this._icon;
	}

	public get value() {
		return {
			label: '',
			tooltip: this.toolTips.join(' / ')
		};
	}

	public get icon() {
		return this._icon;
	}

	public get options() {
		return this._options;
	}

	public get createIconData() {
		return [this.value, this.icon, this.options];
	}

	constructor(monitors: Monitor[], options?: Options | undefined) {
		this.monitors = monitors;
		this.fillIconAndMonitor();
		this.fillToolTips();
		this.fillOptions(options);
	}

	private fillIconAndMonitor = () => {
		this.monitor = this.monitors.find(this.assetMaintenance);
		if (this.monitor) {
			this._icon = StatusIcons.Maintenance;
			return;
		}

		this.monitor = this.monitors.find(this.agentDown);
		if (this.monitor) {
			this._icon = StatusIcons.Error;
			return;
		}

		this.monitor = this.monitors.find(this.agentWarning);
		if (this.monitor) {
			this._icon = StatusIcons.Warning;
			return;
		}

		this.monitor = this.monitors.find(this.sqError);
		if (this.monitor) {
			this._icon = StatusIcons.Warning;
			return;
		}

		this.monitor = this.monitors.find(this.monitorError);
		if (this.monitor) {
			this._icon = StatusIcons.Warning;
			return;
		}

		this.monitor = this.monitors.find(this.monitorMaintenance);
		if (this.monitor) {
			this._icon = StatusIcons.Maintenance;
			return;
		}
	}

	private fillToolTips = () => {
		if (this.monitors.some(this.assetMaintenance)) {
			this.toolTips.push(i('Asset in maintenance'));
		}
		if (this.monitors.some(this.agentDown)) {
			this.toolTips.push(i('Agent is down'));
		}
		if (this.monitors.some(this.agentWarning)) {
			this.toolTips.push(i('Agent in warning'));
		}
		if (this.monitors.some(this.sqError)) {
			this.toolTips.push(i('Qualifier error'));
		}
		if (this.monitors.some(this.monitorError)) {
			this.toolTips.push(i('Monitor error'));
		}
		if (this.monitors.some(this.monitorMaintenance)) {
			this.toolTips.push(i('Monitor in maintenance'));
		}
	}

	private fillOptions = (options?: Options | undefined) => {
		if (![StatusIcons.Error, StatusIcons.Warning, StatusIcons.Maintenance].includes(this._icon)) {
			return;
		}
		this._options = options ?? {};
		this._options.backgroundColor = '#C4C4C5';
	}

	private assetMaintenance = (monitor: Monitor) => {
		return (monitor.assetMaintenance === true
			|| monitor.agentState == 'MAINTENANCE'
			|| monitor.agentStatus == 'AGENT_MAINTENANCE') && !monitor.qualifierError;
	}

	private agentDown = (monitor: Monitor) => {
		return monitor.agentStatus == 'AGENT_DOWN'
			|| monitor.agentState == 'ERROR';
	}

	private monitorError = (monitor: Monitor) => {
		return monitor.monitorError == true
			|| monitor.monitorDataUnavailable == true;
	}

	private monitorMaintenance = (monitor: Monitor) => {
		return (monitor.monitorMaintenance === true
			|| monitor.operatingState === 'IN_MAINTENANCE') && !monitor.qualifierError;
	}

	private agentWarning = (monitor: Monitor) => {
		return monitor.agentState == 'WARNING';
	}

	private sqError = (monitor: Monitor) => {
		return monitor.qualifierError == true;
	}
}

