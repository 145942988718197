import {ApiRequest, PagedList} from "framework/api";
import {GridDataItem} from "../../../controls/grid/gridDataItem";
import {RulesConfiguration} from "../../../controls/queryBuilder/ruleDefinition";

export class ServiceSummaryGridItem {
	id: string
	name: string
	accountId: string
	accountName: string
	serviceId: string
	elementId: string
	serviceName: string
	elementName: string
	serviceState: string
	order: number

	get qualifierName() {
		return this.name.replace(this.accountName, '')
			.replace('/' + this.serviceName, '')
			.replace('/' + this.elementName, '')
			.substring(0, 1)
	}
}

export class ServiceSummaryWidgetSettings {
	accountIds?: string[];
	serviceIds?: string[];
	elementIds?: string[];
	qualifierIds?: string[];

	dynamicServices: boolean = false;
	dynamicElements: boolean = false;
	dynamicQualifiers: boolean = false;

	serviceStates?: string[];
	serviceElementStates?: string[];
	serviceQualifierStates?: string[];
}

export type DataListItem = { id: string, name: string, serviceName: string, elementName: string, qualifierName: string, accountName: string};

export function getServicesByAccountIds(requestData: {accounts: string[], type: string}) {
	const {accounts, type} = requestData
	return new ApiRequest<{items: Array<DataListItem>}>({
		url: `services/summaryItems/${type}/lite`,
		accountBased: true,
		payload: {
			accountIds: accounts
		}
	});
}

export function getServiceSummaryGridItems(data: ServiceSummaryWidgetSettings & {order: {[id: string]: number}}) {
	return new ApiRequest<PagedList<GridDataItem<ServiceSummaryGridItem>>>({
		url: 'services/summaryItems',
		accountBased: true,
		payload: data
	});
}

export function getServicesSummaryWidgetFilters(accountIds?: string[]){
	return new ApiRequest<RulesConfiguration>({
		url: 'services/summaryItems/ruleConfiguration',
		accountBased: true,
		subaccountsFilter: true,
		method: 'POST',
		payload: {
			accountIds: accountIds
		}
	});
}
