import React from "react";

import {AntSelect} from "controls/react/ant/antSelect";
import {sharedLocalization} from './shared';

const i = require('core/localization').translator({
  "Select legend information...": {
    "no": "Velg navninformasjon.."
  }
}, sharedLocalization);

export const LegendItemsSelect = props => {
	const {...others} = props;

	const items = [{
		text: i('Account'),
		value: 'account'
	}, {
		text: i('Cloud Provider'),
		value: 'cloudProvider'
	}, {
		text: i('Monitor'),
		value: 'monitor'
	}, {
		text: i('Subscription'),
		value: 'subscription'
	}, {
		text: i('Resource'),
		value: 'resourceName'
	}];

	return (
		<AntSelect placeholder={i('Select legend information...')}
				   mode={"multiple"}
				   dataList={items}
				   nameField={"text"}
				   valueField={"value"}
				   {...others}
		/>
	);
}
