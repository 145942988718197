import React, { useEffect } from 'react';
import { CollapsibleFrame } from "controls/react/layout/collapsibleFrame";
import { FormEntry, MultiSelect } from "controls/react/form";
import { AntInputNumber } from "controls/react/ant/antInputNumber";
import { AntCheckbox } from "controls/react/ant/antCheckbox";
import { translator } from "core";
import { AntInput } from "controls/react/ant/antInput";
import { AccountDropDown } from "controls/react/dropdowns/accountDropDown";
import { MetricSelector } from '../customMetrics/metricSelector';
import { MetricInlineSelectorState } from '../common/metricInlineSelector';
import {ApplicationState} from "../../../../framework/applicationState";

const b = require('b_').with('custom-metric-widget-configuration');

const i = translator({
  "Title is required": {
    "no": "Tittel kreves",
    "en": "Title required"
  },
  "Min Value": {
    "no": "Min verdi",
    "en": "Min value"
  },
  "Invert": {
    "no": "Invert"
  },
  "Breach Value": {
    "no": "Bruddverdi",
    "en": "Breach value"
  },
  "Hide Labels": {
    "no": "Skjul tekst",
    "en": "Hide labels"
  },
  "Hide Numbers": {
    "no": "Skjul nummere",
    "en": "Hide numbers"
  }
});
const legendDataSource = ['<Asset>', '<Name>', '<Type>', '<Category>', '<Instance>'];

export function BarchartMetricConfiguration(props) {
	const { configLink } = props;

	useEffect(() => {
		if (!configLink.get('accountId').value) {
			configLink.get('accountId').update(ApplicationState.accountId);
		}
	}, []);

	const minValueLink = configLink.get('minValue').check('Min should be less than Max', x => {
		if (isEmpty(configLink.get('maxValue').value))
			return true;
		return !isEmpty(x) && x < configLink.get('maxValue').value
	}).required();

	const maxValueLink = configLink.get('maxValue').check('Max should be greater than Min', x => {
		if (isEmpty(minValueLink.value))
			return true;
		return !isEmpty(x) && x > minValueLink.value
	}).required();

	const breachValueLink = configLink.get('breachValue').check('Breach should be between Min And Max', x => {
		if (!isEmpty(minValueLink.value) && !isEmpty(x) && x < minValueLink.value)
			return false;
		if (!isEmpty(maxValueLink.value) && !isEmpty(x) && x > maxValueLink.value)
			return false;
		return true;
	}).required();

	useEffect(() => {
		minValueLink.validate(minValueLink.value);
		maxValueLink.validate(maxValueLink.value);
		breachValueLink.validate(breachValueLink.value);
	}, [minValueLink.value, maxValueLink.value, breachValueLink.value])

	function isEmpty(value) {
		return (value == undefined || value == null);
	}

	return <div className={b()}>
		<CollapsibleFrame title={"Configuration"} childrenPadding={true}>
			<FormEntry label={i('Title')} width={"fit"}>
				<AntInput {...configLink.get('title').props} />
			</FormEntry>
		</CollapsibleFrame>
		<CollapsibleFrame expanded={props.expanded} title={"Display settings"} childrenPadding={true}>
			<FormEntry label={i('Min Value')} width={"fit"} valueLink={minValueLink}>
				<AntInputNumber valueLink={minValueLink} />
			</FormEntry>
			<FormEntry label={i('Max value')} width={"fit"} valueLink={maxValueLink}>
				<AntInputNumber valueLink={maxValueLink} />
			</FormEntry>
			<FormEntry label={i('Breach Value')} width={"fit"} valueLink={breachValueLink}>
				<AntInputNumber valueLink={breachValueLink} />
			</FormEntry>
			<FormEntry label={i('Padding')} width={"fit"}>
				<AntInputNumber valueLink={configLink.get('contentPadding')} />
			</FormEntry>
			<FormEntry width={"fit"}>
				<AntCheckbox valueLink={configLink.get('invert')}>{i('Invert')}</AntCheckbox>
				<AntCheckbox valueLink={configLink.get('hideLabels')}>{i('Hide Labels')}</AntCheckbox>
				<AntCheckbox valueLink={configLink.get('hideNumbers')}>{i('Hide Numbers')}</AntCheckbox>
			</FormEntry>
			<FormEntry label={i('legend')} vertical={true}>
				<MultiSelect
					data={legendDataSource}
					containerClass={'w100'}
					{...configLink.get('legendValue').props} />
			</FormEntry>
		</CollapsibleFrame>
		<MetricInlineSelectorState
			onAdd={(items) => {configLink.get('selectedMetrics').update(items)}}
			type={configLink.get('type').value}
			accountId={configLink.get('accountId').value}
			selectedMetrics={configLink.get('selectedMetrics')?.value} />
	</div>

	function isEmpty(value) {
		return (value == undefined || value == null);
	}
}

