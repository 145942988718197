import UrlRouter from 'url-router';
import UrlBuilder from "tools/urlBuilder";
import {logRouteIdException} from "../tools/log";

const bundles = require.context("../", true, /bundleDescription$/);

export default class RoutesTable {
	routes = {}
	constructor() {
		this._urlRouter = new UrlRouter();

		for (let fileName of bundles.keys()) {
			let bundle = bundles(fileName);

			try {
				for (let description of bundle.getDescriptions()) {
					let urls = Array.isArray(description.url) ? description.url : [description.url];
					if(this.routes[description.routeId]) {
						logRouteIdException('routeId duplicate, routeId: ', description.routeId, ' url: ', urls[0])
					}
					this.routes[description.routeId] = urls[0]
					if(!description.routeId) {
						logRouteIdException('no route id for:', urls[0])
					}
					urls.forEach( url => this._urlRouter.add(url, () => description));
				}
			} catch (e) {
				console.error(e);
			}
		}
	}

	route(url, callback) {
		this._urlRouter.add(url, callback);
	}

	find(url) {
		const urlBuilder = new UrlBuilder(url);

		const route = this._urlRouter.find(urlBuilder.path);
		if (route == null) {
			return null;
		}

		if(route.params.id == 'new'){
			route.params.id = null;
		}

		const description = route.handler();

		return {
			params: {...route.params, ...urlBuilder.params, ...description?.params},
			description: description
		}
	}
}
