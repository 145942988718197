import {DataSourceElement} from 'controls/designer/dataSourcesManager/dataSourceElement';
import LocalEvents from 'core/localEventsManager';
import State from 'tools/state';
import {translator} from "core/localization/localization";
import {iterateEdges, updateIfChanged, mergeConsumeEventResult} from "controls/designer/utils";
import {PresentationMode} from "areas/services/designer/graph-editor-extensions/presentationMode";
import {ServicesRouter} from "areas/services/bundleDescription";
import {hasHiddenChild} from "controls/designer/features/presentationMode";

const i = translator({
  "Services: {0}": {
    "no": "Tjenester: {0}"
  },
  "Service qualifiers: {0}": {
    "no": "Tjenestepunkt: {0}",
    "en": "Servicequalifiers: {0}"
  },
  "Service elements: {0}": {
    "no": "Tjenesteelement: {0}"
  },
  "Service does not exist": {
    "no": "Tjenesten finnes ikke"
  }
});


export class ServiceDataSourceElement extends DataSourceElement{
	serviceElementIds = [];
	serviceElements = [];

	constructor(designer, cell) {
		super(designer, cell);

		let serviceIdValue = this.datasource.services;
		let serviceElementIdValue = this.datasource.serviceElements;
		let serviceQualifiers = this.datasource.serviceQualifiers;

		//multisource
		if(Array.isArray(serviceIdValue)){
			if(serviceQualifiers?.length > 0){
				this.serviceElementIds = serviceQualifiers.map(x => ({
					serviceQualifierId: x.id,
					serviceElementId: x.elementId,
					serviceId: x.serviceId,
					accountId: x.accountId
				}));
			}
			else if(serviceElementIdValue?.length > 0) { //if service elements selected then only they are used
				this.serviceElementIds = serviceElementIdValue.map(x => ({
					serviceElementId: x.id,
					serviceId: x.serviceId,
					accountId: x.accountId
				}));
			}else {
				this.serviceElementIds = serviceIdValue.map(x => ({
					serviceId: x.id,
					accountId: x.accountId,
				}));
			}
		}

		this.graph.addListener('CELL_CLICKED', this.onGraphClicked);
		this.graph.addListener(mxEvent.REFRESH, this.onGraphRefreshed);
	}

	getEntriesToLoad(){
		return this.serviceElementIds.map(x => ({...x}));
	}

	onHealthInfoLoaded(entries) {
		this.serviceElements = entries;
	}

	async updateState() {
		if (this.serviceElements.length < this.serviceElementIds.length)
			return;

		if (!this.graph.isCellVisible(this.cell))
			return;

		let options = {};
		if (this.addLinkIcon()) {
			options = {shiftIcon: true};
		}

		this.checkForWarnings(this.serviceElements, options)

		this.setState(this.getState());

		await this.addExpandCollapseIcon();
	}

	getState(){
		const minPriority = this.serviceElements
			.reduce((currentMin, x) => Math.min(STATES_TO_PRIORITY[x.altState || x.state], currentMin), STATES_TO_PRIORITY.INVALID);

		return PRIORITY_TO_STATES[minPriority];
	}

	addLinkIcon() {
		if (!this.cell.isServiceLink() || this.linkIcon || this.designer.config.chromeless != true)
			return !!this.linkIcon;

		let value = {
			label: ""
		}

		if (this.designer.config.navigateOnServiceLink) {
			if (this.cell.customData.linkServiceId) {
				value.tooltip = kendo.template(lang.SERVICE_LINK_MESSAGE)({
					serviceName: this.cell.customData.linkServiceName
				});
			} else {
				value.tooltip = i('Service does not exist');
			}
		}

		this.linkIcon = this.createStatusIcon(value, 'link');
		return true;
	}

	getSubscriptions() {
		if (this.cell.isServiceLink()) {
			return {
				links: this.serviceElements.map(x => x.linkServiceId)
			}
		} else {
			return {
				services: this.serviceElementIds.map(x => x.serviceId),
			}
		}
	}

	consumeEvent(event) {
		return mergeConsumeEventResult([this.processServiceSummaryEvent(event),
			this.processServiceStatusEvent(event)]);
	}

	processServiceStatusEvent(event) {
		if(event.eventType != 'ServiceStatus')
			return false;

		let stateChanged = false;

		const services = this.serviceElements.filter(x => x.serviceId == event.serviceId && x.elementId == null && x.qualifierId == null);
		for (const service of services) {
			if (this.copyWarningStatesFromEvent(service, event)) {
				stateChanged = true;
			}
		}

		for (const entry of [...(event.elements || []), ...(event.qualifiers || [])]) {
			const existingElement = this.serviceElements
				.find(x => x.elementId == entry.sourceId || x.qualifierId == entry.sourceId);

			if (!existingElement)
				continue;

			if (existingElement.state != entry.status) {
				if (this.cell.customData != null) {//kinda hack. This will be true only if we are in service designer
					//which means that there is only one element in this.serviceElements and this line will be executed only once
					this.cell.customData.state = entry.status;
				}
				stateChanged = true;
			}

			if (this.copyWarningStatesFromEvent(existingElement, entry)) {
				stateChanged = true;
			}
		}
		return stateChanged;
	}

	processServiceSummaryEvent(event){
		if(event.eventType != 'ServiceSummary')
			return {
				reload: false
			}

		if(this.cell.isServiceLink() && this.cell.customData.linkServiceId == event.id) {
			if(this.copyWarningStatesFromEvent(this.serviceElements[0], event)) {
				return {
					rodraw: true
				}
			}
		}

		let services = this.serviceElements.filter(x => x.serviceId == event.id);

		for (const service of services) {
			if(service.operatingState != event.operatingState){
				return {
					reload: true
				}
			}
		}
	}

	copyWarningStatesFromEvent(element, eventElement){
		let changed = false;

		if(eventElement.status && eventElement.status != element.state
			|| eventElement.serviceState && eventElement.serviceState != element.state) {
			element.state = eventElement.status ? eventElement.status : eventElement.serviceState;
			changed = true;
		}

		changed = updateIfChanged(eventElement, element,['agentStatus', 'qualifierError', 'assetMaintenance', 'operatingState']) || changed;
		return changed;
	}

	getTooltipInternal(accountName) {
		if (this.serviceElements?.length == 0) {
			return null;
		}

		if (this.serviceElements[0].qualifierName) {
			return i('Servicequalifiers: {0}', this.serviceElements.map(x => x.serviceName + '\\' + x.qualifierName).join(', '));
		} else if (this.serviceElements[0].elementName) {
			return i('Service elements: {0}', this.serviceElements.map(x => x.serviceName + '\\' + x.elementName).join(', '));
		} else {
			return i('Services: {0}', this.serviceElements.map(x => x.serviceName).join(', '));
		}
	}

	setState(state) {
		//this trigger was added to changed state of element in viewer.
		//Not really the best solution but another one was to copy all logic from this file to viewer
		//still required a rework of events
		LocalEvents.trigger('state-changed', {
			type: 'service-element',
			serviceId: this.serviceElementIds[0].serviceId,
			serviceElementId: this.serviceElementIds[0].serviceElementId,
			state: state,
			stateColorIndex: Utils.getStateIndex(state)
		});

		DataSourceElement.prototype.setState.call(this, state)
	}

	redirect() {
		if(this.serviceElements.length == 0)
			return

		let entry = this.serviceElements[0]
		if (entry.elementId || entry.qualifierId) {
			this.navigator.go({url: ServicesRouter.viewer(entry.serviceId, {
				serviceElementId: entry.elementId,
				serviceQualifierId: entry.qualifierId
			})})
		}else {
			this.navigator.go({url: ServicesRouter.details(entry.serviceId)});
		}
	}

	getLabel(){
		let name = null;

		if((this.datasource.showAsLabel)
			&& this.serviceElements.length){
			name = this.serviceElements[0].serviceName;

			if(this.serviceElements[0].elementName) {
				name += ' / ' + this.serviceElements[0].elementName;
			}

			if(this.serviceElements[0].qualifierName){
				name += ' / ' + this.serviceElements[0].qualifierName;
			}
		}

		return name;
	}

	async addExpandCollapseIcon() {
		if(this.designer.config.features.presentationMode != true) {
			return;
		}

		let showIcons = this.designer.serviceModelImporting?.presentationMode[this.serviceElements[0].serviceId];
		if(showIcons == null){
			showIcons = this.designer.graph.presentationModeSettings.showIcons;
		}

		if(!showIcons)
			return;

		let hasChildren = iterateEdges(this.cell, x =>{
			if(x.source == this.cell){
				return true;
			}
		});

		if (!hasChildren || !this.graph.designer.config.chromeless)
			return;

		super.addExpandCollapseIcon(!hasHiddenChild(this.graph, this.cell) );
	}

	onGraphClicked = (graph, e) => {
		if(this.expandCollapseIcon != null && e.properties.cell == this.expandCollapseIcon){
			let styles = this.graph.getCellStyle(this.expandCollapseIcon);
			if(styles.image.indexOf('square-empty-minus') !== -1){
				graph.presentationModeOverride[this.cell.id] = PresentationMode.ShowNothing;
				graph.setCellStyles(mxConstants.STYLE_IMAGE, 'square-empty-plus pointer', [this.expandCollapseIcon]);

			}else{
				graph.presentationModeOverride[this.cell.id] = PresentationMode.ShowAllChildren;
				graph.setCellStyles(mxConstants.STYLE_IMAGE, 'square-empty-minus pointer', [this.expandCollapseIcon]);
			}

			graph.refresh();

			this.designer.fit();
		}

		if(this.linkIcon != null && e.properties.cell == this.linkIcon && this.designer.config.navigateOnServiceLink && this.cell.customData.linkServiceId){
			State.mainApp.navigate('/services/' + this.cell.customData.linkServiceId + '/viewer');
			//quickfix for tooltip still shown in viewer
			setTimeout(() => {
				$('.mxTooltip').remove();
			}, 1000);
		}
	}

	onGraphRefreshed = (graph) => {
		if(this.empty())
			return;

		this.linkIcon = null;
		this.cleanUp();
		this.updateState();
	}

	cleanUp() {
		this.linkIcon = null;
		super.cleanUp();
	}


	getGeoTag() {
		const serviceElementWithGeoTag = this.serviceElements.find(x => x.geoTagAssetId != null)
		if (!serviceElementWithGeoTag)
			return null

		return {
			assetId: serviceElementWithGeoTag.geoTagAssetId,
			accountId: this.serviceElementIds[0].accountId,
		}
	}

	destroy(){
		this.graph.removeListener(this.onGraphClicked);
		this.graph.removeListener(this.onGraphRefreshed);
		super.destroy();
	}

	empty(){
		return this.serviceElementIds.length == 0;
	}
}

const STATES_TO_PRIORITY = {
	INVALID: 3,
	ACTIVE: 2,
	WARNING: 1,
	INACTIVE: 0,
};

const PRIORITY_TO_STATES = [
	'INACTIVE',
	'WARNING',
	'ACTIVE',
	'INVALID'
];

