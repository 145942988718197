import {BudgetSplit} from "areas/cost/budget/budgetTypes";
import {GridDataEntry} from "controls/grid/gridDataEntry";
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {ApiRequest, PagedList} from "framework/api";
import {UrlBuilder} from "tools";
import {GridDataItem} from "controls/grid/gridDataItem";

export type GetCostResources = {
	accountId: string,
	budgetId: string,
	budgetItems: string[],
	modelId: string,
	timeDisplayValue: string,
	currency: string,
	timeDisplay: string,
	costSelectionType: string
}

export class CostGridItem implements GridDataEntry {
	budget: number;
	cost: number;
	currentEstimate: number;
	hasChildren: boolean;
	id: string;
	listingPrice: number;
	months: CostGridMonthItem[];
	name: string;
	periodEstimate: number;
	split: BudgetSplit;
}

export type CostGridMonthItem = {
	budget: number,
	cost: number,
	listingPrice: number,
	month: number,
	preliminary: boolean,
	year: number,
}

export function getCostResources(args: GetCostResources) {
	const request = {
		costSelectionType: args.costSelectionType,
		accountId: args.accountId,
		profileId: args.budgetId,
		modelId: args.modelId,
		budgetItemIds: args.budgetItems,
		currency: args.currency,
		year: args.timeDisplay === 'annual' ? args.timeDisplayValue : undefined,
		historicMonths: args.timeDisplay !== 'annual' ? args.timeDisplayValue : undefined,
	}
	return new ApiRequest<PagedList<GridDataItem<CostGridItem>>>({
		url: `cost/resourceCost/search`,
		accountId: args?.accountId,
		accountBased: true,
		method: 'POST',
		payload: request
	});
}

type GetCostResourcesFiltersArgs = {
	year: string,
	historicMonths: number
}

export function getCostResourcesFilters(args: GetCostResourcesFiltersArgs) {
	const url = new UrlBuilder('cost/resourceCost/ruleConfiguration')
			.add('year', args.year, !!args.year)
			.add('historicMonths', args.historicMonths, !!args.historicMonths)
			.build();

	return new ApiRequest<RulesConfiguration>({
		url,
		accountBased: true,
		method: 'GET'
	});
}
