import {ApiRequest, PagedList} from "framework/api";
import {Api} from "tools/api";
import {CostItemInfo} from "areas/cost/budget/budgetTypes";
import {CostBudget, CostBudgetData} from "./models/costBudget";
import {CostProfile} from './models/costProfile'
import {UrlBuilder} from "../../tools";
import {CostMetricData} from "./models/costMetric";
import {CostAlarm} from "./models/costAlarm";
import { CostModel } from "./models/costModel";
import {RulesConfiguration} from "../../controls/queryBuilder/ruleDefinition";
import {CostFilter} from "./models/costFilter";
import {ApplicationState} from "framework/applicationState";
import {
	CostGatewayCloudType,
	CostGatewayLevelType,
	CostGatewaySubscription
} from "./gateway/models/costGatewayCloudType";
import {CostGatewayConnection} from "./gateway/models/costGatewayConnection";
import {CostGatewaySheetDataType} from "./gateway/models/costGatewaySheet";
import {GridStore} from "../../controls/grid/gridStore";
import {Authentication} from "../administration/models/authentication";
import {GridDataItem} from "controls/grid/gridDataItem";
import {CostBudgetItem, CostBudgetLink, FetchType} from "./models/costBudgetItem";
import { ShowColumns } from "./budget/components/BudgetTableStore";

export type SelectionCostData = {
	accountId: string;
	profileId: string;
	modelId: string;
	items: {id: string, name: string}[];
}

export type CostBudgetForCreate = Partial<Omit<CostBudget, 'startDate'>> & {startDate: string, costModelIds?: string[]}

//todo: rewrite this to ApiRequests
export class CostsApi {
	static listUrl(accountId: string = null) {
		return Api.accountRoot(accountId) + 'cost/profiles/';
	}

	static list(accountId: string) {
		return Api.fetch(CostsApi.listUrl(accountId));
	}

	static delete(costIds: string[]) {
		return Api.fetchPost(CostsApi.listUrl() + 'delete', costIds);
	}

	static save(costProfile: CostProfile) {
		return Api.fetchPost(CostsApi.listUrl(), costProfile);
	}

	static async saveLink(budget: CostBudgetForCreate, creationType: 'NEW'|'COST_COLLECTOR'|'DUPLICATE') {
		const url = CostsApi.listUrl() + creationType;
		return Api.fetchPost(url, budget);
	}

	static details(costId: string) {
		return Api.fetch(CostsApi.listUrl() + costId )
	}

	static costModelList(accountId: string, modelId: string = null) {
		let url = Api.accountRoot(accountId) + 'cost/models'
		if (modelId) {
			url += `?linkModelId=${modelId}`
		}
		return Api.fetch(url);
	}

	static subAccountList(accountId: string) {
		return Api.fetch(Api.accountRoot(accountId) + 'subaccounts/allLevels/lite');
	}

	static subAccountProfilesList(accountId: string) {
		return Api.fetch(Api.accountRoot(accountId) + 'cost/profiles/lite');
	}

	static subAccountProfileModelsList(accountId: string, profileId: string, currentModelId: string = null) {
		let url = Api.accountRoot(accountId) + `cost/profiles/${profileId}/models`;
		if (!!currentModelId) {
			url += `?linkModelId=${currentModelId}`;
		}
		return Api.fetch(url);
	}

	static costModelProfilesList(modelId: string, accountId?: string) {
		const url = Api.accountRoot(accountId) + `cost/models/${modelId}/profiles`;
		return Api.fetch(url);
	}

	static costModelDetails(modelId: string) {
		return Api.fetch(Api.accountRoot()+`cost/models/${modelId}`);
	}

	static costModelStructure(modelId: string) {
		return Api.fetch(Api.accountRoot()+`cost/models/${modelId}/treelist`);
	}

	static cloudConnections(accountId: string) {
		return Api.fetch(`${Api.server}accounts/${accountId}/cost/connections`);
	}
}

export function getCostProfile(
	profileId: string,
	params: { modelId?: string, accountId?: string, currency?: string, filter?: CostFilter, fetch?: FetchType, columns?: ShowColumns, expandedRowIds?: string[]},
	deserializationCallback?: (data: CostProfile) => CostProfile
) {
	const { modelId, accountId, currency, filter, fetch, columns, expandedRowIds } = params;

	const url = new UrlBuilder(`cost/profiles/${profileId}`)
		.add('modelId', modelId)
		.build();

	const payload = {
		currency,
		filterType: filter?.type,
		filterOperator: filter?.operator,
		filterValue: filter?.value,
		fetchType: fetch,
		columns,
		expandedRowIds
	}

	return new ApiRequest<CostProfile>({
		url,
		accountId,
		accountBased: true,
		responseType: CostProfile,
		method: 'POST',
		payload,
		deserializationCallback
	});
}

export function getCostBudget(
	profileId: string,
	params: {modelId: string, budgetId: string, accountId?: string, filter?: CostFilter, fetch?: FetchType, columns?: ShowColumns, expandedRowIds?: string[]},
	deserializationCallback?: (data: CostBudgetData) => CostBudgetData
) {
	const { modelId, accountId, budgetId, filter, fetch, columns, expandedRowIds } = params;

	const url = new UrlBuilder(`cost/profiles/${profileId}/models/${modelId}/budgets/${budgetId}`)
		.build()

	const payload = {
		filterType: filter?.type,
		filterOperator: filter?.operator,
		filterValue: filter?.value,
		fetchType: fetch,
		columns,
		expandedRowIds
	}

	return new ApiRequest<CostBudgetData>({
		url: url,
		method: 'POST',
		payload,
		accountId: accountId,
		accountBased: true,
		responseType: CostBudgetData,
		deserializationCallback
	})
}

export interface CreateBudgetParams {
	accountId?: string
	profileId: string
	modelId: string

	useSelectedCost: boolean
	useSelectedBudget: boolean
	selectedStartDate: string
	startDate: string
}

export function createCostBudget(params: CreateBudgetParams) {
	const { accountId, profileId, modelId, ...payload } = params
	const url = new UrlBuilder(`cost/profiles/${profileId}/models/${modelId}/budgets`)
		.build()
	return new ApiRequest<CostBudgetData>({
		url,
		method: 'POST',
		payload,
		accountId: accountId,
		accountBased: true,
		responseType: CostBudgetData
	})
}

export type CostCurrency = {
	text: string,
	value: string
}

export function listCurrencies(accountId?: string){
	return new ApiRequest<CostCurrency[]>({
		url: 'cost/currencies',
		accountId: accountId,
		accountBased: true
	})
}

export function listCostProfiles(accountId?: string, costType?: string) {
	let url = 'cost/profiles/';
	costType && (url = `${url}types/${costType}`);
	return new ApiRequest<CostProfile[]>({
		url,
		accountId,
		accountBased: true,
		includeSubaccounts: ApplicationState.includeSubaccounts,
	})
}

export function listCostModels(costProfileId: string, accountId?: string){
	return new ApiRequest<CostModel[]>({
		url: `cost/profiles/${costProfileId}/models`,
		accountId: accountId,
		accountBased: true
	})
}


export function deleteBudgetYear(budgetId: string, accountId?: string){
	return new ApiRequest({
		method: 'DELETE',
		url: `cost/budgets/${budgetId}`,
		accountId: accountId,
		accountBased: true
	})
}

export function getSelectionCostData(selection: string[]) {
	return new ApiRequest<SelectionCostData>({
		url: 'reports/costItems',
		method: 'POST',
		payload: selection
	})
}

export function getCostModelInfo(costItemId: string, linkModelId?: string, linkItemId?: string) {
	const url = new UrlBuilder(`cost/${costItemId}/costModelInfo`)
		.add('linkModelId', linkModelId)
		.add('linkItemId', linkItemId)
		.build();

	return new ApiRequest<CostItemInfo>({
		url,
		accountBased: true
	})
}

export function getCostBudgetItemChildren(profileId: string, modelId: string, budgetId: string, costItemId: string, fetch: FetchType, columns?: ShowColumns) {
	const url = new UrlBuilder(`cost/profiles/${profileId}/models/${modelId}/budgets/${budgetId}/items/${costItemId}`)
		.add('fetch', fetch)
		.add(columns)
		.build()

	return new ApiRequest<CostBudgetItem[]>({
		url,
		accountBased: true,
		responseTypeArray: CostBudgetItem
	})
}

export function getCostLinkInfo({data, currency, startDate, accountId, modelId}: {
	data: CostBudgetLink,
	currency: string,
	startDate: string,
	accountId?: string,
	modelId: string
}) {
	return new ApiRequest<CostBudgetItem>({
		url: `cost/models/${modelId}/costLinkInfo?currency=${currency}&startDate=${startDate}`,
		accountBased: true,
		accountId,
		method: 'POST',
		payload: data,
		responseType: CostBudgetItem
	});
}


export function getCostMetricData(costItemId: string, currency: string) {
	const url = new UrlBuilder(`cost/${costItemId}/costMetricInfo`)
		.add('currencyId', currency)
		.build();

	return new ApiRequest<CostMetricData>({
		url,
		accountBased: true
	})
}


export type GetResourcesArgs = {
	accountId?: string
	costProfileId: string
	costModelId: string
	showTotal?: boolean
	showOther?: boolean
	showResources?: boolean
}
export type CostResource = {
	id: string
	name: string
}

export function listCostResources(args: GetResourcesArgs){
	const showTotal = args.showTotal ?? true
	const showOther = args.showOther ?? true
	const showResources = args.showResources ?? true

	return new ApiRequest<CostResource[]>({
		url: `cost/profiles/${args.costProfileId}/models/${args.costModelId}/resources?showTotal=${showTotal}&showOther=${showOther}&showResources=${showResources}`,
		accountId: args.accountId,
		accountBased: true
	})
}


export function getCostAlarm(costAlarmId: string) {
	const url = `cost/costAlarms/${costAlarmId}`
	return new ApiRequest<CostAlarm>({
		url,
		accountBased: true
	})
}

export function getCostProfileFilters() {
	return new ApiRequest<RulesConfiguration>({
		url: 'cost/profiles/ruleConfiguration',
		accountBased: true,
		method: 'GET'
	});
}

export function getCostGatewayConnections() {
	return new ApiRequest<PagedList<GridDataItem<CostGatewayConnection>>>({
		url: 'cost/gateway/connections/search',
		accountBased: true,
		subaccountsFilter: true
	})
}

export function getCostGatewayConnectionsFilters() {
	return new ApiRequest<RulesConfiguration>({
		url: 'cost/gateway/connections/ruleConfiguration',
		accountBased: true,
		subaccountsFilter: true,
		method: 'GET'
	})
}

export function getCostGatewayConnection(connectionId: string) {
	return new ApiRequest<CostGatewayConnection>({
		url: `cost/gateway/connections/${connectionId}`,
		accountBased: true,
		responseType: CostGatewayConnection
	})
}

export function getCostCloudTypes(accountId?: string) {
	return new ApiRequest<CostGatewayCloudType[]>({
		url: 'cost/gateway/cloudTypes',
		accountBased: true,
		accountId
	})
}

export function deleteCostConnections(ids: string[]) {
	return new ApiRequest({
		url: 'cost/gateway/connections/delete',
		accountBased: true,
		payload: ids,
		method: 'POST'
	})
}

export function updateCostConnection(data: any) {
	return new ApiRequest({
		url: 'cost/gateway/connections',
		accountBased: true,
		payload: data,
		method: 'POST'
	})
}

export function getCostGatewaySubscriptions(name: string, cloudType: string, authenticationId: string, accountId?: string) {
	return new ApiRequest<CostGatewaySubscription[]>({
		url: 'cost/gateway/subscriptions',
		accountBased: true,
		accountId,
		payload: {name, cloudType, authenticationId},
		method: 'POST',
		responseTypeArray: CostGatewaySubscription
	})
}

export function deleteCostGatewayConnections(store: GridStore<any>) {
	return store.getSelectionApiRequest({
		url: 'cost/gateway/connections/delete'
	})
}

export function getCostGatewayTags(cloudType: string, authentication: Authentication, accountId: string) {
	return new ApiRequest<string[]>({
		url: 'cost/gateway/tags',
		accountBased: true,
		accountId,
		payload: {cloudType, authenticationId: authentication.id, name: authentication.name},
		method: 'POST'
	})
}

export function getCostGatewayLevelTypes(cloudType: string, dataType: CostGatewaySheetDataType, accountId?: string) {
	const url = new UrlBuilder(`cost/gateway/cloudTypes/${cloudType}/levelTypes`)
		.add('dataType', dataType)
		.build()

	return new ApiRequest<CostGatewayLevelType[]>({
		url,
		accountBased: true,
		accountId
	})
}

export function testCostGatewayConnection(cloudType: string, connectionId: string, authentication: Authentication, accountId?: string) {
	return new ApiRequest<{lastRun: number, test: string}>({
		url: 'cost/gateway/test',
		accountBased: true,
		accountId,
		payload: {cloudType, connectionId, authenticationId: authentication?.id, name: authentication?.name},
		method: 'POST'
	})
}

export function exportSheetToCSV(costProfileId: string, costModelId: string, costBudgetId: string) {
	return new ApiRequest<string>({
		url: `cost/profiles/${costProfileId}/models/${costModelId}/budgets/${costBudgetId}/exportCSV`,
		accountBased: true,
		method: 'GET'
	})
}
