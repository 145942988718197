import './reactApp.less'

import React, {useCallback} from 'react';

import {Breadcrumb} from "./layout/breadcrumb";
import {addContextActions} from "./core/react/store";
import {State} from "tools/state";
import MainApplication from "core/mainApplication";
import {UserTags} from 'layout/userTags/userTags';
import {PageHelpButton} from "./layout/helpButton";
import {MainNavigation} from "layout/mainNavigation";
import classnames from 'classnames';
import {observer} from 'mobx-react';
import b_ from 'b_';
import {FavoritesButton} from "./layout/favoritesButton";
import Settings from "settings";
import {LayoutMenuOrientation, LayoutStore} from "./layout/layoutStore";
import {Api, UserPrefs, Utils} from "./tools";
import {Permission} from "./framework/permission";
import {ApplicationState} from "./framework/applicationState";
import {NavigationAction, NavigationStore} from "./framework/navigationStore";
import {ManagementRouter} from "./areas/management/bundleDescription";
import {SummaryRouter} from "./areas/summary/bundleDescription";
import {Cookies} from "./core";

import lang from "./core/localization/lang";
import {UserRouter} from "./areas/administration/users/bundleDescription";
import {AntRadioGroup} from "./controls/react/ant/antRadio";
import {AccountSelector} from "./controls/accountSelector";
import {App, ConfigProvider, FloatButton} from "antd"
import {AntAppWrapper} from "./controls/react/ant/antAppWrapper";
import {ChatStoreInstance} from "controls/chat/chatStore";
import {initCssVariables} from "styles/cssVariables";


const i = require('core/localization').translator({
  "Only account": {
    "no": "Brukerkonto"
  },
  "System messages": {
    "no": "Systemmeldinger"
  },
  "Last changes": {
    "no": "Siste endringer"
  },
  "Clear user settings": {
    "no": "Slett brukerpreferanse",
    "en": "Clear user settings"
  },
  "Logout": {
    "no": "Logg ut"
  },
  "Active": {
    "no": "Aktiv"
  },
  "User tags": {
    "no": "Brukeretiketter",
    "en": "User tags"
  },
  "Ignore tags": {
    "no": "Ignorer etiketter",
    "en": "Ignore tags"
  },
  "Team tags": {
    "no": "Teametiketter",
    "en": "Team tags"
  },
  "Message center": {
    "no": "Meldingssenter"
  },
  "Cost": {
    "no": "Kost"
  },
  "Cost Model": {
    "no": "Kostmodell",
    "en": "Cost model"
  },
  "Cost Store": {
    "no": "Kost Base",
    "en": "Cost store"
  },
  "Cost Gateway": {
    "no": "Kost Gateway",
    "en": "Cost gateway"
  }
});

@addContextActions
export class ReactApp extends React.Component {

	constructor(props) {
		super(props);

		initCssVariables()
		this.state = {};
	}

	render() {
		return (
			<React.Fragment>
				{!ApplicationState.standalone && <LegacyApp/>}
				{ApplicationState.standalone && <StandaloneApp/>}
			</React.Fragment>
		)
	}

	async componentDidMount(){
		State.mainApp = new MainApplication()
		State.mainApp.standalone = ApplicationState.standalone
		State.mainApp.setReactRef(this)
		State.mainApp.run()
	}
}

const StandaloneApp = props => {
	return (
		<div id="content_area"></div>
	)
}

const b = b_.with('react-app')

const Logo = observer(() => {
	const logoId = ApplicationState.accountLogoId
	const showCustomLogo = logoId &&
		Utils.isGuid(logoId) &&
		(ApplicationState.hasAnyPermission(Permission.AccountRead, Permission.AccountProfile))

	return <div className="toolbar__entry">
		{!showCustomLogo && <div className="logo"><a href={Settings.httpRoot} title="Ceeview Logo"></a></div>}
		{showCustomLogo && <div className="cw_logo_custom"><a href={Settings.httpRoot}><img src={Api.images.urls.image(logoId)}/></a></div>}
	</div>
})

const UserInfoItem = observer(({className, icon, title, counter, url, onClick, permissions}) => {
	const counterValue = counter()
	const onClickHandler = onClick ?? React.useCallback(() => {
		url && NavigationStore.go(url, {navigationAction: NavigationAction.Clear})
	}, [url])

	if(permissions && !ApplicationState.hasPermissions(...permissions)) {
		return null
	}

	return <li className={classnames('cw_item', className)} onClick={onClickHandler}>
		<span className={`glyphicons ${icon}`} title={title}></span>
		{ !!counterValue && <b id="notify_bubble" className="cw_value">{Utils.abbreviateNumber(counterValue)}</b> }
	</li>
})

const UserIcon = observer(() => {
	const [error, setError] = React.useState(() => false)
	const pictureId = (ApplicationState.userPictureId != 'null' && ApplicationState.userPictureId != null) ? ApplicationState.userPictureId : 'user-rounded'
	const openProfile = useCallback((e) => {
		const userId = ApplicationState.userId;
		e.stopPropagation();
		State.mainApp.navigate(UserRouter.profile(userId), {
			navigationAction: NavigationAction.PopOne
		});
	}, [])

	if (error) {
		return <span id="cw_user_icon" className={`glyphicons user`} onClick={openProfile}/>
	}

	if (Utils.isGuid(pictureId)) {
		const src = Settings.serverPath + 'sessions/' + Cookies.sessionId + '/accounts/' + Cookies.CeesoftAccountId + '/users/' + Cookies.CeesoftUserId + '/picture/' + pictureId;
		return <img alt={i('User Profile')} src={src} id="cw_user_icon" onError={() => setError(true)} onClick={openProfile}/>
	} else {
		return <span id="cw_user_icon" className={`glyphicons ${pictureId}`} onClick={openProfile}/>
	}
})

const UserInfo = observer(() => {
	React.useEffect(() => {
		$(".cw_user_actions").kendoMenu({
			orientation: 'vertical',
			hoverDelay: 500,
			select: (item) => {
				switch (item.item.id) {
					case 'menu_logout':
						State.mainApp?.doLogout();
						break;
					case 'menu_account':
						$(".current_account").trigger('click');
						break;
					case 'menu_profile':
						const userId = ApplicationState.userId || 'current';

						State.mainApp.navigate(UserRouter.profile(userId), {
							navigationAction: NavigationAction.PopOne
						});

						$('.cw_user_actions').removeClass('is_open');
						break;
					case 'menu_about':
						State.mainApp?.showAboutDialog();
						$('.cw_user_actions').removeClass('is_open');
						break;
					case 'menu_clear_context':
						UserPrefs.clearCategory(() => {
							State.mainApp?.actionNotification.setOptions({
								message: lang.messages.CLEAR_CONTEXT_MESSAGE,
								status: 'success'
							}).show();
							var grid = $('.k-grid.k-widget:visible').data('kendoCustomGrid');
							if (grid) {
								grid.dataSource.sort([]);
								grid.dataSource.filter([]);
								var searchBox = $('.cw_search_box');
								if (searchBox.length) {
									searchBox.val('');
								}
							}
						})
						break;
				}
			}
		});
	}, [])
	return <>
		<ul className="toolbar__entry cw_userinfo">
			<li className="cw_item cw_current_user" onClick={(e) => State.mainApp?.onCurrentUser(e)}>
				<span className="cw_logged_user">{ApplicationState.userName}</span>
				<b className="caret"></b>
				<UserIcon/>
			</li>
			<li className="cw_item cw_page_help">
				<PageHelpButton/>
			</li>
			<UserInfoItem
				permissions={[Permission.MessageList]}
				className={'cw_notifications'}
				icon={'envelope'}
				title={i('Message center')}
				onClick={(e) => State.mainApp.onNotificationsMenu(e)}
				counter={() => ApplicationState.notificationCounters.unreadMessages}
			/>
			<UserInfoItem
				permissions={[Permission.SystemErrorList]}
				className={'cw_systemerror_notifications'}
				icon={'cogwheels'}
				title={i('System messages')}
				url={ManagementRouter.systemMessages()}
				counter={() => ApplicationState.notificationCounters.unreadSystemErrors}/>
			<UserInfoItem
				permissions={[Permission.EventSummaryList, Permission.SummaryList]}
				className={'cw_event_summary_notifications'}
				icon={'article'}
				title={i('Event summary')}
				url={SummaryRouter.events()}
				counter={() => ApplicationState.notificationCounters.eventSummaryCount}
			/>
			<UserInfoItem
				className={'cw_last_changes_notifications'}
				icon={'heat'}
				title={i('Last changes')}
				onClick={(e) => State.mainApp.loadModule('LastChanges', null, {}, e)}
				counter={() => ApplicationState.notificationCounters.lastChanges}
			/>
			<li className="cw_item cw_usertags user-tags__panel-item">
				<UserTags/>
			</li>
		</ul>
		<div className="toolbar__entry cw_open_panels">
			<ul className="cw_user_actions">
				<li className="cw_item" id="menu_about"><a href="#" onClick={e => e.preventDefault()}>{i('About')}</a></li>
				{ApplicationState.hasPermissions('USER_PROFILE') && <li className="cw_item" id="menu_profile"><a href="#" onClick={e => e.preventDefault()}>{i('Profile')}</a></li>}
				<li className="cw_item" id="menu_advanced"><a href="#" onClick={e => e.preventDefault()}>{i('Advanced')}</a>
					<ul>
						<li className="cw_item" id="menu_clear_context"><a href="#"
						                                                   onClick={e => e.preventDefault()}>{i('Clear user settings')}</a>
						</li>
					</ul>
				</li>
				<li className="cw_item" id="menu_logout"><a href="#" onClick={e => e.preventDefault()}>{i('Logout')}</a></li>
			</ul>

			<ul className="cw_user_notifications"></ul>

			<div className="cw_usertags_section hide">
				<fieldset className="cw_usertag">
					<legend>{i('User tags')}<span>{i('Active')}</span>
					</legend>
					<div className="cw_white_mask hide"></div>
					<div className="cw_ignore_tags right">
						<input type="checkbox" className="k-checkbox" id="cw_ignore_tags"/>
						<label htmlFor="cw_ignore_tags"
						       className="k-checkbox-label">{i('Ignore tags')}</label>
					</div>
					<div className="cw_show_untagged go_left">
						<input type="checkbox" className="k-checkbox" id="cw_show_untagged"/>
						<label htmlFor="cw_show_untagged"
						       className="k-checkbox-label">{i('Show Untagged')}</label>
					</div>
					<div className="left w100 cw_usertags_list"></div>
				</fieldset>
				<fieldset className="cw_teamtags">
					<legend>{i('Team tags')}<span>{i('Active')}</span>
					</legend>
					<div className="cw_white_mask hide"></div>
					<div className="left w100 cw_teamtags_list"></div>
				</fieldset>
				<div className="cw_actions">
					<button className="k-button right"
					        id="cancel_tags">{i('Cancel')}</button>
					<button className="k-button k-primary right"
					        id="save_tags">{i('Update')}</button>
				</div>
			</div>
		</div>
	</>
})

const includeSubaccountsMenuB = b_.with('include-subaccounts-menu')
const includeSubaccountsOptions = [{
	label: <div className={includeSubaccountsMenuB('icon', {include: true})} title={i('Include subaccounts')}/>,
	value: true,
	key: 'include',
	title: i('Include subaccounts')
}, {
	label: <div className={includeSubaccountsMenuB('icon', {exclude: true})} title={i('Only account')}/>,
	value: false,
	key: 'exclude',
	title: i('Only account')
}]

const IncludeSubaccountsMenu = observer(() => {
	const onChange = React.useCallback((value) => {
		State.includeSubaccounts = value
		ApplicationState.includeSubaccounts = State.includeSubaccounts;
		State.currentApp?.onIncludeSubaccounts?.(State.includeSubaccounts);

		State.mainApp?.saveUserPreferences();
		State.mainApp?.getStatusesCount();
		State.mainApp?.subscribe();
	})

	return <AntRadioGroup
		onChange={onChange}
		value={ApplicationState.includeSubaccounts}
		disabled={ApplicationState.includeSubaccountsMenuDisabled}
		className={includeSubaccountsMenuB()}
		options={includeSubaccountsOptions}
		optionType={'button'}
		size={'small'}
	/>
})

const Header = observer(({layoutStore, menu}) => {
	const showMenu = !layoutStore.pinned || layoutStore.orientation === LayoutMenuOrientation.Horizontal
	const userInfoOnMenuLine = layoutStore.pinned && layoutStore.orientation === LayoutMenuOrientation.Horizontal

	return <div className={classnames("site-header", b('header'))}>
		{!layoutStore.pinned && <Logo/>}
		<div className={b('menu-wrapper')}>
			{userInfoOnMenuLine && <Logo/>}
			{showMenu && menu}
			{userInfoOnMenuLine && <div className={b('user-info')}><UserInfo/></div>}
		</div>
		<div className={classnames('toolbar', b('toolbar'))}>
			<div className="toolbar__entry site-header__navigation">
				{ApplicationState.hasPermissions(Permission.AccountTraverse) && <IncludeSubaccountsMenu />}
				<AccountSelector/>
				<Breadcrumb className={b('breadcrumb')}/>
				<FavoritesButton/>
			</div>
			<div className="toolbar__entry cw_statuses"></div>
			{!userInfoOnMenuLine && <UserInfo/>}
		</div>
	</div>
})

const LegacyApp = observer(props => {
	const [store] = React.useState(() => new LayoutStore())
	const mainContentRef = React.useRef()

	const menu = <MainNavigation layoutStore={store} className={b('navigation')}/>

	return (

			<App message={{getContainer: () => mainContentRef.current}}>
				<AntAppWrapper/>
				<div className={b({'menu-orientation': store.orientation, 'menu-pinned': store.pinned})}>
					{store.pinned && store.orientation === LayoutMenuOrientation.Vertical && <div className={b('side')}>
						<Logo/>
						{menu}
					</div>}
					<div className={b('content')}>
						<Header layoutStore={store} menu={menu}/>
						<div id="main_loading_mask"></div>
						<div className={b('main-content')} ref={mainContentRef}>
							<div id="content_area"></div>
						</div>
						<div id="modal"></div>
						<div className="cw_status_message"></div>
					</div>
				</div>
				{ChatStoreInstance.showChatButton && <FloatButton type={ ChatStoreInstance.opened ? "primary" : 'default'} onClick={ChatStoreInstance.toggle} />}
			</App>

	)
})
