import {
	getParentCell,
	iterateGraphChildren
} from "controls/designer/utils";
import {PresentationMode} from "areas/services/designer/graph-editor-extensions/presentationMode";

export function enableFeature(designer){
	const {graph} = designer;
	graph.presentationModeOverride = {}
	graph.presentationModeSettings = {
		showIcons: designer.config.mode == 'service' ? false : true
	}

	addCustomOptions(designer);

	if(!designer.config.chromeless)
		return;

	const original = graph.isCellVisible;
	graph.isCellVisible = function(cell) {
		if (!original.apply(this, arguments)) {
			return false;
		}

		return isCellVisible(graph, cell);
	}
}

function isCellVisible(graph, cell, alreadyProsessedCells = {}){
	if(alreadyProsessedCells[cell.id] != null)
		return true;

	alreadyProsessedCells[cell.id] = true;

	if(cell.isServiceRoot())
		return true;

	const parent = getParentCell(cell);
	if(parent == null)
		return true

	if(!isCellVisible(graph, parent, alreadyProsessedCells))
		return false;

	const overrideValue = graph.presentationModeOverride[parent.id];
	if(overrideValue !== undefined){
		return overrideValue == PresentationMode.ShowAllChildren;
	}

	if(!isCellExpanded(graph, parent))
		return false;

	if(parent.getAttribute('sd-visualisation') == PresentationMode.ShowDownElementsOnly && !cell.isDown())
		return false;

	return true;
}

export function isCellExpanded(graph, cell){
	if(graph.presentationModeOverride[cell.id] !== undefined){
		return graph.presentationModeOverride[cell.id] == PresentationMode.ShowAllChildren;
	}

	if ( cell.getAttribute('sd-visualisation') == null
		|| cell.getAttribute('sd-visualisation') == PresentationMode.ShowAllChildren
		|| cell.getAttribute('sd-visualisation') == PresentationMode.ShowDownElementsOnly && cell.isDown()
		|| cell.getAttribute('sd-visualisation') == PresentationMode.ShowAllChildrenIfDown && cell.isDown()) {
		return true;
	}

	return false;
}


export function hasHiddenChild(graph, cell){
	if(graph.presentationModeOverride[cell.id] !== undefined){
		return graph.presentationModeOverride[cell.id] != PresentationMode.ShowAllChildren;
	}

	return iterateGraphChildren(cell, child => {
		if(!isCellVisible(graph, child, {}))
			return true;
	});
}


export async function addCustomOptions(designer) {
	const {graph} = designer;
	const originalReadGraphState = designer.editor.readGraphState;
	designer.editor.readGraphState = function (node) {
		originalReadGraphState.apply(this, arguments);

		if (node.getAttribute('presentationModeSettings.showIcons') != null) {
			graph.presentationModeSettings.showIcons =
				node.getAttribute('presentationModeSettings.showIcons') == '1'
		}

	}

	const originalGetGraphXml = designer.editor.getGraphXml;
	designer.editor.getGraphXml = function () {
		const node = originalGetGraphXml.apply(this, arguments);

		node.setAttribute("presentationModeSettings.showIcons",
			graph.presentationModeSettings.showIcons ? '1' : '0');

		return node
	}
}
