import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import MultiToggle from 'controls/multiToggle';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';

export function HistoryWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {HistoryWidgetConfiguration as default}

jQuery.extend(HistoryWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		//backward compatibility
		var widgetObj = this.widgetObj;
		var html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';

		html += '<div class="cw_field" id="cw_history_profile">';
		html += '<label class="cw_inline">' + lang.PROFILE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_profileid" type="text" value="' + (widgetObj.configuration.profileId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_category">';
		html += '<label class="cw_inline">' + lang.CATEGORY + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_categoryid" type="text" value="' + (widgetObj.configuration.categoryId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_rule_type">';
		html += '<label class="cw_inline">' + lang.kpi.RULE_TYPE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_ruletypeid" type="text" value="' + (widgetObj.configuration.ruleTypeId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_sample_rate">';
		html += '<label class="cw_inline">' + lang.kpi.SAMPLE_RATE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_sample_rateid" type="text" value="' + (widgetObj.configuration.sampleRateId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_kpi_accounts">';
		html += '<label class="cw_inline">' + lang.kpi.KPI_ACCOUNTS + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_kpi_accountsids" type="text" value="' + (widgetObj.configuration.kpiAccountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.DISPLAY_RATE + ': </label>';
		html += '<ul id="cw_history_rate_control" class="cw_multi_toggle right"></ul>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIOD + ': </label>';
		html += '<ul id="cw_history_period_control" class="cw_multi_toggle right"></ul>';
		html += '</div>';
		html += '<div class="cw_field cw_no_label hide" id="cw_history_dates">';
		html += '<input id="cw_history_startdate" type="text" /> - <input id="cw_history_enddate" type="text" />';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_timezone" type="text" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '"/></div>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);
		this.addTimeSelector(this.widgetObj.configuration, $('#cw_history_period_control')[0]);

		this.rateToggle = new MultiToggle({
			id: 'cw_history_rate_control',
			cssClass: 'cw_multi_toggle right',
			value: widgetObj.configuration.rate || 'DAY',
			type: 'label',
			items: [{
				id: 'cw_history_rate_month',
				title: lang.periodMultiToggle.MONTH,
				label: lang.periodMultiToggle.MONTH,
				selected: false,
				scope: this,
				value: 'ONE_MONTH'
			}, {
				id: 'cw_history_rate_week',
				title: lang.periodMultiToggle.WEEK,
				label: lang.periodMultiToggle.WEEK,
				selected: false,
				scope: this,
				value: 'ONE_WEEK'
			}, {
				id: 'cw_history_rate_day',
				title: lang.periodMultiToggle.DAY,
				label: lang.periodMultiToggle.DAY,
				selected: true,
				scope: this,
				value: 'ONE_DAY'
			}]
		});

		if (this.mode === 'update' || this.wasUpdate) {
			this.updateTimePeriodSelectorControlValue?.(widgetObj.configuration.period);
			this.rateToggle.setSelectedItem(widgetObj.configuration.rate);

			this.selectedProfileId = this.widgetObj.configuration.profileId;
			this.selectedCategoryId = this.widgetObj.configuration.categoryId;
			this.selectedRuleTypeId = this.widgetObj.configuration.ruleTypeId;
			this.selectedSampleRateId = this.widgetObj.configuration.sampleRateId;
			this.selectedKpiAccountId = this.widgetObj.configuration.kpiAccountId;
			this.widgetAccountId = this.widgetObj.configuration.accountId;
		}

		this.initKendoComponents();

		const requiredFields = ['#cw_history_accountid', '#cw_history_profileid', '#cw_history_categoryid', '#cw_history_ruletypeid', '#cw_history_ruletypeid', '#cw_history_sample_rateid', '#cw_history_kpi_accountsids'];
		applyRequired(requiredFields);

		if (widgetObj.configuration.period === 'CUSTOM') {
			$('#cw_history_dates').removeClass('hide');
			$('#cw_history_startdate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.startDate));
			$('#cw_history_enddate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.endDate));
		}
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_history_accountid'),
			container: $('#cw_history_account'),
			nextElement: $('#cw_history_profileid'),
			events: {
				change: (e) => {
					this.isInputChanged = true;
					this.currentAccountId = e.sender.value();
					this.profileSelector.dataSource.read();
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_profile'),
							label: lang.PROFILE
						}, {
							container: $('#cw_history_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_history_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_history_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_history_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.sender.dataSource.data().length
					});
				},
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_profile'),
							label: lang.PROFILE
						}, {
							container: $('#cw_history_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_history_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_history_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_history_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: length
					});
				}, this)
			}
		});

		this.profileSelector = $('#cw_history_profileid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/lite?ignoreTags=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					if (e.items.length) {
						$('#cw_history_kpi_accountsids').data('kendoComboBox').enable(true);
					}
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_history_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_history_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_history_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			select: $.proxy(function (e) {
				this.selectedProfileId = e.dataItem.id;
				var categoryHandler = $('#cw_history_categoryid').data('kendoComboBox');
				var ruleTypeHandler = $('#cw_history_ruletypeid').data('kendoComboBox');
				var sampleRateHandler = $('#cw_history_sample_rateid').data('kendoComboBox');
				if (this.selectedProfileId) {
					categoryHandler.enable(true);
					categoryHandler.dataSource.read();

					$('#cw_history_kpi_accountsids').data('kendoComboBox').enable(true);
				} else {
					categoryHandler.enable(false);

					$('#cw_history_kpi_accountsids').data('kendoComboBox').enable(false);
				}
				ruleTypeHandler.enable(false);
				sampleRateHandler.enable(false);
			}, this),
			template: '<span data-id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			dataBound: $.proxy(function () {
				if (this.mode === 'update' || this.wasUpdate) {
					$('#cw_history_profileid').data('kendoComboBox').value(widgetObj.configuration.profileId);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');
		$('#cw_history_categoryid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/categories';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_history_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_history_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				this.selectedCategoryId = e.dataItem.value;
				var ruleTypeHandler = $('#cw_history_ruletypeid').data('kendoComboBox');
				var sampleRateHandler = $('#cw_history_sample_rateid').data('kendoComboBox');
				if (this.selectedCategoryId) {
					ruleTypeHandler.enable(true);
					ruleTypeHandler.dataSource.read();
				} else {
					ruleTypeHandler.enable(false);
				}
				sampleRateHandler.enable(false);
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			dataBound: $.proxy(function () {
				if (this.mode === 'update' || this.wasUpdate) {
					$('#cw_history_categoryid').data('kendoComboBox').value(widgetObj.configuration.categoryId);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');
		$('#cw_history_ruletypeid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_history_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/categories/' + this.selectedCategoryId + '/types';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					model: {
						id: "value"
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				this.selectedRuleTypeId = e.dataItem.value;
				//var ruleType = e.sender.dataSource.get(this.selectedRuleTypeId);
				var sampleRateHandler = $('#cw_history_sample_rateid').data('kendoComboBox');
				if (this.selectedRuleTypeId) {
					sampleRateHandler.enable(true);
					sampleRateHandler.dataSource.read();
				} else {
					sampleRateHandler.enable(false);
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			dataBound: $.proxy(function () {
				if (this.mode === 'update' || this.wasUpdate) {
					$('#cw_history_ruletypeid').data('kendoComboBox').value(widgetObj.configuration.ruleTypeId);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');
		$('#cw_history_sample_rateid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/types/' + this.selectedRuleTypeId + '/sampleRates';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				this.selectedSampleRateId = e.dataItem.value;
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			dataBound: $.proxy(function () {
				if (this.mode === 'update' || this.wasUpdate) {
					$('#cw_history_sample_rateid').data('kendoComboBox').value(widgetObj.configuration.sampleRateId);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');
		$('#cw_history_kpi_accountsids').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/accounts';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: function (data) {
						data.unshift({
							value: 'aggregated',
							text: lang.AGGREGATED
						});

						return data;
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				this.selectedKpiAccountId = e.dataItem.value;
			}, this),
			template: '<span data-value="${value}">${text}</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			dataBound: $.proxy(function (e) {

			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$("#cw_history_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_history_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});

		$('#cw_history_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			clearButton: false,
		});
	},
	/**
	 * Handler function for the click event on qualifier type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onPeriodToggle: function (value) {
		if (value === 'CUSTOM') {
			$('#cw_history_dates').removeClass('hide');
		} else {
			$('#cw_history_dates').addClass('hide');
		}
	},
	findRecord: function (dataSource, id) {
		var record = null;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var r = dataSource.at(i);
			if (r.id === id) {
				record = r;
				break;
			}
		}
		return record;
	},
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var rate = this.rateToggle.getValue(),
			timezone = $('#cw_history_timezone').data('kendoComboBox').value();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'kpi_history',
			title: title,
			configuration: {
				accountId: $('#cw_history_accountid').data('kendoComboBox').value(),
				...this.periodToggle,
				rate: rate,
				timezone: timezone || Cookies.CeesoftTimezone
			}
		};

		var config = {
			profileId: this.selectedProfileId,
			profileText: $('#cw_history_profileid').data('kendoComboBox').text(),
			categoryId: this.selectedCategoryId,
			categoryText: $('#cw_history_categoryid').data('kendoComboBox').text(),
			ruleTypeId: this.selectedRuleTypeId,
			ruleTypeText: $('#cw_history_ruletypeid').data('kendoComboBox').text(),
			sampleRateId: this.selectedSampleRateId,
			sampleRateText: $('#cw_history_sample_rateid').data('kendoComboBox').text(),
			kpiAccountId: this.selectedKpiAccountId
		};

		jQuery.extend(widgetObj.configuration, config);
		return widgetObj;
	},
	/**
	 * Validates the widget config form
	 * @return {Boolean} valid True if the form is valid, false otherwise
	 */
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		if (!Utils.isGuid(this.widgetAccountId)) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(this.selectedProfileId)) {
			valid = false;
			validationMessage = lang.widget.SELECT_PROFILE;
		}
		if (!this.selectedCategoryId) {
			valid = false;
			validationMessage = lang.widget.SELECT_CATEGORY;
		}
		if (!this.selectedRuleTypeId) {
			valid = false;
			validationMessage = lang.widget.SELECT_RULE_TYPE;
		}
		if (!this.selectedSampleRateId) {
			valid = false;
			validationMessage = lang.widget.SELECT_SAMPLE_RATE;
		}
		if (!this.selectedKpiAccountId) {
			valid = false;
			validationMessage = lang.widget.SELECT_KPI_ACCOUNTS;
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
