import {makeAutoObservable} from "mobx"

import {DesignerStore} from "controls/designer/designerStore"
import {getWidgetDescription} from "controls/designer/features/widgets/allWidgets"
import {MxCell} from "controls/designer/mxGraphInterfaces"
import {MobxManager} from "framework/mobx-integration"
import {
	checkCellForWidget,
	destroyWidget,
	destroyWidgetById,
	iterateWidgets
} from "controls/designer/features/widgets/tools"
import {CustomDataEntry} from "controls/designer/customDataEntry";
import {updateNullFields} from "tools/helpers/array";

export class WidgetsManager{
	mobx = new MobxManager()
	constructor(public store: DesignerStore) {
		makeAutoObservable(this)
	}

	init(){
	}

	get(cell: MxCell) {
		return this.store.getCustomData(cell)?.widgetConfig
	}

	getEffectiveConfig(cell: MxCell){
		let config = this.get(cell)

		if(!config)
			return null

		const widgetDescription = getWidgetDescription(config.type)
		if(widgetDescription == null)
			return

		updateNullFields(config, widgetDescription.defaultConfig)
		if(config.configuration && widgetDescription.defaultConfig.configuration) {
			updateNullFields(config.configuration, widgetDescription.defaultConfig.configuration)
		}

		if (widgetDescription.preProcessConfig) {
			config = widgetDescription.preProcessConfig(config);
		}

		return config
	}

	onCustomDataAdded = (customDataList: CustomDataEntry[]) => {
		customDataList.forEach(x => {
			if(x.widgetConfig) {
				this.checkCellForWidget(x.cell)
			}
		})
	}

	onCustomDataRemoved = (customData: CustomDataEntry) => {
		this.destroyWidget(customData.cell)
	}

	destroyWidget(cell: MxCell){
		destroyWidget(this.store.legacyDesigner, cell)
	}

	async checkCellForWidget(cell: MxCell){
		await checkCellForWidget(this.store.graph, cell)
	}

	destroy(){
		this.mobx.destroy()

		iterateWidgets(this.store.graph, (widgetEntry: WidgetsMapEntry) => {
			destroyWidgetById(this.store.graph, widgetEntry.id);
		} );
	}
}

export type WidgetsMapEntry = {
	id: string
}
