import React from "react"

import {CeeviewNavigator, NavigatorGoArgs, RedirectModifier} from "tools/ceeviewNavigator"
import {RedirectConfig} from "controls/designer/features/redirectOnClick/redirectConfig"
import {RedirectType} from "controls/designer/features/redirectOnClick/redirectType"
import {State} from "tools/state"
import {getUrlBuilderForCurrentHash} from "tools/urlBuilder"
import {DashboardsRouter} from "areas/dashboards/bundleDescription"
import {openModal} from "controls/react/ant/antModal";
import {DashboardDesigner} from "areas/dashboards/dashboard-designer/dashboardDesigner";
import {newGuid} from "tools/guid";


export class NavigatorForRedirectConfig extends CeeviewNavigator {
	id: string

	constructor(public wrappedNavigator: CeeviewNavigator, public config: RedirectConfig, linksRoot?: HTMLElement) {
		super();

		if (!linksRoot) {
			return;
		}

		this.id = newGuid()
		linksRoot.addEventListener('click', (e) => {
			if (e.target instanceof HTMLElement) {
				const id = e.target.getAttribute('data-navigator-link-id')
				if (this.id == id) {
					const url = e.target.getAttribute('data-navigator-link')
					this.go({url: url})
				}
			}
		})
	}

	go(args: NavigatorGoArgs) {
		switch (this.config.type) {
			case RedirectType.Custom:
				this.wrappedNavigator.go({
					url: this.config.link,
					modifier: this.config.modifier,
					popup: this.config.popup
				});
				break

			case RedirectType.Dashboard:
				let url = ''

				if (this.config.modifier == RedirectModifier.Popup) {
					const designerConfig = {
						toolbar: 'zoom-in zoom-out',
					}

					openModal({
							title: 'Dashboard',
							footer: null,
							width: this.config.popup.width,
							height: this.config.popup.height,
							positionType: this.config.popup.position,
							resizable: true
						},
						<DashboardDesigner id={this.config.dashboardId}
						                   mode={"viewer"}
						                   config={designerConfig}/>
					)
				} else {
					if (State.mainApp?.standalone) {
						const urlBuilder = getUrlBuilderForCurrentHash();
						//we need to preserve the same session for standalone mode in order to avoid login screen
						//@ts-ignore
						url = DashboardsRouter.standalone(this.config.dashboardId, urlBuilder.params.sessionId)
					} else {
						url = DashboardsRouter.viewer(this.config.dashboardId)
					}

					this.wrappedNavigator.go({
						url: url,
						modifier: this.config.modifier,
					});
				}
				break

			case RedirectType.None:
				break

			default:
			case RedirectType.Default:
				if (args?.url == null && args?.action == null)
					return

				this.wrappedNavigator.go({
					url: args.url,
					action: args.action,
					modifier: this.config.modifier == RedirectModifier.None ? args.modifier : this.config.modifier,
					popup: this.config.popup
				})
				break
		}
	}

	renderLink(link: string): string {
		if ((this.config.type == RedirectType.Default && this.config.modifier == RedirectModifier.None)) {
			return `href="#${link}"`
		} else {
			return `data-navigator-link-id="${this.id}" data-navigator-link="${link}"`
		}
	}

	canDoDefault(){
		return this.config.type == RedirectType.Default && this.config.modifier == RedirectModifier.None
	}
}

