import { debounce } from "lodash";
import { makeAutoObservable, reaction, toJS } from "mobx";
import {Clazz, deserialize, serialize } from "serializr";
import { UserSettings } from "tools/userSettings";

type GetSettingsProps<T extends object> = {
	clazz: Clazz<T>
	trackable?: boolean
	ignoreCache?: boolean
}

export async function getSettings<T extends object>(category: string, props: GetSettingsProps<T>){
	const settings = await UserSettings.getCategory(category, props?.ignoreCache) as T

	const settingsClassInstance = deserialize(props.clazz, settings)

	const updateSettingsDebounced = debounce((updatedSettings) =>{
		UserSettings.set(category, updatedSettings, true)
	}, 1000 )

	if(props?.trackable !== false){
		reaction(() => serialize(props.clazz, settingsClassInstance), (i) => {
			updateSettingsDebounced(i)
		})
	}

	return settingsClassInstance
}

export async function saveSettings(category: string, settings: any){
	await UserSettings.set(category, settings, true)
}
