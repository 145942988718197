import UrlBuilder from "tools/urlBuilder";

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/SLAs.jsp',
			bodyCssClass: 'cw_sla_module',
			url: '/sla',
			getModuleImport: () => import('./sla'),
			routeId: 'slas'
		},
		{
			jspUrl: 'include/Sla/SlaDetails.jsp',
			bodyCssClass: 'cw_sla_module',
			url: '/slas/:id',
			getModuleImport: () => import('./slaDetails'),
			routeId: 'slas_details'
		}
	];
}

export class SlaRouter {
	static list(options = {}) {
		return new UrlBuilder('/sla').addObject(options).build()
	}

	static details(slaId) {
		return`/slas/${slaId}`;
	}

	static createNew(){
		return '/slas/new';
	}
}
