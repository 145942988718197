import './tableWidget.less'

import React from 'react'

import {Section} from "controls/react/layout/section"
import {sharedLocalization} from "controls/designer/features/widgets/localization"
import {DefaultWidgetWrapper} from "../defaultWidgetWrapper"
import {MetricTableDataWindow} from "controls/metricTableDataWindow"
import {FormEntry} from "controls/react/form/formEntry"
import AccountDropDown from "controls/react/dropdowns/accountDropDown"
import {Cookies} from "core/cookies"
import {AntInput} from "controls/react/ant/antInput"
import {legacyPeriods, TimePeriodAppearance, TimePeriodSelector} from "../../../../react/form/timePeriodSelector"
import CollapsibleFrame from "../../../../react/layout/collapsibleFrame"
import {DropDownList} from "../../../../react/kendoWrappers"
import {timezoneDataSource} from "../../../../../areas/service-boards/widgets/common/dataSources/timezoneDataSource"
import b_ from 'b_'
import {FieldTimeOutlined} from '@ant-design/icons'
import {MetricInlineSelectorState} from "../../../../../areas/service-boards/widgets/common/metricInlineSelector"
import { ApplicationState } from 'framework/applicationState'

const i = require('core/localization').translator(sharedLocalization, {
	'Metric selector': {
		no: 'Metrikkvalg'
	}
})

export function getWidgetDescription() {
	return {
		form: TableWidgetConfiguration,
		defaultConfig: {
			type: 'metric-table',
			title: i('Table'),
			accountId: ApplicationState.accountId,
			period: 'LASTHOUR',
			timezone: ApplicationState.timezone,
			reactTimePeriodSelector: true
		},
		widget: TableWidget,
		fullTitle: i('Metric') + '/' + i('Table'),
	}
}

const TableWidgetConfiguration = props => {
	const {configLink} = props

	const metricId = configLink.get('metricId').value
	let period = configLink.get('period').props.value
	if (typeof period == 'string') {
		period = {period: period}
	}
	const timezoneDS = React.useMemo(() => timezoneDataSource())

	return <Section appearance={"none"}
	                contentPadding={false}
	                childrenPadding={true}>
		<FormEntry label={i('Title')} valueLink={configLink.get('title')}>
			<AntInput/>
		</FormEntry>
		<FormEntry label={i('Account')}>
			<AccountDropDown {...configLink.get('accountId').props}/>
		</FormEntry>
		<CollapsibleFrame expanded={props.expanded} title={i('Display settings')} childrenPadding={true}>
			<FormEntry label={i('Period')}>
				<TimePeriodSelector appearance={TimePeriodAppearance.Buttons}
									periods={legacyPeriods}
									value={period}
									onChange={configLink.get('period').props.onChange} />
			</FormEntry>
			<FormEntry label={i('Timezone')} valueLink={configLink.get('timezone').required()}>
				<DropDownList dataSource={timezoneDS} autoBind={true} combo={true} plainArray={true} />
			</FormEntry>
		</CollapsibleFrame>
		<MetricInlineSelectorState
			type={configLink.get('type').value}
			accountId={configLink.get('accountId').value}
			onAdd={(items) => {
				configLink.get('metricId').update(items?.[0].metricId)
			}}
			configObj={props.config}
			retrieveConfig={(field, value) => {
				configLink.get(field).update(value)
			}}
			selectedMetrics={metricId ? [{metricId}] : []}
			showAdvanced={true}
		/>
	</Section>
}

const b = b_.with('metric-table-widget')

class TableWidget extends React.PureComponent {
	render() {
		const showHeader = this.props.dashboardSettings.showWidgetHeader

		const { config } = this.props
		const timeSelectorContainerClass = b("time-selector-container")
		const infoSignContainerClass = b("info-sign-container")
		const timePeriodSelectorContainer = (
			<div className={b("time-selector-wrapper")}>
				<div className={infoSignContainerClass} />
				<div className={timeSelectorContainerClass} />
			</div>
		)

		return (
			<DefaultWidgetWrapper
				{...this.props}
				containerClass={b({ withoutHeader: !showHeader })}
				toolbarAtTheEnd={showHeader && timePeriodSelectorContainer}
			>
				{!showHeader && timePeriodSelectorContainer}
				<MetricTableDataWindow
					metricId={config.metricId}
					defaultTimeSelectorValue={config.period}
					defaultTimeSelectorDatesValue={config.periodSpan}
					infoSignContainer={`#${config.id} .${infoSignContainerClass}`}
					timeSelectorContainer={`#${config.id} .${timeSelectorContainerClass}`}
					timeSelectorAppearance={this.timePeriodSelectorAppearance}
					timeZone={config.timezone}
					conversion={{
						formula: config.formula,
						unit: config.unit,
						unitLabel: config.unitLabel,
						decimals: config.decimals,
					}}
					conversionUnit={config.conversionUnit}
				/>
			</DefaultWidgetWrapper>
		)
	}

	get timePeriodSelectorAppearance() {
		const showHeader = this.props.dashboardSettings.showWidgetHeader
		if(!showHeader || this.props.cell.geometry.width < 420) {
			return TimePeriodAppearance.Hoverable
		}
		return TimePeriodAppearance.Buttons
	}
}
