import './antTabs.less'

import React, {forwardRef, useCallback, useState, DragEvent} from 'react';
import classnames from 'classnames';
import {observer} from 'mobx-react';

import {Tabs, TabsProps} from 'antd';
import {TabPaneProps} from "rc-tabs/lib/TabPanelList/TabPane";
import {insertAfter} from "../../../tools/helpers/array";
import { makeAutoObservable, toJS } from 'mobx';
import { useEffect } from 'react';
import { MoreOutlined } from '@ant-design/icons';


const {TabPane} = Tabs;

type OnOrderChangeCallback = (newOrder: React.Key[]) => void

export interface AntTabsProps extends TabsProps{
	draggable?: boolean;
	onOrderChange?: OnOrderChangeCallback
}

const b = require('b_').with('ant-tabs-wrapper');

interface DraggableTabNodeProps extends React.HTMLAttributes<HTMLDivElement> {
	idKey: React.Key;
}

const DraggableTabNode =  observer(forwardRef<HTMLDivElement, DraggableTabNodeProps>(({ idKey, children }, ref) => {
	const [dragging, setDragging] = useState(false);

	const onDragStart = useCallback(
		(e) => {
			setDragging(true);
			e.dataTransfer.effectAllowed = "move";
			e.dataTransfer.setData("application/json", JSON.stringify({ idKey }));
			e.stopPropagation();
			return false;
		},
		[idKey]
	);

	const onDragOver = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();
		return false;
	}, []);

	const onDragEnd = useCallback((e) => {
		setDragging(false);
		e.stopPropagation();
		e.preventDefault();
		return false;
	}, []);

	return (
		<div
			ref={ref}
			onDragStart={onDragStart}
			onDragOver={onDragOver}
			onDragEnd={onDragEnd}
			draggable="true"
			className={b('tab-wrapper', { dragging })}
		>
			{children}
		</div>
	);
}));


interface DraggableTabNavListWrapperProps {
	store: TabsStore,
	children: React.ReactNode
}

const DraggableTabNavListWrapper = observer(forwardRef<HTMLDivElement, DraggableTabNavListWrapperProps>(({children, store}, ref) => {

	const onDragOver = useCallback((e) => {
		e.preventDefault();
	}, []);

	return (
		<div onDrop={store.onDrop} onDragOver={onDragOver} className={b('tab-nav-list-wrapper')} ref={ref}>
			{children}
		</div>
	);
}));

class TabsStore {
	centers: Map<React.Key, number> = new Map();
	order: React.Key[] = [];
	tabNavList: HTMLDivElement;
	onOrderChange: OnOrderChangeCallback;

	constructor() {
		makeAutoObservable(this);
	}

	setWidth = (key: React.Key, child: HTMLDivElement) => {
		if (child == null) {
			return
		}
		const left = child.offsetLeft;
		const width = child.offsetWidth;
		const center = left + width / 2;
		this.centers.set(key, center);
	}

	onDrop = (e: DragEvent<HTMLDivElement>) => {
		const data = JSON.parse(e.dataTransfer.getData("application/json"));
		const {idKey} = data;
		// HACK: for scrollable nav-list
		const navListRect = this.tabNavList
			.querySelector('.ant-tabs-nav-list')
			.getBoundingClientRect();
		const x = e.clientX - navListRect.x;


		const afterCenter = this.sortedCenters().reverse().find(c => c < x);
		const afterId = this.getKeyByCenter(afterCenter);
		const oldOrder = [...this.currentOrder()];
		const newOrder = insertAfter(oldOrder, idKey.toString(), afterId);
		this.onOrderChange?.(newOrder);
	}

	setOnOrderChange(fn: OnOrderChangeCallback) {
		this.onOrderChange = fn;
	}

	currentOrder() {
		return this.sortedCenters().map(this.getKeyByCenter);
	}

	sortedCenters() {
		return [...this.centers.values()].sort((a,b) => a - b);
	}

	getKeyByCenter = (c: number) => {
		for(let[k,v] of this.centers.entries()) {
			if (Math.abs((v-c)) < 1) {
				return k;
			}
		}
	}

	setTabNavList(tabNavList: HTMLDivElement) {
		this.tabNavList = tabNavList;
	}
}

const DraggableTabs: React.FC<{ children: React.ReactNode, onOrderChange: OnOrderChangeCallback }> = (props) => {
	const [store, ] = useState(new TabsStore);
	const {children, onOrderChange, ...rest} = props;
	useEffect(() => {
		store.setOnOrderChange(onOrderChange);
	}, [onOrderChange]);

	const renderTabBar: TabsProps['renderTabBar'] = (tabBarProps, TabNavList) => {
		return <DraggableTabNavListWrapper store={store} ref={(ref) => store.setTabNavList(ref)}>
			<TabNavList {...tabBarProps}>
			{
				(node) => <DraggableTabNode key={node.key} idKey={node.key} ref={(child) => store.setWidth(node.key, child)}>
					{node}
				</DraggableTabNode>
			}
			</TabNavList>
		</DraggableTabNavListWrapper>
	};


	return (
		<Tabs renderTabBar={renderTabBar} {...rest}>
			{children}
		</Tabs>
	);
};


export const AntTabs = (props:AntTabsProps) => {
	let {className, draggable, onOrderChange, ...rest} = props;

	className = classnames(className, b());

	rest.moreIcon ||= <MoreOutlined />

	if (draggable) {
		className = classnames(className, b({draggable: true}));
		return <DraggableTabs className={className} onOrderChange={onOrderChange} {...rest}/>;
	}
	return <Tabs className={className} {...rest}/>;
};

export interface AntTabPaneProps extends TabPaneProps {}

const bTabePane = require('b_').with('ant-tab-pane-wrapper');

export const AntTabPane = observer( (props:AntTabPaneProps) => {
	let {className, ...rest} = props;

	className = classnames(className, bTabePane());

	return <TabPane className={className} {...rest}></TabPane>;
});
