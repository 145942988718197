import './antMessage.less';

import {ArgsProps} from "antd/lib/message";
import * as React from "react";
import {message} from "./antAppWrapper";

export type PerformActionOptions =  {
	loadingOptions?: Partial<ArgsProps>
	completedOptions?: Partial<ArgsProps>
	loadingDelay?: number
}

const i18n = require('core/localization').translator({
	'Working': {
		en: 'Working...',
		no: 'Arbeider...'
	},
	'Action completed': {
		no: 'Handling utført'
	}
})

export async function performAction<T>(action: () => Promise<T>, options?: PerformActionOptions, hideWaiting: boolean = false): Promise<T> {
	const loadingOptions: Partial<ArgsProps> & {content: React.ReactNode} = Object.assign({
		content: i18n('Working'),
		duration: 10000,
		key: 'action-message'
	}, options?.loadingOptions )

	let hideLoading: () => void = null;
	const timer = setTimeout(() => {
		if (!hideWaiting) {
			hideLoading = message.loading(loadingOptions)
		}
	}, 500)

	const result = await action()

	clearTimeout(timer)

	const completedOptions = Object.assign({}, {
		content: i18n('Action completed'),
		key: 'action-message'
	}, options?.completedOptions ?? {})

	hideLoading?.()
	if (result !== false) {
		message.success(completedOptions)
	}

	return result
}
