import {observer} from "mobx-react"
import React from "react"
import {MenuInfo} from 'rc-menu/lib/interface'

import {DesignerStore} from "controls/designer/designerStore"
import {AntMenu, AntMenuItem, AntMenuSubmenu} from "controls/react/ant/antMenu";
import {MenuProps} from "antd";

export const ScaleSelector = observer(({store}: { store: DesignerStore }) => {
	const onClick = React.useCallback((info: MenuInfo) => {
		store.scale = Number.parseInt(info.key);
	}, []);

	const items= React.useMemo(() => {
		return [{
			label: `${store.scale}% ▼`,
			key: 'root',
			children: [{
				label: '25%',
				key: '25'
			},{
				label: '50%',
				key: '50'
			},{
				label: '75%',
				key: '75'
			},{
				label: '100%',
				key: '100'
			}]
		}]
	}, [store.scale])

	return <AntMenu mode={"horizontal"}
	                selectable={false}
	                className={"scale-selector"}
	                items={items}
	                onClick={onClick}/>
});
