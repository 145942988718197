import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {Tags} from "controls/tags";
import { ApplicationState } from 'framework/applicationState';

export function IncidentsWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

jQuery.extend(IncidentsWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" /></div>';

		html += '<div class="cw_field" id="cw_incidents_account_field">';
		html += '<div class="cw_inline_full">' + lang.ACCOUNT + ':';
		html += '</div>';
		html += '<select id="cw_incidents_accountids" class="left w100" type="text"></select>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" id="cw_incidents_include_subaccounts_check" class="cw_show_column k-checkbox"/>';
		html += '<label for="cw_incidents_include_subaccounts_check" class="k-checkbox-label">' + lang.INCLUDE_SUBACCOUNTS + '</label>';
		html += '</div>';
		html += '<ul class="cw_list_columns">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head">' + lang.widget.COLUMN_VISIBILITY + '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_account" class="cw_show_column k-checkbox"><label for="cw_incidents_account" class="k-checkbox-label">' + lang.ACCOUNT + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_modified" class="cw_show_column k-checkbox"><label for="cw_incidents_modified" class="k-checkbox-label">' + lang.incidents.MODIFIED + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_reportedby" class="cw_show_column k-checkbox"><label for="cw_incidents_reportedby" class="k-checkbox-label">' + lang.incidents.REPORTED_BY + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_assignedto" class="cw_show_column k-checkbox"><label for="cw_incidents_assignedto" class="k-checkbox-label">' + lang.incidents.ASSIGNED_TO + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_number" class="cw_show_column k-checkbox"><label for="cw_incidents_number" class="k-checkbox-label">' + lang.incidents.NUMBER + '</label></div>'
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_status" class="cw_show_column k-checkbox"><label for="cw_incidents_status" class="k-checkbox-label">' + lang.STATUS + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_created" class="cw_show_column k-checkbox"><label for="cw_incidents_created" class="k-checkbox-label">' + lang.incidents.CREATED + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_owner" class="cw_show_column k-checkbox"><label for="cw_incidents_owner" class="k-checkbox-label">' + lang.incidents.OWNER + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_subject" class="cw_show_column k-checkbox"><label for="cw_incidents_subject" class="k-checkbox-label">' + lang.incidents.SUBJECT + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_description" class="cw_show_column k-checkbox"><label for="cw_incidents_description" class="k-checkbox-label">' + lang.DESCRIPTION + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_priority" class="cw_show_column k-checkbox"><label for="cw_incidents_priority" class="k-checkbox-label">' + lang.assethealth.PRIORITY + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_type" class="cw_show_column k-checkbox"><label for="cw_incidents_type" class="k-checkbox-label">' + lang.TYPE + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_impact" class="cw_show_column k-checkbox"><label for="cw_incidents_impact" class="k-checkbox-label">' + lang.assethealth.IMPACT + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_incidents_tags" class="cw_show_column k-checkbox"><label for="cw_incidents_tags" class="k-checkbox-label">' + lang.TAGS + '</label></div>';
		html += '</li>';
		html += '</ul>';
		html += '<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + lang.TAGS + '</span>';
		html += '<div class="w100 left">';
		html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_untagged" class="k-checkbox" /><label for="cw_untagged" class="k-checkbox-label">' + lang.SHOW_UNTAGGED + '</label></div></div>';
		html += '<label class="cw_inline_full">' + lang.TAGS + '</label>';
		html += '<input class="left w100" id="cw_tags" type="text"/>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		if (widgetObj.configuration.columns) {
			//on edit reset all and set only if in configuration
			$('.cw_show_column').prop('checked', false);

			if (widgetObj.configuration.columns.status && !widgetObj.configuration.columns.status.hidden) {
				$('#cw_incidents_status').prop('checked', true);
			}
			if (widgetObj.configuration.columns.modified && !widgetObj.configuration.columns.modified.hidden) {
				$('#cw_incidents_modified').prop('checked', true);
			}
			if (widgetObj.configuration.columns.priorityName && !widgetObj.configuration.columns.priorityName.hidden) {
				$('#cw_incidents_priority').prop('checked', true);
			}
			if (widgetObj.configuration.columns.accountName && !widgetObj.configuration.columns.accountName.hidden) {
				$('#cw_incidents_account').prop('checked', true);
			}
			if (widgetObj.configuration.columns.incidentNumber && !widgetObj.configuration.columns.incidentNumber.hidden) {
				$('#cw_incidents_number').prop('checked', true);
			}
			if (widgetObj.configuration.columns.createdBy && !widgetObj.configuration.columns.createdBy.hidden) {
				$('#cw_incidents_reportedby').prop('checked', true);
			}
			if (widgetObj.configuration.columns.owner && !widgetObj.configuration.columns.owner.hidden) {
				$('#cw_incidents_owner').prop('checked', true);
			}
			if (widgetObj.configuration.columns.contentType && !widgetObj.configuration.columns.contentType.hidden) {
				$('#cw_incidents_type').prop('checked', true);
			}
			if (widgetObj.configuration.columns.subject && !widgetObj.configuration.columns.subject.hidden) {
				$('#cw_incidents_subject').prop('checked', true);
			}
			if (widgetObj.configuration.columns.assignedTo && !widgetObj.configuration.columns.assignedTo.hidden) {
				$('#cw_incidents_assignedto').prop('checked', true);
			}
			if (widgetObj.configuration.columns.created && !widgetObj.configuration.columns.created.hidden) {
				$('#cw_incidents_created').prop('checked', true);
			}
			if (widgetObj.configuration.columns.description && !widgetObj.configuration.columns.description.hidden) {
				$('#cw_incidents_description').prop('checked', true);
			}
			if (widgetObj.configuration.columns.impactName && !widgetObj.configuration.columns.impactName.hidden) {
				$('#cw_incidents_impact').prop('checked', true);
			}
			if (widgetObj.configuration.columns.tags && !widgetObj.configuration.columns.tags.hidden) {
				$('#cw_incidents_tags').prop('checked', true);
			}
		}

		$('#cw_incidents_include_subaccounts_check').prop('checked', widgetObj.configuration.includeSubaccounts);

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_all_accounts').off()
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_all_accounts').on('click', $.proxy(this.onAllAccountsToggle, this));
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;
		let values =  [];
		if(widgetObj.configuration.accountsList)
			values = widgetObj.configuration.accountsList;
		else
			values = Cookies.CeesoftCurrentAccountId;

		this.incidentsAccounts = $('#cw_incidents_accountids').kendoSortedMultiSelect({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + this.serviceBoardAccountId + '/subaccounts/allLevels/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			placeholder: lang.reports.messages.SELECT_ACCOUNT,
			select: $.proxy(function (e) {
				this.selectedAccountName = e.item.text();
			}, this),
			dataBound: $.proxy(function () {
				if(this.widgetObj.configuration.tags)
					this.tagsList.setValues(this.widgetObj.configuration.tags);
			}, this),
			itemTemplate: '<span data-id="${id}"> ${name}</span>',
			value: values
		}).data('kendoSortedMultiSelect');

		this.tagsList =  new Tags({
			renderEl: $('#cw_tags'),
			enabled: true,
			autoBind: false,
			accountId: ApplicationState.accountId
		});

		if (widgetObj.configuration.showUntagged) {
			$('#cw_untagged').prop('checked', true);
		}
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var statusCheck = $('#cw_incidents_status'),
			modifiedCheck = $('#cw_incidents_modified'),
			priorityCheck = $('#cw_incidents_priority'),
			accountCheck = $('#cw_incidents_account'),
			numberCheck = $('#cw_incidents_number'),
			reportedByCheck = $('#cw_incidents_reportedby'),
			ownerCkeck = $('#cw_incidents_owner'),
			typeCheck = $('#cw_incidents_type'),
			subjectCheck = $('#cw_incidents_subject'),
			assignedToCheck = $('#cw_incidents_assignedto'),
			createdCheck = $('#cw_incidents_created'),
			descriptionCheck = $('#cw_incidents_description'),
			impactCheck = $('#cw_incidents_impact'),
			tagsCheck = $('#cw_incidents_tags');

		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'grid_incidents',
			title: title ? title : lang.INCIDENTS,
			configuration: {
				accountsList: this.incidentsAccounts.value(),
				includeSubaccounts: $('#cw_incidents_include_subaccounts_check').is(':checked'),
				columns: {
					status: {
						hidden: statusCheck.is(':not(:checked)')
					},
					modified: {
						hidden: modifiedCheck.is(':not(:checked)')
					},
					priorityName: {
						hidden: priorityCheck.is(':not(:checked)')
					},
					accountName: {
						hidden: accountCheck.is(':not(:checked)')
					},
					incidentNumber: {
						hidden: numberCheck.is(':not(:checked)')
					},
					createdBy: {
						hidden: reportedByCheck.is(':not(:checked)')
					},
					owner: {
						hidden: ownerCkeck.is(':not(:checked)')
					},
					contentType: {
						hidden: typeCheck.is(':not(:checked)')
					},
					subject: {
						hidden: subjectCheck.is(':not(:checked)')
					},
					assignedTo: {
						hidden: assignedToCheck.is(':not(:checked)')
					},
					created: {
						hidden: createdCheck.is(':not(:checked)')
					},
					description: {
						hidden: descriptionCheck.is(':not(:checked)')
					},
					impactName: {
						hidden: impactCheck.is(':not(:checked)')
					},
					tags: {
						hidden: tagsCheck.is(':not(:checked)')
					}
				},
				tags: this.tagsList.getValues(),
				showUntagged: $('#cw_untagged').is(':checked')
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	},
	/*
	 * Handler function for toggle all accounts
	 * @param {Object} e The click event
	 */
	onAllAccountsToggle: function (e) {
		if ($(e.currentTarget).is(':checked')) {
			$('#cw_incidents_accountids').data('kendoSortedMultiSelect').ul.find('li').not('.k-selected').trigger('click');
		}
	},
});
