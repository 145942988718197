import './budgetBarChart.less'

import React, {useEffect, useState, useMemo} from 'react';
import Highcharts from 'highcharts';

import {Cookies} from 'core/cookies';
import {addLinks} from 'core/react/links';
import Configuration from 'configuration';
import {
	processApiResponse,
	sharedLocalization
} from '../../cloudServices/shared';
import {Section} from 'controls/react/layout/section';
import {FormEntry} from 'controls/react/form';
import {AntInput} from 'controls/react/ant/antInput';
import {AccountDropDown} from 'controls/react/dropdowns/accountDropDown';
import {AntSelect} from 'controls/react/ant/antSelect';
import {AntCheckbox} from 'controls/react/ant/antCheckbox';
import {AntInputNumber} from 'controls/react/ant/antInputNumber';
import {sharedLocalization as widgetsSharedLocalization} from 'controls/designer/features/widgets/localization';
import {formatNumber} from 'tools/helpers/math';
import BudgetApi from './api';
import {DefaultWidgetWrapper} from 'controls/designer/features/widgets/defaultWidgetWrapper';
import {LegendPositionSelect} from '../../cloudServices/legendPositionSelect';
import regression from 'regression';
import {getCostBarChartColor} from './chartDefaultColors'
import {capitalize, findLastIndex, throttle, last, uniq} from 'lodash';
import {CostModelDropDown} from './../costModelDropDown';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import RemoteEventsManager from 'core/remoteEventsManager';
import {throttleLimit} from './../../cloudServices/shared';
import {CostBudgetEvent, CostReportEvent} from 'framework/entities/events';
import {SortDirection, getSortDirectionDataSource} from 'areas/cost/models/sortDirection';
import {BoxView} from 'controls/react/layout/boxView';
import {getTimeSelection, TimeSettings} from './timeSettings';
import {listCurrencies} from 'areas/cost/api';
import {apiFetch} from 'framework/api';
import {IconButton} from 'controls/react/form';
import {costTranslates} from 'areas/cost/translations';
import b_ from 'b_';
import {SketchColorPicker} from 'controls/react/colorPicker/sketchColorPicker';
import {ApplicationState} from "framework/applicationState";
import {CostsRouter} from "../../../../../../areas/cost/bundleDescription";
import {Link} from "../../../../../react/link";
import {BudgetDropDown} from "../budgetDropDown";
import {BudgetItemsSelector} from "../budgetItemsSelector";
import classnames from "classnames";
import {getCurrencyConversionErrorString} from "../../../../../../areas/cost/costHelper";
import {getOrientationDataSource, Orientation} from "framework/entities/orientation";

const formB = b_.with('cost-bar-chart-configuration');

const i = require('core/localization').translator({
  "Bar Chart": {
    "no": "Søylediagram",
    "en": "Bar chart"
  },
  "Show cost": {
    "no": "Vis kostnad",
    "en": "Show cost"
  },
  "Show budget": {
    "no": "Vis budsjett",
    "en": "Show budget"
  },
  "Cost Model Item": {
    "no": "Kostnadelement",
    "en": "Costmodel item"
  },
  "Cost Model": {
    "no": "Kostmodell",
    "en": "Costmodel"
  },
  "Legend Settings": {
    "no": "Beskrivelse",
    "en": "Legend settings"
  },
  "Budget": {
    "no": "Budsjett"
  },
  "Cost profile type": {
    "no": "Kostprofil type",
    "en": "Costprofile Type"
  },
  "Cost Profile Item": {
    "no": "Kostprofile element",
    "en": "Costprofile item"
  },
  "Trend": {
    "no": "Trend"
  },
  "Show trend": {
    "no": "Vis trend",
    "en": "Show trend"
  },
  "Only show total value": {
    "no": "Bare vis total verdi",
    "en": "Only show total value"
  },
  "Month Order": {
    "no": "Måned rekkefølge",
    "en": "Month order"
  },
  "Current month first": {
    "no": "Nåværende måned først",
    "en": "Current month first"
  },
  "Cost Sheet": {
    "no": "Kostark",
    "en": "Costsheet"
  },
  "Display as 1000": {
    "no": "Vises som 1000"
  },
  "Costprofile is not found": {
    "no": "Kostprofil er ikke funnet",
    "en": "Costprofile not found"
  },
  "Currency": {
    "no": "Valuta"
  },
  "Budget line": {
    "no": "Budsjett linje"
  },
  "Show Budget line": {
    "no": "Vis budsjettlinje",
    "en": "Show budgetline"
  },
  "Back": {
    "no": "Tilbake"
  },
  "Show Estimated Cost": {
    "no": "Vis estimert kost",
    "en": "Show estimated cost"
  },
  "Show listing price": {
    "no": "Vis listepris",
    "en": "Show listing price"
  },
  "Show estimated cost info": {
    "en": "Estimated cost shown instead of current month cost.",
    "no": "Estimert kost vises istedet for nåværende månedskost."
  },
  "Preliminary": {
    "no": "Foreløpig"
  },
  "Estimated": {
    "no": "Estimert"
  },
  "Top/Bottom N": {
    "no": "Topp/bunn N",
    "en": "Top/bottom N"
  },
  "Top/Bottom N tooltip": {
    "en": "Top/bottom N show the high or lowest values in selection set.  eg. a setting with top 5 will show the 5 highest cost items, any other cost item in selection will be added to a collective bar. On drilldown you can see the details.",
    "no": "Topp/bunn N viser de høyeste eller laveste verdiene i utvalgssettet. f.eks. topp 5 vil vise de 5 høyeste kostnadene, enhver annen kostnad i utvalget vil bli lagt til en kollektiv bar. På drilldown kan du se detaljene for denne."
  },
  "DrillDownTooltip": {
    "no": "Velg hvordan du vil vise søylene. Standard viser alle valgte, topp viser de største kostnadene, nederst de laveste kostnaden. Nummeret bestemmer hvor mange søyler som vises.",
    "en": "Select how to display bars. Default show all selected. Top show the largest costs, bottom the lowest cost. The input number determine how many bars will be displayed."
  },
  "Color Settings": {
    "no": "Farge valg",
    "en": "Color setting"
  },
  "Blue": {
    "no": "Blå"
  },
  "All": {
    "no": "Alle"
  },
  "Axis": {
    "no": "Akser"
  },
  "Bar": {
    "no": "Søyle"
  },
  "Number": {
    "no": "Antall"
  },
  "Link to Costmodel": {
    "no": "Link for kostmodell",
    "en": "Link to costmodel"
  },
  "Cost Model/Store": {
    "no": "Kostmodell/Base"
  },
	'Orientation': {
	  no: 'Diagramvisning'
	},
	'This feature allows you to flip the bar chart orientation between vertical and horizontal view, providing flexibility in how data is displayed.': {
	  no: 'Denne funksjonen lar deg vende stolpediagramorienteringen mellom vertikal og horisontal visning, noe som gir fleksibilitet i hvordan data vises.'
	}
}, sharedLocalization, widgetsSharedLocalization, costTranslates);

const capitalizeFirstKey = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getWidgetDescription() {
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'budget-cost-bar-chart',
			title: i('Bar Chart'),
			accountId: ApplicationState.accountId,
			decimalsNumber: 0,
			legendPosition: 'TOP',
			showCost: true,
			showBudget: true,
			showListingPrice: true,
			monthOrder: SortDirection.Asc,
			modelId: '',
			budgetItemIds: [],
			timeDisplay: 'annual',
			timeDisplayValue: null,
			currency: 'EUR',
			budgetId: null,
			drillDownOption: 'DEFAULT',
			drillDownLimit: 5,
			barColor: 'DEFAULT',
			axisColor: { hex: "#000000"},
			numberColor: { hex: "#000000"},
			legendColor: { hex: "#000000"},
		},
		widget: Widget,
		fullTitle: i('Cost') + '/' + i('Bar Chart'),
	}
}
const bConfig = require('b_').with('cloud-services-cost-bar-chart-config');

const drillDownOptions = [
	{ name: i('Default'), id: 'DEFAULT'},
	{ name: i('Top'), id: 'TOP'},
	{ name: i('Bottom'), id: 'BOTTOM'},
]

const colorSettingsOptions = [
	{ name: i('Default'), id: 'DEFAULT'},
	{ name: i('Blue'), id: 'BLUE'}
]

const WidgetConfiguration = props => {
	const { configLink } = props;
	const [currencies, setCurrencies] = useState([])
	const [budgetsList, setBudgetsList] = useState([]);
	const [modelsList, setModelsList] = useState([]);

	const accountIdLink = configLink.get('accountId');
	const budgetLink = configLink.get('budgetId');
	const modelIdLink = configLink.get('modelId');
	const budgetItemIds = configLink.get('budgetItemIds');
	const drilldownOptionLink = configLink.get('drillDownOption');
	const costLinkText = configLink.get('costLinkText');
	const displayModelLink = configLink.get('displayModelLink');

	useEffect(() => {
		if (!budgetsList.length || !modelsList.length) {
			return;
		}
		const budgetName = budgetsList.find(x => x.id == budgetLink.value)?.name ?? '';
		const modelName = modelsList.find(x => x.id == modelIdLink.value)?.name ?? '';
		if (!(budgetName + modelName)) {
			costLinkText.update('');
			displayModelLink.update(false);
			return;
		}
		costLinkText.update(budgetName + '/' + modelName);
	}, [budgetLink.value, modelIdLink.value, budgetsList, modelsList])

	accountIdLink.changed((e) => {
		budgetLink.update(undefined);
		loadData();
	})

	budgetLink.changed((e) => {
		modelIdLink.update(undefined);
	});

	modelIdLink.changed((e) => {
		budgetItemIds.update([]);
	});

	useEffect(()=>{
		loadData();
	}, []);

	const loadData = () => {
		apiFetch(listCurrencies(accountIdLink.value)).then(result => {
			if(result.success){
				setCurrencies(result.data)
			}
		})
	}
	const orientationSource = getOrientationDataSource();
	const orderSelectionValues = getSortDirectionDataSource();
	return <Section appearance={'none'} contentPadding={false} childrenPadding={true} className={bConfig()}>
		<Section appearance={'frame'} title={i('Configuration')} childrenPadding={true}>
			<FormEntry label={i('Title')} width={'fit'} valueLink={configLink.get('title')}>
				<AntInput/>
			</FormEntry>
			<FormEntry label={i('Account')} width={'fit'}>
				<AccountDropDown {...accountIdLink.props} />
			</FormEntry>
		</Section>
		<Section appearance={'frame'} title={i('Datasource')} childrenPadding={true}>
			<FormEntry label={i('Cost Model')} width={'fit'} valueLink={budgetLink.required()}>
				<BudgetDropDown onReady={setBudgetsList} accountId={accountIdLink.value}/>
			</FormEntry>
			<FormEntry label={i('Cost Sheet')} width={'fit'} valueLink={modelIdLink.required()}>
				<CostModelDropDown onReady={setModelsList}
								   accountId={accountIdLink.value}
				                   profileId={configLink.get('budgetId').value} />
			</FormEntry>
			<FormEntry label={i('Cost Model Item')} width={'fit'} valueLink={budgetItemIds.required()}>
				<BudgetItemsSelector accountId={accountIdLink.value}
				                     profileId={configLink.get('budgetId').value}
				                     modelId={modelIdLink.value}/>
			</FormEntry>
		</Section>
		<TimeSettings configLink={configLink}
		              accountId={accountIdLink.value}
		              profileId={budgetLink.value}
		              modelId={modelIdLink.value}/>
		<Section appearance={'frame'} title={i('Display settings')} childrenPadding={true}>
			<FormEntry label={i('Currency')} valueLink={configLink.get('currency').required()}>
					<AntSelect {...configLink.get('currency').props} dataList={currencies} nameField='text' valueField='value'/>
			</FormEntry>
			<FormEntry label={i('Decimal Numbers')} width={'fit'}>
				<AntInputNumber valueLink={configLink.get('decimalsNumber')} />
			</FormEntry>
			<FormEntry label={i('Month Order')} width={'fit'}>
				<AntSelect {...configLink.get('monthOrder').props} dataList={orderSelectionValues} defaultValue={SortDirection.Asc}/>
			</FormEntry>
			<FormEntry width={'fit'} label={i('Top/Bottom N')}>
				<IconButton iconName={'question-sign'} title={i('Top/Bottom N tooltip')}/>
				<AntSelect {...drilldownOptionLink.props} dataList={drillDownOptions}/>
				{drilldownOptionLink.value!=='DEFAULT' && <AntInputNumber valueLink={configLink.get('drillDownLimit')} />}
			</FormEntry>
			<FormEntry label={i('Orientation')} width={'fit'}>
				<IconButton iconName={'question-sign'} title={i('This feature allows you to flip the bar chart orientation between vertical and horizontal view, providing flexibility in how data is displayed.')}/>
				<AntSelect {...configLink.get('orientation').props} dataList={orientationSource} defaultValue={Orientation.Vertical}/>
			</FormEntry>
			<FormEntry width={'fit'} className={formB('checkboxes')}>
				<div className={formB('checkboxes-column')}>
					<AntCheckbox valueLink={configLink.get('showBudget')}>{i('Show budget')}</AntCheckbox>
					<AntCheckbox valueLink={configLink.get('showTreshold')}>{i('Show Budget line')}</AntCheckbox>
					<AntCheckbox valueLink={configLink.get('showEstimatedCost')}>{i('Show Estimated Cost')} <IconButton iconName={'question-sign'} title={i('Show estimated cost info')} embedded/></AntCheckbox>
				</div>
				<div className={formB('checkboxes-column')}>
					<AntCheckbox valueLink={configLink.get('showCost')}>{i('Show cost')}</AntCheckbox>
					<AntCheckbox valueLink={configLink.get('showRegression')}>{i('Show trend')}</AntCheckbox>
					<AntCheckbox valueLink={configLink.get('displayAsThousands')}>{i('Display as 1000')}</AntCheckbox>
				</div>
				<div className={formB('checkboxes-column')}>
					<AntCheckbox valueLink={configLink.get('showListingPrice')}>{i('Show listing price')}</AntCheckbox>
					<AntCheckbox valueLink={configLink.get('showOnlyTotalValue')}>{i('Only show total value')}</AntCheckbox>
					<AntCheckbox valueLink={displayModelLink}>{i('Link to Costmodel')}</AntCheckbox>
				</div>
			</FormEntry>
		</Section>
		<Section appearance={'frame'} title={i('Color Settings')} childrenPadding={true}>
			<FormEntry width={'fit'} label={i('Bar')}>
				<AntSelect {...configLink.get("barColor").props} dataList={colorSettingsOptions}/>
			</FormEntry>
			<FormEntry width={'fit'} label={i('Axis')}>
				<SketchColorPicker valueLink={configLink.get("axisColor")} />
			</FormEntry>
			<FormEntry width={'fit'} label={i('Legend')}>
				<SketchColorPicker valueLink={configLink.get("legendColor")} />
			</FormEntry>
			<FormEntry width={'fit'} label={i('Number')}>
			<SketchColorPicker valueLink={configLink.get("numberColor")} />
			</FormEntry>
		</Section>
		<Section appearance={'frame'} title={i('Legend Settings')} childrenPadding={true}>
			<FormEntry label={i('Position')} width={'fit'}
				valueLink={configLink.get('legendPosition')}>
				<LegendPositionSelect/>
			</FormEntry>
		</Section>
	</Section>
}

WidgetConfiguration.propTypes = {
	configLink: PropTypes.object
}


const b = require('b_').with('cloud-services-cost-bar-chart-widget');

@addLinks
class Widget extends React.PureComponent {
	static get propTypes() {
		return {
			config: PropTypes.object
		}
	}
	chartContainerRef = React.createRef();
	state = {
		loading: true,
	}

	drilldownPoints = [];
	drilldownSeries = [];
	drilldownLastValidIndex = [];

	render() {
		const {containerClass, ...otherProps} = this.props;
		const passThruProps = {...otherProps};
		const classList = classnames(containerClass, b())
		return (
			<DefaultWidgetWrapper containerClass={classList} {...passThruProps}>
				<div className={b('chart-container')} ref={this.chartContainerRef}>
					{this.state.loading && <div className="cw_widget_mask"><div className="k-loading-image"></div></div>}
					{this.state.noDataAvailable && <BoxView type={'error'}>{this.state.errorMessage ?? i('Costmodel/store/sheet is not found or has been deleted.')}</BoxView>}
				</div>
				{!!this.props.config.displayModelLink && <Link navigator={this.props.navigator} url={CostsRouter.details(this.props.config.budgetId, null, this.props.config.modelId)}>{this.props.config.costLinkText}</Link>}
			</DefaultWidgetWrapper>
		)
	}

	async componentDidMount() {
		await this.initializeChart();
		this.subscribe(this.initializeChart);
	}

	componentWillUnmount() {
		this.subscription?.unsubscribe();
	}

	chartSeries = (data, costProfileType, stack, name, responseData, barColor) => {
		return {
			name,
			data,
			stack,
			color: barColor,
			custom: {
				subscription: name,
				resourceType: this.props.config.resourceType
			},
			legendObj: {
				costProfileType,
				budgetItemName: name,
				budgetName: responseData.name,
				currency: responseData.currency,
			},
			dataLabels: {
				color: this.props.config?.numberColor?.hex
			}
		};
	}

	processDataRow = (dataRow, barColorFn, responseData, preliminaryMonths) => {
		const barColor = barColorFn.next().value;
		const { name, id, currentEstimate } = dataRow;
		let lastValidMonthIndex = 0;
		if (preliminaryMonths) {
			//checking for preliminary months in dataRow
			dataRow.months.filter(x => x.preliminary).forEach(x => {preliminaryMonths.add({number: x.month})});
		}

		const chartSeries = [];
		const now = new Date();
		['budget', 'cost', 'listingPrice'].forEach(key => {
			if (!this.props.config[`show${capitalizeFirstKey(key)}`]) return;
			//finding the last actual month
			const index = findLastIndex(dataRow.months, m => moment([m.year, m.month-1]) < moment() || m[key] != 0) + 1;
			lastValidMonthIndex = Math.max(index, lastValidMonthIndex);
			//getting rid of future months
			let data = dataRow.months
				.map(m => {
					let value = m[key];
					if (key == 'cost' && this.props.config.showEstimatedCost && m.month == now.getMonth() + 1 && m.year == now.getFullYear()) {
						value = currentEstimate;
					}
					return {
						y: value,
						drilldown: dataRow.hasChildren,
						id: dataRow.id,
						budgetItemIds: dataRow.budgetItemIds,
						dataLabels: {
							color: this.props.config?.numberColor?.hex
						}
					}
				});

			const series = this.chartSeries(data, i(capitalizeFirstKey(key)), key, name, responseData, barColor);
			if (chartSeries.length > 0) {
				series.linkedTo = ':previous';
			}
			chartSeries.push(series);
			const drilldownSeries = last(this.drilldownSeries);
			if (!drilldownSeries[series.name]) {
				drilldownSeries[series.name] = [];
			}
			drilldownSeries[series.name].push(id);
		});

		return {
			lastMonthIndex: lastValidMonthIndex,
			series: chartSeries
		};
	}

	initializeChart = async () => {
		const request = {
			profileId: this.props.config.budgetId,
			modelId: this.props.config.modelId,
			accountId: this.props.config.accountId,
			budgetItemIds: this.props.config.budgetItemIds,
			currency: this.props.config.currency
		};
		if(this.props.config.timeDisplay==='annual')
			request.year = this.props.config.timeDisplayValue;
		else
			request.historicMonths = this.props.config.timeDisplayValue;

		const response = await BudgetApi.getResourceCost(request);
		if(!response.data) {
			this.setState({noDataAvailable: true, loading: false, errorMessage: getCurrencyConversionErrorString(response.message)});
			return;
		}

		this.chartData = response.data.items;
		const preliminaryMonths = new Set();
		const chart = {
			categories: [],
			series: [],
		}
		let lastValidMonthIndex = 0;
		const barColorFn = getCostBarChartColor(this.props.config.barColor);
		this.drilldownSeries.push({});
		processApiResponse(response.data.items, dataRow => {
			const { lastMonthIndex, series }  = this.processDataRow(dataRow, barColorFn, response.data, preliminaryMonths);
			lastValidMonthIndex = Math.max(lastMonthIndex, lastValidMonthIndex);
			chart.series.push(...series);
		}, (monthNumber, monthName) => {
			chart.categories.push(monthName);
			Array.from(preliminaryMonths).filter(x => x.number == monthNumber).forEach(x => x.name = monthName);
		});
		this.drilldownLastValidIndex.push(lastValidMonthIndex);
		//getting rid of future months
		chart.categories = chart.categories.slice(0, lastValidMonthIndex);
		chart.series.forEach(x => x.data.splice(lastValidMonthIndex, x.data.length));
		const currentMonth = (new Date()).toLocaleString(Cookies.CeesoftUserLocale, {month: 'long'}) + ' ' + (new Date()).getFullYear();

		//setting * for preliminary months
		preliminaryMonths.forEach(x => {
			const prelimenaryCategoryIndex = chart.categories.findIndex(y => y == x.name)
			if(prelimenaryCategoryIndex >= 0 && (!this.props.config.showEstimatedCost || chart.categories[prelimenaryCategoryIndex] != currentMonth))
				chart.categories[prelimenaryCategoryIndex] = chart.categories[prelimenaryCategoryIndex] + '(*)';
		});

		//setting est for cost estimation
		if (this.props.config.showEstimatedCost) {
			const currentMonthIndex = chart.categories.indexOf(currentMonth);
			chart.categories[currentMonthIndex] = chart.categories[currentMonthIndex] + ' (est)';
		}

		if (this.props.config.monthOrder === 'Desc') {
			chart.categories.reverse();
			chart.series.forEach(x => x.data.reverse());
		}

		this.addTrend(chart.categories, chart.series);
		this.addTreshold(chart.series, response, lastValidMonthIndex);

		this.setState({
			chart,
			loading: false,
		}, this.drawChart);
	}

	drawChart = () => {
		var component = this;

		var decimalsNumber = this.props.config.decimalsNumber;
		var displayAsThousands = this.props.config.displayAsThousands;
		const chartType = this.props.config.orientation == Orientation.Horizontal
			? 'bar'
			: 'column';

		let chartSeries = this.state.chart.series;
		let differentNameSeries = [];
		chartSeries.forEach(item => {
			if (differentNameSeries.indexOf(item.name) === -1) {
				differentNameSeries.push(item.name);
			}
		});
		if (differentNameSeries.length > 5) {
			chartSeries.push({
				name: 'All'
			});
		}

		this.chart = new Highcharts.Chart({
			chart: {
				type: chartType,
				renderTo: this.chartContainerRef.current,
				events: {
					drilldown: throttle(function (e) {
						component.onDrilldown(e, this).then(() => {
							component.recalculateRegressionAndTresholdLines(this);
							component.drilldownPoints.push(e.point);
						});
					}, 300, {trailing: false}),
					// invokes for every series item, but we need only one
					drillup: throttle(function(chart, e) {
						component.drilldownPoints.pop();
						component.drilldownSeries.pop();
						component.drilldownLastValidIndex.pop();
						this.series.forEach(x => {
							if (x.xAxis.categoriesBackup) {
								x.xAxis.categories = x.xAxis.categoriesBackup;
							}
							if (x.visible) {
								return;
							}
							x.setVisible(true, true);
						});
						component.recalculateRegressionAndTresholdLines(this);
					}, 500, {trailing: false})
				},
				backgroundColor: null
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			exporting: Configuration.highcharts.exporting,
			xAxis: {
				categories: this.state.chart.categories,
				labels: {
					style: {
					  color: component.props.config?.axisColor?.hex
					}
				  }
			},
			yAxis: {
				title: {
					text: i('Cost'),
					style: {color: component.props.config?.axisColor?.hex},
				},
				stackLabels: {
					formatter: function () {
						return formatNumber(this.total, decimalsNumber, displayAsThousands);
					},
					enabled: true,
					style: {
						fontWeight: 'bold',
						color:
							this.props.config?.numberColor?.hex ||
							(Highcharts.defaultOptions.title.style &&
								Highcharts.defaultOptions.title.style.color) ||
							'gray',
							textOutline: 'none'
					}
				},
				labels: {
					formatter: function (e) {
						return formatNumber(e.value, decimalsNumber, displayAsThousands);
					},
					style: {color: component.props.config?.axisColor?.hex},
				}
			},
			legend: {
				enabled: component.props.config.legendPosition !== 'NONE',
				align: 'right',
				verticalAlign: component.props.config.legendPosition === 'TOP' ? 'top' : 'bottom',
				backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'none',
				itemStyle: {
					color: component.props.config?.legendColor?.hex,
				},
				shadow: false,
				labelFormatter: function () {
					if (this.name === 'RegressionLineCost')
						return `${i('Cost')} - ${i('Trend')}`;
					if (this.name === 'RegressionLineBudget')
						return `${i('Budget')} - ${i('Trend')}`;
					if (this.name === 'Threshold') return `${i('Budget line')}`;
					if (this.name === 'All') return `<span class="chart-all-option">${i('All')}</span>`;

					const { legendObj } = this.userOptions;
					return `${legendObj?.budgetItemName}`;
				}
			},
			tooltip: {
				backgroundColor: 'white',
				borderWidth: 0,
				shadow:{
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				headerFormat: '<b>{point.x}</b><br/>',
				formatter: function (e) {
					if (this.series.userOptions.name === 'RegressionLineCost')
						return `${i('Cost')} - ${i('Trend')}: ${
							this.series.userOptions.regressionInfo
						}/mo`;
					if (this.series.userOptions.name === 'RegressionLineBudget')
						return `${i('Budget')} - ${i('Trend')}: ${
							this.series.userOptions.regressionInfo
						}/mo`;
					if (this.series.name === 'Threshold')
						return `${i('Budget line')}: ${formatNumber(
							this.point.y,
							decimalsNumber,
							displayAsThousands
						)}`;

					let suffix = component.props.config.showEstimatedCost && this.x.endsWith('(est)') && this.series.options.stack == 'cost'
						? `(${i('Estimated')})`
						: this.x.endsWith('(*)')
							? `(${i('Preliminary')})`
							: '';
					const { legendObj } = this.series.userOptions;
					let tooltip = `${legendObj?.budgetName}
						<br/>${i('Cost profile type')}: ${legendObj?.costProfileType}
						<br/>${i('Cost Profile Item')}: ${legendObj?.budgetItemName}
						<br/>${i('Value')}: ${formatNumber(
						this.point.y,
						decimalsNumber,
						displayAsThousands
					)} (${legendObj?.currency}) ${suffix}`;

					return tooltip;
				}
			},
			plotOptions: {
				bar: {
					pointWidth: 20,
					groupPadding: 0.001,
				},
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: !this.props.config.showOnlyTotalValue,
						formatter: function () {
							let formattedNumber = formatNumber(
								this.y,
								decimalsNumber,
								displayAsThousands
							);
							if (
								parseInt(formattedNumber) === 0 &&
								formattedNumber % 1 === 0
							) {
								return null;
							} else {
								return formattedNumber;
							}
						},
						style: {
							textOutline: 'none'
						}
					}
				},
				series: {
					stacking: 'normal',
					dataLabels: {
						enabled: !this.props.config.showOnlyTotalValue,
						formatter: function () {
							let formattedNumber = formatNumber(
								this.y,
								decimalsNumber,
								displayAsThousands
							);
							if (
								parseInt(formattedNumber) === 0 &&
								formattedNumber % 1 === 0
							) {
								return null;
							} else {
								return formattedNumber;
							}
						},
						style: {
							textOutline: 'none'
						}
					},
					events: {
						legendItemClick(e) {
							if (this.name === 'All') {
								//toggle all series visibility
								this.chart.series.forEach(item => {
									item.setVisible(!this.visible, false);
								});
								this.setVisible(this.visible, true);
								component.recalculateRegressionAndTresholdLines(this.chart);
								return false;
							} else {
								let legendItems = this.chart.legend.allItems;
								let visibleLegend = [];
								let allOptionPresent = false;
								legendItems.forEach(legend => {
									if (legend.name === 'All') {
										allOptionPresent = true;
									} else if (legend.visible) {
										visibleLegend.push(legend);
									}
								});

								if (allOptionPresent) {
									if (visibleLegend.length === 1 && this.visible) {
										//last visible series is toggled off, so all option should be also toggled off
										this.chart.series.forEach(item => {
											if (item.name === 'All') {
												item.setVisible(false, false);
											}
										});
									}
									if (!visibleLegend.length && !this.visible) {
										//a series is toggled on, so all option should be also toggled on
										this.chart.series.forEach(item => {
											if (item.name === 'All') {
												item.setVisible(true, false);
											}
										});
									}
								}

								component.recalculateRegressionAndTresholdLines(this.chart);
							}
						}
					}
				},
			},
			series: chartSeries,
			drilldown: {
				drillUpButton: {
					relativeTo: 'spacingBox',
					position: {
						align: 'left',
						y: 0,
						x: 0
					}
				},
				activeAxisLabelStyle: {
					color: `${component.props.config?.axisColor?.hex} !important`
				},
				activeDataLabelStyle: {
					color: `${component.props.config?.axisColor?.hex} !important`
				}
			},
			lang: {
				drillUpText: i('Back')
			}
		});

		//workaround to remove all option color. Seems like highcharts does not support that out of the box
		$(this.chartContainerRef.current).find('.chart-all-option').closest('g').find('rect').remove();
	}

	onDrilldown = (e, chart) => {
		const barColorFn = getCostBarChartColor(this.props.config.barColor);
		const request = {
			profileId: this.props.config.budgetId,
			modelId: this.props.config.modelId,
			accountId: this.props.config.accountId,
			budgetItemIds: e.point.budgetItemIds ? e.point.budgetItemIds : this.props.config.budgetItemIds,
			currency: this.props.config.currency,
			drillDownId: e.point.id,
			drillDownLimit: this.props.config.drillDownLimit,
			drillDownOption: this.props.config.drillDownOption
		};

		if (this.props.config.timeDisplay === 'annual')
			request.year = this.props.config.timeDisplayValue;
		else
			request.historicMonths = this.props.config.timeDisplayValue;

		const responsePromise = BudgetApi.getResourceCost(request);
		return responsePromise.then((response) => {
			let lastValidMonthIndex = 0;
			const series = [];
			this.drilldownSeries.push({});
			const munths = [];
			processApiResponse(
				response.data.items,
				(dataRow) => {
					const { lastMonthIndex, series: newSeries } = this.processDataRow(
						dataRow,
						barColorFn,
						response.data
					);
					series.push(...newSeries);
					lastValidMonthIndex = Math.max(lastMonthIndex, lastValidMonthIndex);
				},
				(monthNumber, monthName) => {
					munths.push({number: monthNumber, name: monthName});
				}
			);

			this.drilldownLastValidIndex.push(lastValidMonthIndex);

			series.forEach(x => {
				x.data = x.data.slice(0, lastValidMonthIndex);
				if (this.props.config.monthOrder === 'Desc') {
					x.data.reverse();
				}
			});

			this.addTrend(chart.series[0].xAxis.categories, series, e.point.id);
			this.addTreshold(series, response, lastValidMonthIndex, e.point.id);
			series.forEach((x) => {
				chart.addSingleSeriesAsDrilldown(e.point, x)
			});
			chart.series.forEach(s => {
				if (s.xData.length >= s.xAxis.categories.length) {
					return;
				}
				if (s.xAxis.categoriesBackup) {
					s.xAxis.categories = s.xAxis.categoriesBackup;
				} else {
					s.xAxis.categoriesBackup = s.xAxis.categories;
				}
				if (this.props.config.monthOrder === 'Desc') {
					s.xAxis.categories = s.xAxis.categories.slice(s.xAxis.categories.length - lastValidMonthIndex, s.xAxis.categories.length);
				} else {
					s.xAxis.categories = s.xAxis.categories.slice(0, lastValidMonthIndex);
				}
				if (s.xAxis.categories.length > s.xAxis.categoriesBackup) {
					[...s.xAxis.categories].forEach((cat, catIndex) => {
						const index = month.findIndex(x => x.number == +cat);
						if (index>= 0) {
							s.xAxis.categories[catIndex] = month[index].name;
						}
					});
				}
			});
			chart.applyDrilldown();
		});
	}

	addTreshold = (series, response, lastValidMonthIndex, dirilldownPointId = undefined) => {
		if (!this.props.config.showTreshold) {
			return;
		}
		let tresholdData = response.data.months.slice(0, lastValidMonthIndex).map(x => x.budget);
		if (this.props.config.monthOrder === 'Desc')
			tresholdData.reverse();

		series.push({
			zIndex: 0,
			name: 'Threshold',
			color: '#ff0022',
			type: 'line',
			data: tresholdData,
			showInLegend: true,
			enableMouseTracking: true,
			dirilldownPointId: dirilldownPointId
		});
	}


	addTrend = (categories, series, dirilldownPointId = undefined) => {
		if(!this.props.config.showRegression) {
			return;
		}
		if(this.props.config.showCost){
			const result = this.calculateRegressionDataFor(categories, series, 'cost');
			let data = result.points;
			if (this.props.config.monthOrder === 'Desc')
				data.reverse();

			series.push({
				zIndex: 0,
				name: 'RegressionLineCost',
				color: 'rgba(255,165,0, 1)',
				type: 'line',
				data: data,
				showInLegend: true,
				marker: {
					enabled: false
				},
				enableMouseTracking: true,
				regressionInfo: result.equation[0],
				dirilldownPointId: dirilldownPointId
			});
		}
		if(this.props.config.showBudget){
			const result = this.calculateRegressionDataFor(categories, series, 'budget');
			let data = result.points;
			if (this.props.config.monthOrder === 'Desc')
				data.reverse();

			series.push({
				zIndex: 0,
				name: 'RegressionLineBudget',
				color: 'rgba(255,165,0, 1)',
				type: 'line',
				data: data,
				showInLegend: true,
				marker: {
					enabled: false
				},
				enableMouseTracking: true,
				regressionInfo: result.equation[0],
				dirilldownPointId: dirilldownPointId
			});
		}
	}

	onResize = () => {
		setTimeout(() => {
			this.chart?.setSize();
		},0)
	}

	calculateRegressionDataFor = (categories, series, stack) => {
		const regressionData = categories.map((currElement, index)=>{
			return [index, series.reduce((t, n) => {
				if(n.stack === stack && index < n.data.length){
					return t + n.data[index].y ?? n.data[index] ?? 0;
				}
				else
					return t;
			}, 0)]
		});
		return regression.linear(regressionData);
	}

	recalculateRegressionAndTresholdLines = (chart) => {
		//recalculation occures when legend items are being toggled, we need this timeout logic to make sure that we are recalculating trend line after all internal
		//processes finished in highcharts, this would give us clean data collection from highchart
		const drilldownPointId = this.drilldownPoints.length
			? last(this.drilldownPoints).id
			: undefined;
		const lastValidMonthIndex = last(this.drilldownLastValidIndex);
		setTimeout(async () => {
			let response = {};
			if (this.props.config.showTreshold) {
				let budgetItemIds = [];
				const drilldownSeries = last(this.drilldownSeries);

				chart.series.forEach(s => {
					if(!s.visible) {
						return;
					}
					const ids = drilldownSeries[s.name];
					if (ids) {
						budgetItemIds = [...budgetItemIds, ...ids];
					}
				});
				response = await BudgetApi.getResourceCost({
					profileId: this.props.config.budgetId,
					modelId: this.props.config.modelId,
					accountId: this.props.config.accountId,
					budgetItemIds: uniq(budgetItemIds),
					currency: this.props.config.currency,
					drillDownId: drilldownPointId
				});
			}

			if (this.props.config.showTreshold) {
				const currentIndex = chart.series.findIndex(x => x.name == 'Threshold' && x.options.drilldownPointId == drilldownPointId);
				const data = response.data.months?.slice(0, lastValidMonthIndex).map(x => x.budget);
				if (this.props.config.monthOrder === 'Desc')
					data.reverse();

				chart.series[currentIndex].setData(data);
			}
			if(!this.props.config.showRegression) {
				return;
			}
			if(this.props.config.showCost) {
				const costRegression = this.recalculateRegressionData(chart, 'cost');
				const currentIndex = chart.series.findIndex(x => x.name == 'RegressionLineCost' && x.options.drilldownPointId == drilldownPointId);
				const data = costRegression.points.slice(0, lastValidMonthIndex)
				if (this.props.config.monthOrder === 'Desc')
					data.reverse();

				chart.series[currentIndex].setData(data);
			}
			if(this.props.config.showBudget) {
				const budgetRegression = this.recalculateRegressionData(chart, 'budget');
				const currentIndex = chart.series.findIndex(x => x.name == 'RegressionLineBudget' && x.options.drilldownPointId == drilldownPointId);
				const data = budgetRegression.points.slice(0, lastValidMonthIndex)
				if (this.props.config.monthOrder === 'Desc')
					data.reverse();

				chart.series[currentIndex].setData(data);
			}
		}, 0);
	}

	recalculateRegressionData = (chart, stack) => {
		const regressionData = [];
		for(let i = 0; i < chart.xAxis[0]?.categories.length; i++){
			let t = 0;
			chart.series.forEach( n => {
				if(n.userOptions?.stack === stack && n.visible && n.name !== 'RegressionLineBudget' && n.name !== 'RegressionLineCost'){
					t = t + (n.yData[i] ?? 0)
				}
			})
			if(t >= 0)
				regressionData.push([i, t]);
		}
		return regression.linear(regressionData);
	}

	/**
	 * Subscribes to  metrics events
	 */
	subscribe = (callback) => {
		const timeSelection = getTimeSelection(this.props.config.timeDisplay, this.props.config.timeDisplayValue);
		this.subscription = RemoteEventsManager.subscribeCallback([
				CostBudgetEvent.subscriptionByBudget(this.props.config.budgetId, this.props.config.budgetItemIds),
				CostReportEvent.subscription(this.props.config.modelId, timeSelection)
		],
		throttle(callback, throttleLimit, {trailing: false}))
	}
}
