import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridPlugin} from "controls/grid/gridConfiguration"
import {GridStore} from "controls/grid/gridStore"
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition"
import {makeAutoObservable} from "mobx"

type FiltersOverridePluginConfig<T extends object, DataItem extends GridDataEntry> = {
	dataObject: T
	enabled?: boolean
	fields: ([keyof T,string]|keyof T)[]
	customPayloadDefaults?: Record<string, any>
	getFilters?: (filters: RuleDefinition) => void
	getSorting?: () => {[TField in keyof DataItem]?: 'asc'|'desc'}
}

export class StateOverridePlugin<T extends object, DataItem extends GridDataEntry> implements GridPlugin<DataItem> {
	gridStore: GridStore<DataItem>
	config: FiltersOverridePluginConfig<T, DataItem>

	constructor(config: FiltersOverridePluginConfig<T, DataItem>) {
		this.config = config
		makeAutoObservable(this)
	}

	async attach(store: GridStore<DataItem>) {
		this.gridStore = store
	}

	afterStoreInitialized(){
		this.overrideFilters()
	}

	overrideFilters = () => {
		if (this.config.enabled === false)
			return

		const filters: RuleDefinition = RuleDefinition.emptyGroup()

		const availableFilters = Object.keys(this.gridStore.dataProvider.filtersConfiguration);
		for (let fieldsEntry of this.config.fields) {
			let propertyName: keyof T = null
			let gridFieldName: string = null

			if (Array.isArray(fieldsEntry)) {
				propertyName = fieldsEntry[0]
				gridFieldName = fieldsEntry[1]
			} else {
				propertyName = fieldsEntry
				gridFieldName = fieldsEntry as string
			}

			const value = this.config.dataObject[propertyName]
			if (!value || !availableFilters.includes(propertyName.toString()))
				continue

			const rule = RuleDefinition.emptyRule(gridFieldName, "like")

			if (Array.isArray(value)) {
				if (value.length == 0)
					continue

				rule.properties.value = value
				rule.properties.operator = 'multiselect_equals'
			} else {
				rule.properties.value[0] = value?.toString()
			}

			filters.addOrUpdateRule(rule)
		}

		this.config.getFilters?.(filters)

		if (filters.order.length > 0 || this.config.enabled === true) {
			this.gridStore.state.switchToTempView()
			this.gridStore.state.filters = filters
			this.gridStore.state.searchString = ''

			const customPayload = this.config.customPayloadDefaults ?? this.gridStore.config.defaults?.payload

			if (customPayload) {
				this.gridStore.state.customPayload = JSON.parse(JSON.stringify(customPayload))
			}
		}

		if (this.config.getSorting) {
			let sorting = this.config.getSorting()
			if(sorting) {
				this.gridStore.state.clearSorting()
				for (const [field, direction] of Object.entries(sorting)) {
					const column = this.gridStore.state.columns.find(x => x.field == field)
					if (!column) {
						continue
					}

					this.gridStore.state.sortingOrder.push(field)
					column.sorting = direction
				}
			}
		}
	}

	destroy(){

	}
}



