import {sharedLocalization} from "./shared";
import {sharedLocalization as widgetsSharedLocalization} from "controls/designer/features/widgets/localization";

const i = require('core/localization').translator(sharedLocalization, widgetsSharedLocalization);

export let CostGridColumns = {
	get() {
		return [{
			field: 'severity',
			title: i('Severity'),
			shown: true
		}, {
			field: 'budgetSeverity',
			title: i('Budget Severity'),
			shown: true
		}, {
			field: 'resourceName',
			title: i('Resource'),
			shown: true
		}, {
			field: 'assetName',
			title: i('Asset'),
			shown: true
		}, {
			field: 'cloudTypeText',
			title: i('Cloud Provider'),
			shown: true
		}, {
			field: 'subscription',
			title: i('Subscription'),
			shown: true
		}, {
			field: 'monthToDate',
			title: i('Month to date'),
			shown: true
		}, {
			field: 'monthlyEstimate',
			title: i('Monthly Estimate'),
			shown: true
		}, {
			field: 'monthlyBudget',
			title: i('Monthly Budget'),
			shown: true
		}, {
			field: 'yearToDate',
			title: i('Year to date'),
			shown: true
		}, {
			field: 'yearlyEstimate',
			title: i('Yearly estimate'),
			shown: true
		}, {
			field: 'cloudBudget',
			title: i('Cloud Budget'),
			shown: true
		}, {
			field: 'spent',
			title: i('Spent'),
			shown: true
		}, {
			field: 'spentPercent',
			title: i('Spent %'),
			shown: true
		}]
	}
};

export default CostGridColumns;
