import {ConfigProvider} from "antd";
import React, { ReactElement } from "react";
import {observer} from "mobx-react";
import {ApplicationState} from "framework/applicationState";
import {CssVariables} from "styles/cssVariables";
import ReactDOM, { Root } from "react-dom/client";
import ReactDOMOld from 'react-dom';

export type AntConfigProviderProps = {
	children: React.ReactNode;
	theme?: any
}

export const AntConfigProvider = observer((props: AntConfigProviderProps) => {
	//const theme = React.useMemo(() => {
		const theme = {
			token: {
				fontFamily: 'Oxygen, Arial, sans-serif',
				fontSize: 12,
				lineHeight: 1.5,
				colorError: CssVariables.severityCritical,
				colorSuccess: CssVariables.severityNone,
				colorWarning: CssVariables.severityMajor,
			},
			components: {
				Tabs: {
					horizontalMargin: '0 0 6px 0'
				}
			},
			cssVar: true,
			hashed: false
		};

		if (props.theme) {
			const {token, ...others} = props.theme;
			theme.token = Object.assign(theme.token, token);
			Object.assign(theme, others);
		}
		//return defaultTheme;
	//}, [props.theme])

	return <ConfigProvider theme={theme}>
		{props.children}
	</ConfigProvider>
})

export function wrapWithAntdConfigProvider(Component: ReactElement) {
	return <AntConfigProvider>
		{Component}
	</AntConfigProvider>
}


export function patchReactWithAntContext() {
	// react 18+
	const originalCreateRoot = ReactDOM.createRoot;

	ReactDOM.createRoot = (container: Element | DocumentFragment): Root => {
		const root = originalCreateRoot(container);
		const originalRender = root.render

		root.render = (element: ReactElement) => {
			originalRender.call(root, wrapWithAntdConfigProvider(element));
		};
		return root;
	};

	// react 17-
	const originalRender = ReactDOMOld.render;

	// @ts-ignore
	ReactDOMOld.render = (
		element: ReactElement,
		container: Element | DocumentFragment,
		callback?: () => void
	): void => {
		// Wrap the element with ConfigProvider
		originalRender(wrapWithAntdConfigProvider(element), container, callback);
	};
}
