export default {
	DATE: 'date',
	DATETIME: 'datetime',
	TIME: 'time',
	GREEN: '#7cc623',
	LIGHT_GREEN: '#c4de7e',
	BLUE: '#257CBA',
	YELLOW: '#f7e400',
	ORANGE: '#f1ae31',
	CYAN: '#00aeef',
	LIGHT_CYAN: '#6dcff6',
	MAGENTA: '#d06dab',
	LIGHT_MAGENTA: '#e3d4e6',
	BLACK: '#000000',
	PURPLE: '#684198',
	CUSTOM1: '#006990',
	CUSTOM2: '#DFCEB6',
	CUSTOM3: '#777999',
	CUSTOM4: '#999777',
	CUSTOM5: '#ef5500',
	CUSTOM6: '#5fd4f1',
	CUSTOM7: '#000000',
	CUSTOM8: '#c3c888'
};
