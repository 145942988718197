import Utils from 'tools/utils'
import TaskManager from 'tools/taskManager'


export let EventsToolbar = function (config) {
	const configData = {...config};
	if (!Array.isArray(config.subscriberId)) {
		configData.subscriberId = [config.subscriberId];
	}
	Utils.apply(this, configData);
	this._initComponent();
};

export default EventsToolbar;

EventsToolbar.prototype = {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the control is rendered
	 */
	renderTo: null,
	startFrom: 0,
	callBackFn: null,
	/**
	 * @cfg {Boolean} playing True if the events are not blocked, false otherwise. Default to true
	 */
	playing: true,
	/**
	 * Main init function
	 */
	_initComponent: function () {
		this._displayCounter = this.startFrom > 5 ? 5 : 1;
		this._currentCounter = this.startFrom * 1000 + this._displayCounter * 1000;
		this._eventsStack = [];
		var html = '<span class="cw_events_text k-input pointer" title="' + lang.grid.events.UPDATE_INTERVAL + '">' + this.startFrom + 's</span><input class="cw_search_box hide" type="number" min="0" oninput="validity.valid||(value=``)" />';
		html += '<div class="cw_button_toggle pointer cw_events_reset" title="' + lang.grid.events.LOAD + '"><span class="glyphicons repeat"></span></div>';
		if (this.playing) {
			html += '<div class="cw_events_play cw_button_toggle pointer" title="' + lang.grid.events.PAUSE + '">';
			html += '<span class="glyphicons pause"></span>';
		} else {
			html += '<div class="cw_events_play cw_button_toggle pointer" title="' + lang.grid.events.PLAY + '">';
			html += '<span class="glyphicons play"></span>';
		}
		html += '</div>';
		this.renderTo.addClass('cw_events_toolbar').html(html);
		if (!this.playing) {
			this.renderTo.find('.cw_events_text').css('color', '#aaa');
		}
		var playButton = this.renderTo.find('.cw_events_play');
		playButton.attr('title', this.playing ? lang.grid.events.PAUSE : lang.grid.events.PLAY);
		playButton.attr('data-playing', this.playing ? 'true' : 'false');
		this._attachListeners();
		this._taskManager = new TaskManager();
		if (this.startFrom > 0 && this.playing) {
			this._startTaskManager();
		}
	},
	_attachListeners: function () {
		this.renderTo.off().on('click', '.cw_events_text', $.proxy(this._onTextClick, this));
		this.renderTo.on('blur', 'input', $.proxy(this._onInputBlur, this));
		this.renderTo.on('keyup', 'input', $.proxy(this._onInputKeyUp, this));
		this.renderTo.on('click', '.cw_events_reset', $.proxy(this._onReset, this));
		this.renderTo.on('click', '.cw_events_play', $.proxy(this._onPlayPauseClick, this));
	},
	_startTaskManager: function () {
		this._task = {
			run: $.proxy(this._onInterval, this),
			interval: this._displayCounter * 1000,
			name: '5 sec'
		};
		this._taskManager.start(this._task);
	},
	_stopTaskManager: function () {
		this._taskManager.stopAll();
	},
	_onTextClick: function (e) {
		this.renderTo.find('.cw_events_text').addClass('hide');
		this.renderTo.find('input').removeClass('hide').val(this.startFrom).focus();
	},
	_onPlayPauseClick: function () {
		if (this.playing) {
			this._stopTaskManager();
			this.renderTo.find('.cw_events_text').css('color', '#aaa');

		} else {
			this._startTaskManager();
			this.renderTo.find('.cw_events_text').removeAttr('style');
		}
		this.playing = !this.playing;
		var playButton = this.renderTo.find('.cw_events_play');
		playButton.attr('title', this.playing ? lang.PAUSE_EVENTS : lang.PLAY_EVENTS);
		playButton.find('.glyphicons').removeClass().addClass('glyphicons').addClass(this.playing ? 'pause' : 'play');
	},
	_onInputKeyUp: function (e) {
		if (e.keyCode === 13) {
			$(e.currentTarget).trigger('blur');
		}
	},
	_onInputBlur: function (e) {
		var value = $(e.currentTarget).val() || this.startFrom;
		if (parseInt(value, 10) !== NaN) {
			value = parseInt(Math.round(value), 10);
			if (value > 9999) {
				value = 9999;
			}
			/*if (value < 5) {
			 value = 5;
			 }*/
			this.startFrom = value;
		} else {
			value = this.startFrom;
		}
		this._displayCounter = this.startFrom > 5 ? 5 : 1;
		this._currentCounter = this.startFrom * 1000 + this._displayCounter * 1000;
		this._stopTaskManager();
		if (value != 0 && this.playing) {
			this._startTaskManager();
		}
		this.renderTo.find('input').addClass('hide').val(value);
		this.renderTo.find('span').first().text(value + 's').removeClass('hide');

	},
	_updateInputText: function () {
		this.renderTo.find('span').first().text(Math.floor(this._currentCounter / 1000) + 's');
		//this.renderTo.find('input').val(Math.floor(this._currentCounter / 1000));
		var titleTemplate = kendo.template(lang.messages.DELAY_GRID);
		var title = titleTemplate({
			interval: this._currentCounter / 1000
		});
		this.renderTo.attr('title', title);
	},
	_onInterval: function () {
		this._currentCounter = this._currentCounter - this._displayCounter * 1000;
		if (this._currentCounter <= 0) {
			this._onReset();
		} else {
			this._updateInputText();
		}

	},
	_onReset: function (e) {
		this._currentCounter = this.startFrom * 1000;
		if (this._eventsStack.length || e) {
			if (this.callBackFn) {
				this.callBackFn(this._eventsStack);
			}
			this._eventsStack = [];
		}
		this._updateInputText();
	},
	addEvents: function (events) {
		if (events.length !== undefined && this.subscriberId?.length) {
			for (let event of events) {
				if (this.subscriberId.includes(event.wId)) {
					this._eventsStack.push(event);
				}
			}
		} else {
			this._eventsStack.push(events);
		}
		if (this.startFrom === 0 && this.callBackFn && this.playing) {
			if (!this._eventsStack.length) {
				return;
			}
			try {
				this.callBackFn(this._eventsStack);
			}catch(e){
				console.error(e, e.stack);
			}
			this._eventsStack = [];
		}
	},
	destroy: function () {
		this._taskManager.stopAll();
	}
};
