import './actionsColumn.less'

import {MenuOutlined} from '@ant-design/icons'
import {observer} from "mobx-react";
import React from "react";

import {AntMenu} from "controls/react/ant/antMenu";
import {GridDataEntry} from "controls/grid/gridDataEntry";
import {GridSelection} from "controls/grid/gridSelection";
import {GridStore} from "controls/grid/gridStore";
import {GridMenuPlugin} from "controls/grid/plugins/grid-menu/gridMenuPlugin";
import {GridDataItem} from "controls/grid/gridDataItem";

export type ActionsColumnsProps<DataEntry extends GridDataEntry> = {
	store: GridStore<DataEntry>
	item: GridDataItem<DataEntry>
}

const b = require('b_').with('grid-action-column')

export const ActionsColumn = observer(<DataItem extends GridDataEntry>(props: ActionsColumnsProps<DataItem>) => {
	const plugin = props.store.plugins
		.find(x => x.id == 'grid-menu') as GridMenuPlugin<DataItem>

	const gridSelection = new GridSelection(plugin.store)
	gridSelection.ids.push(props.item.id)

	const items = plugin.generateMenu(gridSelection)

	const rootItem = {
		key: 'root',
		popupClassName: b('menu-container'),
		icon: <MenuOutlined/>,
		children: items
	}

	return <AntMenu mode={"horizontal"}
	                onClick={item => plugin.onMenuItemClick(item, gridSelection)}
	                items={[rootItem]}/>
})
