import React from "react";

import {useSubscriptions} from "./api";
import {AntSelect} from "controls/react/ant/antSelect";


const i = require('core/localization').translator({
	'Select a subscription...':{}
})

export const SubscriptionsDropDown = props => {
	const {accountId, cloudType, monitorId, hideAll, ...others} = props;
	const subscriptions = useSubscriptions(accountId, cloudType, monitorId, hideAll);

	return (
		<AntSelect placeholder={i('Select a subscription...')}
		           mode={"multiple"}
		           dataList={subscriptions}
		           nameField={"text"}
		           valueField={"value"}
		           disabled={!monitorId}
		           {...others}
		/>
	);
}
