import classnames from 'classnames';
import React from "react";

export function getSlaState(slaHelper, reactResult, isFromServiceGrid) {
	let slaIndicator;
	let html = '';
	let tooltip = '';
	let classArray = ['glyphicons', 'sla_status_icon', 'pointer'];
	switch (typeof slaHelper) {
		case 'object':
			if (slaHelper && slaHelper.total) {
				slaIndicator = slaHelper.indicator;
			}
			break;
		case 'number':
		case 'string':
			slaIndicator = slaHelper;
			break;
		case 'boolean':
			slaIndicator = slaHelper ? 'OK' : 'FAILED'
			break;
	}
	switch (slaIndicator) {
		case 'FAILED':
		case 3:
			classArray.push('circle-arrow-down');
			tooltip = lang.BREACHED;
			break;
		case 'WARNING':
		case 2:
			classArray.push('circle-arrow-right');
			tooltip = lang.WARNING;
			break;
		case 'OK':
		case 1:
			classArray.push('circle-arrow-top');
			tooltip = lang.COMPLIANT;
			break;
		default:
			classArray = ['cw_status_indicator', 'cw_status_widget_color', 'cw_color6'];
			if (isFromServiceGrid) {
				classArray.push('sla_no_state_status_icon');
			}
	}
	let classes = classnames(classArray);
	html = `<span class="${classes}" title="${tooltip}">`;

	if (reactResult) {
		return <span>
			<span className={classes} title={tooltip}></span>
		</span>
	} else {
		return html;
	}
}
