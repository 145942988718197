import './userTags.less';
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {UserTagsContext, UserTagsStore} from './userTagsStore';
import {QuestionCircleOutlined, TagsOutlined} from '@ant-design/icons';
import {Toolbar} from 'controls/react/layout/toolbar';
import {FormEntry} from 'controls/react/form';
import {Section} from 'controls/react/layout/section';
import {AntButton} from 'controls/react/ant/antButton';
import {translator} from 'core/localization';
import {AntSwitch} from 'controls/react/ant/antSwitch';
import {AntPopover} from 'controls/react/ant/antPopover';
import {AntAlert} from 'controls/react/ant/antAlert';
import {AntCheckbox} from 'controls/react/ant/antCheckbox';
import {AntSelect} from 'controls/react/ant/antSelect';
import {ApplicationState} from "framework/applicationState";

const i = translator({
  "ON": {
    "no": "ON"
  },
  "OFF": {
    "no": "OFF"
  },
  "MODAL_INFO_ROW": {
    "en": "Tags are used to filter content in Ceeview.\r\nUser tags are set per user and is controlled by that user.\r\nTeam tags are set per team. If user has checked Force Team Tags the tags will be applied on login.\r\nUser can’t change Tags from this form. It is changed from Team or by removing Force Team tags.",
    "no": "Etiketter brukes til å filtrere innholdet i Ceeview.\r\nBrukeretiketter settes per bruker og kontrolleres av brukeren.\r\nTeametiketter settes per Team. Hvis brukeren har Påtving Teametiketter valgt, vil de bli brukt ved pålogging. Brukeren kan ikke endre Teametiketter fra dette vinduet. Må gjøres via teams eller ved å fjerne Påtving Teametiketter."
  },
  "TEAM_ALERT": {
    "en": "Tags are inherited from user`s teams and override user own tags.",
    "no": "Etiketter arves fra brukerens team og overstyrer bruker's etiketter."
  },
  "Include Untagged": {
    "no": "Vis uten etikett",
    "en": "Include untagged"
  },
  "Tags": {
    "no": "Etiketter"
  },
  "Team Tags": {
    "no": "Team etikett",
    "en": "Team tags"
  }
});

const b = require('b_').with('user-tags');

export const UserTags = observer(() => {
	const [store] = React.useState(() => new UserTagsStore())

	return <UserTagsContext.Provider value={store}>
		<AntPopover content={<PopoverContent/>} trigger={"click"} onOpenChange={store.onOpenChange} open={store.open}>
			<div className={b('icon-wrapper')} title={i('Tags')}>
				<TagsOutlined className={b('icon', {active: ApplicationState.tagsEnabled})}/>
			</div>
		</AntPopover>

		</UserTagsContext.Provider>
});

const PopoverContent = observer(() => {
	const context = React.useContext(UserTagsContext)

	return <Section contentPadding={false} width={300}>
		<Toolbar title={context.mode == 'user' ? i('Tags') : i('Team Tags')}>
			{context.mode == 'user' && <AntSwitch checkedChildren={i('ON')} unCheckedChildren={i('OFF')} value={context.active} size="small" onChange={context.changeActive} />}
			<AntPopover content={<PopupInfoText />} overlayStyle={{zIndex: 99999999}} placement="bottom">
				<QuestionCircleOutlined />
			</AntPopover>
		</Toolbar>
		<Section direction={'column'} childrenPadding={true}>
			{context.mode == 'user' && context.model && <>
				<UserTagsContent />

				<Toolbar appearance="transparent">
					<AntButton type={"primary"} onClick={context.onOk}>{i('Update')}</AntButton>
					<AntButton onClick={context.onCancel}>{i('Cancel')}</AntButton>
				</Toolbar>
			</>}

			{context.mode == 'team' && context.model && <TeamTagsContent />}
		</Section>
	</Section>
})

const PopupInfoText = () => {
	return <Section>{i('MODAL_INFO_ROW').split('\r\n').map((x: string, index: number) => <span key={index}>{x}</span>)}</Section>
}

const UserTagsContent = observer(() => {
	const context = React.useContext(UserTagsContext)

	return <Section direction="column" appearance="none" childrenPadding={true}>
		<FormEntry>
			<AntSelect
				options={context.tagList}
				disabled={!context.active}
				mode="multiple"
				value={context.model.tags}
				onChange={context.onUserTagsChanged}
				filterOption={(input: string, option: {label: string, value: string}) => {
						return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
				  }}></AntSelect>
		</FormEntry>
		<FormEntry>
			<AntCheckbox disabled={!context.active} value={context.model.showUntagged} onChange={context.onChangeUntagged}>{i('Include Untagged')}</AntCheckbox>
		</FormEntry>
	</Section>
})

const TeamTagsContent = observer(() => {
	return <AntAlert message={i('TEAM_ALERT')} type="info" />
})
