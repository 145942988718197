import {DataListEntry} from "framework/entities/dataList";
import {translator} from "core/localization";
import {AntDefaultOptionType} from "controls/react/ant/antSelect";

const i = translator({
	'Equal': {
		no: 'Lik'
	},
	'Not equal': {
		no: 'Ikke lik'
	},
	'Less than': {
		no: 'Mindre enn'
	},
	'Greater than': {
		no: 'Større enn'
	},
	'Greater or equal' : {
		no: 'Større eller lik'
	},
	'Less or equal': {
		no: 'Mindre eller lik'
	}
})

export enum NumericOperator{
	Gt = 'GT',
	Lt = 'LT',
	Ne = 'NE',
	Eq = 'EQ',
	Le = 'LE',
	Ge = 'GE'
}

let numericOperators: AntDefaultOptionType[]
export const getNumericOperators = () => {
	if (numericOperators == null) {
		numericOperators = [
			{
				value: "GT",
				label: i('Greater than')
			},
			{
				value: 'LT',
				label: i('Less than')
			},
			{
				value: 'NE',
				label: i('Not equal')
			},
			{
				value: 'EQ',
				label: i('Equal')
			},
			{
				value: 'LE',
				label: i('Less or equal')
			},
			{
				value: 'GE',
				label: i('Greater or equal')
			}]
	}
	return numericOperators;
}

export enum EqualityOperator{
	Ne = 'NE',
	Eq = 'EQ'
}

let equalityOperators: AntDefaultOptionType[]
export const getEqualityOperators = () => {
	if (equalityOperators == null) {
		equalityOperators = [
			{
				value: EqualityOperator.Ne,
				label: i('Not equal')
			},
			{
				value: EqualityOperator.Eq,
				label: i('Equal')
			},]
	}
	return equalityOperators;
}
