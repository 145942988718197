export {default as RemoteEventsManager} from './remoteEventsManager';
export {Cookies} from './cookies';
export {Application} from './application';
export {LocalEventsManager} from './localEventsManager';
export {ErrorHandler} from './errorHandler';
export {default as StaticVars} from './staticVars';
export {default as Lang} from './localization/lang'
export {default as translator, Localization} from './localization'
export {default as TempData} from './tempData';
export {default as RoutesTable} from './routesTable';
