import "./widgetInfoContainer.less"

import React from "react";

import {translator} from "core/localization/localization";
import {Window} from 'controls/react/kendoWrappers/window';
import {FormLayout} from 'controls/react/layout/formLayout';
import {Section} from 'controls/react/layout/section';

import FieldRow from 'controls/react/fieldRow';
import Lang from 'core/localization/lang'

let i = translator({
  "Metric information": {
    "no": "Metrikk informasjon",
    "en": "Metric information"
  },
  "Chart settings": {
    "no": "Graf innstillinger",
    "en": "Chart settings"
  }
});

export default class WidgetInfoContainer extends React.PureComponent {
	render() {
		let screenWidth = window.innerWidth;
		let coordinates = this.props.coordinates;
		let infoWindowHeight = 295;
		let infoWindowWidth = this.props.isAnyFlag ? 650 : 450;
		let infoWindowLeft;
		if (coordinates.left + infoWindowWidth < screenWidth) {
			infoWindowLeft = coordinates.left + 30;
		} else {
			infoWindowLeft = coordinates.left - infoWindowWidth - 10;
		}
		let chartSettings = this.props.chartSettings;
		return (
			<Window height={infoWindowHeight}
					width={infoWindowWidth}
					title={false}
					left={infoWindowLeft}
					top={coordinates.top - 30}
					onClose={() => this.onClose()}
					windowRef={this.windowRefSet}>
				<Section appearance={"none"}
				         contentPadding={true}
				         contentClass={"widget-info-container"}
				         direction={"row"}
				         childrenPadding={true}>
					<Section appearance={'frame'} title={i('Metric information')}>
						<div>
							{Object.keys(this.props.data).map(field =>
								this.props.data[field] && <FieldRow key={field} label={this.translateLabels(field)} content={this.props.data[field]}/>)}
						</div>
					</Section>

				</Section>
			</Window>
		)
	}

	windowRefSet = ref => {
		this.window = ref;
		//remove window header padding to save space
		let container = this.window.wrapper;
		let containerStyle = container.attr('style');
		containerStyle += 'padding-top: 0 !important;';
		container.attr('style', containerStyle);

		//adapt window height to dynamic container height to save space
		let kendoWindow = this.window;
		let actualContainerHeight = $(kendoWindow.wrapper.find('.form-layout-widget-info')).height() + 20;
		let coordinates = this.props.coordinates;
		let screenHeight = window.innerHeight;
		let infoWindowTop;
		if (coordinates.top + actualContainerHeight > screenHeight) {
			infoWindowTop = screenHeight - actualContainerHeight - 10;
		} else {
			infoWindowTop = coordinates.top - 30;
		}
		kendoWindow.setOptions({
			height: actualContainerHeight,
			position: {
				top: infoWindowTop,
				left: kendoWindow.options.position.left
			}
		});

		//remove links, no chance to click since it's shown only on hover
		container.find('.cw_link').removeClass('cw_link');
	}

	translateLabels(field) {
		let label;
		switch (field) {
			case 'accountName':
				label = lang.account.ACCOUNT_NAME;
				break;
			case 'agentName':
				label = lang.serviceBoard.AGENT_NAME;
				break;
			case 'algorithm':
				label = lang.designer.ALGORITHM;
				break;
			case 'assetName':
				label = lang.serviceBoard.ASSET_NAME;
				break;
			case 'breachValue':
				label = lang.widget.BREACH_VALUE;
				break;
			case 'categoryId':
				label = lang.serviceBoard.CATEGORY_ID;
				break;
			case 'categoryNode':
				label = lang.serviceBoard.CATEGORY_NODE;
				break;
			case 'categoryPath':
				label = lang.serviceBoard.CATEGORY_PATH;
				break;
			case 'categoryText':
				label = lang.CATEGORY;
				break;
			case 'dataType':
				label = lang.serviceBoard.METRIC_TYPE;
				break;
			case 'description':
				label = lang.DESCRIPTION;
				break;
			case 'instanceName':
				label = lang.serviceBoard.INSTANCE_NAME;
				break;
			case 'inverted':
				label = lang.widget.INVERT;
				break;
			case 'maxValue':
				label = lang.widget.MAX_VALUE;
				break;
			case 'minValue':
				label = lang.widget.MIN_VALUE;
				break;
			case 'metricId':
				label = lang.serviceBoard.METRIC_ID;
				break;
			case 'monitorName':
				label = lang.assethealth.MONITOR_NAME;
				break;
			case 'monitorTypeName':
				label = lang.assethealth.MONITOR_TYPE;
				break;
			case 'operator':
				label = lang.designer.OPERATOR;
				break;
			case 'profileText':
				label = lang.PROFILE;
				break;
			case 'registryIdentifier':
				label = lang.serviceBoard.REGISTRY_IDENTIFIER;
				break;
			case 'registryOwnerId':
				label = lang.serviceBoard.REGISTRY_OWNER_ID;
				break;
			case 'registryOwnerName':
				label = lang.serviceBoard.SOURCENAME;
				break;
			case 'registryType':
				label = lang.serviceBoard.REGISTRY_TYPE;
				break;
			case 'ruleTypeText':
				label = lang.kpi.RULE_TYPE;
				break;
			case 'sampleRateText':
				label = lang.kpi.SAMPLE_RATE;
				break;
			case 'threshold':
				label = lang.designer.THRESHOLD;
				break;
			case 'unitType':
				label = lang.serviceBoard.UNIT_TYPE;
				break;
			case 'unitTypeSymbol':
				label = lang.serviceBoard.UNIT_TYPE_SYMBOL;
				break;
		}
		return label || field;
	}

	componentDidUpdate() {

	}

	onClose = () => {

	}
}

