import {translator} from "core";

export const designerLocalizations = {
	"Datasource Type": {
		"no": "Datakilde Type"
	},
	'Select Datasource Type...': {

	},
	"Show Health Index": {
		"no": "Vis Helseindeks"
	},
	"Expanded": {
		"no": "Ekspandert"
	},
	"Selection": {
		"no": "Seleksjon"
	},
	"Set datasource as label": {
		"no": "Sett datakilde som navn"
	},
	"Monitorfilter": {
		"no": "Monitorfilter"
	},
	"Show Health Reasons": {
		"no": "Vis årsåker",
	},
	"Show Summary Information": {
		"no": "Vis sammendragsinformasjon"
	}
};
