import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import MultiToggle from 'controls/multiToggle';
import GridMenu from 'controls/gridMenu';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import aggregationTypeDataSource from './common/dataSources/aggregationTypeDataSource';
import { ConversionWindow } from "./common/conversionWindow";
import {Api} from "tools/api";
import {renderIntoLegacy} from "../../../controls/react/form/timePeriodSelector";
import {createRoot} from "react-dom/client";
import {GenericMetricsWidgetConfiguration} from "./genericMetricsWidgetConfiguration/widgetConfiguration";

export function GenericMetricsWidgetConfigurationLegacy(config) {
	Utils.apply(this, config);

	this._initComponent();
	return this;
};

export {GenericMetricsWidgetConfigurationLegacy as default};

jQuery.extend(GenericMetricsWidgetConfigurationLegacy.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the form is rendered
	 */
	renderTo: null,
	/*
	 * Init the component
	 * */
	_initComponent: async function () {
		await this.completeWidgetObj();
		this.selectedMetrics = {};

		if (!$('#widget_wrapper').length) {
			$('body').append('<div id="widget_wrapper" class="cw_designer_area"></div>');
		}
		this.widgetWrapper = $('#widget_wrapper');

		this._removeListeners();
		this._attachListeners();

		this._render();
		this._initKendoComponents();

		const requiredFields = ['#cw_account'];
		applyRequired(requiredFields);
	},

	async completeWidgetObj() {
		let data = this.widgetObj.configuration.metrics || [];
		let url = `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/metrics/registeredMetrics`;
		let result = await Api.fetchPost(url, data);

		this.dataSource = result

		let container = this.widgetConfigurationFormEl
		container.empty().html('<div id="cw_metrics_form"></div>');
		this.renderTo = $('#cw_metrics_form');
	},

	/*
	 * Renders the form
	 * */
	_render: function () {
		var widgetObj = this.widgetObj;
		this.metricSelectorRootId = Utils.guid();

		const html = `
			<fieldset>
			  <legend class="cw_widget_form_legend">${lang.CONFIGURATION}</legend>
			  <span class="cw_toggle_container glyphicons minus pointer"></span>
			  <div class="cw_configuration_placeholder pointer hide">${lang.widget.messages.MAXIMIZE_SETTINGS}</div>
			  <div class="cw_configuration_content">
			    <div class="cw_field">
			      <label class="cw_inline">${lang.TITLE}</label>
			      <input class="cw_inline" id="cw_title" value="${widgetObj.title || ''}"/>
			    </div>
			  </div>
			</fieldset>
			<fieldset>
			  <legend class="cw_widget_form_legend">${lang.widget.DISPLAY_SETTINGS}</legend>
			  <span class="cw_toggle_container glyphicons minus pointer"></span>
			  <div class="cw_configuration_placeholder pointer hide">${lang.widget.messages.MAXIMIZE_SETTINGS}</div>
			  <div class="cw_configuration_content widgets-wizard__form">
			    <div class="cw_field">
			      <label class="cw_inline">${lang.PERIOD}</label>
			      <div id="cw_period" class="cw_multi_toggle"></div>
			      <div class="cw_hide_time_selector">
			        <input type="checkbox" class="k-checkbox" id="cw_hide_time_selector">
			        <label for="cw_hide_time_selector" class="k-checkbox-label">${lang.widget.HIDE_TIME_SELECTOR}</label>
			      </div>
			    </div>
			    <div class="cw_field" id="cw_chart_type_container">
			      <label class="cw_inline">${lang.TYPE}</label>
			      <div class="cw_dropdown_container">
			        <input id="cw_chart_type" type="text" />
			      </div>
			    </div>
			    <div class="cw_field">
			      <label class="cw_inline">${lang.AGGREGATION_TYPE}</label>
			      <div class="cw_dropdown_container">
			        <input id="cw_aggregation_type" type="text" />
			      </div>
			    </div>
			    <div class="cw_field" style="width:50%">
			      <input type="checkbox" class="k-checkbox" id="cw_ignore_missing_data">
			      <label for="cw_ignore_missing_data" class="k-checkbox-label">${lang.serviceBoard.IGNORE_MISSING_DATA}</label>
			    </div>
			    <div id="cw_threshold_field" class="cw_field" style="width:50%">
			      <input type="checkbox" class="cw_show_threshold k-checkbox" id="cw_show_threshold" title="" checked></span>
			      <label for="cw_show_threshold" class="k-checkbox-label">${lang.SHOW_THRESHOLD}</label>
			    </div>
			    <div class="cw_field">
			      <input type="checkbox" class="k-checkbox" id="cw_show_regression">
			      <label for="cw_show_regression" class="k-checkbox-label">${lang.SHOW_REGRESSION}</label>
			    </div>
			    <div class="cw_field">
			      <label class="cw_inline">${lang.TIMEZONE}</label>
			      <div class="cw_dropdown_container">
			        <input id="cw_timezone" value="${widgetObj.configuration.timezone || Cookies.CeesoftTimezone}" />
			      </div>
			    </div>
			    <div class="cw_field">
			      <label class="cw_inline">Selector type:</label>
			      <ul id="cw_legend_type_toggle" class="cw_multi_toggle right"></ul>
			    </div>
			    <div id="cw_legend_container" class="cw_field hide">
			      <label class="cw_inline_full">${lang.LEGEND}</label>
			      <input id="cw_legend" />
			    </div>
			  </div>
			</fieldset>
			<div class="metrics-root" id="${this.metricSelectorRootId}"></div>
			`;

		this.renderTo.html(html);
		if (this.mode === 'new') {
			this.setMinimized();
		}
	},
	/**
	 * Removes listeners
	 */
	_removeListeners: function () {
		this.renderTo.off('click', '#cw_select_available_metrics');
		this.widgetWrapper.off('keyup', '#cw_available_metrics_search');
	},
	/**
	 * Attaches listeners
	 */
	_attachListeners: function () {
		this.renderTo.on('click', '.cw_toggle_container', $.proxy(this.onToggleConfiguration, this));
		this.renderTo.on('click', '.cw_configuration_placeholder', $.proxy(this.onToggleConfiguration, this));
	},
	setMinimized: function () {
		this.renderTo.find('.cw_configuration_content').addClass('hide');
		this.renderTo.find('.cw_configuration_placeholder').removeClass('hide');
		this.renderTo.find('.cw_toggle_container').each(function() {
			$(this).removeClass('minus').addClass('unchecked');
		});
	},
	/**
	 * Initialize Kendo components
	 */
	_initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		if (this.wasUpdate) {
			$('#cw_hide_time_selector').prop('checked', widgetObj.configuration.hideTimeSelector);
			$('#cw_ignore_missing_data').prop('checked', widgetObj.configuration.ignoreMissingData);
			$('#cw_show_regression').prop('checked', widgetObj.configuration.showRegression);
			$('#cw_show_threshold').prop('checked', widgetObj.configuration.showThreshold);
		}

		this.chartType = $('#cw_chart_type').kendoDropDownList({
			dataSource: [{
				text: lang.AREA_CHART,
				value: 'area'
			}, {
				text: lang.AREASPLINE_CHART,
				value: 'areaspline'
			}, {
				text: lang.LINE_CHART,
				value: 'line'
			}, {
				text: lang.RANGE_CHART,
				value: 'arearange'
			}, {
				text: lang.SPLINE_CHART,
				value: 'spline'
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.chartType || 'line'
		}).data('kendoDropDownList');

		this.aggregationType = $('#cw_aggregation_type').kendoDropDownList({
			dataSource: aggregationTypeDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.aggregationType || 'high'
		}).data('kendoDropDownList');

		if (typeof widgetObj.configuration.period == 'string') {
			widgetObj.configuration.period = {period: widgetObj.configuration.period};
		}

		const [timePeriod, timePeriodRoot] = renderIntoLegacy({
			container: document.getElementById("cw_period"),
			valueHolder: widgetObj.configuration.period,
			onChange: (v) => this.timePeriod = v,
			dynamicOnly: true
		});
		this.timePeriod = timePeriod;
		this.timePeriodRoot = timePeriodRoot;

		this.legendTypeToggle = new MultiToggle({
			id: 'cw_legend_type_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'line',
			type: 'label',
			items: [{
				id: 'cw_legend_type_legend',
				title: lang.LEGEND,
				label: lang.LEGEND,
				selected: false,
				value: 'legend',
				fn: this.onLegendTypeToggle
			}, {
				id: 'cw_legend_type_none',
				title: lang.NONE,
				label: lang.NONE,
				selected: true,
				value: 'none',
				fn: this.onLegendTypeToggle
			}/*{
				id: 'cw_legend_type_informational',
				title: lang.INFORMATIONAL,
				label: lang.INFORMATIONAL,
				selected: true,
				value: 'informational',
				fn: this.onLegendTypeToggle
			}*/]
		});

		$("#cw_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
			value: widgetObj.configuration.startDate
		});
		$("#cw_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
			value: widgetObj.configuration.endDate
		});

		if (widgetObj.configuration.startDate && widgetObj.configuration.endDate) {
			$('#cw_dates').removeClass('hide');
		}

		$('#cw_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			clearButton: false,
		});

		this.legendDataSource = [{
			name: '<Asset>',
			id: '<Asset>'
		}, {
			name: '<Name>',
			id: '<Name>'
		}, {
			name: '<Type>',
			id: '<Type>'
		}, {
			name: '<Category>',
			id: '<Category>'
		}, {
			name: '<Instance>',
			id: '<Instance>'
		}];

		this.informationalDataSource = [{
			name: '<Asset>',
			id: '<Name>'
		}, {
			name: '<Type>',
			id: '<Type>'
		}, {
			name: '<Category>',
			id: '<Category>'
		}, {
			name: '<Instance>',
			id: '<Instance>'
		}, {
			name: '<Period>',
			id: '<Period>'
		},{
			name: '<Timezone>',
			id: '<Timezone>'
		}];

		//this.renderInformationalMultiSelect();

		var dataSource = [];
		if (this.mode === 'update' || this.wasUpdate) {
			this.legendTypeToggle.setSelectedItem(widgetObj.configuration.legendType || 'none');
			if (widgetObj.configuration.legendType === 'legend') {
				$('#cw_legend_container').removeClass('hide');
				//$('#cw_informational_container').addClass('hide');
				if (!widgetObj.configuration.labelTemplate) {
					this.legendMultiList = [];
				} else {
					this.legendMultiList = widgetObj.configuration.labelTemplate;
				}
				this.informationalMultiList = this.informationalDataSource;
			} else {
				if (!widgetObj.configuration.informationalTemplate) {
					this.informationalMultiList = this.informationalDataSource;
				} else {
					this.informationalMultiList = widgetObj.configuration.informationalTemplate;
				}
				this.legendMultiList = [];
			}

			for (var i = 0, length = this.dataSource.length; i < length; i++) {
				this.selectedMetrics[this.dataSource[i].metricId] = this.dataSource[i];
			}
		}

		this.renderLegendMultiSelect();
		this.renderMetricsSelector();
		this.adjustSectionHeight();
	},

	renderMetricsSelector() {
		this.metricsSelectorRoot = createRoot(document.getElementById(this.metricSelectorRootId))
		this.metricsSelectorRoot.render(<GenericMetricsWidgetConfiguration
			config={this.widgetObj}
			metricsOnly={true}
			isExisting={true}
			hideThreshold={true}
			onChange={(values) => {
				this.values = values;
			}}/>);
	},

	renderLegendMultiSelect: function() {
		this.legendReactRoot = createRoot($('#cw_legend').parent().get()[0])

		this.legendReactRoot.render(<FormEntry label={lang.LEGEND} vertical={true}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},

	renderInformationalMultiSelect: function() {
		this.informationReactRoot = createRoot($('#cw_metrics_informational').parent().get()[0])

		this.informationReactRoot.render(<FormEntry label={lang.INFORMATIONAL} vertical={true}>
			<AntSelect
				dataList={this.informationalDataSource}
				mode={"multiple"}
				sortValues={true}
				onChange={value => this.onInformationalChange(value)}
				defaultValue={this.informationalMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>);
	},
	onInformationalChange: function(value) {
		this.informationalMultiList = value || [];
	},
	onToggleConfiguration: function (e) {
		var target = $(e.target);
		var currentFieldset = target.closest('fieldset');
		var currentToggleContainer = currentFieldset.find('.cw_toggle_container');
		currentFieldset.find('.cw_configuration_content').toggleClass('hide');
		currentFieldset.find('.cw_configuration_placeholder').toggleClass('hide');

		if (currentToggleContainer.hasClass('minus')) {
			currentToggleContainer.removeClass('minus').addClass('unchecked');
		} else {
			currentToggleContainer.removeClass('unchecked').addClass('minus');
		}
	},

	/**
	 * Handler function for the click event on legend type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onLegendTypeToggle: function (value) {
		if (value === 'legend') {
			$('#cw_legend_container').removeClass('hide');
			//$('#cw_informational_container').addClass('hide');
		} else {
			$('#cw_legend_container').addClass('hide');
			//$('#cw_informational_container').removeClass('hide');
		}
	},

	/*
	* Gets the form values
	* */
	getValues: function () {
		var title = $('#cw_title').val();
		var ignoreMissingData = $('#cw_ignore_missing_data').is(':checked'),
			showRegression = $('#cw_show_regression').is(':checked'),
			showThreshold = $('#cw_show_threshold').is(':checked');
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: this.type,
			title: title,
			configuration: {
				chartType: this.chartType.value(),
				aggregationType: this.aggregationType.value(),
				period: this.timePeriod,
				timezone: $('#cw_timezone').data('kendoComboBox').value(),
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				labelTemplate: this.legendMultiList,
				ignoreMissingData: ignoreMissingData,
				showRegression: showRegression,
				showThreshold: showThreshold,
				legendType: this.legendTypeToggle.getValue(),
				informationalTemplate: this.informationalMultiList
			}
		};

		const {configuration: {metrics, metricsItems}} = this.values;

		widgetObj.configuration.metricsItems = metricsItems
		widgetObj.configuration.metrics = metrics
		return widgetObj;
	},
	/**
	 * Validates the widget config form
	 * @return {Boolean} valid True if the form is valid, false otherwise
	 */
	isValid: function () {
		let valid = true;
		let validationMessage = '';

		const metrics = this.values.configuration.metrics;
		if (!metrics?.length) {
			valid = false;
			validationMessage = lang.widget.SELECT_METRICS;
		}

		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}

		return {valid, validationMessage};
	},
	/*
	* Called when resize ends
	*/
	resize: function () {
		this.adjustSectionHeight();
	},
	/*
	* Destroys the form
	* */
	destroy: function () {
		this.informationReactRoot?.unmount()
		this.legendReactRoot?.unmount()
		this.metricsSelectorRoot?.unmount()

		if (this.widgetWrapper.length) {
			this.widgetWrapper.remove();
		}
	}
});
