import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Utils} from "tools/utils";
import {Cookies} from "core";


class DateTimePicker extends React.PureComponent {
	ref = React.createRef();

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.kendoRef = $(this.ref.current).kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat || "EEE, dd MMM HH:mm:ss"),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat || "HH:mm:ss"),
			value: this.props.value,
			min: this.props.min,
			max: this.props.max,
			change: this.onChange
		}).data('kendoDateTimePicker');
		if (this.props.disabled) {
			this.kendoRef.enable(false)
		}
	}

	componentDidUpdate(prevProps) {
		if (this.kendoRef && prevProps.value !== this.props.value) {
			this.kendoRef.value(new Date(this.props.value));
		}

		if (this.kendoRef && prevProps.min !== this.props.min) {
			this.kendoRef.min(new Date(this.props.min));
		}

		this.kendoRef.enable(!this.props.disabled);
	}

	componentWillUnmount(){
		if(this.kendoRef){
			this.kendoRef.destroy();
		}
	}

	onChange = e => {
		this.props.onChange && this.props.onChange(e.sender.value().getTime(), e);
	}

	onAdd() {
		this.props.onAdd();
	}

	render() {
		const {containerClass} = this.props;

		return (
			<div className={classnames('date-time-picker', containerClass)}>
				<input ref={this.ref}/>
			</div>
		);
	}
}

export default DateTimePicker;

DateTimePicker.propTypes = {
	containerClass: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date)
	]),
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	max: PropTypes.instanceOf(Date),
	min: PropTypes.instanceOf(Date)
};
