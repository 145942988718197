import {translator} from "core/localization";

export const i18n = translator({
	"Too many metrics selected": {
		"en": "Ceeview only open 10 metrics at a time.",
		"no": "Ceeview åpner bare 10 metrikker om gangen."
	},
	"Data registry": {
		"no": "Data register",
	},
	"Graph": {
		"no": "Graf"
	},
	"Table": {
		"no": "Tabell"
	},
	"Get last sample time": {
		"no": "Hent siste tid",
		"en": "Get last sampletime"
	},
	"Merge": {
		"no": "Sammenslå"
	},
	"Ignore data": {
		"no": "Ignorer data"
	},
	"Persist data": {
		"no": "Persister data"
	},
	"Persist": {
		"no": "Persister"
	},
	"Category id": {
		"no": "Kategori id",
	},
	"Category node": {
		"no": "Kategori node",
	},
	"Category path": {
		"no": "Kategori sti",
	},
	"Instance name": {
		"no": "Instansnavn",
	},
	"Registry identifier": {
		"no": "Dataregister",
	},
	"Registry owner name": {
		"no": "Dataregister navn",
	},
	"Too many tables selected": {
		"en": "Ceeview only open 10 metrics at a time.",
		"no": "Ceeview åpner bare 10 metrikker om gangen."
	},
	"Last sample": {
		"no": "Sist verdi",
	},
	"Get time": {
		"no": "Hent tid",
	},
	"Max value": {
		"no": "Maks verdi",
	},
	"DELETE_CONFIRMATION": {
		en: "Delete confirmation",
		no: "Bekreft sletting"
	},
	"DELETE_CONFIRMATION_TEXT": {
		en: "Are you sure you want to delete {0} metrics?",
		no: "Vil du slette {0} metrikker?"
	},
	"Show selected metrics only": {
		"no": "Vis valgte metrikker",
		"en": "Show selected metrics"
	},
	"Categorize by": {
		"no": "Kategoriser"
	},
	"This widget supports maximum {0} metrics": {
		"no": "Denne widgeten støtter maks {0} metrikker"
	},
	"Servicequalifier name": {
		"no": "Tjenestepunkt navn",
		"en": "Servicequalifier name"
	},
	"Service name": {
		"no": "Tjeneste navn",
	},
	'Show Selected metrics': {
		no: 'Vis valgte metrikker'
	}
})
