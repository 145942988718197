import './textArea.less'

import React, {memo} from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';


let b = require('b_').with('c-textarea');

const TextArea = (props) => {
	let {value, onChange, onFocus, onBlur, invalid, containerClass, id, placeholder, style,height, rows, ...directProps} = props;
	value = value || '';

	return (
		<textarea className={classnames(b({invalid, height}), containerClass)}
		          value={value}
			 	  id={id}
		          style={style}
				  rows={rows}
		          placeholder={placeholder}
		          onChange={e => onChange && onChange(e.target.value, e)}
		          onBlur={e => onBlur && onBlur(e.target.value, e)}
		          onFocus={e => onFocus && onFocus(e.target.value, e)}
		          {...directProps}/>
	);
};

TextArea.propTypes = {
	value: PropTypes.string,
	invalid: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool,
	containerClass: PropTypes.string,
	rows: PropTypes.number,
	height: PropTypes.oneOf(['full']),
};

TextArea.defaultProps = {
	onChange: () => {}
};


let memoTextArea = memo(TextArea);
export {memoTextArea as default, memoTextArea as TextArea}
