import './antTooltip.less'

import React from 'react'
import {observer} from 'mobx-react'
import {Tooltip, TooltipProps} from 'antd'
import classnames from 'classnames'

export type AntTooltipProps = TooltipProps & {
	enabled?: boolean
}

const b = require('b_').with('ant-tooltip-wrapper')

export const AntTooltip = observer((props: AntTooltipProps) => {
	const {className, enabled, ...rest} = props

	// if(enabled === false){
	// 	return <div className={className}>{props.children}</div>
	// }

	return <Tooltip className={classnames(className, b())} {...rest}/>
})
