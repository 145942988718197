import {getSettings} from "../framework/userSettings"
import {makeAutoObservable} from "mobx"
import {createModelSchemaWrapper} from "../framework/serializr-integration";
import {primitive} from "serializr";

export enum LayoutMenuOrientation {
	Vertical = 'vertical',
	Horizontal = 'horizontal',
}
class MainNavigationUserSettings {
	pinned: boolean = true
	orientation: LayoutMenuOrientation = LayoutMenuOrientation.Vertical
	constructor() {
		makeAutoObservable(this)
	}
}

createModelSchemaWrapper(MainNavigationUserSettings, {
	pinned: primitive(),
	orientation: primitive()
})

export class LayoutStore {
	userSettings: MainNavigationUserSettings
	userSettingsLoaded: boolean

	constructor() {
		makeAutoObservable(this)
		this.init()
	}

	async init() {
		this.userSettings = await getSettings<MainNavigationUserSettings>('MainNavigation', {clazz: MainNavigationUserSettings})
		this.userSettingsLoaded = true
	}

	get loaded() {
		return this.userSettingsLoaded
	}

	get orientation() {
		return this.userSettings?.orientation || LayoutMenuOrientation.Vertical
	}

	set orientation(orientation: LayoutMenuOrientation) {
		if(this.userSettings) {
			this.userSettings.orientation = orientation
		}
	}

	get pinned() {
		return this.userSettings?.pinned ?? true
	}

	set pinned(value: boolean) {
		if(this.userSettings) {
			this.userSettings.pinned = value
		}
	}

	togglePinned = () => {
		this.pinned = !this.pinned
	}

	toggleOrientation = () => {
		this.orientation = this.orientation === LayoutMenuOrientation.Vertical ? LayoutMenuOrientation.Horizontal : LayoutMenuOrientation.Vertical
	}
}
