import {Cookies} from "core/cookies";
import {PreviewWidget} from "./previewWidget";
import {PreviewWidgetConfiguration} from './previewWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: PreviewWidgetConfiguration,
		defaultConfig: {
			type: 'service-preview',
			title: i('Preview'),
			configuration: {
				contentPadding: 10,
				accountId: Cookies.CeesoftCurrentAccountId,
				isDashboard: true
			}
		},
		constructWidget: config => new PreviewWidget(config),
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Service') + '/' + i('Preview'),
	}
}
