import React from 'react';
import ReactDOM from "react-dom";
import {Utils, Api} from "tools";
import {Settings} from "settings";
import {Cookies, LocalEventsManager, translator} from "core";
import {CustomNotification, ExpandableTextarea, Tags} from "controls";
import {AdministrationApi} from "areas/administration/api";
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";
import {TeamApi} from "./teams/api";

const i = translator();

export let TeamForm = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default TeamForm;

jQuery.extend(TeamForm.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: async function () {
		this.tagsDataSource = [];
		var actionButtonText = lang.CREATE;
		this.usersLoaded = false;
		this.dataLoaded = false;
		if (this.mode === 'update') {
			actionButtonText = lang.UPDATE;
		}

		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('#modal .cw_description')
		});

		$('#modal_save_team').text(actionButtonText);
		this.removeListeners();
		this.attachListeners();

		if (this.mode === 'update') {
			this.load();
		}
		else {
			var focus = function () {
				$('#modal #team_name').focus();
			};
			setTimeout(focus, 1000);
		}
		this.loadUsers();

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			style: 'top:30px;',
			type: 'icon',
			hideOnClick: true
		});
		this.renderTags();
		if (this.mode === 'create') {
			this.onTeamNameKeyup();
		}
	},
	renderTags() {
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical>
			<TagsSelect
				mode={'tags'}
				accountId={this.accountId || Cookies.CeesoftCurrentAccountId}
				includeSubaccounts={false}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList}
				placeholder={lang.messages.TAG_CHOOSE} />
		</FormEntry>, $('.cw_team_tags').parent().get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},
	/**
	 * Loads users into dropdown multi select control
	 */
	loadUsers: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/users/lite';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			var users = result;
			for (let user of users) {
				user.name = user.username;
			}
			ReactDOM.render(<FormEntry label={lang.SELECT_USERS} vertical>
				<AntSelect
					dataList={users}
					mode={"multiple"}
					sortValues={true}
					onChange={value => this.onUsersChange(value)}
					defaultValue={this.usersList}
					disabled={this.mode === 'view'}
					placeholder={lang.SELECT} />
			</FormEntry>,  $('#users_multi_select').parent().get()[0]);
			this.usersLoaded = true;
		}, this));
	},
	onUsersChange: function(value) {
		this.usersList = value;
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#modal_save_team').off();
		$('#modal_cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#modal_cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#modal_save_team').on('click', $.proxy(this.onSaveButton, this));
		$('#modal').find('#team_name').on('keyup', $.proxy(this.onTeamNameKeyup, this));
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: function () {
		const loadUrl = AdministrationApi.getTeamUrl(null, this.id, 'true');

		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var data = result.data;
				$('#modal #team_name').val(data.name);
				this.descriptionHandler.value(data.description);
				$('#modal #team_name').focus();
				this.dataLoaded = true;
				this.usersList = data.members;
				this.tagsList = data.tags;
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			//close window
			$('#modal_cancel').trigger('click');
			// show status
			var successText = (this.mode === 'create' ? lang.account.messages.TEAM_SUCCESS_CREATED : lang.account.messages.TEAM_SUCCESS_UPDATED);

			var config = {
				mode: 'create',
				text: successText,
				result: result
			};
			LocalEventsManager.trigger('teamSaved', config);
		}
		else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: async function (e) {
		let name = $('#modal #team_name').val();
		let nameExists = await Utils.checkIfNameExists(TeamApi.getSearchUrl(), name, this.modalNotification, true);
		if(nameExists) {
			return;
		}
		let url = '';
		this.data = {
			name: name.trim(),
			description: this.descriptionHandler.value().trim(),
			id: (this.id === 'new_team' ? null : this.id),
			members: this.usersList || [],
			tags: this.tagsList
		};
		if (this.isFromAccount) {
			if (this.accountId) {
				url = Settings.serverPath + 'accounts/' + this.accountId + '/teams/';
				this.data.accountName = this.accountName;
				Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onAccountNewTeam, this));
			} else {
				$('#modal_cancel').trigger('click');
				LocalEventsManager.trigger('teamCreated', this.data);
			}
        } else {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	onTeamNameKeyup() {
		let name = $('#modal #team_name').val().trim();
		Utils.setInvalidField(name, $('#modal #team_name'), true);
		Utils.setPlaceholder($('#modal #team_name'), i('Please input Name...'));
		if (name) {
			$('#modal_save_team').attr('disabled', false);
		} else {
			$('#modal_save_team').attr('disabled', true);
		}
	},
	onAccountNewTeam: function(result) {
		$('#modal_cancel').trigger('click');
		LocalEventsManager.trigger('teamCreated', {data: this.data, result: result});
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	}
});
