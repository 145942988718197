import './treeView.less';

import React from "react";
import PropTypes from "prop-types";


export default class TreeView extends React.PureComponent {
	constructor(props) {
		super(props);

		const config = props;

		this.state = {
			dataSource: config.dataSource || []
		};

		this.ref = React.createRef();
	}


	render() {
		return (
			<div ref={this.ref}></div>
		);
	}

	componentDidMount() {
		this.kendoTreeView = $(this.ref.current).kendoTreeView({
			dataSource: this.state.dataSource,
			select: (e) => {
				this.props.onSelect?.(this.getDataSourceItem(e.node));
			},
			expand: (e) => {
				// we should get item before collapsing sub nodes
				// because collapsing will change datasource and item will be undefined
				const item = this.getDataSourceItem(e.node)
				this.collapseSubNodes(e.node);
				this.props.onExpand?.(item);
			},
			collapse: (e) => {
				this.props.onCollapse?.(this.getDataSourceItem(e.node));
			},
			checkboxes: this.props.checkboxes,
			check: (e) => {
				this.props.onCheck?.(this.getDataSourceItem(e.node));
			},
			animation: false
		}).data("kendoTreeView");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.dataSource !== this.props.dataSource) {
			this.kendoTreeView.setDataSource(this.props.dataSource);
		}
	}

	search = callback => {
		return this.kendoTreeView.items().toArray()
			.reduce( (result, item) => {
				const dataItem = this.getDataSourceItem(item);
				if(callback(dataItem)){
					result.push(dataItem);
				}
				return result;
			}, []);
	}

	getDataSourceItem = htmlNode => {
		const dataItem = this.kendoTreeView.dataItem(htmlNode);
		if (!dataItem) {
			return undefined;
		}
		return {...dataItem.toJSON(), kendoTreeViewUid: dataItem.uid, hasChildren: dataItem.hasChildren, searchResult: dataItem.searchResult};
	}

	select(item, options){
		options = options || {};

		if(options.collapseEverything){
			this.kendoTreeView.collapse('li.k-first > ul > li');
		}

		let node = item instanceof jQuery
			? item
			: item instanceof HTMLElement
				? $(item)
				: this.kendoTreeView.findByUid(item.kendoTreeViewUid);

		this.kendoTreeView.select(node);

		let dataItem = this.kendoTreeView.dataItem(node);
		this.kendoTreeView.expandPath(this.kendoTreeView._parentIds(dataItem), () =>{
			if(options.scrollOnSelect){
				let container = this.kendoTreeView.element.closest('.section__content_scrollable');
				if( container.length) {
					container[0].scrollTop += node.offset().top - container.offset().top;
				}
			}
		});
	}

	collapseSubNodes = node => {
		//Bernts requirement to have all child nodes collapsed if parent is collapsed
		const elements = $('> ul > li[role="treeitem"]', node).toArray();

		elements.forEach(element => {
			this.kendoTreeView.collapse(element);
		});
	}

}

TreeView.propTypes = {
	dataSource: PropTypes.array.isRequired
};
