import React, {useState, useEffect, useCallback, useRef, useMemo} from "react";
import PropTypes from "prop-types";

import {translator} from "core/localization";
import {Section} from 'controls/react/layout/section';
import {Toolbar} from 'controls/react/layout/toolbar';
import {FormEntry} from "controls/react/form";
import {AssetGroupMultiSelect} from "controls/react/assetGroupMultiSelect";
import {MetricCategoryMultiSelect} from "./metricCategoryMultiSelect";
import {usePlainStore, useStore} from "./useStore";
import classnames from "classnames";
import {MetricCategoryWindow} from "./metricCategoryWindow";
import IconButton from "controls/react/form/iconButton";

const b = require('b_').with('selection_frame');

const i = translator({
  "Selection": {
    "no": "Seleksjon"
  },
  "Asset Group": {
    "no": "Ressursgruppe",
    "en": "Assetgroup"
  },
  "Metric Category": {
    "no": "metrikk kategori",
    "en": "Metric category"
  },
  "Metric categories popup tooltip": {
    "en": "Click to select assetgroup and metric category",
    "no": "Klikk for å velge ressursgruppe og metrikk kategori"
  }
});

SelectionFrame.propTypes = {

};

/**
 * @return {null}
 */
export function SelectionFrame(props) {
	const [type] = useStore(["type"]);
	const [accountId] = useStore(["configuration", "accountId"]);
	const [assetGroups, setAssetGroups] = usePlainStore(["configuration", "assetGroups"]);
	const [metricCategories, setMetricCategories] = usePlainStore(["configuration", "metricCategories"]);

	const [showWindow, setShowWindow] = useState();

	const openWindow = useCallback(() => {
		setShowWindow(true);
	});

	const onClose = useCallback(() => {
		setShowWindow(false);
	});

	const onAdd = useCallback((assetGroupIds, metricCategoryIds) => {
		setAssetGroups(assetGroupIds);
		setMetricCategories(metricCategoryIds);
		setShowWindow(false);
	});

	if (type !== 'multi_graph_assetgroup' && type !== 'metric-multi-graph-asset-group') {
		return null;
	}

	return (
		<Section appearance={'frame'} childrenPadding={true}>
			<Toolbar title={i('Selection')}>
				<span className={classnames("glyphicons show-thumbnails pointer", b('icon'))} onClick={openWindow} title={i('Metric categories popup tooltip')} />
			</Toolbar>
			<FormEntry label={i('Asset Group')} required={assetGroups == null || assetGroups.length == 0}>
				<AssetGroupMultiSelect value={assetGroups}
					accountId={accountId}
					onChange={setAssetGroups}
					options={{ filter: 'contains' }}/>
			</FormEntry>
			<FormEntry label={i('Metric Category')} required={metricCategories == null || metricCategories.length == 0}>
				<MetricCategoryMultiSelect value={metricCategories}
				                           accountId={accountId}
				                           onChange={setMetricCategories}
										   options={{filter: 'contains'}} />
			</FormEntry>
			{showWindow && <MetricCategoryWindow open={showWindow}  onClose={onClose} onAdd={onAdd} />}
		</Section>
	);
}
