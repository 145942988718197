import React from 'react';
import ReactDOM from 'react-dom';

import {ApplicationsApi} from '../api';
import Widget from 'areas/service-boards/widget';
import RemoteEventsManager from 'core/remoteEventsManager';
import ApplicationDesigner from "../application-designer/applicationDesigner";
import {translator} from 'core/localization/localization';
import {showEventsSummaryPopup} from "areas/summary/events/eventsSummaryPopup";
import {iterateParents} from "controls/designer/utils";
import {EntryType} from "areas/summary/events/eventsSummaryEntry";
import {createRoot} from "react-dom/client";
import {TimePeriodType} from "controls/react/form/timePeriodType";

const i = translator({
  "Application » {0}": {
    "no": "Applikasjon » {0}"
  },
  "The application is not found": {
    "no": "Fant ikke applikasjon",
    "en": "Application not found"
  }
});

export class ApplicationWidget extends Widget{
	applicationDesignerRef = React.createRef();
	sp = null;
	editorUi = null;
	serviceElementToCellMap = null;
	services = null;
	onZoom = false;

	async init() {
		var widgetDiv = $('#' + this.id);
		widgetDiv.off();

		this.graphContainer = $('.cw_section_content', widgetDiv);
		if(this.graphContainer.length == 0)
			return

		this.graphContainer.addClass("presentation");

		//2.7 quickfix for geEditor height
		let container = this.graphContainer.get(0);
		this.widgetRoot = createRoot(container)

		const result = await ApplicationsApi.detailsReadonly(this.configuration?.applicationId ?? this.applicationId ?? this.configuration?.servicePresentationId ?? this.servicePresentationId, this.sessionId);

		if(!result.success){
			this.showErrorMessage(i('The application is not found'));
			return;
		}

		this.setTitleIfEmpty(i("Application » {0}", result.data.name));

		this.widgetRoot.render(
			<ApplicationDesigner application={result.data}
			                     onLoaded={this.onLoaded}
			                     sessionId={this.sessionId}
			                     navigator={this.navigator}
			                     redirectOnClick={this.configuration.displayEventsInPopup !== true}
			                     contentPadding={this.configuration.contentPadding}
			                     accountId={this.instanceConfiguration.accountId}
			                     fit={this.configuration.zoom === undefined ? false : this.configuration.zoom}
			                     onCellClicked={this.onCellClicked}
			                     ref={this.applicationDesignerRef}
			                     mode={"preview"}/>
		);
	}

	onPresentationShown() {
		this.presentation = this.designer.presentation;
		this.editorUi = this.designer.editorUi;
		this.subscribe();
	}

	onResize = () => {
		this.designer?.fit();
	}

	onEvents(data) {
		const myEvents = data.filter(x => x.wId == this.id);

		const anyAdminEvent = myEvents.findIndex(x => x.eventType == 'Administration') != -1;

		if(anyAdminEvent){
			this.initComponent();
		}
	}

	onLoaded = designer => {
		this.designer = designer;
		this.subscribe();
	}

	onCellClicked = async (designer, cell, event) => {
		if (!cell || !this.configuration.displayEventsInPopup) {
			return;
		}

		const [datasource, cellWithDatasource] = iterateParents(cell, currentCell => designer.store.dataSourcesManager.get(currentCell))
		if(!datasource)
			return

		let args = {
			accountId: this.configuration.accountId,
			assetIds: datasource.type == 'asset' && datasource.assets.length > 0
				? [datasource.assets[0].id]: null,
			assetGroupIds: datasource.type == 'assetGroup' && datasource.assetGroups.length > 0
				? [datasource.assetGroups[0].id]: null,
			sorting: [{field: 'time', direction: 'desc'}],
			timePeriod: TimePeriodType.All,
			filters:[{
				field: 'type',
				operator: 'multiselect_equals',
				value: [EntryType.AssetHealthReason]
			}]
		}

		this.eventsWindow = showEventsSummaryPopup(args)
	}

	subscribe = designer => {
		this.subscriberId = this.id;
		let subscriptions = [{
			eventType: 'Administration',
			actionTypes: ['APPLICATION_UPDATE', 'APPLICATION_DELETE'],
			entityIds: [this.configuration.applicationId || this.configuration.servicePresentationId]
		}];
		this.subscriptionHandler = RemoteEventsManager.subscribe(this.subscriberId, subscriptions);
	}

	destroy(dontUnsubscribe) {
		this.eventsWindow?.destroy()
		requestIdleCallback(() => {
			this.widgetRoot?.unmount?.();
			this.widgetRoot = undefined;
		});

		if (this.reasonsWindowId) {
			ReactDOM.unmountComponentAtNode(document.getElementById(this.reasonsWindowId));
		}
		if (!dontUnsubscribe) {
			if (this.subscriptionHandler) {
				this.subscriptionHandler.unsubscribe();
			}
			Widget.prototype.destroy.call(this);
		}
	}
}
