import {DataList} from "framework/entities/dataList";

const i = require('core/localization').translator({
	"Number Ordering": {
		no: 'Tall rekkefolge'
	},
	'Flow': {
		no: 'Flyt'
	}
})

export enum AdaptiveAlgorithm{
	Ordered = 'orderd',
	Flow = 'flow'
}

let adaptiveAlgorithmDataSource: DataList<string>

export function getAdaptiveAlgorithmDataSource() {
	if(adaptiveAlgorithmDataSource == null ) {
		adaptiveAlgorithmDataSource = [{
			id: AdaptiveAlgorithm.Flow,
			name: i('Flow')
		},{
			id: AdaptiveAlgorithm.Ordered,
			name: i('Number Ordering')
		},];
	}
	return adaptiveAlgorithmDataSource
}
