import React, {useEffect} from "react";

import { AntSelect } from "controls/react/ant/antSelect";
import PropTypes from 'prop-types';
import {useRemoteList} from "framework/api";
import {listCostModels} from "areas/cost/api";

const i = require('core/localization').translator()

export const CostModelDropDown = props => {
	const {accountId, profileId, disabled, onReady, ...others} = props;

	let [data, loading, loaded] = useRemoteList(listCostModels(profileId, accountId), {makeRequest: !!profileId})
	useEffect(() => {
		if (loaded && onReady) {
			onReady(data);
		}
	}, [loaded])

	return (
		<AntSelect placeholder={i('Select...')}
				   dataList={data}
				   disabled={!loaded || disabled}
				   loading={loading}
				   {...others}
		/>
	);
}

CostModelDropDown.propTypes = {
	accountId: PropTypes.string,
	profileId: PropTypes.string,
	disabled: PropTypes.bool
}
