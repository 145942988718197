import './textBox.less'

import React from 'react';
import classes from 'classnames';
import PropTypes from "prop-types";

let b = require('b_').with('c-textbox')

class TextBox extends  React.PureComponent{
	constructor(props){
		super(props)

		this.ref = props.innerRef || React.createRef();
		this.wrapperRef = props.wrapperRef || React.createRef();
	}

	onFocus = (e) => {
		const {onFocus} = this.props;

		if (!onFocus) {
			return;
		}

		const value = e.target.value;

		onFocus(value, e);
	}

	onChange = (e) => {
		const {numeric, onChange} = this.props;

		if (!onChange) {
			return;
		}

		const value = e.target.value;

		if (numeric && value === "") {
			onChange(this.props.value, e);
		}
		else {
			onChange(value, e);
		}
	}

	onBlur = (e) => {
		const {onBlur} = this.props;

		if (!onBlur) {
			return;
		}

		const value = e.target.value;

		onBlur(value, e);
	}

	render() {
		let {value, numeric, wrapperClass, containerClass, invalid, title, onChange,
			innerRef, onFocus, onBlur, enterAction, escapeAction, large, ...directProps
		} = this.props;
		value = value || '';
		let classNames = classes('c-textbox', 'k-input', 'k-textbox', containerClass, {'large-class': large, 'cw_link_elements': this.props.clickable});

		return <div ref={this.wrapperRef} className={classes(b("wrapper"), {"invalid-field": invalid}, wrapperClass)}>
				<input className={classNames}
					  type={numeric ? 'number' : 'text'}
		              value={value}
		              onChange={this.onChange}
		              onFocus={this.onFocus}
					  onBlur={this.onBlur}
					  onClick={this.onClick}
		              title={title}
		              onKeyUp={this.onKeyUp}
		              {...directProps}
		              ref={this.ref}/></div>
	}

	componentDidMount(){
		if(this.props.readOnly) {
			this.ref.current.addEventListener('click', () => {
				this.ref.current.select();
			})
		}
	}

	onKeyUp = e => {
		if( e.keyCode == 13 && this.props.enterAction ){
			this.props.enterAction();
		}

		if( e.keyCode == 27 && this.props.escapeAction){
			this.props.escapeAction();
		}
	}
};

TextBox.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	title: PropTypes.string,
	invalid: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	enterAction: PropTypes.func,
	escapeAction: PropTypes.func,
	wrapperClass: PropTypes.string,
	containerClass: PropTypes.string,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	numeric: PropTypes.bool,
	placeholder: PropTypes.string,
	large: PropTypes.bool
}

let forwardRef = React.forwardRef((props, ref) => {
	return <TextBox innerRef={ref} {...props}/>
});

export {forwardRef as default, forwardRef as TextBox}
