import React from 'react';
import Utils from 'tools/utils';
import 'jquery-ui-sortable';
import './draggableMultiselect.less';
import {translator} from "core";
import produce from 'immer';

const i = translator({
	"Required field": {
		"no": "Påkrevd felt"
	}
});

export default class DraggableMultiselect extends React.PureComponent {

	constructor(props) {
		super(props);

		const idValueItems = this.props.values
			.filter( v => !v.id )
			.map( v => ({
				id: Utils.guid(),
				value: v
			}));

		this.state = {
			showAddInput: false,
			showEditInput: {},
			editedInputValue: {},
			draggableItems: idValueItems
		};

	}
	render() {
		var containerClass;
		if (this.props.disabled) {
			containerClass = 'container disabled-container';
		} else {
			containerClass = 'container';
		}
		if (!this.state.draggableItems.length) {
			containerClass += ' empty-container';
		}

		return <div id={this.props.id} className={containerClass} onClick={this.onAddNewItem}>
			{this.state.draggableItems.map((item) => (
				<div className='item-container' id={item.id} key={item.id}>
					{!this.state.showEditInput[item.id] && <div>
						<span className="item-text">{item.value}</span>
						<span className="glyphicons pencil pencil-icon" onClick={(e, data) => this.onEditItem(e, data, item)}></span>
						<span className="k-icon k-i-close close-icon" onClick={(e, data) => this.onDeleteItem(e, data, item)}></span>
					</div>}
					{this.state.showEditInput[item.id] && <input value={this.state.editedInputValue[item.id]}
															  onChange={(e) => this.onEditChange(e, item)}
															  onKeyUp={(e) => this.onEditInputKeyUp(e, item)}
															  onBlur={(e) => this.onEditInputFocusout(e, item)}
					/>}
				</div>
			))}
		{!this.state.draggableItems.length && !this.state.hidePlaceholder && <span id="draggable_placeholder" className="draggable_placeholder" onClick={this.onAddNewItem}> {this.props.placeholder}</span>}
			{this.state.showAddInput && <span>
				<div className="item-container">
					<input className='add-item-input'
						   onKeyUp={(e) => this.onAddInputKeyUp(e)}
						   onBlur={(e) => this.onAddInputFocusout(e)}
					/>
				</div>
			</span>}
		</div>;
	}

	componentDidMount() {
		$('#' + this.props.id).off().on('DOMSubtreeModified', () => {
			let items = this.getItemsOrder();
			this.props.sendValues(items, this.props.id);
		}).sortable({
			containment: '#' + this.props.id,
			tolerance: 'pointer'
		});
	}

	onAddNewItem = e => {
		if (e.target.id === this.props.id || e.target.id === 'draggable_placeholder') {
			this.setState({
				showAddInput: true,
				hidePlaceholder: true
			}, () => {
				$('.add-item-input').addClass('item-input').focus();
			})
		}
	}

	onAddInputFocusout(e) {
		let inputValue = e.target.value;
		if (inputValue) {
			this.addItem(inputValue);
		} else {
			this.setState({
				showAddInput: false
			})
		}
	}

	onAddInputKeyUp(e) {
		let keyCode = e.keyCode;
		let inputValue = e.target.value;
		if (keyCode === 13 && inputValue) { //enter
			this.addItem(inputValue);
		}
	}

	addItem(value) {
		$('#' + this.props.id).removeClass('empty-container');
		let draggableItemsCopy = JSON.parse(JSON.stringify(this.state.draggableItems));
		draggableItemsCopy.push({
			id: Utils.guid(),
			value: value
		});
		this.setState({
			showAddInput: false,
			draggableItems: draggableItemsCopy
		});
	}

	onEditItem = (e, data, item) => {
		let target = e.target;
		let currentItem = target.closest('.item-container');
		let currentItemWidth = $(currentItem).width();
		let currentItemText = $(currentItem).find('.item-text').html();
		this.setState(produce(this.state, state => {
			state.editedInputValue[item.id] = currentItemText;
			state.showEditInput[item.id] = true;
		}, () => {
			let currentInput = $(currentItem).find('input');
			currentInput.css('width', currentItemWidth);
			currentInput.focus();
		}));
	}

	onDeleteItem(e, data, item) {
		this.setState(produce(this.state, state => {
			state.showAddInput = false;
			const index = state.draggableItems.findIndex(i => i.id == item.id);
			state.draggableItems.splice(index, 1);
			if (!state.draggableItems.length) {
				$('#' + this.props.id).addClass('empty-container');
				state.hidePlaceholder = false;
			}
		}));
	}

	onEditChange = (e, item) => {
		this.setState(produce(this.state, state => {
			state.editedInputValue[item.id] = e.target.value;
			state.showEditInput[item.id] = true;
			const index = state.draggableItems.findIndex(i => i.id == item.id);
			state.draggableItems[index].value = e.target.value;
		}));
	}

	onEditInputKeyUp(e, item) {
		let keyCode = e.keyCode;
		this.setState(produce(this.state, state => {
			if (keyCode === 13) {
				state.showEditInput[item.id] = false;
			}
		}));
	}

	getItemsOrder() {
		let items = [];
		$('.item-container').each(function() {
			var itemFound = false;
			let itemId = $(this).attr('id');
			let itemValue = $(this).find('.item-text').html() || $(this).find('input').attr('value');
			for (let i = 0; i < items.length; i++) {
				if (items[i].id === itemId) {
					items[i].value = itemValue;
					itemFound = true;
				}
			}
			if (!itemFound) {
				items.push({
					id: itemId,
					value: itemValue
				})
			}
		});
		return items;
	}

	onEditInputFocusout(e, item) {
		this.setState(produce(this.state, state => {
			state.showEditInput[item.id] = false;
		}));
	}
}
