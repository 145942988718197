import {Renderer} from 'tools';
import {getTooltipMetrics, getTooltipMetricY} from 'areas/service-boards/widgets/customMetrics/chartDataStore';
import Utils from 'tools/utils'

export default function formatter(e) {
	const metricIds = getTooltipMetrics(this.point.x);
	const tooltip = [];

	for (const metricId of metricIds) {
		const series = e.chart.series.find((item) => item.userOptions.id === metricId);
		const {userOptions} = series;
		let y = getTooltipMetricY(metricId, this.point.x);
		if (userOptions.decimals) {
			y = Utils.truncateDecimals(y, userOptions.decimals);
		}

		if (!userOptions.customUnit) {
			tooltip.push(userOptions.name + ': ' + y + ' ' + (userOptions.unitTypeSymbol || ''));
		} else {
			tooltip.push(userOptions.name + ': ' + y + ' ' + userOptions.customUnit);
		}
	}

	return Renderer.browserDateRenderer(this.point.x, "datetime", '') + '<br/>' + tooltip.join('<br/>');
}
