import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';

import MultiToggle from 'controls/multiToggle';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import {MetricInlineSelectorState} from 'areas/service-boards/widgets/common/metricInlineSelector';
import {ApplicationState} from "../../../framework/applicationState";
import {createRoot} from "react-dom/client";
import {checkAccountExists, getAccountsLite} from "areas/administration/accounts/api";
import {apiFetch} from "framework/api";

export function GenericGaugeWidgetConfiguration(config) {
	Utils.apply(this, config);

	this._initComponent();
	return this;
};

export {GenericGaugeWidgetConfiguration as default};

jQuery.extend(GenericGaugeWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the form is rendered
	 */
	renderTo: null,
	/*
	 * Init the component
	 * */
	_initComponent: async function () {

		if(this.widgetObj.configuration.accountId ) {
			if( !await checkAccountExists(this.widgetObj.configuration.accountId)) {
				this.widgetObj.configuration.accountId = ApplicationState.accountId
			}
		}

		this.legendData = [];
		this.selection = 'single';
		this.selectedMetricId = null
		if(this.mode === 'update'){
			this.selectedMetricId = this.widgetObj.configuration.metricId
		}

		let container = this.widgetConfigurationFormEl
		container.empty().html('<div id="cw_metrics_form" class="generic-metric-widget-configuration"></div>');
		this.renderTo = container.find('#cw_metrics_form');

		this._removeListeners();
		this._attachListeners();

		this._render();
		this._initKendoComponents();

		const requiredFields = ['#cw_account', '#cw_gauge_min', '#cw_gauge_max', '#cw_gauge_breach'];
		applyRequired(requiredFields);
	},
	/*
	 * Renders the form
	 * */
	_render: function () {
		var widgetObj = this.widgetObj;
		var inverted = widgetObj.configuration.inverted;

		var minValue;

		if (this.wasUpdate) {
			minValue = this.widgetObj.configuration.minValue || 0;
		} else {
			minValue = this.widgetObj.configuration.minValue || '';
		}
		this.widgetObj.configuration.minValue = minValue;
		this.widgetObj.configuration.maxValue = this.widgetObj.configuration.maxValue === undefined ? '' : this.widgetObj.configuration.maxValue;
		this.widgetObj.configuration.breachValue = this.widgetObj.configuration.breachValue === undefined ? '' : this.widgetObj.configuration.breachValue;

		var html = '<fieldset>';
		html += '<legend class="cw_widget_form_legend">' + lang.CONFIGURATION + '</legend>';
		html += '<span class="cw_toggle_container glyphicons minus pointer"></span>';
		html += '<div class="cw_configuration_placeholder pointer hide">' + lang.widget.messages.MAXIMIZE_SETTINGS + '</div>';
		html += '<div class="cw_configuration_content">';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + '</label>';
		html += '<input class="cw_inline" id="cw_title" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		/*html += '<div class="cw_field" id="cw_asset_container">';
		html += '<label class="cw_inline">' + lang.ASSET + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_asset" type="text" value="' + (widgetObj.configuration.assetId || '') + '" />';
		html += '</div>';*/
		html += '</div>';
		html += '</fieldset>';
		html += '<fieldset>';
		html += '<legend class="cw_widget_form_legend">' + lang.widget.DISPLAY_SETTINGS + '</legend>';
		html += '<span class="cw_toggle_container glyphicons minus pointer"></span>';
		html += '<div class="cw_configuration_placeholder pointer hide">' + lang.widget.messages.MAXIMIZE_SETTINGS + '</div>';
		html += '<div class="cw_configuration_content">';
		html += '<div class="cw_field"><label class="cw_inline">' + lang.TYPE + ': </label><ul id="cw_gauge_type"></ul></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_MIN_VALUE + '" id="cw_gauge_min_field" class="' + (inverted ? "" : "hide") + '"><label class="cw_inline">' + lang.widget.MIN_VALUE + ': </label><div class="cw_numeric_container"><input id="cw_gauge_min" type="text" value="' + widgetObj.configuration.minValue + '" /></div></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_MAX_VALUE + '"><label class="cw_inline">' + lang.widget.MAX_VALUE + ': </label><div class="cw_numeric_container"><input id="cw_gauge_max" type="text" value="' + widgetObj.configuration.maxValue + '" /></div></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_BREACH_VALUE + '"><label class="cw_inline">' + lang.widget.BREACH_VALUE + ': </label><div class="cw_numeric_container"><input id="cw_gauge_breach" type="text" value="' + widgetObj.configuration.breachValue + '" /></div></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_DATA_REPRESENTATION + '">';
		html += '<input type="checkbox" id="cw_gauge_invert" class="k-checkbox" ' + (inverted ? 'checked' : '') + ' />';
		html += '<label for="cw_gauge_invert" class="k-checkbox-label">' + lang.widget.INVERT + '</label>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<input id="cw_generic_gauge_legend" />';
		html += '</div>';

		html += '</fieldset>';
		html += '<div class="cw_selected_metrics">';
		html += '</div>';
		this.renderTo.html(html);

		this.addMetricSelector();

		if (this.mode === 'new') {
			this.setMinimized();
		}
	},
	/**
	 * Removes listeners
	 */
	_removeListeners: function () {
	},
	/**
	 * Attaches listeners
	 */
	_attachListeners: function () {
		this.renderTo.on('click', '.cw_toggle_container', $.proxy(this.onToggleConfiguration, this));
		this.renderTo.on('click', '.cw_configuration_placeholder', $.proxy(this.onToggleConfiguration, this));
	},
	setMinimized: function () {
		this.renderTo.find('.cw_configuration_content').addClass('hide');
		this.renderTo.find('.cw_configuration_placeholder').removeClass('hide');
		this.renderTo.find('.cw_toggle_container').each(function() {
			$(this).removeClass('minus').addClass('unchecked');
		});
	},
	/**
	 * Initialize Kendo components
	 */
	_initKendoComponents: function () {
		var widgetObj = this.widgetObj;
		if (!this.widgetObj.configuration.accountId) {
			this.widgetObj.configuration.accountId = ApplicationState.accountId;
		}

		// this.createAccountComponent({
		// 	element: $('#cw_account'),
		// 	container: $('#cw_account_container'),
		// 	events: {
		// 		change: $.proxy((src, e) => {
		// 			this.widgetObj.configuration.accountId = $('#cw_account').data('kendoComboBox').value();
		// 			this.addMetricSelector()
		// 		}, this)
		// 	}
		// });

		this.gaugeType = new MultiToggle({
			selector: this.renderTo.find('#cw_gauge_type'),
			type: 'label',
			cssClass: 'cw_multi_toggle true',
			value: 'gauge',
			items: [{
				id: 'cw_toggle_gauge',
				title: lang.GAUGE,
				label: lang.GAUGE,
				selected: this.mode === 'update' || this.wasUpdate ? (widgetObj.configuration.gaugeType === 'gauge' ? true : false) : true,
				//fn: this.onGaugeTypeToggle,
				scope: this,
				value: 'gauge'
			}, {
				id: 'cw_toggle_solidgauge',
				title: lang.SOLID_GAUGE,
				label: lang.SOLID_GAUGE,
				selected: this.mode === 'update' || this.wasUpdate ? (widgetObj.configuration.gaugeType === 'solidgauge' ? true : false) : false,
				//fn: this.onGaugeTypeToggle,
				scope: this,
				value: 'solidgauge'
			}]
		});

		this.minValue = this.renderTo.find('#cw_gauge_min').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		this.maxValue = this.renderTo.find('#cw_gauge_max').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		this.breachValue = this.renderTo.find('#cw_gauge_breach').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		this.legendDataSource = [{
			name: '<Asset>',
			id: '<Asset>'
		}, {
			name: '<Name>',
			id: '<Name>'
		}, {
			name: '<Type>',
			id: '<Type>'
		}, {
			name: '<Category>',
			id: '<Category>'
		}, {
			name: '<Instance>',
			id: '<Instance>'
		}];

		if (this.mode === 'update' || this.wasUpdate) {
			if (!widgetObj.configuration.labelTemplate) {
				this.legendMultiList = [];
			} else {
				this.legendMultiList = widgetObj.configuration.labelTemplate;
			}
		}
		this.renderLegendMultiSelect();
	},

	renderLegendMultiSelect: function() {

		this.legendReactRoot = createRoot(this.widgetConfigurationFormEl.find('#cw_generic_gauge_legend').parent().get(0))

		this.legendReactRoot.render(<FormEntry label={lang.LEGEND} vertical={true}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},

	onMetricsSelected: function (){
		this.renderAvailableMetricsWindow(true);
	},

	onToggleConfiguration: function (e) {
		var target = $(e.target);
		var currentFieldset = target.closest('fieldset');
		var currentToggleContainer = currentFieldset.find('.cw_toggle_container');
		currentFieldset.find('.cw_configuration_content').toggleClass('hide');
		currentFieldset.find('.cw_configuration_placeholder').toggleClass('hide');

		if (currentToggleContainer.hasClass('minus')) {
			currentToggleContainer.removeClass('minus').addClass('unchecked');
		} else {
			currentToggleContainer.removeClass('unchecked').addClass('minus');
		}
	},


	/*
	* Gets the form values
	* */
	getValues: function () {
		var title = $('#cw_title').val().trim();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: this.type,
			title: title,
			configuration: {
				accountId: ApplicationState.accountId,
				//assetId: $('#cw_asset').data('kendoComboBox').value(),
				//assetName: $('#cw_asset').data('kendoComboBox').text(),
				gaugeType: this.gaugeType.getValue(),
				inverted: $('#cw_gauge_invert').is(':checked'),
				minValue: parseFloat($('#cw_gauge_min').val()),
				breachValue: parseFloat($('#cw_gauge_breach').val()),
				maxValue: parseFloat($('#cw_gauge_max').val()),
				labelTemplate: this.legendMultiList,
				formula: this.widgetObj.configuration.formula,
				unit: this.widgetObj.configuration.unit,
				unitLabel: this.widgetObj.configuration.unitLabel,
				decimals: this.widgetObj.configuration.decimals,
				advanced: this.widgetObj.configuration.advanced,
			}
		};

		widgetObj.configuration.metricId = this.selectedMetricId

		return widgetObj;
	},
	/**
	 * Validates the widget config form
	 * @return {Boolean} valid True if the form is valid, false otherwise
	 */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';


		/*var assetCombo = $('#cw_asset').data('kendoComboBox');
		if (!Utils.isGuid(assetCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ASSET;
		}*/

		if (!this.selectedMetricId) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_METRICS;
		}

		var min = this.minValue.value();
		var breach = this.breachValue.value();
		var max = this.maxValue.value();
		var inverted = $('#cw_gauge_invert').is(':checked');

		if (min === null) {
			valid = false;
			this.validationMessage = lang.widget.messages.FILL_IN_NUMERIC_MIN;
		}
		if (max === null) {
			valid = false;
			this.validationMessage = lang.widget.messages.FILL_IN_NUMERIC_MAX;
			;
		}
		if (breach === null) {
			valid = false;
			this.validationMessage = lang.widget.messages.FILL_IN_NUMERIC_BREACH;
		}
		if (!isNaN(max) && !isNaN(breach)) {
			max = parseFloat(max);
			breach = parseFloat(breach);
			if (max < breach) {
				valid = false;
				this.validationMessage = lang.widget.messages.BREACH_VALUE_MAX;
			}
			if (inverted && !isNaN(min)) {
				if (max <= min) {
					valid = false;
					this.validationMessage = lang.widget.messages.MIN_VALUE_MAX;
				}
			}
		}
		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				this.validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				this.validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},
	/*
	* Called when resize ends
	*/
	resize: function () {
		this.adjustSectionHeight();
	},

	destroyMetric: function() {
		this.metricsSelectorReactRoot?.unmount()
	},

	addMetricSelector: function() {
		this.destroyMetric();

		this.metricsSelectorReactRoot = createRoot(this.widgetConfigurationFormEl[0].querySelector('.cw_selected_metrics'))
		this.metricsSelectorReactRoot.render(<MetricInlineSelectorState
			onAdd={(items) => {
				this.selectedMetricId = items?.length ? items[0].metricId : null
			}}
			type={this.widgetObj.type}
			accountId={this.widgetObj.configuration.accountId}
			configObj={this.widgetObj.configuration}
			retrieveConfig={(field, value) => {
				this.widgetObj.configuration[field] = value;
			}}
			selectedMetrics={this.widgetObj.configuration.metricId ? [{metricId: this.widgetObj.configuration.metricId}] : []} />
		);
	},
	/*
	* Destroys the form
	* */
	destroy: function () {
		this.legendReactRoot?.unmount()

		if (this.availableMetricsWindow) {
			this.availableMetricsWindow.destroy();
			this.availableMetricsWindow = null;
		}
		this.destroyMetric();

	}
});
