import {observer} from "mobx-react"
import React from "react"
import {computed, makeAutoObservable} from "mobx"

import {AntCheckbox} from "controls/react/ant/antCheckbox"
import {ApiRequestPayload, GridStore} from "controls/grid/gridStore"
import {GridDataEntry, GridDataEntryWithId} from "controls/grid/gridDataEntry";
import {GridColumnConfig} from "controls/grid/gridColumnConfig"
import {CheckboxChangeEvent} from "antd/es/checkbox/Checkbox";
import {GridDataItem} from "controls/grid/gridDataItem";
import {GridSelectionType} from "controls/grid/gridConfiguration";


const i18n = require('core/localization/localization').translator({
	'Items selected: {0}': {no: 'Rader valgt: {0}'}
})

export enum GridSelectionMode{
	Exclude = 'EXCLUDE',
	Include = 'INCLUDE'
}

export type GridSelectionPayload = {
	ids: string[],
	mode: GridSelectionMode
}

export enum SelectedDatasetType{
	Full,
	Partial
}

export enum SelectionAction{
	Select,
	Deselect
}

export class SelectionDataset<DataItem extends GridDataEntry>{
	type: SelectedDatasetType
	items: Array<GridDataItem<DataItem>>

	constructor(type?: SelectedDatasetType, items?: Array<GridDataItem<DataItem>>) {
		this.type = type ?? SelectedDatasetType.Partial
		this.items = items ?? []
	}

	static empty<DataItem extends GridDataEntry>(){
		return new SelectionDataset<DataItem>(SelectedDatasetType.Partial, [])
	}
}

export class GridSelection<DataItem extends GridDataEntry> {
	mode: GridSelectionMode = GridSelectionMode.Include
	ids: string[] = []

	store: GridStore<DataItem>

	private lastToggledItem: GridDataItem<DataItem>
	private lastToggledItemAction: SelectionAction = SelectionAction.Select

	constructor(store: GridStore<DataItem>) {
		this.store = store

		if (this.store.config.defaults?.selectedIds?.length > 0) {
			if(this.store.config.selection == GridSelectionType.One){
				this.ids = [this.store.config.defaults.selectedIds[0]]
			}
			this.ids = [...this.store.config.defaults.selectedIds]
		}

		makeAutoObservable(this, {
			dataset: computed({keepAlive: true}),
		})
	}

	get isAnythingSelected(){
		if(!this.store.selfInitialized)
			return false

		if(!this.store.dataProvider?.initialized)
			return false

		if(this.store.dataProvider?.visibleRowsCount == 0)
			return false

		return this.mode == GridSelectionMode.Exclude && this.ids.length < this.store.dataProvider?.visibleRowsCount
			|| this.mode == GridSelectionMode.Include && this.ids.length > 0
	}

	get isOnlyOneSelected(){
		return this.selectedItemsCount == 1;
	}

	get isEverythingSelected(){
		if(this.store.dataProvider?.visibleRowsCount == 0)
			return false

		return this.mode == GridSelectionMode.Exclude && this.ids.length == 0
			|| this.mode == GridSelectionMode.Include && this.ids.length == this.store.dataProvider?.visibleRowsCount
	}

	get payload(){
		return {
			mode: this.mode,
			ids: this.ids
		}
	}

	get dataset(){
		let result = new SelectionDataset<DataItem>(SelectedDatasetType.Full)

		if (this.mode == GridSelectionMode.Include) {
			this.ids.forEach(id => {
				let item = this.store.dataProvider.data.find(x => x && x.id == id)
				if(item != null){
					result.items.push(item)
				} else {
					result.type = SelectedDatasetType.Partial
				}
			})
		} else if (this.mode == GridSelectionMode.Exclude) {
			this.store.dataProvider.data.forEach(item => {
				if (this.ids.indexOf(item.id) == -1) {
					result.items.push(item)
				} else {
					result.type = SelectedDatasetType.Partial
				}
			})
		}

		return result
	}

	some = (callback: (item: GridDataItem<DataItem>) => boolean) => {
		if(this.dataset.type == SelectedDatasetType.Partial)
			return true

		return this.dataset.items.some(callback)
	}

	exactlyOne = (callback: (item: GridDataItem<DataItem>) => boolean) => {
		if(this.selectedItemsCount == 1
			&& this.dataset.type == SelectedDatasetType.Full
			&& callback(this.dataset.items[0])
		){
			return this.dataset.items[0]
		}

		return null
	}

	clear = () => {
		this.ids = []
		this.mode = GridSelectionMode.Include
	}

	toggleSelectionMode = () => {
		this.lastToggledItem = null
		if(this.isEverythingSelected){
			this.lastToggledItemAction = SelectionAction.Deselect
			this.mode = GridSelectionMode.Include
		}else{
			this.mode = GridSelectionMode.Exclude
			this.lastToggledItemAction = SelectionAction.Select
		}
		this.ids = []
	}

	toggleSelected = (item: GridDataItem<DataItem>, massSelection: boolean = false) => {
		if (massSelection && this.store.config.selection != GridSelectionType.One) {
			this.toggleMassSelection(item)
		}else{
			this.lastToggledItem = item
			this.lastToggledItemAction = this.toggleSingleItemSelection(item)
		}
	}

	private toggleSingleItemSelection(item: GridDataItem<DataItem>, action?: SelectionAction): SelectionAction {
		let index = this.ids.indexOf(item.id)

		if (index != -1 && (action == null || action == SelectionAction.Deselect)) {
			this.ids.splice(index, 1)
			return SelectionAction.Deselect
		} else if (index == -1 && (action == null || action == SelectionAction.Select)) {
			if(this.store.config.selection == GridSelectionType.One) {
				this.ids = [item.id]
			}else{
				this.ids.push(item.id)
			}
			return SelectionAction.Select
		}
	}

	private toggleMassSelection(item: GridDataItem<DataItem>) {
		let startIndex = this.store.dataProvider.data.indexOf(item)
		let finishIndex = this.lastToggledItem ? this.store.dataProvider.data.indexOf(this.lastToggledItem) : 0

		if (startIndex != -1 && finishIndex != -1) {
			for (let i = Math.min(startIndex, finishIndex); i <= Math.max(startIndex, finishIndex); i++) {
				let itemToSelect = this.store.dataProvider.data[i]
				if (itemToSelect != null) {
					this.toggleSingleItemSelection(itemToSelect, this.lastToggledItemAction)
				}
			}
		}
	}

	get isSelectedAnythingButNotAll(){
		return this.isAnythingSelected && !this.isEverythingSelected
	}

	isSelected(item: GridDataItem<DataItem> | GridDataEntryWithId){
		let index = this.ids.indexOf(item.id)
		return this.mode == GridSelectionMode.Include
			? index != -1
			: index == -1
	}

	get selectedItemsCount(){
		return this.mode == GridSelectionMode.Include
			? this.ids.length
			: this.store.dataProvider.visibleRowsCount - this.ids.length
	}
}

export const SelectAllComponent = observer(<DataItem extends GridDataEntry, >(props: { store: GridStore<DataItem> }) => {
	const store = props.store

	return <AntCheckbox value={store.selection.isEverythingSelected}
	                    className={'_bypass-dirty-check'}
	                    indeterminate={store.selection.isSelectedAnythingButNotAll}
	                    onChange={store.selection.toggleSelectionMode}/>
})

type SelectRowComponentProps<DataEntry extends GridDataEntry> = {
	store: GridStore<DataEntry>,
	item: GridDataItem<DataEntry>
}

export const SelectRowComponent = observer(<DataEntry extends GridDataEntry, >(props: SelectRowComponentProps<DataEntry>) => {
	const selection = props.store.selection
	const item = props.item

	const onClick = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation()
	}, [item])

	const onChange = (value: boolean, e: CheckboxChangeEvent) =>  {
		if(e.nativeEvent.shiftKey) {
			e.stopPropagation()
		}
		selection.toggleSelected(props.item, e.nativeEvent.shiftKey)
	}

	const disabledResult = props.store.isSelectionDisabled(props.item)

	return <AntCheckbox value={selection.isSelected(item)}
	                    disabled={disabledResult.disabled}
	                    title={disabledResult.reason}
	                    className={'_bypass-dirty-check'}
	                    onClick={onClick}
	                    onChange={onChange}/>
})

export function getSelectorColumn<DataItem extends GridDataEntry>(store: GridStore<DataItem>) : GridColumnConfig<DataItem>{
	return {
		field: 'id',
		title: i18n('Selector'),
		renderer: (item, extra ) => <SelectRowComponent item={extra.gridItem} store={store}/>,
		rendererHeader: () => store.config.selection == GridSelectionType.One ? <div></div> : <SelectAllComponent store={store}/>,
		getHeaderTitle: () => i18n('Items selected: {0}',store.selection.selectedItemsCount),
		className: 'ceeview-grid__cell_id',
		width: 32,
		sortable: false,
		fixed: 'left'
	}
}

export type SelectionArgs = {
	accountId?: string,
	selection: GridSelectionPayload
}

export function getGridApiRequestPayload(args: SelectionArgs, payload?: Record<string, any>){
	return {
		accountBased: true,
		accountId: args.accountId,
		subaccountsFilter: true,
		method: 'POST' as const,
		payload: {
			selection: args.selection,
			...(payload ?? {})
		}
	}
}
