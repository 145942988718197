import {MobxManager} from "framework/mobx-integration";
import {apiFetch, ApiRequest, ApiResponse, PagedList} from "framework/api";
import {makeAutoObservable, runInAction} from "mobx";

export type DataListLoaderOptions<T extends object> = {
	beforeLoad?: () => void
	nameField?: keyof T
	valueField?: keyof T
}

export class DataListLoader<T extends object> {
	dataList: T[]
	loading: boolean = false
	loaded: boolean = false
	firstLoad: boolean = true

	mobx = new MobxManager()

	loader: (() => ApiRequest<T[]>) | (() => ApiRequest<PagedList<T>>)
	options: DataListLoaderOptions<T>


	constructor(loader: (() => ApiRequest<T[]>) | (() => ApiRequest<PagedList<T>>), options: DataListLoaderOptions<T> = {}) {
		makeAutoObservable(this)

		this.loader = loader
		this.options = options

		this.init()
	}

	get props() {
		let result: {
			dataList: T[],
			loading: boolean,
			disabled: boolean,
			nameField?: keyof T,
			valueField?: keyof T
		}  = {
			dataList: this.dataList,
			loading: this.loading,
			disabled: !this.loaded
		}

		if(this.options.nameField){
			result.nameField = this.options.nameField
			result.valueField = this.options.valueField
		}

		return result
	}

	async init() {
		this.mobx.reaction(() => this.loader(), async (request) => {
			if(!request)
				return

			let requestInner = request as ApiRequest<T[]|PagedList<T>>

			this.loading = true
			if (!this.firstLoad) {
				this.options.beforeLoad?.()
			}

			this.firstLoad = false

			let response = await apiFetch(requestInner)

			runInAction(() => {
				if (response.success) {
					if('items' in response.data){
						this.dataList = response.data.items
					}else {
						this.dataList = response.data
					}
					this.loaded = true
				}

				this.loading = false
			})
		}, {
			fireImmediately: true
		})
	}

	destroy() {
		this.mobx.destroy()
	}
}
