import './sketchColorPicker.less'

import React from "react";
import classnames from 'classnames';
import {ColorResult, SketchPicker, SketchPickerProps} from 'react-color';
import {AntPopover} from "controls/react/ant/antPopover";
import {Link} from 'core/react/links';
import {observer} from 'mobx-react';
import { captureValueLink } from '../ant/utils';
import {
	FormEntry, Button
} from "controls/react/form";


interface SketchColorPickerProps<VT extends ColorResult> extends SketchPickerProps{
	valueLink?: Link<VT>
}

const b = require('b_').with('sketch-color-picker')

export const SketchColorPicker = observer(<VT extends ColorResult>(props: SketchColorPickerProps<VT>) => {
	props = captureValueLink(props);
	let { className, value, ...nativeProps} = props

	const classes = classnames(className)

	return <div className={b()}>
			<AntPopover 
				placement="bottomLeft"
				content={<SketchPicker className={classes} color={value?.hex} {...nativeProps}/>}
				trigger="click">
				<Button onClick={()=>{}}
						style={{backgroundColor: value?.hex}}
						className={b("picker-btn")}/>
				{value?.hex}
			</AntPopover>
		</div>
});
