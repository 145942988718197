import {ApiRequest} from "framework/api";

export class KpiProfile{
	id: string
	name: string
}

export function getKpiProfiles(accountId?: string){
	return new ApiRequest<KpiProfile[]>({
		url: 'kpi/lite',
		accountBased: true,
		accountId: accountId,
		subaccountsFilter: true,
		ignoreTags: true
	})
}

export class KpiCategory{
	value: string
	text: string
}

export function getKpiCategories(args: {profileId: string, accountId?: string}){
	return new ApiRequest<KpiCategory[]>({
		url: `kpi/${args.profileId}/categories`,
		accountBased: true,
		accountId: args.accountId,
		subaccountsFilter: true
	})
}

export class KpiRuleType{
	value: string
	text: string
}

export function getKpiRuleTypes(args: {profileId: string, categoryId: string, accountId?: string}){
	return new ApiRequest<KpiRuleType[]>({
		url: `kpi/${args.profileId}/categories/${args.categoryId}/types`,
		accountBased: true,
		accountId: args.accountId,
		subaccountsFilter: true
	})
}

export class KpiSampleRate{
	value: string
	text: string
}

export function getKpiSampleRates(args: {profileId: string, ruleTypeId: string, accountId?: string}){
	return new ApiRequest<KpiSampleRate[]>({
		url: `kpi/${args.profileId}/types/${args.ruleTypeId}/sampleRates`,
		accountBased: true,
		accountId: args.accountId,
		subaccountsFilter: true
	})
}

export class KpiAccount{
	value: string
	text: string
}

export function getKpiAccounts(args: {profileId: string, accountId?: string}){
	return new ApiRequest<KpiAccount[]>({
		url: `kpi/${args.profileId}/accounts`,
		accountBased: true,
		accountId: args.accountId,
		subaccountsFilter: true
	})
}
