import {computed, makeAutoObservable, reaction, runInAction, trace} from "mobx"
import {list, object, primitive, serialize} from "serializr"

import {defaultViewId, GridViewState, tempViewId} from "controls/grid/gridViewState";
import {newGuid} from "tools/guid"
import {copyViaSerializr, createModelSchemaWrapper} from "framework/serializr-integration"
import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridStore} from "./gridStore"
import {MobxManager} from "framework/mobx-integration";
import {debounce} from "lodash";

const i = require('core/localization/localization').translator({
	'Default view': {no: 'Standard visning'},
	'New view': {no: 'Ny visning'},
	'External view': {no: 'Ekstern visning'}
});


export class GridState<DataEntry extends GridDataEntry> {
	initialViewId: string
	_currentViewId: string

	views: GridViewState<DataEntry>[] = []

	//an initial copy of views. Is used to react on 'reset' button
	initialViews: GridViewState<DataEntry>[] = []

	mobx = new MobxManager()

	deletedViews: string[] = []

	get currentViewId(){
		return this._currentViewId ?? this.initialViewId ?? defaultViewId
	}

	set currentViewId(id: string){
		this._currentViewId = id
	}

	get columns() {
		return this.currentView.columns
	}

	get sortingOrder() {
		return this.currentView.sortingOrder
	}

	get showInlineFilters(){
		return this.currentView.showInlineFilters
	}

	set sortingOrder(value) {
		this.currentView.sortingOrder = value
	}

	get filters() {
		return this.currentView.filters
	}

	set filters(value) {
		this.currentView.filters = value
	}

	get searchString() {
		return this.currentView.searchString
	}

	set searchString(value) {
		this.currentView.searchString = value;
	}

	get customPayload() {
		return this.currentView.customPayload
	}

	get groupBy(): (keyof DataEntry)[]{
		return this.currentView.groupBy
	}

	set groupBy(value: (keyof DataEntry)[]){
		this.currentView.groupBy = value
	}

	get collapsedGroups(){
		return this.currentView.collapsedGroups
	}

	set customPayload(value) {
		this.currentView.customPayload = value
	}

	get customData(){
		return this.currentView.customData
	}

	set customData(value){
		this.currentView.customData = value
	}

	get currentView() {
		return this.views.find(x => x.id == this.currentViewId)
			?? this.defaultView
	}

	get defaultView(){
		return this.views.find(x => x.id == defaultViewId)
	}

	constructor() {
		makeAutoObservable(this, {
			currentView: computed({keepAlive: true})
		})

		this.mobx.reaction(() => this.currentViewId, () => {
			if(this.currentViewId != tempViewId) {
				let tempViewIndex = this.views.findIndex(x => x.id == tempViewId)
				tempViewIndex != -1 && this.views.splice(tempViewIndex, 1)
			}
		} )
	}

	ensureDefaultViewExists() {
		let defaultView = this.views.find(x => x.id == defaultViewId)

		if (defaultView == null) {
			defaultView = new GridViewState<DataEntry>()
			defaultView.id = defaultViewId
			defaultView.name = i('Default view')

			this.views.push(defaultView)
		} else {
			defaultView.name =  i('Default view')
		}
	}

	duplicateCurrentView = () => {
		const newView = copyViaSerializr<GridViewState<DataEntry>>(this.currentView)
		newView.name = i('New view')
		newView.id = newGuid()
		this.views.push(newView)

		this.currentViewId = newView.id

		return newView.id
	}

	deleteView = () => {
		if (!this.canDeleteView())
			return

		this.deletedViews.push(this.currentViewId)
		const actualViewIndex = this.views.findIndex(x => x.id == this.currentViewId)

		if (this.initialViewId == this.currentViewId) {
			this.initialViewId = defaultViewId
		}

		this.currentViewId = this.views[Math.max(0, actualViewIndex - 1)].id

		this.views.splice(actualViewIndex, 1)
	}

	canDeleteView = () => {
		return this.currentViewId && this.currentViewId != defaultViewId
	}

	validate = (store: GridStore<DataEntry>) => {
		this.ensureDefaultViewExists()
		this.views.forEach(x => x.validate(store))
	}

	createViewsSnapshot(){
		this.initialViews = this.views.map(x => copyViaSerializr<GridViewState<DataEntry>>(x))
		this.views.forEach(x => x.calculateHash())
	}

	reset = (store: GridStore<DataEntry>) => {
		if(this.currentView.isTemporary()){
			this.currentViewId = defaultViewId

		}else if(this.currentView.isDefault()) {
			this.currentView.resetToDefault(store)

		}else{
			let index =  this.views.findIndex(x => x.id == this.currentViewId)
			let originalView = copyViaSerializr<GridViewState<DataEntry>>(this.initialViews.find(x => x.id == this.currentViewId))
			originalView.validate(store)
			originalView.calculateHash()

			this.views.splice(index, 1, originalView)
		}
	}

	switchToTempView = () => {
		if(this.currentViewId == tempViewId)
			return

		const newView = copyViaSerializr<GridViewState<DataEntry>>(this.currentView)
		newView.name = i('External view')
		newView.id = tempViewId

		this.views.push(newView)
		this.currentViewId = tempViewId
	}

	switchToDefaultView = () => {
		this.currentViewId = defaultViewId
	}

	clearSorting = () => {
		this.sortingOrder = []
		this.columns.forEach(x => x.sorting = null)
	}

	destroy(){
		this.mobx.destroy()
	}
}

createModelSchemaWrapper(GridState, {
	initialViewId: primitive(),
	views: list(object(GridViewState), {
		beforeDeserialize: (callback, jsonValue, jsonParentValue, propNameOrIndex, context, propDef) => {
			//the field got renamed from viewsStored to views, backwards compatibility maintained here
			if(jsonValue == null && jsonParentValue.viewsStored != null){
				jsonValue = jsonParentValue.viewsStored
			}

			callback(null, jsonValue)
		}
	})
});
