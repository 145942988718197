import React from 'react';

import Api from 'tools/api';
import Settings from "settings";
import {Cookies} from 'core/cookies';
import {AntSelect, AntSelectPropsInner, AntSelectValue, textValueFields} from './ant/antSelect';
import {DataListTextValue, DataListTextValueEntry} from "framework/entities/dataList";
import {apiFetch} from "framework/api";
import {getEventTypes} from "areas/summary/events/api";

const i = require('core/localization').translator({
  "Choose an event type...": {
    "no": "Velg hendelsetype..."
  }
});

interface EventTypeSelectProps<VT extends AntSelectValue> extends AntSelectPropsInner<VT>{
	accountId?: string
}

interface EventTypeSelectState {
	dataSource: DataListTextValue<string>
}

export class EventTypeSelect<VT extends AntSelectValue> extends React.Component<EventTypeSelectProps<VT>, EventTypeSelectState>{

	render(){
		const {accountId, ...others} = this.props;

		return <AntSelect mode={'multiple'}
		                  placeholder={i('Choose an event type...')}
		                  dataList={this.state?.dataSource}
		                  {...textValueFields}
		                  {...others}
		/>
	}

	async componentDidMount(){
		const result = await apiFetch(getEventTypes(this.props.accountId))
		if(result.success) {
			this.setState({dataSource: result.data});
		}
	}
}
