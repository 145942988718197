import tinymce from 'tinymce';


const i = require('core/localization').translator({
  "Appearance": {
    "no": "Visning"
  },
  "Cells together": {
    "no": "Celler sammen"
  },
  "Cells with spacing": {
    "no": "Celler med mellomrom"
  },
  "Rounded table border": {
    "no": "Rund tabellramme"
  },
  "Square table border": {
    "no": "Firkantet tabellramme"
  },
  "Rounded cell border": {
    "no": "Rund celleramme"
  },
  "Square cell border": {
    "no": "Firkantet celleramme"
  },
  "Add borders to cells": {
    "no": "Legg til ramme til celle"
  },
  "Remove borders from cells": {
    "no": "Fjern ramme fra celle"
  }
});

function getSelection(element: Element){
	let table: HTMLTableElement;
	let cell: HTMLTableCellElement;

	if(element.tagName == 'TABLE'){
		table = element as HTMLTableElement;
	}else{
		table = element.closest("table");
	}

	if(element.tagName == 'TD')
		cell = element as HTMLTableCellElement;
	else{
		cell = element.closest('td');
	}

	return {table, cell};
}

function convertAttributesToStyles(table: HTMLTableElement){
	if(table.getAttribute("border") == '')
		return;

	addBordersToAllCells(table);

	table.removeAttribute("border");
}

function addBordersToAllCells(table: HTMLTableElement){
	const cells = table.querySelectorAll("td")
	cells.forEach(cell => {
		cell.style.borderStyle = 'solid';
		cell.style.borderWidth = '1px';
		cell.style.borderColor = 'gray';
	});
}

function removeBordersFromAllCells(table: HTMLTableElement){
	const cells = table.querySelectorAll("td")
	cells.forEach(cell => {
		cell.style.borderStyle = '';
		cell.style.borderWidth = '';
		cell.style.borderColor = '';
	});
}

function addTableBorder(table: HTMLTableElement){
	table.style.borderColor = 'gray';
	table.style.borderWidth = '1px';
	table.style.borderStyle = 'solid';
}

function buildMenu(element: Element){
	const {table, cell} = getSelection(element);
	const menuElements: string[] = [];
	if( table != null){
		if(table.style.borderCollapse != 'collapse'){
			menuElements.push('ceeview-table-styling-border-collapse')
		}

		if( table.style.borderCollapse != 'initial'){
			menuElements.push('ceeview-table-styling-border-separate')
		}

		if(!table.style.borderRadius){
			menuElements.push('ceeview-table-styling-border-rounded');
		}else{
			menuElements.push('ceeview-table-styling-border-square')
		}

		menuElements.push('ceeview-table-styling-border-cell-add-all')
		menuElements.push('ceeview-table-styling-border-cell-remove-all')
	}

	if(cell != null){
		if(!cell.style.borderRadius) {
			menuElements.push('ceeview-table-styling-border-rounded-cell')
		}else{
			menuElements.push('ceeview-table-styling-border-square-cell')
		}
	}

	if(menuElements.length == 0)
		return '';

	return menuElements.join(" ");
}

tinymce.PluginManager.add('ceeview-table-styling', function(editor, url) {
	editor.ui.registry.addMenuItem('ceeview-table-styling-border-separate', {
		text: i('Cells with spacing'),
		onAction: function() {
			let {table}= getSelection(editor.selection.getNode());

			if(table == null)
				return;

			convertAttributesToStyles(table);

			table.style.borderCollapse = 'initial';
		}
	});

	editor.ui.registry.addMenuItem('ceeview-table-styling-border-collapse', {
		text: i('Cells together'),
		onAction: function() {
			let {table} = getSelection(editor.selection.getNode());

			if(table == null)
				return;

			convertAttributesToStyles(table);

			table.style.borderCollapse = 'collapse';
		}
	});

	editor.ui.registry.addMenuItem('ceeview-table-styling-border-rounded', {
		text: i('Rounded table border'),
		onAction: function() {
			let {table} = getSelection(editor.selection.getNode());

			if(table == null)
				return;

			convertAttributesToStyles(table);
			addTableBorder(table);

			table.style.borderCollapse = 'initial';
			table.style.borderRadius = '8px';
		}
	});

	editor.ui.registry.addMenuItem('ceeview-table-styling-border-square', {
		text: i('Square table border'),
		onAction: function() {
			let {table} = getSelection(editor.selection.getNode());

			if(table == null)
				return;

			convertAttributesToStyles(table);
			addTableBorder(table);

			table.style.borderCollapse = 'initial';
			table.style.borderRadius = '';
		}
	})

	editor.ui.registry.addMenuItem('ceeview-table-styling-border-rounded-cell', {
		text: i('Rounded cell border'),
		onAction: function() {
			let {table, cell} = getSelection(editor.selection.getNode());

			if(table == null || cell == null) {
				return;
			}

			convertAttributesToStyles(table);

			cell.style.borderRadius = '8px';
		}
	});

	editor.ui.registry.addMenuItem('ceeview-table-styling-border-square-cell', {
		text: i('Square cell border'),
		onAction: function() {
			let {table, cell} = getSelection(editor.selection.getNode());

			if(table == null || cell == null) {
				return;
			}

			convertAttributesToStyles(table);

			cell.style.borderRadius = '';
		}
	});

	editor.ui.registry.addMenuItem('ceeview-table-styling-border-cell-add-all', {
		text: i('Add borders to cells'),
		onAction: function() {
			let {table, cell} = getSelection(editor.selection.getNode());

			if(table == null || cell == null) {
				return;
			}

			convertAttributesToStyles(table)

			addBordersToAllCells(table);
		}
	});

	editor.ui.registry.addMenuItem('ceeview-table-styling-border-cell-remove-all', {
		text: i('Remove borders from cells'),
		onAction: function() {
			let {table, cell} = getSelection(editor.selection.getNode());

			if(table == null || cell == null) {
				return;
			}

			convertAttributesToStyles(table);

			removeBordersFromAllCells(table);
		}
	});


	editor.ui.registry.addNestedMenuItem('ceeview-table-appearance', {
		text: i('Appearance'),
		getSubmenuItems: function(){
			return buildMenu(editor.selection.getNode());
		}
	})

	editor.ui.registry.addContextMenu('ceeview-table-styling', {
		update: function(element){
			return [{
				type: 'submenu',
				text: i('Appearance'),
				getSubmenuItems: () => {
					return buildMenu(element);
				}
			}]
		}
	});
});
