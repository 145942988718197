import './costProfileWidget.less';

import React, { useMemo } from 'react';
import {observer} from 'mobx-react';

import {Section} from "controls/react/layout/section";
import {FormEntry, TextBox} from "controls/react/form";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {AntSelect} from "controls/react/ant/antSelect";
import {apiFetch} from "framework/api";
import {BoxView} from "controls/react/layout/boxView";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {ApplicationState} from "framework/applicationState";
import AccountDropDown from "controls/react/dropdowns/accountDropDown";
import {WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {CostProfileWidgetConfig} from "controls/designer/features/widgets/cost/profile/costProfileWidgetConfig";
import {CostProfileWidgetPersistedState} from "controls/designer/features/widgets/cost/profile/costProfileWidgetPersistedState";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {getCostBudget, getCostProfile, listCostProfiles} from "areas/cost/api";
import {BudgetTable} from "areas/cost/budget/components/BudgetTable";
import {CostModelDropDown} from './../costModelDropDown';
import {CostProfile} from 'areas/cost/models/costProfile';
import {CostBudget, CostBudgetData} from 'areas/cost/models/costBudget';
import {AntInputNumber} from 'controls/react/ant/antInputNumber';
import { ExpandedState } from 'areas/cost/budget/components/ExpandedState';
import { CostBudgetItem, FetchType } from 'areas/cost/models/costBudgetItem';
import { CostsRouter } from 'areas/cost/bundleDescription';
import { State } from 'tools';
import { CurrencyDropDown } from "../currencyDropDown";
import {CostTableViewType} from "areas/cost/budget/components/CostTableViewType";
import {getSortDirectionDataSource, SortDirection} from 'areas/cost/models/sortDirection';
import { RemoteEventsManager } from 'core';
import { CostReportEvent } from 'framework/entities/events';
import { throttle } from 'lodash';
import b_ from 'b_';
import {TimeSettings} from "../budget/timeSettings";
import {CostModel} from "areas/cost/models/costModel";
import {costTranslates} from "areas/cost/translations";
import { deserialize } from 'serializr';
import {SketchColorPicker} from "../../../../../react/colorPicker/sketchColorPicker";
import {getHtmlContainer} from "controls/designer/features/htmlContainerShape";
import { CostFilterEditor } from '../costFilterEditor';
import {AntInput} from "controls/react/ant/antInput";
import {getCurrencyConversionErrorString} from "../../../../../../areas/cost/costHelper";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import { showColumnsToColumns } from 'areas/cost/budget/components/BudgetTableStore';

const i = require('core/localization').translator({
  "Show cost": {
    "no": "Vis kostnad"
  },
  "Show budget": {
    "no": "Vis budsjett"
  },
  "Show estimate": {
    "no": "Vis estimat"
  },
  "Show listing price": {
    "no": "Vis listepris"
  },
  "Display as 1000": {
    "no": "Vises som 1000"
  },
  "Display as 1000 tooltip": {
    "en": "Values are divided by 1000",
    "no": "Verdier er delt med 1000"
  },
  "Decimal Numbers": {
    "no": "Desimalnummer",
    "en": "Decimal number"
  },
  "Cost Model": {
    "no": "Kostmodell",
    "en": "Costmodel"
  },
  "Model": {
    "no": "Modell"
  },
  "Cost Sheet": {
    "no": "Kostfane",
    "en": "Costsheet"
  },
  "Font Size": {
    "no": "Skriftstørrelse",
    "en": "Font size"
  },
  "Fit content to widget": {
    "no": "Tilpass innhold til widget størrelse"
  },
  "Fit content to widget tooltip": {
    "en": "Automatic modify the column width to fit the widget size. Applies only for a single selection of cost, budget or listing price.\nMinimum display width is 1360 px.",
    "no": "Endrer automatisk kolonne bredden for å passe til Widget størrelse. Gjelder kun for ett enkelt utvalg av kostnad, budsjett eller listepris.\nMinimum bredde er 1360 px."
  },
  "Color Settings": {
    "no": "Farge valg",
    "en": "Color settings"
  },
  "Font Color": {
    "no": "Skriftfarge",
    "en": "Font color"
  },
  "Line 1 Color": {
    "no": "Linjefarge 1",
    "en": "Line 1 color"
  },
  "Line 2 Color": {
    "no": "Linjefarge 2",
    "en": "Line 2 color"
  },
  "Please fill all filter values": {
    "no": "Fyll inn filterverdier",
    "en": "Please fill all filter values"
  }
},
sharedLocalization, costTranslates);

export function getWidgetDescription(){
	return {
		form: CostProfileWidgetConfiguration,
		defaultConfig: {
			type: 'cost-profile',
			title: i('Cost Model'),
			accountId: ApplicationState.accountId,
			modelId: '',
			decimalsNumber: 0,
			showCost: true,
			profileId: null as string,
			timeDisplay: 'annual',
			timeDisplayValue: 'CURRENT_YEAR',
			monthOrder: SortDirection.Asc,
			currentMonthFirst: false,
			fontSize: "12px",
			fontColor: { hex: "#262626"},
			line1Color: { hex: "#e8e8e8"},
			line2Color: { hex: "#808080"},
			filter: null
		} as CostProfileWidgetConfig,
		widget: CostProfileWidget,
		fullTitle: i('Cost') + '/' + i('Model'),
	}
}

const configurationB = require('b_').with('cost-profile-widget-configuration');

const CostProfileWidgetConfiguration = observer((props:WidgetConfigurationProps<CostProfileWidgetConfig>) => {
	const {configLink} = props;

	const accountLink = configLink.get('accountId');
	const profileIdLink = configLink.get('profileId');
	const filterLink = configLink.get('filter');

	const [profiles, setProfiles] = React.useState<CostProfile[]>([]);
	React.useEffect(() => {
		if(accountLink.value == null)
			return;

		apiFetch(listCostProfiles(accountLink.value))
			.then(response => {
				if(response.success){
					setProfiles(response.data);
				}
			});
	}, [accountLink]);

	profileIdLink.changed(() => {
		configLink.get('modelId').update(undefined);
	}).required();

	accountLink.changed(() => {
		profileIdLink.update(undefined)
	})

	filterLink.check(i('Please fill all filter values'), (value) => {
		if(value == null) {
			return true;
		}
		return !!(value.type && value.operator && (value.value || value.value == 0));
	});

	const orderSelectionValues = useMemo(getSortDirectionDataSource, []);

	const fontSizes = useMemo(() => {
		return Array.from({ length: 20 }, (_, i) => i)
			.map(i => `${6 + i*2}px`)
			.map(x => ({id: x, name: x}));
	}, [])

	return <Section appearance={"none"}
		contentPadding={false}
		childrenPadding={true}>
		<Section appearance={"frame"} title={i('Configuration')} childrenPadding={true}>
			<FormEntry label={i('Title')}>
				<AntInput {...configLink.get('title').props}/>
			</FormEntry>
			<FormEntry label={i('Account')}>
				<AccountDropDown {...accountLink.props}/>
			</FormEntry>
		</Section>
		<Section appearance={"frame"} title={i('Datasource')} childrenPadding={true}>
			<FormEntry label={i('Cost Model')} valueLink={profileIdLink}>
				<AntSelect dataList={profiles} placeholder={i('Select...')}
					disabled={accountLink.value == null}/>
			</FormEntry>

			<FormEntry label={i('Cost Sheet')} width={'fit'} valueLink={configLink.get('modelId').required()}>
				<CostModelDropDown accountId={configLink.get('accountId').value}
				                   profileId={configLink.get('profileId').value}/>
			</FormEntry>
		</Section>
		<TimeSettings
			configLink={configLink}
			accountId={accountLink.value}
			profileId={configLink.get('profileId').value}
			modelId={configLink.get('modelId').value}
			onlyAnnual={true}
		>
			<FormEntry label={i('Month Order')} width={'fit'} valueLink={configLink.get('monthOrder')}>
				<AntSelect dataList={orderSelectionValues} defaultValue={SortDirection.Asc}/>
			</FormEntry>
			<FormEntry width={'fit'}>
				<AntCheckbox valueLink={configLink.get('currentMonthFirst')}>{i('Current month first')}</AntCheckbox>
			</FormEntry>
		</TimeSettings>
		<Section appearance={"frame"} title={i('Display settings')} childrenPadding={true} direction={"column"}>
			<FormEntry label={i('Decimal Numbers')} width={'fit'}>
				<AntInputNumber valueLink={configLink.get('decimalsNumber')} min={0} max={8} defaultValue={0} />
			</FormEntry>
			<FormEntry label={i('Currency')} width={'fit'} valueLink={configLink.get('currency')}>
				<CurrencyDropDown accountId={configLink.get('accountId').value}/>
			</FormEntry>
			<FormEntry width={'fit'} vertical={true} valueLink={configLink.get('filter')}>
				<CostFilterEditor/>
			</FormEntry>
			<Section appearance={"none"} contentClass={configurationB('display-settings')}>
				<AntCheckbox valueLink={configLink.get('displayAsThousands')} title={i('Display as 1000 tooltip')}>{i('Display as 1000')}</AntCheckbox>
				<AntCheckbox valueLink={configLink.get('fitContent')} title={i('Fit content to widget tooltip')}>{i('Fit content to widget')}</AntCheckbox>
			</Section>
		</Section>
		<Section appearance={"frame"} title={i('Columns')} childrenPadding={false} direction={"column"} contentClass={configurationB('columns-editor')}>
			<AntCheckbox valueLink={configLink.get('showBudget')}>{i('Budget')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showCost')}>{i('Cost')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showCurrentMonthEstimate')}>{i('Current month estimate')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showInformation')}>{i('Information')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showListingPrice')}>{i('Listing price')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showSplit')}>{i('Split')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showYearEstimate')}>{i('Year Estimate')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showCostRate')}>{i('Percentage change')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showStatus')}>{i('Status')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showAction')}>{i('Action')}</AntCheckbox>
		</Section>
		<Section appearance={'frame'} title={i('Color Settings')} childrenPadding={true}>
			<FormEntry width={'fit'} label={i('Font Size')}>
				<AntSelect {...configLink.get("fontSize").props} dataList={fontSizes}/>
			</FormEntry>
			<FormEntry width={'fit'} label={i('Font Color')}>
				<SketchColorPicker valueLink={configLink.get("fontColor")} />
			</FormEntry>
			<FormEntry width={'fit'} label={i('Line 1 Color')}>
				<SketchColorPicker valueLink={configLink.get("line1Color")} />
			</FormEntry>
			<FormEntry width={'fit'} label={i('Line 2 Color')}>
				<SketchColorPicker valueLink={configLink.get("line2Color")} />
			</FormEntry>
		</Section>
	</Section>
});

const b = b_.with('cost-profile-widget');
interface CostProfileWidgetState{
	loaded: boolean;
	loading: boolean;
	loadingError: boolean;
	errorMessage?: string;
	budget: CostBudget,
	sortSettings: any,
	columnWidths: {[x: string]: number},
	noBackground: boolean,
	backgroundColor: string,
}

const CostProfileWidget = observer(class CostProfileWidget extends React.Component<WidgetProps<CostProfileWidgetConfig, CostProfileWidgetPersistedState>, CostProfileWidgetState>{
	unsubscriber: {
		unsubscribe: () => void
 	}
	constructor(props: WidgetProps<CostProfileWidgetConfig, CostProfileWidgetPersistedState>) {
		super(props);
		const bgColor = document.getElementById(props.config.id).style.backgroundColor;
		this.state = {
			loaded: false,
			loading: false,
			loadingError: false,
			budget: null,
			sortSettings: null,
			columnWidths: null,
			noBackground: 'rgb(255, 255, 255)' !== bgColor ,
			backgroundColor: bgColor
		}
		props.designer.editorUi.addListener('styleChanged', this.setBackgroundColor);
	}
	render() {
		const { fontColor, fontSize, line1Color, line2Color } = this.props.config;
		return (
			<DefaultWidgetWrapper {...this.props}>
				<Section height={"fit"} overlaySpinner={this.state.loading} contentOverlay={this.state.loading} appearance={"none"}>
					{this.state.loadingError && <BoxView type={"error"}>{this.state.errorMessage || i('Costmodel/store/sheet is not found or has been deleted')}</BoxView>}
					{this.state.loaded &&
						<div className={b({'no-background': this.state.noBackground})}>
							<BudgetTable
								budget={this.state.budget}
								displayDecimals={this.props.config.decimalsNumber}
								displayAsThousands={this.props.config.displayAsThousands}
								showCost={this.props.config.showCost}
								showCostRate={this.props.config.showCostRate}
								showBudget={this.props.config.showBudget}
								showYearEstimate={this.props.config.showYearEstimate}
								showCurrentMonthEstimate={this.props.config.showCurrentMonthEstimate}
								showInformation={this.props.config.showInformation}
								showListingPrice={this.props.config.showListingPrice}
								showSplit={this.props.config.showSplit}
								showName={this.props.config.showName}
								showAction={this.props.config.showAction}
								showStatus={this.props.config.showStatus}
								monthOrder={this.props.config.monthOrder}
								currentMonthFirst={this.props.config.currentMonthFirst}
								lockValues={true}
								lockStructure={true}
								expandedState={this.props.persistedState.expandedState}
								onExpandedStateChanged={this.onExpandStateChanged}
								viewType={CostTableViewType.Widget}
								onSortChanged={this.onSortSettingsChanged}
								onColumnResize={this.onColumnResize}
								columnWidths={this.state.columnWidths}
								sortSettings={this.state.sortSettings}
								onRowClick={this.onProfileRowClicked}
								linkRedirectDisabled = {this.props.redirectConfig?.type == 'None'}
								viewScale={this.props.designer.graph.view.scale}
								compact={this.props.config.fitContent}
								styles={{fontSize, fontColor: fontColor.hex, line1Color: line1Color.hex, line2Color: line2Color.hex, bgColor: this.state.backgroundColor}}
							/>
						</div>
					}
				</Section>
			</DefaultWidgetWrapper>
		)
	}

	async componentDidMount() {
		await this.loadCostProfile();
		this.subscribe();
	}

	componentWillUnmount(): void {
		this.unsubscriber?.unsubscribe();
		this.props.designer.editorUi.removeListener(this.setBackgroundColor);
	}

	get expandedRowIds() {
		return this.props.persistedState.expandedState?.expandedRowKeys;
	}

	private loadCostProfile = async () => {
		const timeout = setTimeout(() => {
			this.setState({loading: true});
		}, 500);
		const filter = this.props.config.filter;
		const request = getCostProfile(
			this.props.config.profileId,
			{
				modelId: this.props.config.modelId,
				accountId: this.props.config.accountId,
				currency: this.props.config.currency || 'NOK',
				filter,
				columns: this.showColumns,
				fetch: FetchType.Eager,
				expandedRowIds: this.expandedRowIds
			},
			(data) => {
				return deserialize(CostProfile, data, () => null, {readonly: true});
			}
		)
		const response = await apiFetch(request);

		if(!response.success){
			clearTimeout(timeout);
			this.setState({
				loadingError: true,
				errorMessage: getCurrencyConversionErrorString(response.message),
				loading: false
			});
			return;
		}

		const profile = response.data;
		const model = profile.costModels.find(model => model.id == this.props.config.modelId)
		const budget = await this.getBudget(model);
		if(!budget) {
			return;
		}

		clearTimeout(timeout);
		this.setState({
			loaded: true,
			budget: budget,
			loading: false,
			sortSettings: this.props.persistedState.sortSettings,
			columnWidths: this.props.persistedState.columnWidths
		});
	}

	private async getBudget(model: CostModel) {

		const {timeDisplayValue, filter} = this.props.config;
		if(timeDisplayValue === 'CURRENT_YEAR')
			return model.costBudgets[0];
		const budget = model.costBudgets.find(x => x.startDate.year() == timeDisplayValue);
		if(!budget.costBudget) {
			const {profileId, modelId, accountId} = this.props.config;
			const request = getCostBudget(profileId, {
				modelId,
				budgetId: budget.budgetId,
				accountId,
				filter,
				columns: this.showColumns,
				fetch: FetchType.Eager,
				expandedRowIds: this.expandedRowIds
			}, (data) => {
				return deserialize(CostBudgetData, data, () => null, {readonly: true});
			});
			const response = await apiFetch(request);
			if(!response.success) {
				this.setState({ loadingError: true, loading: false });
				return null;
			}
			budget.costBudget = response.data;
		}
		return budget;
	}

	private get showColumns() {
		return showColumnsToColumns(this.props.config)
	}

	private onExpandStateChanged = (state: ExpandedState): void => {
		this.props.persistedState.expandedState = state.toJSON();
	}

	private onSortSettingsChanged = (settings: any) => {
		this.setState({
			sortSettings: settings
		});
	}

	private onColumnResize = (columnKey: string, width: number) => {
		const widthData = Object.assign(this.state.columnWidths ?? {}, {[columnKey]: width});
		this.setState({
			columnWidths: widthData
		});
	}

	private setBackgroundColor = (sender: any, e: any) => {
		const {id} = getHtmlContainer(this.props.designer.graph, e.properties.cells[0]);
		if(id !== this.props.config.id)
			return;
		setTimeout(() => {
			const bgColor = e.properties.values[0];
			this.setState({
				noBackground: 'rgb(255, 255, 255)' !== bgColor ,
				backgroundColor: bgColor
			});
		}, 0)
	}

	getStateForSaving() {
		return {
			expandedState: this.props.persistedState.expandedState,
			sortSettings: {order: this.state.sortSettings?.order, columnKey: this.state.sortSettings?.columnKey},
			columnWidths: this.state.columnWidths
		};
	}

	onProfileRowClicked = (record: CostBudgetItem) => {
		const url = CostsRouter.details(this.state.budget.parent.costProfileId, record.id);
		this.props.navigator.go({url: url})
	}

	subscribe = () => {
		this.unsubscriber = RemoteEventsManager.subscribeCallback([
			CostReportEvent.subscription(this.props.config.modelId, {period: TimePeriodType.CurrentYear})
		],
		this.onEvent);
	}

	onEvent = throttle((e: CostReportEvent) => {
		if (!CostReportEvent.is(e)) {
			return;
		}

		this.loadCostProfile();
	}, 1000, {trailing: false});
});
