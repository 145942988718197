import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import Highcharts from 'highcharts';
import Renderer from 'tools/renderer';
import ErrorHandler from 'core/errorHandler';
import Configuration from 'configuration';

import {translator} from 'core';
import stripZeros from 'areas/sla/widgets/stripZeros';
import {ApplicationState} from "../../../framework/applicationState";
import {CssVariables} from "styles/cssVariables";

const i = translator({
  "SLA » {0}": {
    "no": "SLA » {0}"
  }
});

export function HistoryWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	if (!this.accountName) {
		this.accountName = this.configuration.accountName || Cookies.CeesoftCurrentAccountName;
	}
	this.timeZone ??= ApplicationState.timezone;
	this.initComponent();
};

export {HistoryWidget as default};

jQuery.extend(HistoryWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		var widgetDiv = $('#' + this.id);
		this.serviceNameLoaded = false;
		this.slaNameLoaded = false;
		this.isDetailsView = this.isDetailsView || false;
		widgetDiv.off();
		widgetDiv.on('drop', $.proxy(this.onDrop, this));
		if (this.renderTo) {
			this.getInitialData();
		} else {
			this.getServiceName();
			this.getSlaName();
		}
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: widgetDiv,
		});
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		this.widgetContentDiv.find('#cw_back').on('click', $.proxy(this.onBackButton, this));
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		this.widgetContentDiv.find('#cw_back').off();
	},
	/**
	 * Retrieves the service name from the server and updates the title
	 */
	getServiceName: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/name/';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.serviceName = result.data;
				this.serviceNameLoaded = true;
				this.updateTitle();
			} else {
				this.showErrorMessage(result.message);
			}
		}, this));
	},
	/**
	 * Gets from the server the SLA name
	 */
	getSlaName: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/slas/' + this.configuration.slaId + '/name/';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.slaName = result.data;
				this.slaNameLoaded = true;
				this.updateTitle();
			} else {
				this.showErrorMessage(result.message);
			}
		}, this));
	},
	/**
	 * Gets the inistial data to populate the chart
	 */
	getInitialData: function () {
		if (this.renderTo) {
			this.widgetContentDiv = $('#' + this.renderTo);
		} else {
			this.widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		}
		kendo.ui.progress(this.widgetContentDiv, true);
		this.queryUrl = this.requestPath + 'services/' + this.configuration.serviceId + '/slas/' + this.configuration.slaId + '/history?maxResults=' + this.configuration.periods;
		Utils.ajax(this.queryUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.render(result.data);
			} else {
				kendo.ui.progress(this.widgetContentDiv, false);
				this.showErrorMessage(result.message, 'info', 0);
			}
		}, this), function () {
			kendo.ui.progress(this.widgetContentDiv, false);
		}, 60000, $.proxy(function () {
			kendo.ui.progress(this.widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	/**
	 * Renders the gauge
	 * @param {Object} data The chart's data
	 */
	render: function (data) {
		var scope = this, isComplied;
		if (data) {
			var html = '<div class="cw_sla_history"></div><div class="cw_sla_root_cause hide"><div class="cw_sla_header"></div><div id="sla_history_root"></div></div>';
			this.widgetContentDiv.empty().append(html);

			this.dataSet = data.sort(function (a, b) {
				var diff = parseFloat(a.start) - parseFloat(b.start);
				return diff;
			});
			this.percentageSerie = [];
			var categories = [];
			var periodTag = lang.DAY;
			if (this.dataSet.length) {
				periodTag = this.dataSet[0].periodTag;
			}
			for (var i = 0, length = this.dataSet.length; i < length; i++) {
				if (periodTag === lang.DAY) {
					categories.push(Renderer.browserDateRenderer(this.dataSet[i].start, "date", null, this.timeZone));
				}
				if (periodTag === lang.WEEK) {
					//categories.push(lang.WEEK[0] + (i + 1));
					categories.push(lang.WEEK[0] + Utils.getWeekOfMonth(this.dataSet[i].start));
				}
				if (periodTag === lang.MONTH) {
					categories.push(Renderer.browserDateRenderer(this.dataSet[i].start, '', 'MMM', this.timeZone));
				}

				isComplied = this.dataSet[i].percentage >= this.dataSet[i].threshold ? true : false;
				this.percentageSerie.push({
					// x: this.dataSet[i].start,
					name: Renderer.browserDateRenderer(this.dataSet[i].start, '', Utils.widgetDateFormat(Cookies.CeesoftUserDateFormat, 'slaHistory'), this.timeZone),
					color: isComplied ? CssVariables.severityNone : CssVariables.severityCritical,
					y: this.dataSet[i].percentage,
					periodCompletion: this.dataSet[i].periodCompletion,
					threshold: this.dataSet[i].threshold,
					startDate: this.dataSet[i].start,
					startDateUTC: this.dataSet[i].startUTC,
					endDate: this.dataSet[i].end,
					endDateUTC: this.dataSet[i].endUTC,
					isComplied: isComplied
				});
				periodTag = this.dataSet[i].periodTag;
			}
			let scope = this;
			this.chart = new Highcharts.Chart({
				chart: {
					type: 'column',
					renderTo: this.widgetContentDiv.find('.cw_sla_history')[0],
					height: this.widgetContentDiv.height(),
					marginTop: 30,
					marginBottom: 40,
					marginRight: 20,
					backgroundColor: 'transparent'
				},
				title: {
					text: null
				},
				legend: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				exporting: Configuration.highcharts.exporting,
				xAxis: {
					categories: categories,
					labels: {
						staggerLines: 2
					}
				},
				yAxis: {
					title: {
						text: lang.ACHIEVED_PERCENTAGE,
						margin: 0
					},
					labels: {
						formatter: function () {
							var label = stripZeros(this.value, scope.configuration.decimals) + '%';
							return label;
						}
					},
					min: 0,
					max: 100,
					stackLabels: {
						enabled: true,
						overflow: 'allow',
						crop: false,
						style: {
						 	visibility: 'visible',
						 	fontWeight: 'normal'
						},
						formatter: function () {
							var percentage;
							var point = this.points['0,' + this.x + ',0'];
							if (point) {
								percentage = stripZeros(point[1], scope.configuration.decimals);
							}
							return percentage + '%';
						}
					}
				},
				tooltip: {
					crosshairs: false,
					backgroundColor: 'white',
					borderWidth: 0,
					shadow:{
						offsetX: 0,
						offsetY: 0,
						opacity: 1,
						width: 16,
						color: 'rgb(0,0,0,0.01)'
					},
					useHTML: true,
					formatter: function () {
						var label = '';
						let breachedPercentage = stripZeros(100 - this.y, scope.configuration.decimals);
						let achievedPercentage = stripZeros(this.y, scope.configuration.decimals);
						label += lang.BREACH_PERCENTAGE + ': ' + breachedPercentage + '%' + '<br/>';
						label += lang.ACHIEVED_PERCENTAGE + ': ' + achievedPercentage + '%' + '<br/>';
						label += lang.widget.PERIOD_COMPLETION + ': ' + stripZeros(this.point.options.periodCompletion, scope.configuration.decimals) + '%' + '<br/>';
						label += lang.designer.THRESHOLD + ': ' + stripZeros(this.point.options.threshold, scope.configuration.decimals) + '%' + '<br/>';
						label += lang.PERIOD + ': ';
						switch (periodTag) {
							case lang.DAY:
								label += Renderer.browserDateRenderer(this.point.options.startDate, "date", null, scope.timeZone);
								break;
							case lang.WEEK:
								label += Renderer.browserDateRenderer(this.point.options.startDate, "date", null, scope.timeZone) + ' - ' + Renderer.browserDateRenderer(this.point.options.endDate, "date", null, scope.timeZone);
								break;
							case lang.MONTH:
								var yearStart = new Date(this.point.options.startDate).getUTCFullYear();
								var yearEnd = new Date(this.point.options.endDate).getUTCFullYear();
								var monthFormat = 'MMM';
								if (yearStart !== yearEnd) {
									monthFormat = 'MMM \'yy';
								}
								label += Renderer.browserDateRenderer(this.point.options.startDate, '', monthFormat, scope.timeZone);
								break;

						}
						scope.label = label;
						return '<div>' + label + '</div>';
					},
					positioner: $.proxy(function(labelWidth, labelHeight, point) {
						var chartWidth = this.widgetContentDiv.find('.highcharts-plot-background').width()
						var pointX = point.plotX;
						var tooltipX;
						//move the tooltip to left or right, depending on the bar position, in order not to cover the bar

						if (pointX > 120) {
							tooltipX = pointX - 125;
						} else {
							tooltipX = pointX + 105;
						}


						/*if (pointX < chartWidth / 2) {
							tooltipX = pointX - 100;
						} else {
							tooltipX = pointX + 100;
						}*/
						//var tooltipX = point.plotX + 200;
						var tooltipY = point.plotY;
						return {
							x: tooltipX,
							y: tooltipY
						};
					}, this),
					outside: false
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						}

					},
					series: {
						cursor: 'pointer',
						minPointLength: 2,
						events: {
							click: $.proxy(this.onChartClick, this)
						}
					}
				},
				series: [{
					name: lang.ACHIEVED_PERCENTAGE,
					data: this.percentageSerie
				}]
			});
			//solution for setting red the text label for red chart bars (highcharts seems to allow only one color for all labels)
			this.widgetContentDiv.find('.highcharts-stack-labels text').each(function(index) {
				let series = scope.widgetContentDiv.find('.highcharts-series');
				let seriesColor = $(series.find('rect')[index]).attr('fill');
				if (seriesColor === '#d93b20') {
					$(this).css('fill', seriesColor);
				}
			});
			kendo.ui.progress(this.widgetContentDiv, false);
		}
	},
	/**
	 * Handler for the sla percentage click event
	 * @param {object} e The click event
	 */
	onChartClick: function (e) {
		this.startDateUTC = e.point.startDateUTC;
		this.endDateUTC = e.point.endDateUTC;
		if (!this.isDetailsView) {
			this.widgetContentDiv.find('.cw_sla_history').addClass('hide');
			this.widgetContentDiv.find('.cw_sla_root_cause').removeClass('hide');
			this.createHeader();
			this.initRootCauseGrid();
		} else {
			this.configuration.showStateChanges(e);
		}
	},
	/**
	 * Function for creating the header in the root cause view
	 */
	createHeader: function () {
		var label = this.label.split('<br/>');
		var html = '<div class="cwServiceName">' + lang.SERVICE + ': ' + this.configuration.serviceName + '</div>';
		for (var i = 0; i < label.length; i++) {
			if (i !== 3) {
				html += '<div class="w50 left">' + label[i] + '</div>';
			} else {
				html += '<div class="w50 left expand ellipsis" title="' + label[i] + '">' + label[i] + '</div>';
			}
		}
		html += '<span id="cw_back" class="glyphicons arrow-left back"></span>';
		this.widgetContentDiv.find('.cw_sla_header').html(html);
		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Handler function for the back button in widget
	 */
	onBackButton: function () {
		if (this.rootCauseGrid) {
			this.rootCauseGrid.destroy();
		}
		this.widgetContentDiv.find('.cw_sla_history').removeClass('hide');
		this.widgetContentDiv.find('.cw_sla_root_cause').addClass('hide');
	},
	/**
	 * Function for creating the root cause grid
	 */
	initRootCauseGrid: function () {
		this.rootCauseGrid = this.widgetContentDiv.find('#sla_history_root').kendoCustomGrid({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: `${this.requestPath}services/${this.configuration.serviceId}/slas/${this.configuration.slaId}/calculation/states/causes?fromTime=${this.startDateUTC}&toTime=${this.endDateUTC}`,
						contentType: "application/json; charset=utf-8",
						type: "POST",
						dataType: "json",
						cache: false
					},
					parameterMap: function (data) {
						return kendo.stringify(data);
					}
				},
				schema: {
					data: function (response) {
						return response.items;
					},
					total: function (response) {
						this.visibleItems = response.visible;
						this.totalItems = response.total;

						return response.visible;
					}
				},
				error: ErrorHandler.kendoServerError,
			}),
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			scrollable: false,
			columns: [
				{
					field: 'fromTime',
					title: lang.reports.DATE_FROM,
					sortable: true,
					filterable: false,
					template: (item) => Renderer.browserDateRenderer(item.startTime, "datetime", "", this.timeZone),
					hidden: false,
					width: 120
				},
				{
					field: 'duration',
					title: lang.slas.DOWN_TIME,
					sortable: true,
					filterable: {
						ui: function (element) {
							element.kendoDropDownList({
								dataSource: [{
									text: '1 ' + lang.HOUR,
									value: 3600000
								}, {
									text: '5 ' + lang.HOURS,
									value: 18000000
								}, {
									text: '10 ' + lang.HOURS,
									value: 36000000
								}, {
									text: '1 ' + lang.DAY,
									value: 84600000
								}],
								dataTextField: 'text',
								dataValueField: 'value',
								optionLabel: lang.grid.FILTER_SELECT_VALUE
							});
						}
					},
					hidden: false,
					template: '#=Renderer.duration(duration)#',
					attributes: {
						'class': 'cw_state_duration'
					},
					width: 100
				},
				{
					field: 'description',
					title: lang.MESSAGE
				}
			]
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.rootCauseGrid);
		this.adjustSectionHeight();
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function () {
		if (this.serviceNameLoaded && this.slaNameLoaded) {
			if (!this.title) {
				this.setTitleIfEmpty(i('SLA » {0}', this.slaName))
			}
			this.getInitialData();
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		this.render(this.dataSet);
		// State.currentApp.saveServiceBoard();
	},
	onDrop: function () {
		this.render(this.dataSet);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
	},
	adjustSectionHeight: function () {
		var section = $('.cw_section_content');
		var sectionHeight = section.height() - $('.cw_sla_header').outerHeight();
		section.find('#sla_history_root').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 29);
	}
});
