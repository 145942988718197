import {MonitorType} from "areas/assets/monitors/monitorType"

export function getDescriptions() {
	return [{
		url: '/monitors/:monitorType',
		getModuleImport: () => import('./monitors-list/monitorsListPage'),
		loadingIndicator: false,
		routeId: 'monitors_by_type'
	}, {
		url: '/monitors',
		getModuleImport: () => import('./monitors-list/monitorsListPage'),
		loadingIndicator: false,
		routeId: 'monitors'
	}, {
		url: MonitorsRouter.list(MonitorType.Container) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/container/details'),
		routeId: 'monitor_container'
	}, {
		url: MonitorsRouter.list(MonitorType.Database) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/database/details'),
		routeId: 'monitor_database'
	}, {
		url: MonitorsRouter.list(MonitorType.DbWatch) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/dbwatch/details'),
		routeId: 'monitor_db-watch'
	}, {
		url: MonitorsRouter.list(MonitorType.EatonUps) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/eatonups/details'),
		routeId: 'monitor_eatonups'
	}, {
		url: MonitorsRouter.list(MonitorType.FileSearch) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/filesearch/details'),
		routeId: 'monitor_file-search'
	}, {
		url: MonitorsRouter.list(MonitorType.InterfaceTraffic) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/interface-traffic/details'),
		routeId: 'monitor_interface-traffic'
	},{
		url: MonitorsRouter.list(MonitorType.ProcessesNew) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/processes-new/details'),
		routeId: 'monitor_processes-monitor'
	}, {
		url: MonitorsRouter.list(MonitorType.Log) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/log/details'),
		routeId: 'monitor_log-monitor'
	}, {
		url: MonitorsRouter.list(MonitorType.Metric) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/metric/details'),
		routeId: 'monitor_metric-monitor'
	}, {
		url: MonitorsRouter.list(MonitorType.Modbus) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/modbus/details'),
		routeId: 'monitor_modbus'
	}, {
		url: MonitorsRouter.list(MonitorType.RemoteSeries) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/remoteiseries/details'),
		routeId: 'monitor_remote-ibm-iseries'
	}, {
		url: MonitorsRouter.list(MonitorType.RemoteSystem) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/rsm/details'),
		routeId: 'monitor_remote-system'
	}, {
		url: MonitorsRouter.list(MonitorType.Script) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/script/details'),
		routeId: 'monitor_script'
	}, {
		url: MonitorsRouter.list(MonitorType.Snmp) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/snmp/details'),
		routeId: 'monitor_snmp'
	}, {
		url: MonitorsRouter.list(MonitorType.SnmpTrap) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/snmp-trap/details'),
		routeId: 'monitor_snmp-trap'
	}, {
		url: MonitorsRouter.list(MonitorType.System) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/system/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/system/Details.jsp',
		routeId: 'monitor_system'
	}, {
		url: MonitorsRouter.list(MonitorType.Url) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/url/details'),
		routeId: 'monitor_url'
	}, {
		url: MonitorsRouter.list(MonitorType.XenServer) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/xenserver/details'),
		routeId: 'monitor_xen-server'
	}, {
		url: MonitorsRouter.list(MonitorType.External) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/external/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/external/Details.jsp',
		routeId: 'monitor_external'
	}, {
		url: MonitorsRouter.list(MonitorType.Connectivity) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/connectivity/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/connectivity/Details.jsp',
		routeId: 'monitor_connectivity'
	}, {
		url: MonitorsRouter.list(MonitorType.Groovy) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/groovy/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/groovy/Details.jsp',
		routeId: 'monitor_groovy'
	}, {
		url: MonitorsRouter.list(MonitorType.HyperV) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/hyperv/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/hyperv/Details.jsp',
		routeId: 'monitor_hyperv'
	}, {
		url: MonitorsRouter.list(MonitorType.IbmSvc) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/ibmsvc/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/ibmstorwize/Details.jsp',
		routeId: 'monitor_ibm-svc'
	}, {
		url: MonitorsRouter.list(MonitorType.MailGateway) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/mail-gateway/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/mailgateway/Details.jsp',
		routeId: 'monitor_mail-gateway'
	}, {
		url: MonitorsRouter.list(MonitorType.MsCluster) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/ms-cluster/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/mscluster/Details.jsp',
		routeId: 'monitor_ms-cluster'
	}, {
		url: MonitorsRouter.list(MonitorType.Nagios) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/nagios/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/nagios/Details.jsp',
		routeId: 'monitor_nagios'
	}, {
		url: MonitorsRouter.list(MonitorType.NimSoft) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/nimsoft/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/nimsoft/Details.jsp',
		routeId: 'monitor_nimsoft'
	}, {
		url: MonitorsRouter.list(MonitorType.Processes) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/processes/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/process/Details.jsp',
		routeId: 'monitor_processes'
	}, {
		url: MonitorsRouter.list(MonitorType.Prtg) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/prtg/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/prtg/Details.jsp',
		routeId: 'monitor_prtg'
	}, {
		url: MonitorsRouter.list(MonitorType.SolarWinds) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/solarwinds/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/solarwinds/Details.jsp',
		routeId: 'monitor_solar-winds'
	}, {
		url: MonitorsRouter.list(MonitorType.Vmm) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/vmm/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/vmm/Details.jsp',
		routeId: 'monitor_vmm'
	}, {
		url: MonitorsRouter.list(MonitorType.VmWare) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/vmware/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/vmware/Details.jsp',
		routeId: 'monitor_vmware'
	}, {
		url: MonitorsRouter.list(MonitorType.WindowsEvents) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/windows-events/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/eventlog/Details.jsp',
		routeId: 'monitor_windows-events'
	}, {
		url: MonitorsRouter.list(MonitorType.WindowsServices) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/windows-services/details'),
		jspUrl: 'assets/js/ceeview/assethealth/monitors/service/Details.jsp',
		routeId: 'monitor_windows-service'
	}, {
		url: MonitorsRouter.list(MonitorType.Icinga) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/icinga/details'),
		routeId: 'monitor_icinga'
	}, {
		url: MonitorsRouter.list(MonitorType.Webhook) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/webhook/details'),
		routeId: 'monitor_webhook'
	}, {
		url: MonitorsRouter.list(MonitorType.Azure) + '/:id',
		getModuleImport: () => import('areas/assets/monitors/azure/details'),
		routeId: 'monitor_azure'
	}]
}

export class MonitorsRouter {
	static list(monitorType) {
		let url = '/monitors'

		if (monitorType) {
			url += '/' + monitorTypeToUrl(monitorType)
		}

		return url
	}

	static create(monitorType) {
		return '/monitors/' + monitorTypeToUrl(monitorType) + '/new'
	}

	static createScriptMonitor(scriptType) {
		return '/monitors/' + monitorTypeToUrl(MonitorType.Script) + '/new/?scriptType=' + scriptType
	}

	static details(monitorType, monitorId) {
		return MonitorsRouter.list(monitorType) + '/' + monitorId
	}

	static createCopy(monitorType, accountId, sourceId, name) {
		return `/monitors/${monitorTypeToUrl(monitorType)}/new?accountId=${accountId}&sourceId=${sourceId}&name=${name}`
	}
}

export function urlToMonitorType(url) {
	const entry = Object.entries(monitorUrlToTypeMap).find(x => x[1] == url)
	if (entry == null)
		return null

	return entry[0]
}

export function monitorTypeToUrl(monitorType) {
	return monitorUrlToTypeMap[monitorType]
}

const monitorUrlToTypeMap = {
	[MonitorType.FileSearch]: 'file-search',
	[MonitorType.Snmp]: 'snmp',
	[MonitorType.SnmpTrap]: 'snmp-trap',
	[MonitorType.Vmm]: 'vmm',
	[MonitorType.XenServer]: 'xen-server',
	[MonitorType.RemoteSystem]: 'remote-system',
	[MonitorType.Metric]: 'metric-monitor',
	[MonitorType.Log]: 'log-monitor',
	[MonitorType.Database]: 'database',
	[MonitorType.EatonUps]: 'eaton-ups',
	[MonitorType.Modbus]: 'modbus',
	[MonitorType.Url]: 'url',
	[MonitorType.DbWatch]: 'db-watch',
	[MonitorType.Script]: 'script',
	[MonitorType.Container]: 'container',
	[MonitorType.InterfaceTraffic]: 'interface-traffic',
	[MonitorType.ProcessesNew]: 'processes-monitor',
	[MonitorType.Icinga]: 'icinga',
	[MonitorType.System]: 'system',
	[MonitorType.VmWare]: 'vmware',
	[MonitorType.External]: 'external',
	[MonitorType.NimSoft]: 'nimsoft',
	[MonitorType.WindowsServices]: 'windows-service',
	[MonitorType.WindowsEvents]: 'windows-events',
	[MonitorType.HyperV]: 'hyperv',
	[MonitorType.Nagios]: 'nagios',
	[MonitorType.Groovy]: 'groovy',
	[MonitorType.IbmSvc]: 'ibm-svc',
	[MonitorType.MsCluster]: 'ms-cluster',
	[MonitorType.MailGateway]: 'mail-gateway',
	[MonitorType.Prtg]: 'prtg',
	[MonitorType.SolarWinds]: 'solar-winds',
	[MonitorType.Connectivity]: 'connectivity',
	[MonitorType.AzureBilling]: 'azure-billing',
	[MonitorType.Processes]: 'processes',
	[MonitorType.AmazonBilling]: 'amazon-billing',
	[MonitorType.RemoteSeries]: 'remote-ibm-iseries',
	[MonitorType.Webhook]: 'webhook',
	[MonitorType.Azure]: 'azure'
}
