import {CustomDataEntry} from "controls/designer/customDataEntry";
import {newGuid} from "tools/guid";
import {RedirectType} from "controls/designer/features/redirectOnClick/redirectType";

export function iterateAllCells(graph, callback){
	let cells = graph.getModel().cells;
	let result = null

	for (var i in cells) {
		if (!cells.hasOwnProperty(i))
			continue;

		result = callback(cells[i]);
		if(result !== undefined){
			return result
		}
	}
}

export function iterateGraphParents(cell, callback){
	return iterateEdges(cell, edge => {
		if(edge.target == cell && edge.source != null){
			const result = callback(edge.source);
			if(result !== undefined){
				return result;
			}
		}
	});
}

export function iterateGraphChildren(cell, callback){
	return iterateEdges(cell, edge => {
		if(edge.source == cell && edge.target != null){
			const result = callback(edge.target);
			if(result !== undefined){
				return result;
			}
		}
	})
}

export function iterateParents(cell, callback) {
	const result = callback(cell);
	if (result !== undefined && result !== null)
		return [result, cell];

	if (cell.parent && cell.parent.id != 1) {
		return iterateParents(cell.parent, callback);
	}

	return [null, null];
}

export function iterateChildren(cell, callback) {
	callback(cell);

	if (cell.children) {
		for(let child of cell.children) {
			iterateChildren(child, callback);
		}
	}
}

export function isWindowHidden(selector){
	const window = $(selector).data('kendoWindow');
	if(!window)
		return true;

	return !$(selector).is(':visible');
}

export function traverseDown(startingCell, callback, skipInitialCell){
	return traverseTree(startingCell, iterateGraphChildren, callback, skipInitialCell)
}

export function traverseUp(startingCell, callback, skipInitialCell){
	return traverseTree(startingCell, iterateGraphParents, callback, skipInitialCell)
}

function traverseTree (startingCell, iterateFunction, callback = () => {}, skipInitialCell = false) {
	let visited = {};

	//deep-first search
	const dfs = function (cell) {
		if(cell != startingCell || !skipInitialCell) {
			const callbackResult = callback(cell);
			if(callbackResult !== undefined){
				return callbackResult;
			}
		}

		visited[cell.id] = cell;

		return iterateFunction( cell, iterationCell =>  {
			if (visited[iterationCell.id] != null) {
				return;
			}

			const callbackResult = dfs(iterationCell)
			if(callbackResult !== undefined){
				return callbackResult;
			}
		});

		return;
	}

	return dfs(startingCell);
}

export function iterateEdges(cell, callback){
	for (var i = 0; i < cell.getEdgeCount(); i++) {
		const result = callback(cell.getEdgeAt(i));
		if(result !== undefined) {
			return result;
		}
	}
}

export function getParentCell(cell){
	if(cell.geometry == null)
		return null;

	return iterateEdges(cell, edge => {
		if(edge.target == cell){
			return edge.source;
		}
	});
}

export function isCellGenerated(cell){
	if(!cell)
		return false;

	if(cell.value?.getAttribute == null)
		return false;

	return cell.value.getAttribute("generatedCell") == "true";
}


export function updateIfChanged(source, target, fields){
	let changed = false;

	fields.forEach(x => {
		let sourceKey, targetKey;
		if(Array.isArray(x)){
			sourceKey = x[0];
			targetKey = x[1];
		}else{
			sourceKey = targetKey = x;
		}

		if(source[sourceKey] !== target[targetKey]) {
			target[sourceKey] = source[targetKey];
			changed = true;
		}
	});

	return changed;
}

export function mergeConsumeEventResult(results){
	let mergedResult = {
		reload: false,
		redraw: false
	}

	for (const result of results){
		if(typeof result == 'boolean'){
			mergedResult.redraw = mergedResult.redraw || result;
		}else {
			mergedResult.reload = mergedResult.reload || result?.reload;
			mergedResult.redraw = mergedResult.redraw || result?.redraw;
		}
	}

	return mergedResult;
}

export function executeUpdate(graph, update){
	graph.getModel().beginUpdate();

	try{
		update(graph)
	}catch(e){
		console.error(e);
	}finally {
		graph.getModel().endUpdate();
	}
}

export function convertEmbeddedCustomDataToExternal(cells) {
	const result = []

	for (let i in cells) {
		if (!cells.hasOwnProperty(i))
			continue;

		const cell = cells[i]

		if (!cell.value)
			continue

		if (!cell.value.getAttribute)
			continue

		let customData = new CustomDataEntry({
			id: newGuid()
		})

		let plainDataSource = cell.getDatasource()

		if (plainDataSource) {
			plainDataSource.id = customData.id
			customData.dataSource = plainDataSource
			cell.removeAttribute('datasource')
			cell.removeAttribute('entityType')
		}

		if (cell.getRedirectConfig) {
			let redirectConfig = cell.getRedirectConfig()
			if (redirectConfig && redirectConfig.type != RedirectType.Default) {
				redirectConfig.id = customData.id
				customData.redirectConfig = redirectConfig
				cell.removeAttribute('redirectConfig')
				cell.removeAttribute('link')
				cell.removeAttribute('linkInNewTab')
			}
		}

		if (cell.getWidgetStoredConfig) {
			let widgetConfig = cell.getWidgetStoredConfig()
			if (widgetConfig) {
				widgetConfig.id = customData.id
				customData.widgetConfig = widgetConfig

				let persistedState = cell.getWidgetPersistedState()
				if (persistedState) {
					persistedState.id = customData.id
					customData.persistedState = persistedState
				}

				cell.removeAttribute("config")
				cell.removeAttribute("customProperties")
			}
		}

		if (Object.keys(customData).length) {
			cell.setAttribute("customDataId", customData.id)
			result.push(customData)
		}
	}

	return result
}
