import {Localization} from "core/localization/localization";

export const sharedDictionary = new Localization({
  "State - asset group - MAINTENANCE": {
    "no": "Ressursgrupper uten helseindeks: {0}",
    "en": "Assetgroups without healthindex: {0}"
  },
  "State - asset group - NONE": {
    "no": "Antall ressursgrupper i OK tilstand: {0}",
    "en": "Total assetgoups in OK state: {0}"
  },
  "State - asset group - MINOR": {
    "no": "Antall ressursgrupper i ADVARSEL tilstand: {0}",
    "en": "Total assetgroups in MINOR state: {0}"
  },
  "State - asset group - MAJOR": {
    "no": "Antall ressursgrupper i ALVORLIG tilstand: {0}",
    "en": "Total assetgroups in MAJOR state: {0}"
  },
  "State - asset group - CRITICAL": {
    "no": "Antall ressursgrupper i KRITISK tilstand: {0}",
    "en": "Total assetgroups in CRITICAL state: {0}"
  },
  "State - asset - MAINTENANCE": {
    "en": "Inactive assets: {0}",
    "no": "Inaktive ressurser: {0}"
  },
  "State - asset - NONE": {
    "no": "Totalt antall ressurer i OK tilstand: {0}",
    "en": "Total asset in OK state: {0}"
  },
  "State - asset - MINOR": {
    "no": "Totalt antall ressurser i advarsel tilstand: {0}",
    "en": "Total asset in MINOR state: {0}"
  },
  "State - asset - MAJOR": {
    "no": "Totalt antall ressurser i alvorlig tilstand: {0}",
    "en": "Total asset in MAJOR state: {0}"
  },
  "State - asset - CRITICAL": {
    "no": "Totalt antall ressurser i kritisk tilstand: {0}",
    "en": "Total asset in CRITICAL state: {0}"
  }
});
