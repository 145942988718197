
import {translator} from "core/localization";

let i = translator({
  "Create Service": {
    "no": "Opprett tjeneste",
    "en": "Create service"
  },
  "Create SLA": {
    "no": "Lag SLA"
  },
  "Create Account": {
    "no": "Lag konto",
    "en": "Create account"
  },
  "Create User": {
    "no": "Lag bruker",
    "en": "Create user"
  },
  "Create Team": {
    "no": "Opprett team",
    "en": "Create team"
  },
  "Create Asset": {
    "no": "Lag ressurs",
    "en": "Create asset"
  },
  "Create Assetgroup": {
    "no": "Lag ressursgruppe",
    "en": "Create assetgroup"
  },
  "Create Authentication": {
    "no": "Lag autentisering",
    "en": "Create authentication"
  },
  "Create Dashboard": {
    "no": "Opprett dashboard",
    "en": "Create dashboard"
  },
  "Create Report": {
    "no": "Lag rapport",
    "en": "Create report"
  },
  "Create Presentation": {
    "no": "Opprett presentasjon",
    "en": "Create presentation"
  },
  "Create Automation": {
    "no": "Opprett automatisering",
    "en": "Create automation"
  },
  "Create Filter": {
    "no": "Opprett filter",
    "en": "Create filter"
  },
  "Create Calendar": {
    "no": "Opprett Kalender",
    "en": "Create calendar"
  },
  "Create role": {
    "no": "Opprett Rolle",
    "en": "Create role"
  },
  "Create Application": {
    "no": "Opprett applikasjon",
    "en": "Create application"
  },
  "Create Connectivity": {
    "no": "Opprett tilkobling",
    "en": "Create connectivity"
  },
  "Create External": {
    "no": "Opprett ekstern",
    "en": "Create external"
  },
  "Create Hyper-V": {
    "no": "Opprett Hyper-V",
    "en": "Create Hyper-V"
  },
  "Create IBM SVC": {
    "no": "Opprett IBM SVC"
  },
  "Create Mail Gateway": {
    "no": "Opprett Epost mottak",
    "en": "Create mail gateway"
  },
  "Create MS Cluster": {
    "no": "Opprett MS Cluster"
  },
  "Create Nagios": {
    "no": "Opprett Nagios"
  },
  "Create Process": {
    "no": "Opprett prosess",
    "en": "Create process"
  },
  "Create PRTG": {
    "no": "Opprett PRTG"
  },
  "Create SolarWinds": {
    "no": "Opprett SolarWinds"
  },
  "Create VMM": {
    "no": "Opprett VMM"
  },
  "Create VMware": {
    "no": "Opprett VMware"
  },
  "Create Eventlog": {
    "no": "Opprett hendelselogg",
    "en": "Create eventlog"
  },
  "Create Nimsoft": {
    "no": "Opprett Nimsoft"
  },
  "Create DbWatch": {
    "no": "Opprett DbWatch"
  },
  "Create SQL Response": {
    "no": "Opprett SQL response",
    "en": "Create SQL response"
  },
  "Create Eaton UPS": {
    "no": "Opprett Eaton UPS"
  },
  "Create File Search": {
    "no": "Opprett filsøk",
    "en": "Create filesearch"
  },
  "Create Log": {
    "no": "Opprett logg",
    "en": "Create log"
  },
  "Create Metric": {
    "no": "Opprett metrikk",
    "en": "Create metric"
  },
  "Create Modbus": {
    "no": "Opprett Modbus"
  },
  "Create Remote IBM": {
    "no": "Opprett remote IBM",
    "en": "Create remote IBM"
  },
  "Create Remote System": {
    "no": "Opprett remote system",
    "en": "Create remote system"
  },
  "Create Script": {
    "no": "Opprett skript",
    "en": "Create script"
  },
  "Create SNMP": {
    "no": "Opprett SNMP"
  },
  "Create SNMP Trap": {
    "no": "Opprett SNMP Trap"
  },
  "Create System": {
    "no": "Opprett system",
    "en": "Create system"
  },
  "Create URL": {
    "no": "Opprett URL"
  },
  "Create XenServer": {
    "no": "Opprett XenServer"
  },
  "Create Container": {
    "no": "Opprett container",
    "en": "Create container"
  },
  "Create Cost Model": {
    "no": "Opprett kostmodell",
    "en": "Create cost model"
  },
  "Create Incident": {
    "no": "Lag sak",
    "en": "Create incident"
  },
  "Create Cost Store": {
    "no": "Opprett kost base",
    "en": "Create cost store"
  },
  "Create Interface Traffic": {
    "no": "Opprett interface trafikk",
    "en": "Create interface traffic"
  },
  "Create Icinga": {
    "no": "Opprett Icinga"
  },
  "Create Webhook": {
    "no": "Opprett Webhook"
  },
  "Create Cost Gateway": {
    "no": "Opprett kost gateway",
    "en": "Create cost gateway"
  }
});

export const ContextMenuConstants = {
	entities: ['SERVICE', 'SLA', 'ACCOUNT', 'USER', 'TEAM', 'ASSET', 'GROUP', 'AUTHENTICATION', 'SERVICEBOARD', 'REPORT', 'AUTOMATION', 'HEALTHFILTER', 'CALENDAR',
		'USER_CATEGORY', 'APPLICATION', 'COST_MODEL', 'COST_STORE', 'INCIDENT', 'COST_GATEWAY'],
	texts: [i('Create Service'), i('Create SLA'), i('Create Account'), i('Create User'), i('Create Team'), i('Create Asset'), i('Create Assetgroup'), i('Create Authentication'),
		i('Create Dashboard'), i('Create Report'), i('Create Automation'), i('Create Filter'), i('Create Calendar'), i('Create role'), i('Create Application'),
		i('Create Cost Model'), i('Create Cost Store'), i('Create Incident'), i('Create Cost Gateway')],
	functions: ['onNewService', 'onNewSla', 'onNewAccount', 'onNewUser', 'onNewTeam', 'onNewAsset', 'onNewAssetGroup', 'onNewAuthentication', 'onNewDashboard', 'onNewReport',
		'onNewAutomation', 'onNewFilter', 'onNewCalendar', 'onNewRole', 'onNewApplication', 'onNewCostModel', 'onNewCostStore', 'onNewIncident', 'onNewCostGateway'],
	monitorEntities: ['CONNECTIVITY', 'EXTERNAL', 'HYPERV', 'IBMSVC', 'INTERFACETRAFFIC','MAILGTW', 'MSCLUSTER', 'NAGIOS', 'PROCESSES', 'PRTG', 'SOLARWINDS', 'VMM', 'VMWARE', 'WINEVENTS',
		'WINSERVICE', 'NIMSOFT', 'DBWATCH', 'DATABASE', 'EATONUPS', 'FILESEARCH', 'LOGMONITOR', 'METRICMONITOR', 'MODBUS', 'REMOTEISERIES', 'REMOTESYSTEM', 'SCRIPT', 'SNMP', 'SNMPTRAP',
		'SYSTEM', 'URL', 'XEN_SERVER', 'CONTAINER', 'ICINGA', 'WEBHOOK', 'PROCESSMONITOR'],
	monitorTexts: [i('Create Connectivity'), i('Create External'), i('Create Hyper-V'), i('Create IBM SVC'), i('Create Interface Traffic'), i('Create Mail Gateway'), i('Create MS Cluster'), i('Create Nagios'),
		i('Create Process'), i('Create PRTG'), i('Create SolarWinds'), i('Create VMM'), i('Create VMware'), i('Create Eventlog'), i('Create Service'), i('Create Nimsoft'),
		i('Create DbWatch'), i('Create SQL Response'), i('Create Eaton UPS'), i('Create File Search'), i('Create Log'), i('Create Metric'), i('Create Modbus'),
		i('Create Remote IBM'), i('Create Remote System'), i('Create Script'), i('Create SNMP'), i('Create SNMP Trap'), i('Create System'), i('Create URL'), i('Create XenServer'),
		i('Create Container'), i('Create Icinga'), i('Create Webhook'), i('Create Process')
	]
};

export default ContextMenuConstants;
