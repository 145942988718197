import {Api} from "tools/api";

export class ReasonsGridApi {
	static delete(reasonsIds) {
		return Api.fetchPost(Api.accountRoot() + 'health/assetReasons/delete', reasonsIds);
	}

	static acknowledge(data) {
		return Api.fetchPost(Api.accountRoot() + 'health/acknowledgeAssetHealthEvents', data);
	}

	static unacknowledge(data) {
		return Api.fetchPost(Api.accountRoot() + 'health/unacknowledgeAssetHealthEvents', data);
	}

	static getReasonsGeneric( parameters, accountId) {
		return Api.fetchPost(Api.accountRoot(accountId) + 'health/assetReasons', {
			assetIds: parameters.assetIds,
			assetGroupIds: parameters.assetGroupIds,
			monitorIds: parameters.monitorIds,
			filter: parameters.filter
		});
	}

	static getMonitorReasons(p){
		return Api.fetch(Api.accountRoot(p.accountId) + `/assets/${p.assetId}/monitors/${p.monitorId}/reasons`)
	}

	static getAssetGroupsReasons(assetGroupIds = [], filter, accountId) {
		return ReasonsGridApi.getReasonsGeneric({assetGroupIds, filter}, accountId);
	}
}
