import React, {KeyboardEvent} from "react";
import {Root} from "react-dom/client";
import {makeAutoObservable} from "mobx";
import {MobxManager} from "framework/mobx-integration";
import {ApplicationState} from "framework/applicationState";
import {apiFetch} from "framework/api";
import {ChatAiPrompt, getAiPrompts, getIsChatEnabled} from "controls/chat/api";
import {createContainerRoot} from "controls/react/ant/antModal";
import {Chat} from "controls/chat/chat";
import {Permission} from "framework/permission";
import {AiChatEngine} from "controls/chat/aiChatEngine";


export class ChatStore {
	replyText: string = ''
	enabled: boolean = false
	prompts: ChatAiPrompt[] = []
	mobx = new MobxManager()
	popupRoot: Root


	chatEngine: AiChatEngine = new AiChatEngine()

	constructor() {
		makeAutoObservable(this)

		this.mobx.reaction(() => ApplicationState.accountId, async () => {
			this.enabled = false

			this.prompts = []

			const isEnabledResult = await apiFetch(getIsChatEnabled())
			if (!isEnabledResult.success) {
				return
			}

			this.enabled = isEnabledResult.data

			const promptsResult = await apiFetch(getAiPrompts())
			if (promptsResult.success) {
				this.prompts = promptsResult.data
			}
		})
	}

	get showChatButton() {
		return ApplicationState.hasPermissions(Permission.CeebotMenu) && this.enabled
	}

	get opened() {
		return this.popupRoot != null
	}

	toggle = () => {
		if (this.opened) {
			this.close()
		} else {
			this.open()
		}
	}

	open = () => {
		if (this.popupRoot != null)
			return

		this.popupRoot = createContainerRoot()

		const width = 700
		const left = window.innerWidth - width - 80

		const height = 700
		const top = window.innerHeight - height - 50

		this.popupRoot.render(
			<Chat width={width} height={height} left={left} top={top}
			      store={this}
			      onClose={this.close}/>
		)
	}

	close = () => {
		this.popupRoot?.unmount()
		this.popupRoot = null
	}

	send = async () => {
		if (!this.replyText)
			return

		let text = this.replyText
		this.replyText = ''

		await this.chatEngine.send(text)
	}

	onPressEnter = async (e: KeyboardEvent) => {
		e.preventDefault()
		await this.send()
	}

	destroy() {
		this.mobx.destroy()
	}
}

export const ChatStoreInstance = new ChatStore()
