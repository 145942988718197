

//according to the original request 10823 topLeft is 50 px from header and 50px from left menu
export const topLeftCornerPosition = {
	top: 175,
	left: 265,
};

export const getTopLeftPagePosition = () => {
	const element = document.getElementById('content_area')
	const rect = element.getBoundingClientRect();
	return {
		top: rect.y + 50,
		left: rect.x + 50
	}
}

export let ModalWindow = function (config) {
	//copy-paste from Utils.apply to remove circular dependency
	for (var p in (config || {})) {
		if (config[p] !== undefined && config[p] !== null) {
			this[p] = config[p];
		}
	}

	if (this.minHeight && this.height < this.minHeight) {
		this.height = this.minHeight;
	}
	this.initComponent();
};

export default ModalWindow;

ModalWindow.prototype = {
	/**
	 * @cfg {String} id The modal window id. Default: 'modal'
	 */
	id: 'modal',
	/**
	 * @cfg {String} title The title of the window. Default to ''
	 */
	title: '',
	/**
	 * @cfg {Number} width The width of the window. Default 200.
	 */
	width: 200,
	/**
	 * @cfg {Number} height The height of the window in pixels. Default 400
	 */
	height: 'auto',
	/**
	 * @cfg {String} url The URL of the HTML page to be loaded. Mandatory.
	 */
	url: '#',
	/**
	 * @cfg {Number} minWidth The minimum height of the window (i.e. 300)
	 */
	minWidth: 200,
	/**
	 * * @cfg {Number} minHeight The minimum height of the window (i.e. 300)
	 */
	minHeight: 200,
	/**
	 * @cfg {Object} position Modal position relative to screen (top,left)
	 */
	position: {},
	/**
	 * @cfg {Array} actions Array with the action buttons
	 */
	actions: ['Close'],

	/**
	 * @cfg {Boolean} draggable True if the window is draggable. Default to true
	 */
	draggable: true,
	/**
	 * @cfg {Boolean} resizable True if the window is resizable. Default to true
	 */
	resizable: true,
	/**
	 * @cfg {Function} refresh Function to be called after the content was loaded via AJAX or refresh button on static windows
	 */
	refresh: null,
	/**
	 * @cfg {Function} onClose Function to be called on window close event
	 */
	onClose: null,

	modal: true,
	/**
	 * Main init function
	 */
	initComponent: function () {
		var modalWindowId = '#' + this.id,
			oThis = this;
		if (!$(modalWindowId).length) {
			$('body').append('<div id="' + this.id + '"></div>');
		}
		const scope = this;
		this.kendoWindow = $(modalWindowId).kendoWindow({
			animation: false,
			actions: this.actions,
			draggable: this.draggable,
			resizable: this.resizable,
			title: this.title,
			width: this.width,
			height: this.height,
			minWidth: this.minWidth,
			minHeight: this.minHeight,
			maxHeight: this.maxHeight,
			position: this.position.top == null ? {top: 0, left: 0} : this.position,
			visible: false,
			modal: this.modal,
			content: this.url,
			open: function (e) {
				e.sender.wrapper.parent().find('.k-window-action.k-link').unbind('click');
				if (scope.cssClass) {
					e.sender.wrapper.addClass(scope.cssClass);
				}
			},
			close: function (e) {
				if (oThis.onClose && typeof oThis.onClose === 'function') {
					oThis.onClose.call(oThis);
				}
				this.destroy();

			}
		}).data("kendoWindow");
		if (this.refresh && typeof this.refresh === 'function') {
			this.kendoWindow.bind('refresh', this.refresh);
		}
		if (this.resize && typeof this.resize === 'function') {
			this.kendoWindow.bind('resize', this.resize);
		}
		if (this.activate && typeof this.activate === 'function') {
			this.kendoWindow.bind('activate', this.activate);
		}
		this.kendoWindow.wrapper.addClass('cw_modal');

		this.kendoWindow.dragging._draggable.userEvents.bind("release", function (e) {
			if (oThis.resizeEnd && typeof oThis.resizeEnd === 'function') {
				oThis.resizeEnd.call(oThis);
			}
		});
	},

	/**
	 * Opens the modal window
	 */
	open: function () {
		var modalWindowId = '#' + this.id;
		if (this.position.top === undefined) {
			this.kendoWindow.open().center();
		} else {
			$(modalWindowId).closest('.k-window').css({
				top: this.position.top,
				left: this.position.left
			});
			this.kendoWindow.open();
		}
	},

	/**
	 * Refreshes the modal window
	 */
	refreshWindow: function () {
		this.kendoWindow.refresh();
	},

	/**
	 * Closes the modal window
	 */
	close: function () {
		this.kendoWindow.close();
	},

	destroy(){
		this.kendoWindow.destroy();
	}
};
