import React from "react"
import {translator} from "core";
import {Button} from "controls/react/form";
import {Section} from 'controls/react/layout/section';
import {Toolbar} from 'controls/react/layout/toolbar';
import {Window} from 'controls/react/kendoWrappers/window';
import {FormLayout} from 'controls/react/layout/formLayout';
import CustomNotification from 'controls/customNotification';
import {ManagementApi} from "areas/management/api";
import {MetricConversionForm, validateMetricConversion} from "areas/service-boards/widgets/common/metricConversionForm";

let i = translator({
   "Conversion formula": {
    "no": "Konverteringsformel"
  }
});

export class ConversionWindow extends React.PureComponent {
	constructor(props) {
		super(props);
		let formula;
		if (props.formula) {
			formula = props.formula.trim();
		} else {
			formula = '';
		}
		this.state = {
			formula: props.formula ? props.formula.trim() : '',
			decimals: parseInt(props.decimals) || 2
		};
	}

	render() {
		return (
			<div>
				<Window height={220}
						width={400}
						modal
						top={this.props.offset.top + 30}
						left={this.props.offset.left + 5}
						title={i('Conversion formula')}
						ref={window => this.window = window}
						onResize={this.onResize}
						onClose={this.props.onClose}>
					<div className="notification_container" current-conversion-id={this.props.metricId}></div>
					<Section>
						<FormLayout>
							<MetricConversionForm formula={this.state.formula}
												  decimals={this.state.decimals}
												  onFormulaChange={this.onFormulaChange}
												  onDecimalsChange={this.onDecimalsChange}
												  onValidate={this.onValidate} />
						</FormLayout>
					</Section>
					<Toolbar containerClass="conversion_actions">
						<Button title={'Update'}
								primary={true}
								onClick={() => this.onUpdate()} />
						<Button title={i('Cancel')}
								primary={false}
								onClick={() => this.props.onClose()} />
					</Toolbar>
				</Window>
			</div>
		)
	}

	async componentDidMount() {
		setTimeout(() => {
			$('.c-textarea').focus();
		}, 50);
		this.fetchLastValue();
		this.actionNotification = new CustomNotification({
			appendToElement: '.notification_container',
		});
	}

	async fetchLastValue() {
		let lastInfoResult = await ManagementApi.getQualifiersLastValue([{metricId: this.props.metricId}]);
		let lastValueData = lastInfoResult[0];
		if (lastValueData && lastValueData.found && lastValueData.hasMetrics) {
			this.lastValue = lastValueData.data[0].v;
		}
	}

	onFormulaChange = (value) => {
		this.setState({
			formula: value
		});
	}

	onDecimalsChange = (value) => {
		this.setState({
			decimals: value
		});
	}

	onValidate = async () => {
		const result = await validateMetricConversion(this.state.formula, this.state.decimals, this.lastValue);
		this.actionNotification.setOptions(result).show();
	}

	onUpdate = () => {
		let formula = this.state.formula.trim();
		let decimals = this.state.decimals;
		this.props.onUpdate(formula, decimals);
	}

	onResize() {
		let newHeight = this.wrapper.height() - 50;
		let content = $('.notification_container').next('.section');
		content.css('height', newHeight);
		let textAreaRows = parseInt((newHeight - 100) / 20);
		content.find('textarea').attr('rows', textAreaRows);
	}
}

