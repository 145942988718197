import {Cookies} from "core/cookies";
import MultigraphWidgetConfiguration from './../../service-boards/widgets/multigraphWidgetConfiguration';
import MultigraphWidget from './../../service-boards/widgets/multigraphWidget';
import {sharedLocalization} from "controls/designer/features/widgets/localization";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: MultigraphWidgetConfiguration,
		defaultConfig: {
			type: 'service-multi-graph',
			title: i('Multigraph'),
			configuration: {
				accountId: Cookies.CeesoftCurrentAccountId,
				isDashboard: true,
				reactTimePeriodSelector: true
			}
		},
		constructWidget: config => new MultigraphWidget(config),
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Service') + '/' + i('Multigraph'),
	}
}
