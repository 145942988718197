import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import Widget from './widget'

export default class WidgetReactContainer extends Widget {
	constructor(reactWidget, settingsComponent, config, className) {
		super(config);

		this.config = config;
		this.reactWidget = reactWidget;
		this.settingsComponent = settingsComponent;

		if (this.config.customControls) {
			this.widgetSettingsNode = $('<div class="cw_widget_settings"></div>');
			$(this.config.customControls.target).append(this.widgetSettingsNode);
		}

		if (className) {
			$(`#${this.id}`).addClass(className)
		}

		this.events = new kendo.Observable();
		this.render();
	}

	render() {
		const ReactWidget = this.reactWidget;
		const WidgetSettings = this.settingsComponent;

		this.getReactRoot().render(
			<ReactWidget config={this.config}
						 events={this.events}
						 setTitle={title => this.setTitle(title)}
						 unsetTitle={this.unsetTitle.bind(this)}
						 requestPath={this.getRequestPath.bind(this)}/>
		);

		if (this.config.customControls) {
			this.getWidgetSettingsReactRoot().render(<WidgetSettings
				config={this.config.customControls}
				componentConfig={this.config.configuration}
				events={this.events}
			/>);
		}
	}

	getReactRoot() {
		if(this.root == null) {
			const container = document.getElementById(this.id)?.querySelector('.cw_section_content')
			this.root = createRoot(container)
		}

		return this.root
	}

	getWidgetSettingsReactRoot() {
		if(this.widgetSettingsRoot == null) {
			this.widgetSettingsRoot = createRoot(this.widgetSettingsNode.get(0))
		}

		return this.widgetSettingsRoot
	}

	onEvents(events) {
		this.events.trigger('events', events);
	}

	onResize(event, ui) {
		this.destroy();
		this.render();
	}

	destroy() {
		try {
			this.widgetSettingsRoot?.unmount()
			this.root?.unmount()
			this.root = null
		} catch(e) {
			if (!['NotFoundError', 'Invariant Violation'].includes(e.name)) {
				throw e;
			}
		}
	}
}
