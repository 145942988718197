import './serviceSummaryGridWidget.less';
import {observer} from "mobx-react";
import React from "react";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {GridSearchInput} from "controls/grid/gridSearchInput";
import {Grid} from "controls/grid/grid";
import {deserialize, serialize} from "serializr";
import {Section, Toolbar} from "controls/react/layout";
import {GridState} from "controls/grid/gridState";
import {ModuleProps} from "framework/modules";
import {GridStore} from "controls/grid/gridStore";
import {apiFetch, ApiResponse, PagedList} from "framework/api";
import {ExternalStateProvider} from "controls/grid/stateProviders";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import {computed, makeObservable, observable} from "mobx";
import {getServicesSummaryWidgetFilters, getServiceSummaryGridItems, ServiceSummaryGridItem} from "../api";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {SeverityIndicator} from "controls/react/severityIndicator";
import {serviceSummaryToHealthFlags} from "framework/entities/service";
import {ServiceSummaryWidgetConfig} from "./serviceSummaryWidgetConfiguration";
import {GridDataItem} from "controls/grid/gridDataItem";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import classnames from "classnames";
import {serviceSummaryTranslator} from "./localizations";
import {RemoteEventsManager} from "../../../../core/remoteEventsManager";
import {throttle} from "lodash";
import {Event, ServiceSummaryEvent} from "framework/entities/events";
import {ServicesRouter} from "../../bundleDescription";
import {ViewSelector} from "controls/grid/viewManager/viewSelector";

const i = serviceSummaryTranslator;
const b = require('b_').with('service-summary-grid-widget');

class ServicesSummaryGridWidgetStore {
	gridStore: GridStore<ServiceSummaryGridItem>
	columns: GridColumnConfig<ServiceSummaryGridItem>[]
	subscription: {
		unsubscribe: () => void,
		subscriberId: string
	}

	get editMode() {
		return !this.props.dashboardSettings.readOnly;
	}

	constructor(private props: WidgetProps<ServiceSummaryWidgetConfig, ServicesSummaryGridPersistedState>) {
		makeObservable(this, {
			gridStore: observable,
			columns: observable,
			editMode: computed
		})
	}

	getDefaultDatasource(){
		const payload = ServiceSummaryWidgetConfig.getPayload(this.props.config);
		payload.order = this.getOrderObject();
		return getServiceSummaryGridItems(payload);
	}

	redirectByConfig = (ev: MouseEvent) => {
		if (!!(ev.target as HTMLElement).closest('.ceeview-grid-header')) {
			return; // prevent if clicked header to keep sorting and filtering
		}
		this.props.navigator.go({});
	}

	goToService = (id: string, ev: React.MouseEvent) => {
		ev.stopPropagation();
		this.props.navigator.go({url: ServicesRouter.details(id)})
	}

	goToElement = (serviceId: string, elementId: string, ev: React.MouseEvent) => {
		ev.stopPropagation();
		this.props.navigator.go({url: ServicesRouter.viewer(serviceId, {
			serviceElementId: elementId
		})})
	}

	goToQualifier = (serviceId: string, elementId: string, qualifierId: string, ev: React.MouseEvent) => {
		ev.stopPropagation();
		this.props.navigator.go({url: ServicesRouter.viewer(serviceId, {
			serviceElementId: elementId,
			serviceQualifierId: qualifierId
		})})
	}

	getOrderObject = () => {
		if (this.gridStore?.dataProvider?.data?.length) {
			const orders: {[id: string]: number} = {};
			this.gridStore.dataProvider.data.forEach(item => {
				if (item.data.order != undefined) {
					orders[item.data.id] = item.data.order;
				}
			})
			return orders;
		}
		return this.props.persistedState.order;
	}

	async init() {
		const filtersResult = await apiFetch(getServicesSummaryWidgetFilters(this.props.config.accountIds))
		if(!filtersResult.success){
			return
		}

		let state = null
		if('grid' in this.props.persistedState){
			state = deserialize<GridState<ServiceSummaryGridItem>>(GridState, this.props.persistedState.grid)
		}

		this.gridStore = new GridStore<ServiceSummaryGridItem>({
			columns: this.getColumns(),
			stateProviders: [
				new ExternalStateProvider(state),
			],
			dataProvider: new RemoteDataProvider({
				dataSource: this.getDefaultDatasource(),
				filtersSource: getServicesSummaryWidgetFilters(this.props.config.accountIds),
				onBatchLoaded: (response: ApiResponse<PagedList<GridDataItem<ServiceSummaryGridItem>>>) => {
					if (!response.success) {
						return;
					}
					Object.keys(this.props.persistedState?.order ?? {}).forEach((key: string) => {
						const item = response.data.items.find(x => x.id == key)?.data;
						if (item) {
							item.order = this.props.persistedState.order[key];
						}
					})
				}
			}),
			selection: GridSelectionType.None
		})
		this.subscribe();
	}

	getColumns() {
		if (!this.columns) {
			this.columns = [
				{
					field: 'serviceState',
					title: i('State'),
					renderer: item => <SeverityIndicator {...serviceSummaryToHealthFlags(item as any)}/>,
					className: b('state-cell'),
					width: 80
				}, {
					field: 'order',
					title: i('Order'),
					className: b('order-cell'),
					renderer: item => {
						if (!this.editMode) {
							return item.order
						}
						return <FormEntryNew model={item} modelField={'order'}>
							<AntInputNumber />
						</FormEntryNew>
					},
					width: 80
				}, {
					field: 'serviceName',
					title: i('Service'),
					renderer: item => {
						const classes = classnames('cell-link', {'target-label': !item.elementName?.length})
						return <span className={classes} onClick={(ev) => this.goToService(item.serviceId ?? item.id, ev)}>{item.serviceName}</span>
					},
					width: 150
				}, {
					field: 'elementName',
					title: i('Service element'),
					renderer: item => {
						const classes = classnames('cell-link', {'target-label': !item.qualifierName?.length})
						return <span className={classes} onClick={(ev) => this.goToElement(item.serviceId, item.elementId ?? item.id, ev)}>{item.elementName}</span>
					},
					width: 150
				}, {
					field: 'qualifierName',
					title: i('Service Qualifier'),
					renderer: item => {
						const classes = classnames('cell-link', 'target-label');
						return <span className={classes}
							  onClick={(ev) => this.goToQualifier(item.serviceId, item.elementId, item.id, ev)}>{item.qualifierName}</span>
					}
				}, {
					field: 'accountName',
					title: i('Account'),
					width: 120
				}
			]
		}

		return this.columns
	}

	subscribe() {
		const payload = ServiceSummaryWidgetConfig.getPayload(this.props.config);
		delete payload.serviceElementStates
		delete payload.serviceQualifierStates
		delete payload.serviceStates

		this.subscription?.unsubscribe();
		this.subscription = RemoteEventsManager.subscribeCallback([ServiceSummaryEvent.serviceSummaryGridSubscription(payload)], this.refreshDataThrottled);
	}

	refreshDataThrottled = throttle((ev: Event) => {
		if (!ServiceSummaryEvent.is(ev)) {
			return;
		}
		this.gridStore.dataProvider.silentReload();
	}, 500)

	destroy() {
		this.gridStore.destroy();
		this.subscription?.unsubscribe();
	}
}

export type ServicesSummaryGridPersistedState = {
	grid: GridState<ServiceSummaryGridItem>,
	order: {[id: string]: number}
}

type ServicesSummaryGridWidgetProps = ModuleProps

export const ServicesGridWidget = observer(
	class InnerWidget extends React.Component<WidgetProps<ServiceSummaryWidgetConfig, ServicesSummaryGridPersistedState>, ServicesSummaryGridWidgetProps> {
		store: ServicesSummaryGridWidgetStore;

		constructor(props: WidgetProps<ServiceSummaryWidgetConfig, ServicesSummaryGridPersistedState>) {
			super(props)
			this.store = new ServicesSummaryGridWidgetStore(props);
		}

		render(){
			if(!this.store.gridStore?.selfInitialized)
				return null

			return <DefaultWidgetWrapper containerClass={b()} {...this.props} appearance={'none'}>
				<Section appearance="none" height={"fit"} onClick={this.store.redirectByConfig}>
					{this.store.editMode &&
						<Toolbar>
							<DataSizeIndicator store={this.store.gridStore}/>
							<GridSearchInput store={this.store.gridStore}/>
							<ViewSelector store={this.store.gridStore} createViewDisabled={this.props.dashboardSettings.readOnly} editViewDisabled={this.props.dashboardSettings.readOnly}/>
						</Toolbar>
					}
					<Grid store={this.store.gridStore}/>
				</Section>
			</DefaultWidgetWrapper>
		}

		componentWillUnmount() {
			this.store?.destroy()
		}

		getStateForSaving() {
			return {
				grid: serialize(this.store.gridStore.state),
				order: this.store.getOrderObject()
			};
		}

		async componentDidMount() {
			await this.store.init();
		}
	}
)
