import './validationSummary.less'

import React from "react";
import {BoxView} from "controls/react/layout/boxView";

const ValidationSummary = props => {
	const errors = (props.links || [])
		.filter(x => x.errors.length > 0)
		.map(x => x.errors)
		.reduce( (allErrors, currentErrors) => [...currentErrors, ...allErrors], []);

	if(props.message){
		errors.push(props.message);
	}

	if(errors.length == 0)
		return null;

	return (
		<ul className={"validation-summary"}>
			{errors.map((e,i) => <BoxView key={i} type={"error"} border={true} rounded={true} margin={"both"}>{e}</BoxView>)}
		</ul>
	)
}

const memoizedValidationSummary = React.memo(ValidationSummary);

export {memoizedValidationSummary as ValidationSummary}
