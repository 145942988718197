import { makeAutoObservable } from "mobx";
import {object, primitive } from "serializr";
import {Severity} from "../../../framework/entities/healthData";
import {createModelSchemaWrapper} from "../../../framework/serializr-integration";

export class CostAlarmValue {
	threshold: number
	severity: Severity

	constructor() {
		makeAutoObservable(this)
	}
}

export class CostAlarm {
	description: string
	currency: string
	monthLowThreshold: CostAlarmValue
	monthHighThreshold: CostAlarmValue
	yearLowThreshold: CostAlarmValue
	yearHighThreshold: CostAlarmValue
	costItemId: string
	costModelId: string
	costAlarmId: string

	constructor() {
		makeAutoObservable(this)
	}
}

createModelSchemaWrapper(CostAlarmValue, {
	threshold: primitive(),
	severity: primitive()
})

createModelSchemaWrapper(CostAlarm, {
	description: primitive(),
	currency: primitive(),
	monthLowThreshold: object(CostAlarmValue),
	monthHighThreshold: object(CostAlarmValue),
	yearLowThreshold: object(CostAlarmValue),
	yearHighThreshold: object(CostAlarmValue),
	costItemId: primitive(),
	costModelId: primitive(),
	costAlarmId: primitive(),
})
