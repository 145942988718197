import React from "react";
import PropTypes from "prop-types";
import "./sqInfoForm.less";
import {State} from 'tools';
import {toFixed} from "controls/react/form/ceeviewNumericField";
import {AgentsRouter} from 'areas/management/agents/bundleDescription';
import FieldRow from 'controls/react/fieldRow';
import {translator} from "core";
import Utils from 'tools/utils';
import {openMonitorView} from "core/application";
import Configuration from 'configuration';
import {ApplicationsRouter} from "areas/application/bundleDescription";
import {apiFetch} from "framework/api";
import {getMonitorType} from "areas/assets/monitors/api";
import {NavigationStore} from "../../../../../framework/navigationStore";
import {AssetsRouter} from "../../../../assets/bundleDescription";
import {AssetGroupRouter} from "../../../../assetgroups/bundleDescription";

const i = translator({
  "Template name": {
    "no": "Mal navn",
    "en": "Template name"
  },
  "Class name": {
    "no": "Klassenavn"
  }
});

class AlgorithmInfo extends React.PureComponent {
	render() {
		const {field, info: {value: algorithm}} = this.props;

		let type;

		if (algorithm && algorithm.type) {
			type = algorithm.type;
		} else {
			type = 'AvailableAlgorithm';
		}

		let showOperator = false;
		let showTreshold = false;
		let showFactor = false;
		let showMin = false;
		let showMax = false;
		let showDays = false;
		let showPercentile = false;

		switch (type) {
			case 'StaticThresholdAlgorithm':
			case 'DeltaAlgorithm':
				showOperator = true;
				showTreshold = true;
				break;
			case 'DynamicThresholdAlgorithm':
				showFactor = true;
				break;
			case 'RangeAlgorithm':
				showMin = true;
				showMax = true;
				break;
			case 'PercentileAlgorithm':
				showDays = true;
				showPercentile = true;
				break;

		}

		return <React.Fragment>
			<FieldRow label={lang.designer.ALGORITHM} content={type}/>
			{showOperator && <FieldRow label={lang.designer.OPERATOR} content={algorithm.operator}/>}
			{showTreshold && <FieldRow label={lang.designer.THRESHOLD} content={algorithm.threshold}/>}
			{showFactor && <FieldRow label={lang.designer.FACTOR} content={algorithm.factor}/>}
			{showMin && <FieldRow label={lang.MIN} content={algorithm.min}/>}
			{showMax && <FieldRow label={lang.MAX} content={algorithm.max}/>}
			{showPercentile && <FieldRow label={lang.PERCENTILE} content={algorithm.percentile}/>}
			{showDays && <FieldRow label={lang.DAYS} content={algorithm.days}/>}
		</React.Fragment>
	}
}

class AgentInfo extends React.PureComponent {
	onClick = () => {
		const {
			agentAccountId: {value: accountId, text: accountName},
			agentId: {value: agentId, text: agentName}
		} = this.props.infos;

		State.mainApp.navigate(AgentsRouter.details(agentId));
	}

	render() {
		const {field, info} = this.props;
		const linkEnabled = !Utils.canEditAgent(info.value)
		return <FieldInfo field={field} info={info} onClick={linkEnabled ? null : this.onClick} />
	}
}

class MonitorInfo extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isMonitorInstalled: false
		}
	}

	checkMonitorInstalled = (monitorType) => {
		let isInstalled = Configuration.installedMonitors.indexOf(monitorType) !== -1;
		return isInstalled;
	}

	getMonitorType = async () => {
		this.monitorId = this.props.infos.monitorId.value;
		let result = await apiFetch(getMonitorType(this.monitorId, this.props.accountId));
		if (result.success) {
			this.monitorType = result.data.type;
			let isMonitorInstalled = this.checkMonitorInstalled(this.monitorType);
			this.setState({
				isMonitorInstalled: isMonitorInstalled
			})
		}
	}
	onClick = () => {
		openMonitorView(this.monitorId, this.monitorType);
	}

	render() {
		this.getMonitorType();
		const {field, info} = this.props;

		return <FieldInfo field={field} info={info} onClick={this.state.isMonitorInstalled ? this.onClick : null}/>
	}
}

class AssetGroupInfo extends React.PureComponent {
	onClick = () => {
		let options = {
			isView: true,
			isFromAssetGroupWidget: true,
			assetGroupNameFilter: this.props.infos.groupId.text
		};
		const url = AssetGroupRouter.list(options)
		NavigationStore.go(url)
	}

	render() {
		const {field, info} = this.props;

		return <FieldInfo field={field} info={info} onClick={this.onClick}/>
	}
}

class AssetInfo extends React.PureComponent {
	onClick = () => {
		const {
			assetId: {value: assetId}
		} = this.props.infos;

		NavigationStore.go(AssetsRouter.details(assetId));
	}

	render() {
		const {field, info} = this.props;

		return <FieldInfo field={field} info={info} onClick={this.onClick}/>
	}
}

class ApplicationInfo extends React.PureComponent {
	onClick = () => {
		const {
			applicationId: {value: applicationId}
		} = this.props.infos;

		State.mainApp.navigate(ApplicationsRouter.details(applicationId));
	};

	render() {
		const {field, info} = this.props;

		return <FieldInfo field={field} info={info} onClick={this.onClick}/>
	}
}

class FieldInfo extends React.PureComponent {
	render() {
		const {field, info, onClick} = this.props;
		let placeholderForCheckbox;

		if (field.type === 'CECheckBox') {
			placeholderForCheckbox = (info.value === true) ? "True" : "False";
		}

		let value = info.text || placeholderForCheckbox || info.value || "";

		if (field.numeric) {
			value = toFixed(+value);
		}

		return <FieldRow label={field.caption} content={value} onClick={onClick}/>
	}
}

class WarningInfo extends React.PureComponent {
	warning = this.props.warning;

	render() {
		if (!this.warning) {
			return null;
		}

		return <React.Fragment>
			<FieldRow label={"Warning Operator"} content={this.warning.operator}/>
			<FieldRow label={"Warning threshold"} content={this.warning.threshold}/>
		</React.Fragment>

	}
}

export default class SqInfoForm extends React.PureComponent {
	constructor(props) {
		super(props);

		const {fields, details: info} = this.props;

		this.state = {
			fields,
			info
		}
	}

	componentDidMount() {
		const {id} = this.props;

		$(document).on(`qualifier_info_updated::${id}`, (ev, data) => {
			const {fields, details: info} = data;

			this.setState({
				fields,
				info
			});
		});
	}

	componentWillUnmount() {
		const {id} = this.props;

		$(document).off(`qualifier_info_updated::${id}`);
	}

	render() {
		const {accountId, id, className, staticProperties} = this.props;
		const {fields, info} = this.state;

		return <div className="sqinfo-form">
			{Object.keys(fields).map((field) => {
				const fieldData = fields[field];
				const infoData = info[field];

				if (!fieldData.visible || !fieldData.caption || fieldData.type === "CEGroup") {
					return null;
				}

				switch (field) {
					case "algorithm":
						return <AlgorithmInfo key={field} field={fieldData} info={infoData}/>;
					case "agentId":
						return <AgentInfo key={field} fields={fields} infos={info} field={fieldData} info={infoData}/>
					case "assetId":
						return <AssetInfo key={field} fields={fields} infos={info} field={fieldData} info={infoData}/>
					case "monitorId":
						return <MonitorInfo key={field} fields={fields} infos={info} field={fieldData} info={infoData} accountId={accountId}/>
					case "groupId":
						return <AssetGroupInfo key={field} fields={fields} infos={info} field={fieldData}
											   info={infoData}/>
					case 'applicationId':
						return <ApplicationInfo key={field} fields={fields} infos={info} field={fieldData} info={infoData}/>
					case "name":
						return <div><FieldInfo key={field} field={fieldData} info={infoData}/><FieldRow label={i('Template name')} content={staticProperties.configurationName}/></div>;
					default:
						return <FieldInfo key={field} field={fieldData} info={infoData}/>;
				}
			})}

			<FieldRow label={"CVID"} content={id}/>
			<FieldRow label={i("Class name")} content={className}/>

			<WarningInfo warning={info.warning}/>
		</div>;
	}
}

SqInfoForm.propTypes = {
	fields: PropTypes.object.isRequired,
	details: PropTypes.object.isRequired,
	className: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired
};
