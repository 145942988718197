import React from "react";

import {
	metricVariableComponentDescription,
	MetricVariableConfig
} from "./components/metricVariable";
import {metricChartComponentDescription, MetricChartConfig} from "./components/metricChart";
import {textMetricComponentDescription, TextMetricConfig} from "./components/textMetric";
import {dashboardComponentDescription, DashboardConfig} from "./components/dashboard";
import {ComponentDescription} from "./componentDescription";

export const components = [
	metricVariableComponentDescription,
	textMetricComponentDescription,
	metricChartComponentDescription,
	dashboardComponentDescription
];


export function getComponentDescription(element: Element){
	const id = element.getAttribute('data-type')
	return components.find(x => x.id == id);
}
