import Settings from 'settings'
import State from 'tools/state'
import {showInfo} from 'controls/dialog';
import Lang from 'core/localization/lang'
import {infoDialogAsync} from 'controls/dialog';
import {redirectTo} from "tools/navigation";

export let ErrorHandler = {
	/**
	 * Called on error for AJAX non-kendo requests
	 * @param {Object} result
	 */
	_sessionExpired: false,

	serverError: function (result) {
		var rspObj;
		if (!this._sessionExpired) {
			if (!result.message) {
				if (result.status === 500) {
					try {
						rspObj = JSON.parse(result.responseText);
					}
					catch (ex) {
						rspObj = {
							message: 'Unknown exception'
						};
					}
					result.message = rspObj.message;
					result.details = rspObj.details;
				} else {
					result.message = result.statusText;
				}
			}
			if (result.message === 'Session expired') {
				this._sessionExpired = true;
				console.log('Result.message: ' + result.message);
				redirectTo(Settings.defaultLogoutPage, true);
			}
			else {
				if (!result.message) {
					result.message = 'Unknown exception';
				}
				if (!State.stopAllRequests) {
					infoDialogAsync(Lang.INFO, result.message, result.details);
					State.mainApp?.removeLoadingMask();
				}
			}
		}
	},

	/**
	 * Called on error for AJAX kendo requests
	 * @param {Object} error
	 */
	kendoServerError: function (error) {
		if (!this._sessionExpired) {
			try {
				var errorObj = jQuery.parseJSON(error.xhr.responseText);
				if (errorObj.message === 'Session expired') {
					redirectTo(Settings.defaultLogoutPage, true);
				}
				else {
					if (!State.stopAllRequests) {
						infoDialogAsync(Lang.INFO, errorObj.message, errorObj.details);
						State.mainApp?.removeLoadingMask();
					}
				}
			}
			catch (e) {
				if (!State.stopAllRequests) {
					infoDialogAsync(Lang.INFO, error.errorThrown);
					State.mainApp?.removeLoadingMask();
				}
			}
		}
	}
};

export default ErrorHandler;
