import Settings from "settings";

class CookiesStorageWrapper{
	collection = {};
	parsedString = "";

	init(){
		this.collection = {};
		this.parsedString = document.cookie;

		var allCookies = this.parsedString.split('; ');

		for (const item of allCookies) {
			var cookiePair = item.split('=');
			this.collection[cookiePair[0]] = cookiePair[1];
		}
	}

	get(name){
		if(document.cookie != this.parsedString){
			this.init();
		}

		return this.collection[name];
	}

	create(name, value, hours, path = '/') {
		let expires;
		if (hours) {
			var date = new Date();
			date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
			expires = "; expires=" + date.toGMTString();
		} else {
			expires = "";
		}
		document.cookie = name + "=" + value + expires + "; path=" + path;

		this.collection[name] = value;
	}

	erase(name){
		this.create(name, '', -1);
		delete this.collection[name];
	}
}

class SessionStorageWrapper{

	constructor(sessionId) {
		this.sessionId = sessionId;
	}

	init(){

	}

	get(key){
		return sessionStorage.getItem(this.sessionId + key);
	}

	create(key, value, hours, path = '/') {
		sessionStorage.setItem(this.sessionId + key, value);
	}

	erase(key){
		sessionStorage.removeItem(this.sessionId + key);
	}
}

const storage = {
	internalStorage: new CookiesStorageWrapper(),

	init: function () {
		this.internalStorage.init();
	},

	create: function (name, value, hours, path = '/') {
		this.internalStorage.create(name, value, hours, path);
	},

	erase: function (name) {
		this.internalStorage.erase(name);
	},

	useSessionStorage(sessionId){
		this.internalStorage = new SessionStorageWrapper(sessionId);
		this.init();
	},

	get(name){
		return this.internalStorage.get(name);
	}
}

export let Cookies = new Proxy(storage, {
	get(target, prop) {
		if (target[prop])
			return target[prop];

		return target.get(prop);
	}
});

Cookies.init();

export default Cookies;

export function createCookies(data) {
	var timeout = Settings.COOKIE_TIMEOUT;
	Cookies.create("CeesoftUsername", data.username, timeout);
	Cookies.create("CeesoftUserId", data.userId, timeout);
	Cookies.create("CeesoftAccountId", data.accountId, timeout);
	Cookies.create("CeesoftTimezone", data.timeZone, timeout);
	Cookies.create("CeesoftUserLocale", data.locale, timeout);
	Cookies.create("CeesoftUserDateTimeFormat", data.dateTimeFormat, timeout);
	Cookies.create("CeesoftUserDateFormat", data.dateFormat, timeout);
	Cookies.create("CeesoftUserTimeFormat", data.timeFormat, timeout);
	Cookies.create("CeesoftUserPictureId", data.pictureId, timeout);
	Cookies.create("CeesoftAccountName", data.accountName, timeout);
	Cookies.create("CeesoftUserEmail", data.email, timeout);

	if (data.loginAccountId && data.loginAccountName) {
		Cookies.create("CeesoftCurrentAccountId", data.loginAccountId, timeout);
		Cookies.create("CeesoftCurrentAccountName", data.loginAccountName, timeout);
	} else {
		Cookies.create("CeesoftCurrentAccountId", data.accountId, timeout);
		Cookies.create("CeesoftCurrentAccountName", data.accountName, timeout);
	}

	Cookies.create("CeesoftAccountLogoId", data.logoId, timeout);
	Cookies.create("CeesoftLicenseInfo", JSON.stringify(data.licenseInfo), timeout);
	Cookies.erase("CeesoftParentAccountId");
	Cookies.erase("CeesoftParentAccountName");
	Cookies.create("sessionId", data.sessionId, Settings.COOKIE_TIMEOUT);
}
