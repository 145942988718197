import {ModalWindow} from "controls/modalWindow";
import AuthenticationForm from "areas/administration/authenticationForm";
import React from "react";
import {translator} from "core";
import PropTypes from "prop-types";
import IconButton from "../form/iconButton";

const i = translator({
  "Create new authentication": {
    "no": "Opprett ny autentisering",
    "en": "Create new authentication"
  }
});

export class AddAuthentication extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	onClick() {
		this.window = new ModalWindow({
			title: i('Create new authentication'),
			width: 400,
			minHeight: 400,
			maxHeight: 800,
			id: this.props.newAuthModalWindowId || null,
			url: 'include/Administration/AuthenticationForm.jsp',
			refresh: () => {
				this.newAuthForm = new AuthenticationForm({
					id: 'new_authentication',
					modalId: this.props.newAuthModalWindowId || null,
					mode: 'create',
					authTypes: this.props.authTypes || [],
					onCreated: this.props.onCreated,
					preselectedAuths: this.props.authTypes?.length == 1 ? this.props.authTypes : undefined
				});
			}
		});
		this.window.open();
	}

	render() {
		return <IconButton title={i('Create new authentication')}
		                   onClick={() => this.onClick()}
		                   iconName='plus-sign'
		                   embedded
		                   disabled={this.props.disabled}
		                   containerClass={this.props.containerClass}/>;
	}
}

AddAuthentication.propTypes = {
	types: PropTypes.array,
	onCreated: PropTypes.func
};

export default AddAuthentication;
