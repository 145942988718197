import React, { useEffect, useRef } from 'react';

interface OutsideClickHandlerProps {
	onOutsideClick: () => void;
	children: React.ReactNode;
	containerClass?: string
}

export const OutsideClickHandler: React.FC<OutsideClickHandlerProps> = ({ containerClass, children, onOutsideClick }) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				onOutsideClick();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onOutsideClick]);

	return <div className={containerClass} ref={ref}>{children}</div>;
};
