import React from "react";

import {AntSelect} from "controls/react/ant/antSelect";
import {useRemoteList} from "framework/api";
import {listCostResources} from "areas/cost/api";

const i = require('core/localization').translator()

export const BudgetItemsSelector = props => {
	const {accountId, profileId, modelId, showResources, ...others} = props;

	const [data, loading, loaded] = useRemoteList(listCostResources({
		showResources,
		accountId,
		showTotal: true,
		showOther: true,
		costModelId: modelId,
		costProfileId: profileId
	}), {
		makeRequest: !!profileId && !!modelId
	})

	return (
		<AntSelect placeholder={i('Select...')}
		           dataList={data}
		           mode={'multiple'}
		           loading={loading}
		           disabled={!loaded}
		           {...others}
		/>
	);
}
