import './serviceDocumentConfiguration.less'
import React, { useEffect, useState } from "react";
import { CollapsibleFrame } from "controls/react/layout/collapsibleFrame";
import { FormEntry } from "controls/react/form";
import { AntInputNumber } from "controls/react/ant/antInputNumber";
import { AntCheckbox } from "controls/react/ant/antCheckbox";
import { translator } from "core";
import { AntInput } from "controls/react/ant/antInput";
import { createAccountsDatasource } from "controls/react/dropdowns/accountDropDown";
import { Api } from 'tools';
import Settings from 'settings';
import { TagsSelect } from "controls/react/tagsSelect";
import MultiSelectV2 from 'controls/react/form/multiSelectV2';
import {ColumnsVisibility} from "controls/designer/features/widgets/columnsVisibility";
import {ServiceDocumentColumns} from './serviceDocumentColumns'
import {AntSelect} from 'controls/react/ant/antSelect';
import IconButton from "controls/react/form/iconButton";
import { ServiceDocumentWidgetApi } from './serviceDocumentWidgetApi';

const b = require('b_').with('service-document-widget-configuration');

const i = translator({
  "Title is required": {
    "no": "Tittel kreves"
  },
  "Min value": {
    "no": "Min verdi",
    "en": "Min value"
  },
  "Max value": {
    "no": "Max verdi",
    "en": "Max value"
  },
  "Invert": {
    "no": "Inverter"
  },
  "Breach value": {
    "no": "Bruddverdi",
    "en": "Breach value"
  },
  "Column visibility": {
    "no": "Kolonne visning",
    "en": "Column visibility"
  },
  "Dynamic Services": {
    "no": "Dynamiske tjenester",
    "en": "Dynamic services"
  },
  "Document Limit": {
    "no": "Dokumentgrense",
    "en": "Document limit"
  },
  "Size": {
    "no": "Størrelse"
  },
  "Tags (Add tag below to filter on the services tags)": {
    "no": "Etiketter (legg til etikett nedenfor for å filtrere på tjeneste etikett)",
    "en": "Tags (add tag below to filter on service tag)"
  },
  "Document limit selects the newest documents": {
    "no": "Dokumentgrense tar de nyeste dokumentene"
  }
});

export function ServiceDocumentConfiguration(props) {
	const { configLink } = props;
	const accountIdsLink = configLink.get('accountIds').required();
	const dynamicServicesLink = configLink.get('dynamicServices');
	const tagsLink = configLink.get('tags').props;
	const serviceIdsLink = configLink.get('serviceIds').check('Please select service/s', x => {
		if(dynamicServicesLink.value??false)
			return true;
		if(!x || x.length==0)
			return false;
		return true;
	});

	const [accountsList] = useState(createAccountsDatasource());
	const [serviceList, setServiceList] = useState([]);

	useEffect(() => {
		loadServiceList();
	}, [accountIdsLink.value])

	useEffect(() => {
		serviceIdsLink.update(serviceIdsLink.value)
	},[dynamicServicesLink.value])

	async function loadServiceList() {
		if (accountIdsLink.value && accountIdsLink.value.length > 0) {
			const list = [];
			for (const id of accountIdsLink.value) {
				const resp = await ServiceDocumentWidgetApi.getServiceListLite(id);
				list.push(...resp.map((x) => ({ text: x.name, value: x.id })));
			}
			setServiceList(list);
		}
		else {
			setServiceList([]);
		}
	}
	const allColumns = React.useMemo(() => ServiceDocumentColumns.get(), []);

	return <div className={b()}>
		<CollapsibleFrame title={"Configuration"}>
			<FormEntry label={i('Title')} width={"fit"}>
				<AntInput {...configLink.get('title').props}/>
			</FormEntry>
			<FormEntry label={i('Account')} width={"fit"} valueLink={accountIdsLink} >
				<MultiSelectV2 dataSource={accountsList} valueLink={accountIdsLink} />
			</FormEntry>
			<FormEntry>
				<AntCheckbox className={'right'} valueLink={dynamicServicesLink}>{i('Dynamic Services')}</AntCheckbox>
			</FormEntry>
			<FormEntry label={i('Service')} width={"fit"} valueLink={serviceIdsLink}>
				<AntSelect dataList={serviceList}
						   disabled={dynamicServicesLink.value}
						   nameField={'text'}
						   valueField={'value'}
						   mode={"multiple"}
						   valueLink={serviceIdsLink}/>
			</FormEntry>
			{dynamicServicesLink.value && <FormEntry label={i('Tags (Add tag below to filter on the services tags)')} vertical>
				<TagsSelect
					mode={'tags'}
					onChange={tagsLink.onChange}
					value={tagsLink.value}
				/>
			</FormEntry>}
			<FormEntry label={i('Document Limit')} width={"fit"}>
				<IconButton iconName={"question-sign"} title={i('Document limit selects the newest documents')}/>
				<AntInputNumber {...configLink.get('documentLimit').props} />
			</FormEntry>
			<ColumnsVisibility
				columnsLink={configLink.get('columns')}
				allColumns={allColumns}
			/>
		</CollapsibleFrame>
	</div>
}

