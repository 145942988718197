import React, {useCallback, useEffect, useState} from 'react';
import {Button, Space} from "antd";

export class AntTagsProps {
	options: string[];
	value?: string[];
	disabled?: boolean;
	onChange?: (value: string[]) => void;
}

export const AntTags = (props: AntTagsProps) => {
	const [value, setValue] = useState([]);

	useEffect(() => {
		setValue((props.options ?? []).map(x => ({label: x, checked: (props.value ?? []).includes(x)})));
	}, [props.options, props.value])

	const onChange = useCallback((label: string) => {
		const tag = value.find(x => x.label == label);
		tag.checked = !tag.checked;
		props.onChange(value.filter(x => x.checked).map(x => x.label));
	}, [value]);

	return <Space.Compact block>
		{value.map(({label, checked}) => {
			return <Button key={label} type={checked ? 'primary' : undefined} onClick={() => onChange(label)} disabled={props.disabled}>{label}</Button>
		})}
	</Space.Compact>
};
