import {State} from "tools/state";
import Settings from "settings";
import {RemoteEventsManager} from "core/remoteEventsManager";

export async function redirectTo(url, preserveHash = false, newTab = false) {
	if (!newTab && url[0] == '#') {
		State.mainApp.navigate(url);
	} else {

		if (preserveHash) {
			url += window.location.hash;
		}
		if (newTab) {
			window.open(url, '_blank');
		} else {
			//TODO: not sure why do we need to reset connection and force unload module here
			//But 2.17 is about to be released, cannot make this change righn now
			//WE should try to remove the code in 2.18 and check what will happen
			RemoteEventsManager.closeConnection();
			if (State.currentApp) {
				State.isUnloading = true;

				State.mainApp.unloadModule && await State.mainApp.unloadModule();
			}

			var redirect = function () {
				window.location = url;
			};
			// wait for events to unsubscribe and user preferences to be saved
			setTimeout(redirect, 1000);
		}
	}
}

export function openInNewWindow(url, additional){
	if(!url.startsWith('#')){
		url = '#' + url
	}
	if (additional) {
		window.sessionStorage.setItem('storageAdditional', JSON.stringify(additional?.extra));
	}
	window.open(Settings.httpRoot + url, "_blank");
}

