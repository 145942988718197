import React from "react";


import {ChatMessage} from "controls/chat/chatMessage";
import {makeAutoObservable} from "mobx";
import {ChatAiPrompt, sendChatMessage} from "controls/chat/api";
import {apiFetch} from "framework/api";
import {message} from "controls/react/ant/antAppWrapper"
import {infoDialogAsync} from "controls/dialog";

const i18n = require('core/localization').translator({
	"Typing{0}": {
		"no": "Skriver{0}"
	},
	"An error occurred while sending the request to AI":{

	}
});

export class AiChatEngine {
	messages: ChatMessage[] = []
	sending: boolean = false

	typingMessages = [i18n('Typing{0}', '.') , i18n('Typing{0}', '..'), i18n('Typing{0}', '...')]
	typingMessageNumber: number = -1
	interval: number

	containerRef = React.createRef<HTMLDivElement>()

	constructor() {
		makeAutoObservable(this)
	}

	get typingMessage(){
		return this.typingMessages[this.typingMessageNumber % 3]
	}

	send = async (text: string | ChatAiPrompt, includeLastResponse?: boolean) => {
		if (this.sending)
			return

		this.sending = true
		this.showTypingMessage()

		this.messages.push({
			direction: 'from',
			text: typeof text == 'string' ? text : text.text,
			time: Date.now()
		})

		try {
			if(includeLastResponse) {
				let lastResponse = this.messages.findLast(x => x.direction == "to")
				if (lastResponse != null) {
					text = `The last response was: ${lastResponse.text}. A new question is : ${text}`
				}
			}

			const response = await apiFetch(sendChatMessage({
				message: text
			}))

			this.messages.push({
				direction: 'to',
				type: response.success ? undefined : 'error',
				text: response.success ? response.data : i18n('An error occurred while sending the request to AI'),
				time: Date.now()
			})

			if(response.success === false){
				infoDialogAsync(i18n('An error occurred while sending the request to AI'), response.message, response.details)
			}

		} finally {
			this.sending = false
			this.hideTypingMessage()

			requestIdleCallback(this.scrollToBottom)
		}
	}

	showTypingMessage(){
		this.interval = window.setInterval(() => {
			this.typingMessageNumber ++
			this.scrollToBottom()
		}, 300)
	}

	hideTypingMessage(){
		clearInterval(this.interval)
		this.typingMessageNumber = -1
	}

	scrollToBottom = () => {
		const container = this.containerRef.current
		if (!container)
			return

		container.scrollTop = container.scrollHeight
	}
}
