import moment from 'moment';

import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import Highcharts from 'highcharts';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import Configuration from 'configuration';
import {translator} from "core/localization";
import {getTrendValueWidgetWrapper} from "vendor-init/hightcharts-intergation";
import {timePeriodToUrl} from "../../../controls/react/form/timePeriodSelector";
import {TimePeriodType} from "controls/react/form/timePeriodType";

// It seems that this widget uses nowhere

const i18n = translator();

export function MetricsWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.configuration.timezone = this.configuration.timezone === "<User Time>" ? Cookies.CeesoftTimezone : this.configuration.timezone;
	this.initComponent();
};

export {MetricsWidget as default};

jQuery.extend(MetricsWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		var widgetContentDiv;
		this.isViewer = this.isViewer || false;

		this.hasTimeSelector = true;
		this.hasMissingData = true;
		this.hasRegression = true;
		this.hasChartTypeSelector = true;
		this.customControls = {
			target: '#' + this.id,
			toggleClick: function (value) {
				if ($('#' + this.id).find('.k-i-toggle').length) {
					$('#' + this.id).find('.k-i-toggle').trigger('click');
				} else {
					$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
				}
			}
		};

		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = $('#' + this.renderTo);
		}

		this.widgetContentDiv = widgetContentDiv;
		var widgetDiv = $('#' + this.id);
		this.onZoom = false;
		widgetDiv.off();
		widgetDiv.on('drop', $.proxy(this.onDrop, this));


		this.getData();
		this.subscribe();
		this.updatePeriodSelector();
	},
	/**
	 * Updates the widget title based on asset name and monitor name
	 * @param {String} assetName
	 * @param {String} monitorName
	 */
	updateTitle: function (assetName, monitorName) {
		var title, strTitle;
		var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
		var shownAssetName = assetName || '';
		var shownMonitorName = monitorName || '';
		if (shownAssetName || shownMonitorName) {
			title = shownAssetName + ' &raquo; ' + shownMonitorName;
			strTitle = title.split('&raquo;').join('\u00bb');
		} else {
			title = lang.SINGLEGRAPH;
			strTitle = lang.SINGLEGRAPH;
		}
		//check for user title
		title = this.title || title;
		strTitle = this.title || strTitle;
		titleSpan.html(title);
		titleSpan.attr('title', strTitle);
	},
	/**
	 * Gets the inistial data to populate the chart
	 */
	getData: function () {
		let widgetContentDiv;
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = $('#' + this.renderTo);
		}

		widgetContentDiv.addClass('assets-metricsWidget');

		const maxSampleSize = Math.floor(widgetContentDiv.width()) - 60 //the size of the plot area

		if (!this.instanceConfiguration.period) {
			this.instanceConfiguration.period = this.configuration.period;
		}

		const urlParams = timePeriodToUrl(this.timePeriodZoomed ?? this.instanceConfiguration)
		let loadUrl = this.requestPath + 'accounts/' + this.configuration.accountId
			+ '/assets/' + this.configuration.assetId + '/healthIndexes/?ignoreMissingData=' + this.configuration.ignoreMissingData
			+ '&' + urlParams + '&_dc=' + Utils.timestamp() + '&maxSamples=' + maxSampleSize
		if (this.configuration.timezone) {
			loadUrl += '&timeZone=' + this.configuration.timezone;
		}

		var data;
		if (this.configuration.monitorId && this.configuration.assetId) {
			data = [{
				monitorId: this.configuration.monitorId,
				assetId: this.configuration.assetId
			}];
		} else {
			data = [];
		}

		kendo.ui.progress(widgetContentDiv, true);
		this.createCustomControls();
		Utils.ajax(loadUrl, 'POST', JSON.stringify(data), $.proxy(function (result) {
			this.statusNotification = new CustomNotification({
				animationTime: 0,
				appendToElement: $('#' + this.id).find('.section__content')
			});

			if (result.success || result.data || Object.keys(result).length) {
				if (result.data && result.data.length) {
					this.render(result.data);
				} else if (result.length) {
					this.updateTitle(result[0].assetName, result[0].monitorName);
					this.render(result);
				} else {
					if (this.onZoom) {
						kendo.ui.progress(widgetContentDiv, false);
						this.statusNotification.setOptions({
							message: lang.widget.messages.NO_ZOOM_AVAILABLE,
							status: 'error',
							style: 'left: 70px'
						}).show();
					} else {
						widgetContentDiv.empty().append('<div class="metric_chart"></div>');
						kendo.ui.progress(widgetContentDiv, false);
						this.statusNotification.setOptions({
							message: result.message,
							status: 'error'
						}).show();
					}
				}
			} else {
				if (!this.onZoom) {
					widgetContentDiv.empty().append('<div class="metric_chart"></div>');
				}
				kendo.ui.progress(widgetContentDiv, false);
				this.statusNotification.setOptions({
					message: result.message || lang.serviceBoard.messages.NO_DATA,
					status: 'error'
				}).show();
			}
		}, this));
	},
	/**
	 * Creates the chart based on the received dataset
	 * @param {Object} dataSet
	 */
	render: function (dataSet) {
		var widgetContentDiv;
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = $('#' + this.renderTo);
		}
		if (!widgetContentDiv.length) {
			// we have no container yet
			return;
		}
		widgetContentDiv.empty().append('<div class="metric_chart"></div>');
		this.dataSet = dataSet;
		this.data = [];
		var seriesItem, name, fullName;

		for (var i = 0, length = dataSet.length; i < length; i++) {
			seriesItem = {};
			fullName = this.generateMultigraphLabel(dataSet[i]);
			name = fullName.length > 60 ? fullName.substr(0, 57) + '...' : fullName;

			seriesItem.name = name;
			seriesItem.fullName = fullName;
			if (!seriesItem.name) {
				seriesItem.showInLegend = false;
			}

			seriesItem.visible = true;
			seriesItem.data = [];
			for (var j = 0, lng = dataSet[i].metrics.length; j < lng; j++) {
				seriesItem.data.push([dataSet[i].metrics[j].timestamp, dataSet[i].metrics[j].value]);
			}
			seriesItem.dataGrouping = {
				approximation: this.instanceConfiguration.aggregationType || 'high'
			};

			this.data.push(seriesItem);
		}

		if (this.instanceConfiguration.chartType === 'arearange') {
			//duplicate the second data parameter in order to fulfill the arearange HighCharts format; discuss if low/high values should be take into account instead aggregate ones
			for (let j = 0; j < this.data.length; j++) {
				this.data[j].push(this.data[j][1]);
			}
		}

		if (this.data.length) {
			this.data[0].regression = this.configuration.showRegression;
			this.data[0].regressionSettings = {
				type: 'linear',
				color: 'rgba(255,165,0,1)',
				name: this.data[0].name,
				fullName: this.data[0].fullName
			};
		}

		var exporting = {};
		// pass default configurations
		Utils.apply(exporting, Configuration.highcharts.exporting);
		// remove mask
		kendo.ui.progress(widgetContentDiv, false);
		widgetContentDiv.find('.metric_chart').empty();
		var oThis = this;

		Highcharts.setOptions({
			global: {
				useUTC: true,
				timezoneOffset: -moment.tz(this.configuration.timezone).utcOffset()
			}
		});

		let container = widgetContentDiv.find('.metric_chart');
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
			container.css('height', dashboardWindow.height());
		} else {
			height = container.closest('.cw_section_content').height();
		}

		let header = dashboardWindow.closest('.html-shape-container').find('.toolbar_appearance_section-header').first();
		let missingHeader = false;
		if (header.css('display') === 'none' || header.width() === -30) {
			missingHeader = true;
		}
		if (this.instanceConfiguration.timeSelectorPosition === 'BOTTOM') {
			height = height - 30;
			this.moveTimeSelectorBottom(missingHeader);
		} else if (this.instanceConfiguration.timeSelectorPosition === 'TOP' && missingHeader)  {
			height = height - 30;
			this.renderTo.css('margin-top', '35px');
		}

		this.reasonsChart = new Highcharts.Chart({
			chart: {
				renderTo: widgetContentDiv.find('.metric_chart')[0],
				type: this.configuration.chartType,
				zoomType: 'x',
				resetZoomButton: {
					position: {
						align: 'left',
						width: 5,
						x: 5,
						y: 5
					},
					theme: {
						'stroke-width': 1,
						stroke: '#aaa',
						fill: '#fff',
						r: 0,
						states: {
							hover: {
								fill: '#eee'
							},
							select: {
								fill: '#ccc'
							}
						}
					}
				},
				backgroundColor: 'transparent',
				height: widgetContentDiv.height(),
				events: {
					load: function () {
						kendo.ui.progress(widgetContentDiv, false);
					},
					selection: event => {
						if(this.dataSet.every(x => !x.aggregated) && !event.resetSelection)
							return

						event.preventDefault();

						if(event.resetSelection){
							this.timePeriodZoomed = null
							this.getData()
						} else if (event.xAxis) {
							const timePeriod = {
								period: TimePeriodType.Custom,
								startDate: parseInt(event.xAxis[0].min, 10),
								endDate: parseInt(event.xAxis[0].max, 10)
							}

							this.timePeriodZoomed = timePeriod
							this.getData()
						}
					}
				}
			},
			title: {
				text: ' '
			},
			legend: {
				title: {
					text: '', //lang.assethealth.MONITOR_NAME,
					align: 'center'
				},
				x: 2,
				y: 20,
				floating: false,
				borderWidth: 0,
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				useHTML: true,
				itemDistance: 15,
				itemStyle: {
					fontSize: "10px"
				},
				style: {
					fontSize: "10px"
				},
				labelFormatter: function () {
					if (this.userOptions.isRegressionLine) {
						return '<span title="' + (this.userOptions.fullName || this.userOptions.name) + '">' + i18n('Trend') + ': ' + this.name + '</span>';
					} else {
						return '<span title="' + (this.userOptions.fullName || this.userOptions.name) + '">' + this.name + '</span>';
					}
				}
			},
			credits: {
				enabled: false
			},
			exporting: exporting,
			xAxis: {
				type: 'datetime',
				labels: {
					staggerLines: 1
				},
				dateTimeLabelFormats: {
					millisecond: '%H:%M:%S',
					second: '%H:%M:%S',
					minute: '%H:%M',
					hour: '%H:%M',
					day: '%e. %b',
					week: '%e. %b',
					month: '%b \'%y',
					year: '%Y'
				},
				visible: this.instanceConfiguration.showXAxis
			},
			yAxis: {
				title: {
					text: null
				},
				softMin: 0,
				minPadding: 0,
				visible: this.instanceConfiguration.showYAxis
			},
			tooltip: {
				crosshairs: true,
				snap: 1,
				shared: true,
				backgroundColor: 'white',
				borderWidth: 0,
				shadow:{
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				formatter: function (e) {
					var v;
					var userOptions = this.series?.userOptions || {};
					if (userOptions.isRegressionLine) {
						return getTrendValueWidgetWrapper(this, oThis);
					} else {
						var s = Renderer.browserDateRenderer(this.x, 'datetime', '', oThis.configuration.timezone);
						var v = '<br />' + oThis.reasonsChart.series[0].name + ': ' + this.y;
						return s + v;
					}
				}
			},
			plotOptions: {
				series: {
					marker: {
						enabled: false
					}
				},
				line: {
					events: {
						//click: $.proxy(this.onHealthHistoryPoint, this),
						//legendItemClick: $.proxy(this.onHealthHistoryLegend, this)
					}
				}
			},
			series: this.data
		});

		Highcharts.setOptions({
			global: {
				useUTC: false
			},
			lang: {
				resetZoom: lang.RESET
			}
		});
	},

	/**
	 * Called when a metric event is received
	 */
	onEvent: function () {
		this.getData();
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		kendo.ui.progress(this.widgetContentDiv, true);
		this.getData();
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	/**
	 * Handler function for the drop event
	 */
	onDrop: function () {
		this.destroy();
		this.getData();
		this.subscribe();

	},
	/**
	 * Updates multi toggle component position. When widget size is lower than 440
	 * px, period selector moves on top of the section content
	 */
	updatePeriodSelector: function () {
		var widgetDiv = $('#' + this.id);
		if (widgetDiv.width() < 440) {
			widgetDiv.find('.cw_multi_toggle').css({
				'right': '45px',
				'z-index': 100
			});
			widgetDiv.find('.cw_section_titlebar').css('overflow', 'visible');
		} else {
			widgetDiv.find('.cw_multi_toggle').css({
				'top': 'none',
				'right': 'none'
			});
			widgetDiv.find('.cw_section_titlebar').css('overflow', 'hidden');
		}
	},
	/**
	 * Subscribes to server metric events
	 *  * @param {Boolean} noGetData
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			assetId: this.configuration.assetId
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 * @param {Boolean} dontUnsubscribe
	 */
	destroy: function (dontUnsubscribe) {
		if (!dontUnsubscribe) {
			Widget.prototype.destroy.call(this);
		}
		/*if (this.chart && $(this.chart.renderTo).length) {
			this.chart.destroy();
		}*/
	}
});
