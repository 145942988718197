import { kebabCase } from "lodash"

export const setElementVariables = (element: HTMLElement, prefix: string, variables: {[x: string]: string}): void => {
	Object.entries(variables)
		.filter(([k, v]) => v)
		.forEach(([key, value]
		) => {
			element.style.setProperty(`--${prefix}-${kebabCase(key)}`, value)
	})
}
