import {set} from "mobx"

import {GridState} from "controls/grid/gridState"
import {UpdateMode} from "controls/grid/plugins/autoReloadPlugin"
import {GridColumnConfig, GridColumnState} from "controls/grid/gridColumnConfig"
import {
	FieldType,
	RuleDefinition,
	RulesConfiguration,
	RulesConfigurationEntry
} from "controls/queryBuilder/ruleDefinition"
import {UserSettings} from "tools/userSettings"
import {GridDataEntry} from "controls/grid/gridDataEntry"
import {GridViewState} from "controls/grid/gridViewState";
import {TimePeriod} from "controls/react/form/timePeriodSelector";

type LegacySorting = {
	field: string
	dir: 'asc'|'desc'
}

type LegacyColumn = {
	hidden: boolean
	index: number
	width?: number
}

type LegacyColumnFilter = {
	filters?: LegacyColumnFilter[]
	logic?: 'or' | 'and'
	field?: string
	operator?: string
	value?: string
}


type LegacyGridStateKeys = {
	search: string
	refreshTimer?: string
	sort: string
	columns: string
	filters: string
	timePeriod?: string
	views: string
	defaultView: string
}

type LegacyViewDescription = {
	id: string
	name: string
	search: string
}

type LegacyView = {
	sort: LegacySorting[]
	filter: LegacyColumnFilter
	columns: Record<string, LegacyColumn>
}

export type LegacyGridWidgetPersistedState = {
	columns: Record<string, LegacyColumn>
	sort: {field: string, dir: 'asc'|'desc'}[],
	filter: LegacyColumnFilter,
	group: any[]
}

export type LegacyMappingEntry = {name?: string, filter?: (value: any) => any }
export type LegacyMapping = Record<string, (LegacyMappingEntry | string)>



export async function tryGetLegacyGridState<DataItem extends GridDataEntry>(
	gridId: string,
	rootKey: string,
	subkeys: LegacyGridStateKeys,
	columns: GridColumnConfig<DataItem>[],
	customCallback: (settings: Record<string, any>,state: GridState<DataItem>) => void,
	rules: RulesConfiguration,
	mappings: LegacyMapping
): Promise<GridState<DataItem>>{
	await UserSettings.userSettingsCache.load(rootKey, true)
	const result = await UserSettings.getCategory(rootKey) as Record<string, any>

	//hardcoded legacy services grid state to test migration code
	//can be removed when services grid is released
	// const result2 = [
	// 	{
	// 		"key": "8815c7c1-b7bb-48e4-9409-27b96cead733",
	// 		"value": "{\"sort\":[],\"filter\":[],\"group\":[],\"columns\":{\"id\":{\"hidden\":false,\"index\":0,\"width\":40},\"serviceState\":{\"hidden\":false,\"index\":1,\"width\":45},\"responsibleTeamName\":{\"hidden\":false,\"index\":2,\"width\":160},\"name\":{\"hidden\":false,\"index\":3,\"width\":240},\"accountName\":{\"hidden\":true,\"index\":4,\"width\":150},\"srvHealthIndex\":{\"hidden\":true,\"index\":5,\"width\":140},\"srvSlaIndex\":{\"hidden\":false,\"index\":6,\"width\":80},\"incidentCount\":{\"hidden\":false,\"index\":7,\"width\":120},\"coverage\":{\"hidden\":false,\"index\":8,\"width\":140},\"Operational\":{\"hidden\":false,\"index\":9,\"width\":150},\"stateDuration\":{\"hidden\":false,\"index\":10,\"width\":150},\"tags\":{\"hidden\":false,\"index\":11,\"width\":150},\"shared\":{\"hidden\":true,\"index\":12,\"width\":100},\"information\":{\"hidden\":false,\"index\":13,\"width\":200},\"description\":{\"hidden\":false,\"index\":14}}}"
	// 	},
	// 	{
	// 		"key": "defaultFsView",
	// 		"value": ""
	// 	},
	// 	{
	// 		"key": "eventsStatus",
	// 		"value": "{\"playing\":true,\"startFrom\":15}"
	// 	},
	// 	{
	// 		"key": "servicesColumns",
	// 		"value": "{\"id\":{\"hidden\":false,\"index\":0,\"width\":40},\"serviceState\":{\"hidden\":false,\"index\":1,\"width\":45},\"responsibleTeamName\":{\"hidden\":false,\"index\":2,\"width\":160},\"name\":{\"hidden\":false,\"index\":3,\"width\":240},\"accountName\":{\"hidden\":true,\"index\":4,\"width\":150},\"srvHealthIndex\":{\"hidden\":true,\"index\":5,\"width\":140},\"srvSlaIndex\":{\"hidden\":false,\"index\":6,\"width\":80},\"incidentCount\":{\"hidden\":false,\"index\":7,\"width\":120},\"coverage\":{\"hidden\":false,\"index\":8,\"width\":140},\"Operational\":{\"hidden\":false,\"index\":9,\"width\":150},\"stateDuration\":{\"hidden\":false,\"index\":10,\"width\":150},\"tags\":{\"hidden\":false,\"index\":11,\"width\":150},\"shared\":{\"hidden\":true,\"index\":12,\"width\":100},\"information\":{\"hidden\":false,\"index\":13,\"width\":200},\"description\":{\"hidden\":false,\"index\":14}}"
	// 	},
	// 	{
	// 		"key": "servicesFSViews",
	// 		"value": "[{\"id\":\"8815c7c1-b7bb-48e4-9409-27b96cead733\",\"name\":\"Cool view\",\"search\":\"\"}]"
	// 	},
	// 	{
	// 		"key": "servicesFilter",
	// 		"value": "{\"logic\":\"and\",\"filters\":[{\"logic\":\"or\",\"filters\":[{\"field\":\"name\",\"operator\":\"contains\",\"value\":\"Test\"},{\"field\":\"responsibleTeamName\",\"operator\":\"contains\",\"value\":\"Test\"},{\"field\":\"description\",\"operator\":\"contains\",\"value\":\"Test\"},{\"field\":\"information\",\"operator\":\"contains\",\"value\":\"Test\"}]}],\"isCustom\":true}"
	// 	},
	// 	{
	// 		"key": "servicesSearchPhrase",
	// 		"value": "Test"
	// 	},
	// 	{
	// 		"key": "servicesShowGridView",
	// 		"value": "true"
	// 	},
	// 	{
	// 		"key": "servicesSort",
	// 		"value": "[]"
	// 	},
	// 	{
	// 		"key": "showGridView",
	// 		"value": "true"
	// 	}
	// ]

	// const result: Record<string, any> = {}
	//  result2.forEach((x) => {
	// 	 try{
	// 		 result[x.key] =JSON.parse(x.value)
	// 	 }catch{
	// 		 result[x.key] = x.value
	// 	 }
	//  })

	let state = convertLegacyState(columns, {
		viewsHolder: result as Record<string, LegacyView>,
		views: result[subkeys.views],
		searchString: result[subkeys.search],
		sort: result[subkeys.sort],
		filter: result[subkeys.filters],
		refreshTimer: result[subkeys.refreshTimer],
		columns: result[subkeys.columns],
		timePeriod: result[subkeys.timePeriod],
	}, rules, mappings)

	if(!state)
		return null

	customCallback?.(result, state)

	await UserSettings.set(rootKey + 'backup', result)
	await UserSettings.clear(rootKey)

	await UserSettings.set('grid-settings-' + gridId, state)
}

function convertView<DataItem extends GridDataEntry>(
	viewState: GridViewState<DataItem>,
	columns: GridColumnConfig<DataItem>[],
	legacyColumns: Record<string, LegacyColumn>,
	legacyFilters: LegacyColumnFilter,
	legacySorting: LegacySorting[],
	filtersConfiguration: RulesConfiguration,
	mappings: LegacyMapping
){

	const getMapping = (field: string): LegacyMappingEntry => {
		if(!mappings[field]){
			return {}
		}

		const mapping = mappings[field]

		if(typeof mapping == 'string'){
			return {
				name: mapping
			}
		}else{
			return mapping
		}
	}

	const getMappedField = (field: string): string => {
		const mapping = getMapping(field)

		if (!mapping.name)
			return field

		return mapping.name
	}

	const getFilterValue = (field: string, value: string) => {
		const mapping = getMapping(field)
		if(!mapping.filter){
			return value
		}

		return mapping.filter(value)
	}


	legacySorting = legacySorting ?? []

	if(legacyColumns){
		for(const [field, column] of Object.entries(legacyColumns)){
			const columnState = new GridColumnState()
			columnState.field = getMappedField(field)
			columnState.width = field == 'id' && column.width == 40 ? 32 : column.width
			columnState.visible = !column.hidden
			columnState.sorting = legacySorting.find(x => x.field == field)?.dir

			if(!columns.find(x => x.field == columnState.field) && field != 'id'){
				console.log('Missing column:', columnState.field, field)
			}

			set(viewState.columns, column.index, columnState)
		}

		viewState.columns = viewState.columns.filter(x => x)
	}

	viewState.sortingOrder = legacySorting.map(x => getMappedField(x.field))


	if(legacyFilters?.filters?.length > 0){
		for(const filterEntry of legacyFilters.filters){
			if(filterEntry.filters == null){ //means that is a single-value filter, probably just a text field
				const config = filtersConfiguration[filterEntry.field]
				if(!config){
					console.warn('Column not found in the rules configuration', filterEntry.field)
					continue
				}

				if(getOperator(config, filterEntry.operator) == null){
					console.warn('Unknow operator', filterEntry.operator)
					continue
				}

				const rule = RuleDefinition.emptyRule(
					getMappedField(filterEntry.field),
					getOperator(config, filterEntry.operator)
				)

				rule.properties.value[0] = filterEntry.value

				viewState.filters.addOrUpdateRule(rule)

			}else{ //multiple subfilters detected. That is either a search string or a multiselect filter on a column.
				//So we iterate over every subentry to check that they have the same field and expected operator.
				if(isSearchStringSubgroup(filterEntry, viewState.searchString))
					continue

				if(filterEntry.logic != 'or'){
					console.warn('We dont support not "OR" condition under +')
					continue
				}

				let field: string = null
				let operator: string = null
				let values: string[] = []

				for(const entry of filterEntry.filters){
					if(field == null){
						field = entry.field
					}

					if(field != entry.field){
						console.warn('A different field is met in a subset', field, entry.field)
						continue
					}

					if(operator == null){
						operator = entry.operator
					}

					if(operator != entry.operator){
						console.warn('A different operator is met in a subset', operator, entry.operator)
						continue
					}

					values.push(getFilterValue(field, entry.value))
				}

				const config = filtersConfiguration[getMappedField(field)]
				if(!config){
					console.warn('Column not found in the rules configuration', getMappedField(field))
					continue
				}

				const rule = RuleDefinition.emptyRule(
					getMappedField(field),
					getMultiselectOperator(operator)
				)

				rule.properties.value = values

				viewState.filters.addOrUpdateRule(rule)
			}
		}
	}
}

type LegacyState<DataItem extends GridDataEntry> = {
	columns:  Record<string, LegacyColumn>
	filter: LegacyColumnFilter
	sort: LegacySorting[]
	searchString?: string,
	searchValue?: string,
	timePeriod?: TimePeriod,
	refreshTimer?: {
		startFrom: number
		playing: boolean
	},
	views?: LegacyViewDescription[],
	viewsHolder?: Record<string, LegacyView>
}

export function convertLegacyState<DataItem extends GridDataEntry>(
	columns: GridColumnConfig<DataItem>[],
	legacyState: LegacyState<DataItem>,
	filtersConfiguration: RulesConfiguration,
	mappings: LegacyMapping = {}
) {
	if (!legacyState.columns)
		return

	const state = new GridState<DataItem>()
	state.ensureDefaultViewExists()

	state.searchString = legacyState.searchString ?? legacyState.searchValue

	if (legacyState.timePeriod) {
		state.customPayload.timePeriod = {
			period: legacyState.timePeriod
		}
	}

	if (legacyState.refreshTimer) {
		let timer = Math.max(legacyState.refreshTimer.startFrom ?? 0, 10)
		state.customData.autoReload = {
			interval: timer,
			mode: legacyState.refreshTimer.playing ? UpdateMode.OnTimer : UpdateMode.None
		}
	}

	convertView(state.currentView,
		columns,
		legacyState.columns,
		legacyState.filter,
		legacyState.sort,
		filtersConfiguration,
		mappings
		)

	if (legacyState.views) {
		for (const legacyViewDescription of legacyState.views) {
			if (!legacyState.viewsHolder[legacyViewDescription.id])
				continue

			const view = new GridViewState<DataItem>()
			state.views.push(view)

			view.id = legacyViewDescription.id
			view.name = legacyViewDescription.name
			view.searchString = legacyViewDescription.search
			const legacyView = legacyState.viewsHolder[view.id] as LegacyView
			convertView(view, columns, legacyView.columns, legacyView.filter, legacyView.sort, filtersConfiguration, mappings)
		}
	}

	return state
}

function isSearchStringSubgroup(filter: LegacyColumnFilter, searchString: string){
	if(!searchString || filter.filters.length == 0)
		return false

	return filter.filters.every(x => x.operator == 'contains' && x.value == searchString)
}

const getMultiselectOperator = (legacyOperator: string) => {
	switch (legacyOperator) {
		case 'contains':
			return 'like'
		case 'eq':
			return 'multiselect_equals'
		case 'neq':
			return 'multiselect_not_equals'
	}
	return null
}

function getOperator(config: RulesConfigurationEntry, legacyOperator: string){
	if(config.type == FieldType.Date){
		switch(legacyOperator){
			case 'gt':
				return 'time_greater'
			default:
			case' lt':
				return 'time_less'
		}
	}

	switch (legacyOperator) {
		case 'eq':
			return 'equal'

		case 'neq':
			return 'not_equal'

		case 'gte':
			return'greater_equal'

		case 'lte':
			return 'lesser_equal'

		case 'contains':
			return 'like'

		case 'startswith':
			return 'starts_with'

		case 'endswith':
			return 'ends_with'
	}

	return null
}
