import MetricsWidgetConfiguration from './metricsWidgetConfiguration';
import MetricsWidget from './metricsWidget';
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: MetricsWidgetConfiguration,
		defaultConfig: {
			type: 'health-single-graph',
			title: i('Single Graph'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true,
				reactTimePeriodSelector: true
			}
		},
		widget: MetricsWidget,
		legacyWidget: true,
		legacyForm: true,
		showToggleIcon: true,
		fullTitle: i('Health') + '/' + i('Single Graph'),
	}
}
