import React, {useEffect} from 'react';
import {observer} from "mobx-react";

import {EventsSummaryPageContext, EventsSummaryPageStore} from "areas/summary/events/eventsSummaryPageStore";
import Page from 'controls/react/layout/page';
import {Toolbar} from "controls/react/layout";
import {RefreshTimer} from "controls/grid/refreshTimer";
import {
	TimePeriodAppearance,
	TimePeriodSelector
} from "controls/react/form/timePeriodSelector";
import {Grid} from 'controls/grid/grid';
import {AntSwitch} from "controls/react/ant/antSwitch";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {linkGridAdditionalPayload} from 'controls/grid/gridStore';
import {ViewSelector} from "controls/grid/viewManager/viewSelector";
import {GridSearchInput} from 'controls/grid/gridSearchInput';
import {GridFilteredIndicator} from "controls/grid/gridFilteredIndicator";
import {EventsSummaryStore} from "areas/summary/events/eventsSummaryStore";
import EventSummaryDetails from './eventSummaryDetails';
import {GridMenuButton} from "controls/grid/plugins/grid-menu/gridMenuButton";
import {TimePeriodType} from "controls/react/form/timePeriodType";

const i18n = require('core/localization/localization').translator({
  "Show Historic": {
    "no": "Vis historisk",
    "en": "Show historic"
  }
});

export const EventsTimePeriods = [TimePeriodType.Last24Hours, TimePeriodType.Last7Days,
	TimePeriodType.Last30Days, TimePeriodType.Custom, TimePeriodType.DynamicInterval, TimePeriodType.All]

export const EventsSummaryPage = observer(() => {
	const store = React.useContext(EventsSummaryPageContext);
	useEffect(() => {
		$('.cw_event_summary_notifications').addClass('is_selected');
	}, []);

	if(!store.gridStore?.selfInitialized)
		return null

	return <Page>
		<Toolbar title={store.title}>
			<GridMenuButton store={store.gridStore}/>
			<RefreshTimer store={store.gridStore}/>
			<GridFilteredIndicator store={store.gridStore} position={"after-title"}/>
			<ShowHistoricButton store={store}/>
			<TimePeriodSelector periods={EventsTimePeriods}
			                    appearance={TimePeriodAppearance.Buttons}
			                    size={"small"}
			                    {...linkGridAdditionalPayload(store.gridStore, "timePeriod")}/>
			<DataSizeIndicator store={store.gridStore}/>
			<GridSearchInput store={store.gridStore}/>
			<ViewSelector store={store.gridStore}/>
		</Toolbar>
		<Grid store={store.gridStore} />
		{store.showDetailsModal && <EventSummaryDetails />}
	</Page>
})

type ShowHistoricButtonProps = {
	store: EventsSummaryStore
}
export const ShowHistoricButton = observer((props: ShowHistoricButtonProps) => {
	return <AntSwitch checkedChildren={i18n("Show Historic")}
	                  unCheckedChildren={i18n("Show Historic")}
	                  size={"small"}
	                  {...linkGridAdditionalPayload(props.store.gridStore, "showHistoricEvents")}
	/>
})

export {EventsSummaryPage as default, EventsSummaryPageStore as Store, EventsSummaryPageContext as Context}
