import './menuButton.less';

import React from 'react';
import PropTypes from 'prop-types';
import GridMenu, {GridMenu as GridMenuLegacy} from "controls/gridMenu";

let b = require('b_').with('menu-button');

export class MenuButton extends React.PureComponent {
	constructor(props) {
		super(props);
		this.refEl = React.createRef();
	}

	componentDidMount() {
		this.gridMenu = new GridMenuLegacy({
			renderTo: $(this.refEl),
			items: this.props.items,
			grid: this.props.grid,
			sortingDisabled: this.props.sortingDisabled,
			size: this.props.size,
			indexedByCategory: this.props.indexedByCategory
		})
	}

	componentDidUpdate(prevProps) {
		if( prevProps.grid != this.props.grid){
			this.gridMenu.setGrid(this.getKendoGrid(this.props.grid));
		}
		if (prevProps.items != this.props.items) {
			this.gridMenu.populateMenu(this.props.items);
		}
	}

	componentWillUnmount(){
		this.gridMenu.destroy()
	}

	setGrid(grid){
		this.gridMenu.setGrid(this.getKendoGrid(grid));
	}

	setItemText(id, text){
		this.gridMenu.setItemText(id, text);
	}

	enableItems(enable){
		this.gridMenu.enableItems(enable);
	}

	enableItem(id){
		this.gridMenu.enableItem(id);
	}

	disableItem(id){
		this.gridMenu.disableItem(id);
	}

	getKendoGrid(grid){
		if(grid == null )
			return null;

		if( grid.kendoGrid != null )
			return grid.kendoGrid;

		return grid;
	}

	render() {
		return (
			<div className={b()} ref={(el) => { this.refEl = el }}/>
		)
	}
}


MenuButton.AtLeastOneItemSelected = '1';
MenuButton.ExactlyOneItemSelected = '2';

export {MenuButton as default};

MenuButton.propTypes = {
	items: PropTypes.array.isRequired,
	sortingDisabled: PropTypes.bool
}
