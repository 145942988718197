import {UrlBuilder} from "../../tools";
import {ManagementRouter} from "../management/bundleDescription";

export function getDescriptions(){
	return [{
		url: '/:type/:id/ruleset',
		getModuleImport: () => import('./users/ruleset'),
		params: {},
		react: true,
		routeId: 'ruleset'
	}, {
		url: '/:type/:id/ruleset/channels',
		getModuleImport: () => import('./users/scheduler/schedulerView'),
		params: {},
		react: true,
		routeId: 'channels'
	}, {
		url: '/:type/:id/ruleset/policy',
		getModuleImport: () => import('./users/policyView/policyViewLegacyWrapper'),
		params: {},
		react: true,
		routeId: 'policy'
	}, {
		jspUrl: 'include/Administration/Accounts.jsp',
		url: '/accounts',
		getModuleImport: () => import('./accountsView'),
		routeId: 'administration_accounts'
	}, {
		jspUrl: 'include/Administration/Calendars.jsp',
		url: '/calendars',
		getModuleImport: () => import('./calendarView'),
		routeId: 'administration_calendar'
	}, {
		jspUrl: 'include/Administration/Teams.jsp',
		url: '/teams',
		getModuleImport: () => import('./teamsView'),
		routeId: 'administration_teams'
	}, {
		jspUrl: 'include/Administration/UserCategories/View.jsp',
		url: '/roles',
		getModuleImport: () => import('./roles/roleView'),
		routeId: 'administration_usercategories'
	}, {
		jspUrl: 'include/Administration/Users.jsp',
		url: '/users',
		getModuleImport: () => import('./usersView'),
		routeId: 'administration_users'
	}, {
		jspUrl: 'include/Administration/UserCategories/Details.jsp',
		url: '/roleDetails',
		getModuleImport: () => import('./roles/roleDetails'),
		routeId: 'administration_usercategories_details'
	}, {
		url: '/accounts/:id',
		getModuleImport: () => import('./accounts/detailsContent'),
		react: true,
		routeId: 'administration_accounts_details'
	}, {
		url: '/accounts/configuration/:id',
		getModuleImport: () => import('./accounts/configurationDetailsContent'),
		react: true,
		routeId: 'administration_accounts_configuration'
	}, {
		url: '/calendars/:id',
		getModuleImport: () => import('./calendar/detailsContent'),
		react: true,
		routeId: 'administration_calendar_details'
	}, {
		jspUrl: 'include/Administration/Authentications.jsp',
		url: '/authentications',
		getModuleImport: () => import('./authenticationsView'),
		routeId: 'administration_authentications'
	}, {
		jspUrl: 'include/Administration/ServiceModelTemplates.jsp',
		url: '/templates/model',
		getModuleImport: () => import('./template/serviceModelView.js'),
		routeId: 'administration_servicemodel_templates'
	}, {
		jspUrl: 'include/Administration/ServiceElementTemplates.jsp',
		url: '/templates/element',
		getModuleImport: () => import('./template/serviceElementView.js'),
		routeId: 'administration_serviceelement_templates'
	}, {
		jspUrl: 'include/Administration/ServiceQualifierTemplates.jsp',
		url: '/templates/qualifier',
		getModuleImport: () => import('./template/serviceQualifierView.js'),
		routeId: 'administration_servicequalifier_templates'
	}];
}

export class AdministrationRouter{
	static listAccounts(){
		return '/accounts'
	}

	static listCalendars(){
		return '/calendars'
	}

	static listTeams(){
		return '/teams'
	}

	static listRoles(){
		return '/roles'
	}

	static listUsers(){
		return '/users'
	}

	static roleDetails(){
		return '/roleDetails'
	}

	static accountDetails(accountId){
		return `/accounts/${accountId}`;
	}

	static accountConfigurationDetails(id, type, mode, name, showProfile, factoryName, configurationId){
		return `/accounts/configuration/${id}?type=${type}&mode=${mode}&name=${name}&showProfile=${showProfile}&factoryName=${factoryName}&configurationId=${configurationId}`;
	}

	static calendarDetails(calendarId, type, firstLoad) {
		const builder = new UrlBuilder(`/calendars/${calendarId}?type=${type}`);
		builder.add('firstLoad', firstLoad, !!firstLoad)
		return builder.build();
	}

	static authentications() {
		return '/authentications'
	}

	static serviceModelTemplates() {
		return '/templates/model'
	}

	static serviceElementTemplates() {
		return '/templates/element'
	}

	static serviceQualifierTemplates() {
		return '/templates/qualifier'
	}
}

export const defaultBreadcrumbItems = () => {
	return [{
		title: i('Administration'),
		url: '#' + AdministrationRouter()
	}]
}
