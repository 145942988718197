import React from "react"

import {getHtmlContainer} from "controls/designer/features/htmlContainerShape";
import {noRedirectConfig} from "controls/designer/features/redirectOnClick/redirectConfig";
import {WidgetsActions} from "controls/designer/features/widgets/widgetsActions";

import {Section} from "controls/react/layout/section";
import {Toolbar, ToolbarItemPosition, ToolbarItemsSet} from "controls/react/layout/toolbar";
import {IconButton} from "controls/react";

import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {observer} from "mobx-react";
import {makeObservable, observable, toJS} from "mobx";
import {NavigatorForRedirectConfig} from "controls/designer/features/redirectOnClick/navigatorForRedirectConfig";
import {CeeviewNavigator} from "tools/ceeviewNavigator";
import {createRoot} from "react-dom/client";
import {getWidgetDescription} from "controls/designer/features/widgets/allWidgets";
import { waitForRef } from "tools/iosFixes";

const i = require('core/localization').translator(sharedLocalization);

export function destroyWidgetById(graph, id) {
	if (!graph.widgets[id])
		return

	const {widget, reactRoot} = graph.widgets[id]
	widget.destroy && widget.destroy();

	reactRoot.unmount()
	delete graph.widgets[id]
}

export function destroyWidget(designer, cell) {
	const {graph} = designer;
	const id = Object.keys(graph.widgets).find(x => graph.widgets[x].cell == cell)

	if(id) {
		destroyWidgetById(graph, id);
	}
}

function wrapProxyForInnerAccess(obj) {
	return new Proxy(obj, {
		get(target, prop) {
			console.info('Widget Geometry Wrapper. Field access: ' + prop);
			return target.legacyWidget?.[prop] ?? target.ref.current?.[prop] ?? target[prop];
		}
	})
}

export function checkCellForWidget(graph, cell) {
	const {container, id} = getHtmlContainer(graph, cell)
	if (container == null)
		return;

	const dashboardSettings = graph.designer.dashboardSettings

	let config = toJS(graph.designer.store.widgetsManager.getEffectiveConfig(cell))
	if(config == null)
		return;

	const widgetDescription = getWidgetDescription(config.type)

	let redirectConfig = dashboardSettings.readOnly ? graph.designer.store.redirectConfigsManager.getEffectiveConfig(cell) : noRedirectConfig

	if (redirectConfig) {
		redirectConfig = {
			...redirectConfig, ...{
				defaultRedirectInNewTab: redirectConfig?.type === 'Default' && redirectConfig?.linkInNewTab,
				doDefaultRedirect: redirectConfig?.type === 'Default'
			}
		}
	}

	config.id = id;
	if (widgetDescription.legacyWidget && !widgetDescription.legacyForm && config.configuration == null) {
		config = {
			configuration: config,
			title: config.title,
			id: config.id,
			type: config.type
		}
	}

	if (!widgetDescription.legacyWidget && widgetDescription.legacyForm && config.configuration) {
		Object.assign(config, config.configuration)
		delete config.configuration
	}

	const editWidget = () => {
		graph.designer.ui.actions.get(WidgetsActions.ShowEditWidgetFormForCell).funct(cell);
	}

	const deleteWidget = () => {
		graph.cellsRemoved([cell]);
	}

	const onToggle = (e) => {
		let timeSelector;
		let customContainer = $(e.currentTarget).closest('.html-shape-container').find('.cw_widget_settings');

		if (customContainer.length) {
			if (customContainer.is(':visible')) {
				customContainer.slideUp(150);
				if (timeSelector) {
					timeSelector.addClass('hide');
				}
			} else {
				customContainer.slideDown(350);
			}
		}
		let widgetActions = $(e.currentTarget).closest('.k-window')
			.find('.cw_widget_actions');
		widgetActions.removeClass('expanded');

		e.preventDefault();
	}

	const actions = {
		editWidget,
		deleteWidget,
	}

	const persistedState = graph.designer.store.getCustomData(cell)?.persistedState ?? {}

	const showWidgetHeader = (dashboardSettings.showWidgetHeader && config.configuration?.removeHeader !== true)
		|| !graph.designer.config.chromeless

	let navigator = new NavigatorForRedirectConfig(
		graph.designer.config.navigator ?? new CeeviewNavigator(), redirectConfig, container)

	const reactRoot = createRoot(container)

	if (widgetDescription.legacyWidget) {
		let toolbarEntry = container.querySelectorAll('.toolbar__entry');
		for (let i = 0; i < toolbarEntry.length; i++) {
			toolbarEntry[i].style.marginLeft = 0;
		}

		let resolveWidget;
		const Widget = withGeometry(LegacyWidget, new Promise((res) => resolveWidget = res));

		var legacyWrapperRef = React.createRef();

		reactRoot.render(
			<Widget widgetDescription={widgetDescription}
			        showWidgetHeader={showWidgetHeader}
			        dashboardSettings={dashboardSettings}
			        navigator={navigator}
			        config={config}
			        editWidget={editWidget}
			        deleteWidget={deleteWidget}
			        onToggle={onToggle}
			        cell={cell}
			        geometry={cell.geometry}
					ref={legacyWrapperRef}
			/>);

		requestIdleCallback(async () => {
			config.dashboardSettings = dashboardSettings;

			const widget = widgetDescription.constructWidget
				? widgetDescription.constructWidget(config)
				: new widgetDescription.widget(config);

			// we should ensure that we have ref before resolveWidget
			await waitForRef(legacyWrapperRef)
			resolveWidget(widget);

			widget.persistedState = persistedState;
			widget.redirectConfig = redirectConfig;
			widget.navigator = navigator
			if (widget.init) {
				widget.init();
			}

			let infoIcon = container.querySelector('.cw_widget_info_sign');
			if (infoIcon != null) {
				infoIcon.style.right = dashboardSettings.readOnly ? '5px' : '65px';
			}

			const legacyWidget = wrapProxyForInnerAccess(legacyWrapperRef.current);
			graph.widgets[config.id] = {
				widget,
				id: config.id,
				cell,
				type: config.type,
				reactRoot,
				wrapper: legacyWidget,
				legacy: true
			};
		})
	} else {
		let ref = React.createRef();
		const Widget = withGeometry(widgetDescription.widget)

		reactRoot.render(
			<Widget dashboardSettings={dashboardSettings}
			        designer={graph.designer}
			        actions={actions}
			        persistedState={persistedState}
			        config={config}
			        cell={cell}
			        navigator={navigator}
			        geometry={cell.geometry}
			        ref={ref}/>)

		requestIdleCallback(() => {
			const widget = wrapProxyForInnerAccess(ref.current);
			graph.widgets[config.id] = {
				container,
				widget: widget,
				id: config.id,
				reactRoot,
				type: config.type,
				cell,
				legacy: false
			};
		})
	}

	cell.isConnectable = () => false
}

export function iterateWidgets(graph, callback) {
	if(!graph?.widgets)
		return

	for (const id of Object.keys(graph.widgets)) {
		callback(graph.widgets[id]);
	}
}


function withGeometry(WrappedComponent, legacyWidgetPromise) {
	return observer(class extends React.Component {
		store;
		ref = React.createRef();
		legacyWidget;

		constructor(props) {
			super(props);
			this.store = new MovableWidgetStore(props.config.id, props.geometry, props.dashboardSettings.showWidgetHeader);
			legacyWidgetPromise?.then((legacyWidget) => {
				this.legacyWidget = legacyWidget;
				const widget = this.legacyWidget && Object.getPrototypeOf(legacyWidget);
				if (widget) {
					Object.getOwnPropertyNames(widget)
						.filter(item => typeof widget[item] === 'function' && item != 'constructor' && item != 'render')
						.forEach(key => {
							this[key] = $.proxy(widget[key], legacyWidget);
						});
				}
			});
		}

		onGeometryChanged = (geometry) => {
			this.store.changeGeometry(geometry);
			if (this.legacyWidget) {
				this.legacyWidget.onResize?.(geometry);
			} else {
				this.ref.current?.onResize?.(geometry);
			}
		}

		render() {
			if (typeof WrappedComponent == 'object') {
				return <WrappedComponent {...this.props} geometry={{...this.store.geometry}} headerPosition={{...this.store.headerPosition}}/>;
			}
			return <WrappedComponent ref={this.ref} {...this.props} geometry={{...this.store.geometry}} headerPosition={{...this.store.headerPosition}}/>;
		}
	});
}


const LegacyWidget = observer((props) => {
	return <Section contentPadding={false}
	                height={"fit"}
	                containerClass={props.widgetDescription.containerClass}
	                scrollable={true}
	                appearance={"none"}>
		{props.showWidgetHeader &&
			<Toolbar title={props.config.title || props.widgetDescription.defaultConfig.title} containerStyle={props.headerPosition}>
				<div position={ToolbarItemPosition.AFTER_TITLE} className={"after-title-placeholder"}></div>
				<ToolbarItemsSet noPadding={true}>
					{!props.dashboardSettings.readOnly && <IconButton iconName={"pencil"}
					                                                  title={i('Edit widget')}
					                                                  embedded={true}
					                                                  onClick={props.editWidget}/>}
					{!props.dashboardSettings.readOnly && <IconButton iconName={"remove"}
					                                                  title={i('Delete')}
					                                                  embedded={true}
					                                                  onClick={props.deleteWidget}/>}
					{props.widgetDescription.showToggleIcon && <div className={'cw_toggle_options k-icon k-i-toggle'}
					                                                title={i('Chart settings')}
					                                                onClick={props.onToggle}
					/>}
				</ToolbarItemsSet>
			</Toolbar>
		}
		<div className="cw_section_content"></div>
	</Section>
});

export class MovableWidgetStore {
	geometry;
	headerPosition;
	showWidgetHeader;
	id;
	constructor(id, geometry, showWidgetHeader) {
		makeObservable(this, {
			headerPosition: observable,
			geometry: observable
		});
		this.id = id;
		this.geometry = geometry;
		this.showWidgetHeader = showWidgetHeader;
		this.headerPosition = {};
		this.calculateHeaderPosition();
	}

	changeGeometry = (geometry) => {
		this.geometry = geometry;
		this.calculateHeaderPosition();
	}

	calculateHeaderPosition = () => {
		if (this.showWidgetHeader) {
			return;
		}
		const headerHeight = 40;
		if (this.geometry.y < headerHeight) {
			const top = document.getElementById(this.id).getBoundingClientRect().height - 1;
			this.headerPosition = {top: `${top}px`};
		} else {
			this.headerPosition = {top: `-${headerHeight}px`};
		}
	}
}
