import Cookies from 'core/cookies';
import Settings from 'settings';
import Utils from 'tools/utils';

function wizard() {

	$(document).ready(function() {
		$('.cw_wizard').find('.cw_steps').append(
			'<li id="step1" class="cw_item">' +
			'<div class="cw_field">' + lang.account.messages.CHANGE_DEFAULT_PASSWORD + '</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="admin_password">' + lang.PASSWORD + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="password" name="admin_password" id="admin_password" class="cw_textinput">' +
			'</div>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="admin_password2">' + lang.CONFIRM_PASSWORD + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="password"  name="admin_password2" id="admin_password2"  class="cw_textinput">' +
			'</div>' +
			'</div>' +
			'</li>' +
			'<li id="step2" class="cw_item">' +
			'<div class="cw_field">' + lang.account.messages.YOUR_FIRST_ACCOUNT + '</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="account_name">' + lang.account.ACCOUNT_NAME + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="text" name="account_name" id="account_name" class="cw_textinput required_wizard">' +
			'</div>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline_full" for="account_comment">' + lang.account.ACCOUNT_DESCRIPTION + '</label>' +
			'<textarea type="text" name="account_comment" rows="6" id="account_comment" class="cw_inline"></textarea>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="account_team">' + lang.service.RESPONSIBLE_TEAM + '</label>' +
			'<div class="cw_dropdown_container required">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="text" name="account_team" id="account_team" class="cw_textinput"/>' +
			'</div>' +
			'</div>' +
			'</li>' +
			'<li id="step3" class="cw_item">' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="username">' + lang.USERNAME + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="text" name="username" id="username" class="cw_textinput">' +
			'</div>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="password">' + lang.PASSWORD + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="password" name="password" id="password" class="cw_textinput">' +
			'</div>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="password2">' + lang.CONFIRM_PASSWORD + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="password" name="password2" id="password2" class="cw_textinput">' +
			'</div>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="email">' + lang.EMAIL + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="text" name="email" id="email" class="cw_textinput">' +
			'</div>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="first_name">' + lang.FIRST_NAME + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="text" name="first_name" id="first_name" class="cw_textinput">' +
			'</div>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="last_name">' + lang.LAST_NAME + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<span class="required-wizard-field">*</span>' +
			'<input type="text" name="last_name" id="last_name" class="cw_textinput">' +
			'</div>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="language">' + lang.LANGUAGE + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<input type="text" name="language" id="language">' +
			'</div>' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline" for="timezone">' + lang.TIMEZONE + '</label>' +
			'<div class="cw_dropdown_container">' +
			'<input type="text" name="timezone" id="timezone">' +
			'</div>' +
			'</div>' +
			'</li>');

		function clearValues() {
			$('#admin_password').val('');
			$('#admin_password2').val('');
			$('#account_name').val('');
			$('#account_comment').val('');
			$('#username').val('');
			$('#password').val('');
			$('#password2').val('');
			$('#first_name').val('');
			$('#last_name').val('');
			$('#email_name').val('');
			$('#account_team').val('');
		}

		function addInlineValidations() {
			$('#admin_password, #admin_password2, #account_name, #username, #password, #password2, #first_name, #last_name, #email').blur(function () {
				if ($(this).val() === '') {
				}
				else {
					$(this).removeClass('is_invalid');
				}
			});
		}

		function setFields() {
			$('#admin_password').on('keyup', () => setField($('#admin_password')));
			$('#admin_password2').on('keyup', () => setField($('#admin_password2')));
			$('#account_name').on('keyup', () => setField($('#account_name')));
			$('#account_team').on('keyup', () => setField($('#account_team')));
			$('#email').on('keyup', () => setField($('#email')));
			$('#last_name').on('keyup', () => setField($('#last_name')));
			$('#username').on('keyup', () => setField($('#username')));
			$('#first_name').on('keyup', () => setField($('#first_name')));
			$('#password').on('keyup', () => setField($('#password')));
			$('#password2').on('keyup', () => setField($('#password2')));
		}

		function setField(container) {
			if(container.val().trim() === '') {
				container.parent().find('.required-wizard-field').show();
			}
			else {
				container.parent().find('.required-wizard-field').hide();
			}
		}

		function isFormValid(step) {
			var valid = true;
			var status = $('.status');
			switch (step) {
				case 0:
					var p1 = $('#admin_password').val();
					var p2 = $('#admin_password2').val();
					if (p1 !== p2) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_PASSWORD_IDENTICAL);
						status.find('p').addClass('error');
						status.show();
						$('#admin_password2').addClass('is_invalid');
					}
					if (p1.length < 6) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_PASSWORD_LENGTH);
						status.find('p').addClass('error');
						status.show();
						$('#admin_password').addClass('is_invalid');
					}
					if (p1.indexOf(' ') !== -1) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_PASSWORD_SPACES);
						status.find('p').addClass('error');
						status.show();
						$('#admin_password').addClass('is_invalid');
					}
					break;
				case 1:
					var name = $('#account_name').val();
					if (name.length < 1) {
						valid = false;
						status.find('p').text(lang.account.messages.ACCOUNT_ENTER_NAME);
						status.find('p').addClass('error');
						status.show();
						$('#account_name').addClass('is_invalid');
					}
					var team = $('#account_team').val();
					if (team.length < 1) {
						valid = false;
						status.find('p').text(lang.account.messages.ENTER_TEAM);
						status.find('p').addClass('error');
						status.show();
						$('#account_team').addClass('is_invalid');
					}
					break;
				case 2:
					var username = $('#username').val();
					var password1 = $('#password').val();
					var password2 = $('#password2').val();
					var firstName = $('#first_name').val();
					var lastName = $('#last_name').val();
					var email = $('#email').val();
					var locale = $('#language').data('kendoDropDownList').value();
					var timezone = $('#timezone').data('kendoDropDownList').value();

					if (username.length < 1) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_INPUT_USERNAME);
						status.find('p').addClass('error');
						status.show();
						$('#username').addClass('is_invalid');
					}
					if (password1.length < 6) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_PASSWORD_LENGTH);
						status.find('p').addClass('error');
						status.show();
						$('#password').addClass('is_invalid');

					}
					if (password1.indexOf(' ') !== -1) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_PASSWORD_SPACES);
						status.find('p').addClass('error');
						status.show();
						$('#password').addClass('is_invalid');

					}
					if (username.indexOf(' ') !== -1) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_USERNAME_SPACES);
						status.find('p').addClass('error');
						status.show();
						$('#username').addClass('is_invalid');

					}
					if (firstName.length < 1) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_INPUT_FIRST_NAME);
						status.find('p').addClass('error');
						status.show();
						$('#first_name').addClass('is_invalid');
					}
					if (lastName.length < 1) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_INPUT_LAST_NAME);
						status.find('p').addClass('error');
						status.show();
						$('#last_name').addClass('is_invalid');
					}
					if (password1 !== password2) {
						valid = false;
						status.find('p').text(lang.account.messages.USER_PASSWORD_IDENTICAL);
						status.find('p').addClass('error');
						status.show();
						$('#password2').addClass('is_invalid');
					}
					var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (!re.test(email)) {
						valid = false;
						status.find('p').text('Please, input a valid email address.');
						status.find('p').addClass('error');
						status.show();
						$('#email').addClass('is_invalid');
					}
					break;

			}
			return valid;
		}


		var stepsWrap = $('.cw_wizard').find('.cw_steps');
		var steps = stepsWrap.find('.cw_item');
		var stepsLength = steps.length;
		let bodyWidth = $('body').width();
		$('.main_content').css({
			'width': bodyWidth / stepsLength,
			'padding-left': 0,
			'padding-right': 0
		});
		$('.cw_content').css('width', bodyWidth / stepsLength);
		let wizardWidth = bodyWidth / stepsLength;
		steps.css('width', wizardWidth);
		var stepWidth = steps.first().outerWidth();
		var current = 0;
		var stepsWidth = stepsLength * stepWidth + 5;
		stepsWrap.css({
			'width': stepsWidth,
			'height': '170px',
			'position': 'relative'
		});

		var headings = ['Congratulations! This is the first time you log in.', 'Now, create your first account.', 'Create a new user to your account.'];
		clearValues();
		addInlineValidations();
		setFields();
		$('#step2, #step3').css('visibility', 'hidden');
		$('#language').kendoDropDownList({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/locales/',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				}
			}),
			dataTextField: 'text',
			dataValueField: 'value'
		});
		$('#timezone').kendoDropDownList({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				}
			})
		});

		$('.cw_hint').kendoTooltip({
			showOn: 'click',
			content: lang.profile.PASSWORD_STRENGTH_MESSAGE,
			position: 'left'
		});

		$('#admin_password').focus();

		$('input, textarea, .k-dropdown').keydown(function (e) {
			var code = (e.keyCode ? e.keyCode : e.which);
			if (code == 13 && this.id !== 'email') {
				$('#next_step').click();
			}
		});

		$('.cw_wizard_nav').find('button').on('click', function () {
			var direction = $(this).data('dir');
			if (direction === 'next') {
				if (isFormValid(current)) {
					switch (current) {
						case 0:
							var p1 = $('#admin_password').val();
							var auth = {
								username: 'admin',
								oldPassword: 'admin',
								newPassword: p1
							};
							jQuery.ajax({
								url: Settings.serverPath + 'wizard/changePassword/',
								data: JSON.stringify(auth),
								type: "POST",
								dataType: "json",
								contentType: "application/json; charset=utf-8",
								crossDomain: true,
								beforeSend: function (x) {
									if (x && x.overrideMimeType) {
										x.overrideMimeType("application/json;charset=UTF-8");
									}
								},
								error: function (result, message) {
									var status = $('.status');
									status.find('p').addClass('error');
									status.find('p').text(result.statusText);
									status.show();
								},
								success: function (result) {
									if (result.success) {
										var timeout = Settings.COOKIE_TIMEOUT;

										Cookies.create('sessionId', result.data.sessionId, timeout);
										Cookies.create("CeesoftUsername", 'admin', timeout);
										Cookies.create("CeesoftUserId", result.data.userId, timeout);
										Cookies.create("CeesoftAccountId", result.data.accountId, timeout);
										Cookies.create("CeesoftTimezone", result.data.timeZone, timeout);
										Cookies.create("CeesoftUserLocale", result.data.locale, timeout);
										Cookies.create("CeesoftUserDateTimeFormat", result.data.dateTimeFormat, timeout);
										Cookies.create("CeesoftUserDateFormat", result.data.dateFormat, timeout);
										Cookies.create("CeesoftUserTimeFormat", result.data.timeFormat, timeout);
										Cookies.create("CeesoftAccountName", result.data.accountName, timeout);
										Cookies.create("CeesoftCurrentAccountId", result.data.accountId, timeout);
										Cookies.create("CeesoftCurrentAccountName", result.data.accountName, timeout);
										Cookies.erase("CeesoftParentAccountId");
										Cookies.erase("CeesoftParentAccountName");

										$('.round_step_1').removeClass('is_selected').addClass('is_valid');
										$('.round_step_2').addClass('is_selected');
										$('.round_step_1').find('.value').html('&#10003');
										$('.status').hide();
										$('.status').find('p').removeClass('error');
										$('#step2').css('visibility', 'visible');
										$('.cw_wizard').find('.cw_title').text(headings[1]);
										current += 1;
										slider(stepsWrap, direction);
										$('#step1').css('visibility', 'hidden');
										$('#prev_step').removeClass('hide');
										$('.cw_steps').css('height', '260px');
										$('.required-wizard-field').css('left', stepWidth + 152);
										setTimeout(function () {
											$('#account_name').focus();
										}, 200);
									}
									else {
										$('.status').find('p').text(result.message);
										$('.status').find('p').addClass('error');
										$('.status').show();
									}
								}
							});
							break;
						case 1:
							var name = $('#account_name').val();
							var comment = $('#account_comment').val();
							var team = $('#account_team').val();
							var account = {
								name: name,
								comment: comment,
								team: team
							};
							jQuery.ajax({
								url: Settings.serverPath + 'wizard/createAccount/',
								data: JSON.stringify(account),
								type: "POST",
								dataType: "json",
								contentType: "application/json; charset=utf-8",
								crossDomain: true,
								beforeSend: function (x) {
									if (x && x.overrideMimeType) {
										x.overrideMimeType("application/json;charset=UTF-8");
									}
								},
								error: function (result, message) {
									var status = $('.status');
									status.find('p').addClass('error');
									status.find('p').text(result.statusText);
									status.show();
								},
								success: function (result) {
									if (result.success) {
										$('.round_step_2').removeClass('is_selected').addClass('is_valid');
										$('.round_step_3').addClass('is_selected');
										$('.round_step_2').find('.value').html('&#10003');
										$('.status').hide();
										$('.status').find('p').removeClass('error');
										$('#step3').css('visibility', 'visible');
										$('.cw_wizard').find('.cw_title').text(headings[2]);
										current += 1;
										slider(stepsWrap, direction);
										$('#step2').css('visibility', 'hidden');
										$('#next_step').text('Finish');
										$('.cw_steps').css('height', '400px');
										$('.required-wizard-field').css('left', stepWidth * 2 + 152);
										setTimeout(function () {
											$('#username').focus();
										}, 200);
									}
									else {
										$('.status').find('p').text(result.message);
										$('.status').find('p').addClass('error');
										$('.status').show();
									}
								}
							});
							break;
						case 2:
							var firstName = $('#first_name').val();
							var lastName = $('#last_name').val();
							var email = $('#email').val();
							var username = $('#username').val();
							var password = $('#password').val();
							var password2 = $('#password2').val();
							var locale = $('#language').data('kendoDropDownList').value();
							var timezone = $('#timezone').data('kendoDropDownList').value();
							var userAccount = {
								//TODO Disabled by bogdan. To be removed.
								//                            accountId: accountId,
								username: username,
								firstName: firstName,
								lastName: lastName,
								password: password,
								email: email,
								locale: locale,
								timeZone: timezone
							};
							jQuery.ajax({
								url: Settings.serverPath + 'wizard/createUser/',
								data: JSON.stringify(userAccount),
								type: "POST",
								dataType: "json",
								contentType: "application/json; charset=utf-8",
								crossDomain: true,
								beforeSend: function (x) {
									if (x && x.overrideMimeType) {
										x.overrideMimeType("application/json;charset=UTF-8");
									}
								},
								error: function (result, message) {
									var status = $('.status');
									status.find('p').addClass('error');
									status.find('p').text(result.statusText);
									status.show();
								},
								success: function (result) {
									if (result.success) {
										var auth = {
											username: username,
											password: password
										};
										jQuery.ajax({
											url: Settings.serverPath + 'sessions/login/',
											data: JSON.stringify(auth),
											type: "POST",
											dataType: "json",
											contentType: "application/json; charset=utf-8",
											crossDomain: true,
											beforeSend: function (x) {
												if (x && x.overrideMimeType) {
													x.overrideMimeType("application/json;charset=UTF-8");
												}
											},
											error: function (result, message) {
												var status = $('.status');
												status.find('p').addClass('error');
												status.find('p').text(result.statusText);
												status.show();
											},
											success: function (result) {
												if (result.success) {
													var timeout = Settings.COOKIE_TIMEOUT;
													Cookies.create("CeesoftUsername", username, timeout);
													Cookies.create("CeesoftUserId", result.data.userId, timeout);
													Cookies.create("CeesoftAccountId", result.data.accountId, timeout);
													Cookies.create("CeesoftTimezone", result.data.timeZone, timeout);
													Cookies.create("CeesoftUserLocale", result.data.locale, timeout);
													Cookies.create("CeesoftAccountName", result.data.accountName, timeout);
													Cookies.create("CeesoftCurrentAccountId", result.data.accountId, timeout);
													Cookies.create("CeesoftCurrentAccountName", result.data.accountName, timeout);
													Cookies.create("sessionId", result.data.sessionId, timeout);
													window.location = Settings.httpRoot;
												}
												else {
													var status = $('.status');
													status.find('p').addClass('error');
													status.find('p').text(result.message);
													status.show();

												}
											}
										});
									}
									else {
										var status = $('.status');
										$('.status').find('p').text(result.message);
										$('.status').find('p').addClass('error');
										$('.status').show();
									}
								}
							});
							break;
					}
				}
			}
			else {
				$('#wizard').find('.is_invalid').removeClass('is_invalid');
				$('#step' + current).css('visibility', 'visible');
				$('#next_step').html('Next &raquo;');
				$('.round_step_' + current).addClass('is_selected');
				$('.round_step_' + current).removeClass('is_valid');
				var next = current + 1;
				$('.round_step_' + next).removeClass('is_selected');
				$('.round_step_' + next).removeClass('is_valid');
				$('.round_step_' + current).find('.value').html(current);
				current -= 1;
				$('.cw_wizard').find('.cw_title').text(headings[current]);
				slider(stepsWrap, direction);
				$('#step' + next).css('visibility', 'hidden');
				$('#step' + current + 2).css('visibility', 'hidden');
				$('.status').hide();

				switch (current) {
					case 0:
						$('#prev_step').addClass('hide');
						$('.cw_steps').css('height', '170px');
						$('.required-wizard-field').css('left', 152);
						setTimeout(function () {
							$('#admin_password').focus();
						}, 200);
						break;
					case 1:
						$('.cw_steps').css('height', '260px');
						$('.required-wizard-field').css('left', stepWidth + 152);
						setTimeout(function () {
							$('#account_name').focus();
						}, 200);
						break;
				}
			}

		});

		function slider(container, direction) {
			var unit;
			unit = (direction === 'next') ? '-=' : '+=';
			container.css({
				'margin-left': unit + stepWidth
			});
		}
	});
}

export default wizard;
