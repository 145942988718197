import React from "react";
import classnames from "classnames";

const i18n = require('core/localization').translator();

export default class FieldRow extends React.PureComponent {
	onClick = () => {
		if (this.props.onClick) {
			this.props.onClick();
		}
	};

	render() {
		const {label, content, onClick, tooltip} = this.props;

		let value = content
		if(typeof content == 'boolean'){
			value = content ? i18n('Yes') : i18n('No')
		}

		return <div className="sqinfo-form__row" onClick={this.onClick}>
			<div className="sqinfo-form__label">{label}:</div>
			<span className={classnames("sqinfo-form__content", {cw_link: !!onClick})}>{value}</span>
		</div>
	}
}
