import { i } from "../translations";

export enum SortDirection {
	Asc = 'Asc',
	Desc = 'Desc'
}

export const getSortDirectionDataSource = () => {
	return [{
		id: SortDirection.Asc,
		name: i(SortDirection.Asc)
	},{
		id: SortDirection.Desc,
		name: i(SortDirection.Desc)
	}];
}
