import 'controls/designer/features/widgets/custom/gps/gpsWidget.less';
import React from 'react';

import {Section} from "controls/react/layout/section";
import {FormEntry, IconButton, TextBox} from "controls/react/form";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {observer} from 'mobx-react';
import {BoxView} from "controls/react/layout/boxView";
import {GpsWidgetPersistedState} from "controls/designer/features/widgets/custom/gps/gpsWidgetPersistedState";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {GpsWidgetConfig} from "controls/designer/features/widgets/custom/gps/gpsWidgetConfig";
import {GpsWidgetStore} from "controls/designer/features/widgets/custom/gps/gpsWidgetStore";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {ApplicationState} from "framework/applicationState";
import AccountDropDown from "controls/react/dropdowns/accountDropDown";
import {WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig"
import {AssetsWithGeoTag} from "controls/react/assetsWithGeoTag";
import {AntSelect, fromIdName} from "controls/react/ant/antSelect";
import {useAssetGroups} from "controls/react/assetGroupMultiSelect";
import {AntInput} from "controls/react/ant/antInput";

const i = require('core/localization').translator({
  "Show health index": {
    "no": "Vis helseindeks",
    "en": "Show healthindex"
  },
  "Assets tooltip": {
    "en": "Only assets with geotag set is available for selection.",
    "no": "Bare ressurser med Geotagg satt er tilgjengelig for valg."
  },
  "Show asset name": {
    "no": "Vis ressursnavn"
  },
  "Lock map in view mode": {
    "no": "Lås kart i visningmodus"
  }
}, sharedLocalization);

export function getWidgetDescription(){
	return {
		form: GpsWidgetConfiguration,
		defaultConfig: {
			type: 'gps',
			title: i('GPS Widget'),
			accountId: ApplicationState.accountId,
			assets: [] as string[],
			assetGroups: [] as string[],
  			lockView: false
		},
		widget: GpsWidget,
		fullTitle: i('Custom') + '/' + i('GPS'),
	}
}

const GpsWidgetConfiguration = observer((props:WidgetConfigurationProps<GpsWidgetConfig>) => {
	const {configLink} = props;

	const accountLink = configLink.get('accountId')
		.changing(({rootStore, value}) => {
			let configStore = configLink.getLinkStore(rootStore);
			configStore.assets = []
			configStore.assetGroups = []
		});

	const [assetGroups, assetGroupsLoading] = useAssetGroups(accountLink.value)

	return <Section appearance={"none"}
	                contentPadding={false}
	                childrenPadding={true}>
		<Section appearance={"frame"} title={i('Configuration')} childrenPadding={true}>
			<FormEntry label={i('Title')}>
				<AntInput {...configLink.get('title').props}/>
			</FormEntry>
			<FormEntry label={i('Account')}>
				<AccountDropDown {...accountLink.props}/>
			</FormEntry>
		</Section>
		<Section appearance={"frame"} title={i('Datasource')} childrenPadding={true}>
			<FormEntry label={i('Assets')} valueLink={configLink.get('assets')}>
				<IconButton iconName={"question-sign"} title={i('Assets tooltip')} />
				<AssetsWithGeoTag mode={"multiple"} accountId={accountLink.value} />
			</FormEntry>
			<FormEntry label={i('Asset Groups')} valueLink={configLink.get('assetGroups')}>
				<AntSelect mode={"multiple"}
				           optionFilterProp={"label"}
				           options={fromIdName(assetGroups)}
				           loading={assetGroupsLoading}/>
			</FormEntry>
			<FormEntry>
				<AntCheckbox valueLink={configLink.get('lockView')}>{i('Lock map in view mode')}</AntCheckbox>
			</FormEntry>
			<FormEntry>
				<AntCheckbox valueLink={configLink.get('showHealthIndex')}>{i('Show health index')}</AntCheckbox>
			</FormEntry>
			<FormEntry>
				<AntCheckbox valueLink={configLink.get('showAssetName')}>{i('Show asset name')}</AntCheckbox>
			</FormEntry>
		</Section>
	</Section>
});


const b = require('b_').with('gps-widget');

const GpsWidget = observer(
	class GpsWidget extends React.Component<WidgetProps<GpsWidgetConfig, GpsWidgetPersistedState>, any>{
		store: GpsWidgetStore;

		constructor(props: WidgetProps<GpsWidgetConfig, GpsWidgetPersistedState>) {
			super(props)

			this.store = new GpsWidgetStore(
				this.props.config,
				this.props.persistedState,
				this.props.designer,
				this.props.cell,
				this.props.navigator
			);
		}

		render() {
			return (
				<DefaultWidgetWrapper {...this.props}>
					{this.store.loadingError && <BoxView type={"error"}>{i('Widget could not be loaded')}</BoxView>}
					<div className={b()} ref={this.store.setMapContainer}></div>
				</DefaultWidgetWrapper>
			)
		}

		onResize = () =>{
			this.store.map.updateSize()
		}

		getStateForSaving() {
			return {
				center: this.store.map.getView().getCenter(),
				zoom: this.store.map.getView().getZoom(),
				linkedCells: this.store.overlays
					.filter(x => x.linkedCell)
					.map(x => x.linkedCell.id)
			}
		}

		componentWillUnmount() {
			this.store?.destroy();
		}
	}
);
