import {createModelSchemaWrapper} from "framework/serializr-integration";
import {CostModel} from "./costModel";
import {list, object, primitive} from "serializr";
import {makeAutoObservable} from "mobx";
import {ModelValidator, ValidatableModel} from "../../../framework/mobx-integration";
import {translator} from "core";
import {costTranslates} from "../translations";
import {ModelType} from "../budget/budgetTypes";

const i = translator(costTranslates);

export class CostProfile implements ValidatableModel<CostProfile> {
	id: string;
	accountId: string;
	name: string;
	description: string;
	costModels: CostModel[];
	modelType: ModelType;
	defaultCurrency: string;

	validator = new ModelValidator(this);
	private _existingProfiles: CostProfile[] = [];

	constructor() {
		makeAutoObservable(this);

		this.validator
			.required('name')
			.add('name', {
				callback: () => this.validateName(this._existingProfiles),
				message: i('Name exists')
			});
	}

	get valid() : boolean {
		return this.validator.valid;
	}

	validateName = (profiles: CostProfile[]) => {
		return !profiles.some((x: CostProfile) => x.name.trim().toLowerCase() == this.name?.trim()?.toLowerCase() && x.accountId == this.accountId);
	}

	setExistsProfiles = (profiles: CostProfile[]): void => {
		this._existingProfiles = profiles;
	}
}

createModelSchemaWrapper(CostProfile, {
	id: primitive(),
	name: primitive(),
	description: primitive(),
	costModels: list(object(CostModel)),
	modelType: primitive(),
	defaultCurrency: primitive()
})
