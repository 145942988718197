import {ApiRequest} from "framework/api";
import { RegisteredMetric } from "framework/entities/registeredMetric";
import {ApplicationState} from "framework/applicationState";
import {TimePeriod, timePeriodToUrl} from "../../../../../react/form/timePeriodSelector";

const baseUrl = 'metrics/registeredMetrics/data';

export interface getMetricsPayload{

}
export function getMetrics(metrics: any[], period: TimePeriod){
	return new ApiRequest<RegisteredMetric[]>({
		url: `${baseUrl}?&timeZone=${ApplicationState.timezone}&${timePeriodToUrl(period)}`,
		payload: metrics
	})
}
