import {Cookies, ErrorHandler, RemoteEventsManager} from "core";
import {Settings} from "settings";
import {State, Utils} from "tools";
import {GridMenu} from "controls";


export let TeamPolicyView = function (config) {
	Utils.apply(this, config);
	this._events = [];
	this.excludeFilters = this.excludeFilters || [];
	//this.initComponent();
	if (State.currentApp?.notificationPolicyDataLoaded) {
		this.initComponent();
	} else {
		this.getSchedulerPeriods();
	}
};

jQuery.extend(TeamPolicyView.prototype, kendo.Observable.prototype, {
	notificationTypes: [],
	/**
	 * Gets the notifications data
	 */
	getSchedulerPeriods: function () {
		this.update = true;
		if (this.mode === 'view') {
			this.update = false;
		}
		var loadUrl = Settings.serverPath + 'accounts/' + (this.accountContext || Cookies.CeesoftCurrentAccountId) + '/teams/' + this.id + '/notificationPolicy?update=' + this.update;
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			var data, periods;
			if (result.success) {
				data = result.data;
				this.schedulerId = data.id;
				this.isSchedulerEnabled = data.isEnabled;
				this.markInboxAsRead = data.markInboxAsRead;
				this.notificationTypes = data.notificationTypes || [];
				this.severityThreshold = data.severityThreshold || '';
				this.excludeFilters = data.excludeFilters || [];
				this.schedulerData = data.periods;
				State.currentApp.notificationPolicyDataLoaded = true;
				this.initComponent();
			}
		}, this));
	},
	/**
	 * Main init component
	 */
	initComponent: function () {
		this.eventTypeDatasource = new kendo.ceeview.DataSource({
			data: [],
			schema: {
				model: {
					id: 'value'
				}
			}
		});

		this.filterMenu = new GridMenu({
			renderTo: 'cw_filters_menu',
			items: [{
				id: 'cw_filter_add',
				icon: 'plus-sign',
				text: lang.ADD,
				fn: this.onFilterAdd,
				scope: this,
				disabled: this.mode === 'view' ? true : false
			}, {
				id: 'cw_filter_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onFilterDelete,
				scope: this,
				disabled: this.mode === 'view' ? false : true
			}]
		});
		var self = this;
		this.typeSelector = $('#cw_type_select').kendoSortedMultiSelect({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/' + this.id + '/notificationPolicy/notificationTypes',
						contentType: 'application/json; charset=utf-8',
						type: 'GET',
						dataType: 'json',
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				schema: {
					model: {
						id: 'value'
					},
					parse: $.proxy(function (response) {
						var item;
						for (var i = 0, length = response.length; i < length; i++) {
							item = response[i];
							if (this.notificationTypes.indexOf(item.value) > -1) {
								this.eventTypeDatasource.add({
									text: item.text,
									value: item.value
								});
							}
						}
						return response;
					}, this)
				}
			}),
			autoBind: true,
			placeholder: lang.SELECT,
			dataTextField: 'text',
			dataValueField: 'value',
			value: this.notificationTypes || [],
			enabled: this.mode === 'view' ? false : true,
			dataBound: function (e) {
				if (!this._savedOld) {
					this._savedOld = e.sender.value();
				}
			},
			change: function (e) {
				var previous = this._savedOld, selection = e.sender.value(), diff = [], selectedId, item;
				if (previous) {
					diff = $(previous).not(selection).get();
				}
				this._savedOld = selection.slice(0);

				if (diff.length > 0) { //remove
					selectedId = diff[0];
					item = self.eventTypeDatasource.get(selectedId);

					self.eventTypeDatasource.remove(item);
				} else { // add
					selectedId = selection[selection.length - 1];
					item = e.sender.dataSource.get(selectedId);
					if (item) {
						self.eventTypeDatasource.add({
							text: item.text,
							value: item.value
						});
					}
				}
			}
		}).data('kendoSortedMultiSelect');
		var template = '# if(data.indicator) { # <span class="cw_status_indicator cw_status_widget_color cw_color${indicator}"></span><span>${text}</span> #} else {# <span>${text}</span> #}#';
		var valueTemplate = '# if(data.indicator) { # <span class="cw_inline_status_indicator cw_status_widget_color cw_color${indicator}"></span><span>${text}</span> #} else {# <span>${text}</span> #}#';
		this.hiThresholdList = $('#cw_hi_threshold').kendoDropDownList({
			dataSource: [{
				text: lang.CRITICAL,
				indicator: 1,
				value: 'CRITICAL'
			}, {
				text: lang.MAJOR,
				indicator: 2,
				value: 'MAJOR'
			}, {
				text: lang.MINOR,
				indicator: 3,
				value: 'MINOR'
			}, {
				text: lang.OK,
				indicator: 5,
				value: 'NONE'
			}],
			template: template,
			valueTemplate: valueTemplate,
			optionLabel: {
				text: lang.SELECT,
				indicator: 6,
				value: null
			},
			text: lang.SELECT,
			dataTextField: 'text',
			dataValueField: 'value',
			value: this.severityThreshold,
			enabled: this.mode === 'view' ? false : true,
			change: $.proxy(function (e) {

			}, this)
		}).data('kendoDropDownList');

		var scope = this;
		this.filtersGrid = $('#cw_filters_grid').kendoCustomGrid({
			autoBind: true,
			editable: true,
			dataSource: new kendo.ceeview.DataSource({
				data: [],
				schema: {
					model: {
						id: 'id',
						fields: {
							id: {
								editable: false
							},
							days: {
								type: 'object'
							}
						}
					}
				}
			}),
			filterable: false,
			sortable: true,
			save: $.proxy(this.onFilterGridSave, this),
			columns: [{
				field: 'id',
				title: lang.SELECTOR,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				width: 40,
				sortable: false,
				filterable: false,
				menu: false
			}, {
				field: 'name',
				title: lang.NAME,
				width: 200,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'eventTypeText',
				title: lang.FILTER_TYPE,
				template: '<span title="#= eventTypeText#">#= eventTypeText#</span>',
				width: 230,
				editor: function (container, options) {
					var dataSource = self.eventTypeDatasource || [];
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.eventType,
						optionLabel: lang.SELECT,
						dataSource: dataSource,
						dataTextField: 'text',
						dataValueField: 'value',
						dataBound: function () {

						},
						change: function (e) {
							var item = e.sender.dataItem();

							options.model.set('eventType', item.value);
							options.model.set('eventTypeText', item.text);

							if (item.value !== 'ASSET_HEALTH') {
								options.model.set('assetSelector', 'NONE');
							}

							options.model.set('targetIds', []);
							options.model.set('targetTexts', '');
						}
					});
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'accountText',
				title: lang.ACCOUNT,
				width: 150,
				editor: function (container, options) {
					var input = $('<input data-text-field="name" data-value-field="id"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.account,
						optionLabel: lang.SELECT,
						dataSource: new kendo.ceeview.DataSource({
							transport: {
								read: {
									url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/subaccounts/allLevels/lite',
									contentType: "application/json; charset=utf-8",
									type: "GET",
									dataType: "json",
									cache: false
								}
							}
						}),
						dataTextField: 'name',
						dataValueField: 'id',
						dataBound: function () {

						},
						change: function (e) {
							var item = e.sender.dataItem();

							options.model.set('account', item.id);
							options.model.set('accountText', item.name);

							options.model.set('targetIds', []);
							options.model.set('targetTexts', '');
						}
					});
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assetSelector',
				title: lang.TARGET_TYPE,
				template: '<span title="#= assetSelector#">#= assetSelectorText#</span>',
				editor: function (container, options) {
					if (options.model.eventType === 'ASSET_HEALTH' || options.model.eventType === 'AGENT_STATE' || options.model.eventType === 'AGENT_WARNING') {
						var input = $('<input data-text-field="text" data-value-field="value"/>');
						input.appendTo(container);
						input.kendoDropDownList({
							value: options.model.eventType,
							optionLabel: lang.SELECT,
							dataSource: [{
								text: lang.DEFAULT,
								value: 'NONE'
							}, {
								text: lang.ASSET,
								value: 'ASSET'
							}, {
								text: lang.account.ASSET_GROUP,
								value: 'ASSET_GROUP'
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							change: function (e) {
								options.model.set('assetSelector', e.sender.value());
								options.model.set('assetSelectorText', e.sender.text());
							}
						}).data('kendoDropDownList');
					}
					else {
						if (container.find('input').data('kendoDropDownList')) {
							container.find('input').data('kendoDropDownList').destroy();
						}
						var input = $('<span data-text-field="Default" data-value-field="NONE" disabled="disabled">' + lang.DEFAULT + '</span>');
						input.appendTo(container);
						options.model.set('assetSelector', 'NONE');
						options.model.set('assetSelectorText', 'Default');
					}
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'targetTexts',
				title: lang.TARGET,
				template: '<span title="#= targetTexts#">#= targetTexts#</span>',
				editor: function (container, options) {
					if (options.model.account && options.model.assetSelector && options.model.eventType !== 'MESSAGE') {
						var input = $('<input data-text-field="name" data-value-field="id"/>');
						input.appendTo(container);
						input.kendoSortedMultiSelect({
							value: options.model.targetIds,
							optionLabel: lang.SELECT,
							dataSource: new kendo.ceeview.DataSource({
								transport: {
									read: {
										url: function () {
											var url = Settings.serverPath + 'accounts/' + options.model.account + '/';
											if (options.model.assetSelector === 'ASSET') {
												url = url += 'assets/lite?';
											} else if (options.model.assetSelector === 'ASSET_GROUP') {
												url += 'assetGroups/lite?';
											} else {
												switch (options.model.eventType) {
													case 'ASSET_HEALTH':
														url = url += 'assets/lite?';
														break;
													case 'AGENT_STATE':
														url += 'agents/lite?';
														break;
													case 'AGENT_WARNING':
														url += 'agents/lite?';
														break;
													case 'SERVICE_ASSET_HEALTH'://Service Asset Health
														url += 'services/lite?includeShared=true&hasModel=true&';
														break;
													case 'SLA'://SLA Calculation
														url += 'slas/lite?';
														break;
													// case 'MESSAGE'://Message (Private) - not going to be used - Guttom said
													//     url += '?';
													//     break;
													case 'AGENT_STATE'://Agent State
														url += 'agents/lite?includeShared=true&includeSubAgents=true&';
														break;
													case 'MODEL_ERROR'://Service Model Error
														url += 'services/lite?includeShared=true&hasModel=true&';
														break;
													case 'STATE'://Service Model State
														url += 'services/lite?includeShared=true&hasModel=true&';
														break;
													case 'SYSTEM_ERROR': //System Error
														url += 'agents/lite?includeShared=true&includeSubAgents=true&';
														break;
													default:
														break;
												}
											}
											return url;
										},
										contentType: "application/json; charset=utf-8",
										type: "GET",
										dataType: "json",
										cache: false
									}
								},
								schema: {
									model: {
										id: 'id'
									}
								}
							}),
							dataTextField: 'name',
							dataValueField: 'id',
							dataBound: function () {

							},
							change: function (e) {
								var items = e.sender.dataItems(), item, targetTexts = [], targetIds = [];

								for (var i = 0, length = items.length; i < length; i++) {
									item = items[i];
									targetTexts.push(item.name);
									targetIds.push(item.id);
								}

								options.model.set('targetIds', targetIds);
								options.model.set('targetTexts', targetTexts.join(', '));
							}
						});
					}
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'activeText',
				title: lang.ACTIVE,
				editor: function (container, options) {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.active,
						optionLabel: lang.SELECT,
						dataSource: [{
							text: lang.YES,
							value: true
						}, {
							text: lang.NO,
							value: false
						}],
						dataTextField: 'text',
						dataValueField: 'value',
						width: 100,
						dataBound: function () {

						},
						change: function (e) {
							var item = e.sender.dataItem();

							options.model.set('active', item.value);
							options.model.set('activeText', item.text);
						}
					});
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'daysTexts',
				title: lang.DAY,
				template: '<span title="#= daysTexts#">#= daysTexts#</span>',
				editor: function (container, options) {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoSortedMultiSelect({
						value: options.model.days,
						optionLabel: lang.SELECT,
						dataSource: [{
							text: lang.MONDAY,
							value: 'MONDAY'
						}, {
							text: lang.TUESDAY,
							value: 'TUESDAY'
						}, {
							text: lang.WEDNESDAY,
							value: 'WEDNESDAY'
						}, {
							text: lang.THURSDAY,
							value: 'THURSDAY'
						}, {
							text: lang.FRIDAY,
							value: 'FRIDAY'
						}, {
							text: lang.SATURDAY,
							value: 'SATURDAY'
						}, {
							text: lang.SUNDAY,
							value: 'SUNDAY'
						}],
						dataTextField: 'text',
						dataValueField: 'value',
						dataBound: function () {

						},
						change: function (e) {
							var items = e.sender.dataItems(), item, daysTexts = [], days = [];

							for (var i = 0, length = items.length; i < length; i++) {
								item = items[i];
								daysTexts.push(item.text);
								days.push(item.value);
							}

							options.model.set('days', days);
							options.model.set('daysTexts', daysTexts.join(', '));
						}
					});
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'from',
				title: lang.reports.DATE_FROM,
				template: function (model) {
					return model.from ? kendo.toString(model.from, 'HH:mm') : '';
				},
				editor: function (container, options) {
					var input = $('<input data-text-field="' + options.field + '" data-value-field="' + options.field + '" data-bind="value: ' + options.field + '"/>');
					input.appendTo(container);
					input.kendoTimePicker({
						value: options.model.from,
						format: 'HH:mm',
						change: function (e) {

						}
					});
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'to',
				title: lang.reports.DATE_TO,
				template: function (model) {
					return model.to ? kendo.toString(model.to, 'HH:ss') : '';
				},
				editor: function (container, options) {
					var input = $('<input data-text-field="' + options.field + '" data-value-field="' + options.field + '" data-bind="value: ' + options.field + '""/>');
					input.appendTo(container);
					input.kendoTimePicker({
						value: options.model.to,
						format: 'HH:mm',
						change: function (e) {

						}
					});
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}],
			dataBound: $.proxy(function () {
				$('#cw_filters_grid').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});
				if (this.mode === 'view') {
					$('.cw_grid_check_all').attr('disabled', 'disabled');
					$('.cw_grid_check').attr('disabled', 'disabled');
				}
			}, this),
			change: $.proxy(this.onRowExpand, this),
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.filtersGrid);
		this.adjustFiltersSize();
		this.setPolicies();
		this.attachListeners();
	},
	onRowExpand: function () {
		var selectedRow = this.filtersGrid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_filters_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#cw_filters_grid').off();
	},
	/**
	 * Attaches the listeners
	 */
	attachListeners: function () {
		$('#cw_filters_grid').on('click', '.cw_grid_check_all', $.proxy(this.onFiltersCheckAll, this));
		$('#cw_filters_grid').on('click', '.cw_grid_check', $.proxy(this.onFiltersCheck, this));
		$(window).off().on('resize', $.proxy(this.adjustFiltersSize, this));
	},
	/**
	 * Handler function for the click event on exclude filters checkall checkbox
	 * @param {Object} e The click event object
	 */
	onFiltersCheckAll: function (e) {
		var checkAll = $(e.currentTarget).is(':checked'), filtersGrid = $('#cw_filters_grid');

		if (checkAll) {
			filtersGrid.find('.cw_grid_check:not(:checked)').prop('checked', true);
			this.filterMenu.enableItem('cw_filter_delete');
		} else {
			filtersGrid.find('.cw_grid_check').prop('checked', false);
		}
	},
	/**
	 * Handler for the filters checkbox click
	 * @param {Object} e The click event
	 */
	onFiltersCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var filters = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				filters.push($(checkboxes[i]).data('id'));
			}
		}
		if (filters.length) {
			this.filterMenu.enableItem('cw_filter_delete');
		} else {
			this.filterMenu.disableItem('cw_filter_delete');
		}
	},
	/*
	 * Handler function for setting policies
	 * */
	setPolicies: function () {
		var item, dayTexts, targetTexts, days;
		for (var i = 0, length = this.excludeFilters.length; i < length; i++) {
			item = this.excludeFilters[i], dayTexts = [], days = item.days || [];

			for (var j = 0, daysLength = days.length; j < daysLength; j++) {
				dayTexts.push(lang[days[j]]);
			}

			targetTexts = item.targetNames.join(', ');

			this.filtersGrid.dataSource.add({
				id: item.id,
				valid: true,
				name: item.description,
				account: item.accountIds[0] || '',
				accountText: item.accountNames[0] || '',
				assetSelector: item.assetSelector,
				assetSelectorText: item.assetSelectorText,
				targetIds: item.targetIds || [],
				targetTexts: targetTexts,
				active: item.active,
				activeText: item.active ? lang.YES : lang.NO,
				eventType: item.type,
				eventTypeText: item.typeText,
				days: item.days,
				daysTexts: dayTexts.join(', '),
				from: item.fromTime,
				to: item.toTime
			});
		}
	},
	/*
	 * Handler function for saving the policies
	 * */
	savePolicy: function () {
		if (this.filtersGrid) {
			var excludeFilters = [], data = this.filtersGrid.dataSource.data(), item, id;
			this.notificationTypes = this.typeSelector.value() || [];
			this.severityThreshold = this.hiThresholdList.value();

			for (var i = 0, length = data.length; i < length; i++) {
				item = data[i];
				if (item.valid) {
					excludeFilters.push({
						id: item.added ? null : item.id,
						description: item.name,
						type: item.eventType,
						days: item.days,
						fromTime: kendo.toString(item.from, 'HH:mm'),
						toTime: kendo.toString(item.to, 'HH:mm'),
						accountSelector: 'SELECTED',
						accountIds: [item.account],
						assetSelector: item.assetSelector || 'NONE',
						assetSelectorText: item.assetSelectorText || 'NONE',
						targetIds: item.targetIds || [],
						active: item.active
					});
				}
			}

			this.excludeFilters = excludeFilters;
			this.dbExcludeFilters = null;
		} else {
			var excludeFilters = [], item;
			for (var i = 0, length = this.excludeFilters.length; i < length; i++) {
				item = this.excludeFilters[i];
				excludeFilters.push({
					id: item.id,
					description: item.description,
					type: item.type,
					days: item.days,
					fromTime: item.from,
					toTime: item.to,
					accountSelector: 'SELECTED',
					accountIds: item.accountIds,
					assetSelector: item.assetSelector || 'NONE',
					assetSelectorText: item.assetSelectorText || 'NONE',
					targetIds: item.targetIds || [],
					active: item.active
				});
			}
			this.dbExcludeFilters = excludeFilters;
		}
		return {
			notificationTypes: this.notificationTypes,
			excludeFilters: this.excludeFilters,
			dbExcludeFilters: this.dbExcludeFilters,
			severityThreshold: this.severityThreshold ? this.severityThreshold : null
		};
	},
	/*
	 * Handler function for adding a filter
	 * */
	onFilterAdd: function () {
		var id = Utils.guid();
		this.filterMenu.disableItem('cw_filter_delete');
		this.filtersGrid.dataSource.add({
			id: id,
			added: true,
			name: '',
			account: '',
			accountText: '',
			assetSelector: '',
			assetSelectorText: '',
			targetIds: [],
			targetTexts: '', //comma separated values
			active: '',
			activeText: '',
			eventType: '',
			eventTypeText: '',
			days: [],
			daysTexts: '', //comma separated values
			from: '00:00',
			to: '23:59'
		});
		this.filtersGrid.refresh();
		this.filtersGrid.editCell(this.filtersGrid.element.find('input[data-id="' + id + '"]').parent().next());
	},
	/*
	 * Handler function for delete filters
	 * */
	onFilterDelete: function () {
		var checkboxes = $('.cw_grid_check:checked'), self = this;

		checkboxes.each(function () {
			var id = $(this).data('id'), item;

			item = self.filtersGrid.dataSource.get(id);
			self.filtersGrid.dataSource.remove(item);
		});
		$('.cw_grid_check_all').prop('checked', false);
		this.filterMenu.disableItem('cw_filter_delete');
	},
	/*
	 * Handler function for grid save
	 * @param {Object} e The grid save event
	 * */
	onFilterGridSave: function (e) {
		setTimeout($.proxy(function () {
			var model = e.model, container = $(e.container);
			if (!model.valid && model.name && model.account && model.active && model.eventType) {
				model.valid = true;
			} else if (model.valid) {
			}

			if (!e.values.hasOwnProperty('to')) {
				var nextCell = container.next();
				e.sender.editCell(nextCell);
				nextCell.find('input').focus();
			}
		}, this), 50);
	},
	/**
	 * Method to resize the scheduler to its full height
	 */
	adjustFiltersSize: function () {
		var section = $('.cw_policy_filter');
		var sectionHeight = section.height();
		section.find('.k-grid-content').css('height', sectionHeight - 29);
	},
	destroy: function () {
		this.schedulerId && this.mode === 'update' && RemoteEventsManager.discard(this.schedulerId);
	}
});
