import {Api} from "tools/api";
import {UrlBuilder} from "tools/urlBuilder";
import State from 'tools/state';
import {ApiRequest, useRemoteList, useRemoteListLegacy} from "framework/api";
import {Severity} from "framework/entities/healthData";

export class ApplicationsApi {
	static listUrl(accountId: string, includeSubaccounts?: boolean){
		return Api.accountRoot(accountId) + 'summary/applications?includeSubaccounts=' + (includeSubaccounts !== undefined ? includeSubaccounts : State.includeSubaccounts)
	}

	static list(accountId?: string, includeSubaccounts?: boolean) {
		return Api.fetch(ApplicationsApi.listUrl(accountId, includeSubaccounts));
	}

	static details(applicationId: string, canUpdate?: boolean){
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '?update=' + canUpdate);
	}

	static detailsReadonly(applicationId: string, sessionId?: string) {
		return Api.fetch(Api.sessionRoot(sessionId) + 'applications/' + applicationId + '?update=false');
	}

	static save(application: object){
		return Api.fetchPost(Api.serverRoot() + 'applications', application);
	}

	static delete(applicationIds: string[]) {
		return Api.fetchPost(Api.serverRoot() + 'applications/delete', applicationIds);
	}

	static getReasons(applicationId: string) {
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '/reasons');
	}

	static getAssetReasons(applicationId: string, assetId: string) {
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '/reasons?assetId=' + assetId);
	}

	static getAssetGroupReasons(applicationId: string, assetGroupId: string, severity: Severity){
		const builder = new UrlBuilder(Api.serverRoot() + 'applications/' + applicationId + '/reasons')
			.add("assetGroupId", assetGroupId)
			.add("severity", severity == 'INVALID' ? 'MAINTENANCE' : severity, !!severity);

		return Api.fetch(builder.build());
	}

	static getLastReasons(applicationId: string) {
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '/lastReasons');
	}
	static getDependencies(applicationId: string) {
		return Api.fetch(Api.serverRoot() + 'applications/' + applicationId + '/dependencies');
	}
	static deleteDependencies(applicationId: string) {
		return Api.fetchDelete(Api.serverRoot() + 'applications/' + applicationId + '/dependencies/deleteAll')
	}

	static getAssetGroups(assetGroupId: string, includeSubaccounts?: boolean) {
		return Api.fetchPost(Api.accountRoot() + 'health/assetGroups/treelist?includeSubaccounts=' + includeSubaccounts, {});
	}

	static getSearchUrl() {
		return Api.accountRoot() + 'applications/search/lite?includeSubaccounts=false';
	}
}

type GetApplicationsArgs = {
	accountId?: string
}

export function getApplications(args?: GetApplicationsArgs){
	return new ApiRequest<any[]>({
		url: 'summary/applications',
		subaccountsFilter: true,
		accountBased: true,
		accountId: args?.accountId
	})
}
