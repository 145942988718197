const shortSchemaFields = {
	metricId: {editable: false},
	assetName: {editable: false},
	categoryNode: {editable: false},
	registryType: {editable: false},
	registryOwnerName: {editable: false},
	instanceName: {editable: false},
	unitType: {editable: false},
	advanced: {editable: false}
}

export function multigraphMetricSelectorGridSchema() {
	let schema = {
		model: {
			id: 'metricSelectorSchemaId',
			fields: shortSchemaFields
		}
	};
	return schema;
}
