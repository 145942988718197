import MultigraphWidgetConfiguration from './multigraphWidgetConfiguration';
import MultigraphWidget from './multigraphWidget';
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: MultigraphWidgetConfiguration,
		defaultConfig: {
			type: 'kpi-multi-graph',
			title: i('Multigraph'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true,
				reactTimePeriodSelector: true
			}
		},
		widget: MultigraphWidget,
		legacyWidget: true,
		legacyForm: true,
		showToggleIcon: true,
		fullTitle: i('KPI') + '/' + i('Multigraph'),
	}
}
