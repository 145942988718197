import {GridDataEntry} from "./gridDataEntry";
import {DataProvider} from "./gridConfiguration";
import {GridStore} from "./gridStore";
import {get, IObservableArray, makeAutoObservable, observable, runInAction, set} from "mobx";
import {GridDataItem} from "./gridDataItem";
import {RulesConfiguration, RulesHandler} from "../queryBuilder/ruleDefinition";
import {apiFetch, ApiRequest, ApiResponse, copyApiRequest} from "../../framework/api";
import {MobxManager} from "../../framework/mobx-integration";
import {debounce, orderBy} from "lodash";
import {newGuid} from "../../tools/guid";

interface PlainResponseRemoteDataProviderConfig<DataEntry>{
	dataSource: ApiRequest<Array<DataEntry>>| (() => ApiRequest<Array<DataEntry>>)
	onBatchLoaded?: (response: ApiResponse<GridDataEntry>) => void
}

export class PlainResponseRemoteDataProvider<DataEntry extends GridDataEntry> implements DataProvider<DataEntry> {
	config: PlainResponseRemoteDataProviderConfig<DataEntry>
	allData: IObservableArray<DataEntry>;
	apiRequest: ApiRequest<Array<DataEntry>>;
	filtersConfiguration: RulesConfiguration = {};
	initialized: boolean;
	totalRowsCount: number;
	store: GridStore<DataEntry>
	mobxManager = new MobxManager();
	reloading: boolean
	loading: boolean
	responseMessage: string
	batchInQueue: boolean

	get dataSource(){
		if(typeof this.config.dataSource === 'function'){
			return this.config.dataSource()
		}
		return this.config.dataSource
	}

	constructor(config: PlainResponseRemoteDataProviderConfig<DataEntry>) {
		this.config = config
		makeAutoObservable(this)
	}

	failToLoad: boolean;
    groupBySupported?: boolean;

	async attach(store: GridStore<DataEntry>) {
		this.store = store

		this.mobxManager.reaction(() => ({
			dataSource: this.dataSource
		}), () => {
			this.reload()
		}, {
			fireImmediately: true
		})
	}

	reload = async () => {
		if(!this.store.actualFilter.valid)
			return

		this.allData = observable.array()

		if(this.dataSource == null) {
			this.initialized = true
			this.totalRowsCount = 0
			this.apiRequest = null
			return
		}

		this.allData = observable.array()
		this.totalRowsCount = null
		this.initialized = false
		this.reloading = true
		this.apiRequest = this.dataSource

		await this.loadBatchBounced()
	}

	loadBatch = async () => {
		if (this.loading) {
			this.batchInQueue = true;
			return
		}

		this.loading = true
		this.responseMessage = null

		let request = this.getBaseApiRequest()

		const response = await apiFetch(request)

		this.config.onBatchLoaded?.(response)

		runInAction(() => {
			if (response.success) {
				this.allData = observable.array()
				for (let i = 0; i < response.data.length; i++) {
					set(this.allData, i, response.data[i])
				}

				this.store.listControl?.resetAfterIndex(0)

				if (this.batchInQueue) {
					this.batchInQueue = false;
					this.loadBatch()
				}
			} else {
				this.allData = observable.array()
				this.responseMessage = response.message
			}
			this.initialized = true
			this.reloading = false
			this.loading = false
		})
	}

	get scrollableRowsCount() {
		return this.data.length
	}

	get visibleRowsCount() {
		return this.data.length
	}

	convertToDataItems(data: Array<DataEntry>) {
		return data.map((x, i) => {
			const id = ('id' in x ? x.id : newGuid()).toString();
			return {
				id: id,
				type: 'data' as const,
				groupLevel: 1,
				data: x,
				index: i
			}
		})
	}

	get data(){
		return observable.array(this.convertToDataItems(this.getRefreshedData()))
	}

	getRefreshedData = () => {
		if (!this.store.actualFilter.valid) {
			return orderBy(this.allData, this.store.actualSorting.map(x => x.field), this.store.actualSorting.map(x => x.dir));
		}

		const handler = new RulesHandler(this.store.filtersConfigurationEffective)
		let result = handler.filter(this.allData, this.store.actualFilter)

		if (!this.store.actualSorting) {
			return result;
		}

		return orderBy(result, this.store.actualSorting.map(x => x.field), this.store.actualSorting.map(x => x.dir));
	}

	loadBatchBounced = debounce(this.loadBatch, 500)

	getBaseApiRequest(){
		let request = copyApiRequest(this.apiRequest)

		if(request.payload == null && request.method != 'GET'){
			request.payload = {}
		}
		return request
	}

	destroy(): void {
		this.mobxManager.destroy();
	}

	get(index: number): GridDataItem<DataEntry> {
		if(this.data.length <= index )
			return null

		return get(this.data, index);
	}

	informVisibleRangeChanged(startIndex: number, stopIndex: number): void {
	}

	async silentReload() {
		this.reloading = true
		await this.loadBatch();
	}
}
