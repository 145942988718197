import {Api} from "tools/api";
import {ApiRequest, PagedList, ApiRequestObservable} from "framework/api";
import { Asset } from "framework/entities/asset";
import {ApiRequestContext} from "tools/apiRequestContext";
import {Monitor} from "framework/entities/monitor";
import {AssetGroup} from "framework/entities/assetGroup";
import UrlBuilder from "tools/urlBuilder";

class AssetsApi {
	static getDynamicSearchUrl(includeSubaccounts: string, accountId?: string) {
		return Api.accountRoot(accountId) + 'assets/search/lite?includeSubaccounts=' + includeSubaccounts;
	}

	static getRootUrl(accountId?: string, sessionId?: string){
		return Api.sessionAndAccountRoot(accountId, sessionId) + 'assets/';
	}

	static getAssetUrl(assetId: string, accountId?: string, sessionId?: string){
		return AssetsApi.getRootUrl(accountId, sessionId) + assetId + '/';
	}

	static getAssetDetailsUrl(assetId: string) {
		return Api.accountRoot() + 'assets/' + assetId + '/assetDetails';
	}

	static getAssetLiteUrl({accountId, sessionId, includeSubaccounts}: {accountId?: string, sessionId?: string, includeSubaccounts?: boolean} = {}) {
		return AssetsApi.getRootUrl(accountId, sessionId) + 'assets/lite?includeSubaccounts=' + includeSubaccounts;
	}

	static getAssetsLite({accountId, sessionId, cache, includeSubaccounts = false }
		                     : {accountId?: string, sessionId?: string, cache?: object, includeSubaccounts?: boolean}) {
		return Api.fetch(
			AssetsApi.getRootUrl(accountId, sessionId) + 'lite?includeSubaccounts=' + includeSubaccounts,
			null,
			cache,
			'assets' + accountId);
	}

	static getHealthIndex(monitorId:string = null, assetId:string, accountId?: string, sessionId?: string) {
		let url = AssetsApi.getAssetUrl(assetId, accountId, sessionId) + 'health';

		if (monitorId != null && monitorId !== '' ) {
			url += '/monitors/' + monitorId;
		}

		return Api.fetch(url);
	}

	static getMonitorsUrl(assetId: string, accountId?: string, sessionId?: string) {
		return AssetsApi.getAssetUrl(assetId, accountId, sessionId) + 'health/monitors'
	}

	static getMonitors(assetId:string, context: ApiRequestContext) {
		return Api.fetch(
			AssetsApi.getMonitorsUrl(assetId, context?.accountId, context?.sessionId),
			null,
			context?.cache,
			"asset-monitors" + assetId
		);
	}
}

class AssetGroupsApi{
	static getRootUrl(accountId?: string, sessionId?: string){
		return Api.sessionAndAccountRoot(accountId, sessionId) + 'assetGroups/';
	}

	static getGroups(accountId?: string, sessionId?: string, cache?: object) {
		return Api.fetch(
			AssetGroupsApi.getRootUrl(accountId, sessionId),
			null,
			cache,
			'assetGroups' + accountId
		);
	}

	static getGroupMembers(assetGroupId: string){
		return Api.fetch(
			AssetGroupsApi.getRootUrl() + assetGroupId + '/members'
		);
	}

	static getDynamicSearchUrl(includeSubaccounts: string) {
		return Api.accountRoot() + 'assetGroups/search/lite?includeSubaccounts=' + includeSubaccounts;
	}
}

export {AssetsApi, AssetGroupsApi}

export type GetAssetGroupsOptions = {
	accountId?: string
}

export function getAssetGroups(options: GetAssetGroupsOptions = {}){
	return new ApiRequestObservable<AssetGroup[]>({
		url: 'assetGroups',
		accountId: options.accountId,
		accountBased: true
	});
}

export type GetAssetGroupAssetsOptions = {
	assetGroupId: string
	accountId?: string
	agentsOnly?: boolean
	systemType?: string
}

export function getAssetGroupAssets(options: GetAssetGroupAssetsOptions){
	const urlBuilder = new UrlBuilder(`assetGroups/${options.assetGroupId}/members`)
		.addPath('types/AGENT', !!options.agentsOnly)
		.add('systemType', options.systemType, !!options.systemType)

	return new ApiRequestObservable<Asset[]>({
		url: urlBuilder.build(),
		accountId: options.accountId,
		accountBased: true
	});
}

export type GetAssetGroupAssetsLiteArgs = {
	geotag?: boolean
	assetGroupIds: string[]
	accountId?: string
}
export function getAssetGroupsAssetsLite(args: GetAssetGroupAssetsLiteArgs){
	return new ApiRequest<Asset[]>({
		url: 'assetGroups/members/lite',
		accountId: args.accountId,
		accountBased: true,
		method: 'POST',
		payload: {
			assetGroupIds: args.assetGroupIds,
			geotag: args.geotag ?? false
		}
	});
}

export function getAsset(assetId: string, accountId: string = null){
	return new ApiRequest<Asset>({
		url: `assets/${assetId}`,
		accountId: accountId,
		accountBased: true
	});
}

export enum AssetsDataset{
	Lite,
	Full
}

export type GetAssetsOptions = {
	accountId?: string
	dataset?: AssetsDataset
	hasGeotag?: boolean
	shared?: boolean
	agentsOnly?: boolean
	includeSubaccounts?: boolean
	systemType?: string
}

export function getAssets(options: GetAssetsOptions = {}){
	if(options.hasGeotag && options.dataset != AssetsDataset.Lite){
		console.warn('geotag flag works only for /lite ')
	}

	const urlBuilder = new UrlBuilder('assets')
		.addPath('lite', options.dataset == AssetsDataset.Lite)
		.add('geotag', 'true', !!options.hasGeotag)
		.add('assetType', "AGENT", !!options.agentsOnly)
		.add('includeShared', true, !!options.shared)
		.add('includeSubaccounts', true, !!options.includeSubaccounts)
		.add('systemType', options.systemType, !!options.systemType)

	return new ApiRequestObservable<Asset[]>({
		url: urlBuilder.build(),
		accountId: options.accountId,
		accountBased: true
	})
}

export type SearchAssetsLiteArgs = {
	accountIds: string[]
	includeSubaccounts?: boolean
}

export function searchAssetsLite(args: SearchAssetsLiteArgs) {
	return new ApiRequest<PagedList<Asset>>({
		method: 'POST',
		url: 'assets/search/lite',
		payload: {
			filter: {
				logic: 'or',
				filters: args.accountIds.map(x => ({field: 'accountId', operator: 'eq', value: x}))
			},
			page: 1,
			pageSize: 9999,
			skip: 0,
			sort: [{field: "name", dir: "asc"}],
			take: 9999
		},
		accountBased: true,
		subaccountsFilter: true,
		includeSubaccounts: args.includeSubaccounts
	})
}

export type SearchAssetGroupsLiteArgs = {
	accountIds: string[]
	includeSubaccounts?: boolean
}

export function searchAssetGroupsLite(args: SearchAssetGroupsLiteArgs) {
	return new ApiRequest<PagedList<AssetGroup>>({
		method: 'POST',
		url: 'assetGroups/search/lite',
		payload: {
			filter: {
				logic: 'or',
				filters: args.accountIds.map( x => ({field: 'accountId', operator: 'eq', value: x}))
			},
			page: 1,
			pageSize: 9999,
			skip: 0,
			sort: [{field: "name", dir: "asc"}],
			take: 9999
		},
		accountBased: true,
		subaccountsFilter: true,
		includeSubaccounts: args.includeSubaccounts
	})
}

export function getMonitors(assetId: string, accountId?: string) {
	return new ApiRequest<Monitor[]>({
		url: `assets/${assetId}/health/monitors`,
		accountId: accountId,
		accountBased: true,
		subaccountsFilter: true
	})
}
