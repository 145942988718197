import { RefObject } from 'react';

export const polyfillRequestIdleCallback = () => {
	if (!window.requestIdleCallback) {
		// @ts-ignore
		window.requestIdleCallback = function (callback) {
			setTimeout(function () {
				var start = Date.now();
				callback({
					didTimeout: false,
					timeRemaining: function () {
						return Math.max(0, 50 - (Date.now() - start));
					}
				});
			}, 0);
		};
	}
}

export const waitForRef = async <T extends Node>(ref: RefObject<T>) => {
	return new Promise(resolve => {
		const interval = setInterval(() => {
			if (ref.current) {
				clearInterval(interval);
				resolve(ref.current);
			}
		}, 0);
	});
}
