import Cookies from 'core/cookies';
import Settings from 'settings';

function webLogin() {

	var path = String(window.location.href);
	var position = path.indexOf('#');
	if (position > -1) {
		path = path.substr(0, position);
		window.location.href = path;
	}
	//load language script
	var status = $('.status');
	status.find('p').text('');
	status.find('p').removeClass('error');
	status.hide();
	$('#username').val('');
	$('#password').val('');

	$('#login').on('click', function (button) {
		button.preventDefault();
		var username = $('#username').val();
		var password = $('#password').val();
		if (username && password) {
			$('#username').removeClass('invalid');
			$('#password').removeClass('invalid');
			var auth = {
				username: username,
				password: password
			};
			$(this).hide();
			jQuery.ajax({
				//url : Settings.serverPath + 'sessions/login/',
				// url: 'http://localhost:8080/ceeview/rest/sessions/login/',
				//url: 'http://77.88.64.35:8080/ceeview/rest/sessions/login/',
				url: 'https://77.88.64.35:443/rest/sessions/login/',
				data: JSON.stringify(auth),
				type: "POST",
				dataType: "json",
				contentType: "application/json; charset=utf-8",
				crossDomain: true,
				beforeSend: function (x) {
					if (x && x.overrideMimeType) {
						x.overrideMimeType("application/json;charset=UTF-8");
					}
				},
				error: function (result, message) {
					$('#login').show();
					status.find('p').addClass('error');
					status.find('p').text(lang.account.messages.WARNING_USER_AUTH_CONNECTION_ISSUE);
					status.show().delay(2000).slideUp();
				},
				success: function (result) {
					if (result.success) {
						var timeout = Settings.COOKIE_TIMEOUT;
						Cookies.create("CeesoftUsername", result.data.username, timeout);
						Cookies.create("CeesoftUserId", result.data.userId, timeout);
						Cookies.create("CeesoftAccountId", result.data.accountId, timeout);
						Cookies.create("CeesoftTimezone", result.data.timeZone, timeout);
						Cookies.create("CeesoftUserLocale", result.data.locale, timeout);
						Cookies.create("CeesoftUserDateTimeFormat", result.data.dateTimeFormat, timeout);
						Cookies.create("CeesoftUserDateFormat", result.data.dateFormat, timeout);
						Cookies.create("CeesoftUserTimeFormat", result.data.timeFormat, timeout);
						Cookies.create("CeesoftAccountName", result.data.accountName, timeout);
						Cookies.create("CeesoftCurrentAccountId", result.data.accountId, timeout);
						Cookies.create("CeesoftCurrentAccountName", result.data.accountName, timeout);
						Cookies.erase("CeesoftParentAccountId");
						Cookies.erase("CeesoftParentAccountName");
						Cookies.create("sessionId", result.data.sessionId, timeout);
						window.top.location = Settings.httpRoot;
					} else {
						$('#login').show();
						switch (result.message) {
							case 'wizard':
								window.top.location = 'Wizard.jsp';
								break;
							case 'umchpwd':
								window.top.location = 'ChangePassword.jsp?user=' + $('#username').val();
								break;
							default:
								var status = $('.status');
								status.find('p').text(result.message);
								status.find('p').addClass('error');
								status.show().delay(2000).slideUp();
						}
					}
				}
			});
		} else {
			if (!username) {
				$('#username').addClass('invalid');
			}
			if (!password) {
				$('#password').addClass('invalid');
			}
		}
	});
	$('#username').blur(function () {
		if ($(this).val() === '') {
			$(this).addClass('invalid');
		} else {
			$(this).removeClass('invalid');
		}
	});
	$('#password').blur(function () {
		if ($(this).val() === '') {
			$(this).addClass('invalid');
		} else {
			$(this).removeClass('invalid');
		}
	});
}

export default webLogin;
