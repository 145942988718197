import './metricConversionForm.less';

import React from "react";
import classnames from "classnames";

import {Button, FormEntry, NumericTextBox, TextArea} from "controls/react/form";
import {translator} from "core";
import {apiFetch} from "framework/api";
import {validateConvertion} from "areas/service-boards/widgets/common/dataSources/MetricSettingsApi";
import Utils from "tools/utils";


let i18n = translator({
	"Formula": {
		"en": "Formula",
		"no": "Formel"
	},
	"Decimals": {
		"en": "Decimals",
		"no": "Desimal"
	},
	"Valid formula": {
		"en": "Formula is valid",
		"no": "Formelen er gyldig"
	},
	"Valid formula output": {
		"en": "Formula is valid. Output is: ",
		"no": "Formelen er gyldig. resultatet er : "
	},
	"Not valid formula": {
		"en": "Formula is not valid",
		"no": "Formelen er ikke gyldig"
	},
	"Formula placeholder": {
		"en": "Expressions must use 'metric' to represent metric value. Example: metric*100.",
		"no": "Uttrykket må bruke 'metric' for å representere metrikkverdi. Eksempel: metric*100."
	},
	"Conversion formula": {
		"en": "Conversion formula",
		"no": "Konverteringsformel"
	},
	"Formula tooltip": {
		"en": "Formula editor support normal operators (+-/*%^) and functions (cos, sin, log). Check https://www.objecthunter.net/exp4j/#Built-in_functions for more examples",
		"no": "Formel editor støtter normale operatorer (+ - / *% ^) og funksjoner (cos, sin, log). Se https://www.objecthunter.net/exp4j/#Built-in_functions for flere eksempler"
	},
	"Validate": {
		"no": "Validering"
	}
});


const b = require('b_').with('metrics-conversion-form')

type MetricConversionFormProps = {
	additionalPlaceholder?: string
	readOnly?: boolean
	disableFields?: boolean
	formula?: string
	decimals?: number
	onValidate?: () => void
	onFormulaChange?: (formula: string) => void
	onDecimalsChange?: (decimals: number) => void
}

export const MetricConversionForm = (props: MetricConversionFormProps) => {
	const [placeholder, setPlaceholder] = React.useState(i18n('Formula placeholder'));

	React.useEffect(() => {
		setPlaceholder([i18n('Formula placeholder'), props.additionalPlaceholder].filter(x => x?.length).join(' '));
	}, [props.additionalPlaceholder]);

	const iconClasses = classnames("glyphicons question-sign", b('conversion-formula-tooltip'))

	return <>
		<FormEntry vertical label={i18n('Formula')}>
			<span className={iconClasses} title={i18n("Formula tooltip")}></span>
			<Button title={i18n('Validate')}
			        className={b('validate-button')}
			        primary={true}
			        disabled={!props.formula?.length || props.readOnly || props.disableFields}
			        onClick={() => props.onValidate?.()}/>
			<TextArea value={props.formula}
			          placeholder={placeholder}
			          onChange={props.onFormulaChange}
			          readOnly={props.readOnly || props.disableFields}/>
		</FormEntry>
		<FormEntry label={i18n('Decimals')}>
			<NumericTextBox value={props.decimals}
			                spinners={true}
			                onChange={props.onDecimalsChange}
			                onSpin={props.onDecimalsChange}
			                enabled={!props.disableFields}
			/>
		</FormEntry>
	</>
}

export const validateMetricConversion = async (formula: string, decimals: number, lastValue: string) => {
	const response = await apiFetch(validateConvertion(formula, lastValue));
	const result: Record<string, string> = {};
	if (!response.success) {
		result.status = 'error';
		result.message = i18n('Not valid formula');
		return result;
	}
	result.status = 'success';
	if (!response.data) {
		result.message = i18n('Valid formula');
		return result;
	}
	result.message = i18n('Valid formula output') + Utils.truncateDecimals(response.data, decimals);
	return result;
}
