import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ServicesGridWidget} from "./serviceSummaryGridWidget";
import {ServiceSummaryWidgetConfiguration} from "./serviceSummaryWidgetConfiguration";
import {ApplicationState} from "../../../../framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: ServiceSummaryWidgetConfiguration,
		defaultConfig: {
			type: 'service-summary-grid',
			title: i('Summary grid'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		widget: ServicesGridWidget,
		fullTitle: i('Service') + '/' + i('Summary grid'),
	}
}
