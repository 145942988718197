//import '@progress/kendo-ui/css/web/kendo.classic-main.css'
import '@progress/kendo-ui/css/web/kendo.common.min.css'
import '@progress/kendo-ui/css/web/kendo.default-ocean-blue.css'
import './overrides.less'

require('@progress/kendo-ui/js/kendo.dropdownlist');
require('@progress/kendo-ui/js/kendo.menu');
require('@progress/kendo-ui/js/kendo.editor');
require('@progress/kendo-ui/js/kendo.treelist');
require('@progress/kendo-ui/js/kendo.tabstrip');
require('@progress/kendo-ui/js/kendo.splitter');
require('@progress/kendo-ui/js/kendo.upload');
require('@progress/kendo-ui/js/kendo.scheduler');
require('@progress/kendo-ui/js/kendo.combobox');
require('@progress/kendo-ui/js/kendo.grid');
require('@progress/kendo-ui/js/kendo.multiselect');
require('@progress/kendo-ui/js/kendo.datetimepicker');
require('@progress/kendo-ui/js/kendo.tooltip');
require('@progress/kendo-ui/js/kendo.slider');
require('@progress/kendo-ui/js/kendo.treeview');
require('@progress/kendo-ui/js/kendo.sortable');
require('@progress/kendo-ui/js/kendo.listview');
require('@progress/kendo-ui/js/kendo.pager');
require('@progress/kendo-ui/js/kendo.window');
require('@progress/kendo-ui/js/kendo.panelbar');
require('@progress/kendo-ui/js/kendo.notification');
require('@progress/kendo-ui/js/cultures/kendo.culture.en');
require('@progress/kendo-ui/js/cultures/kendo.culture.no');
require("./kendo-ui-license.js");
