import {Cookies} from "core/cookies";
import GenericMetricsWidgetConfigurationWrapper from './genericMetricsWidgetConfiguration';
import GenericMultigraphWidget from './genericMultigraphWidget';
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: GenericMetricsWidgetConfigurationWrapper,
		defaultConfig: {
			type: 'metric-multi-graph-asset',
			title: i('Multigraph (Asset)'),
			configuration: {
				accountId: ApplicationState.accountId,
				timezone: ApplicationState.timezone,
				hideThreshold: true,
				isDashboard: true,
				reactTimePeriodSelector: true,
			}
		},
		widget: GenericMultigraphWidget,
		legacyWidget: true,
		legacyForm: true,
		showToggleIcon: true,
		fullTitle: i('Metric') + '/' + i('Multigraph (Asset)')
	}
}
