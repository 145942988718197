import Utils from 'tools/utils'

export let ExpandableTextarea = function (config) {
	if (config.hasOwnProperty('readonly')) {
		this._readonly = config.readonly;
		delete config.readonly;

		this._modal = config.modal;
		delete config.modal;
	}
	Utils.apply(this, config);

	this._initComponent();
};

export default ExpandableTextarea;

ExpandableTextarea.prototype = {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where to render the component
	 */
	renderTo: null,
	/*
	* @cfg {String} label. Optional. The label of the component
	* */
	label: '',
	_readonly: false,
	expandable: true,
	id: '',
	/**
	 * Main init function
	 */
	_initComponent: function () {
		if (this.expandable) {
			this.renderTo.addClass('expandable_area cw_description_expand');
		}
		this.label = this.label || lang.DESCRIPTION;

		this._windowArea = $('.window_area');
		this._maskContainer = this.renderTo.closest('.form');


		this._loadTemplate();
		this._render();
		this._setReadOnly();
		this._attachListeners();

	},
	/**
	 * Attaches listeners
	 */
	_attachListeners: function () {
		var scope = this;
		if (this.keyUp) {
			this.textarea.off().on('keyup', $.proxy(this._onTextareaKeyUp, this));
		}
		this.textarea.off().on('focusout', $.proxy(this._onMouseout, this));
		this.textarea.bind('mousedown', function () {
			scope.textarea.focus();
			$('.k-resize-handle.k-resize-se').css('z-index', '100000');
			$(this).css("z-index", "10000005");
			$('.cw_description_expand textarea').first().css("z-index", "100000008");
		});
	},
	_onMouseout: function () {
		this.collapse();
	},
	/*
	* Handler function for setting the textarea read only property
	* */
	_setReadOnly: function () {
		this.textarea.prop('readonly', this._readonly);
	},
	/*
	* Handler function for textarea key up
	* @param {Object} e The key up event
	* */
	_onTextareaKeyUp: function (e) {
		this.keyUp.call(this, e);
	},
	/*
	 * Handler function for rendering the component
	 * */
	_render: function () {
		this.renderTo.empty().html(this.template);

		this.textarea = this.renderTo.find('.is_expandable');
		if (this.renderTo.closest('.cw_section_content')) {
			this.renderTo.closest('.cw_section_content').css({'overflow': 'visible'});
		}
		if (this.renderTo.closest('.k-window-content')) {
			this.renderTo.closest('.k-window-content').css({'overflow': 'visible'});
		}
	},
	/*
	 * Handler function for getting the template
	 * */
	_loadTemplate: function () {
		var html = '';

		html += '<label class="cw_inline_full" ' + this.labelTooltip + '>';
		html += this.label;

		if (this.tooltipIconTitle) {
			html += '<span class="glyphicons question-sign label-question-sign" title="' + this.tooltipIconTitle + '"></span>';
		}

		if (this.expandable) {
			//html +=	'<span class="glyphicons right resize-full cw_expand_area"></span>';
		}
		html += '</label>';
		if ($('.cw_description_expand').length > 0) {
			html += '<textarea id="' + this.id + '" class="cw_inline is_expandable" style="z-index: ' + (21 - $('.cw_description_expand').length) + '"></textarea>';
		} else {
			html += '<textarea id="' + this.id + '" class="cw_inline is_expandable"></textarea>';
		}

		this.template = html;
	},
	/*
	 * Handler function for clicking the expand icon
	 * @param {Object} e The click event object
	 * */
	_onClick: function (e) {
		if (this.textarea.hasClass('expanded')) {
			this.collapse();
		} else {
			this.collapseAll();
			this.expand();
		}

		$(e.currentTarget).toggleClass('resize-full resize-small');
	},
	/*
	* Handler function for getting/setting the description value
	* @param {String} value. Optional
	* */
	value: function (value) {
		if (value !== undefined) {
			this.textarea.val(value);
		} else {
			return this.textarea.val();
		}
	},
	/*
	 * Handler function for getting/setting the readonly property and textarea state
	 * @param {Boolean} value. Optional. Values: true/false
	 * */
	readonly: function (value) {
		if (value !== undefined) {
			this._readonly = value;
		} else {
			return this._readonly;
		}

		this._setReadOnly();
	},
	/*
	* Handler function for removing textarea class
	* @param {String} cssClass The class
	* */
	removeClass: function (cssClass) {
		this.textarea.removeClass(cssClass);
	},
	/*
	* Handler function for collapsing all the descriptions
	* */
	collapseAll: function () {
		$('.is_expandable').removeClass('expanded').removeAttr('style');
	},
	/*
	* Handler function for expanding the description
	* */
	expand: function () {
		var width = this._windowArea.width(), textareaWidth;
		//this.renderTo.closest('.cw_section_content').css({'overflow': 'visible'});
		this.textarea.addClass('expanded');
		textareaWidth = width - 30 - Math.floor(width * 2 / 100);
		this.textarea.css('width', textareaWidth);
		this._maskContainer.append('<div class="cw_mask"></div>');
	},
	/*
	* Handler function for collapsing the description
	* */
	collapse: function () {
		this.textarea.removeClass('expanded');
		this.textarea.attr('style', '');
		this._maskContainer.find('.cw_mask').remove();
	}
};
