import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "../../../framework/applicationState";
import {SummaryWidget} from "./summaryWidget";
import {SummaryWidgetConfiguration} from './summaryWidgetConfiguration'

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: SummaryWidgetConfiguration,
		defaultConfig: {
			type: 'service-summary',
			title: i('Grouped summary'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true
			}
		},
		constructWidget: config => new SummaryWidget(config),
		legacyWidget: true,
		legacyForm: true,
		fullTitle: i('Service') + '/' + i('Summary'),
	}
}
