import Settings from "settings";

export function marketOffer() {
	let url = Settings.ssoPath + 'marketOffer' + window.location.search;
	$.ajax({
		type: "GET",
		url: url,
		data: "",
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (result) {
			if (result.success) {
				let data = result.data;
				$('#company').val(data.company);
				$('#phone').val(data.userTelephone);
				$('#email').val(data.userEmail);
				if (data.company) {
					$('#company').next('.required-field').addClass('hide');
				}
				if (data.userEmail) {
					$('#email').next('.required-field').addClass('hide');
				}
				if (data.company && data.userEmail) {
					$('#cw_send').attr('disabled', false);
				}
			}
		}
	});

	$('#company, #email').on('keyup', function() {
		if ($(this).val()) {
			$(this).next('.required-field').addClass('hide');
		} else {
			$(this).next('.required-field').removeClass('hide');
		}
		if ($('#company').val() && $('#email').val()) {
			$('#cw_send').attr('disabled', false);
		} else {
			$('#cw_send').attr('disabled', true);
		}
	});

	$('#cw_send').on('click', () => {
		let url = Settings.ssoPath + 'marketOffer' + window.location.search;
		let postObj = {
			company: $('#company').val(),
			userTelephone: $('#phone').val(),
			userEmail: $('#email').val()
		};
		$.ajax({
			type: "POST",
			url: url,
			data: JSON.stringify(postObj),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (result) {
				if (result.success) {
					window.location = window.location.origin + '/azuresso/offer365';
				}
			}
		});
	});
}
