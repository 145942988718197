import contextualHelpMap from "../layout/helpMapping";

export const writeRouteIdLog = (routeId: string) => {
	$('#content_area')[0].setAttribute('data-route-id', routeId);
	// @ts-ignore
	if (!window.CEEVIEW_LOGGING_ROUTEID_ENABLED) {
		return
	}
	console.group('')
	console.log('---------------------- Route Id ----------------------');
	console.log('route id: ', routeId);
	console.log('current help route: ', contextualHelpMap.get(routeId) ?? '');
	console.log('------------------------------------------------------');
	console.groupEnd()
}

export const logRouteIdException = (...args: string[]) => {
	// @ts-ignore
	if (!window.CEEVIEW_LOGGING_ROUTEID_ENABLED) {
		return
	}
	console.log(...args)
}
