import {Cookies, ErrorHandler} from "core";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import Utils from "tools/utils";
import Settings from "settings";

export let AssetGroupSelector = function (config) {
	Utils.apply(this, config);
	this._initComponent();
};

export default AssetGroupSelector;

AssetGroupSelector.prototype = {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the control is rendered
	 */
	renderTo: null,
	/**
	 * @cfg {String/UUID} accountId The account id of the asset group
	 */
	accountId: null,
	/**
	 * @cfg {String} mode The mode based on permissions
	 */
	mode: null,
	/**
	 * Main init function
	 */
	_initComponent: function () {
		var html = '<div class="cw_section cw_selected_assets">';
		html += '<div class="cw_section_titlebar">';
		//html += '<div class="cw_section_title"><div id="cw_selected_assets_menu" class="cw_global_actions"></div> ' + lang.account.SELECTED_ASSETS + ' </div>';
		html += '<div class="cw_section_title">' + lang.account.SELECTED_ASSETS + '<span id="cw_selected_asset_count"></span></div>';
		html += '<div class="cw_section_actions right">';
		html += '<div class="cw_search_container">';
		html += '<input type="text" class="cw_search_box" placeholder="' + lang.SEARCH + '">';
		html += '</div></div></div>';
		html += '<div class="cw_section_content">';
		html += '<div class="cw_selected_assets_list"></div>';
		//<!-- <label id="cw_ag_assets" class="cw_inline_full">Assets</label>
		//<select class="cw_assets_list" id="cw_assets_list" data-placeholder="Select assets" multiple></select> -->
		html += '</div></div>';
		html += '<div class="asset_group_actions"><span id="cw_move_asset" class="glyphicons arrow-left is_disabled" title="' + lang.account.ASSET_GROUP_ADD_TO + '"></span>';
		html += '<span id="cw_remove_selected" class="glyphicons arrow-right is_disabled" title="' + lang.account.ASSET_GROUP_REMOVE + '"></span></div>';
		html += '<div class="cw_section cw_all_assets">';
		html += '<div class="cw_section_titlebar">';
		//html += '<div class="cw_section_title"><div id="cw_all_assets_menu" class="cw_global_actions"></div> ' + lang.account.ALL_ASSETS + ' </div>';
		html += '<div class="cw_section_title">' + lang.account.ALL_ASSETS + '<span id="cw_all_asset_count"></span></div>';
		html += '<div class="cw_section_actions right">';
		html += '<div class="cw_search_container">';
		html += '<input type="text" class="cw_search_box" placeholder="' + lang.SEARCH + '">';
		html += '</div></div></div>';
		html += '<div class="cw_section_content"><div class="cw_all_assets_list"></div>';
		html += '</div></div>';
		this.renderTo.append(html);
		if (this.groupType === 'EXTERNAL') {
			$('.asset_group_actions').addClass('hide');
			$('.cw_all_assets').addClass('hide');
			var selectedAssetsContainer = $('.cw_selected_assets');
			selectedAssetsContainer.css('right', '10px');
			selectedAssetsContainer.find('.cw_section_title').text(lang.assethealth.EXTERNAL_ASSETS);
		}
		this._assetsList = [];
		this._removeListeners();
		this._attachListeners();
		this._initKendoComponents();
	},
	_initKendoComponents: function () {
		this.firstTimeLoad = true;
		var columns = [{
			field: 'id',
			title: lang.SELECTOR,
			sortable: false,
			filterable: false,
			menu: false,
			template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
			headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
			headerAttributes: {
				'class': 'text_center'
			},
			attributes: {
				'class': 'text_center'
			},
			width: 40
		}, {
			field: 'name',
			title: lang.NAME,
			width: this.groupType === 'EXTERNAL' ? 300 : 200,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			}
		}, {
			field: 'assetType',
			title: lang.TYPE,
			width: this.groupType === 'EXTERNAL' ? 200 : 120,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			}
		}];
		if (this.groupType === 'EXTERNAL') {
			columns.push(
				{
					field: 'systemType',
					title: lang.assethealth.SYSTEM_TYPE,
					width: 200,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				},
				{
					field: 'description',
					title: lang.DESCRIPTION,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}
			)
		}
		var gridHeader = $('#cw_assetdetails_tabs').length ? $('#cw_assetdetails_tabs') : $('#cw_incident_tabs');
		var gridHeaderRealHeight = gridHeader.height() + parseInt(gridHeader.css('padding-top').split('px')[0]);
		let container = $('.cw_reasons_history').length ? $('.cw_reasons_history') : $('.cw_tier_bottom');
		var gridHeight = container.height() - gridHeaderRealHeight - 68;
		this.selectedAssetsGrid = this.renderTo.find('.cw_selected_assets_list').kendoCustomGrid({
			dataSource: new CeeViewDataSource({
				data: [],
				schema: {
					model: {
						id: 'id'
					}
				}
			}),
			scrollable: true,
			height: gridHeight,
			resizable: true,
			sortable: true,
			filterable: false,
			columns: columns,
			change: $.proxy(function () {
				var selectedRow = this.selectedAssetsGrid.select();
				var myRow = selectedRow[0];
				var messageEl = $(myRow).find('.to_expand');
				if ($(messageEl).hasClass('cw_message_expanded')) {
					$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
				} else {
					this.renderTo.find('.cw_selected_assets_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
					$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
				}
			}, this),
			dataBound: $.proxy(function (e) {
				this.renderTo.find('.cw_selected_assets_list').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});
				if (this.mode === 'view') {
					$('.cw_grid_check').attr('disabled', 'disabled');
					$('.cw_grid_check_all').attr('disabled', 'disabled');
				}
				if (this.selectedAssetsGrid) {
					$('#cw_filtered_groups').text(this.selectedAssetsGrid.dataSource.total());
					$('#cw_total_groups').text(this.selectedAssetsGrid.dataSource.data().length);
				}
			}, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.selectedAssetsGrid);

		this.allAssetsGrid = this.renderTo.find('.cw_all_assets_list').kendoCustomGrid({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + (this.accountId || Cookies.CeesoftCurrentAccountId) + '/assets/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					model: {
						fields: {
							id: {
								type: 'string',
								editable: false
							}
						}
					}
				},
				sort: [{
					field: 'name',
					dir: 'asc'
				}],
				error: ErrorHandler.kendoServerError
			}),
			scrollable: true,
			height: gridHeight,
			resizable: true,
			sortable: true,
			filterable: false,
			columns: columns,
			dataBound: $.proxy(function (e) {
				if (this._assetsList.length) {
					if (this.firstTimeLoad) {
						this.firstTimeLoad = false;
						this.setValue(this._assetsList);
					}
				}
				if (this.mode === 'view') {
					$('.cw_grid_check').attr('disabled', 'disabled');
					$('.cw_grid_check_all').attr('disabled', 'disabled');
				}
				var selectedAssetsLength = this.selectedAssetsGrid.dataSource.data().length;
				var allAssetsLength = e.sender.dataSource.data().length + selectedAssetsLength;

				$('#cw_all_asset_count').text((allAssetsLength - selectedAssetsLength) + '/' + allAssetsLength);
				$('#cw_selected_asset_count').text(selectedAssetsLength + '/' + allAssetsLength);
			}, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.allAssetsGrid);
		// this.selectedAssetsMenu = new GridMenu({
		// 	renderTo: 'cw_selected_assets_menu',
		// 	items: [{
		// 			id: 'cw_remove_selected',
		// 			icon: 'bin',
		// 			text: lang.REMOVE,
		// 			fn: this._onAssetDelete,
		// 			scope: this,
		// 			disabled: true
		// 		}]
		// });
		// this.allAssetsMenu = new GridMenu({
		// 	renderTo: 'cw_all_assets_menu',
		// 	items: [{
		// 			id: 'cw_move_asset',
		// 			icon: 'arrow-left',
		// 			text: lang.account.MOVE_TO_SELECTED_ASSETS,
		// 			fn: this._onAssetMove,
		// 			scope: this,
		// 			disabled: true
		// 		}]
		// });
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function () {
		var selectedRow = this.selectedAssetsGrid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			this.renderTo.find('.cw_selected_assets_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	_removeListeners: function () {
		this.renderTo.find('.cw_selected_assets_list').off();
		this.renderTo.find('.cw_all_assets_list').off();
		this.renderTo.find('.cw_selected_assets').find('.cw_search_box').off();
		this.renderTo.find('.cw_all_assets').find('.cw_search_box').off();
	},
	_attachListeners: function () {
		this.renderTo.find('.cw_selected_assets_list').on('click', '.cw_grid_check', $.proxy(this._onSelectedAssetsGridCheck, this));
		this.renderTo.find('.cw_selected_assets_list').on('click', '.cw_grid_check_all', $.proxy(this._onSelectedAssetsGridCheckAll, this));
		this.renderTo.find('.cw_all_assets_list').on('click', '.cw_grid_check', $.proxy(this._onAllAssetsGridCheck, this));
		this.renderTo.find('.cw_all_assets_list').on('click', '.cw_grid_check_all', $.proxy(this._onAllAssetsGridCheckAll, this));
		this.renderTo.find('.cw_selected_assets').find('.cw_search_box').on('keyup', $.proxy(this._onSelectedAssetsSearch, this));
		this.renderTo.find('.cw_all_assets').find('.cw_search_box').on('keyup', $.proxy(this._onAllAssetsSearch, this));
		$('#cw_remove_selected').on('click', $.proxy(this._onAssetDelete, this));
		$('#cw_move_asset').on('click', $.proxy(this._onAssetMove, this));
	},
	_onSelectedAssetsGridCheck: function () {
		var checkboxes = this.renderTo.find('.cw_selected_assets_list').find('.cw_grid_check:checked');
		if (checkboxes.length) {
			//this.selectedAssetsMenu.enableItem('cw_remove_selected');
			$('#cw_remove_selected').removeClass('is_disabled');
			$('#cw_remove_selected').addClass('pointer');
		} else {
			//this.selectedAssetsMenu.disableItem('cw_remove_selected');
			$('#cw_remove_selected').addClass('is_disabled');
			$('#cw_remove_selected').removeClass('pointer');
		}
	},
	_onSelectedAssetsGridCheckAll: function (e) {
		var checked = $(e.currentTarget).is(':checked');
		this.renderTo.find('.cw_selected_assets_list').find('.cw_grid_check').prop('checked', checked);
		this._onSelectedAssetsGridCheck();
	},
	_onAllAssetsGridCheck: function () {
		var checkboxes = this.renderTo.find('.cw_all_assets_list').find('.cw_grid_check:checked');
		if (checkboxes.length) {
			//this.allAssetsMenu.enableItem('cw_move_asset');
			$('#cw_move_asset').removeClass('is_disabled');
			$('#cw_move_asset').addClass('pointer');
		} else {
			//this.allAssetsMenu.disableItem('cw_move_asset');
			$('#cw_move_asset').addClass('is_disabled');
			$('#cw_move_asset').removeClass('pointer');
		}
	},
	_onAllAssetsGridCheckAll: function (e) {
		var checked = $(e.currentTarget).is(':checked');
		this.renderTo.find('.cw_all_assets_list').find('.cw_grid_check').prop('checked', checked);
		this._onAllAssetsGridCheck();
	},
	_onSelectedAssetsSearch: function (e) {
		var value = $(e.currentTarget).val();
		if (value) {
			this.selectedAssetsGrid.dataSource.filter({
				field: 'name',
				operator: 'contains',
				value: value
			});
		} else {
			this.selectedAssetsGrid.dataSource.filter({});
		}
		var matchingAssets = this.selectedAssetsGrid.dataSource.total();
		var allAssets = this.selectedAssetsGrid.dataSource.data().length;
		$('#cw_selected_asset_count').text(matchingAssets + '/' + allAssets);
	},
	_onAllAssetsSearch: function (e) {
		var value;
		if (e) {
			value = $(e.currentTarget).val();
		} else {
			value = $('.cw_all_assets').find('.cw_search_box').val();
		}
		if (value) {
			this.allAssetsGrid.dataSource.filter({
				field: 'name',
				operator: 'contains',
				value: value
			});
		} else {
			this.allAssetsGrid.dataSource.filter({});
		}
		var matchingAssets = this.allAssetsGrid.dataSource.total();
		var allAssets = this.allAssetsGrid.dataSource.data().length;
		$('#cw_all_asset_count').text(matchingAssets + '/' + allAssets);
	},
	_onAssetMove: function () {
		var checkboxes = this.renderTo.find('.cw_all_assets_list').find('.cw_grid_check:checked');
		var records = [];
		for (var i = 0; i < checkboxes.length; i++) {
			var uid = $(checkboxes[i]).closest('tr').attr('data-uid');
			var assetRecord = this.allAssetsGrid.dataSource.getByUid(uid);
			records.push(assetRecord);
		}
		var data = [];
		for (var i = 0; i < records.length; i++) {
			data.push({
				name: records[i].name,
				id: records[i].id,
				assetType: records[i].assetType
			});
			this._assetsList.push(records[i].id);
		}
		this._removeFromDataSource(this.allAssetsGrid, records);
		this.selectedAssetsGrid.dataSource.data().push.apply(this.selectedAssetsGrid.dataSource.data(), data);
		this.renderTo.find('.cw_all_assets_list').find('.cw_grid_check_all').prop('checked', false);

		$('#cw_move_asset').addClass('is_disabled');
		$('#cw_move_asset').removeClass('pointer');

		this._onAllAssetsSearch();

		/*var selectedAssetsLength = this.selectedAssetsGrid.dataSource.data().length;
		var allAssetsLength = this.allAssetsGrid.dataSource.data().length + selectedAssetsLength;

		$('#cw_all_asset_count').text((allAssetsLength - selectedAssetsLength) + '/' + allAssetsLength);
		$('#cw_selected_asset_count').text(selectedAssetsLength + '/' + allAssetsLength);*/
	},
	_removeFromDataSource: function (grid, records) {
		var data = grid.dataSource.data();
		var newData = [];
		for (var i = 0; i < data.length; i++) {
			var found = false;
			for (var j = 0; j < records.length; j++) {
				if (data[i].id === records[j].id) {
					found = true;
					break;
				}
			}
			if (!found) {
				newData.push(data[i]);
			}
		}
		grid.setDataSource(new CeeViewDataSource({
			data: newData
		}));
	},
	_onAssetDelete: function () {
		var checkboxes = this.renderTo.find('.cw_selected_assets_list').find('.cw_grid_check:checked');
		var records = [];
		for (var i = 0; i < checkboxes.length; i++) {
			var uid = $(checkboxes[i]).closest('tr').attr('data-uid');
			var assetRecord = this.selectedAssetsGrid.dataSource.getByUid(uid);
			records.push(assetRecord);
		}
		var data = [];
		for (var i = 0; i < records.length; i++) {
			var index = this._assetsList.indexOf(records[i].id);
			if (index > -1) {
				this._assetsList.splice(index, 1);
			}
			data.push({
				name: records[i].name,
				id: records[i].id,
				assetType: records[i].assetType
			});
		}
		this._removeFromDataSource(this.selectedAssetsGrid, records);
		this.allAssetsGrid.dataSource.data().push.apply(this.allAssetsGrid.dataSource.data(), data);

		this.renderTo.find('.cw_selected_assets_list').find('.cw_grid_check_all').prop('checked', false);

		$('#cw_remove_selected').addClass('is_disabled');
		$('#cw_remove_selected').removeClass('pointer');
	},

	getValue: function () {
		return this.selectedAssetsGrid.dataSource.data()
			.toJSON()
			.map(x=> x.id);
	},

	setValue: function (assetsList) {
		this._assetsList = assetsList;
		var gridEl = this.renderTo.find('.cw_all_assets_list');
		var data = [], records = [];
		if (!this.firstTimeLoad) {
			for (var i = 0, length = assetsList.length; i < length; i++) {
				var uid = gridEl.find('.cw_grid_check[data-id="' + assetsList[i] + '"]').closest('tr').attr('data-uid');
				var assetRecord = this.allAssetsGrid.dataSource.getByUid(uid);
				if (assetRecord) {
					records.push(assetRecord);
					data.push({
						name: assetRecord.name,
						id: assetRecord.id,
						assetType: assetRecord.assetType,
						systemType: assetRecord.systemType,
						description: assetRecord.description
					});
				}
			}
			this._removeFromDataSource(this.allAssetsGrid, records);
			this.selectedAssetsGrid.dataSource.data().push.apply(this.selectedAssetsGrid.dataSource.data(), data);
		}
	}
};
