import {CssVariables} from "styles/cssVariables";

export const Severity = Object.freeze({
	NONE: 'NONE',
	MINOR: 'MINOR',
	MAJOR: 'MAJOR',
	CRITICAL: 'CRITICAL',
	MAINTENANCE: 'MAINTENANCE', //asset in maintenance but we know the last health,
	INVALID: 'INVALID', //no health for some reasons
	INACTIVE:  'INACTIVE' // maintenance + invalid

});


export function getSeverityColor(severity) {
	switch (severity) {
		case 'CRITICAL':
			return CssVariables.severityCritical
		case 'MAJOR':
			return CssVariables.severityMajor
		case 'MINOR':
			return CssVariables.severityMinor
		case 'NONE':
			return CssVariables.severityNone
		case 'INVALID':
		case 'MAINTENANCE':
		case 'INACTIVE':
			return CssVariables.severityInvalid

		default:
			return CssVariables.severityInvalid
	}
}


export const defaultSeveritiesOrder = [Severity.CRITICAL, Severity.MAJOR, Severity.MINOR, Severity.NONE, Severity.MAINTENANCE];

export const healthToSeverity = {
	critical: Severity.CRITICAL,
	CRITICAL: Severity.CRITICAL,
	major: Severity.MAJOR,
	MAJOR: Severity.MAJOR,
	minor: Severity.MINOR,
	MINOR: Severity.MINOR,
	ok: Severity.NONE,
	OK: Severity.NONE,
	NONE: Severity.NONE,
	none: Severity.NONE,
	noHealth: Severity.INVALID,
	nohealth: Severity.INVALID,
	NOHEALTH: Severity.INVALID,
	maintenance: Severity.INVALID,
	MAINTENANCE: Severity.INVALID,
	INVALID: Severity.INVALID,
	invalid: Severity.INVALID
}
