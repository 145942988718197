import tinymce from 'tinymce/tinymce';

import 'tinymce/icons/default';
import 'tinymce/themes/silver';

import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/media';

import 'vendor-init/tinymce/plugins/componentsPlugin';
import 'vendor-init/tinymce/plugins/tableStylingPlugin';

import './tinymce/tinymce.less'

import _ from 'lodash';

import 'tinymce-i18n/langs/nb_NO';
import Cookies from "core/cookies";

const i = require('core/localization').translator(sharedLocalization)

import {components} from "vendor-init/tinymce/plugins/components";
import {sharedLocalization} from "vendor-init/tinymce/plugins/localization";
import {ApplicationState} from "framework/applicationState";

require.context(
	'file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
	true,
)

export function createTinymceEditor(config) {
	return tinymce.init({
		branding: false,
		plugins: 'code media table lists image paste ceeview-components ceeview-table-styling',
		toolbar: 'undo redo | styleselect | bold italic | numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent',
		menu: {
			file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
			edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
			view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
			insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
			format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat' },
			tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
			table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable | ceeview-table-appearance' },
			components: {title: i('Components'), items: components.map(x => 'component-' + x.id)},
			help: { title: 'Help', items: 'help' }
		},
		menubar: 'file edit view format components table help',
		contextmenu:'link linkchecker image imagetools table spellchecker configurepermanentpen ceeview-components ceeview-table-styling',
		language:  ApplicationState.locale == 'no' ? 'nb_NO' : null,
		automatic_uploads: false,
		paste_data_images: true,
		resize_img_proportional: false,
		font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;zapf dingbats",
		content_css: '/ceeview/assets-generated/app.css',
		...config
	});
}

export function destroyTinymceEditor(key) {
	tinymce.editors[key]?.destroy();
}
